import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { TableFilterType } from 'src/app/common/constants/table.constants';
import { ITableColumn } from 'src/app/common/models/table.model';
import { TableService } from 'src/app/common/services/table/table.service';

@Component({
  selector: 'ignis-table-text-filter',
  templateUrl: './table-text-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableTextFilterComponent {
  @Input() column: ITableColumn;
  @Input() customFilter: string;
  @Input() tableStoredFilters;

  filterType = TableFilterType;
  isInputValueValid: boolean = true;

  tableService: TableService = inject(TableService);

  @Output() handleInputFilterValue: EventEmitter<{ field: string; value: string }> = new EventEmitter<{
    field: string;
    value: string;
  }>();
  @Output() handleClearInputFilterValue: EventEmitter<string> = new EventEmitter<string>();

  onInputChange(value: string): void {
    this.handleInputFilterValue.emit({ field: this.column?.field, value });
  }

  onInputClear(): void {
    this.isInputValueValid = true;
    this.handleClearInputFilterValue.emit(this.column?.field);
  }
}
