<div
  [ngClass]="{ 'alarm-icon': usage === 'alarms', 'team-firefighter-icon': usage === 'team-firefighter' }"
  class="icon"
  [class]="currentAppTheme"
  [ngSwitch]="alarmTypeValue"
>
  <odx-icon
    size="inline"
    class="motion-sensing"
    data-test-id="motion-sensing"
    name="motion-sensing"
    *ngSwitchCase="'adsu'"
  ></odx-icon>
  <odx-icon size="inline" class="sos" data-test-id="sos" name="sos" *ngSwitchCase="'dsu'"></odx-icon>
  <odx-icon
    size="inline"
    class="battery-warning"
    data-test-id="battery-warning"
    name="battery-warning"
    *ngSwitchCase="'low_battery'"
  >
  </odx-icon>
  <odx-icon
    size="inline"
    class="telemetry-error"
    data-test-id="telemetry-error"
    name="telemetry-error"
    *ngSwitchCase="'out_of_range'"
  >
    <span></span>
  </odx-icon>
  <odx-icon
    size="inline"
    class="cylinder-error"
    data-test-id="cylinder-error"
    name="cylinder-error"
    *ngSwitchCase="'low_pressure'"
  >
    <span></span>
  </odx-icon>
  <odx-icon
    size="inline"
    class="evacuation-warning"
    data-test-id="evacuation-warning"
    name="evacuation-warning"
    *ngSwitchCase="'evacuation'"
  >
    <span></span>
  </odx-icon>
  <odx-icon
    size="inline"
    class="person-confirm"
    data-test-id="person-confirm"
    name="person-confirm"
    *ngSwitchCase="'withdraw'"
  >
    <span></span>
  </odx-icon>
  <odx-icon
    size="inline"
    class="thermal-alarm"
    data-test-id="thermal-alarm"
    name="thermal-alarm"
    *ngSwitchCase="'thermal_exposure'"
  >
  </odx-icon>
</div>
