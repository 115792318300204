import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { ILegalTermsData } from '../models/legal-terms.model';
import { LegalTermsActionType } from './legal-terms-action-types';

export class RequestGDPRData implements Action {
  readonly type: LegalTermsActionType.REQUEST_GDPR_DATA = LegalTermsActionType.REQUEST_GDPR_DATA;
}
export class ReceiveGDPRData implements Action {
  readonly type: LegalTermsActionType.RECEIVE_GDPR_DATA = LegalTermsActionType.RECEIVE_GDPR_DATA;

  constructor(public payload: ILegalTermsData) {}
}
export class ErrorReceiveGDPRData implements Action {
  readonly type: LegalTermsActionType.ERROR_RECEIVE_GDPR_DATA = LegalTermsActionType.ERROR_RECEIVE_GDPR_DATA;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestAcceptGDPR implements Action {
  readonly type: LegalTermsActionType.REQUEST_ACCEPT_GDPR = LegalTermsActionType.REQUEST_ACCEPT_GDPR;

  constructor(public payload: string) {}
}
export class ReceiveAcceptGDPR implements Action {
  readonly type: LegalTermsActionType.RECEIVE_ACCEPT_GDPR = LegalTermsActionType.RECEIVE_ACCEPT_GDPR;
}
export class ErrorReceiveAcceptGDPR implements Action {
  readonly type: LegalTermsActionType.ERROR_RECEIVE_ACCEPT_GDPR = LegalTermsActionType.ERROR_RECEIVE_ACCEPT_GDPR;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestTACData implements Action {
  readonly type: LegalTermsActionType.REQUEST_TAC_DATA = LegalTermsActionType.REQUEST_TAC_DATA;
}
export class ReceiveTACData implements Action {
  readonly type: LegalTermsActionType.RECEIVE_TAC_DATA = LegalTermsActionType.RECEIVE_TAC_DATA;

  constructor(public payload: ILegalTermsData) {}
}
export class ErrorReceiveTACData implements Action {
  readonly type: LegalTermsActionType.ERROR_RECEIVE_TAC_DATA = LegalTermsActionType.ERROR_RECEIVE_TAC_DATA;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestAcceptTAC implements Action {
  readonly type: LegalTermsActionType.REQUEST_ACCEPT_TAC = LegalTermsActionType.REQUEST_ACCEPT_TAC;

  constructor(public payload: string) {}
}
export class ReceiveAcceptTAC implements Action {
  readonly type: LegalTermsActionType.RECEIVE_ACCEPT_TAC = LegalTermsActionType.RECEIVE_ACCEPT_TAC;
}
export class ErrorReceiveAcceptTAC implements Action {
  readonly type: LegalTermsActionType.ERROR_RECEIVE_ACCEPT_TAC = LegalTermsActionType.ERROR_RECEIVE_ACCEPT_TAC;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestDPAData implements Action {
  readonly type: LegalTermsActionType.REQUEST_DPA_DATA = LegalTermsActionType.REQUEST_DPA_DATA;
}
export class ReceiveDPAData implements Action {
  readonly type: LegalTermsActionType.RECEIVE_DPA_DATA = LegalTermsActionType.RECEIVE_DPA_DATA;

  constructor(public payload: ILegalTermsData) {}
}
export class ErrorReceiveDPAData implements Action {
  readonly type: LegalTermsActionType.ERROR_RECEIVE_DPA_DATA = LegalTermsActionType.ERROR_RECEIVE_DPA_DATA;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestAcceptDPA implements Action {
  readonly type: LegalTermsActionType.REQUEST_ACCEPT_DPA = LegalTermsActionType.REQUEST_ACCEPT_DPA;

  constructor(public payload: string) {}
}
export class ReceiveAcceptDPA implements Action {
  readonly type: LegalTermsActionType.RECEIVE_ACCEPT_DPA = LegalTermsActionType.RECEIVE_ACCEPT_DPA;
}
export class ErrorReceiveAcceptDPA implements Action {
  readonly type: LegalTermsActionType.ERROR_RECEIVE_ACCEPT_DPA = LegalTermsActionType.ERROR_RECEIVE_ACCEPT_DPA;

  constructor(public payload: HttpErrorResponse) {}
}
