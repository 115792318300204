import { PropertyBag } from '../models';
import { CommonConstants } from './common.constants';

export class StorageConstants {
  public static readonly tablesStorageKeys: PropertyBag = {
    EQUIPMENT: 'equipmentTableStorage',
    WORKFLOW: 'workflowTableStorage',
    SERVICE_TASKS: 'serviceTasksTableStorage',
    SERVICE_RESULTS: 'serviceResultsTableStorage',
    ADDRESS_BOOK: 'addressBookTableStorage',
    DEVICE_CONNECTIONS: 'deviceConnectionsTableStorage',
    BA_LOGBOOK: 'logbookTableStorage',
    BA_LOGBOOK_PRE_FILTER: 'preFilterFormStorage',
    REMOTE_MONITORING: 'remoteMonitoringTableStorage',
    REPORT_CENTER: 'reportCenterTableStorage',
    USER_MANAGEMENT: 'userManagementTableStorage',
    BA_WEARER: 'baWearerTableStorage',
  };

  public static readonly persistentLocalStorageKeys: string[] = [
    'workflowTableData',
    'readAlarms',
    'dismissedAlarms',
    'testParameters',
    'okta-token-storage',
    'okta-original-uri-storage',
    'AUTH_TOKEN',
    'ACCESS_TOKEN',
    CommonConstants.routeToNavigateAfterLogin,
    CommonConstants.routeWithoutAuthentication,
    'refresh',
  ];
}
