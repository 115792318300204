import { CommonConstants } from 'src/app/common';
import { EntryModel, IEntryModel } from 'src/app/common/models/common.model';
import { formatAddressFromLocation } from 'src/app/common/utils/general-helper/general-helpers';
import { MapService } from './../../common/services/map/map.service';

export interface IIncidentPage {
  currentPage: number;
  entries: IIncident[];
  pageSize: number;
  totalPages: number;
  totalRecords: number;
}

export class IncidentPage {
  currentPage: number;
  entries: IIncident[];
  pageSize: number;
  totalPages: number;
  totalRecords: number;

  constructor();
  constructor(reportCenter: IIncidentPage);
  constructor(reportCenter?: IIncidentPage) {
    if (!reportCenter) {
      this.currentPage = null;
      this.entries = null;
      this.pageSize = null;
      this.totalPages = null;
      this.totalRecords = null;
    } else {
      this.currentPage = reportCenter.currentPage;
      this.entries = reportCenter.entries.map((incident: IIncident) => new Incident(incident));
      this.pageSize = reportCenter.pageSize;
      this.totalPages = reportCenter.totalPages;
      this.totalRecords = reportCenter.totalRecords;
    }
  }
}

export interface IHazmatType {
  isHazmat: boolean;
  appId: string;
  equipmentSerialNumber: string;
  firefighterId: string;
}

export interface IIncident {
  aggregateId: string;
  incidentNumber?: string;
  duration: number;
  startTime: string;
  startHour?: string;
  formattedDuration?: string;
  durationId?: number;
  type: IEntryModel;
  firefighterNo?: number;
  ecpNo: number;
  incidentData?: IIncidentData;
  primaryEcp: IEcp;
  ecpNames: string[];
  hubIds: string[];
  ecpLocations: ILocation[];
  ecpAddresses?: string[];
  ecps?: IEcp[];
  version?: number;
  occurredEvents: string[];
  manuallyEdited: boolean;
  firefighterHazmatFlags?: IHazmatType[];
}

export class Incident implements IIncident {
  aggregateId: string;
  incidentNumber: string;
  duration: number;
  durationId?: number;
  startTime: string;
  type: IEntryModel;
  firefighterNo: number;
  ecpNo: number;
  incidentData: IIncidentData;
  primaryEcp: IEcp;
  ecpNames: string[];
  hubIds: string[];
  ecpLocations: ILocation[];
  ecpAddresses: string[];
  ecps: IEcp[];
  version?: number;
  occurredEvents: string[];
  manuallyEdited: boolean;

  constructor();
  constructor(reportCenter: IIncident);
  constructor(reportCenter?: IIncident) {
    if (!reportCenter) {
      this.aggregateId = null;
      this.incidentNumber = null;
      this.duration = null;
      this.startTime = null;
      this.type = null;
      this.firefighterNo = null;
      this.ecpNo = null;
      this.incidentData = null;
      this.primaryEcp = null;
      this.ecpNames = null;
      this.hubIds = null;
      this.ecpLocations = null;
      this.ecpAddresses = null;
      this.ecps = null;
      this.version = null;
      this.occurredEvents = null;
      this.manuallyEdited = null;
    } else {
      this.aggregateId = reportCenter.aggregateId;
      this.incidentNumber = reportCenter.incidentNumber;
      this.duration = reportCenter.duration;
      this.startTime = reportCenter.startTime;
      this.type = reportCenter.type;
      this.firefighterNo = reportCenter.firefighterNo;
      this.ecpNo = reportCenter.ecpNo;
      this.incidentData = new IncidentData(reportCenter.incidentData);
      this.primaryEcp = new Ecp(reportCenter.primaryEcp);
      this.ecpNames = reportCenter.ecpNames?.filter((name: string) => name);
      this.hubIds = reportCenter.hubIds;
      this.ecpLocations = reportCenter.ecpLocations?.map((location: ILocation) => new Location(location));
      this.ecpAddresses = reportCenter.ecpLocations
        ?.map((location: ILocation) => formatAddressFromLocation(location))
        ?.filter((location: string) => location);
      this.ecps = reportCenter.ecps?.map((ecp: IEcp) => new Ecp(ecp));
      this.version = reportCenter.version;
      this.occurredEvents = reportCenter.occurredEvents;
      this.manuallyEdited = reportCenter.manuallyEdited;
    }
  }
}

export interface IIncidentsIds {
  incidentNumberOneId: string;
  incidentNumberTwoId: string;
  sourceVersion?: number;
}

export interface IExportIncident {
  entryControlPoints: IExportEcp[];
  incidentData: IIncidentData;
  firefighters: IFirefightersData[];
  locations: IExportLocation[];
}

export interface IIncidentExcelData {
  incident: IExportIncident;
  ecpEvents: IExportEcpEvent;
  baSets: IExportBASet;
  notes: IExportNote[];
}

export interface IExportNote {
  timestamp: string;
  appName: string;
  hubId: string;
  teamInfo: string;
  user: string;
  note: string;
}
export class ExportNote implements IExportNote {
  timestamp: string;
  appName: string;
  hubId: string;
  teamInfo: string;
  user: string;
  note: string;

  constructor();
  constructor(note: IExportNote);
  constructor(note?: IExportNote) {
    if (!note) {
      this.timestamp = null;
      this.appName = null;
      this.hubId = null;
      this.teamInfo = null;
      this.user = null;
      this.note = null;
    } else {
      this.timestamp = note.timestamp;
      this.appName = note.appName;
      this.hubId = note.hubId;
      this.teamInfo = note.teamInfo;
      this.user = note.user;
      this.note = note.note;
    }
  }
}

export interface IExportEcpEvent {
  [ecpEvent: string]: IEcpEvent[];
}
export interface IExportBASet {
  [baSet: string]: IEcpEvent[];
}

export interface IEcpEvent {
  timestamp: string;
  appName: string;
  hubId: string;
  equipmentId: string;
  personalId: string;
  firefighterName: string;
  teamName: string;
  event: string;
  data: string;
}

export class EcpEvent implements IEcpEvent {
  timestamp: string;
  appName: string;
  hubId: string;
  equipmentId: string;
  personalId: string;
  firefighterName: string;
  teamName: string;
  event: string;
  data: string;

  constructor();
  constructor(ecpEvent: IEcpEvent);
  constructor(ecpEvent?: IEcpEvent) {
    if (!ecpEvent) {
      this.timestamp = null;
      this.appName = null;
      this.hubId = null;
      this.equipmentId = null;
      this.personalId = null;
      this.firefighterName = null;
      this.teamName = null;
      this.event = null;
      this.data = null;
    } else {
      this.timestamp = ecpEvent.timestamp;
      this.appName = ecpEvent.appName;
      this.hubId = ecpEvent.hubId;
      this.equipmentId = ecpEvent.equipmentId;
      this.personalId = ecpEvent.personalId;
      this.firefighterName = ecpEvent.firefighterName;
      this.teamName = ecpEvent.teamName;
      this.event = ecpEvent.event;
      this.data = ecpEvent.data;
    }
  }
}

export interface IExportIncidentData extends IIncidentData {
  deployedVehicles: string[];
  hazmat: boolean;
}

export interface IIncidentData {
  incidentNumber: string;
  startTime: string;
  endTime: string;
  incidentType: string;
  incidentCategory: string;
  incidentSpecialExposure: string;
  object: string;
  hazmat: boolean;
  deployedVehicles: string[] | any;
  deployedResources: string;
  comment: string;
  summary: IIncidentSummary;
}

export interface IFirefightersData {
  personalId: string;
  name: string;
  teamName: string;
  equipmentSerialNumber: string;
  usageDuration: string;
}

export interface IIncidentSummary {
  baSetNo: number;
  firefighterNo: number;
  noteworthyAlarms: IArrayElement[];
  manualEventsNo: number;
}

export interface IArrayElement {
  name: string;
  number: number;
}

export class IncidentData implements IIncidentData {
  incidentNumber: string;
  startTime: string;
  endTime: string;
  incidentType: string;
  incidentCategory: string;
  incidentSpecialExposure: string;
  object: string;
  hazmat: boolean;
  deployedVehicles: string | any;
  deployedResources: string;
  comment: string;
  summary: {
    baSetNo: number;
    firefighterNo: number;
    noteworthyAlarms: IArrayElement[];
    manualEventsNo: number;
  };

  constructor();
  constructor(incident: IIncidentData);
  constructor(incident?: IIncidentData) {
    if (!incident) {
      this.incidentNumber = null;
      this.startTime = null;
      this.endTime = null;
      this.incidentType = null;
      this.incidentCategory = null;
      this.incidentSpecialExposure = null;
      this.object = null;
      this.hazmat = null;
      this.deployedVehicles = null;
      this.deployedResources = null;
      this.comment = null;
      this.summary = null;
    } else {
      this.incidentNumber = incident.incidentNumber;
      this.startTime = incident.startTime;
      this.endTime = incident.endTime;
      this.incidentType = incident.incidentType;
      this.incidentCategory = incident.incidentCategory;
      this.incidentSpecialExposure = incident.incidentSpecialExposure;
      this.object = incident.object;
      this.hazmat = incident.hazmat;
      this.deployedVehicles = incident.deployedVehicles?.join(CommonConstants.splitArrayOfStringsRule);
      this.deployedResources = incident.deployedResources;
      this.comment = incident.comment;
      this.summary = incident.summary;
    }
  }
}

export interface IEntriesModel {
  types: IEntryModel[];
  categories: IEntryModel[];
  specialExposures: IEntryModel[];
  hubIds: string[];
  minDeployedFirefighters: number;
  maxDeployedFirefighters: number;
  withheldReportsNo: number;
}

export class EntriesModel implements IEntriesModel {
  types: IEntryModel[];
  categories: IEntryModel[];
  specialExposures: IEntryModel[];
  hubIds: string[];
  minDeployedFirefighters: number;
  maxDeployedFirefighters: number;
  withheldReportsNo: number;

  constructor();
  constructor(entries: IEntriesModel);
  constructor(entries?: IEntriesModel) {
    if (!entries) {
      this.types = null;
      this.categories = null;
      this.specialExposures = null;
      this.hubIds = null;
      this.minDeployedFirefighters = null;
      this.maxDeployedFirefighters = null;
      this.withheldReportsNo = null;
    } else {
      this.types = entries.types?.map((entry: IEntryModel) => new EntryModel(entry));
      this.categories = entries.categories?.map((entry: IEntryModel) => new EntryModel(entry));
      this.specialExposures = entries.specialExposures?.map((entry: IEntryModel) => new EntryModel(entry));
      this.hubIds = entries.hubIds;
      this.minDeployedFirefighters = entries.minDeployedFirefighters;
      this.maxDeployedFirefighters = entries.maxDeployedFirefighters;
      this.withheldReportsNo = entries.withheldReportsNo;
    }
  }
}

export interface IExportLocation {
  coordinates: { lat: number; lng: number };
  address: string;
  postcode: string;
}

export interface ILocation {
  displayedCoordinates: string;
  coordinates: { lat: number; lng: number };
  address: string;
  postcode: string;
}
export interface ILocation {
  displayedCoordinates: string;
  coordinates: { lat: number; lng: number };
  address: string;
  postcode: string;
}

export class Location implements ILocation {
  address: string;
  postcode: string;
  coordinates: {
    lat: number;
    lng: number;
  };
  displayedCoordinates: string;

  private mapService: MapService;

  constructor();
  constructor(location: ILocation);
  constructor(location?: ILocation) {
    if (location) {
      this.mapService = new MapService();

      this.address = location.address;
      this.postcode = location.postcode;
      this.displayedCoordinates = this.mapService.convertToDMS(
        location.coordinates.lat,
        location.coordinates.lng,
        true,
      );
      this.coordinates = location.coordinates;
    } else {
      this.address = null;
      this.postcode = null;
      this.displayedCoordinates = null;
      this.coordinates = null;
    }
  }
}

export interface IExportEcp {
  appId: string;
  hubId: string;
  appName?: string;
  user?: string;
}

export interface IEcp {
  name: string;
  appId: string;
  hubId: string;
  alarms?: IAlarm[];
  location?: ILocation | any;
  user?: string;
  eventMarkerClusters?: [IEventMarker[]];
  incidentEventMarkerClusters?: [IEventMarkerClusters[]];
  mediaAssets?: [IMediaAsset[]];
  primary?: boolean | any;
}

export class Ecp implements IEcp {
  name: string;
  appId: string;
  hubId: string;
  location: ILocation | any;
  user: string;
  primary?: boolean | any;

  constructor();
  constructor(ecp: IEcp);
  constructor(ecp?: IEcp) {
    if (ecp) {
      this.name = ecp.name || '-';
      this.appId = ecp.appId || '-';
      this.hubId = ecp.hubId || '-';
      this.location = new Location(ecp.location);
      this.user = ecp.user || '-';
      this.primary = ecp.primary;
    } else {
      this.name = null;
      this.appId = null;
      this.hubId = null;
      this.location = null;
      this.user = null;
      this.primary = null;
    }
  }
}

export interface IMediaAsset {
  startTime: string;
  endTime: string;
  uploadedBy: string;
  uploaderName: string;
}

export class MediaAsset implements IMediaAsset {
  startTime: string;
  endTime: string;
  uploadedBy: string;
  uploaderName: string;

  constructor();
  constructor(asset: IMediaAsset);
  constructor(asset?: IMediaAsset) {
    if (asset) {
      this.startTime = asset.startTime;
      this.endTime = asset.endTime;
      this.uploadedBy = asset.uploadedBy ? asset.uploadedBy : '-';
      this.uploaderName = asset.uploaderName ? asset.uploaderName : '-';
    } else {
      this.startTime = null;
      this.endTime = null;
      this.uploadedBy = null;
      this.uploaderName = null;
    }
  }
}

export interface IEventMarkerClusters {
  id: string;
  teamName: string;
  teamStatus: string;
  timestamp: string;
  type: string;
}

export class EventMarkerClusters implements IEventMarkerClusters {
  id: string;
  teamName: string;
  teamStatus: string;
  timestamp: string;
  type: string;

  constructor();
  constructor(cluster: IEventMarkerClusters);
  constructor(cluster?: IEventMarkerClusters) {
    if (cluster) {
      this.id = cluster.id;
      this.teamName = cluster.teamName;
      this.teamStatus = cluster.teamStatus;
      this.timestamp = cluster.timestamp;
      this.type = cluster.type;
    } else {
      this.id = null;
      this.teamName = null;
      this.teamStatus = null;
      this.timestamp = null;
      this.type = null;
    }
  }
}

export interface IIncidentAnalysis {
  startTime: string;
  endTime: string;
  incidentTimeline: IIncidentTimeline;
  ecpTimelines: IEcpTimeline;
  version?: string;
}

export class IncidentAnalysis implements IIncidentAnalysis {
  startTime: string;
  endTime: string;
  incidentTimeline: IIncidentTimeline;
  ecpTimelines: IEcpTimeline;

  constructor();
  constructor(incidentAnalysis: IIncidentAnalysis);
  constructor(incidentAnalysis?: IIncidentAnalysis) {
    if (incidentAnalysis) {
      this.startTime = incidentAnalysis.startTime;
      this.endTime = incidentAnalysis.endTime;
      this.incidentTimeline = new IncidentTimeline(incidentAnalysis.incidentTimeline);
      this.ecpTimelines = new EcpTimeline(incidentAnalysis.ecpTimelines);
    } else {
      this.startTime = null;
      this.endTime = null;
      this.incidentTimeline = null;
      this.ecpTimelines = null;
    }
  }
}

export interface IEcpTimeline {
  ecps: IEcpChart[];
}

export class EcpTimeline implements IEcpTimeline {
  ecps: IEcpChart[];

  constructor();
  constructor(ecpTimeline: IEcpTimeline);
  constructor(ecpTimeline?: IEcpTimeline) {
    if (ecpTimeline) {
      this.ecps = ecpTimeline.ecps.map((ecp: IEcpChart) => new EcpChart(ecp));
    } else {
      this.ecps = null;
    }
  }
}

export interface IEcpChart {
  startTime: string;
  endTime: string;
  name: string;
  appId: string;
  baSets: IBaSet[];
  filteredBaSets: IBaSet[];
}

export class EcpChart implements IEcpChart {
  startTime: string;
  endTime: string;
  name: string;
  appId: string;
  baSets: IBaSet[];
  filteredBaSets: IBaSet[];

  constructor();
  constructor(ecpChart: IEcpChart);
  constructor(ecpChart?: IEcpChart) {
    if (ecpChart) {
      this.startTime = ecpChart.startTime;
      this.endTime = ecpChart.endTime;
      this.name = ecpChart.name;
      this.appId = ecpChart.appId;
      this.baSets = ecpChart.baSets;
      this.filteredBaSets = ecpChart.baSets.filter((baSet: IBaSet) => baSet.ecpAlarmRow.length);
    } else {
      this.startTime = null;
      this.endTime = null;
      this.name = null;
      this.appId = null;
      this.baSets = null;
      this.filteredBaSets = null;
    }
  }
}

export interface IBaSet {
  equipmentSerialNumber: string;
  equipmentModel: string;
  firefighterName: string;
  teamName: string;
  ecpAlarmRow: IAlarm[];
  firefighterAlarmRow: IAlarm[];
  pressures: any[];
  activationTimes: string[];
  deactivationTimes: string[];
  teamLeadBaSet: boolean;
  baWearerDuration: string;
  hazmat: boolean;
  firefighterId: string;
}

export class BaSet implements IBaSet {
  equipmentSerialNumber: string;
  equipmentModel: string;
  firefighterName: string;
  teamName: string;
  ecpAlarmRow: IAlarm[];
  firefighterAlarmRow: IAlarm[];
  pressures: any[];
  activationTimes: string[];
  deactivationTimes: string[];
  teamLeadBaSet: boolean;
  baWearerDuration: string;
  hazmat: boolean;
  firefighterId: string;

  constructor();
  constructor(baSet: IBaSet);
  constructor(baSet?: IBaSet) {
    if (baSet) {
      this.equipmentSerialNumber = baSet.equipmentSerialNumber;
      this.equipmentModel = baSet.equipmentModel;
      this.firefighterName = baSet.firefighterName;
      this.teamName = baSet.teamName;
      this.ecpAlarmRow = baSet.ecpAlarmRow;
      this.firefighterAlarmRow = baSet.firefighterAlarmRow;
      this.pressures = baSet.pressures;
      this.activationTimes = baSet.activationTimes;
      this.deactivationTimes = baSet.deactivationTimes;
      this.teamLeadBaSet = baSet.teamLeadBaSet;
      this.baWearerDuration = baSet.baWearerDuration;
      this.hazmat = baSet.hazmat;
      this.firefighterId = baSet.firefighterId;
    } else {
      this.equipmentSerialNumber = null;
      this.equipmentModel = null;
      this.firefighterName = null;
      this.teamName = null;
      this.ecpAlarmRow = null;
      this.firefighterAlarmRow = null;
      this.pressures = null;
      this.activationTimes = null;
      this.deactivationTimes = null;
      this.teamLeadBaSet = null;
      this.baWearerDuration = null;
      this.hazmat = null;
      this.firefighterId = null;
    }
  }
}

export interface IIncidentTimeline {
  ecps: IEcp[];
}

export class IncidentTimeline implements IIncidentTimeline {
  ecps: IEcp[];

  constructor();
  constructor(eventTimeline: IIncidentTimeline);
  constructor(eventTimeline?: IIncidentTimeline) {
    if (eventTimeline) {
      this.ecps = eventTimeline.ecps;
    } else {
      this.ecps = null;
    }
  }
}

export class AlarmChartDurations {
  startTime: string;
  endTime: string;

  constructor(start: string, end: string) {
    this.startTime = start;
    this.endTime = end;
  }
}

export interface IEventMarker {
  id: string;
  name: string;
  type: string;
  startTime?: string;
  endTime?: string;
  ecp: string;
  comment: string;
  duration?: number;
  lineIndex?: number;
  version?: number;
}

export class EventMarker implements IEventMarker {
  id: string;
  name: string;
  type: string;
  startTime?: string;
  endTime?: string;
  ecp: string;
  comment: string;
  version: number;

  constructor();
  constructor(eMarker: IEventMarker);
  constructor(eMarker?: IEventMarker) {
    if (eMarker) {
      this.id = eMarker.id;
      this.name = eMarker.name;
      this.type = eMarker.type;
      this.startTime = eMarker.startTime;
      this.endTime = eMarker.endTime;
      this.ecp = eMarker.ecp;
      this.comment = eMarker.comment;
      this.version = eMarker.version;
    } else {
      this.id = null;
      this.name = null;
      this.type = null;
      this.startTime = null;
      this.endTime = null;
      this.ecp = null;
      this.comment = null;
      this.version = null;
    }
  }
}

export interface IAlarm {
  startTime?: string;
  endTime?: string;
  durations?: AlarmChartDurations[];
  duration?: string;
  type: string;
  equipmentId?: string;
  firefighterName?: string;
  isOverlap?: boolean;
}

export class Alarm implements IAlarm {
  startTime?: string;
  endTime?: string;
  duration?: string;
  durations?: AlarmChartDurations[];
  type: string;
  equipmentId?: string;
  firefighterName?: string;

  constructor();
  constructor(alarm: IAlarm);
  constructor(alarm?: IAlarm) {
    if (alarm) {
      this.startTime = alarm.startTime;
      this.endTime = alarm.endTime;
      this.duration = alarm.duration;
      this.durations = alarm.durations;
      this.type = alarm.type;
      this.equipmentId = alarm.equipmentId;
      this.firefighterName = alarm.firefighterName;
    } else {
      this.startTime = null;
      this.endTime = null;
      this.duration = null;
      this.durations = null;
      this.type = null;
      this.equipmentId = null;
      this.firefighterName = null;
    }
  }
}

export class AlarmChartEntry {
  start: Date;
  end: Date;

  constructor(start: Date, end: Date) {
    this.start = start;
    this.end = end;
  }
}

export class AlarmChartData {
  id?: string;
  type: string;
  color: string;
  borderColor: string;
  icon?: string;
  entries: AlarmChartEntry[];
  equipmentId?: string;
  firefighter?: string;
  name?: string;
  uploaderName?: string;
  lineIndex?: number;
  isManualEvent?: boolean;
  isMediaAsset?: boolean;
  isEventMarkerCluster?: boolean;
  status?: string;
  order?: number;
  isOverlap?: boolean;
  version?: string;

  constructor();
  constructor(alarm: AlarmChartData);
  constructor(alarm?: AlarmChartData) {
    if (alarm) {
      this.id = alarm.id;
      this.type = alarm.type;
      this.color = alarm.color;
      this.borderColor = alarm.borderColor;
      this.entries = alarm.entries;
      this.equipmentId = alarm.equipmentId;
      this.firefighter = alarm.firefighter;
      this.name = alarm.name;
      this.uploaderName = alarm.uploaderName;
      this.icon = alarm.icon;
      this.lineIndex = alarm.lineIndex;
      this.order = alarm.order;
      this.isManualEvent = alarm.isManualEvent;
      this.isMediaAsset = alarm.isMediaAsset;
      this.isEventMarkerCluster = alarm.isEventMarkerCluster;
      this.status = alarm.status;
      this.isOverlap = alarm.isOverlap;
    } else {
      this.id = undefined;
      this.type = null;
      this.color = null;
      this.borderColor = null;
      this.entries = null;
      this.equipmentId = undefined;
      this.firefighter = undefined;
      this.name = undefined;
      this.uploaderName = undefined;
      this.icon = undefined;
      this.lineIndex = undefined;
      this.order = undefined;
      this.isManualEvent = undefined;
      this.isMediaAsset = undefined;
      this.isEventMarkerCluster = undefined;
      this.status = undefined;
      this.isOverlap = undefined;
    }
  }
}

export interface IPreparedGraph {
  graphData: IPressureEntry[];
  graphType: string;
}

export interface IPressureEntry {
  cylinderPressure: number;
  timestamp: Date;
}

export class PressureEntry implements IPressureEntry {
  cylinderPressure: number;
  timestamp: Date;

  constructor();
  constructor(entry: IPressureEntry);
  constructor(entry?: IPressureEntry) {
    if (entry) {
      this.cylinderPressure = entry.cylinderPressure;
      this.timestamp = new Date(entry.timestamp);
    } else {
      this.cylinderPressure = null;
      this.timestamp = null;
    }
  }
}

export interface IPressureGraph {
  high: IPressureEntry[];
  medium: IPressureEntry[];
  low: IPressureEntry[];
}

export class PressureGraph implements IPressureGraph {
  high: IPressureEntry[];
  medium: IPressureEntry[];
  low: IPressureEntry[];

  constructor();
  constructor(graph: IPressureGraph);
  constructor(graph?: IPressureGraph) {
    if (graph) {
      this.high = graph.high.map((entry: IPressureEntry) => new PressureEntry(entry));
      this.medium = graph.medium.map((entry: IPressureEntry) => new PressureEntry(entry));
      this.low = graph.low.map((entry: IPressureEntry) => new PressureEntry(entry));
    } else {
      this.high = null;
      this.medium = null;
      this.low = null;
    }
  }
}

export interface INewNote {
  senderName: string;
  text?: string;
  mediaLink?: string;
  ecpId?: string;
}

export class NewNote implements INewNote {
  senderName: string;
  text: string;

  constructor();
  constructor(newNote: INewNote);
  constructor(newNote?: INewNote) {
    if (newNote) {
      this.senderName = newNote.senderName;
      this.text = newNote.text;
    } else {
      this.senderName = null;
      this.text = null;
    }
  }
}

export interface IMediaAssets {
  ecpName?: string;
  ecpNumber?: string;
  mediaLink?: string;
  mediaSrc?: string | ArrayBuffer;
  senderName: string;
  teamName?: string;
  teamNumber?: string;
  teamId: string;
  text?: string;
  timestamp?: string;
  time?: string;
  uploadType?: string;
  version?: number;
}

export class MediaAssets implements IMediaAssets {
  ecpName?: string;
  ecpNumber?: string;
  mediaLink?: string;
  senderName: string;
  teamName?: string;
  teamNumber?: string;
  teamId: string;
  text?: string;
  timestamp?: string;
  time?: string;
  uploadType?: string;
  version?: number;

  constructor();
  constructor(mediaAsset: IMediaAssets);
  constructor(mediaAsset?: IMediaAssets) {
    if (mediaAsset) {
      this.ecpName = mediaAsset.ecpName;
      this.ecpNumber = mediaAsset.ecpNumber;
      this.mediaLink = mediaAsset.mediaLink;
      this.senderName = mediaAsset.senderName;
      this.teamName = mediaAsset.teamName;
      this.teamNumber = mediaAsset.teamNumber;
      this.teamId = mediaAsset.teamId;
      this.text = mediaAsset.text;
      this.timestamp = mediaAsset.timestamp;
      this.time = mediaAsset.time;
      this.uploadType = mediaAsset.uploadType;
      this.version = mediaAsset.version;
    } else {
      this.ecpName = null;
      this.ecpNumber = null;
      this.mediaLink = null;
      this.senderName = null;
      this.teamName = null;
      this.teamNumber = null;
      this.teamId = null;
      this.text = null;
      this.timestamp = null;
      this.time = null;
      this.uploadType = null;
      this.version = null;
    }
  }
}

export interface IMedia {
  mediaLink: string;
  uploadType: string;
  uploadedBy: string;
  timestamp: string;
  time?: string;
}

export class Media implements IMedia {
  mediaLink: string;
  uploadType: string;
  uploadedBy: string;
  timestamp: string;

  constructor();
  constructor(mediaItem: IMedia);
  constructor(mediaItem?: IMedia) {
    if (mediaItem) {
      this.mediaLink = mediaItem.mediaLink;
      this.uploadType = mediaItem.uploadType;
      this.uploadedBy = mediaItem.uploadedBy;
      this.timestamp = mediaItem.timestamp;
    } else {
      this.mediaLink = null;
      this.uploadType = null;
      this.uploadedBy = null;
      this.timestamp = null;
    }
  }
}

export interface INote {
  text: string;
  senderName: string;
  ecpName: string;
  teamName: string;
  ecpNumber: string;
  teamNumber: string;
  timestamp: string;
  time?: string;
}

export class Note implements INote {
  text: string;
  senderName: string;
  ecpName: string;
  teamName: string;
  ecpNumber: string;
  teamNumber: string;
  timestamp: string;
  time?: string;

  constructor();
  constructor(note: INote);
  constructor(note?: INote) {
    if (!note) {
      this.text = null;
      this.senderName = null;
      this.ecpName = null;
      this.teamName = null;
      this.ecpNumber = null;
      this.teamNumber = null;
      this.timestamp = null;
    } else {
      this.text = note.text;
      this.senderName = note.senderName;
      this.ecpName = note.ecpName;
      this.teamName = note.teamName;
      this.ecpNumber = note.ecpNumber;
      this.teamNumber = note.teamNumber;
      this.timestamp = note.timestamp;
    }
  }
}
