import { GenericAction } from 'src/app/common';
import { ITableSettingsResponse, ITableSettingsUpdate } from 'src/app/common/models/table.model';
import { ILocalizationProfile } from '../../models/localization-profile.model';
import { SettingsActionTypes } from '../actions/settings-action-types';
import { ISettingsState } from '../models/settings.model';
import {
  errorItemData,
  errorListData,
  errorPostPutItemData,
  receiveItemData,
  receiveListData,
  receivePostPutItemData,
  requestItemData,
  requestListData,
  requestPostPutItemData,
} from './../../../common/utils/reducer/reducer.utils';
import { IMeasurementUnit } from './../../models/localization-profile.model';
import { SettingsState } from './../models/settings.model';

const resetTableColumnsState = (lastState: ISettingsState): ISettingsState => ({
  ...lastState,
  tableColumns: {
    isLoading: false,
    isSuccess: false,
    data: null,
    errors: null,
  },
});

export function settingsReducer(
  lastState: ISettingsState = new SettingsState(),
  action: GenericAction<SettingsActionTypes, any>,
): ISettingsState {
  switch (action.type) {
    case SettingsActionTypes.REQUEST_LOCALIZED_OPTIONS:
      return requestListData<ISettingsState, ILocalizationProfile[]>(lastState, ['localizationList']);
    case SettingsActionTypes.RECEIVE_LOCALIZED_OPTIONS:
      return receiveListData<ISettingsState, ILocalizationProfile[]>(lastState, ['localizationList'], action.payload);
    case SettingsActionTypes.ERROR_RECEIVE_LOCALIZED_OPTIONS:
      return errorListData<ISettingsState, ILocalizationProfile[]>(lastState, ['localizationList'], action.payload);

    case SettingsActionTypes.REQUEST_GET_USER_SETTINGS:
      return requestItemData<ISettingsState, ILocalizationProfile>(lastState, ['userSettings']);
    case SettingsActionTypes.RECEIVE_GET_USER_SETTINGS:
      return receiveItemData<ISettingsState, ILocalizationProfile>(lastState, ['userSettings'], action.payload);
    case SettingsActionTypes.ERROR_GET_USER_SETTINGS:
      return errorItemData<ISettingsState, ILocalizationProfile>(lastState, ['userSettings'], action.payload);

    case SettingsActionTypes.REQUEST_SAVE_USER_SETTINGS:
      return requestPostPutItemData<ISettingsState, ILocalizationProfile>(lastState, ['userSettings'], action.payload);
    case SettingsActionTypes.RECEIVE_SAVE_USER_SETTINGS:
      return receivePostPutItemData<ISettingsState, ILocalizationProfile>(lastState, ['userSettings'], action.payload);
    case SettingsActionTypes.ERROR_SAVE_USER_SETTINGS:
      return errorPostPutItemData<ISettingsState, ILocalizationProfile>(lastState, ['userSettings'], action.payload);

    case SettingsActionTypes.REQUEST_MEASUREMENT_UNITS:
      return requestListData<ISettingsState, IMeasurementUnit[]>(lastState, ['measurementUnits']);
    case SettingsActionTypes.RECEIVE_MEASUREMENT_UNITS:
      return receiveListData<ISettingsState, IMeasurementUnit[]>(lastState, ['measurementUnits'], action.payload);
    case SettingsActionTypes.ERROR_RECEIVE_MEASUREMENT_UNITS:
      return errorListData<ISettingsState, IMeasurementUnit[]>(lastState, ['measurementUnits'], action.payload);

    case SettingsActionTypes.REQUEST_TABLE_COLUMNS:
      return requestListData<ISettingsState, ITableSettingsResponse>(lastState, ['tableColumns']);
    case SettingsActionTypes.RECEIVE_TABLE_COLUMNS:
      return receiveListData<ISettingsState, ITableSettingsResponse>(lastState, ['tableColumns'], action.payload);
    case SettingsActionTypes.ERROR_RECEIVE_TABLE_COLUMNS:
      return errorListData<ISettingsState, ITableSettingsResponse>(lastState, ['tableColumns'], action.payload);
    case SettingsActionTypes.RESET_TABLE_COLUMN:
      return resetTableColumnsState(lastState);

    case SettingsActionTypes.REQUEST_UPDATE_TABLE_COLUMN:
      return requestPostPutItemData<ISettingsState, ITableSettingsUpdate>(
        lastState,
        ['updateTableColumns'],
        action.payload.columns,
      );
    case SettingsActionTypes.RECEIVE_UPDATE_TABLE_COLUMN:
      return receivePostPutItemData<ISettingsState, ITableSettingsUpdate>(
        lastState,
        ['updateTableColumns'],
        action.payload,
      );
    case SettingsActionTypes.ERROR_RECEIVE_UPDATE_TABLE_COLUMN:
      return errorPostPutItemData<ISettingsState, ITableSettingsUpdate>(
        lastState,
        ['updateTableColumns'],
        action.payload,
      );

    default:
      return lastState;
  }
}
