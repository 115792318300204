<ignis-progress-bar *ngIf="isLoading$ | async"></ignis-progress-bar>
<ignis-table
  *ngIf="toggleTable"
  class="large-height-table"
  [tableName]="tableName"
  [localColumns]="localTableColumns"
  [dataTable]="deviceConnectionsList"
  [resizableColumns]="true"
  [reorderableColumns]="true"
  [isDefaultRangeShown]="true"
  [activateClickOutside]="activateClickOutside"
  [displayFilters]="true"
  [customFilterLayout]="customFilterLayouts"
  [datePickerFormat]="formatDate"
  [datePickerSelectionMode]="'range'"
  [isDatePickerShowingTime]="false"
  [customColumnsLayout]="['category.value']"
  [customColumnsRef]="[categoryModel]"
  [savedFiltersName]="tableFiltersKey"
  [lazy]="'true'"
  [activeDate]="selectedDates"
  [isLoading]="isLoading$ | async"
  [paginator]="'true'"
  [pageNumber]="pageNumber"
  [totalRecords]="totalRecords"
  [selectActiveOptions]="selectedDropdowns"
  [sortColumn]="sortColumn"
  [tableColumns]="tableColumns"
  (columns)="processingTableSettings($event); getUpdatedTableColumns($event)"
  (handleTableRef)="tableHelperGetTableRef($event)"
  (handleRowSelection)="onDeviceConnectionSelect($event)"
  (handleDoubleClickRowSelection)="openUpdateDeviceConnectionModal()"
  (handleColumnVisibility)="changeColumnVisibility($event)"
  (handleFilterValue)="getFilterTableValue($event)"
  (handleOnLazyLoading)="tableHelperOnLazyLoading($event)"
  (handleOnPageChange)="tableHelperOnPageChange($event)"
  (handleOnSortChange)="tableHelperOnChangeSort($event)"
>
  <ng-template #categoryModel let-categoryModel>
    <ignis-device-connection-category-translation
      [entry]="categoryModel"
    ></ignis-device-connection-category-translation>
  </ng-template>
</ignis-table>

<ignis-export-table-excel
  [excelArray]="excelArray"
  [formatDate]="formatDate"
  [tableColumns]="tableColumns"
  [excelTranslationKeys]="excelTranslationKeys"
  [sheetName]="'EXCEL_TABLE_EXPORT.STR_DEVICE_CONNECTIONS' | translate"
  [fileName]="'EXCEL_TABLE_EXPORT.STR_DEVICE_CONNECTIONS' | translate"
  (handleExcelExportFinished)="excelExportDone()"
></ignis-export-table-excel>

<footer class="blue-bar">
  <ignis-device-connection-module-footer
    [selectedItem]="activeDeviceConnection"
    [numberOfRecordsToBeExported]="totalRecords"
    [isModalOpened]="openConfirmationDeleteDialog"
    (handleCreate)="openCreateDeviceConnectionModal()"
    (handleEdit)="openUpdateDeviceConnectionModal()"
    (handleDelete)="openDeleteDeviceConnectionDialog()"
    (handleExcelExport)="exportExcelFile()"
  >
  </ignis-device-connection-module-footer>
</footer>

<ignis-delete-confirmation-modal
  [isOpen]="openConfirmationDeleteDialog"
  [isLoading]="isLoading$ | async"
  (handleDeleteModal)="closeDeleteDeviceConnectionDialog($event)"
>
  <div slot="header">
    {{ 'DEVICE_CONNECTION.STR_DELETE_DEVICE_CONNECTION' | translate }}
  </div>
  <div slot="content">
    @if (activeDeviceConnection?.identification) {
      <span>
        {{
          'DEVICE_CONNECTION.STR_DELETE_DEVICE_CONNECTION_MSG'
            | translate
              : {
                  id: activeDeviceConnection?.identification,
                  name: activeDeviceConnection?.name || '-',
                }
        }}
      </span>
    } @else {
      <span>
        {{
          'DEVICE_CONNECTION.STR_DELETE_RAM_DEVICE_MSG'
            | translate
              : {
                  name: activeDeviceConnection?.name || '-',
                }
        }}
      </span>
    }

    <p>{{ 'DEVICE_CONNECTION.STR_DELETE_DEVICE_CONNECTION_SECOND_MSG' | translate }}</p>
  </div>
</ignis-delete-confirmation-modal>
