import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ModalRef, ModalService, ModalSize, ModalVariant } from '@odx/angular/components/modal';
import { Observable } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { IStoreApiList } from 'src/app/common';
import { CommonConstants } from 'src/app/common/constants/common.constants';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IEntryModel } from 'src/app/common/models/common.model';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { IncidentNotificationConstants } from 'src/app/report-center';
import { ReportCenterConstants } from 'src/app/report-center/constants/report-center.constants';
import { IEcp, IEventMarker, IIncident } from 'src/app/report-center/models/incident.model';
import { IncidentActions } from 'src/app/report-center/state/actions/incident.actions';
import { IIncidentState } from 'src/app/report-center/state/models/incident.model';
import {
  selectEventMarker,
  selectEventMarkersEntries,
  selectIncident,
} from 'src/app/report-center/state/selectors/incident.selector';
import {
  calculateDurationWithoutSeconds,
  convertToDateTimeFormat,
} from '../../../../../common/utils/date-utils/date.utils';
import { getDateFormatFromUserProfile } from '../../../../../common/utils/settings-utils/settings-utils';

@Component({
  selector: 'ignis-create-update-event-marker',
  templateUrl: './create-update-event-marker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateUpdateEventMarkerComponent extends OnDestroyMixin() implements OnInit, AfterViewInit {
  modalType: string;
  isLoading: boolean;
  selectedEventMarker: IEventMarker;
  modalReference: ModalRef;
  eventMarkerForm: FormGroup;
  locationState: IIncidentState;
  successAddUpdate: boolean = false;
  isSubmitted: boolean = false;
  hasErrors: boolean = false;
  errors: any;
  isConfirmCloseModalOpen: boolean = false;
  currentIncident: any;
  isSubmitting: Observable<boolean>;

  formatDate: string;
  entries: Observable<IEntryModel[]>;

  private readonly modalService: ModalService = inject(ModalService);
  notificationsService: NotificationsService = inject(NotificationsService);
  translateService: TranslateService = inject(TranslateService);
  route: ActivatedRoute = inject(ActivatedRoute);
  incidentActions: IncidentActions = inject(IncidentActions);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  location: Location = inject(Location);

  incidentVersion: number;

  @ViewChild('createUpdateEventMarkerModal', { read: TemplateRef })
  public createUpdateEventMarkerModal: TemplateRef<any>;

  constructor(private store: Store<IApplicationState>) {
    super();
  }

  @HostListener(CommonConstants.popStateWindowEvent, ['$event'])
  onPopState(event: any): void {
    event.returnValue = false;
  }

  @HostListener(CommonConstants.beforeUnloadWindowEvent, ['$event'])
  handleBeforeUnload($event: any): void {
    if (this.hasUnsavedData()) {
      $event.returnValue = this.hasUnsavedData();
    }
  }

  canDeactivate(): boolean {
    if (this.eventMarkerForm.dirty) {
      this.confirmCloseModalOpen();

      return false;
    } else {
      this.incidentActions.requestIncidentAnalysis(this.currentIncident.aggregateId);
      this.modalReference.close('');
      this.resetOnClose();
      this.isConfirmCloseModalOpen = false;

      return true;
    }
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.currentIncident = JSON.parse(localStorage.getItem(ReportCenterConstants.currentIncidentAnalysis));

    this.buildForm();
    this.getEntries();
    this.readSelectedEventMarkerDataFromStore();

    this.formatDate = getDateFormatFromUserProfile(this.translateService);

    this.entries = this.store.pipe(
      select(selectEventMarkersEntries),
      map((state: IStoreApiList<IEntryModel[]>) => state.data),
      takeUntil(this.destroy),
    );

    this.store
      .pipe(
        select(selectIncident),
        filter((data: IStoreApiItem<IIncident>) => !data.isLoading),
      )
      .subscribe((selectedIncident: IStoreApiItem<IIncident>) => {
        this.incidentVersion = selectedIncident.data?.version;
        this.cdr.detectChanges();
      });

    this.isSubmitting = this.store.pipe(
      select(selectEventMarker),
      takeUntil(this.destroy),
      map((selectedEventMarker: IStoreApiItem<IEventMarker>) => {
        return selectedEventMarker.isLoading;
      }),
    );
  }

  populateForm(): void {
    this.route.params.pipe(takeUntil(this.destroy)).subscribe((params: Params) => {
      if (params.eventId) {
        let selectedAppId: string;

        this.currentIncident.ecps.forEach((ecp: IEcp) => {
          ecp.eventMarkerClusters.forEach((eventMarkerCluster: IEventMarker[]) => {
            eventMarkerCluster.forEach((eventMarker: IEventMarker) => {
              if (eventMarker.id === params.eventId) {
                selectedAppId = ecp.appId;
              }
            });
          });
        });

        this.modalType = CommonConstants.modalType.UPDATE;
        this.incidentActions.requestEventMarkerById(this.currentIncident.aggregateId, params.eventId, selectedAppId);
      } else {
        this.isLoading = false;
        this.modalType = CommonConstants.modalType.CREATE;

        const startDate: string = convertToDateTimeFormat(this.currentIncident?.startTime);
        const endDate: string = convertToDateTimeFormat(this.currentIncident?.endTime);

        this.eventMarkerForm.get('ecp').enable();
        this.eventMarkerForm.reset();

        this.eventMarkerForm.get('startTime').setValue(startDate);
        this.eventMarkerForm.get('endTime').setValue(endDate);

        const diff: number = new Date(endDate).getTime() - new Date(startDate).getTime();

        this.eventMarkerForm.get('duration').setValue(calculateDurationWithoutSeconds(Math.floor(diff / 1000)));
      }

      this.cdr.detectChanges();
    });
  }

  readSelectedEventMarkerDataFromStore(): void {
    this.store
      .pipe(
        select(selectEventMarker),
        filter((state: IStoreApiItem<IEventMarker>) => !state.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((selectedEventMarker: IStoreApiItem<IEventMarker>) => {
        if (selectedEventMarker.data) {
          const diff: number =
            new Date(selectedEventMarker.data.endTime).getTime() -
            new Date(selectedEventMarker.data.startTime).getTime();

          this.isLoading = false;
          this.selectedEventMarker = {
            ...selectedEventMarker.data,
            duration: calculateDurationWithoutSeconds(Math.floor(diff / 1000)),
          };
          this.eventMarkerForm.setValue(this.selectedEventMarker);
          this.eventMarkerForm.get('ecp').disable();
        }

        this.cdr.detectChanges();
      });
  }

  ngAfterViewInit(): void {
    this.populateForm();

    this.modalReference = this.modalService.open(this.createUpdateEventMarkerModal, {
      size: ModalSize.MEDIUM,
      variant: ModalVariant.DEFAULT,
      id: 'event-marker-modal',
      dismissable: false,
      dismissOnNavigation: false,
    });
  }

  buildForm(): void {
    this.eventMarkerForm = new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null, Validators.required),
      type: new FormControl(null),
      startTime: new FormControl(null, Validators.required),
      endTime: new FormControl(null, Validators.required),
      ecp: new FormControl(null),
      comment: new FormControl(null),
      duration: new FormControl({ value: null, disabled: true }),
      version: new FormControl(null),
    });
  }

  confirmCloseModalOpen(): void {
    this.isConfirmCloseModalOpen = true;
  }

  closeBanner(isOpen: boolean): void {
    if (isOpen) {
      this.resetOnClose();
      this.eventMarkerForm.markAsPristine();
      this.location.back();
    } else {
      this.isConfirmCloseModalOpen = false;
    }
  }

  resetOnClose(): void {
    this.isSubmitted = false;
    this.eventMarkerForm.reset();
  }

  onSubmit(): void {
    this.isSubmitted = true;

    const startDate: string = convertToDateTimeFormat(this.eventMarkerForm.get('startTime').value);
    const endDate: string = convertToDateTimeFormat(this.eventMarkerForm.get('endTime').value);

    if (
      this.eventMarkerForm.get('endTime').value &&
      this.eventMarkerForm.get('startTime').value &&
      new Date(endDate).getTime() - new Date(startDate).getTime() < 60000
    ) {
      this.eventMarkerForm.get('endTime').setErrors({ wrongDuration: true });
    }

    if (this.eventMarkerForm.invalid) {
      return;
    }

    if (this.modalType === CommonConstants.modalType.CREATE) {
      this.addNewEventMarker();
    } else {
      this.updateExistingEventMarker();
    }
  }

  addNewEventMarker(): void {
    this.addOrUpdateEventMarker('create');
  }

  updateExistingEventMarker(): void {
    this.addOrUpdateEventMarker('edit');
  }

  addOrUpdateEventMarker(action: string): void {
    this.hasErrors = false;
    this.errors = null;

    if (action === 'create') {
      if (
        ReportCenterConstants.mediaAssetsVersion in localStorage &&
        Number(localStorage.getItem(ReportCenterConstants.mediaAssetsVersion)) > Number(this.incidentVersion)
      ) {
        this.incidentVersion = Number(localStorage.getItem(ReportCenterConstants.mediaAssetsVersion));
      }

      this.incidentActions.requestAddEventMarker(
        { ...this.eventMarkerForm.getRawValue(), version: this.incidentVersion },
        this.currentIncident.aggregateId,
        this.eventMarkerForm.get('ecp').value,
      );
    } else {
      this.incidentActions.requestUpdateEventMarker(
        { ...this.eventMarkerForm.getRawValue() },
        this.currentIncident.aggregateId,
        this.eventMarkerForm.get('ecp').value,
      );
    }

    this.store
      .pipe(
        select(selectEventMarker),
        filter((selectedEventMarker: IStoreApiItem<IEventMarker>) => !selectedEventMarker.isLoading),
        take(1),
      )
      .subscribe((selectedEventMarker: IStoreApiItem<IEventMarker>) => {
        if (selectedEventMarker.errors) {
          this.hasErrors = true;
          this.errors = selectedEventMarker.errors.error.code.toString();

          this.handleEventMarkerErrors();

          this.eventMarkerForm.markAsPristine();
        } else if (selectedEventMarker.isSuccess) {
          this.successAddUpdate = true;
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            action === 'create'
              ? IncidentNotificationConstants.notificationCodes.ADD_EVENT_MARKER_SUCCESS
              : IncidentNotificationConstants.notificationCodes.UPDATE_EVENT_MARKER_SUCCESS,
            IncidentNotificationConstants.notificationCodes,
            { manualEvent: this.eventMarkerForm.value.name },
          );
          this.incidentActions.requestIncidentById(this.currentIncident?.aggregateId);
          this.closeBanner(true);
        }

        this.cdr.detectChanges();
      });
  }

  handleEventMarkerErrors(): void {
    const beforeAfterErrors: string[] = [
      IncidentNotificationConstants.notificationCodes.START_DATE_OUTSIDE_TIMELINE.value,
      IncidentNotificationConstants.notificationCodes.END_DATE_OUTSIDE_TIMELINE.value,
      IncidentNotificationConstants.notificationCodes.END_DATE_BEFORE_START_DATE.value,
    ];

    if (!beforeAfterErrors.includes(this.errors)) {
      this.notificationsService.requestShowNotification(
        CommonConstants.notificationType.ERROR,
        this.errors,
        IncidentNotificationConstants.notificationCodes,
        {
          value: this.eventMarkerForm.value.name,
        },
      );
    } else {
      this.notificationsService.requestShowNotification(
        CommonConstants.notificationType.HIDDEN,
        this.errors,
        IncidentNotificationConstants.notificationCodes,
      );
    }
  }

  getEntries(): void {
    this.incidentActions.requestEventMarkersEntries();
  }

  hasUnsavedData(): boolean {
    return this.eventMarkerForm.dirty;
  }
}
