import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IChecklist } from 'src/app/configuration/models';
import { ChecklistActionTypes } from './checklist-action-types';

export class RequestAddChecklist implements Action {
  readonly type: ChecklistActionTypes.REQUEST_ADD_CHECKLIST = ChecklistActionTypes.REQUEST_ADD_CHECKLIST;

  constructor(public payload: IChecklist) {}
}
export class ReceiveAddChecklist implements Action {
  readonly type: ChecklistActionTypes.RECEIVE_ADD_CHECKLIST = ChecklistActionTypes.RECEIVE_ADD_CHECKLIST;

  constructor(public payload: IChecklist) {}
}
export class ErrorReceiveAddChecklist implements Action {
  readonly type: ChecklistActionTypes.ERROR_RECEIVE_ADD_CHECKLIST = ChecklistActionTypes.ERROR_RECEIVE_ADD_CHECKLIST;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestEditChecklist implements Action {
  readonly type: ChecklistActionTypes.REQUEST_EDIT_CHECKLIST = ChecklistActionTypes.REQUEST_EDIT_CHECKLIST;

  constructor(public payload: IChecklist) {}
}
export class ReceiveEditChecklist implements Action {
  readonly type: ChecklistActionTypes.RECEIVE_EDIT_CHECKLIST = ChecklistActionTypes.RECEIVE_EDIT_CHECKLIST;

  constructor(public payload: IChecklist) {}
}
export class ErrorReceiveEditChecklist implements Action {
  readonly type: ChecklistActionTypes.ERROR_RECEIVE_EDIT_CHECKLIST = ChecklistActionTypes.ERROR_RECEIVE_EDIT_CHECKLIST;

  constructor(public payload: HttpErrorResponse) {}
}
export class RequestDeleteChecklist implements Action {
  readonly type: ChecklistActionTypes.REQUEST_DELETE_CHECKLIST = ChecklistActionTypes.REQUEST_DELETE_CHECKLIST;

  constructor(public payload: Partial<IChecklist>) {}
}
export class ReceiveDeleteChecklist implements Action {
  readonly type: ChecklistActionTypes.RECEIVE_DELETE_CHECKLIST = ChecklistActionTypes.RECEIVE_DELETE_CHECKLIST;

  constructor(public payload: Partial<IChecklist>) {}
}

export class ErrorReceiveDeleteChecklist implements Action {
  readonly type: ChecklistActionTypes.ERROR_RECEIVE_DELETE_CHECKLIST =
    ChecklistActionTypes.ERROR_RECEIVE_DELETE_CHECKLIST;

  constructor(public payload: HttpErrorResponse) {}
}
