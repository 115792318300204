import { IErrorCodesForCSV, PropertyBag } from 'src/app/common';

export class BAWearerConstants {
  public static readonly createEditModalTabs: PropertyBag = {
    PERSONAL_DATA: 'PERSONAL_DATA',
  };

  public static readonly invalidFieldsErrorsMap = {
    '7025': 'personalId',
    '7026': 'rfidCard',
  };

  public static readonly errorCodesForCSVFile: IErrorCodesForCSV = {
    PERSONAL_ID: {
      value: 'PERSONAL_ID',
      text: 'BA_WEARER.STR_PERSONAL_ID_CSV_ERROR',
    },
    FIRST_NAME: {
      value: 'FIRST_NAME',
      text: 'BA_WEARER.STR_FIRST_NAME_CSV_ERROR',
    },
    LAST_NAME: {
      value: 'LAST_NAME',
      text: 'BA_WEARER.STR_LAST_NAME_CSV_ERROR',
    },
    FIRE_STATION: {
      value: 'FIRE_STATION',
      text: 'BA_WEARER.STR_FIRE_STATION_CSV_ERROR',
    },
    RFID_CARD_ID: {
      value: 'RFID_CARD_ID',
      text: 'BA_WEARER.STR_RFID_CARD_ID_CSV_ERROR',
    },
    100: {
      value: 100,
      text: 'BA_WEARER.STR_MISSING_REQUIRED_FIELD',
    },
    200: {
      value: 200,
      text: 'BA_WEARER.STR_MISSING_FIELD_AT_EXPECTED_POSITION',
    },
    2: {
      value: 2,
      text: 'BA_WEARER.STR_SAME_PERSONAL_ID_IN_CSV',
    },
    3: {
      value: 3,
      text: 'BA_WEARER.STR_ALREADY_EXISTING_PERSONAL_ID',
    },
    4: {
      value: 4,
      text: 'BA_WEARER.STR_SAME_RFID_PER_FIRE_STATION_IN_CSV',
    },
    5: {
      value: 5,
      text: 'BA_WEARER.STR_ALREADY_EXISTING_RFID_PER_FIRE_STATION',
    },
    6: {
      value: 6,
      text: 'BA_WEARER.STR_RFID_NOT_IN_RANGE',
    },
    7: {
      value: 7,
      text: 'BA_WEARER.STR_RFID_NOT_NUMERIC',
    },
  };

  public static readonly notificationDescriptionTransKey: string = 'BA_WEARER_NOTIFICATION.BA_WEARER_DESCRIPTION';
}
