<form [formGroup]="equipmentForm" odxForm>
  <ignis-documentation-equipment-information [form]="equipmentForm"> </ignis-documentation-equipment-information>
  <ignis-service-details
    [form]="equipmentForm"
    [selectedTaskName]="selectedTaskName"
    [dateTime]="dateTime"
    [taskResultType]="taskResultType"
  >
  </ignis-service-details>

  <h2 class="mb-3 mt-5" odxLayout="12">
    {{ 'EQUIPMENT_TASK_RESULTS.STR_TASK_RESULT' | translate }}
  </h2>
  <section odxLayout="grid">
    <div odxLayout="12 12@tablet 4@desktop" class="custom-form-field-pr-10">
      <odx-form-field
        variant="horizontal"
        [readonly]="equipmentForm.get('operationalStatus')?.disabled"
        label="{{ 'EQUIPMENT_TASK_RESULTS.STR_EQUIPMENT_STATUS' | translate }}"
      >
        <div class="w-100">
          <p-dropdown
            #dd4
            (onShow)="dropdownService.openDropdown(dd4)"
            (onHide)="dropdownService.closeDropdown(dd4)"
            appendTo="body"
            class="custom-dropdown-form"
            ngDefaultControl
            odxFormFieldControl
            optionValue="value"
            optionLabel="localizedName"
            data-test-id="equipment_task_documentation.equipment_status"
            formControlName="operationalStatus"
            [dropdownIcon]="dropdownIconCSSClass"
            [options]="operationalStatusList"
            [placeholder]="'EQUIPMENT_GENERAL.STR_GENERAL_FORM_SELECT_STATUS' | translate"
            [showClear]="false"
            (onChange)="operationalStatusChanged($event); emitOtherTasks()"
          >
            <ng-template let-selectedItem pTemplate="selectedItem">
              <span>{{ selectedItem?.localizedName | translate }}</span>
            </ng-template>
            <ng-template let-option pTemplate="item">
              <span>{{ option?.localizedName | translate }}</span>
            </ng-template>
          </p-dropdown>
        </div>
      </odx-form-field>
      <odx-form-field
        *ngIf="taskResultType === taskResultTypesEnum.CylinderCharging"
        variant="horizontal"
        label="{{ 'EQUIPMENT_TASK_RESULTS.STR_AIR_PRESSURE' | translate }}"
        class="checkbox-custom-form-field"
        [readonly]="equipmentForm.get('airQualityMeasured').disabled"
      >
        <odx-checkbox
          class="org-form-label org-checkbox-mt-6"
          formControlName="airQualityMeasured"
          odxFormFieldControl
          ngDefaultControl
          data-test-id="equipment_task_documentation.air_pressure"
        >
        </odx-checkbox>
      </odx-form-field>
    </div>
    <div odxLayout="12 12@tablet 4@desktop" class="custom-form-field-pl-10">
      <odx-form-field
        variant="horizontal"
        label="{{ 'EQUIPMENT_TASK_RESULTS.STR_COMMENT' | translate }}"
        [readonly]="equipmentForm.get('comment')?.disabled"
      >
        <textarea
          ignisDisableAutocomplete
          odxFormFieldControl
          id="comment"
          data-test-id="equipment_task_documentation.comment"
          formControlName="comment"
          [rows]="taskResultType === taskResultTypesEnum.CylinderCharging ? 6 : 3"
          (keyup)="equipmentForm.markAsTouched()"
          (blur)="emitOtherTasks()"
        ></textarea>
      </odx-form-field>
    </div>
  </section>
  <section odxLayout="grid">
    <div odxLayout="12 12@tablet 4@desktop" class="custom-form-field-pr-10">
      <odx-form-field
        variant="horizontal"
        class="custom-datepicker-form-field"
        label="{{ 'EQUIPMENT_TASK_RESULTS.STR_OUT_OF_SERVICE_DATE' | translate }}"
        [ngClass]="{
          isReadOnly:
            !outOfServiceDatePickerAndOtherTasksCheckboxState ||
            isFromMigration ||
            equipmentForm.get('outOfServiceDate').disabled,
        }"
      >
        <div class="w-100">
          <ignis-datepicker
            formControlName="outOfServiceDate"
            data-test-id="equipment_task_documentation.out_of_service_date"
            ngDefaultControl
            appendTo="body"
            [selectionMode]="'single'"
            [isDisabled]="
              !outOfServiceDatePickerAndOtherTasksCheckboxState ||
              isFromMigration ||
              equipmentForm.get('outOfServiceDate').disabled
            "
            class="custom-datepicker-form"
            [showTime]="false"
            [formatDate]="formatDate"
            [defaultRange]="extractOnlyDate(equipmentForm.get('outOfServiceDate')?.value)"
            [clearable]="true"
            (dateSelect)="
              datepickerService.onDateChange($event, equipmentForm, 'outOfServiceDate');
              toggleReasonField(true, true);
              equipmentForm.markAsTouched();
              emitOtherTasks()
            "
            (dateChange)="
              datepickerService.resetSelectedDate(equipmentForm, 'outOfServiceDate');
              toggleReasonField(false, true);
              equipmentForm.markAsTouched();
              emitOtherTasks()
            "
          >
          </ignis-datepicker>
        </div>
      </odx-form-field>
    </div>
    <div odxLayout="12 12@tablet 4@desktop" class="custom-form-field-pl-10">
      <odx-form-field
        variant="horizontal"
        label="{{ 'EQUIPMENT_TASK_RESULTS.STR_OUT_OF_SERVICE_REASON' | translate }}"
        [readonly]="equipmentForm.get('outOfServiceReason')?.disabled"
      >
        <textarea
          ignisDisableAutocomplete
          odxFormFieldControl
          id="reason"
          data-test-id="equipment_task_documentation.out_of_service_reason"
          formControlName="outOfServiceReason"
          [rows]="taskResultType === taskResultTypesEnum.CylinderCharging ? 6 : 3"
          (keyup)="equipmentForm.markAsTouched()"
          (blur)="emitOtherTasks()"
        ></textarea>
      </odx-form-field>
    </div>
  </section>
  <section odxLayout="grid">
    <div odxLayout="12 12@tablet 4@desktop" class="custom-form-field-pr-10">
      <odx-form-field
        variant="horizontal"
        [readonly]="equipmentForm.get('newBarcode')?.disabled"
        label="{{ 'EQUIPMENT_TASK_RESULTS.STR_NEW_BARCODE' | translate }}"
      >
        <input
          type="text"
          ignisDisableAutocomplete
          id="newBarcode"
          data-test-id="equipment_task_documentation.new_barcode"
          formControlName="newBarcode"
          odxFormFieldControl
        />
        <odx-icon
          *ngIf="equipmentForm.get('newBarcode').invalid"
          name="info"
          iconSet="core"
          class="custom-validation-icon"
          [odxTooltip]="newBarcodeTooltipContent"
          [odxTooltipVisible]="equipmentForm.get('newBarcode').invalid"
        >
          <ng-template #newBarcodeTooltipContent>
            <div id="new-barcode-error-tooltip" data-test-id="equipment_task_documentation.new_barcode_tooltip">
              {{ 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_BARCODE_ALREADY_EXISTS' | translate }}
            </div>
          </ng-template>
        </odx-icon>
      </odx-form-field>
    </div>
    <div odxLayout="12 12@tablet 4@desktop" class="custom-form-field-pl-10">
      <odx-form-field
        variant="horizontal"
        [readonly]="equipmentForm.get('newRfid')?.disabled"
        label="{{ 'EQUIPMENT_TASK_RESULTS.STR_NEW_RFID' | translate }}"
      >
        <input
          type="text"
          ignisDisableAutocomplete
          id="newRfid"
          data-test-id="equipment_task_documentation.new_rfid"
          formControlName="newRfid"
          odxFormFieldControl
        />
        <odx-icon
          *ngIf="equipmentForm.get('newRfid').invalid"
          name="info"
          iconSet="core"
          class="custom-validation-icon"
          [odxTooltip]="newRFIDTooltipContent"
          [odxTooltipVisible]="equipmentForm.get('newRfid').invalid"
        >
          <ng-template #newRFIDTooltipContent>
            <div id="new-rfid-error-tooltip" data-test-id="equipment_task_documentation.new_rfid_tooltip">
              {{ 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_RFID_ALREADY_EXISTS' | translate }}
            </div>
          </ng-template>
        </odx-icon>
      </odx-form-field>
    </div>
  </section>

  <div class="mt-4">
    <hr class="divider" />
  </div>

  <div
    odxLayout="grid"
    class="mb-5"
    *ngIf="taskResultType !== taskResultTypesEnum.CylinderCharging"
    data-test-id="tasksIncludedSection"
  >
    <div odxLayout="12" class="mb-4" *ngIf="taskData?.includedTasks">
      <h2 class="mb-3">
        {{ 'EQUIPMENT_TASK_RESULTS.STR_TASKS_INCLUDED' | translate }}
      </h2>
      <p-table
        #dt2
        styleClass="p-datatable-striped p-datatable-gridlines"
        scrollHeight="flex"
        class="service-table mr-2"
        [value]="taskData.includedTasks"
        [scrollable]="true"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th scope="col" class="w-20">
              {{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_DESCRIPTION' | translate }}
            </th>
            <th scope="col" class="w-20">
              {{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_LAST_OLD' | translate }}
            </th>
            <th scope="col" class="w-20">
              {{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_NEXT_OLD' | translate }}
            </th>
            <th scope="col" class="w-20">
              {{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_LAST_NEW' | translate }}
            </th>
            <th scope="col" class="w-20">
              {{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_NEXT_NEW' | translate }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-services>
          <tr>
            <td data-column="taskName">{{ services.taskName }}</td>
            <td data-column="oldLastTestDate">{{ services.oldLastTestDate | date: formatDate }}</td>
            <td data-column="oldNextTestDate">
              {{ services.oldNextTestDate | date: formatDate }}
            </td>
            <td data-column="newLastTestDate">{{ services.newLastTestDate | date: formatDate }}</td>
            <td data-column="newNextTestDate">
              {{ services.newNextTestDate | date: formatDate }}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr data-test-id="noTaskServices">
            <td colspan="5">{{ 'EQUIPMENT_TEST.STR_NO_SERVICE_INTERVALS' | translate }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div odxLayout="12" class="mb-4 mt-5" data-test-id="otherTasksSection" *ngIf="taskData?.others">
    <h2 class="mb-2">
      {{ 'EQUIPMENT_TASK_RESULTS.STR_OTHER_TASKS' | translate }}
    </h2>

    <p-table
      #dt2
      styleClass="p-datatable-striped p-datatable-gridlines"
      scrollHeight="flex"
      class="service-table mr-2"
      [value]="otherTasks"
      [scrollable]="true"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th
            scope="col"
            class="w-6"
            [title]="'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_ALSO_DONE' | translate"
            *ngIf="taskResultType !== taskResultTypesEnum.CylinderCharging"
          >
            {{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_ALSO_DONE' | translate }}
          </th>
          <th scope="col" class="w-14">
            {{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_DESCRIPTION' | translate }}
          </th>
          <th scope="col" class="w-20">
            {{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_LAST_OLD' | translate }}
          </th>
          <th scope="col" class="w-20">
            {{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_NEXT_OLD' | translate }}
          </th>
          <th scope="col" class="w-20">
            {{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_LAST_NEW' | translate }}
          </th>
          <th scope="col" class="w-20">
            {{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_NEXT_NEW' | translate }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-tasks>
        <tr>
          <td class="p-relative" *ngIf="taskResultType !== taskResultTypesEnum.CylinderCharging">
            <odx-checkbox
              [value]="tasks.taskId"
              [attr.data-test-id]="tasks.taskId"
              [disabled]="!testResultData.isLastResult || !outOfServiceDatePickerAndOtherTasksCheckboxState"
              [(checked)]="tasks.wasTestIncluded"
              (click)="changeOtherTask($event, tasks)"
            ></odx-checkbox>
          </td>
          <td data-column="taskName">{{ tasks.taskName }}</td>
          <td data-column="oldLastTestDate">{{ tasks.oldLastTestDate | date: formatDate }}</td>
          <td data-column="oldNextTestDate">
            {{ tasks.oldNextTestDate | date: formatDate }}
          </td>
          <td data-column="newLastTestDate">{{ tasks.newLastTestDate | date: formatDate }}</td>
          <td data-column="newNextTestDate">
            {{ tasks.newNextTestDate | date: formatDate }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr data-test-id="noOtherTasks">
          <td *ngIf="taskResultType !== taskResultTypesEnum.CylinderCharging; else notCylinderType" colspan="6">
            {{ 'EQUIPMENT_TEST.STR_NO_SERVICE_INTERVALS' | translate }}
          </td>
          <ng-template #notCylinderType>
            <td colspan="5">
              {{ 'EQUIPMENT_TEST.STR_NO_SERVICE_INTERVALS' | translate }}
            </td>
          </ng-template>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>

<p *ngIf="testResultData.isFromMigration" class="mb-5">
  {{ 'EQUIPMENT_TASK_RESULTS.STR_IMPORTED_FROM_PROTECTOR_SOFTWARE' | translate }}
</p>
