import { IFireStationList } from '../../../common';
import { GenericAction } from '../../../common/state/models/generic-action.model';
import {
  errorItemData,
  errorListData,
  errorPostPutItemData,
  receiveItemData,
  receiveListData,
  receivePostPutItemData,
  requestItemData,
  requestListData,
  requestPostPutItemData,
} from '../../../common/utils/reducer/reducer.utils';
import { IDeviceConnection, IDeviceConnectionToken, IDevicePage } from '../../models/device-connection.model';
import { DeviceConnectionActionTypes } from '../actions/device-connection-action-types';
import { DeviceConnectionDataState, IDeviceConnectionDataState } from '../models/device-connection.model';

const resetIdentifierState = (lastState: IDeviceConnectionDataState): IDeviceConnectionDataState => ({
  ...lastState,
  deviceConnectionIdentifier: {
    isLoading: false,
    isSuccess: false,
    data: null,
    errors: null,
  },
});

const resetDeviceConnectionState = (lastState: IDeviceConnectionDataState): IDeviceConnectionDataState => ({
  ...lastState,
  selectedDeviceConnection: {
    isLoading: false,
    isSuccess: false,
    data: null,
    errors: null,
  },
});

const resetDeviceConnectionPageState = (lastState: IDeviceConnectionDataState): IDeviceConnectionDataState => ({
  ...lastState,
  devicePage: {
    isLoading: false,
    isSuccess: false,
    data: null,
    errors: null,
  },
});

export function deviceConnectionDataReducer(
  lastState: IDeviceConnectionDataState = new DeviceConnectionDataState(),
  action: GenericAction<DeviceConnectionActionTypes, any>,
): IDeviceConnectionDataState {
  switch (action.type) {
    case DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_PAGE:
      return requestItemData<IDeviceConnectionDataState, IDevicePage>(lastState, ['devicePage']);
    case DeviceConnectionActionTypes.RECEIVE_DEVICE_CONNECTION_PAGE:
      return receiveItemData<IDeviceConnectionDataState, IDevicePage>(lastState, ['devicePage'], action.payload);
    case DeviceConnectionActionTypes.ERROR_RECEIVE_DEVICE_CONNECTION_PAGE:
      return errorItemData<IDeviceConnectionDataState, IDevicePage>(lastState, ['devicePage'], action.payload);
    case DeviceConnectionActionTypes.RESET_DEVICE_CONNECTION_PAGE:
      return resetDeviceConnectionPageState(lastState);

    case DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_BY_ID:
      return requestItemData<IDeviceConnectionDataState, IDeviceConnection>(lastState, ['selectedDeviceConnection']);

    case DeviceConnectionActionTypes.RECEIVE_DEVICE_CONNECTION_BY_ID:
      return receiveItemData<IDeviceConnectionDataState, IDeviceConnection>(
        lastState,
        ['selectedDeviceConnection'],
        action.payload,
      );

    case DeviceConnectionActionTypes.ERROR_RECEIVE_DEVICE_CONNECTION_BY_ID:
      return errorItemData<IDeviceConnectionDataState, IDeviceConnection>(
        lastState,
        ['selectedDeviceConnection'],
        action.payload,
      );

    case DeviceConnectionActionTypes.REQUEST_ADD_DEVICE_CONNECTION:
    case DeviceConnectionActionTypes.REQUEST_UPDATE_DEVICE_CONNECTION:
      return requestPostPutItemData<IDeviceConnectionDataState, IDeviceConnection>(
        lastState,
        ['selectedDeviceConnection'],
        action.payload,
      );

    case DeviceConnectionActionTypes.RECEIVE_ADD_DEVICE_CONNECTION:
    case DeviceConnectionActionTypes.RECEIVE_UPDATE_DEVICE_CONNECTION:
      return receivePostPutItemData<IDeviceConnectionDataState, IDeviceConnection>(
        lastState,
        ['selectedDeviceConnection'],
        action.payload,
      );

    case DeviceConnectionActionTypes.ERROR_ADD_DEVICE_CONNECTION:
    case DeviceConnectionActionTypes.ERROR_UPDATE_DEVICE_CONNECTION:
      return errorPostPutItemData<IDeviceConnectionDataState, IDeviceConnection>(
        lastState,
        ['selectedDeviceConnection'],
        action.payload,
      );

    case DeviceConnectionActionTypes.RESET_DEVICE_CONNECTION:
      return resetDeviceConnectionState(lastState);

    case DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_IDENTIFIER:
      return requestItemData<IDeviceConnectionDataState, IDeviceConnectionToken>(lastState, [
        'deviceConnectionIdentifier',
      ]);
    case DeviceConnectionActionTypes.RECEIVE_DEVICE_CONNECTION_IDENTIFIER:
      return receiveItemData<IDeviceConnectionDataState, IDeviceConnectionToken>(
        lastState,
        ['deviceConnectionIdentifier'],
        action.payload,
      );
    case DeviceConnectionActionTypes.ERROR_RECEIVE_DEVICE_CONNECTION_IDENTIFIER:
      return errorItemData<IDeviceConnectionDataState, IDeviceConnectionToken>(
        lastState,
        ['deviceConnectionIdentifier'],
        action.payload,
      );
    case DeviceConnectionActionTypes.RESET_DEVICE_CONNECTION_IDENTIFIER:
      return resetIdentifierState(lastState);

    case DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_DELETE:
      return requestPostPutItemData<IDeviceConnectionDataState, IDeviceConnection>(
        lastState,
        ['deletedDeviceConnection'],
        action.payload,
      );
    case DeviceConnectionActionTypes.RECEIVE_DEVICE_CONNECTION_DELETE:
      return receivePostPutItemData<IDeviceConnectionDataState, IDeviceConnection>(
        lastState,
        ['deletedDeviceConnection'],
        action.payload,
      );
    case DeviceConnectionActionTypes.ERROR_RECEIVE_DEVICE_CONNECTION_DELETE:
      return errorPostPutItemData<IDeviceConnectionDataState, IDeviceConnection>(
        lastState,
        ['deletedDeviceConnection'],
        action.payload,
      );

    case DeviceConnectionActionTypes.REQUEST_UPDATE_DEVICE_CONNECTION_AUTH_TOKEN:
      return requestPostPutItemData<IDeviceConnectionDataState, any>(
        lastState,
        ['deviceConnectionAuthToken'],
        action.payload,
      );
    case DeviceConnectionActionTypes.RECEIVE_UPDATE_DEVICE_CONNECTION_AUTH_TOKEN:
      return receivePostPutItemData<IDeviceConnectionDataState, any>(
        lastState,
        ['deviceConnectionAuthToken'],
        action.payload,
      );
    case DeviceConnectionActionTypes.ERROR_RECEIVE_UPDATE_DEVICE_CONNECTION_AUTH_TOKEN:
      return errorPostPutItemData<IDeviceConnectionDataState, any>(
        lastState,
        ['deviceConnectionAuthToken'],
        action.payload,
      );

    case DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_CATEGORIES:
      return requestListData<IDeviceConnectionDataState, any>(lastState, ['deviceConnectionCategories']);
    case DeviceConnectionActionTypes.RECEIVE_DEVICE_CONNECTION_CATEGORIES:
      return receiveListData<IDeviceConnectionDataState, any>(
        lastState,
        ['deviceConnectionCategories'],
        action.payload,
      );
    case DeviceConnectionActionTypes.ERROR_RECEIVE_DEVICE_CONNECTION_CATEGORIES:
      return errorListData<IDeviceConnectionDataState, any>(
        lastState,
        ['deviceConnectionAssignedFireStations'],
        action.payload,
      );

    case DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_ASSIGNED_FIRE_STATIONS:
      return requestListData<IDeviceConnectionDataState, unknown>(lastState, ['deviceConnectionAssignedFireStations']);
    case DeviceConnectionActionTypes.RECEIVE_DEVICE_CONNECTION_ASSIGNED_FIRE_STATIONS:
      return receiveListData<IDeviceConnectionDataState, IFireStationList[]>(
        lastState,
        ['deviceConnectionAssignedFireStations'],
        action.payload,
      );
    case DeviceConnectionActionTypes.ERROR_RECEIVE_DEVICE_CONNECTION_ASSIGNED_FIRE_STATIONS:
      return errorListData<IDeviceConnectionDataState, unknown>(
        lastState,
        ['deviceConnectionAssignedFireStations'],
        action.payload,
      );

    default:
      return lastState;
  }
}
