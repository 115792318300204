import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { LocationTypeActionTypes } from './location-type-action-types';

export class RequestLocationTypeValues implements Action {
  readonly type: LocationTypeActionTypes.REQUEST_LOCATION_TYPE_VALUES =
    LocationTypeActionTypes.REQUEST_LOCATION_TYPE_VALUES;
}
export class ReceiveLocationTypeValues implements Action {
  readonly type: LocationTypeActionTypes.RECEIVE_LOCATION_TYPE_VALUES =
    LocationTypeActionTypes.RECEIVE_LOCATION_TYPE_VALUES;

  constructor(public payload: any) {}
}
export class ErrorReceiveLocationTypeValues implements Action {
  readonly type: LocationTypeActionTypes.ERROR_RECEIVE_LOCATION_TYPE_VALUES =
    LocationTypeActionTypes.ERROR_RECEIVE_LOCATION_TYPE_VALUES;

  constructor(public payload: HttpErrorResponse) {}
}
