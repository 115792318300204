import { IStoreApiItem, StoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IChecklist } from '../../models/checklist.model';

export interface IChecklistState {
  newChecklist: IStoreApiItem<IChecklist>;
  editedChecklist: IStoreApiItem<IChecklist>;
  deletedChecklist: IStoreApiItem<any>;
}

export class ChecklistState implements IChecklistState {
  newChecklist: IStoreApiItem<IChecklist> = new StoreApiItem();
  editedChecklist: IStoreApiItem<IChecklist> = new StoreApiItem();
  deletedChecklist: IStoreApiItem<any> = new StoreApiItem();
}
