<odx-main-menu
  [title]=""
  [isOpen]="isOpen"
  [subtitle]="version"
  [copyright]="'© Drägerwerk AG & Co. KGaA 2024'"
  data-test-id="sidebar.sidebar_navigation"
>
  <ng-template ngFor let-item [ngForOf]="menuItems">
    <a
      *ngIf="item.visibility"
      odxMainMenuItem
      [ngClass]="{
        isSelected:
          (router.url.includes(AppModuleRoutes.configurationModule) &&
            item.title === 'SIDEBAR.STR_CONFIGURATION_SIDEBAR') ||
          (router.url.includes(AppModuleRoutes.workshopModule) && item.title === 'SIDEBAR.STR_WORKSHOP_SIDEBAR'),
      }"
      [routerLink]="item.route"
      routerLinkActive="is-selected"
      [attr.data-test-id]="item.title"
      (click)="item.route.includes(AppModuleRoutes.logbookModule) ? setLogbookPreFilter() : null; onCloseMenu()"
    >
      <odx-icon name="placeholder"></odx-icon>
      {{ item.displayedTitle | translate }}
    </a>
  </ng-template>
</odx-main-menu>

<ng-template #version>
  <div class="version">
    <p>{{ 'SIDEBAR.STR_DRAGER_RAM' | translate }} - {{ RAM_VERSION }}.{{ sprintAndHotfix }}</p>
    <p>{{ 'SIDEBAR.STR_FIREGROUND_APP' | translate }} - {{ FIREGROUND_VERSION }}.{{ sprintAndHotfix }}</p>
  </div>
</ng-template>
