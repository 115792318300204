export interface IUserPage {
  currentPage: number;
  entries: IUser[];
  pageSize: number;
  totalPages: number;
  totalRecords: number;
}

export class UserPage {
  currentPage: number;
  entries: IUser[];
  pageSize: number;
  totalPages: number;
  totalRecords: number;

  constructor();
  constructor(userPage: IUserPage);
  constructor(userPage?: IUserPage) {
    if (!userPage) {
      this.currentPage = null;
      this.entries = null;
      this.pageSize = null;
      this.totalPages = null;
      this.totalRecords = null;
    } else {
      this.currentPage = userPage.currentPage;
      this.entries = userPage.entries.map((user: IUser) => new User(user));
      this.pageSize = userPage.pageSize;
      this.totalPages = userPage.totalPages;
      this.totalRecords = userPage.totalRecords;
    }
  }
}

export interface IUser {
  aggregateId: string;
  version: number;
  name: string;
  surname: string;
  email: string;
  emailAddress?: string;
  role?: string;
  userRole: {
    value: string;
  };
  localizedRole?: string;
  userId: string;
  lastLogin: string;
  time?: string;
  displayLastLogin?: string;
  restrictedLocationId?: string;
}

export interface INewUser {
  email: string;
  locationRestrictionId?: string;
}

export interface IUpdateUser {
  aggregateId: string;
  version: number;
  email: string;
  role: string;
  locationRestrictionId?: string;
}

export interface IUserRole {
  value: string;
  label?: string;
  localizedName?: string;
}

export class User implements IUser {
  aggregateId: string;
  version: number;
  name: string;
  surname: string;
  email: string;
  userRole: {
    value: string;
  };
  userId: string;
  lastLogin: string;
  time?: string;

  constructor();
  constructor(user: IUser);
  constructor(user?: IUser) {
    if (!user) {
      this.aggregateId = null;
      this.version = null;
      this.name = null;
      this.surname = null;
      this.email = null;
      this.userRole = null;
      this.userId = null;
      this.lastLogin = null;
      this.time = null;
    } else {
      this.aggregateId = user.aggregateId;
      this.version = user.version;
      this.name = user.name;
      this.surname = user.surname;
      this.email = user.email;
      this.userRole = {
        value: user.userRole.value,
      };
      this.userId = user.userId;
      this.lastLogin = user.lastLogin;
      this.time = user.time;
    }
  }
}

export interface IUserPersonalInfo {
  userId: string;
  profileInfo: {
    firstName: string;
    lastName: string;
  };
}

export class UserPersonalInfo implements IUserPersonalInfo {
  userId: string;
  profileInfo: {
    firstName: string;
    lastName: string;
  };

  constructor();
  constructor(users: IUserPersonalInfo);
  constructor(users?: IUserPersonalInfo) {
    if (!users) {
      this.userId = null;
      this.profileInfo = null;
    } else {
      this.userId = users.userId;
      this.profileInfo = users.profileInfo;
    }
  }
}

export class NewUser implements INewUser {
  email: string;
  constructor();
  constructor(newUser: INewUser);
  constructor(newUser?: INewUser) {
    if (!newUser) {
      this.email = null;
    } else {
      this.email = newUser.email;
    }
  }
}

export class UpdateUser implements IUpdateUser {
  aggregateId: string;
  version: number;
  email: string;
  role: string;
  userId: string;
  constructor();
  constructor(updateUser: IUpdateUser);
  constructor(updateUser?: IUpdateUser) {
    if (!updateUser) {
      this.aggregateId = null;
      this.version = null;
      this.email = null;
      this.role = null;
      this.userId = null;
    } else {
      this.aggregateId = updateUser.aggregateId;
      this.version = updateUser.version;
      this.email = updateUser.email;
      this.role = updateUser.role;
    }
  }
}

export class UserRole implements IUserRole {
  value: string;

  constructor();
  constructor(userRole: IUserRole);
  constructor(userRole?: IUserRole) {
    if (!userRole) {
      this.value = null;
    } else {
      this.value = userRole.value;
    }
  }
}

export interface IInvalidEmail {
  code: number;
  errorMessage: string;
  source: string;
  invalidColumn?: string;
  aggregateId?: number;
}
