import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PropertyBag, TableService } from 'src/app/common';
import { WorkshopConstants } from 'src/app/workshop/constants';
import { ITestValue } from 'src/app/workshop/models';

@Component({
  selector: 'ignis-automatic-test-values',
  templateUrl: './automatic-test-values.component.html',
  styleUrls: ['./automatic-test-values.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutomaticTaskValuesComponent {
  @Input() equipmentTaskResultsForm: FormGroup;
  @Input() formatDate: string;
  @Input() taskValues: ITestValue[];

  assessmentsTranslation: PropertyBag = WorkshopConstants.testAssessmentsTranslation;

  constructor(public tableService: TableService) {}
}
