<ngx-simplebar class="alert-container">
  <div class="alert-list" *ngFor="let alert of alarms">
    <div
      class="alert"
      [class]="currentAppTheme"
      (click)="navigateToMonitorTeamsView(alert.incidentAggregateId, alert.ecpId, alert)"
      data-test-id="root.navigate_to_monitor_teams"
    >
      <ignis-alarm-icon
        [alarmTypeValue]="alert.type.value"
        [currentAppTheme]="currentAppTheme"
        [usage]="'alarms'"
      ></ignis-alarm-icon>
      <div class="alert-content">
        <span class="name">{{ alert.type.label }}</span>
        <div class="ecp-identification-label">
          <span *ngIf="alert.ecpNumber">{{
            ('ALARMS.STR_ENTRY_CONTROL_POINT' | translate) + ' ' + alert.ecpNumber
          }}</span>
          <span *ngIf="alert.teamName">{{ ' - ' + alert.teamName }}</span>
        </div>
        <span class="street-name">
          <strong *ngIf="alert.streetInfo">{{ alert.streetInfo }}, </strong>
          <span>{{ alert.firefighterName }}</span>
        </span>
        <span class="timestamp"> {{ alert.activationAt | date: (dateFormat | translate) }} {{ alert.time }} </span>
      </div>
    </div>
  </div>
</ngx-simplebar>
