import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IAccessControlItems } from 'src/app/root/models/access-control-items.model';
import { AccessControlItemsActionType } from './access-control-items-types';

export class RequestAccessControlItems implements Action {
  readonly type: AccessControlItemsActionType.REQUEST_ACCESS_CONTROL_ITEMS = AccessControlItemsActionType.REQUEST_ACCESS_CONTROL_ITEMS;
}
export class ReceiveAccessControlItems implements Action {
  readonly type: AccessControlItemsActionType.RECEIVE_ACCESS_CONTROL_ITEMS = AccessControlItemsActionType.RECEIVE_ACCESS_CONTROL_ITEMS;

  constructor(public payload: IAccessControlItems) { }
}
export class ErrorReceiveAccessControlItems implements Action {
  readonly type: AccessControlItemsActionType.ERROR_RECEIVE_ACCESS_CONTROL_ITEMS = AccessControlItemsActionType.ERROR_RECEIVE_ACCESS_CONTROL_ITEMS;

  constructor(public payload: HttpErrorResponse) { }
}
