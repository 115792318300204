<div>
  <div class="image-wrapp" *ngIf="imageSrc; else emptyImageWrapp">
    <img
      [src]="imageSrc"
      [alt]="mediaItem?.mediaLink"
      class="media-image"
      (click)="openImageModal()"
      data-test-id="incident_media_assets.image"
    />
  </div>

  <ng-template #emptyImageWrapp>
    <div class="image-wrapp align-loader-to-center">
      <div [odxLoadingSpinner]="true" [odxLoadingSpinnerMinHeight]="60" class="app-loader"></div>
    </div>
  </ng-template>

  <p class="media-item-info">
    {{
      mediaItem?.uploadType === deviceCategories.APP
        ? ('INCIDENT_MEDIA_ASSETS.STR_MEDIA_APP' | translate)
        : ('INCIDENT_MEDIA_ASSETS.STR_MEDIA_MANUAL' | translate)
    }}

    @if (!mediaItem?.senderName) {
      <span> | </span>
    } @else {
      <span>- {{ mediaItem?.senderName + ' | ' }}</span>
    }
    {{ mediaItem?.timestamp | date: formatDate }} {{ mediaItem?.time }}
  </p>
</div>

<ignis-image-modal
  [openModal]="isModalOpened"
  [image]="imageSrc"
  (handleCloseNoteImageModal)="closeMediaImageModal()"
></ignis-image-modal>
