import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { UserActionTypes } from './../user-action-types';

export class RequestUploadCSVFile implements Action {
  readonly type: UserActionTypes.REQUEST_UPLOAD_CSV_FILE = UserActionTypes.REQUEST_UPLOAD_CSV_FILE;

  constructor(public payload: any) { }
}

export class ReceiveUploadCSVFile implements Action {
  readonly type: UserActionTypes.RECEIVE_UPLOAD_CSV_FILE = UserActionTypes.RECEIVE_UPLOAD_CSV_FILE;

  constructor(public payload: any) { }
}

export class ErrorReceiveUploadCSVFile implements Action {
  readonly type: UserActionTypes.ERROR_RECEIVE_UPLOAD_CSV_FILE = UserActionTypes.ERROR_RECEIVE_UPLOAD_CSV_FILE;

  constructor(public payload: HttpErrorResponse) { }
}
