import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  inject,
} from '@angular/core';
import { CheckingSettingsModalsService, PropertyBag } from 'src/app/common';
import { AppUserPermissionList } from 'src/app/root/models/app-types';
import { AccessControlService } from 'src/app/root/services/access-control/access-control.service';

@Component({
  selector: 'ignis-task-documentation-list-view-footer',
  templateUrl: './task-documentation-list-view-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDocumentationListViewFooterComponent<T> implements OnChanges {
  @Input() selectedItem: T;
  @Input() numberOfRecordsToBeExported: number;

  @Output() handleTestResult: EventEmitter<void> = new EventEmitter<void>();
  @Output() handleExcelExport: EventEmitter<void> = new EventEmitter<void>();

  appUserPermissionList: PropertyBag = AppUserPermissionList;

  permissions: string[] = [];
  isModalOpened: boolean;

  public accessControlService: AccessControlService = inject(AccessControlService);
  private checkSettingsModal: CheckingSettingsModalsService = inject(CheckingSettingsModalsService);
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  constructor() {
    this.checkSettingsModal.checkIfSettingsModalIsOpen().subscribe((isModalOpened: boolean) => {
      this.isModalOpened = isModalOpened;
      this.cdr.detectChanges();
    });
  }

  ngOnChanges(): void {
    this.permissions = this.accessControlService.permissions;
  }

  onTestResultClick(e: Event): void {
    e.stopPropagation();
    this.handleTestResult.emit();
  }

  onExportExcelClick(e: Event): void {
    e.stopPropagation();
    this.handleExcelExport.emit();
  }
}
