import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IEquipmentState } from '../../models';
import {
  RequestCheckRAMConnectorStatus,
  RequestCheckRAMPortStatus,
  RequestRAMAutoUpdate,
  RequestRAMConnectorToken,
} from './ram-action-types-creators';

@Injectable({ providedIn: 'root' })
export class RAMActions {
  constructor(private store: Store<IEquipmentState>) {}

  public requestRAMConnectorToken(): void {
    this.store.dispatch(new RequestRAMConnectorToken());
  }

  public requestCheckRAMConnectorStatus(port: number): void {
    this.store.dispatch(new RequestCheckRAMConnectorStatus(port));
  }

  public requestCheckRAMPortStatus(port: number): void {
    this.store.dispatch(new RequestCheckRAMPortStatus(port));
  }

  public requestRAMAutoUpdate(): void {
    this.store.dispatch(new RequestRAMAutoUpdate());
  }
}
