import {
  errorPostPutItemData,
  receiveItemData,
  receivePostPutItemData,
  requestPostPutItemData,
} from 'src/app/common/utils/reducer/reducer.utils';
import { ILegalTermsData, ILegalTermsState, LegalTermsState } from '../models/legal-terms.model';
import { GenericAction } from './../../../../common/state/models/generic-action.model';
import { errorItemData, requestItemData } from './../../../../common/utils/reducer/reducer.utils';
import { LegalTermsActionType } from './../actions/legal-terms-action-types';

export function legalTermsReducer(
  lastState: ILegalTermsState = new LegalTermsState(),
  action: GenericAction<LegalTermsActionType, any>,
): ILegalTermsState {
  switch (action.type) {
    case LegalTermsActionType.REQUEST_GDPR_DATA:
      return requestItemData<ILegalTermsState, ILegalTermsData>(lastState, ['legalTermsData']);
    case LegalTermsActionType.RECEIVE_GDPR_DATA:
      return receiveItemData<ILegalTermsState, ILegalTermsData>(lastState, ['legalTermsData'], action.payload);
    case LegalTermsActionType.ERROR_RECEIVE_GDPR_DATA:
      return errorItemData<ILegalTermsState, ILegalTermsData>(lastState, ['legalTermsData'], action.payload);

    case LegalTermsActionType.REQUEST_ACCEPT_GDPR:
      return requestPostPutItemData<ILegalTermsState, any>(lastState, ['accepted'], action.payload);
    case LegalTermsActionType.RECEIVE_ACCEPT_GDPR:
      return receivePostPutItemData<ILegalTermsState, any>(lastState, ['accepted'], action.payload);
    case LegalTermsActionType.ERROR_RECEIVE_ACCEPT_GDPR:
      return errorPostPutItemData<ILegalTermsState, any>(lastState, ['accepted'], action.payload);

    case LegalTermsActionType.REQUEST_TAC_DATA:
      return requestItemData<ILegalTermsState, ILegalTermsData>(lastState, ['legalTermsData']);
    case LegalTermsActionType.RECEIVE_TAC_DATA:
      return receiveItemData<ILegalTermsState, ILegalTermsData>(lastState, ['legalTermsData'], action.payload);
    case LegalTermsActionType.ERROR_RECEIVE_TAC_DATA:
      return errorItemData<ILegalTermsState, ILegalTermsData>(lastState, ['legalTermsData'], action.payload);

    case LegalTermsActionType.REQUEST_ACCEPT_TAC:
      return requestPostPutItemData<ILegalTermsState, any>(lastState, ['accepted'], action.payload);
    case LegalTermsActionType.RECEIVE_ACCEPT_TAC:
      return receivePostPutItemData<ILegalTermsState, any>(lastState, ['accepted'], action.payload);
    case LegalTermsActionType.ERROR_RECEIVE_ACCEPT_TAC:
      return errorPostPutItemData<ILegalTermsState, any>(lastState, ['accepted'], action.payload);

    case LegalTermsActionType.REQUEST_DPA_DATA:
      return requestItemData<ILegalTermsState, ILegalTermsData>(lastState, ['legalTermsData']);
    case LegalTermsActionType.RECEIVE_DPA_DATA:
      return receiveItemData<ILegalTermsState, ILegalTermsData>(lastState, ['legalTermsData'], action.payload);
    case LegalTermsActionType.ERROR_RECEIVE_DPA_DATA:
      return errorItemData<ILegalTermsState, ILegalTermsData>(lastState, ['legalTermsData'], action.payload);

    case LegalTermsActionType.REQUEST_ACCEPT_DPA:
      return requestPostPutItemData<ILegalTermsState, any>(lastState, ['accepted'], action.payload);
    case LegalTermsActionType.RECEIVE_ACCEPT_DPA:
      return receivePostPutItemData<ILegalTermsState, any>(lastState, ['accepted'], action.payload);
    case LegalTermsActionType.ERROR_RECEIVE_ACCEPT_DPA:
      return errorPostPutItemData<ILegalTermsState, any>(lastState, ['accepted'], action.payload);

    default:
      return lastState;
  }
}
