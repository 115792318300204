export interface ILicenseTicket {
  ticketNumber: string;
}

export class LicenseTicket implements ILicenseTicket {
  ticketNumber: string;

  constructor();
  constructor(ticket: ILicenseTicket);
  constructor(ticket?: ILicenseTicket) {
    if (!ticket) {
      this.ticketNumber = null;
    } else {
      this.ticketNumber = ticket.ticketNumber;
    }
  }
}
