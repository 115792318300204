import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { delay, takeUntil } from 'rxjs';
import { IApplicationState, IStoreApiList } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { AppUserPermissionList } from 'src/app/root/models/app-types';
import { AccessControlService } from 'src/app/root/services/access-control/access-control.service';
import { IFeatureToggle } from 'src/app/root/state/features-toggle/models/features-toggle.model';
import { selectFeaturesToggleList } from 'src/app/root/state/features-toggle/selectors/features-toggle.selector';

@Injectable()
export class OrganizationSettingsTabGuard extends OnDestroyMixin() {

  redirectToOverview: boolean;

  constructor(
    private store: Store<IApplicationState>,
    private router: Router,
    private accessControlService: AccessControlService,
  ) {
    super();
  }

  canActivate(): void {
    this.store
      .pipe(select(selectFeaturesToggleList))
      .pipe(takeUntil(this.destroy), delay(0))
      .subscribe((state: IStoreApiList<IFeatureToggle[]>) => {
        if (state.data) {
          const itManagerPermissions: boolean =
            this.accessControlService.checkPermission(AppUserPermissionList.configuration) &&
            this.accessControlService.checkPermission(AppUserPermissionList.organization);

          const adminPermissions: boolean =
            this.accessControlService.checkPermission(AppUserPermissionList.configuration) &&
            this.accessControlService.checkPermission(AppUserPermissionList.organization) &&
            this.accessControlService.checkPermission(AppUserPermissionList.equipmentWrite);

          if (itManagerPermissions || adminPermissions) {
            this.redirectToOverview = true;

            return true;

          } else {
            this.redirectToOverview = false;
            this.router.navigate(['']);

            return false;
          }
        }
      });
  }

}
