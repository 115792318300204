import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { IAddressBook, IAddressBookPage } from 'src/app/configuration/models';
import { AddressBookConfigurationService } from 'src/app/configuration/services/address-book-configuration.service';
import {
  AddressBookActionTypes,
  ErrorReceiveAddAddressBook,
  ErrorReceiveAddressBook,
  ErrorReceiveAddressBookById,
  ErrorReceiveDeleteAddressBook,
  ErrorReceiveEditAddressBook,
  ReceiveAddAddressBook,
  ReceiveAddressBook,
  ReceiveAddressBookById,
  ReceiveDeleteAddressBook,
  ReceiveEditAddressBook,
} from '../../actions/address-book';

@Injectable()
export class AddressBookEffects {
  addressBookConfigurationService: AddressBookConfigurationService = inject(AddressBookConfigurationService);

  requestAddressBook$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(AddressBookActionTypes.REQUEST_ADDRESS_BOOK),
      switchMap((params: { payload: any }) =>
        this.addressBookConfigurationService.getAddressBookList(params.payload).pipe(
          map((addressBookPage: IAddressBookPage) => new ReceiveAddressBook(addressBookPage)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAddressBook(error))),
        ),
      ),
    );
  });

  requestAddAddressBook$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(AddressBookActionTypes.REQUEST_ADD_ADDRESS_BOOK),
      switchMap((addressBook: { payload: IAddressBook }) =>
        this.addressBookConfigurationService.addAddressBook(addressBook.payload).pipe(
          map((addAddressBook: IAddressBook) => new ReceiveAddAddressBook(addAddressBook)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAddAddressBook(error))),
        ),
      ),
    );
  });

  requestAddressBookById$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(AddressBookActionTypes.REQUEST_ADDRESS_BOOK_BY_ID),
      switchMap((addressBookId: { payload: string }) =>
        this.addressBookConfigurationService.getAddressBookById(addressBookId.payload).pipe(
          map((addressBook: { body: IAddressBook }) => new ReceiveAddressBookById(addressBook.body)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAddressBookById(error))),
        ),
      ),
    );
  });

  requestEditAddressBook$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(AddressBookActionTypes.REQUEST_EDIT_ADDRESS_BOOK),
      switchMap((addressBook: { payload: IAddressBook }) =>
        this.addressBookConfigurationService.editAddressBook(addressBook.payload).pipe(
          map((addressBookEdited: IAddressBook) => new ReceiveEditAddressBook(addressBookEdited)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveEditAddressBook(error))),
        ),
      ),
    );
  });

  requestAddressBookDelete$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(AddressBookActionTypes.REQUEST_DELETE_ADDRESS_BOOK),
      switchMap((deleteAddress: { payload: { aggregateId: string; version: number } }) =>
        this.addressBookConfigurationService.deleteAddressBook(deleteAddress.payload).pipe(
          map((addressBook: IAddressBook) => new ReceiveDeleteAddressBook(addressBook)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveDeleteAddressBook(error))),
        ),
      ),
    );
  });
}
