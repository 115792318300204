import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelper } from 'src/app/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private baseUrl: string = environment.API_URLS.USERS;

  constructor(
    private httpClient: HttpClient,
    private httpHelper: HttpHelper
  ) { }

  public getProductName(): Observable<any[] | HttpErrorResponse> {
    return this.httpClient.get<Array<any>>(`${this.baseUrl}/users/product-name`, this.httpHelper.options);
  }
}
