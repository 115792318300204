<ignis-progress-bar *ngIf="isLoading | async"></ignis-progress-bar>

<div class="custom-odx-tab-bar odx-margin-bottom-24">
  <div class="odx-tab-bar__inner">
    <div class="odx-tab-bar__panel padding-left-0">
      <div class="back-btn-group">
        <button
          odxNavigationBack
          id="navigation-back"
          data-test-id="configuration.equipment_hierarchy"
          (click)="hasUnsavedData ? confirmCloseModalOpen() : navigateBack(true)"
        ></button>
        <span class="odx-fs-normal">
          {{ 'CONFIGURATION.STR_EQUIPMENT_HIERARCHY' | translate }}
        </span>
      </div>
    </div>
  </div>
</div>

<ngx-simplebar [options]="scrollbarOptions" class="custom-configuration-scrollbar scrollbar-offset">
  <form [formGroup]="configurationForm" odxForm (ngSubmit)="onSubmit()" class="configuration-form">
    <section odxLayout="grid" class="mb-5">
      <h2
        class="mb-3"
        odxLayout="12"
        *ngIf="
          router.url?.includes(ConfigurationModuleRoutes.createEquipmentHierarchy) &&
          router.url?.split('/').length === 3
        "
      >
        {{ 'CONFIGURATION.STR_ADD_CATEGORY' | translate }}
      </h2>

      <h2
        class="mb-3"
        odxLayout="12"
        *ngIf="
          router.url?.includes(ConfigurationModuleRoutes.createEquipmentHierarchy) &&
          router.url?.split('/').length === 5
        "
      >
        {{ 'CONFIGURATION.STR_ADD_TYPE' | translate }}
      </h2>

      <h2
        class="mb-3"
        odxLayout="12"
        *ngIf="
          router.url?.includes(ConfigurationModuleRoutes.createEquipmentHierarchy) &&
          router.url?.split('/').length === 7
        "
      >
        {{ 'CONFIGURATION.STR_ADD_MODEL' | translate }}
      </h2>

      <h2
        class="mb-3"
        odxLayout="12"
        *ngIf="
          router.url?.includes(ConfigurationModuleRoutes.updateEquipmentHierarchy) &&
          router.url?.includes(ConfigurationModuleRoutes.updateEquipmentCategories) &&
          !router.url?.includes(ConfigurationModuleRoutes.createOrUpdateEquipmentTypes) &&
          !router.url?.includes(ConfigurationModuleRoutes.createOrUpdateEquipmentModels)
        "
      >
        {{ 'CONFIGURATION.STR_EDIT_CATEGORY' | translate }}
      </h2>

      <h2
        class="mb-3"
        odxLayout="12"
        *ngIf="
          router.url?.includes(ConfigurationModuleRoutes.updateEquipmentHierarchy) &&
          router.url?.includes(ConfigurationModuleRoutes.updateEquipmentCategories) &&
          router.url?.includes(ConfigurationModuleRoutes.createOrUpdateEquipmentTypes) &&
          !router.url?.includes(ConfigurationModuleRoutes.createOrUpdateEquipmentModels)
        "
      >
        {{ 'CONFIGURATION.STR_EDIT_TYPE' | translate }}
      </h2>

      <h2
        class="mb-3"
        odxLayout="12"
        *ngIf="
          router.url?.includes(ConfigurationModuleRoutes.updateEquipmentHierarchy) &&
          router.url?.includes(ConfigurationModuleRoutes.createOrUpdateEquipmentModels)
        "
      >
        {{ 'CONFIGURATION.STR_EDIT_MODEL' | translate }}
      </h2>

      <div odxLayout="12 12@tablet 3@desktop" class="custom-form-field-pr-10">
        <odx-form-field
          variant="horizontal"
          label="{{ 'CONFIGURATION.STR_NAME' | translate }}"
          [readonly]="configurationForm.get('name').disabled"
        >
          <input
            type="text"
            odxFormFieldControl
            ignisDisableAutocomplete
            id="name"
            data-test-id="configuration.name"
            formControlName="name"
          />

          <odx-icon
            *ngIf="httpCustomErrorCode && configurationForm.get('name').invalid"
            name="info"
            class="custom-validation-icon"
            iconSet="core"
            [odxTooltip]="eqHierarchyNameTooltipContent"
            [odxTooltipVisible]="httpCustomErrorCode && configurationForm.get('name').invalid"
          >
            <ng-template #eqHierarchyNameTooltipContent>
              <div id="name-error-tooltip" data-test-id="configuration.name_error_tooltip">
                {{ 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_ERROR_CODES_NAME_SHOULD_BE_UNIQUE' | translate }}
              </div>
            </ng-template>
          </odx-icon>
        </odx-form-field>
      </div>

      <div
        odxLayout="12 12@tablet 3@desktop"
        class="custom-form-field-pr-10"
        *ngIf="(router.url?.split('/').length === 7 || router.url?.split('/').length === 9) && displayPressureInput"
      >
        <odx-form-field
          variant="horizontal"
          label=" {{ 'CONFIGURATION.STR_MAX_PRESSURE' | translate }} ({{ pressureDisplayUnit }})"
          [readonly]="configurationForm.get('maxPressure').disabled"
        >
          <input
            type="number"
            ignisDisableAutocomplete
            odxFormFieldControl
            oninput="this.value = this.valueAsNumber"
            [min]="1"
            id="maxPressure"
            data-test-id="configuration.max_pressure"
            formControlName="maxPressure"
            (keyup)="convertPressureUnit($event)"
          />
        </odx-form-field>
      </div>

      <div
        odxLayout="12 12@tablet 3@desktop"
        class="custom-form-field-pr-10"
        *ngIf="
          (router.url?.includes(ConfigurationModuleRoutes.createOrUpdateEquipmentTypes) &&
            router.url?.includes(ConfigurationModuleRoutes.createEquipmentHierarchy) &&
            !router.url?.includes(ConfigurationModuleRoutes.createOrUpdateEquipmentModels)) ||
          (router.url?.includes(ConfigurationModuleRoutes.createOrUpdateEquipmentTypes) &&
            router.url?.includes(ConfigurationModuleRoutes.updateEquipmentHierarchy) &&
            !router.url?.includes(ConfigurationModuleRoutes.createOrUpdateEquipmentModels))
        "
      >
        <odx-form-field
          variant="horizontal"
          label="{{ 'CONFIGURATION.STR_TEST_TYPE' | translate }}"
          [readonly]="configurationForm.get('testType').disabled"
        >
          <div
            class="w-100"
            id="testType-wrap"
            data-test-id="configuration.test_type_wrap"
            [pTooltip]="configurationForm.get('testType').disabled ? cylinderDropdownTooltipContent : null"
            tooltipPosition="top"
            positionTop="-10"
          >
            <p-dropdown
              #dd1
              (onShow)="dropdownService.openDropdown(dd1)"
              (onHide)="dropdownService.closeDropdown(dd1)"
              [ngModel]="selectedProtectorTypeValue"
              (ngModelChange)="selectedProtectorTypeValue = $event"
              class="custom-dropdown-form"
              odxFormFieldControl
              ngDefaultControl
              id="testType"
              data-test-id="configuration.test_type"
              formControlName="testType"
              [dropdownIcon]="dropdownIconCSSClass"
              [options]="protectorTypeValues"
              [appendTo]="'body'"
              [placeholder]="'CONFIGURATION.STR_TEST_TYPE_PLACEHOLDER' | translate"
              [showClear]="true"
            >
              <ng-template let-option pTemplate="item">
                <span>{{ option.label }}</span>
              </ng-template>
              <ng-template pTemplate="clearicon">
                <odx-icon name="close"></odx-icon>
              </ng-template>
            </p-dropdown>
            <ng-template #cylinderDropdownTooltipContent>
              <div id="test-type-tooltip" data-test-id="configuration.test_type_tooltip">
                {{ 'CONFIGURATION.STR_TEST_TYPE_TOOLTIP' | translate }}
              </div>
            </ng-template>
          </div>
        </odx-form-field>
      </div>

      <div
        odxLayout="12 12@tablet 4@desktop"
        class="custom-form-field-pr-10 position-relative d-flex"
        *ngIf="
          (router.url?.includes(ConfigurationModuleRoutes.createOrUpdateEquipmentTypes) &&
            router.url?.includes(ConfigurationModuleRoutes.createEquipmentHierarchy) &&
            router.url?.includes(ConfigurationModuleRoutes.createOrUpdateEquipmentModels)) ||
          (router.url?.includes(ConfigurationModuleRoutes.createOrUpdateEquipmentTypes) &&
            router.url?.includes(ConfigurationModuleRoutes.updateEquipmentHierarchy) &&
            router.url?.includes(ConfigurationModuleRoutes.createOrUpdateEquipmentModels))
        "
      >
        <odx-form-field
          variant="horizontal"
          label=" {{ 'CONFIGURATION.STR_EQUIPMENT_MANUFACTURER' | translate }}"
          [readonly]="configurationForm.get('manufacturerAggregateId').disabled"
          class="custom-width"
        >
          <div class="w-100">
            <p-dropdown
              #dd1
              (onShow)="dropdownService.openDropdown(dd1)"
              (onHide)="dropdownService.closeDropdown(dd1)"
              ngDefaultControl
              class="custom-dropdown-form"
              odxFormFieldControl
              optionValue="manufacturerAggregateId"
              optionLabel="organizationName"
              appendTo="body"
              formControlName="manufacturerAggregateId"
              data-test-id="configuration.equipment_manufacturer"
              [dropdownIcon]="dropdownIconCSSClass"
              [options]="manufacturersList"
              [appendTo]="'body'"
              [placeholder]="'CONFIGURATION.STR_EQUIPMENT_MANUFACTURER_PLACEHOLDER' | translate"
              [emptyMessage]="'CONFIGURATION.STR_NO_RESULTS_FOUND' | translate"
              [showClear]="true"
            >
              <ng-template let-option pTemplate="item">
                <span>{{ option.organizationName }}</span>
              </ng-template>
              <ng-template pTemplate="clearicon">
                <odx-icon name="close"></odx-icon>
              </ng-template>
            </p-dropdown>
          </div>
        </odx-form-field>
        <a
          odxButton
          variant="secondary"
          class="add-address-btn mb-3"
          id="navigate-to-address"
          data-test-id="configuration.navigate_to_address_book"
          (click)="navigateToAddAddress()"
        >
          <odx-icon name="add"></odx-icon>
        </a>
      </div>

      <h2 class="mb-3" odxLayout="12">
        {{ 'CONFIGURATION.STR_TASKS' | translate }}
      </h2>

      <div odxLayout="12 12@tablet 12@desktop" class="mb-3" data-test-id="configuration.tasks">
        <ignis-table
          class="tasks-table"
          [preselectedElement]="preselectedTask"
          [tableColumns]="tasksTableColumns"
          [customColumnsLayout]="['description']"
          [customColumnsRef]="[description]"
          [dataTable]="tasks"
          [routeUrl]="routeUrl"
          [resetPagining]="false"
          [activateClickOutside]="activateClickOutside"
          [ngClass]="{ 'display-scrollbar': tasks && tasks.length > tableScrollingFallback }"
          (handleRowSelection)="onTaskSelect($event)"
          (mouseleave)="activateClickOutside = true"
        >
          <ng-template #description let-description>
            <div *ngIf="description.field === 'description' && description.data">
              <ignis-table-ellipsis-data-column
                [columnData]="description"
                class="ellipsis-data-column"
              ></ignis-table-ellipsis-data-column>
            </div>
          </ng-template>
        </ignis-table>
      </div>

      <h2 class="mb-3" odxLayout="12" *ngIf="selectedTask && !selectedTask.isInherited">
        {{ 'CONFIGURATION.STR_TASKS_INCLUDED' | translate }}
      </h2>

      <div
        odxLayout="12 12@tablet 12@desktop"
        data-test-id="configuration.tasks_included"
        class="mb-3"
        *ngIf="selectedTask && !selectedTask.isInherited"
      >
        <ignis-table
          [tableColumns]="intervalsTableColumns"
          [dataTable]="intervals"
          [multiSelect]="true"
          [resetPagining]="false"
          [preselectedElements]="selectedIncludedTasks"
          class="tasks-table service-intervals-table"
          [ngClass]="{
            'display-scrollbar': intervals && intervals.length > tableScrollingFallback,
          }"
          (mouseenter)="activateClickOutside = false"
          (mouseleave)="activateClickOutside = true"
          (selectedElementsChange)="onIncludedTaskSelect($event)"
        >
        </ignis-table>
      </div>
    </section>
  </form>
</ngx-simplebar>

<ignis-confirm-change-category-type-model
  [isOpen]="openConfirmUpdateHierarchyModal"
  [addedType]="equipmentConfigType"
  (handleConfirmationModificationModal)="closeConfirmBanner($event)"
>
</ignis-confirm-change-category-type-model>

<ignis-confirmation-modal
  [isOpen]="isConfirmCloseModalBanner"
  (handleConfirmationModal)="handleUnsavedChangesResponse($event)"
  class="is-closed-modal"
>
  <div slot="content" (mouseenter)="activateClickOutside = false" (mouseleave)="activateClickOutside = true">
    <div [ngSwitch]="equipmentConfigType">
      <span *ngSwitchCase="configType.CATEGORY" [attr.data-test-id]="configType.CATEGORY">
        {{ 'CONFIRM_CLOSE_MODAL.STR_DESCRIPTION_CREATE_UPDATE_CONFIGURATION_FOR_CATEGORY' | translate }}
      </span>
      <span *ngSwitchCase="configType.TYPE" [attr.data-test-id]="configType.TYPE">
        {{ 'CONFIRM_CLOSE_MODAL.STR_DESCRIPTION_CREATE_UPDATE_CONFIGURATION_FOR_TYPE' | translate }}
      </span>
      <span *ngSwitchCase="configType.MODEL" [attr.data-test-id]="configType.MODEL">
        {{ 'CONFIRM_CLOSE_MODAL.STR_DESCRIPTION_CREATE_UPDATE_CONFIGURATION_FOR_MODEL' | translate }}
      </span>
    </div>
  </div>
</ignis-confirmation-modal>

<footer class="blue-bar">
  <ignis-create-update-configuration-view-footer
    [disableAddBtnTaskInterval]="disableAddBtn"
    [form]="configurationForm"
    [selectedItem]="selectedTask && !selectedTask.isInherited"
    [isSubmitDisabled]="isSubmitting && isLoading | async"
    [selectedIncludedTasks]="selectedIncludedTasks"
    [disableAddChecklistBtn]="disableAddChecklistBtn"
    [disableEditBtnAndDelete]="selectedTask?.isInherited"
    [disableAddBtn]="!selectedTask?.isInherited ? false : true"
    [toggleAddAndEditChecklistBtn]="toggleAddAndEditChecklistBtn"
    [disableEditChecklistBtn]="disableEditChecklistBtn"
    [isModalOpened]="openConfirmationDeleteDialog || isConfirmCloseModalBanner || openConfirmUpdateHierarchyModal"
    (handleSubmit)="onSubmit()"
    (handleCreate)="addNewTask()"
    (handleEdit)="!selectedTask.isInherited ? navigateToModal('update-task') : ''"
    (handleDelete)="openDeleteTaskDialog()"
    (handleAddOpenCheckList)="addOrEditChecklist(modalType.ADD)"
    (handleEditOpenCheckList)="addOrEditChecklist(modalType.UPDATE)"
  >
  </ignis-create-update-configuration-view-footer>
</footer>

<ignis-delete-confirmation-modal
  [isOpen]="openConfirmationDeleteDialog"
  [isLoading]="isLoading | async"
  (handleDeleteModal)="closeDeleteTaskDialog($event)"
>
  <div slot="header" *ngIf="!selectedTask?.isInherited">
    {{ 'CONFIGURATION.STR_DELETE_TASK' | translate }}
  </div>

  <div slot="content" *ngIf="!selectedTask?.isInherited">
    {{ 'CONFIGURATION.STR_DELETE_TASK_CONTENT' | translate }}
  </div>
</ignis-delete-confirmation-modal>

<router-outlet></router-outlet>
