import { GenericAction } from 'src/app/common';
import {
  errorItemData,
  errorListData,
  errorPostPutItemData,
  receiveItemData,
  receiveListData,
  receivePostPutItemData,
  requestItemData,
  requestListData,
  requestPostPutItemData,
} from 'src/app/common/utils/reducer/reducer.utils';
import {
  IEditEquipmentHierarchy,
  IEquipmentConfiguration,
  IEquipmentHierarchy,
} from '../../../models/equipment-configuration.models';
import { EquipmentConfigurationActionTypes } from '../../actions/equipment-config';
import { EquipmentHierarchyActionTypes } from '../../actions/equipment-hierarchy';
import {
  ConfigurationEquipmentHierarchyState,
  IConfigurationEquipmentHierarchyState,
} from '../../models/equipment-hierarchy.model';

export function configurationEquipmentHierarchyReducer(
  lastState: IConfigurationEquipmentHierarchyState = new ConfigurationEquipmentHierarchyState(),
  action: GenericAction<EquipmentHierarchyActionTypes | EquipmentConfigurationActionTypes, any>,
): IConfigurationEquipmentHierarchyState {
  switch (action.type) {
    case EquipmentHierarchyActionTypes.REQUEST_EQUIPMENT_HIERARCHY:
      return requestListData<IConfigurationEquipmentHierarchyState, IEquipmentHierarchy[]>(lastState, ['hierarchy']);
    case EquipmentHierarchyActionTypes.RECEIVE_EQUIPMENT_HIERARCHY:
      return receiveListData<IConfigurationEquipmentHierarchyState, IEquipmentHierarchy[]>(
        lastState,
        ['hierarchy'],
        action.payload,
      );
    case EquipmentHierarchyActionTypes.ERROR_RECEIVE_EQUIPMENT_HIERARCHY:
      return errorListData<IConfigurationEquipmentHierarchyState, IEquipmentHierarchy[]>(
        lastState,
        ['hierarchy'],
        action.payload,
      );

    case EquipmentHierarchyActionTypes.REQUEST_ADD_EQUIPMENT_HIERARCHY:
      return requestPostPutItemData<IConfigurationEquipmentHierarchyState, IEquipmentHierarchy>(
        lastState,
        ['newHierarchy'],
        action.payload,
      );
    case EquipmentHierarchyActionTypes.RECEIVE_ADD_EQUIPMENT_HIERARCHY:
      return receivePostPutItemData<IConfigurationEquipmentHierarchyState, IEquipmentHierarchy>(
        lastState,
        ['newHierarchy'],
        action.payload,
      );
    case EquipmentHierarchyActionTypes.ERROR_RECEIVE_ADD_EQUIPMENT_HIERARCHY:
      return errorPostPutItemData<IConfigurationEquipmentHierarchyState, IEquipmentHierarchy>(
        lastState,
        ['newHierarchy'],
        action.payload,
      );

    case EquipmentConfigurationActionTypes.REQUEST_EQUIPMENT_CONFIGURATION:
      return requestItemData<IConfigurationEquipmentHierarchyState, IEquipmentConfiguration>(lastState, [
        'equipmentConfiguration',
      ]);
    case EquipmentConfigurationActionTypes.RECEIVE_EQUIPMENT_CONFIGURATION:
      return receiveItemData<IConfigurationEquipmentHierarchyState, IEquipmentConfiguration>(
        lastState,
        ['equipmentConfiguration'],
        action.payload,
      );
    case EquipmentConfigurationActionTypes.ERROR_RECEIVE_EQUIPMENT_CONFIGURATION:
      return errorItemData<IConfigurationEquipmentHierarchyState, IEquipmentConfiguration>(
        lastState,
        ['equipmentConfiguration'],
        action.payload,
      );

    case EquipmentHierarchyActionTypes.REQUEST_EDIT_EQUIPMENT_HIERARCHY:
      return requestPostPutItemData<IConfigurationEquipmentHierarchyState, IEditEquipmentHierarchy>(
        lastState,
        ['editedHierarchy'],
        action.payload,
      );
    case EquipmentHierarchyActionTypes.RECEIVE_EDIT_EQUIPMENT_HIERARCHY:
      return receivePostPutItemData<IConfigurationEquipmentHierarchyState, IEditEquipmentHierarchy>(
        lastState,
        ['editedHierarchy'],
        action.payload,
      );
    case EquipmentHierarchyActionTypes.ERROR_RECEIVE_EDIT_EQUIPMENT_HIERARCHY:
      return errorPostPutItemData<IConfigurationEquipmentHierarchyState, IEditEquipmentHierarchy>(
        lastState,
        ['editedHierarchy'],
        action.payload,
      );

    case EquipmentHierarchyActionTypes.REQUEST_DELETE_EQUIPMENT_HIERARCHY:
      return requestPostPutItemData<IConfigurationEquipmentHierarchyState, any>(
        lastState,
        ['deletedHierarchy'],
        action.payload,
      );
    case EquipmentHierarchyActionTypes.RECEIVE_DELETE_EQUIPMENT_HIERARCHY:
      return receivePostPutItemData<IConfigurationEquipmentHierarchyState, any>(
        lastState,
        ['deletedHierarchy'],
        action.payload,
      );
    case EquipmentHierarchyActionTypes.ERROR_RECEIVE_DELETE_EQUIPMENT_HIERARCHY:
      return errorPostPutItemData<IConfigurationEquipmentHierarchyState, any>(
        lastState,
        ['deletedHierarchy'],
        action.payload,
      );

    case EquipmentHierarchyActionTypes.REQUEST_IMPORT_EQUIPMENT_HIERARCHY:
      return requestPostPutItemData<IConfigurationEquipmentHierarchyState, unknown>(
        lastState,
        ['importEquipmentHierarchy'],
        action.payload,
      );
    case EquipmentHierarchyActionTypes.RECEIVE_IMPORT_EQUIPMENT_HIERARCHY:
      return receivePostPutItemData<IConfigurationEquipmentHierarchyState, unknown>(
        lastState,
        ['importEquipmentHierarchy'],
        action.payload,
      );
    case EquipmentHierarchyActionTypes.ERROR_RECEIVE_IMPORT_EQUIPMENT_HIERARCHY:
      return errorPostPutItemData<IConfigurationEquipmentHierarchyState, unknown>(
        lastState,
        ['importEquipmentHierarchy'],
        action.payload,
      );

    default:
      return lastState;
  }
}
