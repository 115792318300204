import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { map, takeUntil } from 'rxjs/operators';
import { CommonConstants } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IStoreApiList } from 'src/app/common/models/store-api-list.model';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { ApplicationState } from 'src/app/common/state/models/app.state.model';
import { RemoteMonitoringNotificationConstants } from 'src/app/remote-monitoring/constants';
import { RemoteMonitoringModuleRoutes } from 'src/app/remote-monitoring/constants/remote-monitoring-module-routes.constants';
import { IEcpTeam, IIncidentEcp, INote } from 'src/app/remote-monitoring/models/remote-monitoring.model';
import { RemoteMonitoringActions } from 'src/app/remote-monitoring/state/actions/remote-monitoring.actions';
import {
  selectIncident,
  selectIncidentEcp,
  selectNotesList,
  selectReadNotes,
} from 'src/app/remote-monitoring/state/selectors/remote-monitoring.selector';
import { formatLocaleTime } from '../../../../common/utils/date-utils/date.utils';
import { getDateFormatFromUserProfile } from '../../../../common/utils/settings-utils/settings-utils';

@Component({
  selector: 'ignis-remote-ecp-monitoring',
  templateUrl: './remote-ecp-monitoring.component.html',
  styleUrls: ['./remote-ecp-monitoring.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemoteEcpMonitoringComponent extends OnDestroyMixin() implements OnInit {
  isLoading: boolean;
  selectedEcp: IIncidentEcp;
  selectedIncidentId: string;
  incidentVersion: number;

  openNotesWidget: boolean = false;
  newNotesNumber: number;
  intervalToGetTeamsData: number | any;
  openFromTeams: boolean = false;

  formatDate: string;
  team: IEcpTeam | any;
  notes: INote[];
  notesBuffer: INote[];

  scrollbarOptions: any = CommonConstants.scrollbarOptions;

  notificationsService: NotificationsService = inject(NotificationsService);
  translateService: TranslateService = inject(TranslateService);
  remoteMonitoringActions: RemoteMonitoringActions = inject(RemoteMonitoringActions);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  currentAppTheme: string;

  constructor(
    public route: ActivatedRoute,
    private store: Store<ApplicationState>,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super();

    this.currentAppTheme = this.document.body.className.split(' ')[1];

    this.router.events?.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.openNotesWidget = true;

        setTimeout(() => {
          this.openNotesWidget = false;
        }, 0);

        this.cdr.detectChanges();
      }
    });
  }

  ngOnInit(): void {
    this.getSelectedEcpData();
    this.readNotesFromStore();
    this.formatDate = getDateFormatFromUserProfile(this.translateService);
  }

  selectReadNotesAndRequestNotesList(): void {
    this.store.pipe(select(selectReadNotes), takeUntil(this.destroy)).subscribe((response: IStoreApiItem<any>) => {
      if (response.data !== null && !response.errors) {
        this.remoteMonitoringActions.requestNotesList(this.selectedIncidentId);
      }

      this.cdr.detectChanges();
    });
  }

  trackByTeam(index: number, team: IEcpTeam): string {
    return `${index}-${team.teamId}`;
  }

  readNotesFromStore(): void {
    this.store
      .pipe(
        select(selectNotesList),
        takeUntil(this.destroy),
        map((state: IStoreApiList<INote[]>) => state.data),
      )
      .subscribe((response: INote[]) => {
        if (response) {
          if (this.openFromTeams) {
            this.selectedEcp?.teams.forEach((team: IEcpTeam) => {
              if (team.teamId === this.team.teamId) {
                this.readSelectedEcpFromStore();

                const allNotes: INote[] = structuredClone(team.notes);

                allNotes?.forEach((note: INote) => {
                  note.time = formatLocaleTime(new Date(note.timestamp));
                });

                this.notes = allNotes;
              }
            });
          } else {
            this.notes = response;
          }

          this.cdr.detectChanges();
        }
      });
  }

  getSelectedEcpData(): void {
    this.route.parent.params.pipe(takeUntil(this.destroy)).subscribe((incidentParams: Params) => {
      this.selectedIncidentId = incidentParams.id;

      this.selectReadNotesAndRequestNotesList();
      this.route.params.pipe(takeUntil(this.destroy)).subscribe((ecpParams: Params) => {
        this.openNotesWidget = false;

        this.remoteMonitoringActions.requestIncidentEcpById(incidentParams.id, ecpParams.ecpId);
        this.readSelectedEcpFromStore();
      });
    });

    this.intervalToGetTeamsData = setInterval(() => {
      const ecpIdFromUrl: string = window.location.href.split('/').slice(-2)[0];
      const incidentIdFromUrl: string = window.location.href.split('/').slice(-4)[0];

      if (window.location.href.includes(RemoteMonitoringModuleRoutes.monitorTeams)) {
        this.remoteMonitoringActions.requestIncidentEcpById(incidentIdFromUrl, ecpIdFromUrl);
      }
    }, 5000);
  }

  requestIncidentData(): void {
    this.remoteMonitoringActions.requestIncidentById(this.selectedIncidentId);
    this.remoteMonitoringActions.requestNotesList(this.selectedIncidentId);
  }

  readSelectedEcpFromStore(): void {
    this.store.pipe(select(selectIncident), takeUntil(this.destroy)).subscribe((response: any) => {
      if (response.data) {
        this.incidentVersion = response.data.version;
      }
    });

    this.store
      .pipe(select(selectIncidentEcp), takeUntil(this.destroy))
      .subscribe((selectedEcp: IStoreApiItem<IIncidentEcp>) => {
        if (selectedEcp.data) {
          this.isLoading = false;
          this.selectedEcp = selectedEcp.data;
        } else {
          if (
            selectedEcp.errors?.error.code.toString() ===
            RemoteMonitoringNotificationConstants.notificationCodes.ACCESS_UNAVAILABLE_INCIDENT.value
          ) {
            setTimeout(() => {
              this.notificationsService.requestShowNotification(
                CommonConstants.notificationType.WARNING,
                RemoteMonitoringNotificationConstants.notificationCodes.ACCESS_UNAVAILABLE_INCIDENT,
                RemoteMonitoringNotificationConstants.notificationCodes,
              );
            });

            setTimeout(() => {
              this.router.navigate([RemoteMonitoringModuleRoutes.remoteMonitoring]);
            }, CommonConstants.DEFAULT_REDIRECT_TIMEOUT);
          }
        }

        this.cdr.detectChanges();
      });
  }

  openNotes(team: IEcpTeam): void {
    this.team = team;
    this.openNotesWidget = true;
    this.notesBuffer = structuredClone(this.notes ?? []);

    if (this.team) {
      this.openFromTeams = true;
      this.notes = this.team.notes;

      if (!this.openNotesWidget) {
        setTimeout(() => {
          this.openNotesWidget = true;
        }, 25);
      }
    }

    this.cdr.detectChanges();
  }

  closeNotes(event: boolean): void {
    this.openNotesWidget = event;
    this.openFromTeams = event;

    if (this.team) {
      this.notes = structuredClone(this.notesBuffer ?? []);
    }
  }

  getNewNotesNumber(event: number): void {
    this.newNotesNumber = event;
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();

    if (this.intervalToGetTeamsData) {
      clearInterval(this.intervalToGetTeamsData);
    }

    this.cdr.detach();
  }
}
