import { ChangeDetectionStrategy, Component, inject, Inject, Input } from '@angular/core';
import { Theme, ThemingService } from '@odx/angular/theming';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { UserStorageService } from 'src/app/common';

@Component({
  selector: 'ignis-unauthorized-page',
  templateUrl: './unauthorized-page.component.html',
  styleUrls: ['./unauthorized-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnauthorizedPageComponent {
  @Input() isLicenseExpired: boolean = false;

  themingService: ThemingService = inject(ThemingService);

  isCreateAccountMode: boolean = false;

  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private userStorageService: UserStorageService,
  ) {
    this.themingService.selectTheme(Theme.DARK);
  }

  logout(): void {
    this.userStorageService.clearBrowserLocalStorageWithoutPersistentKeys();
    this.oktaAuth.signOut();
  }
}
