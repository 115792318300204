import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PropertyBag } from 'src/app/common/models/common.model';
import { ICreateUpdateEquipment, IEquipment } from '../../../models/equipment.model';
import { IEquipmentState } from '../../models/equipment.model';

import {
  ReceiveEquipmentById,
  RequestAddEquipment,
  RequestEquipmentById,
  RequestEquipmentDelete,
  RequestEquipmentExport,
  RequestEquipmentPage,
  RequestSavedEquipments,
  RequestSavedLocationEquipments,
  RequestUpdateEquipment,
  RequestUploadEquipmentCSVFile,
  ResetSavedEquipments,
  ResetSavedLocationEquipment,
  ResetSelectedEquipmentState,
} from './equipment-crud-action-types-creators';

@Injectable({ providedIn: 'root' })
export class EquipmentCRUDActions {
  constructor(private store: Store<IEquipmentState>) {}

  public requestEquipmentPage(params?: PropertyBag, isExportMode: boolean = false): void {
    this.store.dispatch(isExportMode ? new RequestEquipmentExport(params) : new RequestEquipmentPage(params));
  }

  public selectEquipment(equipment: IEquipment): void {
    this.store.dispatch(new ReceiveEquipmentById(equipment));
  }

  public requestEquipmentById(id: string): void {
    this.store.dispatch(new RequestEquipmentById(id));
  }

  public requestSavedEquipments(ids: string[]): void {
    this.store.dispatch(new RequestSavedEquipments(ids));
  }

  public requestSavedLocationEquipment(aggregateId: string): void {
    this.store.dispatch(new RequestSavedLocationEquipments(aggregateId));
  }

  public resetSavedLocationEquipmentState(): void {
    this.store.dispatch(new ResetSavedLocationEquipment());
  }

  public requestAddEquipment(equipment: ICreateUpdateEquipment): void {
    this.store.dispatch(new RequestAddEquipment(equipment));
  }

  public requestUpdateEquipment(equipment: ICreateUpdateEquipment): void {
    this.store.dispatch(new RequestUpdateEquipment(equipment));
  }

  public requestEquipmentDelete(equipment: IEquipment): void {
    this.store.dispatch(new RequestEquipmentDelete(equipment));
  }

  public uploadCSVFile(file: any): any {
    this.store.dispatch(new RequestUploadEquipmentCSVFile(file));
  }

  public resetSelectedEquipmentState(): void {
    this.store.dispatch(new ResetSelectedEquipmentState());
  }

  public resetSavedEquipments(): void {
    this.store.dispatch(new ResetSavedEquipments());
  }
}
