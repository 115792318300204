<form odxForm [formGroup]="equipmentForm" [readonly]="equipmentForm.disabled">
  <section odxLayout="grid" class="mb-5" formGroupName="locationAssignment">
    <h2 class="mb-3" odxLayout="12">{{ 'ASSIGNMENTS.STR_ASSIGNMENTS_FORM_LOCATION' | translate }}</h2>

    <div odxLayout="12 12@tablet 4@desktop">
      <odx-form-field
        variant="horizontal"
        label="{{ 'ASSIGNMENTS.STR_ASSIGNMENTS_FORM_NAME' | translate }}"
        [readonly]="equipmentForm.get('locationAssignment.location').disabled"
      >
        <div class="w-100">
          <p-treeSelect
            #dd1
            formControlName="location"
            ngDefaultControl
            odxFormFieldControl
            filter="true"
            name="location"
            id="location"
            data-test-id="assignments.assignments_form_name"
            class="custom-tree-select-form"
            [showClear]="restrictToLocation ? false : true"
            [placeholder]="'ASSIGNMENTS.STR_ASSIGNMENTS_FORM_SELECT_LOCATION_PLACEHOLDER' | translate"
            [filterPlaceholder]="'CONFIGURATION.STR_SEARCH_LOCATION' | translate"
            [emptyMessage]="'CONFIGURATION.STR_NO_RECORDS_FOUND' | translate"
            [options]="locations"
            appendTo="body"
            [virtualScroll]="true"
            [virtualScrollItemSize]="46"
            [virtualScrollOptions]="{ scrollHeight: '250px' }"
            (onNodeSelect)="setSelectedLocation($event.node)"
            (onClear)="clearFormData()"
          >
            <ng-template let-selectedItem pTemplate="value">
              <div class="select-tree-selectedItem" *ngIf="selectedItem; else placeholder">
                <odx-icon [name]="selectedItem?.icon" class="p-treenode-icon"></odx-icon>
                <label [title]="selectedItem?.label">{{ selectedItem?.label }}</label>
              </div>
              <ng-template #placeholder>
                <span>{{ 'ASSIGNMENTS.STR_ASSIGNMENTS_FORM_SELECT_LOCATION_PLACEHOLDER' | translate }}</span>
              </ng-template>
            </ng-template>
            <ng-template let-node pTemplate="default">
              <odx-icon [name]="node.icon" class="p-treenode-icon"></odx-icon>
              <span class="p-treenode-label">{{ node.label }}</span>
            </ng-template>
            <ng-template pTemplate="clearicon">
              <odx-icon name="close"></odx-icon>
            </ng-template>
          </p-treeSelect>
        </div>
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label="{{ 'ASSIGNMENTS.STR_ASSIGNMENTS_FORM_TYPE' | translate }}"
        [readonly]="equipmentForm.get('locationAssignment.type').disabled"
      >
        <input
          type="text"
          ignisDisableAutocomplete
          odxFormFieldControl
          id="type"
          data-test-id="assignments.assignments_form_type"
          formControlName="type"
        />
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label="{{ 'ASSIGNMENTS.STR_ASSIGNMENTS_FORM_IDENTIFIER' | translate }}"
        [readonly]="equipmentForm.get('locationAssignment.identifier').disabled"
      >
        <input
          type="text"
          ignisDisableAutocomplete
          odxFormFieldControl
          id="identifier"
          data-test-id="assignments.assignments_form_identifier"
          formControlName="identifier"
        />
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label="{{ 'ASSIGNMENTS.STR_ASSIGNMENTS_FORM_ADDRESS' | translate }}"
        [readonly]="equipmentForm.get('locationAssignment.address').disabled"
      >
        <input
          type="text"
          ignisDisableAutocomplete
          odxFormFieldControl
          id="address"
          data-test-id="assignments.assignments_form_address"
          formControlName="address"
        />
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label=" {{ 'ASSIGNMENTS.STR_ASSIGNMENTS_FORM_DESCRIPTION' | translate }}"
        [readonly]="equipmentForm.get('locationAssignment.details').disabled"
      >
        <textarea
          ignisDisableAutocomplete
          odxFormFieldControl
          id="details"
          data-test-id="assignments.assignments_form_description"
          formControlName="details"
          class="custom-form-textarea"
          [rows]="10"
        ></textarea>
      </odx-form-field>
    </div>
  </section>
</form>
