import { DOCUMENT, Location } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgSelectComponent } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ButtonComponent } from '@odx/angular/components/button';
import { combineLatest, filter, map, Observable, take, takeUntil } from 'rxjs';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { NotificationsService } from 'src/app/common/services';
import { getDateFormatFromUserProfile } from 'src/app/common/utils';
import {
  checkAutoUpdateFeatureToggleBeforeStartProtectorTest,
  checkRAMConnectorPort,
  processLocationPath,
  startTest,
} from 'src/app/common/utils/execute-test-utils/start-test';
import { ITask } from 'src/app/configuration/models';
import { DeviceConnectionActions } from 'src/app/device-connection';
import { IDeviceConnectionToken } from 'src/app/device-connection/models/device-connection.model';
import { selectToCheckRAMConnectorToken } from 'src/app/device-connection/state/selectors/device-connection.selector';
import { AccessControlService } from '../../../root';
import {
  EquipmentNotificationConstants,
  ProtectorSoftwareNotificationConstants,
  WorkshopConstants,
  WorkshopModuleRoutes,
} from '../../constants';
import { RAMConnectorErrorsService } from '../../services';
import { EquipmentCRUDActions } from '../../state/actions/equipment-crud/equipment-crud.actions';
import { EquipmentTestActions } from '../../state/actions/equipment-test';
import { RAMActions } from '../../state/actions/ram';
import { createTestEquipmentForm } from './form-fields';

import {
  CommonConstants,
  DropdownService,
  IApplicationState,
  INotificationType,
  IOKTAProfile,
  IScrollOptions,
  IStoreApiItem,
  OKTAProfile,
} from 'src/app/common';
import {
  EquipmentServiceInterval,
  IEquipment,
  IEquipmentServiceInterval,
  IParameter,
  ITestParameters,
  ITestStart,
  ITestStartResponse,
  ITestStatus,
  IToken,
  ServiceDataDTO,
} from '../../models';
import {
  selectCheckRAMConnectorStatus,
  selectEquipmentItem,
  selectRAMConnectorToken,
  selectStartTest,
  selectTestParameters,
  selectUpdatedEquipmentIds,
} from '../../state/selectors';

@Component({
  selector: 'ignis-test-equipment',
  templateUrl: './test-equipment.component.html',
  styleUrls: ['./test-equipment.component.scss'],
})
export class TestEquipmentComponent extends OnDestroyMixin() implements OnInit {
  reason: string;
  formatDate: string;
  user: IOKTAProfile;
  previousRoute: string;
  linkToNavigate: string;
  currentAppTheme: string;
  errors: unknown;
  isLoading: boolean = false;
  equipmentTestForm: FormGroup;
  disableStartTestBtn: boolean;
  selectedEquipment: IEquipment;
  markAllCheck: boolean[] = [];
  testParameters: ITestParameters;
  exitNotAccepted: boolean = true;
  testEquipmentBtnElem: ElementRef | ButtonComponent;
  isTestInProgress: boolean = false;
  ramConnectorStatus: boolean | string;
  startAutoUpdate: boolean = false;
  isSubmitting: Observable<boolean>;
  jwtHelper: JwtHelperService = new JwtHelperService();
  isConfirmCloseModalOpen: boolean = false;
  selectedService: IEquipmentServiceInterval = null;
  otherServiceIntervals: IEquipmentServiceInterval[] = [];
  selectedServiceIntervals: IEquipmentServiceInterval[] = [];

  scrollbarOptions: IScrollOptions = CommonConstants.scrollbarOptions;
  notificationType: INotificationType = CommonConstants.notificationType;

  dropdownService: DropdownService = inject(DropdownService);
  equipmentTestActions: EquipmentTestActions = inject(EquipmentTestActions);
  ramActions: RAMActions = inject(RAMActions);
  equipmentCRUDActions: EquipmentCRUDActions = inject(EquipmentCRUDActions);
  translateService: TranslateService = inject(TranslateService);
  notificationsService: NotificationsService = inject(NotificationsService);
  deviceConnectionsActions: DeviceConnectionActions = inject(DeviceConnectionActions);
  ramConnectorErrorsService: RAMConnectorErrorsService = inject(RAMConnectorErrorsService);
  location: Location = inject(Location);
  accessControlService: AccessControlService = inject(AccessControlService);

  @ViewChild('ddTestType') ddTestType: Partial<NgSelectComponent>;
  @ViewChild('ddTestTask') ddTestTask: Partial<NgSelectComponent>;
  @ViewChild('ddTestProcedure') ddTestProcedure: Partial<NgSelectComponent>;
  @ViewChild('ddTestEquipment') ddTestEquipment: Partial<NgSelectComponent>;
  @ViewChild('ddPressureGauge') ddPressureGauge: Partial<NgSelectComponent>;
  taskDetailsDropdownsAreOpened: boolean[];

  constructor(
    public router: Router,
    private store: Store<IApplicationState>,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super();

    this.currentAppTheme = this.document.body.className.split(' ')[1];

    this.buildForm();
    this.formatDate = getDateFormatFromUserProfile(this.translateService);
    this.markAllCheck = [];
    this.taskDetailsDropdownsAreOpened = [];

    this.router.events?.subscribe((event: NavigationEnd) => {
      this.linkToNavigate = event.url;
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.checkRAMConnectorTokenIfAvailablePortIsSet();
    this.readEquipmentServiceIntervalsDataFromStore();
    this.readRAMTokenStateFromStore();

    this.user = new OKTAProfile(this.jwtHelper.decodeToken(localStorage.getItem(CommonConstants.AUTH_TOKEN)));
    this.equipmentTestForm.get('testerName').setValue(`${this.user?.lastname}, ${this.user?.firstname}`);
    this.previousRoute = localStorage.getItem(WorkshopConstants.testStartFrom) ?? localStorage.getItem('previousRoute');

    this.isSubmitting = combineLatest([
      this.store.select(selectUpdatedEquipmentIds),
      this.store.select(selectStartTest),
    ]).pipe(
      takeUntil(this.destroy),
      map(
        ([updatedEquipment, status]: [
          updatedEquipment: IStoreApiItem<string[]>,
          status: IStoreApiItem<ITestStartResponse>,
        ]) => updatedEquipment.isLoading || status.isLoading,
      ),
    );
  }

  @HostListener(CommonConstants.popStateWindowEvent, ['$event'])
  onPopState(event: { returnValue: boolean }): void {
    this.navigateBack(true);
    event.returnValue = false;
  }

  @HostListener(CommonConstants.beforeUnloadWindowEvent, ['$event'])
  handleBeforeUnload($event: { returnValue: boolean }): void {
    if (this.exitNotAccepted) {
      $event.returnValue = this.exitNotAccepted;
    }
  }

  checkingRAMConnectorStatus(): void {
    checkRAMConnectorPort({
      ramActions: this.ramActions,
      store: this.store,
      notificationsService: this.notificationsService,
    }).then(() => {
      startTest({
        ramActions: this.ramActions,
        store: this.store,
        ramConnectorErrorsService: this.ramConnectorErrorsService,
        router: this.router,
        notificationsService: this.notificationsService,
        equipmentAggregateId: this.selectedEquipment.aggregateId,
      }).then((response: boolean | string) => {
        this.ramConnectorStatus = checkAutoUpdateFeatureToggleBeforeStartProtectorTest(
          this.accessControlService,
          response,
          this.notificationsService,
        );
        this.checkRAMConnectorToken();

        this.getTestParameters(this.selectedEquipment.generalData.model.type.protectorTestType);
      });
    });
  }

  checkRAMConnectorTokenIfAvailablePortIsSet(): void {
    if (CommonConstants.availableRAMConnectorPort in localStorage) {
      this.checkRAMConnectorToken();
    }
  }

  canDeactivate(): boolean {
    if (this.exitNotAccepted) {
      this.confirmCloseModalOpen();

      return false;
    } else {
      this.isConfirmCloseModalOpen = false;

      return true;
    }
  }

  getTestingBtnRef(event: ElementRef<Partial<NgSelectComponent>>): void {
    this.testEquipmentBtnElem = event;
  }

  navigateBack(isOpen: boolean): void {
    if (isOpen) {
      this.exitNotAccepted = false;
      this.equipmentTestForm.markAsPristine();

      if (window.location.pathname === this.linkToNavigate || !this.linkToNavigate) {
        this.router.navigate([this.previousRoute]);
      } else {
        this.router.navigate([this.linkToNavigate]);
      }
    } else {
      this.isConfirmCloseModalOpen = false;
    }

    setTimeout(() => {
      localStorage.removeItem(WorkshopConstants.testIsCanceledStorageKey);
      localStorage.removeItem(WorkshopConstants.testStartFrom);
    }, 250);
  }

  buildForm(): void {
    this.equipmentTestForm = createTestEquipmentForm();
  }

  checkRAMConnectorToken(): void {
    this.ramActions.requestRAMConnectorToken();

    this.store
      .pipe(
        select(selectRAMConnectorToken),
        filter((token: IStoreApiItem<IToken>) => !token.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<IToken>) => {
        if (response.data?.token) {
          this.deviceConnectionsActions.requestToCheckRAMConnectorToken(response.data.token);
        } else {
          this.errors = response.errors?.error.code ? response.errors?.error.code : (0 as number);
          this.ramConnectorErrorsService.processProtectorSoftwareErrorMsg(this.errors as number);

          this.equipmentTestForm.disable();
        }
      });
  }

  readRAMTokenStateFromStore(): void {
    this.store
      .pipe(
        select(selectToCheckRAMConnectorToken),
        filter((tokenExist: IStoreApiItem<IDeviceConnectionToken>) => !tokenExist.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((tokenExist: IStoreApiItem<IDeviceConnectionToken>) => {
        if (tokenExist.errors) {
          this.errors = tokenExist.errors;
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            EquipmentNotificationConstants.notificationCodes.WRONG_RAM_CONNECTOR_TOKEN,
            EquipmentNotificationConstants.notificationCodes,
          );

          this.equipmentTestForm.disable();

          setTimeout(() => {
            this.equipmentTestForm.setErrors({ error: true });
          }, 0);

          this.exitNotAccepted = false;
          this.router.navigate([localStorage.getItem(WorkshopConstants.testStartFrom)]);
          localStorage.removeItem(WorkshopConstants.testStartFrom);
        } else if (tokenExist.data) {
          this.isLoading = false;
          this.checkRAMConnectorStatus();
        }
      });
  }

  checkRAMConnectorStatus(): void {
    this.store
      .select(selectCheckRAMConnectorStatus)
      .pipe(
        filter((ramConnectorStatus: IStoreApiItem<ITestStatus>) => !ramConnectorStatus.isLoading),
        take(1),
      )
      .subscribe((ramConnectorStatus: IStoreApiItem<ITestStatus>) => this.checkRAMOutdate(ramConnectorStatus.data));
  }

  checkRAMOutdate(ramStatus: ITestStatus): void {
    if (
      ramStatus &&
      ramStatus.status === 'idle' &&
      !ramStatus?.isLatestVersion &&
      !localStorage.hasOwnProperty(
        ProtectorSoftwareNotificationConstants.notificationCodes.OUTDATED_RAM_CONNECTION_VERSION.value,
      )
    ) {
      if (this.isRamVersionNewer(ramStatus)) {
        this.notificationsService.requestShowNotification(
          CommonConstants.notificationType.WARNING,
          ProtectorSoftwareNotificationConstants.notificationCodes.NEWER_RAM_CONNECTION_VERSION,
          ProtectorSoftwareNotificationConstants.notificationCodes,
          {
            current: ramStatus.currentVersion,
            latest: ramStatus.latestVersion,
          },
        );
      } else if (this.accessControlService.ramConnectorAutoUpdateFeatureToggle) {
        this.ramConnectorStatus =
          ProtectorSoftwareNotificationConstants.notificationCodes.OUTDATED_RAM_CONNECTION_VERSION.value;
      } else {
        this.notificationsService.requestShowNotification(
          CommonConstants.notificationType.WARNING,
          ProtectorSoftwareNotificationConstants.notificationCodes.OUTDATED_RAM_CONNECTION_VERSION_WITHOUT_AUTO_UPDATE,
          ProtectorSoftwareNotificationConstants.notificationCodes,
        );
      }

      localStorage.setItem(
        ProtectorSoftwareNotificationConstants.notificationCodes.OUTDATED_RAM_CONNECTION_VERSION.value,
        'true',
      );
    }
  }

  isRamVersionNewer(ramStatus: ITestStatus): boolean {
    if (!ramStatus.currentVersion || !ramStatus.latestVersion) return false;

    const currentVersion: string[] = ramStatus.currentVersion?.split('.');
    const latestVersion: string[] = ramStatus.latestVersion?.split('.');

    return (
      Number(currentVersion[0]) > Number(latestVersion[0]) ||
      Number(currentVersion[1]) > Number(latestVersion[1]) ||
      Number(currentVersion[2]) > Number(latestVersion[2])
    );
  }

  getTestParameters(protectorTestType: string): void {
    this.equipmentTestActions.requestTestParameters(protectorTestType);
    this.store
      .select(selectTestParameters)
      .pipe(takeUntil(this.destroy))
      .subscribe((selectParameters: IStoreApiItem<ITestParameters>) => {
        if (selectParameters.data) {
          this.testParameters = structuredClone(selectParameters.data);

          const dummyOption: { id: string; name: string } = { id: null, name: '' };

          this.testParameters.procedures.forEach((procedure: string) => {
            this.testParameters.procedures.push({ id: procedure, name: procedure });
          });

          this.testParameters.procedures = this.testParameters.procedures.filter(
            (elem: IParameter) => typeof elem !== 'string',
          );

          this.testParameters.procedures.unshift(dummyOption);
          this.testParameters.devices.unshift(dummyOption);
          this.testParameters.types.unshift(dummyOption);

          if (this.testParameters.pressureGaugeComparisons.length > 0) {
            this.testParameters.pressureGaugeComparisons.unshift(dummyOption);
            this.equipmentTestForm.get('pressureGaugeId').setValidators([Validators.required]);
          } else {
            this.testParameters.pressureGaugeComparisons.length = 0;
            this.equipmentTestForm.get('pressureGaugeId').removeValidators([Validators.required]);
          }

          this.loadTestParametersFromLocalStorage();
        } else if (selectParameters.errors) {
          this.ramConnectorErrorsService.processProtectorSoftwareErrorMsg(
            selectParameters.errors?.error.code
              ? (selectParameters.errors?.error.code as string)
              : selectParameters.errors.status.toString(),
          );
        }
      });
  }

  checkIfItemExistsInTestParameters(
    array: (string | IParameter | IEquipmentServiceInterval)[],
    key: string,
    value: string,
  ): boolean {
    return array.some((item: string | IParameter) => item[key] === value);
  }

  setToNullTestParameter(storedModel: {
    task: string;
    testDevice: string;
    testProcedure: string;
    testType: string;
    pressureGaugeId: string;
    equipmentModelId: string;
  }): void {
    if (
      !this.checkIfItemExistsInTestParameters(this.selectedEquipment?.serviceIntervals, 'serviceId', storedModel.task)
    ) {
      storedModel.task = null;
    }

    if (!this.checkIfItemExistsInTestParameters(this.testParameters.devices, 'id', storedModel.testDevice)) {
      storedModel.testDevice = null;
    }

    if (!this.checkIfItemExistsInTestParameters(this.testParameters.procedures, 'id', storedModel.testProcedure)) {
      storedModel.testProcedure = null;
    }

    if (!this.checkIfItemExistsInTestParameters(this.testParameters.types, 'name', storedModel.testType)) {
      storedModel.testType = null;
    }

    if (
      !this.checkIfItemExistsInTestParameters(
        this.testParameters.pressureGaugeComparisons,
        'id',
        storedModel.pressureGaugeId,
      )
    ) {
      storedModel.pressureGaugeId = null;
    }
  }

  loadTestParametersFromLocalStorage(): void {
    const storedModelParams: string = `${WorkshopConstants.testParameters}.${this.selectedEquipment.generalData.model.id}`;
    const storedTaskId: string = localStorage.getItem(WorkshopConstants.taksId);

    setTimeout(() => {
      this.equipmentTestForm.get('task').setValue(storedTaskId);
      const selectedTask: ITask = this.selectedEquipment.serviceIntervals.filter(
        (task: ITask) => task.serviceId === storedTaskId,
      )[0] as ITask;

      this.changeService(selectedTask);
    }, 0);

    if (storedModelParams in localStorage) {
      setTimeout(() => {
        const storedModel: {
          task: string;
          testDevice: string;
          testProcedure: string;
          testType: string;
          pressureGaugeId: string;
          equipmentModelId: string;
        } = JSON.parse(localStorage.getItem(storedModelParams));

        this.setToNullTestParameter(storedModel);

        if (this.testParameters.devices.length < 2) {
          storedModel.testDevice = null;
        }

        this.equipmentTestForm.patchValue(storedModel);

        this.disableStartTestBtn = true;

        localStorage.setItem(
          storedModelParams,
          JSON.stringify({
            equipmentModelId: this.selectedEquipment.generalData.model.id,
            task: this.equipmentTestForm.getRawValue().task as string,
            testProcedure: this.equipmentTestForm.getRawValue().testProcedure as string,
            testDevice: this.equipmentTestForm.getRawValue().testDevice as string,
            testType: this.equipmentTestForm.getRawValue().testType as string,
            pressureGaugeId: this.equipmentTestForm.getRawValue().pressureGaugeId as string,
          }),
        );

        this.equipmentTestForm.get('task').setValue(storedTaskId ?? storedModel.task);

        this.ddTestTask?.focus();

        this.selectedEquipment.serviceIntervals.forEach((elem: IEquipmentServiceInterval) => {
          if (elem.serviceId === storedModel.task) {
            this.selectedService = elem;
            this.disableStartTestBtn = false;

            this.changeService(elem);
          }
        });
      }, 0);
    } else {
      this.equipmentTestForm.get('task').reset();
      this.equipmentTestForm.get('testDevice').reset();
      this.equipmentTestForm.get('testProcedure').reset();
      this.equipmentTestForm.get('testType').reset();
      this.equipmentTestForm.get('pressureGaugeId').reset();

      setTimeout(() => {
        if (this.ddTestTask) {
          this.ddTestTask.focus();
        }
      }, 0);
    }
  }

  applyFocusOnElements(): void {
    setTimeout(() => {
      this.applyFocusForEmptyFields();
      this.focusStartTestBtn();
    }, 250);
  }

  applyFocusForEmptyFields(): void {
    if (!this.equipmentTestForm.get('task').value || (this.equipmentTestForm.get('task').value as string).length < 1) {
      this.ddTestTask.focus();
    } else if (
      !this.equipmentTestForm.get('testDevice').value ||
      (this.equipmentTestForm.get('testDevice').value as string).length < 1
    ) {
      this.ddTestEquipment?.focus();
    } else if (
      !this.equipmentTestForm.get('testProcedure').value ||
      (this.equipmentTestForm.get('testProcedure').value as string).length < 1
    ) {
      this.ddTestProcedure.focus();
    } else if (
      !this.equipmentTestForm.get('testType').value ||
      (this.equipmentTestForm.get('testType').value as string).length < 1
    ) {
      this.ddTestType.focus();
    } else if (
      this.ddPressureGauge &&
      (!this.equipmentTestForm.get('pressureGaugeId').value ||
        (this.equipmentTestForm.get('pressureGaugeId').value as string).length < 1)
    ) {
      this.ddPressureGauge.focus();
    }
  }

  readEquipmentServiceIntervalsDataFromStore(): void {
    this.store
      .select(selectEquipmentItem)
      .pipe(
        filter((state: IStoreApiItem<IEquipment>) => !state.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((selectedEquipment: IStoreApiItem<IEquipment>) => {
        if (selectedEquipment.data && this.router.url.includes(WorkshopModuleRoutes.testEquipment)) {
          this.selectedEquipment = structuredClone(selectedEquipment.data);
          const sortedArr: IEquipmentServiceInterval[] = this.sortArrayByName(this.selectedEquipment.serviceIntervals);

          sortedArr?.unshift(new EquipmentServiceInterval());
          this.selectedEquipment.serviceIntervals = sortedArr;

          localStorage.setItem(
            WorkshopConstants.serviceIntervals,
            JSON.stringify(selectedEquipment.data.serviceIntervals),
          );

          this.selectedEquipment.serviceIntervals?.forEach((elem: IEquipmentServiceInterval) => {
            elem.taskName = this.renameDescriptionOfService(elem);
          });

          this.equipmentTestForm.patchValue({
            barcode: selectedEquipment.data.generalData.barcode,
            rfid: selectedEquipment.data.generalData.rfid,
            serialNo: selectedEquipment.data.generalData.serialNo,
            equipmentModel: selectedEquipment.data.generalData.model.name,
            kind: selectedEquipment.data.generalData.model.type.name
              ? selectedEquipment.data.generalData.model.type.name
              : 'test',
            equipmentId: selectedEquipment.data.generalData.identifier,
            aggregateId: selectedEquipment.data.aggregateId,
          });

          if (this.selectedEquipment.serviceIntervals?.length > 0) {
            this.changeService(this.selectedEquipment?.serviceIntervals[0]);

            this.selectedService = this.selectedEquipment.serviceIntervals[0];
            this.equipmentTestForm.get('task').setValue(this.selectedService);

            this.disableStartTestBtn = false;
          } else {
            this.equipmentTestForm.markAsPristine();
            this.equipmentTestForm.markAsUntouched();
            this.disableStartTestBtn = true;
          }

          this.getTestDataAndCheckRAMStatus();
        }
      });
  }

  getTestDataAndCheckRAMStatus(): void {
    if (!(CommonConstants.availableRAMConnectorPort in localStorage)) {
      this.checkingRAMConnectorStatus();
    } else {
      this.getTestParameters(this.selectedEquipment.generalData.model.type.protectorTestType);
    }
  }

  sortArrayByName(data: IEquipmentServiceInterval[]): IEquipmentServiceInterval[] {
    if (data) {
      return data.sort((int1: IEquipmentServiceInterval, int2: IEquipmentServiceInterval) => {
        const c1: boolean = int1.taskName.toLocaleUpperCase() < int2.taskName.toLocaleUpperCase();
        const c2: boolean = int1.taskName.toLocaleUpperCase() > int2.taskName.toLocaleUpperCase();
        const c3: number = c2 ? 1 : 0;

        return c1 ? -1 : c3;
      });
    }
  }

  changeService(event: Partial<ITask>): void {
    this.reason = event?.taskName;
    this.otherServiceIntervals = [];
    this.selectedServiceIntervals = [];

    if (event?.serviceId) {
      this.selectedEquipment.serviceIntervals.forEach((elem: IEquipmentServiceInterval) => {
        if (elem.serviceId && elem.serviceId === event.serviceId) {
          this.selectedService = elem;
          this.selectedServiceIntervals.push(elem);

          elem?.children?.forEach((child: IEquipmentServiceInterval) => {
            this.selectedServiceIntervals.push(child);
          });
        }
      });

      const selectedIntervals: string[] = this.selectedServiceIntervals.map(
        (interval: IEquipmentServiceInterval) => interval.serviceId,
      );

      this.otherServiceIntervals = this.selectedEquipment.serviceIntervals.filter(
        (interval: IEquipmentServiceInterval) => !selectedIntervals.includes(interval.serviceId) && interval.serviceId,
      );

      this.disableStartTestBtn = false;
    }
  }

  renameDescriptionOfService(service: IEquipmentServiceInterval): string {
    if (service.inheritedFrom) {
      return `${service.taskName} (${this.translateService.instant('EQUIPMENT_TASK_RESULTS.STR_INHERITED')} ${service.inheritedFrom})`;
    }

    return service.taskName;
  }

  focusNextDropdown(): void {
    if (!this.equipmentTestForm.get('task').value) {
      this.ddTestTask.focus();
    } else if (!this.equipmentTestForm.get('testDevice').value) {
      this.ddTestEquipment.focus();
    } else if (!this.equipmentTestForm.get('testProcedure').value) {
      this.ddTestProcedure.focus();
    } else if (!this.equipmentTestForm.get('testType').value) {
      this.ddTestType.focus();
    } else if (
      !this.equipmentTestForm.get('pressureGaugeId').value &&
      this.testParameters.pressureGaugeComparisons.length > 1
    ) {
      this.ddPressureGauge.focus();
    } else {
      this.focusStartTestBtn();
    }

    this.taskDetailsDropdownsAreOpened = [];
  }

  clearError(): void {
    this.equipmentTestForm.get('task').setErrors(null);
    this.equipmentTestForm.get('testDevice').setErrors(null);
    this.equipmentTestForm.get('testProcedure').setErrors(null);
    this.equipmentTestForm.get('testType').setErrors(null);
    this.equipmentTestForm.get('pressureGaugeId').setErrors(null);

    this.equipmentTestForm.updateValueAndValidity();
  }

  confirmCloseModalOpen(): void {
    this.isConfirmCloseModalOpen = true;
  }

  focusStartTestBtn(): void {
    if (this.equipmentTestForm.valid) {
      setTimeout(() => {
        ((this.testEquipmentBtnElem as ButtonComponent).element?.nativeElement as HTMLElement)?.focus();
      }, 0);
    }
  }

  checkTestIsPossible(): void {
    this.isLoading = true;

    this.onStartTesting();
    const storedModelParams: string = `${WorkshopConstants.testParameters}.${this.selectedEquipment.generalData.model.id}`;

    localStorage.setItem(
      storedModelParams,
      JSON.stringify({
        equipmentModelId: this.selectedEquipment.generalData.model.id,
        task: this.equipmentTestForm.getRawValue().task as string,
        testProcedure: this.equipmentTestForm.getRawValue().testProcedure as string,
        testDevice: this.equipmentTestForm.getRawValue().testDevice as string,
        testType: this.equipmentTestForm.getRawValue().testType as string,
        pressureGaugeId: this.equipmentTestForm.getRawValue().pressureGaugeId as string,
      }),
    );
  }

  restoreReasonName(): void {
    const serviceIntervals: IEquipmentServiceInterval[] = JSON.parse(
      localStorage.getItem(WorkshopConstants.serviceIntervals),
    ) as IEquipmentServiceInterval[];

    this.selectedServiceIntervals?.forEach((service: ITask) => {
      serviceIntervals?.forEach((storedService: ITask) => {
        if (storedService.serviceId === service.serviceId) {
          service.taskName = storedService.taskName;
        }
      });
    });

    this.otherServiceIntervals?.forEach((service: ITask) => {
      serviceIntervals?.forEach((storedService: ITask) => {
        if (storedService.serviceId === service.serviceId) {
          service.taskName = storedService.taskName;
        }
      });
    });
  }

  onStartTesting(): void {
    this.exitNotAccepted = false;
    const parameter: IParameter[] = this.testParameters?.types.filter((type: IParameter) => {
      return type.name === this.equipmentTestForm.get('testType').value;
    });

    this.restoreReasonName();

    const pressure: string = this.equipmentTestForm.controls.pressureGaugeId.value as string;
    const prepareTestData: Partial<unknown> = {
      ...(this.equipmentTestForm.getRawValue() as ITestStart),
      pressureGaugeId: pressure ? pressure : undefined,
      testKind: parameter ? parameter[0]?.kind : null,
      taskData: {
        includedTasks: this.selectedServiceIntervals.map(
          (interval: IEquipmentServiceInterval) => new ServiceDataDTO(interval),
        ),
        others: this.otherServiceIntervals.map((interval: IEquipmentServiceInterval) => new ServiceDataDTO(interval)),
      },
      locationPath: processLocationPath(this.selectedEquipment?.locationAssignment?.path),
      equipmentAggregateId: this.selectedEquipment.aggregateId,
      equipmentCategory: this.selectedEquipment?.generalData.model.category.name,
      outOfServiceDate: this.selectedEquipment?.generalData?.outOfOrderDate?.toString(),
      outOfServiceReason: this.selectedEquipment?.generalData?.outOfServiceReason,
      equipmentType: this.selectedEquipment?.generalData.model.type.name,
    };

    this.equipmentTestActions.requestStartTestEquipment(prepareTestData as ITestStart);
    const testStart: ReturnType<typeof setTimeout> = setTimeout(() => {
      this.startTestSubscriber();

      clearTimeout(testStart);
      localStorage.removeItem(WorkshopConstants.serviceIntervals);
      localStorage.removeItem(WorkshopConstants.taksId);
    }, 0);
  }

  startTestSubscriber(): void {
    this.store
      .select(selectStartTest)
      .pipe(
        filter((status: IStoreApiItem<ITestStartResponse>) => !status.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((startTestResponse: IStoreApiItem<ITestStartResponse>) => {
        if (!window.navigator.onLine) {
          return;
        }

        if (startTestResponse.data) {
          this.router.navigate(['workshop', 'testing-equipment', startTestResponse.data.identifier]);
          this.isLoading = false;
        }

        if (startTestResponse.errors) {
          this.errors = startTestResponse.errors?.error.code
            ? (startTestResponse.errors?.error.code as number)
            : startTestResponse.errors.status;

          this.isLoading = false;

          this.setDropDownFieldAsInvalid(startTestResponse);

          this.ramConnectorErrorsService.processProtectorSoftwareErrorMsg(this.errors as number);
        }
      });
  }

  setDropDownFieldAsInvalid(startTestStatus: IStoreApiItem<ITestStartResponse>): boolean {
    if (
      (startTestStatus.errors?.error.code as string)?.toString() ===
        ProtectorSoftwareNotificationConstants.notificationCodes.PROTECTOR_SOFTWARE_TEST_PARAMETERS_NOT_VALID.value ||
      'violations' in startTestStatus.errors?.error
    ) {
      this.equipmentTestForm.get('testDevice').setErrors({ invalid: true });
      this.equipmentTestForm.get('testProcedure').setErrors({ invalid: true });
      this.equipmentTestForm.get('testType').setErrors({ invalid: true });
      this.equipmentTestForm.get('pressureGaugeId').setErrors({ invalid: true });

      return false;
    }
  }

  testIsExpired(date: string): boolean {
    return new Date(date) < new Date();
  }

  startRAMConnectorAutoUpdateFromEQTestPage(event: boolean): void {
    this.ramConnectorStatus = null;
    this.startAutoUpdate = event;
  }

  goToParentView(): void {
    this.exitNotAccepted = false;
    this.startAutoUpdate = false;

    this.location.back();
  }

  closeOnClickAgain(dropdownName: string): void {
    this.taskDetailsDropdownsAreOpened.push(this[dropdownName].isOpen as boolean);

    if (this.taskDetailsDropdownsAreOpened.length === 2) {
      this[dropdownName].close();
      this[dropdownName].focus();

      this.taskDetailsDropdownsAreOpened = [];
    }
  }

  compareTasks(task1: ITask, serviceId: string): boolean {
    return task1?.serviceId === serviceId;
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();

    this.deviceConnectionsActions.resetToCheckRAMConnectorToken();
    this.equipmentCRUDActions.resetSelectedEquipmentState();
    this.equipmentTestActions.resetTestParameters();

    localStorage.removeItem(WorkshopConstants.taksId);
  }
}
