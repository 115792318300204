import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ILicenseTicket } from 'src/app/root/models/licenses.model';
import { ILicensesState } from '../models/licenses.model';
import {
  RequestActivateTicketID,
  RequestLicenseStatus,
  ResetActivateTicketID,
  ResetLicenseStatus,
} from './licenses-action-types-creator';

@Injectable({ providedIn: 'root' })
export class LicensesActions {
  constructor(private store: Store<ILicensesState>) {}

  public requestLicenseStatus(ticket: ILicenseTicket): void {
    this.store.dispatch(new RequestLicenseStatus(ticket));
  }

  public resetLicenseStatus(): void {
    this.store.dispatch(new ResetLicenseStatus());
  }

  public requestActivateTicketID(ticket: ILicenseTicket): void {
    this.store.dispatch(new RequestActivateTicketID(ticket));
  }

  public resetActivateTicketID(): void {
    this.store.dispatch(new ResetActivateTicketID());
  }
}
