import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LegalTermsService } from '../../../services/legal-terms/legal-terms.service';
import { LegalTermsActionType } from '../actions/legal-terms-action-types';
import {
  ErrorReceiveAcceptDPA,
  ErrorReceiveAcceptGDPR,
  ErrorReceiveAcceptTAC,
  ErrorReceiveDPAData,
  ErrorReceiveGDPRData,
  ErrorReceiveTACData,
  ReceiveAcceptDPA,
  ReceiveAcceptGDPR,
  ReceiveAcceptTAC,
  ReceiveDPAData,
  ReceiveGDPRData,
  ReceiveTACData,
} from './../actions/legal-terms-action-types-creators';
import { ILegalTermsData } from './../models/legal-terms.model';

@Injectable()
export class LegalTermsEffects {
  constructor(private legalTermsService: LegalTermsService) {}

  requestGDPRData$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(LegalTermsActionType.REQUEST_GDPR_DATA),
      switchMap(() =>
        this.legalTermsService.getGDPRData().pipe(
          map((legalData: ILegalTermsData) => new ReceiveGDPRData(legalData)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveGDPRData(error))),
        ),
      ),
    );
  });

  requestAcceptGDPR$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(LegalTermsActionType.REQUEST_ACCEPT_GDPR),
      switchMap((version: any) =>
        this.legalTermsService.acceptGDPR(version.payload).pipe(
          map(() => new ReceiveAcceptGDPR()),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAcceptGDPR(error))),
        ),
      ),
    );
  });

  requestTACData$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(LegalTermsActionType.REQUEST_TAC_DATA),
      switchMap(() =>
        this.legalTermsService.getTaCData().pipe(
          map((legalData: ILegalTermsData) => new ReceiveTACData(legalData)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveTACData(error))),
        ),
      ),
    );
  });

  requestAcceptTAC$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(LegalTermsActionType.REQUEST_ACCEPT_TAC),
      switchMap((version: any) =>
        this.legalTermsService.acceptTaC(version.payload).pipe(
          map(() => new ReceiveAcceptTAC()),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAcceptTAC(error))),
        ),
      ),
    );
  });

  requestDPAData$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(LegalTermsActionType.REQUEST_DPA_DATA),
      switchMap(() =>
        this.legalTermsService.getDPAData().pipe(
          map((legalData: ILegalTermsData) => new ReceiveDPAData(legalData)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveDPAData(error))),
        ),
      ),
    );
  });

  requestAcceptDPA$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(LegalTermsActionType.REQUEST_ACCEPT_DPA),
      switchMap((version: any) =>
        this.legalTermsService.acceptDPA(version.payload).pipe(
          map(() => new ReceiveAcceptDPA()),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAcceptDPA(error))),
        ),
      ),
    );
  });
}
