import { IStoreApiItem, StoreApiItem } from 'src/app/common';
import { IMediaAssets, INewNote } from '../../models/incident.model';

export interface IIncidentNoteState {
  mediaAssets: IStoreApiItem<IMediaAssets[]>;
  newNote: IStoreApiItem<INewNote>;
}

export class IncidentNoteState implements IIncidentNoteState {
  mediaAssets: IStoreApiItem<IMediaAssets[]> = new StoreApiItem();
  newNote: IStoreApiItem<INewNote> = new StoreApiItem();
}
