import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonConstants } from 'src/app/common';
import { IEntryModel } from 'src/app/common/models/common.model';

@Component({
  selector: 'ignis-organization-settings-section',
  templateUrl: './organization-settings-section.component.html',
  styleUrls: ['./organization-settings-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationSettingsSectionComponent {
  @Input() organizationSettingsForm: FormGroup;
  @Input() languagesOptions: IEntryModel[];
  @Input() dateTimeFormatOptions: string[];

  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;
}
