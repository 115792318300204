import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAddressBook } from 'src/app/configuration/models';
import { IConfigurationState } from '../../models/configuration.models';
import {
  RequestAddAddressBook,
  RequestAddressBook,
  RequestAddressBookById,
  RequestAddressBookDelete,
  RequestEditAddressBook,
  ResetAddressBookPage,
} from './address-book-action-types-creators';

@Injectable({ providedIn: 'root' })
export class AddressBookActions {
  constructor(public store: Store<IConfigurationState>) {}

  public requestAddressBook(params: any): void {
    this.store.dispatch(new RequestAddressBook(params));
  }

  public requestAddAddressBook(newAddress: IAddressBook): void {
    this.store.dispatch(new RequestAddAddressBook(newAddress));
  }

  public requestEditAddressBook(editAddress: IAddressBook): void {
    this.store.dispatch(new RequestEditAddressBook(editAddress));
  }

  public requestAddressBookById(id: string): void {
    this.store.dispatch(new RequestAddressBookById(id));
  }

  public requestAddressBookDelete(payload: { aggregateId: string; version: number }): void {
    this.store.dispatch(new RequestAddressBookDelete(payload));
  }

  public resetAddressBookPage(): void {
    this.store.dispatch(new ResetAddressBookPage());
  }
}
