<odx-form-field
  variant="horizontal"
  label="{{ 'WORKSHOP_WORKFLOW_ASSET.STR_DIRECTLY_ASSIGN_TO_LOCATION' | translate }}"
>
  <div class="w-100">
    <p-treeSelect
      #dd1
      (onShow)="dropdownService.openDropdown(dd1)"
      (onHide)="dropdownService.closeDropdown(dd1)"
      ngDefaultControl
      odxFormFieldControl
      filter="true"
      name="location"
      id="location"
      data-test-id="equipment_workflow_asset.directly_assign_to_location"
      class="custom-tree-select-form"
      [ngModel]="savedLocation"
      [placeholder]="'WORKSHOP_WORKFLOW_ASSET.STR_NO_RELOCATION' | translate"
      [showClear]="true"
      [options]="locations"
      appendTo="body"
      [filterPlaceholder]="'WORKSHOP_WORKFLOW_ASSET.STR_SEARCH_LOCATION' | translate"
      [emptyMessage]="'WORKSHOP_WORKFLOW_ASSET.STR_NO_RECORDS_FOUND' | translate"
      [virtualScroll]="true"
      [virtualScrollItemSize]="46"
      [virtualScrollOptions]="{ scrollHeight: '250px' }"
      (onNodeSelect)="setSelectedLocation($event)"
      (onClear)="clearSelectedLocation()"
    >
      <ng-template let-selectedItem pTemplate="value">
        <div class="select-tree-selectedItem" *ngIf="selectedItem; else placeholder">
          <odx-icon [name]="selectedItem?.icon" class="p-treenode-icon"></odx-icon>
          <label [title]="selectedItem?.label">{{ selectedItem?.label }}</label>
        </div>
        <ng-template #placeholder>
          <span>{{ 'WORKSHOP_WORKFLOW_ASSET.STR_NO_RELOCATION' | translate }}</span>
        </ng-template>
      </ng-template>
      <ng-template let-node pTemplate="default">
        <odx-icon [name]="node.icon" class="p-treenode-icon"></odx-icon>
        <span class="p-treenode-label">{{ node.label }}</span>
      </ng-template>
      <ng-template pTemplate="clearicon">
        <odx-icon name="close"></odx-icon>
      </ng-template>
    </p-treeSelect>
  </div>
</odx-form-field>
