import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  inject,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { isAfter, isBefore, isSameDay, startOfToday } from 'date-fns';
import upperFirst from 'lodash-es/upperFirst';
import { CommonConstants, DatePickerService, DropdownService } from 'src/app/common';
import { IEntryModel } from 'src/app/common/models/common.model';
import { extractOnlyDate } from 'src/app/common/utils/date-utils/date.utils';
import { TaskResultTypeEnum, WorkshopConstants } from 'src/app/workshop/constants';
import { IFaultedField, ITaskData, ITaskInterval, ITestResultDetails } from 'src/app/workshop/models';

@Component({
  selector: 'ignis-general-tab',
  templateUrl: './general-tab.component.html',
  styleUrls: ['./general-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralTabComponent implements OnChanges {
  @Input() selectedTaskName: string;
  @Input() dateTime: string;
  @Input() formatDate: string;
  @Input() taskResultType: string;
  @Input() faultedFields: IFaultedField[];
  @Input() taskData: ITaskData;
  @Input() testResultData: ITestResultDetails;
  @Input() barcodeUpdateInTaskFeatureToggle: boolean;
  @Input() equipmentForm: FormGroup;
  @Input() outOfServiceDatePickerAndOtherTasksCheckboxState: boolean;
  @Input() isFromMigration: boolean;

  initialMaxDate: Date;
  outOfServiceDate: Date;
  extractOnlyDate: typeof extractOnlyDate = extractOnlyDate;
  taskResultTypesEnum: typeof TaskResultTypeEnum = TaskResultTypeEnum;
  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;

  dropdownService: DropdownService = inject(DropdownService);
  datepickerService: DatePickerService = inject(DatePickerService);
  translateService: TranslateService = inject(TranslateService);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  constructor() {
    this.initialMaxDate = new Date();
  }

  operationalStatusList: IEntryModel[] = WorkshopConstants.operationalStatusLocalize.status;

  otherTasks: ITaskInterval[] = [];

  @Output() handleAddedOtherTasks: EventEmitter<ITaskInterval[]> = new EventEmitter<ITaskInterval[]>();

  ngOnChanges(): void {
    this.otherTasks = structuredClone(this.taskData.others);

    this.operationalStatusChanged({ value: this.equipmentForm.get('operationalStatus').value as string });

    if ((this.taskResultType as TaskResultTypeEnum.CylinderCharging) === TaskResultTypeEnum.CylinderCharging) {
      this.handleAddedOtherTasks.emit([]);
    }

    if (this.testResultData.outOfService && this.testResultData.outOfService.date && this.testResultData.isLastResult) {
      setTimeout(() => {
        this.outOfServiceDate = new Date(this.testResultData.outOfService?.date);
        this.toggleReasonField(true);
        this.disableOutOfServiceReasonIfDateIsEmpty();
      }, 50);
    } else {
      setTimeout(() => {
        this.disableOutOfServiceReasonIfDateIsEmpty();
      }, 50);
    }

    if (!this.testResultData.isLastResult) {
      this.equipmentForm.get('outOfServiceReason').disable();
    }

    if (this.faultedFields) {
      const prefix: string = 'new';

      this.faultedFields.forEach((field: IFaultedField) => {
        this.equipmentForm.get(`${prefix}${upperFirst(field.fieldName)}`).setErrors({ invalid: true });
      });
    }

    if (this.isFromMigration) {
      this.equipmentForm.disable();
    }
  }

  emitOtherTasks(): void {
    this.handleAddedOtherTasks.emit(this.otherTasks);
  }

  changeOtherTask(event: Event, task: ITaskInterval): void {
    task.newLastTestDate =
      (event.target as HTMLInputElement).checked &&
      this.equipmentForm.get('operationalStatus').value === WorkshopConstants.operationalStatuses.OPERATIONAL
        ? task.precalculatedLastTestDate
        : null;
    task.newNextTestDate =
      (event.target as HTMLInputElement).checked &&
      this.equipmentForm.get('operationalStatus').value === WorkshopConstants.operationalStatuses.OPERATIONAL
        ? task.precalculatedNextTestDate
        : null;

    this.equipmentForm.markAllAsTouched();
    this.equipmentForm.markAsDirty();

    this.handleAddedOtherTasks.emit(this.otherTasks);

    setTimeout(() => {
      this.equipmentForm.get('otherTasks').setValue(this.otherTasks);
    }, 0);
  }

  operationalStatusChanged(selectedStatus: { value: string }): void {
    if (selectedStatus.value !== WorkshopConstants.operationalStatuses.OPERATIONAL) {
      this.otherTasks.forEach((otherService: ITaskInterval) => {
        otherService.newLastTestDate = null;
        otherService.newNextTestDate = null;
      });

      this.taskData?.includedTasks?.forEach((service: ITaskInterval) => {
        service.newLastTestDate = null;
        service.newNextTestDate = null;
      });
    } else {
      this.taskData?.includedTasks?.forEach((service: ITaskInterval) => {
        service.newLastTestDate = service.precalculatedLastTestDate;
        service.newNextTestDate = service.precalculatedNextTestDate;
      });

      this.otherTasks?.forEach((otherService: ITaskInterval) => {
        if (otherService.wasTestIncluded) {
          otherService.newLastTestDate = otherService.precalculatedLastTestDate;
          otherService.newNextTestDate = otherService.precalculatedNextTestDate;
        }
      });
    }

    this.equipmentForm.get('operationalStatus').patchValue(selectedStatus.value);
  }

  toggleReasonField(isEnabled: boolean, manualChange: boolean = false): void {
    this.outOfServiceDate = new Date(this.equipmentForm.get('outOfServiceDate')?.value as Date);

    if (
      isEnabled &&
      (isSameDay(this.outOfServiceDate, startOfToday()) || isBefore(this.outOfServiceDate, startOfToday()))
    ) {
      this.equipmentForm.get('operationalStatus').patchValue(WorkshopConstants.operationalStatuses.NON_OPERATIONAL);
      this.operationalStatusChanged({ value: this.equipmentForm.get('operationalStatus').value as string });

      this.equipmentForm.get('operationalStatus').disable();
      this.equipmentForm.get('outOfServiceReason').enable();
    } else if (
      isEnabled &&
      (isSameDay(this.outOfServiceDate, startOfToday()) || isAfter(this.outOfServiceDate, startOfToday()))
    ) {
      this.enableOperationalStatusIfOutOfServiceDatePickerStateIsFalse();
      this.equipmentForm.get('outOfServiceReason').enable();
      this.equipmentForm.get('outOfServiceReason').patchValue(this.testResultData.outOfService?.reason);
    } else {
      this.equipmentForm.get('operationalStatus').patchValue(this.testResultData.operationalStatus);
      this.operationalStatusChanged({ value: this.equipmentForm.get('operationalStatus').value as string });

      this.equipmentForm.get('operationalStatus').enable();
      this.equipmentForm.get('outOfServiceReason').disable();
      this.equipmentForm.get('outOfServiceReason').patchValue(null);
    }

    if (manualChange) this.equipmentForm.get('outOfServiceDate').markAsDirty();

    this.equipmentForm.updateValueAndValidity();
  }

  enableOperationalStatusIfOutOfServiceDatePickerStateIsFalse(): void {
    if (this.outOfServiceDatePickerAndOtherTasksCheckboxState) {
      this.equipmentForm.get('operationalStatus').enable();
    }
  }

  disableOutOfServiceReasonIfDateIsEmpty(): void {
    if (!this.testResultData.outOfService?.date) {
      this.equipmentForm.get('outOfServiceReason').disable();
      this.equipmentForm.get('outOfServiceReason').patchValue(null);
    } else if (!this.outOfServiceDatePickerAndOtherTasksCheckboxState) {
      this.equipmentForm.get('outOfServiceReason').disable();
    }

    this.cdr.detectChanges();
  }
}
