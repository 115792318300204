import { createSelector, DefaultProjectorFn, MemoizedSelector, Selector } from '@ngrx/store';
import { ApplicationState, IApplicationState, IStoreApiItem } from 'src/app/common';
import { IProductState } from '../models/product.model';

export const productState: Selector<IApplicationState, IProductState> = (state: IApplicationState) =>
  state.product;

export const selectProductName: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<any>,
  DefaultProjectorFn<IStoreApiItem<any>>
> = createSelector(productState, (state: IProductState) => state.productName);
