import { PropertyBag } from 'src/app/common';

export const tableCustomFiltersLayout: string[] = [
  'personalId',
  'firstName',
  'lastName',
  'fireStation',
  'rfidCard',
  'displayLastUpdate',
];

export const readModelSortKeys: PropertyBag = {
  displayLastUpdate: 'lastUpdate',
};

export const tableDefaultSorting: string = 'personalId,ASC';
