import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RequestProductName } from './product-action-types-creators';
import { ProductState } from '../models/product.model';

@Injectable({ providedIn: 'root' })
export class ProductActions {
  constructor(private store: Store<ProductState>) { }

  public requestProductName(): void {
    this.store.dispatch(new RequestProductName());
  }
}
