import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ModalRef, ModalService, ModalSize, ModalVariant } from '@odx/angular/components/modal';
import { takeUntil } from 'rxjs';
import { CommonConstants, DatePickerService, IStoreApiItem } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { ApplicationState } from 'src/app/common/state/models/app.state.model';
import { convertToDateTimeFormat, extractOnlyDate, formatLocaleDate } from 'src/app/common/utils/date-utils/date.utils';
import { getDateFormatFromUserProfile } from 'src/app/common/utils/settings-utils/settings-utils';
import { IMonitoringIncident } from 'src/app/remote-monitoring/models/remote-monitoring.model';
import { IncidentNotificationConstants } from 'src/app/report-center/constants';
import { RemoteMonitoringActions } from '../../state/actions/remote-monitoring.actions';
import { selectClosedIncident } from '../../state/selectors/remote-monitoring.selector';
import { IIncident } from './../../../report-center/models/incident.model';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';

@Component({
  selector: 'ignis-manual-remove-remote-incident',
  templateUrl: './manual-remove-remote-incident.component.html',
  styleUrls: ['./manual-remove-remote-incident.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManualRemoveRemoteIncidentsComponent extends OnDestroyMixin() implements OnChanges, AfterViewInit, OnInit {
  @ViewChild('closeRemoteIncidentModal', { read: TemplateRef })
  public manualRemoveIncidentModal: TemplateRef<any>;
  modalRef: ModalRef | any;
  @Input() openModal: boolean = false;
  @Input() incidentData: IMonitoringIncident;
  @Output() handleCloseManualRemoveIncidentModal: EventEmitter<string> = new EventEmitter();
  closeIncidentForm: FormGroup = new FormGroup({});
  formatDate: string;
  formatLocaleDate: any = formatLocaleDate;
  endTimeError: string;
  successRemoveIncident: boolean;
  minDate: string;
  defaultDate: string;
  errors: any;
  extractOnlyDate: any = extractOnlyDate;
  toggleDatePicker: boolean = false;

  private translateService: TranslateService = inject(TranslateService);
  private modalService: ModalService = inject(ModalService);
  public notificationsService: NotificationsService = inject(NotificationsService);
  public datepickerService: DatePickerService = inject(DatePickerService);
  private remoteMonitoringActions: RemoteMonitoringActions = inject(RemoteMonitoringActions);
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  constructor(public store: Store<ApplicationState>) {
    super();
  }

  ngOnInit(): void {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.openModal && this.openModal) {
      this.closeIncidentForm.get('endTime').setValue(convertToDateTimeFormat(this.incidentData.lastUpdate));
      this.closeIncidentForm.get('incidentAggregateId').setValue(this.incidentData.aggregateId);
      this.minDate = formatLocaleDate(new Date(this.incidentData.lastUpdate));
      this.defaultDate = formatLocaleDate(new Date(this.incidentData.lastUpdate));
      this.openManualRemoveRemoteIncidentModal();
    }
  }

  ngAfterViewInit(): void {
    this.formatDate = getDateFormatFromUserProfile(this.translateService);
  }

  openManualRemoveRemoteIncidentModal(): void {
    this.modalRef = this.modalService.open(this.manualRemoveIncidentModal, {
      id: 'remove-remote-incident',
      size: ModalSize.SMALL,
      variant: ModalVariant.DEFAULT,
      dismissable: false,
      data: 'isOpen',
    });

    setTimeout(() => {
      this.toggleDatePicker = true;
      this.cdr.detectChanges();
    }, 0);
  }

  buildForm(): void {
    this.closeIncidentForm = new FormGroup({
      incidentAggregateId: new FormControl(null, Validators.required),
      endTime: new FormControl(null, Validators.required),
    });
  }

  proceedManualRemove(): void {
    this.remoteMonitoringActions.requestRemoveIncident({ ...this.closeIncidentForm.value }, this.incidentData?.version);
    this.handleCloseManualRemoveIncidentModal.emit('close');
    this.modalRef.close();
    Object.defineProperty(this.modalRef, 'data', { value: 'isClosed', writable: false });

    this.handleManualRemoveIncident();
  }

  handleManualRemoveIncident(): void {
    this.store
      .pipe(select(selectClosedIncident), takeUntil(this.destroy))
      .subscribe((incident: IStoreApiItem<IIncident>) => {
        if (incident.isSuccess) {
          this.successRemoveIncident = true;
          this.closeIncidentForm.reset();
          this.close();
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            IncidentNotificationConstants.notificationCodes.REMOVE_INCIDENT_SUCCESS,
            IncidentNotificationConstants.notificationCodes,
          );

          this.remoteMonitoringActions.requestMonitoringIncidents();
        } else if (incident.errors) {
          this.successRemoveIncident = false;
          this.errors = incident.errors?.error?.code.toString();

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            this.errors,
            IncidentNotificationConstants.notificationCodes,
          );
        }

        this.cdr.detectChanges();
      });
  }

  onEndTimeSelect(date: Date): void {
    const minimumDate: Date = new Date(this.minDate);

    if (date.getTime() < minimumDate.getTime()) {
      this.closeIncidentForm.get('endTime').setValue(convertToDateTimeFormat(minimumDate));
    } else {
      this.closeIncidentForm.get('endTime').setValue(convertToDateTimeFormat(date));
    }

    this.closeIncidentForm.get('endTime').markAsDirty();
  }

  close(): void {
    this.toggleDatePicker = false;
    this.handleCloseManualRemoveIncidentModal.emit('close');
    Object.defineProperty(this.modalRef, 'data', { value: 'isClosed', writable: false });
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();

    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (this.modalRef?.data === 'isOpen') {
      this.modalRef.close();
    }
  }
}
