<button
  type="button"
  [disabled]="
    !activatePlayBtn ||
    this.router.url?.includes(WorkshopModuleRoutes.completeBatchChecklist) ||
    this.router.url?.includes(WorkshopModuleRoutes.cylinder) ||
    isModalOpened ||
    isSubmitDisabled
  "
  odxButton
  size="medium"
  variant="highlight"
  class="blue-bar-btn"
  (click)="onExecuteClick($event)"
  id="bluebar-execute-btn"
  data-test-id="batch_checklist.execute_btn"
  [pTooltip]="batchChecklistButtonTooltipContent"
  tooltipPosition="top"
  positionLeft="5"
  positionTop="-10"
  tooltipStyleClass="footer-tooltip"
>
  <ng-template #batchChecklistButtonTooltipContent>
    @if (!activatePlayBtn && selectedTasksAreNotTheSame && selectedItems?.length > 1) {
      <div data-test-id="batch_checklist.not_save_reason_tooltip">
        {{ 'EQUIPMENT_FOOTER_ITEMS.STR_BATCH_CHECKLIST_NOT_SAME_REASON' | translate }}
      </div>
    } @else if (!activatePlayBtn && !selectedTasksAreNotTheSame && selectedItems?.length > 1) {
      <div data-test-id="batch_checklist.not_the_same_checklist">
        {{ 'EQUIPMENT_FOOTER_ITEMS.STR_BATCH_CHECKLIST_NOT_SAME_CHECKLIST' | translate }}
      </div>
    } @else if (activatePlayBtn || selectedItems?.length < 2) {
      <div data-test-id="batch_checklist.generic_tooltip">
        {{ 'EQUIPMENT_FOOTER_ITEMS.STR_PERFORM_SERVICE_TASK_TOOLTIP' | translate }}
      </div>
    } @else if (
      !accessControlService.equipmentTestingGlobalFeatureToggle && selectedItems?.length && !selectedItem?.hasCheckList
    ) {
      <div data-test-id="batch_checklist.no_checklist_tooltip">
        {{ 'EQUIPMENT_FOOTER_ITEMS.STR_TASK_WITH_NO_CHECKLIST' | translate }}
      </div>
    } @else if (
      selectedItems?.length &&
      selectedItems?.length < 2 &&
      this.accessControlService.equipmentTestingGlobalFeatureToggle &&
      !selectedItem?.isProtectorTestType &&
      !selectedItem?.hasCheckList
    ) {
      <div data-test-id="batch_checklist.no_protector_checklist_tooltip">
        {{ 'EQUIPMENT_FOOTER_ITEMS.STR_NO_PROTECTOR_TYPE_NO_CHECKLIST' | translate }}
      </div>
    }
  </ng-template>

  <odx-icon name="testing" size="inline"></odx-icon>
</button>
