import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { EquipmentUpdateIdsActionTypes } from './equipment-update-ids-action-types';

export class RequestUpdateEquipmentIds implements Action {
  readonly type: EquipmentUpdateIdsActionTypes.REQUEST_UPDATE_EQUIPMENT_IDS =
    EquipmentUpdateIdsActionTypes.REQUEST_UPDATE_EQUIPMENT_IDS;

  constructor(
    public equipmentId: string,
    public fields: { barcode: string; rfid: string },
    public version: number,
  ) {}
}
export class ReceiveUpdateEquipmentIds implements Action {
  readonly type: EquipmentUpdateIdsActionTypes.RECEIVE_UPDATE_EQUIPMENT_IDS =
    EquipmentUpdateIdsActionTypes.RECEIVE_UPDATE_EQUIPMENT_IDS;

  constructor(public payload: any) {}
}
export class ErrorReceiveUpdateEquipmentIds implements Action {
  readonly type: EquipmentUpdateIdsActionTypes.ERROR_RECEIVE_UPDATE_EQUIPMENT_IDS =
    EquipmentUpdateIdsActionTypes.ERROR_RECEIVE_UPDATE_EQUIPMENT_IDS;

  constructor(public payload: HttpErrorResponse) {}
}
export class ResetUpdateEquipmentIds implements Action {
  readonly type: EquipmentUpdateIdsActionTypes.RESET_UPDATE_EQUIPMENT_IDS =
    EquipmentUpdateIdsActionTypes.RESET_UPDATE_EQUIPMENT_IDS;
}
