<button
  [disabled]="isModalOpened"
  (click)="onExport()"
  type="button"
  odxButton
  size="medium"
  variant="highlight"
  class="blue-bar-btn"
  id="bluebar-excel-export-btn"
  data-test-id="service_excel.export_excel_btn"
  [pTooltip]="exportExcelButtonTooltipContent"
  tooltipPosition="top"
  positionLeft="5"
  positionTop="-10"
  tooltipStyleClass="footer-tooltip"
>
  <ng-template #exportExcelButtonTooltipContent>
    <div data-test-id="service_excel.export_excel_tooltip">
      {{ 'SERVICE_EXCEL.STR_DOWNLOAD_SERVICE_RESULT' | translate }}
    </div>
  </ng-template>

  <odx-icon size="inline" name="export-to-excel"></odx-icon>
</button>
