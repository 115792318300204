import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { formatInTimeZone } from 'date-fns-tz';
import { cloneDeep } from 'lodash-es';
import { filter, interval, Subscription, take, takeUntil } from 'rxjs';
import {
  CommonConstants,
  IApplicationState,
  IStoreApiItem,
  ITableColumn,
  ITableSettings,
  ITableSettingsResponse,
  NotificationsService,
  PropertyBag,
  TableHelperComponent,
  TableService,
} from 'src/app/common';
import { getDateFormatFromUserProfile } from 'src/app/common/utils';
import { DeviceConnectionConstants } from 'src/app/device-connection/constants/device-connection.constants';
import {
  IProtectorMigrationErrorPage,
  IProtectorMigrationTableEntry,
} from 'src/app/device-connection/models/protector-migration.model';
import { DeviceConnectionActions } from 'src/app/device-connection/state/actions/device-connection.actions';
import { selectProtectorMigrationErrorPage } from 'src/app/device-connection/state/selectors/device-connection.selector';
import { SettingsActions } from 'src/app/settings';
import { selectTableColumns } from 'src/app/settings/state/selectors/settings.selector';
import { ramProtectorMigrationColumns } from './table-columns';

@Component({
  selector: 'ignis-protector-migration-error-table',
  templateUrl: './protector-migration-error-table.component.html',
  styleUrl: './protector-migration-error-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProtectorMigrationErrorTableComponent
  extends TableHelperComponent
  implements OnChanges, OnInit, OnDestroy
{
  tableName: string = 'protector-migration';
  formatDate: string;
  tableFiltersKey: string = '';
  defaultSorting: string = '';
  tableColumns: ITableColumn[] = ramProtectorMigrationColumns;
  localTableColumns: ITableColumn[] = ramProtectorMigrationColumns;
  migrationStatuses: Partial<PropertyBag> = DeviceConnectionConstants.protectorSoftwareMigrationStatuses;
  errors: IProtectorMigrationTableEntry[];
  intervalToGetMigrationErrorPage: Subscription;
  pageSize: number;

  @Input() migrationStatusOfDevice: string;
  @Input() liveMigrationStatus: string;
  @Input() migrationId: string;
  @Input() pollingMilliseconds: number;

  @Output() emitErrorsToDisplayTable: EventEmitter<boolean> = new EventEmitter();

  deviceConnectionActions: DeviceConnectionActions = inject(DeviceConnectionActions);
  store: Store<IApplicationState> = inject(Store);
  notificationsService: NotificationsService = inject(NotificationsService);
  tableService: TableService = inject(TableService);
  settingsActions: SettingsActions = inject(SettingsActions);
  translateService: TranslateService = inject(TranslateService);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  ngOnChanges(): void {
    this.startToGetMigrationErrorTableData();
  }

  ngOnInit(): void {
    this.formatDate = getDateFormatFromUserProfile(this.translateService);
    this.emitErrorsToDisplayTable.emit(false);
    this.errors = [];

    this.readErrorTableDataFromTheStore();
  }

  startToGetMigrationErrorTableData(): void {
    if (
      this.migrationId &&
      (this.migrationStatusOfDevice === this.migrationStatuses.IN_PROGRESS ||
        this.liveMigrationStatus === this.migrationStatuses.COMPLETED_WITH_ERROR)
    ) {
      this.store
        .pipe(
          select(selectTableColumns),
          filter((state: IStoreApiItem<ITableSettingsResponse>) => state.data !== null && !state.isLoading),
          take(1),
        )
        .subscribe((tableSettings: IStoreApiItem<ITableSettingsResponse>) => {
          const tableSettingsData: ITableSettings = tableSettings.data.body;

          if (tableSettingsData) {
            this.pageSize = tableSettingsData.pageSize;
            this.pageNumber = tableSettingsData.currentPage;
            this.first = this.pageNumber * tableSettingsData.pageSize;
          } else {
            this.pageSize = CommonConstants.defaultTableRowsNumber;
            this.pageNumber = 0;
            this.first = 0;
          }

          if (typeof this.pageNumber === 'number' && this.migrationStatusOfDevice !== this.migrationStatuses.CANCEL) {
            this.deviceConnectionActions.requestProtectorMigrationErrorPage(this.migrationId, {
              page: this.pageNumber?.toString(),
              size: this.pageSize?.toString(),
            });
          }

          this.startIntervalToRequestMigrationErrorPage();
          this.settingsActions.resetTableColumns();

          this.cdr.detectChanges();
        });
    }
  }

  fetchTableData(params: PropertyBag): void {
    this.deviceConnectionActions.requestProtectorMigrationErrorPage(this.migrationId, params);
  }

  readErrorTableDataFromTheStore(): void {
    this.store
      .pipe(
        select(selectProtectorMigrationErrorPage),
        filter((response: IStoreApiItem<IProtectorMigrationErrorPage>) => !response.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((response: IStoreApiItem<IProtectorMigrationErrorPage>) => {
        if (response.data) {
          this.errors = cloneDeep(response.data.entries);
          this.totalRecords = response.data.totalRecords;
          this.totalPages = response.data.totalPages;
          this.pageSize = response.data.pageSize;

          this.processErrorTableData();

          if (this.errors.length > 0 && this.liveMigrationStatus) {
            this.emitErrorsToDisplayTable.emit(true);
          }

          this.getErrorTableDataWithFirstPageIfMigrationIsEndedAndTheErrorsListIsEmpty();
          this.getErrorTableDataWithFirstPageIfMigrationisInProgressAndTheErrorsListIsEmpty();
          this.stopToRequestMigrationErrorDataTableIfMigrationIsEnded();
        }

        this.cdr.detectChanges();
      });
  }

  getErrorTableDataWithFirstPageIfMigrationIsEndedAndTheErrorsListIsEmpty(): void {
    if (this.errors.length < 1 && [this.migrationStatuses.COMPLETED_WITH_ERROR].includes(this.liveMigrationStatus)) {
      this.first = 0;
      this.deviceConnectionActions.requestProtectorMigrationErrorPage(this.migrationId, {
        page: '0',
        size: this.pageSize?.toString(),
      });
    }
  }

  getErrorTableDataWithFirstPageIfMigrationisInProgressAndTheErrorsListIsEmpty(): void {
    if (
      this.errors.length < 1 &&
      ![
        this.migrationStatuses.COMPLETED_WITH_SUCCESS,
        this.migrationStatuses.COMPLETED_WITH_ERROR,
        this.migrationStatuses.CANCELED,
      ].includes(this.liveMigrationStatus)
    ) {
      this.first = 0;
      this.pageNumber = 0;
    }
  }

  stopToRequestMigrationErrorDataTableIfMigrationIsEnded(): void {
    if (
      [
        this.migrationStatuses.COMPLETED_WITH_SUCCESS,
        this.migrationStatuses.COMPLETED_WITH_ERROR,
        this.migrationStatuses.CANCELED,
      ].includes(this.liveMigrationStatus)
    ) {
      this.stopIntervalToRequestMigrationErrorPage();

      this.deviceConnectionActions.resetProtectorMigrationPage();

      if (this.liveMigrationStatus === this.migrationStatuses.CANCELED) {
        this.emitErrorsToDisplayTable.emit(false);
      }
    }
  }

  savePageNumber(event: any): void {
    this.tableService.saveColumnsSettings(
      this.tableColumns,
      this.settingsActions,
      this.tableName,
      this.rows,
      event.page,
    );
  }

  processErrorTableData(): void {
    this.errors.forEach((columnData: IProtectorMigrationTableEntry) => {
      columnData.aggregateId = columnData.identification;

      if (columnData.identification.split('|')[1]) {
        const dateString: string = columnData.identification.split('|')[1];
        const date: Date = new Date(dateString);
        const timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const formattedDate: string = formatInTimeZone(date, timeZone, CommonConstants.TIME_WITH_SECONDS_FORMAT);

        const dateAndTime: string = `${new DatePipe(CommonConstants.DEFAULT_LOCALE).transform(columnData.identification.split('|')[1], this.formatDate)} ${formattedDate}`;

        columnData.identification = `${columnData.identification.split('|')[0]} / ${dateAndTime}`;
      }

      columnData.entryType = this.translateService.instant(
        DeviceConnectionConstants.entryTypeMap[columnData.entryType],
      );

      columnData.errors.forEach((error) => {
        columnData.errors.push(
          this.translateService.instant(DeviceConnectionConstants.migrationErrorsKeyMap[error.code]),
        );
      });

      columnData.errors = columnData.errors.filter((item) => typeof item !== 'object');
      columnData.errorMessage = columnData.errors;
    });
  }

  startIntervalToRequestMigrationErrorPage() {
    if (
      (!this.intervalToGetMigrationErrorPage || this.intervalToGetMigrationErrorPage.closed) &&
      this.migrationStatusOfDevice === this.migrationStatuses.IN_PROGRESS
    ) {
      this.intervalToGetMigrationErrorPage = interval(this.pollingMilliseconds).subscribe(() => {
        this.deviceConnectionActions.requestProtectorMigrationErrorPage(this.migrationId, {
          page: this.pageNumber?.toString(),
          size: this.pageSize?.toString(),
        });
        this.cdr.detectChanges();
      });
    }
  }

  stopIntervalToRequestMigrationErrorPage() {
    if (this.intervalToGetMigrationErrorPage && !this.intervalToGetMigrationErrorPage.closed) {
      this.intervalToGetMigrationErrorPage.unsubscribe();
      this.intervalToGetMigrationErrorPage.remove(this.intervalToGetMigrationErrorPage);
    }
  }

  ngOnDestroy(): void {
    this.errors = [];
    this.liveMigrationStatus = null;
    this.settingsActions.resetTableColumns();
    this.stopIntervalToRequestMigrationErrorPage();
  }
}
