import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IUserPage } from 'src/app/user-management/models/user.model';
import { UserActionTypes } from '../user-action-types';

export class RequestUserExport implements Action {
  readonly type: UserActionTypes.REQUEST_USER_EXPORT = UserActionTypes.REQUEST_USER_EXPORT;

  constructor(public payload?: any) {}
}
export class RequestUserPage implements Action {
  readonly type: UserActionTypes.REQUEST_USER_PAGE = UserActionTypes.REQUEST_USER_PAGE;

  constructor(public payload?: any) {}
}
export class ReceiveUserPage implements Action {
  readonly type: UserActionTypes.RECEIVE_USER_PAGE = UserActionTypes.RECEIVE_USER_PAGE;

  constructor(public payload: IUserPage) {}
}
export class ErrorReceiveUserPage implements Action {
  readonly type: UserActionTypes.ERROR_RECEIVE_USER_PAGE = UserActionTypes.ERROR_RECEIVE_USER_PAGE;

  constructor(public payload: HttpErrorResponse) {}
}
