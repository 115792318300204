export interface PropertyBag {
  [key: string]: string;
}

export interface TableFilters {
  [key: string]: any;
}

export interface IOKTAProfile {
  lastname: string;
  firstname: string;
}
export class OKTAProfile implements IOKTAProfile {
  lastname: string;
  firstname: string;

  constructor();
  constructor(profile: IOKTAProfile);
  constructor(profile?: IOKTAProfile) {
    if (!profile) {
      this.lastname = null;
      this.firstname = null;
    } else {
      this.lastname = profile.lastname;
      this.firstname = profile.firstname;
    }
  }
}

export interface INavigationTabs {
  displayName: string;
  value: string;
  localizedName: string;
}

export interface ITableQuery {
  page?: number;
  sort?: string;
  sortDirection?: string;
  [key: string]: string | number;
}
export interface IEntryModel {
  value: string;
  label?: string;
  localizedName?: string;
  icon?: string;
}

export class EntryModel implements IEntryModel {
  value: string;

  constructor();
  constructor(entry: IEntryModel);
  constructor(entry?: IEntryModel) {
    this.value = entry?.value || null;
  }
}

export interface AlarmsInfo {
  newAlarmsNumber: number;
  severityAlarms: number;
  lastAlertType?: string;
}

export interface IScrollOptions {
  autoHide: boolean;
}

export type IXLXS = (string | string[])[];

export interface IModalState {
  modalName: string;
  open: boolean;
}
