import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppModulesTypes } from '../../models/app-types';
import { IFeatureToggle } from '../../state/features-toggle/models/features-toggle.model';

@Injectable()
export class AccessControlService {
  jwtHelper: JwtHelperService = new JwtHelperService();
  permissions: string[];
  locationRestriction: string;

  private featuresToggle: Map<string, IFeatureToggle> = new Map();

  constructor(public router: Router) {}

  setPermissions(permissions: string[]): string[] {
    this.permissions = permissions;

    return this.permissions;
  }

  public setFeaturesToggle(featuresToggle: IFeatureToggle[]): void {
    featuresToggle.forEach((item: IFeatureToggle) => {
      this.featuresToggle.set(item.feature, item);
    });
  }

  public setRestrictedLocation(location: string): string {
    this.locationRestriction = location;

    return this.locationRestriction;
  }

  public get workshopFeatureToggle(): boolean {
    return this.hasAccess(AppModulesTypes.workshop);
  }

  public get inventoryFeatureToggle(): boolean {
    return this.hasAccess(AppModulesTypes.inventoryPage);
  }

  public get locationFeatureToggle(): boolean {
    return this.hasAccess(AppModulesTypes.location);
  }

  public get logbookFeatureToggle(): boolean {
    return this.hasAccess(AppModulesTypes.logbook);
  }

  public get remoteMonitoringFeatureToggle(): boolean {
    return this.hasAccess(AppModulesTypes.remoteMonitoring);
  }

  public get reportCenterFeatureToggle(): boolean {
    return this.hasAccess(AppModulesTypes.reportCenter);
  }

  public get roleManagementGlobalFeatureToggle(): boolean {
    return this.hasAccess(AppModulesTypes.roleManagementGlobal);
  }

  public get deviceConnectionsManagementFeatureToggle(): boolean {
    return this.hasAccess(AppModulesTypes.deviceConnections);
  }

  public get equipmentTestingGlobalFeatureToggle(): boolean {
    return this.hasAccess(AppModulesTypes.equipmentTestingGlobal);
  }

  public get equipmentBatchChecklistFeatureToggle(): boolean {
    return this.hasAccess(AppModulesTypes.equipmentBatchChecklistTask);
  }

  public get equipmentPrintTaskResultToggle(): boolean {
    return this.hasAccess(AppModulesTypes.equipmentPrintTaskResult);
  }

  public get baWearerFeatureToggle(): boolean {
    return this.hasAccess(AppModulesTypes.baWearer);
  }

  public get ramConnectorAutoUpdateFeatureToggle(): boolean {
    return this.hasAccess(AppModulesTypes.ramConnectorAutoUpdate);
  }

  public get equipmentWorkflowPageFeatureToggle(): boolean {
    return this.hasAccess(AppModulesTypes.equipmentWorkflowPage);
  }

  public get productFruitsFeatureToggle(): boolean {
    return this.hasAccess(AppModulesTypes.productFruits);
  }

  public hasAccess(module: string): boolean {
    if (!this.featuresToggle.size) {
      return false;
    }

    return this.featuresToggle.get(module)?.isEnabled ?? false;
  }

  public checkPermission(module: string): boolean {
    return this.permissions?.some((p: string) => p.includes(module));
  }

  canActivateModule(permission: string): boolean {
    if (this.permissions && !this.permissions.includes(permission)) {
      return this.navigateToOverviewPage();
    }

    return true;
  }

  public navigateToOverviewPage(): boolean {
    this.router.navigate(['']);

    return false;
  }
}
