<h2 class="mb-3 mt-5" odxLayout="12">
  {{ 'EQUIPMENT_TEST.STR_SERVICE_DETAILS' | translate }}
</h2>

<div odxLayout="grid" [formGroup]="form" [readonly]="true">
  <div odxLayout="12 12@tablet 4@desktop" class="custom-form-field-pr-10">
    <odx-form-field variant="horizontal" label="{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_SERVICE' | translate }}">
      <input
        type="text"
        id="selectedTaskName"
        odxFormFieldControl
        data-test-id="equipment_task_documentation.task"
        name="selectedTaskName"
        [ngModel]="selectedTaskName"
        [ngModelOptions]="{ standalone: true }"
        disabled
      />
    </odx-form-field>

    <odx-form-field variant="horizontal" label="{{ 'EQUIPMENT_TASK_RESULTS.STR_SERVICE_DATE' | translate }}">
      <input
        type="text"
        id="date-time"
        odxFormFieldControl
        data-test-id="equipment_task_documentation.service_date"
        name="dateTime"
        [ngModel]="dateTime"
        [ngModelOptions]="{ standalone: true }"
        disabled
      />
    </odx-form-field>

    <odx-form-field
      variant="horizontal"
      label="{{
        (taskResultType === taskResultTypesEnum.Checklist || taskResultType === taskResultTypesEnum.CylinderCharging
          ? 'EQUIPMENT_TASK_RESULTS.STR_TECHNICIAN_DATE'
          : 'INCIDENT_INFORMATION.STR_USER'
        ) | translate
      }}"
    >
      <input
        type="text"
        id="tester"
        odxFormFieldControl
        data-test-id="equipment_task_documentation.tester"
        formControlName="testerName"
      />
    </odx-form-field>
  </div>

  <div
    odxLayout="12 12@tablet 4@desktop"
    class="custom-form-field-pl-10"
    *ngIf="taskResultType === taskResultTypesEnum.Checklist"
  >
    <ignis-service-details-checklist [form]="form"></ignis-service-details-checklist>
  </div>
  <div
    odxLayout="12 12@tablet 4@desktop"
    class="custom-form-field-pl-10"
    *ngIf="taskResultType === taskResultTypesEnum.CylinderCharging"
  >
    <ignis-service-details-cylinder [form]="form"></ignis-service-details-cylinder>
  </div>

  <ignis-service-details-automatic-test
    odxLayout="12 12@tablet 8@desktop"
    class="custom-form-field-pl-10"
    [form]="form"
    *ngIf="taskResultType !== taskResultTypesEnum.Checklist && taskResultType !== taskResultTypesEnum.CylinderCharging"
  >
  </ignis-service-details-automatic-test>
</div>
<div class="mt-4">
  <hr class="divider" />
</div>
