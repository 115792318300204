import { IStoreApiItem, StoreApiItem } from 'src/app/common';
import { IEntriesModel } from './../../../report-center/models/incident.model';

export interface IRemoteMonitoringEntriesState {
  entriesList: IStoreApiItem<IEntriesModel>;
}

export class RemoteMonitoringEntriesState implements IRemoteMonitoringEntriesState {
  entriesList: IStoreApiItem<IEntriesModel> = new StoreApiItem();
}
