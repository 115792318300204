import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { CommonModule } from 'src/app/common';
import { LogbookListComponent } from '../components/logbook-list/logbook-list.component';
import { StatusColumnComponent } from '../components/logbook-list/status-column/status-column.component';
import { LogbookModuleFooterComponent } from '../components/logbook-module-footer/logbook-module-footer.component';
import { LogbookComponent } from '../components/logbook/logbook.component';
import { LogbookService } from '../services/logbook.service';
import { LogbookEffects } from '../state/effects/logbook.effects';
import { logbookReducer } from '../state/reducers/logbook.reducer';
import { PrefilterComponent } from './../components/prefilter/prefilter.component';
import { LogbookRoutingModule } from './logbook-routing.module';

@NgModule({
  declarations: [
    LogbookComponent,
    LogbookListComponent,
    PrefilterComponent,
    StatusColumnComponent,
    LogbookModuleFooterComponent,
  ],
  imports: [
    LogbookRoutingModule,
    StoreModule.forFeature('logbook', logbookReducer),
    EffectsModule.forFeature([LogbookEffects]),
    CommonModule,
    TableModule,
    MultiSelectModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    FormsModule,
    DropdownModule,
    InputTextModule,
    SliderModule,
  ],
  providers: [LogbookService, provideHttpClient(withInterceptorsFromDi())],
})
export class LogbookModule {}
