import { Component, inject, Input } from '@angular/core';
import { CheckingSettingsModalsService, GenericFooterItemsComponent } from 'src/app/common';
import { DetectChangesInFormsService } from '../../../../../common/services/detect-changes/detect-changes-in-forms.service';

@Component({
  selector: 'ignis-create-update-address-book-view-footer',
  templateUrl: './create-update-address-book-view-footer.component.html',
})
export class CreateUpdateAddressBookViewFooterComponent<T> extends GenericFooterItemsComponent<T> {
  @Input() isModalOpened: boolean;
  @Input() isSubmitDisabled: boolean = false;

  detectChangesInFormsService: DetectChangesInFormsService = inject(DetectChangesInFormsService);

  constructor(private checkSettingsModal: CheckingSettingsModalsService) {
    super();

    this.checkSettingsModal.checkIfSettingsModalIsOpen().subscribe((isModalOpened: boolean) => {
      this.isModalOpened = isModalOpened;
      this.cdr.detectChanges();
    });
  }
}
