<div
  class="notes-widget"
  [ngClass]="{
    'slide-in-right': openNotesWidget,
    slideOutRight: closeNotesWidget,
    maximizedWidget: widgetWidth > 395,
  }"
  *ngIf="openNotesWidget"
  [style.width.px]="widgetWidth"
  id="slide"
>
  <div class="header">
    <span>{{ 'REMOTE_MONITORING.STR_NOTES_WIDGET_TITLE' | translate }}</span>
    <odx-icon
      size="inline"
      name="close"
      class="close-widget"
      (click)="closeWidget()"
      data-test-id="remote_monitoring.close_notes_widget"
    ></odx-icon>
    <odx-icon
      size="inline"
      name="zoom-document-width"
      class="expand-widget"
      (click)="toggleWidgetWidth()"
      data-test-id="remote_monitoring.toggle_notes_widget"
    ></odx-icon>
  </div>

  <div class="notes-list">
    <div
      class="notes-section news"
      *ngIf="newNotes?.length > 0"
      [ngClass]="{ 'section-full-height': earlierNotes?.length < 1 }"
      [style.height]="newSectionHeight"
    >
      <span (click)="toggleNewSection()" data-test-id="remote_monitoring.toggle_new_notes_section">
        {{ 'REMOTE_MONITORING.STR_NOTES_WIDGET_NEW_SECTION' | translate }}
        <odx-icon
          size="inline"
          name="arrow1-up"
          class="toggle-notes-section"
          [currentAppTheme]="currentAppTheme"
          *ngIf="displayNewSection; else arrowDownNew"
        ></odx-icon>
        <ng-template #arrowDownNew>
          <odx-icon size="inline" name="arrow1-down" class="toggle-notes-section"></odx-icon>
        </ng-template>
      </span>
      <ignis-notes-list
        *ngIf="displayNewSection"
        [notes]="newNotes"
        [currentAppTheme]="currentAppTheme"
        [toggleWidget]="toggleWidget"
        [formatDate]="formatDate"
        (handleNoteImageUrl)="openImagePopUp($event)"
      ></ignis-notes-list>
    </div>

    <div class="notes-safety-line" *ngIf="newNotes?.length > 0"></div>

    <div
      class="notes-section earlier"
      *ngIf="earlierNotes?.length > 0"
      [ngClass]="{ 'section-full-height': newNotes?.length < 1 }"
      [style.height]="earlierSectionHeight"
    >
      <span (click)="toggleEarlierSection()" data-test-id="remote_monitoring.toggle_earlier_notes_section">
        {{ 'REMOTE_MONITORING.STR_NOTES_WIDGET_NEW_EARLIER' | translate }}
        <odx-icon
          name="arrow1-up"
          class="toggle-notes-section"
          [currentAppTheme]="currentAppTheme"
          *ngIf="displayEarlierSection; else arrowDownEarlier"
        ></odx-icon>
        <ng-template #arrowDownEarlier>
          <odx-icon name="arrow1-down" class="toggle-notes-section"></odx-icon>
        </ng-template>
      </span>
      <ignis-notes-list
        *ngIf="displayEarlierSection"
        [notes]="earlierNotes"
        [currentAppTheme]="currentAppTheme"
        [toggleWidget]="toggleWidget"
        [formatDate]="formatDate"
        (handleNoteImageUrl)="openImagePopUp($event)"
      ></ignis-notes-list>
    </div>
  </div>

  <div class="add-notes">
    <button
      odxButton
      variant="highlight"
      (click)="displayAddNote()"
      *ngIf="!showAddNote"
      data-test-id="remote_monitoring.display_add_note_section"
    >
      {{ 'REMOTE_MONITORING.STR_ADD_NOTES' | translate }}
    </button>

    <div class="new-note-input-group" *ngIf="showAddNote">
      <form [formGroup]="newNoteForm" (ngSubmit)="addNote()">
        <div class="odx-form-field--horizontal odx-form-field">
          <div class="odx-form-field__inner">
            <div class="odx-form-field__control">
              <input
                type="text"
                ignisDisableAutocomplete
                data-test-id="remove_monitoring.note_text"
                formControlName="noteText"
                class="odx-form-field-control"
                ngDefaultControl
                (keyup.enter)="newNoteForm.get('noteText').value?.length > 0 ? addNote() : ''"
                placeholder="{{ 'REMOTE_MONITORING.STR_NOTE_PLACEHOLDER' | translate }}"
              />
            </div>
          </div>
        </div>

        <button
          odxButton
          variant="highlight"
          type="button"
          *ngIf="newNoteForm.get('noteText').value?.length > 0; else closeAddNote"
          (click)="addNote()"
          data-test-id="remote_monitoring.add_note_btn"
        >
          <odx-icon size="inline" name="arrow1-right"></odx-icon>
        </button>

        <ng-template #closeAddNote>
          <button
            odxButton
            type="button"
            class="close-btn"
            variant="secondary"
            (click)="hideAddNote()"
            data-test-id="remote_monitoring.hide_add_note_btn"
          >
            <odx-icon size="inline" name="close"></odx-icon>
          </button>
        </ng-template>
      </form>
    </div>
  </div>

  <ignis-image-modal
    [openModal]="openImageModal"
    [image]="imageUrl"
    (handleCloseNoteImageModal)="closeNoteImageModal()"
  ></ignis-image-modal>
</div>
