import { PropertyBag } from 'src/app/common/models/common.model';
import { RemoteMonitoringModuleRoutes } from 'src/app/remote-monitoring/constants/remote-monitoring-module-routes.constants';

export const ReportCenterModuleRoutes: PropertyBag = {
  remoteMonitoring: RemoteMonitoringModuleRoutes.remoteMonitoring,
  reportCenter: 'report-center',
  update: 'update',
  eventMarker: 'event-marker',
  createEventMarker: 'event-marker/create',
  updateEventMarker: 'event-marker/update',
  uploadMediaFile: 'upload-media-file',
};
