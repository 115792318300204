import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  ErrorReceiveTableExport,
  ReceiveTableExport,
} from 'src/app/common/state/export/actions/export-action-types-creators';
import { IUser } from '../../models/user.model';
import {
  ErrorAddUser,
  ErrorReceiveUserById,
  ErrorReceiveUserDelete,
  ErrorReceiveUsersPersonalInfoByIds,
  ErrorUpdateUser,
  ReceiveAddUser,
  ReceiveUpdateUser,
  ReceiveUserById,
  ReceiveUserDelete,
  ReceiveUsersPersonalInfoByIds,
} from '../actions/user-action-types-creators/user-crud-action-types-creators';
import {
  ErrorReceiveUserPage,
  ReceiveUserPage,
} from '../actions/user-action-types-creators/user-page-action-types-creators';
import {
  ErrorReceiveUserRoleList,
  ReceiveUserRoleList,
} from '../actions/user-action-types-creators/user-role-list-action-types-creators';
import {
  INewUser,
  IUpdateUser,
  IUserPage,
  IUserPersonalInfo,
  IUserRole,
  UserPage,
  UserRole,
} from './../../models/user.model';
import { UserService } from './../../services/user.service';
import { UserActionTypes } from './../actions/user-action-types';
import {
  ErrorReceiveUploadCSVFile,
  ReceiveUploadCSVFile,
} from './../actions/user-action-types-creators/user-upload-csv-file-action-types-creators';

@Injectable()
export class UserEffects {
  constructor(private userService: UserService) {}

  requestUsersPage$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(UserActionTypes.REQUEST_USER_PAGE),
      switchMap((params: any) =>
        this.userService.getUserPage(params.payload).pipe(
          map((usersPage: IUserPage) => new ReceiveUserPage(new UserPage(usersPage))),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUserPage(error))),
        ),
      ),
    );
  });

  requestUsersExport$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(UserActionTypes.REQUEST_USER_EXPORT),
      switchMap((params: any) =>
        this.userService.getUserPage(params.payload).pipe(
          map((usersPage: IUserPage) => new ReceiveTableExport(new UserPage(usersPage))),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveTableExport(error))),
        ),
      ),
    );
  });

  requestUserById$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(UserActionTypes.REQUEST_USER_BY_ID),
      switchMap((aggregateId: string) =>
        this.userService.getUserById(aggregateId).pipe(
          map((user: { body: IUser }) => new ReceiveUserById(user.body)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUserById(error))),
        ),
      ),
    );
  });

  requestUsersPersonalInfoByIds$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(UserActionTypes.REQUEST_USERS_PERSONAL_INFO_BY_IDS),
      switchMap((ids: string[] | any) =>
        this.userService.getUsersPersonalInfo(ids.payload).pipe(
          map((usersPersonalInfo: IUserPersonalInfo[]) => new ReceiveUsersPersonalInfoByIds(usersPersonalInfo)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUsersPersonalInfoByIds(error))),
        ),
      ),
    );
  });

  requestAddUser$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(UserActionTypes.REQUEST_ADD_USER),
      switchMap((newUser: INewUser) =>
        this.userService.addUser(newUser).pipe(
          map((user: IUser) => new ReceiveAddUser(user)),
          catchError((error: HttpErrorResponse) => of(new ErrorAddUser(error))),
        ),
      ),
    );
  });

  requestUpdateUser$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(UserActionTypes.REQUEST_UPDATE_USER),
      switchMap((updateUser: IUpdateUser) =>
        this.userService.updateUser(updateUser).pipe(
          map((user: IUpdateUser) => new ReceiveUpdateUser(user)),
          catchError((error: HttpErrorResponse) => of(new ErrorUpdateUser(error))),
        ),
      ),
    );
  });

  requestRoleList$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(UserActionTypes.REQUEST_USER_ROLE_LIST),
      switchMap(() =>
        this.userService.getUserRoleList().pipe(
          map(
            (roleList: IUserRole[]) =>
              new ReceiveUserRoleList(roleList.map((userRole: IUserRole) => new UserRole(userRole))),
          ),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUserRoleList(error))),
        ),
      ),
    );
  });

  requestUserDelete$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(UserActionTypes.REQUEST_USER_DELETE),
      switchMap((user: IUser) =>
        this.userService.deleteUser(user).pipe(
          map((deletedUser: IUser) => new ReceiveUserDelete(deletedUser)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUserDelete(error))),
        ),
      ),
    );
  });

  requestUploadCSVFile$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(UserActionTypes.REQUEST_UPLOAD_CSV_FILE),
      switchMap((file: any) =>
        this.userService.uploadCSVFile(file).pipe(
          map((response: any) => new ReceiveUploadCSVFile(response)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUploadCSVFile(error))),
        ),
      ),
    );
  });
}
