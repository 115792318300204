import { ChangeDetectorRef, Component, EventEmitter, OnChanges, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CheckingSettingsModalsService, GenericFooterItemsComponent } from 'src/app/common';
import { AccessControlService } from 'src/app/root/services/access-control/access-control.service';

@Component({
  selector: 'ignis-logbook-module-footer',
  templateUrl: './logbook-module-footer.component.html',
})
export class LogbookModuleFooterComponent<T> extends GenericFooterItemsComponent<T> implements OnChanges {
  @Output() handlePrefilter: EventEmitter<void> = new EventEmitter<void>();
  @Output() navigateToReportCenter: EventEmitter<void> = new EventEmitter<void>();

  permissions: string[] = [];
  isModalOpened: boolean;

  constructor(
    public router: Router,
    public translateService: TranslateService,
    public accessControlService: AccessControlService,
    private checkSettingsModal: CheckingSettingsModalsService,
    public cdr: ChangeDetectorRef,
  ) {
    super();

    this.checkSettingsModal.checkIfSettingsModalIsOpen().subscribe((isModalOpened: boolean) => {
      this.isModalOpened = isModalOpened;
      this.cdr.detectChanges();
    });
  }

  ngOnChanges(): void {
    this.permissions = this.accessControlService.permissions;
  }

  onNavigateToReportCenterClick(e: Event): void {
    e?.stopPropagation();
    this.navigateToReportCenter.emit();
  }
}
