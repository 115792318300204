import { IStoreApiItem, StoreApiItem } from 'src/app/common/models/store-api-item.model';
import {
  IEquipment,
  ISaveTestResults,
  ITestParameters,
  ITestResultDetails,
  ITestResultPage,
  ITestStartResponse,
  ITestStatus,
} from '../../models';

export interface ITestingState {
  saveTest: IStoreApiItem<ISaveTestResults>;
  startTest: IStoreApiItem<ITestStartResponse>;
  workflowAsset: IStoreApiItem<IEquipment>;
  testResults: IStoreApiItem<ITestResultDetails>;
  testParameters: IStoreApiItem<ITestParameters>;
  isTestingInProgress: IStoreApiItem<ITestStatus>;
  testResultsPage: IStoreApiItem<ITestResultPage>;
  testResultsById: IStoreApiItem<ITestResultDetails>;
}

export class TestingState {
  saveTest: IStoreApiItem<ISaveTestResults> = new StoreApiItem<ISaveTestResults>();
  startTest: IStoreApiItem<ITestStartResponse> = new StoreApiItem<ITestStartResponse>();
  workflowAsset: IStoreApiItem<IEquipment> = new StoreApiItem<IEquipment>();
  isTestingInProgress: IStoreApiItem<ITestStatus> = new StoreApiItem<ITestStatus>();
  testParameters: IStoreApiItem<ITestParameters> = new StoreApiItem<ITestParameters>();
  testResultsPage: IStoreApiItem<ITestResultPage> = new StoreApiItem<ITestResultPage>();
  testResults: IStoreApiItem<ITestResultDetails> = new StoreApiItem<ITestResultDetails>();
  testResultsById: IStoreApiItem<ITestResultDetails> = new StoreApiItem<ITestResultDetails>();
}
