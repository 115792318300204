import { IEntryModel, PropertyBag } from 'src/app/common/models/common.model';
import * as entryTypeList from './entryTypeList.json';

export class LogbookConstants {
  public static readonly logbookEntries: {
    entryReasonList: IEntryModel[];
  } = entryTypeList;

  public static readonly statusList: { name: string; value: string }[] = [
    { name: 'ERROR', value: 'ERROR' },
    { name: 'WARNING', value: 'WARNING' },
    { name: 'OK', value: 'OK' },
    { name: 'SPECIAL_EXPOSURE', value: 'SPECIAL_EXPOSURE' },
  ];
  public static readonly readModelSortKeys: PropertyBag = {
    date: 'dateRange.fromTime',
    equipmentId: 'equipmentInfo.equipmentSerialNumber',
    startPressure: 'usageData.startPressure',
    endPressure: 'usageData.endPressure',
    reason: 'usageData.usageType',
    incidentNumber: 'incidentData.incidentNumber',
    usageDuration: 'usageData.usageDuration',
    name: 'person.fullName',
    personalId: 'person.merlinUid',
    status: 'statuses',
  };
  public static readonly translationKeyMap: PropertyBag = {
    equipmentId: 'LOGBOOK_TABLE.STR_SCBA_ID',
    date: 'LOGBOOK_TABLE.STR_DATE_TIME',
    startPressure: 'LOGBOOK_TABLE.STR_START_CYLINDER_PRESSURE_EXCEL',
    endPressure: 'LOGBOOK_TABLE.STR_END_CYLINDER_PRESSURE_EXCEL',
    reason: 'LOGBOOK_TABLE.STR_EVENT',
    usageDuration: 'LOGBOOK_TABLE.STR_USAGE_DURATION',
    name: 'LOGBOOK_TABLE.STR_USERNAME',
    personalId: 'LOGBOOK_TABLE.STR_PERSONAL_ID',
    incidentNumber: 'LOGBOOK_TABLE.STR_INCIDENT_NUMBER',
    status: 'LOGBOOK_TABLE.STR_STATUS',
  };

  public static readonly preFilterKey: string = 'openPreFilter';
}
