import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RequestUpdateEquipmentIds, ResetUpdateEquipmentIds } from './equipment-update-ids-action-types-creators';
import { IEquipmentState } from '../../models';

@Injectable({ providedIn: 'root' })
export class EquipmentUpdateIdsActions {
  constructor(private store: Store<IEquipmentState>) {}

  public requestUpdateEquipmentIds(
    equipmentId: string,
    fields: { barcode: string; rfid: string },
    version: number,
  ): void {
    this.store.dispatch(new RequestUpdateEquipmentIds(equipmentId, fields, version));
  }

  public resetUpdateEquipmentIds(): void {
    this.store.dispatch(new ResetUpdateEquipmentIds());
  }
}
