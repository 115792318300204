<div class="blue-bar-items">
  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    (click)="onFilterClick($event)"
    [disabled]="
      (selectedMerge && selectedItem) || isModalOpened || router.url.includes(RemoteMonitoringModuleRoutes.filter)
    "
    id="filter-incidents-btn"
    data-test-id="remote_monitoring_footer.filter_btn"
    [pTooltip]="filterButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #filterButtonTooltipContent>
      @if (filterParams) {
        <div data-test-id="remote_monitoring_footer.filter_tooltip">
          {{ !appearedNewIncidents ? filterButtonTooltip : filterExclamationButtonTooltip }}
        </div>
      } @else {
        <div data-test-id="remote_monitoring_footer.filter_tooltip">
          {{ 'FILTER_BUTTON_TOOLTIP.STR_FILTER_INCIDENTS' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon
      size="inline"
      [name]="
        !filterParams
          ? 'filter'
          : filterParams && appearedNewIncidents
            ? 'filter-exclamation-icon'
            : filterParams
              ? 'filter-settings-icon'
              : ''
      "
    ></odx-icon>

    <span
      [ngClass]="
        !filterParams
          ? 'filter'
          : filterParams && appearedNewIncidents
            ? 'filter-exclamation-icon'
            : filterParams
              ? 'filter-settings-icon'
              : ''
      "
    ></span>
  </button>

  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    id="merge-incidents-btn"
    data-test-id="remote_monitoring_footer.merge_incidents_btn"
    (click)="onMergeIncidentsClick($event)"
    [disabled]="isModalOpened || router.url.includes(RemoteMonitoringModuleRoutes.filter)"
    [ngClass]="{ 'disabled-merge-btn-color': isModalOpened }"
    [pTooltip]="mergeButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #mergeButtonTooltipContent>
      @if (!selectedItem) {
        <div data-test-id="remote_monitoring_footer.merge_incidents_not_selected_tooltip">
          {{ 'REMOTE_MONITORING.STR_MERGE_INCIDENT_NOT_SELECTED_TOOLTIP' | translate }}
        </div>
      } @else if (selectedItem && !mergeMode) {
        <div data-test-id="remote_monitoring_footer.merge_incidents_first_selected_tooltip">
          {{ 'REMOTE_MONITORING.STR_BEGIN_MERGE_INCIDENT_TOOLTIP' | translate }}
        </div>
      } @else if (selectedItem && mergeMode) {
        <div data-test-id="remote_monitoring_footer.merge_incidents_second_selected_tooltip">
          {{ 'REMOTE_MONITORING.STR_MERGE_INCIDENT_SELECT_SECOND_TOOLTIP' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon size="inline" name="composition-edit"></odx-icon>
  </button>

  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    id="close-incident-btn"
    data-test-id="remote_monitoring_footer.close_incident_btn"
    (click)="onCloseIncidentClick($event)"
    [disabled]="!selectedToClose || isModalOpened"
    [pTooltip]="closeIncidentButtonTooltipContent"
    tooltipPosition="top"
    positionTop="-10"
  >
    <ng-template #closeIncidentButtonTooltipContent>
      <div data-test-id="remote_monitoring_footer.close_incident_tooltip">
        {{ 'REMOVE_REMOTE_INCIDENT.STR_CLOSE_INCIDENT' | translate }}
      </div>
    </ng-template>

    <odx-icon size="inline" name="close"></odx-icon>
  </button>
</div>
