import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { CheckingSettingsModalsService, GenericFooterItemsComponent } from 'src/app/common';
import { DetectChangesInFormsService } from '../../../../common/services/detect-changes/detect-changes-in-forms.service';

@Component({
  selector: 'ignis-organization-settings-footer-view',
  templateUrl: './organization-settings-footer-view.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class OrganizationSettingsFooterViewComponent<T> extends GenericFooterItemsComponent<T> {
  @Input() isModalOpened: boolean;
  @Input() isSubmitDisabled: boolean;

  @Output() handleSubmit: EventEmitter<void> = new EventEmitter();

  detectChangesInFormsService: DetectChangesInFormsService = inject(DetectChangesInFormsService);
  checkSettingsModal: CheckingSettingsModalsService = inject(CheckingSettingsModalsService);

  constructor() {
    super();

    this.checkSettingsModal.checkIfSettingsModalIsOpen().subscribe((isModalOpened: boolean) => {
      this.isModalOpened = isModalOpened;
      this.cdr.detectChanges();
    });
  }
}
