import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DropdownService } from 'src/app/common';
import { CommonConstants } from 'src/app/common/constants/common.constants';
import { IEntryModel } from 'src/app/common/models/common.model';
import { IncidentNotificationConstants } from 'src/app/report-center';
import { ReportCenterConstants } from 'src/app/report-center/constants/report-center.constants';
import { IEventMarker } from 'src/app/report-center/models/incident.model';
import {
  calculateDurationWithoutSeconds,
  convertToDateTimeFormat,
  formatLocaleDate,
} from '../../../../../../common/utils/date-utils/date.utils';

@Component({
  selector: 'ignis-event-marker-form',
  templateUrl: './event-marker-form.component.html',
  styleUrls: ['./event-marker-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventMarkerFormComponent implements OnInit, OnChanges {
  @Input() eventMarkerForm: FormGroup;
  @Input() selectedEventMarker: IEventMarker;
  @Input() isLoading: boolean;
  @Input() isSubmitted: boolean;
  @Input() errors: string;
  @Input() formatDate: string;
  @Input() currentIncident: any;
  @Input() entries: IEntryModel[];
  @Input() modalType: string;

  eventMarkerEntries: IEntryModel[];
  ecpsList: any[];
  defaultOption: any;
  showNameTooltip: boolean = false;
  startTimeError: string;
  endTimeError: string;

  minStartDate: Date;
  maxStartDate: Date;
  minEndDate: Date;
  maxEndDate: Date;
  initialMinDate: string;
  initialMaxDate: string;

  incidentErrors: any = IncidentNotificationConstants.notificationCodes;
  formatLocaleDate: any = formatLocaleDate;
  modalTypes: any = CommonConstants.modalType;
  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;

  constructor(
    private translateService: TranslateService,
    public dropdownService: DropdownService,
  ) {}

  ngOnInit(): void {
    this.initialMinDate = new Date(this.currentIncident?.startTime).toISOString();
    this.initialMaxDate = new Date(this.currentIncident?.endTime).toISOString();

    this.ecpsList = JSON.parse(localStorage.getItem(ReportCenterConstants.currentIncidentAnalysis))?.ecps;
    this.ecpsList?.forEach((elem: any) => {
      elem.appId = elem.appId || '-';
      elem.label = elem.name || elem.appId;
    });

    this.populateECPField();
  }

  ngOnChanges(): void {
    if (this.entries) {
      this.eventMarkerEntries = this.entries.map((entry: IEntryModel) => ({
        ...entry,
        label: this.translateService.instant(
          ReportCenterConstants.incidentEntries.eventMarkerTypes.find((t: IEntryModel) => t.value === entry.value)
            ?.localizedName || entry.value,
        ),
      }));
    }

    if (this.errors) {
      if (this.errors === this.incidentErrors.START_DATE_OUTSIDE_TIMELINE.value) {
        this.startTimeError = this.errors.toString();
      } else if (
        this.errors === this.incidentErrors.END_DATE_OUTSIDE_TIMELINE.value ||
        this.errors === this.incidentErrors.END_DATE_BEFORE_START_DATE.value
      ) {
        this.endTimeError = this.errors.toString();
      }
    }
  }

  populateECPField(): void {
    if (this.modalType === this.modalTypes.CREATE) {
      this.eventMarkerForm.get('ecp').setValue(this.ecpsList[0].appId);
    }
  }

  nameChanged(): void {
    this.eventMarkerForm.controls.name.setValue(this.eventMarkerForm.value.name.trim());
  }

  onStartDateSelect(date: Date): void {
    if (this.eventMarkerForm.get('endTime').value) {
      const endTime: Date = new Date(formatLocaleDate(this.eventMarkerForm.get('endTime').value));
      const diff: number = endTime.getTime() - date.getTime();

      this.eventMarkerForm.get('duration').setValue(calculateDurationWithoutSeconds(Math.floor(diff / 1000)));
    }

    const startTime: Date = new Date(date.setSeconds(0));

    this.eventMarkerForm.get('startTime').setValue(convertToDateTimeFormat(startTime));
    this.eventMarkerForm.get('startTime').markAsDirty();
    this.startTimeError = null;
  }

  onEndDateSelect(date: Date): void {
    if (this.eventMarkerForm.get('startTime').value) {
      const startTime: Date = new Date(formatLocaleDate(this.eventMarkerForm.get('startTime').value));
      const diff: number = date.getTime() - startTime.getTime();

      this.eventMarkerForm.get('duration').setValue(calculateDurationWithoutSeconds(Math.floor(diff / 1000)));
    }

    this.eventMarkerForm.get('endTime').setValue(convertToDateTimeFormat(date));
    this.eventMarkerForm.get('endTime').markAsDirty();
    this.endTimeError = null;
  }

  getNextMinuteDate(date: Date): any {
    return new Date(date.setTime(date.getTime() + 1000 * 60));
  }
}
