import { PropertyBag } from 'src/app/common';

export class WorkshopExportConstants {
  public static readonly equipmentInformation: PropertyBag = {
    identifier: 'EQUIPMENT_TEST.STR_EQUIPMENT_IDENTIFICATION',
    barcode: 'EQUIPMENT_TEST.STR_EQUIPMENT_BARCODE',
    rfid: 'EQUIPMENT_TEST.STR_EQUIPMENT_RFID',
    serialNo: 'EQUIPMENT_TEST.STR_EQUIPMENT_SERIAL_NO',
    equipmentType: 'EQUIPMENT_TEST.STR_EQUIPMENT_TYPE',
    equipmentModel: 'EQUIPMENT_TEST.STR_EQUIPMENT_MODEL',
  };

  public static readonly results: PropertyBag = {
    status: 'EQUIPMENT_TASK_RESULTS.STR_EQUIPMENT_STATUS',
    airPressure: 'EQUIPMENT_TASK_RESULTS.STR_AIR_PRESSURE',
    comment: 'EQUIPMENT_TASK_RESULTS.STR_COMMENT',
    outOfServiceDate: 'EQUIPMENT_TASK_RESULTS.STR_OUT_OF_SERVICE_DATE',
    outOfServiceReason: 'EQUIPMENT_TASK_RESULTS.STR_OUT_OF_SERVICE_REASON',
    newBarcode: 'EQUIPMENT_TASK_RESULTS.STR_NEW_BARCODE',
    newRfid: 'EQUIPMENT_TASK_RESULTS.STR_NEW_RFID',
  };

  public static readonly equipmentServiceDetails: PropertyBag = {
    service: 'EQUIPMENT_TASK_RESULTS.STR_TEST_SERVICE',
    dateTime: 'EQUIPMENT_TASK_RESULTS.STR_SERVICE_DATE',
    technicianName: 'EQUIPMENT_TASK_RESULTS.STR_TECHNICIAN_DATE',
    userName: 'INCIDENT_INFORMATION.STR_USER',
    checklistName: 'EQUIPMENT_TASK_RESULTS.STR_CHECKLIST_NAME',
    locationPath: 'EQUIPMENT_TASK_RESULTS.STR_LOCATION',
    description: 'EQUIPMENT_TASK_RESULTS.STR_CHECKLIST_DESCRIPTION',
    testDevice: 'EQUIPMENT_TASK_RESULTS.STR_TEST_DEVICE',
    testProcedure: 'EQUIPMENT_TASK_RESULTS.STR_TEST_PROCEDURE',
    testType: 'EQUIPMENT_TASK_RESULTS.STR_TEST_TYPE',
    version: 'EQUIPMENT_TASK_RESULTS.STR_SOFTWARE_VERSION',
    serialNumber: 'EQUIPMENT_TASK_RESULTS.STR_SERIAL_NUMBER',
    pressure: 'EQUIPMENT_TASK_RESULTS.STR_PRESSURE',
  };

  public static readonly serviceIncluded: PropertyBag = {
    description: 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_DESCRIPTION',
    lastOld: 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_LAST_OLD',
    nextOld: 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_NEXT_OLD',
    lastNew: 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_LAST_NEW',
    nextNew: 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_NEXT_NEW',
  };

  public static readonly automaticValues: PropertyBag = {
    description: 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_DESCRIPTION',
    assessment: 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_ASSESSMENT',
    value: 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_VALUE',
    minValue: 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_MIN_VALUE',
    maxValue: 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_MAX_VALUE',
    unit: 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_UNIT',
    parameters: 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_PARAMETERS',
  };

  public static readonly checklistHeaders: PropertyBag = {
    value: 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_VALUE',
    minValue: 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_MIN_VALUE',
    maxValue: 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_MAX_VALUE',
    unit: 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_UNIT',
  };
}
