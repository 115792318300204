import { Location } from '@angular/common';
import { Component, HostListener, inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DefaultProjectorFn, MemoizedSelector, select, Store } from '@ngrx/store';
import { combineLatest, filter, map, Observable, take, takeUntil } from 'rxjs';
import { OnDestroyMixin } from 'src/app/common/mixins';
import { INotificationMessage } from 'src/app/common/state/notifications/models/notification.model';
import { DeviceConnectionService } from 'src/app/device-connection/services/device-connection.service';
import { DeviceConnectionActions } from 'src/app/device-connection/state/actions/device-connection.actions';
import { AppUserPermissionList } from 'src/app/root/models';
import { AccessControlService } from 'src/app/root/services';
import { selectDeviceConnectionAssignedFireStations } from '../../../device-connection/state/selectors/device-connection.selector';
import { BAWearerConstants, BAWearerModuleRoutes, BAWearerNotificationConstants } from '../../constants';
import { IBAWearer } from '../../models';
import { BAWearerActions } from '../../state/actions/ba-wearer.actions';

import {
  ApplicationState,
  CommonConstants,
  IApplicationState,
  IFireStationList,
  IScrollOptions,
  IStoreApiItem,
  IStoreApiList,
  NotificationsService,
  PropertyBag,
} from 'src/app/common';

import {
  selectBAWearer,
  selectFireStationList,
  selectNewBAWearer,
  selectUpdatedBAWearer,
} from '../../state/selectors/ba-wearer.selector';

@Component({
  selector: 'ignis-create-update-ba-wearer',
  templateUrl: './create-update-ba-wearer.component.html',
  styleUrl: './create-update-ba-wearer.component.scss',
})
export class CreateUpdateBaWearerComponent extends OnDestroyMixin() implements OnInit {
  activeTab: string = BAWearerConstants.createEditModalTabs.PERSONAL_DATA;
  tabs: PropertyBag = BAWearerConstants.createEditModalTabs;
  linkToNavigate: string;
  modalType: string;
  isLoading$: Observable<boolean>;
  isLoadingForm: boolean = false;
  isConfirmCloseModalOpen: boolean = false;
  httpCustomErrorCode: number | string;
  isSubmitted: boolean = false;
  isSubmitting: Observable<boolean>;
  fireStations: string[];
  scrollbarOptions: IScrollOptions = CommonConstants.scrollbarOptions;

  baWearerForm: FormGroup = new FormGroup({
    personalId: new FormControl(null, [Validators.required]),
    firstName: new FormControl(null, [Validators.required]),
    lastName: new FormControl(null, [Validators.required]),
    fireStation: new FormControl(null),
    rfidCard: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(255)]),
    version: new FormControl(null),
    aggregateId: new FormControl(null),
  });

  location: Location = inject(Location);
  router: Router = inject(Router);
  route: ActivatedRoute = inject(ActivatedRoute);
  store: Store<IApplicationState> = inject(Store);
  baWearerActions: BAWearerActions = inject(BAWearerActions);
  deviceConnectionActions: DeviceConnectionActions = inject(DeviceConnectionActions);
  notificationsService: NotificationsService = inject(NotificationsService);
  deviceConnectionService: DeviceConnectionService = inject(DeviceConnectionService);
  accessControlService: AccessControlService = inject(AccessControlService);

  constructor() {
    super();

    this.router.events?.subscribe((event: any) => {
      this.linkToNavigate = event.url;
    });
  }

  ngOnInit(): void {
    this.checkFormValuesChanges();
    this.submittingStatus();
    this.loadingStatus();

    this.baWearerActions.requestFireStationList();
    this.requestFireStationsFromDeviceConnection();

    this.readFireStationListFromStore();
    this.populateForm();
  }

  @HostListener(CommonConstants.beforeUnloadWindowEvent, ['$event'])
  handleBeforeUnload($event: BeforeUnloadEvent): void {
    if (this.hasUnsavedData()) {
      $event.returnValue = this.hasUnsavedData();
    }
  }

  canDeactivate(): boolean {
    if (this.hasUnsavedData()) {
      this.confirmCloseModalOpen();

      return false;
    }

    this.isConfirmCloseModalOpen = false;

    return true;
  }

  requestFireStationsFromDeviceConnection(): void {
    const hasDeviceConnectionAccess: boolean = this.accessControlService.checkPermission(
      AppUserPermissionList.deviceConnections,
    );
    const isDeviceConnectionToggled: boolean = this.accessControlService.deviceConnectionsManagementFeatureToggle;

    if (hasDeviceConnectionAccess && isDeviceConnectionToggled) {
      this.deviceConnectionActions.requestDeviceConnectionAssignedFireStations();
    }
  }

  checkFormValuesChanges(): void {
    this.baWearerForm.valueChanges
      .pipe(
        map((value: PropertyBag) => {
          value.personalId = value.personalId?.trim();
          value.firstName = value.firstName?.trim();
          value.lastName = value.lastName?.trim();
          value.fireStation = value.fireStation?.trim();

          return value;
        }),
        filter(() => this.baWearerForm.valid),
      )
      .subscribe((value: PropertyBag) => {
        this.hasEmptyStringProperty(value);
      });
  }

  hasEmptyStringProperty(obj: PropertyBag) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === '') {
        this.baWearerForm.markAsPristine();
      }
    }
  }

  loadingStatus(): void {
    this.isLoading$ = combineLatest([
      this.store.select(selectNewBAWearer),
      this.store.select(selectUpdatedBAWearer),
      this.store.select(selectBAWearer),
    ]).pipe(
      takeUntil(this.destroy),
      map(
        ([newBAWearer, updatedBAWearer, baWearerById]: [
          newBAWearer: IStoreApiItem<IBAWearer>,
          updatedBAWearer: IStoreApiItem<IBAWearer>,
          baWearerById: IStoreApiItem<IBAWearer>,
        ]) => {
          this.isLoadingForm = baWearerById.isLoading;

          return newBAWearer.isLoading || baWearerById.isLoading || updatedBAWearer.isLoading;
        },
      ),
    );
  }

  submittingStatus(): void {
    this.isSubmitting = combineLatest([
      this.store.select(selectNewBAWearer),
      this.store.select(selectUpdatedBAWearer),
    ]).pipe(
      takeUntil(this.destroy),
      map(
        ([newBAWearer, updatedBAWearer]: [
          newBAWearer: IStoreApiItem<IBAWearer>,
          updatedBAWearer: IStoreApiItem<IBAWearer>,
        ]) => newBAWearer.isLoading || updatedBAWearer.isLoading,
      ),
    );
  }

  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }

  readFireStationListFromStore(): void {
    combineLatest([
      this.store.select(selectFireStationList),
      this.store.select(selectDeviceConnectionAssignedFireStations),
    ])
      .pipe(
        filter(
          ([baWearerFireStations, deviceConnectionFireStations]: [
            IStoreApiList<IFireStationList>,
            IStoreApiList<IFireStationList>,
          ]) => !baWearerFireStations.isLoading && !deviceConnectionFireStations.isLoading,
        ),
        take(1),
      )
      .subscribe(
        ([baWearerFireStations, deviceConnectionFireStations]: [
          IStoreApiList<IFireStationList>,
          IStoreApiList<IFireStationList>,
        ]) => {
          this.fireStations = this.deviceConnectionService.mergeFireStationsFromBAWearerAndDeviceConnection(
            baWearerFireStations.data?.fireStations,
            deviceConnectionFireStations.data?.fireStations,
          );
        },
      );
  }

  populateForm(): void {
    this.route.params.pipe(takeUntil(this.destroy)).subscribe((params: { id: string }) => {
      if (params.id) {
        this.handleBAWearerById(params.id);
      } else {
        this.modalType = CommonConstants.modalType.CREATE;
      }
    });
  }

  handleBAWearerById(id: string): void {
    this.modalType = CommonConstants.modalType.UPDATE;
    this.baWearerActions.requestBAWearerById(id);

    this.store
      .pipe(
        select(selectBAWearer),
        filter((baWearer: IStoreApiItem<IBAWearer>) => !baWearer.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<IBAWearer>) => {
        if (response.data) {
          this.baWearerForm.patchValue(response.data);
        } else {
          this.apiErrorHandler(response);
        }
      });
  }

  navigateBack(isOpen: boolean): void {
    if (isOpen) {
      this.baWearerForm.markAsPristine();

      if (this.windowPathName() === this.linkToNavigate || !this.linkToNavigate) {
        this.location.back();
      } else {
        this.router.navigate([this.linkToNavigate]);
      }
    }

    this.isConfirmCloseModalOpen = false;
  }

  windowPathName(): string {
    return window.location.pathname;
  }

  confirmCloseModalOpen(): void {
    this.isConfirmCloseModalOpen = true;
  }

  setBAWearerInvalidFields(): void {
    if (this.httpCustomErrorCode in BAWearerConstants.invalidFieldsErrorsMap) {
      this.applyBAWearerInvalidValidator(BAWearerConstants.invalidFieldsErrorsMap[this.httpCustomErrorCode]);
    }
  }

  applyBAWearerInvalidValidator(controlName: string): void {
    this.baWearerForm.get(controlName).clearValidators();
    this.baWearerForm.get(controlName).setValidators([this.setBAWearerFieldIncorrect()]);

    this.baWearerForm.get(controlName).updateValueAndValidity();
  }

  setBAWearerFieldIncorrect(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
      if (!control.value) {
        return null;
      } else {
        return { incorrect: true };
      }
    };
  }

  onSubmit(): void {
    this.isSubmitted = true;

    if (this.modalType === CommonConstants.modalType.CREATE) {
      this.baWearerActions.requestCreateBAWearer(this.baWearerForm.value);
      this.initAddOrUpdateBAWearerSubscription(selectNewBAWearer);
    } else {
      this.baWearerActions.requestUpdateBAWearer(this.baWearerForm.value);
      this.initAddOrUpdateBAWearerSubscription(selectUpdatedBAWearer);
    }
  }

  initAddOrUpdateBAWearerSubscription(
    selector: MemoizedSelector<
      ApplicationState,
      IStoreApiItem<IBAWearer>,
      DefaultProjectorFn<IStoreApiItem<IBAWearer>>
    >,
  ): void {
    this.store
      .pipe(
        select(selector),
        filter((baWearer: IStoreApiItem<IBAWearer>) => !baWearer.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<IBAWearer>) => {
        if (response.errors) {
          this.apiErrorHandler(response);

          return;
        }

        if (response.data && response.isSuccess) {
          this.baWearerForm.disable();
          this.baWearerForm.markAsPristine();

          const successMsg: INotificationMessage =
            this.modalType === CommonConstants.modalType.CREATE
              ? BAWearerNotificationConstants.notificationCodes.ADD_BA_WEARER_SUCCESS
              : BAWearerNotificationConstants.notificationCodes.UPDATE_BA_WEARER_SUCCESS;

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            successMsg,
            BAWearerNotificationConstants.notificationCodes,
            null,
            BAWearerConstants.notificationDescriptionTransKey,
          );

          this.router.navigate([BAWearerModuleRoutes.baWearerOverview]);
        }
      });
  }

  apiErrorHandler(response: IStoreApiItem<IBAWearer>): void {
    this.httpCustomErrorCode = response.errors?.error.code?.toString();

    let notificationType: string = CommonConstants.notificationType.ERROR;

    if (
      this.httpCustomErrorCode === BAWearerNotificationConstants.notificationCodes.NOT_UNIQUE_RFID_CARD.value ||
      this.httpCustomErrorCode === BAWearerNotificationConstants.notificationCodes.NOT_UNIQUE_PERSONAL_ID.value
    ) {
      notificationType = CommonConstants.notificationType.HIDDEN;
    }

    this.notificationsService.requestShowNotification(
      notificationType,
      this.httpCustomErrorCode,
      BAWearerNotificationConstants.notificationCodes,
    );

    if (this.httpCustomErrorCode === BAWearerNotificationConstants.notificationCodes.ENTITY_NOT_FOUND.value) {
      setTimeout(() => {
        this.router.navigate([BAWearerModuleRoutes.baWearerOverview]);
      }, CommonConstants.DEFAULT_REDIRECT_TIMEOUT);
    }

    this.setBAWearerInvalidFields();
  }

  hasUnsavedData(): boolean {
    return this.baWearerForm.dirty;
  }
}
