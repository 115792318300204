import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ApplicationInsights, ICustomProperties } from '@microsoft/applicationinsights-web';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { CommonConstants } from '../../constants/common.constants';
import { LoggerMonitor } from './logger-monitor';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  appInsights: ApplicationInsights;
  connectionString: string = environment.APP_INSIGHTS.CONNECTION_STRING;
  instrumentationKey: string;
  jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(private logger: NGXLogger) {
    this.logger.registerMonitor(new LoggerMonitor());

    this.instrumentationKey = this.connectionString.split(';')[0].replace('InstrumentationKey=', '');
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: this.instrumentationKey,
        connectionString: environment.APP_INSIGHTS.CONNECTION_STRING,
      },
    });

    if (environment.APP_INSIGHTS.ACTIVATE_APP_INSIGHTS === 'true' && !CommonConstants.isHeadless) {
      this.appInsights.loadAppInsights();
    }
  }

  logMessage(message: any, type?: string): void {
    switch (type) {
      case CommonConstants.logType.WARNING:
        this.logger.warn(message);
        break;
      case CommonConstants.logType.ERROR:
        this.logger.error(message);
        break;
      case CommonConstants.logType.INFO:
        this.logger.info(message);
        break;
      default:
        this.logger.debug(message);
    }
  }

  /* istanbul ignore next */
  logTrace(error: string, severityLevel?: number, errorId?: string): void {
    const tenantId: string = localStorage.getItem(CommonConstants.tenantId);
    const customProperties: ICustomProperties = {
      TenantId: tenantId,
      LogType: 'Frontend',
      ...(errorId !== undefined && { 'error-id': errorId }),
    };

    if (window.location.port !== '9876') {
      this.appInsights.trackTrace({ message: error, severityLevel }, customProperties);
    }
  }
}
