<h2 class="mt-4">
  {{ 'EQUIPMENT_TASK_RESULTS.STR_TASKS_INCLUDED' | translate }}
</h2>

<p-table
  #dt2
  styleClass="p-datatable-striped p-datatable-gridlines"
  scrollHeight="flex"
  class="complete-checklist-table"
  [scrollable]="true"
  [value]="selectedTaskIntervals"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th scope="col" class="w-50">
        {{ 'EQUIPMENT_TEST.STR_EQUIPMENT_INTERVAL_DESCRIPTION' | translate }}
      </th>
      <th scope="col" class="w-25">
        {{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_LAST_OLD' | translate }}
      </th>
      <th scope="col" class="w-25">
        {{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_NEXT_OLD' | translate }}
      </th>
      <th scope="col" class="w-25">
        {{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_NEXT_NEW' | translate }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-service>
    <tr>
      <td data-column="taskName">{{ service.taskName }}</td>
      <td data-column="lastInterval">
        {{ service.lastTestDate | date: formatDate }}
      </td>
      <td data-column="nextInterval">
        <span
          [ngClass]="{
            'c-test--is-due': testIsExpired(service.nextTestDate),
          }"
        >
          {{ service.nextTestDate | date: formatDate }}
        </span>
      </td>
      <td data-column="nextInterval">
        {{ service.nextNew | date: formatDate }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="4">{{ 'EQUIPMENT_TEST.STR_NO_SERVICE_INTERVALS' | translate }}</td>
    </tr>
  </ng-template>
</p-table>

<h2 class="mt-4">
  {{ 'EQUIPMENT_TASK_RESULTS.STR_OTHER_TASKS' | translate }}
</h2>

<p-table
  #dt2
  styleClass="p-datatable-striped p-datatable-gridlines"
  scrollHeight="flex"
  class="complete-checklist-table"
  [scrollable]="true"
  [value]="otherTaskIntervals"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th scope="col" class="w-10" [title]="'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_ALSO_DONE' | translate">
        {{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_ALSO_DONE' | translate }}
      </th>
      <th scope="col" class="w-40">
        {{ 'EQUIPMENT_TEST.STR_EQUIPMENT_INTERVAL_DESCRIPTION' | translate }}
      </th>
      <th scope="col" class="w-25">
        {{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_LAST_OLD' | translate }}
      </th>
      <th scope="col" class="w-25">
        {{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_NEXT_OLD' | translate }}
      </th>
      <th scope="col" class="w-25">
        {{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_NEXT_NEW' | translate }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-service>
    <tr>
      <td class="p-relative">
        <odx-checkbox
          class="org-form-label org-checkbox-mt-6"
          ngDefaultControl
          [value]="service.serviceId"
          [attr.data-test-id]="service.serviceId"
          [(checked)]="service.included"
          (click)="changeOtherService(service)"
        >
        </odx-checkbox>
      </td>
      <td data-column="taskName">{{ service.taskName }}</td>
      <td data-column="lastInterval">
        {{ service.lastTestDate | date: formatDate }}
      </td>
      <td data-column="nextInterval">
        <span
          [ngClass]="{
            'c-test--is-due': testIsExpired(service.nextTestDate),
          }"
        >
          {{ service.nextTestDate | date: formatDate }}
        </span>
      </td>
      <td>
        {{ service.nextNew | date: formatDate }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="5">{{ 'EQUIPMENT_TEST.STR_NO_SERVICE_INTERVALS' | translate }}</td>
    </tr>
  </ng-template>
</p-table>
