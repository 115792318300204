<ignis-progress-bar *ngIf="isLoading$ | async"></ignis-progress-bar>

<div class="odx-app-page__content">
  <div class="custom-odx-tab-bar odx-margin-bottom-24">
    <div class="odx-tab-bar__inner">
      <div class="odx-tab-bar__panel">
        <div class="back-btn-group ml--10">
          <button
            odxNavigationBack
            class="odx-tab-bar-item"
            (click)="baWearerForm.dirty ? confirmCloseModalOpen() : navigateBack(true)"
            data-test-id="ba_wearer.back"
          ></button>
          <span class="odx-fs-normal">
            {{ 'BA_WEARER.STR_BA_WEARER_OVERVIEW' | translate }}
          </span>
        </div>

        <div
          [ngClass]="{
            'tab-is-active': activeTab === tabs.PERSONAL_DATA,
          }"
        >
          <button odxButton (click)="setActiveTab(tabs.PERSONAL_DATA)" class="odx-tab-bar-item">
            {{ 'BA_WEARER.STR_PERSONAL_DATA' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <ngx-simplebar [options]="scrollbarOptions" class="create-update-ba-wearer-custom-scrollbar">
    <form [formGroup]="baWearerForm" (ngSubmit)="onSubmit()" *ngIf="!isLoadingForm">
      <ignis-ba-wearer-personal-data
        *ngIf="activeTab === tabs.PERSONAL_DATA"
        [baWearerForm]="baWearerForm"
        [isSubmitted]="isSubmitted"
        [fireStations]="fireStations"
        [httpCustomErrorCode]="httpCustomErrorCode"
      ></ignis-ba-wearer-personal-data>
    </form>
  </ngx-simplebar>
</div>

<ignis-confirmation-modal
  [isOpen]="isConfirmCloseModalOpen"
  (handleConfirmationModal)="navigateBack($event)"
  class="is-closed-modal"
>
  <div slot="content">
    {{ 'CONFIRM_CLOSE_MODAL.STR_DESCRIPTION_BA_WEARER' | translate }}
  </div>
</ignis-confirmation-modal>

<footer class="blue-bar">
  <ignis-create-update-ba-wearer-view-footer
    [form]="baWearerForm"
    [isSubmitted]="isSubmitted"
    [isModalOpened]="isConfirmCloseModalOpen"
    [isSubmitDisabled]="isSubmitting | async"
    (handleSubmit)="onSubmit()"
  ></ignis-create-update-ba-wearer-view-footer>
</footer>
