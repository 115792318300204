import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonConstants } from 'src/app/common';
import { IEntryModel } from 'src/app/common/models/common.model';

@Component({
  selector: 'ignis-fireground-settings-section',
  templateUrl: './fireground-settings-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiregroundSettingsSectionComponent {
  @Input() organizationSettingsForm: FormGroup;
  @Input() profilesOptions: IEntryModel[];
  @Input() defaultTimeOptions: string[];
  @Input() pressureOptions: IEntryModel[];
  @Input() cylinderPressureOptions: string[];

  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;

  @Output() handleProfileChanging: EventEmitter<string> = new EventEmitter();

  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  onProfileChange(selectedProfile: string): void {
    this.handleProfileChanging.emit(selectedProfile);
    this.cdr.detectChanges();
  }
}
