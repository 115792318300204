<form odxForm [formGroup]="equipmentForm" [readonly]="equipmentForm.disabled">
  <section odxLayout="grid" class="mb-5" formGroupName="generalData">
    <h2 class="mb-3" odxLayout="12">{{ 'EQUIPMENT_GENERAL.STR_GENERAL_FORM_EQUIPMENT_DATA' | translate }}</h2>

    <div odxLayout="12 12@tablet 4@desktop" class="custom-form-field-pr-10">
      <odx-form-field
        variant="horizontal"
        label="{{ 'EQUIPMENT_GENERAL.STR_EQUIPMENT_LIST_UNIQUE_IDENTIFIER' | translate }}"
        [readonly]="
          equipmentForm.get('generalData.identifierClone').disabled ||
          equipmentForm.get('generalData.identifier').disabled
        "
        [ngClass]="{
          'has-error':
            errors &&
            errors.toString() === uniqueIdentifierCode &&
            equipmentForm.get('generalData.identifier').invalid &&
            equipmentForm.get('generalData.identifier').value?.length > 0,
        }"
      >
        @if (equipmentForm.get('generalData.identifierClone').value) {
          <input
            formControlName="identifierClone"
            data-test-id="equipment_general.equipment_unique_identifier"
            odxFormFieldControl
            ignisDisableAutocomplete
            (input)="onIdentifierCloneChange($event)"
          />
        } @else {
          <input
            formControlName="identifier"
            data-test-id="equipment_general.equipment_unique_identifier"
            odxFormFieldControl
            ignisDisableAutocomplete
            (input)="onIdentifierChange($event)"
          />
        }

        <odx-icon
          *ngIf="
            errors &&
            errors.toString() === uniqueIdentifierCode &&
            equipmentForm.get('generalData.identifier').invalid &&
            equipmentForm.get('generalData.identifier').value?.length > 0
          "
          name="info"
          class="custom-validation-icon"
          iconSet="core"
          [odxTooltip]="identifierTooltipContent"
          [odxTooltipVisible]="
            errors &&
            errors.toString() === uniqueIdentifierCode &&
            equipmentForm.get('generalData.identifier').invalid &&
            equipmentForm.get('generalData.identifier').value?.length > 0
          "
        >
          <ng-template #identifierTooltipContent>
            <div
              id="identifier-tooltip-error"
              data-test-id="equipment_general.equipment_unique_identifier_error_tooltip"
            >
              {{ 'EQUIPMENT_TOOLTIP.STR_VALUE_SHOULD_BE_UNIQUE' | translate }}
            </div>
          </ng-template>
        </odx-icon>
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label="{{ 'EQUIPMENT_GENERAL.STR_EQUIPMENT_BARCODE' | translate }}"
        [readonly]="equipmentForm.get('generalData.barcode').disabled"
        [ngClass]="{
          'has-error':
            (errors && errors.toString() === uniqueBarcodeCode && equipmentForm.get('generalData.barcode').invalid) ||
            (errors && errors.toString() === blankBarcodeCode),
        }"
      >
        <input
          type="text"
          ignisDisableAutocomplete
          id="barcode"
          data-test-id="equipment_general.equipment_barcode"
          formControlName="barcode"
          odxFormFieldControl
          (change)="clearFormControlValidators('generalData.barcode')"
        />
        <odx-icon
          *ngIf="errors && errors.toString() === uniqueBarcodeCode && equipmentForm.get('generalData.barcode').invalid"
          name="info"
          iconSet="core"
          class="custom-validation-icon"
          [odxTooltip]="barcodeTooltipContent"
          [odxTooltipVisible]="
            errors && errors.toString() === uniqueBarcodeCode && equipmentForm.get('generalData.barcode').invalid
          "
        >
          <ng-template #barcodeTooltipContent>
            <div
              id="unique-barcode-tooltip-error"
              data-test-id="equipment_general.equipment_unique_barcode_error_tooltip"
            >
              {{
                errors && isSubmitted
                  ? errors.toString() === uniqueBarcodeCode
                    ? ('EQUIPMENT_TOOLTIP.STR_VALUE_SHOULD_BE_UNIQUE' | translate)
                    : errors.toString() === blankBarcodeCode
                      ? ('INVENTORY_CSV_IMPORT_ERROR_LIST.STR_BARCODE_CANNOT_HAVE_ONLY_SPACES' | translate)
                      : ''
                  : ''
              }}
            </div>
          </ng-template>
        </odx-icon>

        <odx-icon
          *ngIf="errors && errors.toString() === blankBarcodeCode"
          name="info"
          iconSet="core"
          class="custom-validation-icon"
          [odxTooltip]="blankBarcodeTooltipContent"
          [odxTooltipVisible]="errors && isSubmitted && errors.toString() === blankBarcodeCode"
        >
          <ng-template #blankBarcodeTooltipContent>
            <div id="barcode-tooltip-error" data-test-id="equipment_general.equipment_blank_barcode_error_tooltip">
              {{ 'INVENTORY_CSV_IMPORT_ERROR_LIST.STR_BARCODE_CANNOT_HAVE_ONLY_SPACES' | translate }}
            </div>
          </ng-template>
        </odx-icon>
      </odx-form-field>

      <div formGroupName="model">
        <odx-form-field
          variant="horizontal"
          label="{{ 'EQUIPMENT_GENERAL.STR_GENERAL_FORM_EQUIPMENT_MODEL' | translate }}"
          [readonly]="equipmentForm.get('generalData.model.id').disabled"
        >
          <div
            (mouseenter)="showEquipmentModelTooltip = true"
            (mouseleave)="showEquipmentModelTooltip = false"
            data-test-id="equipment_general.general_form_equipment_model_wrap"
            class="w-100"
          >
            <div
              [odxTooltip]="'EQUIPMENT_TOOLTIP.STR_EQUIPMENT_MODEL_MANDATORY' | translate"
              [odxTooltipVisible]="
                equipmentForm.get('generalData.model.id').invalid && showEquipmentModelTooltip && isSubmitted
              "
            ></div>

            <p-dropdown
              #dd3
              (onShow)="dropdownService.openDropdown(dd3)"
              (onHide)="dropdownService.closeDropdown(dd3)"
              optionValue="id"
              optionLabel="name"
              appendTo="body"
              odxFormFieldControl
              ngDefaultControl
              class="custom-dropdown-form"
              formControlName="id"
              data-test-id="equipment_general.general_form_equipment_model"
              [dropdownIcon]="dropdownIconCSSClass"
              [options]="filteredEquipmentModels"
              [placeholder]="'EQUIPMENT_GENERAL.STR_GENERAL_FORM_SELECT_MODEL' | translate"
              [showClear]="true"
              (onChange)="equipmentModelChanged($event.value)"
            >
              <ng-template let-option pTemplate="item">
                <span>{{ option.name }}</span>
              </ng-template>
              <ng-template let-selectedItem pTemplate="selectedItem">
                <span [title]="selectedItem.name">{{ selectedItem.name }}</span>
              </ng-template>
              <ng-template pTemplate="clearicon">
                <odx-icon name="close"></odx-icon>
              </ng-template>
            </p-dropdown>
          </div>
        </odx-form-field>

        <div formGroupName="type">
          <odx-form-field
            variant="horizontal"
            label="{{ 'EQUIPMENT_GENERAL.STR_GENERAL_FORM_EQUIPMENT_TYPE' | translate }}"
            [readonly]="equipmentForm.get('generalData.model.type.id').disabled"
          >
            <div
              (mouseenter)="showEquipmentTypeTooltip = true"
              (mouseleave)="showEquipmentTypeTooltip = false"
              data-test-id="equipment_general.general_form_equipment_type_wrap"
              class="w-100"
            >
              <div
                [odxTooltip]="'EQUIPMENT_TOOLTIP.STR_EQUIPMENT_TYPE_MANDATORY' | translate"
                [odxTooltipVisible]="
                  equipmentForm.get('generalData.model.type.id').invalid && showEquipmentTypeTooltip && isSubmitted
                "
                data-test-id="equipment_general.general_form_equipment_type_error_tooltip"
              ></div>

              <p-dropdown
                #dd2
                (onShow)="dropdownService.openDropdown(dd2)"
                (onHide)="dropdownService.closeDropdown(dd2)"
                optionValue="id"
                optionLabel="name"
                appendTo="body"
                odxFormFieldControl
                ngDefaultControl
                class="custom-dropdown-form"
                data-test-id="equipment_general.general_form_equipment_type"
                formControlName="id"
                [dropdownIcon]="dropdownIconCSSClass"
                [options]="filteredEquipmentTypes"
                [placeholder]="'EQUIPMENT_GENERAL.STR_GENERAL_FORM_SELECT_TYPE' | translate"
                [showClear]="true"
                (onChange)="typeChanged($event.value)"
              >
                <ng-template let-option pTemplate="item">
                  <span>{{ option.name }}</span>
                </ng-template>
                <ng-template let-selectedItem pTemplate="selectedItem">
                  <span [title]="selectedItem.name">{{ selectedItem.name }}</span>
                </ng-template>
                <ng-template pTemplate="clearicon">
                  <odx-icon name="close"></odx-icon>
                </ng-template>
              </p-dropdown>
            </div>
          </odx-form-field>
        </div>

        <div formGroupName="category">
          <odx-form-field
            variant="horizontal"
            label=" {{ 'EQUIPMENT_GENERAL.STR_GENERAL_FORM_CATEGORY' | translate }}"
            [readonly]="equipmentForm.get('generalData.model.category.id').disabled"
          >
            <div
              (mouseenter)="showCategoryTooltip = true"
              (mouseleave)="showCategoryTooltip = false"
              data-test-id="equipment_general.general_form_category_wrap"
              class="w-100"
            >
              <div
                [odxTooltip]="'EQUIPMENT_TOOLTIP.STR_EQUIPMENT_CATEGORY_MANDATORY' | translate"
                [odxTooltipVisible]="
                  equipmentForm.get('generalData.model.category.id').invalid && showCategoryTooltip && isSubmitted
                "
                data-test-id="equipment_general.general_form_category_error_tooltip"
              ></div>

              <p-dropdown
                #dd1
                (onShow)="dropdownService.openDropdown(dd1)"
                (onHide)="dropdownService.closeDropdown(dd1)"
                optionValue="id"
                optionLabel="name"
                appendTo="body"
                ngDefaultControl
                odxFormFieldControl
                class="custom-dropdown-form"
                formControlName="id"
                data-test-id="equipment_general.general_form_select_category"
                [dropdownIcon]="dropdownIconCSSClass"
                [options]="categoryTypeModelOptions?.categories"
                [placeholder]="'EQUIPMENT_GENERAL.STR_GENERAL_FORM_SELECT_CATEGORY' | translate"
                [showClear]="true"
                (onChange)="categoryChanged($event)"
              >
                <ng-template let-option pTemplate="item">
                  <span>{{ option.name }}</span>
                </ng-template>
                <ng-template let-selectedItem pTemplate="selectedItem">
                  <span [title]="selectedItem.name">{{ selectedItem.name }}</span>
                </ng-template>
                <ng-template pTemplate="clearicon">
                  <odx-icon name="close"></odx-icon>
                </ng-template>
              </p-dropdown>
            </div>
          </odx-form-field>
        </div>
      </div>
    </div>

    <div odxLayout="12 12@tablet 4@desktop" class="custom-form-field-pl-10">
      <odx-form-field
        variant="horizontal"
        label="{{ 'EQUIPMENT_GENERAL.STR_GENERAL_FORM_OPERATIONAL_STATUS' | translate }}"
        [readonly]="equipmentForm.get('generalData.operationalStatus').disabled"
      >
        <div class="w-100">
          <p-dropdown
            #dd4
            (onShow)="dropdownService.openDropdown(dd4)"
            (onHide)="dropdownService.closeDropdown(dd4)"
            ngDefaultControl
            odxFormFieldControl
            class="custom-dropdown-form"
            appendTo="body"
            optionValue="value"
            optionLabel="localizedName"
            formControlName="operationalStatus"
            data-test-id="equipment_general.general_form_operational_status"
            [dropdownIcon]="dropdownIconCSSClass"
            [options]="operationalStatusesList"
            [placeholder]="'EQUIPMENT_GENERAL.STR_GENERAL_FORM_SELECT_STATUS' | translate"
            [showClear]="false"
            (onChange)="operationalStatusChanged($event)"
          >
            <ng-template let-selectedItem pTemplate="selectedItem">
              <span [title]="selectedItem?.localizedName | translate">{{
                selectedItem?.localizedName | translate
              }}</span>
            </ng-template>
            <ng-template let-option pTemplate="item">
              <span>{{ option?.localizedName | translate }}</span>
            </ng-template>
          </p-dropdown>
        </div>
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label="RFID"
        [readonly]="equipmentForm.get('generalData.rfid').disabled"
        [ngClass]="{
          'has-error':
            (errors && errors.toString() === uniqueRFIDCode && equipmentForm.get('generalData.rfid').invalid) ||
            (errors && errors.toString() === blankRFIDCode),
        }"
      >
        <input
          type="text"
          ignisDisableAutocomplete
          id="rfid"
          formControlName="rfid"
          data-test-id="equipment_general.general_form_rfid"
          [title]="equipmentForm.get('generalData.rfid').value ?? ''"
          odxFormFieldControl
          (change)="clearFormControlValidators('generalData.rfid')"
        />
        <odx-icon
          *ngIf="errors && errors.toString() === uniqueRFIDCode && equipmentForm.get('generalData.rfid').invalid"
          name="info"
          iconSet="core"
          class="custom-validation-icon"
          [odxTooltip]="rfidTooltipContent"
          [odxTooltipVisible]="
            errors && errors.toString() === uniqueRFIDCode && equipmentForm.get('generalData.rfid').invalid
          "
        >
          <ng-template #rfidTooltipContent>
            <div id="unique-rfid-tooltip-error" data-test-id="equipment_general.general_form_unique_rfid_error_tooltip">
              {{
                errors && isSubmitted
                  ? errors.toString() === uniqueRFIDCode
                    ? ('EQUIPMENT_TOOLTIP.STR_VALUE_SHOULD_BE_UNIQUE' | translate)
                    : errors.toString() === blankRFIDCode
                      ? ('EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_RFID_CANNOT_HAVE_ONLY_SPACES' | translate)
                      : ''
                  : ''
              }}
            </div>
          </ng-template>
        </odx-icon>

        <odx-icon
          *ngIf="errors && errors.toString() === blankRFIDCode"
          name="info"
          iconSet="core"
          class="custom-validation-icon"
          [odxTooltip]="rfidBlankTooltipContent"
          [odxTooltipVisible]="errors && errors.toString() === blankRFIDCode"
        >
          <ng-template #rfidBlankTooltipContent>
            <div id="rfid-tooltip-error" data-test-id="equipment_general.general_form_blank_rfid_error_tooltip">
              {{ 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_RFID_CANNOT_HAVE_ONLY_SPACES' | translate }}
            </div>
          </ng-template>
        </odx-icon>
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label="{{ 'EQUIPMENT_GENERAL.STR_MANUFACTURER_FORM_SERIAL_NO' | translate }}"
        [readonly]="equipmentForm.get('generalData.serialNo').disabled"
        [ngClass]="{
          'has-error':
            (errors && errors.toString() === uniqueSerialNo && equipmentForm.get('generalData.serialNo').invalid) ||
            (errors && errors.toString() === blankSerialNo),
        }"
      >
        <input
          type="text"
          ignisDisableAutocomplete
          id="serialNo"
          formControlName="serialNo"
          data-test-id="equipment_general.manufacturer_form_serial_no"
          [title]="equipmentForm.get('generalData.serialNo').value ?? ''"
          odxFormFieldControl
          (change)="clearFormControlValidators('generalData.serialNo')"
        />
        <odx-icon
          *ngIf="errors && errors.toString() === uniqueSerialNo && equipmentForm.get('generalData.serialNo').invalid"
          name="info"
          iconSet="core"
          class="custom-validation-icon"
          [odxTooltip]="serialNoTooltipContent"
          [odxTooltipVisible]="
            errors && errors.toString() === uniqueSerialNo && equipmentForm.get('generalData.serialNo').invalid
          "
        >
          <ng-template #serialNoTooltipContent>
            <div
              id="unique-serialNo-tooltip-error"
              data-test-id="equipment_general.manufacturer_form_unique_serial_no_tooltip_error"
            >
              {{
                errors && isSubmitted
                  ? errors.toString() === uniqueSerialNo
                    ? ('EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_SERIAL_NO_ALREADY_EXISTS' | translate)
                    : errors.toString() === blankSerialNo
                      ? ('EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_SERIAL_NO_CANNOT_HAVE_ONLY_SPACES' | translate)
                      : ''
                  : ''
              }}
            </div>
          </ng-template>
        </odx-icon>

        <odx-icon
          *ngIf="errors && errors.toString() === blankSerialNo"
          name="info"
          iconSet="core"
          class="custom-validation-icon"
          [odxTooltip]="serialNoBlankTooltipContent"
          [odxTooltipVisible]="errors && errors.toString() === blankSerialNo"
        >
          <ng-template #serialNoBlankTooltipContent>
            <div
              id="serialNo-tooltip-error"
              data-test-id="equipment_general.manufacturer_form_blank_serial_no_tooltip_error"
            >
              {{ 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_SERIAL_NO_CANNOT_HAVE_ONLY_SPACES' | translate }}
            </div>
          </ng-template>
        </odx-icon>
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label="{{ 'EQUIPMENT_GENERAL.STR_MANUFACTURER_FORM_PART_NO' | translate }}"
        [readonly]="equipmentForm.get('generalData.partNo').disabled"
      >
        <input
          type="text"
          ignisDisableAutocomplete
          id="partNo"
          formControlName="partNo"
          data-test-id="equipment_general.part_no"
          [title]="equipmentForm.get('generalData.partNo').value ?? ''"
          odxFormFieldControl
          (change)="clearFormControlValidators('generalData.partNo')"
        />
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label="{{ 'EQUIPMENT_GENERAL.STR_MANUFACTURER_FORM_PART_NAME' | translate }}"
        [readonly]="equipmentForm.get('generalData.partName').disabled"
      >
        <input
          type="text"
          ignisDisableAutocomplete
          id="partName"
          formControlName="partName"
          data-test-id="equipment_general.part_name"
          [title]="equipmentForm.get('generalData.partName').value ?? ''"
          odxFormFieldControl
          (change)="clearFormControlValidators('generalData.partName')"
        />
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label="{{ 'EQUIPMENT_GENERAL.STR_GENERAL_FORM_DESCRIPTION' | translate }}"
        [readonly]="equipmentForm.get('generalData.description').disabled"
      >
        <textarea
          ignisDisableAutocomplete
          odxFormFieldControl
          id="description"
          data-test-id="equipment_general.general_form_description"
          formControlName="description"
          class="custom-form-textarea"
          rows="3"
        ></textarea>
      </odx-form-field>
    </div>

    <h2 class="mb-3" odxLayout="12">{{ 'EQUIPMENT_GENERAL.STR_GENERAL_FORM_RELATED_DATA' | translate }}</h2>

    <div odxLayout="12 12@tablet 4@desktop" class="custom-form-field-pr-10">
      <odx-form-field
        variant="horizontal"
        label="{{ 'EQUIPMENT_GENERAL.STR_GENERAL_FORM_OWNER' | translate }}"
        [readonly]="equipmentForm.get('generalData.owner').disabled"
      >
        <input
          type="text"
          ignisDisableAutocomplete
          id="ownerData"
          data-test-id="equipment_general.general_form_owner"
          formControlName="owner"
          placeholder="{{ 'EQUIPMENT_GENERAL.STR_GENERAL_FORM_OWNER_PLACEHOLDER' | translate }}"
          odxFormFieldControl
        />
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label="{{ 'EQUIPMENT_GENERAL.STR_GENERAL_FIRST_REGISTRATION_DATE' | translate }}"
        class="custom-datepicker-form-field isReadOnly"
        [readonly]="true"
      >
        <div class="w-100">
          <ignis-datepicker
            #firstRegisteredDatePicker
            formControlName="firstRegisteredDate"
            data-test-id="equipment_general.general_first_registration_date"
            ngDefaultControl
            [isDisabled]="true"
            [selectionMode]="'single'"
            [showTime]="false"
            [formatDate]="formatDate"
            [defaultRange]="extractOnlyDate(equipmentForm.get('generalData.firstRegisteredDate').value)"
            (change)="clearFormControlValidators('generalData.firstRegisteredDate')"
            [clearable]="false"
          >
          </ignis-datepicker>
        </div>
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label="{{ 'EQUIPMENT_GENERAL.STR_GENERAL_FORM_OUT_OF_ORDER_DATE' | translate }}"
        class="custom-datepicker-form-field"
        [ngClass]="{ isReadOnly: equipmentForm.get('generalData.outOfOrderDate').disabled }"
      >
        <div
          class="w-100"
          (mouseenter)="showOutOfOrderDateTooltip = true"
          (mouseleave)="showOutOfOrderDateTooltip = false"
          data-test-id="equipment_general.general_form_out_of_order_date_wrap"
        >
          <div
            [odxTooltipVisible]="showOutOfOrderDateTooltip && equipmentForm.get('generalData.outOfOrderDate').invalid"
            [odxTooltip]="'EQUIPMENT_GENERAL.STR_GENERAL_FORM_INCORRECT_DATE' | translate"
            data-test-id="equipment_general.general_form_out_of_order_date_error_tooltip"
          ></div>
          <ignis-datepicker
            formControlName="outOfOrderDate"
            data-test-id="equipment_general.out_of_order_date"
            ngDefaultControl
            appendTo="body"
            class="custom-datepicker-form"
            [isDisabled]="equipmentForm.get('generalData.outOfOrderDate').disabled"
            [selectionMode]="'single'"
            [showTime]="false"
            [formatDate]="formatDate"
            [defaultRange]="extractOnlyDate(equipmentForm.get('generalData.outOfOrderDate').value)"
            [clearable]="true"
            (dateSelect)="
              datepickerService.onDateChange($event, equipmentForm, 'generalData.outOfOrderDate');
              outOfServiceService.changeOutOfServiceDate(
                $event,
                equipmentForm,
                'generalData.operationalStatus',
                'generalData.outOfServiceReason',
                null,
                true,
                modalType === 'update' ? 'generalData.identifier' : null
              );
              changeInUseDate();
              resetOutOfOrderDateError();
              makeIdentifierCloneRequired()
            "
            (dateChange)="
              datepickerService.resetSelectedDate(equipmentForm, 'generalData.outOfOrderDate');
              outOfServiceService.changeOutOfServiceDate(
                $event,
                equipmentForm,
                'generalData.operationalStatus',
                'generalData.outOfServiceReason',
                null,
                true,
                modalType === 'update' ? 'generalData.identifier' : null
              );
              changeInUseDate();
              makeIdentifierCloneRequired()
            "
          >
          </ignis-datepicker>
        </div>
      </odx-form-field>
    </div>
    <div odxLayout="12 12@tablet 4@desktop" class="custom-form-field-pl-10">
      <div class="empty-col"></div>
      <odx-form-field
        variant="horizontal"
        label="{{ 'EQUIPMENT_GENERAL.STR_GENERAL_FORM_IN_USE_DATA' | translate }}"
        [ngClass]="{ isReadOnly: equipmentForm.get('generalData.inUseDate').disabled }"
        class="custom-datepicker-form-field"
      >
        <div class="w-100">
          <ignis-datepicker
            #inUseDatePickerComponent
            formControlName="inUseDate"
            data-test-id="equipment_general.in_use_data"
            ngDefaultControl
            [isDisabled]="equipmentForm.get('generalData.inUseDate').disabled"
            [selectionMode]="'single'"
            class="custom-datepicker-form"
            appendTo="body"
            [showTime]="false"
            [formatDate]="formatDate"
            [defaultRange]="extractOnlyDate(equipmentForm.get('generalData.inUseDate').value)"
            [maxDate]="equipmentForm.get('generalData.outOfOrderDate').value"
            [clearable]="true"
            (dateSelect)="datepickerService.onDateChange($event, equipmentForm, 'generalData.inUseDate')"
            (dateChange)="datepickerService.resetSelectedDate(equipmentForm, 'generalData.inUseDate')"
          >
          </ignis-datepicker>
        </div>
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label="{{ 'EQUIPMENT_GENERAL.STR_GENERAL_FORM_OUT_OF_SERVICE_REASON' | translate }}"
        [readonly]="equipmentForm.get('generalData.outOfServiceReason').disabled"
      >
        <textarea
          ignisDisableAutocomplete
          id="outOfServiceReason"
          data-test-id="equipment_general.general_form_out_of_service_reason"
          formControlName="outOfServiceReason"
          class="custom-form-textarea"
          odxFormFieldControl
          rows="3"
        ></textarea>
      </odx-form-field>
    </div>
  </section>
</form>
