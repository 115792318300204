import { PropertyBag } from 'src/app/common/models/common.model';

export const ConfigurationModuleRoutes: PropertyBag = {
  configuration: 'configuration',
  createEquipmentHierarchy: 'create',
  createOrUpdateEquipmentTypes: 'types',
  createOrUpdateEquipmentModels: 'models',
  updateEquipmentHierarchy: 'update',
  updateEquipmentCategories: 'categories',
  createTask: 'add-task',
  updateTask: 'update-task',
  createChecklist: 'add-checklist',
  updateChecklist: 'update-checklist',
  locationHierarchy: 'location',
  createLocationHierarchy: 'add-location',
  updateLocationHierarchy: 'update-location',
  addressBook: 'address',
  createAddressBook: 'address/create',
  updateAddressBook: 'address/update',
  organizationSettings: 'organization-settings',
};
