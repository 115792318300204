import { ITableColumn } from 'src/app/common';
import { TableFilterType } from 'src/app/common/constants';

export const tableColumns: ITableColumn[] = [
  {
    field: 'name',
    header: 'USER_MANAGEMENT.STR_TABLE_NAME',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'USER_MANAGEMENT.STR_TABLE_NAME_PLACEHOLDER',
  },
  {
    field: 'surname',
    header: 'USER_MANAGEMENT.STR_TABLE_SURNAME',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'USER_MANAGEMENT.STR_TABLE_SURNAME_PLACEHOLDER',
  },

  {
    field: 'emailAddress',
    header: 'USER_MANAGEMENT.STR_TABLE_EMAIL',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'USER_MANAGEMENT.STR_TABLE_EMAIL_PLACEHOLDER',
  },

  {
    field: 'userId',
    header: 'USER_MANAGEMENT.STR_TABLE_USER_ID',
    visible: true,
  },

  {
    field: 'userRole.value',
    header: 'USER_MANAGEMENT.STR_TABLE_ROLE',
    visible: true,
    filterType: TableFilterType.MULTI_SELECT,
    filterKey: ['role'],
    filterPlaceholder: 'USER_MANAGEMENT.STR_TABLE_ROLE_PLACEHOLDER',
  },

  {
    field: 'displayLastLogin',
    header: 'USER_MANAGEMENT.STR_TABLE_LAST_LOGIN',
    visible: true,
  },
];

export const userImportCSVColumns: ITableColumn[] = [
  { field: 'lineNumber', header: 'USER_MANAGEMENT.STR_LINE_NUMBER', visible: true },
  { field: 'source', header: 'USER_MANAGEMENT.STR_EMAIL_ADDRESS', visible: true },
  { field: 'errorMessage', header: 'USER_MANAGEMENT.STR_ERROR', visible: true },
];
