import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Toast, ToastOptions, ToastService, ToastVariant } from '@odx/angular/components/toast';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CommonConstants } from '../../constants/common.constants';
import { INotificationType } from '../../models/notifications.model';
import { IApplicationState } from '../../state/models/app.state.model';
import {
  INotificationState,
  NotificationState,
  NotificationTypes,
} from '../../state/notifications/models/notification.model';
import { selectNotification } from '../../state/notifications/selectors/notification.selector';

@Component({
  selector: 'ignis-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent implements OnInit {
  maintenanceNotification: boolean;
  type: NotificationTypes;

  destroy$: Subject<boolean> = new Subject<boolean>();
  notificationType: INotificationType = CommonConstants.notificationType;

  router: Router = inject(Router);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  translateService: TranslateService = inject(TranslateService);
  private readonly toastService: ToastService = inject(ToastService);
  store: Store<IApplicationState> = inject<Store<IApplicationState>>(Store<IApplicationState>);

  constructor() {}

  ngOnInit(): void {
    this.store
      .pipe(
        select(selectNotification),
        takeUntil(this.destroy$),
        filter((notificationState: INotificationState) => !!notificationState?.type && !!notificationState.message),
      )
      .subscribe((notificationState: INotificationState) => {
        this.toastService.dismissAll();

        this.type = notificationState.type.toString();

        if (notificationState.type !== CommonConstants.notificationType.HIDDEN) {
          if (notificationState.type === CommonConstants.notificationType.MAINTENANCE) {
            this.maintenanceNotification = true;
          }

          this.createToast(notificationState);
        }

        this.cdr.detectChanges();
      });
  }

  closeNotification(): void {
    if (this.maintenanceNotification) {
      localStorage.setItem(CommonConstants.maintenanceDate, 'true');
    }

    this.toastService.dismissAll();
  }

  createToast(state: NotificationState): void {
    const toast: Toast = {
      id: 'app-notification',
      title: state.description ? this.getNotificationMessage(state.message.text, state.value) : 'System info',
      description: this.getNotificationMessage(state.description ? state.description : state.message.text, state.value),
      variant: ToastVariant[state.type.replace('error', 'danger').toUpperCase()] as ToastVariant,
    };

    const options: ToastOptions = {
      dismissable: true,
      duration: state.type === CommonConstants.notificationType.SUCCESS ? CommonConstants.notificationDuration : 0,
    };

    this.toastService.create(
      {
        ...toast,
        actions: [
          {
            label: this.translateService.instant('NOTIFICATION.STR_CLOSE_NOTIFICATION') as string,
            action: (): void => this.closeNotification(),
          },
        ],
      },
      options,
    );
  }

  getNotificationMessage(key: string, value: object): string {
    return this.translateService.instant(key, value ?? undefined) as string;
  }
}
