import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RequestAlarms } from './alarms-action-types-creators';
import { IAlarmsState } from '../models/alarms.model';

@Injectable({ providedIn: 'root' })
export class AlarmsActions {
  constructor(private store: Store<IAlarmsState>) { }

  public requestAlarms(): void {
    this.store.dispatch(new RequestAlarms());
  }

}
