<div class="checklist-image-wrap">
  <div class="checklist-image" (click)="openImage()" data-test-id="equipment_task_documentation.image">
    @if (!imageSrc) {
      <div [odxLoadingSpinner]="true" [odxLoadingSpinnerMinHeight]="60" class="app-loader"></div>
    }
    <img [src]="imageSrc" loading="lazy" [alt]="mediaName" class="media-image" *ngIf="imageSrc" />
  </div>
  <p class="image-name">
    {{ mediaName }}
  </p>
</div>
