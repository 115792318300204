import { Location } from '@angular/common';
import { Component, HostListener, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import orderBy from 'lodash-es/orderBy';
import { combineLatest, filter, map, Observable, take, takeUntil } from 'rxjs';
import { CommonConstants, IApplicationState, IStoreApiItem } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IEntryModel, IScrollOptions } from 'src/app/common/models/common.model';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { AccessControlService } from 'src/app/root';
import { ConfigurationNotificationConstants } from '../../constants';
import { OrganizationConstants } from '../../constants/configuration.constants';
import { IOrganizationAccessControl, IOrganizationEntries, IProfile } from '../../models/organization-settings.model';
import { OrganizationSettingsActions } from '../../state/actions';
import {
  selectOrganizationAccessControl,
  selectOrganizationEntries,
  selectUpdatedOrganizationAccessControl,
} from '../../state/selectors/configuration.selectors';

@Component({
  selector: 'ignis-organization-settings',
  templateUrl: './organization-settings.component.html',
  styleUrls: ['./organization-settings.component.scss'],
})
export class OrganizationSettingsComponent extends OnDestroyMixin() implements OnInit {
  organizationSettingsForm: FormGroup = new FormGroup({
    organizationSettings: new FormGroup({
      name: new FormControl(null, [Validators.required]),
      language: new FormControl(null, [Validators.required]),
      dateFormat: new FormControl(null, [Validators.required]),
    }),
    fireGroundSettings: new FormGroup({
      name: new FormControl(null, [Validators.required]),
      defaultTimeDisplay: new FormControl(null, [Validators.required]),
      displayUnit: new FormControl(null, [Validators.required]),
      defaultCylinderPressure: new FormControl(null, [Validators.required]),
      defaultTTWMinutes: new FormControl(null, [Validators.required]),
      consumptionRate: new FormControl(null, [Validators.required]),
      fwDv7: new FormControl(null, [Validators.required]),
      mayday: new FormControl(null, [Validators.required]),
      lowPressureThreshold: new FormControl(null, [Validators.required]),
      mediumPressureThreshold: new FormControl(null, [Validators.required]),
      isSelected: new FormControl(null),
    }),
    ramSettings: new FormGroup({
      automaticTestingWorkflow: new FormControl(null, [Validators.required]),
      checklistWorkflow: new FormControl(null, [Validators.required]),
      cleaningWorkflow: new FormControl(null, [Validators.required]),
      cylinderChargingWorkflow: new FormControl(null, [Validators.required]),
    }),
    version: new FormControl(null),
  });

  scrollbarOptions: IScrollOptions = CommonConstants.scrollbarOptions;

  firegroundAccess: boolean = false;
  equipmentModuleActive: boolean = false;
  isLoading: boolean = true;
  entries: IOrganizationEntries;
  profilesOptions: IEntryModel[];
  languagesOptions: IEntryModel[];
  dateTimeFormatOptions: string[];
  pressureOptions: IEntryModel[];
  defaultTimeOptions: string[];
  cylinderPressureOptions: string[];
  linkToNavigate: string;

  profiles: IProfile[];
  selectedProfile: Partial<IProfile>;

  errors: any;
  successUpdateProfile: boolean = false;
  isConfirmCloseModalOpen: boolean = false;

  isSubmitting: Observable<boolean>;

  organizationSettingsActions: OrganizationSettingsActions = inject(OrganizationSettingsActions);
  private translateService: TranslateService = inject(TranslateService);
  private notificationsService: NotificationsService = inject(NotificationsService);
  public accessControlService: AccessControlService = inject(AccessControlService);
  store: Store<IApplicationState> = inject(Store<IApplicationState>);
  router: Router = inject(Router);
  location: Location = inject(Location);

  constructor() {
    super();

    this.router.events?.subscribe((event: any) => {
      this.linkToNavigate = event.url;
    });
  }

  @HostListener(CommonConstants.beforeUnloadWindowEvent, ['$event'])
  handleBeforeUnload($event: any): void {
    if (this.hasUnsavedData()) {
      $event.returnValue = this.hasUnsavedData();
    }
  }

  ngOnInit(): void {
    this.fetchData();

    this.firegroundAccess = this.checkIfUserHaveFeatureToggleToFireGroundModules();
    this.equipmentModuleActive = this.checkIfUserHaveEquipmentModuleActive();

    this.isSubmitting = this.store.select(selectUpdatedOrganizationAccessControl).pipe(
      takeUntil(this.destroy),
      map((uProfile: IStoreApiItem<IOrganizationAccessControl>) => uProfile.isLoading),
    );
  }

  canDeactivate(): boolean {
    if (this.organizationSettingsForm.dirty) {
      this.confirmCloseModalOpen();

      return false;
    } else {
      this.isConfirmCloseModalOpen = false;

      return true;
    }
  }

  confirmCloseModalOpen(): void {
    this.isConfirmCloseModalOpen = true;
  }

  navigateBack(isOpen: boolean): void {
    if (isOpen) {
      this.organizationSettingsForm.markAsPristine();

      if (window.location.pathname === this.linkToNavigate || !this.linkToNavigate) {
        this.location.back();
      } else {
        this.router.navigate([this.linkToNavigate]);
      }
    } else {
      this.isConfirmCloseModalOpen = false;
    }
  }

  checkIfUserHaveFeatureToggleToFireGroundModules(): boolean {
    return (
      this.accessControlService.logbookFeatureToggle ||
      this.accessControlService.remoteMonitoringFeatureToggle ||
      this.accessControlService.reportCenterFeatureToggle
    );
  }

  checkIfUserHaveEquipmentModuleActive(): boolean {
    return this.accessControlService.workshopFeatureToggle;
  }

  checkIfUserHaveEquipmentAutomaticTestActive(): boolean {
    return this.accessControlService.equipmentTestingGlobalFeatureToggle;
  }

  fetchData(): void {
    this.organizationSettingsActions.requestOrganizationAccessControl();
    this.organizationSettingsActions.requestOrganizationSettingsEntries();

    combineLatest([this.store.select(selectOrganizationEntries), this.store.select(selectOrganizationAccessControl)])
      .pipe(
        filter(
          ([entries, formData]: [IStoreApiItem<IOrganizationEntries>, IStoreApiItem<IOrganizationAccessControl>]) =>
            !entries.isLoading && !formData.isLoading,
        ),
        takeUntil(this.destroy),
      )
      .subscribe(
        ([entries, formData]: [IStoreApiItem<IOrganizationEntries>, IStoreApiItem<IOrganizationAccessControl>]) => {
          if (entries.data && formData.data) {
            this.isLoading = false;

            this.processEntriesData(entries.data);
            this.processFormData(formData.data);
          }
        },
      );
  }

  processEntriesData(response: IOrganizationEntries): void {
    this.entries = response;

    this.profilesOptions = this.entries.profiles.map((entry: string) => ({
      value: entry,
      label: this.translateService.instant(
        OrganizationConstants.organizationEntries.profiles.find((t: IEntryModel) => t.value === entry)?.localizedName ||
          entry,
      ),
    }));

    this.languagesOptions = this.entries.languages.map((entry: string) => ({
      value: entry,
      label: this.translateService.instant(
        OrganizationConstants.organizationEntries.languages.find((t: IEntryModel) => t.value === entry)
          ?.localizedName || entry,
      ),
    }));

    this.languagesOptions = orderBy(this.languagesOptions, ['label'], ['asc']);

    this.dateTimeFormatOptions = [...this.entries.dateFormats];

    this.defaultTimeOptions = this.entries.timeDisplays;

    this.pressureOptions = this.entries.displayUnits.map((entry: string) => ({
      value: entry,
      label: this.translateService.instant(
        OrganizationConstants.organizationEntries.displayUnits.find((t: any) => t.value === entry)?.localizedName ||
          entry,
      ),
    }));

    this.cylinderPressureOptions = this.entries.cylinderPressures;
  }

  processFormData(response: IOrganizationAccessControl): void {
    this.processFireGroundSettingsFormGroupData(response);
    this.processRAMSettingsFormGroupData();

    this.organizationSettingsForm.patchValue(response);
    this.organizationSettingsForm.markAsPristine();
  }

  processFireGroundSettingsFormGroupData(response: IOrganizationAccessControl): void {
    if (!this.checkIfUserHaveFeatureToggleToFireGroundModules()) {
      this.organizationSettingsForm.removeControl('fireGroundSettings');

      return;
    }

    this.profiles = response.fireGroundSettings.profiles;
    this.selectedProfile = this.profiles.find((profile: IProfile) => profile.isSelected);
    this.organizationSettingsForm.get('fireGroundSettings').patchValue(this.selectedProfile);
  }

  processRAMSettingsFormGroupData(): void {
    if (!this.checkIfUserHaveEquipmentModuleActive()) {
      this.organizationSettingsForm.removeControl('ramSettings');

      return;
    }

    const ramSettings: FormGroup = this.organizationSettingsForm.get('ramSettings') as FormGroup;

    if (this.checkIfUserHaveEquipmentAutomaticTestActive()) {
      ramSettings.addControl('automaticTestingWorkflow', new FormControl(null, [Validators.required]));

      return;
    }

    ramSettings.removeControl('automaticTestingWorkflow');
  }

  profileChange(selectedProfile: string): void {
    this.selectedProfile = this.profiles.find((profile: IProfile) => profile.name === selectedProfile);
    this.selectedProfile = { ...this.selectedProfile, isSelected: true };

    this.organizationSettingsForm.get('fireGroundSettings').patchValue(this.selectedProfile);
  }

  onSubmit(): void {
    this.organizationSettingsActions.requestUpdateOrganizationAccessControl(
      this.organizationSettingsForm.getRawValue(),
    );

    this.store
      .pipe(
        select(selectUpdatedOrganizationAccessControl),
        filter((uProfile: IStoreApiItem<IOrganizationAccessControl>) => !uProfile.isLoading),
        take(1),
      )
      .subscribe((uProfile: IStoreApiItem<IOrganizationAccessControl>) => {
        if (!uProfile.isSuccess) {
          this.successUpdateProfile = false;
          this.errors = uProfile.errors.error.code;

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            this.errors,
            ConfigurationNotificationConstants.notificationCodes,
          );
        } else {
          this.successUpdateProfile = true;

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            ConfigurationNotificationConstants.notificationCodes.ORGANIZATION_SETTINGS_SAVE_SUCCESS,
            ConfigurationNotificationConstants.notificationCodes,
          );

          this.organizationSettingsActions.requestOrganizationAccessControl();
          this.organizationSettingsForm.markAsPristine();
        }
      });
  }

  hasUnsavedData(): boolean {
    return this.organizationSettingsForm.dirty;
  }
}
