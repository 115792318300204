<div class="blue-bar-items" role="group">
  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    (click)="onSubmit($event)"
    id="bluebar-save"
    data-test-id="ba_wearer.save_btn"
    [disabled]="!form?.valid || !form?.dirty || isModalOpened || isSubmitDisabled"
    [pTooltip]="isSubmitted && !form?.valid ? saveButtonTooltipContent : null"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #saveButtonTooltipContent>
      <div data-test-id="ba_wearer.save_tooltip">
        @if (isSubmitted && !form?.valid) {
          {{ 'BA_WEARER.STR_SAVE_BTN_TOOLTIP' | translate }}
        }
      </div>
    </ng-template>

    <odx-icon size="inline" name="save"></odx-icon>
  </button>
</div>
