import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import remove from 'lodash-es/remove';
import { CommonConstants } from 'src/app/common';
import { ReportCenterConstants } from 'src/app/report-center/constants/report-center.constants';

@Component({
  selector: 'ignis-noteworthy-events-filter',
  templateUrl: './noteworthy-events-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteworthyEventsFilterComponent implements OnInit {
  occurredEventsList: any[];
  copyOfOccurredEventsList: any[];
  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;

  @Input() filterService: any;
  @Input() selectedOccurredEvents: any;

  @Output() handleSaveFilters: EventEmitter<any> = new EventEmitter();

  constructor(private translateService: TranslateService) {
    this.occurredEventsList = [];
  }

  ngOnInit(): void {
    this.occurredEventsList = ReportCenterConstants.incidentEntries.alarmTypes;
    this.occurredEventsList.push(
      { value: 'manual', label: 'Manual event', localizedName: 'INCIDENT_ALARM_TYPE.MANUAL_EVENT' },
      { value: 'media', label: 'Media Asset', localizedName: 'INCIDENT_ALARM_TYPE.MEDIA_ASSET' },
      { value: 'special_exposure', label: 'Special exposure', localizedName: 'INCIDENT_ALARM_TYPE.SPECIAL_EXPOSURE' },
      { value: '-', label: '', localizedName: 'REPORT_CENTER_TABLE.NO_NOTEWORTHY_EVENTS' },
    );

    this.occurredEventsList = this.occurredEventsList
      .filter((item: any) => !['thermal_exposure_alarm', 'low_battery', 'out_of_range'].includes(item.value))
      .filter((v: any, i: any, a: any) => a.findIndex((t: any) => t.value === v.value) === i);

    this.copyOfOccurredEventsList = this.occurredEventsList;

    this.occurredEventsList.forEach((event: any) => {
      ReportCenterConstants.incidentEntries.alarmTypes.forEach((storedEvent: any) => {
        if (event.value === storedEvent.value) {
          event.localizedName = this.translateService.instant(storedEvent.localizedName);
        }
      });
    });

    remove(this.occurredEventsList, { value: 'ecb_in_range' });
  }

  saveFilters(field: string, value: any): void {
    this.handleSaveFilters.emit({ name: field, data: value });
  }

  removeSelectedEvents(): void {
    this.selectedOccurredEvents = [];
    this.occurredEventsList = this.copyOfOccurredEventsList;
  }
}
