import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { EquipmentHistoryActionTypes } from '.';
import { IEquipmentHistoryEvent, IEquipmentHistoryFilters } from '../../../models/equipment-history.model';

export class RequestEquipmentHistory implements Action {
  readonly type: EquipmentHistoryActionTypes.REQUEST_EQUIPMENT_HISTORY =
    EquipmentHistoryActionTypes.REQUEST_EQUIPMENT_HISTORY;

  constructor(public payload: string) {}
}

export class ReceiveEquipmentHistory implements Action {
  readonly type: EquipmentHistoryActionTypes.RECEIVE_EQUIPMENT_HISTORY =
    EquipmentHistoryActionTypes.RECEIVE_EQUIPMENT_HISTORY;

  constructor(public payload: IEquipmentHistoryEvent[]) {}
}

export class ErrorReceiveEquipmentHistory implements Action {
  readonly type: EquipmentHistoryActionTypes.ERROR_RECEIVE_EQUIPMENT_HISTORY =
    EquipmentHistoryActionTypes.ERROR_RECEIVE_EQUIPMENT_HISTORY;

  constructor(public payload: HttpErrorResponse) {}
}

export class ResetEquipmentHistoryState implements Action {
  readonly type: EquipmentHistoryActionTypes.RESET_EQUIPMENT_HISTORY_STATE =
    EquipmentHistoryActionTypes.RESET_EQUIPMENT_HISTORY_STATE;
}

export class SaveHistoryFilterParams implements Action {
  readonly type: EquipmentHistoryActionTypes.SAVE_HISTORY_FILTER_PARAMS =
    EquipmentHistoryActionTypes.SAVE_HISTORY_FILTER_PARAMS;

  constructor(public payload: IEquipmentHistoryFilters) {}
}

export class ResetHistoryFilterParams implements Action {
  readonly type: EquipmentHistoryActionTypes.RESET_HISTORY_FILTER_PARAMS =
    EquipmentHistoryActionTypes.RESET_HISTORY_FILTER_PARAMS;
}
