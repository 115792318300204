import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CommonConstants } from 'src/app/common/constants/common.constants';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { RemoteMonitoringConstants } from 'src/app/remote-monitoring/constants/remote-monitoring.constants';
import { IncidentNotificationConstants } from 'src/app/report-center';
import { IIncident } from 'src/app/report-center/models/incident.model';
import { IMonitoringIncident, IMonitoringIncidents } from '../../../models/remote-monitoring.model';

@Component({
  selector: 'ignis-incidents-grid',
  templateUrl: './incidents-grid.component.html',
  styleUrls: ['./incidents-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncidentsGridComponent implements OnChanges {
  @Input() incidents: IMonitoringIncidents;
  @Input() isLoadingIncidents: boolean;
  @Input() formatDate: boolean;
  @Input() numberOfSelectedIncidents: number = 1;
  @Input() disableMonitorIncidentBtn: boolean;
  @Input() resetMerge: boolean = false;
  @Input() isModalOpened: boolean;
  incidentsList: IMonitoringIncident[];
  selectedIncidentIds: string[];
  selectedIncidentsData: IMonitoringIncident[];
  @Output() handleIncidentsIds: EventEmitter<string[]> = new EventEmitter();
  @Output() handleSelectedIncidentsData: EventEmitter<IMonitoringIncident[]> = new EventEmitter();
  @Output() resetMergeAction: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,
    public notificationsService: NotificationsService,
  ) {
    this.selectedIncidentIds = [];
    this.selectedIncidentsData = [];
    localStorage.removeItem(RemoteMonitoringConstants.selectedIncidents);
  }

  ngOnChanges(): void {
    if (this.incidents) {
      this.incidentsList = this.incidents?.incidents;
    }

    if (this.resetMerge) {
      this.selectedIncidentIds = [];
      this.selectedIncidentsData = [];
      localStorage.removeItem(RemoteMonitoringConstants.selectedIncidents);
    }
  }

  navigateToIncidentOverview(id: string): void {
    this.router.navigate(['remote-monitoring', 'incident-overview', id]);
  }

  resetMergeProcess(): void {
    this.resetMergeAction.emit();
  }

  trackByIncident(index: number, incident: IIncident): string {
    return `${index}-${incident.aggregateId}`;
  }

  getIncidentsToMerge(event: IMonitoringIncident): void {
    if (event === null) {
      this.selectedIncidentsData = [];
      this.storeSelectedIncidentsIds('');
    } else {
      if (this.selectedIncidentsData.length === 2) {
        this.selectedIncidentsData.shift();
      }

      this.selectedIncidentsData.push(event);
      this.storeSelectedIncidentsIds(event.aggregateId);
    }
  }

  storeSelectedIncidentsIds(event: string): void {
    this.selectedIncidentIds.push(event);

    if (this.selectedIncidentIds[0] === this.selectedIncidentIds[1] && this.disableMonitorIncidentBtn) {
      this.disableMonitorIncidentBtn = false;
      this.resetMergeProcess();
      this.selectedIncidentIds = [];
      this.selectedIncidentsData = [];
      this.notificationsService.requestShowNotification(
        CommonConstants.notificationType.WARNING,
        IncidentNotificationConstants.notificationCodes.INCIDENTS_MERGE_SAME_INCIDENT_ERROR,
        IncidentNotificationConstants.notificationCodes,
      );
    } else {
      if (this.selectedIncidentIds.length > this.numberOfSelectedIncidents) {
        this.selectedIncidentIds.shift();
      }
    }

    this.handleIncidentsIds.emit(this.selectedIncidentIds);
    this.handleSelectedIncidentsData.emit(this.selectedIncidentsData);
    localStorage.setItem(RemoteMonitoringConstants.selectedIncidents, JSON.stringify(this.selectedIncidentIds));
  }
}
