<router-outlet></router-outlet>
<ignis-progress-bar *ngIf="isLoading | async"></ignis-progress-bar>
<ngx-simplebar [options]="scrollbarOptions" class="custom-workflow-scrollbar scrollbar-offset">
  <div class="row workflow-container mb-3 g-0">
    <div class="col-auto" *ngIf="!hideSelectedWorkflowSection">
      <ignis-selected-workflow (handleSelectedWorkflow)="getSelectedWorkflow($event)"></ignis-selected-workflow>
    </div>
    <div class="location-container col-xl-4 col-lg-7 col-sm-11 ms-4">
      <ignis-directly-assign-location
        (handleSelectedLocation)="getSelectedLocation($event)"
      ></ignis-directly-assign-location>
    </div>
  </div>

  <ignis-search-barcode
    [isSubmitting]="isSubmitting"
    [selectedLocation]="selectedLocation"
    [_barcode]="barcode"
    [isSearchInputFocused]="isSearchInputFocused"
    (handleEnteredBarcode)="getEnteredBarcode($event)"
    (handleMobileLocation)="getMobileLocationData($event)"
    (handleSearchedEquipmentData)="getWorkflowEquipmentData($event)"
    (handleToUpdateEquipmentData)="updateEquipment()"
    (resetSearchInputFocus)="isSearchInputFocused = false"
  ></ignis-search-barcode>

  <ignis-warning-workflow-table-items
    *ngIf="isTableItemsLimitExceeded"
    [tableLimit]="maximumNumberOfWorkflowTableItems"
  ></ignis-warning-workflow-table-items>

  <ignis-table
    [ngClass]="{
      'display-scrollbar': equipmentList && equipmentList.length > 10,
    }"
    class="workflow-table"
    #ignisTableComponent
    [dataTable]="equipmentList"
    [tableName]="tableName"
    [isLoading]="isLoading | async"
    [localColumns]="localTableColumns"
    [resizableColumns]="true"
    [reorderableColumns]="true"
    [savedFiltersName]="tableFiltersKey"
    [multiSelect]="true"
    [resetPagining]="false"
    [sortColumn]="sortColumn"
    [dataKey]="'aggregateId'"
    [lazy]="'true'"
    [preselectedElements]="autoSelectedEquipment"
    (selectedElementsChange)="onSelectEquipment($event, 'table')"
    (handleDoubleClickRowSelection)="openSelectedEquipmentOnDblClick($event)"
    [customColumnsLayout]="[
      'generalData.rfid',
      'generalData.serialNo',
      'generalData.partNo',
      'generalData.partName',
      'generalData.operationalStatus',
      'generalData.description',
    ]"
    [customColumnsRef]="[rfid, serialNo, partNo, partName, operationalStatus, description]"
    (columns)="reloadTableColumnsData($event)"
    (handleTableRef)="getTableRef($event)"
    (handleOnSortChange)="onTableSort($event)"
    (handleColumnVisibility)="changeColumnVisibility($event)"
  >
    <ng-template #rfid let-rfid>
      <div *ngIf="rfid.field === 'generalData.rfid' && rfid.data">
        <ignis-table-ellipsis-data-column
          [columnData]="rfid"
          class="ellipsis-data-column"
        ></ignis-table-ellipsis-data-column>
      </div>
    </ng-template>

    <ng-template #serialNo let-serialNo>
      <div *ngIf="serialNo.field === 'generalData.serialNo' && serialNo.data">
        <ignis-table-ellipsis-data-column
          [columnData]="serialNo"
          class="ellipsis-data-column"
        ></ignis-table-ellipsis-data-column>
      </div>
    </ng-template>

    <ng-template #partNo let-partNo>
      <div *ngIf="partNo.field === 'generalData.partNo' && partNo.data">
        <ignis-table-ellipsis-data-column
          [columnData]="partNo"
          class="ellipsis-data-column"
        ></ignis-table-ellipsis-data-column>
      </div>
    </ng-template>

    <ng-template #partName let-partName>
      <div *ngIf="partName.field === 'generalData.partName' && partName.data">
        <ignis-table-ellipsis-data-column
          [columnData]="partName"
          class="ellipsis-data-column"
        ></ignis-table-ellipsis-data-column>
      </div>
    </ng-template>

    <ng-template #operationalStatus let-operationalStatus>
      <ignis-operational-status-column [operationalStatus]="operationalStatus"></ignis-operational-status-column>
    </ng-template>

    <ng-template #description let-description>
      <div *ngIf="description.field === 'generalData.description' && description.data">
        <ignis-table-ellipsis-data-column
          [columnData]="description"
          class="ellipsis-data-column"
        ></ignis-table-ellipsis-data-column>
      </div>
    </ng-template>
  </ignis-table>
</ngx-simplebar>

<ignis-confirm-start-cylinder-charge
  [isOpen]="isConfirmCloseModalOpen"
  (closeConfirmModal)="userResponseConfirmBanner($event)"
>
</ignis-confirm-start-cylinder-charge>

<ignis-search-mobile
  *ngIf="isMobileSearch"
  [mobileLocation]="mobileLocation"
  (closeMobileLocationModal)="closeMobileLocationModal($event)"
></ignis-search-mobile>

<footer class="blue-bar">
  <ignis-workflow-view-footer
    [selectedItem]="selectedEquipment ? selectedEquipment[0] : null"
    [selectedItems]="selectedEquipment"
    [multiple]="selectedEquipment?.length > 1"
    [isWorkflowInProgress]="isWorkflowInProgress"
    [disableCylinderChargerBtn]="disableCylinderChargerBtn"
    [disableChecklistBntFlow]="disableChecklistBntFlow"
    [displayNoChecklistTooltip]="displayNoChecklistTooltip"
    [isModalOpened]="isBlueBarButtonsDisabled"
    [isSubmitDisabled]="isSubmitting | async"
    [displayNoServiceIdTooltip]="displayNoServiceIdTooltip"
    (handleStartTesting)="startTest(null, 'workshop/workflow')"
    (handleEdit)="redirectToEquipmentPage()"
    (handleDelete)="removeEquipmentFromTable()"
    (handleCylinderCharge)="checkEquipmentsStatus()"
    (handlePerformCompleteChecklist)="completeChecklist()"
  ></ignis-workflow-view-footer>
</footer>

<ignis-ram-connector-auto-update-modal
  [ramConnectorStatus]="ramConnectorStatus"
  [startAutoUpdate]="startAutoUpdate"
  [type]="notificationType.ERROR"
  (handleConfirmationModal)="startRAMConnectorAutoUpdateFromWorkflowPage($event)"
  (handleAutoUpdateStartsWithSuccess)="startAutoUpdate = false"
>
  <div slot="content">
    {{ 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_PROTECTOR_SOFTWARE_VERSION_NOT_SUPPORTED' | translate }}
  </div>
</ignis-ram-connector-auto-update-modal>
