import { Location } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnChanges, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ModalRef, ModalService } from '@odx/angular/components/modal';
import { filter, take } from 'rxjs';
import { OnDestroyMixin } from 'src/app/common/mixins';
import { ProtectorSoftwareNotificationConstants } from '../../constants';
import { RAMActions } from '../../state/actions/ram';
import { selectRAMAutoUpdate } from '../../state/selectors';

import {
  CommonConstants,
  IApplicationState,
  INotificationType,
  IStoreApiItem,
  NotificationsService,
  NotificationTypes,
} from 'src/app/common';

@Component({
  selector: 'ignis-ram-connector-auto-update-modal',
  templateUrl: './ram-connector-auto-update-modal.component.html',
  styleUrl: './ram-connector-auto-update-modal.component.scss',
})
export class RamConnectorAutoUpdateModalComponent extends OnDestroyMixin() implements OnChanges {
  @Input() ramConnectorStatus: boolean | string;
  @Input() startAutoUpdate: boolean;
  @Input() type: NotificationTypes;

  @Output() handleConfirmationModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() handleAutoUpdateStartsWithSuccess: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('ramConnectorAutoUpdateModalTemplate', { read: TemplateRef })
  public ramConnectorAutoUpdateModalTemplate: TemplateRef<any>;

  modalReference: ModalRef<any>;
  isOpen: boolean;
  notificationType: INotificationType = CommonConstants.notificationType;

  ramActions: RAMActions = inject(RAMActions);
  router: Router = inject(Router);
  notificationsService: NotificationsService = inject(NotificationsService);
  location: Location = inject(Location);
  store: Store<IApplicationState> = inject<Store<IApplicationState>>(Store<IApplicationState>);
  modalService: ModalService = inject(ModalService);

  constructor() {
    super();
  }

  ngOnChanges(): void {
    if (
      this.ramConnectorStatus &&
      typeof this.ramConnectorStatus === 'string' &&
      (this.ramConnectorStatus ===
        ProtectorSoftwareNotificationConstants.notificationCodes.PROTECTOR_SOFTWARE_VERSION_NOT_SUPPORTED.value ||
        this.ramConnectorStatus ===
          ProtectorSoftwareNotificationConstants.notificationCodes.OUTDATED_RAM_CONNECTION_VERSION.value)
    ) {
      this.modalReference = this.modalService?.open(this.ramConnectorAutoUpdateModalTemplate, {
        size: 'small',
        dismissable: false,
      });
    }

    if (this.startAutoUpdate) {
      this.handleToStartRAMAutoUpdate();
    }
  }

  cancelChange(): void {
    this.handleConfirmationModal.emit(false);
  }

  proceedChange(): void {
    this.handleConfirmationModal.emit(true);
  }

  handleToStartRAMAutoUpdate(): void {
    this.ramActions.requestRAMAutoUpdate();

    this.store
      .pipe(
        select(selectRAMAutoUpdate),
        filter((autoUpdate: IStoreApiItem<object>) => !autoUpdate.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<object>) => {
        if (response.isSuccess) {
          this.handleAutoUpdateStartsWithSuccess.emit();

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            ProtectorSoftwareNotificationConstants.notificationCodes.RAM_AUTO_UPDATE_START,
            ProtectorSoftwareNotificationConstants.notificationCodes,
          );
        }
      });
  }
}
