import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IEntriesModel, IIncident, IIncidentPage } from 'src/app/report-center/models/incident.model';
import { IncidentActionTypes } from '../incident-action-types';

export class RequestIncidentExport implements Action {
  readonly type: IncidentActionTypes.REQUEST_INCIDENTS_EXPORT = IncidentActionTypes.REQUEST_INCIDENTS_EXPORT;

  constructor(public payload?: any) {}
}
export class RequestIncidentPage implements Action {
  readonly type: IncidentActionTypes.REQUEST_INCIDENTS = IncidentActionTypes.REQUEST_INCIDENTS;

  constructor(public payload?: any) {}
}
export class ReceiveIncidentPage implements Action {
  readonly type: IncidentActionTypes.RECEIVE_INCIDENTS = IncidentActionTypes.RECEIVE_INCIDENTS;

  constructor(public payload: IIncidentPage) {}
}
export class ErrorReceiveIncidentPage implements Action {
  readonly type: IncidentActionTypes.ERROR_RECEIVE_INCIDENTS = IncidentActionTypes.ERROR_RECEIVE_INCIDENTS;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestIncidentById implements Action {
  readonly type: IncidentActionTypes.REQUEST_INCIDENT_BY_ID = IncidentActionTypes.REQUEST_INCIDENT_BY_ID;

  constructor(public payload: string) {}
}
export class ReceiveIncidentById implements Action {
  readonly type: IncidentActionTypes.RECEIVE_INCIDENT_BY_ID = IncidentActionTypes.RECEIVE_INCIDENT_BY_ID;

  constructor(public payload: IIncident) {}
}
export class ErrorReceiveIncidentById implements Action {
  readonly type: IncidentActionTypes.ERROR_RECEIVE_INCIDENT_BY_ID = IncidentActionTypes.ERROR_RECEIVE_INCIDENT_BY_ID;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestIncidentEntries implements Action {
  readonly type: IncidentActionTypes.REQUEST_INCIDENT_ENTRIES = IncidentActionTypes.REQUEST_INCIDENT_ENTRIES;
}
export class ReceiveIncidentEntries implements Action {
  readonly type: IncidentActionTypes.RECEIVE_INCIDENT_ENTRIES = IncidentActionTypes.RECEIVE_INCIDENT_ENTRIES;

  constructor(public payload: IEntriesModel) {}
}
export class ErrorReceiveIncidentEntries implements Action {
  readonly type: IncidentActionTypes.ERROR_RECEIVE_INCIDENT_ENTRIES =
    IncidentActionTypes.ERROR_RECEIVE_INCIDENT_ENTRIES;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestUpdateIncident implements Action {
  readonly type: IncidentActionTypes.REQUEST_UPDATE_INCIDENT = IncidentActionTypes.REQUEST_UPDATE_INCIDENT;

  constructor(public payload: IIncident) {}
}
export class ReceiveUpdateIncident implements Action {
  readonly type: IncidentActionTypes.RECEIVE_UPDATE_INCIDENT = IncidentActionTypes.RECEIVE_UPDATE_INCIDENT;

  constructor(public payload: IIncident) {}
}
export class ErrorUpdateIncident implements Action {
  readonly type: IncidentActionTypes.ERROR_UPDATE_INCIDENT = IncidentActionTypes.ERROR_UPDATE_INCIDENT;

  constructor(public payload: HttpErrorResponse) {}
}
