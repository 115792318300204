// eslint-disable-next-line no-shadow
export enum LocationHierarchyActionTypes {
  REQUEST_LOCATION_HIERARCHY = 'REQUEST_LOCATION_HIERARCHY',
  RECEIVE_LOCATION_HIERARCHY = 'RECEIVE_LOCATION_HIERARCHY',
  ERROR_RECEIVE_LOCATION_HIERARCHY = 'ERROR_RECEIVE_LOCATION_HIERARCHY',

  REQUEST_ADD_LOCATION_HIERARCHY = 'REQUEST_ADD_LOCATION_HIERARCHY',
  RECEIVE_ADD_LOCATION_HIERARCHY = 'RECEIVE_ADD_LOCATION_HIERARCHY',
  ERROR_RECEIVE_ADD_LOCATION_HIERARCHY = 'ERROR_RECEIVE_ADD_LOCATION_HIERARCHY',

  REQUEST_CHANGE_LOCATION_HIERARCHY = 'REQUEST_CHANGE_LOCATION_HIERARCHY',
  RECEIVE_CHANGE_LOCATION_HIERARCHY = 'RECEIVE_CHANGE_LOCATION_HIERARCHY',
  ERROR_RECEIVE_CHANGE_LOCATION_HIERARCHY = 'ERROR_RECEIVE_CHANGE_LOCATION_HIERARCHY',

  REQUEST_EDIT_LOCATION_HIERARCHY = 'REQUEST_EDIT_LOCATION_HIERARCHY',
  RECEIVE_EDIT_LOCATION_HIERARCHY = 'RECEIVE_EDIT_LOCATION_HIERARCHY',
  ERROR_RECEIVE_EDIT_LOCATION_HIERARCHY = 'ERROR_RECEIVE_EDIT_LOCATION_HIERARCHY',

  REQUEST_DELETE_LOCATION_HIERARCHY = 'REQUEST_DELETE_LOCATION_HIERARCHY',
  RECEIVE_DELETE_LOCATION_HIERARCHY = 'RECEIVE_DELETE_LOCATION_HIERARCHY',
  ERROR_RECEIVE_DELETE_LOCATION_HIERARCHY = 'ERROR_RECEIVE_DELETE_LOCATION_HIERARCHY',
  RESET_DELETE_LOCATION_HIERARCHY = 'RESET_DELETE_LOCATION_HIERARCHY',

  REQUEST_SEARCH_LOCATION = 'REQUEST_SEARCH_LOCATION',
  RECEIVE_SEARCH_LOCATION = 'RECEIVE_SEARCH_LOCATION',
  ERROR_RECEIVE_SEARCH_LOCATION = 'ERROR_RECEIVE_SEARCH_LOCATION',
  RESET_SEARCH_LOCATION = 'RESET_SEARCH_LOCATION',
}
