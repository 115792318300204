export interface IEquipmentHistoryEvent {
  principalId: string;
  occurredAt: string;
  type: string;
  isLastEvent?: boolean;
  displayYear?: number;
  userNameOrId?: string;
}

export class EquipmentHistoryEvent implements IEquipmentHistoryEvent {
  principalId: string;
  occurredAt: string;
  type: string;
  isLastEvent?: boolean;
  displayYear?: number;
  userNameOrId?: string;

  constructor();
  constructor(historyEvent: IEquipmentHistoryEvent);
  constructor(historyEvent?: IEquipmentHistoryEvent) {
    if (!historyEvent) {
      this.principalId = null;
      this.occurredAt = null;
      this.type = null;
      this.isLastEvent = null;
      this.displayYear = null;
      this.userNameOrId = null;
    } else {
      this.principalId = historyEvent.principalId;
      this.occurredAt = historyEvent.occurredAt;
      this.type = historyEvent.type;
      this.isLastEvent = historyEvent.isLastEvent;
      this.displayYear = historyEvent.displayYear;
      this.userNameOrId = historyEvent.userNameOrId;
    }
  }
}

export interface IEquipmentHistoryEventType {
  value: string;
  name: string;
  icon: string;
  localizedName: string;
}

export interface IEquipmentHistoryFilters {
  isManualTestEvent: boolean;
  isProtectorTestEvent: boolean;
  isLocationChangeEvent: boolean;
  isServiceEvent: boolean;
  isAssignmentEvent: boolean;
  isGeneralDataChangeEvent: boolean;
  isEquipmentTasksChangedEvent: boolean;
  isIncidentUsageEvent: boolean;
  isCylinderChargedEvent: boolean;
  usage?: boolean;
  allTests?: boolean;
}

export class EquipmentHistoryFilters implements IEquipmentHistoryFilters {
  isManualTestEvent: boolean;
  isProtectorTestEvent: boolean;
  isLocationChangeEvent: boolean;
  isServiceEvent: boolean;
  isAssignmentEvent: boolean;
  isGeneralDataChangeEvent: boolean;
  isEquipmentTasksChangedEvent: boolean;
  isIncidentUsageEvent: boolean;
  isCylinderChargedEvent: boolean;
  usage?: boolean;
  allTests?: boolean;

  constructor();
  constructor(historyFilter: IEquipmentHistoryFilters);
  constructor(historyFilter?: IEquipmentHistoryFilters) {
    if (!historyFilter) {
      this.isManualTestEvent = false;
      this.isProtectorTestEvent = false;
      this.isLocationChangeEvent = false;
      this.isServiceEvent = false;
      this.isAssignmentEvent = false;
      this.isGeneralDataChangeEvent = false;
      this.isEquipmentTasksChangedEvent = false;
      this.isIncidentUsageEvent = false;
      this.isCylinderChargedEvent = false;
      this.usage = false;
      this.allTests = false;
    } else {
      this.isManualTestEvent = historyFilter.isManualTestEvent;
      this.isProtectorTestEvent = historyFilter.isProtectorTestEvent;
      this.isLocationChangeEvent = historyFilter.isLocationChangeEvent;
      this.isServiceEvent = historyFilter.isServiceEvent;
      this.isAssignmentEvent = historyFilter.isAssignmentEvent;
      this.isGeneralDataChangeEvent = historyFilter.isGeneralDataChangeEvent;
      this.isEquipmentTasksChangedEvent = historyFilter.isEquipmentTasksChangedEvent;
      this.isIncidentUsageEvent = historyFilter.isIncidentUsageEvent;
      this.isCylinderChargedEvent = historyFilter.isCylinderChargedEvent;
      this.usage = historyFilter.usage;
      this.allTests = historyFilter.allTests;
    }
  }
}
