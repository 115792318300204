import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ModalRef, ModalService, ModalSize, ModalVariant } from '@odx/angular/components/modal';
import { takeUntil } from 'rxjs/operators';
import { CommonConstants } from 'src/app/common/constants/common.constants';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { ApplicationState } from 'src/app/common/state/models/app.state.model';
import { checkMergeErrorNotification } from 'src/app/common/utils/general-helper/general-helpers';
import { IncidentNotificationConstants } from 'src/app/report-center';
import { RemoteMonitoringModuleRoutes } from '../../constants/remote-monitoring-module-routes.constants';
import { IIncidentsIds } from '../../models/remote-monitoring.model';
import { RemoteMonitoringActions } from '../../state/actions/remote-monitoring.actions';
import { selectMergeIncidents } from '../../state/selectors/remote-monitoring.selector';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'ignis-confirm-merge-remote-incidents',
  templateUrl: './confirm-merge-remote-incidents.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmMergeRemoteIncidentsComponent extends OnDestroyMixin() implements OnChanges {
  @ViewChild('confirmMergeRemoteIncidentsModal', { read: TemplateRef })
  public confirmMergeRemoteIncidentsModal: TemplateRef<any>;
  modalRef: ModalRef;
  @Input() openConfirmModal: boolean = false;
  @Input() incidentsToMerge: string[];
  @Input() incidentVersion: number;
  @Output() handleCloseConfirmMergeIncidentModal: EventEmitter<string> = new EventEmitter();

  private remoteMonitoringActions: RemoteMonitoringActions = inject(RemoteMonitoringActions);
  private modalService: ModalService = inject(ModalService);
  private router: Router = inject(Router);
  public notificationsService: NotificationsService = inject(NotificationsService);
  public store: Store<ApplicationState> = inject(Store<ApplicationState>);
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.openConfirmModal && this.openConfirmModal) {
      this.openConfirmMergeRemoteIncidentsModal();
    }
  }

  openConfirmMergeRemoteIncidentsModal(): void {
    this.modalRef = this.modalService.open(this.confirmMergeRemoteIncidentsModal, {
      size: ModalSize.SMALL,
      variant: ModalVariant.DEFAULT,
      dismissable: false,
      data: 'isOpen',
    });
  }

  proceedMerge(): void {
    const objForBE: IIncidentsIds = {
      sourceAggregateId: this.incidentsToMerge[0],
      destinationAggregateId: this.incidentsToMerge[1],
    };

    this.remoteMonitoringActions.requestMergeIncidents(objForBE, this.incidentVersion);
    this.handleCloseConfirmMergeIncidentModal.emit('merge');
    this.modalRef?.close('');

    this.handleMergeIncidents();
  }

  handleMergeIncidents(): void {
    this.store.pipe(select(selectMergeIncidents), takeUntil(this.destroy)).subscribe((response: any) => {
      checkMergeErrorNotification(response, this.notificationsService);

      if (response.isSuccess) {
        this.notificationsService.requestShowNotification(
          CommonConstants.notificationType.SUCCESS,
          IncidentNotificationConstants.notificationCodes.INCIDENTS_MERGE_SUCCESS,
          IncidentNotificationConstants.notificationCodes,
        );
        this.remoteMonitoringActions.requestMonitoringIncidents();
      }

      this.cdr.detectChanges();
    });
  }

  close(): void {
    this.handleCloseConfirmMergeIncidentModal.emit('close');
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();

    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (!this.router.url.includes(RemoteMonitoringModuleRoutes.remoteMonitoring) && this.modalRef?.data === 'isOpen') {
      this.modalRef.close('');
    }
  }
}
