<div
  *ngIf="!tableColumns"
  [odxLoadingSpinner]="!tableColumns"
  [odxLoadingSpinnerMinHeight]="60"
  class="app-loader"
></div>

<ignis-table-settings
  *ngIf="reorderableColumns"
  [tableColumns]="tableColumns"
  (changeColumnVisibility)="changeColumnVisibility($event)"
  [style.display]="tableColumns ? 'block' : 'none'"
></ignis-table-settings>

<p-table
  [style.display]="tableColumns ? 'block' : 'none'"
  #ignisTable
  styleClass="p-datatable-striped p-datatable-gridlines"
  class="fireground-table"
  scrollHeight="flex"
  [columns]="visibleColumns"
  [value]="dataTable"
  [resizableColumns]="resizableColumns"
  [reorderableColumns]="reorderableColumns"
  [columnResizeMode]="columnResizeMode"
  [scrollable]="true"
  [lazy]="lazy"
  [loading]="isLoading"
  [paginator]="paginator"
  [rows]="rows"
  [first]="first"
  [filterDelay]="filterDelay"
  [totalRecords]="totalRecords"
  [rowsPerPageOptions]="rowsPerPageOptions"
  [selectionMode]="multiSelect ? 'multiple' : 'single'"
  [selection]="selectedElements"
  [dataKey]="dataKey"
  (selectionChange)="onSelectChange($event)"
  (onSort)="onChangeSort($event)"
  (clickOutside)="removeSelectedRow()"
  (onColReorder)="onColumnReorder($event)"
  (onPage)="onPageAndRowChange($event)"
  (onLazyLoad)="onLazyLoadingData($event)"
  responsiveLayout="scroll"
>
  <ng-template pTemplate="header" let-columns>
    <tr class="table-header-column-title">
      <th scope="col" class="table-multiselect" *ngIf="multiSelect">
        <p-tableHeaderCheckbox
          [disabled]="disableRowCheckbox"
          [ngClass]="{ 'check-all-columns': displayFilters }"
          data-test-id="table.select_all"
        ></p-tableHeaderCheckbox>
      </th>
      <th
        *ngFor="let col of columns"
        pResizableColumn
        pReorderableColumn
        scope="col"
        [attr.data-test-id]="col.field"
        [style]="{ '--columnsCount': columns.length }"
        class="dynamic-column-width"
        [ngClass]="{
          'selected-sort-column': sortColumn?.field === col.field,
          'deselected-sort-column': sortColumn?.field !== col.field,
          'no-reorder-cursor': !reorderableColumns,
        }"
      >
        <span class="sort-area" [pSortableColumn]="col.field">
          {{ col.header | translate }}
          <ignis-sort-icon [columnName]="col.field" [columnSorted]="sortColumn"></ignis-sort-icon>
        </span>
      </th>
    </tr>

    <tr *ngIf="displayFilters" class="table-header-column-filter">
      <th scope="col" class="table-multiselect--second-line" *ngIf="multiSelect"></th>
      <th
        *ngFor="let col of columns; let i = index"
        scope="col"
        class="multiSelect-overflow"
        [style]="{ width: 'calc(100% /' + columns.length + ')' }"
        [ngClass]="{
          'selected-sort-column': sortColumn?.field === col.field,
          'deselected-sort-column': sortColumn?.field !== col.field,
        }"
      >
        <div
          scope="col"
          *ngFor="let customFilter of customFilterLayout"
          [ngClass]="{ 'w-100': col.field === customFilter }"
        >
          <ignis-table-text-filter
            [column]="col"
            [customFilter]="customFilter"
            [tableStoredFilters]="tableStoredFilters"
            (handleInputFilterValue)="onInputFilter($event)"
            (handleClearInputFilterValue)="onClearFilter($event)"
          ></ignis-table-text-filter>

          <ignis-table-datepicker-filter
            [column]="col"
            [customFilter]="customFilter"
            [datePickerFilterOptions]="{
              datePickerFormat,
              datePickerSelectionMode,
              readOnlyDatePicker,
              isDatePickerShowingTime,
              isDatePickerShowingSeconds,
              isDefaultRangeShown,
              activeDate,
            }"
            (handleDatePickerFilterValue)="onDateFilterSelect($event)"
            (handleDatePickerFilterChangedValue)="onDateFilterChange($event)"
          >
          </ignis-table-datepicker-filter>

          <ignis-table-multi-select-filter
            [column]="col"
            [customFilter]="customFilter"
            [multiSelectOptions]="multiSelectOptions"
            [selectActiveOptions]="selectActiveOptions"
            (handleMultiSelectionFilterValue)="onMultiSelectChange($event)"
            (handleRemovedMultiSelectFilterValue)="onMultiSelectRemoveSelectedItems($event)"
          >
          </ignis-table-multi-select-filter>

          <ignis-table-dropdown-filter
            [column]="col"
            [customFilter]="customFilter"
            [selectActiveOptions]="selectActiveOptions"
            (handleDropdownFilterValue)="onDropdownChange($event)"
          >
          </ignis-table-dropdown-filter>
        </div>
      </th>
    </tr>

    <div
      class="table-header-right-wrapper"
      [ngClass]="{
        'selected-sort-column': sortColumn?.field === lastTableElement,
        'deselected-sort-column': sortColumn?.field !== lastTableElement,
      }"
    ></div>
  </ng-template>
  <ng-template pTemplate="body" let-line let-columns="columns">
    <tr
      class="dynamic-column-width"
      [ngClass]="{
        'selected-row': selectedData?.aggregateId === line.aggregateId || selectedRow?.aggregateId === line.aggregateId,
      }"
      [pSelectableRow]="multiSelect ? line : undefined"
      (click)="onSelect(line, $event)"
    >
      <td *ngIf="multiSelect" class="table-multiselect" data-column="multiselect">
        <p-tableCheckbox [value]="line" [pSelectableRow]="line" [disabled]="disableRowCheckbox"></p-tableCheckbox>
      </td>
      <td
        *ngFor="let col of columns; let i = index; trackBy: trackByFn"
        [attr.data-column]="col.field"
        [style]="{ '--columnsCount': columns.length }"
      >
        <ng-container *ngIf="!customColumnsLayout?.includes(col.field)">
          {{ line[col.field] }}
        </ng-container>

        <ng-container *ngFor="let customColumn of customColumnsLayout; let i = index; trackBy: trackByFn">
          <ng-container *ngIf="col.field === customColumn">
            <ng-template ngFor let-template [ngForOf]="customColumnsRef">
              <ng-container
                *ngTemplateOutlet="template; context: { $implicit: { data: line[col.field], field: col.field } }"
              ></ng-container>
            </ng-template>
          </ng-container>
        </ng-container>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns?.length + 1" id="tableNoEntries" data-test-id="table.no_entries">
        {{ 'TABLE.STR_NO_ENTRIES_FOUND' | translate }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft">
    <span *ngIf="totalRecords">
      {{ totalRecords }}
      <span *ngIf="totalRecords > 1; else onlyOneItem">{{ 'TABLE.STR_ITEMS' | translate }}</span>
      <ng-template #onlyOneItem>
        {{ 'TABLE.STR_ITEM' | translate }}
      </ng-template>
      <span *ngIf="selectedElements?.length > 0">
        ({{ selectedElements.length }} {{ 'TABLE.STR_ITEMS_SELECTED' | translate }})
      </span>
    </span>
  </ng-template>
  <ng-template pTemplate="paginatorright" let-state>
    @if (state.pageCount > 0) {
      <span> {{ 'TABLE.STR_TABLE_OF' | translate: { totalPageNumber: state.pageCount } }}</span>
    } @else {
      <span>1 {{ 'TABLE.STR_TABLE_OF' | translate: { totalPageNumber: 1 } }}</span>
    }
  </ng-template>
</p-table>
