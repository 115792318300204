import { ITableColumn } from 'src/app/common';
import { TableFilterType } from 'src/app/common/constants';

export const tableColumns: ITableColumn[] = [
  {
    field: 'organizationName',
    header: 'CONFIGURATION_ADDRESS_TABLE.STR_ORGANIZATION',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'CONFIGURATION_ADDRESS_TABLE.STR_ORGANIZATION_PLACEHOLDER',
  },
  {
    field: 'contactName',
    header: 'CONFIGURATION_ADDRESS_TABLE.STR_CONTACT_NAME',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'CONFIGURATION_ADDRESS_TABLE.STR_CONTACT_NAME_PLACEHOLDER',
  },
  {
    field: 'email',
    header: 'CONFIGURATION_ADDRESS_TABLE.STR_EMAIL_ADDRESS',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'CONFIGURATION_ADDRESS_TABLE.STR_EMAIL_ADDRESS_PLACEHOLDER',
  },
  {
    field: 'phone',
    header: 'CONFIGURATION_ADDRESS_TABLE.STR_PHONE_NUMBER',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'CONFIGURATION_ADDRESS_TABLE.STR_PHONE_NUMBER_PLACEHOLDER',
  },
  {
    field: 'types',
    header: 'CONFIGURATION_ADDRESS_TABLE.STR_ADDRESS_TYPE',
    visible: true,
    filterType: TableFilterType.MULTI_SELECT,
    dropdownOptions: [
      {
        value: 'MANUFACTURER',
        label: 'CONFIGURATION_ADDRESS_TABLE.STR_MANUFACTURER',
      },
      {
        value: 'SUPPLIER',
        label: 'CONFIGURATION_ADDRESS_TABLE.STR_SUPPLIER',
      },
      {
        value: 'NONE',
        label: 'CONFIGURATION_ADDRESS_TABLE.STR_NONE',
      },
    ],
    filterPlaceholder: 'CONFIGURATION_ADDRESS_TABLE.STR_ADDRESS_TYPE_PLACEHOLDER',
  },
  {
    field: 'address',
    header: 'CONFIGURATION_ADDRESS_TABLE.STR_ADDRESS',
    visible: false,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'CONFIGURATION_ADDRESS_TABLE.STR_ADDRESS_PLACEHOLDER',
  },
  {
    field: 'comment',
    header: 'CONFIGURATION_ADDRESS_TABLE.STR_COMMENT',
    visible: false,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'CONFIGURATION_ADDRESS_TABLE.STR_COMMENT_PLACEHOLDER',
  },
];
