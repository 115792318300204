<ignis-progress-bar *ngIf="!displayMap"></ignis-progress-bar>

<div class="map-container">
  <div class="map-style-btn-group" *ngIf="displayMap">
    <button
      class="map-btn"
      (click)="changeMapView('roadmap')"
      [ngClass]="{ 'active-left-map-btn': mapOptionsSelected?.view === 'roadmap' }"
      data-test-id="map.map_view"
    >
      {{ 'MAP.STR_MAP_VIEW' | translate }}
    </button>
    <button
      class="map-btn"
      (click)="changeMapView('hybrid')"
      [ngClass]="{ 'active-left-map-btn': mapOptionsSelected?.view === 'hybrid' }"
      data-test-id="map.satellite_view"
    >
      {{ 'MAP.STR_SATELLITE_VIEW' | translate }}
    </button>
  </div>

  <div class="map-theme-btn-group" *ngIf="displayMap">
    <button
      class="map-btn custom-btn-width"
      (click)="changeMapId(lightMapID)"
      [ngClass]="{ 'active-map-btn': mapOptionsSelected?.mapId === lightMapID }"
      data-test-id="map.light_theme"
    >
      <odx-icon size="inline" name="brightness-3"></odx-icon>
      {{ 'MAP.STR_LIGHT_THEME' | translate }}
    </button>
    <button
      class="map-btn custom-btn-width"
      (click)="changeMapId(darkMapID)"
      [ngClass]="{ 'active-map-btn': mapOptionsSelected?.mapId === darkMapID }"
      data-test-id="map.dark_theme"
    >
      <odx-icon size="inline" name="night-mode"></odx-icon>
      {{ 'MAP.STR_DARK_THEME' | translate }}
    </button>
  </div>
  <div [ngClass]="{ 'loading-mask': !displayMap }"></div>
  <ng-container *ngIf="mapVisible">
    <google-map
      #map
      height="100%"
      width="100%"
      [options]="mapOptions"
      (zoomChanged)="zoomChanged()"
      (authFailure)="mapErrorHandling()"
      (onMapReady)="onMapReady($event)"
    >
    </google-map>
  </ng-container>
</div>
