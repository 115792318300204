import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CheckingSettingsModalsService } from 'src/app/common';
import { AppUserPermissionList } from 'src/app/root/models';
import { AccessControlService } from 'src/app/root/services';
import { DetectChangesInFormsService } from '../../../../common/services/detect-changes/detect-changes-in-forms.service';

@Component({
  selector: 'ignis-test-equipment-view-footer',
  templateUrl: './test-equipment-view-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestEquipmentViewFooterComponent implements AfterViewInit {
  @Input() form: FormGroup;
  @Input() isModalOpened: boolean;
  @Input() disableStartTestBtn: boolean;
  @Input() isSubmitDisabled: boolean = false;

  @Output() handleStartTesting: EventEmitter<void> = new EventEmitter<void>();
  @Output() handleTestingEquipmentBtn: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();

  @ViewChild('testingEquipmentBtn') private testingEquipmentBtn: ElementRef;

  permissions: string[] = [];
  appUserPermissionList: typeof AppUserPermissionList = AppUserPermissionList;

  destroyRef: DestroyRef = inject(DestroyRef);
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  public accessControlService: AccessControlService = inject(AccessControlService);
  private checkSettingsModal: CheckingSettingsModalsService = inject(CheckingSettingsModalsService);
  detectChangesInFormsService: DetectChangesInFormsService = inject(DetectChangesInFormsService);

  constructor() {
    this.permissions = this.accessControlService.permissions;

    this.checkSettingsModal.checkIfSettingsModalIsOpen().subscribe((isModalOpened: boolean) => {
      this.isModalOpened = isModalOpened;
      this.cdr.detectChanges();
    });
  }

  ngAfterViewInit(): void {
    this.handleTestingEquipmentBtn.emit(this.testingEquipmentBtn);

    this.detectChangesInFormsService.detectChangesInForms(this.form, this.cdr);
  }

  onStartTestingClick(e: Event): void {
    e.stopPropagation();
    this.handleStartTesting.emit();
  }
}
