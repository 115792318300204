import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ISaveBatchChecklist, ITask } from 'src/app/configuration/models';
import { ICylinderChargerRequest, IIntervalData } from 'src/app/workshop/models';
import { IEquipmentState } from '../../models';
import {
  RequestChecklistsData,
  RequestSaveBatchChecklist,
  RequestSaveServicesChecklist,
  RequestSendServiceIntervals,
  RequestServiceTaskExport,
  RequestServiceTaskPage,
  RequestUpdateEquipmentLocation,
  RequestUpdateEquipmentLocationDuringBarcodeSearch,
  ResetRequestChecklistsData,
  ResetSaveBatchChecklist,
  ResetSaveServicesChecklist,
  ResetSendServiceIntervals,
  ResetUpdateEquipmentLocation,
  SaveSelectedServiceEquipment,
} from './equipment-service-task-action-types-creators';

@Injectable({ providedIn: 'root' })
export class EquipmentServiceTaskActions {
  constructor(private store: Store<IEquipmentState>) {}

  public requestServiceTaskPage(params?: any, isExport: boolean = false): void {
    this.store.dispatch(isExport ? new RequestServiceTaskExport(params) : new RequestServiceTaskPage(params));
  }

  public saveSelectedServiceEquipment(serviceList: IIntervalData[]): void {
    this.store.dispatch(new SaveSelectedServiceEquipment(serviceList));
  }

  public requestSendServiceIntervals(services: ICylinderChargerRequest): void {
    this.store.dispatch(new RequestSendServiceIntervals(services));
  }

  public resetSentServiceIntervals(): void {
    this.store.dispatch(new ResetSendServiceIntervals());
  }

  public requestUpdateEquipmentLocation(movedEquipments: any): void {
    this.store.dispatch(new RequestUpdateEquipmentLocation(movedEquipments));
  }

  public requestUpdateEquipmentsLocationDuringTheBarcodeSearch(movedEquipments: any): void {
    this.store.dispatch(new RequestUpdateEquipmentLocationDuringBarcodeSearch(movedEquipments));
  }

  public resetUpdateEquipmentsLocation(): void {
    this.store.dispatch(new ResetUpdateEquipmentLocation());
  }

  public requestChecklistsData(ids: string[]): void {
    this.store.dispatch(new RequestChecklistsData(ids));
  }

  public resetRequestChecklistsData(): void {
    this.store.dispatch(new ResetRequestChecklistsData());
  }

  public requestSaveServicesChecklist(service: ITask | any): void {
    this.store.dispatch(new RequestSaveServicesChecklist(service));
  }

  public resetSaveServicesChecklistState(): void {
    this.store.dispatch(new ResetSaveServicesChecklist());
  }

  public requestSaveBatchChecklist(batchChecklistData: ISaveBatchChecklist): void {
    this.store.dispatch(new RequestSaveBatchChecklist(batchChecklistData));
  }

  public resetSaveBatchChecklistState(): void {
    this.store.dispatch(new ResetSaveBatchChecklist());
  }
}
