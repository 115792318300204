// eslint-disable-next-line no-shadow
export enum EquipmentHistoryActionTypes {
  REQUEST_EQUIPMENT_HISTORY = 'REQUEST_EQUIPMENT_HISTORY',
  RECEIVE_EQUIPMENT_HISTORY = 'RECEIVE_EQUIPMENT_HISTORY',
  ERROR_RECEIVE_EQUIPMENT_HISTORY = 'ERROR_RECEIVE_EQUIPMENT_HISTORY',
  RESET_EQUIPMENT_HISTORY_STATE = 'RESET_EQUIPMENT_HISTORY_STATE',

  SAVE_HISTORY_FILTER_PARAMS = 'SAVE_HISTORY_FILTER_PARAMS',
  RESET_HISTORY_FILTER_PARAMS = 'RESET_HISTORY_FILTER_PARAMS',
}
