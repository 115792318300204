import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { FilterService } from 'primeng/api';
import { Table } from 'primeng/table';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { TableExportExcelConstants } from 'src/app/common/constants/table-excel-export.constants';
import { IEntryModel, PropertyBag } from 'src/app/common/models/common.model';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { ITableColumn, ITableSettings } from 'src/app/common/models/table.model';
import { DropdownService } from 'src/app/common/services/dropdown/dropdown.service';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { INotificationMessage } from 'src/app/common/state/notifications/models/notification.model';
import { ExportExcelService } from 'src/app/report-center/services/export-excel.service';
import { AccessControlService } from 'src/app/root';
import { AppModulesTypes, AppUserPermissionList } from 'src/app/root/models/app-types';
import { IFeatureToggle } from 'src/app/root/state/features-toggle/models/features-toggle.model';
import { BAWearerActions } from '../../../ba-wearer';
import { selectFireStationList } from '../../../ba-wearer/state/selectors/ba-wearer.selector';
import { DeviceConnectionNotificationConstants } from '../../constants';
import { DeviceConnectionConstants } from '../../constants/device-connection.constants';
import { IDeviceConnection, IDevicePage } from '../../models/device-connection.model';
import { DeviceConnectionService } from '../../services/device-connection.service';
import { DeviceConnectionActions } from '../../state/actions/device-connection.actions';
import { tableColumns } from './table-columns';
import { readModelSortKeys, tableCustomFiltersLayout, tableDefaultSorting } from './table-settings';

import {
  CommonConstants,
  DeviceConnectionTableFiltersConstants,
  ExportTableExcelService,
  IFireStationList,
  IStoreApiList,
  StorageConstants,
  TableHelperComponent,
} from 'src/app/common';
import {
  formatLocaleTime,
  processEndDateForTableFilter,
  processStartDateForTableFilter,
} from 'src/app/common/utils/date-utils/date.utils';
import {
  selectDeletedDeviceConnection,
  selectDeviceConnection,
  selectDeviceConnectionPage,
} from '../../state/selectors/device-connection.selector';
import {
  selectDeviceConnectionAssignedFireStations,
  selectDeviceConnectionCategories,
} from './../../state/selectors/device-connection.selector';

/* eslint-disable no-prototype-builtins */

@Component({
  selector: 'ignis-device-connection-list',
  templateUrl: './device-connection-list.component.html',
  styleUrls: ['./device-connection-list.component.scss'],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceConnectionListComponent extends TableHelperComponent implements OnInit, OnChanges {
  @Input() formatDate: string;
  @Input() draegerwareWorkshopFeatureToggle: IFeatureToggle;
  @Input() workshopFeatureToggle: IFeatureToggle;
  @Input() ramConnectorFeatureToggle: IFeatureToggle;
  @Input() baWearerFeatureToggle: IFeatureToggle;

  deviceConnectionsPage: IDevicePage;
  deviceConnectionsList: IDeviceConnection[];
  activateClickOutside: boolean = true;

  fireStations: string[];

  categoriesList: Partial<IEntryModel>[];
  assignedFireStationsList: Partial<IEntryModel>[];
  activeDeviceConnection: IDeviceConnection;
  selectedDeviceConnectionData: IDeviceConnection;
  selectedDeviceConnection: Observable<IDeviceConnection>;

  areFiltersValuesLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  filtersValuesLoadedObs$: Observable<boolean> = this.areFiltersValuesLoadedSubject.asObservable();

  datePipe: DatePipe = inject(DatePipe);
  exportExcelService: ExportExcelService = inject(ExportExcelService);
  deviceConnectionsActions: DeviceConnectionActions = inject(DeviceConnectionActions);
  baWearerActions: BAWearerActions = inject(BAWearerActions);
  dropdownService: DropdownService = inject(DropdownService);
  exportTableExcelService: ExportTableExcelService = inject(ExportTableExcelService);
  filterService: FilterService = inject(FilterService);
  notificationsService: NotificationsService = inject(NotificationsService);
  translateService: TranslateService = inject(TranslateService);
  accessControlService: AccessControlService = inject(AccessControlService);
  deviceConnectionService: DeviceConnectionService = inject(DeviceConnectionService);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  errors: any;
  openConfirmationDeleteDialog: boolean = false;
  toggleTable: boolean = false;

  tableExportList: IDeviceConnection[] = [];
  public tableRef: Table | any = null;

  defaultSorting: string = tableDefaultSorting;
  tableFiltersKey: string = StorageConstants.tablesStorageKeys.DEVICE_CONNECTIONS;

  tableName: string = AppModulesTypes.deviceConnections;
  readModelSortKeys: PropertyBag = readModelSortKeys;
  customFilterLayouts: string[] = tableCustomFiltersLayout;
  localTableColumns: ITableColumn[] = tableColumns;
  excelTranslationKeys: PropertyBag = TableExportExcelConstants.deviceManagementSheetTranslationKeys;
  deviceCategoryNotificationMap: { [category: string]: INotificationMessage } =
    DeviceConnectionConstants.deviceCategoryNotificationMap;

  constructor(
    private router: Router,
    private store: Store<IApplicationState>,
  ) {
    super();

    router?.events?.subscribe(() => {
      if (router.url === '/device-connection') {
        this.deviceConnectionsActions.selectDeviceConnection(null);
      }
    });

    this.tableHelperReadSavedFiltersValues(
      DeviceConnectionTableFiltersConstants.selectedDropdownsFilterValues,
      DeviceConnectionTableFiltersConstants.selectedDatesFilterValues,
    );
  }

  ngOnInit(): void {
    this.deviceConnectionsActions.requestDeviceConnectionCategories();
    this.deviceConnectionsActions.requestDeviceConnectionAssignedFireStations();

    this.readDeleteDeviceConnectionResponseFromStore();
    this.processDeviceConnectionCategoriesAndAssignedFireStationsOptions();
    this.toggleAssignedFireStationColumnByFeatureToggle();

    this.selectedDeviceConnection = this.store.pipe(
      select(selectDeviceConnection),
      map((state: IStoreApiItem<IDeviceConnection>) => state.data),
      takeUntil(this.destroy),
    );

    const hasBAWearerAccess: boolean = this.accessControlService.checkPermission(AppUserPermissionList.baWearer);
    const isBAWearerToggled: boolean = this.accessControlService.baWearerFeatureToggle;

    if (isBAWearerToggled && hasBAWearerAccess) {
      this.baWearerActions.requestFireStationList();
    }

    this.store
      .pipe(
        select(selectDeviceConnectionPage),
        map((state: IStoreApiItem<IDevicePage>) => state.data),
        takeUntil(this.destroy),
      )
      .subscribe((response: IDevicePage) => {
        if (response) {
          this.processDevicePageData(response);
        }
      });

    this.isLoading$ = this.store.pipe(
      select(selectDeviceConnectionPage),
      takeUntil(this.destroy),
      map((state: IStoreApiItem<IDevicePage>) => state.isLoading),
    );
  }

  ngOnChanges(): void {
    if (!this.draegerwareWorkshopFeatureToggle?.isEnabled || !this.ramConnectorFeatureToggle?.isEnabled) {
      this.selectedDropdowns = {};
    }

    this.localTableColumns = [];
    this.toggleTable = false;

    this.processRAMLicenseTableColumns();
    this.processFireGroundLicenseTableColumns();
    this.processRAMandFireGroundLicensesOrEmptyLicensesTableColumns();
  }

  processDeviceConnectionCategoriesAndAssignedFireStationsOptions(): void {
    combineLatest([
      this.store.select(selectDeviceConnectionCategories),
      this.store.select(selectDeviceConnectionAssignedFireStations),
      this.store.select(selectFireStationList),
    ])
      .pipe(
        filter(
          ([categories, assignedFireStations, baWearerFireStations]: [
            IStoreApiList<Partial<IEntryModel[]>>,
            IStoreApiList<IFireStationList>,
            IStoreApiList<IFireStationList>,
          ]) => !categories.isLoading && !assignedFireStations.isLoading && !baWearerFireStations.isLoading,
        ),
        takeUntil(this.destroy),
      )
      .subscribe(
        ([categories, assignedFireStations, baWearerFireStations]: [
          IStoreApiList<Partial<IEntryModel[]>>,
          IStoreApiList<IFireStationList>,
          IStoreApiList<IFireStationList>,
        ]) => {
          if (categories.data) {
            this.processCategoryList(categories.data);
          }

          this.fireStations = this.deviceConnectionService.mergeFireStationsFromBAWearerAndDeviceConnection(
            baWearerFireStations.data?.fireStations,
            assignedFireStations.data?.fireStations,
          );
          this.processAssignedFireStationsList(this.fireStations);

          this.tableHelperReadSavedFiltersValues(
            DeviceConnectionTableFiltersConstants.selectedDropdownsFilterValues,
            DeviceConnectionTableFiltersConstants.selectedDatesFilterValues,
          );

          this.areFiltersValuesLoadedSubject.next(true);

          this.cdr.detectChanges();
        },
      );
  }

  processRAMLicenseTableColumns(): void {
    const defaultColumnsForRAMLicense: string[] = ['category.value', 'name', 'displayedRegistration'];

    if (this.workshopFeatureToggle?.isEnabled && !this.baWearerFeatureToggle?.isEnabled) {
      this.localTableColumns = tableColumns.map((column: ITableColumn) => {
        return {
          ...column,
          visible: defaultColumnsForRAMLicense.includes(column.field),
        };
      });

      this.toggleTable = true;
      this.cdr.detectChanges();
    }
  }

  processRAMandFireGroundLicensesOrEmptyLicensesTableColumns(): void {
    const defaultColumnsForBothLicense: string[] = [
      'category.value',
      'identification',
      'name',
      'displayedRegistration',
    ];

    if (this.workshopFeatureToggle?.isEnabled && this.baWearerFeatureToggle?.isEnabled) {
      this.localTableColumns = tableColumns.map((column: ITableColumn) => {
        return {
          ...column,
          visible: defaultColumnsForBothLicense.includes(column.field),
        };
      });

      this.toggleTable = true;
      this.cdr.detectChanges();
    }
  }

  processFireGroundLicenseTableColumns(): void {
    if (!this.workshopFeatureToggle?.isEnabled && this.baWearerFeatureToggle?.isEnabled) {
      this.localTableColumns = tableColumns;

      this.toggleTable = true;
      this.cdr.detectChanges();
    }
  }

  toggleAssignedFireStationColumnByFeatureToggle(): void {
    const field: ITableColumn = this.localTableColumns.find(
      (column: ITableColumn) => column.field === 'assignedFireStation',
    );

    if (field) {
      field.visible = this.accessControlService.baWearerFeatureToggle;
    }
  }

  processCategoryList(categories: Partial<IEntryModel[]>): void {
    this.categoriesList = categories.map((entry: IEntryModel) => ({
      ...entry,
      label: this.translateService.instant(
        DeviceConnectionConstants.deviceConnectionCategories.find((t: IEntryModel) => t.value === entry.value)
          ?.localizedName || entry.value,
      ),
      icon: DeviceConnectionConstants.deviceConnectionCategories.find((t: IEntryModel) => t.value === entry.value)
        ?.icon,
    }));
  }

  processAssignedFireStationsList(fireStations: string[]): void {
    this.assignedFireStationsList = Array.from(new Set(fireStations)).map((entry: string) => ({
      label: entry,
      value: entry,
    }));

    if (this.assignedFireStationsList.length > 0) {
      this.assignedFireStationsList.push({
        label: this.translateService.instant('DEVICE_CONNECTION_TABLE.NO_FIRE_STATION_ASSIGNED_OPTION'),
        value: '-',
      });
    }
  }

  processDevicePageData(devicePage: IDevicePage) {
    this.deviceConnectionsPage = structuredClone(devicePage);

    this.deviceConnectionsPage.entries.forEach((deviceConnections: IDeviceConnection) => {
      deviceConnections.registrationTime = deviceConnections.registrationTimestamp
        ? formatLocaleTime(new Date(deviceConnections.registrationTimestamp))
        : null;
      deviceConnections.lastUploadTime = deviceConnections.lastUploadTimestamp
        ? formatLocaleTime(new Date(deviceConnections.lastUploadTimestamp))
        : null;

      deviceConnections.displayedRegistration = deviceConnections.registrationTimestamp
        ? `${this.datePipe.transform(deviceConnections.registrationTimestamp, this.formatDate)} ${
            deviceConnections.registrationTime
          }`
        : '';
      deviceConnections.displayedLastUpload = deviceConnections.lastUploadTimestamp
        ? `${this.datePipe.transform(deviceConnections.lastUploadTimestamp, this.formatDate)} ${
            deviceConnections.lastUploadTime
          }`
        : '';
    });

    this.totalRecords = this.deviceConnectionsPage.totalRecords;
    this.totalPages = this.deviceConnectionsPage.totalPages;

    this.tableHelperCheckOutOfRangePageFilter();

    this.deviceConnectionsList = this.deviceConnectionsPage.entries;
    this.cdr.detectChanges();
  }

  fetchTableData(params: any): void {
    this.deviceConnectionsActions.requestDeviceConnectionPage(params, this.isExportMode);
  }

  onDeviceConnectionSelect(deviceConnections: IDeviceConnection): void {
    this.activeDeviceConnection = deviceConnections;
    this.deviceConnectionsActions.selectDeviceConnection(deviceConnections);
  }

  openCreateDeviceConnectionModal(): void {
    this.deviceConnectionsActions.selectDeviceConnection(null);
    this.router.navigate(['device-connection', 'create']);
  }

  openUpdateDeviceConnectionModal(): void {
    this.selectedDeviceConnection.pipe(take(1)).subscribe((selectedDeviceConnection: IDeviceConnection) => {
      const selectedDeviceConnectionId: string = selectedDeviceConnection.aggregateId;

      this.router.navigate(['device-connection', 'update', selectedDeviceConnectionId]);
      this.cdr.detectChanges();
    });
  }

  processingTableSettings(settings: ITableSettings): void {
    this.rows = settings.pageSize;

    this.filtersValuesLoadedObs$.pipe(takeUntil(this.destroy)).subscribe((loaded: boolean) => {
      if (loaded) {
        settings.columns.forEach((column: Partial<ITableColumn>) => {
          switch (column.field) {
            case 'assignedFireStation':
              column.dropdownOptions = [...this.assignedFireStationsList];
              break;
            case 'category.value':
              column.dropdownOptions = [...this.categoriesList];
              break;
          }
        });

        this.tableColumns = structuredClone(settings.columns);

        this.tableHelperReadSavedFiltersValues(
          DeviceConnectionTableFiltersConstants.selectedDropdownsFilterValues,
          DeviceConnectionTableFiltersConstants.selectedDatesFilterValues,
        );

        this.tableHelperPrepareTableParameters();
      }
    });
  }

  changeColumnVisibility(columns: ITableColumn[]): void {
    this.tableHelperResetTablePaging();

    columns.forEach((column: ITableColumn) => {
      if (!column.visible) {
        if (this.tableHelperCheckForSorting(column.field)) {
          this.tableHelperResetTableSorting();
        }

        this.tableHelperHideAndResetTableColumn(this.localTableColumns, column);
      }
    });

    this.tableHelperReadSavedFiltersValues(
      DeviceConnectionTableFiltersConstants.selectedDropdownsFilterValues,
      DeviceConnectionTableFiltersConstants.selectedDatesFilterValues,
    );
    this.tableHelperSaveTableSettings(columns);
    this.tableHelperSetAllTableFilters(this.filtersData);

    this.tableHelperPrepareTableParameters();
  }

  getFilterTableValue(event: any): void {
    if ('category.value' in event) {
      event = { category: event['category.value'] };
    } else if ('displayedRegistration' in event) {
      event = this.processTimeStampFilter(
        event,
        'displayedRegistration',
        'minRegistrationTimestamp',
        'maxRegistrationTimestamp',
      );
    } else if ('displayedLastUpload' in event) {
      event = this.processTimeStampFilter(
        event,
        'displayedLastUpload',
        'minLastUploadTimestamp',
        'maxLastUploadTimestamp',
      );
    }

    Object.assign(this.filtersData, event);

    this.tableHelperSetAllTableFilters(this.filtersData);
  }

  processTimeStampFilter(event: any, oldParamName: string, minParamName: string, maxParamName: string): any {
    if (oldParamName in event && event[oldParamName] && event[oldParamName][1]) {
      if (Object.values(event)[0] === 'reset-date') {
        return {
          [oldParamName]: null,
          [minParamName]: null,
          [maxParamName]: null,
        };
      } else {
        return {
          ...this.tableService.tableDateParameter(
            processStartDateForTableFilter(new Date(Object.values(event)[0][0])),
            minParamName,
          ),
          ...this.tableService.tableDateParameter(
            processEndDateForTableFilter(new Date(Object.values(event)[0][1])),
            maxParamName,
          ),
        };
      }
    }
  }

  openDeleteDeviceConnectionDialog(): void {
    this.openConfirmationDeleteDialog = true;
    this.activateClickOutside = false;
  }

  closeDeleteDeviceConnectionDialog(confirmation: boolean): void {
    if (confirmation) {
      this.deleteDeviceConnection();

      return;
    }

    this.openConfirmationDeleteDialog = false;
    this.activateClickOutside = true;
  }

  deleteDeviceConnection(): void {
    this.selectedDeviceConnection.pipe(take(1)).subscribe((selectedDeviceConnection: IDeviceConnection) => {
      this.selectedDeviceConnectionData = selectedDeviceConnection;
      this.deviceConnectionsActions.requestDeviceConnectionDelete(selectedDeviceConnection);
      this.cdr.detectChanges();
    });
  }

  readDeleteDeviceConnectionResponseFromStore(): void {
    this.store
      .pipe(
        select(selectDeletedDeviceConnection),
        filter((deviceConnections: IStoreApiItem<IDeviceConnection>) => !deviceConnections.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((deviceConnections: IStoreApiItem<IDeviceConnection>) => {
        if (this.selectedDeviceConnectionData) {
          const category: string = (this.selectedDeviceConnectionData.category as IEntryModel).value;

          if (deviceConnections.isSuccess) {
            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.SUCCESS,
              this.deviceCategoryNotificationMap[category],
              DeviceConnectionNotificationConstants.notificationCodes,
              {
                name: this.selectedDeviceConnectionData.name ?? '',
                category,
              },
            );
            this.tableHelperPrepareTableParameters();
            this.onDeviceConnectionSelect(null);

            return;
          }

          if (deviceConnections.errors) this.errors = deviceConnections.errors?.error.code;

          if (
            this.errors.toString() ===
              DeviceConnectionNotificationConstants.notificationCodes
                .RAM_CONNECTOR_CANNOT_BE_DELETED_BECAUSE_OF_MIGRATION.value &&
            category === DeviceConnectionConstants.deviceCategories.RAM_CONNECTOR
          ) {
            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.ERROR,
              DeviceConnectionNotificationConstants.notificationCodes
                .RAM_CONNECTOR_CANNOT_BE_DELETED_BECAUSE_OF_MIGRATION,
              DeviceConnectionNotificationConstants.notificationCodes,
            );

            return;
          }

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            this.errors?.toString(),
            DeviceConnectionNotificationConstants.notificationCodes,
            {
              name: this.selectedDeviceConnectionData.name,
              category: (this.selectedDeviceConnectionData.category as IEntryModel).value,
            },
          );
        }

        this.cdr.detectChanges();
      });
  }

  getUpdatedTableColumns(event): void {
    this.tableColumns = event.columns;
  }

  exportExcelFile(): void {
    this.isExportMode = true;
    this.exportExcelService.genericExport(this.getDataForExport, this.preparationForExport).then((data: unknown[]) => {
      this.excelArray = data;
      this.isExportMode = false;

      this.cdr.detectChanges();
    });
  }

  preparationForExport = (deviceConnection: IDeviceConnection | any) => {
    deviceConnection['category.value'] = this.translateService.instant(
      DeviceConnectionConstants.deviceConnectionCategories.find(
        (t: IEntryModel) => t.value === deviceConnection.category.value,
      )?.localizedName || deviceConnection.category.value,
    );

    deviceConnection.displayedRegistration = this.exportTableExcelService.formatExcelDataToLocal(
      deviceConnection.registrationTimestamp,
      this.formatDate,
    );
    deviceConnection.displayedLastUpload = this.exportTableExcelService.formatExcelDataToLocal(
      deviceConnection.lastUploadTimestamp,
      this.formatDate,
    );

    deviceConnection.registrationTimestamp = this.exportTableExcelService.formatExcelDataToLocal(
      deviceConnection.registrationTimestamp,
      this.formatDate,
    );
    deviceConnection.lastUploadTimestamp = this.exportTableExcelService.formatExcelDataToLocal(
      deviceConnection.lastUploadTimestamp,
      this.formatDate,
    );
  };

  getDataForExport = (page: number = 0): void => {
    this.tableHelperPrepareTableParameters(page);
  };

  excelExportDone(): void {
    this.tableHelperExcelExportDone();

    this.deviceConnectionsActions.resetDeviceConnectionPage();
  }
}
