// eslint-disable-next-line no-shadow
export enum RAMActionTypes {
  REQUEST_RAM_CONNECTOR_TOKEN = 'REQUEST_RAM_CONNECTOR_TOKEN',
  RECEIVE_RAM_CONNECTOR_TOKEN = 'RECEIVE_RAM_CONNECTOR_TOKEN',
  ERROR_RECEIVE_RAM_CONNECTOR_TOKEN = 'ERROR_RECEIVE_RAM_CONNECTOR_TOKEN',

  REQUEST_CHECK_RAM_CONNECTOR_STATUS = 'REQUEST_CHECK_RAM_CONNECTOR_STATUS',
  RECEIVE_CHECK_RAM_CONNECTOR_STATUS = 'RECEIVE_CHECK_RAM_CONNECTOR_STATUS',
  ERROR_RECEIVE_CHECK_RAM_CONNECTOR_STATUS = 'ERROR_RECEIVE_CHECK_RAM_CONNECTOR_STATUS',

  REQUEST_CHECK_RAM_PORT_STATUS = 'REQUEST_CHECK_RAM_PORT_STATUS',
  RECEIVE_CHECK_RAM_PORT_STATUS = 'RECEIVE_CHECK_RAM_PORT_STATUS',
  ERROR_RECEIVE_CHECK_RAM_PORT_STATUS = 'ERROR_RECEIVE_CHECK_RAM_PORT_STATUS',

  REQUEST_RAM_AUTO_UPDATE = 'REQUEST_RAM_AUTO_UPDATE',
  RECEIVE_RAM_AUTO_UPDATE = 'RECEIVE_RAM_AUTO_UPDATE',
  ERROR_RECEIVE_RAM_AUTO_UPDATE = 'ERROR_RECEIVE_RAM_AUTO_UPDATE',
}
