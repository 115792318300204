import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TreeDragDropService } from 'primeng/api';
import { Tree } from 'primeng/tree';
import { combineLatest, filter, map, Observable, take, takeUntil } from 'rxjs';
import { CommonConstants, DropdownService, IApplicationState, IStoreApiItem, IStoreApiList } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { INotificationMessage } from 'src/app/common/state/notifications/models/notification.model';
import { UserNotificationConstants } from 'src/app/user-management/constants';
import { ConfigurationNotificationConstants, LocationHierarchyNotificationConstants } from '../../constants';
import { ConfigurationConstants } from '../../constants/configuration.constants';
import { LocationConfigActions, LocationHierarchyActions } from '../../state/actions';
import {
  IEditLocationHierarchy,
  ILocationHierarchy,
  ILocationHierarchyChange,
} from './../../models/location-configuration.model';
import {
  selectDeletedLocationHierarchy,
  selectedLocationConfiguration,
  selectLocationHierarchy,
  selectLocationHierarchyChange,
} from './../../state/selectors/configuration.selectors';

@Component({
  selector: 'ignis-location-hierarchy',
  templateUrl: './location-hierarchy.component.html',
  styleUrls: ['./location-hierarchy.component.scss'],
  providers: [TreeDragDropService],
})
export class LocationHierarchyComponent extends OnDestroyMixin() implements OnInit {
  @ViewChild('locationTree')
  public locationTree: Partial<Tree>;

  hierarchyData: Partial<ILocationHierarchy[]>;
  isLoading: Observable<boolean>;
  selectedTreeNode: any;
  storedSelectedTree: any;
  disableAddBtn: boolean = false;
  disableEditBtnAndDelete: boolean = true;
  openConfirmationDeleteDialog: boolean = false;
  activateClickOutside: boolean = true;
  httpCustomErrorCode: Partial<string>;
  navigationHistory: string[];

  dropdownService: DropdownService = inject(DropdownService);
  locationConfigActions: LocationConfigActions = inject(LocationConfigActions);
  locationHierarchyActions: LocationHierarchyActions = inject(LocationHierarchyActions);
  notificationsService: NotificationsService = inject(NotificationsService);
  router: Router = inject(Router);
  store: Store<IApplicationState> = inject(Store<IApplicationState>);

  constructor() {
    super();

    this.navigationHistory = [];

    this.router.events?.subscribe((event: any) => {
      this.navigationHistory.push(event.url);
      this.navigationHistory = [...new Set(this.navigationHistory)].filter((elem: any) => {
        return elem !== undefined;
      });

      this.navigationHistory.forEach((elem: string) => {
        if (elem.includes('update-location') && this.navigationHistory.length > 1) {
          setTimeout(() => {
            this.selectedTreeNode = this.storedSelectedTree;
            this.disableAddBtn = false;
          }, 0);

          this.navigationHistory = [];
        }
      });
    });

    this.hierarchyData = [];
  }

  ngOnInit(): void {
    this.locationHierarchyActions.requestLocationHierarchy();

    this.isLoading = combineLatest([
      this.store.select(selectLocationHierarchy),
      this.store.select(selectLocationHierarchyChange),
    ]).pipe(
      takeUntil(this.destroy),
      map(
        ([locationState, changeState]: [
          IStoreApiList<ILocationHierarchy[]>,
          IStoreApiItem<ILocationHierarchyChange>,
        ]) => locationState.isLoading || changeState.isLoading,
      ),
    );

    this.store
      .pipe(
        select(selectLocationHierarchy),
        filter((state: IStoreApiList<ILocationHierarchy[]>) => !state.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((response: IStoreApiList<ILocationHierarchy[]>) => {
        if (response.data) {
          this.hierarchyData = structuredClone(response.data);
          this.dropdownService.findTreeNodeAndAddIcon(this.hierarchyData);

          const parent: string = localStorage.getItem(ConfigurationConstants.tempParentAggregateId);
          const origin: string = localStorage.getItem('originAggregateId');

          for (const hierarchy of this.hierarchyData) {
            if (this.checkTreeNode(hierarchy, parent)) {
              this.expandLocationTreeRecursive(hierarchy, true, parent);
              localStorage.removeItem(ConfigurationConstants.tempParentAggregateId);
            }

            if (this.checkTreeNode(hierarchy, origin)) {
              this.expandLocationTreeRecursive(hierarchy, true, origin);
              localStorage.removeItem('originAggregateId');
            }
          }

          if ('newLocationIsAdded' in localStorage) {
            this.disableAddBtn = false;
            localStorage.removeItem('newLocationIsAdded');
          }
        }
      });
  }

  expandLocationTreeRecursive(node: any, isExpand: boolean, storedValue: string = null): void {
    if (node?.data?.aggregateId === storedValue) {
      node.expanded = isExpand;

      node.children.forEach((child: any) => {
        if (child.label === localStorage.getItem('newLocationIsAdded')) {
          this.nodeSelect({ node: child });
        }
      });

      return;
    } else {
      node.expanded = isExpand;
    }

    if (node.children) {
      node.children.forEach((childNode: any) => {
        this.expandLocationTreeRecursive(childNode, isExpand, storedValue);
      });
    }
  }

  checkTreeNode(nodeToBeChecked: any, idToBeFound: string): boolean {
    if (nodeToBeChecked.data?.aggregateId === idToBeFound) {
      return true;
    } else if (nodeToBeChecked.children) {
      for (const node of nodeToBeChecked.children) {
        if (this.checkTreeNode(node, idToBeFound)) {
          return true;
        }
      }
    }

    return false;
  }

  createNewLocationHierarchy(): void {
    this.router.navigate(['configuration', 'location', 'add-location', this.determineAggregate()]);
  }

  determineAggregate(): string {
    if (!this.selectedTreeNode) {
      return ConfigurationConstants.rootNode;
    }

    return (
      this.selectedTreeNode.data?.aggregateId ||
      this.selectedTreeNode.parent?.data?.aggregateId ||
      ConfigurationConstants.rootNode
    );
  }

  nodeSelect(event: any): void {
    this.selectedTreeNode = event.node;
    this.storedSelectedTree = this.selectedTreeNode;
    this.disableAddBtn = false;
    this.disableEditBtnAndDelete = false;

    if (!this.selectedTreeNode?.data) {
      this.disableEditBtnAndDelete = true;
    }

    localStorage.setItem(ConfigurationConstants.tempParentAddress, this.selectedTreeNode?.data?.address || '');
  }

  removeSelectedNode(): void {
    const urlPath: string[] = ['update-location', 'add-location'];

    if (urlPath.every((word: string) => !this.router.url.includes(word)) && this.activateClickOutside) {
      this.selectedTreeNode = null;
    }
  }

  editLocationHierarchy(): void {
    this.router.navigate(['configuration', 'location', 'update-location', this.selectedTreeNode.data?.aggregateId]);
  }

  openDeleteLocationDialog(): void {
    this.openConfirmationDeleteDialog = true;
    this.activateClickOutside = false;
  }

  closeDeleteLocationDialog(confirmation: boolean): void {
    if (confirmation) {
      this.locationConfigActions.requestLocationConfiguration(this.selectedTreeNode?.data.aggregateId);
      this.deleteLocationConfiguration();
    } else {
      this.openConfirmationDeleteDialog = false;
      this.activateClickOutside = true;
    }
  }

  deleteLocationConfiguration(): void {
    this.store
      .pipe(
        select(selectedLocationConfiguration),
        filter((response: IStoreApiItem<IEditLocationHierarchy>) => !response.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<IEditLocationHierarchy>) => {
        if (response.data) {
          this.locationHierarchyActions.requestDeleteLocationHierarchy({
            id: response.data.aggregateId,
            version: response.data.version.toString(),
          });
          localStorage.setItem(ConfigurationConstants.tempParentAggregateId, response.data.parentId);
          this.handleLocationDelete();
        } else if (response.errors) {
          this.httpCustomErrorCode = response.errors.error?.code.toString();

          if (
            this.httpCustomErrorCode ===
            ConfigurationNotificationConstants.notificationCodes.LOCATION_ENTITY_NOT_EXIST.value
          ) {
            this.callDeleteErrorNotification(
              response,
              ConfigurationNotificationConstants.notificationCodes.ENTITY_NOT_EXIST,
            );
          }
        }
      });
  }

  handleLocationDelete(): void {
    this.store
      .pipe(
        select(selectDeletedLocationHierarchy),
        filter((location: IStoreApiItem<any>) => !location.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((response: IStoreApiItem<any>) => {
        if (response.isSuccess) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            ConfigurationNotificationConstants.notificationCodes.DELETE_LOCATION_HIERARCHY_SUCCESS,
            ConfigurationNotificationConstants.notificationCodes,
          );

          this.locationHierarchyActions.requestLocationHierarchy();

          this.locationTree.resetFilter();
          this.locationTree.propagateSelectionUp = true;

          this.locationHierarchyActions.resetDeleteLocationHierarchy();
          this.locationConfigActions.resetLocationConfiguration();
        } else if (response.errors) {
          this.httpCustomErrorCode = response.errors.error?.code.toString();

          if (
            this.httpCustomErrorCode ===
            ConfigurationNotificationConstants.notificationCodes.LOCATION_ALREADY_ASSIGNED.value
          ) {
            this.callDeleteErrorNotification(
              response,
              LocationHierarchyNotificationConstants.notificationCodes.LOCATION_HAS_EQUIPMENT_ASSIGNED,
            );
          } else if (
            this.httpCustomErrorCode ===
            ConfigurationNotificationConstants.notificationCodes.LOCATION_RESTRICTED_USER.value
          ) {
            this.callDeleteErrorNotification(
              response,
              LocationHierarchyNotificationConstants.notificationCodes.LOCATION_HAS_RESTRICTED_USERS,
            );
          }

          localStorage.removeItem(ConfigurationConstants.tempParentAddress);
          this.locationConfigActions.resetLocationConfiguration();
        }
      });
  }

  callDeleteErrorNotification(errorResponse: IStoreApiItem<any>, errorCode: INotificationMessage): void {
    this.notificationsService.requestShowNotification(
      CommonConstants.notificationType.ERROR,
      errorCode,
      UserNotificationConstants.notificationCodes,
    );

    this.locationHierarchyActions.resetDeleteLocationHierarchy();
  }

  onDrop(event: any): void {
    const rootDestination: boolean = event.originalEvent.toElement.classList.value
      .toString()
      .includes('p-treenode-droppoint');

    const body: ILocationHierarchyChange = {
      aggregateId: event.dragNode.data.aggregateId,
      version: event.dragNode.data.version,
      parentAggregateId: rootDestination ? null : event.dropNode.data.aggregateId,
    };

    localStorage.setItem('originAggregateId', event.dragNode.parent?.data?.aggregateId);

    if (!rootDestination) {
      localStorage.setItem(ConfigurationConstants.tempParentAggregateId, event.dropNode.data.aggregateId);
    }

    this.locationHierarchyActions.requestLocationHierarchyChange(body);

    this.store
      .pipe(
        select(selectLocationHierarchyChange),
        filter((state: IStoreApiItem<ILocationHierarchyChange>) => !state.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((response: IStoreApiItem<ILocationHierarchyChange>) => {
        if (response.isSuccess) {
          event.accept();
          this.locationHierarchyActions.requestLocationHierarchy();
        } else if (response.errors) {
          this.httpCustomErrorCode = response.errors.error?.code.toString();

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            this.httpCustomErrorCode,
            {
              ...ConfigurationNotificationConstants.notificationCodes,
              ...LocationHierarchyNotificationConstants.notificationCodes,
            },
          );
        }
      });
  }
}
