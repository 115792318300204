import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IIncident } from 'src/app/report-center/models/incident.model';

@Component({
  selector: 'ignis-noteworthy-events-column',
  templateUrl: './noteworthy-events-column.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteworthyEventsColumnComponent {
  @Input() incident: IIncident;
}
