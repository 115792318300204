import { CommonConstants } from 'src/app/common/constants';
import { IChecklist } from 'src/app/configuration/models';
import { TaskResultTypeEnum } from '../constants';

export interface ITestValue {
  description: string;
  assessment: string;
  value: string;
  minValue: string;
  maxValue: string;
  unit: string;
  parameters: string;
}

export class TestValue implements ITestValue {
  description: string;
  assessment: string;
  value: string;
  minValue: string;
  maxValue: string;
  unit: string;
  parameters: string;

  constructor();
  constructor(taskValues: ITestValue);
  constructor(taskValues?: ITestValue) {
    if (!taskValues) {
      this.description = null;
      this.assessment = null;
      this.value = null;
      this.minValue = null;
      this.maxValue = null;
      this.unit = null;
      this.parameters = null;
    } else {
      this.description = taskValues.description;
      this.assessment = taskValues.assessment;
      this.value = taskValues.value;
      this.minValue = taskValues.minValue;
      this.maxValue = taskValues.maxValue;
      this.unit = taskValues.unit;
      this.parameters = taskValues.parameters;
    }
  }
}

export interface ITaskInterval {
  serviceId: string;
  taskName: string;
  taskId: string;
  oldLastTestDate: string;
  oldNextTestDate: string;
  newLastTestDate: string;
  newNextTestDate: string;
  precalculatedNextTestDate: string;
  precalculatedLastTestDate: string;
  wasTestIncluded?: boolean;
  inheritedFrom?: string;
}

export class TaskInterval implements ITaskInterval {
  serviceId: string;
  taskName: string;
  taskId: string;
  oldLastTestDate: string;
  oldNextTestDate: string;
  newLastTestDate: string;
  newNextTestDate: string;
  precalculatedNextTestDate: string;
  precalculatedLastTestDate: string;
  wasTestIncluded?: boolean;

  constructor();
  constructor(serviceInterval: ITaskInterval);
  constructor(serviceInterval?: ITaskInterval) {
    if (!serviceInterval) {
      this.serviceId = null;
      this.taskName = null;
      this.taskId = null;
      this.oldLastTestDate = null;
      this.oldNextTestDate = null;
      this.newLastTestDate = null;
      this.newNextTestDate = null;
      this.precalculatedNextTestDate = null;
      this.precalculatedLastTestDate = null;
      this.wasTestIncluded = null;
    } else {
      this.serviceId = serviceInterval.serviceId;
      this.taskName = serviceInterval.taskName;
      this.taskId = serviceInterval.taskId;
      this.oldLastTestDate = serviceInterval.oldLastTestDate;
      this.oldNextTestDate = serviceInterval.oldNextTestDate;
      this.newLastTestDate = serviceInterval.newLastTestDate;
      this.newNextTestDate = serviceInterval.newNextTestDate;
      this.precalculatedNextTestDate = serviceInterval.precalculatedNextTestDate;
      this.precalculatedLastTestDate = serviceInterval.precalculatedLastTestDate;
      this.wasTestIncluded = serviceInterval.wasTestIncluded;
    }
  }
}

export interface IServiceDefinition {
  name: string;
  serviceIntervals: ITaskInterval[];
}

export class ServiceDefinition implements IServiceDefinition {
  name: string;
  serviceIntervals: ITaskInterval[];
  constructor();
  constructor(serviceDefinition: IServiceDefinition);
  constructor(serviceDefinition?: IServiceDefinition) {
    if (!serviceDefinition) {
      this.name = null;
      this.serviceIntervals = null;
    } else {
      this.name = serviceDefinition.name;
      this.serviceIntervals = serviceDefinition.serviceIntervals;
    }
  }
}

export interface ITestResultDetails {
  aggregateId: string;
  equipmentAggregateId: string;
  barcode: string;
  rfid: string;
  serialNo: string;
  comment: string;
  deviceSerialNumber: string;
  softwareVersion: string;
  resultId: string;
  pressure: number;
  selectedTaskName: string;
  taskData: ITaskData;
  taskValues: ITestValue[];
  identification: string;
  dateTime: string;
  testerName: string;
  testEquipment: string;
  equipmentModel: string;
  equipmentType: string;
  operationalStatus: string;
  taskResultType: TaskResultTypeEnum;
  airQualityMeasured: boolean;
  checklist: Partial<IChecklist>;
  testType: string;
  testProcedure: string;
  isLastResult: boolean;
  isFromMigration: boolean;
  locationPath: string[] | any;
  version?: number;
  outOfService: IOutOfService;
  newBarcode: string;
  newRfid: string;
}

export interface IOutOfService {
  date: string;
  reason: string;
}

export interface ITaskData {
  includedTasks: ITaskInterval[];
  others: ITaskInterval[];
}

export class TestResultDetails implements ITestResultDetails {
  aggregateId: string;
  equipmentAggregateId: string;
  barcode: string;
  rfid: string;
  serialNo: string;
  resultId: string;
  comment: string;
  deviceSerialNumber: string;
  pressure: number;
  softwareVersion: string;
  selectedTaskName: string;
  taskData: ITaskData;
  taskValues: TestValue[];
  identification: string;
  dateTime: string;
  testerName: string;
  testEquipment: string;
  equipmentModel: string;
  equipmentType: string;
  operationalStatus: string;
  taskResultType: TaskResultTypeEnum;
  airQualityMeasured: boolean;
  checklist: Partial<IChecklist>;
  testType: string;
  testProcedure: string;
  isLastResult: boolean;
  isFromMigration: boolean;
  locationPath: string[];
  version: number;
  outOfService: IOutOfService;
  newBarcode: string;
  newRfid: string;

  constructor();
  constructor(testResultDetails: ITestResultDetails);
  constructor(testResultDetails?: ITestResultDetails) {
    if (!testResultDetails) {
      this.aggregateId = null;
      this.equipmentAggregateId = null;
      this.barcode = null;
      this.rfid = null;
      this.serialNo = null;
      this.resultId = null;
      this.comment = null;
      this.pressure = null;
      this.deviceSerialNumber = null;
      this.softwareVersion = null;
      this.selectedTaskName = null;
      this.taskData = null;
      this.taskValues = null;
      this.identification = null;
      this.dateTime = null;
      this.testerName = null;
      this.testEquipment = null;
      this.equipmentModel = null;
      this.equipmentType = null;
      this.operationalStatus = null;
      this.taskResultType = null;
      this.airQualityMeasured = null;
      this.checklist = null;
      this.testType = null;
      this.testProcedure = null;
      this.isLastResult = null;
      this.isFromMigration = null;
      this.locationPath = null;
      this.version = null;
      this.outOfService = null;
      this.newBarcode = null;
      this.newRfid = null;
    } else {
      this.aggregateId = testResultDetails.aggregateId;
      this.equipmentAggregateId = testResultDetails.equipmentAggregateId;
      this.barcode = testResultDetails.barcode;
      this.rfid = testResultDetails.rfid;
      this.serialNo = testResultDetails.serialNo;
      this.resultId = testResultDetails.resultId;
      this.comment = testResultDetails.comment;
      this.pressure = testResultDetails.pressure;
      this.deviceSerialNumber = testResultDetails.deviceSerialNumber;
      this.softwareVersion = testResultDetails.softwareVersion;
      this.selectedTaskName = testResultDetails.selectedTaskName;
      this.taskData = testResultDetails.taskData;
      this.taskValues = testResultDetails.taskValues;
      this.identification = testResultDetails.identification;
      this.dateTime = testResultDetails.dateTime;
      this.testerName = testResultDetails.testerName;
      this.testEquipment = testResultDetails.testEquipment;
      this.equipmentModel = testResultDetails.equipmentModel;
      this.equipmentType = testResultDetails.equipmentType;
      this.operationalStatus = testResultDetails.operationalStatus;
      this.taskResultType = testResultDetails.taskResultType;
      this.airQualityMeasured = testResultDetails.airQualityMeasured;
      this.checklist = testResultDetails.checklist;
      this.testType = testResultDetails.testType;
      this.testProcedure = testResultDetails.testProcedure;
      this.isLastResult = testResultDetails.isLastResult;
      this.isFromMigration = testResultDetails.isFromMigration;
      this.locationPath = testResultDetails.locationPath;
      this.version = testResultDetails.version;
      this.outOfService = testResultDetails.outOfService;
      this.newBarcode = testResultDetails.newBarcode;
      this.newRfid = testResultDetails.newRfid;
    }
  }
}

export interface IEquipmentIdentifiers {
  barcode?: string;
  rfid?: string;
  equipmentAggregateId?: string;
}

export interface ITestResult {
  aggregateId: string;
  resultId: string;
  task: string;
  identification: string;
  barcode: string;
  rfid: string;
  serialNo: string;
  category: string;
  type: string;
  model: string;
  dateTime: string;
  testerName: string;
  operationalStatus: string;
  taskResultType: TaskResultTypeEnum;
  includedTasks: string[];
  others: string[];
  disableRow?: boolean;
}

export class TestResult implements ITestResult {
  aggregateId: string;
  resultId: string;
  task: string;
  identification: string;
  barcode: string;
  rfid: string;
  serialNo: string;
  category: string;
  type: string;
  model: string;
  dateTime: string;
  testerName: string;
  operationalStatus: string;
  taskResultType: TaskResultTypeEnum;
  includedTasks: string[];
  others: string[];

  constructor();
  constructor(testResult: ITestResult);
  constructor(testResult?: ITestResult) {
    if (!testResult) {
      this.aggregateId = null;
      this.resultId = null;
      this.task = null;
      this.identification = null;
      this.barcode = null;
      this.rfid = null;
      this.serialNo = null;
      this.category = null;
      this.type = null;
      this.model = null;
      this.dateTime = null;
      this.testerName = null;
      this.operationalStatus = null;
      this.taskResultType = null;
      this.includedTasks = null;
      this.others = null;
    } else {
      this.aggregateId = testResult.aggregateId;
      this.resultId = testResult.resultId;
      this.task = testResult.task;
      this.identification = testResult.identification;
      this.barcode = testResult.barcode;
      this.rfid = testResult.rfid;
      this.serialNo = testResult.serialNo;
      this.category = testResult.category;
      this.type = testResult.type;
      this.model = testResult.model;
      this.dateTime = testResult.dateTime;
      this.testerName = testResult.testerName;
      this.operationalStatus = testResult.operationalStatus;
      this.taskResultType = testResult.taskResultType;
      this.includedTasks = testResult.includedTasks;
      this.others = testResult.others;
    }
  }
}

export interface ITestResultExport {
  aggregateId: string;
  resultId: string;
  task: string;
  identification: string;
  barcode: string;
  rfid: string;
  serialNo: string;
  category: string;
  type: string;
  model: string;
  dateTime: string;
  testerName: string;
  operationalStatus: string;
  taskResultType: TaskResultTypeEnum;
  includedTasks: string;
  others: string;
  disableRow?: boolean;
}

export class TestResultExport implements ITestResultExport {
  aggregateId: string;
  resultId: string;
  task: string;
  identification: string;
  barcode: string;
  rfid: string;
  serialNo: string;
  category: string;
  type: string;
  model: string;
  dateTime: string;
  testerName: string;
  operationalStatus: string;
  taskResultType: TaskResultTypeEnum;
  includedTasks: string;
  others: string;

  constructor();
  constructor(testResult: ITestResult);
  constructor(testResult?: ITestResult) {
    if (!testResult) {
      this.aggregateId = null;
      this.resultId = null;
      this.task = null;
      this.identification = null;
      this.barcode = null;
      this.rfid = null;
      this.serialNo = null;
      this.category = null;
      this.type = null;
      this.model = null;
      this.dateTime = null;
      this.testerName = null;
      this.operationalStatus = null;
      this.taskResultType = null;
      this.includedTasks = null;
      this.others = null;
    } else {
      this.aggregateId = testResult.aggregateId;
      this.resultId = testResult.resultId;
      this.task = testResult.task;
      this.identification = testResult.identification;
      this.barcode = testResult.barcode;
      this.rfid = testResult.rfid;
      this.serialNo = testResult.serialNo;
      this.category = testResult.category;
      this.type = testResult.type;
      this.model = testResult.model;
      this.dateTime = testResult.dateTime;
      this.testerName = testResult.testerName;
      this.operationalStatus = testResult.operationalStatus;
      this.taskResultType = testResult.taskResultType;
      this.includedTasks = testResult.includedTasks.join(CommonConstants.splitArrayOfStringsRule);
      this.others = testResult.others.join(CommonConstants.splitArrayOfStringsRule);
    }
  }
}

export interface ITestResultPage {
  currentPage: number;
  entries: ITestResult[];
  pageSize: number;
  totalPages: number;
  totalRecords: number;
}

export class TestResultPage implements ITestResultPage {
  currentPage: number;
  entries: ITestResult[];
  pageSize: number;
  totalPages: number;
  totalRecords: number;

  constructor();
  constructor(testResultPage: ITestResultPage);
  constructor(testResultPage?: ITestResultPage) {
    if (!testResultPage) {
      this.currentPage = null;
      this.entries = null;
      this.pageSize = null;
      this.totalPages = null;
      this.totalRecords = null;
    } else {
      this.currentPage = testResultPage.currentPage;
      this.entries = testResultPage.entries.map((testResult: TestResult) => new TestResult(testResult));
      this.pageSize = testResultPage.pageSize;
      this.totalPages = testResultPage.totalPages;
      this.totalRecords = testResultPage.totalRecords;
    }
  }
}

export interface IFaultedField {
  fieldName: string;
  error: string;
}

export interface IPrintImageSource {
  [name: string]: string[];
}

export interface IPrintServiceResultValue {
  checklist: IChecklist;
  automatic: ITestValue[];
}

export interface ISaveTestResults {
  operationalStatus: string | boolean;
  aggregateId?: string;
  version?: number;
  comment?: string;
  othersTasksIncluded?: string[];
  outOfService?: IOutOfService;
  airQualityMeasured?: boolean;
  newBarcode?: string;
  newRfid?: string;
}
