import { AfterViewInit, Component, HostListener, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@odx/angular/components/toast';
import sortBy from 'lodash-es/sortBy';
import { combineLatest, filter, map, Observable, Subject, take, takeUntil } from 'rxjs';
import { IEntryModel, IScrollOptions, PropertyBag } from 'src/app/common/models/common.model';
import { INotificationConstant } from 'src/app/common/models/notifications.model';
import { ITableColumn } from 'src/app/common/models/table.model';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { INotificationMessage } from 'src/app/common/state/notifications/models/notification.model';
import { getPressureUnit } from 'src/app/common/utils/settings-utils/settings-utils';
import { ConfigurationNotificationConstants } from 'src/app/configuration/constants';
import { ConfigurationModuleRoutes } from 'src/app/configuration/constants/configuration-module-routes.constants';
import { IAddressBook, IAddressBookPage, IManufacturersList } from 'src/app/configuration/models/address-book.model';
import {
  AddressBookActions,
  EquipmentConfigActions,
  EquipmentHierarchyActions,
  ProtectorTypeActions,
  TasksActions,
} from 'src/app/configuration/state/actions';
import { EquipmentNotificationConstants } from 'src/app/workshop';
import { OnDestroyMixin } from '../../../../common/mixins/destroy-mixin';
import { ConfigurationConstants } from '../../../constants/configuration.constants';
import { configurationForm } from './form-fields';
import { tasksIncludedColumns, tasksTableColumns } from './table-columns';

import {
  CommonConstants,
  DropdownService,
  IApplicationState,
  IStoreApiItem,
  IStoreApiList,
  PressureUnitPipe,
  TableConstants,
} from 'src/app/common';
import {
  IEditEquipmentHierarchy,
  IEquipmentConfiguration,
  IEquipmentHierarchy,
  ITask,
  IUpdateTaskChildren,
} from '../../../models/equipment-configuration.models';
import {
  selectAddedEquipmentHierarchy,
  selectAddressBookPage,
  selectDeletedTask,
  selectedEquipmentConfiguration,
  selectEditedEquipmentHierarchy,
  selectProtectorTypeValues,
  selectUpdatedTaskChildren,
} from '../../../state/selectors/configuration.selectors';

/* eslint-disable no-prototype-builtins */
@Component({
  selector: 'ignis-create-update-configuration',
  templateUrl: './create-update-configuration.component.html',
  styleUrls: ['./create-update-configuration.component.scss'],
  providers: [PressureUnitPipe],
})
export class CreateUpdateConfigurationComponent extends OnDestroyMixin() implements OnInit, AfterViewInit {
  isLoading: Observable<boolean>;
  addedType: string;
  equipmentConfigType: string;
  httpCustomErrorCode: string | any;
  isSubmitting: Observable<boolean>;
  activateClickOutside: boolean = true;
  navigationHistory: string[];
  modalType: PropertyBag = ConfigurationConstants.modalType;
  configType: PropertyBag = ConfigurationConstants.EquipmentConfigType;
  readonly ConfigurationModuleRoutes: PropertyBag = ConfigurationModuleRoutes;

  protectorTypeValues: any[];
  selectedProtectorTypeValue: any;
  tableScrollingFallback: number = TableConstants.NO_ROWS_TABLE_SCROLLING_FALLBACK;
  selectedManufacturer: IAddressBook | any;
  selectedTask: ITask;
  manufacturerQuery: { page: number; size: number; sort: string; types: string } = {
    page: 0,
    size: 1000,
    sort: 'organizationName,ASC',
    types: 'MANUFACTURER',
  };

  tasksTableColumns: ITableColumn[] = tasksTableColumns;
  intervalsTableColumns: ITableColumn[] = tasksIncludedColumns;
  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;

  tasks: ITask[] | any;
  preselectedTask: ITask;
  intervals: any[];
  selectedIncludedTasks: any[] = [];
  initialSelectedIncludedTasks: string[] = [];
  isConfirmCloseModalBanner: boolean = false;
  disableAddBtn: boolean = false;
  disableAddBtnTaskInterval: boolean = false;
  linkToNavigate: string;
  openConfirmUpdateHierarchyModal: boolean = false;
  isIncludedTaskDirty: boolean = false;
  isLeavingPage: boolean = false;
  savedSelectedTask: ITask = null;
  displayPressureInput: boolean = false;
  pressureDisplayUnit: string;
  convertedPressureValue: number;
  pressureValue: number;
  openConfirmationDeleteDialog: boolean;
  disableAddChecklistBtn: boolean = true;
  routeUrl: string;
  toggleAddAndEditChecklistBtn: boolean = false;
  disableEditChecklistBtn: boolean = false;
  manufacturersList: IManufacturersList[];
  manufacturerMap: Map<string, string> = new Map<string, string>();
  localDestroy$: Subject<boolean> = new Subject<boolean>();
  isInheritedTranslation: string;
  userWantsToLeaveThePage: boolean = false;

  configurationForm: FormGroup = null;

  scrollbarOptions: IScrollOptions = CommonConstants.scrollbarOptions;

  tasksActions: TasksActions = inject(TasksActions);
  protectorTypeActions: ProtectorTypeActions = inject(ProtectorTypeActions);
  addressBookActions: AddressBookActions = inject(AddressBookActions);
  equipmentConfigActions: EquipmentConfigActions = inject(EquipmentConfigActions);
  equipmentHierarchyActions: EquipmentHierarchyActions = inject(EquipmentHierarchyActions);
  notificationsService: NotificationsService = inject(NotificationsService);
  public dropdownService: DropdownService = inject(DropdownService);
  pressureUnitPipe: PressureUnitPipe = inject(PressureUnitPipe);
  translateService: TranslateService = inject(TranslateService);
  router: Router = inject(Router);
  route: ActivatedRoute = inject(ActivatedRoute);
  store: Store<IApplicationState> = inject(Store<IApplicationState>);
  toastService: ToastService = inject(ToastService);

  constructor() {
    super();

    this.configurationForm = new FormGroup({ ...configurationForm });

    this.manufacturersList = [];
    this.navigationHistory = [];

    this.isInheritedTranslation = this.translateService.instant('CONFIGURATION.STR_INHERITED');

    this.router.events?.subscribe((event: any) => {
      this.linkToNavigate = event.url;

      this.navigationHistory.push(event.url);
      this.navigationHistory = [...new Set(this.navigationHistory)].filter((elem: any) => {
        return elem !== undefined;
      });

      this.navigationHistory.forEach((elem: string) => {
        if (!elem.includes(ConfigurationModuleRoutes.updateTask)) {
          setTimeout(() => {
            this.activateClickOutside = true;
          }, 0);

          this.navigationHistory = [];
        }
      });
    });

    setTimeout(() => {
      this.manageLoading();
    }, 0);
  }

  ngOnInit(): void {
    if (!this.router.url?.includes(ConfigurationModuleRoutes.updateEquipmentHierarchy)) {
      if (
        'isCylinderType' in localStorage &&
        localStorage.getItem('isCylinderType') === 'true' &&
        ConfigurationConstants.isModelRegex.test(this.router.url)
      ) {
        this.configurationForm.addControl('maxPressure', new FormControl(null, [Validators.required]));
        this.displayPressureInput = true;
      } else {
        this.configurationForm.removeControl('maxPressure');
      }

      const categoryId: string = this.route.snapshot.paramMap.get(ConfigurationConstants.categoryId);

      if (categoryId) {
        this.equipmentConfigActions.requestEquipmentConfiguration({
          urlPath: `categories/${categoryId}`,
        });
      }
    }

    this.toggleActivateClickOutsideOfTasksTableWhenNotificationIsClosed();
    this.getDropdownValues();
    this.readPressureUnit();

    this.toggleAddChecklistBtn();
    this.toggleEditChecklistBtn();

    this.protectorTypeActions.requestProtectorTypeValues();

    this.isSubmitting = combineLatest([
      this.store.select(selectAddedEquipmentHierarchy),
      this.store.select(selectEditedEquipmentHierarchy),
    ]).pipe(
      takeUntil(this.destroy),
      map(
        ([newConfig, editedConfig]: [
          newConfig: IStoreApiItem<IEquipmentHierarchy>,
          editedConfig: IStoreApiItem<IEditEquipmentHierarchy>,
        ]) => newConfig.isLoading || editedConfig.isLoading,
      ),
    );
  }

  toggleActivateClickOutsideOfTasksTableWhenNotificationIsClosed(): void {
    this.toastService.onToastRemove$.subscribe(() => {
      this.activateClickOutside = false;

      setTimeout(() => {
        this.activateClickOutside = true;
      }, 0);
    });
  }

  manageLoading(): void {
    this.isLoading = combineLatest([
      this.store.select(selectedEquipmentConfiguration),
      this.store.select(selectAddedEquipmentHierarchy),
      this.store.select(selectEditedEquipmentHierarchy),
      this.store.select(selectAddressBookPage),
    ]).pipe(
      map(
        ([getState, editState, addState, addressState]: [
          IStoreApiItem<IEquipmentConfiguration>,
          IStoreApiItem<IEquipmentHierarchy>,
          IStoreApiItem<IEquipmentHierarchy>,
          IStoreApiItem<IAddressBookPage>,
        ]) => {
          return getState.isLoading || editState.isLoading || addState.isLoading || addressState.isLoading;
        },
      ),
    );
  }

  getDropdownValues() {
    this.store
      .pipe(
        select(selectProtectorTypeValues),
        filter((types: IStoreApiList<IEntryModel[]>) => !types.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((types: IStoreApiList<any[]>) => {
        const localizedData: IEntryModel[] = ConfigurationConstants.protectorTypes.types;
        const label: string = 'label';

        this.protectorTypeValues = sortBy(
          types.data?.map((pType: IEntryModel) => ({
            ...pType,
            label: this.translateService.instant(
              localizedData.find((t: IEntryModel) => t.value === pType.value)?.localizedName || pType.value,
            ),
          })),
          label,
        );
      });

    this.store
      .pipe(
        select(selectAddressBookPage),
        filter((manufacturers: IStoreApiItem<IAddressBookPage>) => !manufacturers.isLoading),
        map((state: IStoreApiList<IAddressBookPage>) => state.data),
        takeUntil(this.localDestroy$),
      )
      .subscribe((manufacturers: IAddressBookPage) => {
        if (!manufacturers) {
          return;
        }

        manufacturers.entries?.forEach((address: IAddressBook) => {
          this.manufacturerMap.set(address.aggregateId, address.organizationName);
        });

        if (manufacturers.totalPages > 0 && manufacturers.currentPage < manufacturers.totalPages - 1) {
          this.addressBookActions.requestAddressBook({
            ...this.manufacturerQuery,
            page: manufacturers.currentPage + 1,
          });
        } else {
          this.manufacturersList = Array.from(
            this.manufacturerMap,
            ([manufacturerAggregateId, organizationName]: [string, string]) => ({
              manufacturerAggregateId,
              organizationName,
            }),
          );

          this.addMissingAddressBook();
        }
      });
  }

  addMissingAddressBook() {
    if (this.selectedManufacturer) {
      if (!this.manufacturerMap.has(this.selectedManufacturer.manufacturerAggregateId)) {
        this.manufacturersList.unshift({
          organizationName: this.selectedManufacturer.organizationName,
          manufacturerAggregateId: this.selectedManufacturer.manufacturerAggregateId,
        });
      }

      this.configurationForm.get('manufacturerAggregateId').setValue(this.selectedManufacturer.manufacturerAggregateId);
    }
  }

  ngAfterViewInit(): void {
    this.requestConfigurationInfo();
  }

  requestConfigurationInfo(): void {
    if (this.router.url?.includes(ConfigurationModuleRoutes.updateEquipmentHierarchy)) {
      this.route.params.pipe(takeUntil(this.destroy)).subscribe((params: Params) => {
        switch (true) {
          case params.modelId !== undefined:
            localStorage.setItem(ConfigurationConstants.modelId, params.modelId);
            break;
          case params.typeId !== undefined:
            localStorage.setItem(ConfigurationConstants.typeId, params.typeId);
            break;
          case params.categoryId !== undefined:
            localStorage.setItem(ConfigurationConstants.categoryId, params.categoryId);
            break;
        }

        if (ConfigurationConstants.modelId in params) {
          this.equipmentConfigActions.requestEquipmentConfiguration({
            urlPath: `categories/${params.categoryId}/types/${params.typeId}/models/${params.modelId}`,
          });
          setTimeout(() => {
            this.addedType = this.translateService.instant('CONFIGURATION.STR_MODEL');
            this.equipmentConfigType = ConfigurationConstants.EquipmentConfigType.MODEL;
          });

          return;
        }

        if (ConfigurationConstants.typeId in params) {
          this.equipmentConfigActions.requestEquipmentConfiguration({
            urlPath: `categories/${params.categoryId}/types/${params.typeId}`,
          });
          setTimeout(() => {
            this.addedType = this.translateService.instant('CONFIGURATION.STR_TYPE');
            this.equipmentConfigType = ConfigurationConstants.EquipmentConfigType.TYPE;
          });

          return;
        }

        if (ConfigurationConstants.categoryId in params) {
          this.equipmentConfigActions.requestEquipmentConfiguration({ urlPath: `categories/${params.categoryId}` });
          setTimeout(() => {
            this.addedType = this.translateService.instant('CONFIGURATION.STR_CATEGORY');
            this.equipmentConfigType = ConfigurationConstants.EquipmentConfigType.CATEGORY;
          });
        }
      });
    }

    this.readEquipmentConfigurationData();

    if (this.router.url?.includes(ConfigurationModuleRoutes.createOrUpdateEquipmentModels)) {
      this.addressBookActions.requestAddressBook(this.manufacturerQuery);
    }

    this.readEquipmentConfigurationDataFromLocalStorage();
  }

  @HostListener(CommonConstants.beforeUnloadWindowEvent, ['$event'])
  handleBeforeUnload($event: any): void {
    if (this.hasUnsavedData) {
      $event.returnValue = this.hasUnsavedData;
    }
  }

  canDeactivate(): boolean {
    this.userWantsToLeaveThePage = true;

    if (this.hasUnsavedData) {
      this.confirmCloseModalOpen();

      return false;
    } else {
      this.resetOnClose();
      this.isConfirmCloseModalBanner = false;
      this.isLeavingPage = false;

      return true;
    }
  }

  confirmCloseModalOpen(): void {
    const isInCreateMode: boolean = this.router.url.includes(ConfigurationModuleRoutes.createEquipmentHierarchy);

    this.isLeavingPage = true;
    this.userWantsToLeaveThePage = true;
    this.isConfirmCloseModalBanner = true;

    switch (this.router.url?.split('/').length) {
      case isInCreateMode ? 3 : 5:
        this.equipmentConfigType = ConfigurationConstants.EquipmentConfigType.CATEGORY;
        break;
      case isInCreateMode ? 5 : 7:
        this.equipmentConfigType = ConfigurationConstants.EquipmentConfigType.TYPE;
        break;
      case isInCreateMode ? 7 : 9:
        this.equipmentConfigType = ConfigurationConstants.EquipmentConfigType.MODEL;
        break;
    }
  }

  readPressureUnit(): void {
    getPressureUnit(this.store, this.destroy).subscribe((unit: string) => {
      this.pressureDisplayUnit = unit;

      if (this.pressureValue) {
        this.configurationForm
          .get('maxPressure')
          .setValue(this.pressureUnitPipe.transform(this.pressureValue, [this.pressureDisplayUnit, false]));
      }
    });
  }

  readEquipmentConfigurationData(): void {
    this.store
      .pipe(select(selectedEquipmentConfiguration), takeUntil(this.destroy))
      .subscribe((response: IStoreApiItem<IEquipmentConfiguration>) => {
        const baCylinderType: string = 'BA_CYLINDER';

        setTimeout(() => {
          if (response.data) {
            if (
              this.router.url.includes(
                `${ConfigurationModuleRoutes.configuration}/${ConfigurationModuleRoutes.createEquipmentHierarchy}`,
              )
            ) {
              this.configurationForm.get('testType').enable();
              this.configurationForm.get('version').setValue(response.data.version);

              return;
            }

            this.configurationForm.get('version').setValue(response.data.version);

            this.selectedManufacturer = this.manufacturersList.filter((m: IManufacturersList) => {
              return m.manufacturerAggregateId === this.selectedManufacturer;
            })[0];

            this.setValuesForEditingConfiguration(response, baCylinderType);

            this.addMissingAddressBook();

            localStorage.setItem(ConfigurationConstants.configurationVersion, response.data.version.toString());

            this.addMaxPressureValidation(response.data);

            this.tasks = structuredClone(response.data.serviceDefinitions);
            this.tasks.forEach((task: ITask | any) => {
              task.aggregateId = task.serviceId;

              task.taskName = this.renameTaskWhenInherited(task);

              if (this.selectedTask && this.selectedTask.serviceId === task.serviceId) {
                this.selectedTask = task;

                this.toggleAddChecklistBtn();
                this.toggleEditChecklistBtn();
                this.disableAddBtnTaskInterval = false;
              }
            });
          }
        }, 0);
      });
  }

  setValuesForEditingConfiguration(response: IStoreApiItem<IEquipmentConfiguration>, baCylinderType: string): void {
    if (!this.configurationForm.touched) {
      this.configurationForm.patchValue(response.data);

      this.selectedManufacturer = response.data.manufacturer;
      this.selectedProtectorTypeValue = response.data.protectorType;

      this.configurationForm.get('testType').enable();

      if (
        this.selectedProtectorTypeValue === baCylinderType &&
        response.data.protectorType === baCylinderType &&
        response.data.hasModels
      ) {
        this.configurationForm.get('testType').disable();
      }

      this.configurationForm.get('testType').setValue(response.data.protectorType);
    }
  }

  renameTaskWhenInherited(task: ITask): string {
    if (task.isInherited) {
      const inheritedFrom: string = task.inheritedFrom
        ? `${this.isInheritedTranslation} ${task.inheritedFrom}`
        : this.isInheritedTranslation;

      return `${task.taskName} (${inheritedFrom})`;
    }

    return task.taskName;
  }

  addMaxPressureValidation(response: IEquipmentConfiguration): void {
    if (response.hasCylinderType) {
      this.displayPressureInput = true;
      this.pressureValue = response.maxPressure;
      const convertedValue: number = this.pressureUnitPipe.transform(response.maxPressure, [
        this.pressureDisplayUnit,
        false,
      ]);

      this.configurationForm.addControl(
        'maxPressure',
        new FormControl(this.pressureValue ? convertedValue : null, [Validators.required]),
      );
    }
  }

  readEquipmentConfigurationDataFromLocalStorage(): void {
    this.store
      .pipe(
        select(selectedEquipmentConfiguration),
        filter((equipmentConfiguration: IStoreApiItem<IEquipmentConfiguration>) => !equipmentConfiguration.isLoading),
        take(1),
      )
      .subscribe((response: any) => {
        setTimeout(() => {
          if (ConfigurationConstants.comeFromAddAddress in localStorage && !response.isLoading) {
            const storedFormValues: IEquipmentConfiguration | any = JSON.parse(
              localStorage.getItem(ConfigurationConstants.comeFromEquipmentHierarchy),
            );

            this.configurationForm.patchValue(storedFormValues);
            localStorage.removeItem(ConfigurationConstants.comeFromEquipmentHierarchy);
            localStorage.removeItem(ConfigurationConstants.comeFromAddAddress);

            if (storedFormValues.touched) {
              this.configurationForm.markAsDirty();
            }
          }
        }, 0);
      });
  }

  resetOnClose(): void {
    this.configurationForm.reset();
  }

  navigateBack(isConfirmed: boolean): void {
    if (isConfirmed) {
      this.configurationForm.markAsPristine();

      if (window.location.pathname === this.linkToNavigate || !this.linkToNavigate) {
        this.router.navigate(['configuration']);
      } else {
        this.router.navigate([this.linkToNavigate]);
      }
    } else {
      this.isConfirmCloseModalBanner = false;
    }
  }

  prepareSavingData(requestType: string): void {
    const isInCreateMode: boolean = this.router.url.includes(ConfigurationModuleRoutes.createEquipmentHierarchy);

    this.route.params.pipe(takeUntil(this.destroy)).subscribe((params: Params) => {
      localStorage.setItem(ConfigurationConstants.categoryId, params.categoryId);
      localStorage.setItem(ConfigurationConstants.typeId, params.typeId);

      switch (this.router.url?.split('/').length) {
        case isInCreateMode ? 3 : 5:
          this.addedType = this.translateService.instant('CONFIGURATION.STR_CATEGORY');
          this.equipmentConfigType = ConfigurationConstants.EquipmentConfigType.CATEGORY;
          this.equipmentHierarchyActions[requestType]({
            data: { ...this.configurationForm.value, id: this.configurationForm.value.aggregateId },
            urlPath: 'categories',
          });

          break;
        case isInCreateMode ? 5 : 7:
          this.addedType = this.translateService.instant('CONFIGURATION.STR_TYPE');
          this.equipmentConfigType = ConfigurationConstants.EquipmentConfigType.TYPE;
          this.equipmentHierarchyActions[requestType]({
            data: {
              ...this.configurationForm.value,
              name: this.configurationForm.get('name').value,
              testType: this.selectedProtectorTypeValue ? this.selectedProtectorTypeValue : null,
              maxPressure: this.convertedPressureValue,
              id: this.configurationForm.get(ConfigurationConstants.typeId).value,
            },
            urlPath: `categories/${params.categoryId}/types`,
          });

          break;
        case isInCreateMode ? 7 : 9:
          this.addedType = this.translateService.instant('CONFIGURATION.STR_MODEL');
          this.equipmentConfigType = ConfigurationConstants.EquipmentConfigType.MODEL;

          if (this.displayPressureInput) {
            this.convertPressureUnit({
              target: {
                value: +this.configurationForm.get('maxPressure').value,
              },
            });
          }

          this.equipmentHierarchyActions[requestType]({
            data: {
              ...this.configurationForm.value,
              maxPressure: this.convertedPressureValue,
              id: this.configurationForm.get(ConfigurationConstants.modelId).value,
            },
            urlPath: `categories/${params.categoryId}/types/${params.typeId}/models`,
          });

          break;
      }
    });
  }

  addEquipmentConfiguration(): void {
    this.prepareSavingData('requestAddEquipmentHierarchy');

    this.store
      .pipe(
        select(selectAddedEquipmentHierarchy),
        filter((state: IStoreApiItem<IEquipmentHierarchy>) => !state.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((response: IStoreApiItem<IEquipmentHierarchy>) => {
        if (response.isSuccess) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            ConfigurationNotificationConstants.notificationCodes.ADD_HIERARCHY_SUCCESS,
            ConfigurationNotificationConstants.notificationCodes,
            { type: this.addedType },
          );

          this.configurationForm.markAsPristine();
          this.router.navigate(['configuration']);
        } else {
          this.httpCustomErrorCode = response.errors.error?.code.toString();
          let type: string = null;

          if (
            this.httpCustomErrorCode === EquipmentNotificationConstants.notificationCodes.NAME_SHOULD_BE_UNIQUE.value
          ) {
            setTimeout(() => {
              this.configurationForm.get('name').setErrors({ invalid: true });
            }, 0);

            type = CommonConstants.notificationType.HIDDEN;
          }

          this.callEditConfigurationErrorNotification(
            this.httpCustomErrorCode,
            {
              ...ConfigurationNotificationConstants.notificationCodes,
              ...EquipmentNotificationConstants.notificationCodes,
            },
            type,
          );

          this.markFormAsPristineIfMigrationIsInProgress(this.httpCustomErrorCode);
        }
      });
  }

  editEquipmentConfiguration(): void {
    this.prepareSavingData('requestEditEquipmentHierarchy');
    this.store
      .pipe(
        select(selectEditedEquipmentHierarchy),
        filter((state: IStoreApiItem<IEditEquipmentHierarchy>) => !state.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<IEditEquipmentHierarchy> | any) => {
        if (response.isSuccess) {
          this.requestConfigurationInfo();

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            ConfigurationNotificationConstants.notificationCodes.EDIT_HIERARCHY_SUCCESS,
            ConfigurationNotificationConstants.notificationCodes,
            { type: this.addedType },
          );

          this.configurationForm.markAsPristine();
        } else {
          this.httpCustomErrorCode = response.errors.error?.code.toString();

          this.markFormAsPristineIfMigrationIsInProgress(this.httpCustomErrorCode);

          if (
            this.httpCustomErrorCode === EquipmentNotificationConstants.notificationCodes.NAME_SHOULD_BE_UNIQUE.value
          ) {
            setTimeout(() => {
              this.configurationForm.get('name').setErrors({ invalid: true });
            }, 0);

            this.callEditConfigurationErrorNotification(
              EquipmentNotificationConstants.notificationCodes.NAME_SHOULD_BE_UNIQUE,
              {
                ...ConfigurationNotificationConstants.notificationCodes,
                ...EquipmentNotificationConstants.notificationCodes,
              },
              CommonConstants.notificationType.HIDDEN,
            );
          } else {
            if (
              this.httpCustomErrorCode ===
              EquipmentNotificationConstants.notificationCodes
                .EQUIPMENT_HIERARCHY_TEST_TYPE_CANNOT_BE_CHANGE_TO_BA_CYLINDER.value
            ) {
              setTimeout(() => {
                this.configurationForm.get('testType').setErrors({ invalid: true });
              }, 0);
            }

            this.callEditConfigurationErrorNotification(
              this.httpCustomErrorCode,
              {
                ...ConfigurationNotificationConstants.notificationCodes,
                ...EquipmentNotificationConstants.notificationCodes,
              },
              null,
            );
          }
        }
      });
  }

  callEditConfigurationErrorNotification(
    errorCode: INotificationMessage,
    codes: INotificationConstant,
    notificationType?: string,
  ): void {
    this.notificationsService.requestShowNotification(
      notificationType ? notificationType : CommonConstants.notificationType.ERROR,
      errorCode,
      codes,
    );
  }

  onSubmit(): void {
    if (this.router.url?.includes(ConfigurationModuleRoutes.createEquipmentHierarchy)) {
      this.addEquipmentConfiguration();
    } else {
      this.activateClickOutside = false;
      this.openConfirmUpdateHierarchyModal = true;
    }
  }

  get hasUnsavedData(): boolean {
    return this.configurationForm.dirty || this.isIncludedTaskDirty;
  }

  addNewTask(): void {
    const urlSegments: string[] = this.router.url.split('/');

    urlSegments.shift();
    urlSegments.push('add-task');
    this.router.navigate(urlSegments);
  }

  editTaskChildren(): Promise<boolean> {
    return new Promise<boolean>((resolve: (value: boolean) => void) => {
      this.route.params.pipe(take(1)).subscribe((params: Params) => {
        const updateRequest: IUpdateTaskChildren = {
          version: +this.configurationForm.get('version').value,
          childrenIds: this.selectedIncludedTasks.map((task: ITask) => task.serviceId),
        };

        switch (true) {
          case ConfigurationConstants.modelId in params:
            updateRequest.urlPath = `categories/${params.categoryId}/types/${params.typeId}/models/${params.modelId}/service-definition/${this.selectedTask.serviceId}`;

            break;
          case ConfigurationConstants.typeId in params:
            updateRequest.urlPath = `categories/${params.categoryId}/types/${params.typeId}/service-definition/${this.selectedTask.serviceId}`;

            break;
          case ConfigurationConstants.categoryId in params:
            updateRequest.urlPath = `categories/${params.categoryId}/service-definition/${this.selectedTask.serviceId}`;

            break;
        }

        this.tasksActions.requestUpdateTaskChildren(updateRequest);
      });

      this.store
        .pipe(
          select(selectUpdatedTaskChildren),
          filter((state: IStoreApiItem<IUpdateTaskChildren>) => !state.isLoading),
          take(1),
        )
        .subscribe((response: IStoreApiItem<IUpdateTaskChildren>) => {
          if (response.isSuccess) {
            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.SUCCESS,
              ConfigurationNotificationConstants.notificationCodes.EDIT_HIERARCHY_SUCCESS,
              ConfigurationNotificationConstants.notificationCodes,
              { type: this.addedType },
            );

            this.configurationForm.get('version').patchValue(response.data.version);

            resolve(true);
          } else {
            this.httpCustomErrorCode = response.errors?.error?.code.toString();

            if (
              this.httpCustomErrorCode === ConfigurationNotificationConstants.notificationCodes.CIRCULAR_INCLUSION.value
            ) {
              this.callEditConfigurationErrorNotification(
                ConfigurationNotificationConstants.notificationCodes.CIRCULAR_INCLUSION,
                ConfigurationNotificationConstants.notificationCodes,
              );
            } else {
              this.callEditConfigurationErrorNotification(
                ConfigurationNotificationConstants.notificationCodes.ENTITY_NOT_EXIST,
                ConfigurationNotificationConstants.notificationCodes,
              );
            }

            resolve(false);
          }
        });
    });
  }

  navigateToModal(type: string): void {
    const urlSegments: string[] = this.router.url.split('/');

    this.activateClickOutside = false;

    urlSegments.shift();
    urlSegments.push(type);
    urlSegments.push(this.selectedTask.serviceId);
    this.router.navigate(urlSegments);
  }

  addOrEditChecklist(type: string): void {
    const urlSegments: string[] = this.router.url.split('/');

    this.routeUrl = `${type}-checklist`;

    if (this.selectedTask.checklist) {
      localStorage.setItem(ConfigurationConstants.selectedChecklist, JSON.stringify(this.selectedTask.checklist));
    }

    urlSegments.shift();
    urlSegments.push(`${type}-checklist`, this.selectedTask.serviceId);
    this.router.navigate(urlSegments);
  }

  onTaskSelect(selectedTask: ITask): void {
    if (selectedTask !== null && selectedTask?.serviceId === this.selectedTask?.serviceId) {
      return;
    }

    if (this.isIncludedTaskDirty) {
      this.isLeavingPage = false;
      this.activateClickOutside = false;
      this.preselectedTask = { ...this.selectedTask };
      this.isConfirmCloseModalBanner = true;
      this.savedSelectedTask = selectedTask;

      return;
    }

    if (selectedTask) {
      this.selectedTask = selectedTask;
      this.intervals = this.tasks.filter((task: ITask) => task.serviceId !== selectedTask.serviceId);

      this.intervals.forEach((interval: any) => {
        if (interval.interval?.pattern) {
          const pattern: string = this.getPatternTranslation(interval.interval.pattern);

          interval.displayedDuration = `${interval.interval.duration} ${pattern}`;
        } else {
          interval.displayedDuration = ``;
        }
      });

      const childrenIds: string[] = this.selectedTask.children.map((children: any) => children.serviceId);

      const localSelected: any[] = [];

      this.tasks.some((task: ITask) => {
        if (childrenIds.includes(task.serviceId)) {
          localSelected.push({
            ...task,
          });
        }
      });

      this.selectedIncludedTasks = [...localSelected];
      this.initialSelectedIncludedTasks = this.getSelectedIds(localSelected);
      this.toggleAddChecklistBtn();
      this.toggleEditChecklistBtn();

      this.disableAddBtn = true;

      return;
    }

    this.selectedTask = null;
    this.intervals = [];
    this.selectedIncludedTasks = [];
    this.disableAddBtn = false;
  }

  getPatternTranslation(pattern: string): string {
    return this.translateService.instant(
      ConfigurationConstants.intervalList.patterns.find((interval: any) => interval.value === pattern)?.localizedName,
    );
  }

  toggleAddChecklistBtn(): void {
    if (
      (this.selectedTask?.checklist && !this.selectedTask?.isInherited) ||
      (!this.selectedTask?.checklist && this.selectedTask?.isInherited)
    ) {
      this.disableAddChecklistBtn = true;
    } else if (this.selectedTask?.isInherited) {
      this.disableAddChecklistBtn = true;
    } else {
      this.disableAddChecklistBtn = false;
    }
  }

  toggleEditChecklistBtn(): void {
    if (this.selectedTask?.checklist && !this.selectedTask?.isInherited) {
      this.toggleAddAndEditChecklistBtn = true;
      this.disableEditChecklistBtn = false;
    } else if (this.selectedTask?.checklist && this.selectedTask?.isInherited) {
      this.toggleAddAndEditChecklistBtn = true;
      this.disableEditChecklistBtn = true;
    } else {
      this.toggleAddAndEditChecklistBtn = false;
      this.disableEditChecklistBtn = false;
    }
  }

  openDeleteTaskDialog(): void {
    this.openConfirmationDeleteDialog = true;
    this.activateClickOutside = false;
  }

  closeDeleteTaskDialog(confirmation: boolean): void {
    if (confirmation) {
      if (!this.selectedTask.isInherited) {
        const urlPath: string = this.router.url.slice(22);
        const deleteUrl: string = urlPath.concat(`/service-definition/${this.selectedTask.serviceId}`);

        this.tasksActions.requestDeleteTask({
          urlPath: deleteUrl,
          version: +this.configurationForm.get('version').value,
        });
        this.handleDeletionAction();
      }
    } else {
      this.openConfirmationDeleteDialog = false;
      this.activateClickOutside = true;
    }
  }

  handleDeletionAction(): void {
    this.store
      .pipe(
        select(selectDeletedTask),
        filter((state: IStoreApiItem<any>) => !state.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((response: IStoreApiItem<any>) => {
        const touchedControls: boolean[] = [];

        Object.keys(this.configurationForm.controls).forEach((key: string) => {
          touchedControls.push(this.configurationForm.get(key).touched);
        });

        if (response.isSuccess) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            ConfigurationNotificationConstants.notificationCodes.DELETE_SERVICE_SUCCESS,
            ConfigurationNotificationConstants.notificationCodes,
          );

          this.isIncludedTaskDirty = false;
          this.onTaskSelect(null);
          this.requestConfigurationInfo();
        } else {
          this.httpCustomErrorCode = response.errors?.error?.code.toString();

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            this.httpCustomErrorCode,
            ConfigurationNotificationConstants.notificationCodes,
          );
        }

        if (touchedControls.every((elem: boolean) => elem === false)) {
          this.configurationForm.markAsPristine();
        }
      });
  }

  onIncludedTaskSelect(includedTask: any[]): void {
    this.selectedIncludedTasks = includedTask;

    this.isIncludedTaskDirty = !this.areArraysEqual(
      this.initialSelectedIncludedTasks,
      this.getSelectedIds(includedTask),
    );
  }

  areArraysEqual(arr1: string[], arr2: string[]): boolean {
    const sortedArr1: string[] = arr1.slice().sort();
    const sortedArr2: string[] = arr2.slice().sort();

    return JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2);
  }

  getSelectedIds(tasks: any[]): string[] {
    return tasks.map((task: ITask | any) => task.serviceId);
  }

  closeConfirmBanner(confirmation: boolean): void {
    if (confirmation) {
      if (this.selectedTask) {
        this.editTaskChildren().then((response: boolean) => {
          if (response && this.hasUnsavedData) {
            this.isIncludedTaskDirty = false;
            this.configurationForm.get('version').setValue(this.configurationForm.get('version').value + 1);

            this.editEquipmentConfiguration();
          } else {
            this.requestConfigurationInfo();
          }
        });
      } else {
        this.editEquipmentConfiguration();
        this.openConfirmUpdateHierarchyModal = false;
        this.userWantsToLeaveThePage = false;
      }
    }

    this.openConfirmUpdateHierarchyModal = false;
    this.activateClickOutside = false;
  }

  convertPressureUnit(event: any): void {
    switch (this.pressureDisplayUnit) {
      case CommonConstants.pressureDisplayUnit.psi:
        this.convertedPressureValue = event.target.value * CommonConstants.PSI_CONVERSION_VALUE;
        break;
      case CommonConstants.pressureDisplayUnit.mpa:
        this.convertedPressureValue = event.target.value * CommonConstants.MPA_CONVERSION_VALUE;
        break;
      default:
        this.convertedPressureValue = event.target.value;
        break;
    }
  }

  navigateToAddAddress(): void {
    const objToStored: any = {
      ...this.configurationForm.value,
      touched: this.configurationForm.touched,
    };

    localStorage.setItem(ConfigurationConstants.comeFromEquipmentHierarchy, JSON.stringify(objToStored));

    this.configurationForm.markAsPristine();
    this.router.navigate(['configuration', 'address', 'create']);
  }

  handleUnsavedChangesResponse(isConfirmed: boolean): void {
    if (this.isIncludedTaskDirty) {
      if (isConfirmed) {
        this.isIncludedTaskDirty = false;

        if (this.userWantsToLeaveThePage) {
          this.navigateBack(isConfirmed);
        }

        this.preselectedTask = { ...this.savedSelectedTask };
        this.onTaskSelect(this.savedSelectedTask);
      }

      this.savedSelectedTask = null;
      this.isConfirmCloseModalBanner = false;
      this.activateClickOutside = false;
      this.userWantsToLeaveThePage = false;
    }

    if (this.isLeavingPage) {
      this.navigateBack(isConfirmed);
    }
  }

  markFormAsPristineIfMigrationIsInProgress(errorCode: string): void {
    if (
      errorCode ===
      ConfigurationNotificationConstants.notificationCodes.OPERATION_BLOCKED_DURING_THE_MIGRATION_IN_PROGRESS.value
    ) {
      this.configurationForm.markAsPristine();
      this.configurationForm.markAsUntouched();
    }
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();

    this.addressBookActions.resetAddressBookPage();
    this.localDestroy$.next(null);
    this.localDestroy$.unsubscribe();
    this.configurationForm = null;
  }
}
