import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { ICreateUpdateEquipment, IEquipment, IEquipmentListPage } from 'src/app/workshop/models/equipment.model';
import { EquipmentCRUDActionTypes } from './equipment-crud-action-types';

export class RequestEquipmentExport implements Action {
  readonly type: EquipmentCRUDActionTypes.REQUEST_EQUIPMENT_EXPORT = EquipmentCRUDActionTypes.REQUEST_EQUIPMENT_EXPORT;

  constructor(public payload?: any) {}
}

export class RequestEquipmentPage implements Action {
  readonly type: EquipmentCRUDActionTypes.REQUEST_EQUIPMENT_PAGE = EquipmentCRUDActionTypes.REQUEST_EQUIPMENT_PAGE;

  constructor(public payload?: any) {}
}
export class ReceiveEquipmentPage implements Action {
  readonly type: EquipmentCRUDActionTypes.RECEIVE_EQUIPMENT_PAGE = EquipmentCRUDActionTypes.RECEIVE_EQUIPMENT_PAGE;

  constructor(public payload: IEquipmentListPage) {}
}
export class ErrorReceiveEquipmentPage implements Action {
  readonly type: EquipmentCRUDActionTypes.ERROR_RECEIVE_EQUIPMENT_PAGE =
    EquipmentCRUDActionTypes.ERROR_RECEIVE_EQUIPMENT_PAGE;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestEquipmentById implements Action {
  readonly type: EquipmentCRUDActionTypes.REQUEST_EQUIPMENT_BY_ID = EquipmentCRUDActionTypes.REQUEST_EQUIPMENT_BY_ID;

  constructor(public payload: string) {}
}
export class ReceiveEquipmentById implements Action {
  readonly type: EquipmentCRUDActionTypes.RECEIVE_EQUIPMENT_BY_ID = EquipmentCRUDActionTypes.RECEIVE_EQUIPMENT_BY_ID;

  constructor(public payload: IEquipment) {}
}
export class ErrorReceiveEquipmentById implements Action {
  readonly type: EquipmentCRUDActionTypes.ERROR_RECEIVE_EQUIPMENT_BY_ID =
    EquipmentCRUDActionTypes.ERROR_RECEIVE_EQUIPMENT_BY_ID;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestSavedEquipments implements Action {
  readonly type: EquipmentCRUDActionTypes.REQUEST_SAVED_EQUIPMENTS = EquipmentCRUDActionTypes.REQUEST_SAVED_EQUIPMENTS;

  constructor(public payload: string[]) {}
}
export class ReceiveSavedEquipments implements Action {
  readonly type: EquipmentCRUDActionTypes.RECEIVE_SAVED_EQUIPMENTS = EquipmentCRUDActionTypes.RECEIVE_SAVED_EQUIPMENTS;

  constructor(public payload: IEquipment[]) {}
}
export class ErrorReceiveSavedEquipments implements Action {
  readonly type: EquipmentCRUDActionTypes.ERROR_RECEIVE_SAVED_EQUIPMENTS =
    EquipmentCRUDActionTypes.ERROR_RECEIVE_SAVED_EQUIPMENTS;

  constructor(public payload: HttpErrorResponse) {}
}
export class ResetSavedEquipments implements Action {
  readonly type: EquipmentCRUDActionTypes.RESET_SAVED_EQUIPMENTS = EquipmentCRUDActionTypes.RESET_SAVED_EQUIPMENTS;
}

export class RequestSavedLocationEquipments implements Action {
  readonly type: EquipmentCRUDActionTypes.REQUEST_SAVED_LOCATION_EQUIPMENTS =
    EquipmentCRUDActionTypes.REQUEST_SAVED_LOCATION_EQUIPMENTS;

  constructor(public payload: string) {}
}
export class ReceiveSavedLocationEquipments implements Action {
  readonly type: EquipmentCRUDActionTypes.RECEIVE_SAVED_LOCATION_EQUIPMENTS =
    EquipmentCRUDActionTypes.RECEIVE_SAVED_LOCATION_EQUIPMENTS;

  constructor(public payload: IEquipment[]) {}
}
export class ErrorReceiveSavedLocationEquipments implements Action {
  readonly type: EquipmentCRUDActionTypes.ERROR_RECEIVE_SAVED_LOCATION_EQUIPMENTS =
    EquipmentCRUDActionTypes.ERROR_RECEIVE_SAVED_LOCATION_EQUIPMENTS;

  constructor(public payload: HttpErrorResponse) {}
}
export class ResetSavedLocationEquipment implements Action {
  readonly type: EquipmentCRUDActionTypes.RESET_SAVED_LOCATION_EQUIPMENTS =
    EquipmentCRUDActionTypes.RESET_SAVED_LOCATION_EQUIPMENTS;
}

export class RequestAddEquipment implements Action {
  readonly type: EquipmentCRUDActionTypes.REQUEST_ADD_EQUIPMENT = EquipmentCRUDActionTypes.REQUEST_ADD_EQUIPMENT;

  constructor(public payload: ICreateUpdateEquipment) {}
}
export class ReceiveAddEquipment implements Action {
  readonly type: EquipmentCRUDActionTypes.RECEIVE_ADD_EQUIPMENT = EquipmentCRUDActionTypes.RECEIVE_ADD_EQUIPMENT;

  constructor(public payload: IEquipment) {}
}
export class ErrorAddEquipment implements Action {
  readonly type: EquipmentCRUDActionTypes.ERROR_ADD_EQUIPMENT = EquipmentCRUDActionTypes.ERROR_ADD_EQUIPMENT;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestUpdateEquipment implements Action {
  readonly type: EquipmentCRUDActionTypes.REQUEST_UPDATE_EQUIPMENT = EquipmentCRUDActionTypes.REQUEST_UPDATE_EQUIPMENT;

  constructor(public payload: ICreateUpdateEquipment) {}
}
export class ReceiveUpdateEquipment implements Action {
  readonly type: EquipmentCRUDActionTypes.RECEIVE_UPDATE_EQUIPMENT = EquipmentCRUDActionTypes.RECEIVE_UPDATE_EQUIPMENT;

  constructor(public payload: IEquipment) {}
}
export class ErrorUpdateEquipment implements Action {
  readonly type: EquipmentCRUDActionTypes.ERROR_UPDATE_EQUIPMENT = EquipmentCRUDActionTypes.ERROR_UPDATE_EQUIPMENT;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestEquipmentDelete implements Action {
  readonly type: EquipmentCRUDActionTypes.REQUEST_EQUIPMENT_DELETE = EquipmentCRUDActionTypes.REQUEST_EQUIPMENT_DELETE;

  constructor(public payload: IEquipment) {}
}
export class ReceiveEquipmentDelete implements Action {
  readonly type: EquipmentCRUDActionTypes.RECEIVE_EQUIPMENT_DELETE = EquipmentCRUDActionTypes.RECEIVE_EQUIPMENT_DELETE;

  constructor(public payload: IEquipment) {}
}
export class ErrorReceiveEquipmentDelete implements Action {
  readonly type: EquipmentCRUDActionTypes.ERROR_RECEIVE_EQUIPMENT_DELETE =
    EquipmentCRUDActionTypes.ERROR_RECEIVE_EQUIPMENT_DELETE;

  constructor(public payload: HttpErrorResponse) {}
}

export class ResetState implements Action {
  readonly type: EquipmentCRUDActionTypes.RESET_STATE = EquipmentCRUDActionTypes.RESET_STATE;
}

export class ResetSelectedEquipmentState implements Action {
  readonly type: EquipmentCRUDActionTypes.RESET_DATA_STATE = EquipmentCRUDActionTypes.RESET_DATA_STATE;
}

export class RequestUploadEquipmentCSVFile implements Action {
  readonly type: EquipmentCRUDActionTypes.REQUEST_UPLOAD_EQUIPMENT_CSV_FILE =
    EquipmentCRUDActionTypes.REQUEST_UPLOAD_EQUIPMENT_CSV_FILE;

  constructor(public payload: any) {}
}

export class ReceiveUploadEquipmentCSVFile implements Action {
  readonly type: EquipmentCRUDActionTypes.RECEIVE_UPLOAD_EQUIPMENT_CSV_FILE =
    EquipmentCRUDActionTypes.RECEIVE_UPLOAD_EQUIPMENT_CSV_FILE;

  constructor(public payload: any) {}
}

export class ErrorReceiveUploadEquipmentCSVFile implements Action {
  readonly type: EquipmentCRUDActionTypes.ERROR_RECEIVE_UPLOAD_EQUIPMENT_CSV_FILE =
    EquipmentCRUDActionTypes.ERROR_RECEIVE_UPLOAD_EQUIPMENT_CSV_FILE;

  constructor(public payload: HttpErrorResponse) {}
}
