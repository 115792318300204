import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TaskResultTypeEnum } from 'src/app/workshop/constants/workshop.constants';

@Component({
  selector: 'ignis-service-details',
  templateUrl: './service-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceDetailsComponent {
  @Input() form: FormGroup;
  @Input() selectedTaskName: string;
  @Input() dateTime: string;
  @Input() taskResultType: string;

  taskResultTypesEnum: typeof TaskResultTypeEnum = TaskResultTypeEnum;
}
