<div [formGroup]="form" [readonly]="true">
  <odx-form-field variant="horizontal" label="{{ 'EQUIPMENT_TASK_RESULTS.STR_PRESSURE' | translate }}">
    <input
      type="text"
      odxFormFieldControl
      id="pressure"
      data-test-id="equipment_task_documentation.pressure"
      formControlName="pressure"
    />
  </odx-form-field>

  <odx-form-field variant="horizontal" label="{{ 'EQUIPMENT_TASK_RESULTS.STR_LOCATION' | translate }}">
    <input
      type="text"
      odxFormFieldControl
      id="locationPath"
      data-test-id="equipment_task_documentation.location"
      formControlName="locationPath"
    />
  </odx-form-field>
</div>
