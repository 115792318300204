/*
  This file is used for any stage except local dev server.

  We use the "build once deploy anywhere approach", so that during the build the values below are computed with the specified tokens.
  Then, during the deploy process, the built files (specifically main.js) are scanned for the string tokens within hashes and curly braces (e.g. #{exampleToken}# ) and are replaced with stage-specific variables.
*/

export const environment = {
  production: true,

  API_BASE_URL: '#{apiBaseUrl}#',

  API_URLS: {
    LOCATION: '#{apiLocation}#',
    TASK_DOCUMENTATION: '#{apiTaskDocumentation}#',
    EQUIPMENT: '#{apiEquipment}#',
    LOGBOOK: '#{apiBaLogBook}#',
    REPORT_CENTER: '#{apiIncidentReport}#',
    USERS: '#{apiUser}#',
    REMOTE_MONITORING: '#{apiRemoteMonitoring}#',
    DEVICE_CONNECTION: '#{apiDeviceConnection}#',
    PROTECTOR_MIGRATION: '#{apiProtectorMigration}#',
    ORGANIZATION: '#{apiOrganization}#',
    BA_WEARER: '#{apiBaWearer}#',
    SPRINT_NUMBER: '#{versionNumber}#',
    HOTFIX_NUMBER: '#{hotfixNumber}#',
    ENV: '#{environment}#',
  },

  APP_INSIGHTS: {
    CONNECTION_STRING: '#{appiConnectionString}#',
    ACTIVATE_APP_INSIGHTS: '#{ACTIVATE_APP_INSIGHTS}#',
  },

  SENTRY: {
    DSN: '#{SENTRY_DSN_ADDRESS}#',
    ACTIVATE_SENTRY: '#{ACTIVATE_SENTRY}#',
    TRACES_SAMPLE_RATE: '#{TRACES_SAMPLE_RATE}#',
    REPLAYS_SESSION_SAMPLE_RATE: '#{REPLAYS_SESSION_SAMPLE_RATE}#',
    REPLAY_ON_ERROR_SAMPLE_RATE: '#{REPLAY_ON_ERROR_SAMPLE_RATE}#',
  },

  PRODUCT_FRUITS: {
    WORKSHOP_CODE: '#{WORKSHOP_CODE}#',
  },

  OKTA: {
    CLIENT_ID: '#{OKTA_APP_CLIENT_ID}#',
    ISSUER: '#{OKTA_ISSUER}#',
  },

  GOOGLE_MAP: {
    GOOGLE_API_KEY: '#{GOOGLE_API_KEY}#',
    DARK_MAP_ID: '#{DARK_MAP_ID}#',
    LIGHT_MAP_ID: '#{LIGHT_MAP_ID}#',
  },

  MAINTENANCE_MODE: {
    TENANTS: '#{apiTenant}#',
  },
};
