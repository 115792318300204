// eslint-disable-next-line no-shadow
export enum EquipmentTestActionTypes {
  REQUEST_EQUIPMENT_TEST_IS_RUNNING = 'REQUEST_EQUIPMENT_TEST_IS_RUNNING',
  RECEIVE_EQUIPMENT_TEST_IS_RUNNING = 'RECEIVE_EQUIPMENT_TEST_IS_RUNNING',
  ERROR_RECEIVE_EQUIPMENT_TEST_IS_RUNNING = 'ERROR_RECEIVE_EQUIPMENT_TEST_IS_RUNNING',

  REQUEST_TEST_PARAMETERS = 'REQUEST_TEST_PARAMETERS',
  RECEIVE_TEST_PARAMETERS = 'RECEIVE_TEST_PARAMETERS',
  ERROR_RECEIVE_TEST_PARAMETERS = 'ERROR_RECEIVE_TEST_PARAMETERS',
  RESET_TEST_PARAMETERS_STATE = 'RESET_TEST_PARAMETERS_STATE',

  REQUEST_START_TEST_EQUIPMENT = 'REQUEST_START_TEST_EQUIPMENT',
  RECEIVE_START_TEST_EQUIPMENT = 'RECEIVE_START_TEST_EQUIPMENT',
  ERROR_RECEIVE_START_TEST_EQUIPMENT = 'ERROR_RECEIVE_START_TEST_EQUIPMENT',

  REQUEST_TEST_RESULTS = 'REQUEST_TEST_RESULTS',
  RECEIVE_TEST_RESULTS = 'RECEIVE_TEST_RESULTS',
  ERROR_RECEIVE_TEST_RESULTS = 'ERROR_RECEIVE_TEST_RESULTS',

  REQUEST_SAVE_TEST_RESULTS = 'REQUEST_SAVE_TEST_RESULTS',
  RECEIVE_SAVE_TEST_RESULTS = 'RECEIVE_SAVE_TEST_RESULTS',
  ERROR_RECEIVE_SAVE_TEST_RESULTS = 'ERROR_RECEIVE_SAVE_TEST_RESULTS',

  REQUEST_WORKFLOW_ASSET = 'REQUEST_WORKFLOW_ASSET',
  RECEIVE_WORKFLOW_ASSET = 'RECEIVE_WORKFLOW_ASSET',
  ERROR_RECEIVE_WORKFLOW_ASSET = 'ERROR_RECEIVE_WORKFLOW_ASSET',
  RESET_WORKFLOW_ASSET_STATE = 'RESET_WORKFLOW_ASSET_STATE',
}
