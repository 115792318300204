import { Component } from '@angular/core';
import { OnDestroyMixin } from 'src/app/common/mixins';

@Component({
  selector: 'ignis-tasks',
  templateUrl: './tasks.component.html',
})
export class TasksComponent extends OnDestroyMixin() {
  constructor() {
    super();
  }
}
