import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { EquipmentHierarchy, IEditEquipmentHierarchy, IEquipmentHierarchy } from 'src/app/configuration/models';
import { EquipmentConfigurationService } from 'src/app/configuration/services/equipment-configuration.service';
import {
  EquipmentHierarchyActionTypes,
  ErrorReceiveAddEquipmentHierarchy,
  ErrorReceiveDeleteEquipmentHierarchy,
  ErrorReceiveEditEquipmentHierarchy,
  ErrorReceiveEquipmentHierarchy,
  ErrorReceiveImportEquipmentHierarchy,
  ReceiveAddEquipmentHierarchy,
  ReceiveDeleteEquipmentHierarchy,
  ReceiveEditEquipmentHierarchy,
  ReceiveEquipmentHierarchy,
  ReceiveImportEquipmentHierarchy,
} from '../../actions';

@Injectable()
export class EquipmentHierarchyEffects {
  equipmentConfigurationService: EquipmentConfigurationService = inject(EquipmentConfigurationService);

  requestEquipmentHierarchy$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(EquipmentHierarchyActionTypes.REQUEST_EQUIPMENT_HIERARCHY),
      switchMap(() =>
        this.equipmentConfigurationService.getEquipmentHierarchy().pipe(
          map(
            (hierarchy: IEquipmentHierarchy[] | any) =>
              new ReceiveEquipmentHierarchy(
                hierarchy.data.map((data: IEquipmentHierarchy) => new EquipmentHierarchy(data)),
              ),
          ),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveEquipmentHierarchy(error))),
        ),
      ),
    );
  });

  requestAddEquipmentHierarchy$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(EquipmentHierarchyActionTypes.REQUEST_ADD_EQUIPMENT_HIERARCHY),
      switchMap((params: any) =>
        this.equipmentConfigurationService.addEquipmentHierarchy(params).pipe(
          map((newHierarchy: IEquipmentHierarchy) => new ReceiveAddEquipmentHierarchy(newHierarchy)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAddEquipmentHierarchy(error))),
        ),
      ),
    );
  });

  requestEditEquipmentHierarchy$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(EquipmentHierarchyActionTypes.REQUEST_EDIT_EQUIPMENT_HIERARCHY),
      switchMap((params: IEditEquipmentHierarchy | any) =>
        this.equipmentConfigurationService.editEquipmentHierarchy(params.payload).pipe(
          map((editedHierarchy: IEditEquipmentHierarchy) => new ReceiveEditEquipmentHierarchy(editedHierarchy)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveEditEquipmentHierarchy(error))),
        ),
      ),
    );
  });

  requestDeleteEquipmentHierarchy$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(EquipmentHierarchyActionTypes.REQUEST_DELETE_EQUIPMENT_HIERARCHY),
      switchMap((params: any) =>
        this.equipmentConfigurationService.deleteEquipmentHierarchy(params).pipe(
          map((deleteHierarchy: any) => new ReceiveDeleteEquipmentHierarchy(deleteHierarchy)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveDeleteEquipmentHierarchy(error))),
        ),
      ),
    );
  });

  requestImportEquipmentHierarchy$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(EquipmentHierarchyActionTypes.REQUEST_IMPORT_EQUIPMENT_HIERARCHY),
      switchMap((params: { language: string }) =>
        this.equipmentConfigurationService.importEquipmentHierarchy(params).pipe(
          map((importedHierarchy: unknown) => new ReceiveImportEquipmentHierarchy(importedHierarchy)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveImportEquipmentHierarchy(error))),
        ),
      ),
    );
  });
}
