import { GenericAction } from 'src/app/common';
import { errorItemData, receiveItemData, requestItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { IEntriesModel } from 'src/app/logbook/models/logbook.model';
import { RemoteMonitoringActionTypes } from '../../actions/remote-monitoring-action-types';
import { IRemoteMonitoringEntriesState, RemoteMonitoringEntriesState } from '../../models/entries.model';

export function remoteMonitoringEntriesReducer(
  lastState: IRemoteMonitoringEntriesState = new RemoteMonitoringEntriesState(),
  action: GenericAction<RemoteMonitoringActionTypes, any>
): IRemoteMonitoringEntriesState {

  switch (action.type) {
    case RemoteMonitoringActionTypes.REQUEST_REMOTE_MONITORING_ENTRIES:
      return requestItemData<IRemoteMonitoringEntriesState, IEntriesModel>(lastState, ['entriesList']);
    case RemoteMonitoringActionTypes.RECEIVE_REMOTE_MONITORING_ENTRIES:
      return receiveItemData<IRemoteMonitoringEntriesState, IEntriesModel>(lastState, ['entriesList'], action.payload);
    case RemoteMonitoringActionTypes.ERROR_RECEIVE_REMOTE_MONITORING_ENTRIES:
      return errorItemData<IRemoteMonitoringEntriesState, IEntriesModel>(lastState, ['entriesList'], action.payload);

    default:
      return lastState;
  }
}
