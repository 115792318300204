import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IEditEquipmentHierarchy } from 'src/app/configuration/models';
import { IConfigurationState } from '../../models/configuration.models';

import {
  RequestAddEquipmentHierarchy,
  RequestDeleteEquipmentHierarchy,
  RequestEditEquipmentHierarchy,
  RequestEquipmentHierarchy,
  RequestImportEquipmentHierarchy,
} from './equipment-hierarchy-action-types-creators';

@Injectable({ providedIn: 'root' })
export class EquipmentHierarchyActions {
  constructor(public store: Store<IConfigurationState>) {}

  public requestEquipmentHierarchy(): void {
    this.store.dispatch(new RequestEquipmentHierarchy());
  }

  public requestAddEquipmentHierarchy(params: any): void {
    this.store.dispatch(new RequestAddEquipmentHierarchy(params));
  }

  public requestEditEquipmentHierarchy(params: IEditEquipmentHierarchy): void {
    this.store.dispatch(new RequestEditEquipmentHierarchy(params));
  }

  public requestDeleteEquipmentHierarchy(params: { urlPath: string; version: number }): void {
    this.store.dispatch(new RequestDeleteEquipmentHierarchy(params));
  }

  public requestImportEquipmentHierarchy(language: string): void {
    this.store.dispatch(new RequestImportEquipmentHierarchy(language));
  }
}
