import { IStoreApiItem, StoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IStoreApiList, StoreApiList } from './../../../common/models/store-api-list.model';
import { ILogbook, ILogbookPage } from './../../models/logbook.model';
import { IEntriesModel } from '../../models/logbook.model';

export interface ILogbookState {
  logbookPage: IStoreApiItem<ILogbookPage>;
  entries: IStoreApiItem<IEntriesModel>;
  logbookExcel: IStoreApiList<ILogbook[]>;
}

export class LogbookState implements ILogbookState {
  logbookPage: IStoreApiItem<ILogbookPage> = new StoreApiItem();
  entries: IStoreApiItem<IEntriesModel> = new StoreApiItem();
  logbookExcel: IStoreApiList<ILogbook[]> = new StoreApiList();
}
