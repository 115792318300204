<div class="c-create-media-notes__wrapper">
  <div class="c-form-wrapper">
    <div>
      <div class="notes-widget">
        <div class="notes-list">
          <div class="notes-section" [ngClass]="{ 'center-aligned-loader': isLoadingNotes }">
            <div *ngIf="!isLoadingNotes; else isLoading" class="position-relative">
              <ignis-media-notes-list
                *ngIf="notes && notes?.length; else noNotes"
                [notes]="notes"
                [formatDate]="formatDate"
              ></ignis-media-notes-list>
              <ng-template #noNotes>
                <h2 class="no-notes-label">{{ 'INCIDENT_MEDIA_ASSETS.STR_NO_NOTES' | translate }}</h2>
              </ng-template>
            </div>
            <ng-template #isLoading>
              <div [odxLoadingSpinner]="true" [odxLoadingSpinnerMinHeight]="60"></div>
            </ng-template>
          </div>
        </div>

        <div class="add-notes">
          <button
            odxButton
            variant="highlight"
            (click)="displayAddNote()"
            *ngIf="!showAddNote"
            [disabled]="incidentForm?.dirty"
            data-test-id="incident_media_assets.display_add_note_section"
          >
            {{ 'INCIDENT_MEDIA_ASSETS.STR_ADD_NOTES' | translate }}
          </button>

          <div class="position-relative new-note-input-group" *ngIf="showAddNote">
            <form [formGroup]="newNoteForm" (ngSubmit)="addNote()">
              <div class="odx-form-field--horizontal odx-form-field">
                <div class="odx-form-field__inner">
                  <div class="odx-form-field__control">
                    <input
                      autofocus
                      type="text"
                      ignisDisableAutocomplete
                      odxFormControl
                      id="noteText"
                      data-test-id="incident_media_assets.note_text"
                      formControlName="noteText"
                      class="new-note-input"
                      ngDefaultControl
                      (keyup.enter)="newNoteForm.get('noteText').value?.length > 0 ? addNote() : ''"
                      placeholder="{{ 'INCIDENT_MEDIA_ASSETS.STR_NOTE_PLACEHOLDER' | translate }}"
                    />
                  </div>
                </div>
              </div>

              <button
                odxButton
                variant="highlight"
                type="button"
                *ngIf="newNoteForm.get('noteText').value?.length > 0; else closeAddNote"
                (click)="addNote()"
                data-test-id="incident_media_assets.add_note_btn"
              >
                <odx-icon size="inline" name="arrow1-right"></odx-icon>
              </button>

              <ng-template #closeAddNote>
                <button
                  type="button"
                  odxButton
                  variant="secondary"
                  (click)="hideAddNote()"
                  data-test-id="incident_media_assets.clear_add_note_btn"
                >
                  <odx-icon size="inline" name="close"></odx-icon>
                </button>
              </ng-template>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
