import { CommonConstants } from 'src/app/common';
export class ProductName {
  productName: string;

  constructor();
  constructor(name: any);
  constructor(name?: any) {
    if (!name) {
      this.productName = null;
    } else {
      this.productName = CommonConstants.productNames[name.productName];
    }
  }
}
