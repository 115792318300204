<div class="ecp-teams-container pl-16 pt-16">
  <ignis-ecp-statistics [selectedEcp]="selectedEcp"></ignis-ecp-statistics>
  <ngx-simplebar [options]="scrollbarOptions" class="ecps-grid-row">
    <div class="row teams-row pr-32">
      <div
        class="ecp-tile"
        [ngClass]="{ 'tile-w-100': team?.teamId === '0' }"
        *ngFor="let team of selectedEcp?.teams; trackBy: trackByTeam"
      >
        <ignis-team-tile
          [teamData]="team"
          [currentAppTheme]="currentAppTheme"
          (openNotes)="openNotes($event)"
        ></ignis-team-tile>
      </div>
    </div>
  </ngx-simplebar>

  <div
    class="notes-widget-btn"
    (click)="openNotes()"
    data-test-id="remote_monitoring.open_notes_widget"
    *ngIf="!openNotesWidget"
    [ngClass]="{
      'slide-in-right': !openNotesWidget,
    }"
    [class]="currentAppTheme"
  >
    <div
      class="hotspot hotspot--warning notification-dot"
      *ngIf="newNotesNumber > 0"
      data-test-id="remote_monitoring.new_notes_number"
    >
      <span class="hotspot__pulse"></span>
      <span class="hotspot__dot"></span>
      <span class="notes-number">{{ newNotesNumber }}</span>
    </div>
    <odx-icon size="inline" name="comment"></odx-icon>
  </div>
</div>

<ignis-notes-widget
  [openNotesWidget]="openNotesWidget"
  [openFromTeams]="openFromTeams"
  [notes]="notes"
  [team]="team"
  [incidentId]="selectedIncidentId"
  [incidentVersion]="incidentVersion"
  [formatDate]="formatDate"
  (handleCloseNotesWidget)="closeNotes($event)"
  (handleNewNotesNumber)="getNewNotesNumber($event)"
  (handleAddNewNote)="requestIncidentData()"
></ignis-notes-widget>
