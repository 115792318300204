import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EntriesModel, IEntriesModel } from 'src/app/report-center/models/incident.model';
import { IIncident, IMonitoringIncidents, Incident, IncidentEcp } from '../../models/remote-monitoring.model';
import {
  ErrorReceiveIncidentEntries,
  ReceiveIncidentEntries,
} from '../actions/remote-monitoring-action-types-creators/remote-monitoring-entries-action-types-creators';
import {
  ErrorReceiveIncidentById,
  ErrorReceiveIncidentEcpById,
  ErrorReceiveMonitoringIncidents,
  ErrorReceiveRemoveIncident,
  ReceiveIncidentById,
  ReceiveIncidentEcpById,
  ReceiveMonitoringIncidents,
  ReceiveRemoveIncident,
} from '../actions/remote-monitoring-action-types-creators/remote-monitoring-incident-action-types-creators';
import {
  ErrorMergeIncidents,
  ReceiveMergeIncidents,
} from '../actions/remote-monitoring-action-types-creators/remote-monitoring-merging-action-types-creators';
import {
  ErrorReceiveAddNote,
  ErrorReceiveNotesList,
  ErrorReceiveReadNotes,
  ReceiveAddNote,
  ReceiveNotesList,
  ReceiveReadNotes,
} from '../actions/remote-monitoring-action-types-creators/remote-monitoring-notes-action-types-creators';
import { IIncidentEcp, INewNote, INote, Note } from './../../models/remote-monitoring.model';
import { RemoteMonitoringService } from './../../services/remote-monitoring.service';
import { RemoteMonitoringActionTypes } from './../actions/remote-monitoring-action-types';

@Injectable()
export class RemoteMonitoringEffects {
  constructor(private remoteMonitoringService: RemoteMonitoringService) {}

  requestMonitoringIncidents$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(RemoteMonitoringActionTypes.REQUEST_MONITORING_INCIDENTS),
      switchMap(() =>
        this.remoteMonitoringService.getIncidents().pipe(
          map((incidents: IMonitoringIncidents) => new ReceiveMonitoringIncidents(incidents)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveMonitoringIncidents(error))),
        ),
      ),
    );
  });

  requestIncidentById$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(RemoteMonitoringActionTypes.REQUEST_MONITORING_INCIDENT_BY_ID),
      switchMap((incidentId: any) =>
        this.remoteMonitoringService.getIncidentById(incidentId.payload).pipe(
          map((incident: { body: IIncident }) => new ReceiveIncidentById(new Incident(incident.body))),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveIncidentById(error))),
        ),
      ),
    );
  });

  requestIncidentEcpById$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(RemoteMonitoringActionTypes.REQUEST_INCIDENT_ECP_BY_ID),
      switchMap((payload: any) =>
        this.remoteMonitoringService.getIncidentEcpById(payload.incidentId, payload.ecpId).pipe(
          map((incidentEcp: IIncidentEcp) => new ReceiveIncidentEcpById(new IncidentEcp(incidentEcp))),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveIncidentEcpById(error))),
        ),
      ),
    );
  });

  requestNotesList$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(RemoteMonitoringActionTypes.REQUEST_NOTES_LIST),
      switchMap((incidentId: any) =>
        this.remoteMonitoringService.getNotesList(incidentId.payload).pipe(
          map((notes: INote[]) => new ReceiveNotesList(notes.map((note: INote) => new Note(note)))),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveNotesList(error))),
        ),
      ),
    );
  });

  setReadNotes$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(RemoteMonitoringActionTypes.REQUEST_READ_NOTES),
      switchMap((payload: any) =>
        this.remoteMonitoringService.setReadNotes(payload.incidentId, payload.notes, payload.incidentVersion).pipe(
          map(() => new ReceiveReadNotes()),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveReadNotes(error))),
        ),
      ),
    );
  });

  requestIncidentEntries$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(RemoteMonitoringActionTypes.REQUEST_REMOTE_MONITORING_ENTRIES),
      switchMap(() =>
        this.remoteMonitoringService.getIncidentEntries().pipe(
          map((entries: IEntriesModel) => new ReceiveIncidentEntries(new EntriesModel(entries))),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveIncidentEntries(error))),
        ),
      ),
    );
  });

  mergeIncidents$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(RemoteMonitoringActionTypes.REQUEST_MERGE_RM_INCIDENTS),
      switchMap((payload: any) =>
        this.remoteMonitoringService.mergeIncidents(payload, payload.incidentVersion).pipe(
          map((response: any) => new ReceiveMergeIncidents(response)),
          catchError((error: HttpErrorResponse) => of(new ErrorMergeIncidents(error))),
        ),
      ),
    );
  });

  closeIncident$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(RemoteMonitoringActionTypes.REQUEST_REMOVE_INCIDENT),
      switchMap((incident: any) =>
        this.remoteMonitoringService.removeIncident(incident.payload, incident.incidentVersion).pipe(
          map((response: any) => new ReceiveRemoveIncident(response)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveRemoveIncident(error))),
        ),
      ),
    );
  });

  requestAdNote$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(RemoteMonitoringActionTypes.REQUEST_ADD_NOTE),
      switchMap((newNote: INewNote) =>
        this.remoteMonitoringService.addNote(newNote).pipe(
          map(() => new ReceiveAddNote()),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAddNote(error))),
        ),
      ),
    );
  });
}
