@if (batchChecklistFeatureToggle) {
  <button
    type="button"
    [disabled]="
      !selectedItems?.length ||
      disableChecklistBntFlow ||
      this.router.url.includes(WorkshopModuleRoutes.completeBatchChecklist) ||
      this.router.url.includes(WorkshopModuleRoutes.completeChecklist) ||
      this.router.url.includes(WorkshopModuleRoutes.cylinder) ||
      isModalOpened
    "
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    (click)="onPerformCompleteChecklistClick($event)"
    id="bluebar-batch-checklist-btn"
    data-test-id="batch_checklist.execute_btn"
    [pTooltip]="batchChecklistButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #batchChecklistButtonTooltipContent>
      @if (selectedItems && displayNoChecklistTooltip) {
        <div data-test-id="batch_checklist.no_checklist_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_EQUIPMENT_WITH_NO_CHECKLIST' | translate }}
        </div>
      } @else if (selectedItems?.length > 1 && displayNoServiceIdTooltip) {
        <div data-test-id="batch_checklist.not_common_checklist_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_NO_COMMON_CHECKLIST' | translate }}
        </div>
      } @else if (!disableChecklistBntFlow) {
        <div data-test-id="batch_checklist.perform_checklist_service_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_PERFORM_CHECKLIST_SERVICE_TOOLTIP' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon name="test" size="inline"></odx-icon>
  </button>
} @else {
  <button
    type="button"
    [disabled]="
      !selectedItems?.length ||
      disableChecklistBntFlow ||
      selectedItems?.length > 1 ||
      this.router.url.includes(WorkshopModuleRoutes.completeChecklist) ||
      this.router.url.includes(WorkshopModuleRoutes.cylinder) ||
      isModalOpened
    "
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    (click)="onPerformCompleteChecklistClick($event)"
    id="bluebar-checklist-btn"
    data-test-id="checklist.checklist_btn"
    [pTooltip]="checklistButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #checklistButtonTooltipContent>
      @if (disableChecklistBntFlow) {
        <div data-test-id="checklist.no_checklist_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_EQUIPMENT_WITH_NO_CHECKLIST' | translate }}
        </div>
      } @else if (selectedItems?.length > 1) {
        <div data-test-id="checklist.multiple_rows_selected_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_MULTIPLE_ROWS_SELECTED' | translate }}
        </div>
      } @else if (!disableChecklistBntFlow && selectedItems?.length < 2) {
        <div data-test-id="checklist.perform_checklist_service_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_PERFORM_CHECKLIST_SERVICE_TOOLTIP' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon name="test" size="inline"></odx-icon>
  </button>
}
