import { Directive, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { CommonConstants } from '../constants';
import { htmlTags } from '../constants/htmlTags';

@Directive({
  selector: '[ignisNoAllowHtmlTags]',
})
export class NoAllowHtmlTagsDirective implements OnInit {
  @Output() emitIsInputValueValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.emitIsInputValueValid.emit(true);
  }

  validateInput(value: string): boolean {
    const tagPattern: RegExp = CommonConstants.matchHTMLTags;
    const match: RegExpMatchArray = tagPattern.exec(value);

    return match ? match[1] === '' || htmlTags.includes(match[1]?.toLowerCase()) : false;
  }

  sanitizeForEmptyTagsInput(input: HTMLInputElement, value: string): void {
    htmlTags.forEach((tag: string) => {
      if (value.toLocaleLowerCase().includes(`<${tag}>`) || value.includes('<>')) {
        this.emitIsInputValueValid.emit(false);
        const sanitizedValue: string = value.replace(/\/>|>/g, '');

        input.value = sanitizedValue;
      }
    });
  }

  @HostListener('input', ['$event'])
  onInputChange(event: Event): void {
    const input: HTMLInputElement = event.target as HTMLInputElement;
    const value: string = input.value;

    this.emitIsInputValueValid.emit(true);

    this.sanitizeForEmptyTagsInput(input, value);

    if (this.validateInput(value)) {
      this.emitIsInputValueValid.emit(false);
      const sanitizedValue: string = value.replace(/\/>|>/g, '');

      input.value = sanitizedValue;
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const input: HTMLInputElement = event.target as HTMLInputElement;
    const value: string = input.value;

    const clipboardData: DataTransfer = event.clipboardData || (window as any).clipboardData;
    const pastedText: string = clipboardData.getData('text/plain');

    this.sanitizeForEmptyTagsInput(input, value);

    if (this.validateInput(pastedText)) {
      this.emitIsInputValueValid.emit(false);
      const sanitizedValue: string = value.replace(/\/>|>/g, '');

      input.value = sanitizedValue;
    }
  }
}
