// eslint-disable-next-line no-shadow
export enum SettingsActionTypes {
  REQUEST_LOCALIZED_OPTIONS = 'REQUEST_LOCALIZED_OPTIONS',
  RECEIVE_LOCALIZED_OPTIONS = 'RECEIVE_LOCALIZED_OPTIONS',
  ERROR_RECEIVE_LOCALIZED_OPTIONS = 'RECEIVE_LOCALIZED_OPTIONS_FAILED',

  REQUEST_GET_USER_SETTINGS = 'REQUEST_GET_USER_SETTINGS',
  RECEIVE_GET_USER_SETTINGS = 'RECEIVE_GET_USER_SETTINGS',
  ERROR_GET_USER_SETTINGS = 'ERROR_GET_USER_SETTINGS',

  REQUEST_SAVE_USER_SETTINGS = 'REQUEST_SAVE_USER_SETTINGS',
  RECEIVE_SAVE_USER_SETTINGS = 'RECEIVE_SAVE_USER_SETTINGS',
  ERROR_SAVE_USER_SETTINGS = 'ERROR_SAVE_USER_SETTINGS',

  REQUEST_MEASUREMENT_UNITS = 'REQUEST_MEASUREMENT_UNITS',
  RECEIVE_MEASUREMENT_UNITS = 'RECEIVE_MEASUREMENT_UNITS',
  ERROR_RECEIVE_MEASUREMENT_UNITS = 'ERROR_RECEIVE_MEASUREMENT_UNITS',

  REQUEST_TABLE_COLUMNS = 'REQUEST_TABLE_COLUMNS',
  RECEIVE_TABLE_COLUMNS = 'RECEIVE_TABLE_COLUMNS',
  ERROR_RECEIVE_TABLE_COLUMNS = 'ERROR_RECEIVE_TABLE_COLUMNS',
  RESET_TABLE_COLUMN = 'RESET_TABLE_COLUMN',

  REQUEST_UPDATE_TABLE_COLUMN = 'REQUEST_UPDATE_TABLE_COLUMN',
  RECEIVE_UPDATE_TABLE_COLUMN = 'RECEIVE_UPDATE_TABLE_COLUMN',
  ERROR_RECEIVE_UPDATE_TABLE_COLUMN = 'ERROR_RECEIVE_UPDATE_TABLE_COLUMN',
}
