import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CheckingSettingsModalsService, PropertyBag } from 'src/app/common';
import { IChecklist } from 'src/app/configuration/models';
import { AppUserPermissionList } from 'src/app/root/models/app-types';
import { AccessControlService } from 'src/app/root/services';
import { WorkshopConstants } from 'src/app/workshop/constants';
import { ITaskData, ITestValue } from 'src/app/workshop/models';
import { DetectChangesInFormsService } from '../../../../../common/services/detect-changes/detect-changes-in-forms.service';

@Component({
  selector: 'ignis-task-result-view-footer',
  templateUrl: './task-result-view-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskResultViewViewFooterComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() selectedTaskName: string;
  @Input() dateTime: string;
  @Input() activeTab: string;
  @Input() formatDate: string;
  @Input() isModalOpened: boolean;
  @Input() taskValues: ITestValue[];
  @Input() taskResultType: string;
  @Input() taskData: ITaskData;
  @Input() checklistData: IChecklist;
  @Input() isSubmitDisabled: boolean = false;
  @Input() isFromMigration: boolean;

  @Output() handleSubmit: EventEmitter<void> = new EventEmitter<void>();
  @Output() handleExport: EventEmitter<void> = new EventEmitter<void>();
  @Output() handlePrintResult: EventEmitter<void> = new EventEmitter<void>();
  @Output() handleSaveFromExport: EventEmitter<void> = new EventEmitter<void>();

  permissions: string[] = [];
  printFeatureToggle: boolean = false;

  appUserPermissionList: typeof AppUserPermissionList = AppUserPermissionList;
  testResultsTabs: PropertyBag = WorkshopConstants.testResultTabs;

  destroyRef: DestroyRef = inject(DestroyRef);
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  public accessControlService: AccessControlService = inject(AccessControlService);
  private checkSettingsModal: CheckingSettingsModalsService = inject(CheckingSettingsModalsService);
  detectChangesInFormsService: DetectChangesInFormsService = inject(DetectChangesInFormsService);

  constructor() {
    this.permissions = this.accessControlService.permissions;
    this.printFeatureToggle = this.accessControlService.equipmentPrintTaskResultToggle;

    this.checkSettingsModal.checkIfSettingsModalIsOpen().subscribe((isModalOpened: boolean) => {
      this.isModalOpened = isModalOpened;
      this.cdr.detectChanges();
    });
  }

  ngOnInit(): void {
    this.detectChangesInFormsService.detectChangesInForms(this.form, this.cdr);
  }

  onSubmit(e: Event): void {
    e.stopPropagation();
    this.handleSubmit.emit();
  }

  saveTestResult(): void {
    this.handleSaveFromExport.emit();
  }

  printTestResult(): void {
    this.handlePrintResult.emit();
  }
}
