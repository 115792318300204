import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs';
import { IApplicationState, IStoreApiList } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { AppUserPermissionList } from 'src/app/root/models/app-types';
import { AccessControlService } from 'src/app/root/services/access-control/access-control.service';
import { IFeatureToggle } from 'src/app/root/state/features-toggle/models/features-toggle.model';
import { selectFeaturesToggleList } from 'src/app/root/state/features-toggle/selectors/features-toggle.selector';

@Injectable()
export class LocationEquipmentAddressBookTabGuard extends OnDestroyMixin() {
  redirectToOverview: boolean;

  constructor(
    private accessControlService: AccessControlService,
    private router: Router,
    private store: Store<IApplicationState>,
  ) {
    super();
  }

  canActivate(): void {
    this.store
      .pipe(select(selectFeaturesToggleList))
      .pipe(takeUntil(this.destroy))
      .subscribe((state: IStoreApiList<IFeatureToggle[]>) => {
        if (state.data) {
          if (
            !(
              this.accessControlService.checkPermission(AppUserPermissionList.configuration) &&
              this.accessControlService.checkPermission(AppUserPermissionList.equipmentWrite)
            )
          ) {
            this.redirectToOverview = false;
            this.router.navigate(['']);

            return false;
          } else {
            this.redirectToOverview = true;

            return true;
          }
        }
      });
  }
}
