<form [formGroup]="mergeIncidentsForm">
  <ng-template odxModal #mergeIncidentsModal let-modal="close">
    <odx-modal-header data-test-id="merge_incidents.confirm_modal">
      <odx-area-header>
        {{ 'MERGE_INCIDENTS.STR_TITLE' | translate }}
      </odx-area-header>
    </odx-modal-header>
    <odx-modal-content class="custom-container">
      <p>
        {{ 'MERGE_INCIDENTS.STR_DESCRIPTION' | translate }}
      </p>

      <div class="row">
        <div class="col-6 mb-3">
          <h5>
            {{ 'MERGE_INCIDENTS.STR_INCIDENT_ONE' | translate
            }}<span *ngIf="selectedIncidents[0].primaryEcp.location.address; else displayIncidentNumber1"
              >: "{{ selectedIncidents[0].primaryEcp.location.address }}"</span
            >

            <ng-template #displayIncidentNumber1>
              <span *ngIf="selectedIncidents[0].incidentNumber">: "{{ selectedIncidents[0].incidentNumber }}"</span>
            </ng-template>
          </h5>
        </div>

        <div class="col-6 mb-3">
          <h5>
            {{ 'MERGE_INCIDENTS.STR_INCIDENT_TWO' | translate }}
            <span *ngIf="selectedIncidents[1].primaryEcp.location.address; else displayIncidentNumber2"
              >: " {{ selectedIncidents[1].primaryEcp.location.address }}"</span
            >

            <ng-template #displayIncidentNumber2>
              <span *ngIf="selectedIncidents[1].incidentNumber">: "{{ selectedIncidents[1].incidentNumber }}"</span>
            </ng-template>
          </h5>
        </div>

        <div class="col-6">
          <div class="col-md-10">
            <odx-form-field variant="full" [readonly]="mergeIncidentsForm.get('first_date').disabled">
              <odx-form-field-label>
                <odx-icon size="medium" name="document"></odx-icon>
                {{ 'MERGE_INCIDENTS.STR_INCIDENT_DATE' | translate }}
              </odx-form-field-label>

              <input
                type="text"
                ignisDisableAutocomplete
                id="first_date"
                data-test-id="merge_incidents.first_incident_date"
                formControlName="first_date"
                odxFormFieldControl
              />
            </odx-form-field>
          </div>

          <div class="col-md-5 mt-3">
            <odx-form-field
              variant="full"
              [readonly]="mergeIncidentsForm.get('first_number').disabled"
              [title]="mergeIncidentsForm.get('first_number').value"
            >
              <odx-form-field-label>
                <odx-icon size="medium" name="document"></odx-icon>
                {{ 'MERGE_INCIDENTS.STR_INCIDENT_NUMBER' | translate }}
              </odx-form-field-label>

              <input
                type="text"
                ignisDisableAutocomplete
                id="first_number"
                data-test-id="merge_incidents.first_incident_number"
                formControlName="first_number"
                odxFormFieldControl
              />
            </odx-form-field>
          </div>

          <div class="col-md-12 mt-3">
            <div class="row">
              <div class="col-md-5">
                <odx-form-field variant="full" [readonly]="mergeIncidentsForm.get('first_ecps_number').disabled">
                  <odx-form-field-label>
                    <span class="baselocation-icon"></span>
                    {{ 'MERGE_INCIDENTS.STR_ECPS_NUMBER' | translate }}
                  </odx-form-field-label>

                  <input
                    type="text"
                    ignisDisableAutocomplete
                    id="first_ecps_number"
                    data-test-id="merge_incidents.first_ecps_number"
                    formControlName="first_ecps_number"
                    odxFormFieldControl
                  />
                </odx-form-field>
              </div>

              <div class="col-md-5">
                <odx-form-field
                  variant="full"
                  [readonly]="mergeIncidentsForm.get('first_ecp_address').disabled"
                  [title]="mergeIncidentsForm.get('first_ecp_address').value"
                >
                  <odx-form-field-label>
                    <odx-icon name="tablet" size="medium"></odx-icon>
                    {{ 'MERGE_INCIDENTS.STR_ECP_NAME' | translate }}
                  </odx-form-field-label>

                  <input
                    type="text"
                    ignisDisableAutocomplete
                    id="first_ecp_address"
                    data-test-id="merge_incidents.first_ecp_address"
                    formControlName="first_ecp_address"
                    odxFormFieldControl
                  />
                </odx-form-field>
              </div>
            </div>
          </div>

          <div class="col-md-12 mt-3">
            <odx-form-field
              variant="full"
              class="custom-input-width"
              [readonly]="mergeIncidentsForm.get('first_deployed_firefighters').disabled"
            >
              <odx-form-field-label>
                <odx-icon size="medium" name="breathing-apparatus"></odx-icon>
                {{ 'MERGE_INCIDENTS.STR_DEPLOYED_FIREFIGHTERS' | translate }}
              </odx-form-field-label>

              <input
                type="text"
                ignisDisableAutocomplete
                id="first_deployed_firefighters"
                data-test-id="merge_incidents.first_deployed_firefighters"
                formControlName="first_deployed_firefighters"
                odxFormFieldControl
              />
            </odx-form-field>
          </div>
        </div>

        <div class="merge-icons-group">
          <odx-icon name="arrow2-right"></odx-icon>
          <odx-icon name="collection-add"></odx-icon>
        </div>

        <div class="col-6">
          <div class="col-md-10">
            <odx-form-field variant="full" [readonly]="mergeIncidentsForm.get('second_date').disabled">
              <odx-form-field-label>
                <odx-icon size="medium" name="document"></odx-icon>
                {{ 'MERGE_INCIDENTS.STR_INCIDENT_DATE' | translate }}
              </odx-form-field-label>

              <input
                type="text"
                ignisDisableAutocomplete
                id="second_date"
                data-test-id="merge_incidents.second_incident_date"
                formControlName="second_date"
                odxFormFieldControl
              />
            </odx-form-field>
          </div>

          <div class="col-md-5 mt-3">
            <odx-form-field
              variant="full"
              [readonly]="mergeIncidentsForm.get('second_number').disabled"
              [title]="mergeIncidentsForm.get('second_number').value"
            >
              <odx-form-field-label>
                <odx-icon size="medium" name="document"></odx-icon>
                {{ 'MERGE_INCIDENTS.STR_INCIDENT_NUMBER' | translate }}
              </odx-form-field-label>

              <input
                type="text"
                ignisDisableAutocomplete
                id="second_number"
                data-test-id="merge_incidents.second_incident_number"
                formControlName="second_number"
                odxFormFieldControl
              />
            </odx-form-field>
          </div>

          <div class="col-md-12 mt-3">
            <div class="row">
              <div class="col-md-5">
                <odx-form-field variant="full" [readonly]="mergeIncidentsForm.get('second_ecps_number').disabled">
                  <odx-form-field-label>
                    <span class="baselocation-icon"></span>
                    {{ 'MERGE_INCIDENTS.STR_ECPS_NUMBER' | translate }}
                  </odx-form-field-label>

                  <input
                    type="text"
                    ignisDisableAutocomplete
                    id="second_ecps_number"
                    data-test-id="merge_incidents.second_ecps_number"
                    formControlName="second_ecps_number"
                    odxFormFieldControl
                  />
                </odx-form-field>
              </div>

              <div class="col-md-5">
                <odx-form-field
                  variant="full"
                  [readonly]="mergeIncidentsForm.get('second_ecp_address').disabled"
                  [title]="mergeIncidentsForm.get('second_ecp_address').value"
                >
                  <odx-form-field-label>
                    <odx-icon name="tablet" size="mediun"></odx-icon>
                    {{ 'MERGE_INCIDENTS.STR_ECP_NAME' | translate }}
                  </odx-form-field-label>

                  <input
                    type="text"
                    ignisDisableAutocomplete
                    id="second_ecp_address"
                    data-test-id="merge_incidents.second_ecp_address"
                    formControlName="second_ecp_address"
                    odxFormFieldControl
                  />
                </odx-form-field>
              </div>
            </div>
          </div>

          <div class="col-md-12 mt-3">
            <odx-form-field
              variant="full"
              class="custom-input-width"
              [readonly]="mergeIncidentsForm.get('second_deployed_firefighters').disabled"
            >
              <odx-form-field-label>
                <odx-icon size="mediun" name="breathing-apparatus"></odx-icon>
                {{ 'MERGE_INCIDENTS.STR_DEPLOYED_FIREFIGHTERS' | translate }}
              </odx-form-field-label>

              <input
                type="text"
                ignisDisableAutocomplete
                id="second_deployed_firefighters"
                data-test-id="merge_incidents.second_deployed_firefighters"
                formControlName="second_deployed_firefighters"
                odxFormFieldControl
              />
            </odx-form-field>
          </div>
        </div>
      </div>
    </odx-modal-content>
    <odx-modal-footer>
      <button
        odxButton
        odxModalClose
        variant="secondary"
        (click)="closeMergeIncidentsModal()"
        data-test-id="modal_actions.cancel_btn"
      >
        {{ 'MERGE_INCIDENTS.STR_CANCEL_BTN' | translate }}
      </button>

      <button odxButton variant="primary" (click)="mergeIncidents()" data-test-id="modal_actions.save_btn">
        {{ 'MERGE_INCIDENTS.STR_SAVE_BTN' | translate }}
      </button>
    </odx-modal-footer>

    <ignis-confirm-merge-incidents
      class="d-none"
      [openModal]="openConfirmMergeIncidents"
      [selectedIncidents]="selectedIncidents"
      (handleCloseConfirmMergeIncidentModal)="closeConfirmMergeModal($event)"
    >
    </ignis-confirm-merge-incidents>
  </ng-template>
</form>
