import { GenericAction } from 'src/app/common';
import {
  errorPostPutItemData,
  receivePostPutItemData,
  requestPostPutItemData,
} from 'src/app/common/utils/reducer/reducer.utils';
import { IEditTask, INewTask, IUpdateTaskChildren } from '../../../models/equipment-configuration.models';
import { TasksActionTypes } from '../../actions/tasks';
import { ITaskState, TaskState } from '../../models/tasks.models';

export function tasksReducer(
  lastState: ITaskState = new TaskState(),
  action: GenericAction<TasksActionTypes, any>,
): ITaskState {
  switch (action.type) {
    case TasksActionTypes.REQUEST_ADD_TASK:
      return requestPostPutItemData<ITaskState, INewTask>(lastState, ['newTask'], action.payload);
    case TasksActionTypes.RECEIVE_ADD_TASK:
      return receivePostPutItemData<ITaskState, INewTask>(lastState, ['newTask'], action.payload);
    case TasksActionTypes.ERROR_RECEIVE_ADD_TASK:
      return errorPostPutItemData<ITaskState, INewTask>(lastState, ['newTask'], action.payload);

    case TasksActionTypes.REQUEST_EDIT_TASK:
      return requestPostPutItemData<ITaskState, IEditTask>(lastState, ['editedTask'], action.payload);
    case TasksActionTypes.RECEIVE_EDIT_TASK:
      return receivePostPutItemData<ITaskState, IEditTask>(lastState, ['editedTask'], action.payload);
    case TasksActionTypes.ERROR_RECEIVE_EDIT_TASK:
      return errorPostPutItemData<ITaskState, IEditTask>(lastState, ['editedTask'], action.payload);

    case TasksActionTypes.REQUEST_DELETE_TASK:
      return requestPostPutItemData<ITaskState, any>(lastState, ['deletedTask'], action.payload);
    case TasksActionTypes.RECEIVE_DELETE_TASK:
      return receivePostPutItemData<ITaskState, any>(lastState, ['deletedTask'], action.payload);
    case TasksActionTypes.ERROR_RECEIVE_DELETE_TASK:
      return errorPostPutItemData<ITaskState, any>(lastState, ['deletedTask'], action.payload);

    case TasksActionTypes.REQUEST_UPDATE_TASK_CHILDREN:
      return requestPostPutItemData<ITaskState, IUpdateTaskChildren>(lastState, ['updateTaskChildren'], action.payload);
    case TasksActionTypes.RECEIVE_UPDATE_TASK_CHILDREN:
      return receivePostPutItemData<ITaskState, IUpdateTaskChildren>(lastState, ['updateTaskChildren'], action.payload);
    case TasksActionTypes.ERROR_RECEIVE_UPDATE_TASK_CHILDREN:
      return errorPostPutItemData<ITaskState, IUpdateTaskChildren>(lastState, ['updateTaskChildren'], action.payload);

    default:
      return lastState;
  }
}
