import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ignis-alarm-icon',
  templateUrl: './alarm-icon.component.html',
  styleUrls: ['./alarm-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlarmIconComponent {
  @Input() alarmTypeValue: string;
  @Input() usage: string;
  @Input() currentAppTheme: string;
}
