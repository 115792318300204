import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RequestEquipmentLicenseInfo } from './equipment-license-info-action-types-creators';
import { IEquipmentState } from '../../models';

@Injectable({ providedIn: 'root' })
export class EquipmentLicenseInfoActions {
  constructor(private store: Store<IEquipmentState>) {}

  public requestEquipmentLicenseInfo(): void {
    this.store.dispatch(new RequestEquipmentLicenseInfo());
  }
}
