import { ITableColumn } from 'src/app/common';

export const tasksTableColumns: ITableColumn[] = [
  { field: 'taskName', header: 'CONFIGURATION.STR_NAME_TABLE_HEADER', visible: true },
  { field: 'description', header: 'CONFIGURATION.STR_DESCRIPTION_SERVICES_TABLE_HEADER', visible: true },
];

export const tasksIncludedColumns: ITableColumn[] = [
  { field: 'taskName', header: 'CONFIGURATION.STR_NAME_TABLE_HEADER', visible: true },
  { field: 'displayedDuration', header: 'CONFIGURATION.STR_INTERVAL_DURATION_TABLE_HEADER', visible: true },
];
