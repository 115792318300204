<div class="unauthorized-container">
  <div class="page-container" *ngIf="!isCreateAccountMode">
    <img src="/assets/img/logo.svg" alt="logo" />
    <img src="/assets/img/unauthorized.svg" alt="unauthorized-logo" class="unauthorized-logo" />

    <p class="no-permission" data-test-id="container.no_permission">{{ 'CONTAINER.STR_NO_PERMISSION' | translate }}</p>
    <ng-container *ngIf="!isLicenseExpired; else expiredLicense">
      <p class="no-permission-text">{{ 'CONTAINER.STR_NO_PERMISSION_TEXT' | translate }}</p>
      <p class="no-permission-text-second">{{ 'CONTAINER.STR_NO_PERMISSION_TEXT_SECOND' | translate }}</p>
      <div class="license-container">
        <p class="create_account">{{ 'CONTAINER.STR_WANT_TO_CREATE_ACCOUNT' | translate }}</p>
        <p class="license_link" (click)="isCreateAccountMode = true" data-test-id="container.enter_license_here">
          {{ 'CONTAINER.STR_ENTER_LICENSE_HERE' | translate }}
        </p>
        <p class="back_text">
          {{ 'CONTAINER.STR_GO_BACK_TO' | translate }}
          <span class="back_link" (click)="logout()" data-test-id="container.previous_page">
            {{ 'CONTAINER.STR_PREVIOUS_PAGE' | translate }}.
          </span>
        </p>
      </div>
    </ng-container>
    <ng-template #expiredLicense>
      <p class="no-permission-text">{{ 'CONTAINER.STR_UNAUTHORIZED_TEXT' | translate }}</p>
      <p class="no-permission-text-second">{{ 'CONTAINER.STR_UNAUTHORIZED_TEXT_SECOND' | translate }}</p>
      <div class="license-container">
        <p class="back_text">
          {{ 'CONTAINER.STR_GO_BACK_TO' | translate }}
          <span class="back_link" (click)="logout()" data-test-id="container.previous_page">
            {{ 'CONTAINER.STR_PREVIOUS_PAGE' | translate }}.
          </span>
        </p>
      </div>
    </ng-template>
  </div>
  <ignis-license-validation *ngIf="isCreateAccountMode"></ignis-license-validation>
</div>
