import { IStoreApiItem, StoreApiItem } from 'src/app/common';
import { IIncidentAnalysis } from '../../models/incident.model';

export interface IIncidentAnalysisState {
  incidentAnalysis: IStoreApiItem<IIncidentAnalysis>;
}

export class IncidentAnalysisState implements IIncidentAnalysisState {
  incidentAnalysis: IStoreApiItem<IIncidentAnalysis> = new StoreApiItem();
}
