import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { IAlarmLists } from 'src/app/root/models/alarms.model';
import { AlarmsActionType } from '../actions/alarms-action-types';
import { ErrorReceiveAlarms, ReceiveAlarms } from '../actions/alarms-action-types-creators';
import { AlarmsService } from './../../../services/alarms/alarms.service';

@Injectable()
export class AlarmsEffects {
  constructor(private alarmsService: AlarmsService) {}

  requestAlarms$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(AlarmsActionType.REQUEST_ALARMS),
      switchMap(() =>
        this.alarmsService.getAlarms().pipe(
          map((alarms: IAlarmLists) => new ReceiveAlarms(alarms)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAlarms(error))),
        ),
      ),
    );
  });
}
