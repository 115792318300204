import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { CommonConstants, FeatureModuleBuilderService } from 'src/app/common';
import { FeatureModuleRouteForNavigationEnum, FeatureModuleTileTitleEnum } from 'src/app/common/enums/feature-module';
import { IFeatureModule } from 'src/app/common/models';
import { LogbookConstants } from 'src/app/logbook/constants/logbook.constants';
import { AppUserRole } from '../../models/app-types';

@Component({
  selector: 'ignis-app-module-tiles',
  templateUrl: './app-module-tiles.component.html',
  styleUrls: ['./app-module-tiles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppModuleTilesComponent implements OnChanges, AfterViewInit {
  @Input() userRole: string;

  role: AppUserRole = AppUserRole.admin;
  tiles: IFeatureModule[];
  visibleTilesNo: number;
  processingPreviousRoute: boolean = false;

  router: Router = inject(Router);
  featureModuleBuilderService: FeatureModuleBuilderService = inject(FeatureModuleBuilderService);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  ngOnChanges(): void {
    this.defineTilesData();

    if (
      CommonConstants.routeWithoutAuthentication in localStorage ||
      CommonConstants.routeToNavigateAfterLogin in localStorage
    ) {
      this.processingPreviousRoute = true;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.redirectAfterLogin(), 1000);
  }

  defineTilesData(): void {
    this.tiles = this.featureModuleBuilderService.getFeatureModuleData(
      FeatureModuleTileTitleEnum,
      this.userRole,
      this.role,
    );

    this.visibleTilesNo = this.tiles.filter((tile: IFeatureModule) => tile.visibility).length;

    this.cdr.detectChanges();
  }

  goToModule(url: string): void {
    if ((url as FeatureModuleRouteForNavigationEnum) === FeatureModuleRouteForNavigationEnum.Logbook) {
      localStorage.setItem(LogbookConstants.preFilterKey, 'true');
    }

    localStorage.removeItem(CommonConstants.routeWithoutAuthentication);
    localStorage.removeItem(CommonConstants.routeToNavigateAfterLogin);

    this.router.navigate([url]);
  }

  redirectAfterLogin(): void {
    if (CommonConstants.routeWithoutAuthentication in localStorage) {
      this.handleAuthenticationRouting(CommonConstants.routeWithoutAuthentication);
    } else if (CommonConstants.routeToNavigateAfterLogin in localStorage) {
      this.handleAuthenticationRouting(CommonConstants.routeToNavigateAfterLogin);
    }

    this.processingPreviousRoute = false;
  }

  handleAuthenticationRouting(authType: string): void {
    const routeList: string[] = localStorage.getItem(authType).split('/');

    this.removeFirstElements(routeList, 3);

    this.router.navigate(routeList);
    localStorage.removeItem(authType);
  }

  removeFirstElements(arr: string[], count: number): void {
    arr.splice(0, count);
  }
}
