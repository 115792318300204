import { GenericAction, reducerUtils } from 'src/app/common';
import { IEquipmentInitialSpecification } from '../../../models/equipment-initial-specification.model';
import { EquipmentInitialSpecificationsActionTypes } from '../../actions/equipment-initial-specs';
import { EquipmentInitialSpecState, IEquipmentInitialSpecState } from '../../models/equipment.model';

const resetCheckingIdentifiersState = (lastState: IEquipmentInitialSpecState): IEquipmentInitialSpecState => ({
  ...lastState,
  equipmentIdentifiersAreUnique: {
    isLoading: false,
    data: null,
    errors: null,
    isSuccess: false,
  },
});

export function equipmentInitialSpecReducer(
  lastState: IEquipmentInitialSpecState = new EquipmentInitialSpecState(),
  action: GenericAction<EquipmentInitialSpecificationsActionTypes, any>,
): IEquipmentInitialSpecState {
  switch (action.type) {
    case EquipmentInitialSpecificationsActionTypes.REQUEST_INITIAL_SPECIFICATION:
      return reducerUtils.requestListData<IEquipmentInitialSpecState, IEquipmentInitialSpecification[]>(lastState, [
        'equipmentInitialSpecification',
      ]);
    case EquipmentInitialSpecificationsActionTypes.RECEIVE_INITIAL_SPECIFICATION:
      return reducerUtils.receiveListData<IEquipmentInitialSpecState, IEquipmentInitialSpecification[]>(
        lastState,
        ['equipmentInitialSpecification'],
        action.payload,
      );
    case EquipmentInitialSpecificationsActionTypes.ERROR_INITIAL_SPECIFICATION:
      return reducerUtils.errorListData<IEquipmentInitialSpecState, IEquipmentInitialSpecification[]>(
        lastState,
        ['equipmentInitialSpecification'],
        action.payload,
      );

    case EquipmentInitialSpecificationsActionTypes.REQUEST_CHECKING_BARCODE_OR_RFID_IS_UNIQUE:
      return reducerUtils.requestItemData<IEquipmentInitialSpecState, IEquipmentInitialSpecState>(lastState, [
        'equipmentIdentifiersAreUnique',
      ]);
    case EquipmentInitialSpecificationsActionTypes.RECEIVE_CHECKING_BARCODE_OR_RFID_IS_UNIQUE:
      return reducerUtils.receiveItemData<IEquipmentInitialSpecState, IEquipmentInitialSpecState>(
        lastState,
        ['equipmentIdentifiersAreUnique'],
        action.payload,
      );
    case EquipmentInitialSpecificationsActionTypes.ERROR_RECEIVE_CHECKING_BARCODE_OR_RFID_IS_UNIQUE:
      return reducerUtils.errorItemData<IEquipmentInitialSpecState, IEquipmentInitialSpecState>(
        lastState,
        ['equipmentIdentifiersAreUnique'],
        action.payload,
      );
    case EquipmentInitialSpecificationsActionTypes.RESET_CHECKING_BARCODE_OR_RFID_IS_UNIQUE:
      return resetCheckingIdentifiersState(lastState);

    default:
      return lastState;
  }
}
