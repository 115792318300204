<div id="device-connection-form-assignment-section" [formGroup]="deviceConnectionForm" odxLayout="grid">
  <div odxLayout="12 6@tablet 4@desktop" class="custom-form-field-pr-10">
    <div class="odx-form-field--horizontal odx-form-field">
      <label class="odx-form-field__label" for="assignedFireStation">
        <odx-form-field-label class="odx-form-field-label">
          {{ 'DEVICE_CONNECTION_FORM.STR_ASSIGNED_FIRE_LOCATION_FIELD' | translate }}
        </odx-form-field-label>
      </label>

      <div class="w-100 mb-15">
        <ng-select
          #assignedFireStationsDD
          ngDefaultControl
          formControlName="assignedFireStation"
          data-test-id="device_connection_form.assigned_fire_location_field"
          [items]="assignedFireStationsToShow"
          [clearable]="true"
          clearAllText=""
          [placeholder]="'DEVICE_CONNECTION_FORM.STR_ASSIGNED_FIRE_LOCATION_FIELD' | translate"
          [notFoundText]="'BA_WEARER.STR_FIRE_STATION_NO_ENTRIES' | translate"
          (input)="addAssignedFireStation($event)"
          (change)="onFireStationChange($event)"
          (click)="toggleAssignedFireStationDropdown('assignedFireStationsDD')"
          (blur)="fsDropdownAreOpened = []"
        >
        </ng-select>
      </div>
    </div>
  </div>

  <div odxLayout="12 6@tablet 4@desktop offset-1" class="custom-form-field-pr-10">
    <odx-form-field
      variant="horizontal"
      label="{{ 'DEVICE_CONNECTION_FORM.STR_ASSIGNED_VEHICLE' | translate }}"
      [readonly]="deviceConnectionForm.get('assignedVehicle').disabled"
    >
      <input
        type="text"
        ignisDisableAutocomplete
        id="assignedVehicle"
        data-test-id="device_connection_form.assigned_vehicle"
        formControlName="assignedVehicle"
        placeholder="{{ 'DEVICE_CONNECTION_FORM.STR_ASSIGNED_VEHICLE' | translate }}"
        odxFormFieldControl
      />
    </odx-form-field>
  </div>

  <div odxLayout="12 6@tablet 4@desktop offset-1" class="custom-form-field-pr-10">
    <odx-form-field
      variant="horizontal"
      label=" {{ 'DEVICE_CONNECTION_FORM.STR_PRIMARY_USER' | translate }}"
      [readonly]="deviceConnectionForm.get('assignedPrimaryUser').disabled"
    >
      <input
        type="text"
        ignisDisableAutocomplete
        id="assignedPrimaryUser"
        data-test-id="device_connection_form.primary_user"
        formControlName="assignedPrimaryUser"
        placeholder="{{ 'DEVICE_CONNECTION_FORM.STR_PRIMARY_USER' | translate }}"
        odxFormFieldControl
      />
    </odx-form-field>
  </div>
</div>
