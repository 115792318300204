<form odxForm [formGroup]="deviceConnectionForm" [readonly]="deviceConnectionForm.disabled">
  <section odxLayout="grid" class="mb-5">
    <h2 class="mb-3" odxLayout="12">{{ 'DEVICE_CONNECTION_FORM.STR_IDENTIFICATION_DATA' | translate }}</h2>

    @if (deviceConnectionForm.get('category').value === deviceCategories.RAM_CONNECTOR) {
      <a
        [href]="ramConnectorDownloadURL"
        target="_blank"
        class="download-link"
        id="download-ram-connector-link"
        data-test-id="device_connection_form.download_ram_connector"
      >
        {{ 'DEVICE_CONNECTION_FORM.STR_DOWNLOAD_RAM_CONNECTOR' | translate }}
      </a>
    }

    <div odxLayout="12 6@tablet 4@desktop" class="custom-form-field-pr-10">
      <odx-form-field
        variant="horizontal"
        label="{{ 'DEVICE_CONNECTION_FORM.STR_CATEGORY_FIELD' | translate }}"
        [readonly]="deviceConnectionForm.get('category').disabled"
      >
        <div class="w-100">
          <p-dropdown
            #dd3
            (onShow)="dropdownService.openDropdown(dd3)"
            (onHide)="dropdownService.closeDropdown(dd3)"
            optionValue="value"
            optionLabel="label"
            class="custom-dropdown-form"
            ngDefaultControl
            appendTo="body"
            odxFormFieldControl
            formControlName="category"
            data-test-id="device_connection_form.category_field"
            [dropdownIcon]="dropdownIconCSSClass"
            [options]="categories"
            [placeholder]="'DEVICE_CONNECTION_FORM.STR_CATEGORY_FIELD_PLACEHOLDER' | translate"
            [showClear]="true"
            (onChange)="categoryChanged($event.value)"
          >
            <ng-template let-option pTemplate="selectedItem">
              @if (option?.icon) {
                <odx-icon [name]="option?.icon" size="inline" class="dropdown-icon-option"></odx-icon>
              }
              <span [id]="option?.value">{{ option?.label }}</span>
            </ng-template>

            <ng-template let-option pTemplate="item">
              @if (option?.icon) {
                <odx-icon [name]="option?.icon" size="inline" class="dropdown-icon-option"></odx-icon>
              }
              <span [id]="option?.value">{{ option?.label }}</span>
            </ng-template>

            <ng-template pTemplate="clearicon">
              <odx-icon name="close"></odx-icon>
            </ng-template>
          </p-dropdown>
        </div>
      </odx-form-field>
    </div>

    <div odxLayout="12 6@tablet 4@desktop" class="custom-form-field-pl-10">
      <odx-form-field
        variant="horizontal"
        label="{{ 'DEVICE_CONNECTION_FORM.STR_NAME_FIELD' | translate }}"
        [readonly]="deviceConnectionForm.get('name').disabled"
      >
        <input
          type="text"
          ignisDisableAutocomplete
          id="name"
          data-test-id="device_connection_form.name_field"
          odxFormFieldControl
          formControlName="name"
          placeholder="{{ 'DEVICE_CONNECTION_FORM.STR_NAME_FIELD' | translate }}"
          maxlength="50"
        />

        <odx-icon
          name="info"
          class="custom-validation-icon"
          iconSet="core"
          data-test-id="device_connection_form.name_wrap"
          [pTooltip]="nameLengthTooltipContent"
          tooltipPosition="top"
          positionLeft="-2"
          positionTop="-10"
        >
          <ng-template #nameLengthTooltipContent>
            <div data-test-id="device_connection_form.name_tooltip">
              {{ 'DEVICE_CONNECTION_FORM.STR_NAME_LENGTH_TOOLTIP' | translate }}
            </div>
          </ng-template>
        </odx-icon>
      </odx-form-field>
    </div>

    @if (this.deviceConnectionForm.get('category').value !== deviceCategories.RAM_CONNECTOR) {
      <div odxLayout="12 6@tablet 4@desktop offset-1" class="custom-form-field-pr-10">
        <odx-form-field
          variant="horizontal"
          label="{{ 'DEVICE_CONNECTION_FORM.STR_IDENTIFIER_FIELD' | translate }}"
          [readonly]="deviceConnectionForm.get('identification').disabled"
        >
          <input
            type="text"
            ignisDisableAutocomplete
            id="identification"
            data-test-id="device_connection_form.identifier_field"
            odxFormFieldControl
            formControlName="identification"
            [pattern]="identifierRegEx"
            [placeholder]="
              deviceConnectionForm.get('category').value === deviceCategories.WORKSHOP
                ? ('DEVICE_CONNECTION_FORM.STR_IDENTIFIER_ID_PLACEHOLDER' | translate)
                : ('DEVICE_CONNECTION_FORM.STR_IDENTIFIER_ECP_PLACEHOLDER' | translate)
            "
            (blur)="errors = null; isSubmitted = false"
          />

          @if (deviceConnectionForm.dirty && deviceConnectionForm.get('identification').invalid && !errors) {
            <odx-icon
              name="info"
              class="custom-validation-icon"
              iconSet="core"
              data-test-id="device_connection_form.identifier_field_wrap"
              [pTooltip]="invalidIdentificationTooltipContent"
              tooltipPosition="top"
              positionTop="-10"
            >
              <ng-template #invalidIdentificationTooltipContent>
                <div data-test-id="device_connection_form.identifier_field_tooltip">
                  {{ 'DEVICE_CONNECTION_NOTIFICATION.STR_INVALID_IDENTIFIER' | translate }}
                </div>
              </ng-template>
            </odx-icon>
          }

          @if (deviceConnectionForm.dirty && deviceConnectionForm.get('identification').invalid && errors) {
            <odx-icon
              name="info"
              class="custom-validation-icon"
              iconSet="core"
              [odxTooltip]="uniqIdentificationTooltipContent"
              [odxTooltipVisible]="
                deviceConnectionForm.dirty && deviceConnectionForm.get('identification').invalid && errors
              "
            >
              <ng-template #uniqIdentificationTooltipContent>
                <div id="identifier-error-tooltip" data-test-id="device_connection_form.identifier_field_error_tooltip">
                  {{ 'COMMON_NOTIFICATION.STR_IDENTIFIER_SHOULD_BE_UNIQUE' | translate }}
                </div>
              </ng-template>
            </odx-icon>
          }
        </odx-form-field>
      </div>
    }

    @if ([deviceCategories.APP, deviceCategories.HUB].includes(this.deviceConnectionForm.get('category').value)) {
      <div odxLayout="12 6@tablet 4@desktop" class="custom-form-field-pl-10">
        <odx-form-field
          variant="horizontal"
          label="{{ 'DEVICE_CONNECTION_FORM.STR_SOFTWARE_VERSION_FIELD' | translate }}"
          [readonly]="deviceConnectionForm.get('firmwareVersion').disabled"
        >
          <input
            type="text"
            ignisDisableAutocomplete
            id="firmwareVersion"
            data-test-id="device_connection_form.software_version_field"
            odxFormFieldControl
            formControlName="firmwareVersion"
          />
        </odx-form-field>
      </div>
    }

    <div odxLayout="12" class="divider"></div>

    <h2 class="mb-3" odxLayout="12">{{ 'DEVICE_CONNECTION_FORM.STR_INFORMATION_DATA' | translate }}</h2>

    @if ([deviceCategories.APP, deviceCategories.HUB].includes(this.deviceConnectionForm.get('category').value)) {
      <ignis-device-connection-form-assignment
        odxLayout="12"
        [deviceConnectionForm]="deviceConnectionForm"
        [fireStations]="fireStations"
      >
      </ignis-device-connection-form-assignment>
    }

    @if (this.deviceConnectionForm.get('category').value === deviceCategories.API_KEY) {
      <div odxLayout="12 6@tablet 4@desktop" class="custom-form-field-pr-10">
        <odx-form-field
          variant="horizontal"
          label="{{ 'DEVICE_CONNECTION_FORM.STR_API_KEY_ROLE' | translate }}"
          [readonly]="deviceConnectionForm.get('role').disabled"
        >
          <div class="w-100">
            <p-dropdown
              #dd1
              (onShow)="dropdownService.openDropdown(dd1)"
              (onHide)="dropdownService.closeDropdown(dd1)"
              appendTo="body"
              class="custom-dropdown-form"
              ngDefaultControl
              odxFormFieldControl
              formControlName="role"
              data-test-id="device_connection_form.api_key_role"
              [dropdownIcon]="dropdownIconCSSClass"
              [options]="apiKeyRoles"
              [showClear]="false"
            >
              <ng-template let-option pTemplate="item">
                <span>{{ option?.label }}</span>
              </ng-template>
              <ng-template pTemplate="clearicon">
                <odx-icon name="close"></odx-icon>
              </ng-template>
            </p-dropdown>
          </div>
        </odx-form-field>
      </div>
    }

    <div odxLayout="12 6@tablet 4@desktop offset-1" class="custom-form-field-pr-10">
      <odx-form-field
        variant="horizontal"
        label="{{ 'DEVICE_CONNECTION_FORM.STR_DESCRIPTION_FIELD' | translate }}"
        [readonly]="deviceConnectionForm.get('description').disabled"
      >
        <textarea
          ignisDisableAutocomplete
          id="description"
          odxFormFieldControl
          class="custom-form-textarea"
          rows="7"
          formControlName="description"
          data-test-id="device_connection_form.description"
        ></textarea>
      </odx-form-field>
    </div>

    <div odxLayout="12" class="divider"></div>

    <h2 class="mb-3" odxLayout="12">{{ 'DEVICE_CONNECTION_FORM.STR_REGISTRATION' | translate }}</h2>

    <div odxLayout="12 6@tablet 4@desktop offset-1" class="custom-form-field-pr-10">
      <odx-form-field
        variant="horizontal"
        label="{{ 'DEVICE_CONNECTION_FORM.STR_AUTHENTICATION_TOKEN_FIELD' | translate }}"
        [readonly]="deviceConnectionForm.get('authenticationToken').disabled"
      >
        <input
          type="text"
          ignisDisableAutocomplete
          id="authenticationToken"
          data-test-id="device_connection_form.authentication_token_field"
          formControlName="authenticationToken"
          placeholder="{{ 'DEVICE_CONNECTION_FORM.STR_AUTHENTICATION_TOKEN_FIELD_PLACEHOLDER' | translate }}"
          odxFormFieldControl
        />
      </odx-form-field>
    </div>

    <div odxLayout="12 6@tablet 4@desktop" class="custom-form-field-pl-10 mt-1">
      @if (deviceConnectionForm?.controls?.authenticationToken?.value) {
        @if (showCopiedTooltip) {
          <button
            odxButton
            variant="secondary"
            class="copy-button"
            type="button"
            data-test-id="device_connection_form.copy_to_clipboard_btn"
            (click)="copyToClipboard(deviceConnectionForm.controls.authenticationToken.value)"
            [pTooltip]="'DEVICE_CONNECTION_FORM.STR_COPIED_TOOLTIP' | translate"
            tooltipPosition="top"
            positionTop="-10"
          >
            <odx-icon name="copy-content" iconSet="core"></odx-icon>
          </button>
        } @else {
          <button
            odxButton
            variant="secondary"
            class="copy-button"
            type="button"
            data-test-id="device_connection_form.copied_to_clipboard_btn"
            (click)="copyToClipboard(deviceConnectionForm.controls.authenticationToken.value)"
            [pTooltip]="'DEVICE_CONNECTION_FORM.STR_COPY_TO_CLIPBOARD_TOOLTIP' | translate"
            tooltipPosition="top"
            positionTop="-10"
          >
            <odx-icon name="copy-content" iconSet="core"></odx-icon>
          </button>
        }
      }
      <button
        odxButton
        variant="primary"
        type="button"
        (click)="generateDeviceConnectionToken()"
        [disabled]="modalType === 'create'"
        data-test-id="device_connection_form.create_new_token"
      >
        {{ 'DEVICE_CONNECTION_FORM.STR_CREATE_TOKEN' | translate }}
      </button>
    </div>

    @if (deviceConnectionForm.get('category').value === deviceCategories.APP) {
      <div odxLayout="12 6@tablet 4@desktop offset-1">
        <div class="odx-form-field--horizontal odx-form-field">
          <label class="odx-form-field__label" for="assignedFireStation">
            <odx-form-field-label class="odx-form-field-label">
              {{ 'DEVICE_CONNECTION_FORM.STR_QR_CODE' | translate }}
            </odx-form-field-label>
          </label>

          <div class="w-100">
            <div
              class="qr-button-wrapper"
              [pTooltip]="
                isQRCodeDeactivated && deviceConnectionForm.get('category').value === deviceCategories.APP
                  ? qrButtonTooltipContent
                  : null
              "
              tooltipPosition="top"
              positionTop="0"
              data-test-id="device_connection_form.show_qr_code_tooltip_wrap"
            >
              <button
                odxButton
                [disabled]="isQRCodeDeactivated"
                (click)="viewQRCode()"
                data-test-id="device_connection_form.show_qr"
              >
                {{ 'DEVICE_CONNECTION_FORM.STR_SHOW_QR' | translate }}
              </button>

              <ng-template #qrButtonTooltipContent>
                <div data-test-id="device_connection_form.str_qr_code_tooltip">
                  {{ 'DEVICE_CONNECTION_FORM.STR_QR_CODE_TOOLTIP' | translate }}
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    }
  </section>
</form>

<ignis-qr-code-modal
  [isOpen]="openQRCodeModal"
  [formValue]="deviceConnectionForm.getRawValue()"
  (closeConfirmModal)="closeViewQRCodeModal($event)"
>
</ignis-qr-code-modal>
