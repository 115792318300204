import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable, Subject } from 'rxjs';
import { CommonConstants } from '../../constants/common.constants';
import { IModalState } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class CheckingSettingsModalsService {
  constructor(
    private storage: StorageMap,
    private router: Router,
  ) {}

  public checkIfSettingsModalIsOpen(): Observable<boolean> {
    const isModalOpened: Subject<boolean> = new Subject<boolean>();

    this.storage.watch(CommonConstants.modalIsOpened).subscribe((modalIsOpened: any) => {
      this.isModalOpened(modalIsOpened?.open, isModalOpened);
    });

    return isModalOpened.asObservable();
  }

  isModalOpened(open: boolean, subject: Subject<boolean>): void | any {
    return subject.next(open);
  }

  remainOnTheSamePageIfTheModalIsOpened(
    isOpen: boolean,
    routeAfterBrowserBackBtnIsPressed: string,
    btnId?: string,
  ): void {
    const modalIsOpened: IModalState = JSON.parse(localStorage.getItem(CommonConstants.modalIsOpened));

    if ((isOpen || modalIsOpened?.open) && routeAfterBrowserBackBtnIsPressed) {
      const timeOutMsValue: number = btnId ? 250 : 0;

      document.getElementById(btnId ?? 'close-modal')?.click();

      setTimeout(() => {
        this.router.navigate(routeAfterBrowserBackBtnIsPressed.split('/'));
      }, timeOutMsValue);
    }
  }
}
