<ng-template odxModal #createUpdateEventMarkerModal let-modal="close">
  @if (!isLoading) {
    <form [formGroup]="eventMarkerForm" (ngSubmit)="onSubmit()">
      <ignis-event-marker-form
        (closeModal)="closeModal($event)"
        [selectedEventMarker]="selectedEventMarker"
        [formatDate]="formatDate"
        [isLoading]="isLoading"
        [isSubmitted]="isSubmitted"
        [eventMarkerForm]="eventMarkerForm"
        [errors]="errors"
        [modalType]="modalType"
        [currentIncident]="currentIncident"
        [entries]="entries | async"
      ></ignis-event-marker-form>
    </form>
  } @else {
    <div [odxLoadingSpinner]="isLoading" [odxLoadingSpinnerMinHeight]="100" class="w-100"></div>
  }

  <odx-modal-footer>
    <button
      *ngIf="eventMarkerForm.dirty; else closeButton"
      data-test-id="create_event_marker.cancel_btn"
      odxButton
      (click)="confirmCloseModalOpen()"
      variant="secondary"
    >
      {{ 'CONFIRM_CLOSE_MODAL.STR_CANCEL_BTN' | translate }}
    </button>
    <ng-template #closeButton>
      <button odxButton (click)="closeBanner(true)" variant="secondary" data-test-id="create_event_marker.cancel_btn">
        {{ 'CONFIRM_CLOSE_MODAL.STR_CANCEL_BTN' | translate }}
      </button>
    </ng-template>

    <button
      data-test-id="create_event_marker.save_btn"
      odxButton
      variant="primary"
      (click)="onSubmit()"
      [disabled]="!eventMarkerForm.dirty || (isSubmitting | async)"
    >
      {{ 'CONFIRM_CLOSE_MODAL.STR_SAVE_BTN' | translate }}
    </button>
  </odx-modal-footer>

  <ignis-confirmation-modal
    class="discard-changes-banner"
    [isOpen]="isConfirmCloseModalOpen"
    (handleConfirmationModal)="closeBanner($event)"
  >
    <div slot="content">
      {{ 'CONFIRM_CLOSE_MODAL.STR_DESCRIPTION_EVENT_MARKER' | translate }}
    </div>
  </ignis-confirmation-modal>
</ng-template>
