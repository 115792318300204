import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard } from '@okta/okta-angular';
import { IncidentOverviewComponent } from '../components/incident-overview/incident-overview.component';
import { RemoteEcpMonitoringComponent } from '../components/incident-overview/remote-ecp-monitoring/remote-ecp-monitoring.component';
import { FilterIncidentsComponent } from '../components/remote-monitoring-grid/filter-incidents/filter-incidents.component';
import { RemoteMonitoringGridComponent } from '../components/remote-monitoring-grid/remote-monitoring-grid.component';
import { RemoteMonitoringModuleRoutes } from '../constants/remote-monitoring-module-routes.constants';
import { MapComponent } from './../../common/components/map/map.component';
import { RemoteMonitoringContainerComponent } from './../components/remote-monitoring-container/remote-monitoring-container.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [OktaAuthGuard],
    canActivateChild: [OktaAuthGuard],
    component: RemoteMonitoringContainerComponent,
    children: [
      {
        path: '',
        component: RemoteMonitoringGridComponent,
        children: [{ path: RemoteMonitoringModuleRoutes.filter, component: FilterIncidentsComponent }],
      },
      {
        path: `${RemoteMonitoringModuleRoutes.incidentOverview}/:id`,
        component: IncidentOverviewComponent,
        children: [
          {
            path: `${RemoteMonitoringModuleRoutes.ecp}/:ecpId/${RemoteMonitoringModuleRoutes.mapView}`,
            component: MapComponent,
          },
          {
            path: `${RemoteMonitoringModuleRoutes.ecp}/:ecpId/${RemoteMonitoringModuleRoutes.monitorTeams}`,
            component: RemoteEcpMonitoringComponent,
          },
        ],
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],

  exports: [RouterModule],
})
export class RemoteMonitoringRoutingModule {}
