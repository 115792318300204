<ng-template odxModal #completeChecklistModal let-modal="close">
  <odx-modal-header data-test-id="complete_checklist.complete_checklist_modal">
    <odx-area-header>
      {{ 'COMPLETE_CHECKLIST.STR_TITLE' | translate }}
    </odx-area-header>
  </odx-modal-header>

  <odx-modal-content odxLayout="grid" [odxLoadingSpinner]="isLoading" class="complete-checklist-overflow">
    <form [formGroup]="completeChecklistForm" (ngSubmit)="onSubmit()" odxForm>
      <div class="odx-form-field odx-form-field--full mb-3">
        <label class="odx-form-field__label" for="dd1">
          <odx-form-field-label class="odx-form-field-label">
            {{ 'COMPLETE_CHECKLIST.STR_SELECT_SERVICE_LABEL' | translate }}
          </odx-form-field-label>
        </label>

        <div class="col-6">
          <p-dropdown
            #dd1
            ngDefaultControl
            optionLabel="taskName"
            formControlName="taskData"
            data-test-id="complete_checklist.select_task_label"
            [dropdownIcon]="dropdownIconCSSClass"
            [options]="serviceIntervals"
            [showClear]="false"
            (onShow)="dropdownService.openDropdown(dd1)"
            (onHide)="dropdownService.closeDropdown(dd1)"
            (onChange)="changeTasks($event)"
          >
          </p-dropdown>
        </div>
      </div>
      <odx-form-field
        variant="full"
        label="{{ 'COMPLETE_CHECKLIST.STR_CHECKLIST_NAME_LABEL' | translate }}"
        [readonly]="true"
      >
        <input
          type="text"
          id="checklistName"
          data-test-id="complete_checklist.checklist_name"
          formControlName="checklistName"
          ngDefaultControl
          odxFormFieldControl
        />
      </odx-form-field>

      <odx-form-field
        variant="full"
        label="{{ 'COMPLETE_CHECKLIST.STR_DESCRIPTION_LABEL' | translate }}"
        [readonly]="true"
      >
        <textarea
          class="form-control text-area"
          id="checklistDescription"
          data-test-id="complete_checklist.description"
          formControlName="checklistDescription"
          odxFormFieldControl
          rows="3"
        ></textarea>
      </odx-form-field>

      <div class="mb-3">
        <hr class="items-divider" />
      </div>

      <div *ngFor="let item of checklistItems" class="checklist-items complete-checklist">
        <ng-container [ngSwitch]="item.type">
          <ng-container *ngSwitchCase="checklistParameterTypes.TEXT_TYPE">
            <ignis-checklist-text-type [form]="completeChecklistForm" [item]="item"></ignis-checklist-text-type>
          </ng-container>
          <ng-container *ngSwitchCase="checklistParameterTypes.BOOLEAN_TYPE">
            <ignis-checklist-boolean-type
              [form]="completeChecklistForm"
              [item]="item"
              data-test-id="single_checklist.boolean_type"
            ></ignis-checklist-boolean-type>
          </ng-container>
          <ng-container *ngSwitchCase="checklistParameterTypes.MULTIPLE_SELECTION_TYPE">
            <ignis-checklist-multiple-selection
              [appendTo]="completeChecklist"
              [form]="completeChecklistForm"
              [item]="item"
              data-test-id="single_checklist.multiple_selection_type"
            ></ignis-checklist-multiple-selection>
          </ng-container>
          <ng-container *ngSwitchCase="checklistParameterTypes.NUMERIC_TYPE">
            <ignis-checklist-numeric-type
              [form]="completeChecklistForm"
              [item]="item"
              data-test-id="single_checklist.numeric_type"
            ></ignis-checklist-numeric-type>
          </ng-container>
          <ng-container *ngSwitchCase="checklistParameterTypes.IMAGE_TYPE">
            <ignis-checklist-image-type
              [form]="completeChecklistForm"
              [item]="item"
              [deleteImages]="deleteImagesSubject"
              [sasUrl]="sasUrl"
              (areFilesUploaded)="areFilesUploaded = $event"
              data-test-id="single_checklist.image_type"
            ></ignis-checklist-image-type>
          </ng-container>
        </ng-container>

        <div class="mt-1" *ngIf="checklistItems.length > 0">
          <hr class="items-divider" />
        </div>
      </div>
      <div odxLayout="grid">
        <div odxLayout="12 12@tablet 6@desktop">
          <div class="mb-3 barcode" *ngIf="barcodeUpdateInTaskFeatureToggle" id="barcode-column">
            <odx-form-field variant="full" label="{{ 'COMPLETE_CHECKLIST.STR_CHANGE_BARCODE' | translate }}">
              <input
                type="text"
                ignisDisableAutocomplete
                id="barcode"
                formControlName="barcode"
                data-test-id="complete_checklist.change_barcode"
                ngDefaultControl
                odxFormFieldControl
              />
              <odx-icon
                *ngIf="completeChecklistForm.get('barcode').invalid && loadedEquipment"
                name="info"
                iconSet="core"
                class="custom-validation-icon"
                [odxTooltip]="barcodeTooltipContent"
                [odxTooltipVisible]="completeChecklistForm.get('barcode').invalid && loadedEquipment"
              >
                <ng-template #barcodeTooltipContent>
                  <div id="barcode-error-tooltip" data-test-id="complete_checklist.change_barcode_error_tooltip">
                    {{ 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_BARCODE_ALREADY_EXISTS' | translate }}
                  </div>
                </ng-template>
              </odx-icon>
            </odx-form-field>
          </div>
          <div class="mb-3 rfid" *ngIf="barcodeUpdateInTaskFeatureToggle" id="rfid-column">
            <odx-form-field variant="full" label="{{ 'COMPLETE_CHECKLIST.STR_CHANGE_RFID' | translate }}">
              <input
                type="text"
                ignisDisableAutocomplete
                id="rfid"
                formControlName="rfid"
                data-test-id="complete_checklist.change_rfid"
                ngDefaultControl
                odxFormFieldControl
              />
              <odx-icon
                name="info"
                iconSet="core"
                class="custom-validation-icon"
                *ngIf="completeChecklistForm.get('rfid').invalid && loadedEquipment"
                [odxTooltip]="rfidTooltipContent"
                [odxTooltipVisible]="completeChecklistForm.get('rfid').invalid && loadedEquipment"
              >
                <ng-template #rfidTooltipContent>
                  <div id="rfid-error-tooltip" data-test-id="complete_checklist.change_rfid_error_tooltip">
                    {{ 'EQUIPMENT_NOTIFICATION.STR_EQUIPMENT_RFID_ALREADY_EXISTS' | translate }}
                  </div>
                </ng-template>
              </odx-icon>
            </odx-form-field>
          </div>
        </div>

        <div odxLayout="12 12@tablet 6@desktop">
          <div
            class="odx-form-field mb-3 complete-checklist operational-status"
            [ngClass]="{ 'is-readonly': completeChecklistForm.get('operationalStatus').disabled }"
          >
            <label class="odx-form-field__label" for="dd3">
              <odx-form-field-label class="odx-form-field-label">
                {{ 'COMPLETE_CHECKLIST.STR_OPERATIONAL_STATUS' | translate }}
              </odx-form-field-label>
            </label>

            <div class="w-100">
              <p-dropdown
                #dd3
                ngDefaultControl
                [ngClass]="{ 'custom-is-readonly': completeChecklistForm.get('operationalStatus').disabled }"
                optionLabel="label"
                optionValue="value"
                formControlName="operationalStatus"
                data-test-id="complete_checklist.operational_status"
                [dropdownIcon]="dropdownIconCSSClass"
                [options]="operationalStatusesList"
                [showClear]="false"
                (onShow)="dropdownService.openDropdown(dd3)"
                (onHide)="dropdownService.closeDropdown(dd3)"
              >
                <ng-template let-option pTemplate="item">
                  <span>{{ option.label }}</span>
                </ng-template>
              </p-dropdown>
            </div>
            <odx-form-field-info class="odx-form-field__info"></odx-form-field-info>
          </div>

          <div class="odx-form-field custom-datepicker-form-field mb-3">
            <label class="odx-form-field__label" for="dataPicker">
              <odx-form-field-label class="odx-form-field-label">
                {{ 'COMPLETE_CHECKLIST.STR_OUT_OF_SERVICE_DATE' | translate }}
              </odx-form-field-label>
            </label>

            <div class="odx-form-field__inner">
              <div class="odx-form-field__control complete-checklist-overflow">
                <div class="w-100">
                  <ignis-datepicker
                    #dataPicker
                    *ngIf="toggleOutOfServiceDatepickerControl"
                    formControlName="outOfOrderDate"
                    data-test-id="complete_checklist.out_of_service_date"
                    class="complete-checklist-datepicker"
                    ngDefaultControl
                    [isDisabled]="completeChecklistForm.get('outOfOrderDate').disabled"
                    [selectionMode]="'single'"
                    [showTime]="false"
                    [formatDate]="formatDate"
                    [defaultRange]="extractOnlyDate(completeChecklistForm.get('outOfOrderDate').value)"
                    [clearable]="true"
                    (dateSelect)="
                      datepickerService.onDateChange($event, completeChecklistForm, 'outOfOrderDate');
                      outOfServiceService.changeOutOfServiceDate(
                        $event,
                        completeChecklistForm,
                        'operationalStatus',
                        'outOfServiceReason'
                      )
                    "
                    (dateChange)="
                      datepickerService.resetSelectedDate(completeChecklistForm, 'outOfOrderDate');
                      outOfServiceService.changeOutOfServiceDate(
                        $event,
                        completeChecklistForm,
                        'operationalStatus',
                        'outOfServiceReason'
                      )
                    "
                  >
                  </ignis-datepicker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <odx-form-field
        variant="full"
        label="{{ 'COMPLETE_CHECKLIST.STR_OUT_OF_SERVICE_REASON' | translate }}"
        [readonly]="completeChecklistForm.get('outOfServiceReason').disabled"
      >
        <textarea
          ignisDisableAutocomplete
          class="custom-form-textarea"
          id="outOfServiceReason"
          data-test-id="complete_checklist.out_of_service_reason"
          formControlName="outOfServiceReason"
          rows="3"
          odxFormFieldControl
        ></textarea>
      </odx-form-field>
    </form>

    <div class="mt-1">
      <hr class="items-divider" />
    </div>

    <ignis-services-tables
      [selectedTaskIntervals]="selectedTaskIntervals"
      [otherTaskIntervals]="otherTaskIntervals"
      [form]="completeChecklistForm"
      [formatDate]="formatDate"
      (handleOtherTaskIntervals)="getOtherTaskIntervals($event)"
    ></ignis-services-tables>
  </odx-modal-content>
  <odx-modal-footer>
    <button odxButton (click)="closeModal()" variant="secondary" data-test-id="modal_actions.cancel_btn">
      {{ 'COMPLETE_CHECKLIST.STR_CANCEL_BTN' | translate }}
    </button>
    <button
      odxButton
      variant="primary"
      type="submit"
      [disabled]="completeChecklistForm.invalid || uploadInProgress || (isSubmitting | async)"
      (click)="onSubmit()"
      data-test-id="modal_actions.save_btn"
    >
      {{ 'COMPLETE_CHECKLIST.STR_SAVE_BTN' | translate }}
    </button>
  </odx-modal-footer>
</ng-template>

<ignis-confirmation-modal [isOpen]="isConfirmCloseModalOpen" (handleConfirmationModal)="navigateBack($event)">
  <div slot="content">
    {{ 'COMPLETE_CHECKLIST.CONFIRM_CLOSE_MODAL' | translate }}
  </div>
</ignis-confirmation-modal>

<ignis-confirmation-modal [isOpen]="openConfirmChangeTasks" (handleConfirmationModal)="closeConfirmBanner($event)">
  <div slot="content">
    {{ 'COMPLETE_CHECKLIST.CONFIRM_CLOSE_MODAL' | translate }}
  </div>
</ignis-confirmation-modal>
