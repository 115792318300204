import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { CheckingSettingsModalsService, GenericFooterItemsComponent } from 'src/app/common';

@Component({
  selector: 'ignis-create-update-ba-wearer-view-footer',
  templateUrl: './create-update-ba-wearer-view-footer.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CreateUpdateBaWearerViewFooterComponent<T> extends GenericFooterItemsComponent<T> {
  @Input() isSubmitted: boolean;
  @Input() isModalOpened: boolean;
  @Input() isSubmitDisabled: boolean;

  showSaveBtnInvalidFormTooltip: boolean = false;

  constructor(
    public checkSettingsModal: CheckingSettingsModalsService,
    public cdr: ChangeDetectorRef,
  ) {
    super();

    this.checkSettingsModal.checkIfSettingsModalIsOpen().subscribe((isModalOpened: boolean) => {
      this.isModalOpened = isModalOpened;
      this.cdr.detectChanges();
    });
  }
}
