<form [formGroup]="mergeIncidentsForm">
  <ng-template odxModal #mergeRemoteIncidentsModal>
    <odx-modal-header data-test-id="merge_remote_incidents.merge_modal">
      <odx-area-header>
        {{ 'MERGE_REMOTE_INCIDENTS.STR_TITLE' | translate }}
      </odx-area-header>
    </odx-modal-header>
    <odx-modal-content class="custom-container">
      <p class="mb-3">
        {{ 'MERGE_REMOTE_INCIDENTS.STR_DESCRIPTION' | translate }}
      </p>

      <div class="row">
        <div class="col-6 mb-3">
          <h5>
            {{ 'MERGE_REMOTE_INCIDENTS.STR_INCIDENT_ONE' | translate
            }}<span
              *ngIf="
                selectedIncidentsData[0]?.location.town && selectedIncidentsData[0]?.location.streetInfo;
                else displayIncidentNumber1
              "
              >: "{{ selectedIncidentsData[0]?.location.town }},
              {{ selectedIncidentsData[0]?.location.streetInfo }}"</span
            >

            <ng-template #displayIncidentNumber1>
              <span *ngIf="selectedIncidentsData[0]?.incidentId">: "{{ selectedIncidentsData[0]?.incidentId }}"</span>
            </ng-template>
          </h5>
        </div>

        <div class="col-6 mb-3">
          <h5>
            {{ 'MERGE_REMOTE_INCIDENTS.STR_INCIDENT_TWO' | translate }}
            <span
              *ngIf="
                selectedIncidentsData[1]?.location.town && selectedIncidentsData[1]?.location.streetInfo;
                else displayIncidentNumber2
              "
              >: "{{ selectedIncidentsData[1]?.location.town }},
              {{ selectedIncidentsData[1]?.location.streetInfo }}"</span
            >

            <ng-template #displayIncidentNumber2>
              <span *ngIf="selectedIncidentsData[1]?.incidentId">: "{{ selectedIncidentsData[1]?.incidentId }}"</span>
            </ng-template>
          </h5>
        </div>

        <div class="col-6">
          <div class="col-md-10">
            <odx-form-field variant="full" [readonly]="mergeIncidentsForm.get('first_date').disabled">
              <odx-form-field-label>
                <odx-icon size="medium" name="document"></odx-icon>
                {{ 'MERGE_REMOTE_INCIDENTS.STR_INCIDENT_START_TIME' | translate }}
              </odx-form-field-label>
              <input
                type="text"
                ignisDisableAutocomplete
                id="first_date"
                data-test-id="merge_remote_incidents.first_incident_date"
                formControlName="first_date"
                odxFormFieldControl
              />
            </odx-form-field>
          </div>

          <div class="col-md-5 mt-3">
            <odx-form-field
              variant="full"
              [readonly]="mergeIncidentsForm.get('first_number').disabled"
              [title]="mergeIncidentsForm.get('first_number').value"
            >
              <odx-form-field-label>
                <odx-icon size="medium" name="document"></odx-icon>
                {{ 'MERGE_REMOTE_INCIDENTS.STR_INCIDENT_NUMBER' | translate }}
              </odx-form-field-label>

              <input
                type="text"
                ignisDisableAutocomplete
                id="first_number"
                data-test-id="merge_remote_incidents.first_incident_number"
                formControlName="first_number"
                odxFormFieldControl
              />
            </odx-form-field>
          </div>

          <div class="col-md-12 mt-3">
            <div class="row">
              <div class="col-md-5">
                <odx-form-field variant="full" [readonly]="mergeIncidentsForm.get('first_ecps_number').disabled">
                  <odx-form-field-label>
                    <odx-icon class="baselocation-icon" [class]="currentAppTheme"></odx-icon>
                    {{ 'MERGE_REMOTE_INCIDENTS.STR_ECPS_NUMBER' | translate }}
                  </odx-form-field-label>

                  <input
                    type="text"
                    ignisDisableAutocomplete
                    id="first_ecps_number"
                    data-test-id="merge_remote_incidents.first_ecps_number"
                    formControlName="first_ecps_number"
                    odxFormFieldControl
                  />
                </odx-form-field>
              </div>

              <div class="col-md-5">
                <odx-form-field
                  variant="full"
                  [readonly]="mergeIncidentsForm.get('first_ecp_address').disabled"
                  [title]="mergeIncidentsForm.get('first_ecp_address').value"
                >
                  <odx-form-field-label>
                    <odx-icon name="tablet" size="medium"></odx-icon>
                    {{ 'MERGE_REMOTE_INCIDENTS.STR_ECP_NAME' | translate }}
                  </odx-form-field-label>

                  <input
                    type="text"
                    ignisDisableAutocomplete
                    id="first_ecp_address"
                    data-test-id="merge_remote_incidents.first_ecp_address"
                    formControlName="first_ecp_address"
                    odxFormFieldControl
                  />
                </odx-form-field>
              </div>
            </div>
          </div>

          <div class="col-md-12 mt-3">
            <odx-form-field
              variant="full"
              class="custom-input-width"
              [readonly]="mergeIncidentsForm.get('first_deployed_firefighters').disabled"
            >
              <odx-form-field-label>
                <odx-icon size="medium" name="breathing-apparatus"></odx-icon>
                {{ 'MERGE_REMOTE_INCIDENTS.STR_DEPLOYED_FIREFIGHTERS' | translate }}
              </odx-form-field-label>

              <input
                type="text"
                ignisDisableAutocomplete
                id="first_deployed_firefighters"
                data-test-id="merge_remote_incidents.first_deployed_firefighters"
                formControlName="first_deployed_firefighters"
                odxFormFieldControl
              />
            </odx-form-field>
          </div>
        </div>

        <div class="merge-icons-group">
          <odx-icon name="arrow2-right"></odx-icon>
          <odx-icon name="collection-add"></odx-icon>
        </div>

        <div class="col-6">
          <div class="col-md-10">
            <odx-form-field variant="full" [readonly]="mergeIncidentsForm.get('second_date').disabled">
              <odx-form-field-label>
                <odx-icon size="medium" name="document"></odx-icon>
                {{ 'MERGE_REMOTE_INCIDENTS.STR_INCIDENT_START_TIME' | translate }}
              </odx-form-field-label>

              <input
                type="text"
                ignisDisableAutocomplete
                id="second_date"
                data-test-id="merge_remote_incidents.second_incident_date"
                formControlName="second_date"
                odxFormFieldControl
              />
            </odx-form-field>
          </div>

          <div class="col-md-5 mt-3">
            <odx-form-field
              variant="full"
              [readonly]="mergeIncidentsForm.get('second_number').disabled"
              [title]="mergeIncidentsForm.get('second_number').value"
            >
              <odx-form-field-label>
                <odx-icon size="medium" name="document"></odx-icon>
                {{ 'MERGE_REMOTE_INCIDENTS.STR_INCIDENT_NUMBER' | translate }}
              </odx-form-field-label>

              <input
                type="text"
                ignisDisableAutocomplete
                id="second_number"
                data-test-id="merge_remote_incidents.second_incident_number"
                formControlName="second_number"
                odxFormFieldControl
              />
            </odx-form-field>
          </div>

          <div class="col-md-12 mt-3">
            <div class="row">
              <div class="col-md-5">
                <odx-form-field variant="full" [readonly]="mergeIncidentsForm.get('second_ecps_number').disabled">
                  <odx-form-field-label>
                    <odx-icon class="baselocation-icon" [class]="currentAppTheme"></odx-icon>
                    {{ 'MERGE_REMOTE_INCIDENTS.STR_ECPS_NUMBER' | translate }}
                  </odx-form-field-label>

                  <input
                    type="text"
                    ignisDisableAutocomplete
                    id="second_ecps_number"
                    data-test-id="merge_remote_incidents.second_ecps_number"
                    formControlName="second_ecps_number"
                    odxFormFieldControl
                  />
                </odx-form-field>
              </div>

              <div class="col-md-5">
                <odx-form-field
                  variant="full"
                  [readonly]="mergeIncidentsForm.get('second_ecp_address').disabled"
                  [title]="mergeIncidentsForm.get('second_ecp_address').value"
                >
                  <odx-form-field-label>
                    <odx-icon name="tablet" size="medium"></odx-icon>
                    {{ 'MERGE_REMOTE_INCIDENTS.STR_ECP_NAME' | translate }}
                  </odx-form-field-label>

                  <input
                    type="text"
                    ignisDisableAutocomplete
                    id="second_ecp_address"
                    data-test-id="merge_remote_incidents.second_ecp_address"
                    formControlName="second_ecp_address"
                    odxFormFieldControl
                  />
                </odx-form-field>
              </div>
            </div>
          </div>

          <div class="col-md-12 mt-3">
            <odx-form-field
              variant="full"
              class="custom-input-width"
              [readonly]="mergeIncidentsForm.get('second_deployed_firefighters').disabled"
            >
              <odx-form-field-label>
                <odx-icon size="medium" name="breathing-apparatus"></odx-icon>
                {{ 'MERGE_REMOTE_INCIDENTS.STR_DEPLOYED_FIREFIGHTERS' | translate }}
              </odx-form-field-label>

              <input
                type="text"
                ignisDisableAutocomplete
                id="second_deployed_firefighters"
                data-test-id="merge_remote_incidents.second_deployed_firefighters"
                formControlName="second_deployed_firefighters"
                odxFormFieldControl
              />
            </odx-form-field>
          </div>
        </div>
      </div>
    </odx-modal-content>
    <odx-modal-footer>
      <button odxButton odxModalClose variant="secondary" (click)="close()" data-test-id="modal_actions.cancel_btn">
        {{ 'MERGE_REMOTE_INCIDENTS.STR_CANCEL_BTN' | translate }}
      </button>

      <button odxButton variant="primary" (click)="mergeIncidents()" data-test-id="modal_actions.save_btn">
        {{ 'MERGE_REMOTE_INCIDENTS.STR_SAVE_BTN' | translate }}
      </button>
    </odx-modal-footer>

    <ignis-confirm-merge-remote-incidents
      class="d-none"
      [openConfirmModal]="openConfirmMergeIncidentsModal"
      [incidentsToMerge]="incidentsToMerge"
      [incidentVersion]="incidentVersion"
      (handleCloseConfirmMergeIncidentModal)="closeConfirmMergeIncidentModal($event)"
    ></ignis-confirm-merge-remote-incidents>
  </ng-template>
</form>
