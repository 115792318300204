<div class="d-flex" *ngIf="value">
  <odx-icon
    name="filter"
    class="mb-1 multiselection-icon"
    size="inline"
    *ngIf="value !== null && value?.length >= 2"
  ></odx-icon>
  <span
    *ngIf="value !== null && value?.length >= 2"
    class="ui-multiselected-empty-token ui-corner-all active-selection"
  >
    {{ value.length }} {{ 'TABLE.STR_SELECTED_ITEMS' | translate }}
  </span>
</div>
