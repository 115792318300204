import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from 'redux';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { WorkshopService } from 'src/app/workshop/services';
import * as models from '../../../models';
import * as actions from '../../actions/equipment-license-info';

@Injectable()
export class EquipmentLicenseInfoEffects {
  workshopService: WorkshopService = inject(WorkshopService);

  requestEquipmentLicenseInfo$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentLicenseInfoActionTypes.REQUEST_EQUIPMENT_LICENSE_INFO),
      switchMap(() =>
        this.workshopService.getEquipmentLicenseInfo().pipe(
          map(
            (licenseInfo: models.IEquipmentLicenseInfo) =>
              new actions.ReceiveEquipmentLicenseInfo(new models.EquipmentLicenseInfo(licenseInfo)),
          ),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveEquipmentLicenseInfo(error))),
        ),
      ),
    );
  });
}
