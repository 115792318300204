import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDirective, ModalRef, ModalService, ModalSize, ModalVariant } from '@odx/angular/components/modal';
import { BehaviorSubject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OnDestroyMixin } from 'src/app/common/mixins';
import { PropertyBag } from 'src/app/common/models';

@Component({
  selector: 'ignis-confirm-change-category-type-ids',
  templateUrl: './confirm-change-category-type-ids.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmChangeCategoryTypeIdsComponent extends OnDestroyMixin() implements OnChanges {
  @Input() equipmentForm: FormGroup;
  @Input() isOpen: boolean = false;
  @Input() lastEquipmentDataChanged: PropertyBag;

  @Output() closeConfirmModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  modalReference: ModalRef<unknown, unknown>;

  @ViewChild('confirmChangeCategoryTypeModal', { read: TemplateRef })
  public confirmChangeCategoryTypeModal: TemplateRef<ModalDirective>;

  public router: Router = inject(Router);
  modalService: ModalService = inject(ModalService);

  confirmationChangeCategoryOrTypeOrModelIsClosedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  confirmationState$: Observable<boolean> = this.confirmationChangeCategoryOrTypeOrModelIsClosedSubject.asObservable();

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpen && this.isOpen) {
      this.modalReference = this.modalService.open(this.confirmChangeCategoryTypeModal, {
        size: ModalSize.SMALL,
        variant: ModalVariant.DEFAULT,
        dismissable: false,
      });

      this.modalReference.onClose$.pipe(takeUntil(this.destroy)).subscribe((shouldCloseModal: boolean) => {
        this.closeConfirmModal.emit(shouldCloseModal);
        this.destroy.next();
        this.confirmationChangeCategoryOrTypeOrModelIsClosedSubject.next(true);
      });

      this.modalReference.onDismiss$.pipe(takeUntil(this.destroy)).subscribe(() => {
        this.closeConfirmModal.emit(false);
        this.confirmationChangeCategoryOrTypeOrModelIsClosedSubject.next(true);
        this.destroy.next();
      });
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(): void {
    this.confirmationState$.pipe(takeUntil(this.destroy)).subscribe((state: boolean) => {
      if (!state) {
        this.modalReference?.close('');
      }
    });
  }
}
