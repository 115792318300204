import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { TreeSelectModule } from 'primeng/treeselect';
import { ExportTableExcelComponent } from 'src/app/common';
import { CreateUpdateUserComponent } from '../components/create-update-user/create-update-user.component';
import { UserManagementModuleFooterComponent } from '../components/user-management-module-footer/user-management-module-footer.component';
import { UserService } from '../services/user.service';
import { UserEffects } from '../state/effects/user.effects';
import { userReducer } from '../state/reducers/user.reducer';
import { CommonModule } from './../../common/modules/common.module';
import { UserManagementComponent } from './../components/user-management/user-management.component';
import { UsersListComponent } from './../components/users-list/users-list.component';
import { UserManagementRoutingModule } from './user-management-routing.module';

@NgModule({
  declarations: [
    UserManagementComponent,
    UsersListComponent,
    CreateUpdateUserComponent,
    UserManagementModuleFooterComponent,
  ],
  imports: [
    UserManagementRoutingModule,
    StoreModule.forFeature('user', userReducer),
    EffectsModule.forFeature([UserEffects]),
    CommonModule,
    TableModule,
    MultiSelectModule,
    TreeSelectModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    FormsModule,
    DropdownModule,
    InputTextModule,
    ExportTableExcelComponent,
  ],
  providers: [UserService, provideHttpClient(withInterceptorsFromDi())],
})
export class UserManagementModule {}
