import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { LocationConfigurationService } from 'src/app/configuration/services/location-configuration.service';
import { ErrorReceiveLocationTypeValues, LocationTypeActionTypes, ReceiveLocationTypeValues } from '../../actions';

@Injectable()
export class LocationTypeEffects {
  private locationConfigurationService: LocationConfigurationService = inject(LocationConfigurationService);

  requestLocationTypeValues$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(LocationTypeActionTypes.REQUEST_LOCATION_TYPE_VALUES),
      switchMap(() =>
        this.locationConfigurationService.getLocationTypeValues().pipe(
          map((types: any) => new ReceiveLocationTypeValues(types)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveLocationTypeValues(error))),
        ),
      ),
    );
  });
}
