<div class="blue-bar-items">
  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="add-location-hierarchy-item"
    id="add-location-hierarchy-btn"
    data-test-id="configuration.create_location_hierarchy_btn"
    (click)="onCreateClick($event)"
    [disabled]="
      disableAddBtn ||
      isModalOpened ||
      router.url?.includes(ConfigurationModuleRoutes.createLocationHierarchy) ||
      router.url?.includes(ConfigurationModuleRoutes.updateLocationHierarchy)
    "
    [pTooltip]="addButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #addButtonTooltipContent>
      <div data-test-id="configuration.create_location_hierarchy_tooltip">
        {{ 'CONFIGURATION.STR_ADD_NEW_LOCATION_TOOLTIP' | translate }}
      </div>
    </ng-template>

    <odx-icon size="inline" name="add"></odx-icon>
  </button>

  <button
    [disabled]="
      !selectedItem ||
      isModalOpened ||
      router.url?.includes(ConfigurationModuleRoutes.createLocationHierarchy) ||
      router.url?.includes(ConfigurationModuleRoutes.updateLocationHierarchy)
    "
    (click)="onEditClick($event)"
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="edit-location-hierarchy-item"
    id="edit-location-hierarchy-btn"
    data-test-id="configuration.edit_location_hierarchy_btn"
    [pTooltip]="'CONFIGURATION.STR_EDIT_LOCATION_TOOLTIP' | translate"
    tooltipPosition="top"
    positionTop="-10"
  >
    <ng-template #editLocationHierarchyButtonTooltipContent>
      <div data-test-id="configuration.edit_location_hierarchy_tooltip">
        {{ 'CONFIGURATION.STR_EDIT_LOCATION_TOOLTIP' | translate }}
      </div>
    </ng-template>

    <odx-icon size="inline" name="edit"></odx-icon>
  </button>

  <button
    [disabled]="
      !selectedItem ||
      isModalOpened ||
      router.url?.includes(ConfigurationModuleRoutes.createLocationHierarchy) ||
      router.url?.includes(ConfigurationModuleRoutes.updateLocationHierarchy)
    "
    (click)="onDeleteClick($event)"
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="delete-location-hierarchy-item"
    id="delete-location-hierarchy-btn"
    data-test-id="configuration.delete_location_hierarchy_btn"
    [pTooltip]="deleteButtonTooltipContent"
    tooltipPosition="top"
    positionTop="-10"
  >
    <ng-template #deleteButtonTooltipContent>
      <div data-test-id="configuration.delete_location_hierarchy_tooltip">
        {{ 'CONFIGURATION.STR_DELETE_LOCATION_TOOLTIP' | translate }}
      </div>
    </ng-template>

    <odx-icon name="delete"></odx-icon>
  </button>
</div>
