<div class="checklist-container">
  <h1 class="checklist-name">
    {{ 'EQUIPMENT_TASK_RESULTS.STR_CHECKLIST_ITEMS' | translate }}
  </h1>

  <div
    class="checklist-step"
    [ngClass]="{ 'image-step': item.type === checklistParameterTypes.IMAGE_TYPE }"
    *ngFor="let item of checklistData.items"
    odxLayout="grid"
  >
    <div odxLayout="6">
      <p class="checklist-item-name">{{ item.name }} {{ item.required ? '*' : '' }}</p>
      <p class="muted-text" *ngIf="item.type === checklistParameterTypes.NUMERIC_TYPE">
        <ng-container *ngIf="item.itemValue.minimum !== null && item.itemValue.maximum !== null">
          {{
            'COMPLETE_CHECKLIST.STR_NUMERIC_TYPE_INFO_TEXT_BOTH'
              | translate: { minimum: item.itemValue.minimum, maximum: item.itemValue.maximum }
              | replaceDigitChar
                : {
                    minimum: item.itemValue.minimum,
                    maximum: item.itemValue.maximum,
                    decimalNumberFormat: decimalNumberFormat,
                  }
          }}
        </ng-container>

        <ng-container *ngIf="item.itemValue.minimum !== null && item.itemValue.maximum === null">
          {{
            'COMPLETE_CHECKLIST.STR_NUMERIC_TYPE_INFO_TEXT_MINIMUM'
              | translate: { minimum: item.itemValue.minimum }
              | replaceDigitChar: { minimum: item.itemValue.minimum, decimalNumberFormat: decimalNumberFormat }
          }}
        </ng-container>

        <ng-container *ngIf="item.itemValue.maximum !== null && item.itemValue.minimum === null">
          {{
            'COMPLETE_CHECKLIST.STR_NUMERIC_TYPE_INFO_TEXT_MAXIMUM'
              | translate: { maximum: item.itemValue.maximum }
              | replaceDigitChar: { maximum: item.itemValue.maximum, decimalNumberFormat: decimalNumberFormat }
          }}
        </ng-container>
      </p>
    </div>
    <ng-container [ngSwitch]="item.type">
      <div odxLayout="6" class="item-content" *ngSwitchCase="checklistParameterTypes.BOOLEAN_TYPE">
        <odx-icon name="feedback-positive" *ngIf="item.itemValue.value"></odx-icon>
        <odx-icon name="feedback-negative" class="negative-color" *ngIf="!item.itemValue.value"></odx-icon>
      </div>
      <div odxLayout="6" class="item-content is-readonly" *ngSwitchCase="checklistParameterTypes.TEXT_TYPE">
        <textarea
          ignisDisableAutocomplete
          class="form-control text-area"
          id="text-type"
          rows="3"
          data-test-id="complete_checklist.text_type"
          disabled
        >
          {{ item.itemValue.text || '-' }}
        </textarea>
      </div>
      <div odxLayout="6" class="item-content" *ngSwitchCase="checklistParameterTypes.NUMERIC_TYPE">
        <span class="numeric"
          >{{
            item.itemValue.value
              | replaceDigitChar: { value: item.itemValue.value, decimalNumberFormat: decimalNumberFormat } ?? '-'
          }}
          {{ item.itemValue.unit ?? '-' }}</span
        >
      </div>
      <div odxLayout="12" class="item-content" *ngSwitchCase="checklistParameterTypes.IMAGE_TYPE">
        <div class="images">
          <ignis-image-checker
            *ngFor="let imageName of item.itemValue.mediaLinks; let i = index"
            [mediaName]="imageName"
            (handleImageOpened)="openImageModal($event)"
          ></ignis-image-checker>

          <span *ngIf="item.itemValue.mediaLinks.length === 0">{{
            'EQUIPMENT_TASK_RESULTS.STR_CHECKLIST_NO_IMAGES' | translate
          }}</span>
        </div>
      </div>
      <div odxLayout="6" class="item-content" *ngSwitchCase="checklistParameterTypes.MULTIPLE_SELECTION_TYPE">
        <div class="multi-selection">
          <div class="option" *ngFor="let option of item.itemValue.options">
            <odx-icon name="feedback-positive" *ngIf="option.checked"></odx-icon>
            <odx-icon name="feedback-negative" class="negative-color" *ngIf="!option.checked"></odx-icon>
            {{ option.name }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ignis-image-modal
  [openModal]="isModalOpened"
  [image]="selectedImage"
  (handleCloseNoteImageModal)="closeImageModal()"
></ignis-image-modal>
