import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as d3 from 'd3';
import { orderBy } from 'lodash-es';
import { PressureUnitPipe } from 'src/app/common';
import { dateFormatWithSeconds } from 'src/app/common/utils/date-utils/date.utils';
import { ReportCenterConstants } from 'src/app/report-center/constants/report-center.constants';
import { IPreparedGraph, IPressureEntry } from 'src/app/report-center/models/incident.model';
import { ChartHelper, ChartOptions } from '../../../../helpers/chart-helper';
import { IPressureGraph } from './../../../../models/incident.model';

// eslint-disable-next-line no-shadow
enum GraphSection {
  High = 0,
  Medium = 1,
  Low = 2,
}
@Component({
  selector: 'ignis-pressure-timeline',
  templateUrl: './pressure-timeline.component.html',
  styleUrls: ['./pressure-timeline.component.scss'],
  providers: [PressureUnitPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PressureTimelineComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('chart') public chart: ElementRef;
  @Input() pressureData: any[] = [];
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() formatDate: string;
  @Input() timelineVisibleWidth: number;
  @Input() timelineLeftContentWidth: number = 230;
  @Input() minuteToPixel: number;
  @Input() timelineWidth: number;
  @Input() currentBaSet: number;
  @Input() ecpsList: HTMLElement;
  @Input() currentEventTimelineIndex: number;
  @Input() pressureDisplayUnit: string;

  data: any[];
  chartData: IPreparedGraph[] = [];
  chartHelper: any;
  options: ChartOptions;

  private readonly maxPressure: number = 300;
  private readonly pressureOkThreshold: number = 100;
  private readonly pressureWarningThreshold: number = 55;

  private x0: any;
  private y0: any;
  private svg: any;
  private pressureChart: any;
  private tooltip: any;

  constructor(
    private translateService: TranslateService,
    private pressureUnitPipe: PressureUnitPipe,
  ) {}

  ngOnInit(): void {
    this.data = structuredClone(this.pressureData);
    this.data.forEach((graph: IPressureGraph) => {
      Object.keys(graph).forEach((key: any) => {
        if (graph[key].length) {
          this.chartData.push({ graphData: graph[key], graphType: key });
        }
      });
    });

    this.chartData.forEach((d: any) => {
      d.graphData.forEach((entry: IPressureEntry) => (entry.timestamp = new Date(entry.timestamp)));
    });

    this.chartData[GraphSection.Low]?.graphData.unshift({
      timestamp: new Date(Math.round(this.chartData[GraphSection.Low]?.graphData[0].timestamp.getTime() - 10000)),
      cylinderPressure: this.chartData[GraphSection.Low]?.graphData[0].cylinderPressure,
    });

    this.processPressureGraphWithZeroFirst();
    this.initScales();
  }

  ngOnChanges(): void {
    this.initializeChart();
  }

  ngAfterViewInit(): void {
    this.drawChart();
  }

  /* istanbul ignore next */
  initializeChart(): void {
    this.options = {
      width: this.timelineWidth,
      height: 60,
      minWidth: 0,
      minHeight: 0,
      margin: { top: 0, right: 0, bottom: 1, left: 0 },
    };

    this.chartHelper = new ChartHelper(this.options);
  }

  private initScales(): void {
    this.x0 = this.chartHelper.createXTimeScale([this.startDate, this.endDate]);

    this.y0 = d3
      .scaleLinear()
      .domain([0, this.maxPressure])
      .nice()
      .range([this.options.height - this.options.margin.bottom, this.options.margin.top]);

    this.drawChartShadows();
  }

  /* istanbul ignore next */
  private drawChart(): void {
    this.svg = this.chartHelper.appendSvg(this.chart);

    const area: any = d3
      .area()
      .x((d: IPressureEntry) => this.x0(d.timestamp))
      .y1((d: IPressureEntry) => this.y0(d.cylinderPressure))
      .y0(this.y0(0));

    this.chartData
      .slice()
      .reverse()
      .forEach((data: IPreparedGraph) => {
        if (data.graphType === 'high') {
          const firstValue: IPressureEntry = {
            timestamp: new Date(Math.round(new Date(data.graphData[0].timestamp).getTime() - 2000)),
            cylinderPressure: data.graphData[0].cylinderPressure,
          };

          data.graphData.splice(0, 0, firstValue);

          const lastObjectIndex: number = data.graphData.length - 1;

          const lastValue: IPressureEntry = {
            timestamp: new Date(Math.round(new Date(data.graphData[lastObjectIndex].timestamp).getTime() - 2000)),
            cylinderPressure: data.graphData[lastObjectIndex].cylinderPressure,
          };

          data.graphData.splice(lastObjectIndex, 0, lastValue);
        }

        this.pressureChart = this.svg
          .append('path')
          .attr('d', area(data.graphData))
          .attr('stroke', 'none')
          .attr('stroke-width', '2px')
          .attr('transform', this.scalePressureGraph(data))
          .attr('fill', this.getChartColor(data));
        this.addTooltip(this.pressureChart, data.graphData);

        if (data.graphData[1]?.cylinderPressure === 0) {
          this.pressureChart.attr('stroke', ReportCenterConstants.alarmColors.ZERO_PRESSURE_COLOR);
        }
      });

    this.drawFullHourMark();
    this.showHideEcpTimelineShadow();
  }

  /* istanbul ignore next */
  drawFullHourMark(): void {
    const lineHeight: number = this.options.height + 20;
    const ticks: Date[] = this.chartHelper
      .calculateTickValues(this.startDate, this.endDate)
      .filter((tick: Date) => tick !== this.startDate);
    const fullHourValues: Date[] = ticks.filter((tick: Date) =>
      Number.isInteger((tick.getTime() - this.startDate.getTime()) / 1000 / 60 / 60),
    );

    fullHourValues.forEach((fullHour: Date, index: number) => {
      this.svg
        .append('svg:line')
        .attr('class', 'full-hour')
        .attr('transform', `translate(0,${0 - 13})`)
        .attr('x1', this.x0(fullHour))
        .attr('y1', lineHeight)
        .attr('x2', this.x0(fullHour))
        .attr('y2', 0)
        .attr('stroke', ReportCenterConstants.alarmColors.FULL_HOUR_LINE)
        .attr('stroke-width', '3px')
        .attr('date', fullHour)
        .attr('index', index);
    });

    this.initializeFullHourTooltip();
  }

  initializeFullHourTooltip(): void {
    const tooltipText: string = this.translateService.instant('INCIDENT_EVENT_TIMELINE.STR_HOUR_MARK');

    this.chartHelper.initializeFullHourTooltip(this.formatDate, this.tooltip, tooltipText);
  }

  /* istanbul ignore next */
  drawChartShadows(): void {
    const leftShadow: HTMLElement = document.getElementById(
      `ba-set-timeline-left-shadow-${this.currentEventTimelineIndex}${this.currentBaSet}`,
    );
    const rightShadow: HTMLElement = document.getElementById(
      `ba-set-timeline-right-shadow-${this.currentEventTimelineIndex}${this.currentBaSet}`,
    );

    setTimeout(() => {
      if (leftShadow || rightShadow) {
        leftShadow.style.height = '100%';
        rightShadow.style.height = '100%';
      }
    });
  }

  /* istanbul ignore next */
  showHideEcpTimelineShadow(): void {
    const list: any = this.ecpsList;
    const leftShadow: HTMLElement = document.getElementById(
      `ba-set-timeline-left-shadow-${this.currentEventTimelineIndex}${this.currentBaSet}`,
    );
    const rightShadow: HTMLElement = document.getElementById(
      `ba-set-timeline-right-shadow-${this.currentEventTimelineIndex}${this.currentBaSet}`,
    );

    this.chartHelper.manageChartShadows(list, leftShadow, rightShadow);
  }

  /* istanbul ignore next */
  private addTooltip(chart: any, data: IPressureEntry[]): void {
    this.tooltip = d3.select('body').append('div').style('display', 'none').classed(`timeline-tooltip`, true);

    this.pressureChart.on('touchmove mousemove', (event: any) => this.showTooltip(event, chart, data));
    this.pressureChart.on('touchend mouseleave', () => this.tooltip.style('display', 'none'));
  }

  /* istanbul ignore next */
  private showTooltip(event: any, chart: any, data: IPressureEntry[]): void {
    const { timestamp, cylinderPressure }: any = this.chartHelper.bisector(
      d3.pointer(event, chart.node()),
      data,
      this.x0,
      'timestamp',
    );

    const [tooltipWidth, tooltipHeight]: any = this.chartHelper.showTooltip(
      this.tooltip,
      `
        ${this.translateService.instant('INCIDENT_ANALYSIS.PRESSURE')}: ${this.pressureUnitPipe.transform(
          cylinderPressure,
          [this.pressureDisplayUnit, true],
        )}<br>
         ${this.translateService.instant('INCIDENT_EVENT_TIMELINE.STR_TIME')}: ${dateFormatWithSeconds(
           timestamp,
           this.formatDate,
         )}
        `,
    );

    this.tooltip
      ?.style('top', `${event.pageY - tooltipHeight - 10}px`)
      ?.style('left', `${event.pageX - tooltipWidth / 2}px`);
  }

  getChartColor(data: IPreparedGraph): string {
    return ReportCenterConstants.graphColors[data.graphType];
  }

  scalePressureGraph(data: IPreparedGraph): string {
    const isMediumOrHigh: boolean = [
      ReportCenterConstants.graphLevel.HIGH,
      ReportCenterConstants.graphLevel.MEDIUM,
    ].includes(data.graphType);

    return `scale(1, ${isMediumOrHigh ? 1.01 : 1})`;
  }

  processPressureGraphWithZeroFirst(): void {
    const highPressures: IPressureEntry[] = this.chartData[GraphSection.High]?.graphData;
    const mediumPressures: IPressureEntry[] = this.chartData[GraphSection.Medium]?.graphData;
    const lowPressures: IPressureEntry[] = this.chartData[GraphSection.Low]?.graphData;

    if (highPressures && mediumPressures && lowPressures) {
      this.chartData[GraphSection.High].graphData = orderBy(highPressures, ['timestamp'], ['asc']);
      this.chartData[GraphSection.Medium].graphData = orderBy(mediumPressures, ['timestamp'], ['asc']);
      this.chartData[GraphSection.Low].graphData = orderBy(lowPressures, ['timestamp'], ['asc']);

      if (this.chartData[GraphSection.Low].graphData[0].cylinderPressure === 0) {
        const entries: IPressureEntry[] = [];

        this.chartData[GraphSection.Low].graphData.forEach((elem: IPressureEntry) => {
          if (elem.cylinderPressure > 0) {
            entries.push(elem);
          }
        });

        this.chartData[GraphSection.Low].graphData = this.chartData[GraphSection.Low].graphData.filter(
          (entry: IPressureEntry) => entry.cylinderPressure < 1,
        );
        this.chartData = [...this.chartData, { graphData: entries, graphType: 'low' }];
        this.chartData[GraphSection.Low].graphData.push({
          timestamp: this.chartData[GraphSection.High].graphData[0].timestamp,
          cylinderPressure: 0,
        });
        this.chartData[GraphSection.Low].graphData = orderBy(
          this.chartData[GraphSection.Low].graphData,
          ['timestamp'],
          ['asc'],
        );
      }
    }
  }
}
