import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, inject } from '@angular/core';
import { CommonConstants } from 'src/app/common';
import { PropertyBag } from 'src/app/common/models/common.model';
import { formatLocaleTime } from 'src/app/common/utils/date-utils/date.utils';
import { DeviceConnectionConstants } from 'src/app/device-connection/constants/device-connection.constants';
import { IMediaAssets } from 'src/app/report-center/models/incident.model';

@Component({
  selector: 'ignis-media-item',
  templateUrl: './media-item.component.html',
  styleUrls: ['./media-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaItemComponent implements OnChanges {
  @Input() media: IMediaAssets;
  @Input() formatDate: string;

  mediaItem: IMediaAssets;
  imageUrl: string;
  imageSrc: ArrayBuffer | string;
  isModalOpened: boolean = false;

  deviceCategories: PropertyBag = DeviceConnectionConstants.deviceCategories;

  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  httpClient: HttpClient = inject(HttpClient);

  ngOnChanges(): void {
    if (this.media) {
      this.mediaItem = this.media;
      this.mediaItem = { ...this.mediaItem, time: formatLocaleTime(new Date(this.mediaItem.timestamp)) };
      this.imageUrl = this.media?.mediaLink;

      this.loadImageWithHeaders();
    }
  }

  openImageModal(): void {
    this.isModalOpened = true;
  }

  closeMediaImageModal(): void {
    this.isModalOpened = false;
  }

  loadImageWithHeaders(): void {
    const headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem(CommonConstants.ACCESS_TOKEN)}`,
    });

    this.httpClient.get(this.imageUrl, { headers: headers, responseType: 'blob' }).subscribe((response: Blob) => {
      const reader: FileReader = new FileReader();

      reader.onloadend = () => {
        this.imageSrc = reader.result;
        this.cdr.detectChanges();
      };

      reader.readAsDataURL(response);
    });
  }
}
