<ignis-progress-bar *ngIf="isLoading"></ignis-progress-bar>
<section odxLayout="grid">
  <div odxLayout="6 6@mobile">
    <h2 class="mb-3">{{ 'INCIDENT_MEDIA_ASSETS.STR_MEDIA_GALLERY' | translate }}</h2>

    <ignis-media-gallery [media]="media" [formatDate]="formatDate"></ignis-media-gallery>
  </div>
  <div odxLayout="6 6@mobile">
    <h2 class="mb-3">{{ 'INCIDENT_MEDIA_ASSETS.STR_NOTES' | translate }}</h2>

    <ignis-media-notes
      [notes]="notes"
      [version]="version"
      [formatDate]="formatDate"
      [selectedIncident]="selectedIncident"
      [incidentForm]="incidentForm"
      (getMediaAssets)="getMediaAssets($event)"
    ></ignis-media-notes>
  </div>
</section>
