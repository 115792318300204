import { Component, inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getDateFormatFromUserProfile } from 'src/app/common/utils';

@Component({
  selector: 'ignis-ba-wearer',
  templateUrl: './ba-wearer.component.html',
})
export class BaWearerComponent implements OnInit {
  formatDate: string;

  translateService: TranslateService = inject(TranslateService);

  ngOnInit(): void {
    this.formatDate = getDateFormatFromUserProfile(this.translateService);
  }
}
