import { PropertyBag } from 'src/app/common';

export const tableCustomFiltersLayout: string[] = [
  'category.value',
  'identification',
  'name',
  'assignedFireStation',
  'assignedVehicle',
  'assignedPrimaryUser',
  'displayedRegistration',
  'displayedLastUpload',
  'firstRegisteredDate',
];

export const readModelSortKeys: PropertyBag = {
  displayedRegistration: 'registrationTimestamp',
  displayedLastUpload: 'lastUploadTimestamp',
  'category.value': 'category',
};

export const tableDefaultSorting: string = 'lastUploadTimestamp,DESC';
