<div class="media-content">
  <div class="image-wrapp" [ngClass]="{ 'large-image': toggleWidget }" *ngIf="imageSrc">
    <img
      [src]="imageSrc"
      loading="lazy"
      [alt]="mediaName"
      class="note-image"
      (click)="getNoteImage(imageSrc)"
      data-test-id="remote_monitoring_notes.image"
    />
  </div>

  <div class="image-wrapp align-loader-to-center" *ngIf="!imageSrc && isImage">
    <div [odxLoadingSpinner]="!imageSrc && isImage" [odxLoadingSpinnerMinHeight]="60" class="app-loader"></div>
  </div>

  <div class="audio-note" *ngIf="audioSrc">
    <odx-icon size="inline" name="microphone"></odx-icon>
    <audio controls controlslist="nodownload noplaybackrate" data-test-id="remote_monitoring_notes.audio">
      <source [src]="audioSrc" />
    </audio>
  </div>

  <div class="align-loader-to-center" *ngIf="!audioSrc && isAudio">
    <div [odxLoadingSpinner]="!audioSrc && isAudio" [odxLoadingSpinnerMinHeight]="60" class="app-loader"></div>
  </div>
</div>
