import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { CheckingSettingsModalsService } from 'src/app/common';
import { IEquipmentHistoryFilters } from 'src/app/workshop/models';

@Component({
  selector: 'ignis-equipment-history-view-footer',
  templateUrl: './equipment-history-view-footer.component.html',
  styleUrls: ['./equipment-history-view-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentHistoryViewFooterComponent implements OnChanges {
  @Input() filterParams: IEquipmentHistoryFilters;
  @Input() isModalOpened: boolean;

  @Output() handleFilter: EventEmitter<void> = new EventEmitter<void>();

  showHistoryFilterButtonTooltip: boolean = false;
  hideFilterGearIcon: boolean = true;

  public router: Router = inject(Router);
  private checkSettingsModal: CheckingSettingsModalsService = inject(CheckingSettingsModalsService);
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  constructor() {
    this.checkSettingsModal.checkIfSettingsModalIsOpen().subscribe((isModalOpened: boolean) => {
      this.isModalOpened = isModalOpened;
      this.cdr.detectChanges();
    });
  }

  ngOnChanges(): void {
    if (this.filterParams) {
      this.hideFilterGearIcon = Object.values(this.filterParams).every((elem: boolean) => elem === false);
    } else {
      this.hideFilterGearIcon = true;
    }
  }

  onFilterClick(e: Event): void {
    e.stopPropagation();
    this.handleFilter.emit();
  }
}
