import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AvatarComponent } from '@odx/angular/components/avatar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { SimplebarAngularModule } from 'simplebar-angular';
import { ExportTableExcelComponent } from 'src/app/common';
import { CommonModule } from '../../common/modules/common.module';
import { BaWearerContainerComponent } from '../components/ba-wearer-container/ba-wearer-container.component';
import { BaWearerListViewFooterComponent } from '../components/ba-wearer-list/ba-wearer-list-view-footer/ba-wearer-list-view-footer.component';
import { BaWearerListComponent } from '../components/ba-wearer-list/ba-wearer-list.component';
import { BaWearerComponent } from '../components/ba-wearer/ba-wearer.component';
import { BaWearerPersonalDataComponent } from '../components/create-update-ba-wearer/ba-wearer-personal-data/ba-wearer-personal-data.component';
import { CreateUpdateBaWearerViewFooterComponent } from '../components/create-update-ba-wearer/create-update-ba-wearer-view-footer/create-update-ba-wearer-view-footer.component';
import { CreateUpdateBaWearerComponent } from '../components/create-update-ba-wearer/create-update-ba-wearer.component';
import { BAWearerEffects } from '../state/effects/ba-wearer.effects';
import { baWearerReducer } from '../state/reducers/ba-wearer.reducer';
import { BAWearerRoutingModule } from './ba-wearer-routing.module';

@NgModule({
  declarations: [
    BaWearerComponent,
    BaWearerContainerComponent,
    BaWearerListComponent,
    BaWearerListViewFooterComponent,
    CreateUpdateBaWearerComponent,
    BaWearerPersonalDataComponent,
    CreateUpdateBaWearerViewFooterComponent,
  ],
  imports: [
    BAWearerRoutingModule,
    StoreModule.forFeature('baWearer', baWearerReducer),
    EffectsModule.forFeature([BAWearerEffects]),
    CommonModule,
    TableModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    FormsModule,
    DropdownModule,
    InputTextModule,
    ExportTableExcelComponent,
    AvatarComponent,
    SimplebarAngularModule,
    NgSelectModule,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class BAWearerModule {}
