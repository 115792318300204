<div class="blue-bar-items">
  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    id="submit-org-settings-btn"
    data-test-id="organization_settings.save_btn"
    [disabled]="!form?.valid || !form?.dirty || isModalOpened || isSubmitDisabled"
    (click)="onSubmit($event)"
    [pTooltip]="saveButtonTooltipContent"
    tooltipPosition="top"
    positionTop="-10"
    positionLeft="5"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #saveButtonTooltipContent>
      <div data-test-id="organization_settings.save_tooltip">
        {{ 'CONFIGURATION.STR_SAVE_TOOLTIP' | translate }}
      </div>
    </ng-template>

    <odx-icon size="inline" name="save"></odx-icon>
  </button>
</div>
