import { DOCUMENT, Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ModalRef, ModalService, ModalSize, ModalVariant } from '@odx/angular/components/modal';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ApplicationState, CommonConstants, IStoreApiItem, IStoreApiList } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { EntryModel, IEntryModel } from 'src/app/common/models/common.model';
import { sliderInputValueChange } from 'src/app/common/utils/general-helper/general-helpers';
import {
  IIncidentFilter,
  IMonitoringIncident,
  IMonitoringIncidents,
} from 'src/app/remote-monitoring/models/remote-monitoring.model';
import { RemoteMonitoringActions } from 'src/app/remote-monitoring/state/actions/remote-monitoring.actions';
import {
  selectFilterParams,
  selectMonitoringIncidents,
} from 'src/app/remote-monitoring/state/selectors/remote-monitoring.selector';
import { ReportCenterConstants } from 'src/app/report-center/constants/report-center.constants';
import { IEntriesModel } from 'src/app/report-center/models/incident.model';
import { IncidentActions } from 'src/app/report-center/state/actions/incident.actions';
import { selectEntries } from 'src/app/report-center/state/selectors/incident.selector';
import { DropdownService } from '../../../../common/services/dropdown/dropdown.service';

@Component({
  selector: 'ignis-filter-incidents',
  templateUrl: './filter-incidents.component.html',
  styleUrls: ['./filter-incidents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterIncidentsComponent extends OnDestroyMixin() implements OnInit, AfterViewInit {
  @ViewChild('filterModal', { read: TemplateRef })
  public filterModal: TemplateRef<any>;

  modalRef: ModalRef;
  filterForm: FormGroup = new FormGroup({
    type: new FormControl([]),
    searchedType: new FormControl(null),
    firemenNo: new FormControl([0, 0]),
    firemenNoMin: new FormControl(0),
    firemenNoMax: new FormControl(0),
    showFinishedIncidents: new FormControl(true),
  });

  isLoading: boolean = false;
  sliderValues: number[] = [0, 0];
  sliderMaxValue: number = 0;
  entries: Observable<IEntriesModel>;
  types: Partial<IEntryModel>[];
  copyOfTypes: Partial<IEntryModel>[];
  selectedTypes: any[];
  firefightersNoList: any[];
  filterParams: IIncidentFilter;
  currentAppTheme: string;
  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;

  incidents: Observable<IMonitoringIncidents>;

  monitoringIncident: IMonitoringIncidents;

  private readonly modalService: ModalService = inject(ModalService);
  public router: Router = inject(Router);
  public dropdownService: DropdownService = inject(DropdownService);
  private translateService: TranslateService = inject(TranslateService);
  private incidentActions: IncidentActions = inject(IncidentActions);
  private store: Store<ApplicationState> = inject(Store<ApplicationState>);
  public remoteMonitoringActions: RemoteMonitoringActions = inject(RemoteMonitoringActions);
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  location: Location = inject(Location);

  constructor(@Inject(DOCUMENT) private document: Document) {
    super();

    this.currentAppTheme = this.document.body.className.split(' ')[1];
  }

  @HostListener(CommonConstants.beforeUnloadWindowEvent, ['$event'])
  handleBeforeUnload($event: any): void {
    if (this.hasUnsavedData()) {
      $event.returnValue = this.hasUnsavedData();
    }
  }

  ngOnInit(): void {
    const firefightersNoList: number[] = [];

    this.getEntries();
    this.getRemoteIncidents();

    if (this.entries) {
      this.entries.subscribe((entry: IEntriesModel) => {
        this.types = entry?.types.map((type: IEntryModel) => ({
          ...type,
          label: this.translateService.instant(
            ReportCenterConstants.incidentEntries.types.find((t: EntryModel) => t.value === type.value)
              ?.localizedName || type.value,
          ),
        }));
        this.copyOfTypes = this.types;
        this.cdr.detectChanges();
      });
    }

    this.incidents.subscribe((monitoringIncident: IMonitoringIncidents) => {
      if (monitoringIncident) {
        this.monitoringIncident = monitoringIncident;

        monitoringIncident?.incidents.forEach((incident: IMonitoringIncident) => {
          firefightersNoList.push(incident.deployedFirefighters);
        });

        this.sliderMaxValue = Math.max.apply(null, firefightersNoList);
        const defaultSliderValue: number[] = [0, this.sliderMaxValue];

        if (this.sliderValues[1] === 0) {
          this.sliderValues = defaultSliderValue;
        }
      }

      this.cdr.detectChanges();
    });
  }

  ngAfterViewInit(): void {
    this.openFilterModal();
    this.populateForm();
  }

  getRemoteIncidents(): void {
    this.incidents = this.store.pipe(
      select(selectMonitoringIncidents),
      takeUntil(this.destroy),
      map((state: IStoreApiList<IMonitoringIncidents>) => state.data),
    );
  }

  getEntries(): void {
    this.incidentActions.requestIncidentEntries();
    this.entries = this.store.pipe(
      select(selectEntries),
      map((state: IStoreApiItem<IEntriesModel>) => state.data),
      takeUntil(this.destroy),
    );
  }

  populateForm(): void {
    this.store
      .pipe(
        select(selectFilterParams),
        map((state: IStoreApiItem<IIncidentFilter>) => state.data),
        takeUntil(this.destroy),
      )
      .subscribe((params: IIncidentFilter) => {
        this.filterParams = params;

        if (params) {
          this.selectedTypes = params.type;
          this.sliderValues = [params.firemenNoMin, params.firemenNoMax];
          this.filterForm.controls.showFinishedIncidents.setValue(params.showFinishedIncidents);
          this.filterForm.controls.firemenNoMin.setValue(params.firemenNoMin);
          this.filterForm.controls.firemenNoMax.setValue(params.firemenNoMax);
          this.filterForm.controls.firemenNo.setValue(this.sliderValues);
        } else {
          this.sliderValues = [0, this.sliderMaxValue];
          this.filterForm.controls.firemenNo.setValue(this.sliderValues);
          this.filterForm.controls.firemenNoMax.setValue(this.sliderMaxValue);
        }

        this.cdr.detectChanges();
      });
  }

  openFilterModal(): void {
    this.modalRef = this.modalService.open(this.filterModal, {
      size: ModalSize.MEDIUM,
      variant: ModalVariant.DEFAULT,
      dismissable: false,
      data: 'isOpen',
    });
  }

  onSubmit(): void {
    const valueToSubmit: IIncidentFilter = {
      type: this.filterForm.value.type ? this.filterForm.value.type : [],
      firemenNoMin: this.filterForm.value.firemenNoMin,
      firemenNoMax: this.filterForm.value.firemenNoMax,
      showFinishedIncidents: this.filterForm.value.showFinishedIncidents,
    };

    this.remoteMonitoringActions.saveFilterParams(valueToSubmit);
    this.getAllIncidents();
    this.closeModal();
  }

  getAllIncidents(): void {
    this.remoteMonitoringActions.requestMonitoringIncidents();
  }

  removeUnusedElements(): void {
    const lastMultiselectFilterContainer: any = document.getElementsByClassName('p-multiselect-filter-container')[1];

    lastMultiselectFilterContainer.remove();
  }

  removeSelectedTypes(): void {
    this.selectedTypes = [];
    this.types = this.copyOfTypes;
    this.filterForm.controls.searchedType.setValue(null);
  }

  onTypesFilter(event: any): void {
    this.types = this.copyOfTypes;
    this.types = this.types.filter((type: IEntryModel) => type.label.includes(event.target.value));
  }

  closeModal(): void {
    this.location.back();
    this.modalRef.close('');
    this.filterForm.reset();
    Object.defineProperty(this.modalRef, 'data', { value: 'isClosed', writable: false });
  }

  sliderInputChange(event: any, type: string): void {
    this.sliderValues = sliderInputValueChange(event, type, this.sliderValues, this.sliderMaxValue);
  }

  hasUnsavedData(): boolean {
    return this.filterForm.dirty;
  }

  resetFilterParams(): void {
    this.remoteMonitoringActions.resetFilterParams();
    this.closeModal();
    this.getAllIncidents();
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();

    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (this.router.url !== 'remote-monitoring' && this.modalRef && this.modalRef['data'] === 'isOpen') {
      this.modalRef.close('');
    }
  }
}
