import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelper } from 'src/app/common';
import { environment } from 'src/environments/environment';
import { IAccessControlItems } from '../../models/access-control-items.model';

@Injectable({
  providedIn: 'root',
})
export class AccessControlItemsService {
  private baseUrl: string = environment.API_URLS.USERS;

  constructor(
    private httpClient: HttpClient,
    private httpHelper: HttpHelper,
  ) {}

  public getAccessControlItems(): Observable<IAccessControlItems> {
    return this.httpClient.get<any>(`${this.baseUrl}/users/access-control`, {
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
      }),
    });
  }

  public updateLastLogin(): Observable<unknown> {
    return this.httpClient.put(`${this.baseUrl}/users/last-login`, {}, this.httpHelper.options);
  }
}
