// eslint-disable-next-line no-shadow
export enum FeatureModuleIconEnum {
  Workshop = 'equipment-fire',
  RemoteMonitoring = 'observation-fire',
  Logbook = 'document',
  ReportCenter = 'analysis',
  Configuration = 'settings',
  UserManagement = 'user-group',
  RoleManagement = 'user-administration',
  DeviceConnection = 'device-connection',
  BAWearer = 'breathing-apparatus',
}
