<router-outlet></router-outlet>
<ignis-progress-bar *ngIf="isLoading | async"></ignis-progress-bar>

<span class="filter-label">{{ 'CONFIGURATION.STR_SEARCH_LOCATION' | translate }}</span>
<p-tree
  class="configuration-tree location-tree"
  #locationTree
  [value]="hierarchyData"
  [filter]="true"
  [filterMode]="'strict'"
  [emptyMessage]="'CONFIGURATION.STR_NO_RECORDS_FOUND' | translate"
  selectionMode="single"
  [(selection)]="selectedTreeNode"
  (onNodeSelect)="nodeSelect($event)"
  (clickOutside)="removeSelectedNode()"
  (onNodeDrop)="onDrop($event)"
  [validateDrop]="true"
  [draggableNodes]="true"
  [droppableNodes]="true"
  draggableScope="self"
  droppableScope="self"
  data-test-id="location_hierarchy.tree"
>
  <ng-template pTemplate="header">
    <span class="location-header">
      {{ 'CONFIGURATION.STR_LOCATION_HIERARCHY' | translate }}
    </span>
  </ng-template>

  <ng-template let-node pTemplate="default">
    <odx-icon [name]="node.icon" class="p-treenode-icon"></odx-icon>
    <span class="p-treenode-label">{{ node.label }}</span>
  </ng-template>
</p-tree>

<footer class="blue-bar">
  <ignis-location-hierarchy-view-footer
    [disableAddBtn]="disableAddBtn"
    [disableEditBtnAndDelete]="disableEditBtnAndDelete"
    [selectedItem]="selectedTreeNode"
    [isModalOpened]="openConfirmationDeleteDialog"
    (handleCreate)="createNewLocationHierarchy()"
    (handleEdit)="editLocationHierarchy()"
    (handleDelete)="openDeleteLocationDialog()"
  >
  </ignis-location-hierarchy-view-footer>
</footer>

<ignis-delete-confirmation-modal
  [isOpen]="openConfirmationDeleteDialog"
  [isLoading]="isLoading | async"
  (handleDeleteModal)="closeDeleteLocationDialog($event)"
>
  <div slot="header">
    {{ 'DELETE_LOCATION_CONFIGURATION.STR_MODAL_HEADER' | translate }}
  </div>
  <div slot="content">
    {{ 'DELETE_LOCATION_CONFIGURATION.STR_MODAL_CONTENT' | translate }}
  </div>
</ignis-delete-confirmation-modal>
