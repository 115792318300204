import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ILicenseTicket } from 'src/app/root/models/licenses.model';
import { LicensesService } from 'src/app/root/services/licenses/licenses.service';
import { LicensesActionType } from '../actions/licenses-action-types';
import {
  ErrorReceiveActivateTicketID,
  ErrorReceiveLicenseStatus,
  ReceiveActivateTicketID,
  ReceiveLicenseStatus,
} from '../actions/licenses-action-types-creator';

@Injectable()
export class LicensesEffects {
  constructor(private licensesService: LicensesService) {}

  requestLicenseStatus$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(LicensesActionType.REQUEST_LICENSE_STATUS),
      switchMap((ticket: { payload: ILicenseTicket }) =>
        this.licensesService.checkLicenseStatus(ticket.payload).pipe(
          map((status: any) => new ReceiveLicenseStatus(status)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveLicenseStatus(error))),
        ),
      ),
    );
  });

  requestActivateTicketID$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(LicensesActionType.REQUEST_ACTIVATE_TICKET_ID),
      switchMap((ticket: { payload: ILicenseTicket }) =>
        this.licensesService.activateTicketID(ticket.payload).pipe(
          map((status: any) => new ReceiveActivateTicketID(status)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveActivateTicketID(error))),
        ),
      ),
    );
  });
}
