import { Location } from '@angular/common';
import { AfterViewInit, Component, HostListener, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ModalRef, ModalService, ModalSize, ModalVariant } from '@odx/angular/components/modal';
import { TreeSelect } from 'primeng/treeselect';
import { Observable } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { CommonConstants, IApplicationState, IStoreApiItem, IStoreApiList, PropertyBag } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { AccessControlService } from 'src/app/root';
import { AppModulesTypes, AppUserRole } from 'src/app/root/models/app-types';
import { IFeatureToggle } from 'src/app/root/state/features-toggle/models/features-toggle.model';
import { selectFeaturesToggleList } from 'src/app/root/state/features-toggle/selectors/features-toggle.selector';
import { LoggerService } from './../../../common/services/logger-monitor/logger.service';
import { TableService } from './../../../common/services/table/table.service';
import { ILocationHierarchy } from './../../../configuration/models/location-configuration.model';
import { selectLocationHierarchy } from './../../../configuration/state/selectors/configuration.selectors';
import { DropdownService } from '../../../common/services/dropdown/dropdown.service';
import { UserNotificationConstants } from '../../constants';
import { UserManagementModuleRoutes } from '../../constants/user-management-module-routes.contants';
import { UserManagementConstants } from '../../constants/user-management.constants';
import { IUpdateUser, IUser, IUserRole } from '../../models/user.model';
import { UserActions } from '../../state/actions/user.actions';
import { selectedUser, selectRoleList } from '../../state/selectors/user.selector';

@Component({
  selector: 'ignis-create-update-user',
  templateUrl: './create-update-user.component.html',
  styleUrls: ['./create-update-user.component.scss'],
})
export class CreateUpdateUserComponent extends OnDestroyMixin() implements OnInit, AfterViewInit {
  @ViewChild('addUserModal', { read: TemplateRef })
  public addUserModal: TemplateRef<any>;
  @ViewChild('dd2') dd2: Partial<TreeSelect>;
  modalRef: ModalRef;
  addUserForm: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.pattern(CommonConstants.emailRegex)]),
    role: new FormControl(null),
    locationRestrictionId: new FormControl(null),
    location: new FormControl(null),
  });

  successAddUser: boolean = false;
  showEmailTooltip: boolean = false;
  roles: IUserRole[];
  httpCustomErrorCode: string | any;
  selectedUserRole: string;
  selectedRestrictedUserLocation: string | any = null;
  selectedUser: IUser;
  appUserRole: PropertyBag = AppUserRole;
  isLoading: boolean = false;
  restrictToLocationFeatureToggle: boolean;
  locations: ILocationHierarchy[] | any;
  emailAlreadyExist: string = UserNotificationConstants.notificationCodes.EMAIL_ALREADY_EXISTS.value;
  isSubmitting: Observable<boolean>;
  readonly UserManagementModuleRoutes: PropertyBag = UserManagementModuleRoutes;
  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;

  public tableService: TableService = inject(TableService);
  private readonly modalService: ModalService = inject(ModalService);
  loggerService: LoggerService = inject(LoggerService);
  userActions: UserActions = inject(UserActions);
  notificationsService: NotificationsService = inject(NotificationsService);
  dropdownService: DropdownService = inject(DropdownService);
  translateService: TranslateService = inject(TranslateService);
  accessControlService: AccessControlService = inject(AccessControlService);
  location: Location = inject(Location);

  constructor(
    public router: Router,
    private store: Store<IApplicationState>,
  ) {
    super();
    this.selectedUserRole = this.appUserRole.firefighter;
  }

  canDeactivate(): boolean {
    if (this.hasUnsavedData()) {
      this.closeModal();

      return false;
    }

    return true;
  }

  @HostListener(CommonConstants.beforeUnloadWindowEvent, ['$event'])
  handleBeforeUnload($event: any): void {
    if (this.hasUnsavedData()) {
      $event.returnValue = this.hasUnsavedData();
    }
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.store
      .pipe(
        select(selectRoleList),
        map((state: IStoreApiList<IUserRole[]>) => state.data),
        takeUntil(this.destroy),
      )
      .subscribe((response: IUserRole[]) => {
        this.roles = response?.map((role: IUserRole) => ({
          ...role,
          label: this.translateService.instant(
            UserManagementConstants.userRoles.roles.find((t: IUserRole) => t.value === role.value)?.localizedName,
          ),
        }));
      });

    this.isSubmitting = this.store.select(selectedUser).pipe(
      takeUntil(this.destroy),
      map((user: IStoreApiItem<IUser>) => user.isLoading),
    );

    this.processRestrictToLocationFT();
  }

  ngAfterViewInit(): void {
    if (this.router.url?.includes(UserManagementModuleRoutes.updateUser)) {
      const routerSegments: string[] = this.router.url.split('/');
      const aggregateId: string = routerSegments[routerSegments.length - 1];

      this.userActions.requestUserById(aggregateId);
      this.addUserForm.get('email').disable();

      this.store.pipe(select(selectedUser), takeUntil(this.destroy)).subscribe((state: IStoreApiItem<IUser>) => {
        this.selectedUser = state.data;
        this.isLoading = state.isLoading;

        if (this.selectedUser) {
          this.addUserForm.get('email').setValue(this.selectedUser.email);
          this.selectedUserRole = this.selectedUser.userRole?.value;
          this.addUserForm.get('role').setValue(this.selectedUser.userRole?.value);
          this.addUserForm.get('location').setValue(null);

          if (!this.selectedUser.restrictedLocationId) {
            this.selectedRestrictedUserLocation = this.locations[0];
          } else {
            this.searchAndSelectNode(this.locations, this.selectedUser.restrictedLocationId);
          }
        }
      });
    }

    this.openAddUserModal();
  }

  searchAndSelectNode(locations: any, locationId: string): void {
    for (const node of locations) {
      if (node.data?.aggregateId === locationId) {
        setTimeout(() => {
          this.selectedRestrictedUserLocation = node;
        }, 0);

        return this.addUserForm.get('location').setValue(node);
      } else {
        this.selectedRestrictedUserLocation = this.locations[0];
      }

      if (node.children) {
        this.searchAndSelectNode(node.children, locationId);
      }
    }
  }

  processRestrictToLocationFT(): void {
    this.store
      .pipe(select(selectFeaturesToggleList))
      .pipe(takeUntil(this.destroy))
      .subscribe((state: IStoreApiList<IFeatureToggle[]>) => {
        const featuresToggle: IFeatureToggle[] = state?.data;

        featuresToggle?.forEach((ft: IFeatureToggle) => {
          if (Object.values(ft).includes(AppModulesTypes.restrictToLocation) && ft.isEnabled) {
            this.restrictToLocationFeatureToggle = ft.isEnabled;
          }
        });
      });

    this.store
      .pipe(
        select(selectLocationHierarchy),
        map((locationList: IStoreApiList<ILocationHierarchy[]>) => locationList.data || []),
        takeUntil(this.destroy),
      )
      .subscribe((response: ILocationHierarchy[]) => {
        this.locations = structuredClone(response);
        this.isLoading = false;

        this.dropdownService.findTreeNodeAndAddIcon(this.locations);

        this.locations = [
          {
            label: this.translateService.instant('USER_MANAGEMENT.STR_RESTRICTED_TO_LOCATION_DEFAULT_VALUE') as string,
            icon: 'hidden-icon',
            data: { aggregateId: '-' },
            children: [],
          },
          ...this.locations,
        ];

        if (this.selectedUser) {
          this.searchAndSelectNode(this.locations, this.selectedUser.restrictedLocationId);
        }
      });
  }

  emailChanged(): void {
    this.addUserForm.controls.email.setValue(
      this.addUserForm.controls.email.value.length === 0 ? null : this.addUserForm.value.email,
    );
  }

  setSelectedLocation(location: any): void {
    this.addUserForm.get('location').setValue(location.node);
    this.addUserForm.get('locationRestrictionId').setValue(location.node.data.aggregateId);
    this.selectedRestrictedUserLocation = location.node;

    this.dd2.overlayVisible = false;
  }

  changeUserRole(role: { value: string }): void {
    const selectedLocation: any = this.addUserForm.get('location').value;

    this.addUserForm.get('role').setValue(role.value);
    this.selectedUserRole = role.value;

    if (this.selectedUserRole === AppUserRole.tester.toString()) {
      this.addUserForm.get('locationRestrictionId').setValue(selectedLocation?.data.aggregateId);
      this.addUserForm.get('location').setValidators([Validators.required]);
    } else {
      this.addUserForm.get('location').clearValidators();
      this.addUserForm.get('location').setValue(null);
      this.addUserForm.get('locationRestrictionId').setValue(null);
    }
  }

  openAddUserModal(): void {
    this.modalRef = this.modalService.open(this.addUserModal, {
      size: ModalSize.SMALL,
      variant: ModalVariant.DEFAULT,
      dismissable: false,
      data: 'isOpen',
    });
  }

  onSubmit(): void {
    if (this.router.url?.includes(UserManagementModuleRoutes.updateUser)) {
      this.handleEditUser();

      return;
    }

    this.handleAddUser();
  }

  handleEditUser(): void {
    const objToUpdate: IUpdateUser = {
      aggregateId: this.selectedUser?.aggregateId,
      version: this.selectedUser?.version,
      email: this.selectedUser?.email,
      role: this.addUserForm.value.role,
      locationRestrictionId:
        this.selectedRestrictedUserLocation?.data.aggregateId === '-'
          ? null
          : this.selectedRestrictedUserLocation?.data.aggregateId,
    };

    this.userActions.requestUpdateUser({ ...objToUpdate });
    this.store
      .pipe(
        select(selectedUser),
        filter((user: IStoreApiItem<IUser>) => !user.isLoading),
        take(1),
      )
      .subscribe((user: IStoreApiItem<IUser>) => {
        if (user.errors) {
          this.httpCustomErrorCode = user.errors.error.code;

          switch (this.httpCustomErrorCode.toString()) {
            case UserNotificationConstants.notificationCodes.AGGREGATE_VERSION_CONFLICT.value:
              return this.callEditErrorNotification(
                UserNotificationConstants.notificationCodes.USER_AGGREGATE_VERSION_CONFLICT.value,
              );
            case UserNotificationConstants.notificationCodes.IT_MANAGER_NOT_EDIT_ADMIN.value:
              return this.callEditErrorNotification(
                UserNotificationConstants.notificationCodes.IT_MANAGER_NOT_EDIT_ADMIN.value,
              );
            case UserNotificationConstants.notificationCodes.USER_ENTITY_NOT_FOUND.value:
              return this.callEditErrorNotification(
                UserNotificationConstants.notificationCodes.USER_ENTITY_NOT_FOUND.value,
              );
            case UserNotificationConstants.notificationCodes.USER_TRY_TO_UPDATE_OWN_ROLE.value:
              return this.callEditErrorNotification(
                UserNotificationConstants.notificationCodes.USER_TRY_TO_UPDATE_OWN_ROLE.value,
              );
            case UserNotificationConstants.notificationCodes.INVALID_ROLE_RESTRICTION.value:
              return this.callEditErrorNotification(
                UserNotificationConstants.notificationCodes.INVALID_ROLE_RESTRICTION.value,
              );
          }
        } else {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            UserNotificationConstants.notificationCodes.UPDATE_USER_SUCCESS,
            UserNotificationConstants.notificationCodes,
          );
        }
      });

    this.addUserForm.reset();
    this.closeModal();
  }

  callEditErrorNotification(errorCode: string, value?: any): void {
    let notificationType: string = CommonConstants.notificationType.ERROR;

    if (errorCode === UserNotificationConstants.notificationCodes.EMAIL_ALREADY_EXISTS.value) {
      notificationType = CommonConstants.notificationType.HIDDEN;
      this.addUserForm.get('email').setErrors({ invalid: true });
    }

    this.notificationsService.requestShowNotification(
      notificationType,
      errorCode,
      UserNotificationConstants.notificationCodes,
      value,
    );
  }

  handleAddUser(): void {
    delete this.addUserForm.value.location;

    this.userActions.requestAddUser({
      ...this.addUserForm.value,
      locationRestrictionId:
        this.selectedRestrictedUserLocation?.data.aggregateId === '-'
          ? null
          : this.selectedRestrictedUserLocation?.data.aggregateId,
    });

    this.store
      .pipe(
        select(selectedUser),
        filter((sUser: IStoreApiItem<IUser>) => !sUser.isLoading),
        take(1),
      )
      .subscribe((sUser: IStoreApiItem<IUser>) => {
        if (sUser.isSuccess) {
          this.successAddUser = true;
          this.addUserForm.reset();
          this.closeModal();
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            UserNotificationConstants.notificationCodes.ADD_USER_SUCCESS,
            UserNotificationConstants.notificationCodes,
          );
          this.router.navigate(['user-management']);
        } else if (sUser.errors) {
          this.successAddUser = false;
          this.httpCustomErrorCode = sUser.errors?.error?.code.toString();
          this.checkSaveErrors();
        }
      });
  }

  checkSaveErrors(): void {
    if (this.httpCustomErrorCode === UserNotificationConstants.notificationCodes.USER_ALREADY_ASSIGNED.value) {
      this.callEditErrorNotification(UserNotificationConstants.notificationCodes.USER_ALREADY_ASSIGNED.value);
    } else {
      this.callEditErrorNotification(this.httpCustomErrorCode);
    }
  }

  closeModal(): void {
    this.addUserForm.markAsPristine();
    this.addUserForm.reset();
    this.location.back();
    this.modalRef.close('');
    Object.defineProperty(this.modalRef, 'data', { value: 'isClosed', writable: false });
  }

  hasUnsavedData(): boolean {
    return this.addUserForm.dirty;
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();

    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (this.router.url !== 'user-management' && this.modalRef && this.modalRef['data'] === 'isOpen') {
      this.modalRef.close('');
    }
  }
}
