<div class="blue-bar-items">
  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    (click)="onCreateClick($event)"
    id="bluebar-create"
    data-test-id="ba_wearer_list_view_footer.create_btn"
    [disabled]="isModalOpened || selectedItem"
  >
    <odx-icon size="inline" name="add"></odx-icon>
  </button>

  <button
    (click)="onEditClick($event)"
    id="bluebar-edit"
    data-test-id="ba_wearer_list_view_footer.edit_btn"
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    [disabled]="!selectedItem || isModalOpened"
  >
    <odx-icon size="xlarge" name="edit"></odx-icon>
  </button>

  <button
    (click)="onDeleteClick($event)"
    id="bluebar-delete"
    data-test-id="ba_wearer_list_view_footer.delete_btn"
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    [disabled]="!selectedItem || isModalOpened"
  >
    <odx-icon size="xlarge" name="delete"></odx-icon>
  </button>

  <button
    type="button"
    (click)="onDownloadBAWearerTemplateClick($event)"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    id="download-ba-wearer-csv-btn"
    data-test-id="ba_wearer_list_view_footer.download_csv_template_btn"
    [disabled]="isModalOpened"
    [pTooltip]="downloadCSVButtonTooltipContent"
    tooltipPosition="top"
    positionTop="-10"
  >
    <ng-template #downloadCSVButtonTooltipContent>
      <div data-test-id="ba_wearer_list_view_footer.download_csv_template_tooltip">
        {{ 'BA_WEARER.STR_DOWNLOAD_TEMPLATE' | translate }}
      </div>
    </ng-template>

    <odx-icon size="xlarge" name="download"></odx-icon>
  </button>

  <button
    type="button"
    (click)="onUploadBAWearerCSVFileClick($event)"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    id="upload-ba-wearer-csv-btn"
    data-test-id="ba_wearer_list_view_footer.upload_csv_btn"
    [disabled]="isModalOpened"
    [pTooltip]="uploadCSVButtonTooltipContent"
    tooltipPosition="top"
    positionTop="-10"
  >
    <ng-template #uploadCSVButtonTooltipContent>
      <div data-test-id="ba_wearer_list_view_footer.upload_csv_tooltip">
        {{ 'BA_WEARER.STR_UPLOAD_TEMPLATE' | translate }}
      </div>
    </ng-template>

    <odx-icon size="xlarge" name="upload"></odx-icon>
  </button>
</div>
