import { Location } from '@angular/common';
import { Component, HostListener, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import sortBy from 'lodash-es/sortBy';
import { combineLatest, filter, map, Observable, take, takeUntil } from 'rxjs';
import { ApplicationState, CommonConstants, IScrollOptions, IStoreApiItem } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { ConfigurationModuleRoutes, ConfigurationNotificationConstants } from 'src/app/configuration/constants';
import { ConfigurationConstants } from 'src/app/configuration/constants/configuration.constants';
import { AddressBookActions } from 'src/app/configuration/state/actions';
import { EquipmentNotificationConstants } from 'src/app/workshop';
import { DropdownService } from './../../../../common/services/dropdown/dropdown.service';
import { IAddressBook } from './../../../models/address-book.model';
import {
  selectAddressBookById,
  selectEditAddressBook,
  selectNewAddressBook,
} from 'src/app/configuration/state/selectors/configuration.selectors';

@Component({
  selector: 'ignis-create-update-address-book',
  templateUrl: './create-update-address-book.component.html',
  styleUrls: ['./create-update-address-book.component.scss'],
})
export class CreateUpdateAddressBookComponent extends OnDestroyMixin() implements OnInit {
  modalType: string;
  isLoading: Observable<boolean>;
  isLoadingForm: boolean = false;
  isConfirmCloseModalOpen: boolean = false;
  showOrganizationNameTooltip: boolean = false;
  showContactNameTooltip: boolean = false;
  showEmailTooltip: boolean = false;
  linkToNavigate: string;
  isSubmitting: Observable<boolean>;
  httpCustomErrorCode: number | string;

  typesList: any = ConfigurationConstants.addressBookTypes.types.slice(0, -1);
  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;
  scrollbarOptions: IScrollOptions = CommonConstants.scrollbarOptions;

  addressBookForm: FormGroup;

  addressBookActions: AddressBookActions = inject(AddressBookActions);
  notificationsService: NotificationsService = inject(NotificationsService);
  translateService: TranslateService = inject(TranslateService);
  public dropdownService: DropdownService = inject(DropdownService);
  location: Location = inject(Location);
  router: Router = inject(Router);
  route: ActivatedRoute = inject(ActivatedRoute);
  store: Store<ApplicationState> = inject(Store<ApplicationState>);

  constructor() {
    super();

    this.router.events?.subscribe((event: any) => {
      this.linkToNavigate = event.url;
    });
  }

  @HostListener(CommonConstants.beforeUnloadWindowEvent, ['$event'])
  handleBeforeUnload($event: any): void {
    if (this.hasUnsavedData()) {
      $event.returnValue = this.hasUnsavedData();
    }
  }

  ngOnInit(): void {
    this.isLoading = combineLatest([
      this.store.select(selectNewAddressBook),
      this.store.select(selectEditAddressBook),
      this.store.select(selectAddressBookById),
    ]).pipe(
      map(
        ([addState, editState, getState]: [
          IStoreApiItem<IAddressBook>,
          IStoreApiItem<IAddressBook>,
          IStoreApiItem<IAddressBook>,
        ]) => {
          this.isLoadingForm = getState.isLoading;

          return addState.isLoading || editState.isLoading || getState.isLoading;
        },
      ),
      takeUntil(this.destroy),
    );

    this.buildForm();
    this.populateForm();

    if (ConfigurationConstants.comeFromEquipmentHierarchy in localStorage) {
      this.addressBookForm.get('types').patchValue([this.typesList[0].value]);
    }

    this.isSubmitting = combineLatest([
      this.store.select(selectNewAddressBook),
      this.store.select(selectEditAddressBook),
    ]).pipe(
      takeUntil(this.destroy),
      map(
        ([newAddressBook, editedAddressBook]: [
          newAddressBook: IStoreApiItem<IAddressBook>,
          editedAddressBook: IStoreApiItem<IAddressBook>,
        ]) => newAddressBook.isLoading || editedAddressBook.isLoading,
      ),
    );
  }

  canDeactivate(): boolean {
    if (this.addressBookForm.dirty) {
      this.confirmCloseModalOpen();

      return false;
    } else {
      this.isConfirmCloseModalOpen = false;

      return true;
    }
  }

  confirmCloseModalOpen(): void {
    this.isConfirmCloseModalOpen = true;
  }

  navigateBack(isOpen: boolean): void {
    if (isOpen) {
      this.addressBookForm.markAsPristine();

      if (window.location.pathname === this.linkToNavigate || !this.linkToNavigate) {
        if (ConfigurationConstants.comeFromEquipmentHierarchy in localStorage) {
          localStorage.setItem(ConfigurationConstants.comeFromAddAddress, 'true');
        }

        this.location.back();
      } else {
        this.router.navigate([this.linkToNavigate]);
      }
    } else {
      this.isConfirmCloseModalOpen = false;
    }
  }

  buildForm(): void {
    this.addressBookForm = new FormGroup({
      aggregateId: new FormControl(''),
      organizationName: new FormControl(null, [Validators.required]),
      types: new FormControl(null),
      address: new FormControl(''),
      comment: new FormControl(''),
      contactName: new FormControl(''),
      email: new FormControl('', [Validators.pattern(CommonConstants.emailRegex)]),
      phone: new FormControl(''),
      version: new FormControl(''),
    });
  }

  populateForm(): void {
    this.route.params.pipe(takeUntil(this.destroy)).subscribe((params: { id: string }) => {
      if (params.id) {
        this.handleAddressBookById(params.id);
      } else {
        this.modalType = CommonConstants.modalType.CREATE;
      }
    });
  }

  handleAddressBookById(id: string): void {
    this.modalType = CommonConstants.modalType.UPDATE;
    this.addressBookActions.requestAddressBookById(id);

    this.store
      .pipe(select(selectAddressBookById), takeUntil(this.destroy))
      .subscribe((addressBook: IStoreApiItem<IAddressBook>) => {
        if (addressBook.data) {
          this.addressBookForm.patchValue(this.setData(addressBook.data));
        }

        if (addressBook.errors) {
          this.httpCustomErrorCode = addressBook.errors?.error.code?.toString();

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            this.httpCustomErrorCode,
            ConfigurationNotificationConstants.notificationCodes,
          );

          if (
            this.httpCustomErrorCode === ConfigurationNotificationConstants.notificationCodes.ENTITY_NOT_EXIST.value
          ) {
            setTimeout(() => {
              this.router.navigate([ConfigurationModuleRoutes.configuration, ConfigurationModuleRoutes.address]);
            }, CommonConstants.DEFAULT_REDIRECT_TIMEOUT);
          }
        }
      });
  }

  setData(addressBook: IAddressBook): IAddressBook {
    return {
      ...addressBook,
      types: addressBook.types[0] === 'NONE' ? null : addressBook.types,
    };
  }

  onSubmit(): void {
    this.addressBookForm.markAsPristine();

    if (this.modalType === CommonConstants.modalType.CREATE) {
      this.addAddressBook();
    } else {
      this.updateAddressBook();
    }
  }

  updateAddressBook(): void {
    const types: string[] = sortBy(this.addressBookForm.value.types, (type: string) => type.toLowerCase());

    const editBody: IAddressBook = {
      ...this.addressBookForm.value,
      types: types.length ? types : null,
    };

    this.addressBookActions.requestEditAddressBook(editBody);

    this.store
      .pipe(
        select(selectEditAddressBook),
        filter((editAddressBook: IStoreApiItem<IAddressBook>) => !editAddressBook.isLoading),
        take(1),
      )
      .subscribe((editedAddressBook: IStoreApiItem<IAddressBook>) => {
        if (editedAddressBook.isSuccess) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            ConfigurationNotificationConstants.notificationCodes.EDIT_ADDRESS_BOOK_SUCCESS,
            ConfigurationNotificationConstants.notificationCodes,
          );
          this.router.navigate(['configuration', 'address']);
        } else if (editedAddressBook.errors) {
          this.handleFormErrors(editedAddressBook.errors);
        }
      });
  }

  addAddressBook(): void {
    const types: string[] = sortBy(this.addressBookForm.value.types, (type: string) => type.toLowerCase());

    this.addressBookActions.requestAddAddressBook({
      ...this.addressBookForm.value,
      types: types.length ? types : null,
    });

    this.store
      .pipe(
        select(selectNewAddressBook),
        filter((newAddressBook: IStoreApiItem<IAddressBook>) => !newAddressBook.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<IAddressBook>) => {
        if (response.isSuccess) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            ConfigurationNotificationConstants.notificationCodes.ADD_ADDRESS_BOOK_SUCCESS,
            ConfigurationNotificationConstants.notificationCodes,
          );

          if (ConfigurationConstants.comeFromEquipmentHierarchy in localStorage) {
            this.location.back();
            localStorage.setItem(ConfigurationConstants.comeFromAddAddress, 'true');
          } else {
            this.router.navigate(['configuration', 'address']);
          }
        } else if (response.errors) {
          this.handleFormErrors(response.errors);
        }
      });
  }

  handleFormErrors(errors: any) {
    this.httpCustomErrorCode = errors?.error.code.toString();

    if (
      this.httpCustomErrorCode ===
      EquipmentNotificationConstants.notificationCodes.ADDRESS_BOOK_ENTRY_ALREADY_EXISTS.value
    ) {
      this.markFormFieldsAsInvalid();
    }

    this.notificationsService.requestShowNotification(
      CommonConstants.notificationType.ERROR,
      this.httpCustomErrorCode,
      { ...ConfigurationNotificationConstants.notificationCodes, ...EquipmentNotificationConstants.notificationCodes },
    );
  }

  markFormFieldsAsInvalid(): void {
    this.addressBookForm.get('organizationName').setErrors({ invalid: true });
    this.addressBookForm.markAsDirty();

    if (this.addressBookForm.get('contactName').value) {
      this.addressBookForm.get('contactName').setErrors({ invalid: true });
    }

    if (this.addressBookForm.get('email').value) {
      this.addressBookForm.get('email').setErrors({ invalid: true });
    }
  }

  checkFieldValidity(): void {
    if (
      (!this.addressBookForm.get('organizationName').invalid && this.addressBookForm.get('organizationName').value) ||
      (!this.addressBookForm.get('contactName').invalid && this.addressBookForm.get('contactName').value) ||
      (!this.addressBookForm.get('email').invalid && this.addressBookForm.get('email').value)
    ) {
      this.addressBookForm.get('organizationName').setErrors({ invalid: null });
      this.addressBookForm.get('organizationName').updateValueAndValidity();

      this.addressBookForm.get('contactName').setErrors({ invalid: null });
      this.addressBookForm.get('contactName').updateValueAndValidity();

      this.addressBookForm.get('email').setErrors({ invalid: null });
      this.addressBookForm.get('email').updateValueAndValidity();

      this.httpCustomErrorCode = null;
    }
  }

  hasUnsavedData(): boolean {
    return this.addressBookForm.dirty;
  }
}
