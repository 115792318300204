import { IStoreApiItem, StoreApiItem } from 'src/app/common';
import { IEquipmentLicenseInfo } from '../../models/equipment-license-info.model';

export interface IEquipmentLicenseInfoState {
  licenseInfo: IStoreApiItem<IEquipmentLicenseInfo>;
}

export class EquipmentLicenseInfoState {
  licenseInfo: IStoreApiItem<IEquipmentLicenseInfo> = new StoreApiItem<IEquipmentLicenseInfo>();
}
