import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ApplicationState, CommonConstants } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { IncidentNotificationConstants } from 'src/app/report-center';
import { getDateFormatFromUserProfile } from '../../../common/utils/settings-utils/settings-utils';
import { RemoteMonitoringModuleRoutes } from '../../constants/remote-monitoring-module-routes.constants';
import { RemoteMonitoringConstants } from '../../constants/remote-monitoring.constants';
import { IIncidentFilter, IMonitoringIncident, IMonitoringIncidents } from '../../models/remote-monitoring.model';
import { RemoteMonitoringActions } from '../../state/actions/remote-monitoring.actions';
import { selectFilterParams, selectMonitoringIncidents } from '../../state/selectors/remote-monitoring.selector';

@Component({
  selector: 'ignis-remote-monitoring-grid',
  templateUrl: './remote-monitoring-grid.component.html',
  styleUrls: ['./remote-monitoring-grid.component.scss'],
})
export class RemoteMonitoringGridComponent extends OnDestroyMixin() implements OnInit {
  public incidents: Observable<IMonitoringIncidents>;
  public isLoadingIncidents: Observable<boolean>;
  public formatDate: string;
  public intervalToGetIncidents: number | any;
  filterParams: Observable<IIncidentFilter>;
  activateMergeBtn: boolean = false;
  activateCloseBtn: boolean = false;
  numberOfSelectedIncidents: number = 1;
  openModal: boolean = false;
  openManualRemoveModal: boolean = false;
  disableMonitorIncidentBtn: boolean = false;
  selectedItem: boolean = false;
  incidentsToMerge: string[];
  resetMerge: boolean = false;
  countMergeBtnClick: number = 0;
  selectedIncident: IMonitoringIncident;
  incidentsToFilter: IMonitoringIncident[];
  selectedIncidentsData: IMonitoringIncident[];
  appearedNewIncidents: boolean = false;
  existIncidents: boolean = true;
  existFilterIncidents: boolean = true;
  componentIsDestroyed: boolean = false;
  readonly RemoteMonitoringModuleRoutes = RemoteMonitoringModuleRoutes;

  constructor(
    public remoteMonitoringActions: RemoteMonitoringActions,
    public notificationsService: NotificationsService,
    public store: Store<ApplicationState>,
    public router: Router,
    public translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getRemoteIncidents();
    this.getFilterParams();

    this.incidents = this.store.pipe(
      select(selectMonitoringIncidents),
      takeUntil(this.destroy),
      map((state: IStoreApiItem<IMonitoringIncidents>) => this.getFilteredIncidents(state.data)),
    );

    this.isLoadingIncidents = this.store.pipe(
      select(selectMonitoringIncidents),
      takeUntil(this.destroy),
      map((state: IStoreApiItem<IMonitoringIncidents>) => state.isLoading),
    );

    this.formatDate = getDateFormatFromUserProfile(this.translateService);
  }

  getFilterParams(): void {
    this.filterParams = this.store.pipe(
      select(selectFilterParams),
      map((state: IStoreApiItem<IIncidentFilter>) => state.data),
      takeUntil(this.destroy),
    );
  }

  getFilteredIncidents(incidents: IMonitoringIncidents): IMonitoringIncidents {
    this.incidentsToFilter = incidents?.incidents;
    const incidentsToSave: IMonitoringIncident[] = incidents?.incidents;
    let objectToReturn: IMonitoringIncidents;

    if (!this.incidentsToFilter) {
      return;
    }

    this.existIncidents = !!this.incidentsToFilter.length;

    const newAppearedIncidents: IMonitoringIncident[] = this.getArrayDifference();

    this.filterParams.subscribe((filterValues: IIncidentFilter) => {
      if (!filterValues || !incidents) {
        this.appearedNewIncidents = false;
        objectToReturn = incidents;
      } else if (filterValues) {
        this.incidentsToFilter = this.incidentsToFilter?.filter(
          (incident: IMonitoringIncident) =>
            incident.deployedFirefighters <= filterValues.firemenNoMax &&
            incident.deployedFirefighters >= filterValues.firemenNoMin,
        );

        if (!filterValues.showFinishedIncidents) {
          this.incidentsToFilter = this.incidentsToFilter.filter((incident: IMonitoringIncident) => !incident.ended);
        }

        if (filterValues.type.length) {
          objectToReturn = this.filterValuesByType(filterValues, incidents);
        } else {
          objectToReturn = { ...incidents, incidents: this.incidentsToFilter };
        }
      }
    });

    localStorage.setItem(RemoteMonitoringConstants.lastGotIncidents, JSON.stringify(incidentsToSave));

    const newAppearedIncidentsIntersection: IMonitoringIncident[] = newAppearedIncidents.filter(
      (incident: IMonitoringIncident) => objectToReturn.incidents.includes(incident),
    );

    if (newAppearedIncidents.length && !newAppearedIncidentsIntersection.length) {
      this.appearedNewIncidents = true;
    }

    this.existFilterIncidents = !!objectToReturn.incidents.length;

    return objectToReturn;
  }

  getArrayDifference(): IMonitoringIncident[] {
    const lastGotIncidents: IMonitoringIncident[] =
      JSON.parse(localStorage.getItem(RemoteMonitoringConstants.lastGotIncidents)) || [];

    return this.incidentsToFilter.filter(
      (incident: IMonitoringIncident) =>
        lastGotIncidents.filter((newIncident: IMonitoringIncident) => newIncident.aggregateId === incident.aggregateId)
          .length === 0,
    );
  }

  filterValuesByType(filterValues: IIncidentFilter, incidents: IMonitoringIncidents): IMonitoringIncidents | any {
    const filteredIncidents: IMonitoringIncident[] = incidents?.incidents;
    const finalFilteredIncidents: IMonitoringIncident[] = [];

    for (const filteredIncident of filteredIncidents) {
      for (const type of filterValues.type) {
        if (filteredIncident.type.value === type.value) {
          finalFilteredIncidents.push(filteredIncident);
        }
      }
    }

    return { ...incidents, incidents: finalFilteredIncidents };
  }

  getRemoteIncidents(): void {
    this.remoteMonitoringActions.requestMonitoringIncidents();

    if (!this.componentIsDestroyed) {
      this.setIntervalToGetIncidents();
    }
  }

  setIntervalToGetIncidents() {
    this.intervalToGetIncidents = setInterval(() => {
      this.remoteMonitoringActions.requestMonitoringIncidents();
    }, 20000);
  }

  filterIncidents(): void {
    this.router.navigate(['remote-monitoring', 'filter']);
  }

  getSelectedIncidentsData(incidents: IMonitoringIncident[]): void {
    this.selectedIncidentsData = incidents;
    this.checkIfClosableIncident(incidents);
  }

  checkIfClosableIncident(incidents: IMonitoringIncident[]): void {
    if (incidents.length > 0 && incidents[incidents.length - 1].closable) {
      this.selectedIncident = incidents[incidents.length - 1];
      this.activateCloseBtn = true;
    } else {
      this.selectedIncident = null;
      this.activateCloseBtn = false;
    }
  }

  getSelectedIncidents(ids: string[]): void {
    if (ids[0]?.length > 0) {
      this.activateMergeBtn = true;
      this.selectedItem = true;
      this.resetMerge = false;
    } else {
      this.activateMergeBtn = false;
    }

    if (ids.length === 2 && ids[1]?.length > 0) {
      this.checkIncidentsForMerging(ids);
    }

    if (ids.length === 2 && ids[1]?.length < 1) {
      this.incidentsToMerge = [];
      this.activateMergeBtn = false;
      this.disableMonitorIncidentBtn = false;
      this.selectedItem = false;
      this.resetMerge = true;
      this.numberOfSelectedIncidents = 1;
    }

    if (this.numberOfSelectedIncidents === 2 && ids.length === 0) {
      this.numberOfSelectedIncidents = 1;
    }
  }

  checkIncidentsForMerging(ids: string[]): void {
    if (
      (this.selectedIncidentsData[0].ended && !this.selectedIncidentsData[1].ended) ||
      (this.selectedIncidentsData[0].ended && this.selectedIncidentsData[1].ended) ||
      (!this.selectedIncidentsData[0].ended && !this.selectedIncidentsData[1].ended)
    ) {
      this.openModal = true;
      this.incidentsToMerge = ids;
    } else {
      this.notificationsService.requestShowNotification(
        CommonConstants.notificationType.ERROR,
        IncidentNotificationConstants.notificationCodes.INCIDENTS_MERGE_PREVENT_ERROR,
        IncidentNotificationConstants.notificationCodes,
      );
      setTimeout(() => {
        this.resetMerge = true;
        this.resetMergeAction();
      }, 0);
    }
  }

  mergeIncidents(): void {
    this.countMergeBtnClick += 1;

    if (this.countMergeBtnClick > 1) {
      this.resetMergeAction();
      this.resetMerge = true;
    }

    if (this.activateMergeBtn) {
      this.numberOfSelectedIncidents += 1;
      this.disableMonitorIncidentBtn = true;
    }

    if (this.numberOfSelectedIncidents === 3) {
      this.resetMergeAction();
      this.activateMergeBtn = true;
    }

    this.activateCloseBtn = false;
  }

  manualRemoveIncident(): void {
    this.openManualRemoveModal = true;
  }

  closeManualRemoveModal(event: string): void {
    if (event) {
      this.openManualRemoveModal = false;
      this.resetManualRemoveAction();
    }
  }

  closeMergeModal(event: string): void {
    if (event) {
      this.resetMerge = true;
      this.openModal = false;
      this.resetMergeAction();
    }
  }

  resetManualRemoveAction(): void {
    this.selectedIncident = null;
    this.selectedItem = false;
    this.getSelectedIncidents([]);
    this.getSelectedIncidentsData([]);
    localStorage.removeItem(RemoteMonitoringConstants.selectedIncidents);
  }

  resetMergeAction(): void {
    this.countMergeBtnClick = 0;
    this.getSelectedIncidents([]);
    this.getSelectedIncidentsData([]);
    localStorage.removeItem(RemoteMonitoringConstants.selectedIncidents);
    this.numberOfSelectedIncidents = 1;
    this.disableMonitorIncidentBtn = false;
    this.selectedItem = false;
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();

    this.componentIsDestroyed = true;

    if (this.intervalToGetIncidents) {
      clearInterval(this.intervalToGetIncidents);
    }

    this.resetFilterParams();
  }

  resetFilterParams(): void {
    this.remoteMonitoringActions.resetFilterParams();
    this.getRemoteIncidents();
  }
}
