import { Injectable } from '@angular/core';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { AppUserPermissionList } from 'src/app/root/models/app-types';
import { AccessControlService } from 'src/app/root/services/access-control/access-control.service';

@Injectable()
export class ConfigurationModuleGuard extends OnDestroyMixin() {

  constructor(
    private accessControlService: AccessControlService
  ) {
    super();
  }

  canActivate(): boolean {
    return this.accessControlService.canActivateModule(AppUserPermissionList.configuration);
  }

}
