import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import {
  IEditLocationHierarchy,
  ILocationHierarchy,
  ILocationHierarchyChange,
  INewLocationHierarchy,
} from 'src/app/configuration/models';
import { LocationHierarchyActionTypes } from './location-hierarchy-action-types';

export class RequestLocationHierarchy implements Action {
  readonly type: LocationHierarchyActionTypes.REQUEST_LOCATION_HIERARCHY =
    LocationHierarchyActionTypes.REQUEST_LOCATION_HIERARCHY;
}

export class ReceiveLocationHierarchy implements Action {
  readonly type: LocationHierarchyActionTypes.RECEIVE_LOCATION_HIERARCHY =
    LocationHierarchyActionTypes.RECEIVE_LOCATION_HIERARCHY;

  constructor(public payload: ILocationHierarchy[]) {}
}

export class ErrorReceiveLocationHierarchy implements Action {
  readonly type: LocationHierarchyActionTypes.ERROR_RECEIVE_LOCATION_HIERARCHY =
    LocationHierarchyActionTypes.ERROR_RECEIVE_LOCATION_HIERARCHY;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestAddLocationHierarchy implements Action {
  readonly type: LocationHierarchyActionTypes.REQUEST_ADD_LOCATION_HIERARCHY =
    LocationHierarchyActionTypes.REQUEST_ADD_LOCATION_HIERARCHY;

  constructor(public payload: INewLocationHierarchy) {}
}

export class ReceiveAddLocationHierarchy implements Action {
  readonly type: LocationHierarchyActionTypes.RECEIVE_ADD_LOCATION_HIERARCHY =
    LocationHierarchyActionTypes.RECEIVE_ADD_LOCATION_HIERARCHY;

  constructor(public payload: ILocationHierarchy) {}
}

export class ErrorReceiveAddLocationHierarchy implements Action {
  readonly type: LocationHierarchyActionTypes.ERROR_RECEIVE_ADD_LOCATION_HIERARCHY =
    LocationHierarchyActionTypes.ERROR_RECEIVE_ADD_LOCATION_HIERARCHY;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestLocationHierarchyChange implements Action {
  readonly type: LocationHierarchyActionTypes.REQUEST_CHANGE_LOCATION_HIERARCHY =
    LocationHierarchyActionTypes.REQUEST_CHANGE_LOCATION_HIERARCHY;

  constructor(public payload: ILocationHierarchyChange) {}
}

export class ReceiveLocationHierarchyChange implements Action {
  readonly type: LocationHierarchyActionTypes.RECEIVE_CHANGE_LOCATION_HIERARCHY =
    LocationHierarchyActionTypes.RECEIVE_CHANGE_LOCATION_HIERARCHY;

  constructor(public payload: ILocationHierarchyChange) {}
}

export class ErrorReceiveLocationHierarchyChange implements Action {
  readonly type: LocationHierarchyActionTypes.ERROR_RECEIVE_CHANGE_LOCATION_HIERARCHY =
    LocationHierarchyActionTypes.ERROR_RECEIVE_CHANGE_LOCATION_HIERARCHY;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestEditLocationHierarchy implements Action {
  readonly type: LocationHierarchyActionTypes.REQUEST_EDIT_LOCATION_HIERARCHY =
    LocationHierarchyActionTypes.REQUEST_EDIT_LOCATION_HIERARCHY;

  constructor(public payload: IEditLocationHierarchy) {}
}
export class ReceiveEditLocationHierarchy implements Action {
  readonly type: LocationHierarchyActionTypes.RECEIVE_EDIT_LOCATION_HIERARCHY =
    LocationHierarchyActionTypes.RECEIVE_EDIT_LOCATION_HIERARCHY;

  constructor(public payload: IEditLocationHierarchy) {}
}
export class ErrorReceiveEditLocationHierarchy implements Action {
  readonly type: LocationHierarchyActionTypes.ERROR_RECEIVE_EDIT_LOCATION_HIERARCHY =
    LocationHierarchyActionTypes.ERROR_RECEIVE_EDIT_LOCATION_HIERARCHY;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestDeleteLocationHierarchy implements Action {
  readonly type: LocationHierarchyActionTypes.REQUEST_DELETE_LOCATION_HIERARCHY =
    LocationHierarchyActionTypes.REQUEST_DELETE_LOCATION_HIERARCHY;

  constructor(public payload: any) {}
}
export class ReceiveDeleteLocationHierarchy implements Action {
  readonly type: LocationHierarchyActionTypes.RECEIVE_DELETE_LOCATION_HIERARCHY =
    LocationHierarchyActionTypes.RECEIVE_DELETE_LOCATION_HIERARCHY;

  constructor(public payload: any) {}
}
export class ErrorReceiveDeleteLocationHierarchy implements Action {
  readonly type: LocationHierarchyActionTypes.ERROR_RECEIVE_DELETE_LOCATION_HIERARCHY =
    LocationHierarchyActionTypes.ERROR_RECEIVE_DELETE_LOCATION_HIERARCHY;

  constructor(public payload: HttpErrorResponse) {}
}
export class ResetDeleteLocationHierarchy implements Action {
  readonly type: LocationHierarchyActionTypes.RESET_DELETE_LOCATION_HIERARCHY =
    LocationHierarchyActionTypes.RESET_DELETE_LOCATION_HIERARCHY;
}

export class RequestSearchLocation implements Action {
  readonly type: LocationHierarchyActionTypes.REQUEST_SEARCH_LOCATION =
    LocationHierarchyActionTypes.REQUEST_SEARCH_LOCATION;

  constructor(public payload: any) {}
}
export class ReceiveSearchLocation implements Action {
  readonly type: LocationHierarchyActionTypes.RECEIVE_SEARCH_LOCATION =
    LocationHierarchyActionTypes.RECEIVE_SEARCH_LOCATION;

  constructor(public payload: any) {}
}
export class ErrorReceiveSearchLocation implements Action {
  readonly type: LocationHierarchyActionTypes.ERROR_RECEIVE_SEARCH_LOCATION =
    LocationHierarchyActionTypes.ERROR_RECEIVE_SEARCH_LOCATION;

  constructor(public payload: HttpErrorResponse) {}
}
export class ResetSearchLocation implements Action {
  readonly type: LocationHierarchyActionTypes.RESET_SEARCH_LOCATION =
    LocationHierarchyActionTypes.RESET_SEARCH_LOCATION;
}
