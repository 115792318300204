export interface IAccessControlItems {
  features: string[];
  locationRestriction: string;
  permissions: string[];
  role: string;
  tenantId: string;
  userAggregateId: string;
}

export class AccessControlItems implements IAccessControlItems {
  features: string[];
  locationRestriction: string;
  permissions: string[];
  role: string;
  tenantId: string;
  userAggregateId: string;

  constructor();
  constructor(accessControlItems: IAccessControlItems);
  constructor(accessControlItems?: IAccessControlItems) {
    if (!accessControlItems) {
      this.features = null;
      this.locationRestriction = null;
      this.permissions = null;
      this.role = null;
      this.tenantId = null;
      this.userAggregateId = null;
    } else {
      this.features = accessControlItems.features;
      this.locationRestriction = accessControlItems.locationRestriction;
      this.permissions = accessControlItems.permissions;
      this.role = accessControlItems.role;
      this.tenantId = accessControlItems.tenantId;
      this.userAggregateId = accessControlItems.userAggregateId;
    }
  }
}
