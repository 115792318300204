import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IIncidentExcelData } from 'src/app/report-center/models/incident.model';
import { IncidentActionTypes } from '../incident-action-types';

export class RequestIncidentExcelData implements Action {
  readonly type: IncidentActionTypes.REQUEST_INCIDENT_EXCEL_DATA = IncidentActionTypes.REQUEST_INCIDENT_EXCEL_DATA;

  constructor(public payload: string) { }
}
export class ReceiveIncidentExcelData implements Action {
  readonly type: IncidentActionTypes.RECEIVE_INCIDENT_EXCEL_DATA = IncidentActionTypes.RECEIVE_INCIDENT_EXCEL_DATA;

  constructor(public payload: IIncidentExcelData[]) { }
}
export class ErrorIncidentExcelData implements Action {
  readonly type: IncidentActionTypes.ERROR_INCIDENT_EXCEL_DATA = IncidentActionTypes.ERROR_INCIDENT_EXCEL_DATA;

  constructor(public payload: HttpErrorResponse) { }
}
