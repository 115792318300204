import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { IChecklist } from 'src/app/configuration/models';
import { EquipmentConfigurationService } from 'src/app/configuration/services/equipment-configuration.service';
import {
  ChecklistActionTypes,
  ErrorReceiveAddChecklist,
  ErrorReceiveDeleteChecklist,
  ErrorReceiveEditChecklist,
  ReceiveAddChecklist,
  ReceiveDeleteChecklist,
  ReceiveEditChecklist,
} from '../../actions';

@Injectable()
export class ChecklistEffects {
  equipmentConfigurationService: EquipmentConfigurationService = inject(EquipmentConfigurationService);

  requestAddChecklist$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(ChecklistActionTypes.REQUEST_ADD_CHECKLIST),
      switchMap((params: any) =>
        this.equipmentConfigurationService.addChecklist(params).pipe(
          map((newChecklist: IChecklist) => new ReceiveAddChecklist(newChecklist)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAddChecklist(error))),
        ),
      ),
    );
  });

  requestEditChecklist$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(ChecklistActionTypes.REQUEST_EDIT_CHECKLIST),
      switchMap((params: any) =>
        this.equipmentConfigurationService.editChecklist(params).pipe(
          map((editedChecklist: IChecklist) => new ReceiveEditChecklist(editedChecklist)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveEditChecklist(error))),
        ),
      ),
    );
  });

  requestDeleteChecklist$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(ChecklistActionTypes.REQUEST_DELETE_CHECKLIST),
      switchMap((params: any) =>
        this.equipmentConfigurationService.deleteChecklist(params).pipe(
          map((deletedChecklist: Partial<IChecklist>) => new ReceiveDeleteChecklist(deletedChecklist)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveDeleteChecklist(error))),
        ),
      ),
    );
  });
}
