import { PropertyBag } from '../models';

export class TableExportExcelConstants {
  public static readonly excelEntriesNumber: number = 10000;
  public static readonly cellWidthSize: { [size: string]: number } = {
    SMALL: 150,
    NORMAL: 200,
    MEDIUM: 250,
    LARGE: 350,
  };

  public static readonly headerInfo: Array<{ column: string; visible: boolean; field: string; header: string }> = [
    {
      column: 'organizationName',
      visible: true,
      field: 'organizationName',
      header: 'EXCEL_TABLE_EXPORT.STR_ORGANIZATION_NAME',
    },
    {
      column: 'exportDate',
      visible: true,
      field: 'exportDate',
      header: 'EXCEL_TABLE_EXPORT.STR_EXPORT_DATE_AND_TIME',
    },
    {
      column: 'userName',
      visible: true,
      field: 'userName',
      header: 'EXCEL_TABLE_EXPORT.STR_NAME_OF_THE_CURRENT_USER',
    },
  ];

  public static readonly reportCenterSheetTranslationKeys: PropertyBag = {
    startTime: 'REPORT_CENTER_TABLE.STR_DATE',
    incidentNumber: 'REPORT_CENTER_TABLE.STR_INCIDENT_NUMBER',
    'type.value': 'REPORT_CENTER_TABLE.STR_INCIDENT_TYPE',
    startHour: 'REPORT_CENTER_TABLE.STR_START_TIME',
    duration: 'REPORT_CENTER_TABLE.STR_DURATION',
    ecpAddresses: 'REPORT_CENTER_TABLE.STR_LOCATION',
    ecpNames: 'REPORT_CENTER_TABLE.STR_UPLOAD_DEVICES',
    hubIds: 'REPORT_CENTER_TABLE.STR_HUB_ADDRESSES',
    firefighterNo: 'REPORT_CENTER_TABLE.STR_DEPLOYED_FIREFIGHTERS',
    occurredEvents: 'REPORT_CENTER_TABLE.STR_NOTEWORTHY_EVENTS',
  };

  public static readonly userManagementSheetTranslationKeys: PropertyBag = {
    name: 'USER_MANAGEMENT.STR_TABLE_NAME',
    surname: 'USER_MANAGEMENT.STR_TABLE_SURNAME',
    emailAddress: 'USER_MANAGEMENT.STR_TABLE_EMAIL',
    userId: 'USER_MANAGEMENT.STR_TABLE_USER_ID',
    'userRole.value': 'USER_MANAGEMENT.STR_TABLE_ROLE',
    displayLastLogin: 'USER_MANAGEMENT.STR_TABLE_LAST_LOGIN',
  };

  public static readonly roleManagementSheetTranslationKeys: PropertyBag = {
    permissionRole: 'ROLE_MANAGEMENT.STR_PERMISSION_ROLE',
    admin: 'ROLE_MANAGEMENT.STR_ADMIN_OPTION',
    fire_chief: 'ROLE_MANAGEMENT.STR_FIRE_CHIEF_OPTION',
    firefighter: 'ROLE_MANAGEMENT.STR_FIREFIGHTER_OPTION',
    technician: 'ROLE_MANAGEMENT.STR_TECHNICIAN_OPTION',
    tester: 'ROLE_MANAGEMENT.STR_TESTER_OPTION',
    it_manager: 'ROLE_MANAGEMENT.STR_IT_MANAGER_OPTION',
  };

  public static readonly deviceManagementSheetTranslationKeys: PropertyBag = {
    'category.value': 'DEVICE_CONNECTION.STR_TABLE_CATEGORY',
    identification: 'DEVICE_CONNECTION.STR_TABLE_IDENTIFICATION',
    name: 'DEVICE_CONNECTION.STR_TABLE_NAME',
    assignedFireStation: 'DEVICE_CONNECTION.STR_TABLE_ASSIGNED_FIRE_STATION',
    assignedVehicle: 'DEVICE_CONNECTION.STR_TABLE_ASSIGNED_VEHICLE',
    assignedPrimaryUser: 'DEVICE_CONNECTION.STR_TABLE_ASSIGNED_PRIMARY_USER',
    firmwareVersion: 'DEVICE_CONNECTION.STR_TABLE_SOFTWARE_VERSION',
    displayedRegistration: 'DEVICE_CONNECTION.STR_TABLE_REGISTRATION_DATE_TIME',
    displayedLastUpload: 'DEVICE_CONNECTION.STR_TABLE_LAST_UPLOAD_DATE_TIME',
    registrationTimestamp: 'DEVICE_CONNECTION.STR_TABLE_REGISTRATION_DATE_TIME',
    lastUploadTimestamp: 'DEVICE_CONNECTION.STR_TABLE_LAST_UPLOAD_DATE_TIME',
  };

  public static readonly equipmentsSheetTranslationKeys: PropertyBag = {
    identifier: 'INVENTORY.STR_UNIQUE_IDENTIFIER',
    barcode: 'INVENTORY.STR_BARCODE',
    rfid: 'RFID',
    serialNo: 'INVENTORY.STR_SERIAL_NO',
    partNo: 'INVENTORY.STR_PART_NO',
    partName: 'INVENTORY.STR_PART_NAME',
    typeId: 'INVENTORY.STR_EQUIPMENT_TYPE',
    manufacturer: 'INVENTORY.STR_MANUFACTURER',
    model: 'INVENTORY.STR_EQUIPMENT_MODEL',
    description: 'INVENTORY.STR_DESCRIPTION',
    operationalStatus: 'INVENTORY.STR_OPERATIONAL_STATUS',
    serviceStatus: 'INVENTORY.STR_SERVICE_STATUS',
    location: 'INVENTORY.STR_LOCATION',
    owner: 'INVENTORY.STR_OWNER',
    outOfService: 'INVENTORY.STR_OF_OF_SERVICE',
    firstRegisteredDate: 'INVENTORY.STR_FIRST_REGISTRATION_DATE',
  };

  public static readonly serviceTasksSheetTranslationKeys: PropertyBag = {
    equipmentId: 'SERVICE_TASK_TABLE.STR_EQUIPMENT_ID',
    barcode: 'SERVICE_TASK_TABLE.STR_BARCODE',
    rfid: 'RFID',
    serialNo: 'SERVICE_TASK_TABLE.STR_SERIAL_NO',
    category: 'SERVICE_TASK_TABLE.STR_CATEGORY',
    type: 'SERVICE_TASK_TABLE.STR_TYPE',
    model: 'SERVICE_TASK_TABLE.STR_MODEL',
    operationalStatus: 'SERVICE_TASK_TABLE.STR_OPERATIONAL_STATUS',
    nextServiceDate: 'SERVICE_TASK_TABLE.STR_NEXT_SERVICE_DATE',
    taskName: 'SERVICE_TASK_TABLE.STR_TASK',
    owner: 'SERVICE_TASK_TABLE.STR_OWNER',
    location: 'SERVICE_TASK_TABLE.STR_LOCATION',
    lastServiceDate: 'SERVICE_TASK_TABLE.STR_LAST_SERVICE_DATE',
  };

  public static readonly testResultsSheetTranslationKeys: PropertyBag = {
    identification: 'EQUIPMENT_TASK_RESULTS.STR_IDENTIFICATION',
    dateTime: 'EQUIPMENT_TASK_RESULTS.STR_DATE_AND_TIME',
    barcode: 'EQUIPMENT_TASK_RESULTS.STR_BARCODE',
    rfid: 'EQUIPMENT_TASK_RESULTS.STR_RFID',
    serialNo: 'EQUIPMENT_TASK_RESULTS.STR_SERIAL_NO',
    category: 'EQUIPMENT_TASK_RESULTS.STR_CATEGORY',
    type: 'EQUIPMENT_TASK_RESULTS.STR_TYPE',
    model: 'EQUIPMENT_TASK_RESULTS.STR_MODEL',
    task: 'EQUIPMENT_TASK_RESULTS.STR_TASK',
    includedTasks: 'EQUIPMENT_TASK_RESULTS.STR_TASKS_INCLUDED',
    others: 'EQUIPMENT_TASK_RESULTS.STR_OTHER_TASKS',
    testerName: 'EQUIPMENT_TASK_RESULTS.STR_USER',
    operationalStatus: 'EQUIPMENT_TASK_RESULTS.STR_EQUIPMENT_STATUS',
  };
}
