import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IConfigurationState } from '../../models/configuration.models';
import { RequestLocationConfiguration, ResetLocationConfiguration } from './location-config-action-types-creators';

@Injectable({ providedIn: 'root' })
export class LocationConfigActions {
  constructor(public store: Store<IConfigurationState>) {}

  public requestLocationConfiguration(params: string): void {
    this.store.dispatch(new RequestLocationConfiguration(params));
  }

  public resetLocationConfiguration(): void {
    this.store.dispatch(new ResetLocationConfiguration());
  }
}
