// eslint-disable-next-line no-shadow
export enum IncidentActionTypes {
  REQUEST_INCIDENTS = 'REQUEST_INCIDENTS',
  RECEIVE_INCIDENTS = 'RECEIVE_INCIDENTS',
  ERROR_RECEIVE_INCIDENTS = 'ERROR_RECEIVE_INCIDENTS',
  REQUEST_INCIDENTS_EXPORT = 'REQUEST_INCIDENTS_EXPORT',

  REQUEST_INCIDENT_BY_ID = 'REQUEST_INCIDENT_BY_ID',
  RECEIVE_INCIDENT_BY_ID = 'RECEIVE_INCIDENT_BY_ID',
  ERROR_RECEIVE_INCIDENT_BY_ID = 'ERROR_RECEIVE_INCIDENT_BY_ID',

  REQUEST_INCIDENT_ENTRIES = 'REQUEST_INCIDENT_ENTRIES',
  RECEIVE_INCIDENT_ENTRIES = 'RECEIVE_INCIDENT_ENTRIES',
  ERROR_RECEIVE_INCIDENT_ENTRIES = 'ERROR_RECEIVE_INCIDENT_ENTRIES',

  REQUEST_UPDATE_INCIDENT = 'REQUEST_UPDATE_INCIDENT',
  RECEIVE_UPDATE_INCIDENT = 'RECEIVE_UPDATE_INCIDENT',
  ERROR_UPDATE_INCIDENT = 'ERROR_UPDATE_INCIDENT',

  REQUEST_MERGE_INCIDENTS = 'REQUEST_MERGE_INCIDENTS',
  RECEIVE_MERGE_INCIDENTS = 'RECEIVE_MERGE_INCIDENTS',
  ERROR_MERGE_INCIDENTS = 'ERROR_MERGE_INCIDENTS',

  REQUEST_INCIDENT_EXCEL_DATA = 'REQUEST_INCIDENT_EXCEL_DATA',
  RECEIVE_INCIDENT_EXCEL_DATA = 'RECEIVE_INCIDENT_EXCEL_DATA',
  ERROR_INCIDENT_EXCEL_DATA = 'ERROR_INCIDENT_EXCEL_DATA',

  REQUEST_INCIDENT_ANALYSIS = 'REQUEST_INCIDENT_ANALYSIS',
  RECEIVE_INCIDENT_ANALYSIS = 'RECEIVE_INCIDENT_ANALYSIS',
  ERROR_RECEIVE_INCIDENT_ANALYSIS = 'ERROR_RECEIVE_INCIDENT_ANALYSIS',

  REQUEST_EVENT_MARKERS_ENTRIES = 'REQUEST_EVENT_MARKERS_ENTRIES',
  RECEIVE_EVENT_MARKERS_ENTRIES = 'RECEIVE_EVENT_MARKERS_ENTRIES',
  ERROR_RECEIVE_EVENT_MARKERS_ENTRIES = 'ERROR_RECEIVE_EVENT_MARKERS_ENTRIES',

  REQUEST_ADD_EVENT_MARKER = 'REQUEST_ADD_EVENT_MARKER',
  RECEIVE_ADD_EVENT_MARKER = 'RECEIVE_ADD_EVENT_MARKER',
  ERROR_ADD_EVENT_MARKER = 'ERROR_ADD_EVENT_MARKER',

  REQUEST_UPDATE_EVENT_MARKER = 'REQUEST_UPDATE_EVENT_MARKER',
  RECEIVE_UPDATE_EVENT_MARKER = 'RECEIVE_UPDATE_EVENT_MARKER',
  ERROR_UPDATE_EVENT_MARKER = 'ERROR_UPDATE_EVENT_MARKER',

  REQUEST_EVENT_MARKER_BY_ID = 'REQUEST_EVENT_MARKER_BY_ID',
  RECEIVE_EVENT_MARKER_BY_ID = 'RECEIVE_EVENT_MARKER_BY_ID',
  ERROR_RECEIVE_EVENT_MARKER_BY_ID = 'ERROR_RECEIVE_EVENT_MARKER_BY_ID',

  REQUEST_EVENT_MARKER_DELETE = 'REQUEST_EVENT_MARKER_DELETE',
  RECEIVE_EVENT_MARKER_DELETE = 'RECEIVE_EVENT_MARKER_DELETE',
  ERROR_RECEIVE_EVENT_MARKER_DELETE = 'ERROR_RECEIVE_EVENT_MARKER_DELETE',

  REQUEST_INCIDENT_MEDIA_ASSETS = 'REQUEST_INCIDENT_MEDIA_ASSETS',
  RECEIVE_INCIDENT_MEDIA_ASSETS = 'RECEIVE_INCIDENT_MEDIA_ASSETS',
  ERROR_RECEIVE_INCIDENT_MEDIA_ASSETS = 'ERROR_RECEIVE_INCIDENT_MEDIA_ASSETS',

  REQUEST_ADD_MEDIA_NOTE_OR_MEDIA_FILE = 'REQUEST_ADD_MEDIA_NOTE',
  RECEIVE_ADD_MEDIA_NOTE_OR_MEDIA_FILE = 'RECEIVE_ADD_MEDIA_NOTE',
  ERROR_RECEIVE_ADD_MEDIA_NOTE_OR_MEDIA_FILE = 'ERROR_RECEIVE_ADD_MEDIA_NOTE',

  RESET_STATE = 'RESET_STATE',
}
