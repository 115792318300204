import { Location } from '@angular/common';
import { Component, HostListener, inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import filter from 'lodash-es/filter';
import sortBy from 'lodash-es/sortBy';
import {
  combineLatest,
  debounceTime,
  delay,
  distinctUntilChanged,
  map,
  Observable,
  filter as rxjsFilter,
  takeUntil,
} from 'rxjs';
import { NotificationConstants } from 'src/app/common/constants';
import { OnDestroyMixin } from 'src/app/common/mixins';
import { INotificationMessage } from 'src/app/common/state/notifications/models/notification.model';
import {
  checkAutoUpdateFeatureToggleBeforeStartProtectorTest,
  checkRAMConnectorPort,
  getDateFormatFromUserProfile,
  prepareDataForCylinderCharging,
  startTest,
} from 'src/app/common/utils';
import { ConfigurationConstants } from 'src/app/configuration/constants';
import { IncidentNotificationConstants } from 'src/app/report-center';
import { AccessControlService } from 'src/app/root';
import { AppUserPermissionList } from 'src/app/root/models';
import { EquipmentNotificationConstants, WorkshopConstants, WorkshopModuleRoutes } from '../../constants';
import { RAMConnectorErrorsService } from '../../services';
import { EquipmentServiceTaskActions } from '../../state/actions';
import { EquipmentCRUDActions } from '../../state/actions/equipment-crud/equipment-crud.actions';
import { EquipmentInitialSpecificationActions } from '../../state/actions/equipment-initial-specs';
import { RAMActions } from '../../state/actions/ram';
import {
  selectedSavedServicesChecklist,
  selectEquipmentItem,
  selectEquipmentSpecification,
  selectSendServiceInterval,
  selectUpdateEquipmentsLocation,
} from '../../state/selectors';
import { createEquipmentForm } from './form-fields';
import { GeneralComponent } from './general/general.component';

import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  CommonConstants,
  IApplicationState,
  IEntryModel,
  INotificationType,
  IScrollOptions,
  IStoreApiItem,
  IStoreApiList,
  NotificationsService,
  PropertyBag,
  UserStorageService,
} from 'src/app/common';
import {
  IAddressBook,
  IAddressBookPage,
  IEquipmentConfiguration,
  ILocationHierarchy,
  ILocationNode,
  ITask,
} from 'src/app/configuration/models';
import {
  AddressBookActions,
  EquipmentConfigActions,
  LocationHierarchyActions,
} from 'src/app/configuration/state/actions';
import {
  selectAddressBookPage,
  selectedEquipmentConfiguration,
  selectLocationHierarchy,
} from 'src/app/configuration/state/selectors/configuration.selectors';
import {
  CreateUpdateEquipment,
  EquipmentServiceInterval,
  ICreateUpdateEquipment,
  ICylinderChargerRequest,
  IEquipment,
  IEquipmentCategoryList,
  IEquipmentInitialSpecification,
  IEquipmentModelDTO,
  IEquipmentServiceInterval,
  IIntervalData,
  ILocationAssignment,
  ILocationChange,
} from '../../models';

@Component({
  selector: 'ignis-create-update-equipment',
  templateUrl: './create-update-equipment.component.html',
  styleUrls: ['./create-update-equipment.component.scss'],
})
export class CreateUpdateEquipmentComponent extends OnDestroyMixin() implements OnInit {
  location: Location = inject(Location);

  equipmentCRUDActions: EquipmentCRUDActions = inject(EquipmentCRUDActions);
  equipmentInitialSpecsActions: EquipmentInitialSpecificationActions = inject(EquipmentInitialSpecificationActions);
  translateService: TranslateService = inject(TranslateService);
  locationHierarchyActions: LocationHierarchyActions = inject(LocationHierarchyActions);
  addressBookActions: AddressBookActions = inject(AddressBookActions);
  equipmentConfigActions: EquipmentConfigActions = inject(EquipmentConfigActions);
  notificationsService: NotificationsService = inject(NotificationsService);
  accessControlService: AccessControlService = inject(AccessControlService);
  userStorageService: UserStorageService = inject(UserStorageService);
  renderer: Renderer2 = inject(Renderer2);
  ramActions: RAMActions = inject(RAMActions);
  ramConnectorErrorsService: RAMConnectorErrorsService = inject(RAMConnectorErrorsService);
  equipmentServiceTaskActions: EquipmentServiceTaskActions = inject(EquipmentServiceTaskActions);

  lastTest: Date = null;
  nextTest: Date = null;
  linkToNavigate: string;
  disableAllFields: boolean = false;
  restrictToLocation: boolean;
  equipmentWritePermissionAccess: boolean;
  eqIsMigrated: boolean;
  ramConnectorStatus: boolean | string;
  startAutoUpdate: boolean = false;
  notificationType: INotificationType = CommonConstants.notificationType;
  isCylinderChargeConfirmationCloseModalOpen: boolean = false;

  jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(
    private store: Store<IApplicationState>,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    super();

    this.router.events?.subscribe((event: NavigationEnd) => {
      this.linkToNavigate = event.url;
    });
  }

  @ViewChild(GeneralComponent) generalComponent: GeneralComponent;

  format: string;
  errors: string;
  fieldError: string;
  isLoading: boolean = true;
  equipmentConfigurationIsLoading: boolean = false;
  modalType: string = CommonConstants.modalType.CREATE;
  successAddUpdate: boolean;
  equipmentForm: FormGroup;
  selectedEquipment: IEquipment;
  operationalStatus: IEntryModel;
  locations: ILocationHierarchy[];

  isSubmitted: boolean = false;
  tabsWithErrors: string[] = [];
  isConfirmCloseModalOpen: boolean = false;
  suppliers: Partial<IAddressBook[] | unknown[]>;
  selectedLocation: ILocationNode = null;
  suppliersMap: Map<string, IAddressBook> = new Map<string, IAddressBook>();

  isSubmitting: Observable<boolean>;
  markToOpenConfirmCloseModal: boolean = false;
  lastEquipmentDataChanged: PropertyBag;
  lastEquipmentModel: IEquipmentModelDTO;
  openConfirmChangeCategoryTypeModelOrID: boolean = false;
  serviceDefinitions: IEquipmentServiceInterval[] = [];
  equipmentInitialSpecification: IEquipmentCategoryList;
  selectedTask: ITask = null;

  tabs: PropertyBag = WorkshopConstants.createEditModalTabs;
  scrollbarOptions: IScrollOptions = CommonConstants.scrollbarOptions;
  activeTab: string = WorkshopConstants.createEditModalTabs.GENERAL;
  disableTabs: boolean = false;
  operationalStatuses: PropertyBag = WorkshopConstants.operationalStatuses;
  operationalStatusList: IEntryModel[] = WorkshopConstants.operationalStatusLocalize.status;

  @HostListener(CommonConstants.popStateWindowEvent, ['$event'])
  onPopState(event: { returnValue: boolean }): void {
    event.returnValue = false;
  }

  @HostListener(CommonConstants.beforeUnloadWindowEvent, ['$event'])
  handleBeforeUnload($event: { returnValue: boolean }): void {
    if (this.hasUnsavedData()) {
      $event.returnValue = this.hasUnsavedData();
    }
  }

  canDeactivate(): boolean {
    if (this.equipmentForm.dirty) {
      this.confirmCloseModalOpen();

      return false;
    } else {
      this.resetOnClose();
      this.isConfirmCloseModalOpen = false;
      this.equipmentForm.get('generalData.identifier').setValidators([Validators.required]);
      this.equipmentForm.get('generalData.identifier').updateValueAndValidity();

      return true;
    }
  }

  ngOnInit(): void {
    this.onOperationalStatusChange(this.operationalStatuses.NON_OPERATIONAL);
    this.equipmentInitialSpecsActions.requestInitialEquipmentSpecification();
    this.buildForm();
    this.populateForm();

    this.errors = null;
    this.isSubmitted = false;
    this.selectedLocation = null;
    this.activeTab = WorkshopConstants.createEditModalTabs.GENERAL;
    this.equipmentWritePermissionAccess = this.accessControlService.checkPermission(
      AppUserPermissionList.equipmentWrite,
    );

    if (this.equipmentWritePermissionAccess) {
      this.locationHierarchyActions.requestLocationHierarchy();
      this.addressBookActions.requestAddressBook(WorkshopConstants.addressBookSupplierRequestOnEquipment);
    }

    this.restrictToLocation = !!this.accessControlService.locationRestriction;

    if (this.restrictToLocation) {
      this.equipmentForm.get('locationAssignment').get('location').addValidators([Validators.required]);
      this.equipmentForm.get('locationAssignment').get('location').updateValueAndValidity();
    }

    this.isSubmitting = this.store.select(selectEquipmentItem).pipe(
      takeUntil(this.destroy),
      map((state: IStoreApiItem<IEquipment>) => state.isLoading),
    );

    this.initializeSubscriptions();
  }

  initializeSubscriptions(): void {
    this.getEquipmentInitialSpecification();
    this.readEquipmentDataFromStore();
    this.getDateFormat();
    this.getLocations();
    this.getSuppliers();
  }

  populateForm(): void {
    const params: { id: string } = this.route.snapshot.params as { id: string };

    if (params.id) {
      this.modalType = CommonConstants.modalType.UPDATE;

      return;
    }

    this.equipmentForm.reset();
    this.equipmentCRUDActions.resetSelectedEquipmentState();

    this.assignLocationIfBarcodeToSaveExist();
  }

  assignLocationIfBarcodeToSaveExist(): void {
    if (
      WorkshopConstants.savedLocation in localStorage &&
      WorkshopConstants.barCodeToSave in localStorage &&
      localStorage.getItem('previousRoute')?.includes(WorkshopModuleRoutes.workflow)
    ) {
      const savedLocation: ILocationAssignment = JSON.parse(
        localStorage.getItem(WorkshopConstants.savedLocation),
      ) as ILocationAssignment;

      const localizedData: IEntryModel[] = ConfigurationConstants.locationTypes.types;
      const selectedType: IEntryModel = localizedData.find(
        (constantType: IEntryModel) => constantType.value === savedLocation.type,
      );

      this.equipmentForm.get('locationAssignment').setValue({
        location,
        aggregateId: savedLocation.aggregateId,
        name: savedLocation.name,
        type: selectedType?.localizedName
          ? (this.translateService.instant(selectedType?.localizedName) as string)
          : savedLocation.type,
        identifier: savedLocation.identifier,
        address: savedLocation.address,
        details: savedLocation.details,
      });
      this.equipmentForm.get('locationAssignment').markAsDirty();
    }
  }

  handleCreateUpdateEquipment(): void {
    this.successAddUpdate = true;
    let successNotification: INotificationMessage;

    this.equipmentForm.get('generalData.identifier').setValidators([Validators.required]);
    this.equipmentForm.get('generalData.identifier').updateValueAndValidity();

    if (this.modalType === CommonConstants.modalType.CREATE) {
      successNotification = EquipmentNotificationConstants.notificationCodes.ADD_EQUIPMENT_SUCCESS;

      localStorage.setItem(WorkshopConstants.equipmentAdded, 'true');
    } else {
      successNotification = EquipmentNotificationConstants.notificationCodes.UPDATE_EQUIPMENT_SUCCESS;
    }

    this.notificationsService.requestShowNotification(
      CommonConstants.notificationType.SUCCESS,
      successNotification,
      EquipmentNotificationConstants.notificationCodes,
      {
        equipment: (this.equipmentForm.get('generalData.identifierClone').value ||
          this.equipmentForm.get('generalData.identifier').value) as string,
      },
    );

    this.navigateBack(true);
  }

  handleFetchEquipment(equipment: IEquipment): void {
    this.isLoading = false;
    this.selectedEquipment = structuredClone(equipment);
    this.eqIsMigrated = equipment.isMigrated;

    this.lastEquipmentDataChanged = {
      lastEquipmentID: this.selectedEquipment.generalData.identifier,
      lastEquipmentBarcode: this.selectedEquipment.generalData.barcode,
      lastEquipmentRFID: this.selectedEquipment.generalData.rfid,
      lastEquipmentSerialNo: this.selectedEquipment.generalData.serialNo,
      lastEquipmentCategory: this.selectedEquipment.generalData.model.category.name,
      lastEquipmentType: this.selectedEquipment.generalData.model.type.name,
      lastEquipmentModel: this.selectedEquipment.generalData.model.name,
    };
    this.lastEquipmentModel = this.selectedEquipment.generalData.model;
    this.setEquipmentServices(false);

    this.equipmentForm.patchValue(this.selectedEquipment);
    this.equipmentForm.get('generalData.equipmentModel').patchValue(this.selectedEquipment.generalData.model.id);
    this.onOperationalStatusChange(this.selectedEquipment.generalData.operationalStatus);

    this.patchValuesForFirefighter(this.selectedEquipment);
    this.addMissingAddressBook();

    if (this.disableTabs || WorkshopConstants.openTasksTab in localStorage) {
      this.setActiveTab(this.tabs.TASKS);
      localStorage.removeItem(WorkshopConstants.openTasksTab);
    }
  }

  patchValuesForFirefighter(equipment: IEquipment): void {
    if (!this.equipmentWritePermissionAccess) {
      this.suppliers = [equipment.supplierData];

      this.equipmentForm
        .get('locationAssignment.location')
        .patchValue({ label: equipment.locationAssignment.name, data: {}, children: [] });
      this.equipmentForm.get('supplierData').patchValue(equipment.supplierData);
    }
  }

  getLocations(): void {
    this.store
      .pipe(
        select(selectLocationHierarchy),
        map((locationList: IStoreApiList<ILocationHierarchy[]>) => locationList.data || []),
        takeUntil(this.destroy),
      )
      .subscribe((response: ILocationHierarchy[]) => {
        this.locations = structuredClone(response);

        if (this.restrictToLocation) {
          this.equipmentForm.get('locationAssignment').patchValue(this.locations[0]?.data);
        }
      });
  }

  getSuppliers(): void {
    this.store
      .pipe(select(selectAddressBookPage))
      .pipe(
        rxjsFilter((addressBook: IStoreApiItem<IAddressBookPage>) => !addressBook.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((addressBook: IStoreApiItem<IAddressBookPage>) => {
        if (addressBook.data) {
          addressBook.data.entries?.forEach((address: IAddressBook) => {
            this.suppliersMap.set(address.aggregateId, address);
          });

          this.suppliers = Array.from(
            this.suppliersMap,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ([_aggregateId, address]: [string, IAddressBook]) => address,
          );

          this.addMissingAddressBook();
        }
      });
  }

  getDateFormat(): void {
    this.format = getDateFormatFromUserProfile(this.translateService);
  }

  getEquipmentInitialSpecification(): void {
    this.store
      .pipe(
        select(selectEquipmentSpecification),
        rxjsFilter(
          (equipmentInitialSpecification: IStoreApiList<IEquipmentInitialSpecification[]>) =>
            Boolean(equipmentInitialSpecification) && !equipmentInitialSpecification.isLoading,
        ),
        takeUntil(this.destroy),
      )
      .subscribe((equipmentInitialSpecification: IStoreApiList<IEquipmentInitialSpecification[]>) => {
        if (equipmentInitialSpecification.errors) {
          this.errors = equipmentInitialSpecification.errors.error?.code.toString() as string;

          this.handleAPIErrors(this.errors, {
            equipment: this.equipmentForm.get('generalData.identifier').value as string,
          });

          return;
        }

        const equipmentCategories: IEquipmentInitialSpecification[] = equipmentInitialSpecification.data || [];

        this.equipmentInitialSpecification = {
          categories: equipmentCategories
            .map((category: IEquipmentInitialSpecification) => {
              return {
                ...category,
                name: category.name,
                types: category.types,
              };
            })
            .sort((categoryA: IEquipmentInitialSpecification, categoryB: IEquipmentInitialSpecification) =>
              categoryA.name.localeCompare(categoryB.name),
            ),
        };

        this.isLoading = false;
      });
  }

  addMissingAddressBook(): void {
    const selectedSupplier: string = this.equipmentForm.get('supplierData.aggregateId').value as string;

    if (selectedSupplier && !this.suppliersMap.has(selectedSupplier)) {
      const supplier: IAddressBook = { ...this.equipmentForm.get('supplierData').getRawValue() } as IAddressBook;

      this.suppliers?.unshift(supplier);
      this.suppliersMap.set(selectedSupplier, supplier);
    }
  }

  get serviceControls(): FormArray {
    return this.equipmentForm.get('serviceIntervals') as FormArray;
  }

  buildForm(): void {
    this.equipmentForm = createEquipmentForm();
    this.calculateLastAndNextDates();
    this.toggleControlsState();
  }

  calculateLastAndNextDates(): void {
    this.equipmentForm
      .get('serviceIntervals')
      .valueChanges.pipe(takeUntil(this.destroy), debounceTime(50), distinctUntilChanged())
      .subscribe(() => {
        const tasks: ITask[] = this.equipmentForm.get('serviceIntervals').value as ITask[];

        if (!tasks || tasks.length === 0) {
          this.nextTest = null;
          this.lastTest = null;
        } else {
          const localNext: number = Math.min(
            ...filter(tasks, 'nextTestDate').map((test: ITask) => new Date(test.nextTestDate).getTime()),
          );

          const localLast: number = Math.max(
            ...filter(tasks, 'lastTestDate').map((test: ITask) => new Date(test.lastTestDate).getTime()),
          );

          this.nextTest = localNext && localNext !== Infinity ? new Date(localNext) : null;
          this.lastTest = localLast && localLast !== -Infinity ? new Date(localLast) : null;
        }
      });
  }

  toggleControlsState(): void {
    const hasWritePermission: boolean = this.accessControlService.checkPermission(AppUserPermissionList.equipmentWrite);
    const hasChangeTasksPermission: boolean = this.accessControlService.checkPermission(
      AppUserPermissionList.equipmentChangeTasks,
    );

    if (!hasWritePermission && !hasChangeTasksPermission) {
      this.disableAllFields = true;
      this.equipmentForm.disable();
    } else if (!hasWritePermission && hasChangeTasksPermission) {
      this.disableAllFields = false;
      this.disableTabs = true;
    } else {
      this.disableAllFields = false;
      this.equipmentForm.get('generalData').enable();
    }
  }

  onOperationalStatusChange(newStatus: string): void {
    this.operationalStatus = this.operationalStatusList.find(
      (opStatus: { value: string }) => opStatus.value === newStatus,
    );
  }

  confirmCloseModalOpen(): void {
    this.markToOpenConfirmCloseModal = true;

    if (this.equipmentForm.get('aggregateId').value) {
      if (this.markToOpenConfirmCloseModal || this.successAddUpdate) {
        this.isConfirmCloseModalOpen = true;
        this.markToOpenConfirmCloseModal = false;
      }
    } else {
      this.isConfirmCloseModalOpen = true;
    }
  }

  showLocationChanged(location: ILocationNode): void {
    this.selectedLocation = location;
  }

  setActiveTab(tab: string): void {
    if (this.isLoading) {
      return;
    }

    this.activeTab = tab;
  }

  get handleEquipmentFormBeforeSubmit(): ICreateUpdateEquipment {
    const equipmentPayload: ICreateUpdateEquipment = new CreateUpdateEquipment({
      ...(this.equipmentForm.getRawValue() as ICreateUpdateEquipment),
    }) as ICreateUpdateEquipment;

    Object.keys(equipmentPayload).forEach((key: string) => {
      if (!this.equipmentForm.get(key).dirty && !(this.equipmentForm.get(key) instanceof FormControl)) {
        delete equipmentPayload[key];
      }
    });

    return equipmentPayload;
  }

  markTabsWithErrors(): void {
    this.tabsWithErrors = [];

    if (this.isSubmitted) {
      switch (true) {
        case this.equipmentForm.controls.generalData.invalid:
          this.tabsWithErrors.push(this.tabs.GENERAL);
          break;

        case this.equipmentForm.controls.locationAssignment.invalid:
          this.tabsWithErrors.push(this.tabs.ASSIGNMENTS);
          break;

        case this.equipmentForm.controls.manufacturerData.invalid:
        case this.equipmentForm.controls.supplierData.invalid:
          this.tabsWithErrors.push(this.tabs.MANUFACTURERS);
          break;

        case EquipmentNotificationConstants.notificationCodes.INVALID_TEST_INTERVALS.value === this.errors:
          this.tabsWithErrors.push(this.tabs.TASKS);
          break;

        case this.equipmentForm.controls.additionalEquipmentData.invalid:
          this.tabsWithErrors.push(this.tabs.ADDITIONAL_DETAILS);
          break;
      }
    }
  }

  onSubmit(): void {
    this.errors = null;
    this.isSubmitted = true;
    this.markTabsWithErrors();

    if (this.equipmentForm.valid) {
      this.saveAndUpdateEquipment();
    }
  }

  get mandatoryUncompletedFields(): string[] {
    const fields: string[] = [];
    const controls: { [key: string]: AbstractControl<unknown, unknown> } = this.equipmentForm.controls;

    for (const name in controls) {
      if (controls[name].invalid) {
        fields.push(name);
      }
    }

    return fields;
  }

  saveAndUpdateEquipment(): void {
    this.successAddUpdate = false;

    const fromEquipmentId: string = this.equipmentForm.get('generalData.identifier').value as string;
    const formBarcode: string = this.equipmentForm.get('generalData.barcode').value as string;
    const formRFID: string = this.equipmentForm.get('generalData.rfid').value as string;
    const formSerialNo: string = this.equipmentForm.get('generalData.serialNo').value as string;
    const formModelId: string = this.equipmentForm.get('generalData.model.id').value as string;
    const formTypeId: string = this.equipmentForm.get('generalData.model.type.id').value as string;
    const formCategoryId: string = this.equipmentForm.get('generalData.model.category.id').value as string;

    const barcodeFromSelectedEquipment: string = this.selectedEquipment?.generalData.barcode;
    const rfidFromSelectedEquipment: string = this.selectedEquipment?.generalData.rfid;
    const serialNoFromSelectedEquipment: string = this.selectedEquipment?.generalData.serialNo;

    const checkIfBarcodeFieldIsModified: boolean =
      (barcodeFromSelectedEquipment &&
        formBarcode?.trim().length > 0 &&
        barcodeFromSelectedEquipment !== formBarcode) ||
      (barcodeFromSelectedEquipment && barcodeFromSelectedEquipment !== formBarcode);

    const checkIfRFIDFieldIsModified: boolean =
      (rfidFromSelectedEquipment && formRFID?.trim().length > 0 && rfidFromSelectedEquipment !== formRFID) ||
      (rfidFromSelectedEquipment && rfidFromSelectedEquipment !== formRFID);

    const checkIfSerialNoFieldIsModified: boolean =
      (serialNoFromSelectedEquipment &&
        formSerialNo?.trim().length > 0 &&
        serialNoFromSelectedEquipment !== formSerialNo) ||
      (serialNoFromSelectedEquipment && serialNoFromSelectedEquipment !== formSerialNo);

    if (this.restrictToLocation) {
      this.equipmentForm.get('locationAssignment').markAsDirty();
    }

    if (this.modalType === CommonConstants.modalType.CREATE) {
      this.saveEquipment();

      return;
    }

    if (
      this.selectedEquipment.generalData.model.id !== formModelId ||
      this.selectedEquipment.generalData.model.type.id !== formTypeId ||
      this.selectedEquipment.generalData.model.category.id !== formCategoryId ||
      this.selectedEquipment.generalData.identifier !== fromEquipmentId ||
      checkIfBarcodeFieldIsModified ||
      checkIfRFIDFieldIsModified ||
      checkIfSerialNoFieldIsModified
    ) {
      this.openConfirmChangeCategoryTypeModelOrID = true;

      return;
    }

    this.updateEquipment();
  }

  readEquipmentDataFromStore(): void {
    this.store
      .pipe(select(selectEquipmentItem), takeUntil(this.destroy))
      .subscribe((equipmentState: IStoreApiItem<IEquipment>) => {
        if (equipmentState.errors) {
          this.successAddUpdate = false;
          this.markToOpenConfirmCloseModal = false;
          this.errors = equipmentState.errors.error.code?.toString() as string;
          this.fieldError = equipmentState.errors.error?.data as string;

          this.handleAPIErrors(this.errors, {
            equipment: this.equipmentForm.get('generalData.identifier').value as string,
          });

          this.setInvalidFields();

          return;
        }

        if (equipmentState.data && equipmentState.isSuccess) {
          if (this.isSubmitted) {
            this.handleCreateUpdateEquipment();

            return;
          }

          this.handleFetchEquipment(equipmentState.data);
        }
      });
  }

  saveEquipment(): void {
    this.equipmentCRUDActions.requestAddEquipment(this.handleEquipmentFormBeforeSubmit);
  }

  updateEquipment(): void {
    this.equipmentCRUDActions.requestUpdateEquipment(this.handleEquipmentFormBeforeSubmit);
  }

  setInvalidFields(): void {
    if (this.errors in WorkshopConstants.invalidFieldsErrorsMap) {
      this.applyInvalidValidator(WorkshopConstants.invalidFieldsErrorsMap[this.errors]);
    }

    if (this.fieldError in WorkshopConstants.invalidAdditionalDetailsErrosMap) {
      this.applyInvalidValidator(WorkshopConstants.invalidAdditionalDetailsErrosMap[this.fieldError]);
    }

    this.markTabsWithErrors();
  }

  applyInvalidValidator(controlName: string): void {
    this.equipmentForm.get(controlName).clearValidators();
    this.equipmentForm.get(controlName).setValidators([this.setFieldIncorrect()]);
    this.equipmentForm.get(controlName).updateValueAndValidity();
  }

  setFieldIncorrect(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        return { incorrect: true };
      } else {
        return null;
      }
    };
  }

  navigateBack(isOpen: boolean): void {
    if (isOpen) {
      this.equipmentForm.markAsPristine();
      this.checkIfPreviousRouteIsEqTestingView();

      if (window.location.pathname === this.linkToNavigate || !this.linkToNavigate) {
        this.location.back();
      } else {
        this.router.navigate([this.linkToNavigate]);
      }
    } else {
      this.isConfirmCloseModalOpen = false;
    }
  }

  checkIfPreviousRouteIsEqTestingView(): void {
    const previousRoute: boolean =
      localStorage.getItem('previousRoute')?.includes(WorkshopModuleRoutes.testEquipment) ||
      localStorage.getItem('previousRoute')?.includes(WorkshopModuleRoutes.taskResult);

    if (previousRoute) {
      this.router.navigate([WorkshopModuleRoutes.workshop, WorkshopModuleRoutes.inventory]);
    }
  }

  resetOnClose(): void {
    this.isLoading = false;
    this.isSubmitted = false;
    this.tabsWithErrors = [];
    this.equipmentForm.reset();
    this.activeTab = this.tabs.GENERAL;
    this.resetUpdatedCategoryAndType();
  }

  resetUpdatedCategoryAndType(): void {
    this.lastEquipmentModel = null;
  }

  closeConfirmModal(isConfirmed: boolean): void {
    if (!isConfirmed) {
      this.equipmentForm.get('generalData.identifier').patchValue(this.lastEquipmentDataChanged.lastEquipmentID);
      this.equipmentForm.get('generalData.barcode').patchValue(this.lastEquipmentDataChanged.lastEquipmentBarcode);
      this.equipmentForm.get('generalData.rfid').patchValue(this.lastEquipmentDataChanged.lastEquipmentRFID);
      this.equipmentForm.get('generalData.serialNo').patchValue(this.lastEquipmentDataChanged.lastEquipmentSerialNo);
      this.equipmentForm.get('generalData.model').patchValue(this.lastEquipmentModel);

      this.generalComponent?.setFilteredEquipmentSpecification();
    } else {
      this.updateEquipment();
    }

    this.openConfirmChangeCategoryTypeModelOrID = false;
  }

  setEquipmentServices(userInteraction: boolean): void {
    if (this.selectedEquipment) {
      this.orderServices(this.selectedEquipment.serviceIntervals);
    } else {
      const categoryId: string = this.equipmentForm.get('generalData.model.category.id').value as string;
      const typeId: string = this.equipmentForm.get('generalData.model.type.id').value as string;
      const modelId: string = this.equipmentForm.get('generalData.model.id').value as string;

      if (categoryId && typeId && modelId) {
        this.equipmentConfigActions.requestEquipmentConfiguration({
          urlPath: `categories/${categoryId}/types/${typeId}/models/${modelId}`,
        });
      }
    }

    if (userInteraction) {
      this.equipmentConfigurationIsLoading = true;
      this.store
        .pipe(select(selectedEquipmentConfiguration), takeUntil(this.destroy))
        .subscribe((response: IStoreApiItem<IEquipmentConfiguration>) => {
          if (response.data) {
            this.orderServices(
              response.data.serviceDefinitions.map((service: ITask) => new EquipmentServiceInterval(service)),
            );

            if (response.data.manufacturer) {
              this.equipmentForm.get('manufacturerData').patchValue(response.data.manufacturer);
            } else {
              this.equipmentForm.get('manufacturerData').reset();
            }

            this.equipmentConfigurationIsLoading = false;
          }
        });
    }
  }

  formArrayFromServices(services: IEquipmentServiceInterval[]): void {
    this.serviceControls.clear();

    services?.forEach((service: IEquipmentServiceInterval) => {
      const serviceGroup: FormGroup = this.fb.group({
        ...service,
        children: new FormArray(service.children.map((child: unknown) => this.fb.group(child))),
      });

      this.serviceControls.push(serviceGroup);
    });

    this.serviceDefinitions = structuredClone(services);
  }

  orderServices(data: IEquipmentServiceInterval[]): void {
    this.formArrayFromServices([
      ...sortBy(filter(data, ['isInherited', true]), ['taskName']),
      ...sortBy(filter(data, ['isInherited', false]), ['taskName']),
    ]);
  }

  handleAPIErrors(errorCode: string, value?: object): void {
    let isNotificationHidden: boolean = false;

    if (errorCode === EquipmentNotificationConstants.notificationCodes.ENTITY_DOES_NOT_EXIST.value) {
      this.equipmentForm.markAsPristine();
      this.location.back();
    }

    if (
      [
        NotificationConstants.commonCodes.IDENTIFIER_SHOULD_BE_UNIQUE.value,
        EquipmentNotificationConstants.notificationCodes.EQUIPMENT_BARCODE_ALREADY_EXISTS.value,
        EquipmentNotificationConstants.notificationCodes.BARCODE_CANNOT_HAVE_ONLY_SPACES.value,
        EquipmentNotificationConstants.notificationCodes.EQUIPMENT_RFID_ALREADY_EXISTS.value,
        EquipmentNotificationConstants.notificationCodes.RFID_CANNOT_HAVE_ONLY_SPACES.value,
        EquipmentNotificationConstants.notificationCodes.SERIAL_NO_CANNOT_HAVE_ONLY_SPACES.value,
        EquipmentNotificationConstants.notificationCodes.EQUIPMENT_SERIAL_NO_ALREADY_EXISTS.value,
        EquipmentNotificationConstants.notificationCodes.EQUIPMENT_ADDITIONAL_DETAILS_DUPLICATE_ERROR.value,
      ].includes(errorCode)
    ) {
      isNotificationHidden = true;
    }

    this.notificationsService.requestShowNotification(
      isNotificationHidden ? CommonConstants.notificationType.HIDDEN : CommonConstants.notificationType.ERROR,
      errorCode,
      { ...IncidentNotificationConstants.notificationCodes, ...EquipmentNotificationConstants.notificationCodes },
      value,
    );
  }

  hasUnsavedData(): boolean {
    return this.equipmentForm.dirty;
  }

  redirectToHistory(): void {
    localStorage.setItem(CommonConstants.rootUrl, localStorage.getItem('previousRoute'));
    this.router.navigate([
      WorkshopModuleRoutes.workshop,
      WorkshopModuleRoutes.equipmentHistory,
      this.selectedEquipment.aggregateId,
    ]);
  }

  checkEquipmentStatusForCylinderCharge(): void {
    if (this.equipmentForm.get('generalData.operationalStatus').value !== this.operationalStatuses.OPERATIONAL) {
      this.isCylinderChargeConfirmationCloseModalOpen = true;

      return;
    }

    this.startCylinderCharge();
  }

  userResponseConfirmCylinderChargeBanner(response: boolean): void {
    if (response) {
      this.startCylinderCharge();
    }

    this.isCylinderChargeConfirmationCloseModalOpen = false;
  }

  startCylinderCharge(): void {
    const preparedData: IIntervalData[] = prepareDataForCylinderCharging([this.selectedEquipment]);

    this.equipmentCRUDActions.requestSavedEquipments([this.selectedEquipment.aggregateId]);
    this.equipmentServiceTaskActions.saveSelectedServiceEquipment(preparedData);
    this.router.navigate([
      WorkshopModuleRoutes.workshop,
      WorkshopModuleRoutes.updateEquipment,
      this.selectedEquipment.aggregateId,
      WorkshopModuleRoutes.cylinder,
    ]);

    this.readCylinderChargerStateFromStore();
  }

  readCylinderChargerStateFromStore(): void {
    combineLatest([this.store.select(selectSendServiceInterval), this.store.select(selectUpdateEquipmentsLocation)])
      .pipe(
        rxjsFilter(
          ([charge, updateLocation]: [IStoreApiItem<ICylinderChargerRequest>, IStoreApiItem<ILocationChange>]) =>
            !charge.isLoading && !updateLocation.isLoading,
        ),
        delay(500),
        takeUntil(this.destroy),
      )
      .subscribe(
        ([charge, updateLocation]: [IStoreApiItem<ICylinderChargerRequest>, IStoreApiItem<ILocationChange>]) => {
          if (updateLocation.errors) {
            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.ERROR,
              EquipmentNotificationConstants.notificationCodes.LOCATION_CHANGED_FAILED,
              EquipmentNotificationConstants.notificationCodes,
            );

            this.equipmentServiceTaskActions.resetUpdateEquipmentsLocation();
          } else if ((charge.isSuccess && updateLocation.isSuccess) || charge.isSuccess) {
            this.equipmentCRUDActions.requestEquipmentById(this.selectedEquipment.aggregateId);
          }
        },
      );
  }

  startTest(): void {
    const module: string = this.router.url;

    checkRAMConnectorPort({
      ramActions: this.ramActions,
      store: this.store,
      notificationsService: this.notificationsService,
    }).then(() => {
      startTest({
        ramActions: this.ramActions,
        store: this.store,
        ramConnectorErrorsService: this.ramConnectorErrorsService,
        router: this.router,
        notificationsService: this.notificationsService,
        equipmentAggregateId: this.selectedEquipment.aggregateId,
        module,
      }).then((response: boolean | string) => {
        this.ramConnectorStatus = checkAutoUpdateFeatureToggleBeforeStartProtectorTest(
          this.accessControlService,
          response,
          this.notificationsService,
        );
      });
    });
  }

  startRAMConnectorAutoUpdateFromEQList(event: boolean): void {
    this.ramConnectorStatus = null;
    this.startAutoUpdate = event;
  }

  getSelectedTask(task: ITask): void {
    setTimeout(() => {
      this.selectedTask = task;
    }, 0);
  }

  completeChecklist(): void {
    this.router.navigate([
      WorkshopModuleRoutes.workshop,
      WorkshopModuleRoutes.updateEquipment,
      this.selectedEquipment.aggregateId,
      WorkshopModuleRoutes.completeChecklist,
      this.selectedEquipment.aggregateId,
    ]);

    this.readCompleteChecklistStateFromStore();
  }

  readCompleteChecklistStateFromStore(): void {
    this.store
      .pipe(
        select(selectedSavedServicesChecklist),
        rxjsFilter((checklistCompleted: IStoreApiList<ITask>) => !checklistCompleted.isLoading),
        delay(500),
        takeUntil(this.destroy),
      )
      .subscribe((response: IStoreApiItem<ITask>) => {
        if (response.isSuccess) {
          this.equipmentCRUDActions.requestEquipmentById(this.selectedEquipment.aggregateId);
        }
      });
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();

    this.equipmentForm.get('generalData.identifier').setValidators([]);
    this.equipmentForm.get('generalData.identifier').updateValueAndValidity();

    this.equipmentForm.get('generalData.barcode').setValidators([]);
    this.equipmentForm.get('generalData.barcode').updateValueAndValidity();

    this.equipmentForm.get('generalData.rfid').setValidators([]);
    this.equipmentForm.get('generalData.rfid').updateValueAndValidity();

    this.equipmentForm.get('generalData.serialNo').setValidators([]);
    this.equipmentForm.get('generalData.serialNo').updateValueAndValidity();

    this.addressBookActions.resetAddressBookPage();
    this.equipmentCRUDActions.resetSelectedEquipmentState();
  }
}
