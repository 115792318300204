<div class="blue-bar-items">
  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    id="excel-export-btn"
    (click)="onExportExcelClick($event)"
    [disabled]="!selectedItem || isModalOpened"
    data-test-id="ba_logbook_footer.excel_export_btn"
    [pTooltip]="excelExportButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #excelExportButtonTooltipContent>
      <div data-test-id="ba_logbook_footer.excel_export_tooltip">
        {{ 'EXCEL_TABLE_EXPORT.STR_EXPORT_DATA' | translate }}
      </div>
    </ng-template>

    <odx-icon size="inline" name="export-to-excel"></odx-icon>
  </button>

  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    [disabled]="
      !activeEdit ||
      !accessControlService.checkPermission(appUserPermissionList.reportCenter) ||
      !accessControlService.reportCenterFeatureToggle ||
      isModalOpened
    "
    (click)="onNavigateToReportCenterClick($event)"
    id="navigate-btn"
    data-test-id="ba_logbook_footer.navigate_report_btn"
    [pTooltip]="navigateExportButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #navigateExportButtonTooltipContent>
      @if (
        !accessControlService.checkPermission(appUserPermissionList.reportCenter) ||
        !accessControlService.reportCenterFeatureToggle
      ) {
        <div data-test-id="ba_logbook_footer.navigate_report_no_permission_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_NO_PERMISSION' | translate }}
        </div>
      } @else if (
        accessControlService.checkPermission(appUserPermissionList.reportCenter) &&
        accessControlService.reportCenterFeatureToggle
      ) {
        <div data-test-id="ba_logbook_footer.navigate_report_tooltip">
          {{ 'LOGBOOK_TABLE.STR_ACCESS_REPORT_CENTER_TOOLTIP' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon size="inline" name="analysis" [ngClass]="{ 'prevent-click-icon': !activeEdit }"> </odx-icon>
  </button>
</div>
