<router-outlet></router-outlet>

<div class="odx-app-page__content">
  <div class="custom-odx-tab-bar odx-margin-bottom-24">
    <div class="odx-tab-bar__inner">
      <div class="odx-tab-bar__panel">
        <div class="back-btn-group ml--10">
          <button
            odxNavigationBack
            class="odx-tab-bar-item"
            (click)="incidentForm.dirty ? confirmCloseModalOpen() : navigateBack(true)"
            data-test-id="view_update_incident.back"
          ></button>
          <span class="odx-fs-normal">
            @if (previousRoute?.includes(ReportCenterModuleRoutes.reportCenter)) {
              {{ 'VIEW_UPDATE_INCIDENT.STR_INCIDENT_OVERVIEW' | translate }}
            } @else {
              @if (previousRoute?.includes(ReportCenterModuleRoutes.remoteMonitoring)) {
                {{ 'VIEW_UPDATE_INCIDENT.STR_REMOTE_MONITORING' | translate }}
              } @else if (previousRoute?.includes('login-callback')) {
                {{ 'VIEW_UPDATE_INCIDENT.STR_INCIDENT_OVERVIEW' | translate }}
              } @else {
                {{ 'VIEW_UPDATE_INCIDENT.STR_BA_LOGBOOK' | translate }}
              }
            }
          </span>
        </div>

        <div
          [ngClass]="{
            'tab-is-active': activeTab === tabs.INCIDENT_INFORMATION,
            'tab-has-errors': tabsWithErrors.includes(tabs.INCIDENT_INFORMATION),
          }"
        >
          <button
            data-test-id="view_update_incident.incident_information_tab"
            class="odx-tab-bar-item"
            odxButton
            (click)="setActiveTab(tabs.INCIDENT_INFORMATION)"
          >
            {{ 'VIEW_UPDATE_INCIDENT.STR_INCIDENT_INFORMATION_TAB' | translate }}
          </button>
        </div>

        <div
          [ngClass]="{
            'tab-is-active': activeTab === tabs.INCIDENT_ANALYSIS,
            'tab-has-errors': tabsWithErrors.includes(tabs.INCIDENT_ANALYSIS),
          }"
        >
          <button
            (click)="setActiveTab(tabs.INCIDENT_ANALYSIS)"
            data-test-id="view_update_incident.incident_analysis_tab"
            class="odx-tab-bar-item"
            odxButton
            (click)="setActiveTab(tabs.INCIDENT_ANALYSIS)"
          >
            {{ 'VIEW_UPDATE_INCIDENT.STR_INCIDENT_ANALYSIS_TAB' | translate }}
          </button>
        </div>

        <div
          [ngClass]="{
            'tab-is-active': activeTab === tabs.INCIDENT_MEDIA_ASSETS,
            'tab-has-errors': tabsWithErrors.includes(tabs.INCIDENT_MEDIA_ASSETS),
          }"
        >
          <button
            data-test-id="view_update_incident.media_and_notes_tab"
            class="odx-tab-bar-item"
            odxButton
            (click)="setActiveTab(tabs.INCIDENT_MEDIA_ASSETS)"
          >
            {{ 'VIEW_UPDATE_INCIDENT.STR_INCIDENT_MEDIA_ASSETS_TAB' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <form [formGroup]="incidentForm" *ngIf="!isLoading; else loadingIncident" (ngSubmit)="onSubmit()">
    <ignis-incident-information
      *ngIf="activeTab === tabs.INCIDENT_INFORMATION"
      [selectedIncident]="selectedIncident"
      [isSubmitted]="isSubmitted"
      [incidentForm]="incidentForm"
      [errors]="errors"
      [formatDate]="formatDate"
      [entries]="entries"
      [errors]="errors"
      [overlappingIncidentNumber]="overlappingIncidentNumber"
      [language]="language | async"
    ></ignis-incident-information>
  </form>

  <ignis-incident-analysis
    *ngIf="activeTab === tabs.INCIDENT_ANALYSIS"
    [formatDate]="formatDate"
    [incidentForm]="incidentForm"
    [activateClickOutside]="activateClickOutside"
    [hazmatIncidentType]="hazmatIncidentType"
    (getManualEventId)="getManualEventId($event)"
    (resetFormErrors)="resetFormErrors()"
  ></ignis-incident-analysis>

  <ignis-media-assets
    *ngIf="activeTab === tabs.INCIDENT_MEDIA_ASSETS"
    [formatDate]="formatDate"
    [selectedIncident]="selectedIncident"
    [incidentForm]="incidentForm"
  ></ignis-media-assets>

  <ng-template #loadingIncident>
    <ignis-progress-bar *ngIf="isLoading"></ignis-progress-bar>
  </ng-template>
</div>

<ignis-confirmation-modal
  [isOpen]="isConfirmCloseModalOpen"
  (handleConfirmationModal)="navigateBack($event)"
  class="is-closed-modal"
>
  <div slot="content">
    {{ 'CONFIRM_CLOSE_MODAL.STR_DESCRIPTION_INCIDENT' | translate }}
  </div>
</ignis-confirmation-modal>

<footer class="blue-bar">
  <ignis-view-update-incident-footer
    [dirtyForm]="incidentForm.dirty"
    [activeTab]="activeTab"
    [activeEdit]="!!selectedEventMarker?.id"
    [isSubmitDisabled]="isSubmitting | async"
    [isModalOpened]="isConfirmCloseModalOpen || openConfirmationDeleteDialog"
    (handleExportIncidentExcel)="exportExcel()"
    (handleSubmit)="onSubmit()"
    (handleCreate)="openCreateEventMarkerModal()"
    (handleEdit)="openUpdateEventMarkerModal()"
    (handleDelete)="openDeleteEventMarkerDialog()"
    (handleUploadFile)="openUploadFileModal()"
  >
  </ignis-view-update-incident-footer>
</footer>

<ignis-delete-confirmation-modal
  [isOpen]="openConfirmationDeleteDialog"
  [isLoading]="isLoading"
  (handleDeleteModal)="closeDeleteEventMarkerDialog($event)"
>
  <div slot="header">
    {{ 'DELETE_EVENT_MARKER.STR_MODAL_HEADER' | translate }}
  </div>
  <div slot="content">
    <span> {{ 'DELETE_EVENT_MARKER.STR_MODAL_CONTENT' | translate }} {{ selectedEventMarker?.name }}? </span>
    <p>
      {{ 'DELETE_EVENT_MARKER.STR_MODAL_CONTENT_IRREVERSIBLE' | translate }}
    </p>
  </div>
</ignis-delete-confirmation-modal>
