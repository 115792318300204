// eslint-disable-next-line no-shadow
export enum DeviceConnectionActionTypes {
  REQUEST_DEVICE_CONNECTION_PAGE = 'REQUEST_DEVICE_CONNECTION_PAGE',
  RECEIVE_DEVICE_CONNECTION_PAGE = 'RECEIVE_DEVICE_CONNECTION_PAGE',
  ERROR_RECEIVE_DEVICE_CONNECTION_PAGE = 'ERROR_RECEIVE_DEVICE_CONNECTION_PAGE',
  RESET_DEVICE_CONNECTION_PAGE = 'RESET_DEVICE_CONNECTION_PAGE',
  REQUEST_DEVICE_CONNECTION_EXPORT = 'REQUEST_DEVICE_CONNECTION_EXPORT',

  REQUEST_DEVICE_CONNECTION_BY_ID = 'REQUEST_DEVICE_CONNECTION_BY_ID',
  RECEIVE_DEVICE_CONNECTION_BY_ID = 'RECEIVE_DEVICE_CONNECTION_BY_ID',
  ERROR_RECEIVE_DEVICE_CONNECTION_BY_ID = 'ERROR_RECEIVE_DEVICE_CONNECTION_BY_ID',

  REQUEST_ADD_DEVICE_CONNECTION = 'REQUEST_ADD_DEVICE_CONNECTION',
  RECEIVE_ADD_DEVICE_CONNECTION = 'RECEIVE_ADD_DEVICE_CONNECTION',
  ERROR_ADD_DEVICE_CONNECTION = 'ERROR_ADD_DEVICE_CONNECTION',

  REQUEST_UPDATE_DEVICE_CONNECTION = 'REQUEST_UPDATE_DEVICE_CONNECTION',
  RECEIVE_UPDATE_DEVICE_CONNECTION = 'RECEIVE_UPDATE_DEVICE_CONNECTION',
  ERROR_UPDATE_DEVICE_CONNECTION = 'ERROR_UPDATE_DEVICE_CONNECTION',
  RESET_DEVICE_CONNECTION = 'RESET_DEVICE_CONNECTION',

  REQUEST_DEVICE_CONNECTION_IDENTIFIER = 'REQUEST_DEVICE_CONNECTION_IDENTIFIER',
  RECEIVE_DEVICE_CONNECTION_IDENTIFIER = 'RECEIVE_DEVICE_CONNECTION_IDENTIFIER',
  ERROR_RECEIVE_DEVICE_CONNECTION_IDENTIFIER = 'ERROR_RECEIVE_DEVICE_CONNECTION_IDENTIFIER',
  RESET_DEVICE_CONNECTION_IDENTIFIER = 'RESET_DEVICE_CONNECTION_IDENTIFIER',

  REQUEST_DEVICE_CONNECTION_DELETE = 'REQUEST_DEVICE_CONNECTION_DELETE',
  RECEIVE_DEVICE_CONNECTION_DELETE = 'RECEIVE_DEVICE_CONNECTION_DELETE',
  ERROR_RECEIVE_DEVICE_CONNECTION_DELETE = 'ERROR_RECEIVE_DEVICE_CONNECTION_DELETE',

  REQUEST_UPDATE_DEVICE_CONNECTION_AUTH_TOKEN = 'REQUEST_UPDATE_DEVICE_CONNECTION_AUTH_TOKEN',
  RECEIVE_UPDATE_DEVICE_CONNECTION_AUTH_TOKEN = 'RECEIVE_UPDATE_DEVICE_CONNECTION_AUTH_TOKEN',
  ERROR_RECEIVE_UPDATE_DEVICE_CONNECTION_AUTH_TOKEN = 'ERROR_RECEIVE_UPDATE_DEVICE_CONNECTION_AUTH_TOKEN',

  REQUEST_DEVICE_CONNECTION_CATEGORIES = 'REQUEST_DEVICE_CONNECTION_CATEGORIES',
  RECEIVE_DEVICE_CONNECTION_CATEGORIES = 'RECEIVE_DEVICE_CONNECTION_CATEGORIES',
  ERROR_RECEIVE_DEVICE_CONNECTION_CATEGORIES = 'ERROR_RECEIVE_DEVICE_CONNECTION_CATEGORIES',

  REQUEST_TO_CHECK_RAM_CONNECTOR_TOKEN = 'REQUEST_TO_CHECK_RAM_CONNECTOR_TOKEN',
  RECEIVE_TO_CHECK_RAM_CONNECTOR_TOKEN = 'RECEIVE_TO_CHECK_RAM_CONNECTOR_TOKEN',
  ERROR_RECEIVE_TO_CHECK_RAM_CONNECTOR_TOKEN = 'ERROR_RECEIVE_TO_CHECK_RAM_CONNECTOR_TOKEN',
  RESET_CHECK_RAM_CONNECTOR_TOKEN = 'RESET_CHECK_RAM_CONNECTOR_TOKEN',

  REQUEST_DEVICE_CONNECTION_LICENSE_INFO = 'REQUEST_DEVICE_CONNECTION_LICENSE_INFO',
  RECEIVE_DEVICE_CONNECTION_LICENSE_INFO = 'RECEIVE_DEVICE_CONNECTION_LICENSE_INFO',
  ERROR_RECEIVE_DEVICE_CONNECTION_LICENSE_INFO = 'ERROR_RECEIVE_ORGANIZATION_LICENSE_INFO',

  REQUEST_DEVICE_CONNECTION_ASSIGNED_FIRE_STATIONS = 'REQUEST_DEVICE_CONNECTION_ASSIGNED_FIRE_STATIONS',
  RECEIVE_DEVICE_CONNECTION_ASSIGNED_FIRE_STATIONS = 'RECEIVE_DEVICE_CONNECTION_ASSIGNED_FIRE_STATIONS',
  ERROR_RECEIVE_DEVICE_CONNECTION_ASSIGNED_FIRE_STATIONS = 'ERROR_RECEIVE_DEVICE_CONNECTION_ASSIGNED_FIRE_STATIONS',

  REQUEST_START_PROTECTOR_MIGRATION = 'REQUEST_START_PROTECTOR_MIGRATION',
  RECEIVE_START_PROTECTOR_MIGRATION = 'RECEIVE_START_PROTECTOR_MIGRATION',
  ERROR_RECEIVE_START_PROTECTOR_MIGRATION = 'ERROR_RECEIVE_START_PROTECTOR_MIGRATION',

  REQUEST_CANCEL_PROTECTOR_MIGRATION_IN_RAM = 'REQUEST_CANCEL_PROTECTOR_MIGRATION_IN_RAM',
  RECEIVE_CANCEL_PROTECTOR_MIGRATION_IN_RAM = 'RECEIVE_CANCEL_PROTECTOR_MIGRATION_IN_RAM',
  ERROR_RECEIVE_CANCEL_PROTECTOR_MIGRATION_IN_RAM = 'ERROR_RECEIVE_CANCEL_PROTECTOR_MIGRATION_IN_RAM',

  REQUEST_CANCEL_PROTECTOR_MIGRATION = 'REQUEST_CANCEL_PROTECTOR_MIGRATION',
  RECEIVE_CANCEL_PROTECTOR_MIGRATION = 'RECEIVE_CANCEL_PROTECTOR_MIGRATION',
  ERROR_RECEIVE_CANCEL_PROTECTOR_MIGRATION = 'ERROR_RECEIVE_CANCEL_PROTECTOR_MIGRATION',

  REQUEST_PROTECTOR_MIGRATION_STATUS = 'REQUEST_PROTECTOR_MIGRATION_STATUS',
  RECEIVE_PROTECTOR_MIGRATION_STATUS = 'RECEIVE_PROTECTOR_MIGRATION_STATUS',
  ERROR_RECEIVE_PROTECTOR_MIGRATION_STATUS = 'ERROR_RECEIVE_PROTECTOR_MIGRATION_STATUS',
  RESET_PROTECTOR_MIGRATION_STATUS = 'RESET_PROTECTOR_MIGRATION_STATUS',

  REQUEST_PROTECTOR_MIGRATION_ERROR_PAGE = 'REQUEST_PROTECTOR_MIGRATION_ERROR_PAGE',
  RECEIVE_PROTECTOR_MIGRATION_ERROR_PAGE = 'RECEIVE_PROTECTOR_MIGRATION_ERROR_PAGE',
  ERROR_RECEIVE_PROTECTOR_MIGRATION_ERROR_PAGE = 'ERROR_RECEIVE_PROTECTOR_MIGRATION_ERROR_PAGE',
  RESET_PROTECTOR_MIGRATION_ERROR_PAGE = 'RESET_PROTECTOR_MIGRATION_ERROR_PAGE',
}
