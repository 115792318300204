import { DatePipe } from '@angular/common';
import { isValid, toDate } from 'date-fns';
import { format, formatInTimeZone, toZonedTime } from 'date-fns-tz';
import { CommonConstants } from '../../constants/common.constants';

interface SplittedDuration {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const splitDuration: (duration: number) => SplittedDuration = (duration: number) => {
  return {
    days: Math.floor(duration / (3600 * 24)),
    hours: Math.floor((duration % (3600 * 24)) / 3600),
    minutes: Math.floor((duration % 3600) / 60),
    seconds: Math.floor(duration % 60),
  };
};

export const getDateWithAPIFormat: (date: Date) => string = (date: Date) => {
  if (!date || !(date instanceof Date)) return null;

  const day: string = String(date.getDate()).padStart(2, '0');
  const month: string = String(date.getMonth() + 1).padStart(2, '0');

  return date.getFullYear() + '-' + month + '-' + day;
};

export const getDateFromAPIFormat: (date: string) => Date = (date: string) => {
  if (!date) return null;

  const dateParts: Array<string> = date.split('-');

  const result: Date = new Date(dateParts[0] + '-' + dateParts[1] + '-' + dateParts[2]);

  return isNaN(result.getTime()) ? null : result;
};

export const getDateAsNgbStruct: (date: Date) => any = (date: Date) => {
  if (!date) return null;

  return { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() };
};

export const formatLocaleTime: (date: Date) => string | undefined = (date: Date) => {
  if (!date) return;

  const timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zonedDate: Date = toZonedTime(date, timeZone);

  return format(zonedDate, CommonConstants.TIME_FORMAT);
};

export const formatLocaleDate: (date: Date) => string | undefined = (date: Date) => {
  if (!date) return;

  const timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const utcDate: Date = toDate(date);

  return isValid(utcDate) ? formatInTimeZone(utcDate, timeZone, CommonConstants.DATE_TIME_FORMAT) : null;
};

export const convertToDateTimeFormat: (date: string | Date) => string | undefined = (date: string | Date) => {
  if (!date) return;

  const dateObject: Date = typeof date === 'string' ? new Date(date) : date;

  if (isNaN(dateObject.getTime())) {
    return;
  }

  return format(dateObject, CommonConstants.DATE_TIME_FORMAT);
};

export const processStartDateForTableFilter: (date: string | Date) => string = (date: string | Date) => {
  if (!date) return;

  return new Date(date).toISOString();
};

export const processEndDateForTableFilter: (date: string | Date) => string = (date: string | Date) => {
  if (!date) return;

  const dateObject: Date = new Date(date);

  dateObject.setDate(dateObject.getDate() + 1);
  dateObject.setSeconds(dateObject.getSeconds() - 1);

  return dateObject.toISOString();
};

export const convertToDateTimePrintFormat: (date: string | Date) => string | undefined = (date: string | Date) => {
  if (!date) return;

  const timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zonedDate: Date = toZonedTime(new Date(date), timeZone);
  const dateOnly: string = format(zonedDate, CommonConstants.DATE_FORMAT_PRINT_NAME);
  const timeOnly: string = format(zonedDate, CommonConstants.TIME_FORMAT_PRINT_NAME);

  return `${dateOnly}_${timeOnly}`;
};

export const extractOnlyDate: (date: string | Date) => string | undefined = (date: Date) => {
  if (!date) return undefined;

  const dateObject: Date = new Date(date);

  const localDate: Date = isValid(dateObject)
    ? toZonedTime(dateObject, Intl.DateTimeFormat().resolvedOptions().timeZone)
    : null;

  return format(localDate, CommonConstants.DATE_FORMAT);
};

export const formatOnlyDate: (date: string, formatDate: string) => string = (date: string, formatDate: string) => {
  if (!date) return;

  return new DatePipe('en-GB').transform(date, formatDate);
};

export const dateFormat: (date: string, formatDate: string) => string | undefined = (
  date: string,
  formatDate: string,
) => {
  if (!date) return;

  const dateObject: Date = new Date(date);

  if (isNaN(dateObject.getTime())) {
    return;
  }

  const formattedDateString: string = format(
    dateObject,
    typeof formatDate === 'string' ? formatDate : CommonConstants.DATE_FORMAT,
  );
  const timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zonedDate: Date = toZonedTime(dateObject, timeZone);
  const formattedTimeString: string = format(zonedDate, CommonConstants.TIME_FORMAT);

  return `${formattedDateString}, ${formattedTimeString}`;
};

export const dateFormatWithSeconds: (date: string, formatDate: string) => string | undefined = (
  date: string,
  formatDate: string,
) => {
  if (!date) return;

  const dateObject: Date = new Date(date);

  if (isNaN(dateObject.getTime())) {
    return;
  }

  const formattedDateString: string = format(dateObject, formatDate);
  const timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zonedDate: Date = toZonedTime(dateObject, timeZone);
  const formattedTimeString: string = format(zonedDate, CommonConstants.TIME_WITH_SECONDS_FORMAT);

  return `${formattedDateString}, ${formattedTimeString}`;
};

export const convertMillisecondsToMinutes: (milliseconds: number) => number = (milliseconds: number) => {
  return milliseconds / 60 / 1000;
};

export const convertMinutesToMilliseconds: (minutes: number) => number = (minutes: number) => {
  return minutes * 60 * 1000;
};

export const getTimeFromSeconds: (duration: number, hasZeroHours: boolean) => string = (
  duration: number,
  hasZeroHours: boolean,
) => {
  const { hours, minutes, seconds }: SplittedDuration = splitDuration(duration);

  const hFormatCondition: string = hours < 10 ? `0${hours}` : `${hours}`;
  const mFormatCondition: string = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const sFormatCondition: string = seconds < 10 ? `0${seconds}` : `${seconds}`;

  let hFormat: string;

  if (hasZeroHours) {
    hFormat = hours ? hFormatCondition + ':' : '00:';
  } else {
    hFormat = hours ? hFormatCondition + ':' : '';
  }

  const mFormat: string = minutes ? mFormatCondition : '00';
  const sFormat: string = seconds ? sFormatCondition : '00';

  return `${hFormat}${mFormat}:${sFormat}`;
};

export const checkDuration: (duration: number) => any = (duration: number) => {
  const { days, hours, minutes, seconds }: SplittedDuration = splitDuration(duration);

  const dDisplay: string = days > 0 ? days + 'd' : '';
  const hDisplay: string = hours > 0 ? hours + 'h' : '';
  const mDisplay: string = minutes > 0 ? minutes + 'min' : '';
  const sDisplay: string = seconds > 0 ? seconds + 'sec' : '';

  return `${dDisplay}${hDisplay ? ' ' + hDisplay : ''}${mDisplay ? ' ' + mDisplay : ''}${
    sDisplay ? ' ' + sDisplay : ''
  }`;
};

export const calculateDurationWithoutSeconds: (duration: number) => any = (duration: number) => {
  const { days, hours, minutes }: SplittedDuration = splitDuration(duration);

  const dDisplay: string = days > 0 ? days + 'd' : '';
  const hDisplay: string = hours > 0 ? hours + 'h' : '';
  const mDisplay: string = minutes >= 0 ? minutes + 'min' : '';

  return `${dDisplay}${hDisplay ? ' ' + hDisplay : ''}${mDisplay ? ' ' + mDisplay : ''}`;
};
