export interface ILocationNode {
  aggregateId: string;
  parentId: string;
  identifier: string;
  name: string;
  type: string;
  address: string;
  details: string;
  locations: string[];
  version?: number;
  root?: boolean;
}

export interface ILocationHierarchy {
  label: string;
  expanded?: boolean;
  key?: string;
  icon?: string;
  data: ILocationNode;
  children: ILocationHierarchy[];
}

export class LocationHierarchy implements ILocationHierarchy {
  label: string;
  data: ILocationNode;
  children: ILocationHierarchy[];

  constructor();
  constructor(hierarchy: ILocationHierarchy);
  constructor(hierarchy?: ILocationHierarchy) {
    if (!hierarchy) {
      this.label = null;
      this.data = null;
      this.children = null;
    } else {
      this.label = hierarchy.label;
      this.data = hierarchy.data;
      this.children = hierarchy.children;
    }
  }
}

export interface INewLocationHierarchy {
  parentAggregateId: string;
  identifier: string;
  name: string;
  type: string;
  address: string;
  details: string;
}

export class NewLocationHierarchy implements INewLocationHierarchy {
  parentAggregateId: string;
  identifier: string;
  name: string;
  type: string;
  address: string;
  details: string;

  constructor();
  constructor(location: INewLocationHierarchy);
  constructor(location?: INewLocationHierarchy) {
    if (!location) {
      this.parentAggregateId = null;
      this.identifier = null;
      this.name = null;
      this.type = null;
      this.address = null;
      this.details = null;
    } else {
      this.parentAggregateId = location.parentAggregateId;
      this.identifier = location.identifier;
      this.name = location.name;
      this.type = location.type;
      this.address = location.address;
      this.details = location.details;
    }
  }
}

export interface IEditLocationHierarchy {
  parentAggregateId: string;
  aggregateId: string;
  parentId: string;
  identifier: string;
  name: string;
  type: string;
  property: string;
  address: string;
  details: string;
  version: number;
}

export class EditLocationHierarchy implements IEditLocationHierarchy {
  parentAggregateId: string;
  aggregateId: string;
  parentId: string;
  identifier: string;
  name: string;
  type: string;
  property: string;
  address: string;
  details: string;
  version: number;

  constructor();
  constructor(location: IEditLocationHierarchy);
  constructor(location?: IEditLocationHierarchy) {
    if (!location) {
      this.parentAggregateId = null;
      this.aggregateId = null;
      this.parentId = null;
      this.identifier = null;
      this.name = null;
      this.type = null;
      this.property = null;
      this.address = null;
      this.details = null;
      this.version = null;
    } else {
      this.parentAggregateId = location.parentAggregateId;
      this.aggregateId = location.aggregateId;
      this.parentId = location.parentId;
      this.identifier = location.identifier;
      this.name = location.name;
      this.type = location.type;
      this.property = location.property;
      this.address = location.address;
      this.details = location.details;
      this.version = location.version;
    }
  }
}

export interface ILocationHierarchyChange {
  parentAggregateId: string;
  aggregateId: string;
  version: number;
}

export class LocationHierarchyChange implements ILocationHierarchyChange {
  parentAggregateId: string;
  aggregateId: string;
  version: number;

  constructor();
  constructor(location: ILocationHierarchyChange);
  constructor(location?: ILocationHierarchyChange) {
    if (!location) {
      this.parentAggregateId = null;
      this.aggregateId = null;
      this.version = null;
    } else {
      this.parentAggregateId = location.parentAggregateId;
      this.aggregateId = location.aggregateId;
      this.version = location.version;
    }
  }
}

export interface ISearchedLocation {
  aggregateId: string;
  parentId: string;
  identifier: string;
  type: string;
  property: string;
  name: string;
  address: string;
  details: string;
  locations: string[];
}

export class SearchedLocation implements ISearchedLocation {
  aggregateId: string;
  parentId: string;
  identifier: string;
  type: string;
  property: string;
  name: string;
  address: string;
  details: string;
  locations: string[];

  constructor();
  constructor(location: ISearchedLocation);
  constructor(location?: ISearchedLocation) {
    if (!location) {
      this.aggregateId = null;
      this.parentId = null;
      this.identifier = null;
      this.type = null;
      this.property = null;
      this.name = null;
      this.address = null;
      this.details = null;
      this.locations = null;
    } else {
      this.aggregateId = location.aggregateId;
      this.parentId = location.parentId;
      this.identifier = location.identifier;
      this.type = location.type;
      this.property = location.property;
      this.name = location.name;
      this.address = location.address;
      this.details = location.details;
      this.locations = location.locations;
    }
  }
}
