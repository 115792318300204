import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnChanges,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { CheckingSettingsModalsService, GenericFooterItemsComponent, PropertyBag } from 'src/app/common';
import { AccessControlService } from 'src/app/root/services/access-control/access-control.service';
import { DeviceConnectionModuleRoutes } from '../../constants/device-connection-module-routes.constants';

@Component({
  selector: 'ignis-device-connection-module-footer',
  templateUrl: './device-connection-module-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceConnectionModuleFooterComponent<T> extends GenericFooterItemsComponent<T> implements OnChanges {
  @Input() numberOfRecordsToBeExported: number;
  @Input() isSubmitDisabled: boolean = false;
  @Input() isModalOpened: boolean;

  deviceConnectionsSaveTooltip: string;
  showExportExcelTooltip: boolean = false;
  displayExportExcelBtnRoutes: string[] = ['/device-connection'];

  readonly DeviceConnectionModuleRoutes: PropertyBag = DeviceConnectionModuleRoutes;

  destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    public router: Router,
    public translateService: TranslateService,
    public accessControlService: AccessControlService,
    private checkSettingsModal: CheckingSettingsModalsService,
    public cdr: ChangeDetectorRef,
  ) {
    super();

    this.router.events
      ?.pipe(
        filter((event: NavigationEnd) => event instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.cdr.detectChanges();
      });

    this.checkSettingsModal.checkIfSettingsModalIsOpen().subscribe((isModalOpened: boolean) => {
      this.isModalOpened = isModalOpened;
      this.cdr.detectChanges();
    });
  }

  ngOnChanges(): void {
    this.deviceConnectionsSaveTooltip = this.translateService.instant('SAVE_BUTTON_TOOLTIP.STR_DEVICE_CONNECTION');
  }
}
