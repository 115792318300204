<span [ngSwitch]="option?.value" class="text-center">
  <odx-icon
    *ngSwitchCase="'motion_alarm'"
    name="motion-sensing"
    class="h3"
    data-test-id="noteworthy_filter_option.motion_alarm"
  >
  </odx-icon>
  <odx-icon
    *ngSwitchCase="'distress_button'"
    name="sos"
    class="h3"
    data-test-id="noteworthy_filter_option.distress_button"
  >
  </odx-icon>
  <odx-icon
    *ngSwitchCase="'ecb_evacuation'"
    name="evacuation-warning"
    class="h3"
    data-test-id="noteworthy_filter_option.ecb_evacuation"
  >
    <span></span>
  </odx-icon>
  <odx-icon
    *ngSwitchCase="'ecb_out_withdraw'"
    name="person-confirm"
    class="h3"
    data-test-id="noteworthy_filter_option.ecb_out_withdraw"
  >
    <span></span>
  </odx-icon>
  <odx-icon
    *ngSwitchCase="'low_pressure_1_alarm'"
    name="cylinder-error"
    class="h3"
    data-test-id="noteworthy_filter_option.low_pressure_1_alarm"
  >
    <span></span>
  </odx-icon>
  <odx-icon
    *ngSwitchCase="'low_pressure_2_alarm'"
    name="cylinder-error"
    class="h3"
    data-test-id="noteworthy_filter_option.low_pressure_2_alarm"
  >
    <span></span>
  </odx-icon>
  <odx-icon *ngSwitchCase="'manual'" name="edit" class="h3" data-test-id="noteworthy_filter_option.manual"> </odx-icon>
  <odx-icon *ngSwitchCase="'media'" name="picture" class="h3" data-test-id="noteworthy_filter_option.media"> </odx-icon>
  <odx-icon
    *ngSwitchCase="'special_exposure'"
    name="toxic"
    class="h3"
    data-test-id="noteworthy_filter_option.special_exposure"
  >
  </odx-icon>
  <span title="{{ option?.localizedName | translate }}"> {{ option?.localizedName | translate }}</span>
</span>
