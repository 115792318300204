import { IStoreApiItem } from 'src/app/common';
import { IChecklists, ISaveBatchChecklist } from 'src/app/configuration/models/checklist.model';
import { ITask } from 'src/app/configuration/models/equipment-configuration.models';
import { StoreApiItem } from '../../../common/models/store-api-item.model';
import { IStoreApiList, StoreApiList } from '../../../common/models/store-api-list.model';
import {
  ICylinderChargerRequest,
  IIntervalData,
  ILocationChange,
  IServiceTaskPage,
} from '../../models/service-task.model';

export interface IServiceTaskState {
  serviceTaskPage: IStoreApiItem<IServiceTaskPage>;
  selectedServiceEquipment: IStoreApiList<IIntervalData[]>;
  sendServiceIntervals: IStoreApiItem<ICylinderChargerRequest>;
  updateEquipmentsLocation: IStoreApiItem<ILocationChange>;
  updateEquipmentLocationDuringBarcodeSearch: IStoreApiItem<ILocationChange>;
  checklists: IStoreApiItem<IChecklists>;
  saveBatchChecklist: IStoreApiItem<ISaveBatchChecklist>;
  sendServicesChecklist: IStoreApiItem<ITask | any>;
}

export class ServiceTaskState {
  serviceTaskPage: IStoreApiItem<IServiceTaskPage> = new StoreApiItem<IServiceTaskPage>();
  selectedServiceEquipment: IStoreApiList<IIntervalData[]> = new StoreApiList<IIntervalData[]>();
  sendServiceIntervals: IStoreApiItem<ICylinderChargerRequest> = new StoreApiItem<ICylinderChargerRequest>();
  updateEquipmentsLocation: IStoreApiItem<ILocationChange> = new StoreApiItem<ILocationChange>();
  updateEquipmentLocationDuringBarcodeSearch: IStoreApiItem<ILocationChange> = new StoreApiItem<ILocationChange>();
  checklists: IStoreApiItem<IChecklists> = new StoreApiItem<IChecklists>();
  saveBatchChecklist: IStoreApiItem<ISaveBatchChecklist> = new StoreApiItem<ISaveBatchChecklist>();
  sendServicesChecklist: IStoreApiItem<ITask | any> = new StoreApiItem<ITask | any>();
}
