import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter, take } from 'rxjs';
import { IOrganizationAccessControl } from 'src/app/configuration/models/organization-settings.model';
import { selectOrganizationAccessControl } from 'src/app/configuration/state/selectors/configuration.selectors';
import { utils } from 'xlsx';
import { WorkSheet } from 'xlsx/types';
import { CommonConstants } from '../../constants/common.constants';
import { IOKTAProfile, PropertyBag } from '../../models/common.model';
import { IStoreApiItem } from '../../models/store-api-item.model';
import { ApplicationState } from '../../state/models/app.state.model';
import { dateFormat } from '../../utils/date-utils/date.utils';

@Injectable({
  providedIn: 'root',
})
export class ExportTableExcelService {
  jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(private translateService: TranslateService) {}

  translateKeys(entity: any, objectKeys: PropertyBag): any {
    // eslint-disable-next-line @typescript-eslint/typedef
    return Object.entries(entity).reduce((op: any, [key, value]) => {
      const newKey: string = objectKeys[key];

      op[(newKey?.includes('STR') ? this.translateService.instant(newKey) : newKey) || key] = value;

      return op;
    }, {});
  }

  formatExcelDataToLocal(entityDate: string, formatDate: string): string {
    if (!entityDate) {
      return '';
    }

    return dateFormat(new Date(entityDate).toString(), formatDate);
  }

  insertMetaInformationHeader(store: Store<ApplicationState>, worksheet: WorkSheet, formatDate: string): void {
    store
      .select(selectOrganizationAccessControl)
      .pipe(
        filter(
          (orgProfile: IStoreApiItem<IOrganizationAccessControl>) =>
            Boolean(orgProfile) && !orgProfile.errors && !orgProfile.isLoading && !!orgProfile.data,
        ),
        take(1),
      )
      .subscribe((orgProfile: IStoreApiItem<IOrganizationAccessControl>) => {
        const organizationName: string = orgProfile.data.organizationSettings.name;

        const user: Partial<IOKTAProfile> = this.jwtHelper.decodeToken(
          localStorage.getItem(CommonConstants.AUTH_TOKEN),
        );

        utils.sheet_add_aoa(
          worksheet,
          [
            [[this.translateService.instant('EXCEL_TABLE_EXPORT.STR_ORGANIZATION_NAME')], [organizationName]],
            [
              [this.translateService.instant('EXCEL_TABLE_EXPORT.STR_EXPORT_DATE_AND_TIME')],
              [dateFormat(new Date().toString(), formatDate)],
            ],
            [
              [this.translateService.instant('EXCEL_TABLE_EXPORT.STR_NAME_OF_THE_CURRENT_USER')],
              [`${user?.lastname}, ${user?.firstname}`],
            ],
          ],
          { origin: 'A1' },
        );
      });
  }
}
