import { Injectable } from '@angular/core';
import { CommonConstants } from 'src/app/common';
import { NotificationsService } from 'src/app/common/services';
import { INotificationMessage } from 'src/app/common/state/notifications/models/notification.model';
import { ProtectorSoftwareNotificationConstants } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class RAMConnectorErrorsService {
  constructor(private notificationsService: NotificationsService) {}

  processProtectorSoftwareErrorMsg(errors: number | string): void {
    const errorCode: INotificationMessage = this.getCustomErrorMessage(errors?.toString());

    this.notificationsService.requestShowNotification(
      CommonConstants.notificationType.ERROR,
      errorCode,
      ProtectorSoftwareNotificationConstants.notificationCodes,
    );
  }

  getCustomErrorMessage(errorCode: string): INotificationMessage {
    return (
      ProtectorSoftwareNotificationConstants.ramCustomErrors[errorCode] ||
      ProtectorSoftwareNotificationConstants.notificationCodes.PROTECTOR_SOFTWARE_UNHANDLED_ERROR
    );
  }
}
