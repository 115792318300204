import { IStoreApiItem, StoreApiItem } from 'src/app/common/models';

export interface ITableExport {
  currentPage: number;
  entries: any[];
  pageSize: number;
  totalPages: number;
  totalRecords: number;
}
export interface ITableExportState {
  tableExportData: IStoreApiItem<ITableExport>;
  exportInProgress: IStoreApiItem<boolean>;
}

export class TableExportState implements ITableExportState {
  tableExportData: IStoreApiItem<ITableExport> = new StoreApiItem();
  exportInProgress: IStoreApiItem<boolean> = new StoreApiItem();
}
