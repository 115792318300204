import { Injectable, NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterModule, RouterStateSnapshot, Routes, TitleStrategy } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OktaAuthGuard } from '@okta/okta-angular';
import { delay, filter } from 'rxjs';
import { BaWearerModuleGuard } from 'src/app/ba-wearer/modules/guards/ba-wearer/ba-wearer.module.guard';
import { IApplicationState, IStoreApiList } from 'src/app/common';
import { ConfigurationModuleGuard } from 'src/app/configuration/modules/configuration.module.guard';
import { DeviceConnectionModuleGuard } from 'src/app/device-connection/modules/device-connection.module.guard';
import { LogbookModuleGuard } from 'src/app/logbook/modules/logbook.module.guard';
import { RemoteMonitoringModuleGuard } from 'src/app/remote-monitoring/modules/remote-monitoring.module.guard';
import { ReportCenterModuleGuard } from 'src/app/report-center/modules/report-center.module.guard';
import { RoleManagementModuleGuard } from 'src/app/role-management/modules/role-management.module.guard';
import { UserManagementModuleGuard } from 'src/app/user-management/modules/user-management.module.guard';
import { WorkshopModuleGuard } from 'src/app/workshop/modules/guards/workshop/workshop.module.guard';
import { AppModuleRoutes } from '../constants/app-routes.constants';
import { selectProductName } from '../state/product/selectors/product-name.selector';
import { DashboardComponent } from './../components/dashboard/dashboard.component';
import { GenericErrorPageComponent } from './../components/generic-error-page/generic-error-page.component';
import { LoginCallbackComponent } from './../components/login-callback/login-callback.component';

/* istanbul ignore next */
export const routes: Routes = [
  {
    path: AppModuleRoutes.workshopModule,
    canActivate: [OktaAuthGuard, WorkshopModuleGuard],
    title: 'NAVBAR.STR_TITLE_WORKSHOP',
    loadChildren: () =>
      import('../../workshop/modules/workshop.module').then(
        (m: typeof import('../../workshop/modules/workshop.module')) => m.WorkshopModule,
      ),
  },
  {
    path: AppModuleRoutes.logbookModule,
    canActivate: [OktaAuthGuard, LogbookModuleGuard],
    title: 'NAVBAR.STR_TITLE_LOGBOOK',
    loadChildren: () =>
      import('../../logbook/modules/logbook.module').then(
        (m: typeof import('../../logbook/modules/logbook.module')) => m.LogbookModule,
      ),
  },
  {
    path: AppModuleRoutes.reportCenterModule,
    canActivate: [OktaAuthGuard, ReportCenterModuleGuard],
    title: 'NAVBAR.STR_TITLE_REPORT_CENTER',
    loadChildren: () =>
      import('../../report-center/modules/report-center.module').then(
        (m: typeof import('../../report-center/modules/report-center.module')) => m.ReportCenterModule,
      ),
  },
  {
    path: AppModuleRoutes.userManagementModule,
    canActivate: [OktaAuthGuard, UserManagementModuleGuard],
    title: 'NAVBAR.STR_TITLE_USER_MANAGEMENT',
    loadChildren: () =>
      import('../../user-management/modules/user-management.module').then(
        (m: typeof import('../../user-management/modules/user-management.module')) => m.UserManagementModule,
      ),
  },
  {
    path: AppModuleRoutes.roleManagementModule,
    canActivate: [OktaAuthGuard, RoleManagementModuleGuard],
    title: 'NAVBAR.STR_TITLE_ROLE_MANAGEMENT',
    loadChildren: () =>
      import('../../role-management/modules/role-management.module').then(
        (m: typeof import('../../role-management/modules/role-management.module')) => m.RoleManagementModule,
      ),
  },
  {
    path: AppModuleRoutes.remoteMonitoringModule,
    canActivate: [OktaAuthGuard, RemoteMonitoringModuleGuard],
    title: 'NAVBAR.STR_TITLE_REMOTE_MONITORING',
    loadChildren: () =>
      import('../../remote-monitoring/modules/remote-monitoring.module').then(
        (m: typeof import('../../remote-monitoring/modules/remote-monitoring.module')) => m.RemoteMonitoringModule,
      ),
  },
  {
    path: AppModuleRoutes.deviceConnectionModule,
    canActivate: [OktaAuthGuard, DeviceConnectionModuleGuard],
    title: 'NAVBAR.STR_TITLE_DEVICE_CONNECTION',
    loadChildren: () =>
      import('../../device-connection/modules/device-connection-management.module').then(
        (m: typeof import('../../device-connection/modules/device-connection-management.module')) =>
          m.DeviceConnectionManagementModule,
      ),
  },
  {
    path: AppModuleRoutes.configurationModule,
    canActivate: [OktaAuthGuard, ConfigurationModuleGuard],
    title: 'NAVBAR.STR_TITLE_CONFIGURATION',
    loadChildren: () =>
      import('../../configuration/modules/configuration.module').then(
        (m: typeof import('../../configuration/modules/configuration.module')) => m.ConfigurationModule,
      ),
  },
  {
    path: AppModuleRoutes.baWearer,
    canActivate: [OktaAuthGuard, BaWearerModuleGuard],
    title: 'NAVBAR.STR_TITLE_BA_WEARER',
    loadChildren: () =>
      import('../../ba-wearer/modules/ba-wearer.module').then(
        (m: typeof import('../../ba-wearer/modules/ba-wearer.module')) => m.BAWearerModule,
      ),
  },
  {
    path: AppModuleRoutes.loginCallback,
    component: LoginCallbackComponent,
    canActivate: [OktaAuthGuard],
    title: '',
  },
  {
    path: '',
    component: DashboardComponent,
    pathMatch: 'full',
    title: 'NAVBAR.STR_TITLE_EMPTY',
  },
  {
    path: AppModuleRoutes.internalServerError,
    component: GenericErrorPageComponent,
    pathMatch: 'full',
    title: 'NAVBAR.STR_INTERNAL_SERVER_ERROR',
  },
  { path: '**', redirectTo: '', title: 'NAVBAR.STR_TITLE_EMPTY' },
];

const isIframe: boolean = window !== window.parent && !window.opener;

/* istanbul ignore next */
@Injectable()
export class TemplatePageTitleStrategy extends TitleStrategy {
  constructor(
    private readonly title: Title,
    private store: Store<IApplicationState>,
    private translateService: TranslateService,
  ) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title: any = this.buildTitle(routerState);

    this.store
      .pipe(
        select(selectProductName),
        filter((state: IStoreApiList<any[]>) => !state.isLoading),
        delay(750),
      )
      .subscribe((state: IStoreApiList<any>) => {
        if (state.data) {
          const productName: string = `${state.data?.productName}`;

          sessionStorage.setItem('productName', productName ? productName : 'Dräger');

          if (Object.values(this.translateService.store.translations).length > 0 && title) {
            const pageTitle: string = `${sessionStorage.getItem('productName')} ${this.translateService.instant(
              title,
            )}`;

            this.title.setTitle(pageTitle);
            sessionStorage.setItem('productName', pageTitle);
          } else {
            this.title.setTitle(productName);
          }
        }
      });
  }
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes
      initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled',
      useHash: false,
      canceledNavigationResolution: 'computed',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
