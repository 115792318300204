@if (hyperLink) {
  <div class="legal-terms-page" data-test-id="legal_terms.legal_page">
    <odx-card variant="default" class="legal-terms-form">
      <odx-card-content>
        <form [formGroup]="legalTermsForm" (ngSubmit)="submitLegal()">
          @if (!dpaMode) {
            <h1 class="form-title" [attr.data-test-id]="gdprMode ? 'legal_terms.gdpr_title' : 'legal_terms.tac_title'">
              {{ gdprMode ? ('LEGAL_TERMS.STR_GDPR_TITLE' | translate) : ('LEGAL_TERMS.STR_TAC_TITLE' | translate) }}
            </h1>
          } @else {
            <h1 class="form-title" data-test-id="legal_terms.dpa_title">
              {{ 'LEGAL_TERMS.STR_DPA_TITLE' | translate }}
            </h1>
          }

          <label class="legal-terms-checkbox" [ngClass]="{ 'change-terms-checkbox-position': dpaMode }" for="accepted">
            <odx-checkbox ngDefaultControl formControlName="accepted" id="accepted" data-test-id="legal_terms.checkbox">
              @if (!dpaMode) {
                <span [attr.data-test-id]="gdprMode ? 'legal_terms.gdpr_text' : 'legal_terms.tac_text'">
                  {{
                    gdprMode
                      ? ('LEGAL_TERMS.STR_GDPR_CHECKBOX_LABEL' | translate)
                      : ('LEGAL_TERMS.STR_TAC_CHECKBOX_LABEL' | translate)
                  }}
                </span>
              } @else {
                <span data-test-id="legal_terms.dpa_text"
                  >{{ 'LEGAL_TERMS.STR_DPA_CHECKBOX_FIRST_LABEL' | translate }}
                </span>
              }
            </odx-checkbox>
          </label>

          @if (dpaMode) {
            <span [ngClass]="{ 'mb-4': dpaMode }">
              {{ 'LEGAL_TERMS.STR_DPA_CHECKBOX_SECOND_LABEL' | translate }}
            </span>
          }

          <a odxLink [href]="hyperLink" target="_blank" data-test-id="legal_terms.link">
            @if (!dpaMode) {
              <span [attr.data-test-id]="gdprMode ? 'legal_terms.gdpr_link' : 'legal_terms.tac_link'">
                {{
                  gdprMode
                    ? ('LEGAL_TERMS.STR_GDPR_LINK_TEXT' | translate)
                    : ('LEGAL_TERMS.STR_TAC_LINK_TEXT' | translate)
                }}
              </span>
            } @else {
              <span data-test-id="legal_terms.dpa_link"> {{ 'LEGAL_TERMS.STR_DPA_LINK_TEXT' | translate }} </span>
            }
          </a>

          <button
            odxButton
            variant="highlight"
            [disabled]="!legalTermsForm.get('accepted').value"
            type="submit"
            class="legal-terms-submit-btn"
            data-test-id="legal_terms.ok_btn"
          >
            {{ 'LEGAL_TERMS.STR_OK_BTN' | translate }}
          </button>
        </form>
      </odx-card-content>
    </odx-card>
  </div>
}
