import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalRef, ModalService, ModalSize, ModalVariant } from '@odx/angular/components/modal';
import { format, subWeeks } from 'date-fns';
import { CommonConstants, StorageConstants } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { getDateAsNgbStruct } from 'src/app/common/utils/date-utils/date.utils';

@Component({
  selector: 'ignis-prefilter',
  templateUrl: './prefilter.component.html',
  styleUrls: ['./prefilter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrefilterComponent extends OnDestroyMixin() implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('prefilterModal', { read: TemplateRef })
  public prefilterModal: TemplateRef<any>;
  modalRef: ModalRef | any;
  @Input() openModal: boolean;
  @Input() logBookLength: number;
  @Input() isPreFilterSet: boolean;
  displayBackBtn: boolean = true;
  goBackIsPressed: boolean = false;
  prefilterForm: FormGroup = new FormGroup({
    equipmentId: new FormControl(null),
    name: new FormControl(null),
    date: new FormControl(),
  });

  preFilterKey: string = StorageConstants.tablesStorageKeys.BA_LOGBOOK_PRE_FILTER;
  selectedDateRange: string;
  fromDate: any;
  toDate: any;
  isBASerialInputValueValid: boolean = true;
  isUserNameInputValueValid: boolean = true;
  @Input() formatDate: string;

  @Output() handleProceed: EventEmitter<void> = new EventEmitter();
  @Output() handleClosePrefilter: EventEmitter<boolean> = new EventEmitter();
  @Output() minDate: EventEmitter<string | Date> = new EventEmitter();
  @Output() maxDate: EventEmitter<string | Date> = new EventEmitter();
  @Output() handlePrefilterFormValues: EventEmitter<{ equipmentId: string; name: string; dateRange: Date[] }> =
    new EventEmitter();

  constructor(
    private readonly modalService: ModalService,
    private router: Router,
  ) {
    super();
  }

  @HostListener(CommonConstants.beforeUnloadWindowEvent, ['$event'])
  handleBeforeUnload($event: any): void {
    if (this.modalRef?.data === 'isOpen' && this.prefilterForm.dirty) {
      $event.returnValue = this.prefilterForm.dirty;
    }
  }

  ngOnInit(): void {
    this.checkFormFields();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.openModal && this.openModal) {
      this.checkFormFields();
    }

    if (changes.logBookLength && this.logBookLength > 0) {
      this.displayBackBtn = false;
    }

    if (changes.formatDate && this.formatDate) {
      this.changeDateOperator();
    }
  }

  ngAfterViewInit(): void {
    if (this.openModal) {
      this.openPreFilterModal();
    }
  }

  changeDateOperator(): void {
    // eslint-disable-next-line no-prototype-builtins
    if (localStorage.hasOwnProperty(this.preFilterKey)) {
      let dateFormatOperator: string = '/';

      if (this.formatDate && this.formatDate.includes('.')) {
        dateFormatOperator = '.';
      } else if (this.formatDate && this.formatDate.includes('-')) {
        dateFormatOperator = '-';
      }

      const storedPrefilterFormValues: any = JSON.parse(localStorage.getItem(this.preFilterKey));

      let newDateFormat: string;

      if (storedPrefilterFormValues.date && storedPrefilterFormValues.date?.includes('/')) {
        newDateFormat = storedPrefilterFormValues.date.replaceAll('/', dateFormatOperator);
      }

      if (storedPrefilterFormValues.date && storedPrefilterFormValues.date?.includes('.')) {
        newDateFormat = storedPrefilterFormValues.date.replaceAll('.', dateFormatOperator);
      }

      if (
        storedPrefilterFormValues.date &&
        storedPrefilterFormValues.date.split(CommonConstants.splitDateRangeRegex)[0].includes('-')
      ) {
        const dateRangeToArr: any = storedPrefilterFormValues.date.split(CommonConstants.splitDateRangeRegex);

        const date1: string = dateRangeToArr[0].replaceAll('-', dateFormatOperator);
        const date2: string = dateRangeToArr[1].replaceAll('-', dateFormatOperator);

        newDateFormat = `${date1} - ${date2}`;
      }

      storedPrefilterFormValues.date = newDateFormat;

      localStorage.setItem(this.preFilterKey, JSON.stringify(storedPrefilterFormValues));
    }
  }

  checkFormFields(): void {
    let splitOperator: string = '/';

    if (this.formatDate && this.formatDate.includes('.')) {
      splitOperator = '.';
    }

    // eslint-disable-next-line no-prototype-builtins
    if (localStorage.hasOwnProperty(this.preFilterKey)) {
      const storedPrefilterFormValues: any = JSON.parse(localStorage.getItem(this.preFilterKey));
      const storedDateRange: string = JSON.parse(localStorage.getItem(this.preFilterKey)).date;

      this.selectedDateRange = storedPrefilterFormValues.date;

      this.prefilterForm.controls.equipmentId.setValue(storedPrefilterFormValues.equipmentId);
      this.prefilterForm.controls.name.setValue(storedPrefilterFormValues.name);
      this.prefilterForm.controls.date.setValue(this.selectedDateRange);

      if (storedDateRange) {
        this.fromDate = getDateAsNgbStruct(
          new Date(
            `${storedDateRange.split('-')[0].split(splitOperator)[1]}/${
              storedDateRange.split('-')[0].split(splitOperator)[0]
            }/${storedDateRange.split('-')[0].split(splitOperator)[2]}`,
          ),
        );

        this.toDate = getDateAsNgbStruct(
          new Date(
            `${storedDateRange.split('-')[1].split(splitOperator)[1]}/${
              storedDateRange.split('-')[1].split(splitOperator)[0]
            }/${storedDateRange.split('-')[1].split(splitOperator)[2]}`,
          ),
        );
      }
    } else {
      this.fromDate = getDateAsNgbStruct(new Date(format(subWeeks(new Date(), 1), 'MM/dd/yyyy')));
      this.toDate = getDateAsNgbStruct(new Date());

      this.selectedDateRange = `${this.fromDate.day}${splitOperator}${this.fromDate.month < 10 ? '0' : ''}${
        this.fromDate.month
      }${splitOperator}${this.fromDate.year} - ${this.toDate.day}${splitOperator}${this.toDate.month < 10 ? '0' : ''}${
        this.toDate.month
      }${splitOperator}${this.toDate.year}`;

      this.prefilterForm.controls.date.setValue(this.selectedDateRange);
      localStorage.setItem(this.preFilterKey, JSON.stringify(this.prefilterForm.value));
    }
  }

  openPreFilterModal(): void {
    this.modalRef = this.modalService.open(this.prefilterModal, {
      size: ModalSize.MEDIUM,
      variant: ModalVariant.DEFAULT,
      dismissable: false,
      data: 'isOpen',
    });
  }

  onDateSelect(event: any): void {
    let splitOperator: string = '/';

    this.fromDate = getDateAsNgbStruct(new Date(`${event[0]}`));
    this.toDate = getDateAsNgbStruct(new Date(`${event[1]}`));

    if (this.formatDate && this.formatDate.includes('.')) {
      splitOperator = '.';
    } else if (this.formatDate && this.formatDate.includes('-')) {
      splitOperator = '-';
    }

    if (event[0] && event[1]) {
      this.selectedDateRange = `${this.fromDate.day}${splitOperator}${this.fromDate.month}${splitOperator}${this.fromDate.year} - ${this.toDate.day}${splitOperator}${this.toDate.month}${splitOperator}${this.toDate.year}`;

      this.prefilterForm.controls.date.setValue(this.selectedDateRange);
    }
  }

  onDateChange(event: any): void {
    if (event.length < 1) {
      this.selectedDateRange = null;
    } else {
      this.selectedDateRange = event;
    }

    this.prefilterForm.controls.date.setValue(this.selectedDateRange);
  }

  proceedPrefilter(): void {
    localStorage.setItem(this.preFilterKey, JSON.stringify(this.prefilterForm.value));

    if (this.prefilterForm.value.date) {
      const fromDate: any = this.prefilterForm.value.date.split(' - ')[0];
      const toDate: any = this.prefilterForm.value.date.split(' - ')[1];
      const separator: string = fromDate?.toString().match(/\D/)[0];
      const fromDateArray: any = fromDate?.toString().split(separator);
      const toDateArray: any = toDate?.toString().split(separator);
      const startDate: string = `${fromDateArray[2]}-${fromDateArray[1]}-${fromDateArray[0]}`;
      const endDate: string = `${toDateArray[2]}-${toDateArray[1]}-${toDateArray[0]}`;

      this.minDate.emit(startDate);
      this.maxDate.emit(endDate);
      this.handlePrefilterFormValues.emit({
        equipmentId: this.prefilterForm.value.equipmentId?.trimStart()?.trimEnd(),
        name: this.prefilterForm.value.name?.trimStart()?.trimEnd(),
        dateRange: [new Date(startDate.replace(/-/g, '/')), new Date(endDate.replace(/-/g, '/'))],
      });
    } else {
      this.minDate.emit(null);
      this.maxDate.emit(null);
      this.handlePrefilterFormValues.emit({
        equipmentId: this.prefilterForm.value.equipmentId?.trimStart()?.trimEnd(),
        name: this.prefilterForm.value.name?.trimStart()?.trimEnd(),
        dateRange: [null, null],
      });
    }

    this.handleProceed.emit();
    Object.defineProperty(this.modalRef, 'data', { value: 'isClosed', writable: false });
    this.handleClosePrefilter.emit(false);
  }

  goBack(): void {
    this.router.navigate(['']);
    this.goBackIsPressed = true;
  }

  cancelPrefilter(): void {
    this.handleClosePrefilter.emit(false);
    this.goBackIsPressed = true;
  }

  resetFilterParams(): void {
    this.prefilterForm.reset();
    this.onDateChange('');
    localStorage.removeItem(this.preFilterKey);
    this.proceedPrefilter();
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();

    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (this.modalRef && !this.goBackIsPressed) {
      this.modalRef.close();
    }
  }
}
