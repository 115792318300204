import { combineReducers } from 'redux';
import { remoteMonitoringEntriesReducer } from './entries/entries.reducer';
import { remoteMonitoringFilterReducer } from './filter/filter.reducer';
import { remoteMonitoringIncidentReducer } from './incident/incident.reducer';
import { remoteMonitoringIncidentsMergeReducer } from './merging/merging.reducer';
import { remoteMonitoringNotesReducer } from './notes/notes.reducer';

export const remoteMonitoringReducer: any = combineReducers<{
  incidentsMerge: typeof remoteMonitoringIncidentsMergeReducer;
  entries: typeof remoteMonitoringEntriesReducer;
  incident: typeof remoteMonitoringIncidentReducer;
  filter: typeof remoteMonitoringFilterReducer;
  notes: typeof remoteMonitoringNotesReducer;
}>({
  incidentsMerge: remoteMonitoringIncidentsMergeReducer,
  entries: remoteMonitoringEntriesReducer,
  incident: remoteMonitoringIncidentReducer,
  filter: remoteMonitoringFilterReducer,
  notes: remoteMonitoringNotesReducer,
});
