import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IUserRole } from 'src/app/user-management/models/user.model';
import { UserActionTypes } from '../user-action-types';

export class RequestUserRoleList implements Action {
  readonly type: UserActionTypes.REQUEST_USER_ROLE_LIST = UserActionTypes.REQUEST_USER_ROLE_LIST;
}
export class ReceiveUserRoleList implements Action {
  readonly type: UserActionTypes.RECEIVE_USER_ROLE_LIST = UserActionTypes.RECEIVE_USER_ROLE_LIST;

  constructor(public payload: IUserRole[]) { }
}
export class ErrorReceiveUserRoleList implements Action {
  readonly type: UserActionTypes.ERROR_RECEIVE_USER_ROLE_LIST = UserActionTypes.ERROR_RECEIVE_USER_ROLE_LIST;

  constructor(public payload: HttpErrorResponse) { }
}
