import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IBAWearerPage } from 'src/app/ba-wearer/models';
import { BAWearerActionTypes } from '../ba-wearer-action-types';

export class RequestBAWearerPage implements Action {
  readonly type: BAWearerActionTypes.REQUEST_BA_WEARER_PAGE = BAWearerActionTypes.REQUEST_BA_WEARER_PAGE;

  constructor(public payload?: any) {}
}
export class ReceiveBAWearerPage implements Action {
  readonly type: BAWearerActionTypes.RECEIVE_BA_WEARER_PAGE = BAWearerActionTypes.RECEIVE_BA_WEARER_PAGE;

  constructor(public payload: IBAWearerPage) {}
}
export class ErrorReceiveBAWearerPage implements Action {
  readonly type: BAWearerActionTypes.ERROR_RECEIVE_BA_WEARER_PAGE = BAWearerActionTypes.ERROR_RECEIVE_BA_WEARER_PAGE;

  constructor(public payload: HttpErrorResponse) {}
}
