import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IEditTask, INewTask, IUpdateTaskChildren } from 'src/app/configuration/models';
import { TasksActionTypes } from './tasks-action-types';

export class RequestAddTask implements Action {
  readonly type: TasksActionTypes.REQUEST_ADD_TASK = TasksActionTypes.REQUEST_ADD_TASK;

  constructor(public payload: INewTask) {}
}
export class ReceiveAddTask implements Action {
  readonly type: TasksActionTypes.RECEIVE_ADD_TASK = TasksActionTypes.RECEIVE_ADD_TASK;

  constructor(public payload: INewTask) {}
}
export class ErrorReceiveAddTask implements Action {
  readonly type: TasksActionTypes.ERROR_RECEIVE_ADD_TASK = TasksActionTypes.ERROR_RECEIVE_ADD_TASK;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestEditTask implements Action {
  readonly type: TasksActionTypes.REQUEST_EDIT_TASK = TasksActionTypes.REQUEST_EDIT_TASK;

  constructor(public payload: IEditTask) {}
}
export class ReceiveEditTask implements Action {
  readonly type: TasksActionTypes.RECEIVE_EDIT_TASK = TasksActionTypes.RECEIVE_EDIT_TASK;

  constructor(public payload: IEditTask) {}
}
export class ErrorReceiveEditTask implements Action {
  readonly type: TasksActionTypes.ERROR_RECEIVE_EDIT_TASK = TasksActionTypes.ERROR_RECEIVE_EDIT_TASK;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestUpdateTaskChildren implements Action {
  readonly type: TasksActionTypes.REQUEST_UPDATE_TASK_CHILDREN = TasksActionTypes.REQUEST_UPDATE_TASK_CHILDREN;

  constructor(public payload: IUpdateTaskChildren) {}
}
export class ReceiveUpdateTaskChildren implements Action {
  readonly type: TasksActionTypes.RECEIVE_UPDATE_TASK_CHILDREN = TasksActionTypes.RECEIVE_UPDATE_TASK_CHILDREN;

  constructor(public payload: IUpdateTaskChildren) {}
}
export class ErrorReceiveUpdateTaskChildren implements Action {
  readonly type: TasksActionTypes.ERROR_RECEIVE_UPDATE_TASK_CHILDREN =
    TasksActionTypes.ERROR_RECEIVE_UPDATE_TASK_CHILDREN;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestDeleteTask implements Action {
  readonly type: TasksActionTypes.REQUEST_DELETE_TASK = TasksActionTypes.REQUEST_DELETE_TASK;

  constructor(public payload: { urlPath: string; version: number }) {}
}
export class ReceiveDeleteTask implements Action {
  readonly type: TasksActionTypes.RECEIVE_DELETE_TASK = TasksActionTypes.RECEIVE_DELETE_TASK;

  constructor(public payload: any) {}
}
export class ErrorReceiveDeleteTask implements Action {
  readonly type: TasksActionTypes.ERROR_RECEIVE_DELETE_TASK = TasksActionTypes.ERROR_RECEIVE_DELETE_TASK;

  constructor(public payload: HttpErrorResponse) {}
}
