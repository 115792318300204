import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CardModule } from '@odx/angular/components/card';
import { ClickOutsideModule } from '@solidexpert/ng-click-outside';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { SimplebarAngularModule } from 'simplebar-angular';
import { TeamStatusTranslationComponent } from 'src/app/common/components/translations/team-status-translation/team-status-translation.component';
import { CommonModule } from 'src/app/common/modules/common.module';
import { ConfirmMergeRemoteIncidentsComponent } from '../components/confirm-merge-remote-incidents/confirm-merge-remote-incidents.component';
import { ImageAudioComponent } from '../components/incident-overview/notes-widget/notes-list/image-audio/image-audio.component';
import { NotesListComponent } from '../components/incident-overview/notes-widget/notes-list/notes-list.component';
import { NotesWidgetComponent } from '../components/incident-overview/notes-widget/notes-widget.component';
import { RemoteEcpMonitoringComponent } from '../components/incident-overview/remote-ecp-monitoring/remote-ecp-monitoring.component';
import { TeamFirefighterComponent } from '../components/incident-overview/remote-ecp-monitoring/team-tile/team-firefighter/team-firefighter.component';
import { TeamTileComponent } from '../components/incident-overview/remote-ecp-monitoring/team-tile/team-tile.component';
import { ManualRemoveRemoteIncidentsComponent } from '../components/manual-remove-remote-incident/manual-remove-remote-incident.component';
import { MergeRemoteIncidentsComponent } from '../components/merge-remote-incidents/merge-remote-incidents.component';
import { FilterIncidentsComponent } from '../components/remote-monitoring-grid/filter-incidents/filter-incidents.component';
import { IncidentStatisticsComponent } from '../components/remote-monitoring-grid/incident-statistics/incident-statistics.component';
import { IncidentTileComponent } from '../components/remote-monitoring-grid/incidents-grid/incident-tile/incident-tile.component';
import { IncidentsGridComponent } from '../components/remote-monitoring-grid/incidents-grid/incidents-grid.component';
import { RemoteMonitoringGridComponent } from '../components/remote-monitoring-grid/remote-monitoring-grid.component';
import { RemoteMonitoringModuleFooterComponent } from '../components/remote-monitoring-module-footer/remote-monitoring-module-footer.component';
import { remoteMonitoringReducer } from '../state/reducers/remote-monitoring.reducer';
import { IncidentOverviewComponent } from './../components/incident-overview/incident-overview.component';
import { EcpStatisticsComponent } from './../components/incident-overview/remote-ecp-monitoring/ecp-statistics/ecp-statistics.component';
import { RemoteMonitoringContainerComponent } from './../components/remote-monitoring-container/remote-monitoring-container.component';
import { RemoteMonitoringService } from './../services/remote-monitoring.service';
import { RemoteMonitoringEffects } from './../state/effects/remote-monitoring.effects';
import { RemoteMonitoringRoutingModule } from './remote-monitoring-routing.module';

@NgModule({
  declarations: [
    RemoteMonitoringContainerComponent,
    RemoteMonitoringGridComponent,
    IncidentStatisticsComponent,
    IncidentsGridComponent,
    IncidentOverviewComponent,
    IncidentTileComponent,
    NotesWidgetComponent,
    NotesListComponent,
    RemoteEcpMonitoringComponent,
    EcpStatisticsComponent,
    TeamTileComponent,
    TeamStatusTranslationComponent,
    FilterIncidentsComponent,
    MergeRemoteIncidentsComponent,
    ManualRemoveRemoteIncidentsComponent,
    ConfirmMergeRemoteIncidentsComponent,
    TeamFirefighterComponent,
    ImageAudioComponent,
    RemoteMonitoringModuleFooterComponent,
  ],

  imports: [
    RemoteMonitoringRoutingModule,
    StoreModule.forFeature('remoteMonitoring', remoteMonitoringReducer),
    EffectsModule.forFeature([RemoteMonitoringEffects]),
    CommonModule,
    TableModule,
    DropdownModule,
    InputTextModule,
    MultiSelectModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    FormsModule,
    SimplebarAngularModule,
    InputNumberModule,
    SliderModule,
    CheckboxModule,
    ClickOutsideModule,
    CardModule,
  ],
  providers: [RemoteMonitoringService, provideHttpClient(withInterceptorsFromDi())],
})
export class RemoteMonitoringModule {}
