<form [formGroup]="closeIncidentForm" odxForm>
  <ng-template odxModal #closeRemoteIncidentModal>
    <odx-modal-header>
      <odx-area-header>
        {{ 'REMOVE_REMOTE_INCIDENT.STR_CLOSE_INCIDENT' | translate }}
      </odx-area-header>
    </odx-modal-header>
    <odx-modal-content [ngStyle]="{ overflow: 'visible' }">
      <section odxLayout="grid">
        <div *ngIf="isLoading; else formContent" class="loading-centered">
          <div [odxLoadingSpinner]="true" [odxLoadingSpinnerMinHeight]="60" class="app-loader"></div>
        </div>

        <ng-template #formContent>
          <p odxLayout="12">
            {{ 'REMOVE_REMOTE_INCIDENT.STR_CLOSE_INCIDENT_MODAL_DESCRIPTION' | translate }}
          </p>

          <div odxLayout="12 12@phone">
            <odx-form-field
              variant="full"
              label=" {{ 'REMOVE_REMOTE_INCIDENT.STR_INCIDENT_END_TIME' | translate }}"
              class="custom-datepicker-form-field"
            >
              <div
                class="w-100"
                [odxTooltip]="!closeIncidentForm.get('endTime').invalid ? null : incorrectDateTooltipContent"
                data-test-id="remove_remote_incident.date_error_wrap"
              >
                @if (toggleDatePicker) {
                  <ignis-datepicker
                    formControlName="endTime"
                    ngDefaultControl
                    [selectionMode]="'single'"
                    [formatDate]="formatDate"
                    data-test-id="remove_remote_incident.date"
                    [showTime]="true"
                    [showSeconds]="true"
                    [minDate]="minDate"
                    [defaultRange]="formatLocaleDate(closeIncidentForm.get('endTime').value)"
                    (dateSelect)="onEndTimeSelect($event)"
                    [changeToPreviousValue]="true"
                    [clearable]="false"
                  >
                  </ignis-datepicker>
                }
              </div>
            </odx-form-field>
            <ng-template #incorrectDateTooltipContent>
              <div data-test-id="remove_remote_incident.date_error_tooltip">
                {{ 'EQUIPMENT_GENERAL.STR_GENERAL_FORM_INCORRECT_DATE' | translate }}
              </div>
            </ng-template>
          </div>
        </ng-template>
      </section>
    </odx-modal-content>
    <odx-modal-footer>
      <button odxButton odxModalClose variant="secondary" (click)="close()" data-test-id="modal_actions.no_btn">
        {{ 'REMOVE_REMOTE_INCIDENT.STR_NO_BTN' | translate }}
      </button>

      <button odxButton variant="primary" (click)="proceedManualRemove()" data-test-id="modal_actions.yes_btn">
        {{ 'REMOVE_REMOTE_INCIDENT.STR_YES_BTN' | translate }}
      </button>
    </odx-modal-footer>
  </ng-template>
</form>
