import { IStoreApiItem, StoreApiItem } from 'src/app/common/models/store-api-item.model';
import { StoreApiList } from 'src/app/common/models/store-api-list.model';
import { IFireStationList } from '../../../common';
import {
  IDeviceConnection,
  IDeviceConnectionToken,
  IDevicePage,
  IMigrationID,
} from '../../models/device-connection.model';
import { IProtectorMigrationErrorPage, IProtectorMigrationStatus } from '../../models/protector-migration.model';
import { IStoreApiList } from './../../../common/models/store-api-list.model';
import { IDeviceConnectionLicenseInfo } from './../../models/device-connection.model';

export interface IDeviceConnectionState {
  deviceConnectionData: IDeviceConnectionDataState;
  deviceConnectionLicenseInfo: IDeviceConnectionLicenseInfoState;
  ramConnectorData: IRAMConnectorDataState;
  protectorSoftwareMigration: IProtectorSoftwareMigrationState;
}

export class DeviceConnectionState implements IDeviceConnectionState {
  deviceConnectionData: IDeviceConnectionDataState = new DeviceConnectionDataState();
  deviceConnectionLicenseInfo: IDeviceConnectionLicenseInfoState = new DeviceConnectionLicenseInfoState();
  ramConnectorData: IRAMConnectorDataState = new RAMConnectorDataState();
  protectorSoftwareMigration: IProtectorSoftwareMigrationState = new ProtectorSoftwareMirationState();
}

export interface IDeviceConnectionDataState {
  devicePage: IStoreApiItem<IDevicePage>;
  selectedDeviceConnection: IStoreApiItem<IDeviceConnection>;
  deviceConnectionAuthToken: IStoreApiItem<any>;
  deviceConnectionIdentifier: IStoreApiItem<IDeviceConnectionToken>;
  deletedDeviceConnection: IStoreApiItem<IDeviceConnection>;
  deviceConnectionCategories: IStoreApiList<any>;
  deviceConnectionAssignedFireStations: IStoreApiList<IFireStationList>;
}

export class DeviceConnectionDataState implements IDeviceConnectionDataState {
  devicePage: IStoreApiItem<IDevicePage> = new StoreApiItem();
  selectedDeviceConnection: IStoreApiItem<IDeviceConnection> = new StoreApiItem();
  deviceConnectionAuthToken: IStoreApiItem<any> = new StoreApiItem();
  deviceConnectionIdentifier: IStoreApiItem<IDeviceConnectionToken> = new StoreApiItem();
  deletedDeviceConnection: IStoreApiItem<IDeviceConnection> = new StoreApiItem();
  deviceConnectionCategories: IStoreApiList<any> = new StoreApiList();
  deviceConnectionAssignedFireStations: IStoreApiList<IFireStationList> = new StoreApiList();
}

export interface IRAMConnectorDataState {
  checkRAMConnectorToken: IStoreApiItem<any>;
}

export class RAMConnectorDataState implements IRAMConnectorDataState {
  checkRAMConnectorToken: IStoreApiItem<any> = new StoreApiItem();
}

export interface IDeviceConnectionLicenseInfoState {
  licenseInfo: IStoreApiItem<IDeviceConnectionLicenseInfo>;
}

export class DeviceConnectionLicenseInfoState implements IDeviceConnectionLicenseInfoState {
  licenseInfo: IStoreApiItem<IDeviceConnectionLicenseInfo> = new StoreApiItem();
}

export interface IProtectorSoftwareMigrationState {
  startProtectorMigration: IStoreApiItem<IMigrationID>;
  cancelProtectorMigrationInRAM: IStoreApiItem<unknown>;
  cancelProtectorMigration: IStoreApiItem<unknown>;
  protectorMigrationStatus: IStoreApiItem<IProtectorMigrationStatus>;
  protectorMigrationErrorPage: IStoreApiItem<IProtectorMigrationErrorPage>;
}

export class ProtectorSoftwareMirationState implements IProtectorSoftwareMigrationState {
  startProtectorMigration: IStoreApiItem<IMigrationID> = new StoreApiItem();
  cancelProtectorMigrationInRAM: IStoreApiItem<unknown> = new StoreApiItem();
  cancelProtectorMigration: IStoreApiItem<unknown> = new StoreApiItem();
  protectorMigrationStatus: IStoreApiItem<IProtectorMigrationStatus> = new StoreApiItem();
  protectorMigrationErrorPage: IStoreApiItem<IProtectorMigrationErrorPage> = new StoreApiItem();
}
