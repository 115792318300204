import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { IStoreApiItem } from 'src/app/common/models';
import { IApplicationState } from '../../models/app.state.model';
import { ITableExport, ITableExportState } from '../models/export.model';

/* istanbul ignore next */
export const getApplicationState = (state: IApplicationState): ITableExportState => state.exportTable;

export const selectTableExportResponse: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<ITableExport>,
  DefaultProjectorFn<IStoreApiItem<ITableExport>>
> = createSelector(getApplicationState, (state: ITableExportState) => state.tableExportData);

export const selectTableExportInProgress: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<boolean>,
  DefaultProjectorFn<IStoreApiItem<boolean>>
> = createSelector(getApplicationState, (state: ITableExportState) => state.exportInProgress);
