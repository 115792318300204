import { GenericAction } from 'src/app/common';
import { errorListData, receiveListData, requestListData } from 'src/app/common/utils/reducer/reducer.utils';
import { ProductActionType } from '../actions/product-action-types';
import { IProductState, ProductState } from '../models/product.model';

export function productReducer(
  lastState: IProductState = new ProductState(),
  action: GenericAction<ProductActionType, any>
): IProductState {

  switch (action.type) {
    case ProductActionType.REQUEST_PRODUCT_NAME:
      return requestListData<IProductState, any[]>(lastState, ['productName']);

    case ProductActionType.RECEIVE_PRODUCT_NAME:
      return receiveListData<IProductState, any[]>(lastState, ['productName'], action.payload);

    case ProductActionType.ERROR_RECEIVE_PRODUCT_NAME:
      return errorListData<IProductState, any[]>(lastState, ['productName'], action.payload);

    default:
      return lastState;
  }
}
