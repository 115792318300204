export interface IOrganizationEntries {
  profiles: string[];
  languages: string[];
  dateFormats: string[];
  orientations: string[];
  timeDisplays: string[];
  displayUnits: string[];
  cylinderPressures: string[];
}

export class OrganizationEntries implements IOrganizationEntries {
  profiles: string[];
  languages: string[];
  dateFormats: string[];
  orientations: string[];
  timeDisplays: string[];
  displayUnits: string[];
  cylinderPressures: string[];

  constructor();
  constructor(entries: IOrganizationEntries);
  constructor(entries?: IOrganizationEntries) {
    if (!entries) {
      this.profiles = null;
      this.languages = null;
      this.dateFormats = null;
      this.orientations = null;
      this.timeDisplays = null;
      this.displayUnits = null;
      this.cylinderPressures = null;
    } else {
      this.profiles = entries.profiles;
      this.languages = entries.languages;
      this.dateFormats = entries.dateFormats;
      this.orientations = entries.orientations;
      this.timeDisplays = entries.timeDisplays;
      this.displayUnits = entries.displayUnits;
      this.cylinderPressures = entries.cylinderPressures;
    }
  }
}

export interface IOrganizationSettings {
  name: string;
  language: string;
  dateFormat: string;
}

export class OrganizationSettings implements IOrganizationSettings {
  name: string;
  language: string;
  dateFormat: string;

  constructor();
  constructor(organizationSettings: IOrganizationSettings);
  constructor(organizationSettings?: IOrganizationSettings) {
    if (!organizationSettings) {
      this.name = null;
      this.language = null;
      this.dateFormat = null;
    } else {
      this.name = organizationSettings.name;
      this.language = organizationSettings.language;
      this.dateFormat = organizationSettings.dateFormat;
    }
  }
}

export interface IProfile {
  name: string;
  defaultTimeDisplay: string;
  displayUnit: string;
  defaultCylinderPressure: string;
  defaultTTWMinutes: number;
  consumptionRate: number;
  fwDv7: boolean;
  mayday: boolean;
  lowPressureThreshold: number;
  mediumPressureThreshold: number;
  isSelected: boolean;
}

export class Profile implements IProfile {
  name: string;
  defaultTimeDisplay: string;
  displayUnit: string;
  defaultCylinderPressure: string;
  defaultTTWMinutes: number;
  consumptionRate: number;
  fwDv7: boolean;
  mayday: boolean;
  lowPressureThreshold: number;
  mediumPressureThreshold: number;
  isSelected: boolean;

  constructor();
  constructor(profile: IProfile);
  constructor(profile?: IProfile) {
    if (!profile) {
      this.name = null;
      this.defaultTimeDisplay = null;
      this.displayUnit = null;
      this.defaultCylinderPressure = null;
      this.defaultTTWMinutes = null;
      this.consumptionRate = null;
      this.fwDv7 = null;
      this.mayday = null;
      this.lowPressureThreshold = null;
      this.mediumPressureThreshold = null;
      this.isSelected = null;
    } else {
      this.name = profile.name;
      this.defaultTimeDisplay = profile.defaultTimeDisplay;
      this.displayUnit = profile.displayUnit;
      this.defaultCylinderPressure = profile.defaultCylinderPressure;
      this.defaultTTWMinutes = profile.defaultTTWMinutes;
      this.consumptionRate = profile.consumptionRate;
      this.fwDv7 = profile.fwDv7;
      this.mayday = profile.mayday;
      this.lowPressureThreshold = profile.lowPressureThreshold;
      this.mediumPressureThreshold = profile.mediumPressureThreshold;
      this.isSelected = profile.isSelected;
    }
  }
}

export interface IFiregroundSettings {
  profiles: IProfile[]
}

export interface IRAMSettings {
  cleaningWorkflow: boolean;
  cylinderChargingWorkflow: boolean;
  automaticTestingWorkflow: boolean;
  checklistWorkflow: boolean;
}

export class RAMSettings implements IRAMSettings {
  cleaningWorkflow: boolean;
  cylinderChargingWorkflow: boolean;
  automaticTestingWorkflow: boolean;
  checklistWorkflow: boolean;

  constructor();
  constructor(ramSettings: IRAMSettings);
  constructor(ramSettings?: IRAMSettings) {
    if (!ramSettings) {
      this.cleaningWorkflow = null;
      this.cylinderChargingWorkflow = null;
      this.automaticTestingWorkflow = null;
      this.checklistWorkflow = null;
    } else {
      this.cleaningWorkflow = ramSettings.cleaningWorkflow;
      this.cylinderChargingWorkflow = ramSettings.cylinderChargingWorkflow;
      this.automaticTestingWorkflow = ramSettings.automaticTestingWorkflow;
      this.checklistWorkflow = ramSettings.checklistWorkflow;
    }
  }
}

export interface IOrganizationAccessControl {
  organizationSettings: IOrganizationSettings;
  fireGroundSettings: IFiregroundSettings;
  ramSettings: IRAMSettings;
  version?: number;
}

export class OrganizationAccessControl implements IOrganizationAccessControl {
  organizationSettings: IOrganizationSettings;
  fireGroundSettings: IFiregroundSettings;
  ramSettings: IRAMSettings
  version: number;

  constructor();
  constructor(organizationAccessControl: IOrganizationAccessControl);
  constructor(organizationAccessControl?: IOrganizationAccessControl) {
    if (!organizationAccessControl) {
      this.organizationSettings = null;
      this.fireGroundSettings = null;
      this.ramSettings = null;
      this.version = null;
    } else {
      this.organizationSettings = organizationAccessControl.organizationSettings;
      this.fireGroundSettings = organizationAccessControl.fireGroundSettings;
      this.ramSettings = organizationAccessControl.ramSettings;
      this.version = organizationAccessControl.version;
    }
  }

}

export interface IOrganizationLicenseInfo {
  productName: string;
  ticketId: string;
  label?: string;
}

export class OrganizationLicenseInfo implements IOrganizationLicenseInfo {
  productName: string;
  ticketId: string;
  label?: string;

  constructor();
  constructor(licenseInfo: IOrganizationLicenseInfo);
  constructor(licenseInfo?: IOrganizationLicenseInfo) {
    if (!licenseInfo) {
      this.productName = null;
      this.ticketId = null;
      this.label = null;
    } else {
      this.productName = licenseInfo.productName;
      this.ticketId = licenseInfo.ticketId;
      this.label = licenseInfo.label;
    }
  }
}
