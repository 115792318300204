import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CommonConstants } from '../constants/common.constants';
import { getDecimalNumberFormatUserProfile } from '../utils/settings-utils/settings-utils';

@Directive({
  selector: '[ignisCustomDigitDecimalNumber]',
  standalone: true,
})
export class CustomDigitDecimalNumberDirective {
  @Input() ignisCustomDigitDecimalNumber: string;
  @Input() directiveFormData: FormGroup;

  regex: RegExp = CommonConstants.numericWithOptionalDecimal;
  decimalNumberFormat: string;

  constructor(
    private el: ElementRef,
    private translationService: TranslateService,
  ) {
    this.decimalNumberFormat = getDecimalNumberFormatUserProfile(this.translationService);
  }

  @HostListener('input', ['$event'])
  onInput(): void {
    if (this.decimalNumberFormat === ',') {
      this.regex = CommonConstants.numericWithOptionalCommaDecimal;
    }

    const inputValue: string = this.el.nativeElement.value;
    const isValid: boolean = this.regex.test(inputValue);

    if (!isValid) {
      this.directiveFormData.get(this.ignisCustomDigitDecimalNumber).patchValue(inputValue.slice(0, -1));
    }
  }
}
