import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CheckingSettingsModalsService, GenericFooterItemsComponent } from 'src/app/common';
import { PropertyBag } from 'src/app/common/models/common.model';
import { ReportCenterModuleRoutes } from 'src/app/report-center/constants/report-center-module-routes.constants';
import { ReportCenterConstants } from 'src/app/report-center/constants/report-center.constants';

@Component({
  selector: 'ignis-view-update-incident-footer',
  templateUrl: './view-update-incident-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewUpdateIncidentFooterComponent<T> extends GenericFooterItemsComponent<T> {
  @Input() isModalOpened: boolean;
  @Input() isSubmitDisabled: boolean = false;

  @Output() handleExportIncidentExcel: EventEmitter<void> = new EventEmitter();
  @Output() handleUploadFile: EventEmitter<void> = new EventEmitter();

  showAddButtonTooltip: boolean = false;
  showEditButtonTooltip: boolean = false;
  showDeleteButtonTooltip: boolean = false;
  showDownloadIncidentButtonTooltip: boolean = false;
  readonly ReportCenterModuleRoutes: PropertyBag = ReportCenterModuleRoutes;

  incidentTabs: PropertyBag = ReportCenterConstants.viewEditModalTabs;

  constructor(
    private checkSettingsModal: CheckingSettingsModalsService,
    public router: Router,
  ) {
    super();

    this.checkSettingsModal.checkIfSettingsModalIsOpen().subscribe((isModalOpened: boolean) => {
      this.isModalOpened = isModalOpened;
      this.cdr.detectChanges();
    });
  }

  onIncidentExportClick(e: Event): void {
    e.stopPropagation();
    this.handleExportIncidentExcel.emit();
  }

  onUploadFileClick(e: Event): void {
    e.stopPropagation();
    this.handleUploadFile.emit();
  }
}
