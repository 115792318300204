import { ITableDateFilter, ITableDropdownFilter } from '../models/table.model';

export class EquipmentListTableFiltersConstants {
  public static readonly selectedDropdownsFilterValues: ITableDropdownFilter[] = [
    { key: 'typeId', value: 'types' },
    { key: 'model', value: 'models' },
    { key: 'operationalStatus', value: 'operationalStatus' },
    { key: 'serviceStatus', value: 'serviceStatus' },
  ];

  public static readonly selectedDatesFilterValues: ITableDateFilter[] = [
    { key: 'outOfService', startDate: 'outOfServiceStartDate', endDate: 'outOfServiceEndDate' },
    { key: 'firstRegisteredDate', startDate: 'firstRegisteredStartDate', endDate: 'firstRegisteredEndDate' },
  ];
}

export class EquipmentTasksTableFiltersConstants {
  public static readonly selectedDropdownsFilterValues: ITableDropdownFilter[] = [
    { key: 'operationalStatus', value: 'operationalStatus' },
  ];

  public static readonly selectedDatesFilterValues: ITableDateFilter[] = [
    { key: 'nextServiceDate', startDate: 'nextServiceDateStartDate', endDate: 'nextServiceDateEndDate' },
    { key: 'lastServiceDate', startDate: 'lastServiceDateStartDate', endDate: 'lastServiceDateEndDate' },
  ];
}

export class EquipmentTaskResultsTableFiltersConstants {
  public static readonly selectedDropdownsFilterValues: ITableDropdownFilter[] = [
    { key: 'operationalStatus', value: 'operationalStatus' },
  ];

  public static readonly selectedDatesFilterValues: ITableDateFilter[] = [
    { key: 'dateTime', startDate: 'startTime', endDate: 'endTime' },
  ];
}

export class AddressBookTableFiltersConstants {
  public static readonly selectedDropdownsFilterValues: ITableDropdownFilter[] = [{ key: 'types', value: 'types' }];
}

export class DeviceConnectionTableFiltersConstants {
  public static readonly selectedDropdownsFilterValues: ITableDropdownFilter[] = [
    { key: 'category.value', value: 'category' },
    { key: 'assignedFireStation', value: 'assignedFireStation' },
  ];

  public static readonly selectedDatesFilterValues: ITableDateFilter[] = [
    { key: 'displayedRegistration', startDate: 'minRegistrationTimestamp', endDate: 'maxRegistrationTimestamp' },
    { key: 'displayedLastUpload', startDate: 'minLastUploadTimestamp', endDate: 'maxLastUploadTimestamp' },
  ];
}

export class BAWearerTableFiltersConstants {
  public static readonly selectedDatesFilterValues: ITableDateFilter[] = [
    { key: 'displayLastUpdate', startDate: 'startLastUpdate', endDate: 'endLastUpdate' },
  ];
}
