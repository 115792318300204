import { createSelector, DefaultProjectorFn, MemoizedSelector, Selector } from '@ngrx/store';
import { IApplicationState, IStoreApiItem, IStoreApiList } from 'src/app/common';
import { IAddressBook, IAddressBookPage } from '../../models/address-book.model';
import {
  IEditEquipmentHierarchy,
  IEditTask,
  IEquipmentConfiguration,
  IEquipmentHierarchy,
  INewTask,
  IUpdateTaskChildren,
} from '../../models/equipment-configuration.models';
import {
  IOrganizationAccessControl,
  IOrganizationEntries,
  IOrganizationLicenseInfo,
} from '../../models/organization-settings.model';
import { IChecklist } from './../../models/checklist.model';
import {
  IEditLocationHierarchy,
  ILocationHierarchy,
  ILocationHierarchyChange,
  SearchedLocation,
} from './../../models/location-configuration.model';
import { IConfigurationState } from './../models/configuration.models';

export const selectConfigurationState: Selector<IApplicationState, IConfigurationState> = (state: IApplicationState) =>
  state.configuration;

export const selectEquipmentHierarchy: MemoizedSelector<
  IApplicationState,
  IStoreApiList<IEquipmentHierarchy[]>,
  DefaultProjectorFn<IStoreApiList<IEquipmentHierarchy[]>>
> = createSelector(selectConfigurationState, (state: IConfigurationState) => state.equipmentHierarchy.hierarchy);

export const selectAddedEquipmentHierarchy: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IEquipmentHierarchy>,
  DefaultProjectorFn<IStoreApiItem<IEquipmentHierarchy>>
> = createSelector(selectConfigurationState, (state: IConfigurationState) => state.equipmentHierarchy.newHierarchy);

export const selectProtectorTypeValues: MemoizedSelector<
  IApplicationState,
  IStoreApiList<any>,
  DefaultProjectorFn<IStoreApiList<any>>
> = createSelector(selectConfigurationState, (state: IConfigurationState) => state.protectorTypeValues.values);

export const selectAddedTask: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<INewTask>,
  DefaultProjectorFn<IStoreApiItem<INewTask>>
> = createSelector(selectConfigurationState, (state: IConfigurationState) => state.tasks.newTask);

export const selectUpdatedTaskChildren: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IUpdateTaskChildren>,
  DefaultProjectorFn<IStoreApiItem<IUpdateTaskChildren>>
> = createSelector(selectConfigurationState, (state: IConfigurationState) => state.tasks.updateTaskChildren);

export const selectedEquipmentConfiguration: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IEquipmentConfiguration>,
  DefaultProjectorFn<IStoreApiItem<IEquipmentConfiguration>>
> = createSelector(
  selectConfigurationState,
  (state: IConfigurationState) => state.equipmentHierarchy.equipmentConfiguration,
);

export const selectEditedEquipmentHierarchy: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IEditEquipmentHierarchy>,
  DefaultProjectorFn<IStoreApiItem<IEditEquipmentHierarchy>>
> = createSelector(selectConfigurationState, (state: IConfigurationState) => state.equipmentHierarchy.editedHierarchy);

export const selectDeletedEquipmentHierarchy: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<any>,
  DefaultProjectorFn<IStoreApiItem<any>>
> = createSelector(selectConfigurationState, (state: IConfigurationState) => state.equipmentHierarchy.deletedHierarchy);

export const selectImportEquipmentHierarchy: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<unknown>,
  DefaultProjectorFn<IStoreApiItem<unknown>>
> = createSelector(
  selectConfigurationState,
  (state: IConfigurationState) => state.equipmentHierarchy.importEquipmentHierarchy,
);

export const selectEditedTask: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IEditTask>,
  DefaultProjectorFn<IStoreApiItem<IEditTask>>
> = createSelector(selectConfigurationState, (state: IConfigurationState) => state.tasks.editedTask);

export const selectDeletedTask: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<any>,
  DefaultProjectorFn<IStoreApiItem<any>>
> = createSelector(selectConfigurationState, (state: IConfigurationState) => state.tasks.deletedTask);

export const selectLocationHierarchy: MemoizedSelector<
  IApplicationState,
  IStoreApiList<ILocationHierarchy[]>,
  DefaultProjectorFn<IStoreApiList<ILocationHierarchy[]>>
> = createSelector(selectConfigurationState, (state: IConfigurationState) => state.locationHierarchy.hierarchy);

export const selectAddedLocationHierarchy: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<ILocationHierarchy>,
  DefaultProjectorFn<IStoreApiItem<ILocationHierarchy>>
> = createSelector(selectConfigurationState, (state: IConfigurationState) => state.locationHierarchy.newHierarchy);

export const selectLocationHierarchyChange: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<ILocationHierarchyChange>,
  DefaultProjectorFn<IStoreApiItem<ILocationHierarchyChange>>
> = createSelector(selectConfigurationState, (state: IConfigurationState) => state.locationHierarchy.changeHierarchy);

export const selectLocationTypeValues: MemoizedSelector<
  IApplicationState,
  IStoreApiList<any>,
  DefaultProjectorFn<IStoreApiList<any>>
> = createSelector(selectConfigurationState, (state: IConfigurationState) => state.locationTypeValues.values);

export const selectedLocationConfiguration: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IEditLocationHierarchy>,
  DefaultProjectorFn<IStoreApiItem<IEditLocationHierarchy>>
> = createSelector(
  selectConfigurationState,
  (state: IConfigurationState) => state.locationHierarchy.locationConfiguration,
);

export const selectEditedLocationHierarchy: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IEditLocationHierarchy>,
  DefaultProjectorFn<IStoreApiItem<IEditLocationHierarchy>>
> = createSelector(selectConfigurationState, (state: IConfigurationState) => state.locationHierarchy.editedHierarchy);

export const selectDeletedLocationHierarchy: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<any>,
  DefaultProjectorFn<IStoreApiItem<any>>
> = createSelector(selectConfigurationState, (state: IConfigurationState) => state.locationHierarchy.deletedHierarchy);

export const selectedNewChecklist: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IChecklist>,
  DefaultProjectorFn<IStoreApiItem<IChecklist>>
> = createSelector(selectConfigurationState, (state: IConfigurationState) => state.checklist.newChecklist);

export const selectAddressBookPage: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IAddressBookPage>,
  DefaultProjectorFn<IStoreApiItem<IAddressBookPage>>
> = createSelector(selectConfigurationState, (state: IConfigurationState) => state.addressBook.addressBookList);

export const selectNewAddressBook: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IAddressBook>,
  DefaultProjectorFn<IStoreApiItem<IAddressBook>>
> = createSelector(selectConfigurationState, (state: IConfigurationState) => state.addressBook.newAddressBook);

export const selectAddressBookById: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IAddressBook>,
  DefaultProjectorFn<IStoreApiItem<IAddressBook>>
> = createSelector(selectConfigurationState, (state: IConfigurationState) => state.addressBook.addressBookById);

export const selectEditAddressBook: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IAddressBook>,
  DefaultProjectorFn<IStoreApiItem<IAddressBook>>
> = createSelector(selectConfigurationState, (state: IConfigurationState) => state.addressBook.editAddressBook);

export const selectDeleteAddressBook: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IAddressBook>,
  DefaultProjectorFn<IStoreApiItem<IAddressBook>>
> = createSelector(selectConfigurationState, (state: IConfigurationState) => state.addressBook.deleteAddressBook);

export const selectedEditedChecklist: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IChecklist>,
  DefaultProjectorFn<IStoreApiItem<IChecklist>>
> = createSelector(selectConfigurationState, (state: IConfigurationState) => state.checklist.editedChecklist);

export const selectedDeleteChecklist: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<Partial<IChecklist>>,
  DefaultProjectorFn<IStoreApiItem<Partial<IChecklist>>>
> = createSelector(selectConfigurationState, (state: IConfigurationState) => state.checklist.deletedChecklist);

export const selectSearchedLocation: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<SearchedLocation>,
  DefaultProjectorFn<IStoreApiItem<SearchedLocation>>
> = createSelector(selectConfigurationState, (state: IConfigurationState) => state.locationHierarchy.searchedLocation);

export const selectOrganizationEntries: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IOrganizationEntries>,
  DefaultProjectorFn<IStoreApiItem<IOrganizationEntries>>
> = createSelector(
  selectConfigurationState,
  (state: IConfigurationState) => state.organizationSettings.organizationEntries.entries,
);

export const selectOrganizationAccessControl: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IOrganizationAccessControl>,
  DefaultProjectorFn<IStoreApiItem<IOrganizationAccessControl>>
> = createSelector(
  selectConfigurationState,
  (state: IConfigurationState) => state.organizationSettings.organizationAccessControl.organizationAccessControl,
);

export const selectUpdatedOrganizationAccessControl: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IOrganizationAccessControl>,
  DefaultProjectorFn<IStoreApiItem<IOrganizationAccessControl>>
> = createSelector(
  selectConfigurationState,
  (state: IConfigurationState) => state.organizationSettings.organizationAccessControl.updateOrganizationAccessControl,
);

export const selectOrganizationLicenseInfo: MemoizedSelector<
  IApplicationState,
  IStoreApiList<IOrganizationLicenseInfo[]>,
  DefaultProjectorFn<IStoreApiList<IOrganizationLicenseInfo[]>>
> = createSelector(
  selectConfigurationState,
  (state: IConfigurationState) => state.organizationSettings.organizationEntries.licenseInfo,
);
