<ignis-progress-bar *ngIf="isLoading | async"></ignis-progress-bar>

@if (!displayImportBtn) {
  <ignis-eq-hierarchy-filter
    [hierarchyData]="hierarchyData"
    (emitFilteredHierarchyData)="displayFiteredData($event)"
  ></ignis-eq-hierarchy-filter>
}

<div class="d-flex">
  @if (hierarchyData[0]?.children?.length < 1 && displayImportBtn) {
    <h2>{{ 'CONFIGURATION.STR_NO_RECORDS_FOUND' | translate }}</h2>
  }
  <p-tree
    class="configuration-tree equipment-tree"
    [ngClass]="currentAppTheme"
    [value]="hierarchyData"
    data-test-id="equipment_hierarchy.tree"
    selectionMode="single"
    layout="horizontal"
    [(selection)]="selectedTreeNode"
    (onNodeSelect)="nodeSelect($event); toggleTreeHeader()"
    (onNodeUnselect)="nodeUnselect($event); toggleTreeHeader()"
  >
    <ng-template pTemplate="header">
      @if (hierarchyData[0]?.children?.length > 0 && !displayImportBtn) {
        <div class="hierarchy-header">
          <h2 class="mb-3">{{ 'CONFIGURATION.STR_CATEGORY' | translate }}</h2>
          @if (displayTypeLabel) {
            <h2 class="mb-3">{{ 'CONFIGURATION.STR_TYPE' | translate }}</h2>
          }
          @if (displayModelLabel) {
            <h2 class="mb-3">{{ 'CONFIGURATION.STR_MODEL' | translate }}</h2>
          }
        </div>
      }
    </ng-template>
    <ng-template let-node pTemplate="default" (clickOutside)="removeSelectedNode()">
      <span class="p-tree-toggler" [ngClass]="{ 'tree-toggle-disabled': node.children?.length < 1 }">
        <odx-icon [iconSet]="'core'" [name]="node.expanded ? 'chevron-down' : 'chevron-right'"></odx-icon>
      </span>

      <span
        [title]="node.label"
        class="node-label"
        [ngClass]="{ 'active-node-border': node.borderHighlighted || node.highlighted }"
        >{{ node.label }}</span
      >
    </ng-template>
  </p-tree>

  <button
    odxButton
    variant="secondary"
    class="import-eq-hierarchy-btn"
    data-test-id="configuration.import_eq_hierarchy_btn"
    *ngIf="displayImportBtn && !(isLoading | async)"
    (click)="openImportEquipmentHierarchyModal()"
  >
    {{ 'CONFIGURATION.STR_IMPORT_EQUIPMENT_HIERARCHY' | translate }}
  </button>
</div>
<footer class="blue-bar">
  <ignis-equipment-hierarchy-view-footer
    [disableAddBtn]="disableAddBtn"
    [selectedItem]="selectedTreeNode"
    [isModalOpened]="openConfirmationDeleteDialog"
    (handleCreate)="createNewConfiguration()"
    (handleEdit)="editConfiguration()"
    (handleDelete)="openDeleteEquipmentHierarchyDialog()"
  >
  </ignis-equipment-hierarchy-view-footer>
</footer>

<ignis-delete-confirmation-modal
  [isOpen]="openConfirmationDeleteDialog"
  [isLoading]="isLoading | async"
  (handleDeleteModal)="closeDeleteEquipmentHierarchyDialog($event)"
>
  <div slot="header">
    {{ 'DELETE_CONFIGURATION.STR_MODAL_HEADER' | translate }}
  </div>
  <div slot="content">
    <div [ngSwitch]="equipmentConfigType">
      <span *ngSwitchCase="configType.CATEGORY">
        {{ 'DELETE_CONFIGURATION.STR_MODAL_CONTENT_FOR_CATEGORY' | translate }}
      </span>
      <span *ngSwitchCase="configType.TYPE">
        {{ 'DELETE_CONFIGURATION.STR_MODAL_CONTENT_FOR_TYPE' | translate }}
      </span>
      <span *ngSwitchCase="configType.MODEL">
        {{ 'DELETE_CONFIGURATION.STR_MODAL_CONTENT_FOR_MODEL' | translate }}
      </span>
    </div>
  </div>
</ignis-delete-confirmation-modal>

<ignis-import-equipment-hierarchy-modal
  [isOpen]="openImportEQHierarchyModal"
  (closeModal)="closeImportEquipmentHierarchyModal($event)"
></ignis-import-equipment-hierarchy-modal>
