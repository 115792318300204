import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CheckingSettingsModalsService, GenericFooterItemsComponent } from 'src/app/common';

@Component({
  selector: 'ignis-report-center-list-view-footer',
  templateUrl: './report-center-list-view-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportCenterListViewFooterComponent<T> extends GenericFooterItemsComponent<T> {
  @Input() numberOfRecordsToBeExported: number;
  @Input() isModalOpened: boolean;
  @Input() mergeMode: boolean = false;

  @Output() handleMergeIncidents: EventEmitter<void> = new EventEmitter();
  @Output() handleExcelExport: EventEmitter<void> = new EventEmitter();
  @Output() handTableToExcelFile: EventEmitter<void> = new EventEmitter();

  openIncidentReport: boolean = false;

  constructor(private checkSettingsModal: CheckingSettingsModalsService) {
    super();

    this.checkSettingsModal.checkIfSettingsModalIsOpen().subscribe((isModalOpened: boolean) => {
      this.isModalOpened = isModalOpened;
      this.cdr.detectChanges();
    });
  }

  onMergeIncidentsClick(e: Event): void {
    e.stopPropagation();
    this.handleMergeIncidents.emit();
  }

  onExportExcelClick(e: Event): void {
    e.stopPropagation();
    this.handleExcelExport.emit();
  }

  onExportDataTableToExcel(e: Event): void {
    e.stopPropagation();
    this.handTableToExcelFile.emit();
  }
}
