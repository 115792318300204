<odx-card
  variant="default"
  class="incident-tile"
  (click)="selectIncident(incident?.aggregateId, incident)"
  (dblclick)="
    incident?.ended ? navigateToReportCenter(incident?.reportId) : incidentOverviewNavigate(incident?.aggregateId)
  "
  (clickOutside)="!isModalOpened ? onClickedOutside(selectedIncidentIds?.includes(incident?.aggregateId)) : ''"
  [attachOutsideOnClick]="true"
  data-test-id="remote_monitoring.incident_tile"
  [ngClass]="{
    'finished-incident-body-color': incident?.ended,
    'selected-incident-body': selectedIncidentIds?.includes(incident?.aggregateId) && selectedIncident,
  }"
>
  <div class="tile-header">
    <span [ngSwitch]="incident?.type?.value">
      <odx-icon *ngSwitchDefault size="inline" name="fire" data-test-id="remote_monitoring.fire_icon"></odx-icon>
      <odx-icon
        *ngSwitchCase="'dwelling'"
        size="inline"
        name="practice"
        data-test-id="remote_monitoring.dwelling_icon"
      ></odx-icon>
      <odx-icon
        *ngSwitchCase="'other_buildings'"
        size="inline"
        name="practice"
        data-test-id="remote_monitoring.other_buildings_icon"
      ></odx-icon>
      <odx-icon
        *ngSwitchCase="'road_vehicles'"
        size="inline"
        name="car-accident"
        data-test-id="remote_monitoring.road_vehicles_icon"
      ></odx-icon>
    </span>

    <span *ngIf="incident?.type; else emptyTitle" class="tile-title">
      <span *ngIf="!incident?.ended; else finishedTitle">
        {{ 'REMOTE_MONITORING.STR_TILE_TITLE' | translate }}
      </span>
      <ng-template #finishedTitle> {{ 'REMOTE_MONITORING.STR_TILE_TITLE_FINISHED' | translate }} </ng-template>
      <ignis-incident-type-translation [entry]="incident?.type"></ignis-incident-type-translation>
    </span>
    <ng-template #emptyTitle>
      <span></span>
    </ng-template>

    <span class="isHazmat" *ngIf="incident?.isHazmat">
      <odx-icon
        size="inline"
        name="toxic"
        [pTooltip]="'REMOTE_MONITORING.STR_HAZMAT_EXPOSURE_RECORDED' | translate"
        tooltipPosition="top"
        tooltipStyleClass="incident-tile-tooltip"
        positionTop="-10"
        data-test-id="remote_monitoring.hazmat_icon"
      ></odx-icon>
    </span>

    <div *ngIf="incident?.ended" class="finished-incident-mark">
      <odx-icon size="inline" name="feedback-positive" data-test-id="remote_monitoring.ended_incident_icon"></odx-icon>
    </div>
  </div>
  <odx-card-content>
    <section odxLayout="grid" class="custom-grid-gap">
      <div odxLayout="12 4@phone">
        <span class="top-label">
          {{ 'REMOTE_MONITORING.STR_TILE_START_LABEL' | translate }}
        </span>
      </div>
      <div odxLayout="12 8@phone">
        <span class="top-value">{{ incident?.startTime | date: formatDate }}, {{ incident?.start }}</span>
      </div>

      <div odxLayout="12 4@phone">
        <span class="top-label">
          {{ 'REMOTE_MONITORING.STR_TILE_LOCATION_LABEL' | translate }}
        </span>
      </div>
      <div odxLayout="12 8@phone" class="column-with-overflow">
        <span class="top-value" #topValue>
          <span
            (mouseenter)="showAddressTooltip = true"
            (mouseleave)="showAddressTooltip = false"
            *ngIf="incident?.location?.town"
            data-test-id="remote_monitoring.address_town_tooltip_wrap"
            [pTooltip]="!isTextOverflow ? null : getIncidentAddress(incident)"
            tooltipPosition="top"
            positionTop="-10"
          >
            {{ incident?.location?.town
            }}<span *ngIf="incident?.location?.streetInfo || incident?.location?.postcode">, </span></span
          ><span
            (mouseenter)="showAddressTooltip = true"
            (mouseleave)="showAddressTooltip = false"
            *ngIf="incident?.location?.streetInfo"
            data-test-id="remote_monitoring.address_streetInfo_tooltip_wrap"
            [pTooltip]="!isTextOverflow ? null : getIncidentAddress(incident)"
            tooltipPosition="top"
            positionTop="-10"
            >{{ incident?.location?.streetInfo }}<span *ngIf="incident?.location?.postcode">, </span></span
          >
          <span
            (mouseenter)="showAddressTooltip = true"
            (mouseleave)="showAddressTooltip = false"
            *ngIf="incident?.location?.postcode"
            data-test-id="remote_monitoring.address_postcode_tooltip_wrap"
            [pTooltip]="!isTextOverflow ? null : getIncidentAddress(incident)"
            tooltipPosition="top"
            positionTop="-10"
            >{{ incident?.location?.postcode
            }}<span *ngIf="!incident?.location?.town && !incident?.location?.streetInfo">, </span>
          </span>
          <span
            (mouseenter)="showCoordinatesTooltip = true"
            (mouseleave)="showCoordinatesTooltip = false"
            data-test-id="remote_monitoring.address_town_street_info_tooltip_wrap"
            class="top-value"
            #topValue
            *ngIf="!incident?.location?.town && !incident?.location?.streetInfo"
          >
            {{
              mapService.convertToDMS(incident?.location.gpsCoordinate.lat, incident?.location.gpsCoordinate.lng, true)
            }}
          </span>
        </span>

        <div
          class="address-tooltip"
          [pTooltip]="showCoordinatesTooltip && isTextOverflow ? getIncidentAddress(incident) : null"
          tooltipPosition="top"
          positionTop="-10"
          data-test-id="remote_monitoring.incident_adress_tooltip"
        ></div>
      </div>

      <div odxLayout="12 12@phone">
        <div class="map-placeholder">
          <img [src]="incident?.incidentImage" alt="map" />
        </div>
      </div>

      <div odxLayout="12 4@phone">
        <span class="top-label">
          {{ 'REMOTE_MONITORING.STR_TILE_DURATION_LABEL' | translate }}
        </span>
      </div>
      <div odxLayout="12 8@phone" class="text-end">
        <span class="bottom-value" data-test-id="remote_monitoring.duration_value">
          {{ incident?.duration }}
        </span>
      </div>

      <div odxLayout="12 6@phone">
        <span class="top-label">
          {{ 'REMOTE_MONITORING.STR_TILE_ENTRY_CONTROL_POINTS_LABEL' | translate }}
        </span>
      </div>
      <div odxLayout="12 6@phone" class="text-end">
        <span class="bottom-value" data-test-id="remote_monitoring.ecp_no_value">{{ incident?.ecpNo }}</span>
      </div>

      <div odxLayout="12 12@phone">
        <div class="tile-app-names">
          <odx-icon name="tablet" size="inline"></odx-icon>
          <span
            [pTooltip]="incident?.appNames.join(splitArrayOfStringsRule)"
            tooltipPosition="top"
            positionTop="-10"
            data-test-id="remote_monitoring.app_names_value"
          >
            {{ incident?.appNames.join(splitArrayOfStringsRule) }}
          </span>
        </div>
      </div>

      <div odxLayout="12 6@phone">
        <span class="top-label">
          {{ 'REMOTE_MONITORING.STR_TILE_DEPLOYED_FIREFIGHTERS_LABEL' | translate }}
        </span>
      </div>
      <div odxLayout="12 6@phone" class="text-end">
        <span class="bottom-value" data-test-id="remote_monitoring.deployed_firefighers_value">
          {{ incident?.deployedFirefighters }}
        </span>
      </div>

      <div odxLayout="12 6@phone">
        <span class="top-label">
          {{ 'REMOTE_MONITORING.STR_TILE_STANDBY_FIREFIGHTERS_LABEL' | translate }}
        </span>
      </div>
      <div odxLayout="12 6@phone" class="text-end">
        <span class="bottom-value" data-test-id="remote_monitoring.standby_firefighters_no_value">
          {{ incident?.standbyFirefightersNo }}
        </span>
      </div>

      <div odxLayout="12 12@phone" class="text-end">
        @if (incident?.hasFaultyReports) {
          <div
            class="withheld-info-icon"
            [pTooltip]="'REMOTE_MONITORING.STR_TILE_WITHHELD_TOOLTIP' | translate"
            tooltipPosition="top"
            positionTop="-10"
            tooltipStyleClass="incident-tile-tooltip"
            data-test-id="remote_monitoring.faulty_report_tooltip"
          >
            <odx-icon size="inline" name="feedback-warning"></odx-icon>
          </div>
        }

        @if (!incident?.ended) {
          <button
            odxButton
            variant="highlight"
            (click)="incidentOverviewNavigate(incident?.aggregateId); $event.stopPropagation()"
            [disabled]="disableMonitorIncidentBtn"
            data-test-id="remote_monitoring.monitor_incident_btn"
          >
            {{ 'REMOTE_MONITORING.STR_TILE_MONITOR_INCIDENT_BUTTON_MONITOR_INCIDENT' | translate }}
          </button>
        }

        @if (incident?.ended) {
          <button
            odxButton
            [variant]="incident?.reportId ? 'highlight' : 'secondary'"
            (click)="$event.stopPropagation(); navigateToReportCenter(incident?.reportId)"
            (mouseenter)="showIncidentReportBtnTooltip = incident?.aggregateId"
            (mouseleave)="showIncidentReportBtnTooltip = ''"
            data-test-id="remote_monitoring.view_report_btn"
            [pTooltip]="
              showIncidentReportBtnTooltip === incident?.aggregateId && !incident?.reportId
                ? viewReportButtonTooltipContent
                : null
            "
            tooltipPosition="top"
            positionTop="-10"
            tooltipStyleClass="incident-tile-tooltip"
          >
            {{ 'REMOTE_MONITORING.STR_TILE_MONITOR_INCIDENT_BUTTON_VIEW_REPORT' | translate }}

            <ng-template #viewReportButtonTooltipContent>
              <div data-test-id="remote_monitoring.view_report_btn_tooltip">
                {{ 'REMOTE_MONITORING.STR_VIEW_REPORT_BTN_TOOLTIP' | translate }}
              </div>
            </ng-template>
          </button>
        }
      </div>
    </section>
  </odx-card-content>
</odx-card>
