import { GenericAction } from 'src/app/common';
import { errorPostPutItemData, receivePostPutItemData, requestItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { RemoteMonitoringActionTypes } from '../../actions/remote-monitoring-action-types';
import { IRemoteMonitoringMergeIncidentsState, RemoteMonitoringMergeIncidentsState } from '../../models/merging.model';

export function remoteMonitoringIncidentsMergeReducer(
  lastState: IRemoteMonitoringMergeIncidentsState = new RemoteMonitoringMergeIncidentsState(),
  action: GenericAction<RemoteMonitoringActionTypes, any>
): IRemoteMonitoringMergeIncidentsState {

  switch (action.type) {
    case RemoteMonitoringActionTypes.REQUEST_MERGE_RM_INCIDENTS:
      return requestItemData<IRemoteMonitoringMergeIncidentsState, any>(lastState, ['merged']);
    case RemoteMonitoringActionTypes.RECEIVE_MERGE_RM_INCIDENTS:
      return receivePostPutItemData<IRemoteMonitoringMergeIncidentsState, any>(lastState, ['merged'], action.payload);
    case RemoteMonitoringActionTypes.ERROR_MERGE_RM_INCIDENTS:
      return errorPostPutItemData<IRemoteMonitoringMergeIncidentsState, any>(lastState, ['merged'], action.payload);

    default:
      return lastState;
  }
}
