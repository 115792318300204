<div class="alarm-notification-wrapper">
  <div class="alarm-notification-container">
    <div *ngFor="let alarm of newAlarms; index as i">
      <div
        class="alarm-notification"
        [class]="currentAppTheme"
        [ngClass]="{ 'hovered-notification': isHovered[i] }"
        *ngIf="alarm.open"
        (click)="navigateToMonitorTeamsView(alarm.incidentAggregateId, alarm.ecpId, alarm)"
        (mouseenter)="isHovered[i] = true"
        (mouseleave)="isHovered[i] = false"
        data-test-id="root.navigate_to_monitor_teams"
      >
        <div class="row">
          <div class="col-2 custom-col-2">
            <div class="icon" [class]="currentAppTheme" [ngSwitch]="alarm.type.value">
              <odx-icon
                size="inline"
                class="motion-sensing-alarm"
                name="motion-sensing"
                *ngSwitchCase="'adsu'"
                data-test-id="adsu"
              ></odx-icon>
              <odx-icon size="inline" class="sos-alarm" name="sos" *ngSwitchCase="'dsu'"></odx-icon>
              <odx-icon
                size="inline"
                class="battery-warning-alarm"
                name="battery-warning"
                *ngSwitchCase="'low_battery'"
                data-test-id="low_battery"
              >
              </odx-icon>
              <odx-icon
                size="inline"
                class="telemetry-error-alarm"
                name="telemetry-error"
                *ngSwitchCase="'out_of_range'"
                data-test-id="out_of_range"
              >
                <span></span>
              </odx-icon>
              <odx-icon
                size="inline"
                class="cylinder-error-alarm"
                name="cylinder-error"
                *ngSwitchCase="'low_pressure'"
                data-test-id="low_pressure"
              >
                <span></span>
              </odx-icon>
              <odx-icon
                size="inline"
                class="evacuation-warning-alarm"
                name="evacuation-warning"
                *ngSwitchCase="'evacuation'"
                data-test-id="evacuation"
              >
                <span></span>
              </odx-icon>
              <odx-icon
                size="inline"
                class="person-confirm-alarm"
                name="person-confirm"
                *ngSwitchCase="'withdraw'"
                data-test-id="withdraw"
              >
                <span></span>
              </odx-icon>
              <odx-icon
                size="inline"
                class="thermal-alarm"
                name="thermal-alarm"
                *ngSwitchCase="'thermal_exposure'"
                data-test-id="thermal_exposure"
              >
                <span></span>
              </odx-icon>
            </div>
          </div>
          <div class="col-9 custom-col-9">
            <div>
              <span class="alarm-type-label" [ngClass]="{ 'hovered-notification-title': isHovered[i] }">{{
                alarm.type.label | translate
              }}</span>
            </div>
            <div class="ecp-identification-label">
              <span *ngIf="alarm.ecpNumber">{{
                ('ALARMS.STR_ENTRY_CONTROL_POINT' | translate) + ' ' + alarm.ecpNumber + ' - '
              }}</span>
              <span>{{ alarm.teamName }}</span>
            </div>
            <div class="street-name-label">
              <span *ngIf="alarm.streetInfo" [ngClass]="{ 'new-line-span': alarm.streetInfo?.length > 22 }">{{
                alarm.streetInfo + ', '
              }}</span>
              <span
                class="firefighter-name-label"
                [ngClass]="{ 'new-line-span': alarm.firefighterName?.length > 22 }"
                >{{ alarm.firefighterName }}</span
              >
            </div>
            <div class="timestamp-label mt-23">
              {{ alarm.activationAt | date: (dateFormat | translate) }} {{ alarm.time }}
            </div>
          </div>
          <div class="col-1 custom-col-1 ms-2 text-center">
            <odx-icon
              size="inline"
              name="close"
              class="close-widget"
              (click)="closeWidget(alarm)"
              data-test-id="root.close_alarm_widget"
            ></odx-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
