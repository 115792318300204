<div
  *ngIf="workflowData?.visible"
  class="test-equipment-btn"
  [ngClass]="{ 'disable-workflow-btn': workflowData.disabled }"
  [pTooltip]="workflowData.tooltipText | translate"
  [tooltipOptions]="tooltipOptions"
  tooltipStyleClass="workflow-button-tooltip"
  [attr.workflowId]="workflowData.id"
  [attr.data-test-id]="workflowData.id"
  (click)="setWorkflow(workflowData.workflowType)"
>
  <input
    type="checkbox"
    class="btn-check"
    [name]="workflowData.name"
    [attr.data-test-id]="workflowData.name"
    [checked]="workflowData.checked"
    [disabled]="workflowData.disabled"
  />
  <label class="btn btn-outline-primary" [for]="workflowData.id">
    <odx-icon [name]="workflowData.icon" size="large"></odx-icon>
  </label>
</div>
