import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { CommonConstants } from 'src/app/common';
import { DeviceConnectionConstants } from 'src/app/device-connection/constants/device-connection.constants';
import { environment } from 'src/environments/environment';
import { HttpHelper } from '../../common/services/http-helper/http-helper';
import { IDeviceConnection, IDeviceConnectionToken, IDevicePage } from '../models/device-connection.model';
import { IProtectorMigrationErrorPage, IProtectorMigrationStatus } from '../models/protector-migration.model';
import { IDeviceConnectionLicenseInfo } from './../models/device-connection.model';

@Injectable()
export class DeviceConnectionService {
  private deviceConnectionBaseUrl: string = environment.API_URLS.DEVICE_CONNECTION;
  private protectorMigrationBaseUrl: string = environment.API_URLS.PROTECTOR_MIGRATION;

  get availableRAMConnectorPort(): number {
    return Number(localStorage.getItem(CommonConstants.availableRAMConnectorPort));
  }

  constructor(
    private httpClient: HttpClient,
    private httpHelper: HttpHelper,
  ) {}

  public getDeviceConnectionPage(params?: any): Observable<IDevicePage | HttpErrorResponse> {
    return this.httpClient.get<IDevicePage>(`${this.deviceConnectionBaseUrl}/devices`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: { ...params },
    });
  }

  public getDeviceConnectionById(id: string): Observable<IDeviceConnection | HttpErrorResponse> {
    return this.httpClient
      .get<IDeviceConnection>(`${this.deviceConnectionBaseUrl}/devices/${id}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      })
      .pipe(
        tap((response: HttpResponseBase | any) => {
          /* istanbul ignore next */
          if (response.body) {
            response.body.version = response.headers?.get('etag');
          }
        }),
      );
  }

  public addDeviceConnection(deviceConnections: IDeviceConnection): Observable<IDeviceConnection | HttpErrorResponse> {
    return this.httpClient
      .post<IDeviceConnection>(`${this.deviceConnectionBaseUrl}/devices`, deviceConnections, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      })
      .pipe(
        tap((response: HttpResponseBase | any) => {
          const locationUrlToArr: string[] = response.headers?.get('Location').split('/');

          /* istanbul ignore next */
          if (locationUrlToArr) {
            localStorage.setItem(
              DeviceConnectionConstants.savedDeviceConfigurationID,
              locationUrlToArr[locationUrlToArr.length - 1],
            );
          }
        }),
      );
  }

  public updateDeviceConnection(
    deviceConnections: IDeviceConnection,
  ): Observable<IDeviceConnection | HttpErrorResponse> {
    const dvConn: IDeviceConnection = { ...deviceConnections };
    const deviceConnectionId: string = dvConn.aggregateId;
    const version: string = deviceConnections.version?.toString();

    delete dvConn.aggregateId;
    delete dvConn.version;

    return this.httpClient.put<IDeviceConnection>(
      `${this.deviceConnectionBaseUrl}/devices/${deviceConnectionId}`,
      dvConn,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'If-Match': version,
        }),
      },
    );
  }

  public getDeviceConnectionIdentifier(): Observable<IDeviceConnectionToken | HttpErrorResponse> {
    return this.httpClient.get<IDeviceConnectionToken>(
      `${this.deviceConnectionBaseUrl}/devices/identifier`,
      this.httpHelper.options,
    );
  }

  public deleteDeviceConnection(deviceConnections: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.delete<void>(
      `${this.deviceConnectionBaseUrl}/devices/${deviceConnections.payload.aggregateId}`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'If-Match': deviceConnections.payload.version?.toString(),
        }),
      },
    );
  }

  public updateDeviceConnectionAuthToken(aggregateId: any, version: number): Observable<any> {
    return this.httpClient.put<any>(
      `${this.deviceConnectionBaseUrl}/devices/${aggregateId}/authentication-token`,
      {},
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'If-Match': version.toString(),
        }),
      },
    );
  }

  public getDeviceConnectionCategories(): Observable<any> {
    return this.httpClient.get<any>(`${this.deviceConnectionBaseUrl}/devices/categories`, this.httpHelper.options);
  }

  public checkRAMConnectorToken(token: string): Observable<any> {
    return this.httpClient.head<any>(
      `${this.deviceConnectionBaseUrl}/device/ram-connector/${token}`,
      this.httpHelper.options,
    );
  }

  public getDeviceConnectionLicenseInfo(): Observable<IDeviceConnectionLicenseInfo | HttpErrorResponse> {
    return this.httpClient.get<IDeviceConnectionLicenseInfo>(
      `${this.deviceConnectionBaseUrl}/devices/license-info`,
      this.httpHelper.options,
    );
  }

  public getDeviceConnectionAssignedFireStations(): Observable<any> {
    return this.httpClient.get<any>(`${this.deviceConnectionBaseUrl}/devices/fire-stations`, this.httpHelper.options);
  }

  public mergeFireStationsFromBAWearerAndDeviceConnection(
    baWearerFS: string[],
    deviceConnectionFS: string[],
  ): string[] {
    const bwFireStations: string[] = baWearerFS || [];
    const dcFireStations: string[] = deviceConnectionFS || [];

    const mergeFireStations: string[] = [...bwFireStations, ...dcFireStations];

    return Array.from(new Set(mergeFireStations)).filter((fireStation: string) => fireStation !== null);
  }

  public startProtectorMigration(): Observable<object | HttpErrorResponse> {
    return this.httpClient.post(
      `http://localhost:${this.availableRAMConnectorPort}/api/v1/migration/start`,
      {},
      this.httpHelper.RAMConnectorOptions,
    );
  }

  public cancelProtectorMigrationInRAM(): Observable<object | HttpErrorResponse> {
    return this.httpClient.post(
      `http://localhost:${this.availableRAMConnectorPort}/api/v1/migration/cancel`,
      {},
      this.httpHelper.RAMConnectorOptions,
    );
  }

  public cancelProtectorMigration(migrationId: {
    payload: string;
    type: string;
  }): Observable<object | HttpErrorResponse> {
    return this.httpClient.delete(`${this.protectorMigrationBaseUrl}/migrations/${migrationId.payload}`, {});
  }

  public getProtectorSoftwareMigrationStatus(migrationId: {
    payload: string;
    type: string;
  }): Observable<IProtectorMigrationStatus | HttpErrorResponse> {
    return this.httpClient.get<IProtectorMigrationStatus>(
      `${this.protectorMigrationBaseUrl}/migrations/${migrationId.payload}/status`,
      this.httpHelper.options,
    );
  }

  public geProtectorSoftwareMigrationErrorPage(params: {
    migrationId: string;
    type: string;
    payload?: any;
  }): Observable<IProtectorMigrationErrorPage | HttpErrorResponse> {
    return this.httpClient.get<IProtectorMigrationErrorPage>(
      `${this.protectorMigrationBaseUrl}/migrations/${params.migrationId}/errors`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        params: { ...params.payload },
      },
    );
  }
}
