<p-columnFilter
  *ngIf="column?.field === customFilter && column.filterType === filterType.TEXT"
  field="column?.field"
  matchMode="contains"
  [showMenu]="false"
  [showClearButton]="false"
>
  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
    <odx-form-field [variant]="'simple'" odxLayout="6@phone 12" [ngClass]="{ 'has-error': !isInputValueValid }">
      <input
        type="text"
        ignisDisableAutocomplete
        ignisNoAllowHtmlTags
        (emitIsInputValueValid)="isInputValueValid = $event"
        odxFormFieldControl
        [placeholder]="column.filterPlaceholder | translate"
        [title]="
          tableStoredFilters && tableStoredFilters[column?.field]
            ? tableStoredFilters && tableStoredFilters[column?.field]
            : (column.filterPlaceholder | translate)
        "
        [id]="column?.field"
        [attr.data-test-id]="column?.field"
        [ngModel]="tableStoredFilters && tableStoredFilters[column?.field]"
        [ngClass]="{ 'text-filter-padding-right': tableService.getInputFilterValueLength(column?.field) > 0 }"
        (input)="onInputChange($event.target.value)"
        (drop)="tableService.preventToDropColumn()"
      />
      <odx-action-group odxFormFieldSuffix>
        <button
          odxButton
          size="medium"
          *ngIf="tableService.getInputFilterValueLength(column?.field) > 0"
          (click)="onInputClear(); tableService.clearTextFilter(column?.field)"
        >
          <odx-icon name="close" size="medium"></odx-icon>
        </button>
      </odx-action-group>

      <odx-icon
        *ngIf="!isInputValueValid"
        name="info"
        class="custom-validation-icon r-35"
        iconSet="core"
        [odxTooltip]="'TABLE.STR_NO_VALID_VALUE' | translate"
        [odxTooltipVisible]="!isInputValueValid"
      >
      </odx-icon>
    </odx-form-field>
  </ng-template>
</p-columnFilter>
