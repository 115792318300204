import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IEditLocationHierarchy, ILocationHierarchyChange, INewLocationHierarchy } from 'src/app/configuration/models';
import { IConfigurationState } from '../../models/configuration.models';
import {
  RequestAddLocationHierarchy,
  RequestDeleteLocationHierarchy,
  RequestEditLocationHierarchy,
  RequestLocationHierarchy,
  RequestLocationHierarchyChange,
  RequestSearchLocation,
  ResetDeleteLocationHierarchy,
  ResetSearchLocation,
} from './location-hierarchy-action-types-creators';

@Injectable({ providedIn: 'root' })
export class LocationHierarchyActions {
  constructor(public store: Store<IConfigurationState>) {}

  public requestLocationHierarchy(): void {
    this.store.dispatch(new RequestLocationHierarchy());
  }

  public requestAddLocationHierarchy(params: INewLocationHierarchy): void {
    this.store.dispatch(new RequestAddLocationHierarchy(params));
  }

  public requestLocationHierarchyChange(params: ILocationHierarchyChange): void {
    this.store.dispatch(new RequestLocationHierarchyChange(params));
  }

  public requestEditLocationHierarchy(params: IEditLocationHierarchy): void {
    this.store.dispatch(new RequestEditLocationHierarchy(params));
  }

  public requestDeleteLocationHierarchy(params: any): void {
    this.store.dispatch(new RequestDeleteLocationHierarchy(params));
  }

  public resetDeleteLocationHierarchy(): void {
    this.store.dispatch(new ResetDeleteLocationHierarchy());
  }

  public requestSearchLocation(id: string): void {
    this.store.dispatch(new RequestSearchLocation(id));
  }

  public resetSearchLocation(): void {
    this.store.dispatch(new ResetSearchLocation());
  }
}
