import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { HttpHelper } from 'src/app/common';
import { environment } from 'src/environments/environment';
import { IAddressBook, IAddressBookPage } from '../models/address-book.model';

@Injectable({
  providedIn: 'root',
})
export class AddressBookConfigurationService {
  private baseUrl: string = environment.API_URLS.EQUIPMENT;

  constructor(
    private httpClient: HttpClient,
    private httpHelper: HttpHelper,
  ) {}

  public getAddressBookList(params?: any): Observable<IAddressBookPage | HttpErrorResponse> {
    return this.httpClient.get<IAddressBookPage>(`${this.baseUrl}/address-book`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: { ...params },
    });
  }

  public addAddressBook(newAddressBook: IAddressBook): Observable<IAddressBook | HttpErrorResponse> {
    return this.httpClient.post<IAddressBook>(`${this.baseUrl}/address-book`, newAddressBook, this.httpHelper.options);
  }

  public editAddressBook(addressBook: IAddressBook): Observable<IAddressBook | HttpErrorResponse> {
    const editedChecklist: IAddressBook = { ...addressBook, version: undefined, aggregateId: undefined };

    return this.httpClient.put<IAddressBook>(
      `${this.baseUrl}/address-book/${addressBook.aggregateId}`,
      editedChecklist,
      this.httpHelper.optionsWithIfMatch(addressBook.version),
    );
  }

  public getAddressBookById(id: string): Observable<{ body: IAddressBook } | HttpErrorResponse> {
    return this.httpClient
      .get<IAddressBook>(`${this.baseUrl}/address-book/${id}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      })
      .pipe(
        tap((response: HttpResponseBase | any) => {
          /* istanbul ignore next */
          if (response.body) {
            response.body.version = response.headers?.get('etag');
          }
        }),
      );
  }

  public deleteAddressBook(payload: {
    aggregateId: string;
    version: number;
  }): Observable<IAddressBook | HttpErrorResponse> {
    return this.httpClient.delete<IAddressBook>(
      `${this.baseUrl}/address-book/${payload.aggregateId}`,
      this.httpHelper.optionsWithIfMatch(payload.version),
    );
  }
}
