import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { CheckingSettingsModalsService, GenericFooterItemsComponent } from 'src/app/common';

@Component({
  selector: 'ignis-ba-wearer-list-view-footer',
  templateUrl: './ba-wearer-list-view-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaWearerListViewFooterComponent<T> extends GenericFooterItemsComponent<T> {
  @Input() isModalOpened: boolean;

  @Output() handleDownloadTemplate: EventEmitter<void> = new EventEmitter();
  @Output() handleUploadCSVFile: EventEmitter<void> = new EventEmitter();

  private checkSettingsModal: CheckingSettingsModalsService = inject(CheckingSettingsModalsService);

  constructor() {
    super();

    this.checkSettingsModal.checkIfSettingsModalIsOpen().subscribe((isModalOpened: boolean) => {
      this.isModalOpened = isModalOpened;
      this.cdr.detectChanges();
    });
  }

  onDownloadBAWearerTemplateClick(e: Event): void {
    e.stopPropagation();
    this.handleDownloadTemplate.emit();
  }

  onUploadBAWearerCSVFileClick(e: Event): void {
    e.stopPropagation();
    this.handleUploadCSVFile.emit();
  }
}
