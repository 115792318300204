import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard } from '@okta/okta-angular';
import { LogbookComponent } from '../components/logbook/logbook.component';
import { LogbookModuleRoutes } from '../constants/logbook-module-routes.constants';
import { PrefilterComponent } from './../components/prefilter/prefilter.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [OktaAuthGuard],
    canActivateChild: [OktaAuthGuard],
    component: LogbookComponent,
    children: [],
  },
  {
    path: LogbookModuleRoutes.prefilter,
    canActivate: [OktaAuthGuard],
    canActivateChild: [OktaAuthGuard],
    component: PrefilterComponent,
    outlet: 'modal',
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LogbookRoutingModule {}
