import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StorageMap } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { CheckingSettingsModalsService, CommonConstants } from 'src/app/common';
import { NotificationConstants } from 'src/app/common/constants';
import { PropertyBag } from 'src/app/common/models/common.model';
import { ReplaceDigitCharPipe } from 'src/app/common/pipes/replace-digit-char/replace-digit-char.pipe';
import { NotificationsService } from 'src/app/common/services';
import { formatOnlyDate, getDecimalNumberFormatUserProfile } from 'src/app/common/utils';
import { ChecklistParameterTypes } from 'src/app/configuration/constants';
import { IChecklist, IChecklistItem, IChecklistOption } from 'src/app/configuration/models';
import { ExportExcelService } from 'src/app/report-center/services/export-excel.service';
import { AccessControlService } from 'src/app/root/services/access-control/access-control.service';
import { TaskResultTypeEnum, WorkshopConstants, WorkshopExportConstants } from 'src/app/workshop/constants';
import { ITaskData, ITaskInterval, ITestValue } from 'src/app/workshop/models';

@Component({
  selector: 'ignis-service-result-export',
  templateUrl: './service-result-export.component.html',
  providers: [ReplaceDigitCharPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceResultExportComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() selectedTaskName: string;
  @Input() dateTime: string;
  @Input() formatDate: string;
  @Input() isModalOpened: boolean = false;
  @Input() taskResultType: string;
  @Input() taskData: ITaskData;
  @Input() taskValues: ITestValue[];
  @Input() checklistData: IChecklist;
  proceedExportXLSX: boolean;
  componentDestroyed: Subject<boolean> = new Subject<boolean>();

  @Output() handleSaveTestResult: EventEmitter<void> = new EventEmitter<void>();

  taskResultTypesEnum: typeof TaskResultTypeEnum = TaskResultTypeEnum;

  exportHeaders: PropertyBag;
  translateService: TranslateService = inject(TranslateService);
  exportExcelService: ExportExcelService = inject(ExportExcelService);
  accessControlService: AccessControlService = inject(AccessControlService);
  notificationsService: NotificationsService = inject(NotificationsService);
  checkSettingsModal: CheckingSettingsModalsService = inject(CheckingSettingsModalsService);
  replaceDigitCharPipe: ReplaceDigitCharPipe = inject(ReplaceDigitCharPipe);

  constructor(public storage: StorageMap) {
    this.exportHeaders = {
      details: this.translateService.instant('EQUIPMENT_TEST.STR_SERVICE_DETAILS') as string,
      info: this.translateService.instant('EQUIPMENT_TEST.STR_EQUIPMENT_INFORMATION') as string,
      other: this.translateService.instant('EQUIPMENT_TASK_RESULTS.STR_OTHER_TASKS') as string,
      test: this.translateService.instant('EQUIPMENT_TASK_RESULTS.STR_TASK_RESULT') as string,
      values: this.translateService.instant('EQUIPMENT_TEST.STR_EQUIPMENT_TEST_VALUES_TAB') as string,
      checklist: this.translateService.instant('EQUIPMENT_TASK_RESULTS.STR_CHECKLIST_ITEMS') as string,
      included: this.translateService.instant('EQUIPMENT_TASK_RESULTS.STR_TASKS_INCLUDED') as string,
      service: this.translateService.instant('EQUIPMENT_TASK_RESULTS.STR_TASK_RESULT') as string,
    };
  }

  ngOnInit(): void {
    this.storage
      .watch('proceedExportXLSX')
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe((state: string) => {
        if (state) {
          this.onExport();

          this.storage.delete('proceedExportXLSX').subscribe(() => {
            return;
          });
        } else {
          this.proceedExportXLSX = false;
        }
      });
  }

  generateExportService(): Array<Array<string>> {
    return [[], [], ...this.exportGeneralTab(), ...this.exportValuesTab()];
  }

  exportGeneralTab(): unknown[] {
    const resultsSection: string =
      (this.taskResultType as TaskResultTypeEnum) === this.taskResultTypesEnum.CylinderCharging
        ? this.exportHeaders.service
        : this.exportHeaders.test;

    return [
      ...this.generateEquipmentInformation(),
      [this.exportHeaders.details],
      ...this.generateServiceDetails(),
      [resultsSection],
      ...this.generateResults(),
      ...this.generateServices(),
    ];
  }

  exportValuesTab(): any {
    if ((this.taskResultType as TaskResultTypeEnum) === this.taskResultTypesEnum.Checklist) {
      return [[this.exportHeaders.values], ...this.generateChecklistItems(), ...this.generateImportedMessage()];
    }

    if ((this.taskResultType as TaskResultTypeEnum) === this.taskResultTypesEnum.AutomatedTest) {
      return [[this.exportHeaders.values], ...this.generateAutomaticValues(), ...this.generateImportedMessage()];
    }

    return [];
  }

  generateImportedMessage(): Array<Array<string>> {
    let message: Array<Array<string>> = [];

    if (this.generateServices().length < 1) {
      message = [
        [this.translateService.instant('EQUIPMENT_TASK_RESULTS.STR_IMPORTED_FROM_PROTECTOR_SOFTWARE') as string],
        CommonConstants.exportNewLine,
      ];
    }

    return message;
  }

  get isOperational(): boolean {
    const currentStatus: string =
      WorkshopConstants.operationalStatusesMapping[this.form.get('operationalStatus').value as string];

    return currentStatus === WorkshopConstants.operationalStatuses.OPERATIONAL;
  }

  generateServices(): unknown[] {
    let generateService: unknown[] = [];

    const selectedOthers: ITaskInterval[] = this.taskData?.others?.filter(
      (service: ITaskInterval) => service.wasTestIncluded,
    );

    if (
      (this.taskResultType as TaskResultTypeEnum) !== this.taskResultTypesEnum.CylinderCharging &&
      this.taskData?.includedTasks?.length
    ) {
      generateService = [
        ...generateService,
        [this.exportHeaders.included],
        ...this.exportExcelService.generateExcelTable(
          this.taskData?.includedTasks?.map(this.serviceMapper),
          WorkshopExportConstants.serviceIncluded,
        ),
      ];
    }

    if (selectedOthers?.length) {
      generateService = [
        ...generateService,
        [this.exportHeaders.other],
        ...this.exportExcelService.generateExcelTable(
          selectedOthers.map(this.serviceMapper),
          WorkshopExportConstants.serviceIncluded,
        ),
      ];
    }

    return generateService;
  }

  serviceMapper: (value: ITaskInterval, index: number, array: ITaskInterval[]) => unknown = (
    service: ITaskInterval,
  ) => {
    return {
      description: service.taskName,
      lastOld: this.formatToDate(service.oldLastTestDate),
      nextOld: this.formatToDate(service.oldNextTestDate),
      lastNew: this.formatToDate(this.isOperational ? service.precalculatedLastTestDate : service.newLastTestDate),
      nextNew: this.formatToDate(this.isOperational ? service.precalculatedNextTestDate : service.newNextTestDate),
    };
  };

  generateAutomaticValues(): Array<Array<Array<string>>> {
    const translatedValues: ITestValue[] = this.taskValues.map((test: ITestValue) => {
      const assessment: string = WorkshopConstants.testAssessmentsTranslation[test.assessment];

      return { ...test, assessment: this.translateService.instant(assessment) as string };
    });

    return this.exportExcelService.generateExcelTable(translatedValues, WorkshopExportConstants.automaticValues);
  }

  generateChecklistItems(): Array<Array<string>> {
    const exportableItems: IChecklistItem[] = this.checklistData.items.filter(
      (item: IChecklistItem) => (item.type as ChecklistParameterTypes) !== ChecklistParameterTypes.IMAGE_TYPE,
    );
    const checklistHeaders: string[] = Object.keys(WorkshopExportConstants.checklistHeaders).map(
      (key: string) => this.translateService.instant(WorkshopExportConstants.checklistHeaders[key]) as string,
    );

    const checklistItems: string[][] = exportableItems.map((item: IChecklistItem) => {
      const value: string[] = [];

      switch (item.type as ChecklistParameterTypes) {
        case ChecklistParameterTypes.NUMERIC_TYPE:
          value.push(
            this.dashIfNoValue(
              this.replaceDigitCharPipe.transform(
                (item.itemValue.value as number)?.toString(),
                getDecimalNumberFormatUserProfile(this.translateService),
              ),
            ),
            this.dashIfNoValue(
              this.replaceDigitCharPipe.transform(
                item.itemValue.minimum?.toString(),
                getDecimalNumberFormatUserProfile(this.translateService),
              ),
            ),
            this.dashIfNoValue(
              this.replaceDigitCharPipe.transform(
                item.itemValue.maximum?.toString(),
                getDecimalNumberFormatUserProfile(this.translateService),
              ),
            ),
            this.dashIfNoValue(item.itemValue.unit),
          );

          break;
        case ChecklistParameterTypes.BOOLEAN_TYPE:
          value.push(item.itemValue.value ? CommonConstants.exportCheck : CommonConstants.exportDash);

          break;
        case ChecklistParameterTypes.TEXT_TYPE:
          value.push(this.dashIfNoValue(this.handleNewLine(item.itemValue.text)));

          break;
        case ChecklistParameterTypes.MULTIPLE_SELECTION_TYPE:
          const multipleComposed: string = item.itemValue.options
            .filter((option: IChecklistOption) => option.checked)
            .map((option: IChecklistOption) => option.name)
            .join(CommonConstants.splitArrayOfStringsRule);

          value.push(this.dashIfNoValue(multipleComposed));

          break;
        default:
          break;
      }

      return [`${item.name}${item.required ? ' *' : ''}`, ...value];
    });

    return [[this.exportHeaders.checklist, ...checklistHeaders], ...checklistItems];
  }

  generateResults(): Array<Array<string>> {
    interface IFormFields {
      operationalStatus: string;
      comment: string;
      newRfid: string;
      newBarcode: string;
      outOfServiceDate: string;
      outOfServiceReason: string;
      airQualityMeasured: boolean;
    }

    const formFields: IFormFields = this.form.getRawValue() as IFormFields;

    const localizedStatus: string = WorkshopConstants.operationalStatusLocalize.status.find(
      (status: { value: string; localizedName: string }) => status.value === formFields.operationalStatus,
    ).localizedName;

    const exportObject: PropertyBag = {
      comment: this.handleNewLine(formFields.comment),
      newRfid: this.handleNewLine(formFields.newRfid),
      newBarcode: this.handleNewLine(formFields.newBarcode),
      status: this.translateService.instant(localizedStatus) as string,
      outOfServiceDate: this.formatToDate(formFields.outOfServiceDate),
      outOfServiceReason: this.handleNewLine(formFields.outOfServiceReason),
      airPressure: formFields.airQualityMeasured ? CommonConstants.exportCheck : '',
    };

    if ((this.taskResultType as TaskResultTypeEnum) !== this.taskResultTypesEnum.CylinderCharging) {
      delete exportObject.airPressure;
    }

    return this.exportExcelService.generateExcelSection(exportObject, WorkshopExportConstants.results);
  }

  generateEquipmentInformation(): Array<Array<string>> {
    interface IFormFields {
      identification: string;
      barcode: string;
      rfid: string;
      serialNo: string;
      equipmentType: string;
      equipmentModel: string;
    }

    const formFields: IFormFields = this.form.getRawValue() as IFormFields;

    const exportObj: PropertyBag = {
      identifier: formFields.identification,
      barcode: formFields.barcode,
      rfid: formFields.rfid,
      serialNo: formFields.serialNo,
      equipmentType: formFields.equipmentType,
      equipmentModel: formFields.equipmentModel,
    };

    const generatedDate: string[][] = this.exportExcelService.generateExcelSection(
      exportObj,
      WorkshopExportConstants.equipmentInformation,
    );

    return [[this.exportHeaders.info], ...generatedDate];
  }

  generateServiceDetails(): Array<Array<string>> {
    interface IFormFields {
      testerName: string;
      testEquipment: string;
      testProcedure: string;
      testType: string;
      softwareVersion: string;
      deviceSerialNumber: string;
      checklist: IChecklist;
      pressure: number;
      locationPath: string;
    }

    const formFields: IFormFields = this.form.getRawValue() as IFormFields;

    let specificFields: PropertyBag = {};

    switch (this.taskResultType as TaskResultTypeEnum) {
      case this.taskResultTypesEnum.AutomatedTest:
        specificFields = {
          userName: formFields.testerName,
          testDevice: formFields.testEquipment,
          testProcedure: formFields.testProcedure,
          testType: formFields.testType,
          version: formFields.softwareVersion,
          serialNumber: formFields.deviceSerialNumber,
        };
        break;

      case this.taskResultTypesEnum.Checklist:
        specificFields = {
          technicianName: formFields.testerName,
          checklistName: formFields.checklist.name,
          description: this.handleNewLine(formFields.checklist.description),
        };
        break;

      case this.taskResultTypesEnum.CylinderCharging:
        specificFields = { technicianName: formFields.testerName, pressure: formFields.pressure?.toString() };
        break;

      default:
        break;
    }

    return this.exportExcelService.generateExcelSection(
      {
        service: this.selectedTaskName,
        dateTime: this.dateTime,
        ...specificFields,
        locationPath: formFields.locationPath,
      },
      WorkshopExportConstants.equipmentServiceDetails,
    );
  }

  onExport(): void {
    const identifier: string = this.form.get('identification').value as string;
    const fileName: string = `${this.dateTime}-${identifier}-${this.selectedTaskName}`;
    const sheetName: string = this.translateService.instant('EQUIPMENT_TEST.STR_SERVICE_RESULT') as string;

    if (this.form.dirty) {
      this.handleSaveTestResult.emit();
    } else {
      this.proceedExportXLSX = true;
    }

    if (this.proceedExportXLSX) {
      try {
        this.exportExcelService.exportTaskResultExcel(
          this.generateExportService(),
          fileName,
          this.formatDate,
          sheetName,
        );
      } catch (error) {
        this.notificationsService.requestShowNotification(
          CommonConstants.notificationType.ERROR,
          NotificationConstants.commonCodes.EXCEL_EXPORT_ERROR,
          NotificationConstants.commonCodes,
        );
      }

      this.proceedExportXLSX = false;
    }
  }

  formatToDate(date: string): string {
    if (!date) return CommonConstants.exportDash;

    return formatOnlyDate(new Date(date).toString(), this.formatDate);
  }

  dashIfNoValue(value: string | number): string {
    return value?.toString() || CommonConstants.exportDash;
  }

  handleNewLine(value: string): string {
    return value?.replace(/\n/g, ' ');
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next(false);
  }
}
