// eslint-disable-next-line no-shadow
export enum TableFilterType {
  TEXT = 'TEXT',
  DATE = 'DATE',
  MULTI_SELECT = 'MULTI_SELECT',
  DROPDOWN = 'DROPDOWN',
}

export class TableConstants {
  public static readonly notNeededInQueries: string[] = [
    'startPressure',
    'endPressure',
    'date',
    'dateTime',
    'duration',
    'selectedHubIds',
    'selectedOccurredEvents',
  ];
  public static readonly rowsPerPageOptions: number[] = [10, 25, 50, 100];
  public static readonly NO_CONTENT_STATUS_CODE: number = 204;
  public static readonly NO_ROWS_TABLE_SCROLLING_FALLBACK: number = 6;
}
