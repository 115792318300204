import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICustomColumn, PropertyBag } from 'src/app/common/models';
import { WorkshopConstants } from 'src/app/workshop/constants';

@Component({
  selector: 'ignis-operational-status-column',
  templateUrl: './operational-status-column.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationalStatusColumnComponent {
  @Input() operationalStatus: ICustomColumn;

  operationalStatuses: PropertyBag = WorkshopConstants.operationalStatuses;
}
