<ng-template odxModal #confirmDeleteModalTemplate>
  <odx-modal-hero icon="delete" variant="danger" data-test-id="delete_confirmation_dialog.delete_modal">
    <ng-content select="[slot=header]"></ng-content>
  </odx-modal-hero>

  <odx-modal-content>
    <ng-content select="[slot=content]"></ng-content>
  </odx-modal-content>
  <odx-modal-footer>
    <button odxButton variant="secondary" odxModalClose id="cancel-delete" data-test-id="modal_actions.cancel_btn">
      {{ 'DELETE_CONFIRMATION_DIALOG.STR_CANCEL_BTN' | translate }}
    </button>

    <button
      odxButton
      variant="primary"
      id="confirm-delete"
      [odxModalClose]="true"
      data-test-id="modal_actions.confirm_btn"
      [odxLoadingSpinner]="isLoading"
      odxLoadingSpinnerAutoColor
    >
      <p class="button-text">
        {{ 'DELETE_CONFIRMATION_DIALOG.STR_CONFIRM_BTN' | translate }}
      </p>
    </button>
  </odx-modal-footer>
</ng-template>
