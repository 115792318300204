import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CheckingSettingsModalsService, GenericFooterItemsComponent, PropertyBag } from 'src/app/common';
import { AccessControlService } from 'src/app/root/services/access-control/access-control.service';
import { UserManagementModuleRoutes } from '../../constants/user-management-module-routes.contants';

@Component({
  selector: 'ignis-user-management-module-footer',
  templateUrl: './user-management-module-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserManagementModuleFooterComponent<T> extends GenericFooterItemsComponent<T> {
  @Input() numberOfRecordsToBeExported: number;
  @Input() isModalOpened: boolean;

  @Output() handleDownloadTemplate: EventEmitter<void> = new EventEmitter();
  @Output() handleUploadCSVFile: EventEmitter<void> = new EventEmitter();

  readonly UserManagementModuleRoutes: PropertyBag = UserManagementModuleRoutes;

  constructor(
    public router: Router,
    public translateService: TranslateService,
    public accessControlService: AccessControlService,
    private checkSettingsModal: CheckingSettingsModalsService,
    public cdr: ChangeDetectorRef,
  ) {
    super();

    this.checkSettingsModal.checkIfSettingsModalIsOpen().subscribe((isModalOpened: boolean) => {
      this.isModalOpened = isModalOpened;
      this.cdr.detectChanges();
    });
  }

  onDownloadTemplateClick(e: Event): void {
    e.stopPropagation();
    this.handleDownloadTemplate.emit();
  }

  onUploadCSVFileClick(e: Event): void {
    e.stopPropagation();
    this.handleUploadCSVFile.emit();
  }
}
