import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IAddressBook, IAddressBookPage } from 'src/app/configuration/models';
import { AddressBookActionTypes } from './address-book-action-types';

export class RequestAddressBook implements Action {
  readonly type: AddressBookActionTypes.REQUEST_ADDRESS_BOOK = AddressBookActionTypes.REQUEST_ADDRESS_BOOK;

  constructor(public payload: any) {}
}
export class ReceiveAddressBook implements Action {
  readonly type: AddressBookActionTypes.RECEIVE_ADDRESS_BOOK = AddressBookActionTypes.RECEIVE_ADDRESS_BOOK;

  constructor(public payload: IAddressBookPage) {}
}
export class ErrorReceiveAddressBook implements Action {
  readonly type: AddressBookActionTypes.ERROR_RECEIVE_ADDRESS_BOOK = AddressBookActionTypes.ERROR_RECEIVE_ADDRESS_BOOK;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestAddAddressBook implements Action {
  readonly type: AddressBookActionTypes.REQUEST_ADD_ADDRESS_BOOK = AddressBookActionTypes.REQUEST_ADD_ADDRESS_BOOK;

  constructor(public payload: IAddressBook) {}
}
export class ReceiveAddAddressBook implements Action {
  readonly type: AddressBookActionTypes.RECEIVE_ADD_ADDRESS_BOOK = AddressBookActionTypes.RECEIVE_ADD_ADDRESS_BOOK;

  constructor(public payload: IAddressBook) {}
}
export class ErrorReceiveAddAddressBook implements Action {
  readonly type: AddressBookActionTypes.ERROR_RECEIVE_ADD_ADDRESS_BOOK =
    AddressBookActionTypes.ERROR_RECEIVE_ADD_ADDRESS_BOOK;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestAddressBookById implements Action {
  readonly type: AddressBookActionTypes.REQUEST_ADDRESS_BOOK_BY_ID = AddressBookActionTypes.REQUEST_ADDRESS_BOOK_BY_ID;

  constructor(public payload: string) {}
}
export class ReceiveAddressBookById implements Action {
  readonly type: AddressBookActionTypes.RECEIVE_ADDRESS_BOOK_BY_ID = AddressBookActionTypes.RECEIVE_ADDRESS_BOOK_BY_ID;

  constructor(public payload: IAddressBook) {}
}
export class ErrorReceiveAddressBookById implements Action {
  readonly type: AddressBookActionTypes.ERROR_RECEIVE_ADDRESS_BOOK_BY_ID =
    AddressBookActionTypes.ERROR_RECEIVE_ADDRESS_BOOK_BY_ID;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestEditAddressBook implements Action {
  readonly type: AddressBookActionTypes.REQUEST_EDIT_ADDRESS_BOOK = AddressBookActionTypes.REQUEST_EDIT_ADDRESS_BOOK;

  constructor(public payload: IAddressBook) {}
}
export class ReceiveEditAddressBook implements Action {
  readonly type: AddressBookActionTypes.RECEIVE_EDIT_ADDRESS_BOOK = AddressBookActionTypes.RECEIVE_EDIT_ADDRESS_BOOK;

  constructor(public payload: IAddressBook) {}
}
export class ErrorReceiveEditAddressBook implements Action {
  readonly type: AddressBookActionTypes.ERROR_RECEIVE_EDIT_ADDRESS_BOOK =
    AddressBookActionTypes.ERROR_RECEIVE_EDIT_ADDRESS_BOOK;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestAddressBookDelete implements Action {
  readonly type: AddressBookActionTypes.REQUEST_DELETE_ADDRESS_BOOK =
    AddressBookActionTypes.REQUEST_DELETE_ADDRESS_BOOK;

  constructor(public payload: { aggregateId: string; version: number }) {}
}
export class ReceiveDeleteAddressBook implements Action {
  readonly type: AddressBookActionTypes.RECEIVE_DELETE_ADDRESS_BOOK =
    AddressBookActionTypes.RECEIVE_DELETE_ADDRESS_BOOK;

  constructor(public payload: IAddressBook) {}
}
export class ErrorReceiveDeleteAddressBook implements Action {
  readonly type: AddressBookActionTypes.ERROR_RECEIVE_DELETE_ADDRESS_BOOK =
    AddressBookActionTypes.ERROR_RECEIVE_DELETE_ADDRESS_BOOK;

  constructor(public payload: HttpErrorResponse) {}
}

export class ResetAddressBookPage implements Action {
  readonly type: AddressBookActionTypes.RESET_ADDRESS_BOOK_PAGE = AddressBookActionTypes.RESET_ADDRESS_BOOK_PAGE;
}
