import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'callback',
})
export class CallbackPipe implements PipeTransform {
  transform<T>(items: T[], callback: (item: T) => boolean): T[] {
    if (!items || !callback) {
      return items;
    }

    return items.filter(callback);
  }
}
