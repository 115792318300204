import { Injectable } from '@angular/core';
import { ITask } from 'src/app/configuration/models';
import { WorkshopConstants } from '../constants';
import { IEquipmentServiceInterval } from '../models';

@Injectable({
  providedIn: 'root',
})
export class RestoreServiceTaskReasonService {
  restoreReasonName(
    tasksIncluded: ITask[] | IEquipmentServiceInterval[],
    otherTasksIncluded: IEquipmentServiceInterval[],
  ): void {
    const storedTasksIncluded: ITask[] = JSON.parse(sessionStorage.getItem(WorkshopConstants.tasksIncluded)) as ITask[];
    const storedOtherTasksIncluded: IEquipmentServiceInterval[] = JSON.parse(
      sessionStorage.getItem(WorkshopConstants.otherTasks),
    ) as IEquipmentServiceInterval[];

    tasksIncluded.forEach((service: ITask) => {
      storedTasksIncluded.forEach((storedService: ITask) => {
        if (storedService.serviceId === service.serviceId) {
          service.taskName = storedService.taskName;
        }
      });
    });

    otherTasksIncluded.forEach((service: ITask) => {
      storedOtherTasksIncluded.forEach((storedService: ITask) => {
        if (storedService.serviceId === service.serviceId) {
          service.taskName = storedService.taskName;
        }
      });
    });
  }
}
