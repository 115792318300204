import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpHelper } from '../../../common/services/http-helper/http-helper';
import { IAlarmLists } from '../../models/alarms.model';

@Injectable({
  providedIn: 'root',
})
export class AlarmsService {
  private baseUrl: string = environment.API_URLS.REMOTE_MONITORING;

  constructor(
    private httpClient: HttpClient,
    private httpHelper: HttpHelper,
  ) {}

  public getAlarms(): Observable<IAlarmLists | HttpErrorResponse> {
    return this.httpClient.get<IAlarmLists>(`${this.baseUrl}/incidents/alarms`, this.httpHelper.options);
  }
}
