import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IMaintenanceDate } from './../models/maintenance-mode.model';
import { MaintenanceModeActionType } from './maintenance-mode-action-types';

export class RequestMaintenanceDate implements Action {
  readonly type: MaintenanceModeActionType.REQUEST_MAINTENANCE_DATE = MaintenanceModeActionType.REQUEST_MAINTENANCE_DATE;
}
export class ReceiveMaintenanceDate implements Action {
  readonly type: MaintenanceModeActionType.RECEIVE_MAINTENANCE_DATE = MaintenanceModeActionType.RECEIVE_MAINTENANCE_DATE;

  constructor(public payload: IMaintenanceDate) { }
}
export class ErrorReceiveMaintenanceDate implements Action {
  readonly type: MaintenanceModeActionType.ERROR_RECEIVE_MAINTENANCE_DATE = MaintenanceModeActionType.ERROR_RECEIVE_MAINTENANCE_DATE;

  constructor(public payload: HttpErrorResponse) { }
}
