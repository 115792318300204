<ng-container *ngIf="entry" [ngSwitch]="entry?.data">
  <ng-container *ngSwitchCase="appUserRole.admin">
    {{ 'USER_ROLES.ADMIN_OPTION' | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="appUserRole.firefighter">
    {{ 'USER_ROLES.FIREFIGHTER_OPTION' | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="appUserRole.fireChief">
    {{ 'USER_ROLES.FIRE_CHIEF_OPTION' | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="appUserRole.technician">
    {{ 'USER_ROLES.TECHNICIAN_OPTION' | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="appUserRole.tester">
    {{ 'USER_ROLES.TESTER_OPTION' | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="appUserRole.itManager">
    {{ 'USER_ROLES.IT_MANAGER_OPTION' | translate }}
  </ng-container>
</ng-container>
