import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHelper } from 'src/app/common/services/http-helper/http-helper';
import { environment } from 'src/environments/environment';
import { IMeasurementUnit } from './../models/localization-profile.model';
import { ILocalizationProfile } from '../models/localization-profile.model';
import { ITableSettingsResponse, ITableSettings, ITableSettingsUpdate } from 'src/app/common/models/table.model';

@Injectable()
export class SettingsService {
  private baseUrl: string = environment.API_URLS.USERS;

  constructor(private httpClient: HttpClient, private httpHelper: HttpHelper) { }

  public getLocalizationSettings(): Observable<ILocalizationProfile[] | HttpErrorResponse> {
    return this.httpClient.get<Array<ILocalizationProfile>>(
      `${this.baseUrl}/users/settings/languages`,
      this.httpHelper.options
    );
  }

  public getUserLocalizationSettings(): Observable<ILocalizationProfile | HttpErrorResponse> {
    return this.httpClient.get<ILocalizationProfile>(`${this.baseUrl}/users/settings/me`, this.httpHelper.options);
  }

  public saveUserLocalizationSettings(
    localizationProfile: ILocalizationProfile
  ): Observable<ILocalizationProfile | HttpErrorResponse> {
    return this.httpClient.put<ILocalizationProfile>(
      `${this.baseUrl}/users/settings/me`,
      localizationProfile,
      this.httpHelper.options
    );
  }

  public getMeasurementUnits(): Observable<IMeasurementUnit[] | HttpErrorResponse> {
    return this.httpClient.get<IMeasurementUnit[]>(
      `${this.baseUrl}/users/settings/pressure-units`,
      this.httpHelper.options
    );
  }

  public getTableSettings(tableName: string): Observable<ITableSettingsResponse | HttpErrorResponse> {
    return this.httpClient.get<ITableSettings>(`${this.baseUrl}/users/settings/table/${tableName}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response',
    });
  }

  public updateTableSettings(payload: ITableSettingsUpdate): Observable<ITableSettings | HttpErrorResponse> {
    return this.httpClient.put<ITableSettings>(
      `${this.baseUrl}/users/settings/table/${payload.tableName}`,
      payload.updateSettings,
      this.httpHelper.options
    );
  }
}
