<ng-template odxModal #confirmChangeTokenModal>
  <odx-modal-hero icon="warning" variant="confirmation" data-test-id="CONFIRM_CLOSE_MODAL.STR_TITLE">
    {{ 'CONFIRM_CLOSE_MODAL.STR_TITLE' | translate }}
  </odx-modal-hero>

  <odx-modal-content>
    {{ 'CONFIRM_CHANGE_TOKEN.STR_CONFIRM' | translate }}
    <p>
      {{ 'CONFIRM_CHANGE_TOKEN.STR_NOTE' | translate }}
      <ng-container [ngSwitch]="category">
        <ng-container *ngSwitchCase="deviceCategories.APP">
          <span [attr.data-test-id]="deviceCategories.APP">
            {{ 'CONFIRM_CHANGE_TOKEN.STR_APP_NOTE' | translate }}
          </span>
        </ng-container>
        <ng-container *ngSwitchCase="deviceCategories.HUB">
          <span [attr.data-test-id]="deviceCategories.HUB">
            {{ 'CONFIRM_CHANGE_TOKEN.STR_HUB_NOTE' | translate }}
          </span>
        </ng-container>
        <ng-container *ngSwitchCase="deviceCategories.WORKSHOP">
          <span [attr.data-test-id]="deviceCategories.WORKSHOP">
            {{ 'CONFIRM_CHANGE_TOKEN.STR_WORKSHOP_NOTE' | translate }}
          </span>
        </ng-container>
        <ng-container *ngSwitchCase="deviceCategories.RAM_CONNECTOR">
          <span [attr.data-test-id]="deviceCategories.RAM_CONNECTOR">
            {{ 'CONFIRM_CHANGE_TOKEN.STR_RAM_CONNECTOR_NOTE' | translate }}
          </span>
        </ng-container>
        <ng-container *ngSwitchCase="deviceCategories.API_KEY">
          <span [attr.data-test-id]="deviceCategories.API_KEY">
            {{ 'CONFIRM_CHANGE_TOKEN.STR_API_KEY_NOTE' | translate }}
          </span>
        </ng-container>
      </ng-container>
    </p>
  </odx-modal-content>

  <odx-modal-footer>
    <button odxButton variant="secondary" [odxModalClose]="false" data-test-id="modal_actions.no_btn">
      {{ 'CONFIRM_CLOSE_MODAL.STR_NO_BTN' | translate }}
    </button>
    <button odxButton variant="primary" [odxModalClose]="true" data-test-id="modal_actions.yes_btn">
      {{ 'CONFIRM_CLOSE_MODAL.STR_YES_BTN' | translate }}
    </button>
  </odx-modal-footer>
</ng-template>
