<div class="blue-bar-items">
  @if (!batchChecklistFeatureToggle) {
    <button
      type="button"
      [disabled]="
        !selectedItems?.length ||
        selectedItems?.length > 1 ||
        !toggleStateOfExecuteBtn ||
        this.router.url?.includes(WorkshopModuleRoutes.completeChecklist) ||
        this.router.url?.includes(WorkshopModuleRoutes.cylinder) ||
        isModalOpened ||
        isSubmitDisabled
      "
      odxButton
      size="medium"
      variant="highlight"
      class="blue-bar-btn"
      (click)="onExecuteClick($event)"
      id="bluebar-execute-btn"
      data-test-id="equipment_footer_items.execute_btn"
      [pTooltip]="executeWorkflowButtonTooltipContent"
      tooltipPosition="top"
      positionLeft="5"
      positionTop="-10"
      tooltipStyleClass="footer-tooltip"
    >
      <ng-template #executeWorkflowButtonTooltipContent>
        @if (selectedItems?.length > 1) {
          <div data-test-id="equipment_footer_items.multiple_items_selected_tooltip">
            {{ 'EQUIPMENT_FOOTER_ITEMS.STR_MULTIPLE_ITEMS_SELECTED' | translate }}
          </div>
        } @else {
          @if (
            selectedItems?.length &&
            selectedItems?.length < 2 &&
            this.accessControlService.equipmentTestingGlobalFeatureToggle &&
            !selectedItem?.isProtectorTestType &&
            !selectedItem?.hasCheckList
          ) {
            <div data-test-id="equipment_footer_items.no_protector_type_no_checklist_tooltip">
              {{ 'EQUIPMENT_FOOTER_ITEMS.STR_NO_PROTECTOR_TYPE_NO_CHECKLIST' | translate }}
            </div>
          } @else {
            @if (selectedItems?.length < 2) {
              <div data-test-id="equipment_footer_items.perform_service_task_tooltip">
                {{ 'EQUIPMENT_FOOTER_ITEMS.STR_PERFORM_SERVICE_TASK_TOOLTIP' | translate }}
              </div>
            } @else if (
              !accessControlService.equipmentTestingGlobalFeatureToggle &&
              selectedItems?.length &&
              !selectedItem?.hasCheckList
            ) {
              <div data-test-id="equipment_footer_items.no_checklist_tooltip">
                {{ 'EQUIPMENT_FOOTER_ITEMS.STR_TASK_WITH_NO_CHECKLIST' | translate }}
              </div>
            }
          }
        }
      </ng-template>

      <odx-icon name="testing" size="inline"></odx-icon>
    </button>
  } @else {
    <ignis-checklist-workflow-service-tasks-footer-view
      [selectedItem]="selectedItem"
      [selectedItems]="selectedItems"
      [isSubmitDisabled]="isSubmitDisabled"
      [activatePlayBtn]="activatePlayBtn"
      [selectedTasksAreNotTheSame]="selectedTasksAreNotTheSame"
      (handleExecuteChecklistOrTest)="onExecuteClick($event)"
    ></ignis-checklist-workflow-service-tasks-footer-view>
  }

  <button
    type="button"
    [disabled]="
      !selectedItems?.length ||
      selectedItems?.length > 1 ||
      this.router.url?.includes(WorkshopModuleRoutes.completeChecklist) ||
      isModalOpened
    "
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    (click)="onNavigateToEquipment($event)"
    id="bluebar-edit-btn"
    data-test-id="equipment_footer_items.edit_view_btn"
    [pTooltip]="selectedItems?.length < 2 ? editButtonTooltipContent : null"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #editButtonTooltipContent>
      @if (selectedItems?.length < 2) {
        @if (
          !accessControlService.checkPermission(appUserPermissionList.equipmentWrite) &&
          !accessControlService.checkPermission(appUserPermissionList.equipmentChangeTasks)
        ) {
          <div data-test-id="equipment_footer_items.edit_equipment_tooltip">
            {{ 'EQUIPMENT_FOOTER_ITEMS.STR_VIEW_EQUIPMENT_TOOLTIP' | translate }}
          </div>
        } @else {
          <div data-test-id="equipment_footer_items.edit_equipment_tooltip">
            {{ 'EQUIPMENT_FOOTER_ITEMS.STR_EDIT_EQUIPMENT_TOOLTIP' | translate }}
          </div>
        }
      }
    </ng-template>

    <odx-icon
      *ngIf="
        !accessControlService.checkPermission(appUserPermissionList.equipmentWrite) &&
          !accessControlService.checkPermission(appUserPermissionList.equipmentChangeTasks);
        else editButton
      "
      name="view"
      size="inline"
    ></odx-icon>
    <ng-template #editButton>
      <odx-icon name="edit" size="inline"></odx-icon>
    </ng-template>
  </button>

  <ignis-export-excel-footer-btn
    [isModalOpened]="this.router.url?.includes(WorkshopModuleRoutes.completeChecklist) || isModalOpened"
    [numberOfRecordsToBeExported]="numberOfRecordsToBeExported"
    (handleClickEvent)="onExportExcelClick($event)"
  >
  </ignis-export-excel-footer-btn>
</div>
