import { GenericAction } from 'src/app/common';
import { errorListData, receiveListData, requestListData } from 'src/app/common/utils/reducer/reducer.utils';
import { ProtectorTypeActionTypes } from '../../actions/protector-type';
import { IProtectorTypeValuesState, ProtectorTypeValuesState } from '../../models/protector-type-values.model';

export function protectorTypeValuesReducer(
  lastState: IProtectorTypeValuesState = new ProtectorTypeValuesState(),
  action: GenericAction<ProtectorTypeActionTypes, any>,
): IProtectorTypeValuesState {
  switch (action.type) {
    case ProtectorTypeActionTypes.REQUEST_PROTECTOR_TYPE_VALUES:
      return requestListData<IProtectorTypeValuesState, any[]>(lastState, ['values']);
    case ProtectorTypeActionTypes.RECEIVE_PROTECTOR_TYPE_VALUES:
      return receiveListData<IProtectorTypeValuesState, any[]>(lastState, ['values'], action.payload);
    case ProtectorTypeActionTypes.ERROR_RECEIVE_PROTECTOR_TYPE_VALUES:
      return errorListData<IProtectorTypeValuesState, any[]>(lastState, ['values'], action.payload);

    default:
      return lastState;
  }
}
