import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ModalRef, ModalService, ModalSize, ModalVariant } from '@odx/angular/components/modal';
import { takeUntil } from 'rxjs/operators';
import { ApplicationState, CommonConstants } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { getOrgNameFromOrganizationProfile } from 'src/app/common/utils/settings-utils/settings-utils';
import { DeviceConnectionNotificationConstants } from 'src/app/device-connection/constants';
import { IAppQRCode, IDeviceConnection } from 'src/app/device-connection/models/device-connection.model';
import { AccessControlService } from 'src/app/root';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'ignis-qr-code-modal',
  templateUrl: './qr-code-modal.component.html',
  styleUrls: ['./qr-code-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrCodeModalComponent extends OnDestroyMixin() implements OnChanges {
  @Input() isOpen: boolean = false;
  @Input() formValue: IDeviceConnection;

  @Output() closeConfirmModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  modalReference: ModalRef<any>;

  @ViewChild('qrCodeModal', { read: TemplateRef })
  public qrCodeModal: TemplateRef<any>;

  @ViewChild('qrCodeElem', { read: TemplateRef })
  public qrCodeElem: TemplateRef<any>;

  qrData: IAppQRCode | string;
  organizationName: string;

  notificationsService: NotificationsService = inject(NotificationsService);
  accessControlService: AccessControlService = inject(AccessControlService);
  modalService: ModalService = inject(ModalService);
  store: Store<ApplicationState> = inject(Store<ApplicationState>) as Store<ApplicationState>;
  router: Router = inject(Router);

  constructor() {
    super();

    getOrgNameFromOrganizationProfile(this.store, this.destroy).subscribe((orgName: string) => {
      this.organizationName = orgName;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpen && this.isOpen) {
      this.modalReference = this.modalService.open(this.qrCodeModal, {
        size: ModalSize.MEDIUM,
        variant: ModalVariant.DEFAULT,
        dismissable: false,
      });

      this.modalReference.onClose$.pipe(takeUntil(this.destroy)).subscribe((shouldCloseModal: boolean) => {
        this.closeConfirmModal.emit(shouldCloseModal);
        this.destroy.next();
      });

      this.modalReference.onDismiss$.pipe(takeUntil(this.destroy)).subscribe(() => {
        this.closeConfirmModal.emit(false);
        this.destroy.next();
      });

      setTimeout(() => {
        if (document.getElementsByClassName('qrcode')[0]?.clientHeight < 1) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            DeviceConnectionNotificationConstants.notificationCodes.INVALID_QR_CODE,
            DeviceConnectionNotificationConstants.notificationCodes,
          );

          this.closeConfirmModal.emit(false);
          this.modalReference.close('');
        }
      }, 0);
    }

    if (this.formValue?.category) {
      const qrApiBaseUrl: string = environment.API_BASE_URL;

      this.qrData = {
        apiBaseUrl: qrApiBaseUrl,
        deviceName: this.formValue.name || '',
        organizationName: this.organizationName,
        authenticationToken: this.formValue.authenticationToken,
        version: 3,
      };
      this.qrData = JSON.stringify(this.qrData);
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(): void {
    this.modalReference?.close('');
  }
}
