import { ITableColumn } from 'src/app/common';

export const tableColumns: ITableColumn[] = [
  {
    field: 'generalData.identifier',
    header: 'INVENTORY.STR_UNIQUE_IDENTIFIER',
    visible: true,
  },
  {
    field: 'generalData.barcode',
    header: 'INVENTORY.STR_BARCODE',
    visible: true,
  },
  {
    field: 'generalData.rfid',
    header: 'RFID',
    visible: false,
  },
  {
    field: 'generalData.serialNo',
    header: 'INVENTORY.STR_SERIAL_NO',
    visible: false,
  },
  {
    field: 'generalData.partNo',
    header: 'INVENTORY.STR_PART_NO',
    visible: false,
  },
  {
    field: 'generalData.partName',
    header: 'INVENTORY.STR_PART_NAME',
    visible: false,
  },
  {
    field: 'generalData.model.type.name',
    header: 'INVENTORY.STR_EQUIPMENT_TYPE',
    visible: true,
  },
  {
    field: 'generalData.model.name',
    header: 'INVENTORY.STR_EQUIPMENT_MODEL',
    visible: true,
  },
  {
    field: 'generalData.operationalStatus',
    header: 'INVENTORY.STR_OPERATIONAL_STATUS',
    visible: true,
  },
  {
    field: 'locationAssignment.name',
    header: 'INVENTORY.STR_LOCATION',
    visible: true,
  },
  {
    field: 'generalData.description',
    header: 'INVENTORY.STR_DESCRIPTION',
    visible: false,
  },
  {
    field: 'nextDueService',
    header: 'INVENTORY.STR_NEXT_DUE_SERVICES',
    visible: false,
  },
  {
    field: 'nextDueServiceDate',
    header: 'INVENTORY.STR_NEXT_DUE_SERVICES_DATE',
    visible: false,
  },
  {
    field: 'manufacturerData.organizationName',
    header: 'INVENTORY.STR_MANUFACTURER',
    visible: false,
  },
  {
    field: 'generalData.owner',
    header: 'INVENTORY.STR_OWNER',
    visible: false,
  },
];
