<div class="blue-bar-items">
  <button
    type="button"
    *ngIf="ramSettings?.cleaningWorkflow"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    [disabled]="true"
    [pTooltip]="cleanButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #cleanButtonTooltipContent>
      <div data-test-id="equipment_footer_items.clean_btn">
        {{ 'EQUIPMENT_FOOTER_ITEMS.STR_WASH_EQUIPMENT_TOOLTIP' | translate }}
      </div>
    </ng-template>

    <odx-icon name="cleaning"></odx-icon>
  </button>

  <button
    type="button"
    *ngIf="ramSettings?.cylinderChargingWorkflow"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    [disabled]="
      !selectedItems?.length ||
      disableCylinderChargerBtn ||
      this.router.url.includes(WorkshopModuleRoutes.completeChecklist) ||
      this.router.url.includes(WorkshopModuleRoutes.completeBatchChecklist) ||
      this.router.url.includes(WorkshopModuleRoutes.cylinder) ||
      isModalOpened
    "
    (click)="onCylinderCharge($event)"
    id="bluebar-cylinder-charging"
    data-test-id="equipment_footer_items.cylinder_charging_btn"
    [pTooltip]="cylinderChargerButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #cylinderChargerButtonTooltipContent>
      @if (selectedItems?.length === 0 && !multiple) {
        <div data-test-id="equipment_footer_items.no_cylinder_charging_workflow_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_NOT_SELECTED_TASK' | translate }}
        </div>
      } @else if (disableCylinderChargerBtn) {
        <div data-test-id="equipment_footer_items.no_cylinder_charging_workflow_selected_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_NO_CYLINDER_SELECTED' | translate }}
        </div>
      } @else if (selectedItems?.length && !disableCylinderChargerBtn) {
        <div data-test-id="equipment_footer_items.perform_cylinder_charging_workflow_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_CYLINDER_CHARGING_TOOLTIP' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon name="cylinder-charge" size="inline"></odx-icon>
  </button>

  <button
    type="button"
    *ngIf="ramSettings?.automaticTestingWorkflow && accessControlService.equipmentTestingGlobalFeatureToggle"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    id="bluebar-start-test"
    data-test-id="equipment_footer_items.start_test_btn"
    [disabled]="
      !selectedItem ||
      !selectedItem?.generalData?.hasProtectorType ||
      multiple ||
      this.router.url.includes(WorkshopModuleRoutes.completeChecklist) ||
      this.router.url.includes(WorkshopModuleRoutes.completeBatchChecklist) ||
      this.router.url.includes(WorkshopModuleRoutes.cylinder) ||
      isModalOpened ||
      isSubmitDisabled
    "
    (click)="onStartTestingClick($event)"
    #testingEquipmentBtn
    [pTooltip]="testingButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #testingButtonTooltipContent>
      @if (!selectedItem && !multiple) {
        <div data-test-id="equipment_footer_items.no_selected_protector_test_type_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_TOOLTIP1' | translate }}
        </div>
      } @else if (!selectedItem?.generalData?.hasProtectorType && !multiple) {
        <div data-test-id="equipment_footer_items.no_protector_test_type_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_TOOLTIP2' | translate }}
        </div>
      } @else if (multiple) {
        <div data-test-id="equipment_footer_items.multiple_items_for_protector_test_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_TEST_MULTI_SELECT_TOOLTIP' | translate }}
        </div>
      } @else if (!multiple && selectedItem?.generalData?.hasProtectorType && selectedItem) {
        <div data-test-id="equipment_footer_items.start_protector_test_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_START_PROTECTOR_TEST_TOOLTIP' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon name="test-eq" size="inline"></odx-icon>
  </button>

  <ignis-checklist-workflow-footer-view
    [selectedItems]="selectedItems"
    [disableChecklistBntFlow]="disableChecklistBntFlow"
    [isModalOpened]="isModalOpened"
    [isSubmitDisabled]="isSubmitDisabled"
    [isWorkflowInProgress]="isWorkflowInProgress"
    [ramSettings]="ramSettings"
    [displayNoChecklistTooltip]="displayNoChecklistTooltip"
    [displayNoServiceIdTooltip]="displayNoServiceIdTooltip"
    (handlePerformCompleteChecklist)="onPerformCompleteChecklistClick()"
  >
  </ignis-checklist-workflow-footer-view>

  <button
    type="button"
    *ngIf="
      accessControlService.checkPermission(appUserPermissionList.equipmentWrite) ||
      accessControlService.checkPermission(appUserPermissionList.equipmentChangeTasks)
    "
    [disabled]="
      !selectedItem ||
      multiple ||
      this.router.url.includes(WorkshopModuleRoutes.completeChecklist) ||
      this.router.url.includes(WorkshopModuleRoutes.completeBatchChecklist) ||
      this.router.url.includes(WorkshopModuleRoutes.cylinder) ||
      isModalOpened
    "
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    (click)="onEditClick($event)"
    id="bluebar-workflow-edit"
    data-test-id="equipment_footer_items.edit_workflow_btn"
    [pTooltip]="editEqButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #editEqButtonTooltipContent>
      @if (!selectedItem && !multiple) {
        <div data-test-id="equipment_footer_items.edit_workflow_no_selected_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_WORKFLOW_EDIT_TOOLTIP' | translate }}
        </div>
      } @else if (multiple) {
        <div data-test-id="equipment_footer_items.edit_workflow_multiple_selected_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_WORKFLOW_MULTI_SELECT_TOOLTIP' | translate }}
        </div>
      } @else if (selectedItem && !multiple) {
        <div data-test-id="equipment_footer_items.edit_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_EDIT_EQUIPMENT_TOOLTIP' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon name="edit" size="medium"></odx-icon>
  </button>

  <button
    type="button"
    *ngIf="
      !accessControlService.checkPermission(appUserPermissionList.equipmentWrite) &&
      !accessControlService.checkPermission(appUserPermissionList.equipmentChangeTasks)
    "
    [disabled]="!selectedItem"
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    (click)="onEditClick($event)"
    id="bluebar-view-btn"
    data-test-id="equipment_footer_items.view_btn"
    [pTooltip]="viewEqButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #viewEqButtonTooltipContent>
      @if (
        !accessControlService.checkPermission(appUserPermissionList.equipmentWrite) &&
        !accessControlService.checkPermission(appUserPermissionList.equipmentChangeTasks)
      ) {
        <div data-test-id="equipment_footer_items.view_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_VIEW_EQUIPMENT_TOOLTIP' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon name="view" size="inline"></odx-icon>
  </button>

  <button
    type="button"
    [disabled]="
      !selectedItem ||
      this.router.url.includes(WorkshopModuleRoutes.completeChecklist) ||
      this.router.url.includes(WorkshopModuleRoutes.completeBatchChecklist) ||
      this.router.url.includes(WorkshopModuleRoutes.cylinder) ||
      isModalOpened
    "
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    (click)="onDeleteFromWorkflowClick($event)"
    id="bluebar-workflow-delete"
    data-test-id="equipment_footer_items.delete_workflow_btn"
    [pTooltip]="removeEqButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #removeEqButtonTooltipContent>
      @if (!selectedItem) {
        <div data-test-id="equipment_footer_items.delete_workflow_no_selected_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_WORKFLOW_DELETE_EQUIPMENT' | translate }}
        </div>
      } @else {
        <div data-test-id="equipment_footer_items.delete_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_REMOVE_EQUIPMENT_TOOLTIP' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon name="close"></odx-icon>
  </button>
</div>
