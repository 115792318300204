import { Component, HostListener, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { IStoreApiList } from 'src/app/common';
import { CommonConstants } from 'src/app/common/constants/common.constants';
import { NotificationConstants } from 'src/app/common/constants/notification.constants';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IEntryModel, IScrollOptions } from 'src/app/common/models/common.model';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { INotificationMessage } from 'src/app/common/state/notifications/models/notification.model';
import { AppModulesTypes, AppUserRole } from 'src/app/root/models/app-types';
import { IFeatureToggle } from 'src/app/root/state/features-toggle/models/features-toggle.model';
import { selectFeaturesToggleList } from 'src/app/root/state/features-toggle/selectors/features-toggle.selector';
import { UserActions } from 'src/app/user-management';
import { UserManagementConstants } from 'src/app/user-management/constants/user-management.constants';
import { IUserRole } from 'src/app/user-management/models/user.model';
import { selectRoleList } from 'src/app/user-management/state/selectors/user.selector';
import { convertToDateTimeFormat } from '../../../common/utils/date-utils/date.utils';
import { DeviceConnectionNotificationConstants } from '../../constants';
import { DeviceConnectionModuleRoutes } from '../../constants/device-connection-module-routes.constants';
import { DeviceConnectionConstants } from '../../constants/device-connection.constants';
import { IDeviceConnection, IDeviceConnectionToken } from '../../models/device-connection.model';
import { DeviceConnectionActions } from '../../state/actions/device-connection.actions';
import { IDeviceConnectionState } from '../../state/models/device-connection.model';
import {
  selectDeviceConnection,
  selectDeviceConnectionCategories,
  selectDeviceConnectionIdentifier,
  selectUpdatedDeviceConnectionAuthToken,
} from '../../state/selectors/device-connection.selector';

@Component({
  selector: 'ignis-create-update-device-connection',
  templateUrl: './create-update-device-connection.component.html',
  styleUrls: ['./create-update-device-connection.component.scss'],
})
export class CreateUpdateDeviceConnectionComponent extends OnDestroyMixin() implements OnInit {
  apiKeyRoles: IUserRole[];
  appUserRole: typeof AppUserRole = AppUserRole;
  categories: any[];
  deviceConnectionForm: FormGroup;
  deviceConnectionIdentifier: Observable<string>;
  deviceConnectionState: IDeviceConnectionState;
  deviceConnectionToken: Observable<string>;
  hasErrors: boolean = false;
  httpCustomErrorCode: string | any;
  isConfirmCloseModalOpen: boolean = false;
  isLoading: boolean = true;
  isSubmitted: boolean = false;
  isSubmitting: Observable<boolean>;
  linkToNavigate: string;
  modalType: string;
  openConfirmChangeTokenModal: boolean = false;
  requestCreateNewToken: boolean = false;
  selectedCategory: IEntryModel;
  selectedDeviceConnection: IDeviceConnection;
  successAddUpdate: boolean = false;
  apiKeysFeatureToggleIsEnabled: boolean;
  ramProtectorMigrationFeatureToggleIsEnabled: boolean;
  migrationIsInProgress: boolean = false;
  openCancelMigrationConfirmationBanner: boolean;
  openMigrationConfirmationBannerForNetworkInstallation: boolean;

  scrollbarOptions: IScrollOptions = CommonConstants.scrollbarOptions;
  deviceCategories: typeof DeviceConnectionConstants.deviceCategories = DeviceConnectionConstants.deviceCategories;
  tabs: { GENERAL: string; MIGRATION: string } = DeviceConnectionConstants.createEditModalTabs;
  activeTab: string = DeviceConnectionConstants.createEditModalTabs.GENERAL;

  userActions: UserActions = inject(UserActions);
  translateService: TranslateService = inject(TranslateService);
  notificationsService: NotificationsService = inject(NotificationsService);
  deviceConnectionsActions: DeviceConnectionActions = inject(DeviceConnectionActions);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<IApplicationState>,
  ) {
    super();

    this.loadingStatus();

    this.router.events?.subscribe((event: any) => {
      this.linkToNavigate = event.url;
    });
  }

  @HostListener(CommonConstants.popStateWindowEvent, ['$event'])
  onPopState(event: any): void {
    event.returnValue = false;
  }

  @HostListener(CommonConstants.beforeUnloadWindowEvent, ['$event'])
  handleBeforeUnload($event: any): void {
    if (this.hasUnsavedData()) {
      $event.returnValue = this.hasUnsavedData();
    }
  }

  navigateBack(isOpen: boolean): void {
    if (isOpen) {
      this.resetOnClose();
      this.deviceConnectionForm.markAsPristine();

      const returnLocation: string =
        window.location.pathname === this.linkToNavigate || !this.linkToNavigate
          ? 'device-connection'
          : this.linkToNavigate;

      this.router.navigate([returnLocation]);

      return;
    }

    this.isConfirmCloseModalOpen = false;
  }

  canDeactivate(): boolean {
    if (this.deviceConnectionForm.dirty) {
      this.confirmCloseModalOpen();

      return false;
    }

    this.resetOnClose();
    this.isConfirmCloseModalOpen = false;

    return true;
  }

  ngOnInit(): void {
    this.deviceConnectionsActions.requestDeviceConnectionCategories();

    this.readApiKeysFeatureToggle();
    this.readRAMProtectorMigrationFeatureToggle();
    this.buildForm();
    this.populateForm();
    this.readRoleList();
    this.getDeviceConnectionIdentifier();
    this.readSelectedDeviceDataFromStore();
    this.readDeviceConnectionIdentifierLoadingState();

    this.deviceConnectionIdentifier = this.store.pipe(
      select(selectDeviceConnectionIdentifier),
      map((state: IStoreApiItem<IDeviceConnectionToken>) => state.data?.token),
      takeUntil(this.destroy),
    );

    this.store
      .pipe(
        select(selectDeviceConnectionCategories),
        map((state: IStoreApiList<IEntryModel[]>) => state.data),
        takeUntil(this.destroy),
      )
      .subscribe((response: IEntryModel[]) => {
        if (response) {
          this.categories = response.map((entry: IEntryModel) => {
            const localDevice: IEntryModel = DeviceConnectionConstants.deviceConnectionCategories.find(
              (t: IEntryModel) => t.value === entry.value,
            );

            return {
              ...entry,
              icon: localDevice?.icon,
              localizedName: localDevice.localizedName,
              label: this.translateService.instant(localDevice?.localizedName || entry.value),
            };
          });
        }
      });

    if (this.apiKeysFeatureToggleIsEnabled) {
      this.userActions.requestUserRoleList();
    }
  }

  buildForm(): void {
    this.deviceConnectionForm = new FormGroup({
      aggregateId: new FormControl(null),
      category: new FormControl(null, Validators.required),
      identification: new FormControl(null, Validators.required),
      name: new FormControl(null),
      assignedFireStation: new FormControl(null),
      assignedVehicle: new FormControl(null),
      assignedPrimaryUser: new FormControl(null),
      description: new FormControl(null),
      authenticationToken: new FormControl({ value: null, disabled: true }),
      registrationTimestamp: new FormControl(null),
      lastUploadTimestamp: new FormControl(null),
      version: new FormControl(null),
      firmwareVersion: new FormControl({ value: null, disabled: true }),
    });
  }

  setActiveTab(tab: string): void {
    if (this.isLoading) {
      return;
    }

    this.activeTab = tab;
    this.populateForm();
  }

  confirmCloseModalOpen(): void {
    this.isConfirmCloseModalOpen = true;
  }

  readApiKeysFeatureToggle(): void {
    this.store
      .pipe(
        select(selectFeaturesToggleList),
        map((state: IStoreApiList<IFeatureToggle[]>) =>
          state.data?.find((ft: IFeatureToggle) => Object.values(ft).includes(AppModulesTypes.generalAPIKeys)),
        ),
        takeUntil(this.destroy),
      )
      .subscribe((response: IFeatureToggle) => {
        this.apiKeysFeatureToggleIsEnabled = response?.isEnabled;
      });
  }

  readRAMProtectorMigrationFeatureToggle(): void {
    this.store
      .pipe(
        select(selectFeaturesToggleList),
        map((state: IStoreApiList<IFeatureToggle[]>) =>
          state.data?.find((ft: IFeatureToggle) => Object.values(ft).includes(AppModulesTypes.ramProtectorMigration)),
        ),
        takeUntil(this.destroy),
      )
      .subscribe((response: IFeatureToggle) => {
        this.ramProtectorMigrationFeatureToggleIsEnabled = response?.isEnabled;
      });
  }

  populateForm(): void {
    this.route.params.pipe(takeUntil(this.destroy)).subscribe((params: Params) => {
      if (!params.id) {
        this.modalType = CommonConstants.modalType.CREATE;

        return;
      }

      this.modalType = CommonConstants.modalType.UPDATE;

      this.deviceConnectionForm.get('category').disable();
      this.deviceConnectionForm.get('identification').disable();
      this.deviceConnectionsActions.requestDeviceConnectionById(params.id);

      localStorage.removeItem(DeviceConnectionConstants.savedDeviceConfigurationID);
    });
  }

  loadingStatus(): void {
    this.isSubmitting = combineLatest([
      this.store.select(selectDeviceConnection),
      this.store.select(selectUpdatedDeviceConnectionAuthToken),
    ]).pipe(
      takeUntil(this.destroy),
      map(
        ([deviceState, tokenStatus]: [IStoreApiList<IDeviceConnection>, IStoreApiItem<IDeviceConnectionToken>]) =>
          deviceState.isLoading || tokenStatus.isLoading,
      ),
    );
  }

  readSelectedDeviceDataFromStore(): void {
    this.store
      .pipe(
        select(selectDeviceConnection),
        filter(
          (deviceConnection: IStoreApiItem<IDeviceConnection>) =>
            deviceConnection.data !== null || deviceConnection.errors !== null,
        ),
        takeUntil(this.destroy),
      )
      .subscribe((selectedDeviceConnection: IStoreApiItem<IDeviceConnection>) => {
        this.httpCustomErrorCode = selectedDeviceConnection.errors?.error.code?.toString();

        if (selectedDeviceConnection.data) {
          this.isLoading = false;
          this.selectedDeviceConnection = this.setData(selectedDeviceConnection);

          if (this.requestCreateNewToken) {
            this.deviceConnectionForm.get('version').setValue(this.selectedDeviceConnection.version);
            this.deviceConnectionForm
              .get('authenticationToken')
              .setValue(this.selectedDeviceConnection.authenticationToken);

            return;
          }

          if (this.selectedDeviceConnection.category === 'api-key') {
            this.deviceConnectionForm.addControl('role', new FormControl('', [Validators.required]));
          }

          this.deviceConnectionForm.patchValue(this.selectedDeviceConnection);
        }

        if (
          this.httpCustomErrorCode === DeviceConnectionNotificationConstants.notificationCodes.ENTITY_NOT_FOUND.value
        ) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            this.httpCustomErrorCode,
            DeviceConnectionNotificationConstants.notificationCodes,
            { value: this.deviceConnectionForm.value.name },
          );

          setTimeout(() => {
            this.router.navigate([DeviceConnectionModuleRoutes.deviceConnection]);
          }, CommonConstants.DEFAULT_REDIRECT_TIMEOUT);
        }
      });
  }

  readDeviceConnectionIdentifierLoadingState(): void {
    this.store
      .pipe(
        select(selectDeviceConnectionIdentifier),
        map((state: IStoreApiItem<IDeviceConnectionToken>) => state.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((loading: boolean) => {
        this.isLoading = loading;
      });
  }

  readRoleList(): void {
    this.store
      .pipe(
        select(selectRoleList),
        map((state: IStoreApiList<IUserRole[]>) => state.data),
        takeUntil(this.destroy),
      )
      .subscribe((response: IUserRole[]) => {
        this.apiKeyRoles = response?.map((role: IUserRole) => ({
          ...role,
          label: this.translateService.instant(
            UserManagementConstants.userRoles.roles.find((t: IUserRole) => t.value === role.value)?.localizedName,
          ),
        }));
      });
  }

  setData(selectedDeviceConnection: IStoreApiItem<IDeviceConnection>): IDeviceConnection {
    return {
      ...selectedDeviceConnection.data,
      lastUploadTimestamp: selectedDeviceConnection.data.lastUploadTimestamp || null,
      registrationTimestamp: selectedDeviceConnection.data.registrationTimestamp || null,
    };
  }

  generateNewToken(): void {
    if (this.deviceConnectionForm.get('authenticationToken').value) {
      /* istanbul ignore else */
      if (this.modalType === CommonConstants.modalType.UPDATE) {
        this.openConfirmChangeTokenModal = true;
      }
    }
  }

  closeConfirmModal(isConfirmed: boolean): void {
    this.openConfirmChangeTokenModal = false;

    if (!isConfirmed) return;

    this.deviceConnectionsActions.requestUpdateDeviceConnectionAuthToken(
      this.selectedDeviceConnection?.aggregateId,
      this.selectedDeviceConnection?.version,
    );

    /* istanbul ignore next */
    this.store
      .pipe(
        select(selectUpdatedDeviceConnectionAuthToken),
        map((state: IStoreApiItem<IDeviceConnectionToken>) => !state.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((isLoaded: boolean) => {
        if (isLoaded) {
          this.deviceConnectionsActions.requestDeviceConnectionById(this.selectedDeviceConnection?.aggregateId);
          this.requestCreateNewToken = true;
        }
      });
  }

  getDeviceConnectionIdentifier(): void {
    this.deviceConnectionsActions.requestDeviceConnectionIdentifier();
  }

  setSelectedCategory(category: IEntryModel): void {
    if (!category) {
      this.selectedCategory = null;

      return;
    }

    this.selectedCategory = { ...category };

    if (category?.value === DeviceConnectionConstants.deviceCategories.API_KEY) {
      this.deviceConnectionForm.addControl(
        'role',
        new FormControl(this.appUserRole.firefighter, [Validators.required]),
      );

      return;
    }

    this.deviceConnectionForm.removeControl('role');
  }

  resetOnClose(): void {
    this.isSubmitted = false;
    this.deviceConnectionForm.reset();
    this.deviceConnectionsActions.resetDeviceConnectionIdentifier();
  }

  onSubmit(): void {
    this.isSubmitted = true;

    if (this.deviceConnectionForm.invalid) {
      return;
    }

    this.convertDatesToUTC();

    this.modalType === CommonConstants.modalType.CREATE
      ? this.addNewDeviceConnection()
      : this.updateExistingDeviceConnection();
  }

  convertDatesToUTC(): void {
    this.deviceConnectionForm
      .get('registrationTimestamp')
      .setValue(convertToDateTimeFormat(this.deviceConnectionForm.getRawValue().registrationTimestamp));

    this.deviceConnectionForm
      .get('lastUploadTimestamp')
      .setValue(convertToDateTimeFormat(this.deviceConnectionForm.getRawValue().lastUploadTimestamp));
  }

  addNewDeviceConnection(): void {
    const formData: IDeviceConnection = this.deviceConnectionForm.getRawValue();

    this.deleteIdentificationFieldForRAMDevices(formData);

    this.deviceConnectionsActions.requestAddDeviceConnection({ ...formData });
    this.store
      .pipe(
        select(selectDeviceConnection),
        filter((selectedDeviceConnection: IStoreApiItem<IDeviceConnection>) => !selectedDeviceConnection.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((selectedDeviceConnection: IStoreApiItem<IDeviceConnection | any>) => {
        if (selectedDeviceConnection.errors) {
          this.dealDeviceConnectionErrors(selectedDeviceConnection);
          this.deviceConnectionsActions.resetDeviceConnection();

          return;
        }

        if (selectedDeviceConnection.data && selectedDeviceConnection.isSuccess) {
          this.successAddUpdate = true;
          const deviceId: string = localStorage.getItem(DeviceConnectionConstants.savedDeviceConfigurationID);

          this.handleDeviceCategory();

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            DeviceConnectionNotificationConstants.notificationCodes.ADD_DEVICE_CONNECTION_SUCCESS,
            DeviceConnectionNotificationConstants.notificationCodes,
            { appOrHub: this.selectedCategory.label },
          );

          this.deviceConnectionForm.disable();
          this.deviceConnectionForm.markAsPristine();
          this.deviceConnectionsActions.resetDeviceConnection();

          if (deviceId) {
            this.router.navigate(['device-connection', 'update', deviceId]);
          }
        }
      });
  }

  updateExistingDeviceConnection(): void {
    let updateObj: IDeviceConnection = this.deviceConnectionForm.getRawValue();

    this.deleteIdentificationFieldForRAMDevices(updateObj);

    updateObj = {
      ...updateObj,
      name: updateObj.name ? updateObj.name : null,
      description: updateObj.description ? updateObj.description : null,
      assignedVehicle: updateObj.assignedVehicle ? updateObj.assignedVehicle : null,
      assignedPrimaryUser: updateObj.assignedPrimaryUser ? updateObj.assignedPrimaryUser : null,
      assignedFireStation: updateObj.assignedFireStation ? updateObj.assignedFireStation : null,
    };

    this.deviceConnectionsActions.requestUpdateDeviceConnection(updateObj);
    this.store
      .pipe(
        select(selectDeviceConnection),
        filter((selectedDeviceConnection: IStoreApiItem<IDeviceConnection>) => !selectedDeviceConnection.isLoading),
        take(1),
      )
      .subscribe((selectedDeviceConnection: IStoreApiItem<IDeviceConnection>) => {
        if (selectedDeviceConnection.errors) {
          this.dealDeviceConnectionErrors(selectedDeviceConnection);
        } else if (selectedDeviceConnection.isSuccess) {
          this.successAddUpdate = true;
          this.handleDeviceCategory();

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            this.getUpdateNotificationType(),
            DeviceConnectionNotificationConstants.notificationCodes,
            { appOrHub: this.selectedCategory.label },
          );
          this.deviceConnectionForm.markAsPristine();
          this.populateForm();
        }
      });
  }

  deleteIdentificationFieldForRAMDevices(formData: IDeviceConnection): void {
    if (this.deviceConnectionForm.get('category').value === this.deviceCategories.RAM_CONNECTOR) {
      this.deviceConnectionForm.get('category').clearValidators();
      this.deviceConnectionForm.get('category').updateValueAndValidity();

      delete formData.identification;
    }
  }

  getUpdateNotificationType(): INotificationMessage {
    const notificationType: INotificationMessage =
      this.selectedCategory.value === DeviceConnectionConstants.deviceCategories.APP
        ? DeviceConnectionNotificationConstants.notificationCodes.UPDATE_APP_DEVICE_CONNECTION_SUCCESS
        : DeviceConnectionNotificationConstants.notificationCodes.UPDATE_DEVICE_CONNECTION_SUCCESS;

    return notificationType;
  }

  handleDeviceCategory(): void {
    if (this.selectedDeviceConnection?.category) {
      this.selectedCategory = {
        ...this.selectedCategory,
        label: this.translateService.instant(
          DeviceConnectionConstants.deviceConnectionCategories.find(
            (t: IEntryModel) => t.value === this.selectedDeviceConnection.category,
          )?.localizedName || '',
        ),
        value: this.selectedDeviceConnection.category as string,
      };
    }
  }

  dealDeviceConnectionErrors(selectedDeviceConnection: IStoreApiItem<IDeviceConnection>): void {
    this.hasErrors = true;
    this.httpCustomErrorCode = selectedDeviceConnection.errors.error.code.toString();
    let notificationType: string = null;

    if (
      this.httpCustomErrorCode ===
        DeviceConnectionNotificationConstants.notificationCodes.DEVICE_INVALID_IDENTIFIER.value ||
      this.httpCustomErrorCode === NotificationConstants.commonCodes.IDENTIFIER_SHOULD_BE_UNIQUE.value
    ) {
      this.deviceConnectionForm.get('identification').setErrors({ invalid: true });
      notificationType = CommonConstants.notificationType.HIDDEN;
    }

    this.notificationsService.requestShowNotification(
      notificationType ? notificationType : CommonConstants.notificationType.ERROR,
      this.httpCustomErrorCode,
      DeviceConnectionNotificationConstants.notificationCodes,
      { value: this.deviceConnectionForm.value.name },
    );
  }

  getCancelMigrationBannerState(event: boolean): void {
    this.openCancelMigrationConfirmationBanner = event;
  }

  hasUnsavedData(): boolean {
    return this.deviceConnectionForm.dirty;
  }
}
