import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { ITestResultDetails, ITestResultPage } from '../../../models/test-results.model';
import { EquipmentTestResultsActionTypes } from './equipment-test-results-action-types';

export class RequestTestResultsExport implements Action {
  readonly type: EquipmentTestResultsActionTypes.REQUEST_TEST_RESULTS_EXPORT =
    EquipmentTestResultsActionTypes.REQUEST_TEST_RESULTS_EXPORT;

  constructor(public payload?: any) {}
}
export class RequestTestResultsPage implements Action {
  readonly type: EquipmentTestResultsActionTypes.REQUEST_TEST_RESULTS_PAGE =
    EquipmentTestResultsActionTypes.REQUEST_TEST_RESULTS_PAGE;

  constructor(public payload?: any) {}
}
export class ReceiveTestResultPage implements Action {
  readonly type: EquipmentTestResultsActionTypes.RECEIVE_TEST_RESULTS_PAGE =
    EquipmentTestResultsActionTypes.RECEIVE_TEST_RESULTS_PAGE;

  constructor(public payload: ITestResultPage) {}
}
export class ErrorReceiveTestResultPage implements Action {
  readonly type: EquipmentTestResultsActionTypes.ERROR_RECEIVE_TEST_RESULTS_PAGE =
    EquipmentTestResultsActionTypes.ERROR_RECEIVE_TEST_RESULTS_PAGE;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestTestResultsById implements Action {
  readonly type: EquipmentTestResultsActionTypes.REQUEST_TEST_RESULTS_BY_ID =
    EquipmentTestResultsActionTypes.REQUEST_TEST_RESULTS_BY_ID;

  constructor(public payload?: any) {}
}
export class ReceiveTestResultsById implements Action {
  readonly type: EquipmentTestResultsActionTypes.RECEIVE_TEST_RESULTS_BY_ID =
    EquipmentTestResultsActionTypes.RECEIVE_TEST_RESULTS_BY_ID;

  constructor(public payload: ITestResultDetails) {}
}
export class ErrorReceiveTestResultsById implements Action {
  readonly type: EquipmentTestResultsActionTypes.ERROR_RECEIVE_TEST_RESULTS_BY_ID =
    EquipmentTestResultsActionTypes.ERROR_RECEIVE_TEST_RESULTS_BY_ID;

  constructor(public payload: HttpErrorResponse) {}
}
