<p-table
  #taskValuesTable
  styleClass="p-datatable-striped p-datatable-gridlines"
  scrollHeight="flex"
  class="fireground-table automatic-table"
  [value]="taskValues"
  [scrollable]="true"
>
  <ng-template pTemplate="header" let-columns>
    <tr class="column-table-width table-header-column-filter">
      <th scope="col" class="flex-direction-column">
        {{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_DESCRIPTION' | translate }}

        <div class="p-fluid custom-filter-wrapp">
          <odx-form-field variant="simple">
            <input
              type="text"
              ignisDisableAutocomplete
              odxFormFieldControl
              [ngClass]="{ 'text-filter-padding-right': tableService.getInputFilterValueLength('description') > 0 }"
              [id]="'description'"
              data-test-id="equipment_task_documentation.test_values_description"
              placeholder="{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_DESCRIPTION_PLACEHOLDER' | translate }}"
              (input)="taskValuesTable.filter($event?.target?.value, 'description', 'contains')"
            />

            <odx-action-group odxFormFieldSuffix *ngIf="tableService.getInputFilterValueLength('description') > 0">
              <button
                odxButton
                size="medium"
                (click)="
                  tableService.clearTextFilter('description');
                  taskValuesTable.filter($event?.target?.value, 'description', 'contains')
                "
              >
                <odx-icon name="close" size="medium"></odx-icon>
              </button>
            </odx-action-group>
          </odx-form-field>
        </div>
      </th>
      <th scope="col" class="flex-direction-column">
        {{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_ASSESSMENT' | translate }}

        <div class="p-fluid custom-filter-wrapp">
          <odx-form-field variant="simple">
            <input
              type="text"
              ignisDisableAutocomplete
              odxFormFieldControl
              [ngClass]="{ 'text-filter-padding-right': tableService.getInputFilterValueLength('assessment') > 0 }"
              [id]="'assessment'"
              data-test-id="equipment_task_documentation.test_values_assessment"
              placeholder="{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_ASSESSMENT_PLACEHOLDER' | translate }}"
              (input)="taskValuesTable.filter($event?.target?.value, 'assessment', 'contains')"
            />

            <odx-action-group odxFormFieldSuffix *ngIf="tableService.getInputFilterValueLength('assessment') > 0">
              <button
                odxButton
                size="medium"
                (click)="
                  tableService.clearTextFilter('assessment');
                  taskValuesTable.filter($event?.target?.value, 'assessment', 'contains')
                "
              >
                <odx-icon name="close" size="medium"></odx-icon>
              </button>
            </odx-action-group>
          </odx-form-field>
        </div>
      </th>
      <th scope="col">
        {{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_VALUE' | translate }}
      </th>
      <th scope="col">
        {{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_MIN_VALUE' | translate }}
      </th>
      <th scope="col">
        {{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_MAX_VALUE' | translate }}
      </th>
      <th scope="col">
        {{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_UNIT' | translate }}
      </th>
      <th scope="col" class="flex-direction-column">
        {{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_PARAMETERS' | translate }}

        <div class="p-fluid custom-filter-wrapp">
          <odx-form-field variant="simple">
            <input
              type="text"
              ignisDisableAutocomplete
              odxFormFieldControl
              [ngClass]="{ 'text-filter-padding-right': tableService.getInputFilterValueLength('parameters') > 0 }"
              [id]="'parameters'"
              data-test-id="equipment_task_documentation.test_values_parameters"
              placeholder="{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_PARAMETERS_PLACEHOLDER' | translate }}"
              (input)="taskValuesTable.filter($event?.target?.value, 'parameters', 'contains')"
            />

            <odx-action-group odxFormFieldSuffix *ngIf="tableService.getInputFilterValueLength('parameters') > 0">
              <button
                odxButton
                size="medium"
                (click)="
                  tableService.clearTextFilter('parameters');
                  taskValuesTable.filter($event?.target?.value, 'parameters', 'contains')
                "
              >
                <odx-icon name="close" size="medium"></odx-icon>
              </button>
            </odx-action-group>
          </odx-form-field>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-values>
    <tr class="column-table-width">
      <td data-column="description">{{ values.description }}</td>
      <td data-column="assessment">{{ assessmentsTranslation[values.assessment] | translate }}</td>
      <td data-column="value">{{ values.value }}</td>
      <td data-column="minValue">{{ values.minValue }}</td>
      <td data-column="maxValue">{{ values.maxValue }}</td>
      <td data-column="unit">{{ values.unit }}</td>
      <td data-column="parameters">{{ values.parameters }}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="7">{{ 'EQUIPMENT_TASK_RESULTS.STR_NO_TEST_VALUES' | translate }}</td>
    </tr>
  </ng-template>
</p-table>
