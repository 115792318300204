// eslint-disable-next-line no-shadow
export enum EquipmentHierarchyActionTypes {
  REQUEST_EQUIPMENT_HIERARCHY = 'REQUEST_EQUIPMENT_HIERARCHY',
  RECEIVE_EQUIPMENT_HIERARCHY = 'RECEIVE_EQUIPMENT_HIERARCHY',
  ERROR_RECEIVE_EQUIPMENT_HIERARCHY = 'ERROR_RECEIVE_EQUIPMENT_HIERARCHY',

  REQUEST_ADD_EQUIPMENT_HIERARCHY = 'REQUEST_ADD_EQUIPMENT_HIERARCHY',
  RECEIVE_ADD_EQUIPMENT_HIERARCHY = 'RECEIVE_ADD_EQUIPMENT_HIERARCHY',
  ERROR_RECEIVE_ADD_EQUIPMENT_HIERARCHY = 'ERROR_RECEIVE_ADD_EQUIPMENT_HIERARCHY',

  REQUEST_EDIT_EQUIPMENT_HIERARCHY = 'REQUEST_EDIT_EQUIPMENT_HIERARCHY',
  RECEIVE_EDIT_EQUIPMENT_HIERARCHY = 'RECEIVE_EDIT_EQUIPMENT_HIERARCHY',
  ERROR_RECEIVE_EDIT_EQUIPMENT_HIERARCHY = 'ERROR_RECEIVE_EDIT_EQUIPMENT_HIERARCHY',

  REQUEST_DELETE_EQUIPMENT_HIERARCHY = 'REQUEST_DELETE_EQUIPMENT_HIERARCHY',
  RECEIVE_DELETE_EQUIPMENT_HIERARCHY = 'RECEIVE_DELETE_EQUIPMENT_HIERARCHY',
  ERROR_RECEIVE_DELETE_EQUIPMENT_HIERARCHY = 'ERROR_RECEIVE_DELETE_EQUIPMENT_HIERARCHY',

  REQUEST_IMPORT_EQUIPMENT_HIERARCHY = 'REQUEST_IMPORT_EQUIPMENT_HIERARCHY',
  RECEIVE_IMPORT_EQUIPMENT_HIERARCHY = 'RECEIVE_IMPORT_EQUIPMENT_HIERARCHY',
  ERROR_RECEIVE_IMPORT_EQUIPMENT_HIERARCHY = 'ERROR_RECEIVE_IMPORT_EQUIPMENT_HIERARCHY',
}
