import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { StorageMap } from '@ngx-pwa/local-storage';
import { combineLatest, filter, interval, Subscription, take, takeUntil } from 'rxjs';
import { CommonConstants, IApplicationState, IStoreApiItem, NotificationsService, PropertyBag } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins';
import { checkRAMConnectorPort } from 'src/app/common/utils';
import { DeviceConnectionConstants } from 'src/app/device-connection/constants/device-connection.constants';
import { IDeviceConnection, IMigrationID } from 'src/app/device-connection/models/device-connection.model';
import { IProtectorMigrationStatus } from 'src/app/device-connection/models/protector-migration.model';
import { DeviceConnectionActions } from 'src/app/device-connection/state/actions/device-connection.actions';
import {
  selectCancelProtectorSoftwareMigration,
  selectCancelProtectorSoftwareMigrationInRAM,
  selectProtectorMigrationStatus,
  selectStartProtectorSoftwareMigration,
} from 'src/app/device-connection/state/selectors/device-connection.selector';
import { IRAMStatus, ITestStatus, IToken } from 'src/app/workshop/models';
import { RAMActions } from 'src/app/workshop/state/actions/ram';
import { selectCheckRAMConnectorStatus, selectRAMConnectorToken } from 'src/app/workshop/state/selectors';

@Component({
  selector: 'ignis-protector-software-migration-tab',
  templateUrl: './protector-software-migration-tab.component.html',
  styleUrl: './protector-software-migration-tab.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProtectorSoftwareMigrationTabComponent extends OnDestroyMixin() implements OnInit, OnDestroy {
  token: string;
  migrationId: string;
  displayTable: boolean;
  liveMigrationStatus: string;
  isNetworkInstallation: boolean;
  migrationStatusOfDevice: string;
  disableMigrateBtn: boolean = true;
  pollingMilliseconds: number = 10000;
  intervalToGetMigrationStatus: Subscription;
  migrationStatus: IProtectorMigrationStatus;
  intervalToGetRAMConnectorStatus: Subscription;
  isIntervalToGetMigrationStatusRunning: boolean;
  openCancelMigrationConfirmationModal: boolean = false;
  openMigrationConfirmationBannerForNetworkInstallation: boolean = false;
  migrationStatuses: Partial<PropertyBag> = DeviceConnectionConstants.protectorSoftwareMigrationStatuses;

  @Input() selectedDeviceConnection: IDeviceConnection;
  @Output() emitMigrationInProgress: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output() handleCancelMigrationBannerState: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output() handleNetworkInstallBannerState: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  storage: StorageMap = inject(StorageMap);
  ramActions: RAMActions = inject(RAMActions);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  notificationsService: NotificationsService = inject(NotificationsService);
  store: Store<IApplicationState> = inject<Store<IApplicationState>>(Store);
  deviceConnectionActions: DeviceConnectionActions = inject(DeviceConnectionActions);

  ngOnInit(): void {
    this.checkIfSelectedDeviceHaveMigrationInfo();
  }

  checkIfSelectedDeviceHaveMigrationInfo(): void {
    if (
      !this.selectedDeviceConnection.migrationInfo?.status ||
      Number(localStorage.getItem(CommonConstants.availableRAMConnectorPort)) < 1
    ) {
      checkRAMConnectorPort({
        ramActions: this.ramActions,
        store: this.store,
        notificationsService: this.notificationsService,
      }).then((response: IRAMStatus) => {
        if (response.port) {
          this.ramActions.requestCheckRAMConnectorStatus(Number(response.port));

          this.cdr.detectChanges();
        }
      });
    } else {
      this.migrationId = this.selectedDeviceConnection.migrationInfo?.migrationId;
      this.migrationStatusOfDevice = this.selectedDeviceConnection.migrationInfo?.status;
      this.deviceConnectionActions.requestProtectorMigrationStatus(this.migrationId);
      this.readProtectorMigrationStatusFromStore();

      if (this.migrationStatusOfDevice === this.migrationStatuses.IN_PROGRESS) {
        this.ramActions.requestCheckRAMConnectorStatus(
          Number(localStorage.getItem(CommonConstants.availableRAMConnectorPort)),
        );
        this.startIntervalToGetRAMConnectorStatus();
      }
    }

    this.readRAMConnectorStatusFromTheStore();
    this.readRAMConnectorTokenDataFromTheStore();
    this.readRAMConnectorClosedStatus();
  }

  readRAMConnectorClosedStatus(): void {
    this.storage.watch(CommonConstants.RAMConnectorIsClosed).subscribe((ramStatus: boolean) => {
      if (ramStatus) {
        this.disableMigrateBtn = true;

        this.storage.delete(CommonConstants.RAMConnectorIsClosed).subscribe(() => {
          return;
        });
      }

      this.cdr.detectChanges();
    });

    this.store
      .pipe(
        select(selectCheckRAMConnectorStatus),
        filter(
          (ramConnectorStatus: IStoreApiItem<ITestStatus>) =>
            ramConnectorStatus.data !== null && !ramConnectorStatus.isLoading,
        ),
        takeUntil(this.destroy),
      )
      .subscribe((response: IStoreApiItem<ITestStatus>) => {
        if (response.data) {
          this.disableMigrateBtn = this.selectedDeviceConnection.authenticationToken !== this.token;
        }

        this.cdr.detectChanges();
      });
  }

  readRAMConnectorStatusFromTheStore(): void {
    this.store
      .select(selectCheckRAMConnectorStatus)
      .pipe(
        filter(
          (ramConnectorStatus: IStoreApiItem<ITestStatus>) =>
            ramConnectorStatus.data !== null && !ramConnectorStatus.isLoading,
        ),
        take(1),
      )
      .subscribe((ramConnectorStatus: IStoreApiItem<ITestStatus>) => {
        if (ramConnectorStatus.data) {
          this.isNetworkInstallation = ramConnectorStatus.data.isNetworkInstallation;
          this.ramActions.requestRAMConnectorToken();
        }

        this.cdr.detectChanges();
      });
  }

  readRAMConnectorTokenDataFromTheStore(): void {
    this.store
      .pipe(
        select(selectRAMConnectorToken),
        filter((token: IStoreApiItem<IToken>) => token.data !== null && !token.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<IToken>) => {
        if (response.data?.token) {
          this.token = response.data.token;
          this.disableMigrateBtn = this.selectedDeviceConnection.authenticationToken !== this.token;
          this.migrationStatusOfDevice = this.selectedDeviceConnection.migrationInfo?.status;
          this.migrationId = this.selectedDeviceConnection.migrationInfo?.migrationId;

          this.getStatusIfMigrationIdExistsAndDeviceMigrationStatusIsNotInProgress();
          this.getStatusIfMigrationIsInProgress();
        }

        this.cdr.detectChanges();
      });
  }

  getStatusIfMigrationIsInProgress(): void {
    if (this.migrationStatusOfDevice === this.migrationStatuses.IN_PROGRESS) {
      this.getProtectorMigrationStatus();
    }
  }

  getStatusIfMigrationIdExistsAndDeviceMigrationStatusIsNotInProgress(): void {
    if (this.migrationId && this.migrationStatusOfDevice !== this.migrationStatuses.IN_PROGRESS) {
      this.deviceConnectionActions.requestProtectorMigrationStatus(this.migrationId);
      this.readProtectorMigrationStatusFromStore();
    }
  }

  startProtectorSoftwareMigration(): void {
    this.deviceConnectionActions.requestStartProtectorMigration();
    this.readStartProtectorSoftwareMigrationStateFromStore();
  }

  readStartProtectorSoftwareMigrationStateFromStore(): void {
    this.store
      .pipe(
        select(selectStartProtectorSoftwareMigration),
        filter((response: IStoreApiItem<IMigrationID>) => response.data !== null && !response.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<IMigrationID>) => {
        this.migrationId = response.data.migrationId;
        this.migrationStatusOfDevice = this.migrationStatuses.IN_PROGRESS;

        this.selectedDeviceConnection.migrationInfo = {
          status: this.migrationStatuses.IN_PROGRESS,
          migrationId: this.migrationId,
        };

        this.getProtectorMigrationStatus();

        this.ramActions.requestCheckRAMConnectorStatus(
          Number(localStorage.getItem(CommonConstants.availableRAMConnectorPort)),
        );
        this.startIntervalToGetRAMConnectorStatus();

        this.cdr.detectChanges();
      });
  }

  cancelProtectorSoftwareMigration(): void {
    this.deviceConnectionActions.requestCancelProtectorMigrationInRAM();
    this.deviceConnectionActions.requestCancelProtectorMigration(this.migrationId);

    this.readCancelProtectorSoftwareMigrationStateFromStore();
  }

  readCancelProtectorSoftwareMigrationStateFromStore(): void {
    combineLatest([
      this.store.select(selectCancelProtectorSoftwareMigrationInRAM),
      this.store.select(selectCancelProtectorSoftwareMigration),
    ])
      .pipe(
        filter(
          ([cancelInRam, cancel]: [IStoreApiItem<unknown>, IStoreApiItem<unknown>]) =>
            !cancelInRam.isLoading && !cancel.isLoading,
        ),
        takeUntil(this.destroy),
      )
      .subscribe(([cancelInRam, cancel]: [IStoreApiItem<unknown>, IStoreApiItem<unknown>]) => {
        if (cancelInRam.isSuccess && cancel.isSuccess) {
          this.migrationStatusOfDevice = this.migrationStatuses.CANCELED;
          this.liveMigrationStatus = this.migrationStatuses.CANCELED;
          this.displayTable = false;

          this.selectedDeviceConnection.migrationInfo = {
            status: this.migrationStatuses.CANCELED,
            migrationId: this.migrationId,
          };
        }

        this.cdr.detectChanges();
      });
  }

  openConfirmCloseMigrationBanner(): void {
    this.openCancelMigrationConfirmationModal = true;
    this.handleCancelMigrationBannerState.emit(this.openCancelMigrationConfirmationModal);
  }

  openNetworkInstallationBanner(): void {
    this.openMigrationConfirmationBannerForNetworkInstallation = true;
    this.handleNetworkInstallBannerState.emit(this.openMigrationConfirmationBannerForNetworkInstallation);
  }

  cancelMigration(event: boolean): void {
    this.openCancelMigrationConfirmationModal = false;
    this.handleCancelMigrationBannerState.emit(this.openCancelMigrationConfirmationModal);

    if (event) {
      this.cancelProtectorSoftwareMigration();
    }
  }

  startMigration(event: boolean): void {
    this.openMigrationConfirmationBannerForNetworkInstallation = false;
    this.handleNetworkInstallBannerState.emit(this.openMigrationConfirmationBannerForNetworkInstallation);

    if (event) {
      this.startProtectorSoftwareMigration();
    }
  }

  getProtectorMigrationStatus(): void {
    this.deviceConnectionActions.requestProtectorMigrationStatus(this.migrationId);

    this.startIntervalToRequestMigrationStatus();
    this.readProtectorMigrationStatusFromStore();
  }

  startIntervalToRequestMigrationStatus(): void {
    if (!this.intervalToGetMigrationStatus || this.intervalToGetMigrationStatus.closed) {
      this.intervalToGetMigrationStatus = interval(this.pollingMilliseconds).subscribe(() => {
        this.deviceConnectionActions.requestProtectorMigrationStatus(this.migrationId);

        this.cdr.detectChanges();
      });
    }
  }

  stopIntervalToRequestMigrationStatus(): void {
    if (this.intervalToGetMigrationStatus && !this.intervalToGetMigrationStatus.closed) {
      this.intervalToGetMigrationStatus.unsubscribe();
      this.intervalToGetMigrationStatus.remove(this.intervalToGetMigrationStatus);
    }
  }

  startIntervalToGetRAMConnectorStatus(): void {
    if (!this.intervalToGetRAMConnectorStatus || this.intervalToGetRAMConnectorStatus.closed) {
      this.intervalToGetRAMConnectorStatus = interval(this.pollingMilliseconds).subscribe(() => {
        this.ramActions.requestCheckRAMConnectorStatus(
          Number(localStorage.getItem(CommonConstants.availableRAMConnectorPort)),
        );

        this.cdr.detectChanges();
      });
    }
  }

  stopIntervalToGetRAMConnectorStatus(): void {
    if (this.intervalToGetRAMConnectorStatus && !this.intervalToGetRAMConnectorStatus.closed) {
      this.intervalToGetRAMConnectorStatus.unsubscribe();
      this.intervalToGetRAMConnectorStatus.remove(this.intervalToGetRAMConnectorStatus);
    }
  }

  readProtectorMigrationStatusFromStore(): void {
    this.store
      .pipe(
        select(selectProtectorMigrationStatus),
        filter((response: IStoreApiItem<IProtectorMigrationStatus>) => !response.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((response: IStoreApiItem<IProtectorMigrationStatus>) => {
        if (response.data) {
          this.emitMigrationInProgress.emit(true);

          this.liveMigrationStatus = response.data?.state;
          this.migrationStatus = response.data;

          if (
            [
              this.migrationStatuses.COMPLETED_WITH_SUCCESS,
              this.migrationStatuses.COMPLETED_WITH_ERROR,
              this.migrationStatuses.CANCELED,
            ].includes(this.liveMigrationStatus)
          ) {
            this.stopIntervalToRequestMigrationStatus();
            this.stopIntervalToGetRAMConnectorStatus();

            this.migrationStatusOfDevice = this.migrationStatuses.FINISHED;
            this.selectedDeviceConnection.migrationInfo = {
              status: this.migrationStatuses.FINISHED,
              migrationId: this.migrationId,
            };

            this.emitMigrationInProgress.emit(false);

            this.deviceConnectionActions.resetProtectorMigrationStatus();
          }
        }

        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.liveMigrationStatus = null;
    this.migrationStatusOfDevice = null;

    this.stopIntervalToRequestMigrationStatus();
    this.stopIntervalToGetRAMConnectorStatus();
  }
}
