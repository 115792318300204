// eslint-disable-next-line no-shadow
export enum FeatureModuleRouteForNavigationEnum {
  Workshop = '/workshop/workflow',
  Logbook = '/logbook',
  RemoteMonitoring = '/remote-monitoring',
  ReportCenter = '/report-center',
  UserManagement = '/user-management',
  RoleManagement = '/role-management',
  DeviceConnection = '/device-connection',
  Configuration = '/configuration',
  ConfigurationLocation = '/configuration/location',
  ConfigurationOrganizationSettings = 'configuration/organization-settings',
  BAWearer = '/ba-wearer',
}
