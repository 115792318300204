<ng-template odxModal #importEQHierarchyModal>
  <odx-modal-header>
    <odx-area-header>
      {{ 'CONFIGURATION.STR_IMPORT_EQUIPMENT_HIERARCHY_MODAL_TITLE' | translate }}
    </odx-area-header>
  </odx-modal-header>
  <odx-modal-content [ngStyle]="{ overflow: 'visible' }">
    <p class="mb-2">
      {{ 'CONFIGURATION.STR_IMPORT_EQUIPMENT_HIERARCHY_MODAL_LABEL' | translate }}
    </p>

    <p-dropdown
      #dd1
      (onShow)="dropdownService.openDropdown(dd1)"
      (onHide)="dropdownService.closeDropdown(dd1)"
      optionValue="value"
      optionLabel="key"
      [dropdownIcon]="dropdownIconCSSClass"
      [options]="languages"
      [placeholder]="'SETTINGS.STR_SELECT_LANGUAGE_PLACEHOLDER' | translate"
      [showClear]="false"
      (onChange)="selectedLanguage = $event"
      data-test-id="configuration.import_eq_hierarchy_dropdown"
    >
      <ng-template let-option pTemplate="item">
        <span>{{ option.key }}</span>
      </ng-template>
    </p-dropdown>
  </odx-modal-content>

  <odx-modal-footer>
    <button odxButton variant="secondary" [odxModalClose]="true" data-test-id="modal_actions.cancel_btn">
      {{ 'CONFIGURATION.STR_IMPORT_EQUIPMENT_HIERARCHY_CANCEL_BTN' | translate }}
    </button>
    <button
      odxButton
      variant="primary"
      [odxModalClose]="true"
      [disabled]="!selectedLanguage"
      data-test-id="modal_actions.confirm_btn"
      (click)="importEquipmentHierarchy()"
    >
      {{ 'CONFIGURATION.STR_IMPORT_EQUIPMENT_HIERARCHY_CONFIRM_BTN' | translate }}
    </button>
  </odx-modal-footer>
</ng-template>
