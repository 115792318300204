import { CompleteChecklistComponent } from 'src/app/workshop/components/tasks/complete-checklist/complete-checklist.component';
import { CreateUpdateEquipmentComponent } from '../../../components/create-update-equipment/create-update-equipment.component';
import { CompleteBatchChecklistComponent } from '../../../components/tasks/complete-batch-checklist/complete-batch-checklist.component';

export class DeactivateGuard {
  canDeactivate(component: CreateUpdateEquipmentComponent): boolean {
    return component?.canDeactivate();
  }
}

export class CompleteBatchChecklistDeactivateGuard {
  canDeactivate(component: CompleteBatchChecklistComponent): boolean {
    return component?.canDeactivate();
  }
}

export class CompleteChecklistDeactivateGuard {
  canDeactivate(component: CompleteChecklistComponent): boolean {
    return component?.canDeactivate();
  }
}
