<div class="search mt-3">
  <odx-icon
    name="barcode-scan"
    class="workflow-asset-barcode-icon"
    pTooltip="{{ 'WORKSHOP_WORKFLOW_ASSET.STR_BARCODE_SCANNER_BTN_TOOLTIP' | translate }}"
    positionLeft="5"
    positionTop="-5"
    (click)="startBarcodeScanner()"
  ></odx-icon>

  <ignis-barcode-scanner-modal
    [isOpen]="isBarcodeScannerOpened"
    (closeModal)="isBarcodeScannerOpened = $event"
    (scannedBarcode)="getScannedBarcode($event)"
  ></ignis-barcode-scanner-modal>

  <div
    class="odx-form-field--horizontal odx-form-field relative"
    [ngClass]="{ 'has-error': !isBarcodeInputValueValid }"
  >
    <div class="odx-form-field__inner">
      <div class="odx-form-field__control">
        <input
          id="barcode"
          type="text"
          ignisDisableAutocomplete
          ignisNoAllowHtmlTags
          (emitIsInputValueValid)="isBarcodeInputValueValid = $event"
          data-test-id="equipment_workflow_asset.barcode"
          [ngModel]="_barcode"
          (ngModelChange)="_barcode = $event"
          [disabled]="isSubmitting | async"
          #barcodeInput
          (keyup.enter)="getWorkflowAsset()"
          [ngClass]="{ 'custom-padding-right': !isBarcodeInputValueValid }"
          (ngModelChange)="_barcode = $event; isAddEquipmentBtnEnabled = false"
          placeholder="{{ 'WORKSHOP_WORKFLOW_ASSET.STR_WORKFLOW_EQUIPMENT_PLACEHOLDER' | translate }}"
        />

        <odx-icon
          class="clear-barcode"
          name="close"
          size="medium"
          *ngIf="_barcode?.length > 0"
          data-test-id="equipment_workflow_asset.clear_barcode"
          [ngClass]="{ 'disabled-clear-barcode': isSubmitting | async }"
          (click)="_barcode = null; isAddEquipmentBtnEnabled = false; isBarcodeInputValueValid = true"
        ></odx-icon>

        <odx-icon
          *ngIf="!isBarcodeInputValueValid"
          name="info"
          class="custom-validation-icon r-35"
          iconSet="core"
          [odxTooltip]="'TABLE.STR_NO_VALID_VALUE' | translate"
          [odxTooltipVisible]="!isBarcodeInputValueValid"
        >
        </odx-icon>
      </div>
    </div>
  </div>
  <div class="search-buttons">
    <button
      odxButton
      variant="primary"
      class="align-self-end custom-btn"
      (click)="getWorkflowAsset()"
      [disabled]="barcode?.length < 1 || (isSubmitting | async)"
      data-test-id="equipment_workflow_asset.get_workflow_asset"
    >
      <odx-icon name="ok" size="medium"></odx-icon>
    </button>

    <button
      *ngIf="!isAddEquipmentBtnHidden"
      odxButton
      variant="primary"
      class="align-self-end custom-btn"
      [disabled]="!isAddEquipmentBtnEnabled"
      (click)="addNewEquipment()"
      id="add-new-equipment"
      data-test-id="equipment_workflow_asset.add_new_equipment"
    >
      <odx-icon name="add" size="medium"></odx-icon>
    </button>
  </div>
</div>
