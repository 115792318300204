import { KeyValue } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { StorageMap } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { ModalRef, ModalService, ModalSize } from '@odx/angular/components/modal';
import orderBy from 'lodash-es/orderBy';
import { combineLatest, Observable } from 'rxjs';
import { delay, filter, map, take, takeUntil } from 'rxjs/operators';
import { CheckingSettingsModalsService, DropdownService, IModalState } from 'src/app/common';
import { CommonConstants } from 'src/app/common/constants/common.constants';
import { NotificationConstants } from 'src/app/common/constants/notification.constants';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IStoreApiList } from 'src/app/common/models/store-api-list.model';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { ConfigurationNotificationConstants } from 'src/app/configuration/constants';
import { IOrganizationAccessControl } from 'src/app/configuration/models/organization-settings.model';
import { selectOrganizationAccessControl } from 'src/app/configuration/state/selectors/configuration.selectors';
import { NotificationsService } from '../../../common';
import { SettingsConstants } from '../../constants/settings.constants';
import { ILocalizationProfile } from '../../models/localization-profile.model';
import { SettingsActions } from '../../state/actions/settings.actions';
import { selectLocalizationSettingsList, selectUserSettings } from '../../state/selectors/settings.selector';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';

@Component({
  selector: 'ignis-settings',
  templateUrl: './settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent extends OnDestroyMixin() implements OnInit, OnChanges {
  @Input() isOpen: boolean = false;

  @Output() closeSettingsModal: EventEmitter<boolean> = new EventEmitter();

  appSelectedTheme: string;
  modalRef: ModalRef | any;
  isLoading$: Observable<boolean>;
  languages: KeyValue<string, string>[];
  isLanguageOrThemeChange: boolean = false;
  routeAfterBrowserBackBtnIsPressed: string;
  localizationOptions: ILocalizationProfile[];
  settingsForm: FormGroup = new FormGroup({
    theme: new FormControl(null),
    language: new FormControl(null),
    name: new FormControl({ value: null, disabled: true }),
  });
  @ViewChild('settingsModal', { read: TemplateRef })
  public settingsModal: TemplateRef<any>;

  readonly darkTheme: string = CommonConstants.initial_dark_theme;
  readonly lightTheme: string = CommonConstants.initial_light_theme;

  softwareVersion: string;
  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;

  settingsActions: SettingsActions = inject(SettingsActions);
  dropdownService: DropdownService = inject(DropdownService);
  translateService: TranslateService = inject(TranslateService);
  private readonly modalService: ModalService = inject(ModalService);
  notificationsService: NotificationsService = inject(NotificationsService);
  checkingSettingsModalsService: CheckingSettingsModalsService = inject(CheckingSettingsModalsService);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  constructor(
    private store: Store<IApplicationState>,
    private router: Router,
    private storage: StorageMap,
  ) {
    super();
  }

  @HostListener(CommonConstants.popStateWindowEvent, ['$event'])
  onPopState(): void {
    this.checkingSettingsModalsService.remainOnTheSamePageIfTheModalIsOpened(
      this.isOpen,
      this.routeAfterBrowserBackBtnIsPressed,
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const modalIsOpened: IModalState = JSON.parse(localStorage.getItem(CommonConstants.modalIsOpened));

    if (changes.isOpen && this.isOpen) {
      this.openSettingsModal();
      this.routeAfterBrowserBackBtnIsPressed = this.router.url;
    }

    if (modalIsOpened?.modalName === CommonConstants.modalsName.SETTINGS && modalIsOpened?.open && !this.isOpen) {
      setTimeout(() => {
        this.openSettingsModal();
        this.routeAfterBrowserBackBtnIsPressed = this.router.url;
      }, 0);
    }
  }

  ngOnInit(): void {
    this.readLoadingState();

    combineLatest([
      this.store.select(selectUserSettings),
      this.store.select(selectLocalizationSettingsList),
      this.store.select(selectOrganizationAccessControl),
    ])
      .pipe(
        filter(
          ([userSettings, localizationSettings, entries]: [
            IStoreApiItem<ILocalizationProfile>,
            IStoreApiList<ILocalizationProfile[]>,
            IStoreApiItem<IOrganizationAccessControl>,
          ]) =>
            !localizationSettings.isLoading &&
            !userSettings.isLoading &&
            !entries.isLoading &&
            (!!localizationSettings.errors || localizationSettings.data?.length > 0) &&
            (!!userSettings.errors || !!userSettings.data),
        ),
        takeUntil(this.destroy),
      )
      .subscribe(
        ([userSettings, localizationSettings, entries]: [
          IStoreApiItem<ILocalizationProfile>,
          IStoreApiList<ILocalizationProfile[]>,
          IStoreApiItem<IOrganizationAccessControl>,
        ]) => {
          this.cdr.detectChanges();

          if (localizationSettings.errors || userSettings.errors) {
            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.ERROR,
              NotificationConstants.globalCodes.GENERIC_SERVER_ERROR,
              NotificationConstants.globalCodes,
            );

            return;
          }

          this.languages = localizationSettings.data.map((option: ILocalizationProfile) => {
            const mappedLanguage: string[] = SettingsConstants.languagesMap.find(
              (l: string[]) => l[1] === option.language,
            );

            return {
              key: this.translateService.instant(mappedLanguage[2]),
              value: option.language,
            };
          });

          this.languages = orderBy(this.languages, ['key'], ['asc']);

          this.localizationOptions = localizationSettings.data;
          this.appSelectedTheme = userSettings.data.theme;
          this.settingsForm.controls.language.setValue(userSettings.data.language);
          this.onAppThemeChanged(userSettings.data.theme);
          this.settingsForm.controls.name.setValue(entries.data?.organizationSettings.name);
        },
      );
  }

  readLoadingState(): void {
    this.isLoading$ = combineLatest([
      this.store.select(selectUserSettings),
      this.store.select(selectLocalizationSettingsList),
      this.store.select(selectOrganizationAccessControl),
    ]).pipe(
      map(
        ([userSettings, localizationSettings, entries]: [
          IStoreApiItem<ILocalizationProfile>,
          IStoreApiList<ILocalizationProfile[]>,
          IStoreApiItem<IOrganizationAccessControl>,
        ]) => localizationSettings.isLoading || userSettings.isLoading || entries.isLoading,
      ),
      takeUntil(this.destroy),
    );

    this.cdr.detectChanges();
  }

  onLanguageChanged(language: KeyValue<string, string>): void {
    this.settingsForm.controls.language.setValue(language.value);
    this.isLanguageOrThemeChange = true;
  }

  onAppThemeChanged(theme: string): void {
    this.appSelectedTheme = theme;
    this.settingsForm.get('theme').setValue(theme);
    this.isLanguageOrThemeChange = true;
  }

  openSettingsModal(): void {
    this.settingsActions.requestUserSettings();
    this.settingsActions.requestLocalizedOptions();

    this.modalRef = this.modalService?.open(this.settingsModal, { size: ModalSize.SMALL, dismissable: false });

    this.modalRef?.onClose$.subscribe((chosenValue: any) => this.handleCloseModal(chosenValue));
    this.modalRef?.onDismiss$.subscribe(() => this.handleCloseModal());
  }

  handleCloseModal(isConfirmed: boolean = false): void {
    this.cdr.detectChanges();

    if (!isConfirmed) {
      this.closeSettingsModal.emit(isConfirmed);
      this.settingsForm.reset();

      return;
    }

    let selectedProfile: ILocalizationProfile = this.localizationOptions.find(
      (option: ILocalizationProfile) => option.language === this.settingsForm.controls.language.value,
    );

    selectedProfile = {
      ...selectedProfile,
      theme: this.settingsForm.get('theme').value ?? CommonConstants.initial_dark_theme,
    };

    this.translateService.use(selectedProfile.language);

    this.settingsActions.requestSaveUserSettings(selectedProfile);
    this.store
      .select(selectUserSettings)
      .pipe(
        filter(
          (userSettings: IStoreApiItem<ILocalizationProfile>) =>
            Boolean(userSettings) && !userSettings.isLoading && userSettings.isSuccess,
        ),
        take(1),
      )
      .subscribe((userSettings: IStoreApiItem<ILocalizationProfile>) => {
        this.cdr.detectChanges();

        if (userSettings.errors) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            userSettings.errors.error?.code,
            ConfigurationNotificationConstants.notificationCodes,
          );

          return;
        }

        this.notificationsService.requestShowNotification(
          CommonConstants.notificationType.SUCCESS,
          ConfigurationNotificationConstants.notificationCodes.SETTINGS_SAVE_SUCCESS,
          ConfigurationNotificationConstants.notificationCodes,
        );

        this.handleCloseModal(false);

        this.storage
          .watch(CommonConstants.modalIsOpened)
          .pipe(delay(250))
          .subscribe((modalIsOpened: any) => {
            if (!modalIsOpened.open) {
              window.location.reload();
            }
          });
      });
  }
}
