import { GenericAction } from 'src/app/common';
import {
  errorItemData,
  errorPostPutItemData,
  receiveItemData,
  receivePostPutItemData,
  requestItemData,
  requestPostPutItemData,
} from 'src/app/common/utils/reducer/reducer.utils';
import { IMigrationID } from '../../models/device-connection.model';
import { IProtectorMigrationErrorPage, IProtectorMigrationStatus } from '../../models/protector-migration.model';
import { DeviceConnectionActionTypes } from '../actions/device-connection-action-types';
import { IProtectorSoftwareMigrationState, ProtectorSoftwareMirationState } from '../models/device-connection.model';

const resetMigrationState = (lastState: IProtectorSoftwareMigrationState): IProtectorSoftwareMigrationState => ({
  ...lastState,
  protectorMigrationStatus: {
    isLoading: false,
    isSuccess: false,
    data: null,
    errors: null,
  },
});

const resetMigrationErrorPageState = (
  lastState: IProtectorSoftwareMigrationState,
): IProtectorSoftwareMigrationState => ({
  ...lastState,
  protectorMigrationErrorPage: {
    isLoading: false,
    isSuccess: false,
    data: null,
    errors: null,
  },
});

export function protectorSoftwareMigrationReducer(
  lastState: IProtectorSoftwareMigrationState = new ProtectorSoftwareMirationState(),
  action: GenericAction<DeviceConnectionActionTypes, any>,
): IProtectorSoftwareMigrationState {
  switch (action.type) {
    case DeviceConnectionActionTypes.REQUEST_START_PROTECTOR_MIGRATION:
      return requestPostPutItemData<IProtectorSoftwareMigrationState, unknown>(
        lastState,
        ['startProtectorMigration'],
        action.payload,
      );
    case DeviceConnectionActionTypes.RECEIVE_START_PROTECTOR_MIGRATION:
      return receivePostPutItemData<IProtectorSoftwareMigrationState, IMigrationID>(
        lastState,
        ['startProtectorMigration'],
        action.payload,
      );
    case DeviceConnectionActionTypes.ERROR_RECEIVE_START_PROTECTOR_MIGRATION:
      return errorPostPutItemData<IProtectorSoftwareMigrationState, unknown>(
        lastState,
        ['startProtectorMigration'],
        action.payload,
      );

    case DeviceConnectionActionTypes.REQUEST_CANCEL_PROTECTOR_MIGRATION_IN_RAM:
      return requestPostPutItemData<IProtectorSoftwareMigrationState, unknown>(
        lastState,
        ['cancelProtectorMigrationInRAM'],
        action.payload,
      );
    case DeviceConnectionActionTypes.RECEIVE_CANCEL_PROTECTOR_MIGRATION_IN_RAM:
      return receivePostPutItemData<IProtectorSoftwareMigrationState, unknown>(
        lastState,
        ['cancelProtectorMigrationInRAM'],
        action.payload,
      );
    case DeviceConnectionActionTypes.ERROR_RECEIVE_CANCEL_PROTECTOR_MIGRATION_IN_RAM:
      return errorPostPutItemData<IProtectorSoftwareMigrationState, unknown>(
        lastState,
        ['cancelProtectorMigrationInRAM'],
        action.payload,
      );

    case DeviceConnectionActionTypes.REQUEST_CANCEL_PROTECTOR_MIGRATION:
      return requestPostPutItemData<IProtectorSoftwareMigrationState, string>(
        lastState,
        ['cancelProtectorMigration'],
        action.payload,
      );
    case DeviceConnectionActionTypes.RECEIVE_CANCEL_PROTECTOR_MIGRATION:
      return receivePostPutItemData<IProtectorSoftwareMigrationState, unknown>(
        lastState,
        ['cancelProtectorMigration'],
        action.payload,
      );
    case DeviceConnectionActionTypes.ERROR_RECEIVE_CANCEL_PROTECTOR_MIGRATION:
      return errorPostPutItemData<IProtectorSoftwareMigrationState, unknown>(
        lastState,
        ['cancelProtectorMigration'],
        action.payload,
      );

    case DeviceConnectionActionTypes.REQUEST_PROTECTOR_MIGRATION_STATUS:
      return requestItemData<IProtectorSoftwareMigrationState, string>(lastState, ['protectorMigrationStatus']);
    case DeviceConnectionActionTypes.RECEIVE_PROTECTOR_MIGRATION_STATUS:
      return receiveItemData<IProtectorSoftwareMigrationState, IProtectorMigrationStatus>(
        lastState,
        ['protectorMigrationStatus'],
        action.payload,
      );
    case DeviceConnectionActionTypes.ERROR_RECEIVE_PROTECTOR_MIGRATION_STATUS:
      return errorItemData<IProtectorSoftwareMigrationState, IProtectorMigrationStatus>(
        lastState,
        ['protectorMigrationStatus'],
        action.payload,
      );
    case DeviceConnectionActionTypes.RESET_PROTECTOR_MIGRATION_STATUS:
      return resetMigrationState(lastState);

    case DeviceConnectionActionTypes.REQUEST_PROTECTOR_MIGRATION_ERROR_PAGE:
      return requestItemData<IProtectorSoftwareMigrationState, IProtectorMigrationErrorPage>(lastState, [
        'protectorMigrationErrorPage',
      ]);
    case DeviceConnectionActionTypes.RECEIVE_PROTECTOR_MIGRATION_ERROR_PAGE:
      return receiveItemData<IProtectorSoftwareMigrationState, IProtectorMigrationErrorPage>(
        lastState,
        ['protectorMigrationErrorPage'],
        action.payload,
      );
    case DeviceConnectionActionTypes.ERROR_RECEIVE_PROTECTOR_MIGRATION_ERROR_PAGE:
      return errorItemData<IProtectorSoftwareMigrationState, IProtectorMigrationErrorPage>(
        lastState,
        ['protectorMigrationErrorPage'],
        action.payload,
      );
    case DeviceConnectionActionTypes.RESET_PROTECTOR_MIGRATION_ERROR_PAGE:
      return resetMigrationErrorPageState(lastState);

    default:
      return lastState;
  }
}
