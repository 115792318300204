import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from 'redux';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { FireStationList, IFireStationList } from '../../../common';
import { BAWearer, BAWearerPage, IBAWearer, IBAWearerPage, ICreateUpdateBAWearer, IDeleteBAWearer } from '../../models';
import { BAWearerService } from '../../services/ba-wearer.service';
import { BAWearerActionTypes } from '../actions/ba-wearer-action-types';
import {
  ErrorReceiveUploadCSVFile,
  ReceiveUploadCSVFile,
} from '../actions/ba-wearer-action-types-creators/ba-wearer-csv-file-action-types-creators';
import { ReceiveFireStationList } from '../actions/ba-wearer-action-types-creators/ba-wearer-fire-station-action-types-creators';
import { ReceiveBAWearerPage } from '../actions/ba-wearer-action-types-creators/ba-wearer-page-action-types-creators';
import {
  ErrorReceiveBAWearerById,
  ErrorReceiveCreateBAWearer,
  ErrorReceiveDeleteBAWearer,
  ErrorReceiveUpdateBAWearer,
  ReceiveBAWearerById,
  ReceiveCreateBAWearer,
  ReceiveDeleteBAWearer,
  ReceiveUpdateBAWearer,
} from './../actions/ba-wearer-action-types-creators/ba-wearer-crud-action-types-creators';
import { ErrorReceiveFireStationList } from './../actions/ba-wearer-action-types-creators/ba-wearer-fire-station-action-types-creators';
import { ErrorReceiveBAWearerPage } from './../actions/ba-wearer-action-types-creators/ba-wearer-page-action-types-creators';

@Injectable()
export class BAWearerEffects {
  constructor(private baWearerService: BAWearerService) {}

  requestBAWearerPage$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(BAWearerActionTypes.REQUEST_BA_WEARER_PAGE),
      switchMap((params: any) =>
        this.baWearerService.getBAWearerPage(params.payload).pipe(
          map((baWearerPage: IBAWearerPage) => new ReceiveBAWearerPage(new BAWearerPage(baWearerPage))),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveBAWearerPage(error))),
        ),
      ),
    );
  });

  requestBAWearer$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(BAWearerActionTypes.REQUEST_BA_WEARER_BY_ID),
      switchMap((params: { payload: string }) =>
        this.baWearerService.getBAWearerById(params.payload).pipe(
          map((baWearer: { body: IBAWearer }) => new ReceiveBAWearerById(baWearer.body)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveBAWearerById(error))),
        ),
      ),
    );
  });

  requestCreateBAWearer$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(BAWearerActionTypes.REQUEST_CREATE_BA_WEARER),
      switchMap((params: { payload: ICreateUpdateBAWearer }) =>
        this.baWearerService.addBAWearer(params.payload).pipe(
          map((baWearer: IBAWearer) => new ReceiveCreateBAWearer(new BAWearer(baWearer))),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveCreateBAWearer(error))),
        ),
      ),
    );
  });

  requestUpdateBAWearer$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(BAWearerActionTypes.REQUEST_UPDATE_BA_WEARER),
      switchMap((params: { payload: ICreateUpdateBAWearer }) =>
        this.baWearerService.updateBAWearer(params.payload).pipe(
          map((baWearer: IBAWearer) => new ReceiveUpdateBAWearer(new BAWearer(baWearer))),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUpdateBAWearer(error))),
        ),
      ),
    );
  });

  requestDeleteBAWearer$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(BAWearerActionTypes.REQUEST_DELETE_BA_WEARER),
      switchMap((params: { payload: IDeleteBAWearer }) =>
        this.baWearerService.deleteBAWearer(params.payload).pipe(
          map((baWearer: IBAWearer) => new ReceiveDeleteBAWearer(new BAWearer(baWearer))),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveDeleteBAWearer(error))),
        ),
      ),
    );
  });

  requestFireStationList$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(BAWearerActionTypes.REQUEST_FIRE_STATION_LIST),
      switchMap(() =>
        this.baWearerService.getFireStations().pipe(
          map((fireStationList: IFireStationList) => new ReceiveFireStationList(new FireStationList(fireStationList))),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveFireStationList(error))),
        ),
      ),
    );
  });

  requestUploadCSVFile$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(BAWearerActionTypes.REQUEST_UPLOAD_BA_WEARER_CSV_FILE),
      switchMap((data: { payload: FormData; type: string }) =>
        this.baWearerService.uploadCSVFile(data).pipe(
          map((response: unknown) => new ReceiveUploadCSVFile(response)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUploadCSVFile(error))),
        ),
      ),
    );
  });
}
