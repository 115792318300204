import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth, { AuthState, EVENT_EXPIRED } from '@okta/okta-auth-js';
import { captureMessage, setUser } from '@sentry/angular-ivy';
import { productFruits } from 'product-fruits';
import { BehaviorSubject, filter, map, Observable, Subject, take, takeUntil } from 'rxjs';
import {
  CommonConstants,
  IApplicationState,
  IStoreApiItem,
  LoggerService,
  UserStorageService,
  WindowService,
} from 'src/app/common';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import {
  getDateFormatFromOrganizationProfile,
  getDateFormatFromUserProfile,
} from 'src/app/common/utils/settings-utils/settings-utils';
import { AppModulesTypes } from 'src/app/root/models/app-types';
import { ILocalizationProfile, SettingsActions } from 'src/app/settings';
import { selectUserSettings } from 'src/app/settings/state/selectors/settings.selector';
import { environment } from 'src/environments/environment';
import { AppModuleRoutes } from '../../constants/app-routes.constants';
import { IAccessControlItems } from '../../models/access-control-items.model';
import { IAlarmLists } from '../../models/alarms.model';
import { AppUserPermissionList } from '../../models/app-types';
import { AccessControlItemsService } from '../../services';
import { AccessControlService } from '../../services/access-control/access-control.service';
import { AlarmsActions } from '../../state/alarms/actions/alarms.actions';
import { selectAlarms } from '../../state/alarms/selectors/alarms.selector';
import { IFeatureToggle } from '../../state/features-toggle/models/features-toggle.model';

@Component({
  selector: 'ignis-app',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  isIframe: boolean = false;
  isLoggedIn: boolean = false;
  featuresToggle: IFeatureToggle[];
  alarmList: Observable<IAlarmLists>;
  jwtHelper: JwtHelperService = new JwtHelperService();
  getDataOnlyFirstTime: boolean = false;
  dateFormat: string;
  organizationDateFormat: Observable<string>;
  intervalToGetAlarms: number | any;

  readonly destroy: Subject<void> = new Subject<void>();

  alarmsActions: AlarmsActions = inject(AlarmsActions);
  loggerService: LoggerService = inject(LoggerService);
  translateService: TranslateService = inject(TranslateService);
  settingsActions: SettingsActions = inject(SettingsActions);
  userStorageService: UserStorageService = inject(UserStorageService);
  accessControlService: AccessControlService = inject(AccessControlService);
  notificationsService: NotificationsService = inject(NotificationsService);
  accessControlItemsService: AccessControlItemsService = inject(AccessControlItemsService);

  userRole: string;
  permissionList: string[];
  appUserPermissionList: typeof AppUserPermissionList = AppUserPermissionList;
  isTokenExpired: boolean;
  oktaAuthError: boolean;

  areOktaTokensSetSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingTokenState$: Observable<unknown> = this.areOktaTokensSetSubject.asObservable();

  appLanguageSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  appLanguageObs$: Observable<string> = this.appLanguageSubject.asObservable();

  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    @Inject(DOCUMENT) private document: Document,
    public locationService: WindowService,
    private oktaStateService: OktaAuthStateService,
    private store: Store<IApplicationState>,
    private router: Router,
  ) {
    this.setAppTheme();
    this.featuresToggle = [];
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;

    this.handleOktaLogin();
    this.refreshOktaToken();

    this.alarmList = this.store.pipe(
      select(selectAlarms),
      takeUntil(this.destroy),
      map((state: IStoreApiItem<IAlarmLists>) => state.data),
    );

    this.dateFormat = getDateFormatFromUserProfile(this.translateService);
    this.organizationDateFormat = getDateFormatFromOrganizationProfile(this.store, this.destroy);
  }

  setAppTheme(): void {
    const headerLinks: any = this.document.getElementsByTagName('link');

    for (const link of headerLinks) {
      if (link.href.includes(CommonConstants.odx_dark_theme)) {
        link.id = CommonConstants.odx_dark_theme;
      } else if (link.href.includes(CommonConstants.odx_light_theme)) {
        link.id = CommonConstants.odx_light_theme;
      } else if (link.href.includes(CommonConstants.initial_dark_theme)) {
        link.id = CommonConstants.initial_dark_theme;
      } else if (link.href.includes(CommonConstants.initial_light_theme)) {
        link.id = CommonConstants.initial_light_theme;
      }
    }

    this.store
      .select(selectUserSettings)
      .pipe(
        filter(
          (userSettings: IStoreApiItem<ILocalizationProfile>) =>
            Boolean(userSettings) && !userSettings.isLoading && !userSettings.errors && Boolean(userSettings.data),
        ),
        take(1),
      )
      .subscribe((userSettings: IStoreApiItem<ILocalizationProfile>) => {
        const appTheme: string = userSettings.data.theme;

        if (appTheme === CommonConstants.initial_light_theme) {
          this.document.getElementById(CommonConstants.initial_dark_theme)?.remove();
          this.document.getElementById(CommonConstants.odx_dark_theme)?.remove();

          this.document.body.classList.add(CommonConstants.initial_light_theme);
          this.document.body.classList.add(CommonConstants.odx_light_theme);
        } else {
          this.document.getElementById(CommonConstants.initial_light_theme)?.remove();
          this.document.getElementById(CommonConstants.odx_light_theme)?.remove();

          this.document.body.classList.add(CommonConstants.initial_dark_theme);
          this.document.body.classList.add(CommonConstants.odx_dark_theme);
        }
      });
  }

  requestAlarms(): void {
    if (this.intervalToGetAlarms) {
      clearInterval(this.intervalToGetAlarms);
    }

    if (
      this.accessControlService.remoteMonitoringFeatureToggle &&
      this.accessControlService.checkPermission(this.appUserPermissionList.remoteMonitoring)
    ) {
      this.alarmsActions.requestAlarms();
      this.getAlarms();
    }
  }

  getAlarms(): void {
    this.intervalToGetAlarms = setInterval(() => {
      if (!this.isTokenExpired) {
        this.alarmsActions.requestAlarms();
      }
    }, 20000);
  }

  /* istanbul ignore next */
  handleOktaLogin(): void {
    const displayErrorPageAfter3Times: number = 3;

    this.oktaAuth
      .handleRedirect()
      .then(() => {
        this.oktaStateService.authState$.subscribe((authState: AuthState) => {
          this.isLoggedIn = authState.isAuthenticated;

          if (this.isLoggedIn && authState.accessToken) {
            this.setTokensInStorage();
            this.oktaAuthError = false;
          } else if (localStorage.getItem(CommonConstants.AUTH_TOKEN) === 'undefined') {
            this.router.navigate([AppModuleRoutes.loginCallback]);
          } else if (
            this.jwtHelper.isTokenExpired(localStorage.getItem(CommonConstants.AUTH_TOKEN)) ||
            !this.isLoggedIn
          ) {
            this.router.navigate([AppModuleRoutes.loginCallback]);
          }
        });
      })
      .catch((errorResponse: HttpErrorResponse) => {
        if (
          errorResponse.message.includes(CommonConstants.oktaErrors.PKCE_CODE_VERIFIER_ERROR) ||
          errorResponse.message.includes(CommonConstants.oktaErrors.VERIFICATION_CODE_EXPIRED)
        ) {
          this.oktaAuth.signOut();
          this.router.navigate([AppModuleRoutes.loginCallback]);
        }

        if (errorResponse.message.includes(CommonConstants.oktaErrors.JWT_TIME_IN_FUTURE)) {
          this.loggerService.logMessage(errorResponse.message, CommonConstants.logType.INFO);
          this.loggerService.logTrace(errorResponse.message, SeverityLevel.Information);

          captureMessage(errorResponse.message, 'info');

          if (Number(localStorage.refresh) >= displayErrorPageAfter3Times) {
            this.oktaAuthError = true;
          } else {
            this.router.navigate([AppModuleRoutes.loginCallback]);
          }

          localStorage.refresh = localStorage.refresh != null ? localStorage.refresh : 0;
          localStorage.setItem('refresh', `${Number(localStorage.refresh) + 1}`);
        }

        this.userStorageService.clearBrowserLocalStorageWithoutPersistentKeys();
      });

    this.handleLastLogin();
  }

  handleLastLogin(): void {
    if (this.oktaAuth.token.isLoginRedirect()) {
      this.loadingTokenState$.pipe(takeUntil(this.destroy)).subscribe((oktaTokensAreReady: boolean) => {
        if (oktaTokensAreReady) {
          this.updateUserLastLoginState();

          this.userStorageService.clearBrowserLocalStorageWithoutPersistentKeys();
        }
      });
    }
  }

  getAppLanguage(appLanguage: string): void {
    this.appLanguageSubject.next(appLanguage);
  }

  updateUserLastLoginState(): void {
    this.accessControlItemsService.updateLastLogin()?.subscribe(() => {});
  }

  updateUserLastLoginAfterAcceptTerms(): void {
    if (CommonConstants.acceptedTerms in localStorage) {
      this.updateUserLastLoginState();
      localStorage.removeItem(CommonConstants.acceptedTerms);
    }
  }

  /* istanbul ignore next */
  refreshOktaToken(): void {
    this.oktaAuth.start();

    this.oktaAuth.tokenManager.on(EVENT_EXPIRED, (key: string) => {
      this.oktaAuth.tokenManager.renew(key);
      this.setTokensInStorage();
    });
  }

  setTokensInStorage(): void {
    localStorage.setItem(CommonConstants.AUTH_TOKEN, this.oktaAuth.getIdToken());
    localStorage.setItem(CommonConstants.ACCESS_TOKEN, this.oktaAuth.getAccessToken());

    this.areOktaTokensSetSubject.next(true);
  }

  getAccessControlItems(accessControlItems: IAccessControlItems): void {
    if (!this.isLoggedIn) {
      return;
    }

    this.updateUserLastLoginAfterAcceptTerms();

    this.accessControlService.setRestrictedLocation(accessControlItems.locationRestriction);
    this.accessControlService.setPermissions(accessControlItems.permissions);
    this.userRole = accessControlItems.role;
    this.permissionList = accessControlItems.permissions;
    this.isTokenExpired = this.jwtHelper.isTokenExpired(localStorage.getItem(CommonConstants.AUTH_TOKEN));

    const userName: string = accessControlItems.userAggregateId.substring(0, 8);

    this.initProductFruits(accessControlItems.features, userName);
    this.requestAlarms();

    setUser({
      username: userName,
      id: `${accessControlItems.tenantId}|${accessControlItems.userAggregateId}`,
    });
  }

  initProductFruits(features: string[], username: string): void {
    this.appLanguageObs$.subscribe((appLanguage: string) => {
      if (features.includes(AppModulesTypes.productFruits) && appLanguage.length > 0) {
        productFruits.init(
          environment.PRODUCT_FRUITS.WORKSHOP_CODE,
          appLanguage.split('-')[0],
          { username },
          { disableLocationChangeDetection: true },
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
