import { ChangeDetectorRef, DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class DetectChangesInFormsService {
  destroyRef: DestroyRef = inject(DestroyRef);

  public detectChangesInForms(form: FormGroup, cdr: ChangeDetectorRef): void {
    form?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      cdr?.detectChanges();
    });
  }
}
