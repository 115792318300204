import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpHelper, PressureUnitPipe } from 'src/app/common';
import { environment } from 'src/environments/environment';
import { EntriesModel } from '../models/logbook.model';
import { ILogbook, ILogbookPage } from './../models/logbook.model';

@Injectable()
export class LogbookService {
  private baseUrl: string = environment.API_URLS.LOGBOOK;

  constructor(
    private httpClient: HttpClient,
    private httpHelper: HttpHelper,
    private translateService: TranslateService,
    private pressureUnitPipe: PressureUnitPipe,
  ) {}

  public getLogbookPage(params?: any): Observable<ILogbookPage | HttpErrorResponse> {
    return this.httpClient.get<ILogbookPage>(`${this.baseUrl}/ba-logbook`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: { ...params },
    });
  }

  public getEntries(): Observable<EntriesModel | HttpErrorResponse> {
    return this.httpClient.get<EntriesModel>(`${this.baseUrl}/ba-logbook/filter-options`, this.httpHelper.options);
  }

  public getLogbookExcelData(params?: any): Observable<ILogbook[] | HttpErrorResponse> {
    return this.httpClient.get<ILogbook[]>(`${this.baseUrl}/ba-logbook/export`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: { ...params },
    });
  }

  translateEventType(typeValue: string): string {
    switch (typeValue) {
      case 'test':
        return this.translateService.instant('EVENT_TYPE_TRANSLATION.TEST');
      case 'incident':
        return this.translateService.instant('EVENT_TYPE_TRANSLATION.INCIDENT');
      case 'training':
        return this.translateService.instant('EVENT_TYPE_TRANSLATION.TRAINING');
    }
  }

  translateStatus(statusObj: any): string {
    const tooltip: string[] = [];
    let specialExposureValue: string = statusObj.incidentSpecialExposure;

    if (!statusObj.incidentType && !statusObj.incidentSpecialExposure) {
      specialExposureValue = '–';
    }

    switch (statusObj.statuses[0]) {
      case 'error':
        tooltip.push(this.translateService.instant('LOGBOOK_TABLE.STR_STATUS_NOT_ACCORDING_TO_GUIDELINES'));
        break;
      case 'warning':
        tooltip.push(this.translateService.instant('LOGBOOK_TABLE.STR_STATUS_LOW_STARTING_PRESSURE'));
        break;
      case 'ok':
        tooltip.push(this.translateService.instant('LOGBOOK_TABLE.STR_STATUS_OK'));
        break;
    }

    if (statusObj.type === 'incident' || statusObj.type === 'training') {
      tooltip.push(
        `${this.translateService.instant('LOGBOOK_TABLE.STR_STATUS_CYLINDER_PRESSURE', {
          bars: this.pressureUnitPipe.transform(statusObj.cylinderRatedPressure, [statusObj.pressureDisplayUnit, true]),
        })}`,
      );
    }

    if (statusObj.statuses[1]) {
      switch (statusObj.incidentSpecialExposure) {
        case 'asbestos':
          tooltip.push(`${this.translateService.instant('INCIDENT_ALARM_TYPE.STR_HAZMAT_ASBESTOS')}`);
          break;
        case 'hazmat':
          tooltip.push(`${this.translateService.instant('INCIDENT_ALARM_TYPE.STR_HAZMAT_HAZMAT')}`);
          break;
        case 'witnessed_fatality':
          tooltip.push(`${this.translateService.instant('INCIDENT_ALARM_TYPE.STR_WITNESSED_FATALITY')}`);
          break;
        case 'extreme_heat':
          tooltip.push(`${this.translateService.instant('INCIDENT_ALARM_TYPE.STR_HAZMAT_EXTREME_HEAT')}`);
          break;
        case 'other':
          tooltip.push(`${this.translateService.instant('INCIDENT_ALARM_TYPE.STR_HAZMAT_OTHER')}`);
          break;
        default:
          tooltip.push(
            `${this.translateService.instant('INCIDENT_ALARM_TYPE.STR_CUSTOM_HAZMAT', { event: specialExposureValue })}`,
          );
          break;
      }
    }

    return tooltip.join('; ');
  }

  translateIncidentNo(incidentNumber: string): string {
    /* istanbul ignore else */
    if (!incidentNumber) {
      return this.translateService.instant('LOGBOOK_TABLE.STR_NO_INCIDENT_NUMBER_YET');
    }
  }
}
