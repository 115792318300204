import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonConstants } from 'src/app/common';
import { TableFilterType } from 'src/app/common/constants/table.constants';
import { ITableColumn } from 'src/app/common/models/table.model';

@Component({
  selector: 'ignis-table-multi-select-filter',
  templateUrl: './table-multi-select-filter.component.html',
  styleUrls: ['./table-multi-select-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableMultiSelectFilterComponent {
  @Input() column: ITableColumn;
  @Input() customFilter: string;
  @Input() multiSelectOptions;
  @Input() selectActiveOptions = {};

  filterType = TableFilterType;
  dropdownIconCSSClass: string = CommonConstants.tableDropdownIconCSSClass;

  @Output() handleMultiSelectionFilterValue: EventEmitter<{ options: any[]; field: string }> = new EventEmitter<{
    options: any[];
    field: string;
  }>();
  @Output() handleRemovedMultiSelectFilterValue: EventEmitter<string> = new EventEmitter<string>();

  onMultiSelectChange(options: any[]): void {
    this.handleMultiSelectionFilterValue.emit({ options, field: this.column?.field });
  }

  onMultiSelectRemoveSelectedItems(): void {
    this.handleRemovedMultiSelectFilterValue.emit(this.column?.field);
  }
}
