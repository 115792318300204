import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IMediaAssets, INewNote } from 'src/app/report-center/models/incident.model';
import { IncidentActionTypes } from '../incident-action-types';

export class RequestIncidentMediaAssets implements Action {
  readonly type: IncidentActionTypes.REQUEST_INCIDENT_MEDIA_ASSETS = IncidentActionTypes.REQUEST_INCIDENT_MEDIA_ASSETS;

  constructor(public payload: string) {
  }
}
export class ReceiveIncidentMediaAssets implements Action {
  readonly type: IncidentActionTypes.RECEIVE_INCIDENT_MEDIA_ASSETS = IncidentActionTypes.RECEIVE_INCIDENT_MEDIA_ASSETS;

  constructor(public payload: IMediaAssets[]) { }

}
export class ErrorReceiveIncidentMediaAssets implements Action {
  readonly type: IncidentActionTypes.ERROR_RECEIVE_INCIDENT_MEDIA_ASSETS =
    IncidentActionTypes.ERROR_RECEIVE_INCIDENT_MEDIA_ASSETS;

  constructor(public payload: HttpErrorResponse) { }
}

export class RequestAddNoteOrMediaFile implements Action {
  readonly type: IncidentActionTypes.REQUEST_ADD_MEDIA_NOTE_OR_MEDIA_FILE = IncidentActionTypes.REQUEST_ADD_MEDIA_NOTE_OR_MEDIA_FILE;

  constructor(public incidentId: string, public payload: INewNote[], public version: number) { }
}

export class ReceiveAddNoteOrMediaFile implements Action {
  readonly type: IncidentActionTypes.RECEIVE_ADD_MEDIA_NOTE_OR_MEDIA_FILE = IncidentActionTypes.RECEIVE_ADD_MEDIA_NOTE_OR_MEDIA_FILE;
}

export class ErrorReceiveAddNoteOrMediaFile implements Action {
  readonly type: IncidentActionTypes.ERROR_RECEIVE_ADD_MEDIA_NOTE_OR_MEDIA_FILE = IncidentActionTypes.ERROR_RECEIVE_ADD_MEDIA_NOTE_OR_MEDIA_FILE;

  constructor(public payload: HttpErrorResponse) { }
}
