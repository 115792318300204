<div class="blue-bar-items">
  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    (click)="onCreateClick($event)"
    id="bluebar-create"
    data-test-id="user_management.create_btn"
    [disabled]="
      isModalOpened ||
      selectedItem ||
      router.url?.includes(UserManagementModuleRoutes.createUser) ||
      router.url?.includes(UserManagementModuleRoutes.updateUser)
    "
  >
    <odx-icon size="inline" name="add"></odx-icon>
  </button>

  <button
    [disabled]="
      !selectedItem ||
      selfSelected ||
      router.url?.includes(UserManagementModuleRoutes.updateUser) ||
      isModalOpened ||
      router.url?.includes(UserManagementModuleRoutes.createUser) ||
      router.url?.includes(UserManagementModuleRoutes.updateUser)
    "
    (click)="onEditClick($event)"
    id="bluebar-edit"
    data-test-id="user_management.edit_btn"
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
  >
    <odx-icon size="inline" name="edit"></odx-icon>
  </button>

  <button
    [disabled]="
      !selectedItem ||
      router.url?.includes(UserManagementModuleRoutes.updateUser) ||
      isModalOpened ||
      router.url?.includes(UserManagementModuleRoutes.createUser) ||
      router.url?.includes(UserManagementModuleRoutes.updateUser)
    "
    (click)="onDeleteClick($event)"
    id="bluebar-delete"
    data-test-id="user_management.delete_btn"
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
  >
    <odx-icon size="inline" name="delete"></odx-icon>
  </button>

  <button
    type="button"
    (click)="onDownloadTemplateClick($event)"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    id="download-csv-btn"
    data-test-id="user_management.download_csv_template_btn"
    [disabled]="
      isModalOpened ||
      router.url?.includes(UserManagementModuleRoutes.createUser) ||
      router.url?.includes(UserManagementModuleRoutes.updateUser)
    "
    [pTooltip]="downloadCSVButtonTooltipContent"
    tooltipPosition="top"
    positionTop="-10"
  >
    <ng-template #downloadCSVButtonTooltipContent>
      <div data-test-id="user_management.download_csv_template_tooltip">
        {{ 'USER_MANAGEMENT.STR_DOWNLOAD_TEMPLATE' | translate }}
      </div>
    </ng-template>

    <odx-icon size="inline" name="download"></odx-icon>
  </button>

  <button
    type="button"
    (click)="onUploadCSVFileClick($event)"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    id="upload-csv-btn"
    data-test-id="user_management.upload_csv_template_btn"
    [disabled]="
      isModalOpened ||
      router.url?.includes(UserManagementModuleRoutes.createUser) ||
      router.url?.includes(UserManagementModuleRoutes.updateUser)
    "
    [pTooltip]="uploadCSVButtonTooltipContent"
    tooltipPosition="top"
    positionTop="-10"
  >
    <ng-template #uploadCSVButtonTooltipContent>
      <div data-test-id="user_management.upload_csv_template_tootlip">
        {{ 'USER_MANAGEMENT.STR_UPLOAD_FILE' | translate }}
      </div>
    </ng-template>

    <odx-icon size="inline" name="upload"></odx-icon>
  </button>

  <ignis-export-excel-footer-btn
    [numberOfRecordsToBeExported]="numberOfRecordsToBeExported"
    [isModalOpened]="
      isModalOpened ||
      router.url?.includes(UserManagementModuleRoutes.createUser) ||
      router.url?.includes(UserManagementModuleRoutes.updateUser)
    "
    (handleClickEvent)="onExportExcelClick($event)"
  >
  </ignis-export-excel-footer-btn>
</div>
