import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnChanges } from '@angular/core';
import { CommonConstants, IScrollOptions } from 'src/app/common';
import { IMediaAssets } from 'src/app/report-center/models/incident.model';

@Component({
  selector: 'ignis-media-gallery',
  templateUrl: './media-gallery.component.html',
  styleUrls: ['./media-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaGalleryComponent implements OnChanges {
  @Input() media: IMediaAssets[];
  @Input() formatDate: string;
  mediaItems: IMediaAssets[];
  scrollbarOptions: IScrollOptions = CommonConstants.scrollbarOptions;

  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  ngOnChanges(): void {
    if (this.media) {
      this.mediaItems = this.media;
      this.cdr.detectChanges();
    }
  }
}
