import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { IIncidentEcp } from 'src/app/remote-monitoring/models/remote-monitoring.model';

@Component({
  selector: 'ignis-ecp-statistics',
  templateUrl: './ecp-statistics.component.html',
  styleUrls: ['./ecp-statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EcpStatisticsComponent implements OnChanges {
  @Input() selectedEcp: IIncidentEcp;

  repeatersWithLowBattery: string;
  alignToLeft: boolean;

  @ViewChild('ecpRigthStatisticBlock', { static: false }) ecpRigthStatisticBlock: ElementRef;

  ngOnChanges(): void {
    if (this.selectedEcp) {
      this.repeatersWithLowBattery = this.getRepeatersWithLowBattery();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.alignToLeft = this.ecpRigthStatisticBlock?.nativeElement.getBoundingClientRect().top > 64;
  }

  getTeamsNo(no: number): string {
    if (no < 10) {
      return `0${no}`;
    }

    return no?.toString();
  }

  getRepeatersWithLowBattery(): string {
    const filteredRepeaters = this.selectedEcp.hardwareDetails.repeaters
      .filter((element) => element.lowBattery)
      .map((element) => element.id);

    return filteredRepeaters.join(', ');
  }
}
