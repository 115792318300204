import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IOrganizationAccessControl } from 'src/app/configuration/models';
import { IConfigurationState } from '../../models/configuration.models';
import {
  RequestOrganizationAccessControl,
  RequestOrganizationLicenseInfo,
  RequestOrganizationSettingsEntries,
  RequestUpdateOrganizationAccessControl,
  ResetOrganizationLicenseInfo,
} from './organization-settings-action-types-creators';

@Injectable({ providedIn: 'root' })
export class OrganizationSettingsActions {
  constructor(public store: Store<IConfigurationState>) {}

  public requestOrganizationSettingsEntries(): void {
    this.store.dispatch(new RequestOrganizationSettingsEntries());
  }

  public requestOrganizationAccessControl(): void {
    this.store.dispatch(new RequestOrganizationAccessControl());
  }

  public requestUpdateOrganizationAccessControl(profile: IOrganizationAccessControl): void {
    this.store.dispatch(new RequestUpdateOrganizationAccessControl(profile));
  }

  public requestOrganizationLicenseInfo(): void {
    this.store.dispatch(new RequestOrganizationLicenseInfo());
  }

  public resetOrganizationLicenseInfo(): void {
    this.store.dispatch(new ResetOrganizationLicenseInfo());
  }
}
