<p-columnFilter
  *ngIf="column?.field === customFilter && column.filterType === filterType.DROPDOWN"
  field="column?.field"
  matchMode="equals"
  [showMenu]="false"
  [showClearButton]="false"
>
  {{ column.dropdownOptions }}
  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
    <odx-form-field [variant]="'simple'" odxLayout="6@phone 12">
      <div class="w-100">
        <p-dropdown
          data-test-id="table.dropdown_filter"
          appendTo="body"
          class="table-dropdown-filter"
          ngDefaultControl
          [dropdownIcon]="dropdownIconCSSClass"
          #dd
          [ngModel]="selectActiveOptions && selectActiveOptions[column?.field]"
          (onShow)="dropdownService.openDropdown(dd)"
          (onHide)="dropdownService.closeDropdown(dd)"
          [options]="column.dropdownOptions"
          (onChange)="onDropdownChange($event)"
          [placeholder]="column.filterPlaceholder | translate"
          [showClear]="true"
          [autoDisplayFirst]="false"
        >
          <ng-template let-selectedItem pTemplate="selectedItem" *ngIf="column.dropdownWithIcon">
            <div class="selected-item-with-icon" *ngIf="column?.field">
              <odx-icon [name]="selectedItem?.icon" *ngIf="selectedItem?.icon"></odx-icon>
              <span [title]="selectedItem?.label | translate">{{ selectedItem?.label | translate }}</span>
            </div>
          </ng-template>

          <ng-template let-option pTemplate="item" *ngIf="column.dropdownWithIcon">
            <div class="item-with-icon">
              <odx-icon [name]="option?.icon"></odx-icon>
              <span>{{ option?.label | translate }}</span>
            </div>
          </ng-template>

          <ng-template let-option pTemplate="item" *ngIf="!column.dropdownWithIcon">
            <span>{{ option?.label }}</span>
          </ng-template>

          <ng-template pTemplate="clearicon">
            <odx-icon name="close"></odx-icon>
          </ng-template>
        </p-dropdown>
      </div>
    </odx-form-field>
  </ng-template>
</p-columnFilter>
