import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { captureMessage, getCurrentScope, setTag } from '@sentry/angular-ivy';
import { CommonConstants, LoggerService } from 'src/app/common';
import { NotificationConstants } from 'src/app/common/constants/notification.constants';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { v4 as uuidv4 } from 'uuid';

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    public zone: NgZone,
    public injector: Injector,
  ) {}

  handleError(error: any): void {
    const notificationsService: NotificationsService = this.injector.get(NotificationsService);
    const loggerService: LoggerService = this.injector.get(LoggerService);
    const errorID: string = uuidv4().toString();

    this.zone.run(() =>
      notificationsService.requestShowNotification(
        CommonConstants.notificationType.ERROR,
        NotificationConstants.globalCodes.ERROR_ON_THE_UI,
        NotificationConstants.globalCodes,
        { errorID },
      ),
    );

    if (!error.headers) {
      getCurrentScope().setLevel('warning');
      setTag('error-id', errorID);

      captureMessage(error, 'warning');
      loggerService.logMessage(error, CommonConstants.logType.WARNING);
      loggerService.logTrace(error.toString(), SeverityLevel.Warning, errorID);
    }
  }
}
