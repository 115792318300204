import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { PropertyBag } from 'src/app/common';
import {
  ErrorReceiveTableExport,
  ReceiveTableExport,
} from 'src/app/common/state/export/actions/export-action-types-creators';
import { ITableExport } from 'src/app/common/state/export/models/export.model';
import { WorkshopService } from 'src/app/workshop/services';
import * as models from '../../../models';
import { ErrorReceiveUploadEquipmentCSVFile, ReceiveUploadEquipmentCSVFile } from '../../actions';
import * as actions from '../../actions/equipment-crud/index';

@Injectable()
export class EquipmentCRUDEffects {
  workshopService: WorkshopService = inject(WorkshopService);

  requestEquipmentPage$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentCRUDActionTypes.REQUEST_EQUIPMENT_PAGE),
      switchMap((params?: { payload: PropertyBag }) =>
        this.workshopService.getEquipmentPage(params.payload).pipe(
          map((equipmentPage: models.IEquipmentListPage) => new actions.ReceiveEquipmentPage(equipmentPage)),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveEquipmentPage(error))),
        ),
      ),
    );
  });

  requestEquipmentExport$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentCRUDActionTypes.REQUEST_EQUIPMENT_EXPORT),
      switchMap((params?: { payload: PropertyBag }) =>
        this.workshopService.getEquipmentPage(params.payload).pipe(
          map((equipmentPage: ITableExport) => new ReceiveTableExport(equipmentPage)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveTableExport(error))),
        ),
      ),
    );
  });

  requestEquipmentById$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentCRUDActionTypes.REQUEST_EQUIPMENT_BY_ID),
      switchMap((equipmentId: { payload: string }) =>
        this.workshopService.getEquipmentById(equipmentId.payload).pipe(
          map(
            (payload: HttpResponse<models.IEquipment>) =>
              new actions.ReceiveEquipmentById(new models.Equipment(payload.body)),
          ),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveEquipmentById(error))),
        ),
      ),
    );
  });

  requestSavedEquipments$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentCRUDActionTypes.REQUEST_SAVED_EQUIPMENTS),
      switchMap((equipmentIds: { payload: string[] }) =>
        this.workshopService.getSavedEquipments(equipmentIds.payload).pipe(
          map((eqs: models.IEquipment[]) => new actions.ReceiveSavedEquipments(eqs)),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveSavedEquipments(error))),
        ),
      ),
    );
  });

  requestSavedLocationEquipments$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentCRUDActionTypes.REQUEST_SAVED_LOCATION_EQUIPMENTS),
      switchMap((aggregateId: { payload: string }) =>
        this.workshopService.getSavedLocationEquipments(aggregateId.payload).pipe(
          map((eqs: models.IEquipment[]) => new actions.ReceiveSavedLocationEquipments(eqs)),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveSavedLocationEquipments(error))),
        ),
      ),
    );
  });

  addEquipment$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentCRUDActionTypes.REQUEST_ADD_EQUIPMENT),
      switchMap((equipment: { payload: models.ICreateUpdateEquipment }) =>
        this.workshopService.addEquipment(equipment.payload).pipe(
          map((eq: HttpResponse<models.IEquipment>) => new actions.ReceiveAddEquipment(eq?.body)),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorAddEquipment(error))),
        ),
      ),
    );
  });

  refreshEquipmentListOnAddEquipment$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentCRUDActionTypes.RECEIVE_ADD_EQUIPMENT),
      map(() => new actions.RequestEquipmentPage()),
    );
  });

  updateEquipment$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentCRUDActionTypes.REQUEST_UPDATE_EQUIPMENT),
      switchMap((equipment: { payload: models.ICreateUpdateEquipment }) => {
        return this.workshopService.updateEquipment(equipment.payload).pipe(
          map((eq: models.IEquipment) => new actions.ReceiveUpdateEquipment(eq)),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorUpdateEquipment(error))),
        );
      }),
    );
  });

  requestEquipmentDelete$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentCRUDActionTypes.REQUEST_EQUIPMENT_DELETE),
      switchMap((equipment: { payload: models.IEquipment }) =>
        this.workshopService.deleteEquipment(equipment).pipe(
          map((deletedEquipment: models.IEquipment) => new actions.ReceiveEquipmentDelete(deletedEquipment)),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveEquipmentDelete(error))),
        ),
      ),
    );
  });

  requestUploadCSVFile$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentCRUDActionTypes.REQUEST_UPLOAD_EQUIPMENT_CSV_FILE),
      switchMap((file: File) =>
        this.workshopService.uploadCSVFile(file).pipe(
          map((response: any) => new ReceiveUploadEquipmentCSVFile(response)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUploadEquipmentCSVFile(error))),
        ),
      ),
    );
  });

  resetState$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(
        actions.EquipmentCRUDActionTypes.RECEIVE_UPDATE_EQUIPMENT,
        actions.EquipmentCRUDActionTypes.ERROR_UPDATE_EQUIPMENT,
        actions.EquipmentCRUDActionTypes.RECEIVE_ADD_EQUIPMENT,
        actions.EquipmentCRUDActionTypes.ERROR_ADD_EQUIPMENT,
      ),
      map(() => {
        return new actions.ResetState();
      }),
    );
  });
}
