import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { captureMessage } from '@sentry/angular-ivy';
import { Observable, map } from 'rxjs';
import { CommonConstants, LoggerService } from 'src/app/common';
import { environment } from 'src/environments/environment';
import { HttpHelper } from '../../../common/services/http-helper/http-helper';
import { ILegalTermsData } from '../../state/legal-terms/models/legal-terms.model';

@Injectable({
  providedIn: 'root',
})
export class LegalTermsService {
  private baseUrl: string = environment.API_URLS.USERS;

  constructor(
    private httpClient: HttpClient,
    private httpHelper: HttpHelper,
    public loggerService: LoggerService,
  ) {}

  public getGDPRData(): Observable<ILegalTermsData | HttpErrorResponse> {
    return this.httpClient.get<ILegalTermsData>(`${this.baseUrl}/documents/gdpr`, this.httpHelper.options);
  }

  public acceptGDPR(version: string): Observable<any | HttpErrorResponse> {
    return this.httpClient
      .post(`${this.baseUrl}/documents/gdpr/accepted`, { version }, { ...this.httpHelper.options, observe: 'response' })
      .pipe(
        map((data: any) => {
          this.logWarning(data, 'GDPR');
        }),
      );
  }

  public getTaCData(): Observable<ILegalTermsData | HttpErrorResponse> {
    return this.httpClient.get<ILegalTermsData>(`${this.baseUrl}/documents/tac`, this.httpHelper.options);
  }

  public acceptTaC(version: string): Observable<any | HttpErrorResponse> {
    return this.httpClient
      .post(`${this.baseUrl}/documents/tac/accepted`, { version }, { ...this.httpHelper.options, observe: 'response' })
      .pipe(
        map((data: any) => {
          this.logWarning(data, 'TaC');
        }),
      );
  }

  public getDPAData(): Observable<ILegalTermsData | HttpErrorResponse> {
    return this.httpClient.get<ILegalTermsData>(`${this.baseUrl}/documents/dpa`, this.httpHelper.options);
  }

  public acceptDPA(version: string): Observable<any | HttpErrorResponse> {
    return this.httpClient
      .post(`${this.baseUrl}/documents/dpa/accepted`, { version }, { ...this.httpHelper.options, observe: 'response' })
      .pipe(
        map((data: any) => {
          this.logWarning(data, 'DPA');
        }),
      );
  }

  logWarning(data: any, type: string): void {
    if (data.status === 202) {
      this.loggerService.logMessage(`Accepted the same ${type} version`, CommonConstants.logType.WARNING);
      this.loggerService.logTrace(`Accepted the same ${type} version`, SeverityLevel.Warning);

      captureMessage(`Accepted the same ${type} version`, 'info');
    }
  }
}
