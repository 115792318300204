import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { CheckingSettingsModalsService, GenericFooterItemsComponent, PropertyBag } from 'src/app/common';
import { AccessControlService } from 'src/app/root/services/access-control/access-control.service';
import { RemoteMonitoringModuleRoutes } from '../../constants/remote-monitoring-module-routes.constants';
import { IncidentFilter } from '../../models/remote-monitoring.model';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  inject,
} from '@angular/core';

@Component({
  selector: 'ignis-remote-monitoring-module-footer',
  templateUrl: './remote-monitoring-module-footer.component.html',
  styleUrls: ['./remote-monitoring-module-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemoteMonitoringModuleFooterComponent<T> extends GenericFooterItemsComponent<T> implements OnChanges {
  @Input() selectedToClose: boolean;
  @Input() appearedNewIncidents: boolean;
  @Input() filterParams: IncidentFilter;
  @Input() isModalOpened: boolean;
  @Output() handleFilter: EventEmitter<void> = new EventEmitter();
  @Output() handleCloseIncident: EventEmitter<void> = new EventEmitter();

  filterButtonTooltip: string;
  filterExclamationButtonTooltip: string;

  readonly RemoteMonitoringModuleRoutes: PropertyBag = RemoteMonitoringModuleRoutes;

  destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    public router: Router,
    public translateService: TranslateService,
    public accessControlService: AccessControlService,
    private checkSettingsModal: CheckingSettingsModalsService,
    public cdr: ChangeDetectorRef,
  ) {
    super();

    this.checkSettingsModal.checkIfSettingsModalIsOpen().subscribe((isModalOpened: boolean) => {
      this.isModalOpened = isModalOpened;
      this.cdr.detectChanges();
    });

    this.router.events
      ?.pipe(
        filter((event: NavigationEnd) => event instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.cdr.detectChanges();
      });
  }

  ngOnChanges(): void {
    this.filterButtonTooltip = this.translateService.instant('FILTER_BUTTON_TOOLTIP.STR_FILTER_APPLIED') as string;
    this.filterExclamationButtonTooltip = this.translateService.instant(
      'FILTER_BUTTON_TOOLTIP.STR_FILTER_NEW_INCIDENTS',
    ) as string;
  }

  onFilterClick(e: Event): void {
    e.stopPropagation();
    this.handleFilter.emit();
  }

  onCloseIncidentClick(e: Event): void {
    e.stopPropagation();
    this.handleCloseIncident.emit();
  }
}
