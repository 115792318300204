import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PressureUnitPipe } from 'src/app/common';

@Component({
  selector: 'ignis-status-column',
  templateUrl: './status-column.component.html',
  providers: [PressureUnitPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusColumnComponent {
  @Input() statusesName: string[];
  @Input() type: string;
  @Input() incidentType: string;
  @Input() incidentSpecialExposure: string;
  @Input() cylinderRatedPressure: number;
  @Input() pressureDisplayUnit: string;

  constructor(
    private translateService: TranslateService,
    private pressureUnitPipe: PressureUnitPipe,
  ) {}

  composeTooltipTxt(statusName: string): string {
    let tooltip: string;

    switch (statusName) {
      case 'error':
        tooltip = `${this.translateService.instant('LOGBOOK_TABLE.STR_STATUS_NOT_ACCORDING_TO_GUIDELINES')}`;
        break;
      case 'warning':
        tooltip = `${this.translateService.instant('LOGBOOK_TABLE.STR_STATUS_LOW_STARTING_PRESSURE')}`;
        break;
      case 'ok':
        tooltip = `${this.translateService.instant('LOGBOOK_TABLE.STR_STATUS_OK')}`;
        break;
    }

    if (this.type === 'incident' || this.type === 'training') {
      tooltip = `${tooltip}\n ${this.translateService.instant('LOGBOOK_TABLE.STR_STATUS_CYLINDER_PRESSURE', {
        bars: this.pressureUnitPipe.transform(this.cylinderRatedPressure, [this.pressureDisplayUnit, true]),
      })}`;
    }

    return tooltip;
  }

  composeSpecialExposureTooltipTxt(): string {
    let specialExposureValue: string = this.incidentSpecialExposure;

    if (!this.incidentType && !this.incidentSpecialExposure) {
      specialExposureValue = '–';
    }

    switch (this.incidentSpecialExposure) {
      case 'asbestos':
        return `${this.translateService.instant('INCIDENT_ALARM_TYPE.STR_HAZMAT_ASBESTOS')}`;
      case 'hazmat':
        return `${this.translateService.instant('INCIDENT_ALARM_TYPE.STR_HAZMAT_HAZMAT')}`;
      case 'extreme_heat':
        return `${this.translateService.instant('INCIDENT_ALARM_TYPE.STR_HAZMAT_EXTREME_HEAT')}`;
      case 'witnessed_fatality':
        return `${this.translateService.instant('INCIDENT_ALARM_TYPE.STR_WITNESSED_FATALITY')}`;
      case 'other':
        return `${this.translateService.instant('INCIDENT_ALARM_TYPE.STR_HAZMAT_OTHER')}`;
      default:
        return `${this.translateService.instant('INCIDENT_ALARM_TYPE.STR_CUSTOM_HAZMAT', { event: specialExposureValue })}`;
    }
  }
}
