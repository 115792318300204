import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, filter } from 'rxjs';
import { IApplicationState, IStoreApiItem } from 'src/app/common';
import { ITestResultDetails } from 'src/app/workshop/models/test-results.model';
import { EquipmentTestResultsActions } from 'src/app/workshop/state/actions/equipment-test-results';
import { selectTestResultsById } from 'src/app/workshop/state/selectors';

export const serviceResultResolver: ResolveFn<Observable<IStoreApiItem<ITestResultDetails>>> = (
  route: ActivatedRouteSnapshot,
) => {
  const equipmentTestResultsActions: EquipmentTestResultsActions = inject(EquipmentTestResultsActions);
  const store: Store<IApplicationState> = inject<Store<IApplicationState>>(Store<IApplicationState>);
  const testResultId: string = route.params.id as string;

  equipmentTestResultsActions.requestTestResultsById(testResultId);

  return store
    .select(selectTestResultsById)
    .pipe(filter((testResultData: IStoreApiItem<ITestResultDetails>) => !testResultData.isLoading));
};
