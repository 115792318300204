import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2,
  inject,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StorageMap } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { includes } from 'lodash-es';
import { Subscription } from 'rxjs';
import { CommonConstants, UserStorageService } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { AlarmsInfo, IOKTAProfile, PropertyBag } from 'src/app/common/models/common.model';
import { AppModuleRoutes } from '../../constants/app-routes.constants';
import { AppUserPermissionList } from '../../models/app-types';
import { AccessControlService } from '../../services/access-control/access-control.service';

interface IProductFruitsAPI {
  announcementsV2: {
    listen: (event: string, callback: (data: { count: number }) => void) => void;
    attachNewsWidgetToElement: (element: HTMLElement) => void;
  };
}

declare global {
  interface Window {
    productFruits?: {
      api: IProductFruitsAPI;
    };
  }
}

@Component({
  selector: 'ignis-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NavbarComponent extends OnDestroyMixin() implements OnInit, AfterViewInit {
  @Input() alarmsInfo: AlarmsInfo;
  @Input() permissions: string[];

  @Output() openSidebar: EventEmitter<boolean> = new EventEmitter();
  @Output() openAlarmsWidget: EventEmitter<boolean> = new EventEmitter();

  licensesModalOpen: boolean = false;
  openSettingsModal: boolean = false;
  user: Partial<IOKTAProfile>;
  jwtHelper: JwtHelperService = new JwtHelperService();
  readonly AppModuleRoutes: PropertyBag = AppModuleRoutes;
  ramProduct: string = 'ram';
  firegroundProduct: string = 'fireground';
  productFruitsSubscription?: Subscription;
  newsFeedCount: number;

  modalNames: PropertyBag = CommonConstants.modalsName;

  appUserPermissionList: typeof AppUserPermissionList = AppUserPermissionList;

  document: Document;
  currentAppTheme: string;

  router: Router = inject(Router);
  titleService: Title = inject(Title);
  accessControlService: AccessControlService = inject(AccessControlService);
  userStorageService: UserStorageService = inject(UserStorageService);
  storage: StorageMap = inject(StorageMap);
  translateService: TranslateService = inject(TranslateService);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  renderer: Renderer2 = inject(Renderer2);
  el: ElementRef = inject(ElementRef);

  constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth) {
    super();

    this.document = inject(DOCUMENT);
    this.currentAppTheme = this.document.body.className.split(' ')[1];
  }

  ngOnInit(): void {
    this.user = this.jwtHelper.decodeToken(localStorage.getItem(CommonConstants.AUTH_TOKEN));

    if (CommonConstants.refreshAppAfterTicketSaved in localStorage) {
      this.toggleModal(this.modalNames.LICENSE, true);
      localStorage.removeItem(CommonConstants.refreshAppAfterTicketSaved);
    }
  }

  ngAfterViewInit(): void {
    this.logoNavigateToMain();
    this.activateProductFruitsNewsFeed();
  }

  async activateProductFruitsNewsFeed(): Promise<void> {
    if (this.accessControlService.productFruitsFeatureToggle) {
      const newsfeedElem: HTMLElement = this.document.getElementById('newsfeedBtn');

      await this.waitForProductFruits();

      window.productFruits?.api.announcementsV2.listen('newsfeed-unread-count-changed', (data: { count: number }) => {
        this.newsFeedCount = data.count;
      });

      window.productFruits?.api.announcementsV2.attachNewsWidgetToElement(newsfeedElem);
    }
  }

  waitForProductFruits(): Promise<void> {
    return new Promise((resolve: () => void) => {
      const intervalId: Partial<unknown> = setInterval(() => {
        if (window.productFruits?.api?.announcementsV2) {
          clearInterval(intervalId as unknown as number);
          resolve();
        }
      }, 100);

      setTimeout(() => {
        clearInterval(intervalId as unknown as number);
      }, 10000);
    });
  }

  logoNavigateToMain(): void {
    const logoElement: Element = this.el.nativeElement.querySelector('odx-logo');

    if (logoElement) {
      this.renderer.listen(logoElement, 'click', () => {
        this.router.navigate(['']);
      });
    }
  }

  openSidebarHandler(): void {
    this.openSidebar.emit(true);
    this.cdr.detectChanges();
  }

  navigateToDashboard(): void {
    localStorage.removeItem(CommonConstants.routeWithoutAuthentication);
    localStorage.removeItem(CommonConstants.routeToNavigateAfterLogin);

    this.router.navigate(['']);
  }

  handleLogoutClick(): void {
    this.userStorageService.clearBrowserLocalStorageWithoutPersistentKeys();
    localStorage.setItem(CommonConstants.routeToNavigateAfterLogin, window.location.href);
    sessionStorage.clear();

    this.oktaAuth.signOut();
  }

  toggleModal(modalName: string, open: boolean): void {
    localStorage.setItem(CommonConstants.modalIsOpened, JSON.stringify({ modalName, open }));
    this.storage.set(CommonConstants.modalIsOpened, { modalName, open }).subscribe(() => {
      return;
    });

    switch (modalName) {
      case this.modalNames.SETTINGS:
        this.openSettingsModal = open;
        break;
      case this.modalNames.LICENSE:
        this.licensesModalOpen = open;
        break;
      default:
        this.openSettingsModal = false;
        this.licensesModalOpen = false;
        break;
    }
  }

  handleAlarmsClick(): void {
    this.openAlarmsWidget.emit();
    this.cdr.detectChanges();
  }

  navigateToGuide(product: string): void {
    const availableUserGuidesForRAM: string[] = ['en-GB', 'de-DE', 'nl-NL', 'nb-NO'];
    const availableUserGuidesForFireground: string[] = ['en-GB', 'de-DE'];
    const defaultLanguage: string = 'en-GB';
    const selectedAvailableProductLanguage: string[] =
      product === this.ramProduct ? availableUserGuidesForRAM : availableUserGuidesForFireground;

    const currentAppLanguage: string = includes(selectedAvailableProductLanguage, this.translateService.currentLang)
      ? this.translateService.currentLang
      : defaultLanguage;

    const url: string = `https://sc.draeger.com/public/user-guides/${product}/${currentAppLanguage}/index.html`;

    window.open(url, '_blank');
  }
}
