import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { format, isBefore, isSameDay, startOfToday } from 'date-fns';
import { CommonConstants } from 'src/app/common';
import { DatePickerComponent } from 'src/app/common/components/datepicker/datepicker.component';
import { WorkshopConstants } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class OutOfServiceFieldInteractionService {
  cloneSuffix: string = 'Clone';

  public changeOutOfServiceDate(
    event: Date,
    form: FormGroup,
    opStatusField: string,
    textareaFieldName: string,
    datePickerElem?: DatePickerComponent,
    userInteraction?: boolean,
    fieldName?: string,
  ): void {
    this.checkOutOfServiceReasonFieldState(event, form, textareaFieldName);

    if (form && event && (isSameDay(new Date(event), startOfToday()) || isBefore(new Date(event), startOfToday()))) {
      form.get(opStatusField).setValue(WorkshopConstants.operationalStatuses.NON_OPERATIONAL);
      form.get(opStatusField).disable();

      if (userInteraction) {
        this.setIdentifierPrefix(form, event, fieldName);
        datePickerElem?.resetRange();
      }
    } else {
      form.get(opStatusField).enable();
      if (fieldName) form.get(fieldName + this.cloneSuffix).patchValue(null);
    }
  }

  public checkOutOfServiceReasonFieldState(date: Date, form: FormGroup, fieldName: string): void {
    if (date) {
      form.get(fieldName).enable();

      return;
    }

    form.get(fieldName)?.setValue(null);
    form.get(fieldName)?.disable();
  }

  setIdentifierPrefix(form: FormGroup, event: Date, fieldName?: string): void {
    if (fieldName) {
      const value: string = (form.get(fieldName).value as string) || '';
      const date: string = format(new Date(event), CommonConstants.DATE_FORMAT_PRINT_NAME);

      form.get(fieldName + this.cloneSuffix).patchValue(`${date}_${value}`);
    }
  }
}
