import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalRef, ModalService, ModalSize, ModalVariant } from '@odx/angular/components/modal';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { CommonConstants } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IIncident } from './../../models/incident.model';

@Component({
  selector: 'ignis-merge-incidents',
  templateUrl: './merge-incidents.component.html',
  styleUrls: ['./merge-incidents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MergeIncidentsComponent extends OnDestroyMixin() implements OnChanges {
  @ViewChild('mergeIncidentsModal', { read: TemplateRef })
  public mergeIncidentsModal: TemplateRef<any>;
  modalRef: ModalRef;
  @Input() openModal: boolean = false;
  @Input() selectedIncidents: IIncident[];
  @Input() formatDate: string;
  @Output() handleCloseMergeIncidentsModal: EventEmitter<boolean> = new EventEmitter();
  openConfirmMergeIncidents: boolean = false;
  mergeIncidentsForm: FormGroup = new FormGroup({});

  constructor(
    private readonly modalService: ModalService,
    private router: Router,
  ) {
    super();
  }

  ngOnChanges(): void {
    if (this.openModal && this.selectedIncidents.length === 2) {
      this.openMergeIncidentsModal();

      const secondIncidentFormatStartDate: string = format(
        new Date(this.selectedIncidents[1].startTime),
        this.formatDate,
      );
      const secondIncidentFormatStartHour: string = formatInTimeZone(
        new Date(this.selectedIncidents[1].startTime),
        Intl.DateTimeFormat().resolvedOptions().timeZone,
        CommonConstants.TIME_WITH_SECONDS_FORMAT,
      );

      const secondIncidentFormatEndDate: string = format(
        new Date(new Date(this.selectedIncidents[1].startTime).getTime() + this.selectedIncidents[1].duration * 1000),
        this.formatDate,
      );

      const secondIncidentFormatEndHour: string = formatInTimeZone(
        new Date(new Date(this.selectedIncidents[1].startTime).getTime() + this.selectedIncidents[1].duration * 1000),
        Intl.DateTimeFormat().resolvedOptions().timeZone,
        CommonConstants.TIME_WITH_SECONDS_FORMAT,
      );

      const firstIncidentFormatStartDate: string = format(
        new Date(this.selectedIncidents[0].startTime),
        this.formatDate,
      );
      const firstIncidentFormatStartHour: string = formatInTimeZone(
        new Date(this.selectedIncidents[0].startTime),
        Intl.DateTimeFormat().resolvedOptions().timeZone,
        CommonConstants.TIME_WITH_SECONDS_FORMAT,
      );

      const firstIncidentFormatEndDate: string = format(
        new Date(new Date(this.selectedIncidents[0].startTime).getTime() + this.selectedIncidents[0].duration * 1000),
        this.formatDate,
      );

      const firstIncidentFormatEndHour: string = formatInTimeZone(
        new Date(new Date(this.selectedIncidents[0].startTime).getTime() + this.selectedIncidents[1].duration * 1000),
        Intl.DateTimeFormat().resolvedOptions().timeZone,
        CommonConstants.TIME_WITH_SECONDS_FORMAT,
      );

      this.mergeIncidentsForm = new FormGroup({
        first_date: new FormControl({
          value: `${firstIncidentFormatStartDate} ${firstIncidentFormatStartHour} - ${firstIncidentFormatEndDate} ${firstIncidentFormatEndHour} `,
          disabled: true,
        }),
        first_number: new FormControl({ value: this.selectedIncidents[0].incidentNumber, disabled: true }),
        first_ecps_number: new FormControl({ value: this.selectedIncidents[0].ecpNo, disabled: true }),
        first_ecp_address: new FormControl({
          value: this.selectedIncidents[0].primaryEcp?.name || this.selectedIncidents[0].primaryEcp?.appId,
          disabled: true,
        }),
        first_deployed_firefighters: new FormControl({
          value: this.selectedIncidents[0].firefighterNo,
          disabled: true,
        }),

        second_date: new FormControl({
          value: `${secondIncidentFormatStartDate} ${secondIncidentFormatStartHour} - ${secondIncidentFormatEndDate} ${secondIncidentFormatEndHour} `,
          disabled: true,
        }),
        second_number: new FormControl({ value: this.selectedIncidents[1].incidentNumber, disabled: true }),
        second_ecps_number: new FormControl({ value: this.selectedIncidents[1].ecpNo, disabled: true }),
        second_ecp_address: new FormControl({
          value: this.selectedIncidents[1].primaryEcp?.name || this.selectedIncidents[1].primaryEcp?.appId,
          disabled: true,
        }),
        second_deployed_firefighters: new FormControl({
          value: this.selectedIncidents[1].firefighterNo,
          disabled: true,
        }),
      });
    }
  }

  openMergeIncidentsModal(): void {
    this.modalRef = this.modalService.open(this.mergeIncidentsModal, {
      id: 'merge-incidents-modal',
      size: ModalSize.MEDIUM,
      variant: ModalVariant.DEFAULT,
      dismissable: false,
      data: 'isOpen',
    });
  }

  closeMergeIncidentsModal(): void {
    this.openModal = false;
    this.handleCloseMergeIncidentsModal.emit(false);
    Object.defineProperty(this.modalRef, 'data', { value: 'isClosed', writable: false });
  }

  mergeIncidents(): void {
    this.openConfirmMergeIncidents = true;
  }

  closeConfirmMergeModal(event: string): void {
    this.openConfirmMergeIncidents = false;

    if (event === 'merge') {
      this.closeMergeIncidentsModal();
      this.handleCloseMergeIncidentsModal.emit(false);
      this.modalRef.close('');
      this.modalService.dismissAll();
    }
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();

    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (this.router.url !== 'report-center' && this.modalRef?.data === 'isOpen') {
      this.modalRef.close('');
    }
  }
}
