<ignis-progress-bar *ngIf="isLoading$ | async"></ignis-progress-bar>

<ignis-table
  #ignisTableComponent
  [tableName]="tableName"
  [localColumns]="localTableColumns"
  [datePickerFormat]="formatDate"
  [datePickerSelectionMode]="'range'"
  [readOnlyDatePicker]="false"
  [isDatePickerShowingTime]="false"
  [dataTable]="list"
  [resizableColumns]="true"
  [reorderableColumns]="true"
  [displayFilters]="true"
  [isDefaultRangeShown]="true"
  [savedFiltersName]="tableFiltersKey"
  [selectActiveOptions]="selectedDropdowns"
  [customFilterLayout]="customFilterLayouts"
  [customColumnsLayout]="['includedTasks', 'task', 'rfid', 'others', 'operationalStatus', 'serialNo']"
  [customColumnsRef]="[includedTasks, task, rfid, others, operationalStatus, serialNo]"
  [lazy]="'true'"
  [isLoading]="isLoading$ | async"
  [sortColumn]="sortColumn"
  [paginator]="'true'"
  [first]="first"
  [pageNumber]="pageNumber"
  [activeDate]="selectedDates"
  [totalRecords]="totalRecords"
  (columns)="tableHelperGetTableColumns($event)"
  (handleOnSortChange)="tableHelperOnChangeSort($event)"
  (handleTableRef)="tableHelperGetTableRef($event)"
  (handleRowSelection)="onTestResultSelect($event)"
  (handleDoubleClickRowSelection)="onTestViewResult()"
  (handleColumnVisibility)="changeColumnVisibility($event)"
  (handleFilterValue)="getFilterTableValue($event)"
  (handleOnLazyLoading)="tableHelperOnLazyLoading($event)"
  (handleOnPageChange)="tableHelperOnPageChange($event)"
>
  <ng-template #task let-task>
    <div *ngIf="task.field === 'task'" class="services-column">
      <ignis-tasks-column [tasks]="task"></ignis-tasks-column>
    </div>
  </ng-template>

  <ng-template #includedTasks let-includedTasks>
    <div *ngIf="includedTasks.field === 'includedTasks' && includedTasks.data" class="services-column">
      <ignis-tasks-column [tasks]="includedTasks"></ignis-tasks-column>
    </div>
  </ng-template>

  <ng-template #rfid let-rfid>
    <div *ngIf="rfid.field === 'rfid' && rfid.data">
      <ignis-table-ellipsis-data-column
        [columnData]="rfid"
        class="ellipsis-data-column"
      ></ignis-table-ellipsis-data-column>
    </div>
  </ng-template>

  <ng-template #others let-others>
    <div *ngIf="others.field === 'others'" class="services-column">
      <ignis-tasks-column [tasks]="others"></ignis-tasks-column>
    </div>
  </ng-template>

  <ng-template #operationalStatus let-operationalStatus>
    <div *ngIf="operationalStatus.field === 'operationalStatus'">
      <ignis-functional-status-column
        [operationalStatus]="operationalStatus"
        class="ellipsis-data-column"
      ></ignis-functional-status-column>
    </div>
  </ng-template>

  <ng-template #serialNo let-serialNo>
    <div *ngIf="serialNo.field === 'serialNo' && serialNo.data">
      <ignis-table-ellipsis-data-column
        [columnData]="serialNo"
        class="ellipsis-data-column"
      ></ignis-table-ellipsis-data-column>
    </div>
  </ng-template>
</ignis-table>

<ignis-export-table-excel
  [excelArray]="excelArray"
  [formatDate]="formatDate"
  [sheetName]="'EXCEL_TABLE_EXPORT.TEST_RESULTS' | translate"
  [fileName]="'EXCEL_TABLE_EXPORT.TEST_RESULTS' | translate"
  [tableColumns]="tableColumns"
  [excelTranslationKeys]="excelTranslationKeys"
  (handleExcelExportFinished)="tableHelperExcelExportDone()"
></ignis-export-table-excel>

<footer class="blue-bar">
  <ignis-task-documentation-list-view-footer
    [selectedItem]="selectedTestResult"
    [numberOfRecordsToBeExported]="totalRecords"
    (handleTestResult)="onTestViewResult()"
    (handleExcelExport)="exportExcelFile()"
  ></ignis-task-documentation-list-view-footer>
</footer>
