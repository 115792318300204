import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from 'redux';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { AccessControlItems, IAccessControlItems } from 'src/app/root/models/access-control-items.model';
import { AccessControlItemsService } from 'src/app/root/services/access-control-items/access-control-items.service';
import { ErrorReceiveAccessControlItems, ReceiveAccessControlItems } from '../actions/access-control-items-action-types-creators';
import { AccessControlItemsActionType } from '../actions/access-control-items-types';

@Injectable()
export class AccessControlItemsEffects {

  constructor(private accessControlItemsService: AccessControlItemsService) { }

  requestAccessControlItems$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(AccessControlItemsActionType.REQUEST_ACCESS_CONTROL_ITEMS),
      switchMap(() =>
        this.accessControlItemsService.getAccessControlItems().pipe(
          map((accessControlItems: IAccessControlItems) => new ReceiveAccessControlItems(new AccessControlItems(accessControlItems))),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAccessControlItems(error)))
        ),
      )
    );
  });

}
