import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ITableExportState } from '../models/export.model';
import { ReceiveExportInProgress, RequestTableExport, ResetTableExport } from './export-action-types-creators';

@Injectable({ providedIn: 'root' })
export class ExportActions {
  constructor(public store: Store<ITableExportState>) {}

  public requestTableExport(): void {
    this.store.dispatch(new RequestTableExport(''));
  }

  public resetTableExport(): void {
    this.store.dispatch(new ReceiveExportInProgress(false));
    this.store.dispatch(new ResetTableExport());
  }

  public startTableExporting(): void {
    this.store.dispatch(new ReceiveExportInProgress(true));
  }
}
