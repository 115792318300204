import { GenericAction } from 'src/app/common';
import {
  errorItemData,
  errorPostPutItemData,
  receiveItemData,
  receivePostPutItemData,
  requestItemData,
  requestPostPutItemData,
} from 'src/app/common/utils/reducer/reducer.utils';
import { IOrganizationAccessControl } from '../../../models/organization-settings.model';
import { OrganizationSettingsActionTypes } from '../../actions/organization-settings';
import {
  IOrganizationAccessControlState,
  OrganizationAccessControlState,
} from '../../models/organization-access-control.model';

export function organizationAccessControlReducer(
  lastState: IOrganizationAccessControlState = new OrganizationAccessControlState(),
  action: GenericAction<OrganizationSettingsActionTypes, any>,
): OrganizationAccessControlState {
  switch (action.type) {
    case OrganizationSettingsActionTypes.REQUEST_ORGANIZATION_ACCESS_CONTROL:
      return requestItemData<IOrganizationAccessControlState, IOrganizationAccessControl>(lastState, [
        'organizationAccessControl',
      ]);
    case OrganizationSettingsActionTypes.RECEIVE_ORGANIZATION_ACCESS_CONTROL:
      return receiveItemData<IOrganizationAccessControlState, IOrganizationAccessControl>(
        lastState,
        ['organizationAccessControl'],
        action.payload,
      );
    case OrganizationSettingsActionTypes.ERROR_RECEIVE_ORGANIZATION_ACCESS_CONTROL:
      return errorItemData<IOrganizationAccessControlState, IOrganizationAccessControl>(
        lastState,
        ['organizationAccessControl'],
        action.payload,
      );

    case OrganizationSettingsActionTypes.REQUEST_UPDATE_ORGANIZATION_ACCESS_CONTROL:
      return requestPostPutItemData<IOrganizationAccessControlState, IOrganizationAccessControl>(
        lastState,
        ['updateOrganizationAccessControl'],
        action.payload,
      );
    case OrganizationSettingsActionTypes.RECEIVE_UPDATE_ORGANIZATION_ACCESS_CONTROL:
      return receivePostPutItemData<IOrganizationAccessControlState, IOrganizationAccessControl>(
        lastState,
        ['updateOrganizationAccessControl'],
        action.payload,
      );
    case OrganizationSettingsActionTypes.ERROR_RECEIVE_UPDATE_ORGANIZATION_ACCESS_CONTROL:
      return errorPostPutItemData<IOrganizationAccessControlState, IOrganizationAccessControl>(
        lastState,
        ['updateOrganizationAccessControl'],
        action.payload,
      );

    default:
      return lastState;
  }
}
