import { EntryModel, IEntryModel } from 'src/app/common/models/common.model';

export interface IMonitoringIncident {
  aggregateId: string;
  incidentId: string;
  ended: boolean;
  type: IEntryModel;
  startTime: string;
  commander: string;
  location: {
    streetInfo: string;
    town: string;
    postcode: string;
    gpsCoordinate: {
      lat: number;
      lng: number;
    };
  };
  duration: number;
  ecpNo: number;
  teamNo: number;
  deployedFirefighters: number;
  standbyFirefightersNo: number;
  start?: string;
  formattedDuration?: string;
  incidentImage?: string;
  mainEcpName: string;
  reportId: string;
  closable?: boolean;
  lastUpdate?: string;
  hasFaultyReports: boolean;
  isHazmat: boolean;
  appNames: string[];
  version: number;
}

export class MonitoringIncident implements IMonitoringIncident {
  aggregateId: string;
  incidentId: string;
  ended: boolean;
  version: number;
  type: IEntryModel;
  startTime: string;
  commander: string;
  location: {
    streetInfo: string;
    town: string;
    postcode: string;
    gpsCoordinate: {
      lat: number;
      lng: number;
    };
  };
  duration: number;
  ecpNo: number;
  teamNo: number;
  deployedFirefighters: number;
  standbyFirefightersNo: number;
  incidentImage?: string;
  mainEcpName: string;
  reportId: string;
  closable: boolean;
  lastUpdate: string;
  hasFaultyReports: boolean;
  isHazmat: boolean;
  appNames: string[];

  constructor();
  constructor(remoteMonitoring: IMonitoringIncident);
  constructor(remoteMonitoring?: IMonitoringIncident) {
    if (!remoteMonitoring) {
      this.aggregateId = null;
      this.incidentId = null;
      this.ended = null;
      this.version = null;
      this.type = null;
      this.startTime = null;
      this.commander = null;
      this.location = null;
      this.duration = null;
      this.ecpNo = null;
      this.teamNo = null;
      this.deployedFirefighters = null;
      this.standbyFirefightersNo = null;
      this.mainEcpName = null;
      this.reportId = null;
      this.lastUpdate = null;
      this.closable = null;
      this.hasFaultyReports = null;
      this.isHazmat = null;
      this.appNames = null;
    } else {
      this.aggregateId = remoteMonitoring.aggregateId;
      this.incidentId = remoteMonitoring.incidentId;
      this.ended = remoteMonitoring.ended;
      this.version = remoteMonitoring.version;
      this.type = {
        value: remoteMonitoring.type.value,
      };
      this.commander = remoteMonitoring.commander;
      this.startTime = remoteMonitoring.startTime;
      this.location = {
        streetInfo: remoteMonitoring.location.streetInfo,
        town: remoteMonitoring.location.town,
        postcode: remoteMonitoring.location.postcode,
        gpsCoordinate: remoteMonitoring.location.gpsCoordinate,
      };
      this.duration = remoteMonitoring.duration;
      this.ecpNo = remoteMonitoring.ecpNo;
      this.teamNo = remoteMonitoring.ecpNo;
      this.deployedFirefighters = remoteMonitoring.deployedFirefighters;
      this.standbyFirefightersNo = remoteMonitoring.standbyFirefightersNo;
      this.mainEcpName = remoteMonitoring.mainEcpName;
      this.reportId = remoteMonitoring.reportId;
      this.closable = remoteMonitoring.closable;
      this.lastUpdate = remoteMonitoring.lastUpdate;
      this.hasFaultyReports = remoteMonitoring.hasFaultyReports;
      this.isHazmat = remoteMonitoring.isHazmat;
      this.appNames = remoteMonitoring.appNames;
    }
  }
}

export interface IMonitoringIncidents {
  incidentsRunningNo: number;
  firemenInIncidentRunningNo: number;
  incidentsEndedNo: number;
  firemenInIncidentEndedNo: number;
  incidents: IMonitoringIncident[];
}

export class MonitoringIncidents implements IMonitoringIncidents {
  incidentsRunningNo: number;
  firemenInIncidentRunningNo: number;
  incidentsEndedNo: number;
  firemenInIncidentEndedNo: number;
  incidents: IMonitoringIncident[];

  constructor();
  constructor(remoteMonitoring: IMonitoringIncidents);
  constructor(remoteMonitoring?: IMonitoringIncidents) {
    if (!remoteMonitoring) {
      this.incidentsRunningNo = null;
      this.firemenInIncidentRunningNo = null;
      this.incidentsEndedNo = null;
      this.firemenInIncidentEndedNo = null;
      this.incidents = null;
    } else {
      this.incidentsRunningNo = remoteMonitoring.incidentsRunningNo;
      this.firemenInIncidentRunningNo = remoteMonitoring.firemenInIncidentRunningNo;
      this.incidentsEndedNo = remoteMonitoring.incidentsEndedNo;
      this.firemenInIncidentEndedNo = remoteMonitoring.firemenInIncidentEndedNo;
      this.incidents = remoteMonitoring.incidents;
    }
  }
}

export interface IRemoveIncident {
  incidentAggregateId: string;
  endTime: string;
}

export class RemoveIncident implements IRemoveIncident {
  incidentAggregateId: string;
  endTime: string;

  constructor();
  constructor(removeIncident: RemoveIncident);
  constructor(removeIncident?: RemoveIncident) {
    if (!removeIncident) {
      this.incidentAggregateId = null;
      this.endTime = null;
    } else {
      this.incidentAggregateId = removeIncident.incidentAggregateId;
      this.endTime = removeIncident.endTime;
    }
  }
}

export interface IIncident {
  aggregateId: string;
  isHazmat: boolean;
  type: IEntryModel;
  location: ILocation;
  ecps: IEcp[];
  version: number;
}

export class Incident implements IIncident {
  aggregateId: string;
  isHazmat: boolean;
  type: IEntryModel;
  location: ILocation;
  ecps: IEcp[];
  version: number;

  constructor();
  constructor(incident: IIncident);
  constructor(incident?: IIncident) {
    if (!incident) {
      this.aggregateId = null;
      this.isHazmat = null;
      this.type = null;
      this.location = null;
      this.ecps = null;
      this.version = null;
    } else {
      this.aggregateId = incident.aggregateId;
      this.isHazmat = incident.isHazmat;
      this.type = new EntryModel(incident.type);
      this.location = new Location(incident.location);
      this.ecps = incident.ecps.map((ecp: IEcp) => new Ecp(ecp));
      this.version = incident.version;
    }
  }
}

export interface IIncidentFilter {
  type: IEntryModel[];
  firemenNoMin: number;
  firemenNoMax: number;
  showFinishedIncidents: boolean;
}

export class IncidentFilter implements IIncidentFilter {
  type: IEntryModel[];
  firemenNoMin: number;
  firemenNoMax: number;
  showFinishedIncidents: boolean;

  constructor();
  constructor(incidentFilter: IIncidentFilter);
  constructor(incidentFilter?: IIncidentFilter) {
    if (!incidentFilter) {
      this.type = [];
      this.firemenNoMin = 0;
      this.firemenNoMax = 0;
      this.showFinishedIncidents = true;
    } else {
      this.type = incidentFilter.type.map((t: IEntryModel) => new EntryModel(t));
      this.firemenNoMin = incidentFilter.firemenNoMin;
      this.firemenNoMax = incidentFilter.firemenNoMin;
      this.showFinishedIncidents = incidentFilter.showFinishedIncidents;
    }
  }
}

export interface IEcp {
  ecpId: string;
  ecpNumber: string;
  ecpIdentifier: string;
  ecpName: string;
  ecpOfficer: string;
  startTime: string;
  endTime: string;
  location: ILocation;
  duration: number;
  deployedFirefighters: number;
  standbyFirefightersNo: number;
  timeElapsedLastUpdate: number;
  connectionLost: boolean;
  hasDsuOrLowPressureAlarms: boolean;
  hasAlarms: boolean;
  lastUpdate: string;
  ended: boolean;
  appName: string;
  hubName: string;
}

export class Ecp implements IEcp {
  ecpId: string;
  ecpNumber: string;
  ecpIdentifier: string;
  ecpName: string;
  ecpOfficer: string;
  startTime: string;
  endTime: string;
  location: ILocation;
  duration: number;
  deployedFirefighters: number;
  standbyFirefightersNo: number;
  timeElapsedLastUpdate: number;
  connectionLost: boolean;
  hasDsuOrLowPressureAlarms: boolean;
  hasAlarms: boolean;
  lastUpdate: string;
  ended: boolean;
  appName: string;
  hubName: string;

  constructor();
  constructor(ecp: IEcp);
  constructor(ecp?: IEcp) {
    if (!ecp) {
      this.ecpId = null;
      this.ecpNumber = null;
      this.ecpIdentifier = null;
      this.ecpName = null;
      this.ecpOfficer = null;
      this.startTime = null;
      this.endTime = null;
      this.location = null;
      this.duration = null;
      this.deployedFirefighters = null;
      this.standbyFirefightersNo = null;
      this.timeElapsedLastUpdate = null;
      this.connectionLost = null;
      this.hasDsuOrLowPressureAlarms = null;
      this.hasAlarms = null;
      this.lastUpdate = null;
      this.ended = null;
      this.appName = null;
      this.hubName = null;
    } else {
      this.ecpId = ecp.ecpId;
      this.ecpNumber = ecp.ecpNumber;
      this.ecpIdentifier = ecp.ecpIdentifier;
      this.ecpName = ecp.ecpName;
      this.ecpOfficer = ecp.ecpOfficer;
      this.startTime = ecp.startTime;
      this.endTime = ecp.endTime;
      this.location = ecp.location;
      this.duration = ecp.duration;
      this.deployedFirefighters = ecp.deployedFirefighters;
      this.standbyFirefightersNo = ecp.standbyFirefightersNo;
      this.timeElapsedLastUpdate = ecp.timeElapsedLastUpdate;
      this.connectionLost = ecp.connectionLost;
      this.hasDsuOrLowPressureAlarms = ecp.hasDsuOrLowPressureAlarms;
      this.hasAlarms = ecp.hasAlarms;
      this.lastUpdate = ecp.lastUpdate;
      this.ended = ecp.ended;
      this.appName = ecp.appName;
      this.hubName = ecp.hubName;
    }
  }
}

export interface IRepeater {
  id: string;
  lowBattery: boolean;
}

export interface IHubBattery {
  hubName: string;
  overallBattery: number;
  hubBatteryLow: boolean;
}

export interface IHardwareDetails {
  repeaters: IRepeater[];
  hubBattery: IHubBattery;
}

export interface IIncidentEcp {
  id: string;
  teamsNo: number;
  firemenNo: number;
  standbyFiremenNo: number;
  teams: IEcpTeam[];
  hardwareDetails: IHardwareDetails;
}

export class IncidentEcp implements IIncidentEcp {
  id: string;
  teamsNo: number;
  firemenNo: number;
  standbyFiremenNo: number;
  teams: IEcpTeam[];
  hardwareDetails: IHardwareDetails;

  constructor();
  constructor(ecp: IIncidentEcp);
  constructor(ecp?: IIncidentEcp) {
    if (!ecp) {
      this.id = null;
      this.teamsNo = null;
      this.firemenNo = null;
      this.standbyFiremenNo = null;
      this.teams = null;
      this.hardwareDetails = null;
    } else {
      this.id = ecp.id;
      this.teamsNo = ecp.teamsNo;
      this.firemenNo = ecp.firemenNo;
      this.standbyFiremenNo = ecp.standbyFiremenNo;
      this.teams = ecp.teams.map((team: IEcpTeam) => new EcpTeam(team));
      this.hardwareDetails = ecp.hardwareDetails;
    }
  }
}

export interface IEcpTeam {
  name: string;
  status: IEntryModel;
  notes: INote[];
  hasEvacuationSignal: boolean;
  teamId: string;
  firefighters: ITeamFirefighter[];
}

export class EcpTeam implements IEcpTeam {
  name: string;
  status: IEntryModel;
  notes: INote[];
  hasEvacuationSignal: boolean;
  teamId: string;
  firefighters: ITeamFirefighter[];

  constructor();
  constructor(team: IEcpTeam);
  constructor(team?: IEcpTeam) {
    if (!team) {
      this.name = null;
      this.status = null;
      this.notes = null;
      this.hasEvacuationSignal = false;
      this.teamId = null;
      this.firefighters = null;
    } else {
      this.name = team.name;
      this.status = team.status;
      this.notes = team.notes;
      this.hasEvacuationSignal = team.hasEvacuationSignal;
      this.teamId = team.teamId;
      this.firefighters = team.firefighters.map((firefighter: ITeamFirefighter) => new TeamFirefighter(firefighter));
    }
  }
}

export interface ITeamFirefighter {
  id: string;
  name: string;
  teamLead: boolean;
  currentPressure: number;
  cylinderRatedPressure: number;
  ttw: number;
  te: number;
  tow: string;
  ttr: number;
  defaultTimeDisplay?: any;
  equipmentModel: string;
  fireFighterAlarms: IFireFighterAlarms[];
}

export class TeamFirefighter implements ITeamFirefighter {
  id: string;
  name: string;
  teamLead: boolean;
  currentPressure: number;
  cylinderRatedPressure: number;
  ttw: number;
  te: number;
  tow: string;
  ttr: number;
  equipmentModel: string;
  fireFighterAlarms: IFireFighterAlarms[];

  constructor();
  constructor(firefighter: ITeamFirefighter);
  constructor(firefighter?: ITeamFirefighter) {
    if (!firefighter) {
      this.id = null;
      this.name = null;
      this.teamLead = false;
      this.currentPressure = null;
      this.cylinderRatedPressure = null;
      this.ttw = null;
      this.te = null;
      this.tow = null;
      this.ttr = null;
      this.equipmentModel = null;
      this.fireFighterAlarms = [new FireFighterAlarms(null)];
    } else {
      this.id = firefighter.id;
      this.name = firefighter.name;
      this.teamLead = firefighter.teamLead;
      this.currentPressure = firefighter.currentPressure;
      this.cylinderRatedPressure = firefighter.cylinderRatedPressure;
      this.ttw = firefighter.ttw;
      this.te = firefighter.te;
      this.tow = firefighter.tow;
      this.ttr = firefighter.ttr;
      this.equipmentModel = firefighter.equipmentModel;
      this.fireFighterAlarms = firefighter.fireFighterAlarms;
    }
  }
}

export interface IFireFighterAlarms {
  type: {
    value: string;
    severity: number;
  };
  activationAt: string;
  duration: number;
}

export class FireFighterAlarms implements IFireFighterAlarms {
  type: {
    value: string;
    severity: number;
  };
  activationAt: string;
  duration: number;

  constructor();
  constructor(alarms: IFireFighterAlarms);
  constructor(alarms?: IFireFighterAlarms) {
    if (!alarms) {
      this.type = null;
      this.activationAt = null;
      this.duration = null;
    } else {
      this.type = alarms.type;
      this.activationAt = alarms.activationAt;
      this.duration = alarms.duration;
    }
  }
}

export interface ILocation {
  postcode: string;
  town: string;
  streetInfo: string;
  gpsCoordinate: {
    lat: number;
    lng: number;
  };
}

export class Location implements ILocation {
  postcode: string;
  town: string;
  streetInfo: string;
  gpsCoordinate: {
    lat: number;
    lng: number;
  };

  constructor();
  constructor(location: ILocation);
  constructor(location?: ILocation) {
    if (!location) {
      this.postcode = null;
      this.town = null;
      this.streetInfo = null;
      this.gpsCoordinate = null;
    } else {
      this.postcode = location.postcode;
      this.town = location.town;
      this.streetInfo = location.streetInfo;
      this.gpsCoordinate = location.gpsCoordinate;
    }
  }
}

export interface INote {
  ecpId: string;
  teamId: string;
  identifier: string;
  senderName: string;
  note: string;
  incidentLocation: ILocation;
  timestamp: string;
  time?: string;
  ecpName: string;
  read: boolean;
  teamName: string;
  mediaLink: string;
  ecpNumber: string;
  teamNumber: string;
  manualNote?: boolean;
}

export class Note implements INote {
  ecpId: string;
  teamId: string;
  identifier: string;
  senderName: string;
  note: string;
  incidentLocation: ILocation;
  timestamp: string;
  ecpName: string;
  read: boolean;
  teamName: string;
  mediaLink: string;
  ecpNumber: string;
  teamNumber: string;
  manualNote?: boolean;

  constructor();
  constructor(note: INote);
  constructor(note?: INote) {
    if (!note) {
      this.ecpId = null;
      this.teamId = null;
      this.identifier = null;
      this.senderName = null;
      this.note = null;
      this.incidentLocation = null;
      this.timestamp = null;
      this.ecpName = null;
      this.read = null;
      this.teamName = null;
      this.mediaLink = null;
      this.ecpNumber = null;
      this.teamNumber = null;
      this.manualNote = null;
    } else {
      this.ecpId = note.ecpId;
      this.teamId = note.teamId;
      this.identifier = note.identifier;
      this.senderName = note.senderName;
      this.note = note.note;
      this.incidentLocation = new Location(note.incidentLocation);
      this.timestamp = note.timestamp;
      this.ecpName = note.ecpName;
      this.read = note.read;
      this.teamName = note.teamName;
      this.mediaLink = note.mediaLink;
      this.ecpNumber = note.ecpNumber;
      this.teamNumber = note.teamNumber;
      this.manualNote = note.manualNote;
    }
  }
}

export interface IIncidentsIds {
  sourceAggregateId: string;
  destinationAggregateId: string;
}

export interface INewNote {
  incidentAggregateId: string;
  ecpId: string;
  teamId: string;
  text: string;
  version: number;
}

export class NewNote implements INewNote {
  incidentAggregateId: string;
  ecpId: string;
  teamId: string;
  text: string;
  version: number;

  constructor();
  constructor(note: INewNote);
  constructor(note?: INewNote) {
    if (!note) {
      this.incidentAggregateId = null;
      this.ecpId = null;
      this.teamId = null;
      this.text = null;
      this.version = null;
    } else {
      this.incidentAggregateId = note.incidentAggregateId;
      this.ecpId = note.ecpId;
      this.teamId = note.teamId;
      this.text = note.text;
      this.version = note.version;
    }
  }
}
