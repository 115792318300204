import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IIncidentsIds } from 'src/app/report-center/models/incident.model';
import { IncidentActionTypes } from '../incident-action-types';

export class RequestMergeIncidents implements Action {
  readonly type: IncidentActionTypes.REQUEST_MERGE_INCIDENTS = IncidentActionTypes.REQUEST_MERGE_INCIDENTS;

  constructor(public payload: IIncidentsIds) { }
}
export class ReceiveMergeIncidents implements Action {
  readonly type: IncidentActionTypes.RECEIVE_MERGE_INCIDENTS = IncidentActionTypes.RECEIVE_MERGE_INCIDENTS;

  constructor(public payload: any) { }
}
export class ErrorMergeIncidents implements Action {
  readonly type: IncidentActionTypes.ERROR_MERGE_INCIDENTS = IncidentActionTypes.ERROR_MERGE_INCIDENTS;

  constructor(public payload: HttpErrorResponse) { }
}

export class ResetState implements Action {
  readonly type: IncidentActionTypes.RESET_STATE = IncidentActionTypes.RESET_STATE;
}
