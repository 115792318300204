import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IAlarm } from '../../../models';

@Component({
  selector: 'ignis-alarms-list',
  templateUrl: './alarms-list.component.html',
  styleUrls: ['./alarms-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlarmsListComponent {
  @Input() alarms: IAlarm[];
  @Input() dateFormat: string;
  @Input() currentAppTheme: string;

  @Output() selectedAlert: EventEmitter<IAlarm> = new EventEmitter();

  router: Router = inject(Router);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  translateService: TranslateService = inject(TranslateService);

  navigateToMonitorTeamsView(incidentAggregateId: string, ecpId: string, alert: IAlarm): void {
    this.router.navigate([
      'remote-monitoring',
      'incident-overview',
      incidentAggregateId,
      'ecp',
      ecpId,
      'monitor-teams',
    ]);
    this.selectedAlert.emit(alert);
    this.cdr.detectChanges();
  }
}
