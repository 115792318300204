import { GenericAction, reducerUtils } from 'src/app/common';
import { receiveItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { IEquipmentHistoryEvent, IEquipmentHistoryFilters } from '../../../models/equipment-history.model';
import { EquipmentHistoryActionTypes } from '../../actions/equipment-history';
import { EquipmentHistoryState, IEquipmentHistoryState } from '../../models/history.model';

const resetEquipmentHistoryState = (lastState: IEquipmentHistoryState): IEquipmentHistoryState => ({
  ...lastState,
  equipmentHistory: {
    data: null,
    isLoading: false,
    errors: null,
  },
});

const resetFilterParamsState = (lastState: IEquipmentHistoryState): IEquipmentHistoryState => ({
  ...lastState,
  historyFilters: {
    isLoading: false,
    data: null,
    errors: null,
    isSuccess: false,
  },
});

export function historyReducer(
  lastState: IEquipmentHistoryState = new EquipmentHistoryState(),
  action: GenericAction<EquipmentHistoryActionTypes, any>,
): IEquipmentHistoryState {
  switch (action.type) {
    case EquipmentHistoryActionTypes.REQUEST_EQUIPMENT_HISTORY:
      return reducerUtils.requestListData<IEquipmentHistoryState, string>(lastState, ['equipmentHistory']);
    case EquipmentHistoryActionTypes.RECEIVE_EQUIPMENT_HISTORY:
      return reducerUtils.receiveListData<IEquipmentHistoryState, IEquipmentHistoryEvent[]>(
        lastState,
        ['equipmentHistory'],
        action.payload,
      );
    case EquipmentHistoryActionTypes.ERROR_RECEIVE_EQUIPMENT_HISTORY:
      return reducerUtils.errorListData<IEquipmentHistoryState, IEquipmentHistoryEvent[]>(
        lastState,
        ['equipmentHistory'],
        action.payload,
      );

    case EquipmentHistoryActionTypes.SAVE_HISTORY_FILTER_PARAMS:
      return receiveItemData<IEquipmentHistoryState, IEquipmentHistoryFilters>(
        lastState,
        ['historyFilters'],
        action.payload,
      );

    case EquipmentHistoryActionTypes.RESET_HISTORY_FILTER_PARAMS:
      return resetFilterParamsState(lastState);

    case EquipmentHistoryActionTypes.RESET_EQUIPMENT_HISTORY_STATE:
      return resetEquipmentHistoryState(lastState);

    default:
      return lastState;
  }
}
