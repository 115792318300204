import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PropertyBag } from 'src/app/common/models';
import { WorkshopConstants } from 'src/app/workshop';

@Component({
  selector: 'ignis-functional-status-column',
  templateUrl: './functional-status-column.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunctionalStatusColumnComponent {
  @Input() operationalStatus: { data: string; field: string };

  operationalStatuses: PropertyBag = WorkshopConstants.operationalStatuses;
}
