import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  CommonConstants,
  IEntryModel,
  IScrollOptions,
  LoggerService,
  PropertyBag,
  ReplaceDigitCharPipe,
  TableService,
} from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { getDecimalNumberFormatUserProfile } from 'src/app/common/utils/settings-utils/settings-utils';
import {
  ChecklistParameterTypes,
  ConfigurationConstants,
} from 'src/app/configuration/constants/configuration.constants';
import { IChecklist } from 'src/app/configuration/models/checklist.model';
import { IncidentActions } from 'src/app/report-center';

@Component({
  selector: 'ignis-manual-test-values',
  templateUrl: './manual-test-values.component.html',
  styleUrls: ['./manual-test-values.component.scss'],
  providers: [ReplaceDigitCharPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManualTaskValuesComponent extends OnDestroyMixin() {
  @Input() formatDate: string;
  @Input() checklistData: Partial<IChecklist>;

  isModalOpened: boolean = false;
  selectedImage: string;
  decimalNumberFormat: string;

  scrollbarOptions: IScrollOptions = CommonConstants.scrollbarOptions;
  types: IEntryModel[] = ConfigurationConstants.checklistParameterTypes.types;
  checklistParameterTypes: PropertyBag = ChecklistParameterTypes;

  incidentActions: IncidentActions = inject(IncidentActions);
  loggerService: LoggerService = inject(LoggerService);
  notificationsService: NotificationsService = inject(NotificationsService);
  tableService: TableService = inject(TableService);
  translateService: TranslateService = inject(TranslateService);

  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  constructor() {
    super();

    this.decimalNumberFormat = getDecimalNumberFormatUserProfile(this.translateService);
  }

  openImageModal(imageURL: string): void {
    this.selectedImage = imageURL;
    this.isModalOpened = true;
  }

  closeImageModal(): void {
    this.isModalOpened = false;
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();
  }
}
