<div class="workflows-container" *ngIf="workflowsBtnData?.length">
  <div class="odx-form-field odx-form-field--horizontal">
    <label class="odx-form-field__label" for="workflows">
      <odx-form-field-label class="odx-form-field-label">
        {{ 'WORKSHOP_WORKFLOW_ASSET.STR_WORKFLOW_EQUIPMENT_SELECTED_WORKFLOW' | translate }}
      </odx-form-field-label>
    </label>
  </div>

  <div class="d-flex workflows" id="workflows">
    <ignis-workflow-button
      *ngFor="let workflowData of workflowsBtnData"
      [workflowData]="workflowData"
      [currentlyWorkflowChecked]="currentlyWorkflowChecked"
      (handleSelectedWorkflow)="getSelectedWorkflowFromChildAndSendToTheParent($event)"
    ></ignis-workflow-button>
  </div>
</div>
