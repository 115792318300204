import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription, takeUntil } from 'rxjs';
import { IApplicationState, IStoreApiList } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { AppModulesTypes, AppUserPermissionList } from 'src/app/root/models/app-types';
import { AccessControlService } from 'src/app/root/services/access-control/access-control.service';
import { IFeatureToggle } from 'src/app/root/state/features-toggle/models/features-toggle.model';
import { selectFeaturesToggleList } from 'src/app/root/state/features-toggle/selectors/features-toggle.selector';

@Injectable()
export class RoleManagementModuleGuard extends OnDestroyMixin() {

  moduleFeatureToggle: boolean;

  constructor(
    private accessControlService: AccessControlService,
    private router: Router,
    private store: Store<IApplicationState>,
  ) {
    super();
    this.readFeatureTogglesFromStore(AppModulesTypes.roleManagementGlobal);
  }

  canActivate(): boolean {
    return this.accessControlService.canActivateModule(AppUserPermissionList.roleManagement);
  }

  readFeatureTogglesFromStore(module: string): Subscription {
    return this.store
      .pipe(select(selectFeaturesToggleList))
      .pipe(takeUntil(this.destroy))
      .subscribe((state: IStoreApiList<IFeatureToggle[]>) => {
        const featuresToggle: IFeatureToggle[] = state?.data;

        if (featuresToggle) {
          featuresToggle?.forEach((ft: IFeatureToggle) => {
            if (Object.values(ft).includes(module)) {
              this.moduleFeatureToggle = ft.isEnabled;
            }

            if (Object.values(ft).includes(module) && ft.isEnabled) {
              this.moduleFeatureToggle = ft.isEnabled;
            }
          });

          this.navigateToOverviewPage();
        }

      });
  }

  navigateToOverviewPage(): boolean {
    if (!this.moduleFeatureToggle) {
      this.router.navigate(['']);

      return false;
    }
  }

}
