<div
  class="blue-bar-items"
  *ngIf="
    accessControlService.checkPermission(appUserPermissionList.equipmentTest) &&
    (activeTab === testResultsTabs.GENERAL || activeTab === testResultsTabs.TEST_VALUES)
  "
>
  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    (click)="onSubmit($event)"
    id="bluebar-save"
    data-test-id="equipment_footer_items.save_service_results_btn"
    [disabled]="isModalOpened || isSubmitDisabled || !form?.dirty"
    [pTooltip]="saveButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #saveButtonTooltipContent>
      <div data-test-id="equipment_footer_items.save_service_results_tooltip">
        {{ 'EQUIPMENT_FOOTER_ITEMS.STR_SAVE_SERVICE_RESULTS_TOOLTIP' | translate }}
      </div>
    </ng-template>

    <odx-icon name="save" size="inline"></odx-icon>
  </button>

  <ignis-service-result-export
    [form]="form"
    [proceedExportXLSX]="proceedExportXLSX"
    [selectedTaskName]="selectedTaskName"
    [dateTime]="dateTime"
    [formatDate]="formatDate"
    [taskResultType]="taskResultType"
    [taskData]="taskData"
    [taskValues]="taskValues"
    [checklistData]="checklistData"
    (handleSaveTestResult)="saveTestResult()"
    (resetProceedExportXLSXState)="getExportXLSXState($event)"
  ></ignis-service-result-export>

  @if (printFeatureToggle) {
    <button
      type="button"
      odxButton
      size="medium"
      variant="highlight"
      class="blue-bar-btn"
      (click)="printTestResult($event)"
      id="bluebar-save"
      data-test-id="equipment_footer_items.print_service_results_btn"
      [pTooltip]="printButtonTooltipContent"
      tooltipPosition="top"
      positionLeft="5"
      positionTop="-10"
      tooltipStyleClass="footer-tooltip"
    >
      <ng-template #printButtonTooltipContent>
        @if (!isFromMigration) {
          <div data-test-id="equipment_footer_items.print_service_results_tooltip">
            {{ 'EQUIPMENT_FOOTER_ITEMS.STR_PRINT_SERVICE_RESULTS_TOOLTIP' | translate }}
          </div>
        } @else {
          <div data-test-id="equipment_footer_items.print_service_results_tooltip">
            {{ 'EQUIPMENT_FOOTER_ITEMS.STR_ONLY_PRINT_SERVICE_RESULTS_TOOLTIP' | translate }}
          </div>
        }
      </ng-template>
      <odx-icon name="print-selection" size="inline"></odx-icon>
    </button>
  }
</div>
