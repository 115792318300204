import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

export function createTestEquipmentForm(): FormGroup {
  return new FormGroup({
    kind: new FormControl({ value: null, disabled: true }),
    equipmentId: new FormControl({ value: null, disabled: true }),
    barcode: new FormControl({ value: null, disabled: true }),
    rfid: new FormControl({ value: null, disabled: true }),
    serialNo: new FormControl({ value: null, disabled: true }),
    equipmentModel: new FormControl({ value: null, disabled: true }),
    task: new FormControl(null, Validators.required),
    testProcedure: new FormControl(null, Validators.required),
    pressureGaugeId: new FormControl(null),
    testDevice: new FormControl(null, Validators.required),
    testType: new FormControl(null, Validators.required),
    testerName: new FormControl({ value: null, disabled: true }, Validators.required),
  });
}

export function testResultsFormFieldsDisabled(): FormGroup {
  return new FormGroup({
    barcode: new FormControl({ value: null, disabled: true }),
    rfid: new FormControl({ value: null, disabled: true }),
    serialNo: new FormControl({ value: null, disabled: true }),
    comment: new FormControl({ value: null, disabled: true }),
    deviceSerialNumber: new FormControl({ value: null, disabled: true }),
    softwareVersion: new FormControl({ value: null, disabled: true }),
    operationalStatus: new FormControl({ value: null, disabled: true }),
    airQualityMeasured: new FormControl({ value: null, disabled: true }),
    testerName: new FormControl({ value: null, disabled: true }),
    selectedTaskName: new FormControl({ value: null, disabled: true }),
    pressure: new FormControl({ value: null, disabled: true }),
    identification: new FormControl({ value: null, disabled: true }),
    dateTime: new FormControl({ value: null, disabled: true }),
    testEquipment: new FormControl({ value: null, disabled: true }),
    equipmentType: new FormControl({ value: null, disabled: true }),
    equipmentModel: new FormControl({ value: null, disabled: true }),
    aggregateId: new FormControl({ value: null, disabled: true }),
    resultId: new FormControl({ value: null, disabled: true }),
    testProcedure: new FormControl({ value: null, disabled: true }),
    testType: new FormControl({ value: null, disabled: true }),
    locationPath: new FormControl({ value: null, disabled: true }),
    outOfServiceDate: new FormControl({ value: null, disabled: false }),
    outOfServiceReason: new FormControl({ value: null, disabled: true }),
    newBarcode: new FormControl({ value: null, disabled: true }),
    newRfid: new FormControl({ value: null, disabled: true }),
    version: new FormControl({ value: null, disabled: true }),
    serviceDefinition: new FormGroup({
      name: new FormControl({ value: null, disabled: true }),
      serviceIntervals: new FormGroup({
        name: new FormControl({ value: null, disabled: true }),
        oldLastTestDate: new FormControl({ value: null, disabled: true }),
        oldNextTestDate: new FormControl({ value: null, disabled: true }),
        newLastTestDate: new FormControl({ value: null, disabled: true }),
        newNextTestDate: new FormControl({ value: null, disabled: true }),
      }),
    }),
    checklist: new FormGroup({
      name: new FormControl({ value: null, disabled: true }),
      description: new FormControl({ value: null, disabled: true }),
      items: new FormArray([]),
    }),
    taskValues: new FormGroup({
      description: new FormControl({ value: null, disabled: true }),
      assessment: new FormControl({ value: null, disabled: true }),
      value: new FormControl({ value: null, disabled: true }),
      minValue: new FormControl({ value: null, disabled: true }),
      maxValue: new FormControl({ value: null, disabled: true }),
      unit: new FormControl({ value: null, disabled: true }),
      parameters: new FormControl({ value: null, disabled: true }),
    }),
    otherTasks: new FormControl({ value: null }),
  });
}
