import { createSelector, DefaultProjectorFn, MemoizedSelector, Selector } from '@ngrx/store';
import { ApplicationState, IApplicationState } from 'src/app/common';
import { ILegalTermsData, ILegalTermsState } from './../models/legal-terms.model';
import { IStoreApiItem } from './../../../../common/models/store-api-item.model';

export const legalTermsState: Selector<IApplicationState, ILegalTermsState> = (state: IApplicationState) => state.legalTerms;

export const selectLegalTermsData: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<ILegalTermsData>,
  DefaultProjectorFn<IStoreApiItem<ILegalTermsData>>
> = createSelector(legalTermsState, (state: ILegalTermsState) => state.legalTermsData);

export const selectAcceptLegalTerms: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<any>,
  DefaultProjectorFn<IStoreApiItem<any>>
> = createSelector(legalTermsState, (state: ILegalTermsState) => state.accepted);

