import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnChanges, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CheckingSettingsModalsService, GenericFooterItemsComponent, PropertyBag } from 'src/app/common';
import { IRAMSettings } from 'src/app/configuration/models/organization-settings.model';
import { AccessControlService } from 'src/app/root';
import { WorkshopModuleRoutes } from 'src/app/workshop/constants/workshop-module-routes.constants';

@Component({
  selector: 'ignis-checklist-workflow-footer-view',
  templateUrl: './checklist-workflow-footer-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistWorkflowFooterViewComponent<T> extends GenericFooterItemsComponent<T> implements OnChanges {
  @Input() disableChecklistBntFlow: boolean;
  @Input() isModalOpened: boolean;
  @Input() isSubmitDisabled: boolean = false;
  @Input() isWorkflowInProgress: boolean;
  @Input() ramSettings: IRAMSettings;
  @Input() displayNoServiceIdTooltip: boolean;
  @Input() displayNoChecklistTooltip: boolean;

  @Output() handlePerformCompleteChecklist: EventEmitter<void> = new EventEmitter<void>();

  readonly WorkshopModuleRoutes: PropertyBag = WorkshopModuleRoutes;

  batchChecklistFeatureToggle: boolean;

  public router: Router = inject(Router);
  private checkSettingsModal: CheckingSettingsModalsService = inject(CheckingSettingsModalsService);
  public accessControlService: AccessControlService = inject(AccessControlService);

  constructor() {
    super();

    this.checkSettingsModal.checkIfSettingsModalIsOpen().subscribe((isModalOpened: boolean) => {
      this.isModalOpened = isModalOpened;
      this.cdr.detectChanges();
    });

    this.batchChecklistFeatureToggle = this.accessControlService.equipmentBatchChecklistFeatureToggle;
  }

  ngOnChanges(): void {
    if (this.isWorkflowInProgress) {
      this.selectedItems = null;
    }
  }

  onPerformCompleteChecklistClick(e: Event): void {
    e.stopPropagation();
    this.handlePerformCompleteChecklist.emit();
  }
}
