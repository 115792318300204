<form odxForm [formGroup]="organizationSettingsForm" class="org-settings-section-ml-25">
  <section odxLayout="grid" formGroupName="organizationSettings">
    <div odxLayout="12 12@tablet 3@desktop" class="custom-form-field-pr-10">
      <odx-form-field
        variant="horizontal"
        label="{{ 'ORGANIZATION_SETTINGS.STR_ORGANIZATION_NAME' | translate }}"
        [readonly]="organizationSettingsForm.get('organizationSettings.name').disabled"
      >
        <input
          type="text"
          ignisDisableAutocomplete
          id="organizationName"
          data-test-id="organization_settings.organization_name"
          ngDefaultControl
          formControlName="name"
          [title]="organizationSettingsForm.get('organizationSettings.name').value"
          odxFormFieldControl
          [pTooltip]="'ORGANIZATION_SETTINGS.STR_ORGANIZATION_NAME_TOOLTIP' | translate"
          tooltipPosition="top"
          positionTop="-10"
        />
      </odx-form-field>
    </div>
    <div odxLayout="12 12@tablet 3@desktop" class="custom-form-field-pl-10 custom-form-field-pr-10">
      <odx-form-field
        variant="horizontal"
        label="{{ 'ORGANIZATION_SETTINGS.STR_LANGUAGE' | translate }}"
        [readonly]="organizationSettingsForm.get('organizationSettings.language').disabled"
      >
        <div class="w-100">
          <p-dropdown
            #dd1
            class="custom-dropdown-form"
            ngDefaultControl
            odxFormFieldControl
            formControlName="language"
            data-test-id="organization_settings.language"
            [dropdownIcon]="dropdownIconCSSClass"
            [options]="languagesOptions"
            [appendTo]="'body'"
            [showClear]="false"
          >
            <ng-template let-selectedItem pTemplate="selectedItem">
              <span [title]="selectedItem?.label">{{ selectedItem?.label }}</span>
            </ng-template>
            <ng-template let-option pTemplate="item">
              <span>{{ option?.label }}</span>
            </ng-template>
          </p-dropdown>
        </div>
      </odx-form-field>
    </div>
    <div odxLayout="12 12@tablet 3@desktop" class="custom-form-field-pl-10">
      <odx-form-field
        variant="horizontal"
        label="{{ 'ORGANIZATION_SETTINGS.STR_DATE_TIME_FORMAT' | translate }}"
        [readonly]="organizationSettingsForm.get('organizationSettings.dateFormat').disabled"
      >
        <div class="w-100">
          <p-dropdown
            #dd2
            class="custom-dropdown-form"
            ngDefaultControl
            odxFormFieldControl
            formControlName="dateFormat"
            data-test-id="organization_settings.date_format"
            [dropdownIcon]="dropdownIconCSSClass"
            [options]="dateTimeFormatOptions"
            [appendTo]="'body'"
            [showClear]="false"
          >
            <ng-template let-selectedItem pTemplate="selectedItem">
              <span [title]="selectedItem">{{ selectedItem }}</span>
            </ng-template>
            <ng-template let-option pTemplate="item">
              <span>{{ option }}</span>
            </ng-template>
          </p-dropdown>
        </div>
      </odx-form-field>
    </div>
  </section>
</form>
