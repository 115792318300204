<div class="blue-bar-items">
  <button
    type="button"
    [disabled]="!selectedItem || isModalOpened"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    (click)="onTestResultClick($event)"
    id="open-test-result"
    data-test-id="task_documentation_footer_items.task_documentation_btn"
    [pTooltip]="testResultButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #testResultButtonTooltipContent>
      @if (!accessControlService.checkPermission(appUserPermissionList.equipmentTaskDocumentation)) {
        <div data-test-id="equipment_footer_items.test_results_no_permission_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_NO_PERMISSION' | translate }}
        </div>
      } @else {
        <div data-test-id="equipment_footer_items.test_results_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_ACCESS_SERVICE_RESULTS_TOOLTIP' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon name="test-results" size="inline"></odx-icon>
  </button>
  <ignis-export-excel-footer-btn
    [isModalOpened]="isModalOpened"
    [numberOfRecordsToBeExported]="numberOfRecordsToBeExported"
    (handleClickEvent)="onExportExcelClick($event)"
  >
  </ignis-export-excel-footer-btn>
</div>
