import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IIncidentFilter, IIncidentsIds, INewNote, INote } from '../../models/remote-monitoring.model';
import { IRemoteMonitoringState } from '../models/remote-monitoring.model';
import { IRemoveIncident } from './../../models/remote-monitoring.model';
import { RequestIncidentEntries } from './remote-monitoring-action-types-creators/remote-monitoring-entries-action-types-creators';
import {
  ResetFilterParams,
  SaveFilterParams,
} from './remote-monitoring-action-types-creators/remote-monitoring-filter-action-types-creators';
import {
  RequestIncidentById,
  RequestIncidentEcpById,
  RequestMonitoringIncidents,
  RequestRemoveIncident,
} from './remote-monitoring-action-types-creators/remote-monitoring-incident-action-types-creators';
import { RequestMergeIncidents } from './remote-monitoring-action-types-creators/remote-monitoring-merging-action-types-creators';
import {
  RequestAddNote,
  RequestNotesList,
  RequestReadNotes,
  ResetAddNote,
} from './remote-monitoring-action-types-creators/remote-monitoring-notes-action-types-creators';

@Injectable({ providedIn: 'root' })
export class RemoteMonitoringActions {
  constructor(private store: Store<IRemoteMonitoringState>) {}

  public requestMonitoringIncidents(): void {
    this.store.dispatch(new RequestMonitoringIncidents());
  }

  public requestIncidentById(id: string): void {
    this.store.dispatch(new RequestIncidentById(id));
  }

  public requestIncidentEcpById(incidentId: string, ecpId: string): void {
    this.store.dispatch(new RequestIncidentEcpById(incidentId, ecpId));
  }

  public requestNotesList(id: string): void {
    this.store.dispatch(new RequestNotesList(id));
  }

  public saveFilterParams(params: IIncidentFilter): void {
    this.store.dispatch(new SaveFilterParams(params));
  }

  public requestIncidentEntries(): void {
    this.store.dispatch(new RequestIncidentEntries());
  }

  public requestRemoveIncident(params: IRemoveIncident, incidentVersion: number): void {
    this.store.dispatch(new RequestRemoveIncident(params, incidentVersion));
  }

  public requestMergeIncidents(incidentsIds: IIncidentsIds, incidentVersion: number): void {
    this.store.dispatch(new RequestMergeIncidents(incidentsIds, incidentVersion));
  }

  public requestReadNotes(incidentId: string, notes: Partial<INote>[], incidentVersion: number): void {
    this.store.dispatch(new RequestReadNotes(incidentId, notes, incidentVersion));
  }

  public requestAddNote(newNote: INewNote): void {
    this.store.dispatch(new RequestAddNote(newNote));
  }

  public requestResetAddNote(): void {
    this.store.dispatch(new ResetAddNote());
  }

  public resetFilterParams(): any {
    this.store.dispatch(new ResetFilterParams());
  }
}
