import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IRAMStatus, ITestStatus, IToken } from 'src/app/workshop/models';
import { RAMActionTypes } from './ram-action-types';

export class RequestRAMConnectorToken implements Action {
  readonly type: RAMActionTypes.REQUEST_RAM_CONNECTOR_TOKEN = RAMActionTypes.REQUEST_RAM_CONNECTOR_TOKEN;
}
export class ReceiveRAMConnectorToken implements Action {
  readonly type: RAMActionTypes.RECEIVE_RAM_CONNECTOR_TOKEN = RAMActionTypes.RECEIVE_RAM_CONNECTOR_TOKEN;

  constructor(public payload: IToken) {}
}
export class ErrorReceiveRAMConnectorToken implements Action {
  readonly type: RAMActionTypes.ERROR_RECEIVE_RAM_CONNECTOR_TOKEN = RAMActionTypes.ERROR_RECEIVE_RAM_CONNECTOR_TOKEN;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestCheckRAMConnectorStatus implements Action {
  readonly type: RAMActionTypes.REQUEST_CHECK_RAM_CONNECTOR_STATUS = RAMActionTypes.REQUEST_CHECK_RAM_CONNECTOR_STATUS;

  constructor(public payload: number) {}
}
export class ReceiveCheckRAMConnectorStatus implements Action {
  readonly type: RAMActionTypes.RECEIVE_CHECK_RAM_CONNECTOR_STATUS = RAMActionTypes.RECEIVE_CHECK_RAM_CONNECTOR_STATUS;

  constructor(public payload: ITestStatus) {}
}
export class ErrorReceiveCheckRAMConnectorStatus implements Action {
  readonly type: RAMActionTypes.ERROR_RECEIVE_CHECK_RAM_CONNECTOR_STATUS =
    RAMActionTypes.ERROR_RECEIVE_CHECK_RAM_CONNECTOR_STATUS;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestCheckRAMPortStatus implements Action {
  readonly type: RAMActionTypes.REQUEST_CHECK_RAM_PORT_STATUS = RAMActionTypes.REQUEST_CHECK_RAM_PORT_STATUS;

  constructor(public payload: number) {}
}
export class ReceiveCheckRAMPortStatus implements Action {
  readonly type: RAMActionTypes.RECEIVE_CHECK_RAM_PORT_STATUS = RAMActionTypes.RECEIVE_CHECK_RAM_PORT_STATUS;

  constructor(public payload: IRAMStatus) {}
}
export class ErrorReceiveCheckRAMPortStatus implements Action {
  readonly type: RAMActionTypes.ERROR_RECEIVE_CHECK_RAM_PORT_STATUS =
    RAMActionTypes.ERROR_RECEIVE_CHECK_RAM_PORT_STATUS;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestRAMAutoUpdate implements Action {
  readonly type: RAMActionTypes.REQUEST_RAM_AUTO_UPDATE = RAMActionTypes.REQUEST_RAM_AUTO_UPDATE;
}
export class ReceiveRAMAutoUpdate implements Action {
  readonly type: RAMActionTypes.RECEIVE_RAM_AUTO_UPDATE = RAMActionTypes.RECEIVE_RAM_AUTO_UPDATE;

  constructor(public payload: object) {}
}
export class ErrorReceiveRAMAutoUpdate implements Action {
  readonly type: RAMActionTypes.ERROR_RECEIVE_RAM_AUTO_UPDATE = RAMActionTypes.ERROR_RECEIVE_RAM_AUTO_UPDATE;

  constructor(public payload: HttpErrorResponse) {}
}
