import { createSelector, DefaultProjectorFn, MemoizedSelector, Selector } from '@ngrx/store';
import { ApplicationState, IApplicationState, IStoreApiItem } from 'src/app/common';
import { IAccessControlItems } from 'src/app/root/models/access-control-items.model';
import { IAccessControlItemsState } from '../models/access-control-items.model';


export const accessControlItemsState: Selector<IApplicationState, IAccessControlItemsState> = (state: IApplicationState) => state.accessControlItems;

export const selectAccessControlItems: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IAccessControlItems>,
  DefaultProjectorFn<IStoreApiItem<IAccessControlItems>>
> = createSelector(accessControlItemsState, (state: IAccessControlItemsState) => state.accessControlItems);
