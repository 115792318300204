import { IStoreApiItem } from 'src/app/common';
import { StoreApiItem } from '../../../../common/models/store-api-item.model';

export interface ILicenseStatus {
  active: boolean;
}

export interface ILicensesState {
  licenseStatus: IStoreApiItem<ILicenseStatus>;
  ticketID: IStoreApiItem<ILicenseStatus>;
}

export class LicensesState implements ILicensesState {
  licenseStatus: IStoreApiItem<ILicenseStatus> = new StoreApiItem();
  ticketID: IStoreApiItem<ILicenseStatus> = new StoreApiItem();
}
