<ng-template odxModal #confirmStartCylinderChargeModal>
  <odx-modal-hero icon="warning" variant="confirmation" data-test-id="CONFIRM_CLOSE_MODAL.STR_TITLE">
    {{ 'CONFIRM_CLOSE_MODAL.STR_TITLE' | translate }}
  </odx-modal-hero>
  <odx-modal-content>
    {{ 'CONFIRM_CLOSE_MODAL.STR_CYLINDER_NOT_OPERATIONAL' | translate }}
  </odx-modal-content>

  <odx-modal-footer>
    <button odxButton variant="secondary" [odxModalClose]="false" data-test-id="modal_actions.no_btn">
      {{ 'CONFIRM_CLOSE_MODAL.STR_NO_BTN' | translate }}
    </button>
    <button odxButton variant="primary" [odxModalClose]="true" data-test-id="modal_actions.yes_btn">
      {{ 'CONFIRM_CLOSE_MODAL.STR_YES_BTN' | translate }}
    </button>
  </odx-modal-footer>
</ng-template>
