import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IEquipmentConfiguration } from 'src/app/configuration/models';
import { EquipmentConfigurationActionTypes } from './equipment-config-action-types';

export class RequestEquipmentConfiguration implements Action {
  readonly type: EquipmentConfigurationActionTypes.REQUEST_EQUIPMENT_CONFIGURATION =
    EquipmentConfigurationActionTypes.REQUEST_EQUIPMENT_CONFIGURATION;

  constructor(public payload: any) {}
}
export class ReceiveEquipmentConfiguration implements Action {
  readonly type: EquipmentConfigurationActionTypes.RECEIVE_EQUIPMENT_CONFIGURATION =
    EquipmentConfigurationActionTypes.RECEIVE_EQUIPMENT_CONFIGURATION;

  constructor(public payload: IEquipmentConfiguration) {}
}
export class ErrorReceiveEquipmentConfiguration implements Action {
  readonly type: EquipmentConfigurationActionTypes.ERROR_RECEIVE_EQUIPMENT_CONFIGURATION =
    EquipmentConfigurationActionTypes.ERROR_RECEIVE_EQUIPMENT_CONFIGURATION;

  constructor(public payload: HttpErrorResponse) {}
}
