import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IEntryModel } from 'src/app/common/models/common.model';
import { IEventMarker } from 'src/app/report-center/models/incident.model';
import { IncidentActionTypes } from '../incident-action-types';

export class RequestEventMarkersEntries implements Action {
  readonly type: IncidentActionTypes.REQUEST_EVENT_MARKERS_ENTRIES = IncidentActionTypes.REQUEST_EVENT_MARKERS_ENTRIES;
}
export class ReceiveEventMarkersEntries implements Action {
  readonly type: IncidentActionTypes.RECEIVE_EVENT_MARKERS_ENTRIES = IncidentActionTypes.RECEIVE_EVENT_MARKERS_ENTRIES;

  constructor(public payload: IEntryModel[]) {}
}
export class ErrorReceiveEventMarkersEntries implements Action {
  readonly type: IncidentActionTypes.ERROR_RECEIVE_EVENT_MARKERS_ENTRIES =
    IncidentActionTypes.ERROR_RECEIVE_EVENT_MARKERS_ENTRIES;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestAddEventMarker implements Action {
  readonly type: IncidentActionTypes.REQUEST_ADD_EVENT_MARKER = IncidentActionTypes.REQUEST_ADD_EVENT_MARKER;

  constructor(
    public payload: IEventMarker,
    public incidentId: string,
    public ecpAddress: string,
  ) {}
}
export class ReceiveAddEventMarker implements Action {
  readonly type: IncidentActionTypes.RECEIVE_ADD_EVENT_MARKER = IncidentActionTypes.RECEIVE_ADD_EVENT_MARKER;

  constructor(public payload: IEventMarker) {}
}
export class ErrorAddEventMarker implements Action {
  readonly type: IncidentActionTypes.ERROR_ADD_EVENT_MARKER = IncidentActionTypes.ERROR_ADD_EVENT_MARKER;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestUpdateEventMarker implements Action {
  readonly type: IncidentActionTypes.REQUEST_UPDATE_EVENT_MARKER = IncidentActionTypes.REQUEST_UPDATE_EVENT_MARKER;

  constructor(
    public payload: IEventMarker,
    public incidentId: string,
    public ecpAddress: string,
  ) {}
}
export class ReceiveUpdateEventMarker implements Action {
  readonly type: IncidentActionTypes.RECEIVE_UPDATE_EVENT_MARKER = IncidentActionTypes.RECEIVE_UPDATE_EVENT_MARKER;

  constructor(public payload: IEventMarker) {}
}
export class ErrorUpdateEventMarker implements Action {
  readonly type: IncidentActionTypes.ERROR_UPDATE_EVENT_MARKER = IncidentActionTypes.ERROR_UPDATE_EVENT_MARKER;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestEventMarkerById implements Action {
  readonly type: IncidentActionTypes.REQUEST_EVENT_MARKER_BY_ID = IncidentActionTypes.REQUEST_EVENT_MARKER_BY_ID;

  constructor(
    public payload: string,
    public eventMarkerId: string,
    public ecpAddress: string,
  ) {}
}
export class ReceiveEventMarkerById implements Action {
  readonly type: IncidentActionTypes.RECEIVE_EVENT_MARKER_BY_ID = IncidentActionTypes.RECEIVE_EVENT_MARKER_BY_ID;

  constructor(public payload: IEventMarker) {}
}
export class ErrorReceiveEventMarkerById implements Action {
  readonly type: IncidentActionTypes.ERROR_RECEIVE_EVENT_MARKER_BY_ID =
    IncidentActionTypes.ERROR_RECEIVE_EVENT_MARKER_BY_ID;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestEventMarkerDelete implements Action {
  readonly type: IncidentActionTypes.REQUEST_EVENT_MARKER_DELETE = IncidentActionTypes.REQUEST_EVENT_MARKER_DELETE;

  constructor(
    public incidentId: string,
    public payload: IEventMarker,
    public ecpAddress: string,
    public version: number,
  ) {}
}
export class ReceiveEventMarkerDelete implements Action {
  readonly type: IncidentActionTypes.RECEIVE_EVENT_MARKER_DELETE = IncidentActionTypes.RECEIVE_EVENT_MARKER_DELETE;

  constructor(public payload: IEventMarker) {}
}
export class ErrorReceiveEventMarkerDelete implements Action {
  readonly type: IncidentActionTypes.ERROR_RECEIVE_EVENT_MARKER_DELETE =
    IncidentActionTypes.ERROR_RECEIVE_EVENT_MARKER_DELETE;

  constructor(public payload: HttpErrorResponse) {}
}
