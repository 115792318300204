import { INotificationConstant } from 'src/app/common';

export class LocationHierarchyNotificationConstants {
  public static readonly notificationCodes: INotificationConstant = {
    EXPECTED_VALID_JSON: {
      value: '1002',
      text: 'LOCATION_HIERARCHY_NOTIFICATION.STR_EXPECTING_VALID_JSON',
    },
    LOCATION_DOES_NOT_EXIST: {
      value: '1004',
      text: 'LOCATION_HIERARCHY_NOTIFICATION.STR_LOCATION_WITH_PROVIDER_NOT_EXIST',
    },
    MANDATORY_LOCATION_NAME_FIELD: {
      value: '1005',
      text: 'LOCATION_HIERARCHY_NOTIFICATION.STR_LOCATION_NAME_FIELD_MANDATORY',
    },
    UPDATE_OLDER_VERSION: {
      value: '1006',
      text: 'LOCATION_HIERARCHY_NOTIFICATION.STR_OLD_VERSION_EDIT',
    },
    INVALID_EMAIL: {
      value: '1007',
      text: 'USER_NOTIFICATION.STR_LOCATION_ERROR_CODES_INVALID_EMAIL',
    },
    LOCATION_HAS_RESTRICTED_USERS: {
      value: '1008',
      text: 'LOCATION_HIERARCHY_NOTIFICATION.STR_LOCATION_HAS_RESTRICTED_USERS',
    },
    LOCATION_HAS_EQUIPMENT_ASSIGNED: {
      value: '1009',
      text: 'LOCATION_HIERARCHY_NOTIFICATION.STR_LOCATION_EQUIPMENT_ASSIGNED',
    },
    MOBILE_NO_DIRECT_LOCATION: {
      value: 'MOBILE_NO_DIRECT_LOCATION',
      text: 'LOCATION_HIERARCHY_NOTIFICATION.STR_MOBILE_NO_DIRECT_LOCATION',
    },
    SCAN_LOCATION_NOT_MOBILE: {
      value: 'SCAN_LOCATION_NOT_MOBILE',
      text: 'LOCATION_HIERARCHY_NOTIFICATION.STR_SCAN_LOCATION_NOT_MOBILE',
    },
    ADD_LOCATION_SUCCESS: {
      value: 'ADD_LOCATION_SUCCESS',
      text: 'LOCATION_HIERARCHY_NOTIFICATION.STR_CONFIGURATION_ADD_LOCATION_SUCCESS',
    },
    UPDATE_LOCATION_SUCCESS: {
      value: 'UPDATE_LOCATION_SUCCESS',
      text: 'LOCATION_HIERARCHY_NOTIFICATION.STR_LOCATION_UPDATED_SUCCESS',
    },
    DELETE_LOCATION_SUCCESS: {
      value: 'DELETE_LOCATION_SUCCESS',
      text: 'LOCATION_HIERARCHY_NOTIFICATION.STR_LOCATION_DELETED_SUCCESS',
    },
  };
}
