import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscriber, forkJoin, map, mergeMap } from 'rxjs';
import { IPrintImageSource } from 'src/app/workshop/models';
import { environment } from 'src/environments/environment';
import { CommonConstants } from '../../constants';

@Injectable({
  providedIn: 'root',
})
export class ImageReaderService {
  constructor(private http: HttpClient) {}

  public blobToBase64Observable(blob: Blob): Observable<string> {
    return new Observable<string>((observer: Subscriber<string>) => {
      const reader: FileReader = new FileReader();

      reader.onloadend = () => {
        observer.next(reader.result as string);
        observer.complete();
      };

      reader.onerror = (error: ProgressEvent<FileReader>) => observer.error(error);
      reader.readAsDataURL(blob);
    });
  }

  public httpCall(fileName: string): Observable<string> {
    const headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem(CommonConstants.ACCESS_TOKEN)}`,
    });

    return this.http
      .get(`${environment.API_URLS.DEVICE_CONNECTION}/files/${fileName}`, { headers: headers, responseType: 'blob' })
      .pipe(mergeMap(this.blobToBase64Observable));
  }

  fetchImages(images: IPrintImageSource): Observable<IPrintImageSource> {
    const result: { [id: string]: Observable<string[]> } = {};

    const nonEmptyImages: string[] = Object.keys(images).filter((id: string) => images[id].length > 0);

    for (const id of nonEmptyImages) {
      result[id] = forkJoin(images[id].map((name: string) => this.httpCall(name)));
    }

    return forkJoin(
      nonEmptyImages.map((id: string) => result[id].pipe(map((base64Images: string[]) => ({ [id]: base64Images })))),
    ).pipe(map((results: IPrintImageSource[]) => Object.assign({} as IPrintImageSource, ...results)));
  }
}
