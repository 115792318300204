// eslint-disable-next-line no-shadow
export enum TasksActionTypes {
  REQUEST_ADD_TASK = 'REQUEST_ADD_TASK',
  RECEIVE_ADD_TASK = 'RECEIVE_ADD_TASK',
  ERROR_RECEIVE_ADD_TASK = 'ERROR_RECEIVE_ADD_TASK',

  REQUEST_EDIT_TASK = 'REQUEST_EDIT_TASK',
  RECEIVE_EDIT_TASK = 'RECEIVE_EDIT_TASK',
  ERROR_RECEIVE_EDIT_TASK = 'ERROR_RECEIVE_EDIT_TASK',

  REQUEST_UPDATE_TASK_CHILDREN = 'REQUEST_UPDATE_TASK_CHILDREN',
  RECEIVE_UPDATE_TASK_CHILDREN = 'RECEIVE_UPDATE_TASK_CHILDREN',
  ERROR_RECEIVE_UPDATE_TASK_CHILDREN = 'ERROR_RECEIVE_UPDATE_TASK_CHILDREN',

  REQUEST_DELETE_TASK = 'REQUEST_DELETE_TASK',
  RECEIVE_DELETE_TASK = 'RECEIVE_DELETE_TASK',
  ERROR_RECEIVE_DELETE_TASK = 'ERROR_RECEIVE_DELETE_TASK',
}
