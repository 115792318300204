<form [formGroup]="prefilterForm">
  <ng-template odxModal #prefilterModal let-modal="close">
    <odx-modal-header data-test-id="logbook_table.prefilter_modal">
      <odx-area-header>
        {{ 'LOGBOOK_PREFILTER.TITLE' | translate }}
      </odx-area-header>
    </odx-modal-header>

    <odx-modal-content [ngStyle]="{ overflow: 'auto' }">
      <section odxLayout="grid">
        <p odxLayout="12" class="mb-3">
          {{ 'LOGBOOK_PREFILTER.SUBTITLE_1' | translate }}
          <br />{{ 'LOGBOOK_PREFILTER.SUBTITLE_2' | translate }}
        </p>

        <div odxLayout="12 12@desktop">
          <odx-form-field
            variant="full"
            label="{{ 'LOGBOOK_TABLE.STR_SCBA_ID' | translate }}"
            [ngClass]="{ 'has-error': !isBASerialInputValueValid }"
          >
            <input
              type="text"
              ignisNoAllowHtmlTags
              (emitIsInputValueValid)="isBASerialInputValueValid = $event"
              odxFormFieldControl
              ignisDisableAutocomplete
              id="equipmentId"
              data-test-id="logbook_table.scba_id"
              placeholder="f.e. ABCD-1234"
              formControlName="equipmentId"
            />

            <odx-icon
              *ngIf="!isBASerialInputValueValid"
              name="info"
              class="custom-validation-icon"
              iconSet="core"
              [odxTooltip]="'TABLE.STR_NO_VALID_VALUE' | translate"
              [odxTooltipVisible]="!isBASerialInputValueValid"
            >
            </odx-icon>
          </odx-form-field>

          <odx-form-field
            variant="full"
            label="{{ 'LOGBOOK_TABLE.STR_USERNAME' | translate }}"
            [ngClass]="{ 'has-error': !isUserNameInputValueValid }"
          >
            <input
              type="text"
              odxFormFieldControl
              ignisNoAllowHtmlTags
              (emitIsInputValueValid)="isUserNameInputValueValid = $event"
              ignisDisableAutocomplete
              id="name"
              data-test-id="logbook_table.username"
              formControlName="name"
              placeholder="f.e. Jackson, Pete"
            />

            <odx-icon
              *ngIf="!isUserNameInputValueValid"
              name="info"
              class="custom-validation-icon"
              iconSet="core"
              [odxTooltip]="'TABLE.STR_NO_VALID_VALUE' | translate"
              [odxTooltipVisible]="!isUserNameInputValueValid"
            >
            </odx-icon>
          </odx-form-field>

          <odx-form-field
            variant="full"
            label="{{ 'LOGBOOK_PREFILTER.DATE' | translate }}"
            class="custom-datepicker-form-field"
          >
            <div class="w-100">
              <ignis-datepicker
                formControlName="date"
                ngDefaultControl
                [defaultRange]="selectedDateRange"
                [selectionMode]="'range'"
                [appendTo]="datePickerWrapp"
                [formatDate]="formatDate"
                [yearNavigator]="false"
                [numberOfMonths]="2"
                (dateSelect)="onDateSelect($event)"
                (dateChange)="onDateChange($event)"
                data-test-id="logbook_table.prefilter_date"
              >
              </ignis-datepicker>
            </div>
          </odx-form-field>
          <div class="datePickerWrapp" #datePickerWrapp></div>
        </div>
      </section>
    </odx-modal-content>
    <odx-modal-footer>
      <button
        odxButton
        *ngIf="isPreFilterSet"
        (click)="resetFilterParams()"
        variant="secondary"
        id="preFilterClear"
        data-test-id="logbook_prefilter.clear_filters"
      >
        {{ 'REMOTE_MONITORING.FILTER_CLEAR_FILTERS_BUTTON' | translate }}
      </button>

      <button
        *ngIf="displayBackBtn; else displayCancelBtn"
        id="preFilterBack"
        odxButton
        odxModalClose
        variant="secondary"
        (click)="goBack()"
        data-test-id="logbook_prefilter.back_btn"
      >
        {{ 'LOGBOOK_PREFILTER.BACK_BTN' | translate }}
      </button>

      <ng-template #displayCancelBtn>
        <button
          odxButton
          odxModalClose
          variant="secondary"
          (click)="cancelPrefilter()"
          id="preFilterCancel"
          data-test-id="logbook_prefilter.cancel_btn"
        >
          {{ 'LOGBOOK_PREFILTER.CANCEL_BTN' | translate }}
        </button>
      </ng-template>

      <button
        odxButton
        [disabled]="!isBASerialInputValueValid || !isUserNameInputValueValid"
        variant="primary"
        (click)="proceedPrefilter()"
        id="preFilterProceed"
        data-test-id="logbook_prefilter.proceed_btn"
      >
        {{ 'LOGBOOK_PREFILTER.PROCEED_BTN' | translate }}
      </button>
    </odx-modal-footer>
  </ng-template>
</form>
