export class SettingsConstants {
  public static readonly LOCALIZATION_PROFILE_STORAGE_KEY: string = 'IGNIS_LOCALIZATION_PROFILE';

  public static readonly languagesMap: string[][] = [
    ['English (British)', 'en-GB', 'SETTINGS.STR_ENGLISH_LANGUAGE'],
    ['German', 'de-DE', 'SETTINGS.STR_GERMAN_LANGUAGE'],
    ['Danish', 'da-DK', 'SETTINGS.STR_DANISH_LANGUAGE'],
    ['Spanish', 'es-ES', 'SETTINGS.STR_SPANISH_LANGUAGE'],
    ['French', 'fr-FR', 'SETTINGS.STR_FRENCH_LANGUAGE'],
    ['Dutch', 'nl-NL', 'SETTINGS.STR_NEDERLAND_LANGUAGE'],
    ['Norwegian', 'nb-NO', 'SETTINGS.STR_NORSK_LANGUAGE'],
  ];
}
