import { ElementRef } from '@angular/core';
import * as d3 from 'd3';
import { convertMinutesToMilliseconds, dateFormatWithSeconds } from 'src/app/common/utils/date-utils/date.utils';

export type ChartOptions = {
  width: number;
  height: number;
  minWidth: number;
  minHeight: number;
  margin: {
    top: number;
    left: number;
    right: number;
    bottom: number;
  };
};
export type SizeFactor = { xFactor: number; yFactor: number };

/* istanbul ignore next */
export class ChartHelper {
  options: ChartOptions;

  constructor(options: ChartOptions) {
    this.options = options;
  }

  public appendSvg(element: ElementRef): any {
    return d3
      .select(element.nativeElement)
      .append('svg')
      .classed('chart', true)
      .attr(
        'viewBox',
        `${this.options.minWidth} ${this.options.minHeight} ${this.options.width} ${this.options.height}`
      )
      .style('-webkit-tap-highlight-color', 'transparent')
      .style('overflow', 'visible');
  }

  public createXTimeScale(dateRange: Date[]): any {
    return d3
      .scaleTime()
      .domain(dateRange)
      .range([this.options.margin.left, this.options.width - this.options.margin.right]);
  }

  public calculateSizeFactor(element: any): SizeFactor {
    const elementClientRect: any = element.node().getBoundingClientRect();
    const xFactor: any = elementClientRect.width / this.options.width;
    const yFactor: any = elementClientRect.height / this.options.height;

    return { xFactor, yFactor };
  }

  public bisector<T>(mouseLocation: number[], data: T[], xScale: any): T {
    const bisector: any = d3.bisector((d: any) => d.timestamp).left;
    const date: any = xScale.invert(mouseLocation[0]);
    const index: any = bisector(data, date, 1);
    const a: any = data[index - 1];
    const b: any = data[index];

    if (b) {
      return a && date.getTime() <= a.end?.getTime() && date.getTime() >= a.start?.getTime() ? a : b;
    } else { return a; }

  }

  public showTooltip(tooltip: any, tooltipContent: string): number[] {
    tooltip.selectAll('*').remove();
    tooltip.append('span').html(tooltipContent);
    tooltip.style('display', 'block');
    const { width, height }: any = tooltip.node().getBoundingClientRect();

    return [width, height];
  }

  /* istanbul ignore next */
  public calculateTickLength(startDate: Date, endDate: Date): number {
    const ticksNumber: number = 4;
    const startTime: number = startDate.getTime();
    const endTime: number = endDate.getTime();
    const eventLength: number = endTime - startTime;

    if (eventLength >= convertMinutesToMilliseconds(60)) {
      return convertMinutesToMilliseconds(15);
    }

    return eventLength / ticksNumber;
  }

  /* istanbul ignore next */
  public calculateTickValues(startDate: Date, endDate: Date): Date[] {
    const tickValues: Date[] = [startDate];
    const tickLength: number = this.calculateTickLength(startDate, endDate);
    const eventPeriod: number = endDate.getTime() - startDate.getTime();
    const ticksToCalculate: any = eventPeriod / tickLength;
    const eventPeriodInHours: number = Math.floor(eventPeriod / (1000 * 60 * 60));
    const fiveMinutes: number = 5 * 60 * 1000;
    let currentTickValue: any = startDate.getTime();

    for (let i: number = 0; i < ticksToCalculate; i++) {
      currentTickValue = currentTickValue + tickLength;

      tickValues.push(new Date(currentTickValue));
    }

    if (tickValues[tickValues.length - 1].getTime() !== endDate.getTime()) {
      tickValues.push(endDate);
    }

    if (eventPeriodInHours >= 1) {
      tickValues.splice(tickValues.indexOf(tickValues[tickValues.length - 2]), 1);

      if ((tickValues[tickValues.length - 1]?.getTime() - tickValues[tickValues.length - 2]?.getTime()) < fiveMinutes) {
        tickValues.splice(tickValues.indexOf(tickValues[tickValues.length - 2]), 1);
      }
    }

    return tickValues;
  }

  /* istanbul ignore next */
  public manageChartShadows(list: any, leftShadow: HTMLElement, rightShadow: HTMLElement): void {
    if (rightShadow && leftShadow) {
      rightShadow.style.right = '0px';

      list.addEventListener('scroll', (e: any) => {
        if (e.target.scrollLeft > 0) {
          leftShadow.style.visibility = 'visible';
        } else {
          leftShadow.style.visibility = 'hidden';
        }

        if (e.target.scrollLeft >= 0 && Math.round(e.target.scrollLeft) !== e.target.scrollWidth - list.offsetWidth) {
          rightShadow.style.visibility = 'visible';
        } else {
          rightShadow.style.visibility = 'hidden';
        }
      });
    }
  }

  /* istanbul ignore next */
  public initializeFullHourTooltip(formatDate: string, tooltip: any, tooltipText: string): void {
    d3.selectAll('svg>line')
      .on('touchmove mousemove', (event: any) => {
        const attributes: HTMLElement = event.target as HTMLElement;
        const date: string = dateFormatWithSeconds(attributes.getAttribute('date'), formatDate);
        const index: number = parseInt(attributes.getAttribute('index'), 10) + 1;
        const tooltipData: any = { date, index };

        this.showFullHourTooltip(event, tooltipData, tooltip, tooltipText);
      })
      .on('touchend mouseleave', () => tooltip?.style('display', 'none'));
  }

  /* istanbul ignore next */
  showFullHourTooltip(event: any, data: any, tooltip: any, tooltipText: string): void {
    const [tooltipWidth, tooltipHeight]: any = this.showTooltip(
      tooltip,
      `
        <b>${data.index + ' ' + tooltipText}</b><br>
       ${tooltipText}: ${data.date}
      `
    );

    tooltip
      ?.style('top', `${event.pageY - tooltipHeight}px`)
      ?.style('left', `${event.pageX - tooltipWidth / 2}px`);
  }

  public compareOverlappingAlarmDate(firstStartTime: Date, firstEndTime: Date, secondStartTime: Date, secondEndTime: Date): boolean {
    const msToH: number = 1000 * 60 * 60;
    const overlappingHours: number = Math.min(
      (firstEndTime.getTime() - firstStartTime.getTime()) / msToH,
      (firstEndTime.getTime() - secondStartTime.getTime()) / msToH,
      (secondEndTime.getTime() - firstStartTime.getTime()) / msToH,
      (secondEndTime.getTime() - secondStartTime.getTime()) / msToH
    );

    return overlappingHours > 0;
  }

  /* istanbul ignore next */
  public increaseTooltipContentMarginLeft(tooltipWidth: number, minTooltipWidth: number, timelineTooltipContainerClassName: string, addedClass: string): void {
    if (Math.round(tooltipWidth) <= minTooltipWidth) {
      const tooltips: any = document.querySelectorAll(`.${timelineTooltipContainerClassName}`);

      for (const box of tooltips) {
        box.classList.add(addedClass);
      }

    }
  }

}
