import { GenericAction } from 'src/app/common';
import { IIncidentPage } from './../../../models/incident.model';
import { IEntriesModel, IIncident } from '../../../models/incident.model';
import { IncidentActionTypes } from '../../actions/incident-action-types';
import { IIncidentDataState, IncidentDataState } from '../../models/incident-data.model';
import {
  errorItemData,
  errorListData,
  errorPostPutItemData,
  receiveItemData,
  receiveListData,
  receivePostPutItemData,
  requestItemData,
  requestListData,
  requestPostPutItemData,
} from '../../../../common/utils/reducer/reducer.utils';

export function incidentDataReducer(
  lastState: IIncidentDataState = new IncidentDataState(),
  action: GenericAction<IncidentActionTypes, any>
): IIncidentDataState {
  switch (action.type) {

    case IncidentActionTypes.REQUEST_INCIDENTS:
      return requestListData<IIncidentDataState, IIncidentPage>(lastState, ['incidentPage']);
    case IncidentActionTypes.RECEIVE_INCIDENTS:
      return receiveListData<IIncidentDataState, IIncidentPage>(lastState, ['incidentPage'], action.payload);
    case IncidentActionTypes.ERROR_RECEIVE_INCIDENTS:
      return errorListData<IIncidentDataState, IIncidentPage>(lastState, ['incidentPage'], action.payload);

    case IncidentActionTypes.REQUEST_INCIDENT_BY_ID:
      return requestItemData<IIncidentDataState, IIncident>(lastState, ['selectedIncident']);
    case IncidentActionTypes.RECEIVE_INCIDENT_BY_ID:
      return receiveItemData<IIncidentDataState, IIncident>(lastState, ['selectedIncident'], action.payload);
    case IncidentActionTypes.ERROR_RECEIVE_INCIDENT_BY_ID:
      return errorItemData<IIncidentDataState, IIncident>(lastState, ['selectedIncident'], action.payload);

    case IncidentActionTypes.REQUEST_INCIDENT_ENTRIES:
      return requestItemData<IIncidentDataState, IEntriesModel>(lastState, ['entries']);
    case IncidentActionTypes.RECEIVE_INCIDENT_ENTRIES:
      return receiveItemData<IIncidentDataState, IEntriesModel>(lastState, ['entries'], action.payload);
    case IncidentActionTypes.ERROR_RECEIVE_INCIDENT_ENTRIES:
      return errorItemData<IIncidentDataState, IEntriesModel>(lastState, ['entries'], action.payload);

    case IncidentActionTypes.REQUEST_UPDATE_INCIDENT:
      return requestPostPutItemData<IIncidentDataState, IIncident>(lastState, ['selectedIncident'], action.payload);
    case IncidentActionTypes.RECEIVE_UPDATE_INCIDENT:
      return receivePostPutItemData<IIncidentDataState, IIncident>(lastState, ['selectedIncident'], action.payload);
    case IncidentActionTypes.ERROR_UPDATE_INCIDENT:
      return errorPostPutItemData<IIncidentDataState, IIncident>(lastState, ['selectedIncident'], action.payload);

    default:
      return lastState;
  }
}
