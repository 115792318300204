import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RequestEntries, RequestLogbookExcelData, RequestLogbookPage } from './logbook-action-types-creators';
import { ILogbookState } from '../models/logbook.model';

@Injectable({ providedIn: 'root' })
export class LogbookActions {
  constructor(private store: Store<ILogbookState>) { }

  public requestLogbookPage(params?: any): void {
    this.store.dispatch(new RequestLogbookPage(params));
  }

  public requestEntries(): void {
    this.store.dispatch(new RequestEntries());
  }

  public requestLogbookExcelData(params?: any): void {
    this.store.dispatch(new RequestLogbookExcelData(params));
  }
}
