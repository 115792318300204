<div class="event-card" id="{{ event.isLastEvent ? 'lastEvent' : null }}">
  <div class="timeline-details">
    <p class="event-date">{{ event.occurredAt | date: formatDate }} {{ time }}</p>
    <p class="event-type my-2">
      <odx-icon [name]="eventType.icon"></odx-icon>
      <span class="event-type--name">
        {{ eventType.name }}
      </span>
    </p>
    <p class="event-description" [title]="eventType.label">
      {{ eventType.label }}
    </p>
    <p class="event-username" [title]="eventType.userNameOrId">
      {{ eventType.userNameOrId }}
    </p>
  </div>
</div>
