<ignis-progress-bar *ngIf="isLoading | async"></ignis-progress-bar>

<div class="custom-odx-tab-bar odx-margin-bottom-24">
  <div class="odx-tab-bar__inner">
    <div class="odx-tab-bar__panel padding-left-0">
      <div class="back-btn-group">
        <button
          odxNavigationBack
          id="navigation-back"
          data-test-id="configuration_address_book.back"
          (click)="addressBookForm.dirty ? confirmCloseModalOpen() : navigateBack(true)"
        ></button>
        <span class="odx-fs-normal">
          {{ 'CONFIGURATION_ADDRESS_BOOK.STR_BACK' | translate }}
        </span>
      </div>
    </div>
  </div>
</div>

<ngx-simplebar [options]="scrollbarOptions" class="custom-address-book-scrollbar scrollbar-offset">
  <form [formGroup]="addressBookForm" odxForm *ngIf="!isLoadingForm">
    <section odxLayout="grid">
      <h2 class="mb-3" odxLayout="12">{{ 'CONFIGURATION_ADDRESS_BOOK.STR_ADDRESS_BOOK_ENTRY' | translate }}</h2>

      <div odxLayout="12 6@tablet 4@desktop" class="custom-form-field-pr-10-phone">
        <odx-form-field
          variant="horizontal"
          label="{{ 'CONFIGURATION_ADDRESS_BOOK.STR_ORGANIZATION' | translate }}"
          [readonly]="addressBookForm.get('organizationName').disabled"
        >
          <input
            type="text"
            ignisDisableAutocomplete
            odxFormFieldControl
            id="organizationName"
            data-test-id="configuration_address_book.organization"
            formControlName="organizationName"
            placeholder="{{ 'CONFIGURATION_ADDRESS_BOOK.STR_ORGANIZATION_PLACEHOLDER' | translate }}"
            (keyup)="checkFieldValidity()"
          />
          <odx-icon
            *ngIf="httpCustomErrorCode && addressBookForm.controls.organizationName.invalid"
            name="info"
            class="custom-validation-icon"
            iconSet="core"
            data-test-id="configuration_address_book.organization_name_wrap"
            [pTooltip]="organizationNameTooltipContent"
            tooltipPosition="top"
            positionTop="-10"
          >
            <ng-template #organizationNameTooltipContent>
              <div
                id="organizationName-error-tooltip"
                data-test-id="configuration_address_book.combination_of_fields_tooltip"
              >
                {{ 'CONFIGURATION_ADDRESS_BOOK.STR_COMBINATION_OF_FIELDS_TOOLTIP' | translate }}
              </div>
            </ng-template>
          </odx-icon>
        </odx-form-field>
      </div>

      <div odxLayout="12 6@tablet 4@desktop" class="custom-form-field-pl-10-phone">
        <odx-form-field
          variant="horizontal"
          label=" {{ 'CONFIGURATION_ADDRESS_BOOK.STR_CONTACT_NAME' | translate }}"
          [readonly]="addressBookForm.get('contactName').disabled"
        >
          <input
            type="text"
            ignisDisableAutocomplete
            id="contactName"
            data-test-id="configuration_address_book.contact_name"
            formControlName="contactName"
            placeholder="{{ 'CONFIGURATION_ADDRESS_BOOK.STR_CONTACT_NAME_PLACEHOLDER' | translate }}"
            odxFormFieldControl
            (keyup)="checkFieldValidity()"
          />
          <odx-icon
            *ngIf="httpCustomErrorCode && addressBookForm.controls.contactName.invalid"
            name="info"
            class="custom-validation-icon"
            iconSet="core"
            [pTooltip]="contactNameTooltipContent"
            tooltipPosition="top"
            positionTop="-10"
          >
            <ng-template #contactNameTooltipContent>
              <div
                id="contactName-error-tooltip"
                data-test-id="configuration_address_book.combination_of_fields_tooltip"
              >
                {{ 'CONFIGURATION_ADDRESS_BOOK.STR_COMBINATION_OF_FIELDS_TOOLTIP' | translate }}
              </div>
            </ng-template>
          </odx-icon>
        </odx-form-field>
      </div>

      <div odxLayout="12 6@tablet 4@desktop offset-1" class="custom-form-field-pr-10-phone">
        <odx-form-field
          variant="horizontal"
          label="{{ 'CONFIGURATION_ADDRESS_BOOK.STR_TYPES' | translate }}"
          [readonly]="addressBookForm.get('types').disabled"
        >
          <p-multiSelect
            [appendTo]="'body'"
            ngDefaultControl
            formControlName="types"
            [dropdownIcon]="dropdownIconCSSClass"
            optionLabel="name"
            optionValue="value"
            [filter]="false"
            [showHeader]="false"
            [showClear]="true"
            [appendTo]="'body'"
            [options]="typesList"
            [placeholder]="'CONFIGURATION_ADDRESS_BOOK.STR_TYPES_PLACEHOLDER' | translate"
            class="address-book-multiselect"
            data-test-id="configuration_address_book.types"
          >
            <ng-template let-option pTemplate="item">
              <div class="p-multiselect-representative-option">
                <span class="p-ms-1">{{ option?.localizedName | translate }}</span>
              </div>
            </ng-template>
            <ng-template let-value pTemplate="selectedItems">
              <div *ngIf="value !== null && value?.length === 1">
                <div *ngFor="let val of value" class="ui-multiselected-item-token ui-corner-all">
                  <span [ngSwitch]="val?.value">
                    <span *ngSwitchCase="'MANUFACTURER'">
                      {{ 'CONFIGURATION_ADDRESS_BOOK.STR_MANUFACTURER' | translate }}
                    </span>
                    <span *ngSwitchCase="'SUPPLIER'">
                      {{ 'CONFIGURATION_ADDRESS_BOOK.STR_SUPPLIER' | translate }}
                    </span>
                  </span>
                </div>
                <div class="ui-multiselected-item-token ui-corner-all" [ngSwitch]="value[0]"></div>
              </div>
              <span *ngIf="value !== null && value?.length >= 2" class="ui-multiselected-empty-token ui-corner-all"
                >{{ value.length }} {{ 'CONFIGURATION_ADDRESS_BOOK.SELECTED_ITEMS' | translate }}</span
              >
              <span *ngIf="!value || value?.length === 0" class="ui-multiselected-empty-token ui-corner-all">
                {{ 'CONFIGURATION_ADDRESS_BOOK.FIELD_ADDRESS_TYPE' | translate }}
              </span>
            </ng-template>
            <ng-template pTemplate="clearicon">
              <odx-icon name="close"></odx-icon>
            </ng-template>
          </p-multiSelect>
        </odx-form-field>
      </div>

      <div odxLayout="12 6@tablet 4@desktop" class="custom-form-field-pl-10-phone">
        <odx-form-field
          variant="horizontal"
          label="{{ 'CONFIGURATION_ADDRESS_BOOK.STR_EMAIL' | translate }}"
          [readonly]="addressBookForm.get('email').disabled"
        >
          <input
            type="email"
            id="email"
            data-test-id="configuration_address_book.emai"
            formControlName="email"
            placeholder="{{ 'CONFIGURATION_ADDRESS_BOOK.STR_EMAIL_PLACEHOLDER' | translate }}"
            odxFormFieldControl
            (keyup)="checkFieldValidity()"
          />
          <odx-icon
            name="info"
            class="custom-validation-icon"
            iconSet="core"
            *ngIf="addressBookForm.controls.email.value && addressBookForm.controls.email.invalid"
            [pTooltip]="emailTooltipContent"
            tooltipPosition="top"
            positionTop="-10"
          >
            <ng-template #emailTooltipContent>
              <div data-test-id="configuration_address_book.emaiL_INPUT_TOOLTIP">
                {{ 'CONFIGURATION_ADDRESS_BOOK.STR_EMAIL_INPUT_TOOLTIP' | translate }}
              </div>
            </ng-template>
          </odx-icon>

          <odx-icon
            *ngIf="
              httpCustomErrorCode && addressBookForm.controls.email.invalid && addressBookForm.controls.email.value
            "
            name="info"
            class="custom-validation-icon"
            iconSet="core"
            data-test-id="configuration_address_book.combination_of_fields_wrap"
            [pTooltip]="emailCombinationTooltipContent"
            tooltipPosition="top"
            positionTop="-10"
          >
            <ng-template #emailCombinationTooltipContent>
              <div id="email-error-tooltip" data-test-id="configuration_address_book.combination_of_fields_tooltip">
                {{ 'CONFIGURATION_ADDRESS_BOOK.STR_COMBINATION_OF_FIELDS_TOOLTIP' | translate }}
              </div>
            </ng-template>
          </odx-icon>

          <odx-icon
            name="check"
            class="custom-validation-icon"
            iconSet="core"
            *ngIf="addressBookForm.controls.email.valid && addressBookForm.controls.email.value"
          ></odx-icon>
        </odx-form-field>
      </div>

      <div odxLayout="12 6@tablet 4@desktop offset-1" class="custom-form-field-pr-10-phone">
        <odx-form-field
          variant="horizontal"
          label="{{ 'CONFIGURATION_ADDRESS_BOOK.STR_ADDRESS' | translate }}"
          [readonly]="addressBookForm.get('address').disabled"
        >
          <input
            type="text"
            ignisDisableAutocomplete
            id="address"
            data-test-id="configuration_address_book.address"
            formControlName="address"
            placeholder="{{ 'CONFIGURATION_ADDRESS_BOOK.STR_ADDRESS_PLACEHOLDER' | translate }}"
            odxFormFieldControl
          />
        </odx-form-field>
      </div>

      <div odxLayout="12 6@tablet 4@desktop" class="custom-form-field-pl-10-phone">
        <odx-form-field
          variant="horizontal"
          label="{{ 'CONFIGURATION_ADDRESS_BOOK.STR_PHONE' | translate }}"
          [readonly]="addressBookForm.get('phone').disabled"
        >
          <input
            type="text"
            ignisDisableAutocomplete
            id="phone"
            data-test-id="configuration_address_book.phone"
            formControlName="phone"
            placeholder="{{ 'CONFIGURATION_ADDRESS_BOOK.STR_PHONE_PLACEHOLDER' | translate }}"
            odxFormFieldControl
          />
        </odx-form-field>
      </div>

      <div odxLayout="12 12@tablet 4@desktop offset-1" class="custom-form-field-pr-10-phone">
        <odx-form-field
          variant="horizontal"
          label=" {{ 'CONFIGURATION_ADDRESS_BOOK.STR_COMMENT' | translate }}"
          [readonly]="addressBookForm.get('comment').disabled"
        >
          <textarea
            ignisDisableAutocomplete
            class="custom-form-textarea"
            odxFormFieldControl
            id="comment"
            data-test-id="configuration_address_book.comment"
            rows="3"
            formControlName="comment"
            placeholder="{{ 'CONFIGURATION_ADDRESS_BOOK.STR_COMMENT_PLACEHOLDER' | translate }}"
          ></textarea>
        </odx-form-field>
      </div>
    </section>
  </form>
</ngx-simplebar>

<footer class="blue-bar">
  <ignis-create-update-address-book-view-footer
    [isModalOpened]="isConfirmCloseModalOpen"
    [form]="addressBookForm"
    [isSubmitDisabled]="isSubmitting | async"
    (handleSubmit)="onSubmit()"
  >
  </ignis-create-update-address-book-view-footer>
</footer>
<ignis-confirmation-modal
  [isOpen]="isConfirmCloseModalOpen"
  (handleConfirmationModal)="navigateBack($event)"
  class="is-closed-modal"
>
  <div slot="content">
    {{ 'CONFIRM_CLOSE_MODAL.STR_DESCRIPTION_ADDRESS_BOOK' | translate }}
  </div>
</ignis-confirmation-modal>
