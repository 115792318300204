import { Location } from '@angular/common';
import { AfterViewInit, Component, inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { StorageMap } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import uniq from 'lodash-es/uniq';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { delay, filter, map, take, takeUntil } from 'rxjs/operators';
import { NotificationConstants } from 'src/app/common/constants';
import { IModalState, INotificationConstant } from 'src/app/common/models';
import { NotificationsService } from 'src/app/common/services';
import { INotificationMessage } from 'src/app/common/state/notifications/models/notification.model';
import { AppUserPermissionList } from 'src/app/root/models';
import { AccessControlService } from 'src/app/root/services';
import { UserActions } from 'src/app/user-management';
import { IUserPersonalInfo } from 'src/app/user-management/models/user.model';
import { selectUsersPersonalInfo } from 'src/app/user-management/state/selectors/user.selector';
import { CommonConstants, IApplicationState, IStoreApiList } from '../../../common';
import { OnDestroyMixin } from '../../../common/mixins';
import { IStoreApiItem } from '../../../common/models/store-api-item.model';
import { getDateFormatFromUserProfile } from '../../../common/utils';
import { EquipmentNotificationConstants, WorkshopModuleRoutes } from '../../constants';
import { IEquipment, IEquipmentHistoryEvent, IEquipmentHistoryFilters } from '../../models';
import { EquipmentCRUDActions } from '../../state/actions/equipment-crud/equipment-crud.actions';
import { EquipmentHistoryActions } from '../../state/actions/equipment-history/equipment-history.actions';
import { selectEquipmentHistory, selectEquipmentItem, selectHistoryFilters } from '../../state/selectors';

@Component({
  selector: 'ignis-equipment-history',
  templateUrl: './equipment-history.component.html',
  styleUrls: ['./equipment-history.component.scss'],
})
export class EquipmentHistoryComponent extends OnDestroyMixin() implements OnInit, AfterViewInit {
  appUserPermissionList: Partial<typeof AppUserPermissionList> = AppUserPermissionList;

  history: IEquipmentHistoryEvent[] = [];
  shownDates: number[] = [];
  selectedEquipment: IEquipment = null;
  aggregateId: string = null;
  isComingFromEqPage: boolean = false;
  isLoading: Observable<boolean>;
  displayTimeline: boolean = false;
  openFilterModal: boolean = false;
  spaceSubscription: Subscription;
  filterParams: Observable<IEquipmentHistoryFilters>;
  httpCustomErrorCode: number;
  formatDate: string;
  @ViewChildren('spacerElem')
  public spacerElem: QueryList<unknown>;

  location: Location = inject(Location);
  userActions: UserActions = inject(UserActions);
  equipmentCRUDActions: EquipmentCRUDActions = inject(EquipmentCRUDActions);
  equipmentHistoryActions: EquipmentHistoryActions = inject(EquipmentHistoryActions);
  translateService: TranslateService = inject(TranslateService);
  notificationsService: NotificationsService = inject(NotificationsService);
  accessControlService: AccessControlService = inject(AccessControlService);

  constructor(
    private store: Store<IApplicationState>,
    private route: ActivatedRoute,
    private router: Router,
    private storage: StorageMap,
  ) {
    super();

    window.addEventListener('resize', this.calculateTimelineHeight, false);
  }

  ngOnInit(): void {
    this.getFilterParams();

    this.isLoading = combineLatest([
      this.store.select(selectEquipmentHistory),
      this.store.select(selectEquipmentItem),
    ]).pipe(
      map(
        ([history, equipment]: [IStoreApiList<IEquipmentHistoryEvent[]>, IStoreApiItem<IEquipment>]) =>
          history.isLoading || equipment.isLoading,
      ),
      takeUntil(this.destroy),
    );

    this.formatDate = getDateFormatFromUserProfile(this.translateService);

    this.route.params.pipe(take(1)).subscribe((params: { id: string }) => {
      if (params.id) {
        this.aggregateId = params.id;

        this.getEquipmentData();
        this.getHistoryData();
      }
    });

    this.checkFromWhereUserCome();
  }

  /* istanbul ignore next */
  ngAfterViewInit(): void {
    this.spaceSubscription = this.spacerElem.changes.pipe(delay(1)).subscribe((el: any) => {
      if (el._results?.length > 0) {
        this.scrollIntoView('spacer');
      }
    });

    this.calculateTimelineHeight();

    this.storage
      .watch(CommonConstants.modalIsOpened)
      .pipe(
        filter((response: IModalState) => response !== null),
        take(1),
      )
      .subscribe((response: IModalState) => {
        if (response && response.modalName === CommonConstants.modalsName.EQUIPMENT_HISTORY_FILTER) {
          this.openFilterModal = response.open;
        }
      });
  }

  checkFromWhereUserCome(): void {
    if (
      'previousRoute' in localStorage &&
      localStorage.getItem('previousRoute').includes(WorkshopModuleRoutes.updateEquipment)
    ) {
      this.isComingFromEqPage = true;
    }
  }

  /* istanbul ignore next */
  calculateTimelineHeight(): void {
    const footerHeight: number = 50;
    const headerHeight: number = 140;
    const headerFooter: number = footerHeight - headerHeight;

    const maxHeight: number = 505;
    const calculatedHeight: number =
      window.innerHeight - headerFooter - 4 * parseFloat(getComputedStyle(document.documentElement).fontSize);
    const element: HTMLElement = document.getElementById('timeline-content');

    if (element) {
      element.style.height = calculatedHeight > maxHeight ? `${maxHeight}px` : `${calculatedHeight}px`;
    }
  }

  getHistoryData(): void {
    this.equipmentHistoryActions.requestEquipmentHistory(this.aggregateId);

    this.store
      .pipe(select(selectEquipmentHistory), takeUntil(this.destroy))
      .subscribe((history: IStoreApiList<IEquipmentHistoryEvent[]>) => {
        if (history.data) {
          this.shownDates = [];

          const historyData: IEquipmentHistoryEvent[] = history.data.map((event: IEquipmentHistoryEvent) => {
            const extractedYear: number = new Date(event.occurredAt).getUTCFullYear();

            let yearToDisplay: number = null;

            if (!this.shownDates.includes(extractedYear)) {
              yearToDisplay = extractedYear;
              this.shownDates.push(extractedYear);
            }

            return { ...event, displayYear: yearToDisplay };
          });

          this.getUsersPersonalInfo(historyData);
        } else if (history.errors) {
          this.handleBackendErrors(history.errors);
        }

        this.spacerElem?.notifyOnChanges();
      });
  }

  getEquipmentData(): void {
    this.equipmentCRUDActions.requestEquipmentById(this.aggregateId);

    this.store
      .pipe(select(selectEquipmentItem), takeUntil(this.destroy))
      .subscribe((equipment: IStoreApiItem<IEquipment>) => {
        if (equipment.data) {
          this.selectedEquipment = equipment.data;
        } else if (equipment.errors) {
          this.handleBackendErrors(equipment.errors);
        }
      });
  }

  getUsersPersonalInfo(historyData: IEquipmentHistoryEvent[]): void {
    const ids: string[] = historyData.map((event: IEquipmentHistoryEvent) => event.principalId);

    if (this.accessControlService.checkPermission(this.appUserPermissionList.userManagement)) {
      if (ids.length > 0) {
        this.userActions.requestUsersPersonalInfoByIds(uniq(ids));
      }

      this.store
        .pipe(select(selectUsersPersonalInfo), takeUntil(this.destroy))
        .subscribe((usersPersonalInfo: IStoreApiList<IUserPersonalInfo[]>) => {
          this.processPersonalInfoDataAndHistoryData(usersPersonalInfo.data, historyData);
        });
    } else {
      this.history = historyData.map((event: IEquipmentHistoryEvent) => {
        return { ...event, userNameOrId: '***' };
      });

      this.displayTimeline = true;
    }
  }

  processPersonalInfoDataAndHistoryData(
    usersPersonalInfo: IUserPersonalInfo[],
    historyData: IEquipmentHistoryEvent[],
  ): void {
    this.displayTimeline = false;

    if (usersPersonalInfo) {
      historyData.forEach((event: IEquipmentHistoryEvent) => {
        usersPersonalInfo.forEach((personalInfo: IUserPersonalInfo) => {
          if (event.principalId === personalInfo.userId) {
            event.userNameOrId =
              personalInfo.profileInfo && Object.values(personalInfo.profileInfo).length > 0
                ? `${personalInfo.profileInfo.lastName}, ${personalInfo.profileInfo.firstName}`
                : personalInfo.userId;
          }
        });
      });

      this.history = historyData;
      this.displayTimeline = true;
      this.spacerElem.notifyOnChanges();
    }
  }

  scrollIntoView(id: string): void {
    const element: Element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ block: 'end', inline: 'end' });
    }
  }

  handleBackendErrors(errors: { error: { code: number } }): void {
    this.httpCustomErrorCode = errors?.error?.code || 0;

    if (
      this.httpCustomErrorCode.toString() ===
      EquipmentNotificationConstants.notificationCodes.ENTITY_DOES_NOT_EXIST.value
    ) {
      this.callErrorNotification(
        EquipmentNotificationConstants.notificationCodes.ENTITY_DOES_NOT_EXIST,
        EquipmentNotificationConstants.notificationCodes,
      );

      this.router.navigate(['workshop']);
    } else {
      this.callErrorNotification(NotificationConstants.commonCodes.UNEXPECTED_ERROR, NotificationConstants.commonCodes);
    }
  }

  callErrorNotification(errorCode: INotificationMessage, codes: INotificationConstant): void {
    this.notificationsService.requestShowNotification(CommonConstants.notificationType.ERROR, errorCode, codes);
  }

  navigateToEquipment(): void {
    this.location.back();
  }

  toggleFilterModal(open: boolean): void {
    this.openFilterModal = open;

    localStorage.setItem(
      CommonConstants.modalIsOpened,
      JSON.stringify({ modalName: CommonConstants.modalsName.EQUIPMENT_HISTORY_FILTER, open }),
    );
    this.storage
      .set(CommonConstants.modalIsOpened, { modalName: CommonConstants.modalsName.EQUIPMENT_HISTORY_FILTER, open })
      .subscribe(() => {
        return;
      });
  }

  getFilterParams(): void {
    const storedFilters: string = localStorage.getItem('timelineFilters');

    if (storedFilters) {
      this.equipmentHistoryActions.saveHistoryFilters(JSON.parse(storedFilters) as IEquipmentHistoryFilters);
    }

    this.filterParams = this.store.pipe(
      select(selectHistoryFilters),
      map((state: IStoreApiItem<IEquipmentHistoryFilters>) => state.data),
      takeUntil(this.destroy),
    );
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();

    this.equipmentHistoryActions.resetEquipmentHistoryState();
    this.spaceSubscription.unsubscribe();
    window.removeEventListener('resize', this.calculateTimelineHeight, false);
    this.isComingFromEqPage = false;

    this.storage.delete(CommonConstants.modalIsOpened).subscribe(() => {
      return;
    });
  }
}
