import { TemplateRef } from '@angular/core';
import { ModalRef, ModalService, ModalSize, ModalVariant } from '@uib/angular';
import { RemoteMonitoringNotificationConstants } from 'src/app/remote-monitoring/constants';
import { IncidentNotificationConstants } from 'src/app/report-center/constants';
import { ILocation } from 'src/app/report-center/models/incident.model';
import { CommonConstants } from '../../constants/common.constants';
import { NotificationsService } from '../../services/notifications/notifications.service';

export function formatAddressFromLocation(location: ILocation): string {
  return (
    (location.address ? location.address : '') +
    (location.postcode && location.address ? ', ' : ' ') +
    (location.postcode ? location.postcode : '')
  );
}

export function transformEmptyStrings(formValue: any): any {
  return Object.keys(formValue).reduce((acc: any, key: any) => {
    acc[key] = formValue[key] === '' ? null : formValue[key];

    return acc;
  }, {});
}

export function checkMergeErrorNotification(response: any, notificationsService: NotificationsService): void {
  if (response?.errors?.error.code) {
    notificationsService.requestShowNotification(
      CommonConstants.notificationType.ERROR,
      response?.errors?.error.code.toString(),
      {
        ...IncidentNotificationConstants.notificationCodes,
        ...RemoteMonitoringNotificationConstants.notificationCodes,
      },
    );
  }
}

export function sliderInputValueChange(
  event: any,
  type: string,
  sliderValues: number[],
  sliderMaxValue: number,
): number[] {
  const value: number = event.target.value ? parseInt(event.target.value, 10) : 0;
  const minValue: number = sliderValues[0];
  const maxValue: number = sliderValues[1];

  if (type === 'min') {
    if (value > maxValue && value < sliderMaxValue) {
      return [maxValue, value];
    }

    if (value > sliderMaxValue) {
      return [maxValue, sliderMaxValue];
    }

    if (value < 0) {
      return [0, maxValue];
    }

    return [value, maxValue];
  } else {
    if (value < minValue && value > 0) {
      return [value, minValue];
    }

    if (value < 0) {
      return [0, minValue];
    }

    return checkMaxValue(value, sliderMaxValue, minValue);
  }
}

function checkMaxValue(value: number, sliderMaxValue: number, minValue: number): number[] {
  if (value > sliderMaxValue) {
    return [minValue, sliderMaxValue];
  }

  return [minValue, value];
}

export function openTheSmallModal(modalService: ModalService, modal: TemplateRef<any>): ModalRef<any> {
  return modalService.open(modal, {
    size: ModalSize.SMALL,
    variant: ModalVariant.DEFAULT,
    isDismissable: false,
  });
}

export function prepareNumericValueData(value: string | number): string | number {
  return typeof value === 'string' ? value.replaceAll(',', '.') : value;
}
