<form [formGroup]="settingsForm">
  <ng-template odxModal #settingsModal>
    <odx-modal-header data-test-id="settings.software_settings_modal">
      <odx-area-header>
        {{ 'SETTINGS.STR_HEADER' | translate }}
      </odx-area-header>
    </odx-modal-header>
    <odx-modal-content [ngStyle]="{ overflow: 'visible' }">
      @if ((isLoading$ | async) === true) {
        <div [odxLoadingSpinner]="(isLoading$ | async) === true" [odxLoadingSpinnerMinHeight]="100" class="w-100"></div>
      } @else {
        <div class="odx-form-field--horizontal odx-form-field is-readonly">
          <div class="col-6">
            <label class="odx-form-field__label" for="organizationName">
              <odx-form-field-label class="odx-form-field-label">
                {{ 'SETTINGS.STR_ORGANIZATION' | translate }}
              </odx-form-field-label>
            </label>
          </div>
          <div class="col-6 ps-0">
            <div class="odx-form-field__inner">
              <div class="odx-form-field__control">
                <div class="odx-form-field__prefix"></div>
                <input
                  type="text"
                  ignisDisableAutocomplete
                  id="organizationName"
                  data-test-id="settings.organization"
                  formControlName="name"
                  class="odx-form-field-control"
                />
                <div class="odx-form-field__suffix"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="odx-form-field--horizontal odx-form-field">
          <div class="col-6">
            <label class="odx-form-field__label" for="language">
              <odx-form-field-label class="odx-form-field-label">
                {{ 'SETTINGS.STR_LANGUAGE_SELECTION' | translate }}
              </odx-form-field-label>
            </label>
          </div>
          <div class="col-6 ps-0">
            <p-dropdown
              #dd1
              (onShow)="dropdownService.openDropdown(dd1)"
              (onHide)="dropdownService.closeDropdown(dd1)"
              optionValue="value"
              optionLabel="key"
              ngDefaultControl
              formControlName="language"
              data-test-id="settings.language_selection"
              [dropdownIcon]="dropdownIconCSSClass"
              [options]="languages"
              [placeholder]="'SETTINGS.STR_SELECT_LANGUAGE_PLACEHOLDER' | translate"
              [showClear]="false"
              (onChange)="onLanguageChanged($event)"
            >
              <ng-template let-option pTemplate="item">
                <span>{{ option.key }}</span>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
        <div class="odx-form-field--horizontal odx-form-field">
          <div class="col-6">
            <label class="odx-form-field__label" for="theme">
              <odx-form-field-label class="odx-form-field-label">
                {{ 'SETTINGS.STR_DISPLAY_THEME' | translate }}
              </odx-form-field-label>
            </label>
          </div>
          <div class="col-6 ps-0">
            <odx-toggle-button-group formControlName="theme" class="w-100">
              <odx-toggle-button [value]="lightTheme" data-test-id="settings.light_theme">
                <odx-icon size="inline" name="brightness-3"></odx-icon>
                {{ 'SETTINGS.STR_LIGHT_THEME' | translate }}
              </odx-toggle-button>
              <odx-toggle-button [value]="darkTheme" data-test-id="settings.dark_theme">
                <odx-icon size="inline" name="night-mode"></odx-icon>
                {{ 'SETTINGS.STR_DARK_THEME' | translate }}
              </odx-toggle-button>
            </odx-toggle-button-group>
          </div>
        </div>
      }
    </odx-modal-content>
    <odx-modal-footer>
      <button odxButton odxModalClose variant="secondary" id="close-modal" data-test-id="modal_actions.cancel_btn">
        {{ 'SETTINGS.STR_CANCEL_BTN' | translate }}
      </button>
      <button odxButton [odxModalClose]="true" variant="primary" data-test-id="modal_actions.save_btn">
        {{ 'SETTINGS.STR_SAVE_BTN' | translate }}
      </button>
    </odx-modal-footer>
  </ng-template>
</form>
