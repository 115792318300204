import { Component, EventEmitter, inject, OnInit, Output, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TreeNode } from 'primeng/api';
import { TreeSelect } from 'primeng/treeselect';
import { map, takeUntil } from 'rxjs';
import { ApplicationState, DropdownService, IStoreApiList } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins';
import { ILocationHierarchy } from 'src/app/configuration/models';
import { LocationHierarchyActions } from 'src/app/configuration/state/actions';
import { selectLocationHierarchy } from 'src/app/configuration/state/selectors/configuration.selectors';
import { WorkshopConstants } from 'src/app/workshop';
import { ILocationAssignment } from 'src/app/workshop/models';
import { AccessControlService } from '../../../../root';

@Component({
  selector: 'ignis-directly-assign-location',
  templateUrl: './directly-assign-location.component.html',
  styleUrls: ['./directly-assign-location.component.scss'],
})
export class DirectlyAssignLocationComponent extends OnDestroyMixin() implements OnInit {
  @ViewChild('dd1') dd1: Partial<TreeSelect>;

  @Output() handleSelectedLocation: EventEmitter<ILocationAssignment> = new EventEmitter<ILocationAssignment>();

  locations: ILocationHierarchy[];
  savedLocation: ILocationHierarchy;
  selectedLocation: ILocationAssignment;

  dropdownService: DropdownService = inject(DropdownService);
  locationHierarchyActions: LocationHierarchyActions = inject(LocationHierarchyActions);
  accessControlService: AccessControlService = inject(AccessControlService);

  constructor(private store: Store<ApplicationState>) {
    super();

    this.locations = [];
  }

  ngOnInit(): void {
    this.getLocations();
  }

  getLocations(): void {
    this.locationHierarchyActions.requestLocationHierarchy();

    this.store
      .pipe(
        select(selectLocationHierarchy),
        map((locationList: IStoreApiList<ILocationHierarchy[]>) => locationList.data || []),
        takeUntil(this.destroy),
      )
      .subscribe((response: ILocationHierarchy[]) => {
        if (response.length) {
          this.locations = structuredClone(response);
          this.dropdownService.findTreeNodeAndAddIcon(this.locations);

          if (WorkshopConstants.savedLocation in localStorage) {
            if (this.accessControlService.locationRestriction) {
              localStorage.setItem(WorkshopConstants.savedLocation, JSON.stringify(this.locations[0].data));
            }

            this.selectedLocation = JSON.parse(
              localStorage.getItem(WorkshopConstants.savedLocation),
            ) as ILocationAssignment;
            this.searchAndSelectNode(this.locations, this.selectedLocation.aggregateId);
            this.handleSelectedLocation.emit(this.selectedLocation);
          }
        }
      });
  }

  setSelectedLocation(selected: { node: TreeNode }): void {
    const location: ILocationAssignment = selected.node.data as ILocationAssignment;

    this.selectedLocation = { ...location };

    this.handleSelectedLocation.emit(this.selectedLocation);
    localStorage.setItem(WorkshopConstants.savedLocation, JSON.stringify(this.selectedLocation));

    this.dd1.overlayVisible = false;
  }

  clearSelectedLocation(): void {
    this.selectedLocation = null;
    this.handleSelectedLocation.emit(this.selectedLocation);
    localStorage.removeItem(WorkshopConstants.savedLocation);
  }

  searchAndSelectNode(locations: ILocationHierarchy[], locationId: string): void {
    for (const node of locations) {
      if (node.data?.aggregateId === locationId) {
        setTimeout(() => {
          this.savedLocation = node;
        }, 0);

        return;
      } else {
        this.savedLocation = this.locations[0];
      }

      if (node.children) {
        this.searchAndSelectNode(node.children, locationId);
      }
    }
  }
}
