import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PropertyBag } from 'src/app/common';
import { AppUserPermissionList } from 'src/app/root/models/app-types';
import { AccessControlService } from 'src/app/root/services/access-control/access-control.service';
import { ConfigurationModuleRoutes } from '../../constants/configuration-module-routes.constants';

@Component({
  selector: 'ignis-configuration-overview',
  templateUrl: './configuration-overview.component.html',
  styleUrls: ['./configuration-overview.component.scss'],
})
export class ConfigurationOverviewComponent implements OnInit {
  appUserPermissionList: any = AppUserPermissionList;
  isNoPermissionTooltipDisplayedForOrgSettings: boolean = false;
  isNoPermissionTooltipDisplayedForLocation: boolean = false;
  isNoPermissionTooltipDisplayedForEquipment: boolean = false;
  isNoPermissionTooltipDisplayedForAddressBook: boolean = false;

  readonly ConfigurationModuleRoutes: PropertyBag = ConfigurationModuleRoutes;

  constructor(
    public router: Router,
    public accessControlService: AccessControlService,
  ) {
    this.navigateToActiveFeature();
  }

  ngOnInit(): void {
    if (
      this.accessControlService.checkPermission(this.appUserPermissionList.configuration) &&
      this.accessControlService.checkPermission(this.appUserPermissionList.organization) &&
      !this.accessControlService.checkPermission(this.appUserPermissionList.userManagement)
    ) {
      this.router.navigate(['configuration', 'organization-settings']);
    }
  }

  navigateToLocationTab(): void {
    this.router.navigate(['configuration', 'location']);
  }

  navigateToEquipmentTab(): void {
    this.router.navigate(['configuration']);
  }

  navigateToActiveFeature(): void {
    if (!this.accessControlService.locationFeatureToggle && this.accessControlService.workshopFeatureToggle) {
      this.navigateToEquipmentTab();
    }
  }

  navigateToAddressBookTab(): void {
    this.router.navigate(['configuration', 'address']);
  }

  navigateToOrganizationSettingsTab(): void {
    this.router.navigate(['configuration', 'organization-settings']);
  }
}
