import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TableExportExcelConstants } from '../../constants/table-excel-export.constants';

@Component({
  selector: 'ignis-export-excel-footer-btn',
  templateUrl: './export-excel-footer-btn.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportExcelFooterBtnComponent {
  @Input() numberOfRecordsToBeExported: number;
  @Input() url: string[];
  @Input() isModalOpened: boolean;
  @Input() exportRoleEntries: boolean;

  maximumExcelEntriesNumber: number = TableExportExcelConstants.excelEntriesNumber;

  @Output() handleClickEvent: EventEmitter<Event> = new EventEmitter();

  constructor(public router: Router) {}

  onClick(e: Event): void {
    e.stopPropagation();
    this.handleClickEvent.emit(e);
  }
}
