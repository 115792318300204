import { GenericAction } from 'src/app/common';
import {
  errorPostPutItemData,
  receivePostPutItemData,
  requestPostPutItemData,
} from 'src/app/common/utils/reducer/reducer.utils';
import { EquipmentUpdateIdsActionTypes } from '../../actions/equipment-update-ids';
import { IUpdateEquipmentIdsState, UpdateEquipmentIdsState } from '../../models/equipment.model';

const resetUpdateEquipmentFieldsState = (lastState: IUpdateEquipmentIdsState): IUpdateEquipmentIdsState => ({
  ...lastState,
  equipmentIds: {
    data: null,
    errors: null,
    isSuccess: null,
    isLoading: false,
  },
});

export function updateEquipmentIdsReducer(
  lastState: IUpdateEquipmentIdsState = new UpdateEquipmentIdsState(),
  action: GenericAction<EquipmentUpdateIdsActionTypes, any>,
): IUpdateEquipmentIdsState {
  switch (action.type) {
    case EquipmentUpdateIdsActionTypes.REQUEST_UPDATE_EQUIPMENT_IDS:
      return requestPostPutItemData<IUpdateEquipmentIdsState, any>(lastState, ['equipmentIds'], action.payload);
    case EquipmentUpdateIdsActionTypes.RECEIVE_UPDATE_EQUIPMENT_IDS:
      return receivePostPutItemData<IUpdateEquipmentIdsState, any>(lastState, ['equipmentIds'], action.payload);
    case EquipmentUpdateIdsActionTypes.ERROR_RECEIVE_UPDATE_EQUIPMENT_IDS:
      return errorPostPutItemData<IUpdateEquipmentIdsState, any>(lastState, ['equipmentIds'], action.payload);
    case EquipmentUpdateIdsActionTypes.RESET_UPDATE_EQUIPMENT_IDS:
      return resetUpdateEquipmentFieldsState(lastState);

    default:
      return lastState;
  }
}
