import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { CommonConstants } from '../../constants/common.constants';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  accessToken: string;
  jwtHelper: JwtHelperService = new JwtHelperService();

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let cloned: HttpRequest<any> = req;

    this.accessToken = localStorage.getItem(CommonConstants.ACCESS_TOKEN);

    if (this.accessToken) {
      cloned = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.accessToken}`,
          'X-sc-tenant-id': this.generateTenantId(req.url),
        },
      });
    }

    return next.handle(cloned);
  }

  generateTenantId(url: string): string {
    if (
      CommonConstants.excludeTenantIdEndpoints.find(
        (segment: string) => url.includes(segment) || localStorage.getItem(CommonConstants.tenantId) === null,
      )
    ) {
      return 'tenant-id-not-available';
    }

    return localStorage.getItem(CommonConstants.tenantId);
  }
}
