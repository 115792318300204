import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { AppUserPermissionList } from 'src/app/root/models/app-types';
import { AccessControlService } from 'src/app/root/services/access-control/access-control.service';
import { OnDestroyMixin } from '../../mixins';
import { DetectChangesInFormsService } from '../../services/detect-changes/detect-changes-in-forms.service';

@Component({
  selector: 'ignis-generic-footer-items',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericFooterItemsComponent<T> extends OnDestroyMixin() implements OnChanges, OnInit {
  @Input() selfSelected: T;
  @Input() selectedItem: T | any;
  @Input() selectedItems: T[] | any;
  @Input() selectedMerge: T;
  @Input() dirtyForm: boolean;
  @Input() validForm: boolean;
  @Input() form: FormGroup | any;
  @Input() activeEdit: boolean;
  @Input() activeTab: string;
  @Input() mandatoryUncompletedFields: string[];
  @Input() restrictedItem: boolean;
  @Input() mergeMode: boolean = false;

  @Output() handleCreate: EventEmitter<void> = new EventEmitter();
  @Output() handleEdit: EventEmitter<void> = new EventEmitter();
  @Output() handleDelete: EventEmitter<boolean> = new EventEmitter();
  @Output() handleSubmit: EventEmitter<void> = new EventEmitter();
  @Output() handleMergeIncidents: EventEmitter<void> = new EventEmitter();
  @Output() handleExcelExport: EventEmitter<void> = new EventEmitter();

  appUserPermissionList: any = AppUserPermissionList;
  permissions: string[] = [];

  jwtHelper: JwtHelperService = new JwtHelperService();

  showAddButtonTooltip: boolean = false;
  showSaveButtonTooltip: boolean = false;

  router: Router = inject(Router);
  translateService: TranslateService = inject(TranslateService);
  accessControlService: AccessControlService = inject(AccessControlService);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  destroyRef: DestroyRef = inject(DestroyRef);
  detectChangesInFormsService: DetectChangesInFormsService = inject(DetectChangesInFormsService);

  constructor() {
    super();

    this.router.events
      ?.pipe(
        filter((event: NavigationEnd) => event instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.cdr.detectChanges();
      });
  }

  ngOnChanges(): void {
    this.permissions = this.accessControlService.permissions;
  }

  ngOnInit(): void {
    this.detectChangesInFormsService.detectChangesInForms(this.form, this.cdr);
  }

  onCreateClick(e: Event): void {
    e.stopPropagation();
    this.handleCreate.emit();
    this.showAddButtonTooltip = false;
    this.cdr.detectChanges();
  }

  onEditClick(e: Event): void {
    e.stopPropagation();
    this.handleEdit.emit();
    this.cdr.detectChanges();
  }

  onDeleteClick(e: Event): void {
    e.stopPropagation();
    this.handleDelete.emit();
    this.cdr.detectChanges();
  }

  onSubmit(e: Event): void {
    e.stopPropagation();
    this.handleSubmit.emit();
    this.cdr.detectChanges();
  }

  onMergeIncidentsClick(e: Event): void {
    e.stopPropagation();
    this.handleMergeIncidents.emit();
    this.cdr.detectChanges();
  }

  onExportExcelClick(e: Event): void {
    e.stopPropagation();
    this.handleExcelExport.emit();
    this.cdr.detectChanges();
  }
}
