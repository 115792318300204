import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IDeviceConnection } from '../../models/device-connection.model';
import { IDeviceConnectionState } from '../models/device-connection.model';
import {
  ReceiveDeviceConnectionById,
  RequestAddDeviceConnection,
  RequestCancelProtectorMigration,
  RequestCancelProtectorMigrationInRAM,
  RequestDeviceConnectionAssignedFireStations,
  RequestDeviceConnectionById,
  RequestDeviceConnectionCategories,
  RequestDeviceConnectionDelete,
  RequestDeviceConnectionExport,
  RequestDeviceConnectionIdentifier,
  RequestDeviceConnectionLicenseInfo,
  RequestDeviceConnectionPage,
  RequestProtectorMigrationErrorPage,
  RequestProtectorMigrationStatus,
  RequestStartProtectorMigration,
  RequestToCheckRAMConnectorToken,
  RequestUpdateDeviceConnection,
  RequestUpdateDeviceConnectionAuthToken,
  ResetCheckRAMConnectorToken,
  ResetDeviceConnection,
  ResetDeviceConnectionIdentifier,
  ResetDeviceConnectionPage,
  ResetProtectorMigrationErrorPage,
  ResetProtectorMigrationStatus,
} from './device-connection-action-types-creators';

@Injectable({ providedIn: 'root' })
export class DeviceConnectionActions {
  constructor(private store: Store<IDeviceConnectionState>) {}

  public requestDeviceConnectionPage(params?: any, isExport: boolean = false): void {
    this.store.dispatch(isExport ? new RequestDeviceConnectionExport(params) : new RequestDeviceConnectionPage(params));
  }

  public selectDeviceConnection(deviceConnection: IDeviceConnection): void {
    this.store.dispatch(new ReceiveDeviceConnectionById(deviceConnection));
  }

  public requestDeviceConnectionById(id: string): void {
    this.store.dispatch(new RequestDeviceConnectionById(id));
  }

  public requestAddDeviceConnection(deviceConnection: IDeviceConnection): any {
    this.store.dispatch(new RequestAddDeviceConnection(deviceConnection));
  }

  public requestUpdateDeviceConnection(deviceConnection: IDeviceConnection): any {
    this.store.dispatch(new RequestUpdateDeviceConnection(deviceConnection));
  }

  public requestDeviceConnectionIdentifier(): void {
    this.store.dispatch(new RequestDeviceConnectionIdentifier());
  }

  public resetDeviceConnectionIdentifier(): any {
    this.store.dispatch(new ResetDeviceConnectionIdentifier());
  }

  public requestDeviceConnectionDelete(deviceConnection: IDeviceConnection): void {
    this.store.dispatch(new RequestDeviceConnectionDelete(deviceConnection));
  }

  public requestUpdateDeviceConnectionAuthToken(aggregateId: string, version: number): void {
    this.store.dispatch(new RequestUpdateDeviceConnectionAuthToken(aggregateId, version));
  }

  public requestDeviceConnectionCategories(): void {
    this.store.dispatch(new RequestDeviceConnectionCategories());
  }

  public resetDeviceConnection(): void {
    this.store.dispatch(new ResetDeviceConnection());
  }

  public requestToCheckRAMConnectorToken(token: string): void {
    this.store.dispatch(new RequestToCheckRAMConnectorToken(token));
  }

  public resetToCheckRAMConnectorToken(): void {
    this.store.dispatch(new ResetCheckRAMConnectorToken());
  }

  public resetDeviceConnectionPage(): void {
    this.store.dispatch(new ResetDeviceConnectionPage());
  }

  public requestDeviceConnectionLicenseInfo(): void {
    this.store.dispatch(new RequestDeviceConnectionLicenseInfo());
  }

  public requestDeviceConnectionAssignedFireStations(): void {
    this.store.dispatch(new RequestDeviceConnectionAssignedFireStations());
  }

  public requestStartProtectorMigration(): void {
    this.store.dispatch(new RequestStartProtectorMigration());
  }

  public requestCancelProtectorMigrationInRAM(): void {
    this.store.dispatch(new RequestCancelProtectorMigrationInRAM());
  }

  public requestCancelProtectorMigration(migrationId: string): void {
    this.store.dispatch(new RequestCancelProtectorMigration(migrationId));
  }

  public requestProtectorMigrationStatus(migrationId: string): void {
    this.store.dispatch(new RequestProtectorMigrationStatus(migrationId));
  }

  public resetProtectorMigrationStatus(): void {
    this.store.dispatch(new ResetProtectorMigrationStatus());
  }

  public requestProtectorMigrationErrorPage(migrationId: string, params?: any): void {
    this.store.dispatch(new RequestProtectorMigrationErrorPage(migrationId, params));
  }

  public resetProtectorMigrationPage(): void {
    this.store.dispatch(new ResetProtectorMigrationErrorPage());
  }
}
