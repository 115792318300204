import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Inject,
  Input,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { UserStorageService } from 'src/app/common/services/user-based-local-storage/user-based-local-storage.service';
import { IAlarm } from 'src/app/root/models/alarms.model';

@Component({
  selector: 'ignis-alarm-notification',
  templateUrl: './alarm-notification.component.html',
  styleUrls: ['./alarm-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlarmNotificationComponent {
  isHovered: boolean[] = [];
  @Input() newAlarms: IAlarm[];
  @Input() dateFormat: string;
  @Output() closeNotification: EventEmitter<IAlarm> = new EventEmitter();
  @Output() handleNewAlarmsInfo: EventEmitter<any> = new EventEmitter();

  currentAppTheme: string;

  router: Router = inject(Router);
  userStorageService: UserStorageService = inject(UserStorageService);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.currentAppTheme = this.document.body.className.split(' ')[1];
  }

  get readAlarms(): string[] {
    const readAlarms: string[] = this.userStorageService.getStoredValue('readAlarms');

    return readAlarms ? readAlarms : [];
  }

  closeWidget(alarm: IAlarm): void {
    this.closeNotification.emit(alarm);
    this.cdr.detectChanges();
  }

  navigateToMonitorTeamsView(incidentAggregateId: string, ecpId: string, selectedAlert: IAlarm): void {
    const read: string[] = this.readAlarms;

    if (!read.length) {
      read.push(selectedAlert.id);
    }

    if (read.length && !read.find((alarm: string) => alarm === selectedAlert.id)) {
      read.push(selectedAlert.id);
    }

    this.userStorageService.setStoredValue('readAlarms', read);

    this.handleNewAlarmsInfo.emit();
    this.closeWidget(selectedAlert);
    this.router.navigate([
      'remote-monitoring',
      'incident-overview',
      incidentAggregateId,
      'ecp',
      ecpId,
      'monitor-teams',
    ]);

    this.cdr.detectChanges();
  }
}
