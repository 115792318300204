import { GenericAction, IFireStationList } from 'src/app/common';
import {
  errorDeleteItem,
  errorItemData,
  errorListData,
  errorPostPutItemData,
  receiveDeleteItem,
  receiveItemData,
  receiveListData,
  receivePostPutItemData,
  requestDeleteItem,
  requestItemData,
  requestListData,
  requestPostPutItemData,
} from 'src/app/common/utils';
import { IBAWearer, IBAWearerPage, ICreateUpdateBAWearer, IDeleteBAWearer } from '../../models';
import { BAWearerActionTypes } from '../actions/ba-wearer-action-types';
import { BAWearerState, IBAWearerState } from '../models/ba-wearer.model';

export function baWearerReducer(
  lastState: IBAWearerState = new BAWearerState(),
  action: GenericAction<BAWearerActionTypes, any>,
) {
  switch (action.type) {
    case BAWearerActionTypes.REQUEST_BA_WEARER_PAGE:
      return requestItemData<IBAWearerState, IBAWearerPage>(lastState, ['baWearerPage']);
    case BAWearerActionTypes.RECEIVE_BA_WEARER_PAGE:
      return receiveItemData<IBAWearerState, IBAWearerPage>(lastState, ['baWearerPage'], action.payload);
    case BAWearerActionTypes.ERROR_RECEIVE_BA_WEARER_PAGE:
      return errorItemData<IBAWearerState, IBAWearerPage>(lastState, ['baWearerPage'], action.payload);

    case BAWearerActionTypes.REQUEST_BA_WEARER_BY_ID:
      return requestItemData<IBAWearerState, IBAWearer>(lastState, ['baWearer']);
    case BAWearerActionTypes.RECEIVE_BA_WEARER_BY_ID:
      return receiveItemData<IBAWearerState, IBAWearer>(lastState, ['baWearer'], action.payload);
    case BAWearerActionTypes.ERROR_RECEIVE_BA_WEARER_BY_ID:
      return errorItemData<IBAWearerState, IBAWearer>(lastState, ['baWearer'], action.payload);

    case BAWearerActionTypes.REQUEST_CREATE_BA_WEARER:
      return requestPostPutItemData<IBAWearerState, ICreateUpdateBAWearer>(lastState, ['newBAWearer'], action.payload);
    case BAWearerActionTypes.RECEIVE_CREATE_BA_WEARER:
      return receivePostPutItemData<IBAWearerState, IBAWearer>(lastState, ['newBAWearer'], action.payload);
    case BAWearerActionTypes.ERROR_RECEIVE_CREATE_BA_WEARER:
      return errorPostPutItemData<IBAWearerState, ICreateUpdateBAWearer>(lastState, ['newBAWearer'], action.payload);

    case BAWearerActionTypes.REQUEST_UPDATE_BA_WEARER:
      return requestPostPutItemData<IBAWearerState, ICreateUpdateBAWearer>(
        lastState,
        ['updatedBAWearer'],
        action.payload,
      );
    case BAWearerActionTypes.RECEIVE_UPDATE_BA_WEARER:
      return receivePostPutItemData<IBAWearerState, IBAWearer>(lastState, ['updatedBAWearer'], action.payload);
    case BAWearerActionTypes.ERROR_RECEIVE_UPDATE_BA_WEARER:
      return errorPostPutItemData<IBAWearerState, ICreateUpdateBAWearer>(
        lastState,
        ['updatedBAWearer'],
        action.payload,
      );

    case BAWearerActionTypes.REQUEST_DELETE_BA_WEARER:
      return requestDeleteItem<IBAWearerState, IDeleteBAWearer>(lastState, ['deletedBAWearer']);
    case BAWearerActionTypes.RECEIVE_DELETE_BA_WEARER:
      return receiveDeleteItem<IBAWearerState, IBAWearer>(lastState, ['deletedBAWearer']);
    case BAWearerActionTypes.ERROR_RECEIVE_DELETE_BA_WEARER:
      return errorDeleteItem<IBAWearerState, IDeleteBAWearer>(lastState, ['deletedBAWearer'], action.payload);

    case BAWearerActionTypes.REQUEST_FIRE_STATION_LIST:
      return requestListData<IBAWearerState, IFireStationList>(lastState, ['fireStationList']);
    case BAWearerActionTypes.RECEIVE_FIRE_STATION_LIST:
      return receiveListData<IBAWearerState, IFireStationList>(lastState, ['fireStationList'], action.payload);
    case BAWearerActionTypes.ERROR_RECEIVE_FIRE_STATION_LIST:
      return errorListData<IBAWearerState, IFireStationList>(lastState, ['fireStationList'], action.payload);

    case BAWearerActionTypes.REQUEST_UPLOAD_BA_WEARER_CSV_FILE:
      return requestPostPutItemData<IBAWearerState, File>(lastState, ['csv'], action.payload);
    case BAWearerActionTypes.RECEIVE_UPLOAD_BA_WEARER_CSV_FILE:
      return receivePostPutItemData<IBAWearerState, unknown>(lastState, ['csv'], action.payload);
    case BAWearerActionTypes.ERROR_RECEIVE_UPLOAD_BA_WEARER_CSV_FILE:
      return errorPostPutItemData<IBAWearerState, unknown>(lastState, ['csv'], action.payload);

    default:
      return lastState;
  }
}
