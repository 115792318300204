import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalRef, ModalService, ModalSize, ModalVariant } from '@odx/angular/components/modal';
import { TreeNode } from 'primeng/api';
import { CheckingSettingsModalsService, CommonConstants, IModalState } from 'src/app/common';
import { WorkshopConstants } from 'src/app/workshop/constants';
import { EquipmentHistoryFilters, IEquipmentHistoryFilters } from 'src/app/workshop/models';
import { EquipmentHistoryActions } from 'src/app/workshop/state/actions/equipment-history/equipment-history.actions';

@Component({
  selector: 'ignis-timeline-filter',
  templateUrl: './timeline-filter.component.html',
  styleUrls: ['./timeline-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineFilterComponent implements OnChanges, AfterViewInit {
  @Input() isOpen: boolean = false;
  @Output() closeFilterModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('timelineFilterModal', { read: TemplateRef })
  public timelineFilterModal: TemplateRef<string>;
  aggregateId: string = null;
  modalRef: ModalRef<string, string>;

  selectedFilters: TreeNode[] = [];
  timelineFilters: TreeNode[][] = structuredClone(WorkshopConstants.historyFilters);
  currentAppTheme: string;
  routeAfterBrowserBackBtnIsPressed: string;

  readonly modalService: ModalService = inject(ModalService);
  checkingSettingsModalsService: CheckingSettingsModalsService = inject(CheckingSettingsModalsService);
  equipmentHistoryActions: EquipmentHistoryActions = inject(EquipmentHistoryActions);
  router: Router = inject(Router);

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.aggregateId = window.location.href.split('/').slice(-1)[0];

    this.currentAppTheme = this.document.body.className.split(' ')[1];
  }

  @HostListener(CommonConstants.popStateWindowEvent, ['$event'])
  onPopState(): void {
    this.checkingSettingsModalsService.remainOnTheSamePageIfTheModalIsOpened(
      this.isOpen,
      this.routeAfterBrowserBackBtnIsPressed,
      'history-filter--cancel',
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const modalIsOpened: IModalState = JSON.parse(localStorage.getItem(CommonConstants.modalIsOpened)) as IModalState;

    if (
      (changes.isOpen && this.isOpen) ||
      (modalIsOpened?.modalName === CommonConstants.modalsName.EQUIPMENT_HISTORY_FILTER && modalIsOpened?.open)
    ) {
      setTimeout(() => {
        this.openFilterModal();
        this.routeAfterBrowserBackBtnIsPressed = this.router.url;
      }, 0);
    }
  }

  ngAfterViewInit(): void {
    this.loadFilters();
  }

  openFilterModal(): void {
    this.modalRef = this.modalService?.open(this.timelineFilterModal, {
      size: ModalSize.MEDIUM,
      variant: ModalVariant.DEFAULT,
      dismissable: false,
      data: 'isOpen',
    });

    this.modalRef?.onClose$.subscribe((chosenValue: string) => this.handleCloseModal(Boolean(chosenValue)));
    this.modalRef?.onDismiss$.subscribe(() => this.handleCloseModal());
  }

  closeModal(): void {
    this.modalRef?.close('');
  }

  loadFilters(): void {
    const storedFilters: string = localStorage.getItem('timelineFilters');

    if (storedFilters) {
      const filtersObj: IEquipmentHistoryFilters = new EquipmentHistoryFilters(
        JSON.parse(storedFilters) as IEquipmentHistoryFilters,
      ) as IEquipmentHistoryFilters;
      const filters: string[] = Object.keys(filtersObj).filter((key: string) => filtersObj[key]);

      this.recoverSelected([...this.timelineFilters[0], ...this.timelineFilters[1]], filters);
      this.equipmentHistoryActions.saveHistoryFilters(filtersObj);
    } else {
      this.initiateFilters();
    }
  }

  initiateFilters(): void {
    this.timelineFilters = null;
    this.timelineFilters = structuredClone(WorkshopConstants.historyFilters);
    this.selectedFilters = [];
  }

  getSelectedNodes(): IEquipmentHistoryFilters {
    const filters: IEquipmentHistoryFilters = new EquipmentHistoryFilters();

    this.selectedFilters.forEach((node: TreeNode) => (filters[node.data as number] = true));

    return filters;
  }

  onSubmit(): void {
    this.saveFilters();

    this.equipmentHistoryActions.saveHistoryFilters(this.getSelectedNodes());

    this.getEquipmentHistory();
    this.closeModal();
  }

  recoverSelected(tree: TreeNode[], keys: string[] = []): void {
    let count: number = tree.length;

    for (const node of tree) {
      if (keys.includes(node.data as string) || this.selectedFilters.includes(node.parent)) {
        this.selectedFilters.push(node);
        count--;
      }

      if (node.children) {
        this.recoverSelected(node.children, keys);
      }
    }

    if (tree.length > 0 && tree[0].parent) {
      this.setPartialSelectedParent(tree[0].parent.data as string, count > 0 && count !== tree.length);
    }
  }

  setPartialSelectedParent(parentData: string, status: boolean): void {
    this.timelineFilters.some((column: TreeNode[]) => {
      column.some((node: TreeNode) => {
        if (node.data === parentData) {
          node.partialSelected = status;
        }
      });
    });
  }

  saveFilters(): void {
    localStorage.setItem('timelineFilters', JSON.stringify(this.getSelectedNodes()));
  }

  getEquipmentHistory(): void {
    this.equipmentHistoryActions.requestEquipmentHistory(this.aggregateId);
  }

  onResetFilters(): void {
    this.initiateFilters();
    this.equipmentHistoryActions.resetHistoryFilters();
    localStorage.removeItem('timelineFilters');
    this.getEquipmentHistory();
    this.closeModal();
  }

  handleCloseModal(isConfirmed: boolean = false): void {
    if (!isConfirmed) {
      this.closeFilterModal.emit(isConfirmed);
    }
  }
}
