import { Platform } from '@angular/cdk/platform';
import { Directive, ElementRef, HostListener } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Directive({
  selector: '[ignisDisableAutocomplete]',
})
export class DisableAutocompleteDirective {
  constructor(
    private el: ElementRef,
    private platform: Platform,
  ) {}

  @HostListener('focus')
  onFocus() {
    const attributeValue: string = this.changeAutoCompleteAttributeByBrowser();

    this.el.nativeElement?.setAttribute('autocomplete', attributeValue);
    this.el.nativeElement?.setAttribute('aria-autocomplete', 'list');
    this.el.nativeElement?.setAttribute('name', uuidv4().toString());
  }

  @HostListener('blur')
  blur() {
    this.el.nativeElement?.removeAttribute('autocomplete');
    this.el.nativeElement?.removeAttribute('aria-autocomplete');
    this.el.nativeElement?.removeAttribute('name');
  }

  changeAutoCompleteAttributeByBrowser(): string {
    return this.platform.FIREFOX ? 'off' : 'nope';
  }
}
