// eslint-disable-next-line no-shadow
export enum UserActionTypes {
  REQUEST_USER_PAGE = 'REQUEST_USER_LIST',
  RECEIVE_USER_PAGE = 'RECEIVE_USER_LIST',
  ERROR_RECEIVE_USER_PAGE = 'ERROR_RECEIVE_USER_LIST',
  REQUEST_USER_EXPORT = 'REQUEST_USER_EXPORT',

  REQUEST_ADD_USER = 'REQUEST_ADD_USER',
  RECEIVE_ADD_USER = 'RECEIVE_ADD_USER',
  ERROR_ADD_USER = 'ERROR_ADD_USER',

  REQUEST_USER_BY_ID = 'REQUEST_USER_BY_ID',
  RECEIVE_USER_BY_ID = 'RECEIVE_USER_BY_ID',
  ERROR_RECEIVE_USER_BY_ID = 'ERROR_RECEIVE_USER_BY_ID',

  REQUEST_USERS_PERSONAL_INFO_BY_IDS = 'REQUEST_USERS_PERSONAL_INFO_BY_IDS',
  RECEIVE_USERS_PERSONAL_INFO_BY_IDS = 'RECEIVE_USERS_PERSONAL_INFO_BY_IDS',
  ERROR_RECEIVE_USERS_PERSONAL_INFO_BY_IDS = 'ERROR_RECEIVE_USERS_PERSONAL_INFO_BY_IDS',

  REQUEST_UPDATE_USER = 'REQUEST_UPDATE_USER',
  RECEIVE_UPDATE_USER = 'RECEIVE_UPDATE_USER',
  ERROR_UPDATE_USER = 'ERROR_UPDATE_USER',

  REQUEST_USER_ROLE_LIST = 'REQUEST_USER_ROLE_LIST',
  RECEIVE_USER_ROLE_LIST = 'RECEIVE_USER_ROLE_LIST',
  ERROR_RECEIVE_USER_ROLE_LIST = 'ERROR_RECEIVE_USER_ROLE_LIST',

  REQUEST_USER_DELETE = 'REQUEST_USER_DELETE',
  RECEIVE_USER_DELETE = 'RECEIVE_USER_DELETE',
  ERROR_RECEIVE_USER_DELETE = 'ERROR_RECEIVE_USER_DELETE',

  REQUEST_UPLOAD_CSV_FILE = 'REQUEST_UPLOAD_CSV_FILE',
  RECEIVE_UPLOAD_CSV_FILE = 'RECEIVE_UPLOAD_CSV_FILE',
  ERROR_RECEIVE_UPLOAD_CSV_FILE = 'ERROR_RECEIVE_UPLOAD_CSV_FILE',

  RESET_CRUD_USER_STATE = 'RESET_CRUD_USER_STATE',
}
