import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonConstants } from 'src/app/common';
import { ReportCenterConstants } from 'src/app/report-center/constants/report-center.constants';

@Component({
  selector: 'ignis-duration-filter',
  templateUrl: './duration-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DurationFilterComponent implements OnInit {
  durationsList: any[] = ReportCenterConstants.incidentDurations;
  copyOfDurationsList: any[] = [];
  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;

  @Input() filterService: any;
  @Input() selectedDurations: any;

  @Output() handleSaveFilters: EventEmitter<any> = new EventEmitter();

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.durationsList = this.durationsList.map((duration: any) => ({
      ...duration,
      duration: this.translateService.instant(
        ReportCenterConstants.incidentDurations.find((d: any) => d.id === duration.id)?.localizedName ||
          duration.duration,
      ),
    }));

    this.copyOfDurationsList = this.durationsList;
  }

  saveFilters(field: string, value: any): void {
    this.handleSaveFilters.emit({ name: field, data: value });
  }

  removeSelectedDurations(): void {
    this.selectedDurations = [];
    this.durationsList = this.copyOfDurationsList;
  }
}
