import { GenericAction } from 'src/app/common';
import { errorItemData, receiveItemData, requestItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { IDeviceConnectionLicenseInfo } from '../../models/device-connection.model';
import { DeviceConnectionActionTypes } from '../actions/device-connection-action-types';
import { DeviceConnectionLicenseInfoState, IDeviceConnectionLicenseInfoState } from '../models/device-connection.model';

export function deviceConnectionLicenseInfoReducer(
  lastState: IDeviceConnectionLicenseInfoState = new DeviceConnectionLicenseInfoState(),
  action: GenericAction<DeviceConnectionActionTypes, any>
): IDeviceConnectionLicenseInfoState {
  switch (action.type) {

    case DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_LICENSE_INFO:
      return requestItemData<IDeviceConnectionLicenseInfoState, IDeviceConnectionLicenseInfo>(lastState, ['licenseInfo']);
    case DeviceConnectionActionTypes.RECEIVE_DEVICE_CONNECTION_LICENSE_INFO:
      return receiveItemData<IDeviceConnectionLicenseInfoState, IDeviceConnectionLicenseInfo>(lastState, ['licenseInfo'], action.payload);
    case DeviceConnectionActionTypes.ERROR_RECEIVE_DEVICE_CONNECTION_LICENSE_INFO:
      return errorItemData<IDeviceConnectionLicenseInfoState, IDeviceConnectionLicenseInfo>(lastState, ['licenseInfo'], action.payload);

    default:
      return lastState;
  }
}
