<odx-form-field [variant]="'simple'" odxLayout="6@phone 12">
  <p-multiSelect
    #multiSelection
    ngDefaultControl
    data-test-id="report_center_list.duration_filter"
    appendTo="body"
    [dropdownIcon]="dropdownIconCSSClass"
    class="table-multiSelect-filter"
    optionLabel="duration"
    [ngModel]="selectedDurations"
    [options]="durationsList"
    [placeholder]="'REPORT_CENTER_TABLE.SEARCH_DURATION' | translate"
    [filter]="true"
    [resetFilterOnHide]="true"
    (onChange)="filterService($event.value); saveFilters('duration', $event.value)"
  >
    <ng-template pTemplate="header">
      <button
        type="button"
        class="p-multiselect-close p-link p-multiselect-custom-close-btn"
        data-test-id="report_center_list.reset_duration_filter_btn"
        (click)="
          multiSelection.hide(); removeSelectedDurations(); saveFilters('duration', []); filterService($event.value)
        "
      >
        <span class="p-multiselect-close-icon pi pi-times"></span>
      </button>
    </ng-template>
    <ng-template let-value pTemplate="selectedItems">
      <div *ngIf="value !== null && value?.length === 1">
        <div *ngFor="let val of value" class="ui-multiselected-item-token ui-corner-all">
          <span>{{ val.duration }}</span>
        </div>
      </div>
      <ignis-multiple-items-selected [value]="value"></ignis-multiple-items-selected>
      <span *ngIf="!value || value?.length === 0" class="ui-multiselected-empty-token ui-corner-all">
        {{ 'REPORT_CENTER_TABLE.SEARCH_DURATION' | translate }}
      </span>
    </ng-template>
  </p-multiSelect>
</odx-form-field>
