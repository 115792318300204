import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { CommonConstants } from '../constants';

@Directive({
  selector: '[ignisDashIfEmpty]',
})
export class DashIfEmptyDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    const value: string = this.elementRef.nativeElement.textContent.trim();

    if (!value) {
      this.elementRef.nativeElement.textContent = CommonConstants.exportDash;
    }
  }
}
