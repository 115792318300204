import { GenericAction } from 'src/app/common';
import { errorItemData, receiveItemData, requestItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { IIncidentAnalysis } from 'src/app/report-center/models/incident.model';
import { IncidentActionTypes } from '../../actions/incident-action-types';
import { IIncidentAnalysisState, IncidentAnalysisState } from '../../models/incident-analysis.model';

export function incidentAnalysisReducer(
  lastState: IIncidentAnalysisState = new IncidentAnalysisState(),
  action: GenericAction<IncidentActionTypes, any>
): IIncidentAnalysisState {
  switch (action.type) {

    case IncidentActionTypes.REQUEST_INCIDENT_ANALYSIS:
      return requestItemData<IIncidentAnalysisState, IIncidentAnalysis>(lastState, ['incidentAnalysis']);
    case IncidentActionTypes.RECEIVE_INCIDENT_ANALYSIS:
      return receiveItemData<IIncidentAnalysisState, IIncidentAnalysis>(lastState, ['incidentAnalysis'], action.payload);
    case IncidentActionTypes.ERROR_RECEIVE_INCIDENT_ANALYSIS:
      return errorItemData<IIncidentAnalysisState, IIncidentAnalysis>(lastState, ['incidentAnalysis'], action.payload);

    default:
      return lastState;
  }
}
