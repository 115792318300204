import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { ApplicationState } from 'src/app/common/state/models/app.state.model';
import { IStoreApiList } from './../../../common/models/store-api-list.model';
import { ILogbook, ILogbookPage } from './../../models/logbook.model';
import { getDateFormatFromUserProfile, getPressureUnit } from '../../../common/utils/settings-utils/settings-utils';
import { IEntriesModel } from '../../models/logbook.model';
import { LogbookActions } from '../../state/actions/logbook.actions';
import { selectEntries, selectLogbookExcelData, selectLogbookPage } from '../../state/selectors/logbook.selector';

@Component({
  selector: 'ignis-logbook',
  templateUrl: './logbook.component.html',
})
export class LogbookComponent extends OnDestroyMixin() implements OnInit {
  formatDate: string;
  pressureDisplayUnit: Observable<string>;
  isLoading: Observable<boolean>;
  logbookPage: Observable<ILogbookPage>;
  entries: Observable<IEntriesModel>;
  logBookLength: number;
  logbookExcelData: Observable<ILogbook[]>;

  constructor(
    private logbookActions: LogbookActions,
    private store: Store<ApplicationState>,
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getEntries();
    this.getLogbook();

    this.entries = this.store.pipe(
      select(selectEntries),
      map((state: IStoreApiItem<IEntriesModel>) => state.data),
      takeUntil(this.destroy)
    );

    this.logbookExcelData = this.store.pipe(
      select(selectLogbookExcelData),
      map((state: IStoreApiList<ILogbook[]>) => state.data),
      takeUntil(this.destroy)
    );

    this.formatDate = getDateFormatFromUserProfile(this.translateService);
    this.pressureDisplayUnit = getPressureUnit(this.store, this.destroy);
  }

  getLogbook(): void {
    this.isLoading = this.store.pipe(
      select(selectLogbookPage),
      takeUntil(this.destroy),
      map((state: IStoreApiItem<ILogbookPage>) => state.isLoading)
    );

    this.logbookPage = this.store.pipe(
      select(selectLogbookPage),
      map((state: IStoreApiItem<ILogbookPage>) => {
        if (state.data) {
          this.logBookLength = state.data.entries.length;
        } else {
          this.logBookLength = -1;
        }

        return state.data;
      }),
      takeUntil(this.destroy)
    );
  }

  getEntries(): void {
    this.logbookActions.requestEntries();
  }
}
