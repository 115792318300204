import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { ApplicationState, IApplicationState, IFireStationList, IStoreApiItem, IStoreApiList } from 'src/app/common';
import { IBAWearer, IBAWearerPage } from '../../models';
import { IBAWearerState } from '../models/ba-wearer.model';

export const baWearerState = (state: IApplicationState): IBAWearerState => state.baWearer;

export const selectBAWearerPage: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IBAWearerPage>,
  DefaultProjectorFn<IStoreApiItem<IBAWearerPage>>
> = createSelector(baWearerState, (state: IBAWearerState) => state.baWearerPage);

export const selectBAWearer: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IBAWearer>,
  DefaultProjectorFn<IStoreApiItem<IBAWearer>>
> = createSelector(baWearerState, (state: IBAWearerState) => state.baWearer);

export const selectNewBAWearer: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IBAWearer>,
  DefaultProjectorFn<IStoreApiItem<IBAWearer>>
> = createSelector(baWearerState, (state: IBAWearerState) => state.newBAWearer);

export const selectUpdatedBAWearer: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IBAWearer>,
  DefaultProjectorFn<IStoreApiItem<IBAWearer>>
> = createSelector(baWearerState, (state: IBAWearerState) => state.updatedBAWearer);

export const selectDeletedBAWearer: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IBAWearer>,
  DefaultProjectorFn<IStoreApiItem<IBAWearer>>
> = createSelector(baWearerState, (state: IBAWearerState) => state.deletedBAWearer);

export const selectFireStationList: MemoizedSelector<
  ApplicationState,
  IStoreApiList<IFireStationList>,
  DefaultProjectorFn<IStoreApiList<IFireStationList>>
> = createSelector(baWearerState, (state: IBAWearerState) => state.fireStationList);

export const selectCSVFile: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<unknown>,
  DefaultProjectorFn<IStoreApiItem<unknown>>
> = createSelector(baWearerState, (state: IBAWearerState) => state.csv);
