import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { CommonConstants, MapService } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IEntryModel } from 'src/app/common/models/common.model';
import { checkDuration, formatLocaleTime } from 'src/app/common/utils/date-utils/date.utils';
import { environment } from 'src/environments/environment';
import { IMonitoringIncident } from '../../../../models/remote-monitoring.model';
import { RemoteMonitoringConstants } from './../../../../constants/remote-monitoring.constants';

@Component({
  selector: 'ignis-incident-tile',
  templateUrl: './incident-tile.component.html',
  styleUrls: ['./incident-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncidentTileComponent extends OnDestroyMixin() implements OnChanges, AfterViewInit {
  @Input() incidentData: IMonitoringIncident;
  @Input() formatDate: string;
  @Input() selectedIncidentIds: string[];
  @Input() numberOfSelectedIncidents: number;
  @Input() disableMonitorIncidentBtn: boolean;
  @Input() isModalOpened: boolean;
  selectedIncident: boolean = false;
  @Output() navigateToIncidentOverview: EventEmitter<string> = new EventEmitter();
  @Output() selectedIncidentToMerge: EventEmitter<IMonitoringIncident> = new EventEmitter();
  @Output() resetMergeProcess: EventEmitter<void> = new EventEmitter();

  incident: IMonitoringIncident;
  incidentTypes: Partial<IEntryModel>[];
  showAddressTooltip: boolean = false;
  apiKey: string = environment.GOOGLE_MAP.GOOGLE_API_KEY;
  darkTheme: string = RemoteMonitoringConstants.staticDarkTheme;
  showCoordinatesTooltip: boolean = false;
  showIncidentReportBtnTooltip: string;

  readonly splitArrayOfStringsRule: string = CommonConstants.splitArrayOfStringsRule;

  @ViewChild('topValue') topValue: ElementRef;
  isTextOverflow: boolean = false;
  zoomLevel: string = '15';
  imageSize: string = '351x110';
  currentAppTheme: string;

  constructor(
    public mapService: MapService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super();

    this.currentAppTheme = this.document.body.className.split(' ')[1];
  }

  ngOnChanges(): void {
    if (this.incidentData) {
      this.incident = structuredClone(this.incidentData);

      this.incident.start = formatLocaleTime(new Date(this.incident.startTime));
      this.incident.duration = checkDuration(this.incident.duration);
      this.incident.incidentImage = `https://maps.googleapis.com/maps/api/staticmap?center=${this.incident.location.gpsCoordinate.lat},${this.incident.location.gpsCoordinate.lng}&zoom=${this.zoomLevel}&size=${this.imageSize}&maptype=roadmap&style=${this.darkTheme}&key=${this.apiKey}`;

      const selectedIncidentsIds: string[] = JSON.parse(
        localStorage.getItem(RemoteMonitoringConstants.selectedIncidents),
      );

      if (selectedIncidentsIds && selectedIncidentsIds.includes(this.incident.aggregateId)) {
        this.selectedIncident = true;
      }
    }
  }

  ngAfterViewInit(): void {
    this.isTextOverflow = this.isEllipsisActive(this.topValue.nativeElement);

    window.addEventListener('resize', () => {
      this.isTextOverflow = this.isEllipsisActive(this.topValue.nativeElement);
    });
  }

  incidentOverviewNavigate(aggregateId: string): void {
    this.navigateToIncidentOverview.emit(aggregateId);
  }

  selectIncident(_aggregateId: string, incident: IMonitoringIncident): void {
    this.selectedIncidentToMerge.emit(incident);

    if (this.selectedIncidentIds && this.selectedIncidentIds.includes(this.incident.aggregateId)) {
      this.selectedIncident = true;
    }
  }

  onClickedOutside(isTrue: boolean): void {
    if (isTrue && this.selectedIncidentIds.length < 2) {
      this.selectedIncident = false;
      this.selectedIncidentToMerge.emit(null);
      this.resetMergeProcess.emit();
    }
  }

  getIncidentAddress(incident: IMonitoringIncident): string {
    const town: string = incident?.location?.town ? `${incident?.location?.town}` : '';
    const townComma: string = incident?.location?.streetInfo || incident?.location?.postcode ? ', ' : '';

    const streetInfo: string = incident?.location?.streetInfo ? `${incident?.location?.streetInfo}` : '';
    const streetInfoComma: string = incident?.location?.postcode ? ', ' : '';

    const postCode: string = incident?.location?.postcode ? `${incident?.location?.postcode}` : '';
    const postCodeComma: string = !incident?.location?.town && !incident?.location?.streetInfo ? ', ' : '';

    const location: string = this.mapService.convertToDMS(
      incident?.location.gpsCoordinate.lat,
      incident?.location.gpsCoordinate.lng,
      true,
    );

    if (!incident?.location?.town && !incident?.location?.streetInfo) {
      return `${postCode}${postCodeComma}${location}`;
    } else {
      return `${town}${townComma}${streetInfo}${streetInfoComma}${postCode}${postCodeComma}`;
    }
  }

  isEllipsisActive(e: HTMLElement): boolean {
    return e.offsetWidth < e.scrollWidth;
  }

  navigateToReportCenter(reportId: string): void {
    if (reportId) {
      this.router.navigate(['report-center', 'update', reportId]);
    }
  }
}
