// eslint-disable-next-line no-shadow
export enum LicensesActionType {
  REQUEST_LICENSE_STATUS = 'REQUEST_LICENSE_STATUS',
  RECEIVE_LICENSE_STATUS = 'RECEIVE_LICENSE_STATUS',
  ERROR_RECEIVE_LICENSE_STATUS = 'ERROR_RECEIVE_LICENSE_STATUS',
  RESET_LICENSE_STATUS = 'RESET_LICENSE_STATUS',

  REQUEST_ACTIVATE_TICKET_ID = 'REQUEST_ACTIVATE_TICKET_ID',
  RECEIVE_ACTIVATE_TICKET_ID = 'RECEIVE_ACTIVATE_TICKET_ID',
  ERROR_RECEIVE_ACTIVATE_ITCKET_ID = 'ERROR_RECEIVE_ACTIVATE_ITCKET_ID',
  RESET_ACTIVATE_TICKET_ID = 'RESET_ACTIVATE_TICKET_ID',
}
