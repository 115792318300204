import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { ProductActionType } from './product-action-types';

export class RequestProductName implements Action {
  readonly type: ProductActionType.REQUEST_PRODUCT_NAME = ProductActionType.REQUEST_PRODUCT_NAME;
}

export class ReceiveProductName implements Action {
  readonly type: ProductActionType.RECEIVE_PRODUCT_NAME = ProductActionType.RECEIVE_PRODUCT_NAME;

  constructor(public payload: any) { }
}

export class ErrorReceiveProductName implements Action {
  readonly type: ProductActionType.ERROR_RECEIVE_PRODUCT_NAME = ProductActionType.ERROR_RECEIVE_PRODUCT_NAME;

  constructor(public payload: HttpErrorResponse) { }
}
