import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ModalRef, ModalService } from '@odx/angular/components/modal';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { PropertyBag } from 'src/app/common/models/common.model';
import { ConfigurationConstants } from 'src/app/configuration/constants/configuration.constants';

@Component({
  selector: 'ignis-confirm-change-category-type-model',
  templateUrl: './confirm-change-category-type-model.component.html',
  styleUrls: ['./confirm-change-category-type-model.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmChangeCategoryTypeModelComponent extends OnDestroyMixin() implements OnChanges {
  @Input() isOpen: boolean = false;
  @Input() addedType: any;
  configType: PropertyBag = ConfigurationConstants.EquipmentConfigType;

  @Output() handleConfirmationModificationModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  modalReference: ModalRef<any>;

  @ViewChild('confirmChangeCatTypeModelModalTemplate', { read: TemplateRef })
  public confirmChangeCatTypeModelModalTemplate: TemplateRef<any>;

  constructor(
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpen && this.isOpen) {
      this.openModal();
    }
  }

  openModal(): void {
    this.modalReference = this.modalService.open(this.confirmChangeCatTypeModelModalTemplate, {
      size: 'small',
      dismissable: false,
    });
  }

  closeBanner(isConfirmed: boolean): void {
    this.handleConfirmationModificationModal.emit(isConfirmed);
    this.cdr.detectChanges();
  }
}
