export interface IEquipmentLicenseInfo {
  noAllowedAssets: number;
  noCreatedAssets: number;
}

export class EquipmentLicenseInfo implements IEquipmentLicenseInfo {
  noAllowedAssets: number;
  noCreatedAssets: number;

  constructor();
  constructor(licenseInfo: IEquipmentLicenseInfo);
  constructor(licenseInfo?: IEquipmentLicenseInfo) {
    if (!licenseInfo) {
      this.noAllowedAssets = null;
      this.noCreatedAssets = null;
    } else {
      this.noAllowedAssets = licenseInfo.noAllowedAssets;
      this.noCreatedAssets = licenseInfo.noCreatedAssets;
    }
  }
}
