<div class="row">
  <div class="lefside-incidents-list pe-0">
    <h5 class="back-btn">
      <button odxNavigationBack (click)="navigateBack()"></button>
      {{ 'REMOTE_MONITORING.STR_INCIDENT_OVERVIEW' | translate }}
    </h5>

    <h2 class="sidebar-title">
      <span class="incident-title">
        <ignis-incident-type-translation [entry]="selectedIncident?.type"></ignis-incident-type-translation>
        {{ selectedIncident?.location.streetInfo ? '- ' + selectedIncident?.location.streetInfo : '' }}
      </span>
      <span class="isHazmat" *ngIf="selectedIncident?.isHazmat">
        <odx-icon
          size="inline"
          name="toxic"
          [pTooltip]="'REMOTE_MONITORING.STR_HAZMAT_EXPOSURE_RECORDED' | translate"
          tooltipPosition="top"
          positionTop="-10"
          data-test-id="remote_monitoring.hazmat_exposure_recorder"
        ></odx-icon>
      </span>
    </h2>
    <ngx-simplebar [options]="scrollbarOptions" class="sidebar-scroll">
      <div class="ecp-list">
        <div *ngFor="let ecp of selectedIncident?.ecps; index as i; trackBy: trackByIdentifier">
          <div
            class="ecp-card"
            [ngClass]="{ 'active-ecp-card': !checkActiveEcp(ecp), 'custom-mr': scrollbarIsDisplayed }"
            (click)="onEcpCardClick($event, ecp)"
            data-test-id="remote_monitoring.ecp_card"
          >
            <div class="row">
              <div class="col-11 mt-1">
                <p>ECP {{ ecp.ecpNumber }}</p>
              </div>
              <div
                class="col-1 incident-status"
                (mouseenter)="showConnectionInformationTooltip[i] = true"
                (mouseleave)="showConnectionInformationTooltip[i] = false"
                data-test-id="remote_monitoring.connection_information_wrap"
                [pTooltip]="
                  !ecp.ended
                    ? ('REMOTE_MONITORING.LAST_INFORMATION_RECEIVED'
                      | translate: { time: dateFormat(ecp.lastUpdate, formatDate) })
                    : ('REMOTE_MONITORING.ECP_HAS_ENDED' | translate: { time: dateFormat(ecp.endTime, formatDate) })
                "
                tooltipPosition="right"
                positionLeft="5"
                tooltipStyleClass="right-tooltip"
              >
                <odx-icon *ngIf="ecp.ended" name="ok"></odx-icon>
                <div
                  *ngIf="!ecp.ended && !ecp.connectionLost"
                  class="hotspot hotspot--primary custom-hotspot-width"
                  data-test-id="remote_monitoring.connection_ok"
                >
                  <span class="hotspot__pulse"></span>
                  <span class="hotspot__dot"></span>
                </div>
                <div
                  *ngIf="!ecp.ended && ecp.connectionLost"
                  class="hotspot hotspot--critical custom-hotspot-width"
                  data-test-id="remote_monitoring.connection_lost"
                >
                  <span class="hotspot__pulse"></span>
                  <span class="hotspot__dot"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 app-and-hub-name mt-5">
                <odx-icon name="fg-hub" size="inline"></odx-icon>
                <span
                  #hub_name
                  [pTooltip]="!(hub_name.offsetWidth < hub_name.scrollWidth) ? null : ecp.hubName"
                  tooltipPosition="top"
                  positionTop="-10"
                  [attr.data-test-id]="ecp.hubName"
                >
                  {{ ecp.hubName ? ecp.hubName : '-' }}
                </span>
              </div>
              <div class="col-12 app-and-hub-name mt-3">
                <odx-icon name="tablet" size="inline"></odx-icon>
                <span
                  #app_name
                  [pTooltip]="!(app_name.offsetWidth < app_name.scrollWidth) ? null : ecp.appName"
                  tooltipPosition="top"
                  positionTop="-10"
                  [attr.data-test-id]="ecp.appName"
                >
                  {{ ecp.appName }}
                </span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12 col-xl-8">
                <div class="ecp-line">
                  <odx-icon name="user"></odx-icon>
                  <div class="ms-8" data-test-id="remote_monitoring.ecp_officer">
                    {{ ecp.ecpOfficer ? ecp.ecpOfficer : '-' }}
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="right-alignment-duration">
                  <odx-icon name="timer-elapsed"></odx-icon>
                  <div class="ecp-field-value" data-test-id="remote_monitoring.ecp_duration">
                    {{ getTime(ecp.duration, true) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3 address-helmet-line">
              <div class="col-12 col-xl-8">
                <div class="address-line">
                  <odx-icon class="location-icon-padding" name="location"></odx-icon>
                  <div class="ms-8">
                    <span *ngIf="ecp.location.town || ecp.location.postcode">
                      <span *ngIf="ecp.location.postcode" data-test-id="remote_monitoring.postcode">
                        {{ ecp.location.postcode }}<span *ngIf="ecp.location.town">, </span></span
                      >
                      {{ ecp.location.town }}
                    </span>
                    <span class="wrap-line" data-test-id="remote_monitoring.street_info">{{
                      ecp.location.streetInfo
                    }}</span>
                    <span class="same-line" data-test-id="remote_monitoring.gps_coordinates">{{
                      mapService.convertToDMS(ecp.location.gpsCoordinate.lat, ecp.location.gpsCoordinate.lng, true)
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-4 helmet-column">
                <div>
                  <odx-icon name="breathing-apparatus"></odx-icon>
                  <div
                    id="ecp-active-standby-value"
                    class="ecp-active-standby-value"
                    [pTooltip]="activeStandbyTpl"
                    tooltipPosition="top"
                    positionTop="-10"
                    data-test-id="remote_monitoring.firefighters_activity_status"
                  >
                    {{ ecp.deployedFirefighters || 0 }} / {{ ecp.standbyFirefightersNo || 0 }}
                  </div>
                </div>
              </div>
              <ng-template #activeStandbyTpl>
                {{
                  'INCIDENT_INFORMATION.STR_ACTIVE_STANDBY_FIREMEN_TOOLTIP'
                    | translate: { active: ecp.deployedFirefighters || 0, standby: ecp.standbyFirefightersNo || 0 }
                }}
              </ng-template>
            </div>
            <div class="row mt-4">
              <div class="col-12 text-end p-0">
                <button
                  odxButton
                  variant="highlight"
                  *ngIf="checkActiveMapPage(ecp); else mapView"
                  (click)="switchToMonitorTeams($event, ecp)"
                  [pTooltip]="ecp.ended && 'INCIDENT_INFORMATION.STR_REVIEW_TOOLTIP' | translate"
                  tooltipPosition="top"
                  positionTop="-10"
                  data-test-id="remote_monitoring.switch_to_monitor_teams_btn"
                >
                  <span *ngIf="!ecp.ended; else endedEcp" data-test-id="remote_monitoring.ended_incident">
                    {{ 'INCIDENT_INFORMATION.STR_MONITOR_TEAMS' | translate }}
                  </span>
                  <ng-template #endedEcp>
                    <span data-test-id="remote_monitoring.running_incident">
                      {{ 'INCIDENT_INFORMATION.STR_REVIEW_ECP' | translate }}
                    </span>
                  </ng-template>
                </button>
                <ng-template #mapView>
                  <button
                    odxButton
                    variant="highlight"
                    (click)="switchToMapView($event, ecp)"
                    data-test-id="remote_monitoring.map_view_btn"
                  >
                    {{ 'INCIDENT_INFORMATION.STR_MAP_VIEW' | translate }}
                  </button>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ngx-simplebar>
  </div>
  <div class="map-content ps-0">
    <ignis-map
      [selectedEcp]="selectedEcp"
      [ecpIdentifier]="ecpIdentifier"
      [selectedIncident]="selectedIncident"
      (handleSelectedMarker)="selectedMarkerOnMap()"
      (handleToDeselectECP)="deselectEcp()"
      (handleThemeChanged)="ngAfterViewInit()"
      data-test-id="remote_monitoring.google_map"
      *ngIf="!router.url.includes(RemoteMonitoringModuleRoutes.monitorTeams); else monitorTeams"
    ></ignis-map>

    <ng-template #monitorTeams>
      <router-outlet></router-outlet>
    </ng-template>

    <div
      class="notes-widget-btn"
      (click)="openNotes()"
      data-test-id="remote_monitoring.open_notes_btn"
      *ngIf="!openNotesWidget && !router.url?.includes(RemoteMonitoringModuleRoutes.monitorTeams)"
      [ngClass]="{
        'slide-in-right': !openNotesWidget,
      }"
      [class]="currentAppTheme"
    >
      <div
        class="hotspot hotspot--warning notification-dot"
        *ngIf="newNotesNumber > 0"
        data-test-id="remote_monitoring.new_notes_indicator"
      >
        <span class="hotspot__pulse"></span>
        <span class="hotspot__dot"></span>
        <span class="notes-number">{{ newNotesNumber }}</span>
      </div>
      <odx-icon size="inline" name="comment"></odx-icon>
    </div>
  </div>

  <ignis-notes-widget
    *ngIf="!router.url?.includes(RemoteMonitoringModuleRoutes.monitorTeams)"
    [openNotesWidget]="openNotesWidget"
    [notes]="notes"
    [incidentId]="incidentId"
    [incidentVersion]="incidentVersion"
    [formatDate]="formatDate"
    [incident]="selectedIncident?.aggregateId"
    (handleCloseNotesWidget)="closeNotes($event)"
    (handleNewNotesNumber)="getNewNotesNumber($event)"
    (handleAddNewNote)="getIncident()"
  ></ignis-notes-widget>
</div>
