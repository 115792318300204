import { EntryModel } from 'src/app/common/models/common.model';

export interface ILogbookPage {
  currentPage: number;
  entries: ILogbook[];
  pageSize: number;
  totalPages: number;
  totalRecords: number;
}

export class LogbookPage {
  currentPage: number;
  entries: ILogbook[];
  pageSize: number;
  totalPages: number;
  totalRecords: number;

  constructor();
  constructor(logbookPage: ILogbookPage);
  constructor(logbookPage?: ILogbookPage) {
    if (!logbookPage) {
      this.currentPage = null;
      this.entries = null;
      this.pageSize = null;
      this.totalPages = null;
      this.totalRecords = null;
    } else {
      this.currentPage = logbookPage.currentPage;
      this.entries = logbookPage.entries.map((logbook: ILogbook) => new Logbook(logbook));
      this.pageSize = logbookPage.pageSize;
      this.totalPages = logbookPage.totalPages;
      this.totalRecords = logbookPage.totalRecords;
    }
  }
}

export interface ILogbook {
  aggregateId: string;
  correlationId: string;
  equipmentId: string;
  equipmentModel: string;
  date: string | any;
  time?: string;
  startPressure: number;
  endPressure: number;
  reason: any;
  name: string;
  personalId: string;
  isManualImported?: boolean;
  incidentNumber: string;
  statuses: string[] | any;
  incidentSpecialExposure: string;
  incidentType: string;
  cylinderRatedPressure: number;
  usageDuration: string;
}

export class Logbook implements ILogbook {
  aggregateId: string;
  correlationId: string;
  equipmentId: string;
  equipmentModel: string;
  date: string;
  startPressure: number;
  endPressure: number;
  reason: any;
  name: string;
  personalId: string;
  incidentNumber: string;
  statuses: string;
  incidentSpecialExposure: string;
  incidentType: string;
  cylinderRatedPressure: number;
  usageDuration: string;

  constructor();
  constructor(logbook: ILogbook);
  constructor(logbook?: ILogbook) {
    if (!logbook) {
      this.aggregateId = null;
      this.correlationId = null;
      this.equipmentId = null;
      this.equipmentModel = null;
      this.date = null;
      this.startPressure = 0;
      this.endPressure = 0;
      this.reason = null;
      this.name = null;
      this.personalId = null;
      this.incidentNumber = null;
      this.statuses = null;
      this.incidentSpecialExposure = null;
      this.incidentType = null;
      this.cylinderRatedPressure = null;
      this.usageDuration = null;
    } else {
      this.aggregateId = logbook.aggregateId;
      this.correlationId = logbook.correlationId;
      this.equipmentId = logbook.equipmentId;
      this.equipmentModel = logbook.equipmentModel;
      this.date = logbook.date;
      this.startPressure = logbook.startPressure;
      this.endPressure = logbook.endPressure;
      this.reason = { value: logbook.reason };
      this.name = logbook.name;
      this.personalId = logbook.personalId;
      this.incidentNumber = logbook.incidentNumber;
      this.statuses = logbook.statuses;
      this.incidentSpecialExposure = logbook.incidentSpecialExposure;
      this.incidentType = logbook.incidentType;
      this.cylinderRatedPressure = logbook.cylinderRatedPressure;
      this.usageDuration = logbook.usageDuration;
    }
  }
}

export interface IEntriesModel {
  entryReasonList: any[];
  maxStartPressure: number;
  minStartPressure: number;
  maxEndPressure: number;
  minEndPressure: number;
}

export class EntriesModel implements IEntriesModel {
  entryReasonList: any[];
  maxStartPressure: number;
  minStartPressure: number;
  maxEndPressure: number;
  minEndPressure: number;

  constructor();
  constructor(entries: IEntriesModel);
  constructor(entries?: IEntriesModel) {
    if (!entries) {
      this.entryReasonList = null;
      this.maxStartPressure = null;
      this.minStartPressure = null;
      this.maxEndPressure = null;
      this.minEndPressure = null;
    } else {
      this.entryReasonList = entries.entryReasonList?.map((entry: string) => new EntryModel({ value: entry }));
      this.maxStartPressure = entries.maxStartPressure;
      this.minStartPressure = entries.minStartPressure;
      this.maxEndPressure = entries.maxEndPressure;
      this.minEndPressure = entries.minEndPressure;
    }
  }
}
