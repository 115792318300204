import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import distance from '@turf/distance';
import { point } from '@turf/helpers';
import { set } from 'date-fns';
import filter from 'lodash-es/filter';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { Observable } from 'rxjs';
import { map, filter as rxjsFilter, take, takeUntil } from 'rxjs/operators';
import { NotificationConstants } from 'src/app/common/constants/notification.constants';
import { TableExportExcelConstants } from 'src/app/common/constants/table-excel-export.constants';
import { EntryModel, IEntryModel, PropertyBag } from 'src/app/common/models/common.model';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { ITableColumn, ITableSettings, ITableSettingsResponse } from 'src/app/common/models/table.model';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { IApplicationState } from 'src/app/common/state/models/app.state.model';
import { checkDuration, formatLocaleTime } from 'src/app/common/utils/date-utils/date.utils';
import { AppModulesTypes } from 'src/app/root/models/app-types';
import { IncidentNotificationConstants } from '../../constants';
import { ReportCenterConstants } from '../../constants/report-center.constants';
import { IEntriesModel, IIncident, IIncidentExcelData, IIncidentPage, ILocation } from '../../models/incident.model';
import { IncidentService } from '../../services/incident.service';
import { IncidentActions } from '../../state/actions/incident.actions';
import { selectTableColumns } from './../../../settings/state/selectors/settings.selector';
import { ExportExcelService } from './../../services/export-excel.service';
import { tableColumns } from './table-columns';
import { readModelSortKeys, tableDefaultSorting } from './table-settings';

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  CommonConstants,
  DropdownService,
  IStoreApiList,
  StorageConstants,
  TableConstants,
  TableHelperComponent,
} from 'src/app/common';
import {
  checkMergeErrorNotification,
  formatAddressFromLocation,
  sliderInputValueChange,
} from 'src/app/common/utils/general-helper/general-helpers';
import {
  selectIncident,
  selectIncidentExcelData,
  selectIncidentPage,
  selectMergeIncidents,
} from '../../state/selectors/incident.selector';

@Component({
  selector: 'ignis-report-center-list',
  templateUrl: './report-center-list.component.html',
  styleUrls: ['./report-center-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportCenterListComponent extends TableHelperComponent implements OnInit, OnChanges, AfterViewInit {
  incidentPage: IIncidentPage;
  isLoading: boolean;
  @Input() formatDate: string;
  @Input() entries: IEntriesModel;
  @Input() pressureDisplayUnit: string;

  rowsPerPageOptions: number[] = TableConstants.rowsPerPageOptions;
  tableName: string = AppModulesTypes.reportCenter;
  defaultSorting: string = tableDefaultSorting;
  tableFiltersKey: string = StorageConstants.tablesStorageKeys.REPORT_CENTER;
  localColumns: ITableColumn[] = tableColumns;

  incidentTypes: IEntryModel[];
  incidentList: IIncident[];
  loading: boolean = true;
  defaultRange: any;
  dateFilters: any;

  selectedIncident: Observable<IIncident>;
  activeIncident: IIncident;

  sliderValues: number[] = [0, 0];
  sliderMinValue: number = 0;
  sliderMaxValue: number = 0;
  withheldReportsNo: number;

  @ViewChild('dt2')
  public tableRef: Table | any = null;

  selectedIncidents: IIncident[];
  selectionMode: string = 'single';
  activateEditBtn: boolean = true;
  displayMergeError: boolean = false;
  openMergeIncidents: boolean = false;

  hubIdsList: any[] = [];
  selectedHubIds: any[];

  selectedTypes: any[];
  selectedDurations: any[];
  selectedOccurredEvents: any[];

  params: any;
  incidentExcelData: Observable<IIncidentExcelData>;
  tableIncidentsToBeExported: IIncident[];
  excelTranslationKeys: PropertyBag = TableExportExcelConstants.reportCenterSheetTranslationKeys;

  exportExcelService: ExportExcelService = inject(ExportExcelService);
  dropdownService: DropdownService = inject(DropdownService);
  translateService: TranslateService = inject(TranslateService);
  notificationsService: NotificationsService = inject(NotificationsService);
  incidentActions: IncidentActions = inject(IncidentActions);
  incidentService: IncidentService = inject(IncidentService);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  errors: any;
  lastTableElement: any;
  ecpNameCharactersToShow: number = 20;
  hubIdsCharactersToShow: number = 17;

  readModelSortKeys: PropertyBag = readModelSortKeys;

  constructor(
    private router: Router,
    private store: Store<IApplicationState>,
  ) {
    super();
    this.selectedIncidents = [];
  }

  ngOnChanges(): void {
    if (this.entries) {
      this.sliderMinValue = this.entries.minDeployedFirefighters;
      this.sliderMaxValue = this.entries.maxDeployedFirefighters;
      this.sliderValues =
        this.tableHelperSavedFilters?.firefighterNo?.length > 0
          ? this.tableHelperSavedFilters?.firefighterNo
          : [this.sliderMinValue, this.sliderMaxValue];
      this.withheldReportsNo = this.entries.withheldReportsNo;

      if (this.hubIdsList.length < 1) {
        this.entries.hubIds?.forEach((elem: string) => {
          this.hubIdsList.push({ name: elem, telemetryAddress: elem });
        });
      }

      this.cdr.detectChanges();
    }
  }

  ngOnInit(): void {
    this.setTableColumns();

    this.store
      .pipe(
        select(selectIncidentPage),
        map((state: IStoreApiItem<IIncidentPage>) => state.data),
        takeUntil(this.destroy),
      )
      .subscribe((response: IIncidentPage) => {
        this.incidentPage = response;

        if (this.incidentPage) {
          this.processingIncidentList();
        }

        this.getReportCenterDateFilter();

        this.cdr.detectChanges();
      });

    this.selectedIncident = this.store.pipe(
      select(selectIncident),
      map((state: IStoreApiItem<IIncident>) => state.data),
      takeUntil(this.destroy),
    );

    this.store
      .pipe(
        select(selectIncidentPage),
        takeUntil(this.destroy),
        map((state: IStoreApiItem<IIncidentPage>) => state.isLoading),
      )
      .subscribe((response: boolean) => {
        this.isLoading = response;
        this.cdr.detectChanges();
      });

    this.displayIncidentsMergeNotification();
    this.readExcelData();

    this.selectedTypes = this.tableHelperSavedFilters?.type;
    this.selectedHubIds = this.tableHelperSavedFilters?.selectedHubIds;
    this.selectedDurations = this.tableHelperSavedFilters?.duration;
    this.selectedOccurredEvents = this.tableHelperSavedFilters?.selectedOccurredEvents;
  }

  ngAfterViewInit(): void {
    this.tableService.arrangePaginatingOfElement(this.tableRef);

    this.cdr.detectChanges();
  }

  displayIncidentsMergeNotification(): void {
    this.store.pipe(select(selectMergeIncidents), takeUntil(this.destroy)).subscribe((response: any) => {
      checkMergeErrorNotification(response, this.notificationsService);

      if (response.isSuccess) {
        this.notificationsService.requestShowNotification(
          CommonConstants.notificationType.SUCCESS,
          IncidentNotificationConstants.notificationCodes.INCIDENTS_MERGE_SUCCESS,
          IncidentNotificationConstants.notificationCodes,
        );
        this.prepareTableParameters();
      }

      this.cdr.detectChanges();
    });
  }

  readExcelData(): void {
    this.incidentExcelData = this.store.select(selectIncidentExcelData).pipe(
      rxjsFilter(
        (incidentExcelData: IStoreApiList<IIncidentExcelData | any>) =>
          !incidentExcelData.isLoading && !incidentExcelData.errors && !!incidentExcelData.data,
      ),
      takeUntil(this.destroy),
      map((incidentExcelData: IStoreApiList<IIncidentExcelData>) => {
        return incidentExcelData.data;
      }),
    );
  }

  setTableColumns(): void {
    this.settingsActions.requestTableColumns(this.tableName);

    this.store
      .pipe(
        select(selectTableColumns),
        rxjsFilter((state: IStoreApiItem<ITableSettingsResponse>) => !state.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((columnsSettings: IStoreApiItem<ITableSettingsResponse>) => {
        if (columnsSettings.data) {
          if (columnsSettings.data.status === TableConstants.NO_CONTENT_STATUS_CODE) {
            this.handleNoSavedColumns();

            return;
          }

          this.handleSavedColumns(columnsSettings.data.body);
          this.prepareTableParameters();
        }

        if (
          columnsSettings.errors &&
          columnsSettings.errors.error.code ===
            Number(NotificationConstants.commonCodes.TABLE_COLUMN_SETTINGS_ERROR.value)
        ) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            NotificationConstants.commonCodes.TABLE_COLUMN_SETTINGS_ERROR,
            NotificationConstants.commonCodes,
          );

          this.handleNoSavedColumns();
        }

        this.cdr.detectChanges();
      });
  }

  handleNoSavedColumns(): void {
    this.tableColumns = this.localColumns;
    this.lastTableElement = this.tableService.lastTableHeaderElem(this.tableColumns);

    this.prepareTableParameters();
  }

  handleSavedColumns(columnsSettings: ITableSettings): void {
    this.rows = columnsSettings.pageSize || CommonConstants.defaultTableRowsNumber;

    const responseData: ITableColumn[] = this.tableService.orderTableColumns(columnsSettings, this.localColumns);

    this.tableColumns = filter(responseData, (col: ITableColumn) => 'field' in col);
    this.lastTableElement = this.tableService.lastTableHeaderElem(this.tableColumns);
  }

  changeColumnVisibility(column: ITableColumn): void {
    this.tableService.toggleColumn(column, this.tableColumns);
    this.lastTableElement = this.tableService.lastTableHeaderElem(this.tableColumns);
    this.tableColumns = [...this.tableService.tableColumns];
    this.tableHelperResetTablePaging();

    if (!column.visible) {
      this.resetFilterForHiddenColumn(column);
    }

    this.tableHelperSaveTableSettings();

    this.selectedDurations = this.tableHelperSavedFilters?.duration ? this.tableHelperSavedFilters.duration : null;
    this.selectedTypes = this.tableHelperSavedFilters?.type ? this.tableHelperSavedFilters.type : null;
    this.selectedHubIds = this.tableHelperSavedFilters?.selectedHubIds
      ? this.tableHelperSavedFilters.selectedHubIds
      : null;
    this.selectedOccurredEvents = this.tableHelperSavedFilters?.selectedOccurredEvents
      ? this.tableHelperSavedFilters.selectedOccurredEvents
      : null;
  }

  resetFilterForHiddenColumn(column: ITableColumn): void {
    if (this.tableHelperCheckForSorting(column.field)) {
      this.tableHelperResetTableSorting();
    }

    switch (column.field) {
      case 'startTime':
        this.filtersData.startTime = null;
        break;
      case 'type.value':
        this.filtersData.type = null;
        this.selectedTypes = null;
        break;
      case 'hubIds':
        this.filtersData.selectedHubIds = null;
        this.selectedHubIds = null;
        break;
      case 'occurredEvents':
        this.filtersData.selectedOccurredEvents = null;
        this.selectedOccurredEvents = null;
        break;
      case 'duration':
        this.filtersData.duration = null;
        this.selectedDurations = null;
        break;
      default:
        this.filtersData[column.field] = null;
        break;
    }

    this.tableHelperSetAllTableFilters(this.filtersData);

    this.prepareTableParameters();
  }

  fetchTableData(params?: any): void {
    this.incidentActions.requestIncidentPage(params, this.isExportMode);
  }

  onColReorder(event: any): void {
    this.tableColumns = this.tableService.reorderColumns(event, this.tableColumns);
    this.lastTableElement = this.tableService.lastTableHeaderElem(this.tableColumns);

    this.tableHelperSaveTableSettings();
  }

  onColResize(event: any): void {
    const colIndex: number = event.element.cellIndex;
    const colWidth: number = event.element.clientWidth;

    if (this.tableColumns[colIndex].field === 'ecpNames') {
      this.ecpNameCharactersToShow = colWidth / 10;
    } else if (this.tableColumns[colIndex].field === 'hubIds') {
      this.hubIdsCharactersToShow = colWidth / 14;
    }
  }

  prepareTableParameters(): void {
    this.extraFilters = {
      ...this.tableStartTimeParameter(this.tableHelperSavedFilters?.startTime),
      ...this.tableEndTimeParameter(this.tableHelperSavedFilters?.startTime),
      ...this.tableIncidentNumberParameter(),
      ...this.tableIncidentTypeParameter(),
      ...this.tableIncidentDurationsParameter(),
      ...this.tableIncidentLocationParameter(),
      ...this.tableIncidentEcpNamesParameter(),
      ...this.tableIncidentEcpAddressesParameter(),
      ...this.tableMinFirefightersNoParameter(),
      ...this.tableMaxFirefightersNoParameter(),
      ...this.tableIncidentOccurredEventsParameter(),
    };

    this.tableHelperPrepareTableParameters();
  }

  onFilter(field: string, value: any): void {
    this.tableHelperResetTablePaging();
    this.filtersData[field] = typeof value === 'string' ? value?.trimStart()?.trimEnd() : value;

    this.tableHelperSetAllTableFilters(this.filtersData);
    this.prepareTableParameters();
  }

  clearFilter(column: string): void {
    this.onFilter(column, null);
  }

  tableIncidentDurationsParameter(): any {
    if (this.tableHelperSavedFilters?.duration?.length > 0) {
      const durations: any = this.tableHelperSavedFilters.duration.map((duration: any) => {
        return {
          minDuration: duration.minDuration,
          maxDuration: duration.timestamp,
        };
      });

      const minDurations: string[] = durations.map((duration: any) => duration.minDuration).toString();
      const maxDurations: string[] = durations.map((duration: any) => duration.maxDuration).toString();

      return {
        minDurations,
        maxDurations,
      };
    }

    return null;
  }

  tableStartTimeParameter(timeInterval: any[]): any {
    if (!timeInterval) return null;

    const startOfDay: Date = new Date(timeInterval[0]);

    return timeInterval?.length > 0 ? { startTime: startOfDay.toISOString() } : null;
  }

  tableEndTimeParameter(timeInterval: any[]): any {
    if (!timeInterval) return null;

    const endOfDay: Date = set(new Date(timeInterval[1]), { hours: 23, minutes: 59, seconds: 59 });

    return timeInterval?.length > 0 && timeInterval[1]
      ? {
          endTime: endOfDay.toISOString(),
        }
      : null;
  }

  tableIncidentNumberParameter(): any {
    return this.tableHelperSavedFilters?.incidentNumber?.length > 0
      ? { incidentNumber: this.tableHelperSavedFilters?.incidentNumber }
      : null;
  }

  tableIncidentTypeParameter(): any {
    return this.tableHelperSavedFilters?.type?.length > 0
      ? { incidentTypes: this.tableHelperSavedFilters?.type }
      : null;
  }

  tableIncidentLocationParameter(): any {
    return this.tableHelperSavedFilters?.ecpAddresses?.length > 0
      ? { ecpAddresses: this.tableHelperSavedFilters?.ecpAddresses }
      : null;
  }

  tableIncidentEcpNamesParameter(): any {
    return this.tableHelperSavedFilters?.ecpNames?.length > 0
      ? { ecpNames: this.tableHelperSavedFilters?.ecpNames }
      : null;
  }

  tableIncidentEcpAddressesParameter(): any {
    const newArr: string[] = [];

    this.tableHelperSavedFilters?.selectedHubIds?.forEach((ecp: any) => {
      newArr.push(ecp.telemetryAddress);
    });

    return this.tableHelperSavedFilters?.selectedHubIds?.length > 0 ? { hubIds: newArr.toString() } : null;
  }

  tableMinFirefightersNoParameter(): any {
    return this.tableHelperSavedFilters?.firefighterNo?.length > 0
      ? { minFirefighterNo: this.tableHelperSavedFilters?.firefighterNo[0] }
      : null;
  }

  tableMaxFirefightersNoParameter(): any {
    return this.tableHelperSavedFilters?.firefighterNo?.length > 0
      ? { maxFirefighterNo: this.tableHelperSavedFilters?.firefighterNo[1] }
      : null;
  }

  tableIncidentOccurredEventsParameter(): any {
    const newArr: string[] = [];

    this.tableHelperSavedFilters?.selectedOccurredEvents?.forEach((ecp: any) => {
      newArr.push(ecp.value);
    });

    return this.tableHelperSavedFilters?.selectedOccurredEvents?.length > 0
      ? { occurredEvents: newArr.toString() }
      : null;
  }

  onRowSort(event: { field: string; order: number }): void {
    this.tableHelperOnChangeSort(event);
    this.tableHelperResetTablePaging();
    this.prepareTableParameters();
  }

  onPageChangeLocal(event: { first: number; rows: number }): void {
    if (this.rows === event.rows) {
      this.tableHelperOnPageChange({ page: event.first / event.rows, rows: this.rows });
    } else {
      this.rows = event.rows;
      this.tableHelperResetTablePaging();
      this.tableHelperSaveTableSettings();
      this.prepareTableParameters();
    }

    this.onSelectIncident(null);
  }

  loadData(event: LazyLoadEvent): void {
    this.tableHelperOnLazyLoading(event);

    if (event.rows !== this.rows) {
      this.tableRef._first = 0;
    }
  }

  firstItemChange(): void {
    this.tableRef.first = this.first;
    this.tableRef._first = this.first;
  }

  /* istanbul ignore next */
  formatLocation(location: ILocation): string {
    return formatAddressFromLocation(location);
  }

  getReportCenterDateFilter(): void {
    if (
      this.tableHelperSavedFilters &&
      Object.prototype.hasOwnProperty.call(this.tableHelperSavedFilters, 'startTime')
    ) {
      if (this.tableHelperSavedFilters.startTime) {
        this.defaultRange = [
          new Date(this.tableHelperSavedFilters.startTime[0]),
          new Date(this.tableHelperSavedFilters.startTime[1]),
        ];
        this.dateFilters = this.defaultRange;
      } else {
        this.defaultRange = null;
        this.dateFilters = null;
      }
    } else {
      const today: Date = new Date();
      const weekAgo: Date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);

      this.defaultRange = [weekAgo, today];
      this.dateFilters = this.defaultRange;
      this.onFilter('startTime', this.dateFilters);

      this.tableStartTimeParameter(this.dateFilters);
      this.tableEndTimeParameter(this.dateFilters);
      this.prepareTableParameters();
    }

    this.cdr.detectChanges();
  }

  processingIncidentList(): void {
    this.totalRecords = this.incidentPage.totalRecords;
    this.totalPages = this.incidentPage.totalPages;

    this.tableHelperCheckOutOfRangePageFilter();

    this.incidentList = structuredClone(this.incidentPage.entries);

    let hubIds: string[] = [];

    this.incidentList.forEach((incident: IIncident) => {
      incident.startHour = formatLocaleTime(new Date(incident.startTime));
      incident.formattedDuration = checkDuration(incident.duration);
      incident.primaryEcp.appId = incident.primaryEcp.appId ? incident.primaryEcp.appId : '-';
      hubIds = [...new Set(hubIds.concat(incident.hubIds))];
      incident.occurredEvents = incident.occurredEvents.length < 1 ? ['-'] : incident.occurredEvents;
    });

    const index: number = hubIds.indexOf('');

    if (index !== -1) {
      hubIds[index] = '-';
    }

    this.cdr.detectChanges();
  }

  truncateData(source: string, size: number): string {
    return source?.length > size ? source.slice(0, size - 1) + ' [...]' : source;
  }

  composeListTooltip(array: string[]): string {
    let stringToReturn: string = '';

    array.forEach((item: string) => (stringToReturn = stringToReturn + (item ? item : '-') + '\n'));

    return stringToReturn;
  }

  onSelectIncident(incident: IIncident): void {
    this.activeIncident = incident;
    this.incidentActions.selectIncident(incident);

    if (this.activeIncident) {
      this.selectedIncidents.push(this.activeIncident);

      if (this.selectedIncidents.length === 2 && this.selectionMode === 'multiple') {
        this.checkIfIncidentsCanBeMerged();
      }
    }

    if (this.displayMergeError) {
      const firstIncident: IIncident = this.selectedIncidents[0];

      this.activeIncident = firstIncident;
      this.selectedIncidents = [this.activeIncident];
    }

    if (!incident && !this.openMergeIncidents) {
      this.displayMergeError = false;
    }

    if (!this.activeIncident && !this.displayMergeError && !this.openMergeIncidents) {
      this.activeIncident = null;
      this.selectedIncidents = [];
      this.activateEditBtn = true;
      this.selectionMode = 'single';
    }
  }

  checkIfIncidentsCanBeMerged(): void {
    let distanceBetweenECPS: number;

    if (
      this.selectedIncidents[0].primaryEcp.location.coordinates.lng &&
      this.selectedIncidents[0].primaryEcp.location.coordinates.lat &&
      this.selectedIncidents[1].primaryEcp.location.coordinates.lng &&
      this.selectedIncidents[1].primaryEcp.location.coordinates.lat
    ) {
      const firstIncidentPoint: any = point([
        this.selectedIncidents[0].primaryEcp.location.coordinates.lng,
        this.selectedIncidents[0].primaryEcp.location.coordinates.lat,
      ]);
      const secondIncidentPoint: any = point([
        this.selectedIncidents[1].primaryEcp.location.coordinates.lng,
        this.selectedIncidents[1].primaryEcp.location.coordinates.lat,
      ]);

      distanceBetweenECPS = distance(firstIncidentPoint, secondIncidentPoint);
    } else {
      distanceBetweenECPS = -1;
    }

    const firstIncidentStartTime: Date = new Date(this.selectedIncidents[0].startTime);
    const firstIncidentEndTime: Date = new Date(
      new Date(this.selectedIncidents[0].startTime).getTime() + this.selectedIncidents[0].duration * 1000,
    );

    const secondIncidentStartTime: Date = new Date(this.selectedIncidents[1].startTime);
    const secondIncidentEndTime: Date = new Date(
      new Date(this.selectedIncidents[1].startTime).getTime() + this.selectedIncidents[1].duration * 1000,
    );

    if (
      distanceBetweenECPS <= 10 &&
      this.canBeMerged(firstIncidentStartTime, firstIncidentEndTime, secondIncidentStartTime, secondIncidentEndTime) &&
      this.selectedIncidents[0].aggregateId !== this.selectedIncidents[1].aggregateId
    ) {
      this.openMergeIncidents = true;
    } else if (
      this.selectedIncidents[0].aggregateId === this.selectedIncidents[1].aggregateId ||
      distanceBetweenECPS > 10
    ) {
      this.displayMergeError = false;
      this.activeIncident = null;
    }

    if (distanceBetweenECPS > 10) {
      this.notificationsService.requestShowNotification(
        CommonConstants.notificationType.ERROR,
        IncidentNotificationConstants.notificationCodes.INCIDENTS_MERGE_DISTANCE_ERROR,
        IncidentNotificationConstants.notificationCodes,
      );
      this.displayMergeError = true;
    }

    if (this.selectedIncidents[0].aggregateId === this.selectedIncidents[1].aggregateId) {
      this.notificationsService.requestShowNotification(
        CommonConstants.notificationType.WARNING,
        IncidentNotificationConstants.notificationCodes.INCIDENTS_MERGE_SAME_INCIDENT_ERROR,
        IncidentNotificationConstants.notificationCodes,
      );
      this.displayMergeError = true;
    }

    if (
      !this.canBeMerged(firstIncidentStartTime, firstIncidentEndTime, secondIncidentStartTime, secondIncidentEndTime)
    ) {
      this.notificationsService.requestShowNotification(
        CommonConstants.notificationType.ERROR,
        IncidentNotificationConstants.notificationCodes.INCIDENTS_MERGE_TIME_ERROR,
        IncidentNotificationConstants.notificationCodes,
      );
      this.displayMergeError = true;
    }
  }

  sliderInputChange(event: any, type: string): void {
    this.sliderValues = sliderInputValueChange(event, type, this.sliderValues, this.sliderMaxValue);

    this.onFilter('firefighterNo', this.sliderValues);
  }

  onDateSelect(event: any): void {
    this.dateFilters = event;

    setTimeout(() => {
      if (!event) {
        this.onFilter('startTime', null);
      }

      if (this.dateFilters && this.dateFilters[1]) {
        this.onFilter('startTime', event);
        this.prepareTableParameters();
      }
    }, 250);
  }

  onDateChange(event: any): void {
    if (!event) {
      this.onFilter('startTime', null);
    }

    if (this.dateFilters?.length > 0) {
      this.dateFilters = null;
      this.onFilter('startTime', event);
      this.prepareTableParameters();
    }
  }

  navigateToUpdateIncident(): void {
    this.selectedIncident.pipe(take(1)).subscribe((selectedIncident: IIncident) => {
      const selectedIncidentId: string = selectedIncident.aggregateId;

      this.router.navigate([AppModulesTypes.reportCenter, 'update', selectedIncidentId]);
      this.activeIncident = null;
      this.cdr.detectChanges();
    });
  }

  initMerge(): void {
    if (!this.activateEditBtn) {
      this.activateEditBtn = true;
      this.selectionMode = 'single';
    } else {
      this.activateEditBtn = false;
      this.selectionMode = 'multiple';
    }

    const lastIncident: IIncident = [...this.selectedIncidents].pop();

    this.selectedIncidents = [];
    this.selectedIncidents.push(lastIncident);
  }

  canBeMerged(firstStartTime: Date, firstEndTime: Date, secondStartTime: Date, secondEndTime: Date): boolean {
    const msToH: number = 1000 * 60 * 60;
    const overlappingHours: number = Math.min(
      (firstEndTime.getTime() - firstStartTime.getTime()) / msToH,
      (firstEndTime.getTime() - secondStartTime.getTime()) / msToH,
      (secondEndTime.getTime() - firstStartTime.getTime()) / msToH,
      (secondEndTime.getTime() - secondStartTime.getTime()) / msToH,
    );

    return overlappingHours > 0 || Math.abs(overlappingHours) <= 48;
  }

  cancelMergeIncidents(event: boolean): void {
    if (!event) {
      this.openMergeIncidents = false;
      this.selectedIncidents = [];
    }
  }

  exportExcel(selectedIncident: IIncident): void {
    const incidentId: string = selectedIncident.aggregateId;

    this.incidentActions.requestIncidentExcelData(incidentId);

    this.incidentExcelData.pipe(take(1)).subscribe((response: IIncidentExcelData) => {
      this.exportExcelService.translatedIncidentSheetName = this.translateService.instant(
        'INCIDENT_EXCEL.STR_INCIDENT_SHEET_NAME',
      ) as string;
      this.exportExcelService.pressureDisplayUnit = this.pressureDisplayUnit;
      this.exportExcelService.exportIncidentExcel(response, this.formatDate, (onProgress: boolean) => {
        this.isLoading = onProgress;
        this.cdr.detectChanges();
      });
    });
  }

  /* istanbul ignore next */
  clear(table: Table): void {
    table.clear();
  }

  exportExcelFile(): void {
    this.isExportMode = true;
    this.exportExcelService.genericExport(this.getDataForExport, this.preparationForExport).then((data: unknown[]) => {
      this.excelArray = data;
      this.isExportMode = false;

      this.cdr.detectChanges();
    });
  }

  preparationForExport = (incident: IIncident | any): void => {
    const translatedOccurredEvents: unknown[] = [];

    incident.startHour = formatLocaleTime(new Date(incident.startTime));
    incident.startTime = new DatePipe('en-GB').transform(incident.startTime, this.formatDate);

    if (incident.type?.value) {
      incident['type.value'] = this.translateService.instant(
        ReportCenterConstants.incidentEntries.types.find((t: EntryModel) => t.value === incident.type.value)
          ?.localizedName || incident.type.value,
      );
    }

    incident.duration = checkDuration(incident.duration);
    incident.ecpAddresses = this.formatLocation(incident.primaryEcp?.location);
    incident.ecpNames = incident.ecpNames?.toString().replaceAll(',', ', ');
    incident.hubIds = incident.hubIds.map((address: string) => {
      if (!address) {
        return '-';
      }

      return address;
    });
    incident.hubIds = incident.hubIds?.toString().replaceAll(',', ', ');

    if (incident.occurredEvents.length < 1) {
      incident.occurredEvents = this.translateService.instant('REPORT_CENTER_TABLE.NO_NOTEWORTHY_EVENTS');
    } else {
      incident.occurredEvents.forEach((event: string) => {
        translatedOccurredEvents.push(this.incidentService.translateOccurredEvents(event));
      });

      incident.occurredEvents = translatedOccurredEvents.toString().replaceAll(',', ', ');
    }
  };

  getDataForExport = (page: number = 0): void => {
    this.tableHelperPrepareTableParameters(page);
  };

  excelExportDone(): void {
    this.tableIncidentsToBeExported = null;
    this.tableHelperExcelExportDone();
  }
}
