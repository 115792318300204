@if (!router?.url?.includes(AppModuleRoutes.internalServerError)) {
  <odx-header>
    <button odxButton odxMainMenuButton (click)="openSidebarHandler()" data-test-id="navbar.open_sidebar">
      <odx-icon [iconSet]="'core'" name="menu"></odx-icon>
    </button>
    <odx-header-title>{{ titleService.getTitle() }}</odx-header-title>
    <odx-action-group>
      @if (accessControlService.productFruitsFeatureToggle) {
        <button odxButton id="newsfeedBtn">
          <odx-icon name="application-feedback"></odx-icon>
          @if (newsFeedCount > 0) {
            <div class="notification-dot green-dot">
              <span class="notes-number"> {{ newsFeedCount }}</span>
            </div>
          }
        </button>
      }

      @if (accessControlService.remoteMonitoringFeatureToggle) {
        <button
          odxButton
          (click)="
            accessControlService.checkPermission(appUserPermissionList.remoteMonitoring) ? handleAlarmsClick() : null
          "
          class="alarms-btn"
          [disabled]="!accessControlService.checkPermission(appUserPermissionList.remoteMonitoring)"
          [pTooltip]="
            accessControlService.checkPermission(appUserPermissionList.remoteMonitoring) ? null : noPermissionTooltip
          "
          tooltipPosition="bottom"
          tooltipStyleClass="tab-bottom-btn-tooltip"
          data-test-id="navbar.display_alarms_widget"
        >
          @if (
            alarmsInfo?.newAlarmsNumber > 0 &&
            accessControlService.checkPermission(appUserPermissionList.remoteMonitoring)
          ) {
            <div class="notification-dot" [ngClass]="alarmsInfo?.severityAlarms > 0 ? 'red-dot' : 'orange-dot'">
              <span class="notes-number" [class]="currentAppTheme"> {{ alarmsInfo?.newAlarmsNumber }}</span>
            </div>
          }

          <odx-icon name="notification"></odx-icon>
        </button>
      }

      <button odxButton [odxMenu]="userGuideMenu" odxMenuPosition="bottom" data-test-id="navbar.open_user_guides">
        <odx-icon name="help"></odx-icon>
      </button>
      <button odxButton [odxMenu]="settingsMenu" odxMenuPosition="bottom" data-test-id="navbar.open_settings_menu">
        <odx-icon name="service"></odx-icon>
      </button>
      <button odxButton [odxMenu]="userMenu" odxMenuPosition="bottom" data-test-id="navbar.open_user_dropdown">
        <odx-icon iconSet="core" name="user"></odx-icon>
      </button>
      <button odxButton (click)="navigateToDashboard()" data-test-id="navbar.circle_btn">
        <odx-icon iconSet="core" name="home"></odx-icon>
      </button>
    </odx-action-group>
  </odx-header>
}

<ng-template #userGuideMenu>
  <odx-menu>
    <button odxButton odxMenuItem (click)="navigateToGuide(ramProduct)" data-test-id="navbar.ram_user_guide">
      <span>{{ 'NAVBAR.STR_RAM_USER_GUIDE' | translate }} </span>
    </button>
    <button
      odxButton
      odxMenuItem
      data-test-id="navbar.fireground_user_guide"
      (click)="navigateToGuide(firegroundProduct)"
    >
      <span>{{ 'NAVBAR.STR_FIREGROUND_USER_GUIDE' | translate }}</span>
    </button>
  </odx-menu>
</ng-template>

<ng-template #settingsMenu>
  <odx-menu>
    <button
      odxButton
      odxMenuItem
      (click)="toggleModal(modalNames.SETTINGS, true)"
      data-test-id="navbar.software_settings"
    >
      <span>{{ 'NAVBAR.STR_SOFTWARE_SETTINGS' | translate }}</span>
    </button>
    <button
      odxButton
      odxMenuItem
      [disabled]="!accessControlService.checkPermission(appUserPermissionList.organization)"
      [pTooltip]="accessControlService.checkPermission(appUserPermissionList.organization) ? null : noPermissionTooltip"
      tooltipPosition="bottom"
      tooltipStyleClass="tab-bottom-btn-tooltip"
      data-test-id="navbar.licenses"
      (click)="toggleModal(modalNames.LICENSE, true)"
    >
      <span>{{ 'NAVBAR.STR_LICENSES' | translate }}</span>
    </button>
  </odx-menu>
</ng-template>

<ng-template #userMenu>
  <odx-menu>
    <button odxButton odxMenuItem disabled>{{ user?.firstname }}, {{ user?.lastname }}</button>
    <button odxButton odxMenuItem (click)="handleLogoutClick()" data-test-id="navbar.logout_btn">
      <odx-icon iconSet="core" name="logout" alignLeft></odx-icon>
      {{ 'NAVBAR.STR_LOGOUT' | translate }}
    </button>
  </odx-menu>
</ng-template>

<ng-template #noPermissionTooltip>
  {{ 'NAVBAR.STR_NO_PERMISSION' | translate }}
</ng-template>

<ignis-settings [isOpen]="openSettingsModal" (closeSettingsModal)="toggleModal(modalNames.SETTINGS, false)">
</ignis-settings>

@if (licensesModalOpen) {
  <ignis-licenses-modal [isOpen]="licensesModalOpen" (closeLicensesModal)="toggleModal(modalNames.LICENSE, false)">
  </ignis-licenses-modal>
}
