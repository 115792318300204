@if (!isIframe && !oktaAuthError) {
  <ignis-container
    [isLoggedIn]="isLoggedIn"
    [userRole]="userRole"
    [alarmList]="alarmList | async"
    [dateFormat]="dateFormat"
    [organizationDateFormat]="organizationDateFormat | async"
    [permissions]="permissionList"
    (checkUserRole)="getAccessControlItems($event)"
    (requestAlarms)="requestAlarms()"
    (handleAppLanguage)="getAppLanguage($event)"
  ></ignis-container>
}

@if (!isLoggedIn && !oktaAuthError) {
  <div [odxLoadingSpinner]="!isLoggedIn && !oktaAuthError" [odxLoadingSpinnerMinHeight]="60" class="app-loader"></div>
}

@if (oktaAuthError) {
  <ignis-wrong-system-date-error-page></ignis-wrong-system-date-error-page>
}
