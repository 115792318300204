<div class="blue-bar-items">
  <button
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    type="button"
    (click)="onCreateClick($event)"
    id="bluebar-create"
    data-test-id="inventory_footer_items.create_equipment_btn"
    [disabled]="
      selectedItem || !accessControlService.checkPermission(appUserPermissionList.equipmentWrite) || isModalOpened
    "
    [pTooltip]="eqAddButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #eqAddButtonTooltipContent>
      @if (!accessControlService.checkPermission(appUserPermissionList.equipmentWrite)) {
        <div data-test-id="inventory_footer_items.create_equipment_btn_NO_PERMISSION_TOOLTIP">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_NO_PERMISSION' | translate }}
        </div>
      } @else {
        <div data-test-id="inventory_footer_items.add_equipment_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_ADD_EQUIPMENT_TOOLTIP' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon size="inline" name="add"></odx-icon>
  </button>

  <button
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    [disabled]="!selectedItem || isModalOpened"
    (click)="onEditClick($event)"
    type="button"
    id="bluebar-edit"
    data-test-id="inventory_footer_items.edit_equipment_btn"
    *ngIf="
      accessControlService.checkPermission(appUserPermissionList.equipmentWrite) ||
      accessControlService.checkPermission(appUserPermissionList.equipmentChangeTasks)
    "
    [pTooltip]="eqEditButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <odx-icon size="inline" name="edit"></odx-icon>
  </button>

  <ng-template #eqEditButtonTooltipContent>
    @if (
      accessControlService.checkPermission(appUserPermissionList.equipmentWrite) ||
      accessControlService.checkPermission(appUserPermissionList.equipmentChangeTasks)
    ) {
      <div data-test-id="inventory_footer_items.edit_equipment_btn_TOOLTIP">
        {{ 'EQUIPMENT_FOOTER_ITEMS.STR_EDIT_EQUIPMENT_TOOLTIP' | translate }}
      </div>
    }
  </ng-template>

  <button
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    [disabled]="!selectedItem"
    (click)="onNavigateToEquipment($event)"
    type="button"
    id="bluebar-view-btn"
    data-test-id="inventory_footer_items.view_equipment_btn"
    *ngIf="
      !accessControlService.checkPermission(appUserPermissionList.equipmentWrite) &&
      !accessControlService.checkPermission(appUserPermissionList.equipmentChangeTasks)
    "
    [pTooltip]="eqViewButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #eqViewButtonTooltipContent>
      @if (
        !accessControlService.checkPermission(appUserPermissionList.equipmentWrite) &&
        !accessControlService.checkPermission(appUserPermissionList.equipmentChangeTasks)
      ) {
        <div data-test-id="inventory_footer_items.view_equipment_btn_TOOLTIP">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_VIEW_EQUIPMENT_TOOLTIP' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon name="view" size="inline"></odx-icon>
  </button>

  <button
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    [disabled]="
      !selectedItem || !accessControlService.checkPermission(appUserPermissionList.equipmentWrite) || isModalOpened
    "
    (click)="onDeleteClick($event)"
    type="button"
    id="bluebar-delete"
    data-test-id="inventory_footer_items.delete_equipment_btn"
    [pTooltip]="eqDeleteButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #eqDeleteButtonTooltipContent>
      @if (!accessControlService.checkPermission(appUserPermissionList.equipmentWrite)) {
        <div data-test-id="inventory_footer_items.delete_equipment_no_permission_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_NO_PERMISSION' | translate }}
        </div>
      } @else {
        <div data-test-id="inventory_footer_items.delete_equipment_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_DELETE_EQUIPMENT_TOOLTIP' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon size="inline" name="delete"></odx-icon>
  </button>

  <button
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    [disabled]="!selectedItem || isModalOpened"
    (click)="onHistoryClick($event)"
    type="button"
    id="bluebar-history"
    data-test-id="inventory_footer_items.history_equipment_btn"
    [pTooltip]="eqHistroyButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #eqHistroyButtonTooltipContent>
      <div data-test-id="inventory_footer_items.history_equipment_tooltip">
        {{ 'EQUIPMENT_FOOTER_ITEMS.STR_VIEW_HISTORY_TOOLTIP' | translate }}
      </div>
    </ng-template>

    <odx-icon size="inline" name="timeline-view"></odx-icon>
  </button>

  <button
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    [disabled]="!selectedItem || isModalOpened || isSubmitDisabled"
    type="button"
    (click)="onPerformTaskClick($event)"
    id="bluebar-perform-task"
    data-test-id="inventory_footer_items.perform_task_btn"
    [pTooltip]="eqTestButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #eqTestButtonTooltipContent>
      @if (accessControlService.checkPermission(appUserPermissionList.equipmentTest)) {
        <div data-test-id="inventory_footer_items.perform_task">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_PERFORM_SERVICE_TASK_TOOLTIP' | translate }}
        </div>
      } @else {
        <div data-test-id="inventory_footer_items.perform_task_no_permission_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_NO_PERMISSION' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon size="inline" name="testing"></odx-icon>
  </button>

  <button
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    type="button"
    id="bluebar-download-equipment-csv-btn"
    data-test-id="inventory_footer_items.download_csv_btn"
    [disabled]="!accessControlService.checkPermission(appUserPermissionList.equipmentWrite) || isModalOpened"
    (click)="onDownloadTemplateClick($event)"
    [pTooltip]="eqDownloadCSVButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #eqDownloadCSVButtonTooltipContent>
      @if (!accessControlService.checkPermission(appUserPermissionList.equipmentWrite)) {
        <div data-test-id="inventory_footer_items.download_csv_no_permission_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_NO_PERMISSION' | translate }}
        </div>
      } @else {
        <div data-test-id="inventory_footer_items.download_csv_tooltip">
          {{ 'INVENTORY.STR_DOWNLOAD_TEMPLATE' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon size="inline" name="download"></odx-icon>
  </button>

  <button
    odxButton
    [size]="'medium'"
    [variant]="'highlight'"
    class="blue-bar-btn"
    type="button"
    id="bluebar-upload-equipment-csv-btn"
    data-test-id="inventory_footer_items.upload_csv_btn"
    [disabled]="!accessControlService.checkPermission(appUserPermissionList.equipmentWrite) || isModalOpened"
    (click)="onUploadCSVFileClick($event)"
    [pTooltip]="eqUploadCSVButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #eqUploadCSVButtonTooltipContent>
      @if (!accessControlService.checkPermission(appUserPermissionList.equipmentWrite)) {
        <div data-test-id="inventory_footer_items.upload_csv_no_permission_tooltip">
          <div data-test-id="inventory_footer_items.upload_csv_no_permission_tooltip">
            {{ 'EQUIPMENT_FOOTER_ITEMS.STR_NO_PERMISSION' | translate }}
          </div>
        </div>
      } @else {
        <div data-test-id="inventory_footer_items.upload_csv_tooltip">
          <div data-test-id="inventory_footer_items.upload_csv_tooltip">
            {{ 'INVENTORY.STR_UPLOAD_FILE' | translate }}
          </div>
        </div>
      }
    </ng-template>

    <odx-icon size="inline" name="upload"></odx-icon>
  </button>

  <ignis-export-excel-footer-btn
    [isModalOpened]="isModalOpened"
    [numberOfRecordsToBeExported]="numberOfRecordsToBeExported"
    (handleClickEvent)="onExportExcelClick($event)"
  >
  </ignis-export-excel-footer-btn>
</div>
