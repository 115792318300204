<div class="protector-software-migration-container">
  <div class="title-and-description mb-3">
    <img src="/assets/img/logo.svg" alt="draeger-logo" />
    <p class="mt-5 white-space-break" *ngIf="!migrationId || migrationStatusOfDevice === migrationStatuses.IN_PROGRESS">
      {{ 'DEVICE_CONNECTION_MIGRATION.STR_PAGE_DESCRIPTION' | translate }}
    </p>

    <p class="mt-5" *ngIf="liveMigrationStatus === migrationStatuses.COMPLETED_WITH_SUCCESS">
      {{ 'DEVICE_CONNECTION_MIGRATION.STR_PAGE_SUCCESS_MESSAGE' | translate }}
    </p>

    <p class="mt-5" *ngIf="liveMigrationStatus === migrationStatuses.CANCELED">
      {{ 'DEVICE_CONNECTION_MIGRATION.STR_PAGE_CANCEL_MESSAGE' | translate }}
    </p>

    <p class="mt-5" *ngIf="liveMigrationStatus === migrationStatuses.COMPLETED_WITH_ERROR">
      {{ 'DEVICE_CONNECTION_MIGRATION.STR_PAGE_SUCCESS_WITH_ERROR' | translate }}
    </p>

    <span
      [pTooltip]="
        disableMigrateBtn && !migrationId ? ('DEVICE_CONNECTION_MIGRATION.STR_MIGRATE_BTN_TOOLTIP' | translate) : null
      "
      tooltipPosition="top"
      positionTop="20"
    >
      <button
        odxButton
        variant="primary"
        class="mt-5"
        data-test-id="device_connection.migrate_btn"
        [disabled]="
          disableMigrateBtn || (migrationStatusOfDevice && migrationStatusOfDevice !== migrationStatuses.IN_PROGRESS)
        "
        (click)="
          migrationId && migrationStatusOfDevice === migrationStatuses.IN_PROGRESS
            ? openConfirmCloseMigrationBanner()
            : isNetworkInstallation
              ? openNetworkInstallationBanner()
              : startProtectorSoftwareMigration()
        "
      >
        {{
          migrationId && migrationStatusOfDevice === migrationStatuses.IN_PROGRESS
            ? ('DEVICE_CONNECTION_MIGRATION.STR_CANCEL_BTN_LABEL' | translate)
            : ('DEVICE_CONNECTION_MIGRATION.STR_MIGRATE_BTN_LABEL' | translate)
        }}
      </button>
    </span>

    @if (
      liveMigrationStatus === migrationStatuses.MIGRATING_EQUIPMENT ||
      liveMigrationStatus === migrationStatuses.MIGRATING_TASK_RESULTS
    ) {
      <p class="mt-5">
        {{ 'DEVICE_CONNECTION_MIGRATION.STR_PAGE_EQUIPMENT_UPLOADED' | translate }}
        {{ migrationStatus.equipmentUploaded }}/{{ migrationStatus.totalEquipment }}
      </p>
      <p>
        {{ 'DEVICE_CONNECTION_MIGRATION.STR_PAGE_EQUIPMENT_MIGRATED' | translate }}
        {{ migrationStatus.equipmentMigrated }}/{{ migrationStatus.totalEquipment }}
      </p>
    }

    @if (liveMigrationStatus === migrationStatuses.MIGRATING_TASK_RESULTS) {
      <p class="mt-3">
        {{ 'DEVICE_CONNECTION_MIGRATION.STR_PAGE_TASK_RESULTS_UPLOADED' | translate }}
        {{ migrationStatus.taskResultsUploaded }}/{{ migrationStatus.totalTaskResults }}
      </p>
      <p>
        {{ 'DEVICE_CONNECTION_MIGRATION.STR_PAGE_TASK_RESULTS_MIGRATED' | translate }}
        {{ migrationStatus.taskResultsMigrated }}/{{ migrationStatus.totalTaskResults }}
      </p>
    }
  </div>

  <div [hidden]="!displayTable" class="table-wrap">
    <ignis-protector-migration-error-table
      *ngIf="migrationId"
      [migrationStatusOfDevice]="migrationStatusOfDevice"
      [liveMigrationStatus]="liveMigrationStatus"
      [migrationId]="migrationId"
      [pollingMilliseconds]="pollingMilliseconds"
      (emitErrorsToDisplayTable)="displayTable = $event"
    ></ignis-protector-migration-error-table>
  </div>

  <ignis-confirmation-modal
    [isOpen]="openCancelMigrationConfirmationModal"
    [displayProceedBtn]="true"
    (handleConfirmationModal)="cancelMigration($event)"
    class="is-closed-modal migration-banner"
  >
    <div slot="content">{{ 'DEVICE_CONNECTION_MIGRATION.STR_CONFIRM_CANCEL' | translate }}</div>
  </ignis-confirmation-modal>

  <ignis-confirmation-modal
    [isOpen]="openMigrationConfirmationBannerForNetworkInstallation"
    [displayProceedBtn]="true"
    (handleConfirmationModal)="startMigration($event)"
    class="is-closed-modal migration-banner"
  >
    <div slot="content">
      {{ 'DEVICE_CONNECTION_MIGRATION.STR_CONFIRM_NETWORK_INSTALL' | translate }}
    </div>
  </ignis-confirmation-modal>
</div>
