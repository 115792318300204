import { OktaConfig } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { environment } from 'src/environments/environment';

export const oktaConfig: any = {
  oidc: {
    clientId: environment.OKTA.CLIENT_ID,
    issuer: environment.OKTA.ISSUER,
    redirectUri: window.location.origin,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    pkce: true,
    tokenManager: {
      expireEarlySeconds: 15,
      autoRemove: false,
      autoRenew: true,
      secure: true,
    },
    storageManager: {
      token: {
        storageTypes: ['localStorage', 'cookie'],
      },
      cache: {
        storageTypes: ['sessionStorage', 'cookie'],
      },
      transaction: {
        storageTypes: ['sessionStorage', 'cookie'],
      },
    },
  },
};

/* istanbul ignore next */
export function OktaAuthFactory(): OktaConfig {
  const oktaAuth: OktaAuth = new OktaAuth(oktaConfig.oidc);

  return {
    oktaAuth,
    onAuthRequired: (authOkta: OktaAuth) => {
      const triggerLogin = async () => {
        await authOkta.signInWithRedirect();
      };

      if (!authOkta.authStateManager.getPreviousAuthState()?.isAuthenticated) {
        triggerLogin();
      }
    },
  };
}
