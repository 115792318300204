import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { browserTracingIntegration, globalHandlersIntegration, init, replayIntegration } from '@sentry/angular-ivy';
import { CommonConstants } from './app/common';
import { AppModule } from './app/root';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

init({
  dsn: environment.SENTRY.DSN,
  environment: environment.API_URLS.ENV,
  enabled: environment.SENTRY.ACTIVATE_SENTRY === 'true' && !CommonConstants.isHeadless,
  integrations: [
    browserTracingIntegration(),
    replayIntegration(),
    globalHandlersIntegration({
      onerror: false,
      onunhandledrejection: false,
    }),
  ],
  tracesSampleRate: Number(environment.SENTRY.TRACES_SAMPLE_RATE),
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: Number(environment.SENTRY.REPLAYS_SESSION_SAMPLE_RATE),
  replaysOnErrorSampleRate: Number(environment.SENTRY.REPLAY_ON_ERROR_SAMPLE_RATE),
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

if (!window.location.href.includes('?code') && localStorage.getItem('okta-token-storage') === '{}') {
  localStorage.setItem(CommonConstants.routeWithoutAuthentication, window.location.href);
  localStorage.removeItem(CommonConstants.routeToNavigateAfterLogin);
}
