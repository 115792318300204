import { combineReducers } from 'redux';
import { incidentAnalysisReducer } from './analysis/incident-analysis.reducer';
import { incidentDataReducer } from './data/incident-data.reducer';
import { incidentEventMarkerReducer } from './event-marker/incident-event-marker.reducer';
import { incidentExcelReducer } from './excel/incident-excel.reducer';
import { mergeIncidentReducer } from './merge/incident-merge.reducer';
import { incidentNoteReducer } from './note/incident-note.reducer';

export const incidentReducer: any = combineReducers<{
  incidentData: typeof incidentDataReducer;
  incidentNote: typeof incidentNoteReducer;
  eventMarker: typeof incidentEventMarkerReducer;
  mergeIncidents: typeof mergeIncidentReducer;
  incidentExcel: typeof incidentExcelReducer;
  analysis: typeof incidentAnalysisReducer;
}>({
  incidentData: incidentDataReducer,
  incidentNote: incidentNoteReducer,
  eventMarker: incidentEventMarkerReducer,
  mergeIncidents: mergeIncidentReducer,
  incidentExcel: incidentExcelReducer,
  analysis: incidentAnalysisReducer,
});
