import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TreeSelect } from 'primeng/treeselect';
import { OnDestroyMixin } from 'src/app/common/mixins';
import { IEntryModel } from 'src/app/common/models';
import { DropdownService } from 'src/app/common/services';
import { ConfigurationConstants } from 'src/app/configuration/constants';
import { ILocationHierarchy, ILocationNode } from 'src/app/configuration/models';
import { IEquipment } from 'src/app/workshop/models';

@Component({
  selector: 'ignis-assignments',
  templateUrl: './assignments.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignmentsComponent extends OnDestroyMixin() implements OnChanges, AfterViewInit {
  @Input() formatDate: string;
  @Input() isSubmitted: boolean;
  @Input() equipmentForm: FormGroup;
  @Input() disableAllFields: boolean;
  @Input() restrictToLocation: boolean;
  @Input() locations: ILocationHierarchy[];
  @Input() selectedEquipment: IEquipment;
  @Output() markTabsWithErrors: EventEmitter<string> = new EventEmitter<string>();
  @Output() showLocationChanged: EventEmitter<ILocationNode> = new EventEmitter<ILocationNode>();

  @ViewChild('dd1') dd1: Partial<TreeSelect>;

  selectedLocation: ILocationNode;

  public dropdownService: DropdownService = inject(DropdownService);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  translateService: TranslateService = inject(TranslateService);

  constructor() {
    super();
  }

  ngOnChanges(): void {
    if (!this.disableAllFields) {
      this.equipmentForm.get('locationAssignment.location').enable();
    }

    this.setLocationValueIfUserCantEditEquipment();

    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.searchAndSelectNode(this.locations);
      this.dropdownService.findTreeNodeAndAddIcon(this.locations);

      if (this.disableAllFields) {
        this.equipmentForm.get('locationAssignment.location').disable();
      }
    }, 250);
  }

  searchAndSelectNode(locations: ILocationHierarchy[]): void {
    for (const locationNode of locations) {
      if (locationNode.data?.aggregateId === this.equipmentForm.get('locationAssignment.aggregateId').value) {
        this.setSelectedLocation(locationNode);

        return this.equipmentForm.get('locationAssignment.location').setValue(locationNode);
      }

      if (locationNode.children) {
        this.searchAndSelectNode(locationNode.children);
      }
    }
  }

  setSelectedLocation(location: { data: ILocationNode }): void {
    this.selectedLocation = location.data;
    const localizedData: IEntryModel[] = ConfigurationConstants.locationTypes.types;
    const selectedType: IEntryModel = localizedData.find(
      (constantType: IEntryModel) => constantType.value === location.data.type,
    );

    this.showLocationChanged.emit(this.selectedLocation);

    this.equipmentForm.get('locationAssignment').setValue({
      location,
      aggregateId: location.data.aggregateId,
      name: location.data.name,
      type: selectedType?.localizedName
        ? (this.translateService.instant(selectedType?.localizedName) as string)
        : location.data.type,
      identifier: location.data.identifier,
      address: location.data.address,
      details: location.data.details,
    });
    this.equipmentForm.get('locationAssignment.aggregateId').markAsTouched();

    this.markTabsWithErrors.emit();

    if (this.dd1) {
      this.dd1.overlayVisible = false;
    }
  }

  clearFormData(): void {
    this.selectedLocation = null;
    this.showLocationChanged.emit(null);

    this.equipmentForm.get('locationAssignment').setValue({
      location: null,
      aggregateId: null,
      name: null,
      type: null,
      identifier: null,
      address: null,
      details: null,
    });
    this.equipmentForm.get('locationAssignment.aggregateId').markAsTouched();

    this.markTabsWithErrors.emit();
  }

  setLocationValueIfUserCantEditEquipment(): void {
    if (this.selectedEquipment?.locationAssignment && this.disableAllFields) {
      const node: ILocationHierarchy = {
        label: this.selectedEquipment.locationAssignment.name,
        key: this.selectedEquipment.locationAssignment.aggregateId,
        icon: this.selectedEquipment.locationAssignment.type?.toLocaleLowerCase(),
        children: [],
        data: {
          aggregateId: this.selectedEquipment.locationAssignment.aggregateId,
          parentId: '',
          identifier: '',
          name: this.selectedEquipment.locationAssignment.name,
          type: this.selectedEquipment.locationAssignment.type?.toLocaleLowerCase(),
          address: this.selectedEquipment.locationAssignment.address,
          details: this.selectedEquipment.locationAssignment.details,
          locations: [],
        },
      };

      this.dropdownService.findTreeNodeAndAddIcon([node]);

      return this.equipmentForm.get('locationAssignment.location').setValue(node);
    }
  }
}
