import { DOCUMENT, Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  inject,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { select, Store } from '@ngrx/store';
import { StorageMap } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { ModalRef, ModalService, ModalSize, ModalVariant } from '@odx/angular/components/modal';
import { addDays, addMonths, addWeeks, addYears, isBefore, isEqual, startOfDay, startOfToday } from 'date-fns';
import sortBy from 'lodash-es/sortBy';
import uniqBy from 'lodash-es/uniqBy';
import { filter, map, Observable, Subject, takeUntil } from 'rxjs';
import {
  ApplicationState,
  CommonConstants,
  DropdownService,
  IStoreApiItem,
  IStoreApiList,
  LoggerService,
  UserStorageService,
} from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IOKTAProfile } from 'src/app/common/models';
import { PropertyBag } from 'src/app/common/models/common.model';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { extractOnlyDate } from 'src/app/common/utils/date-utils/date.utils';
import { processLocationPath } from 'src/app/common/utils/execute-test-utils/start-test';
import { prepareNumericValueData } from 'src/app/common/utils/general-helper/general-helpers';
import { getDateFormatFromUserProfile } from 'src/app/common/utils/settings-utils/settings-utils';
import { ChecklistParameterTypes } from 'src/app/configuration/constants/configuration.constants';
import {
  IChecklist,
  IChecklistEquipmentData,
  IChecklistItem,
  IChecklists,
  ISaveBatchChecklist,
  ITask,
} from 'src/app/configuration/models';
import { IncidentActions, UploadFilesService } from 'src/app/report-center';
import { EquipmentNotificationConstants, WorkshopConstants, WorkshopModuleRoutes } from 'src/app/workshop/constants';
import { OutOfServiceFieldInteractionService } from 'src/app/workshop/services/out-of-service-field-interaction.service';
import { RestoreServiceTaskReasonService } from 'src/app/workshop/services/restore-service-task-reason.service';
import { EquipmentCRUDActions } from 'src/app/workshop/state/actions';
import { EquipmentServiceTaskActions } from 'src/app/workshop/state/actions/equipment-service-task';
import { IEquipment, IEquipmentServiceInterval, ILocationAssignment, IServiceTask } from '../../../models';
import { selectChecklistsData, selectSaveBatchChecklist } from '../../../state/selectors';

@Component({
  selector: 'ignis-batch-complete-checklist',
  templateUrl: './complete-batch-checklist.component.html',
  styleUrls: ['./complete-batch-checklist.component.scss'],
})
export class CompleteBatchChecklistComponent extends OnDestroyMixin() implements OnInit, AfterViewInit {
  @ViewChild('completeChecklistModal', { read: TemplateRef })
  public completeChecklistModal: TemplateRef<ViewContainerRef>;
  @ViewChild('completeChecklist') completeChecklist!: ElementRef<HTMLDivElement>;
  modalRef: ModalRef<string, string>;

  completeChecklistForm: FormGroup = new FormGroup({});
  availableChecklists: Array<{ serviceId: string; checklist: IChecklist }>;
  selectedChecklist: IChecklist;
  serviceIntervals: ITask[];
  defaultServiceInterval: ITask;
  selectedTaskIntervals: ITask[] = [];
  taskIncluded: ITask[] = [];
  selectedTask: IServiceTask;
  otherTaskIncluded: ITask[] = [];
  selectedServices: ITask;
  loadedEquipment: IEquipment[] | IEquipment;
  checklistParameterTypes: typeof ChecklistParameterTypes = ChecklistParameterTypes;
  checklistItems: IChecklistItem[];
  formatDate: string;
  tooltipDateFormat: string;
  operationalStatusesList: {
    value: string;
    label: string;
  }[] = [];
  operationalStatuses: PropertyBag = WorkshopConstants.operationalStatuses;

  openConfirmChangeTasks: boolean = false;
  isConfirmCloseModalOpen: boolean = false;
  areFilesUploaded: boolean = false;

  jwtHelper: JwtHelperService = new JwtHelperService();
  user: IOKTAProfile;

  deleteImagesSubject: Subject<void> = new Subject<void>();

  currentAppTheme: string;
  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;

  isLoading: boolean = true;
  isSubmitting: Observable<boolean>;

  toggleOutOfServiceDatepickerControl: boolean = true;

  router: Router = inject(Router);
  location: Location = inject(Location);
  document: Document = inject(DOCUMENT);
  storage: StorageMap = inject(StorageMap);
  formBuilder: FormBuilder = inject(FormBuilder);
  modalService: ModalService = inject(ModalService);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  loggerService: LoggerService = inject(LoggerService);
  dropdownService: DropdownService = inject(DropdownService);
  incidentActions: IncidentActions = inject(IncidentActions);
  equipmentServiceTaskActions: EquipmentServiceTaskActions = inject(EquipmentServiceTaskActions);
  equipmentCRUDActions: EquipmentCRUDActions = inject(EquipmentCRUDActions);
  translateService: TranslateService = inject(TranslateService);
  store: Store<ApplicationState> = inject<Store<ApplicationState>>(Store<ApplicationState>);
  uploadFileService: UploadFilesService = inject(UploadFilesService);
  userStorageService: UserStorageService = inject(UserStorageService);
  notificationsService: NotificationsService = inject(NotificationsService);
  restoreServiceTaskReasonService: RestoreServiceTaskReasonService = inject(RestoreServiceTaskReasonService);
  outOfServiceService: OutOfServiceFieldInteractionService = inject(OutOfServiceFieldInteractionService);

  @HostListener(CommonConstants.beforeUnloadWindowEvent, ['$event'])
  handleBeforeUnload($event: { returnValue: boolean }): void {
    if (this.hasUnsavedData()) {
      $event.returnValue = this.hasUnsavedData();
    }
  }

  canDeactivate(): boolean {
    this.markFormElements('markAsPristine');

    if (this.hasUnsavedData()) {
      this.confirmCloseModalOpen();

      return false;
    }

    this.isConfirmCloseModalOpen = false;

    return true;
  }

  get form(): { [key: string]: AbstractControl<unknown, unknown> } {
    return this.completeChecklistForm && this.completeChecklistForm.controls;
  }

  ngOnInit(): void {
    this.getAppThemeAndOperationalStatusList();
    this.getAppInfo();

    if (localStorage.getItem(WorkshopConstants.selectedTask)) {
      this.selectedTask = JSON.parse(localStorage.getItem(WorkshopConstants.selectedTask)) as IServiceTask;
    }

    this.createCompleteChecklistForm();

    this.isSubmitting = this.store.select(selectSaveBatchChecklist).pipe(
      takeUntil(this.destroy),
      map((checklistCompleted: IStoreApiItem<ISaveBatchChecklist>) => checklistCompleted.isLoading),
    );
  }

  createCompleteChecklistForm(): void {
    this.completeChecklistForm = this.formBuilder.group({
      taskData: new FormControl(null),
      dateTime: new FormControl({ value: null, disabled: true }),
      checklistName: new FormControl({ value: null, disabled: true }),
      checklistDescription: new FormControl({ value: null, disabled: true }),
      operationalStatus: new FormControl(null, [Validators.required]),
      otherTasks: new FormControl(null),
    });
  }

  ngAfterViewInit(): void {
    this.populateTaskDropdown();
    this.openModal();
  }

  getAppThemeAndOperationalStatusList(): void {
    this.currentAppTheme = this.document.body.className.split(' ')[1];

    this.operationalStatusesList = [
      {
        value: this.operationalStatuses.OPERATIONAL,
        label: this.translateService.instant('EQUIPMENT_GENERAL.STR_OPERATIONAL_STATUSES_OPERATIONAL') as string,
      },
      {
        value: this.operationalStatuses.NON_OPERATIONAL,
        label: this.translateService.instant('EQUIPMENT_GENERAL.STR_NON_OPERATIONAL_STATUSES') as string,
      },
      {
        value: this.operationalStatuses.DEFECTIVE_BUT_OPERATIONAL,
        label: this.translateService.instant('EQUIPMENT_GENERAL.STR_DEFECTIVE_BUT_OPERATIONAL_STATUSES') as string,
      },
    ];
  }

  getAppInfo(): void {
    this.user = this.jwtHelper.decodeToken(localStorage.getItem(CommonConstants.AUTH_TOKEN));
    this.formatDate = getDateFormatFromUserProfile(this.translateService);
    this.tooltipDateFormat = `${this.formatDate} ${CommonConstants.TIME_WITH_SECONDS_FORMAT}`;
  }

  confirmCloseModalOpen(): void {
    this.isConfirmCloseModalOpen = true;
  }

  navigateBack(isOpen: boolean): void {
    if (isOpen) {
      this.removeImages();

      this.createCompleteChecklistForm();
      this.completeChecklistForm.markAsPristine();

      this.navigateToOrigin();

      return;
    }

    this.isConfirmCloseModalOpen = false;
  }

  populateTaskDropdown(): void {
    this.store
      .pipe(
        select(selectChecklistsData),
        filter((checklists: IStoreApiItem<IChecklists>) => !checklists.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((checklists: IStoreApiItem<IChecklists>) => {
        if (checklists.data) {
          this.processingTaskData(checklists);

          this.loadedEquipment = checklists.data.equipmentData;

          if (this.selectedTask) {
            this.serviceIntervals.forEach((sd: ITask) => {
              if (sd.serviceId === this.selectedTask.serviceId) {
                this.defaultServiceInterval = structuredClone(sd);
              }
            });
          } else {
            const selectedLocation: ILocationAssignment = JSON.parse(
              localStorage.getItem(WorkshopConstants.savedLocation),
            ) as ILocationAssignment;

            this.defaultServiceInterval = structuredClone(this.serviceIntervals[0]);
            this.changeLocation(selectedLocation);
          }

          this.selectedServices = this.defaultServiceInterval;
          this.processingTasksTablesData(this.selectedServices.serviceId);
          this.completeChecklistForm.patchValue({
            taskData: this.defaultServiceInterval,
            checklistName: this.processingTaskData(checklists).defaultChecklist.name,
            checklistDescription: this.processingTaskData(checklists).defaultChecklist.description,
          });
          this.processChecklistItemsData(this.processingTaskData(checklists).defaultChecklist.items);
          this.isLoading = false;
        }

        this.setChecklistDependingBySelectedTask();

        this.completeChecklistForm.get('operationalStatus').markAsPristine();
        this.cdr.detectChanges();
      });
  }

  setChecklistDependingBySelectedTask(): void {
    this.availableChecklists.forEach((elem: { serviceId: string; checklist: IChecklist }) => {
      if (elem.serviceId === this.completeChecklistForm.get('taskData').value?.serviceId) {
        this.selectedChecklist = elem.checklist;
      }
    });

    this.processChecklistItemsData(this.selectedChecklist?.items);
  }

  processingTaskData(checklists: IStoreApiItem<IChecklists>): {
    defaultChecklist: IChecklist;
    defaultServiceId: string;
  } {
    const serviceIntervalsWithChecklists: ITask[] = [];
    let defaultChecklist: IChecklist;
    let defaultServiceId: string;

    this.availableChecklists = checklists.data.availableChecklists;
    this.serviceIntervals = [];

    checklists.data.equipmentData.forEach((eq: IEquipment) => {
      eq.serviceIntervals.forEach((service: ITask) => {
        if (service.hasCheckList) {
          serviceIntervalsWithChecklists.push(service);
        }
      });
    });

    this.availableChecklists.forEach((checklist: { serviceId: string; checklist: IChecklist }) => {
      serviceIntervalsWithChecklists.forEach((service: ITask) => {
        if (service.serviceId === checklist.serviceId) {
          this.serviceIntervals.push(service);
        }
      });
    });

    this.serviceIntervals = structuredClone(uniqBy(this.serviceIntervals, 'serviceId'));
    this.serviceIntervals = this.sortServicesData(this.serviceIntervals);

    this.translateTaskNameInheritance();

    if (this.selectedTask && this.router.url.includes(WorkshopModuleRoutes.completeBatchChecklist)) {
      checklists.data.availableChecklists.forEach((checklist: { serviceId: string; checklist: IChecklist }) => {
        if (checklist.serviceId === this.selectedTask.serviceId) {
          this.selectedChecklist = checklist.checklist;
          defaultChecklist = this.selectedChecklist;
          defaultServiceId = checklist.serviceId;
        }
      });
    } else {
      this.availableChecklists.forEach((elem: { serviceId: string; checklist: IChecklist }) => {
        if (elem.serviceId === this.defaultServiceInterval?.serviceId) {
          defaultChecklist = elem.checklist;
          defaultServiceId = elem.serviceId;
        }
      });

      this.selectedChecklist = defaultChecklist;
    }

    return {
      defaultChecklist,
      defaultServiceId,
    };
  }

  translateTaskNameInheritance(): void {
    this.serviceIntervals.forEach((service: ITask) => {
      service.taskName = service.inheritedFrom
        ? `${service.taskName} (${this.translateService.instant('EQUIPMENT_TASK_RESULTS.STR_INHERITED')} ${service.inheritedFrom})`
        : service.taskName;
    });
  }

  sortServicesData(data: ITask[]): ITask[] {
    return sortBy(data, ['reason']);
  }

  changeLocation(location: ILocationAssignment): void {
    if (location && this.loadedEquipment && this.router.url.includes(WorkshopModuleRoutes.workflow)) {
      interface ILocation {
        locationAssignment: { aggregateId: string };
        equipmentAggregateIds: string[];
      }
      const locationChange: ILocation = {
        locationAssignment: { aggregateId: location.aggregateId },
        equipmentAggregateIds: [...(this.loadedEquipment as IEquipment[]).map((eq: IEquipment) => eq.aggregateId)],
      };

      (this.loadedEquipment as IEquipment[]).forEach((eq: IEquipment) => {
        const equipment: IEquipment = structuredClone(eq);

        equipment.version = +equipment.version + 1;
      });

      this.equipmentServiceTaskActions.requestUpdateEquipmentLocation(locationChange);
    }
  }

  openModal(): void {
    this.modalRef = this.modalService.open(this.completeChecklistModal, {
      size: ModalSize.MEDIUM,
      variant: ModalVariant.DEFAULT,
      dismissable: false,
      data: 'isOpen',
      dismissOnNavigation: false,
    });
  }

  closeModal(onSubmit?: boolean): void {
    this.markFormElements('markAsPristine');

    if (this.hasUnsavedData()) {
      this.confirmCloseModalOpen();
    } else {
      this.completeChecklistForm = null;
      this.createCompleteChecklistForm();

      this.modalRef.close('');

      if (!onSubmit) {
        this.removeFormControls(this.selectedChecklist.items);
      }

      this.location.back();
      Object.defineProperty(this.modalRef, 'data', { value: 'isClosed', writable: false });
    }
  }

  navigateToOrigin(): void {
    this.location.back();
  }

  markFormElements(type: string): void {
    const controlsToUpdate: string[] = ['taskData', 'operationalStatus'];

    Object.keys(this.completeChecklistForm.controls).forEach((controlName: string) => {
      if (controlsToUpdate.includes(controlName)) {
        this.completeChecklistForm.get(controlName)[type]();
      }
    });
  }

  processChecklistItemsData(items: IChecklistItem[]): void {
    this.checklistItems = structuredClone(items);

    this.checklistItems?.forEach((item: IChecklistItem, index: number) => {
      item.id = item.name + index.toString();
      item.type = item.type.toLocaleLowerCase();
      item.itemValue.type = item.type;
      this.removeImageType(item);

      if ((item.type as ChecklistParameterTypes) === this.checklistParameterTypes.BOOLEAN_TYPE) {
        this.completeChecklistForm.addControl(
          item.id,
          new FormControl(false, [item.required ? Validators.requiredTrue : Validators.nullValidator]),
        );
      } else {
        this.completeChecklistForm.addControl(
          item.id,
          new FormControl(null, [item.required ? Validators.required : Validators.nullValidator]),
        );
      }
    });
  }

  removeImageType(item: IChecklistItem): void {
    const emptyMediaLinksList: string[] = [];

    if ((item.itemValue.type as ChecklistParameterTypes) !== this.checklistParameterTypes.IMAGE_TYPE) {
      delete item.itemValue.mediaLinks;

      return;
    }

    item.itemValue.mediaLinks = emptyMediaLinksList;
  }

  closeConfirmBanner(confirmation: boolean): void {
    if (confirmation) {
      this.toggleOutOfServiceDatepickerControl = false;

      this.deleteImagesSubject.next();
      this.areFilesUploaded = false;

      this.processingTasksTablesData(this.selectedServices.serviceId);

      this.createCompleteChecklistForm();

      this.changeTasks({ value: this.selectedServices });
    } else {
      this.completeChecklistForm.get('taskData').patchValue(this.defaultServiceInterval);
    }

    this.openConfirmChangeTasks = false;

    setTimeout(() => {
      this.toggleOutOfServiceDatepickerControl = true;
    }, 0);
  }

  removeFormControls(items: IChecklistItem[]): void {
    items?.forEach((item: IChecklistItem) => {
      this.completeChecklistForm.removeControl(item.name);
    });

    this.deleteImagesSubject.next();

    this.completeChecklistForm.updateValueAndValidity();
  }

  onSubmit(): void {
    this.processingChecklistItems();

    this.selectedChecklist = structuredClone(this.selectedChecklist);
    this.selectedChecklist.items = this.checklistItems;

    this.equipmentServiceTaskActions.requestSaveBatchChecklist(this.prepareDateForSubmit());
    this.handleCompleteBatchChecklist();
  }

  processingChecklistItems(): void {
    const formValue: AbstractControl = this.completeChecklistForm.getRawValue();

    this.checklistItems.forEach((item: any) => {
      Object.keys(formValue).forEach((key: string) => {
        if (item.type === this.checklistParameterTypes.MULTIPLE_SELECTION_TYPE) {
          item.itemValue.options.forEach((option: any, index: number) => {
            if (key === item.id && Array.isArray(formValue[key])) {
              option.checked = formValue[key][index];
            }
          });
        }

        if (key === item.id) {
          switch (item.type as ChecklistParameterTypes) {
            case this.checklistParameterTypes.TEXT_TYPE:
              item.itemValue.text = formValue[key] as string;
              break;
            case this.checklistParameterTypes.BOOLEAN_TYPE:
              delete item.itemValue.checked;
              item.itemValue.value = formValue[key] as boolean;
              break;
            case this.checklistParameterTypes.NUMERIC_TYPE:
              item.itemValue.value = prepareNumericValueData(formValue[key] as number);
              break;
            case this.checklistParameterTypes.IMAGE_TYPE:
              delete item.itemValue.imageLinks;
              break;
          }
        }
      });
    });
  }

  prepareDateForSubmit(): ISaveBatchChecklist {
    const equipmentData: Partial<IChecklistEquipmentData[]> = [];
    const copyOfOtherTaskIncluded: ITask[] = this.otherTaskIncluded;

    (this.loadedEquipment as IEquipment[]).forEach((eq: IEquipment) => {
      const eqServiceIntervalsWithoutOthersServices: IEquipmentServiceInterval[] = structuredClone(
        eq.serviceIntervals,
      ).filter((eqSvInterval: ITask) => {
        return !copyOfOtherTaskIncluded.some((otherSvInterval: ITask) => {
          return otherSvInterval.serviceId === eqSvInterval.serviceId;
        });
      });

      const indexToRemove: number[] = [];

      for (let i: number = 0; i < eqServiceIntervalsWithoutOthersServices.length; i++) {
        const serviceIntervalItem: IEquipmentServiceInterval = eqServiceIntervalsWithoutOthersServices[i];
        let founded: boolean = false;

        for (const element of this.taskIncluded) {
          if (element.serviceId === serviceIntervalItem.serviceId) {
            founded = true;
            break;
          }
        }

        if (!founded) {
          copyOfOtherTaskIncluded.push(serviceIntervalItem as ITask);
          indexToRemove.push(i);
        }
      }

      for (let i: number = indexToRemove.length - 1; i >= 0; i--) {
        eqServiceIntervalsWithoutOthersServices.splice(indexToRemove[i], 1);
      }

      this.restoreServiceTaskReasonService.restoreReasonName(
        eqServiceIntervalsWithoutOthersServices,
        copyOfOtherTaskIncluded,
      );

      equipmentData.push({
        taskData: {
          others: copyOfOtherTaskIncluded.map((interval: ITask) => {
            return this.processOthersTasksDataForBE(eq, interval);
          }),
          includedTasks: eqServiceIntervalsWithoutOthersServices.map((interval: ITask) => {
            delete interval.hasCheckList;

            return {
              ...interval,
              nextTestDate: interval.nextTestDate ? extractOnlyDate(interval.nextTestDate) : null,
              lastTestDate: interval.lastTestDate ? extractOnlyDate(interval.lastTestDate) : null,
              taskId: interval.serviceId,
            };
          }),
        },
        identification: eq.generalData.identifier,
        equipmentAggregateId: eq.aggregateId,
        outOfServiceDate: eq.generalData.outOfOrderDate,
        outOfServiceReason: eq.generalData.outOfServiceReason,
        barcode: eq.generalData.barcode,
        rfid: eq.generalData.rfid,
        serialNo: eq.generalData.serialNo,
        equipmentType: eq.generalData.model.type.name,
        equipmentModel: eq.generalData.model.name,
        equipmentCategory: eq.generalData.model.category.name,
        locationPath: processLocationPath(eq?.locationAssignment?.path),
      });
    });

    (this.loadedEquipment as IEquipment[]).forEach((loadedEquipment: IEquipment) => {
      equipmentData.forEach((eqData: IChecklistEquipmentData) => {
        if (eqData.equipmentAggregateId === loadedEquipment.aggregateId) {
          eqData.taskData.others = eqData.taskData.others.filter((other: ITask) => {
            return loadedEquipment.serviceIntervals.some(
              (interval: IEquipmentServiceInterval) => interval.serviceId === other?.serviceId,
            );
          });
        }
      });
    });

    return {
      checklist: this.selectedChecklist,
      equipmentData,
      testerName: `${this.user.lastname}, ${this.user.firstname}`,
      operationalStatus: this.completeChecklistForm.get('operationalStatus').getRawValue() as string,
    };
  }

  processOthersTasksDataForBE(eq: IEquipment, interval: IEquipmentServiceInterval): IEquipmentServiceInterval {
    const otherServiceInterval: IEquipmentServiceInterval = eq.serviceIntervals.find(
      (service: ITask) => service.serviceId === interval.serviceId,
    );

    if (!otherServiceInterval) {
      return;
    }

    delete interval.children;
    delete interval.isInherited;
    delete interval.hasCheckList;

    return {
      ...interval,
      nextTestDate: otherServiceInterval.nextTestDate ? extractOnlyDate(otherServiceInterval.nextTestDate) : null,
      lastTestDate: otherServiceInterval.lastTestDate ? extractOnlyDate(otherServiceInterval.lastTestDate) : null,
      taskId: interval.serviceId,
    };
  }

  handleCompleteBatchChecklist(): void {
    this.store
      .pipe(
        select(selectSaveBatchChecklist),
        filter((checklistCompleted: IStoreApiList<ISaveBatchChecklist>) => !checklistCompleted.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((response: IStoreApiItem<ISaveBatchChecklist>) => {
        if (response.isSuccess) {
          this.areFilesUploaded = false;
          this.completeChecklistForm.markAsPristine();
          this.closeModal(true);

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            EquipmentNotificationConstants.notificationCodes.COMPLETE_CHECKLIST_SUCCESS,
            EquipmentNotificationConstants.notificationCodes,
          );

          if (this.router.url.includes(WorkshopModuleRoutes.workflow)) {
            const idsToRemove: string[] = (this.loadedEquipment as IEquipment[])?.map((eq: IEquipment) => {
              return eq.aggregateId;
            });

            this.storage.set(CommonConstants.markEquipmentToBeRemovedAfterChecklist, idsToRemove).subscribe(() => {
              return;
            });

            this.storage.set(CommonConstants.updateWorkflowTableDataAfterChecklist, true).subscribe(() => {
              return;
            });

            this.storage.set(CommonConstants.updateWorkflowFooterItemsAfterChecklist, true).subscribe(() => {
              return;
            });
          }
        }

        this.cdr.detectChanges();
      });
  }

  multipleSelectionCheckboxValidator(minRequired: number = 1): ValidatorFn {
    return function validate(formGroup: FormGroup) {
      let checked: number = 0;

      Object.keys(formGroup.controls).forEach((key: string) => {
        const control: AbstractControl = formGroup.controls[key];

        if (control.value) {
          checked++;
        }
      });

      if (checked < minRequired) {
        return {
          requireCheckboxToBeChecked: true,
        };
      }

      return null;
    };
  }

  changeTasks(event: any): void {
    this.selectedServices = event.value as ITask;
    this.checkIfTaskIsChangedWithFormDirty();

    this.processingTasksTablesData(event.value.serviceId);

    if (!this.router.url.includes(WorkshopModuleRoutes.completeBatchChecklist)) {
      this.outOfServiceService.changeOutOfServiceDate(
        (this.loadedEquipment as IEquipment)?.generalData?.outOfOrderDate ??
          this.completeChecklistForm.get('outOfOrderDate')?.value,
        this.completeChecklistForm,
        'operationalStatus',
        'outOfServiceReason',
      );
    }
  }

  processingTasksTablesData(serviceId: string): void {
    this.processingTaskDataForBE(serviceId);
    const allServiceIntervals: ITask[] = [];

    this.taskIncluded = [];
    this.otherTaskIncluded = [];

    this.completeChecklistForm.get('operationalStatus').setValue('OPERATIONAL');
    this.completeChecklistForm.get('operationalStatus').enable();

    (this.loadedEquipment as IEquipment[]).forEach((eq: IEquipment) => {
      eq.serviceIntervals.forEach((sv: ITask) => {
        allServiceIntervals.push(sv);
      });

      if (eq.generalData.outOfOrderDate) {
        const outOfOrderDate: Date = new Date(eq.generalData.outOfOrderDate);
        const inputDate: Date = startOfDay(outOfOrderDate);

        const today: Date = startOfDay(new Date());

        if (isEqual(inputDate, today) || isBefore(inputDate, today)) {
          this.completeChecklistForm.get('operationalStatus').setValue('NON_OPERATIONAL');
          this.completeChecklistForm.get('operationalStatus').disable();
        }
      }
    });

    this.serviceIntervals?.forEach((elem: ITask) => {
      if (elem.serviceId === serviceId) {
        this.taskIncluded.push(elem);

        elem?.children?.forEach((child: unknown) => {
          this.taskIncluded.push(child as ITask);
        });
      }
    });

    this.processingTaskIncludedArrayDataToBeDisplayedInTheTable(allServiceIntervals);
    this.processingOtherTaskIncludedArrayDataToBeDisplayedInTheTable(allServiceIntervals);
  }

  processingTaskDataForBE(serviceId: string): void {
    this.selectedTaskIntervals = [];

    (this.loadedEquipment as IEquipment[]).forEach((eq: IEquipment) => {
      eq.serviceIntervals.forEach((sv: ITask) => {
        if (sv.serviceId && sv.serviceId === serviceId) {
          this.selectedTaskIntervals.push(sv);

          sv?.children?.forEach((child: any) => {
            this.selectedTaskIntervals.push(child);
          });
        }
      });
    });

    this.selectedTaskIntervals = structuredClone(this.selectedTaskIntervals);

    this.selectedTaskIntervals.forEach((service: ITask) => {
      service.nextNew = this.calculateNewTaskIntervalDate(service) as string;
    });
  }

  calculateNewTaskIntervalDate(service: ITask): any {
    if (service.interval) {
      const { duration }: { duration: number } = service.interval;

      let newDate: Date;

      switch (service.interval.pattern) {
        case CommonConstants.dateFNSIntervalPattern.DAY:
          newDate = addDays(startOfToday(), duration);
          break;
        case CommonConstants.dateFNSIntervalPattern.WEEK:
          newDate = addWeeks(startOfToday(), duration);
          break;
        case CommonConstants.dateFNSIntervalPattern.MONTH:
          newDate = addMonths(startOfToday(), duration);
          break;
        case CommonConstants.dateFNSIntervalPattern.YEAR:
          newDate = addYears(startOfToday(), duration);
          break;
        default:
          return null;
      }

      return newDate;
    }
  }

  processingTaskIncludedArrayDataToBeDisplayedInTheTable(allServiceIntervals: ITask[]): void {
    const servicesFromTheAvailableEquipment: ITask[] = [];

    allServiceIntervals.forEach((sv: ITask) => {
      this.taskIncluded.forEach((selectedSvInt: ITask) => {
        if (sv.serviceId === selectedSvInt.serviceId) {
          servicesFromTheAvailableEquipment.push(sv);
        }
      });
    });

    this.taskIncluded = this.processTaskData(servicesFromTheAvailableEquipment);
    this.taskIncluded = structuredClone(this.taskIncluded);

    this.taskIncluded.forEach((service: ITask) => {
      service.nextNew = this.calculateNewTaskIntervalDate(service);
    });
  }

  processTaskData(services: ITask[]): ITask[] {
    const duplicatesByServiceId: object = {};

    structuredClone(services).forEach((item: ITask) => {
      const serviceId: string = item.serviceId;

      if (duplicatesByServiceId.hasOwnProperty(serviceId)) {
        if (item.lastTestDate !== duplicatesByServiceId[serviceId].lastTestDate) {
          duplicatesByServiceId[serviceId].lastTestDate = null;
        }

        if (item.nextTestDate !== duplicatesByServiceId[serviceId].nextTestDate) {
          duplicatesByServiceId[serviceId].nextTestDate = null;
        }
      } else {
        duplicatesByServiceId[serviceId] = { ...item };
      }
    });

    const resultArray: ITask[] = Object.values(duplicatesByServiceId) as ITask[];

    return resultArray;
  }

  processingOtherTaskIncludedArrayDataToBeDisplayedInTheTable(allServiceIntervals: ITask[]): void {
    const temporalListOfOtherTaskIncluded: ITask[] = [];

    const arrayOfArrays: IEquipmentServiceInterval[][] = (this.loadedEquipment as IEquipment[]).map(
      (eq: IEquipment) => eq.serviceIntervals,
    );

    const isInAllArrays = (service: IEquipmentServiceInterval, arrays: IEquipmentServiceInterval[][]) =>
      arrays.every((array: IEquipmentServiceInterval[]) =>
        array.some((item: IEquipmentServiceInterval) => item.serviceId === service.serviceId),
      );

    const firstArray: IEquipmentServiceInterval[] = arrayOfArrays[0];

    const commonServices: IEquipmentServiceInterval[] = firstArray.filter((obj: IEquipmentServiceInterval) =>
      isInAllArrays(obj, arrayOfArrays),
    );

    commonServices.forEach((service: ITask) => {
      const servicesList: ITask[] = allServiceIntervals.filter((s: ITask) => {
        return s.serviceId === service.serviceId;
      });

      const allServicesHaveSameLastDate: boolean = servicesList.every((last: ITask) => {
        return last.lastTestDate === servicesList[0].lastTestDate;
      });

      const allServicesHaveSameNextDate: boolean = servicesList.every((next: ITask) => {
        return next.nextTestDate === servicesList[0].nextTestDate;
      });

      temporalListOfOtherTaskIncluded.push({
        ...service,
        lastTestDate: allServicesHaveSameLastDate ? service.lastTestDate : null,
        nextTestDate: allServicesHaveSameNextDate ? service.nextTestDate : null,
      });
    });

    if (temporalListOfOtherTaskIncluded.length < 1) {
      const serviceIdsInList1: Set<unknown> = new Set(this.taskIncluded.map((item: ITask) => item.serviceId));
      const filteredList2: IEquipmentServiceInterval[] = commonServices.filter(
        (item: ITask) => !serviceIdsInList1.has(item.serviceId),
      );

      this.calculateNextNewDateOfOtherTaskIncluded(filteredList2);

      return;
    }

    this.calculateNextNewDateOfOtherTaskIncluded(temporalListOfOtherTaskIncluded);
  }

  calculateNextNewDateOfOtherTaskIncluded(
    temporalListOfOtherTaskIncluded: Partial<ITask[] | IEquipmentServiceInterval[]>,
  ): void {
    temporalListOfOtherTaskIncluded = uniqBy(temporalListOfOtherTaskIncluded as ITask[], 'serviceId');

    sessionStorage.setItem(WorkshopConstants.tasksIncluded, JSON.stringify(this.taskIncluded));
    sessionStorage.setItem(WorkshopConstants.otherTasks, JSON.stringify(temporalListOfOtherTaskIncluded));

    this.taskIncluded.forEach((taskIncluded: ITask) => {
      temporalListOfOtherTaskIncluded.forEach((service: ITask, index: number) => {
        if (service.serviceId === taskIncluded.serviceId) {
          temporalListOfOtherTaskIncluded.splice(index, 1);

          taskIncluded.taskName = taskIncluded.inheritedFrom
            ? `${taskIncluded.taskName} (${this.translateService.instant('EQUIPMENT_TASK_RESULTS.STR_INHERITED')} ${taskIncluded.inheritedFrom})`
            : taskIncluded.taskName;
        }
      });
    });

    this.otherTaskIncluded = structuredClone(temporalListOfOtherTaskIncluded) as ITask[];

    this.otherTaskIncluded.forEach((otherService: ITask) => {
      otherService.included = false;
      otherService.nextNew = this.calculateNewTaskIntervalDate(otherService) as string;
      otherService.taskName = otherService.inheritedFrom
        ? `${otherService.taskName} (${this.translateService.instant('EQUIPMENT_TASK_RESULTS.STR_INHERITED')} ${otherService.inheritedFrom})`
        : otherService.taskName;
    });
  }

  checkIfTaskIsChangedWithFormDirty(): void {
    let checklistFormControlDirty: string[] = [];
    const areIncluded: ITask[] = this.otherTaskIncluded.filter((service: ITask) => service.included);
    let selectedChecklist: IChecklist;

    this.completeChecklistForm.get('taskData')?.markAsPristine();

    Object.keys(this.completeChecklistForm.controls)?.forEach((controlName: string) => {
      if (this.completeChecklistForm.get(controlName)?.dirty) {
        checklistFormControlDirty.push(controlName);
      }
    });

    if (checklistFormControlDirty.length > 0 || areIncluded.length > 0 || this.areFilesUploaded) {
      this.openConfirmChangeTasks = true;
      checklistFormControlDirty = [];

      return;
    }

    this.isLoading = true;

    this.createCompleteChecklistForm();

    this.availableChecklists.forEach((elem: { serviceId: string; checklist: IChecklist }) => {
      if (elem.serviceId === this.selectedServices.serviceId) {
        selectedChecklist = elem.checklist;
      }
    });

    this.selectedChecklist = selectedChecklist;

    this.defaultServiceInterval = structuredClone(this.selectedServices);
    this.completeChecklistForm.patchValue({
      taskData: this.defaultServiceInterval,
      checklistName: selectedChecklist.name,
      checklistDescription: selectedChecklist.description,
    });
    this.processChecklistItemsData(selectedChecklist.items);

    this.isLoading = false;
  }

  getOtherTaskIntervals(event: ITask[] | any[]): void {
    this.otherTaskIncluded = event;
  }

  hasUnsavedData(): boolean {
    return this.completeChecklistForm.dirty || this.areFilesUploaded;
  }

  removeImages(): void {
    if (this.areFilesUploaded) {
      this.areFilesUploaded = false;
      this.deleteImagesSubject.next();
    }
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();

    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (this.router.url !== 'workshop/tasks' && this.modalRef && this.modalRef['data'] === 'isOpen') {
      this.modalRef.close('');
    }

    sessionStorage.removeItem(WorkshopConstants.tasksIncluded);
    sessionStorage.removeItem(WorkshopConstants.otherTasks);

    this.deleteImagesSubject.complete();
    this.equipmentCRUDActions.resetSelectedEquipmentState();
  }
}
