import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { ITableSettings, ITableSettingsUpdate } from 'src/app/common/models/table.model';
import { ILocalizationProfile } from '../../models/localization-profile.model';
import { IMeasurementUnit } from './../../models/localization-profile.model';
import { SettingsActionTypes } from './settings-action-types';

export class RequestLocalizedOptions implements Action {
  readonly type: SettingsActionTypes.REQUEST_LOCALIZED_OPTIONS = SettingsActionTypes.REQUEST_LOCALIZED_OPTIONS;
}

export class ReceiveLocalizedOptions implements Action {
  readonly type: SettingsActionTypes.RECEIVE_LOCALIZED_OPTIONS = SettingsActionTypes.RECEIVE_LOCALIZED_OPTIONS;

  constructor(public payload: ILocalizationProfile[]) {}
}

export class ErrorReceiveLocalizedOptions implements Action {
  readonly type: SettingsActionTypes.ERROR_RECEIVE_LOCALIZED_OPTIONS =
    SettingsActionTypes.ERROR_RECEIVE_LOCALIZED_OPTIONS;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestUserSettings implements Action {
  readonly type: SettingsActionTypes.REQUEST_GET_USER_SETTINGS = SettingsActionTypes.REQUEST_GET_USER_SETTINGS;
}

export class ReceiveUserSettings implements Action {
  readonly type: SettingsActionTypes.RECEIVE_GET_USER_SETTINGS = SettingsActionTypes.RECEIVE_GET_USER_SETTINGS;

  constructor(public payload: ILocalizationProfile) {}
}

export class ErrorUserSettings implements Action {
  readonly type: SettingsActionTypes.ERROR_GET_USER_SETTINGS = SettingsActionTypes.ERROR_GET_USER_SETTINGS;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestSaveUserSettings implements Action {
  readonly type: SettingsActionTypes.REQUEST_SAVE_USER_SETTINGS = SettingsActionTypes.REQUEST_SAVE_USER_SETTINGS;

  constructor(public payload: ILocalizationProfile) {}
}

export class ReceiveSaveUserSettings implements Action {
  readonly type: SettingsActionTypes.RECEIVE_SAVE_USER_SETTINGS = SettingsActionTypes.RECEIVE_SAVE_USER_SETTINGS;
}

export class ErrorSaveUserSettings implements Action {
  readonly type: SettingsActionTypes.ERROR_SAVE_USER_SETTINGS = SettingsActionTypes.ERROR_SAVE_USER_SETTINGS;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestMeasurementUnits implements Action {
  readonly type: SettingsActionTypes.REQUEST_MEASUREMENT_UNITS = SettingsActionTypes.REQUEST_MEASUREMENT_UNITS;
}

export class ReceiveMeasurementUnits implements Action {
  readonly type: SettingsActionTypes.RECEIVE_MEASUREMENT_UNITS = SettingsActionTypes.RECEIVE_MEASUREMENT_UNITS;

  constructor(public payload: IMeasurementUnit[]) {}
}

export class ErrorReceiveMeasurementUnits implements Action {
  readonly type: SettingsActionTypes.ERROR_RECEIVE_MEASUREMENT_UNITS =
    SettingsActionTypes.ERROR_RECEIVE_MEASUREMENT_UNITS;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestTableColumns implements Action {
  readonly type: SettingsActionTypes.REQUEST_TABLE_COLUMNS = SettingsActionTypes.REQUEST_TABLE_COLUMNS;

  constructor(public payload: string) {}
}

export class ReceiveTableColumns implements Action {
  readonly type: SettingsActionTypes.RECEIVE_TABLE_COLUMNS = SettingsActionTypes.RECEIVE_TABLE_COLUMNS;

  constructor(public payload: ITableSettings) {}
}

export class ErrorReceiveTableColumns implements Action {
  readonly type: SettingsActionTypes.ERROR_RECEIVE_TABLE_COLUMNS = SettingsActionTypes.ERROR_RECEIVE_TABLE_COLUMNS;

  constructor(public payload: HttpErrorResponse) {}
}
export class ResetTableColumns implements Action {
  readonly type: SettingsActionTypes.RESET_TABLE_COLUMN = SettingsActionTypes.RESET_TABLE_COLUMN;
}

export class RequestUpdateTableColumn implements Action {
  readonly type: SettingsActionTypes.REQUEST_UPDATE_TABLE_COLUMN = SettingsActionTypes.REQUEST_UPDATE_TABLE_COLUMN;

  constructor(public payload: ITableSettingsUpdate) {}
}

export class ReceiveUpdateTableColumn implements Action {
  readonly type: SettingsActionTypes.RECEIVE_UPDATE_TABLE_COLUMN = SettingsActionTypes.RECEIVE_UPDATE_TABLE_COLUMN;
}

export class ErrorReceiveUpdateTableColumn implements Action {
  readonly type: SettingsActionTypes.ERROR_RECEIVE_UPDATE_TABLE_COLUMN =
    SettingsActionTypes.ERROR_RECEIVE_UPDATE_TABLE_COLUMN;

  constructor(public payload: HttpErrorResponse) {}
}
