<p-columnFilter
  *ngIf="column?.field === customFilter && column.filterType === filterType.MULTI_SELECT"
  field="column?.field"
  matchMode="in"
  [showMenu]="false"
  [showClearButton]="false"
>
  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
    <odx-form-field [variant]="'simple'" odxLayout="6@phone 12">
      <p-multiSelect
        #multiSelectFilter
        ngDefaultControl
        data-test-id="table.multiselect_filter"
        [dropdownIcon]="dropdownIconCSSClass"
        class="table-multiSelect-filter"
        optionLabel="label"
        appendTo="body"
        [ngModel]="selectActiveOptions && selectActiveOptions[column?.field]"
        [options]="multiSelectOptions || column.dropdownOptions"
        [emptyMessage]="'TABLE.STR_NO_RESULTS_FOUND' | translate"
        [filter]="true"
        [resetFilterOnHide]="true"
        [placeholder]="column.filterPlaceholder | translate"
        (onChange)="onMultiSelectChange($event.value)"
      >
        <ng-template let-option pTemplate="item">
          <div class="p-multiselect-representative-option">
            <odx-icon
              *ngIf="option?.icon"
              [name]="option?.icon"
              size="inline"
              class="multiselect-icon-option"
            ></odx-icon>
            <span class="p-ms-1">{{ option?.label | translate }}</span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <button
            type="button"
            class="p-multiselect-close p-link p-multiselect-custom-close-btn"
            (click)="multiSelectFilter.hide(); onMultiSelectRemoveSelectedItems()"
            data-test-id="table.multiselect_remove_selected_items"
          >
            <span class="p-multiselect-close-icon pi pi-times"></span>
          </button>
        </ng-template>
        <ng-template let-value pTemplate="selectedItems">
          <div *ngIf="value !== null && value?.length === 1">
            <div *ngFor="let val of value" class="ui-multiselected-item-token ui-corner-all">
              <odx-icon *ngIf="val?.icon" [name]="val.icon" size="inline" class="multiselect-icon-option"></odx-icon>
              <span>{{ val?.label | translate }}</span>
            </div>
          </div>
          <ignis-multiple-items-selected [value]="value"></ignis-multiple-items-selected>
          <span *ngIf="!value || value?.length === 0" class="ui-multiselected-empty-token ui-corner-all">
            {{ column.filterPlaceholder | translate }}
          </span>
        </ng-template>
      </p-multiSelect>
    </odx-form-field>
  </ng-template>
</p-columnFilter>
