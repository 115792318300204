import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IFeaturesToggleState, IFeatureToggle } from './../models/features-toggle.model';
import { AddFeaturesToggle } from './features-toggle-action-types-creators';

@Injectable({ providedIn: 'root' })
export class FeaturesToggleActions {
  constructor(private store: Store<IFeaturesToggleState>) { }

  public setFeaturesToggle(featuresToggle: IFeatureToggle[]): void {
    this.store.dispatch(new AddFeaturesToggle(featuresToggle));
  }

}
