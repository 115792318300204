import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpHelper } from './../../common/services/http-helper/http-helper';
import { INewUser, IUpdateUser, IUser, IUserPage, IUserPersonalInfo, IUserRole } from './../models/user.model';

@Injectable()
export class UserService {
  private baseUrl: string = environment.API_URLS.USERS;

  constructor(
    private httpClient: HttpClient,
    private httpHelper: HttpHelper,
  ) {}

  public getUserPage(params?: any): Observable<IUserPage | HttpErrorResponse> {
    return this.httpClient.get<IUserPage>(`${this.baseUrl}/users`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: { ...params },
    });
  }

  public getUserById(aggregateId: string | any): Observable<{ body: IUser } | HttpErrorResponse> {
    return this.httpClient
      .get<{ body: IUser }>(`${this.baseUrl}/users/${aggregateId.payload}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      })
      .pipe(
        tap((response: HttpResponseBase | any) => {
          /* istanbul ignore next */
          if (response.body) {
            response.body.version = response.headers?.get('etag');
          }
        }),
      );
  }

  public getUsersPersonalInfo(ids: string[]): Observable<IUserPersonalInfo[] | HttpErrorResponse> {
    const queryIds: string = ids.map((id: string) => `ids=${id}`).join('&');

    return this.httpClient.get<IUserPersonalInfo[]>(`${this.baseUrl}/users/personal-info?${queryIds}`);
  }

  public addUser(newUser: INewUser | any): Observable<INewUser | HttpErrorResponse> {
    return this.httpClient.post<INewUser>(`${this.baseUrl}/users`, newUser.payload, this.httpHelper.options);
  }

  public updateUser(updateUser: IUpdateUser | any): Observable<IUpdateUser | HttpErrorResponse> {
    const user: IUpdateUser = { ...updateUser.payload };
    const userId: string = user.aggregateId;
    const version: number = user.version;

    delete user.aggregateId;
    delete user.version;

    return this.httpClient.put<IUpdateUser>(
      `${this.baseUrl}/users/${userId}`,
      user,
      this.httpHelper.optionsWithIfMatch(version),
    );
  }

  public deleteUser(userPayload: any): Observable<any | HttpErrorResponse> {
    const user: IUser = userPayload.payload;
    const version: number = user.version;

    return this.httpClient.delete<void>(
      `${this.baseUrl}/users/${user.aggregateId}`,
      this.httpHelper.optionsWithIfMatch(version),
    );
  }

  public getUserRoleList(): Observable<IUserRole[] | HttpErrorResponse> {
    return this.httpClient.get<Array<IUserRole>>(`${this.baseUrl}/roles`, this.httpHelper.options);
  }

  public uploadCSVFile(file: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.post<any>(`${this.baseUrl}/users/import`, file.payload);
  }
}
