<ng-template odxModal #ramConnectorAutoUpdateModalTemplate>
  <odx-modal-header data-test-id="equipment_ram_connector_auto_update.close_confirmation_modal">
    <odx-area-header>
      <odx-icon
        [name]="type === notificationType.ERROR ? notificationType.ERROR : notificationType.WARNING"
        size="xlarge"
      ></odx-icon>
      @if (type === notificationType.ERROR) {
        {{ 'EQUIPMENT_RAM_CONNECTOR_AUTO_UPDATE.MODAL.STR_INCOMPATIBLE_VERSION_TITLE' | translate }}
      } @else {
        {{ 'EQUIPMENT_RAM_CONNECTOR_AUTO_UPDATE.MODAL.STR_OUTDATED_VERSION_TITLE' | translate }}
      }
    </odx-area-header>
  </odx-modal-header>
  <odx-modal-content>
    <div class="ram-auto-update-modal-content">
      <ng-content select="[slot=content]"></ng-content>
    </div>
  </odx-modal-content>
  <odx-modal-footer>
    <button
      odxButton
      variant="secondary"
      odxModalClose
      (click)="cancelChange()"
      data-test-id="equipment_ram_connector_auto_update.modal.cancel_btn"
    >
      {{ 'EQUIPMENT_RAM_CONNECTOR_AUTO_UPDATE.MODAL.STR_CANCEL_BTN' | translate }}
    </button>

    <button
      odxButton
      variant="primary"
      [odxModalClose]="true"
      (click)="proceedChange()"
      [odxLoadingSpinner]="isLoading"
      data-test-id="equipment_ram_connector_auto_update.modal.proceed_btn"
    >
      {{ 'EQUIPMENT_RAM_CONNECTOR_AUTO_UPDATE.MODAL.STR_PROCEED_BTN' | translate }}
    </button>
  </odx-modal-footer>
</ng-template>
