<p
  #columnText
  class="table-ellipsis-data"
  [odxTooltip]="!disableTooltip ? null : columnData.data"
  odxTooltipSize="auto"
  (mouseover)="calculateToDisableTooltip()"
  data-test-id="table.ellipsis"
>
  {{ columnData.data }}
</p>
