import { IEntryModel, PropertyBag } from 'src/app/common/models/common.model';
import { INotificationMessage } from 'src/app/common/state/notifications/models/notification.model';
import { DeviceConnectionNotificationConstants } from './device-connection.notification.constants';

export class DeviceConnectionConstants {
  public static readonly savedDeviceConfigurationID: string = 'savedDeviceConfigurationID';
  public static readonly deviceCategories: PropertyBag = {
    API_KEY: 'api-key',
    APP: 'app',
    HUB: 'hub',
    WORKSHOP: 'workshop',
    RAM_CONNECTOR: 'ram-connector',
  };

  public static readonly deviceConnectionCategories: Partial<IEntryModel[]> = [
    {
      value: this.deviceCategories.APP,
      label: 'App',
      localizedName: 'DEVICE_CONNECTION.STR_APP',
      icon: 'tablet',
    },
    {
      value: this.deviceCategories.HUB,
      label: 'Hub',
      localizedName: 'DEVICE_CONNECTION.STR_HUB',
      icon: 'fg-hub',
    },
    {
      value: this.deviceCategories.WORKSHOP,
      label: 'Drägerware Workshop',
      localizedName: 'DEVICE_CONNECTION.STR_WORKSHOP',
      icon: 'pc',
    },
    {
      value: this.deviceCategories.RAM_CONNECTOR,
      label: 'RAM connector',
      localizedName: 'DEVICE_CONNECTION.STR_RAM_CONNECTOR',
      icon: 'test-eq-connection',
    },
    {
      value: this.deviceCategories.API_KEY,
      label: 'API Key',
      localizedName: 'DEVICE_CONNECTION.STR_API_KEY',
      icon: 'password',
    },
  ];

  public static readonly deviceCategoryNotificationMap: { [category: string]: INotificationMessage } = {
    'api-key': DeviceConnectionNotificationConstants.notificationCodes.DELETE_API_KEY_DEVICE_CONNECTION_SUCCESS,
    app: DeviceConnectionNotificationConstants.notificationCodes.DELETE_APP_DEVICE_CONNECTION_SUCCESS,
    hub: DeviceConnectionNotificationConstants.notificationCodes.DELETE_HUB_DEVICE_CONNECTION_SUCCESS,
    workshop: DeviceConnectionNotificationConstants.notificationCodes.DELETE_WORKSHOP_DEVICE_CONNECTION_SUCCESS,
    'ram-connector':
      DeviceConnectionNotificationConstants.notificationCodes.DELETE_RAM_CONNECTOR_DEVICE_CONNECTION_SUCCESS,
  };

  public static readonly createEditModalTabs = {
    GENERAL: 'GENERAL',
    MIGRATION: 'MIGRATION',
  };

  public static readonly protectorSoftwareMigrationStatuses: PropertyBag = {
    FINISHED: 'FINISHED',
    IN_PROGRESS: 'IN_PROGRESS',
    NOT_STARTED: 'NOT_STARTED',
    MIGRATING_EQUIPMENT: 'MIGRATING_EQUIPMENT',
    CHECKING_EQUIPMENT_MIGRATION_COMPLETION: 'CHECKING_EQUIPMENT_MIGRATION_COMPLETION',
    MIGRATING_TASK_RESULTS: 'MIGRATING_TASK_RESULTS',
    CHECKING_TASK_RESULTS_MIGRATION_COMPLETION: 'CHECKING_TASK_RESULTS_MIGRATION_COMPLETION',
    COLLECTING_MIGRATION_RESULTS: 'COLLECTING_MIGRATION_RESULTS',
    COMPLETED_WITH_SUCCESS: 'COMPLETED_WITH_SUCCESS',
    COMPLETED_WITH_ERROR: 'COMPLETED_WITH_ERROR',
    CANCELED: 'CANCELED',
  };

  public static readonly migrationErrorsKeyMap: PropertyBag = {
    0: 'DEVICE_CONNECTION_MIGRATION.STR_TABLE_UNEXPECTED_ERROR',
    1: 'DEVICE_CONNECTION_MIGRATION.STR_TABLE_PROCESSING_ERROR',
    1001: 'DEVICE_CONNECTION_MIGRATION.STR_TABLE_IDENTIFIER_MISSING_ERROR',
    1002: 'DEVICE_CONNECTION_MIGRATION.STR_TABLE_IDENTIFIER_NOT_UNIQUE_ERROR',
    1003: 'DEVICE_CONNECTION_MIGRATION.STR_TABLE_BARCODE_NOT_UNIQUE_ERROR',
    1004: 'DEVICE_CONNECTION_MIGRATION.STR_TABLE_SERIAL_NUMBER_NOT_UNIQUE_ERROR',
    1005: 'DEVICE_CONNECTION_MIGRATION.STR_TABLE_TASK_NAME_MISSING_ERROR',
    1006: 'DEVICE_CONNECTION_MIGRATION.STR_TABLE_TASK_NAME_NOT_UNIQUE_ERROR',
    1007: 'DEVICE_CONNECTION_MIGRATION.STR_TABLE_HIERARCHY_ERROR',
    1008: 'DEVICE_CONNECTION_MIGRATION.STR_TABLE_MAXIMUM_ASSET_ERROR',
    2001: 'DEVICE_CONNECTION_MIGRATION.STR_TABLE_TASK_RESULT_ALREADY_EXISTS_ERROR',
  };

  public static readonly entryTypeMap: PropertyBag = {
    EQUIPMENT: 'DEVICE_CONNECTION_MIGRATION.STR_TABLE_EQUIPMENT_TYPE',
    TASK_RESULT: 'DEVICE_CONNECTION_MIGRATION.STR_TABLE_TASK_RESULT_TYPE',
  };
}
