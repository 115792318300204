<ignis-table
  class="protector-migration-error-table"
  [tableName]="tableName"
  [localColumns]="localTableColumns"
  [tableColumns]="tableColumns"
  [dataTable]="errors"
  [customColumnsLayout]="['errorMessage']"
  [customColumnsRef]="[errorMessage]"
  [lazy]="'true'"
  [paginator]="'true'"
  [pageNumber]="pageNumber"
  [first]="first"
  [resetPagining]="false"
  [totalRecords]="totalRecords"
  [displayFilters]="false"
  (handleOnPageChange)="tableHelperOnPageChange($event); savePageNumber($event)"
  (handleTableRef)="tableHelperGetTableRef($event)"
>
  <ng-template #errorMessage let-errorMessage>
    <div *ngIf="errorMessage.field === 'errorMessage'">
      <ignis-protector-migrate-error-table-column
        [errorMessages]="errorMessage.data"
      ></ignis-protector-migrate-error-table-column>
    </div>
  </ng-template>
</ignis-table>
