<div
  class="row odx-form-field--horizontal odx-form-field"
  [ngClass]="{ 'odx-form-field--required': item.required }"
  [formGroup]="form"
>
  <div class="col-6">
    <label class="odx-form-field__label" [for]="item.id">
      <odx-form-field-label class="odx-form-field-label">
        {{ item.name | translate }}
      </odx-form-field-label>
      @if (item.required) {
        <span class="odx-form-field__label-is-required ng-star-inserted">*</span>
      }
    </label>
  </div>

  <div class="col-6">
    <a
      odxButton
      variant="secondary"
      (click)="uploadFileBtn.click()"
      class="upload-file-btn mb-3"
      data-test-id="complete_checklist.upload_file_btn"
      [odxLoadingSpinner]="(uploadInProgress$ | async) && uploadFileInput.id === item.name"
    >
      <span>
        <odx-icon name="upload"></odx-icon>
        {{ 'COMPLETE_CHECKLIST.STR_UPLOAD_IMAGE' | translate }}
      </span>
    </a>
    <input
      type="file"
      class="custom-file-input form-control"
      [id]="item.name"
      [attr.data-test-id]="item.name"
      multiple
      #uploadFileBtn
      hidden
      accept=".png, .jpg, .jpeg"
      (change)="uploadFiles($event)"
    />
    <input type="hidden" [formControlName]="item.id" />

    <div *ngFor="let filename of item.itemValue?.mediaLinks; let fileIndex = index" class="uploaded-image">
      <p [title]="filename">{{ filename }}</p>
      <div class="relative" #self>
        <odx-icon
          *ngIf="filename"
          [pTooltip]="'COMPLETE_CHECKLIST.STR_REMOVE_BTN_TOOLTIP' | translate"
          tooltipPosition="left"
          [appendTo]="self"
          name="close"
          [attr.data-test-id]="item.name"
          class="remove-uploaded-file-btn"
          (click)="removeUploadedFile(filename, fileIndex)"
        ></odx-icon>
      </div>
    </div>
  </div>
</div>
