import { ITableColumn } from 'src/app/common';
import { TableFilterType } from 'src/app/common/constants';

export const tableColumns: ITableColumn[] = [
  {
    field: 'category.value',
    header: 'DEVICE_CONNECTION.STR_TABLE_CATEGORY',
    visible: true,
    filterType: TableFilterType.MULTI_SELECT,
    filterKey: ['category'],
    filterPlaceholder: 'DEVICE_CONNECTION_TABLE.SEARCH_CATEGORY',
  },
  {
    field: 'identification',
    header: 'DEVICE_CONNECTION.STR_TABLE_IDENTIFICATION',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'DEVICE_CONNECTION_TABLE.SEARCH_IDENTIFICATION',
  },
  {
    field: 'name',
    header: 'DEVICE_CONNECTION.STR_TABLE_NAME',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'DEVICE_CONNECTION_TABLE.SEARCH_NAME',
  },
  {
    field: 'assignedFireStation',
    header: 'DEVICE_CONNECTION.STR_TABLE_ASSIGNED_FIRE_STATION',
    visible: false,
    filterType: TableFilterType.MULTI_SELECT,
    filterKey: ['assignedFireStation'],
    filterPlaceholder: 'DEVICE_CONNECTION_TABLE.SEARCH_ASSIGNED_FIRE_STATION',
  },
  {
    field: 'assignedVehicle',
    header: 'DEVICE_CONNECTION.STR_TABLE_ASSIGNED_VEHICLE',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'DEVICE_CONNECTION_TABLE.SEARCH_ASSIGNED_VEHICLE',
  },
  {
    field: 'assignedPrimaryUser',
    header: 'DEVICE_CONNECTION.STR_TABLE_ASSIGNED_PRIMARY_USER',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'DEVICE_CONNECTION_TABLE.SEARCH_ASSIGNED_PRIMARY_USER',
  },
  {
    field: 'firmwareVersion',
    header: 'DEVICE_CONNECTION.STR_TABLE_SOFTWARE_VERSION',
    visible: true,
  },
  {
    field: 'displayedRegistration',
    header: 'DEVICE_CONNECTION.STR_TABLE_REGISTRATION_DATE_TIME',
    visible: true,
    filterType: TableFilterType.DATE,
    filterKey: ['minRegistrationTimestamp', 'maxRegistrationTimestamp'],
  },
  {
    field: 'displayedLastUpload',
    header: 'DEVICE_CONNECTION.STR_TABLE_LAST_UPLOAD_DATE_TIME',
    visible: true,
    filterType: TableFilterType.DATE,
    filterKey: ['minLastUploadTimestamp', 'maxLastUploadTimestamp'],
  },
];
