import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IStoreApiList } from 'src/app/common/models/store-api-list.model';
import { IFireStationList } from '../../../common';
import { ApplicationState, IApplicationState } from '../../../common/state/models/app.state.model';
import {
  IDeviceConnection,
  IDeviceConnectionToken,
  IDevicePage,
  IMigrationID,
} from '../../models/device-connection.model';
import { IProtectorMigrationErrorPage, IProtectorMigrationStatus } from '../../models/protector-migration.model';
import { IDeviceConnectionState } from '../models/device-connection.model';
import { IDeviceConnectionLicenseInfo } from './../../models/device-connection.model';

const deviceConnectionsState = (state: IApplicationState) => state.deviceConnections;

export const selectDeviceConnectionPage: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IDevicePage>,
  DefaultProjectorFn<IStoreApiItem<IDevicePage>>
> = createSelector(deviceConnectionsState, (state: IDeviceConnectionState) => state.deviceConnectionData.devicePage);

export const selectDeviceConnection: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IDeviceConnection>,
  DefaultProjectorFn<IStoreApiItem<IDeviceConnection>>
> = createSelector(
  deviceConnectionsState,
  (state: IDeviceConnectionState) => state.deviceConnectionData.selectedDeviceConnection,
);

export const selectDeviceConnectionIdentifier: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IDeviceConnectionToken>,
  DefaultProjectorFn<IStoreApiItem<IDeviceConnectionToken>>
> = createSelector(
  deviceConnectionsState,
  (state: IDeviceConnectionState) => state.deviceConnectionData.deviceConnectionIdentifier,
);

export const selectDeletedDeviceConnection: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IDeviceConnection>,
  DefaultProjectorFn<IStoreApiItem<IDeviceConnection>>
> = createSelector(
  deviceConnectionsState,
  (state: IDeviceConnectionState) => state.deviceConnectionData.deletedDeviceConnection,
);

export const selectUpdatedDeviceConnectionAuthToken: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<any>,
  DefaultProjectorFn<IStoreApiItem<any>>
> = createSelector(
  deviceConnectionsState,
  (state: IDeviceConnectionState) => state.deviceConnectionData.deviceConnectionAuthToken,
);

export const selectDeviceConnectionCategories: MemoizedSelector<
  ApplicationState,
  IStoreApiList<any>,
  DefaultProjectorFn<IStoreApiList<any>>
> = createSelector(
  deviceConnectionsState,
  (state: IDeviceConnectionState) => state.deviceConnectionData.deviceConnectionCategories,
);

export const selectToCheckRAMConnectorToken: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<any>,
  DefaultProjectorFn<IStoreApiItem<any>>
> = createSelector(
  deviceConnectionsState,
  (state: IDeviceConnectionState) => state.ramConnectorData.checkRAMConnectorToken,
);

export const selectDeviceConnectionLicenseInfo: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IDeviceConnectionLicenseInfo>,
  DefaultProjectorFn<IStoreApiItem<IDeviceConnectionLicenseInfo>>
> = createSelector(
  deviceConnectionsState,
  (state: IDeviceConnectionState) => state.deviceConnectionLicenseInfo.licenseInfo,
);

export const selectDeviceConnectionAssignedFireStations: MemoizedSelector<
  ApplicationState,
  IStoreApiList<IFireStationList>,
  DefaultProjectorFn<IStoreApiList<IFireStationList>>
> = createSelector(
  deviceConnectionsState,
  (state: IDeviceConnectionState) => state.deviceConnectionData.deviceConnectionAssignedFireStations,
);

export const selectStartProtectorSoftwareMigration: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IMigrationID>,
  DefaultProjectorFn<IStoreApiItem<IMigrationID>>
> = createSelector(
  deviceConnectionsState,
  (state: IDeviceConnectionState) => state.protectorSoftwareMigration.startProtectorMigration,
);

export const selectCancelProtectorSoftwareMigrationInRAM: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<unknown>,
  DefaultProjectorFn<IStoreApiItem<unknown>>
> = createSelector(
  deviceConnectionsState,
  (state: IDeviceConnectionState) => state.protectorSoftwareMigration.cancelProtectorMigrationInRAM,
);

export const selectCancelProtectorSoftwareMigration: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<unknown>,
  DefaultProjectorFn<IStoreApiItem<unknown>>
> = createSelector(
  deviceConnectionsState,
  (state: IDeviceConnectionState) => state.protectorSoftwareMigration.cancelProtectorMigration,
);

export const selectProtectorMigrationStatus: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IProtectorMigrationStatus>,
  DefaultProjectorFn<IStoreApiItem<IProtectorMigrationStatus>>
> = createSelector(
  deviceConnectionsState,
  (state: IDeviceConnectionState) => state.protectorSoftwareMigration.protectorMigrationStatus,
);

export const selectProtectorMigrationErrorPage: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IProtectorMigrationErrorPage>,
  DefaultProjectorFn<IStoreApiItem<IProtectorMigrationErrorPage>>
> = createSelector(
  deviceConnectionsState,
  (state: IDeviceConnectionState) => state.protectorSoftwareMigration.protectorMigrationErrorPage,
);
