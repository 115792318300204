import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { IMonitoringIncidents } from '../../../models/remote-monitoring.model';

@Component({
  selector: 'ignis-incident-statistics',
  templateUrl: './incident-statistics.component.html',
  styleUrls: ['./incident-statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncidentStatisticsComponent implements OnChanges, AfterViewInit {
  @Input() incidents: IMonitoringIncidents;

  runningIncidentsNo: number;
  firemanInRunningIncidentsNo: number;
  endedIncidentsNo: number;
  firemanInEndedIncidentsNo: number;
  scrollbarExists: boolean = false;

  constructor(public router: Router) {}

  ngOnChanges(): void {
    if (this.incidents) {
      this.runningIncidentsNo = this.incidents.incidentsRunningNo;
      this.firemanInRunningIncidentsNo = this.incidents.firemenInIncidentRunningNo;
      this.endedIncidentsNo = this.incidents.incidentsEndedNo;
      this.firemanInEndedIncidentsNo = this.incidents.firemenInIncidentEndedNo;

      this.checkExistsScrollbar(0);
    }
  }

  ngAfterViewInit(): void {
    window.addEventListener(
      'resize',
      () => {
        this.checkExistsScrollbar(0);
      },
      true,
    );
  }

  checkExistsScrollbar(ms: number): void {
    const tileContainer: HTMLElement = document.getElementsByClassName('incidents-grid-row')[0] as HTMLElement;

    setTimeout(() => {
      if (tileContainer?.scrollHeight > tileContainer?.clientHeight) {
        this.scrollbarExists = true;
      } else {
        this.scrollbarExists = false;
      }
    }, ms);
  }

  getTeamsNo(no: number): string {
    if (no < 10) {
      return `0${no}`;
    }

    return no?.toString();
  }
}
