import { createSelector, DefaultProjectorFn, MemoizedSelector, Selector } from '@ngrx/store';
import { ApplicationState, IApplicationState, IStoreApiItem } from 'src/app/common';
import { ILicensesState, ILicenseStatus } from '../models/licenses.model';

export const licensesState: Selector<IApplicationState, ILicensesState> = (state: IApplicationState) => state.licenses;

export const selectLicenseStatus: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<ILicenseStatus>,
  DefaultProjectorFn<IStoreApiItem<ILicenseStatus>>
> = createSelector(licensesState, (state: ILicensesState) => state.licenseStatus);

export const selectTicketID: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<ILicenseStatus>,
  DefaultProjectorFn<IStoreApiItem<ILicenseStatus>>
> = createSelector(licensesState, (state: ILicensesState) => state.ticketID);
