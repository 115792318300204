import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonConstants, NotificationConstants } from '../../constants';
import { NotificationsService } from '../notifications/notifications.service';

@Injectable({
  providedIn: 'root',
})
export class CSVService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly notificationsService: NotificationsService,
    private readonly translateService: TranslateService,
  ) {}

  public downloadFile(apiURL: string, fileName: string): void {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'text/csv',
    });

    this.httpClient.get(apiURL, { headers, responseType: 'blob' }).subscribe({
      next: (blob: Blob) => {
        const url: string = window.URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement('a');

        a.href = url;
        a.download = `${this.translateService.instant(fileName)}.csv`;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      },
      error: () => {
        this.notificationsService.requestShowNotification(
          CommonConstants.notificationType.ERROR,
          NotificationConstants.commonCodes.DOWNLOAD_CSV_ERROR,
          NotificationConstants.commonCodes,
        );
      },
    });
  }
}
