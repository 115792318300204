import { KeyValue } from '@angular/common';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective, ModalRef, ModalService, ModalSize, ModalVariant } from '@odx/angular/components/modal';
import orderBy from 'lodash-es/orderBy';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { OnDestroyMixin } from 'src/app/common/mixins';
import { ConfigurationConstants, ConfigurationNotificationConstants } from 'src/app/configuration/constants';
import { EquipmentHierarchyActions } from 'src/app/configuration/state/actions';
import { selectImportEquipmentHierarchy } from 'src/app/configuration/state/selectors/configuration.selectors';
import { SettingsActions } from 'src/app/settings';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import {
  CommonConstants,
  DropdownService,
  IApplicationState,
  IEntryModel,
  IStoreApiItem,
  NotificationsService,
} from 'src/app/common';

@Component({
  selector: 'ignis-import-equipment-hierarchy-modal',
  templateUrl: './import-equipment-hierarchy-modal.component.html',
})
export class ImportEquipmentHierarchyModalComponent extends OnDestroyMixin() implements OnChanges {
  @Input() isOpen: boolean = false;

  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
  modalReference: ModalRef<any, any>;

  @ViewChild('importEQHierarchyModal', { read: TemplateRef })
  public importEQHierarchyModal: TemplateRef<ModalDirective>;

  modalService: ModalService = inject(ModalService);
  settingsActions: SettingsActions = inject(SettingsActions);
  equipmentHierarchyActions: EquipmentHierarchyActions = inject(EquipmentHierarchyActions);
  dropdownService: DropdownService = inject(DropdownService);
  translateService: TranslateService = inject(TranslateService);
  notificationsService: NotificationsService = inject(NotificationsService);
  store: Store<IApplicationState> = inject(Store<IApplicationState>);

  importEQHierarchyClosedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  modalState: Observable<boolean> = this.importEQHierarchyClosedSubject.asObservable();
  languages: KeyValue<string, string>[];
  selectedLanguage: DropdownChangeEvent;
  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpen && this.isOpen) {
      this.modalReference = this.modalService.open(this.importEQHierarchyModal, {
        size: ModalSize.SMALL,
        variant: ModalVariant.DEFAULT,
        dismissable: false,
        data: 'isOpen',
      });

      this.modalReference.onClose$.pipe(takeUntil(this.destroy)).subscribe(() => {
        this.closeModal.emit(false);
        this.destroy.next();
        this.importEQHierarchyClosedSubject.next(true);
      });

      this.modalReference.onDismiss$.pipe(takeUntil(this.destroy)).subscribe(() => {
        this.closeModal.emit(false);
        this.importEQHierarchyClosedSubject.next(true);
        this.destroy.next();
      });

      this.settingsActions.requestLocalizedOptions();
      this.populateDropdownWithAvailableLanguages();
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this.modalState.pipe(takeUntil(this.destroy)).subscribe((state: boolean) => {
      this.closeModal.emit(state);
      this.modalReference?.close('');
    });
  }

  populateDropdownWithAvailableLanguages(): void {
    this.languages = ConfigurationConstants.importEquipmentHierarchyLanguagesMap.map((lng: IEntryModel) => {
      return {
        key: this.translateService.instant(lng.localizedName),
        value: lng.value,
      };
    });

    this.languages = orderBy(this.languages, ['key'], ['asc']);
  }

  importEquipmentHierarchy(): void {
    this.equipmentHierarchyActions.requestImportEquipmentHierarchy(this.selectedLanguage.value);

    this.readImportEquipmentHierarchyResponseFromStore();
  }

  readImportEquipmentHierarchyResponseFromStore(): void {
    this.store
      .select(selectImportEquipmentHierarchy)
      .pipe(
        filter((importResponse: IStoreApiItem<unknown>) => !importResponse.isLoading),
        take(1),
      )
      .subscribe((importStatus: IStoreApiItem<unknown>) => {
        if (importStatus.errors) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            importStatus.errors.error?.code ||
              ConfigurationNotificationConstants.notificationCodes.IMPORT_HIERARCHY_FAIL,
            ConfigurationNotificationConstants.notificationCodes,
          );

          return;
        }

        this.notificationsService.requestShowNotification(
          CommonConstants.notificationType.SUCCESS,
          ConfigurationNotificationConstants.notificationCodes.IMPORT_HIERARCHY_SUCCESS,
          ConfigurationNotificationConstants.notificationCodes,
        );

        this.equipmentHierarchyActions.requestEquipmentHierarchy();
      });
  }
}
