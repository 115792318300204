import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { HttpHelper } from 'src/app/common/services/http-helper/http-helper';
import { IEntriesModel } from 'src/app/report-center/models/incident.model';
import {
  IIncident,
  IIncidentEcp,
  IIncidentsIds,
  IMonitoringIncidents,
  INewNote,
  INote,
} from '../models/remote-monitoring.model';
import { environment } from './../../../environments/environment';
import { IRemoveIncident } from './../models/remote-monitoring.model';

@Injectable({
  providedIn: 'root',
})
export class RemoteMonitoringService {
  baseUrl: string = environment.API_URLS.REMOTE_MONITORING;

  constructor(
    private httpClient: HttpClient,
    private httpHelper: HttpHelper,
  ) {}

  public getIncidents(): Observable<IMonitoringIncidents | HttpErrorResponse> {
    return this.httpClient.get<IMonitoringIncidents>(`${this.baseUrl}/incidents`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

  public getIncidentById(id: string): Observable<{ body: IIncident | HttpErrorResponse }> {
    return this.httpClient
      .get<IIncident>(`${this.baseUrl}/incidents/${id}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      })
      .pipe(
        tap((response: HttpResponseBase | any) => {
          /* istanbul ignore next */
          if (response.body) {
            response.body.version = response.headers?.get('etag');
          }
        }),
      );
  }

  public getIncidentEcpById(incidentId?: string, ecpId?: string): Observable<IIncidentEcp | HttpErrorResponse> {
    return this.httpClient.get<IIncidentEcp>(
      `${this.baseUrl}/incidents/${incidentId}/ecps/${ecpId}/teams`,
      this.httpHelper.options,
    );
  }

  public getNotesList(id: string): Observable<INote[] | HttpErrorResponse> {
    return this.httpClient.get<INote[]>(`${this.baseUrl}/incidents/${id}/notes`, this.httpHelper.options);
  }

  public setReadNotes(
    id: string,
    notes: Partial<INote>[],
    incidentVersion: number,
  ): Observable<string[] | HttpErrorResponse> {
    return this.httpClient.put<string[]>(
      `${this.baseUrl}/incidents/${id}/notes`,
      notes,
      this.httpHelper.optionsWithIfMatch(incidentVersion),
    );
  }

  public getIncidentEntries(): Observable<IEntriesModel | HttpErrorResponse> {
    return this.httpClient.get<IEntriesModel>(
      `${environment.API_URLS.REPORT_CENTER}/incidents/filter-options`,
      this.httpHelper.options,
    );
  }

  public removeIncident(
    incident: IRemoveIncident,
    incidentVersion: number,
  ): Observable<IRemoveIncident | HttpErrorResponse> {
    const incidentToBeRemoved: IRemoveIncident = { ...incident };
    const incidentId: string = incidentToBeRemoved.incidentAggregateId;

    delete incidentToBeRemoved.incidentAggregateId;

    return this.httpClient.put<IRemoveIncident>(
      `${this.baseUrl}/incidents/${incidentId}/close`,
      incidentToBeRemoved,
      this.httpHelper.optionsWithIfMatch(incidentVersion),
    );
  }

  public mergeIncidents(
    incidentsIds: IIncidentsIds | any,
    incidentVersion: number,
  ): Observable<IIncident[] | IIncidentsIds | HttpErrorResponse> {
    return this.httpClient.put<IIncidentsIds>(
      `${this.baseUrl}/incidents/merge`,
      incidentsIds.payload,
      this.httpHelper.optionsWithIfMatch(incidentVersion),
    );
  }

  public addNote(newNote: INewNote | any): Observable<INewNote | HttpErrorResponse> {
    const noteData = structuredClone(newNote.payload);

    delete noteData.senderName;

    return this.httpClient.post<INewNote>(
      `${this.baseUrl}/incidents/notes`,
      noteData,
      this.httpHelper.optionsWithIfMatch(newNote.payload.version),
    );
  }
}
