<form [formGroup]="organizationSettingsForm" class="org-settings-section-ml-25">
  <section odxLayout="grid" formGroupName="ramSettings">
    <h2 odxLayout="12" class="org-form-label">
      {{ 'ORGANIZATION_SETTINGS.STR_AVAILABLE_PRE_DEFINED_WORKFLOW' | translate }}
    </h2>

    <div odxLayout="12 12@desktop">
      <odx-checkbox
        formControlName="cleaningWorkflow"
        data-test-id="organization_settings.available_pre_defined_workflow"
      >
        {{ 'ORGANIZATION_SETTINGS.STR_CLEANING_WORKFLOW' | translate }}
      </odx-checkbox>
    </div>

    <div odxLayout="12 12@desktop">
      <odx-checkbox
        formControlName="cylinderChargingWorkflow"
        data-test-id="organization_settings.cylinder_charge_workflow"
      >
        {{ 'ORGANIZATION_SETTINGS.STR_CYLINDER_CHARGE_WORKFLOW' | translate }}
      </odx-checkbox>
    </div>

    <div odxLayout="12 12@desktop">
      <odx-checkbox
        formControlName="automaticTestingWorkflow"
        data-test-id="organization_settings.automatic_test_workflow"
      >
        {{ 'ORGANIZATION_SETTINGS.STR_AUTOMATIC_TEST_WORKFLOW' | translate }}
      </odx-checkbox>
    </div>

    <div odxLayout="12 12@desktop">
      <odx-checkbox formControlName="checklistWorkflow" data-test-id="organization_settings.checklist_workflow">
        {{ 'ORGANIZATION_SETTINGS.STR_CHECKLIST_WORKFLOW' | translate }}
      </odx-checkbox>
    </div>
  </section>
</form>
