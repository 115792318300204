<div [formGroup]="form" [readonly]="true">
  <odx-form-field
    formGroupName="checklist"
    variant="horizontal"
    label="{{ 'EQUIPMENT_TASK_RESULTS.STR_CHECKLIST_NAME' | translate }}"
  >
    <input
      type="text"
      odxFormFieldControl
      id="checklist-name"
      data-test-id="equipment_task_documentation.checklist_name"
      formControlName="name"
    />
  </odx-form-field>

  <odx-form-field variant="horizontal" label="{{ 'EQUIPMENT_TASK_RESULTS.STR_LOCATION' | translate }}">
    <input
      type="text"
      odxFormFieldControl
      id="locationPath"
      data-test-id="equipment_task_documentation.location"
      formControlName="locationPath"
    />
  </odx-form-field>

  <odx-form-field
    formGroupName="checklist"
    variant="horizontal"
    label="{{ 'EQUIPMENT_TASK_RESULTS.STR_CHECKLIST_DESCRIPTION' | translate }}"
  >
    <textarea
      odxFormFieldControl
      id="checklist-description"
      data-test-id="equipment_task_documentation.checklist_description"
      formControlName="description"
      class="custom-form-textarea"
    >
    </textarea>
  </odx-form-field>
</div>
