import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { EquipmentServiceTaskActions } from 'src/app/workshop/state/actions/equipment-service-task';

export const batchChecklistResolver: ResolveFn<boolean> = (route: ActivatedRouteSnapshot) => {
  batchChecklistResolverFn(route);

  return true;
};

export const batchChecklistResolverFn = (route: ActivatedRouteSnapshot): boolean => {
  const equipmentServiceTaskActions: EquipmentServiceTaskActions = inject(EquipmentServiceTaskActions);
  const checklistId: string[] = (route.params.id as string).split(',');

  equipmentServiceTaskActions.requestChecklistsData(checklistId);

  return true;
};
