import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from 'redux';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { WorkshopService } from 'src/app/workshop/services';
import * as models from '../../../models';
import * as actions from '../../actions/ram';

@Injectable()
export class EquipmentRAMEffects {
  workshopService: WorkshopService = inject(WorkshopService);

  requestCheckRAMConnectorStatus$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.RAMActionTypes.REQUEST_CHECK_RAM_CONNECTOR_STATUS),
      switchMap((params) =>
        this.workshopService.checkRAMConnectorStatus(params.payload).pipe(
          map(
            (connectorStatus: HttpResponse<models.ITestStatus>) =>
              new actions.ReceiveCheckRAMConnectorStatus(connectorStatus?.body),
          ),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveCheckRAMConnectorStatus(error))),
        ),
      ),
    );
  });

  requestCheckRAMPortStatus$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.RAMActionTypes.REQUEST_CHECK_RAM_PORT_STATUS),
      switchMap((params) =>
        this.workshopService.checkRAMPortStatus(params.payload).pipe(
          map((portStatus: HttpResponse<models.IRAMStatus>) => new actions.ReceiveCheckRAMPortStatus(portStatus?.body)),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveCheckRAMPortStatus(error))),
        ),
      ),
    );
  });

  requestRAMConnectorToken$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.RAMActionTypes.REQUEST_RAM_CONNECTOR_TOKEN),
      switchMap(() =>
        this.workshopService.getRAMConnectorToken().pipe(
          map((token: models.IToken) => new actions.ReceiveRAMConnectorToken(token)),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveRAMConnectorToken(error))),
        ),
      ),
    );
  });

  requestRAMAutoUpdate$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.RAMActionTypes.REQUEST_RAM_AUTO_UPDATE),
      switchMap(() =>
        this.workshopService.startAutoUpdate().pipe(
          map((result: object) => new actions.ReceiveRAMAutoUpdate(result)),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveRAMAutoUpdate(error))),
        ),
      ),
    );
  });
}
