import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ReplaceDigitCharPipe } from 'src/app/common';
import { getDecimalNumberFormatUserProfile } from 'src/app/common/utils';
import { IChecklistItem } from 'src/app/configuration/models';
import { WorkshopConstants } from 'src/app/workshop/constants';

@Component({
  selector: 'ignis-checklist-numeric-type',
  styleUrls: ['./checklist-numeric-type.component.scss'],
  templateUrl: './checklist-numeric-type.component.html',
  providers: [ReplaceDigitCharPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistNumericTypeComponent implements OnInit {
  @Input() item: IChecklistItem;
  @Input() form: FormGroup;
  decimalNumberFormat: string;

  translateService: TranslateService = inject(TranslateService);

  ngOnInit(): void {
    this.decimalNumberFormat = getDecimalNumberFormatUserProfile(this.translateService);
  }

  checkOutOfRange(): void {
    const current: number = this.form.get([this.item.id]).value as number;
    const min: number = this.item.itemValue.minimum;
    const max: number = this.item.itemValue.maximum;

    if (current === null) return;

    if ((max !== null && current > max) || (min !== null && current < min)) {
      this.form.get('operationalStatus').patchValue(WorkshopConstants.operationalStatuses.NON_OPERATIONAL);
    }
  }
}
