<ng-container *ngIf="entry" [ngSwitch]="entry.value">
  <ng-container *ngSwitchCase="'test'">
    {{ 'EVENT_TYPE_TRANSLATION.TEST' | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="'incident'">
    {{ 'EVENT_TYPE_TRANSLATION.INCIDENT' | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="'training'">
    {{ 'EVENT_TYPE_TRANSLATION.TRAINING' | translate }}
  </ng-container>
  <ng-container *ngSwitchDefault>{{ entry.value }}</ng-container>
</ng-container>
