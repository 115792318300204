import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IChecklistItem } from 'src/app/configuration/models';

@Component({
  selector: 'ignis-checklist-boolean-type',
  templateUrl: './checklist-boolean-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistBooleanTypeComponent {
  @Input() item: IChecklistItem;
  @Input() form: FormGroup;
}
