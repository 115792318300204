export interface IBAWearer {
  aggregateId: string;
  personalId: string;
  firstName: string;
  lastName: string;
  fireStation: string;
  rfidCard: string;
  version: number;
  lastUpdate: string;
  displayLastUpdate?: string;
}

export class BAWearer implements IBAWearer {
  aggregateId: string;
  personalId: string;
  firstName: string;
  lastName: string;
  fireStation: string;
  rfidCard: string;
  version: number;
  lastUpdate: string;

  constructor();
  constructor(baWearer: BAWearer);
  constructor(baWearer?: BAWearer) {
    if (!baWearer) {
      this.aggregateId = null;
      this.personalId = null;
      this.firstName = null;
      this.lastName = null;
      this.fireStation = null;
      this.rfidCard = null;
      this.version = null;
      this.lastUpdate = null;
    } else {
      this.aggregateId = baWearer.aggregateId;
      this.personalId = baWearer.personalId;
      this.firstName = baWearer.firstName;
      this.lastName = baWearer.lastName;
      this.fireStation = baWearer.fireStation;
      this.rfidCard = baWearer.rfidCard;
      this.version = baWearer.version;
      this.lastUpdate = baWearer.lastUpdate;
    }
  }
}

export interface IBAWearerPage {
  currentPage: number;
  entries: IBAWearer[];
  pageSize: number;
  totalPages: number;
  totalRecords: number;
}

export class BAWearerPage implements IBAWearerPage {
  currentPage: number;
  entries: IBAWearer[];
  pageSize: number;
  totalPages: number;
  totalRecords: number;

  constructor();
  constructor(baWearerPage: IBAWearerPage);
  constructor(baWearerPage?: IBAWearerPage) {
    if (!baWearerPage) {
      this.currentPage = null;
      this.entries = null;
      this.pageSize = null;
      this.totalPages = null;
      this.totalRecords = null;
    } else {
      this.currentPage = baWearerPage.currentPage;
      this.entries = baWearerPage.entries.map((wearer: IBAWearer) => new BAWearer(wearer));
      this.pageSize = baWearerPage.pageSize;
      this.totalPages = baWearerPage.totalPages;
      this.totalRecords = baWearerPage.totalRecords;
    }
  }
}

export interface ICreateUpdateBAWearer {
  personalId: string;
  firstName: string;
  lastName: string;
  fireStation: string;
  rfidCard: number;
  version?: number;
  aggregateId?: string;
}

export class CreateUpdateBAWearer implements ICreateUpdateBAWearer {
  personalId: string;
  firstName: string;
  lastName: string;
  fireStation: string;
  rfidCard: number;

  constructor();
  constructor(baWearer: ICreateUpdateBAWearer);
  constructor(baWearer?: ICreateUpdateBAWearer) {
    if (!baWearer) {
      this.personalId = null;
      this.firstName = null;
      this.lastName = null;
      this.fireStation = null;
      this.rfidCard = null;
    } else {
      this.personalId = baWearer.personalId;
      this.firstName = baWearer.firstName;
      this.lastName = baWearer.lastName;
      this.fireStation = baWearer.fireStation;
      this.rfidCard = baWearer.rfidCard;
    }
  }
}

export interface IDeleteBAWearer {
  aggregateId: string;
  version: number;
}

export class DeleteBAWearer implements IDeleteBAWearer {
  aggregateId: string;
  version: number;

  constructor();
  constructor(baWearer: IDeleteBAWearer);
  constructor(baWearer?: IDeleteBAWearer) {
    if (!baWearer) {
      this.aggregateId = null;
      this.version = null;
    } else {
      this.aggregateId = baWearer.aggregateId;
      this.version = baWearer.version;
    }
  }
}
