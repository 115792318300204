import { HttpErrorResponse } from '@angular/common/http';
import { GenericAction } from './../../state/models/generic-action.model';
import { IStoreApiItem } from '../../models/store-api-item.model';
import { IStoreApiList } from '../../models/store-api-list.model';

const httpError: HttpErrorResponse = new HttpErrorResponse({ error: 'http error' });

export const testListApiPartOfReducer = <StateType, DataType>(
  reducer: (lastState: StateType, action: GenericAction<string, DataType | HttpErrorResponse>) => StateType,
  initialState: StateType,
  actionTypes: { request: string; receive: string; error: string },
  pathToListInsideReducer: string[]
) =>
  describe(`${pathToListInsideReducer.toString()} list`, () => {
    it('request list data should set isLoading true while requesting list', () => {
      const requestListAction: GenericAction<string, any> = new GenericAction(actionTypes.request, null);
      const state: StateType = reducer(initialState, requestListAction);

      expect(getStateByPath<StateType, IStoreApiList<DataType>>(state, pathToListInsideReducer).isLoading).toBe(true);
    });

    it('receive list data should set isLoading false while receiving list', () => {
      const receiveListAction: GenericAction<string, any> = new GenericAction(actionTypes.receive, null);
      const state: StateType = reducer(initialState, receiveListAction);

      expect(getStateByPath<StateType, IStoreApiList<DataType>>(state, pathToListInsideReducer).isLoading).toBe(false);
    });

    it('error list data should set isLoading false when a error is caught while requesting list', () => {
      const errorReceiveListAction: GenericAction<string, any> = new GenericAction(actionTypes.error, httpError);
      const state: StateType = reducer(initialState, errorReceiveListAction);

      expect(getStateByPath<StateType, IStoreApiList<DataType>>(state, pathToListInsideReducer).isLoading).toBe(false);
    });
  });

export const testGetItemApiPartOfReducer = <StateType, DataType>(
  reducer: (lastState: StateType, action: GenericAction<string, DataType | HttpErrorResponse>) => StateType,
  initialState: StateType,
  actionTypes: { request: string; receive: string; error: string },
  pathToItemInsideReducer: string[]
) =>
  describe(`${pathToItemInsideReducer.toString()} item`, () => {
    it('request item data should set isLoading true while requesting item', () => {
      const requestItemAction: GenericAction<string, any> = new GenericAction(actionTypes.request, null);
      const state: StateType = reducer(initialState, requestItemAction);

      expect(getStateByPath<StateType, IStoreApiItem<DataType>>(state, pathToItemInsideReducer).isLoading).toBe(true);
    });

    it('receive item data should set isLoading false while receiving item', () => {
      const receiveItemAction: GenericAction<string, any> = new GenericAction(actionTypes.receive, null);
      const state: StateType = reducer(initialState, receiveItemAction);

      expect(getStateByPath<StateType, IStoreApiItem<DataType>>(state, pathToItemInsideReducer).isLoading).toBe(false);
    });

    it('error item data should set isLoading false when a error is caught', () => {
      const errorReceiveItemAction: GenericAction<string, any> = new GenericAction(actionTypes.error, httpError);
      const state: StateType = reducer(initialState, errorReceiveItemAction);

      expect(getStateByPath<StateType, IStoreApiItem<DataType>>(state, pathToItemInsideReducer).isLoading).toBe(false);
    });
  });

export const testPutPostDeleteItemApiPartOfReducer = <StateType, DataType>(
  reducer: (lastState: StateType, action: GenericAction<string, DataType | HttpErrorResponse>) => StateType,
  initialState: StateType,
  actionTypes: { request: string; receive: string; error: string },
  pathToItemInsideReducer: string[]
) =>
  describe(`${pathToItemInsideReducer.toString()} item`, () => {
    it('request put post delete item data should set isSuccess null while request to add', () => {
      const requestItemAction: GenericAction<string, any> = new GenericAction(actionTypes.request, false);
      const state: StateType = reducer(initialState, requestItemAction);

      expect(getStateByPath<StateType, IStoreApiItem<DataType>>(state, pathToItemInsideReducer).isSuccess).toBe(false);
    });

    it('receive put post delete item data should set isSuccess true while receive a successful add action', () => {
      const receiveItemAction: GenericAction<string, any> = new GenericAction(actionTypes.receive, null);
      const state: StateType = reducer(initialState, receiveItemAction);

      expect(getStateByPath<StateType, IStoreApiItem<DataType>>(state, pathToItemInsideReducer).isSuccess).toBe(true);
    });

    it('error put post delete item data should set isSuccess false when an error is caught', () => {
      const errorReceiveItemAction: GenericAction<string, any> = new GenericAction(actionTypes.error, httpError);
      const state: StateType = reducer(initialState, errorReceiveItemAction);

      expect(getStateByPath<StateType, IStoreApiItem<DataType>>(state, pathToItemInsideReducer).isSuccess).toBe(false);
    });
  });

const getStateByPath: <StateType, FinalPathType>(state: StateType, path: string[]) => FinalPathType = <
  StateType,
  DataType
>(
  state: StateType,
  path: string[]
) => {
  let iteratedBranch: any = state;

  path.forEach((pathPart: string) => {
    iteratedBranch = { ...iteratedBranch[pathPart] };
  });

  return iteratedBranch as DataType;
};
