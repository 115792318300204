import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnChanges, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CheckingSettingsModalsService, GenericFooterItemsComponent } from 'src/app/common';
import { IEntryModel, PropertyBag } from 'src/app/common/models';
import { ITask } from 'src/app/configuration/models';
import { AccessControlService } from 'src/app/root/';
import { AppUserPermissionList } from 'src/app/root/models';
import { WorkshopConstants, WorkshopModuleRoutes } from 'src/app/workshop/constants';

@Component({
  selector: 'ignis-create-update-equipment-view-footer',
  templateUrl: './create-update-equipment-view-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateUpdateEquipmentViewFooterComponent<T> extends GenericFooterItemsComponent<T> implements OnChanges {
  @Input() disableCylinderCharghingBtn: boolean;
  @Input() disableStartTestBtn: boolean;
  @Input() isSubmitDisabled: boolean = false;
  @Input() isModalOpened: boolean;
  @Input() selectedTask: ITask;

  @Output() handleCylinderCharge: EventEmitter<void> = new EventEmitter<void>();
  @Output() handleStartTesting: EventEmitter<void> = new EventEmitter<void>();
  @Output() handleCompleteChecklist: EventEmitter<void> = new EventEmitter<void>();
  @Output() handleHistory: EventEmitter<void> = new EventEmitter<void>();

  equipmentSaveTooltip: string;
  permissions: string[] = [];

  showSaveButtonTooltip: boolean = false;

  appUserPermissionList: PropertyBag = AppUserPermissionList;
  readonly WorkshopModuleRoutes: PropertyBag = WorkshopModuleRoutes;

  public router: Router = inject(Router);
  public translateService: TranslateService = inject(TranslateService);
  public accessControlService: AccessControlService = inject(AccessControlService);
  checkSettingsModal: CheckingSettingsModalsService = inject(CheckingSettingsModalsService);

  constructor() {
    super();

    this.permissions = this.accessControlService.permissions;

    this.checkSettingsModal.checkIfSettingsModalIsOpen().subscribe((isModalOpened: boolean) => {
      this.isModalOpened = isModalOpened;
      this.cdr.detectChanges();
    });
  }

  ngOnChanges(): void {
    let inCompletedTabs: string[] = this.mandatoryUncompletedFields?.map(
      (field: string) =>
        this.translateService.instant(
          WorkshopConstants.tabs.find((t: IEntryModel) => t.value === field)?.localizedName || field,
        ) as string,
    );

    inCompletedTabs = inCompletedTabs?.filter((item: string, index: number, inputArray: string[]) => {
      return inputArray.indexOf(item) === index;
    });

    this.equipmentSaveTooltip = (this.translateService.instant('SAVE_BUTTON_TOOLTIP.STR_SHOW') +
      inCompletedTabs?.map((tab: string) => {
        return '\n - ' + tab;
      })) as string;

    this.permissions = this.accessControlService.permissions;
  }

  onCylinderCharge(e: Event): void {
    e.stopPropagation();
    this.handleCylinderCharge.emit();
  }

  onPerformCompleteChecklistClick(e: Event): void {
    e.stopPropagation();
    this.handleCompleteChecklist.emit();
  }

  onStartTestingClick(e: Event): void {
    e.stopPropagation();
    this.handleStartTesting.emit();
  }

  onHistoryClick(e: Event): void {
    e.stopPropagation();
    this.handleHistory.emit();
  }
}
