import { ITask } from 'src/app/configuration/models';

export interface IServiceTask {
  equipmentAggregateId?: string;
  equipmentId: string;
  barcode: string;
  rfid: string;
  serialNo: string;
  category: string;
  type: string;
  operationalStatus: string;
  taskName: string;
  owner: string;
  location: string;
  nextServiceDate: Date | string;
  lastServiceDate: Date | string;
  duration: number;
  intervalId: string;
  serviceId: string;
  model: string;
  isCylinderType: boolean;
  maxPressure: number;
  hasCheckList: boolean;
  isProtectorTestType: boolean;
  uniqueId?: string;
}

export class ServiceTask implements IServiceTask {
  equipmentAggregateId: string;
  equipmentId: string;
  barcode: string;
  rfid: string;
  serialNo: string;
  category: string;
  type: string;
  operationalStatus: string;
  taskName: string;
  owner: string;
  location: string;
  nextServiceDate: Date | string;
  lastServiceDate: Date | string;
  intervalId: string;
  serviceId: string;
  model: string;
  isCylinderType: boolean;
  maxPressure: number;
  duration: number;
  hasCheckList: boolean;
  isProtectorTestType: boolean;

  constructor();
  constructor(serviceTask: IServiceTask);
  constructor(serviceTask?: IServiceTask) {
    if (!serviceTask) {
      this.equipmentAggregateId = null;
      this.equipmentId = null;
      this.barcode = null;
      this.rfid = null;
      this.serialNo = null;
      this.category = null;
      this.type = null;
      this.operationalStatus = null;
      this.nextServiceDate = null;
      this.taskName = null;
      this.owner = null;
      this.location = null;
      this.lastServiceDate = null;
      this.intervalId = null;
      this.serviceId = null;
      this.model = null;
      this.isCylinderType = null;
      this.maxPressure = null;
      this.duration = null;
      this.hasCheckList = null;
      this.isProtectorTestType = null;
    } else {
      this.equipmentAggregateId = serviceTask.equipmentAggregateId;
      this.equipmentId = serviceTask.equipmentId;
      this.barcode = serviceTask.barcode;
      this.rfid = serviceTask.rfid;
      this.serialNo = serviceTask.serialNo;
      this.category = serviceTask.category;
      this.type = serviceTask.type;
      this.operationalStatus = serviceTask.operationalStatus;
      this.nextServiceDate = serviceTask.nextServiceDate;
      this.taskName = serviceTask.taskName;
      this.owner = serviceTask.owner;
      this.location = serviceTask.location;
      this.lastServiceDate = serviceTask.lastServiceDate;
      this.intervalId = serviceTask.intervalId;
      this.serviceId = serviceTask.serviceId;
      this.model = serviceTask.model;
      this.isCylinderType = serviceTask.isCylinderType;
      this.maxPressure = serviceTask.maxPressure;
      this.duration = serviceTask.duration;
      this.hasCheckList = serviceTask.hasCheckList;
      this.isProtectorTestType = serviceTask.isProtectorTestType;
    }
  }
}

export interface IServiceTaskPage {
  currentPage: number;
  entries: IServiceTask[];
  pageSize: number;
  totalPages: number;
  totalRecords: number;
}

export class ServiceTaskPage implements IServiceTaskPage {
  currentPage: number;
  entries: IServiceTask[];
  pageSize: number;
  totalPages: number;
  totalRecords: number;

  constructor();
  constructor(serviceTask: IServiceTaskPage);
  constructor(serviceTask?: IServiceTaskPage) {
    if (!serviceTask) {
      this.currentPage = null;
      this.entries = null;
      this.pageSize = null;
      this.totalPages = null;
      this.totalRecords = null;
    } else {
      this.currentPage = serviceTask.currentPage;
      this.entries = serviceTask.entries.map((service: IServiceTask) => new ServiceTask(service));
      this.pageSize = serviceTask.pageSize;
      this.totalPages = serviceTask.totalPages;
      this.totalRecords = serviceTask.totalRecords;
    }
  }
}

export interface ILocationAssignment {
  aggregateId: string;
  identifier: string;
  name: string;
  type: string;
  address: string;
  details: string;
}

export class LocationAssignment implements ILocationAssignment {
  aggregateId: string;
  identifier: string;
  name: string;
  type: string;
  address: string;
  details: string;

  constructor();
  constructor(locationAssignment: ILocationAssignment);
  constructor(locationAssignment?: ILocationAssignment) {
    if (!locationAssignment) {
      this.aggregateId = null;
      this.identifier = null;
      this.name = null;
      this.type = null;
      this.address = null;
      this.details = null;
    } else {
      this.aggregateId = locationAssignment.aggregateId;
      this.identifier = locationAssignment.identifier;
      this.name = locationAssignment.name;
      this.type = locationAssignment.type;
      this.address = locationAssignment.address;
      this.details = locationAssignment.details;
    }
  }
}

export interface ILocationAssignmentDTO {
  aggregateId: string;
  name?: string;
  identifier?: string;
}

export interface ILocationChange {
  locationAssignment: ILocationAssignmentDTO;
  equipmentAggregateIds: string[];
  version?: number;
}

export class LocationChange implements ILocationChange {
  locationAssignment: ILocationAssignmentDTO;
  equipmentAggregateIds: string[];
  reason: string;
  version?: number;

  constructor();
  constructor(locationChange: ILocationChange);
  constructor(locationChange?: ILocationChange) {
    if (!locationChange) {
      this.locationAssignment = null;
      this.equipmentAggregateIds = null;
      this.version = null;
    } else {
      this.locationAssignment = locationChange.locationAssignment;
      this.equipmentAggregateIds = locationChange.equipmentAggregateIds;
      this.version = locationChange.version;
    }
  }
}

export interface ILocationId {
  id: string;
  name: string;
}

export interface IIntervalData {
  others: ITask[];
  includedTasks: ITask[];
  equipmentAggregateId: string;
  equipmentIdentification: string;
  equipmentBarcode: string;
  rfid: string;
  serialNo: string;
  equipmentCategory: string;
  equipmentType: string;
  equipmentModel: string;
  operationalStatus: boolean | string;
  isCylinderType: boolean;
  hasCheckList?: boolean;
  maxPressure?: number;
  outOfServiceDate: string;
  outOfServiceReason: string;
  location?: string;
  locationPath?: ILocationId[];
}

export interface ICylinderChargingData {
  comment: string;
  pressure: number;
  airQualityMeasured: boolean;
  taskName: string;
}

export interface IServiceValues {
  description: string;
  assessment: string;
  value: number;
  minValue: number;
  maxValue: number;
  unit: string;
  parameters: string;
}

export class ServiceValues implements IServiceValues {
  description: string;
  assessment: string;
  value: number;
  minValue: number;
  maxValue: number;
  unit: string;
  parameters: string;
  constructor();
  constructor(serviceValues: IServiceValues);
  constructor(serviceValues?: IServiceValues) {
    if (!serviceValues) {
      this.description = null;
      this.assessment = null;
      this.value = null;
      this.minValue = null;
      this.maxValue = null;
      this.unit = null;
      this.parameters = null;
    } else {
      this.description = serviceValues.description;
      this.assessment = serviceValues.assessment;
      this.value = serviceValues.value;
      this.minValue = serviceValues.minValue;
      this.maxValue = serviceValues.maxValue;
      this.unit = serviceValues.unit;
      this.parameters = serviceValues.parameters;
    }
  }
}

export interface ICylinderChargerRequest {
  intervalsData: IIntervalData[];
  cylinderChargingData: ICylinderChargingData;
  testerName: string;
}
