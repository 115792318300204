import { DatePipe } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { format, formatISO } from 'date-fns';
import * as FileSaver from 'file-saver';
import { filter, Subscription } from 'rxjs';
import {
  CommonConstants,
  ExportTableExcelService,
  IApplicationState,
  MapService,
  PressureUnitPipe,
} from 'src/app/common';
import { TableExportExcelConstants } from 'src/app/common/constants';
import { EntryModel, IStoreApiItem, PropertyBag } from 'src/app/common/models';
import { ExportActions } from 'src/app/common/state/export/actions/export.actions';
import { ITableExport } from 'src/app/common/state/export/models/export.model';
import { selectTableExportResponse } from 'src/app/common/state/export/selectors/export.selector';
import { ReportCenterConstants } from 'src/app/report-center/constants/report-center.constants';
import { ColInfo, WorkBook, WorkSheet } from 'xlsx/types';
import {
  EcpEvent,
  ExportNote,
  IEcpEvent,
  IExportBASet,
  IExportEcp,
  IExportIncident,
  IExportLocation,
  IExportNote,
  IFirefightersData,
  IIncidentData,
  IIncidentExcelData,
  IIncidentSummary,
} from '../models/incident.model';

@Injectable()
export class ExportExcelService {
  incidentNumber: string;
  incidentStartTime: string;

  public pressureDisplayUnit: string;
  public translatedIncidentSheetName: string;

  dangerCharacters: string[] = ReportCenterConstants.dangerCharacters;
  dataTransKey: PropertyBag = ReportCenterConstants.dataTransKey;
  firefightersTransKey: PropertyBag = ReportCenterConstants.firefightersTransKey;
  locationTransKey: PropertyBag = ReportCenterConstants.locationTransKey;
  notesTransKey: PropertyBag = ReportCenterConstants.notesTransKey;
  ecpTransKeys: PropertyBag = ReportCenterConstants.exportTranslationKeys;
  entryControlPointTransKey: PropertyBag = ReportCenterConstants.entryControlPointTransKey;

  mapService: MapService = inject(MapService);
  exportActions: ExportActions = inject(ExportActions);
  pressureUnitPipe: PressureUnitPipe = inject(PressureUnitPipe);
  translateService: TranslateService = inject(TranslateService);
  store: Store<IApplicationState> = inject(Store<IApplicationState>);
  exportTableExcelService: ExportTableExcelService = inject(ExportTableExcelService);

  private isDst(date: Date): boolean {
    const offset: number = -date.getTimezoneOffset();
    const january: Date = new Date(date.getTime());

    january.setMonth(0);

    const januaryOffset: number = -january.getTimezoneOffset();

    const may: Date = new Date(date.getTime());

    may.setMonth(4);

    const mayOffset: number = -may.getTimezoneOffset();

    return offset > januaryOffset || offset > mayOffset;
  }

  private getLocalTimezone(): string {
    const timeZone: number = new Date().getTimezoneOffset();
    const offset: number = Math.abs(timeZone);

    return (timeZone < 0 ? '+' : '-') + ('00' + Math.floor(offset / 60 - (this.isDst(new Date()) ? 1 : 0))).slice(-2);
  }

  processEntryControlPointRows(ecps: IExportEcp[]): Array<Array<string>> {
    if (!ecps.length) return [[]];

    const locationData: Array<Array<string>> = [
      [this.translateService.instant(this.entryControlPointTransKey.entryControlPoint)],
      [this.translateService.instant(this.entryControlPointTransKey.hubId)],
      [this.translateService.instant(this.entryControlPointTransKey.appName)],
      [this.translateService.instant(this.entryControlPointTransKey.user)],
    ];

    ecps.forEach((entry: IExportEcp) => {
      locationData[1].push(this.sanitizeDangerousCharacters(entry.hubId));
      locationData[2].push(this.sanitizeDangerousCharacters(entry.appName));
      locationData[3].push(this.sanitizeDangerousCharacters(entry.user));
    });

    return locationData;
  }

  processLocationInfoRows(locations: IExportLocation[]): Array<Array<string>> {
    if (!locations.length) return [[]];

    const locationData: Array<Array<string>> = [
      [this.translateService.instant(this.locationTransKey.locationInfo)],
      [this.translateService.instant(this.locationTransKey.coordinates)],
      [this.translateService.instant(this.locationTransKey.address)],
      [this.translateService.instant(this.locationTransKey.postcode)],
    ];

    locations.forEach((entry: IExportLocation) => {
      locationData[1].push(
        this.sanitizeDangerousCharacters(
          this.mapService.convertToDMS(entry.coordinates.lat, entry.coordinates.lng, true),
        ),
      );
      locationData[2].push(this.sanitizeDangerousCharacters(entry.address));
      locationData[3].push(this.sanitizeDangerousCharacters(entry.postcode));
    });

    return locationData;
  }

  processIncidentDataRows(incidentData: IIncidentData, dateFormat: string): Array<Array<string>> {
    const outputData: Array<Array<string>> = [];

    this.incidentNumber = incidentData.incidentNumber;

    Object.entries(incidentData).forEach((entry: [string, any]) => {
      if (this.dataTransKey[entry[0]] && this.dataTransKey[entry[0]].includes('STR')) {
        const translatedKey: string = this.translateService.instant(this.dataTransKey[entry[0]]);

        this.translateIncidentDataZoneStrings(entry);
        this.processIncidentDataRowsDates(entry, dateFormat);

        this.dangerCharacters.forEach((elem: string) => {
          if (entry[1] && typeof entry[1] === 'string' && entry[1].startsWith(elem)) {
            entry[1] = entry[1].replace(elem, `'${elem}`);
          }
        });

        if (Array.isArray(entry[1]) && entry[1].length > 1) {
          entry[1] = entry[1].join(CommonConstants.splitArrayOfStringsRule);
        }

        this.processSummaryField(entry);

        return outputData.push([translatedKey, entry[1]]);
      }
    });

    return [[this.translateService.instant(this.dataTransKey.incidentData)], ...outputData];
  }

  processIncidentFirefightersDataRows(firefightersList: IFirefightersData[]): Array<string[] | string> {
    const processedFirefightersData: Array<Array<string>> = [];
    let translatedKeys: string[] = [];

    firefightersList.forEach((ff: Partial<IFirefightersData>) => {
      Object.keys(ff).forEach((keyToTranslate: string) => {
        translatedKeys.push(this.translateService.instant(this.firefightersTransKey[keyToTranslate]));
      });

      processedFirefightersData.push([ff.personalId, ff.name, ff.teamName, ff.equipmentSerialNumber, ff.usageDuration]);
    });

    translatedKeys = [...new Set(translatedKeys)];

    return [translatedKeys, ...processedFirefightersData];
  }

  processIncidentDataRowsDates(entry: string[], dateFormat: string): void {
    if (entry[1] && [entry[0]][0].includes('start')) {
      this.incidentStartTime = entry[1];
    }

    if (entry[1] && ([entry[0]][0].includes('start') || [entry[0]][0].includes('end'))) {
      entry[1] = `${new DatePipe('en-GB').transform(entry[1], dateFormat)} ${format(new Date(entry[1]), CommonConstants.TIME_WITH_SECONDS_FORMAT)}`;
    }

    if (entry[0].includes('hazmat')) {
      entry[1] = this.translateService.instant(
        Boolean(entry[1]) ? 'INCIDENT_EXCEL.STR_HAZMAT_YES' : 'INCIDENT_EXCEL.STR_HAZMAT_NO',
      );
    }
  }

  private processSummaryField(entry: any): void {
    if (typeof entry[1] === 'object' && [entry[0]][0] === 'summary') {
      const noteworthyAlarmsArray: string[] = [];
      let relevantAlarmsNo: number = 0;

      if (entry[1]?.noteworthyAlarms.length > 0) {
        // eslint-disable-next-line id-blacklist
        const sortedArray: [{ name: string; number: number }] = structuredClone(entry[1].noteworthyAlarms).sort(
          (a: any, b: any) => Number(b.number) - Number(a.number),
        );

        // eslint-disable-next-line id-blacklist
        sortedArray.forEach((elem: { name: string; number: number }) => {
          elem.name = this.translateService.instant(
            ReportCenterConstants.incidentEntries.alarmTypes.find((t: EntryModel) => t.value === elem.name)
              ?.localizedName || elem.name,
          );

          relevantAlarmsNo += elem.number;

          noteworthyAlarmsArray.push(`${elem.number} ${elem.name}`);
        });
      }

      entry[1] = this.buildSummaryFieldText(entry[1], relevantAlarmsNo, noteworthyAlarmsArray);
    }
  }

  buildSummaryFieldText(entry: IIncidentSummary, relevantAlarmsNo: number, noteworthyAlarmsArray: string[]): string {
    const firefighterStringPart: string = this.translateService.instant(
      entry.firefighterNo < 2 ? 'INCIDENT_EXCEL.STR_SINGLE_FIREFIGHTER' : 'INCIDENT_EXCEL.STR_MULTIPLE_FIREFIGHTERS',
    );

    const firefightersMessage: string =
      entry.firefighterNo > 0 ? `${entry.firefighterNo} ${firefighterStringPart} \r\n` : '';

    const baSetStringPart: string = this.translateService.instant(
      entry.baSetNo < 2 ? 'INCIDENT_EXCEL.STR_ONE_BA_SET' : 'INCIDENT_EXCEL.STR_MULTIPLE_BA_SETS',
    );

    const baSetsNoMessage: string = entry.baSetNo > 0 ? `${entry.baSetNo} ${baSetStringPart} \r\n` : '';

    const manualEventsStringPart: string = this.translateService.instant(
      entry.manualEventsNo < 2
        ? 'INCIDENT_EXCEL.STR_SINGLE_MANUALLY_INCIDENT'
        : 'INCIDENT_EXCEL.STR_MULTIPLE_MANUALLY_INCIDENT',
    );

    const manualEventsNoMessage: string =
      entry.manualEventsNo > 0 ? `${entry.manualEventsNo} ${manualEventsStringPart} \r\n` : '';

    const relevantAlarmsStringPart: string = this.translateService.instant(
      relevantAlarmsNo < 2 ? 'INCIDENT_EXCEL.STR_SINGLE_RELEVANT_ALARM' : 'INCIDENT_EXCEL.STR_MULTIPLE_RELEVANT_ALARMS',
    );

    const relevantAlarmsMessage: string =
      relevantAlarmsNo > 0
        ? `${relevantAlarmsNo} ${relevantAlarmsStringPart}  ${noteworthyAlarmsArray.join(
            CommonConstants.splitArrayOfStringsRule,
          )}`
        : '';

    return `${firefightersMessage}${baSetsNoMessage}${manualEventsNoMessage}${relevantAlarmsMessage}`;
  }

  /* istanbul ignore next */
  private translateIncidentDataZoneStrings(entry: string[]): void {
    if (entry[1] && [entry[0]][0].includes('Type')) {
      entry[1] = this.translateService.instant(
        ReportCenterConstants.incidentEntries.types.find((t: EntryModel) => t.value === entry[1])?.localizedName ||
          (entry[1][0]?.toUpperCase() + entry[1]?.slice(1)).replace(CommonConstants.excelCharacterEscaper, ' '),
      );
    }

    if (entry[1] && [entry[0]][0].includes('SpecialExposure')) {
      if (
        ReportCenterConstants.incidentEntries.specialExposures.find((t: EntryModel) => t.value === entry[1])
          ?.localizedName
      ) {
        entry[1] = this.translateService.instant(
          ReportCenterConstants.incidentEntries.specialExposures.find((t: EntryModel) => t.value === entry[1])
            ?.localizedName,
        );
      }
    }
  }

  processECPSheet(ecpSheetData: any[], dateFormat: string, type: string): any {
    if (Array.isArray(ecpSheetData) && !ecpSheetData.length) {
      ecpSheetData = [new EcpEvent()];
    }

    const translatedArray: IEcpEvent[] = [];
    const baSetsNames: string[] = [];

    ecpSheetData.forEach((ecpEvent: IEcpEvent[]) => {
      if (typeof ecpEvent === 'string') {
        baSetsNames.push(ecpEvent);
      }

      if (Array.isArray(ecpEvent) && !ecpEvent.length) {
        ecpEvent = [new EcpEvent()];
      }

      if (Array.isArray(ecpEvent)) {
        ecpEvent.forEach((entry: IEcpEvent) => {
          translatedArray.push(
            this.translateExcelStrings(
              this.sortObjectKeys({ ...entry }, this.ecpTransKeys) as IEcpEvent,
              dateFormat,
              type,
            ),
          );
        });
      } else {
        translatedArray.push(
          this.translateExcelStrings(
            this.sortObjectKeys({ ...(ecpEvent as object) }, this.ecpTransKeys) as IEcpEvent,
            dateFormat,
            type,
          ),
        );
      }
    });

    return [...baSetsNames, translatedArray];
  }

  translateExcelStrings(entry: IEcpEvent, dateFormat: string, type: string): IEcpEvent {
    const isEmptyObject: string = entry.timestamp ? '-' : '';

    entry = {
      ...entry,
      hubId: entry.hubId?.length ? entry.hubId : isEmptyObject,
      timestamp: this.formatTimestamp(entry.timestamp, dateFormat),
    };

    if (type === 'ECP') {
      delete entry.teamName;
    }

    if (entry.event === 'pressure' && Number(entry.data)) {
      entry.data = this.pressureUnitPipe.transform(Number(entry.data), [this.pressureDisplayUnit, true]);
    } else if (entry.event === 'temperature' && Number(entry.data)) {
      entry.data = `${Math.round(Number(entry.data)).toFixed(2)} °C`;
    }

    // eslint-disable-next-line @typescript-eslint/typedef
    entry = Object.entries(entry).reduce((op: any, [key, value]) => {
      let newKey: string = this.ecpTransKeys[key];

      if (newKey === 'INCIDENT_EXCEL.STR_TIMESTAMP') {
        newKey = `${this.translateService.instant(newKey)} [UTC${this.getLocalTimezone()}]`;
      }

      op[(newKey?.includes('STR') ? this.translateService.instant(newKey) : newKey) || key] = value;

      return op;
    }, {});

    return entry;
  }

  processBASetsSheets(baSetsSheetsData: IExportBASet, dateFormat: string): any[] {
    const sheetsData: any[] = Object.entries(baSetsSheetsData);
    const translatedArray: any[] = [];

    sheetsData.forEach((entry: any[]) => {
      translatedArray.push(this.processECPSheet(entry, dateFormat, 'SCBA'));
    });

    translatedArray.forEach((a: any) => {
      a.forEach((b: any) => {
        if (Array.isArray(b)) {
          b.shift();
        }
      });
    });

    return translatedArray;
  }

  processNotesSheets(notesData: IExportNote[] | IEcpEvent[], dateFormat: string): PropertyBag[] {
    const translatedHeaders: PropertyBag = this.translateHeaders(this.notesTransKey);

    return notesData.map((note: IExportNote | IEcpEvent) => {
      const translatedObj: PropertyBag = {};

      note = { ...note, timestamp: this.formatTimestamp(note.timestamp, dateFormat) };

      Object.keys(this.notesTransKey).forEach((key: string) => {
        translatedObj[translatedHeaders[key]] = note[key];
      });

      return translatedObj;
    });
  }

  private processIncidentSheetData(incidentData: IExportIncident, dateFormat: string): any {
    let incidentDataRows: (string | string[])[] = this.processIncidentDataRows(incidentData.incidentData, dateFormat);

    incidentDataRows = [
      incidentDataRows[0],
      incidentDataRows[3],
      CommonConstants.exportNewLine,
      incidentDataRows[1],
      incidentDataRows[2],
      incidentDataRows[8],
      incidentDataRows[5],
      incidentDataRows[4],
      incidentDataRows[12],
      incidentDataRows[6],
      incidentDataRows[7],
      incidentDataRows[9],
      incidentDataRows[10],
      incidentDataRows[11],
      CommonConstants.exportNewLine,
      CommonConstants.exportNewLine,
    ];

    return [
      [CommonConstants.exportNewLine],
      this.processEntryControlPointRows(incidentData.entryControlPoints),
      [CommonConstants.exportNewLine],
      this.processLocationInfoRows(incidentData.locations),
      [CommonConstants.exportNewLine],
      incidentDataRows,
      this.processIncidentFirefightersDataRows(incidentData.firefighters),
    ];
  }

  public exportIncidentExcel(data: IIncidentExcelData, dateFormat: string, onProgress?: any): void | any {
    const incidentSheetData: any[] = this.processIncidentSheetData(data.incident, dateFormat);

    const baSetsSheetsData: any[] = this.processBASetsSheets(data.baSets, dateFormat);
    const notesData: PropertyBag[] = this.processNotesSheets(
      data.notes.length ? data.notes : [new ExportNote()],
      dateFormat,
    );

    if (onProgress) {
      onProgress(true);
    }

    const translatedFileName: string = this.translateService.instant('INCIDENT_EXCEL.FILE_NAME');

    import('xlsx').then((xlsx) => {
      const incident: WorkSheet = xlsx.utils.json_to_sheet([]);
      const incidentColsOptions: ColInfo[] = [];

      this.exportTableExcelService.insertMetaInformationHeader(this.store, incident, dateFormat);

      incidentSheetData.forEach((zone: any) => {
        xlsx.utils.sheet_add_aoa(incident, zone, { origin: -1 });
      });

      Object.keys(incidentSheetData).forEach(() => {
        incidentColsOptions.push({ wpx: TableExportExcelConstants.cellWidthSize.LARGE });
      });
      incident['!cols'] = incidentColsOptions;
      const workbook: WorkBook = {
        Sheets: {
          [this.sanitizeSheetName(this.translatedIncidentSheetName)]: incident,
        },
        SheetNames: [this.sanitizeSheetName(this.translatedIncidentSheetName)],
      };

      Object.keys(data.ecpEvents).forEach((ecp: string) => {
        const dataT: Array<IEcpEvent[]> = this.processECPSheet(data.ecpEvents[ecp], dateFormat, 'ECP');

        const ecpEvents: WorkSheet = xlsx.utils.json_to_sheet(dataT[0]);
        const ecpEventsColsOptions: ColInfo[] = [];

        Object.keys(ecpEvents).forEach(() => {
          ecpEventsColsOptions.push({ wpx: TableExportExcelConstants.cellWidthSize.SMALL });
        });
        ecpEvents['!cols'] = ecpEventsColsOptions;

        const ecpTrans: string = this.translateService.instant('INCIDENT_EXCEL.STR_ECP_SHEET_NAME');
        const appName: string = this.sanitizeSheetName(`${ecpTrans}-${ecp}`);

        workbook.Sheets = { ...workbook.Sheets, [appName]: ecpEvents };
        workbook.SheetNames.push(appName);
      });

      baSetsSheetsData.forEach((entry: any) => {
        const scbaTrans: string = this.translateService.instant('INCIDENT_EXCEL.STR_SCBA_SHEET_NAME');
        const name: string = this.sanitizeSheetName(`${scbaTrans}-${entry[0]}`);
        const sheet: WorkSheet = xlsx.utils.json_to_sheet(entry[1]);

        const baSetsColsOptions: ColInfo[] = [];

        Object.keys(sheet).forEach(() => {
          baSetsColsOptions.push({ wpx: TableExportExcelConstants.cellWidthSize.SMALL });
        });
        sheet['!cols'] = baSetsColsOptions;

        workbook.Sheets = { ...workbook.Sheets, [name]: sheet };
        workbook.SheetNames.push(name);
      });

      const sheet: WorkSheet = xlsx.utils.json_to_sheet(notesData);
      const notesColsOptions: ColInfo[] = [];

      Object.keys(sheet).forEach(() => {
        notesColsOptions.push({ wpx: TableExportExcelConstants.cellWidthSize.SMALL });
      });
      sheet['!cols'] = notesColsOptions;

      const notesSheetName: string = this.sanitizeSheetName(
        this.translateService.instant('INCIDENT_EXCEL.STR_NOTES_SHEET_NAME'),
      );

      workbook.Sheets = { ...workbook.Sheets, [notesSheetName]: sheet };
      workbook.SheetNames.push(notesSheetName);

      const excelBuffer: BlobPart = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      const fileDate: string = formatISO(new Date(this.incidentStartTime), { representation: 'complete' }).split(
        'T',
      )[0];
      const name: string = this.incidentNumber
        ? `${this.incidentNumber}_${translatedFileName}_${fileDate}`
        : `${translatedFileName}_${fileDate}`;

      this.saveAsExcelFile(excelBuffer, name);
    });

    if (onProgress) {
      onProgress(false);
    }
  }

  exportTaskResultExcel(data: Array<Array<string>>, fileName: string, formatDate: string, sheetName: string): void {
    const serviceColsOptions: ColInfo[] = [];
    const maxColumns: number = data.reduce((max: number, arr: Array<string>) => Math.max(max, arr.length), 0);

    [...Array(maxColumns).keys()].forEach(() => {
      serviceColsOptions.push({ wpx: TableExportExcelConstants.cellWidthSize.MEDIUM });
    });

    import('xlsx').then((xlsx) => {
      const service: WorkSheet = xlsx.utils.json_to_sheet([]);

      this.exportTableExcelService.insertMetaInformationHeader(this.store, service, formatDate);

      data.forEach((element: Array<string>) => {
        xlsx.utils.sheet_add_aoa(service, [element], { origin: -1 });
      });

      service['!cols'] = serviceColsOptions;

      const workbook: WorkBook = { Sheets: { [sheetName]: service }, SheetNames: [sheetName] };
      const excelBuffer: BlobPart = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });

      this.saveAsExcelFile(excelBuffer, fileName);
    });
  }

  saveAsExcelFile(buffer: BlobPart, fileName: string): void {
    const excelExtension: string = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: CommonConstants.excelType,
    });

    this.dangerCharacters.forEach((elem: string) => {
      if (fileName.startsWith(elem)) {
        fileName = fileName.replace(elem, '');
      }
    });

    FileSaver.default.saveAs(data, fileName + excelExtension);
  }

  generateExcelSection(object: PropertyBag, constants: PropertyBag): Array<Array<string>> {
    const keys: string[] = Object.keys(object);
    const translated: Array<Array<string>> = keys.map((key: string) => {
      const translatedKey: string = this.translateService.instant(constants[key]);

      return [translatedKey, object[key] || CommonConstants.exportDash];
    });

    return [...translated, [], []];
  }

  generateExcelTable(tableData: any[], constants: PropertyBag): Array<Array<string>>[] {
    const keys: string[] = Object.keys(constants);
    const tableHeader: Array<Array<string>> = keys.map((key: string) => [
      this.translateService.instant(constants[key]),
    ]);

    const tableBody: Array<Array<Array<string>>> = tableData.map((obj) => keys.map((key: string) => [obj[key]]));

    return [tableHeader, ...tableBody, [], []];
  }

  formatTimestamp(timestamp: string | Date, dateFormat: string): string {
    if (!timestamp) return '';

    const date: string = `${new DatePipe('en-GB').transform(timestamp, dateFormat)}`;
    const time: string = `${format(new Date(timestamp), CommonConstants.TIME_WITH_SECONDS_FORMAT)}`;

    return `${date} ${time}`;
  }

  translateHeaders(entry: PropertyBag): PropertyBag {
    return Object.entries(entry).reduce((op: PropertyBag, [key, value]: [string, string]) => {
      let newValue: string = this.translateService.instant(value);

      if (value === 'INCIDENT_EXCEL.STR_TIMESTAMP') {
        newValue += ` [UTC${this.getLocalTimezone()}]`;
      }

      op[key] = newValue;

      return op;
    }, {});
  }

  sanitizeDangerousCharacters(value: string): string {
    this.dangerCharacters.forEach((elem: string) => {
      if (value?.startsWith(elem)) {
        value = value.replace(elem, `'${elem}`);
      }
    });

    return value ?? CommonConstants.exportDash;
  }

  sanitizeSheetName(name: string): string {
    return name
      .replace(CommonConstants.excelForbiddenCharactersRegExp, CommonConstants.excelCharacterEscaper)
      .substring(0, CommonConstants.excelSheetNameLimit);
  }

  sortObjectKeys(target: Record<string, any>, reference: Record<string, any>): Record<string, any> {
    const sortedObject: Record<string, any> = {};

    Object.keys(reference).forEach((key: string) => {
      if (target.hasOwnProperty(key)) {
        sortedObject[key] = target[key];

        delete target[key];
      }
    });

    Object.keys(target).forEach((key: string) => {
      sortedObject[key] = target[key];
    });

    return sortedObject;
  }

  genericExport(
    getDataCall: (page?: number) => void,
    preparationCall: (value: unknown, index: number, array: unknown[]) => void,
  ): Promise<unknown[]> {
    let subscription: Subscription = new Subscription();

    return new Promise<unknown[]>((resolve: (value: unknown[]) => void) => {
      this.exportActions.startTableExporting();

      const excelArray: unknown[] = [];

      getDataCall();

      let currentPage: number = 0;

      subscription = this.store
        .pipe(
          select(selectTableExportResponse),
          filter((state: IStoreApiItem<ITableExport>) => !state.isLoading && state.isSuccess),
        )
        .subscribe((response: IStoreApiItem<ITableExport>) => {
          if (response.data) {
            const copy: unknown[] = structuredClone(response.data.entries) as unknown[];

            copy.forEach(preparationCall);
            excelArray.push(...copy);

            if (currentPage < response.data.totalPages - 1) {
              getDataCall(++currentPage);
            } else {
              resolve(excelArray);
              subscription?.unsubscribe();
              this.exportActions.resetTableExport();
            }
          }
        });
    });
  }
}
