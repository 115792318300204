<div class="blue-bar-items">
  <button
    type="button"
    [disabled]="
      !form?.valid ||
      disableStartTestBtn ||
      !accessControlService.checkPermission(appUserPermissionList.equipmentTest) ||
      isModalOpened ||
      isSubmitDisabled
    "
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    (click)="onStartTestingClick($event)"
    #testingEquipmentBtn
    id="testing-equipment-btn"
    data-test-id="equipment_footer_items.test_equipment_btn"
    [pTooltip]="startTestButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #startTestButtonTooltipContent>
      @if (accessControlService.checkPermission(appUserPermissionList.equipmentTest)) {
        <div data-test-id="equipment_footer_items.start_test_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_START_TESTING_TOOLTIP' | translate }}
        </div>
      } @else {
        <div data-test-id="equipment_footer_items.start_test_no_permission_tooltip">
          {{ 'EQUIPMENT_FOOTER_ITEMS.STR_NO_PERMISSION' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon name="testing" size="inline"></odx-icon>
  </button>
</div>
