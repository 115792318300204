import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ISaveTestResults, ITestStart } from 'src/app/workshop/models';
import { IEquipmentState } from '../../models';
import {
  RequestEquipmentTestIsRunning,
  RequestSaveTestResults,
  RequestStartTestEquipment,
  RequestTestParameters,
  RequestTestResults,
  RequestWorkflowAsset,
  ResetTestParameters,
  ResetWorkflowAssetState,
} from './equipment-test-action-types-creators';

@Injectable({ providedIn: 'root' })
export class EquipmentTestActions {
  constructor(private store: Store<IEquipmentState>) {}

  public requestEquipmentTestIsRunning(): void {
    this.store.dispatch(new RequestEquipmentTestIsRunning());
  }

  public requestStartTestEquipment(testEquipment: ITestStart): void {
    this.store.dispatch(new RequestStartTestEquipment(testEquipment));
  }

  public requestTestResults(identifier: string): void {
    this.store.dispatch(new RequestTestResults(identifier));
  }

  public requestTestParameters(params: string): void {
    this.store.dispatch(new RequestTestParameters(params));
  }

  public requestSaveTestResults(testResults: ISaveTestResults): void {
    this.store.dispatch(new RequestSaveTestResults(testResults));
  }

  public resetTestParameters(): void {
    this.store.dispatch(new ResetTestParameters());
  }

  public requestWorkflowAsset(barcode: string): void {
    this.store.dispatch(new RequestWorkflowAsset(barcode));
  }

  public resetWorkflowAssetState(): void {
    this.store.dispatch(new ResetWorkflowAssetState());
  }
}
