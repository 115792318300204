import { AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[ignisElementIsVisible]',
})
export class ElementIsVisibleDirective implements AfterViewInit, OnDestroy {
  @Output() visible: EventEmitter<void> = new EventEmitter<void>();
  private observer: IntersectionObserver;

  constructor(private readonly el: ElementRef) {}

  ngAfterViewInit(): void {
    this.observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          this.visible.emit();
        }
      });
    });

    this.observer.observe(this.el.nativeElement);
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
