import { GenericAction } from 'src/app/common';
import { IIncidentExcelData } from './../../../models/incident.model';
import { IncidentActionTypes } from '../../actions/incident-action-types';
import { IIncidentExcelState, IncidentExcelState } from '../../models/incident-excel.model';
import {
  errorListData,
  receiveListData,
  requestListData,
} from '../../../../common/utils/reducer/reducer.utils';

export function incidentExcelReducer(
  lastState: IIncidentExcelState = new IncidentExcelState(),
  action: GenericAction<IncidentActionTypes, any>
): IIncidentExcelState {
  switch (action.type) {

    case IncidentActionTypes.REQUEST_INCIDENT_EXCEL_DATA:
      return requestListData<IIncidentExcelState, IIncidentExcelData[]>(lastState, ['incidentExcelData']);
    case IncidentActionTypes.RECEIVE_INCIDENT_EXCEL_DATA:
      return receiveListData<IIncidentExcelState, IIncidentExcelData[]>(lastState, ['incidentExcelData'], action.payload);
    case IncidentActionTypes.ERROR_INCIDENT_EXCEL_DATA:
      return errorListData<IIncidentExcelState, IIncidentExcelData[]>(lastState, ['incidentExcelData'], action.payload);

    default:
      return lastState;
  }
}
