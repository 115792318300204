import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import {
  IIncident,
  IIncidentEcp,
  IMonitoringIncidents,
  IRemoveIncident,
} from 'src/app/remote-monitoring/models/remote-monitoring.model';
import { RemoteMonitoringActionTypes } from '../remote-monitoring-action-types';

export class RequestMonitoringIncidents implements Action {
  readonly type: RemoteMonitoringActionTypes.REQUEST_MONITORING_INCIDENTS =
    RemoteMonitoringActionTypes.REQUEST_MONITORING_INCIDENTS;
}
export class ReceiveMonitoringIncidents implements Action {
  readonly type: RemoteMonitoringActionTypes.RECEIVE_MONITORING_INCIDENTS =
    RemoteMonitoringActionTypes.RECEIVE_MONITORING_INCIDENTS;

  constructor(public payload: IMonitoringIncidents) {}
}
export class ErrorReceiveMonitoringIncidents implements Action {
  readonly type: RemoteMonitoringActionTypes.ERROR_RECEIVE_MONITORING_INCIDENTS =
    RemoteMonitoringActionTypes.ERROR_RECEIVE_MONITORING_INCIDENTS;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestRemoveIncident implements Action {
  readonly type: RemoteMonitoringActionTypes.REQUEST_REMOVE_INCIDENT =
    RemoteMonitoringActionTypes.REQUEST_REMOVE_INCIDENT;

  constructor(
    public payload: IRemoveIncident,
    public incidentVersion: number,
  ) {}
}
export class ReceiveRemoveIncident implements Action {
  readonly type: RemoteMonitoringActionTypes.RECEIVE_REMOVE_INCIDENT =
    RemoteMonitoringActionTypes.RECEIVE_REMOVE_INCIDENT;

  constructor(public payload: any) {}
}
export class ErrorReceiveRemoveIncident implements Action {
  readonly type: RemoteMonitoringActionTypes.ERROR_RECEIVE_REMOVE_INCIDENT =
    RemoteMonitoringActionTypes.ERROR_RECEIVE_REMOVE_INCIDENT;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestIncidentById implements Action {
  readonly type: RemoteMonitoringActionTypes.REQUEST_MONITORING_INCIDENT_BY_ID =
    RemoteMonitoringActionTypes.REQUEST_MONITORING_INCIDENT_BY_ID;

  constructor(public payload: string) {}
}
export class ReceiveIncidentById implements Action {
  readonly type: RemoteMonitoringActionTypes.RECEIVE_MONITORING_INCIDENT_BY_ID =
    RemoteMonitoringActionTypes.RECEIVE_MONITORING_INCIDENT_BY_ID;

  constructor(public payload: IIncident) {}
}
export class ErrorReceiveIncidentById implements Action {
  readonly type: RemoteMonitoringActionTypes.ERROR_RECEIVE_MONITORING_INCIDENT_BY_ID =
    RemoteMonitoringActionTypes.ERROR_RECEIVE_MONITORING_INCIDENT_BY_ID;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestIncidentEcpById implements Action {
  readonly type: RemoteMonitoringActionTypes.REQUEST_INCIDENT_ECP_BY_ID =
    RemoteMonitoringActionTypes.REQUEST_INCIDENT_ECP_BY_ID;

  constructor(
    public incidentId: string,
    public ecpId: string,
  ) {}
}
export class ReceiveIncidentEcpById implements Action {
  readonly type: RemoteMonitoringActionTypes.RECEIVE_INCIDENT_ECP_BY_ID =
    RemoteMonitoringActionTypes.RECEIVE_INCIDENT_ECP_BY_ID;

  constructor(public payload: IIncidentEcp) {}
}
export class ErrorReceiveIncidentEcpById implements Action {
  readonly type: RemoteMonitoringActionTypes.ERROR_RECEIVE_INCIDENT_ECP_BY_ID =
    RemoteMonitoringActionTypes.ERROR_RECEIVE_INCIDENT_ECP_BY_ID;

  constructor(public payload: HttpErrorResponse) {}
}
