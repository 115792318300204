import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CheckingSettingsModalsService, GenericFooterItemsComponent } from 'src/app/common';

@Component({
  selector: 'ignis-address-book-list-view-footer',
  templateUrl: './address-book-list-view-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressBookListViewFooterComponent<T> extends GenericFooterItemsComponent<T> {
  @Input() isModalOpened: boolean;

  constructor(private checkSettingsModal: CheckingSettingsModalsService) {
    super();

    this.checkSettingsModal.checkIfSettingsModalIsOpen().subscribe((isModalOpened: boolean) => {
      this.isModalOpened = isModalOpened;
      this.cdr.detectChanges();
    });
  }
}
