import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { ApplicationState, IApplicationState, IStoreApiItem } from 'src/app/common';
import { IStoreApiList } from './../../../common/models/store-api-list.model';
import { ILogbook, ILogbookPage } from './../../models/logbook.model';
import { IEntriesModel } from '../../models/logbook.model';
import { ILogbookState } from '../models/logbook.model';

const logbookState = (state: IApplicationState) => state.logbook;

export const selectLogbookPage: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<ILogbookPage>,
  DefaultProjectorFn<IStoreApiItem<ILogbookPage>>
> = createSelector(logbookState, (state: ILogbookState) => state.logbookPage);

export const selectEntries: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IEntriesModel>,
  DefaultProjectorFn<IStoreApiItem<IEntriesModel>>
> = createSelector(logbookState, (state: ILogbookState) => state.entries);

export const selectLogbookExcelData: MemoizedSelector<
  ApplicationState,
  IStoreApiList<ILogbook[]>,
  DefaultProjectorFn<IStoreApiList<ILogbook[]>>
> = createSelector(logbookState, (state: ILogbookState) => state.logbookExcel);
