import { inject } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { Observable } from 'rxjs';
import { ITableDateFilter, ITableDropdownFilter, ITableSettings } from 'src/app/common/models/table.model';
import { SettingsActions } from 'src/app/settings';
import { TableConstants } from '../../constants/table.constants';
import { OnDestroyMixin } from '../../mixins/destroy-mixin';
import { PropertyBag, TableFilters } from '../../models/common.model';
import { ITableColumn } from '../../models/table.model';
import { TableService } from '../../services/table/table.service';

export abstract class TableHelperComponent extends OnDestroyMixin() {
  abstract tableFiltersKey: string;
  abstract tableName: string;
  abstract defaultSorting: string;

  abstract fetchTableData(params: any): void;

  rows: number = 25;
  first: number = 0;
  pageNumber: number = 0;
  totalRecords: number = 0;
  totalPages: number;
  isExportMode: boolean = false;

  sortColumn: TableFilters;
  excelArray: unknown[];
  filtersData: TableFilters = {};
  extraFilters: TableFilters = {};
  loadDataEvent: LazyLoadEvent;
  tableExportList: any[] = null;
  isLoading$: Observable<boolean>;
  tableColumns: ITableColumn[] | any;
  readModelSortKeys: PropertyBag = {};

  selectedDropdowns: PropertyBag;
  selectedDates: Record<string, unknown>;

  public tableRef: Table | any;
  public tableService: TableService = inject(TableService);
  public settingsActions: SettingsActions = inject(SettingsActions);

  constructor() {
    super();
  }

  get tableHelperVisibleColumns(): ITableColumn[] {
    return this.tableColumns?.filter((col: ITableColumn) => col.visible);
  }

  get tableHelperSavedFilters(): any {
    return this.tableFiltersKey in localStorage ? JSON.parse(localStorage.getItem(this.tableFiltersKey)) : null;
  }

  tableHelperPrepareTableParameters(exportPage: number = 0): void {
    const fieldName: string = this.readModelSortKeys[this.loadDataEvent?.sortField] ?? this.loadDataEvent?.sortField;
    const sortOrder: string = this.tableService.determineSortingOrder(this.loadDataEvent?.sortOrder);
    const savedPageNumber: number = this.tableHelperSavedFilters?.page ?? this.pageNumber;

    this.tableHelperSetTablePaging(savedPageNumber);

    const queryParams: any = this.filterObjectKeys({
      ...this.tableService.processFilterValues({
        ...this.tableHelperSavedFilters,
        ...this.filtersData,
        ...this.extraFilters,
      }),
      page: this.pageNumber,
      size: this.rows,
      sort: this.determineSorting(fieldName, sortOrder),
    });

    this.tableHelperSetTableFilters('sort', queryParams.sort);

    this.fetchTableData(
      this.isExportMode
        ? {
            ...queryParams,
            page: exportPage,
            size: 100,
          }
        : queryParams,
    );
  }

  filterObjectKeys(obj: any): object {
    return Object.keys(obj).reduce((result: {}, key: string) => {
      if (!TableConstants.notNeededInQueries.includes(key)) {
        result[key] = obj[key];
      }

      return result;
    }, {});
  }

  tableHelperGetTableRef(event: any): void {
    this.tableRef = event;
  }

  tableHelperOnLazyLoading(event: LazyLoadEvent): void {
    this.loadDataEvent = event;
  }

  tableHelperGetTableColumns(settings: ITableSettings): void {
    this.tableColumns = settings.columns;
    this.rows = settings.pageSize;

    this.tableHelperPrepareTableParameters();
  }

  tableHelperOnPageChange(event: { page: number; rows: number }): void {
    this.pageNumber = event.page;
    this.rows = event.rows;

    this.tableHelperSetTablePaging(event.page);
    this.tableHelperPrepareTableParameters();
  }

  tableHelperSaveTableSettings(localColumns: any = null): void {
    if (localColumns) {
      this.tableColumns = structuredClone(localColumns);
    }

    this.tableService.saveColumnsSettings(this.tableColumns, this.settingsActions, this.tableName, this.rows);
  }

  tableHelperSetTableFilters(field: string, value: any): void {
    this.tableService.saveFilters(field, value, this.tableFiltersKey, this.tableHelperSavedFilters);
  }

  tableHelperSetAllTableFilters(object: any): void {
    this.tableService.saveAllFilters(object, this.tableFiltersKey, this.tableHelperSavedFilters);
  }

  tableHelperOnChangeSort(event: any): void {
    this.sortColumn = event;
  }

  tableHelperSetTablePaging(page: number): void {
    this.pageNumber = page;
    this.first = page * this.rows;
    this.setTableRefFirst(this.first);

    this.tableHelperSetTableFilters('page', page);
  }

  tableHelperResetTablePaging(): void {
    this.setTableRefFirst(0);

    this.pageNumber = 0;
    this.first = 0;

    this.tableHelperSetTableFilters('page', 0);
  }

  tableHelperCheckOutOfRangePageFilter(): void {
    if (this.pageNumber > 0 && this.pageNumber >= this.totalPages) {
      this.tableHelperOnPageChange({ page: this.totalPages - 1, rows: this.rows });
    }
  }

  tableHelperExcelExportDone(): void {
    this.excelArray = null;
    this.tableExportList = null;
    this.isExportMode = false;
  }

  tableHelperResetTableSorting(): void {
    this.sortColumn.field = '';
    this.sortColumn.order = 1;
    this.loadDataEvent.sortField = '';
    this.loadDataEvent.sortOrder = 1;
    this.filtersData.sort = null;

    this.tableHelperSetTableFilters('sort', null);
  }

  tableHelperCheckForSorting(field: string): boolean {
    const checkedColumn: string = this.readModelSortKeys[field] ?? field;

    return this.tableHelperSavedFilters?.sort?.includes(checkedColumn);
  }

  tableHelperHideAndResetTableColumn(
    localTableColumns: ITableColumn[],
    column: ITableColumn,
    clearAllFilterData: boolean = true,
  ): void {
    localTableColumns.forEach((localColumn: any) => {
      if (localColumn.field === column.field) {
        if (localColumn.filterKey?.length > 1) {
          this.tableService.saveFilters(
            localColumn.filterKey[0],
            null,
            this.tableFiltersKey,
            this.tableHelperSavedFilters,
          );
          this.tableService.saveFilters(
            localColumn.filterKey[1],
            null,
            this.tableFiltersKey,
            this.tableHelperSavedFilters,
          );

          this.filtersData[localColumn.filterKey[0]] = null;
          this.filtersData[localColumn.filterKey[1]] = null;
          this.filtersData[localColumn.field] = null;
        } else {
          this.tableService.saveFilters(
            localColumn.filterKey ? localColumn.filterKey[0] : localColumn.field,
            null,
            this.tableFiltersKey,
            this.tableHelperSavedFilters,
          );

          if (clearAllFilterData) {
            if (localColumn.filterKey) {
              this.filtersData[localColumn.filterKey[0]] = null;
            }

            this.filtersData[localColumn.field] = null;
            this.selectedDropdowns = { ...this.selectedDropdowns, [localColumn.field]: null };
          }
        }
      }
    });
  }

  tableHelperReadSavedFiltersValues(
    selectedDropdownsFilterValues?: ITableDropdownFilter[],
    selectedDatesFilterValues?: ITableDateFilter[],
  ): void {
    if (this.tableHelperSavedFilters) {
      selectedDropdownsFilterValues?.forEach((obj: ITableDropdownFilter) => {
        this.selectedDropdowns = {
          ...this.selectedDropdowns,
          [obj.key]: this.tableHelperSavedFilters[obj.value],
        };
      });

      selectedDatesFilterValues?.forEach((obj: ITableDateFilter) => {
        this.selectedDates = {
          ...this.selectedDates,
          [obj.key]: [
            this.tableHelperSavedFilters[obj.startDate] ? new Date(this.tableHelperSavedFilters[obj.startDate]) : null,
            this.tableHelperSavedFilters[obj.endDate] ? new Date(this.tableHelperSavedFilters[obj.endDate]) : null,
          ],
        };
      });
    }
  }

  private getKeyByValue = (value: any): string => {
    const readModelKey: string = Object.keys(this.readModelSortKeys).find(
      (key: string) => this.readModelSortKeys[key] === value,
    );

    return readModelKey ?? value;
  };

  private setTableRefFirst(value: number): void {
    if (this.tableRef) {
      this.tableRef.first = value;
    }
  }

  private determineSorting(fieldName: string, sortOrder: string): string {
    const savedSort: string = this.tableHelperSavedFilters?.sort;

    if (fieldName) {
      this.sortColumn = { field: this.getKeyByValue(fieldName), order: sortOrder === 'DESC' ? -1 : 1 };

      return `${fieldName},${sortOrder}`;
    }

    if (savedSort) {
      this.sortColumn = this.sortingFromString(savedSort);

      return savedSort;
    }

    this.sortColumn = this.sortingFromString(this.defaultSorting);

    return this.defaultSorting;
  }

  private sortingFromString(sorting: string): { field: string; order: number } {
    const [field, order]: [string, string] = (sorting?.split(',') as [string, string]) ?? ['', ''];

    return { field: this.getKeyByValue(field), order: order === 'DESC' ? -1 : 1 };
  }
}
