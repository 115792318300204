<ng-template odxModal #uploadMediaFilesModal>
  <odx-modal-header>
    <odx-area-header>
      {{ 'INCIDENT_UPLOAD_FILE.STR_TITLE' | translate }}
    </odx-area-header>
  </odx-modal-header>
  <odx-modal-content [ngStyle]="{ overflow: 'visible' }">
    <section odxLayout="grid">
      <div odxLayout="12 12@mobile">
        <div class="odx-form-field odx-form-field--required">
          <label class="odx-form-field__label" for="ecp">
            <odx-form-field-label class="odx-form-field-label">
              {{ 'EVENT_MARKER.EVENT_ECP' | translate }}
            </odx-form-field-label>
            <span class="odx-form-field__label-is-required">*</span>
          </label>

          <div class="w-100 mb-15">
            <p-dropdown
              #dd2
              (onShow)="dropdownService.openDropdown(dd2)"
              (onHide)="dropdownService.closeDropdown(dd2)"
              optionValue="telemetryAddress"
              data-test-id="incident_upload_file.event_ecp"
              [dropdownIcon]="dropdownIconCSSClass"
              [options]="ecpsList"
              [placeholder]="'EVENT_MARKER.EVENT_ECP_PLACEHOLDER' | translate"
              [showClear]="true"
              [ngModel]="selectedTelemetryAddress"
              (ngModelChange)="selectedTelemetryAddress = $event"
              (onChange)="selectTelemetryAddress($event)"
            >
              <ng-template let-option pTemplate="item">
                <span>{{ option.label }}</span>
              </ng-template>
              <ng-template pTemplate="clearicon">
                <odx-icon name="close"></odx-icon>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
      </div>
    </section>

    <p-fileUpload
      #uploadFileControl
      accept=".jpeg, .png, .jpg"
      multiple="true"
      [maxFileSize]="maxSizeOfUploadedImages"
      [invalidFileSizeMessageSummary]="'COMMON_NOTIFICATION.STR_UPLOAD_LARGE_FILE_FAILED' | translate"
      [invalidFileSizeMessageDetail]="''"
      [chooseLabel]="'INCIDENT_UPLOAD_FILE.STR_SELECT_FILE' | translate"
      chooseIcon="''"
      [customUpload]="true"
      [auto]="true"
      data-test-id="incident_upload_file.upload_file_component"
      [invalidFileTypeMessageSummary]="'INCIDENT_UPLOAD_FILE.STR_FILE_TYPE_ERROR' | translate"
      [invalidFileTypeMessageDetail]="'jpeg, png, jpg.'"
      (onSelect)="selectFilesToBeUploaded($event, uploadFileControl)"
      (onError)="isLoading = false"
      (onRemove)="removeUploadedFile($event)"
    >
      <ng-template let-file pTemplate="content">
        <ignis-progress-bar class="upload-media-file-progress-bar" *ngIf="isLoading"></ignis-progress-bar>
      </ng-template>
    </p-fileUpload>
  </odx-modal-content>
  <odx-modal-footer>
    <button odxButton (click)="closeModal(true)" variant="secondary" data-test-id="modal_actions.cancel_btn">
      {{ 'INCIDENT_UPLOAD_FILE.CANCEL_BTN' | translate }}
    </button>

    <button
      odxButton
      variant="primary"
      type="button"
      [disabled]="submitBtnState || isLoading"
      (click)="setMediaAssets()"
      data-test-id="modal_actions.ok_btn"
    >
      {{ 'INCIDENT_UPLOAD_FILE.OK_BTN' | translate }}
    </button>
  </odx-modal-footer>
</ng-template>
