<div
  class="odx-card--default odx-cdk-interactive odx-card team-tile"
  [ngClass]="{ 'wide-tile-card': team?.teamId === '0' }"
  *ngIf="team?.firefighters.length > 0"
  data-test-id="remote_monitoring.team_tile"
>
  <div class="odx-card__container">
    <div class="odx-card__title odx-title-5">
      <div class="tile-header">
        <div
          class="left-side"
          *ngIf="team?.teamId !== '0'; else standbyContent"
          data-test-id="remote_monitoring.team_info"
        >
          <odx-icon name="user-group" size="inline" class="user-icon"></odx-icon>
          <span class="team-name">{{ team?.name }} </span>
          <span
            class="status-name"
            [ngClass]="{
              'active-statuses': team?.status.value === 'at_incident' || team?.status.value === 'entering_incident',
              'withdraw-status': team?.status.value === 'retreating',
              'standby-status': team?.status.value === 'standby' || team?.status.value === 'finished',
            }"
            ><ignis-team-status-translation [entry]="team?.status"></ignis-team-status-translation
          ></span>
        </div>
        <ng-template #standbyContent>
          <div class="full-width" data-test-id="remote_monitoring.team_in_stand_by">
            <span>{{ 'REMOTE_MONITORING.STR_WAITING_STANDBY' | translate }}</span>
          </div>
        </ng-template>
        <div class="right-side" *ngIf="team?.teamId !== '0'">
          <span
            class="notes-mark odx-icon"
            data-icon-set="uib-legacy"
            data-icon-name="comment"
            [class]="currentAppTheme"
            [ngClass]="{ 'active-notes': team?.notes.length }"
            (click)="openTeamNotes(team)"
            data-test-id="remote_monitoring.open_team_notes"
          ></span>
          <span
            class="signal-mark odx-icon"
            data-icon-set="uib-legacy"
            data-icon-name="evacuation"
            [ngClass]="{
              'has-evacuation-signal': team?.hasEvacuationSignal,
              'no-evacuation-signal': !team?.hasEvacuationSignal,
            }"
          ></span>
        </div>
      </div>
    </div>

    <div class="odx-card__content">
      <div class="tile-content">
        <ngx-simplebar
          [options]="scrollbarOptions"
          class="team-card"
          [ngClass]="{
            'fix-height': team?.firefighters.length > 12,
            'card-custom-margins': team?.teamId === '0',
          }"
        >
          <div
            *ngFor="let firefighter of team?.firefighters; index as i; last as isLast"
            class="firefighters-body"
            [ngClass]="{
              'yellow-highlighted': getFirefighterAlarms(firefighter) === 1,
              'red-highlighted': getFirefighterAlarms(firefighter) === 0,
              'last-elem-border': isLast && team?.teamId !== '0' && team?.firefighters.length >= 4,
              'grid-elem': team?.teamId === '0',
            }"
          >
            <ignis-team-firefighter
              [teamId]="team.teamId"
              [firefighter]="firefighter"
              [longestAlarm]="getTheLongestFirefighterAlarm(firefighter)"
              [pressureDisplayUnit]="pressureDisplayUnit | async"
              [defaultTimeDisplay]="defaultTimeDisplay | async"
              [mediumPressureValue]="mediumPressureValue | async"
              [lowPressureValue]="lowPressureValue | async"
            ></ignis-team-firefighter>
          </div>
        </ngx-simplebar>
      </div>
    </div>
  </div>
</div>
