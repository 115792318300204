import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { NotificationTypes } from '../models/notification.model';
import { INotificationMessage, INotificationState } from './../models/notification.model';
import { RequestShowNotification } from './notification-action-types-creators';

@Injectable({ providedIn: 'root' })
export class NotificationActions {
  constructor(private store: Store<INotificationState>) {}

  public requestShowNotification(
    type: NotificationTypes,
    message: INotificationMessage,
    value?: object,
    description?: string,
  ): void {
    this.store.dispatch(new RequestShowNotification({ type, message, value, description }));
  }
}
