import { IStoreApiItem, StoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IStoreApiList, StoreApiList } from 'src/app/common/models/store-api-list.model';
import { IMeasurementUnit } from './../../models/localization-profile.model';
import { ILocalizationProfile } from '../../models/localization-profile.model';
import { ITableSettingsResponse, ITableSettings } from 'src/app/common/models/table.model';

export interface ISettingsState {
  userSettings: IStoreApiItem<ILocalizationProfile>;
  localizationList: IStoreApiList<ILocalizationProfile[]>;
  measurementUnits: IStoreApiList<IMeasurementUnit[]>;
  tableColumns: IStoreApiItem<ITableSettingsResponse>;
  updateTableColumns: IStoreApiItem<ITableSettings>;
}

export class SettingsState {
  userSettings: IStoreApiItem<ILocalizationProfile> = new StoreApiItem();
  localizationList: IStoreApiList<ILocalizationProfile[]> = new StoreApiList();
  measurementUnits: IStoreApiList<IMeasurementUnit[]> = new StoreApiList();
  tableColumns: IStoreApiItem<ITableSettingsResponse> = new StoreApiItem();
  updateTableColumns: IStoreApiItem<ITableSettings> = new StoreApiItem();
}
