import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription, takeUntil } from 'rxjs';
import { IApplicationState, IStoreApiList } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { AppModulesTypes } from 'src/app/root/models/app-types';
import { IFeatureToggle } from 'src/app/root/state/features-toggle/models/features-toggle.model';
import { selectFeaturesToggleList } from 'src/app/root/state/features-toggle/selectors/features-toggle.selector';

@Injectable()
export class WorkshopFeatureToggleGuard extends OnDestroyMixin() {
  inventoryTabFT: boolean;
  scanAssetTabFT: boolean;
  serviceTasksTabFT: boolean;
  equipmentTestingGlobal: boolean;

  constructor(
    private router: Router,
    private store: Store<IApplicationState>,
  ) {
    super();
    this.readFeatureTogglesFromStore();
  }

  navigateToOverViewPage(): boolean {
    this.router.navigate(['workshop']);

    return false;
  }

  readFeatureTogglesFromStore(): Subscription {
    return this.store
      .pipe(select(selectFeaturesToggleList))
      .pipe(takeUntil(this.destroy))
      .subscribe((state: IStoreApiList<IFeatureToggle[]>) => {
        const featuresToggle: IFeatureToggle[] = state.data;

        featuresToggle?.forEach((ft: IFeatureToggle) => {
          if (Object.values(ft).includes(AppModulesTypes.inventoryPage)) {
            this.inventoryTabFT = ft.isEnabled;
          } else if (Object.values(ft).includes(AppModulesTypes.equipmentWorkflowPage)) {
            this.scanAssetTabFT = ft.isEnabled;
          } else if (Object.values(ft).includes(AppModulesTypes.equipmentTasksTab)) {
            this.serviceTasksTabFT = ft.isEnabled;
          } else if (Object.values(ft).includes(AppModulesTypes.equipmentTestingGlobal)) {
            this.equipmentTestingGlobal = ft.isEnabled;
          }
        });

        this.canActivate();
      });
  }

  canActivate(): boolean {
    if (
      this.inventoryTabFT === false &&
      this.scanAssetTabFT === false &&
      this.serviceTasksTabFT === false &&
      this.equipmentTestingGlobal === false
    ) {
      this.navigateToOverViewPage();
    } else {
      return true;
    }
  }
}
