import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { extractOnlyDate } from '../../utils/date-utils/date.utils';

@Injectable({
  providedIn: 'root',
})
export class DatePickerService {
  onDateChange(date: Date, form: FormGroup, controlName: string): void {
    /* istanbul ignore else */
    if (new Date(extractOnlyDate(date)).getTime() !== new Date(form.get(controlName).value).getTime()) {
      form.get(controlName).setValue(extractOnlyDate(date));
      form.get(controlName).markAsDirty();
      form.get(controlName).markAsTouched();
    }
  }

  resetSelectedDate(form: FormGroup, controlName: string): void {
    form.get(controlName).setValue(null);
    form.get(controlName).markAsDirty();
    form.get(controlName).markAsTouched();
  }
}
