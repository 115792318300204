<div class="table-column-ellipsis-wrap" #tableColumnEllipsisWrap>
  <span
    class="ellipsis-text"
    *ngFor="let taskName of tasks?.data?.slice(0, 3); index as i; let isFirst = first; let isLast = last"
    [pTooltip]="!disableRootTooltip && tasks?.data.length <= 3 ? null : composeListTooltip(tasks?.data)"
    tooltipPosition="top"
    positionTop="-10"
    (mouseover)="checkIfItHasEllipses()"
  >
    <span *ngIf="tasks?.data.length > 1; else withoutComma">
      <span *ngIf="!isFirst">&nbsp;</span> {{ taskName }}<span *ngIf="!isLast">,</span>
    </span>
    <ng-template #withoutComma>
      <p
        class="ellipsis-text"
        #otherTasksColumnText
        [pTooltip]="!disableTooltip ? null : taskName"
        tooltipPosition="top"
        positionTop="-10"
        (mouseover)="calculateToDisableTooltip()"
        data-test-id="table.ellipsis"
      >
        {{ taskName }}
      </p>
    </ng-template>
  </span>
</div>
<span
  *ngIf="tasks?.data?.length > 3"
  [pTooltip]="composeListTooltip(tasks?.data)"
  tooltipPosition="top"
  positionTop="-10"
  data-test-id="service_results.service_column_tooltip"
  >[...]
</span>
