import { Location } from '@angular/common';
import { AfterViewInit, Component, HostListener, inject, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ModalRef, ModalService, ModalSize, ModalVariant } from '@odx/angular/components/modal';
import { combineLatest, filter, map, Observable, take, takeUntil } from 'rxjs';
import {
  ApplicationState,
  CommonConstants,
  DropdownService,
  IStoreApiItem,
  LoggerService,
  PropertyBag,
} from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { INotificationMessage } from 'src/app/common/state/notifications/models/notification.model';
import { ConfigurationNotificationConstants } from 'src/app/configuration/constants';
import { ConfigurationModuleRoutes } from 'src/app/configuration/constants/configuration-module-routes.constants';
import {
  IEditTask,
  IEquipmentConfiguration,
  INewTask,
} from 'src/app/configuration/models/equipment-configuration.models';
import { EquipmentConfigActions, TasksActions } from 'src/app/configuration/state/actions';
import {
  selectAddedTask,
  selectedEquipmentConfiguration,
  selectEditedTask,
} from 'src/app/configuration/state/selectors/configuration.selectors';
import { EquipmentNotificationConstants } from 'src/app/workshop/constants';
import { ConfigurationConstants } from '../../../../constants/configuration.constants';
import { ITask } from '../../../../models/equipment-configuration.models';
@Component({
  selector: 'ignis-create-update-task',
  templateUrl: './create-update-task.component.html',
  styleUrls: ['./create-update-task.component.scss'],
})
export class CreateUpdateTaskComponent extends OnDestroyMixin() implements AfterViewInit {
  @ViewChild('addServiceModal', { read: TemplateRef })
  public addServiceModal: TemplateRef<any>;
  modalRef: ModalRef | any;

  isLoading: boolean = true;

  taskForm: FormGroup = new FormGroup(
    {
      taskName: new FormControl('', [Validators.required]),
      description: new FormControl(null),
      aggregateId: new FormControl(null),
      version: new FormControl(null),
      duration: new FormControl(null, [Validators.min(1), Validators.max(1000)]),
      pattern: new FormControl('YEAR'),
    },
    { validators: this.patternRequiredValidator() },
  );
  httpCustomErrorCode: string | any;
  isConfirmCloseModalOpen: boolean = false;
  isManuallyUpdated: boolean = false;
  openConfirmUpdateHierarchyBanner: boolean = false;
  isSubmitting: Observable<boolean>;
  readonly ConfigurationModuleRoutes: PropertyBag = ConfigurationModuleRoutes;
  intervalList: any[] = ConfigurationConstants.intervalList.patterns;
  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;

  private readonly modalService: ModalService = inject(ModalService);
  tasksActions: TasksActions = inject(TasksActions);
  equipmentConfigActions: EquipmentConfigActions = inject(EquipmentConfigActions);
  notificationsService: NotificationsService = inject(NotificationsService);
  loggerService: LoggerService = inject(LoggerService);
  translateService: TranslateService = inject(TranslateService);
  router: Router = inject(Router);
  route: ActivatedRoute = inject(ActivatedRoute);
  store: Store<ApplicationState> = inject(Store<ApplicationState>);
  dropdownService: DropdownService = inject(DropdownService);
  location: Location = inject(Location);

  taskType: string;

  constructor() {
    super();

    this.taskType = ConfigurationConstants.EquipmentConfigType.TASK;
  }

  generateTaskName(): void {
    const taskName: AbstractControl<any, any> = this.taskForm.get('taskName');
    const pattern: AbstractControl<any, any> = this.taskForm.get('pattern');
    const duration: AbstractControl<any, any> = this.taskForm.get('duration');

    if (
      (!this.isManuallyUpdated || !taskName.value) &&
      duration.value &&
      pattern.value &&
      duration.valid &&
      pattern.valid
    ) {
      const isTaskNameIdentical: boolean = taskName.value !== this.generateNewTaskName(duration.value, pattern.value);

      if (isTaskNameIdentical) {
        this.taskForm.patchValue({
          taskName: this.generateNewTaskName(duration.value, pattern.value),
        });

        this.isManuallyUpdated = false;
      }
    }
  }

  markedAsManualUpdated(): void {
    this.isManuallyUpdated = true;
  }

  canDeactivate(): boolean {
    if (this.taskForm.dirty) {
      this.confirmCloseModalOpen();

      return false;
    } else {
      this.isConfirmCloseModalOpen = false;

      return true;
    }
  }

  confirmCloseModalOpen(): void {
    this.isConfirmCloseModalOpen = true;
  }

  @HostListener(CommonConstants.beforeUnloadWindowEvent, ['$event'])
  handleBeforeUnload($event: any): void {
    if (this.hasUnsavedData()) {
      $event.returnValue = this.hasUnsavedData();
    }
  }

  navigateBack(isOpen: boolean): void {
    if (isOpen) {
      this.taskForm.markAsPristine();

      this.location.back();
    } else {
      this.isConfirmCloseModalOpen = false;
    }
  }

  ngAfterViewInit(): void {
    this.taskForm
      .get('duration')
      .valueChanges.pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.generateTaskName();
      });

    this.taskForm
      .get('pattern')
      .valueChanges.pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.generateTaskName();
      });

    this.openAddTaskModal();

    this.store
      .pipe(select(selectedEquipmentConfiguration), takeUntil(this.destroy))
      .subscribe((response: IStoreApiItem<IEquipmentConfiguration>) => {
        if (response.data) {
          this.isLoading = false;

          this.taskForm.patchValue({ version: response.data.version, aggregateId: response.data.aggregateId });

          if (this.router.url.includes(ConfigurationModuleRoutes.updateTask)) {
            const serviceDefinitions: ITask[] = response.data.serviceDefinitions;

            this.processingTaskData(serviceDefinitions);
          }
        }
      });

    this.isSubmitting = combineLatest([this.store.select(selectAddedTask), this.store.select(selectEditedTask)]).pipe(
      takeUntil(this.destroy),
      map(
        ([newService, editedService]: [
          newHierarchy: IStoreApiItem<INewTask>,
          editHierarchy: IStoreApiItem<IEditTask>,
        ]) => newService.isLoading || editedService.isLoading,
      ),
    );
  }

  processingTaskData(services: ITask[]): void {
    this.route.params.pipe(takeUntil(this.destroy)).subscribe((params: Params) => {
      services.forEach((service: ITask) => {
        this.taskForm.addControl('serviceId', new FormControl(null));

        if (service.serviceId === params.serviceId) {
          this.isManuallyUpdated = true;

          this.taskForm.patchValue({
            taskName: service.taskName,
            description: service.description,
            serviceId: service.serviceId,
          });

          if (service.interval) {
            this.isManuallyUpdated =
              service.taskName === this.generateNewTaskName(service.interval.duration, service.interval.pattern)
                ? false
                : this.isManuallyUpdated;

            this.taskForm.patchValue({
              duration: service.interval.duration,
              pattern: service.interval.pattern,
            });
          } else {
            this.taskForm.patchValue({ duration: null, pattern: null });
          }
        }
      });
    });
  }

  openAddTaskModal(): void {
    this.modalRef = this.modalService.open(this.addServiceModal, {
      size: ModalSize.MEDIUM,
      variant: ModalVariant.DEFAULT,
      dismissable: false,
      data: 'isOpen',
      dismissOnNavigation: false,
    });
  }

  closeModal(): void {
    if (this.taskForm.dirty) {
      this.confirmCloseModalOpen();
    } else {
      this.taskForm.markAsPristine();
      this.modalRef.close();

      this.location.back();
      Object.defineProperty(this.modalRef, 'data', { value: 'isClosed', writable: false });
    }
  }

  onSubmit(): void {
    const urlPath: string = this.router.url.slice(22, this.router.url.lastIndexOf('/'));

    if (this.router.url.includes(ConfigurationModuleRoutes.updateTask)) {
      this.openConfirmUpdateHierarchyBanner = true;
    } else {
      this.addTask(urlPath);
    }
  }

  addTask(urlPath: string): void {
    this.tasksActions.requestAddTask({
      ...this.prepareServiceBody(),
      urlPath,
    });

    this.store
      .pipe(
        select(selectAddedTask),
        filter((newService: IStoreApiItem<INewTask>) => !newService.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<INewTask>) => {
        if (response.isSuccess) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            ConfigurationNotificationConstants.notificationCodes.ADD_SERVICE_SUCCESS,
            ConfigurationNotificationConstants.notificationCodes,
          );

          this.taskForm.markAsPristine();
          this.closeModal();
          this.equipmentConfigActions.requestEquipmentConfiguration({ urlPath });
        } else if (response.errors) {
          this.httpCustomErrorCode = response.errors?.error.code?.toString();

          let notificationType: string = null;

          if (
            this.httpCustomErrorCode ===
            ConfigurationNotificationConstants.notificationCodes.TASK_NAME_SHOULD_BE_UNIQUE.value
          ) {
            setTimeout(() => {
              this.taskForm.get('taskName').setErrors({ invalid: true });
            }, 0);

            notificationType = CommonConstants.notificationType.HIDDEN;
          }

          this.callEditTaskErrorNotification(this.httpCustomErrorCode, notificationType);

          this.closeModalIfMigrationIsInProgress(this.httpCustomErrorCode);
        }
      });
  }

  editTask(urlPath: string): void {
    const url: string = this.router.url.slice(22, this.router.url.lastIndexOf('/'));
    const getConfigurationUrl: string = url.replace('/update-task', '');

    this.tasksActions.requestEditTask({
      ...this.prepareServiceBody(),
      urlPath,
    });

    this.store
      .pipe(
        select(selectEditedTask),
        filter((editedService: IStoreApiItem<IEditTask>) => !editedService.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<IEditTask>) => {
        if (response.isSuccess) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            ConfigurationNotificationConstants.notificationCodes.EDIT_SERVICE_SUCCESS,
            ConfigurationNotificationConstants.notificationCodes,
          );

          this.taskForm.markAsPristine();
          this.closeModal();
          this.equipmentConfigActions.requestEquipmentConfiguration({ urlPath: getConfigurationUrl });
        } else {
          this.httpCustomErrorCode = response.errors?.error.code.toString();

          if (
            this.httpCustomErrorCode === ConfigurationNotificationConstants.notificationCodes.UPDATE_OLDER_VERSION.value
          ) {
            this.callEditTaskErrorNotification(
              ConfigurationNotificationConstants.notificationCodes.UPDATE_OLDER_VERSION,
            );
          } else if (
            this.httpCustomErrorCode ===
            ConfigurationNotificationConstants.notificationCodes.TASK_NAME_SHOULD_BE_UNIQUE.value
          ) {
            setTimeout(() => {
              this.taskForm.get('taskName').setErrors({ invalid: true });
            }, 0);

            this.callEditTaskErrorNotification(
              EquipmentNotificationConstants.notificationCodes.NAME_SHOULD_BE_UNIQUE,
              CommonConstants.notificationType.HIDDEN,
            );
          } else {
            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.ERROR,
              this.httpCustomErrorCode,
              ConfigurationNotificationConstants.notificationCodes,
            );

            this.closeModalIfMigrationIsInProgress(this.httpCustomErrorCode);
          }
        }
      });
  }

  prepareServiceBody(): any {
    const toSubmit: any = { ...this.taskForm.value };

    if (!toSubmit.duration) {
      toSubmit.pattern = null;
    }

    return toSubmit;
  }

  callEditTaskErrorNotification(errorCode: INotificationMessage, notificationType?: string): void {
    this.notificationsService.requestShowNotification(
      notificationType ? notificationType : CommonConstants.notificationType.ERROR,
      errorCode,
      {
        ...ConfigurationNotificationConstants.notificationCodes,
        ...EquipmentNotificationConstants.notificationCodes,
      },
    );
  }

  hasUnsavedData(): boolean {
    return this.taskForm.dirty;
  }

  closeConfirmBanner(confirmation: boolean): void {
    if (confirmation) {
      const urlPath: string = this.router.url.slice(22, this.router.url.lastIndexOf('/'));
      const updateUrl: string = urlPath.replace('/update-task', '/service-definition');

      this.editTask(updateUrl);
    }

    this.openConfirmUpdateHierarchyBanner = false;
  }

  getLocalizedPattern(key: string): string {
    return (
      ConfigurationConstants.intervalList.patterns.find((interval: any) => interval.value === key)?.localizedName || key
    );
  }

  generateNewTaskName(duration: string | number, pattern: string): string {
    const localized: string = this.getLocalizedPattern(pattern);

    return `${duration}-${this.translateService.instant(localized)}`;
  }

  patternRequiredValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup: FormGroup<any> = control as FormGroup;
      const duration: AbstractControl<any, any> = formGroup.get('duration');
      const pattern: AbstractControl<any, any> = formGroup.get('pattern').value;

      if (duration && duration.value && +duration.value > 0 && pattern === null) {
        return { patternRequired: true };
      }

      return null;
    };
  }

  closeModalIfMigrationIsInProgress(errorCode: string): void {
    if (
      errorCode ===
      ConfigurationNotificationConstants.notificationCodes.OPERATION_BLOCKED_DURING_THE_MIGRATION_IN_PROGRESS.value
    ) {
      this.taskForm.markAsPristine();
      this.taskForm.markAsUntouched();

      this.closeModal();
    }
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();

    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (this.router.url !== 'configuration' && this.modalRef && this.modalRef['data'] === 'isOpen') {
      this.modalRef.close();
    }
  }
}
