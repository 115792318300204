import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { ApplicationState, IApplicationState, IStoreApiItem, IStoreApiList } from 'src/app/common';
import { IEntryModel } from 'src/app/common/models/common.model';
import {
  IEntriesModel,
  IEventMarker,
  IIncident,
  IIncidentAnalysis,
  IIncidentExcelData,
  IIncidentPage,
  IMediaAssets,
  INewNote,
} from '../../models/incident.model';
import { IIncidentState } from '../models/incident.model';

const incidentState = (state: IApplicationState) => state.incident;

export const selectIncidentPage: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IIncidentPage>,
  DefaultProjectorFn<IStoreApiItem<IIncidentPage>>
> = createSelector(incidentState, (state: IIncidentState) => state.incidentData.incidentPage);

export const selectIncident: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IIncident>,
  DefaultProjectorFn<IStoreApiItem<IIncident>>
> = createSelector(incidentState, (state: IIncidentState) => state.incidentData.selectedIncident);

export const selectEntries: MemoizedSelector<
  ApplicationState,
  IStoreApiList<IEntriesModel>,
  DefaultProjectorFn<IStoreApiList<IEntriesModel>>
> = createSelector(incidentState, (state: IIncidentState) => state.incidentData.entries);

export const selectIncidentAnalysis: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IIncidentAnalysis>,
  DefaultProjectorFn<IStoreApiItem<IIncidentAnalysis>>
> = createSelector(incidentState, (state: IIncidentState) => state.analysis.incidentAnalysis);

export const selectMergeIncidents: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<any>,
  DefaultProjectorFn<IStoreApiItem<any>>
> = createSelector(incidentState, (state: IIncidentState) => state.mergeIncidents.merged);

export const selectIncidentExcelData: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IIncidentExcelData>,
  DefaultProjectorFn<IStoreApiItem<IIncidentExcelData>>
> = createSelector(incidentState, (state: IIncidentState) => state.incidentExcel.incidentExcelData);

export const selectEventMarkersEntries: MemoizedSelector<
  ApplicationState,
  IStoreApiList<IEntryModel[]>,
  DefaultProjectorFn<IStoreApiList<IEntryModel[]>>
> = createSelector(incidentState, (state: IIncidentState) => state.eventMarker.eventMarkersEntries);

export const selectEventMarker: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IEventMarker>,
  DefaultProjectorFn<IStoreApiItem<IEventMarker>>
> = createSelector(incidentState, (state: IIncidentState) => state.eventMarker.selectedEventMarker);

export const selectDeletedEventMarker: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IEventMarker>,
  DefaultProjectorFn<IStoreApiItem<IEventMarker>>
> = createSelector(incidentState, (state: IIncidentState) => state.eventMarker.deletedEventMarker);

export const selectIncidentMediaAssets: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IMediaAssets[]>,
  DefaultProjectorFn<IStoreApiItem<IMediaAssets[]>>
> = createSelector(incidentState, (state: IIncidentState) => state.incidentNote.mediaAssets);

export const selectNewNote: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<INewNote>,
  DefaultProjectorFn<IStoreApiItem<any>>
> = createSelector(incidentState, (state: IIncidentState) => state.incidentNote.newNote);
