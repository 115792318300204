<ng-template odxModal #cylinderChargerModal let-modal="close">
  <odx-modal-header data-test-id="service_task_cylinder.cylinder_charging_modal">
    <odx-area-header>
      {{ 'SERVICE_TASK_CYLINDER.STR_CYLINDER_CHARGER' | translate }}
    </odx-area-header>
  </odx-modal-header>
  <odx-modal-content [odxLoadingSpinner]="isLoading | async">
    <div class="cylinder-charger">
      <form [formGroup]="serviceForm" (ngSubmit)="onSubmit()" class="cylinder-charger">
        <div odxLayout="grid">
          <div odxLayout="8" class="custom-form-field-pr-10">
            <div class="odx-form-field mb-3">
              <label class="odx-form-field__label" for="dd1">
                <odx-form-field-label class="odx-form-field-label">
                  {{ 'SERVICE_TASK_CYLINDER.STR_NEW_LOCATION' | translate }}
                </odx-form-field-label>
              </label>
              <p-treeSelect
                #dd1
                [options]="locations"
                formControlName="location"
                ngDefaultControl
                filter="true"
                [showClear]="true"
                [filterPlaceholder]="'SERVICE_TASK_CYLINDER.STR_SEARCH_LOCATION' | translate"
                [emptyMessage]="'SERVICE_TASK_CYLINDER.STR_NO_RECORDS_FOUND' | translate"
                name="location"
                id="location"
                class="custom-tree-select-form"
                data-test-id="service_task_cylinder.new_location"
                [dropdownIcon]="dropdownIconCSSClass"
                [placeholder]="'SERVICE_TASK_CYLINDER.STR_SELECT_LOCATION' | translate"
                [virtualScroll]="true"
                [virtualScrollItemSize]="46"
                [virtualScrollOptions]="{ scrollHeight: '250px' }"
                (onNodeSelect)="setSelectedLocation($event)"
                (onClear)="clearSelectedLocation()"
              >
                <ng-template let-selectedItem pTemplate="value">
                  <div class="select-tree-selectedItem" *ngIf="selectedItem; else placeholder">
                    <odx-icon [name]="selectedItem?.icon" class="p-treenode-icon"></odx-icon>
                    <label [title]="selectedItem?.label">{{ selectedItem?.label }}</label>
                  </div>
                  <ng-template #placeholder>
                    <span>{{ 'SERVICE_TASK_CYLINDER.STR_SELECT_LOCATION' | translate }}</span>
                  </ng-template>
                </ng-template>
                <ng-template let-node pTemplate="default">
                  <odx-icon [name]="node.icon" class="p-treenode-icon"></odx-icon>
                  <span class="p-treenode-label">{{ node.label }}</span>
                </ng-template>
                <ng-template pTemplate="clearicon">
                  <odx-icon name="close"></odx-icon>
                </ng-template>
              </p-treeSelect>
            </div>
          </div>

          <div odxLayout="4" class="custom-form-field-pl-10">
            <odx-form-field variant="full" label="{{ 'SERVICE_TASK_CYLINDER.STR_PRESSURE' | translate }}">
              <input
                type="number"
                ignisDisableAutocomplete
                id="pressure"
                [step]="1"
                [min]="minimumPressureConstant"
                ignisOnlyNumber
                formControlName="pressure"
                data-test-id="service_task_cylinder.pressure"
                odxFormFieldControl
                [odxTooltip]="pressureTooltip"
                [odxTooltipDisabled]="!serviceForm.get('pressure').invalid"
              />
            </odx-form-field>
          </div>
        </div>

        <div class="mb-3">
          <odx-checkbox formControlName="airQualityMeasured">{{
            'SERVICE_TASK_CYLINDER.STR_AIR_QUALITY' | translate
          }}</odx-checkbox>
        </div>
        <odx-form-field variant="full" label="{{ 'SERVICE_TASK_CYLINDER.STR_COMMENT' | translate }}">
          <textarea
            ignisDisableAutocomplete
            odxFormFieldControl
            class="custom-form-textarea w-100"
            id="comment"
            data-test-id="service_task_cylinder.comment"
            formControlName="comment"
            rows="3"
          ></textarea>
        </odx-form-field>
      </form>

      <div
        class="equipment-list"
        *ngIf="selectedEquipment"
        [ngClass]="{ 'table-scroll': selectedEquipment?.length > tableScrollingFallback }"
        data-test-id="service_task_cylinder.equipment_list"
      >
        <div class="equipment-line" *ngFor="let equipment of selectedEquipment">
          @if (equipment.operationalStatus === operationalStatuses.OPERATIONAL) {
            <odx-icon name="feedback-positive"></odx-icon>
          }
          @if (equipment.operationalStatus === operationalStatuses.NON_OPERATIONAL) {
            <odx-icon name="error"></odx-icon>
          }
          @if (equipment.operationalStatus === operationalStatuses.DEFECTIVE_BUT_OPERATIONAL) {
            <odx-icon name="warning"></odx-icon>
          }

          <span [title]="equipment.equipmentIdentification">
            {{ 'SERVICE_TASK_CYLINDER.STR_EQUIPMENT_ID' | translate }} {{ equipment.equipmentIdentification }}
          </span>
          <span [title]="equipment.location ?? '-'">
            <odx-icon name="location"></odx-icon>
            {{ 'SERVICE_TASK_CYLINDER.STR_EQUIPMENT_LOCATION' | translate }} {{ equipment.location ?? '-' }}
          </span>
          <span [title]="equipment.equipmentModel">
            <odx-icon name="cylinder"></odx-icon>
            {{ equipment.equipmentModel }}
          </span>
        </div>
      </div>
    </div>
  </odx-modal-content>
  <odx-modal-footer>
    <div class="separator"></div>
    <button odxButton (click)="closeModal()" variant="secondary" data-test-id="modal_actions.cancel_btn">
      {{ 'SERVICE_TASK_CYLINDER.STR_CANCEL_BTN' | translate }}
    </button>
    <button
      odxButton
      variant="primary"
      (click)="onSubmit()"
      type="button"
      [disabled]="serviceForm.invalid || (isSubmitting | async)"
      data-test-id="modal_actions.save_btn"
    >
      {{ 'SERVICE_TASK_CYLINDER.STR_SAVE_BTN' | translate }}
    </button>
  </odx-modal-footer>
</ng-template>
<ng-template #pressureTooltip>
  {{ 'SERVICE_TASK_CYLINDER.STR_INVALID_PRESSURE' | translate: { pressure: minimumPressureConstant } }}
</ng-template>
