// eslint-disable-next-line no-shadow
export enum ExportTableActionTypes {
  REQUEST_TABLE_DATA = 'REQUEST_TABLE_DATA',
  RECEIVE_TABLE_DATA = 'RECEIVE_TABLE_DATA',
  ERROR_RECEIVE_TABLE_DATA = 'ERROR_RECEIVE_TABLE_DATA',
  RESET_TABLE_DATA = 'RESET_TABLE_DATA',

  REQUEST_EXPORT_IN_PROGRESS = 'REQUEST_EXPORT_IN_PROGRESS',
  RECEIVE_EXPORT_IN_PROGRESS = 'RECEIVE_EXPORT_IN_PROGRESS',
  ERROR_RECEIVE_EXPORT_IN_PROGRESS = 'ERROR_RECEIVE_EXPORT_IN_PROGRESS',
}
