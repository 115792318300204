import { errorListData, receiveListData, requestListData } from 'src/app/common/utils/reducer/reducer.utils';
import { GenericAction } from '../../../../common/state/models/generic-action.model';
import { IAlarmLists } from '../../../models/alarms.model';
import { AlarmsActionType } from '../actions/alarms-action-types';
import { AlarmsState, IAlarmsState } from '../models/alarms.model';

export function alarmsReducer(
  lastState: IAlarmsState = new AlarmsState(),
  action: GenericAction<AlarmsActionType, any>,
): IAlarmsState {
  switch (action.type) {
    case AlarmsActionType.REQUEST_ALARMS:
      return requestListData<IAlarmsState, IAlarmLists>(lastState, ['alarms']);

    case AlarmsActionType.RECEIVE_ALARMS:
      return receiveListData<IAlarmsState, IAlarmLists>(lastState, ['alarms'], action.payload);

    case AlarmsActionType.ERROR_RECEIVE_ALARMS:
      return errorListData<IAlarmsState, IAlarmLists>(lastState, ['alarms'], action.payload);

    default:
      return lastState;
  }
}
