import { GenericAction } from 'src/app/common';
import {
  errorDeleteItem,
  errorPostPutItemData,
  receiveDeleteItem,
  receivePostPutItemData,
  requestDeleteItem,
  requestPostPutItemData,
} from 'src/app/common/utils';
import { IChecklist } from 'src/app/configuration/models';
import { ChecklistActionTypes } from '../../actions/checklist';
import { ChecklistState, IChecklistState } from '../../models/checklist.model';

export function checklistReducer(
  lastState: IChecklistState = new ChecklistState(),
  action: GenericAction<ChecklistActionTypes, any>,
): IChecklistState {
  switch (action.type) {
    case ChecklistActionTypes.REQUEST_ADD_CHECKLIST:
      return requestPostPutItemData<IChecklistState, IChecklist>(lastState, ['newChecklist'], action.payload);
    case ChecklistActionTypes.RECEIVE_ADD_CHECKLIST:
      return receivePostPutItemData<IChecklistState, IChecklist>(lastState, ['newChecklist'], action.payload);
    case ChecklistActionTypes.ERROR_RECEIVE_ADD_CHECKLIST:
      return errorPostPutItemData<IChecklistState, IChecklist>(lastState, ['newChecklist'], action.payload);

    case ChecklistActionTypes.REQUEST_EDIT_CHECKLIST:
      return requestPostPutItemData<IChecklistState, IChecklist>(lastState, ['editedChecklist'], action.payload);
    case ChecklistActionTypes.RECEIVE_EDIT_CHECKLIST:
      return receivePostPutItemData<IChecklistState, IChecklist>(lastState, ['editedChecklist'], action.payload);
    case ChecklistActionTypes.ERROR_RECEIVE_EDIT_CHECKLIST:
      return errorPostPutItemData<IChecklistState, IChecklist>(lastState, ['editedChecklist'], action.payload);

    case ChecklistActionTypes.REQUEST_DELETE_CHECKLIST:
      return requestDeleteItem<IChecklistState, Partial<IChecklist>>(lastState, ['deletedChecklist']);
    case ChecklistActionTypes.RECEIVE_DELETE_CHECKLIST:
      return receiveDeleteItem<IChecklistState, Partial<IChecklist>>(lastState, ['deletedChecklist']);
    case ChecklistActionTypes.ERROR_RECEIVE_DELETE_CHECKLIST:
      return errorDeleteItem<IChecklistState, Partial<IChecklist>>(lastState, ['deletedChecklist'], action.payload);

    default:
      return lastState;
  }
}
