import { IStoreApiItem, IStoreApiList, StoreApiList, StoreApiItem } from 'src/app/common';
import { IEquipmentHistoryEvent, IEquipmentHistoryFilters } from '../../models/equipment-history.model';

export interface IEquipmentHistoryState {
  equipmentHistory: IStoreApiList<IEquipmentHistoryEvent[]>;
  historyFilters: IStoreApiItem<IEquipmentHistoryFilters>
}

export class EquipmentHistoryState {
  equipmentHistory: IStoreApiList<IEquipmentHistoryEvent[]> = new StoreApiList<IEquipmentHistoryEvent[]>();
  historyFilters: IStoreApiItem<IEquipmentHistoryFilters> = new StoreApiItem<IEquipmentHistoryFilters>();
}
