<div class="blue-bar-items">
  <div
    (mouseenter)="showHistoryFilterButtonTooltip = true"
    (mouseleave)="showHistoryFilterButtonTooltip = false"
    id="bluebar-filter--wrap"
    data-test-id="equipment_history.filter_wrap"
  >
    <button
      type="button"
      odxButton
      size="medium"
      variant="highlight"
      class="blue-bar-btn"
      (click)="onFilterClick($event)"
      id="bluebar-filter"
      data-test-id="equipment_history.filter_btn"
      [disabled]="isModalOpened"
      [pTooltip]="!hideFilterGearIcon ? filterButtonTooltipContent : null"
      tooltipPosition="top"
      positionLeft="5"
      positionTop="-10"
      tooltipStyleClass="footer-tooltip"
    >
      <ng-template #filterButtonTooltipContent>
        @if (!hideFilterGearIcon) {
          <div data-test-id="equipment_history.filter_tooltip">
            {{ 'EQUIPMENT_HISTORY.STR_FILTER_TOOLTIP' | translate }}
          </div>
        }
      </ng-template>

      <odx-icon
        size="inline"
        [ngClass]="{ 'filter-settings': !hideFilterGearIcon }"
        [name]="!hideFilterGearIcon ? '' : 'filter'"
      ></odx-icon>
    </button>
  </div>
</div>
