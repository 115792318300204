<div class="row justify-content-between">
  <div class="col-md-6 col-sm-6 col-xs-12 reset-filter-container">
    <div class="incident-statistic">
      <div class="no" data-test-id="remote_monitoring.running_incidents">
        {{ getTeamsNo(runningIncidentsNo) || '00' }}
      </div>
      <div class="desc">
        {{ 'REMOTE_MONITORING.STR_RUNNING_INCIDENTS' | translate }}
      </div>
    </div>
    <div class="incident-statistic">
      <div class="no" data-test-id="remote_monitoring.fireman_in_incidents">
        {{ getTeamsNo(firemanInRunningIncidentsNo) || '00' }}
      </div>
      <div class="desc">{{ 'REMOTE_MONITORING.STR_FIREMAN_IN_INCIDENTS' | translate }}</div>
    </div>
  </div>

  <div class="col-md-6 col-sm-6 col-xs-12 text-end pl-8 pr-10" [ngClass]="{ 'pr-25': scrollbarExists }">
    <div class="incident-statistic">
      <div class="no" data-test-id="remote_monitoring.ended_incidents">{{ getTeamsNo(endedIncidentsNo) || '00' }}</div>
      <div class="desc">{{ 'REMOTE_MONITORING.STR_ENDED_INCIDENTS' | translate }}</div>
    </div>
    <div class="incident-statistic me-0">
      <div class="no" data-test-id="remote_monitoring.fireman_in_ended_incidents">
        {{ getTeamsNo(firemanInEndedIncidentsNo) || '00' }}
      </div>
      <div class="desc">{{ 'REMOTE_MONITORING.STR_FIREMAN_IN_ENDED_INCIDENTS' | translate }}</div>
    </div>
  </div>
</div>
