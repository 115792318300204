import { PropertyBag } from 'src/app/common';

export const tableCustomFiltersLayout: string[] = [
  'equipmentId',
  'barcode',
  'rfid',
  'serialNo',
  'category',
  'type',
  'model',
  'operationalStatus',
  'nextServiceDate',
  'taskName',
  'owner',
  'location',
  'lastServiceDate',
];

export const readModelSortKeys: PropertyBag = {
  equipmentId: 'equipmentId',
};

export const tableDefaultSorting: string = 'nextServiceDate,ASC';
