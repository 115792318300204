import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IFireStationList } from '../../../common';
import {
  IDeviceConnection,
  IDeviceConnectionLicenseInfo,
  IDeviceConnectionToken,
  IDevicePage,
  IMigrationID,
} from '../../models/device-connection.model';
import { IProtectorMigrationErrorPage, IProtectorMigrationStatus } from '../../models/protector-migration.model';
import { DeviceConnectionActionTypes } from './device-connection-action-types';

export class RequestDeviceConnectionPage implements Action {
  readonly type: DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_PAGE =
    DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_PAGE;

  constructor(public payload?: any) {}
}

export class RequestDeviceConnectionExport implements Action {
  readonly type: DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_EXPORT =
    DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_EXPORT;

  constructor(public payload?: any) {}
}

export class ReceiveDeviceConnectionPage implements Action {
  readonly type: DeviceConnectionActionTypes.RECEIVE_DEVICE_CONNECTION_PAGE =
    DeviceConnectionActionTypes.RECEIVE_DEVICE_CONNECTION_PAGE;

  constructor(public payload: IDevicePage) {}
}

export class ErrorReceiveDeviceConnectionPage implements Action {
  readonly type: DeviceConnectionActionTypes.ERROR_RECEIVE_DEVICE_CONNECTION_PAGE =
    DeviceConnectionActionTypes.ERROR_RECEIVE_DEVICE_CONNECTION_PAGE;

  constructor(public payload: HttpErrorResponse) {}
}

export class ResetDeviceConnectionPage implements Action {
  readonly type: DeviceConnectionActionTypes.RESET_DEVICE_CONNECTION_PAGE =
    DeviceConnectionActionTypes.RESET_DEVICE_CONNECTION_PAGE;
}

export class RequestDeviceConnectionById implements Action {
  readonly type: DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_BY_ID =
    DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_BY_ID;

  constructor(public payload: string) {}
}

export class ReceiveDeviceConnectionById implements Action {
  readonly type: DeviceConnectionActionTypes.RECEIVE_DEVICE_CONNECTION_BY_ID =
    DeviceConnectionActionTypes.RECEIVE_DEVICE_CONNECTION_BY_ID;

  constructor(public payload: IDeviceConnection) {}
}

export class ErrorReceiveDeviceConnectionById implements Action {
  readonly type: DeviceConnectionActionTypes.ERROR_RECEIVE_DEVICE_CONNECTION_BY_ID =
    DeviceConnectionActionTypes.ERROR_RECEIVE_DEVICE_CONNECTION_BY_ID;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestAddDeviceConnection implements Action {
  readonly type: DeviceConnectionActionTypes.REQUEST_ADD_DEVICE_CONNECTION =
    DeviceConnectionActionTypes.REQUEST_ADD_DEVICE_CONNECTION;

  constructor(public payload: IDeviceConnection) {}
}

export class ReceiveAddDeviceConnection implements Action {
  readonly type: DeviceConnectionActionTypes.RECEIVE_ADD_DEVICE_CONNECTION =
    DeviceConnectionActionTypes.RECEIVE_ADD_DEVICE_CONNECTION;

  constructor(public payload: IDeviceConnection | any) {}
}

export class ErrorAddDeviceConnection implements Action {
  readonly type: DeviceConnectionActionTypes.ERROR_ADD_DEVICE_CONNECTION =
    DeviceConnectionActionTypes.ERROR_ADD_DEVICE_CONNECTION;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestUpdateDeviceConnection implements Action {
  readonly type: DeviceConnectionActionTypes.REQUEST_UPDATE_DEVICE_CONNECTION =
    DeviceConnectionActionTypes.REQUEST_UPDATE_DEVICE_CONNECTION;

  constructor(public payload: IDeviceConnection) {}
}

export class ReceiveUpdateDeviceConnection implements Action {
  readonly type: DeviceConnectionActionTypes.RECEIVE_UPDATE_DEVICE_CONNECTION =
    DeviceConnectionActionTypes.RECEIVE_UPDATE_DEVICE_CONNECTION;

  constructor(public payload: IDeviceConnection) {}
}

export class ErrorUpdateDeviceConnection implements Action {
  readonly type: DeviceConnectionActionTypes.ERROR_UPDATE_DEVICE_CONNECTION =
    DeviceConnectionActionTypes.ERROR_UPDATE_DEVICE_CONNECTION;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestDeviceConnectionIdentifier implements Action {
  readonly type: DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_IDENTIFIER =
    DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_IDENTIFIER;
}

export class ReceiveDeviceConnectionIdentifier implements Action {
  readonly type: DeviceConnectionActionTypes.RECEIVE_DEVICE_CONNECTION_IDENTIFIER =
    DeviceConnectionActionTypes.RECEIVE_DEVICE_CONNECTION_IDENTIFIER;

  constructor(public payload: IDeviceConnectionToken) {}
}

export class ErrorReceiveDeviceConnectionIdentifier implements Action {
  readonly type: DeviceConnectionActionTypes.ERROR_RECEIVE_DEVICE_CONNECTION_IDENTIFIER =
    DeviceConnectionActionTypes.ERROR_RECEIVE_DEVICE_CONNECTION_IDENTIFIER;

  constructor(public payload: HttpErrorResponse) {}
}

export class ResetDeviceConnectionIdentifier implements Action {
  readonly type: DeviceConnectionActionTypes.RESET_DEVICE_CONNECTION_IDENTIFIER =
    DeviceConnectionActionTypes.RESET_DEVICE_CONNECTION_IDENTIFIER;
}

export class RequestDeviceConnectionDelete implements Action {
  readonly type: DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_DELETE =
    DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_DELETE;

  constructor(public payload: IDeviceConnection) {}
}

export class ReceiveDeviceConnectionDelete implements Action {
  readonly type: DeviceConnectionActionTypes.RECEIVE_DEVICE_CONNECTION_DELETE =
    DeviceConnectionActionTypes.RECEIVE_DEVICE_CONNECTION_DELETE;

  constructor(public payload: IDeviceConnection) {}
}

export class ErrorReceiveDeviceConnectionDelete implements Action {
  readonly type: DeviceConnectionActionTypes.ERROR_RECEIVE_DEVICE_CONNECTION_DELETE =
    DeviceConnectionActionTypes.ERROR_RECEIVE_DEVICE_CONNECTION_DELETE;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestUpdateDeviceConnectionAuthToken implements Action {
  readonly type: DeviceConnectionActionTypes.REQUEST_UPDATE_DEVICE_CONNECTION_AUTH_TOKEN =
    DeviceConnectionActionTypes.REQUEST_UPDATE_DEVICE_CONNECTION_AUTH_TOKEN;

  constructor(
    public aggregateId: string,
    public version: number,
  ) {}
}

export class ReceiveUpdateDeviceConnectionAuthToken implements Action {
  readonly type: DeviceConnectionActionTypes.RECEIVE_UPDATE_DEVICE_CONNECTION_AUTH_TOKEN =
    DeviceConnectionActionTypes.RECEIVE_UPDATE_DEVICE_CONNECTION_AUTH_TOKEN;

  constructor(public payload: any) {}
}

export class ErrorReceiveUpdateDeviceConnectionAuthToken implements Action {
  readonly type: DeviceConnectionActionTypes.ERROR_RECEIVE_UPDATE_DEVICE_CONNECTION_AUTH_TOKEN =
    DeviceConnectionActionTypes.ERROR_RECEIVE_UPDATE_DEVICE_CONNECTION_AUTH_TOKEN;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestDeviceConnectionCategories implements Action {
  readonly type: DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_CATEGORIES =
    DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_CATEGORIES;
}

export class ReceiveDeviceConnectionCategories implements Action {
  readonly type: DeviceConnectionActionTypes.RECEIVE_DEVICE_CONNECTION_CATEGORIES =
    DeviceConnectionActionTypes.RECEIVE_DEVICE_CONNECTION_CATEGORIES;

  constructor(public payload: any) {}
}

export class ErrorReceiveDeviceConnectionCategories implements Action {
  readonly type: DeviceConnectionActionTypes.ERROR_RECEIVE_DEVICE_CONNECTION_CATEGORIES =
    DeviceConnectionActionTypes.ERROR_RECEIVE_DEVICE_CONNECTION_CATEGORIES;

  constructor(public payload: HttpErrorResponse) {}
}

export class ResetDeviceConnection implements Action {
  readonly type: DeviceConnectionActionTypes.RESET_DEVICE_CONNECTION =
    DeviceConnectionActionTypes.RESET_DEVICE_CONNECTION;
}

export class RequestToCheckRAMConnectorToken implements Action {
  readonly type: DeviceConnectionActionTypes.REQUEST_TO_CHECK_RAM_CONNECTOR_TOKEN =
    DeviceConnectionActionTypes.REQUEST_TO_CHECK_RAM_CONNECTOR_TOKEN;

  constructor(public payload: string) {}
}

export class ReceiveToCheckRAMConnectorToken implements Action {
  readonly type: DeviceConnectionActionTypes.RECEIVE_TO_CHECK_RAM_CONNECTOR_TOKEN =
    DeviceConnectionActionTypes.RECEIVE_TO_CHECK_RAM_CONNECTOR_TOKEN;

  constructor(public payload: any) {}
}

export class ErrorReceiveToCheckRAMConnectorToken implements Action {
  readonly type: DeviceConnectionActionTypes.ERROR_RECEIVE_TO_CHECK_RAM_CONNECTOR_TOKEN =
    DeviceConnectionActionTypes.ERROR_RECEIVE_TO_CHECK_RAM_CONNECTOR_TOKEN;

  constructor(public payload: HttpErrorResponse) {}
}

export class ResetCheckRAMConnectorToken implements Action {
  readonly type: DeviceConnectionActionTypes.RESET_CHECK_RAM_CONNECTOR_TOKEN =
    DeviceConnectionActionTypes.RESET_CHECK_RAM_CONNECTOR_TOKEN;
}

export class RequestDeviceConnectionLicenseInfo implements Action {
  readonly type: DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_LICENSE_INFO =
    DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_LICENSE_INFO;
}

export class ReceiveDeviceConnectionLicenseInfo implements Action {
  readonly type: DeviceConnectionActionTypes.RECEIVE_DEVICE_CONNECTION_LICENSE_INFO =
    DeviceConnectionActionTypes.RECEIVE_DEVICE_CONNECTION_LICENSE_INFO;

  constructor(public payload: IDeviceConnectionLicenseInfo) {}
}

export class ErrorReceiveDeviceConnectionLicenseInfo implements Action {
  readonly type: DeviceConnectionActionTypes.ERROR_RECEIVE_DEVICE_CONNECTION_LICENSE_INFO =
    DeviceConnectionActionTypes.ERROR_RECEIVE_DEVICE_CONNECTION_LICENSE_INFO;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestDeviceConnectionAssignedFireStations implements Action {
  readonly type: DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_ASSIGNED_FIRE_STATIONS =
    DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_ASSIGNED_FIRE_STATIONS;
}

export class ReceiveDeviceConnectionAssignedFireStations implements Action {
  readonly type: DeviceConnectionActionTypes.RECEIVE_DEVICE_CONNECTION_ASSIGNED_FIRE_STATIONS =
    DeviceConnectionActionTypes.RECEIVE_DEVICE_CONNECTION_ASSIGNED_FIRE_STATIONS;

  constructor(public payload: IFireStationList) {}
}

export class ErrorReceiveDeviceConnectionAssignedFireStations implements Action {
  readonly type: DeviceConnectionActionTypes.ERROR_RECEIVE_DEVICE_CONNECTION_ASSIGNED_FIRE_STATIONS =
    DeviceConnectionActionTypes.ERROR_RECEIVE_DEVICE_CONNECTION_ASSIGNED_FIRE_STATIONS;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestStartProtectorMigration implements Action {
  readonly type: DeviceConnectionActionTypes.REQUEST_START_PROTECTOR_MIGRATION =
    DeviceConnectionActionTypes.REQUEST_START_PROTECTOR_MIGRATION;
}
export class ReceiveStartProtectorMigration implements Action {
  readonly type: DeviceConnectionActionTypes.RECEIVE_START_PROTECTOR_MIGRATION =
    DeviceConnectionActionTypes.RECEIVE_START_PROTECTOR_MIGRATION;

  constructor(public payload: IMigrationID) {}
}
export class ErrorReceiveStartProtectorMigration implements Action {
  readonly type: DeviceConnectionActionTypes.ERROR_RECEIVE_START_PROTECTOR_MIGRATION =
    DeviceConnectionActionTypes.ERROR_RECEIVE_START_PROTECTOR_MIGRATION;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestCancelProtectorMigrationInRAM implements Action {
  readonly type: DeviceConnectionActionTypes.REQUEST_CANCEL_PROTECTOR_MIGRATION_IN_RAM =
    DeviceConnectionActionTypes.REQUEST_CANCEL_PROTECTOR_MIGRATION_IN_RAM;
}
export class ReceiveCancelProtectorMigrationInRAM implements Action {
  readonly type: DeviceConnectionActionTypes.RECEIVE_CANCEL_PROTECTOR_MIGRATION_IN_RAM =
    DeviceConnectionActionTypes.RECEIVE_CANCEL_PROTECTOR_MIGRATION_IN_RAM;
}
export class ErrorReceiveCancelProtectorMigrationInRAM implements Action {
  readonly type: DeviceConnectionActionTypes.ERROR_RECEIVE_CANCEL_PROTECTOR_MIGRATION_IN_RAM =
    DeviceConnectionActionTypes.ERROR_RECEIVE_CANCEL_PROTECTOR_MIGRATION_IN_RAM;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestCancelProtectorMigration implements Action {
  readonly type: DeviceConnectionActionTypes.REQUEST_CANCEL_PROTECTOR_MIGRATION =
    DeviceConnectionActionTypes.REQUEST_CANCEL_PROTECTOR_MIGRATION;

  constructor(public payload: string) {}
}
export class ReceiveCancelProtectorMigration implements Action {
  readonly type: DeviceConnectionActionTypes.RECEIVE_CANCEL_PROTECTOR_MIGRATION =
    DeviceConnectionActionTypes.RECEIVE_CANCEL_PROTECTOR_MIGRATION;
}
export class ErrorReceiveCancelProtectorMigration implements Action {
  readonly type: DeviceConnectionActionTypes.ERROR_RECEIVE_CANCEL_PROTECTOR_MIGRATION =
    DeviceConnectionActionTypes.ERROR_RECEIVE_CANCEL_PROTECTOR_MIGRATION;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestProtectorMigrationStatus implements Action {
  readonly type: DeviceConnectionActionTypes.REQUEST_PROTECTOR_MIGRATION_STATUS =
    DeviceConnectionActionTypes.REQUEST_PROTECTOR_MIGRATION_STATUS;

  constructor(public payload: string) {}
}
export class ReceiveProtectorMigrationStatus implements Action {
  readonly type: DeviceConnectionActionTypes.RECEIVE_PROTECTOR_MIGRATION_STATUS =
    DeviceConnectionActionTypes.RECEIVE_PROTECTOR_MIGRATION_STATUS;

  constructor(public payload: IProtectorMigrationStatus) {}
}
export class ErrorReceiveProtectorMigrationStatus implements Action {
  readonly type: DeviceConnectionActionTypes.ERROR_RECEIVE_PROTECTOR_MIGRATION_STATUS =
    DeviceConnectionActionTypes.ERROR_RECEIVE_PROTECTOR_MIGRATION_STATUS;

  constructor(public payload: HttpErrorResponse) {}
}
export class ResetProtectorMigrationStatus implements Action {
  readonly type: DeviceConnectionActionTypes.RESET_PROTECTOR_MIGRATION_STATUS =
    DeviceConnectionActionTypes.RESET_PROTECTOR_MIGRATION_STATUS;
}

export class RequestProtectorMigrationErrorPage implements Action {
  readonly type: DeviceConnectionActionTypes.REQUEST_PROTECTOR_MIGRATION_ERROR_PAGE =
    DeviceConnectionActionTypes.REQUEST_PROTECTOR_MIGRATION_ERROR_PAGE;

  constructor(
    public migrationId: string,
    public payload?: any,
  ) {}
}
export class ReceiveProtectorMigrationErrorPage implements Action {
  readonly type: DeviceConnectionActionTypes.RECEIVE_PROTECTOR_MIGRATION_ERROR_PAGE =
    DeviceConnectionActionTypes.RECEIVE_PROTECTOR_MIGRATION_ERROR_PAGE;

  constructor(public payload: IProtectorMigrationErrorPage) {}
}
export class ErrorReceiveProtectorMigrationErrorPage implements Action {
  readonly type: DeviceConnectionActionTypes.ERROR_RECEIVE_PROTECTOR_MIGRATION_ERROR_PAGE =
    DeviceConnectionActionTypes.ERROR_RECEIVE_PROTECTOR_MIGRATION_ERROR_PAGE;

  constructor(public payload: HttpErrorResponse) {}
}
export class ResetProtectorMigrationErrorPage implements Action {
  readonly type: DeviceConnectionActionTypes.RESET_PROTECTOR_MIGRATION_ERROR_PAGE =
    DeviceConnectionActionTypes.RESET_PROTECTOR_MIGRATION_ERROR_PAGE;
}
