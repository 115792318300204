import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard } from '@okta/okta-angular';
import { ReportCenterContainerComponent } from '../components/report-center-container/report-center-container.component';
import { ReportCenterComponent } from '../components/report-center/report-center.component';
import { CreateUpdateEventMarkerComponent } from '../components/view-update-incident/incident-analysis/create-update-event-marker/create-update-event-marker.component';
import { UploadMediaFileComponent } from '../components/view-update-incident/media-assets/upload-media-file/upload-media-file.component';
import { ViewUpdateIncidentComponent } from '../components/view-update-incident/view-update-incident.component';
import { ReportCenterModuleRoutes } from '../constants/report-center-module-routes.constants';
import { DeactivateEventMarkerGuard, DeactivateIncidentGuard } from './can-deactivate.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [OktaAuthGuard],
    component: ReportCenterContainerComponent,
    children: [
      { path: '', component: ReportCenterComponent, pathMatch: 'full' },
      {
        path: `${ReportCenterModuleRoutes.update}/:id`,
        component: ViewUpdateIncidentComponent,
        canActivate: [OktaAuthGuard],
        canDeactivate: [DeactivateIncidentGuard],
        children: [
          {
            path: ReportCenterModuleRoutes.createEventMarker,
            component: CreateUpdateEventMarkerComponent,
            canDeactivate: [DeactivateEventMarkerGuard],
          },
          {
            path: `${ReportCenterModuleRoutes.updateEventMarker}/:eventId`,
            component: CreateUpdateEventMarkerComponent,
            canDeactivate: [DeactivateEventMarkerGuard],
          },
          {
            path: ReportCenterModuleRoutes.uploadMediaFile,
            component: UploadMediaFileComponent,
          },
        ],
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportCenterRoutingModule {}
