import { INotificationConstant } from 'src/app/common';

export class ProtectorSoftwareNotificationConstants {
  public static readonly notificationCodes: INotificationConstant = {
    PROTECTOR_SOFTWARE_CONNECTION_LOST: {
      value: 'PROTECTOR_SOFTWARE_CONNECTION_LOST',
      text: 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_PROTECTOR_SOFTWARE_CONNECTION_LOST',
    },
    NEWER_RAM_CONNECTION_VERSION: {
      value: 'NEWER_RAM_CONNECTION_VERSION',
      text: 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_NEWER_VERSION_OF_RAM_CONNECTOR',
    },
    OUTDATED_RAM_CONNECTION_VERSION: {
      value: 'OUTDATED_RAM_CONNECTION_VERSION',
      text: 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_OUTDATED_VERSION_OF_RAM_CONNECTOR',
    },
    OUTDATED_RAM_CONNECTION_VERSION_WITHOUT_AUTO_UPDATE: {
      value: 'OUTDATED_RAM_CONNECTION_VERSION',
      text: 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_OUTDATED_VERSION_OF_RAM_CONNECTOR_WITHOUT_AUTO_UPDATE',
    },
    RAM_AUTO_UPDATE_START: {
      value: 'RAM_AUTO_UPDATE_START',
      text: 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_RAM_AUTO_UPDATE_START',
    },
    PROTECTOR_SOFTWARE_UNEXPECTED_ERROR: {
      value: '20001',
      text: 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_PROTECTOR_SOFTWARE_UNEXPECTED_ERROR',
    },
    PROTECTOR_SOFTWARE_PORT_NOT_SET_EXCEPTION: {
      value: '20002',
      text: 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_PROTECTOR_SOFTWARE_PORT_NOT_SET_EXCEPTION',
    },
    PROTECTOR_SOFTWARE_FAILED_TO_CONNECT: {
      value: '20003',
      text: 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_PROTECTOR_SOFTWARE_FAILED_TO_CONNECT',
    },
    PROTECTOR_SOFTWARE_TEST_PARAMETERS_NOT_VALID: {
      value: '20004',
      text: 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_PROTECTOR_SOFTWARE_TEST_PARAMETERS_NOT_VALID',
    },
    PROTECTOR_SOFTWARE_REQUEST_BODY_NOT_VALID: {
      value: '20005',
      text: 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_PROTECTOR_SOFTWARE_REQUEST_BODY_NOT_VALID',
    },
    PROTECTOR_SOFTWARE_ERROR: {
      value: '20006',
      text: 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_PROTECTOR_SOFTWARE_ERROR',
    },
    PROTECTOR_SOFTWARE_NO_TOKEN_SET: {
      value: '20008',
      text: 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_PROTECTOR_SOFTWARE_NO_TOKEN_SET',
    },
    PROTECTOR_SOFTWARE_TEST_NOT_SAVED: {
      value: '20009',
      text: 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_PROTECTOR_SOFTWARE_TEST_NOT_SAVED',
    },
    PROTECTOR_SOFTWARE_INVALID_PROTECTOR_TYPE: {
      value: '20010',
      text: 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_PROTECTOR_SOFTWARE_INVALID_PROTECTOR_TYPE',
    },
    PROTECTOR_SOFTWARE_FAILED_TO_UPDATE: {
      value: '20011',
      text: 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_PROTECTOR_SOFTWARE_FAILED_TO_UPDATE',
    },
    PROTECTOR_SOFTWARE_BACKEND_NO_AVAILABLE: {
      value: '20012',
      text: 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_PROTECTOR_SOFTWARE_BACKEND_NO_AVAILABLE',
    },
    PROTECTOR_SOFTWARE_BACKEND_IS_DOWN: {
      value: '20013',
      text: 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_PROTECTOR_SOFTWARE_BACKEND_IS_DOWN',
    },
    PROTECTOR_SOFTWARE_IS_STARTING: {
      value: '20014',
      text: 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_PROTECTOR_SOFTWARE_IS_STARTING',
    },
    PROTECTOR_SOFTWARE_IS_NOT_AVAILABLE: {
      value: '20016',
      text: 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_PROTECTOR_SOFTWARE_IS_NOT_AVAILABLE',
    },
    PROTECTOR_SOFTWARE_TEST_ALREADY_RUNNING: {
      value: '20018',
      text: 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_PROTECTOR_SOFTWARE_TEST_ALREADY_RUNNING',
    },
    PROTECTOR_SOFTWARE_VERSION_NOT_SUPPORTED: {
      value: '20020',
      text: 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_PROTECTOR_SOFTWARE_VERSION_NOT_SUPPORTED',
    },
    PROTECTOR_SOFTWARE_UNHANDLED_ERROR: {
      value: 'RAM_UNHANDLED_ERROR',
      text: 'PROTECTOR_SOFTWARE_NOTIFICATION.NOTIFICATION.STR_PROTECTOR_SOFTWARE_UNHANDLED_ERROR',
    },
  };

  public static readonly ramCustomErrors: unknown = {
    '0': ProtectorSoftwareNotificationConstants.notificationCodes.PROTECTOR_SOFTWARE_CONNECTION_LOST,
    '400': ProtectorSoftwareNotificationConstants.notificationCodes.PROTECTOR_SOFTWARE_TEST_PARAMETERS_NOT_VALID,
    '20001': ProtectorSoftwareNotificationConstants.notificationCodes.PROTECTOR_SOFTWARE_UNEXPECTED_ERROR,
    '20002': ProtectorSoftwareNotificationConstants.notificationCodes.PROTECTOR_SOFTWARE_PORT_NOT_SET_EXCEPTION,
    '20003': ProtectorSoftwareNotificationConstants.notificationCodes.PROTECTOR_SOFTWARE_FAILED_TO_CONNECT,
    '20004': ProtectorSoftwareNotificationConstants.notificationCodes.PROTECTOR_SOFTWARE_TEST_PARAMETERS_NOT_VALID,
    '20005': ProtectorSoftwareNotificationConstants.notificationCodes.PROTECTOR_SOFTWARE_REQUEST_BODY_NOT_VALID,
    '20006': ProtectorSoftwareNotificationConstants.notificationCodes.PROTECTOR_SOFTWARE_ERROR,
    '20008': ProtectorSoftwareNotificationConstants.notificationCodes.PROTECTOR_SOFTWARE_NO_TOKEN_SET,
    '20009': ProtectorSoftwareNotificationConstants.notificationCodes.PROTECTOR_SOFTWARE_TEST_NOT_SAVED,
    '20011': ProtectorSoftwareNotificationConstants.notificationCodes.PROTECTOR_SOFTWARE_FAILED_TO_UPDATE,
    '20012': ProtectorSoftwareNotificationConstants.notificationCodes.PROTECTOR_SOFTWARE_BACKEND_NO_AVAILABLE,
    '20013': ProtectorSoftwareNotificationConstants.notificationCodes.PROTECTOR_SOFTWARE_BACKEND_IS_DOWN,
    '20014': ProtectorSoftwareNotificationConstants.notificationCodes.PROTECTOR_SOFTWARE_IS_STARTING,
    '20016': ProtectorSoftwareNotificationConstants.notificationCodes.PROTECTOR_SOFTWARE_IS_NOT_AVAILABLE,
    '20018': ProtectorSoftwareNotificationConstants.notificationCodes.PROTECTOR_SOFTWARE_TEST_ALREADY_RUNNING,
  };
}
