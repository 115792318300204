import { extractOnlyDate } from 'src/app/common/utils/date-utils/date.utils';
import { ITask as IConfigurationService, IInterval } from 'src/app/configuration/models/equipment-configuration.models';

export interface IEquipment {
  aggregateId: string;
  version?: number;
  isMigrated?: boolean;
  generalData?: IEquipmentGeneralData;
  locationAssignment?: IEquipmentLocationAssignment;
  manufacturerData?: IManufacturerData;
  supplierData?: ISupplierData;
  serviceIntervals?: IEquipmentServiceInterval[];
  additionalEquipmentData?: IAdditionalEquipmentData;
  nextDueService?: string;
  nextDueServiceDate?: string;
}

export interface IEquipmentGeneralData {
  identifier: string;
  barcode: string;
  rfid: string;
  serialNo: string;
  partNo: string;
  partName: string;
  equipmentModel?: string;
  hasProtectorType?: boolean;
  hasCheckList?: boolean;
  isCylinderType?: boolean;
  maxPressure?: number;
  model: IEquipmentModelDTO;
  description: string;
  operationalStatus: string;
  inUseDate: Date;
  outOfOrderDate: Date;
  owner: string;
  firstRegisteredDate: Date | string;
  outOfServiceReason: string;
}

export interface ITestParameters {
  service: IParameter[];
  procedures: (IParameter | string)[];
  pressureGaugeComparisons: IParameter[];
  devices: IParameter[];
  types: IParameter[];
}

export interface ITestEquipment {
  aggregateId: string;
  testerName: string;
  equipmentInformation: IEquipmentInformation;
  testParameters: ITestParameters;
}

export interface ITestStatus {
  previousTestWasCancelled: boolean;
  version: string;
  status: string;
  testResult?: ITestStatusResults;
  isNetworkInstallation?: boolean;
  isLatestVersion?: boolean;
  latestVersion?: string;
  currentVersion?: string;
}

export interface IRAMStatus {
  ramIsUp: boolean;
  port?: string;
}

export interface ITestStatusResults {
  testRun: string;
  testResult: string;
}

export interface ITestStartResponse {
  identifier: string;
}

export interface ITestStart {
  testDevice: string;
  testKind?: string;
  testType: string;
  testProcedure: string;
  outOfServiceDate: string;
  outOfServiceReason: string;
  pressureGaugeId: string;
  equipmentId: string;
  equipmentId2: string;
  testerName: string;
  reason: string;
  locationPath: string[];
  equipmentModel: string;
  equipmentCategory: string;
  serviceData: {
    includedServices: IServiceDataDTO[];
    others: IServiceDataDTO[];
  };
  rfid?: string;
  equipmentAggregateId?: string;
}

export interface IServiceDataDTO {
  serviceId: string;
  taskId?: string;
  reason?: string;
  taskName?: string;
  origin?: string;
  interval: IInterval;
  lastTestDate?: Date | string;
  nextTestDate?: Date | string;
}

export class ServiceDataDTO implements IServiceDataDTO {
  serviceId: string;
  taskId: string;
  reason?: string;
  taskName: string;
  origin: string;
  interval: IInterval;
  lastTestDate?: Date | string;
  nextTestDate?: Date | string;

  constructor();
  constructor(serviceDTO: IServiceDataDTO);
  constructor(serviceDTO?: IServiceDataDTO) {
    if (!serviceDTO) {
      this.taskId = null;
      this.serviceId = null;
      this.reason = null;
      this.taskName = null;
      this.origin = null;
      this.interval = null;
      this.lastTestDate = null;
      this.nextTestDate = null;
    } else {
      this.serviceId = serviceDTO.serviceId;
      this.taskId = serviceDTO.serviceId;
      this.reason = serviceDTO.reason;
      this.taskName = serviceDTO.taskName;
      this.origin = serviceDTO.origin;
      this.interval = serviceDTO.interval;
      this.lastTestDate = extractOnlyDate(serviceDTO.lastTestDate);
      this.nextTestDate = extractOnlyDate(serviceDTO.nextTestDate);
    }
  }
}

export interface IEquipmentInformation {
  type: string;
  identification: string;
  model: string;
}

export interface IParameter {
  id: string;
  name: string;
  kind?: string;
}

export interface IEquipmentCategory {
  id: string;
  name: string;
}

export interface IEquipmentTypeDTO {
  id: string;
  name: string;
  protectorTestType: string;
  serviceDefinitions: IServices;
}

export interface IEquipmentModelDTO {
  id: string;
  name: string;
  type: IEquipmentTypeDTO;
  category: IEquipmentCategory;
}

export interface ILocationAggregateId {
  aggregateId: string;
  name: string;
}

export interface IEquipmentLocationAssignment {
  aggregateId: string;
  name?: string;
  type?: string;
  address?: string;
  identifier?: string;
  path?: ILocationAggregateId[];
  details?: string;
  version?: number;
}

export interface IManufacturerData extends IManufacturer {
  aggregateId: string;
  organizationName: string;
  address: string;
  comment: string;
  contactName: string;
  email: string;
  phone: string;
  version?: number;
}

export interface ISupplierData extends ISupplier {
  organizationName: string;
  address: string;
  comment: string;
  contactName: string;
  email: string;
  phone: string;
  version?: number;
}

export interface IManufacturer {
  manufacturerDate: Date | string;
}
export interface ISupplier {
  aggregateId: string;
  supplierDate: Date | string;
}

export interface IServiceIntervalEquipment {
  intervalId: string;
  name: string;
  duration: number;
  isInherited?: boolean;
  lastTestDate?: Date | string;
  nextTestDate?: Date | string;
}

export class ServiceIntervalEquipment implements IServiceIntervalEquipment {
  intervalId: string;
  name: string;
  duration: number;
  lastTestDate?: Date | string;
  nextTestDate?: Date | string;

  constructor();
  constructor(serviceInterval: IServiceIntervalEquipment);
  constructor(serviceInterval?: IServiceIntervalEquipment) {
    if (!serviceInterval) {
      this.intervalId = null;
      this.name = null;
      this.duration = null;
      this.lastTestDate = null;
      this.nextTestDate = null;
    } else {
      this.intervalId = serviceInterval.intervalId;
      this.name = serviceInterval.name;
      this.duration = serviceInterval.duration;
      this.lastTestDate = serviceInterval.lastTestDate;
      this.nextTestDate = serviceInterval.nextTestDate;
    }
  }
}

export interface IServices {
  value: number | string;
}

export class EquipmentCategory {
  id: string = null;
  name: string = null;
}

export class Services {
  value: number | string = null;
}

export class EquipmentType {
  id: string = null;
  name: string = null;
  protectorTestType: string = null;
  serviceDefinitions: IServices = new Services();
}

export class EquipmentModel {
  id: string = null;
  name: string = null;
  type: IEquipmentTypeDTO = new EquipmentType();
  category: IEquipmentCategory = new EquipmentCategory();
}

export class EquipmentGeneralData implements IEquipmentGeneralData {
  identifier: string = null;
  barcode: string = null;
  rfid: string = null;
  serialNo: string = null;
  partNo: string = null;
  partName: string = null;
  model: IEquipmentModelDTO = new EquipmentModel();
  description: string = null;
  operationalStatus: string = null;
  inUseDate: Date = null;
  outOfOrderDate: Date = null;
  owner: string = null;
  hasProtectorType?: boolean = null;
  isCylinderType?: boolean = null;
  maxPressure?: number = null;
  firstRegisteredDate: Date = null;
  outOfServiceReason: string = null;
}

export class ManufacturerData implements IManufacturerData {
  aggregateId: string = null;
  name: string = null;
  organizationName: string = null;
  address: string = null;
  comment: string = null;
  email: string = null;
  phone: string = null;
  contactName: string = null;
  manufacturerDate: Date | string = null;
  version?: number = null;

  constructor();
  constructor(supplier: IManufacturerData);
  constructor(supplier?: IManufacturerData) {
    if (!supplier) {
      this.aggregateId = null;
      this.organizationName = null;
      this.address = null;
      this.comment = null;
      this.email = null;
      this.phone = null;
      this.contactName = null;
      this.manufacturerDate = null;
      this.version = null;
    } else {
      this.aggregateId = supplier.aggregateId;
      this.organizationName = supplier.organizationName;
      this.address = supplier.address;
      this.comment = supplier.comment;
      this.email = supplier.email;
      this.phone = supplier.phone;
      this.contactName = supplier.contactName;
      this.manufacturerDate = supplier.manufacturerDate;
      this.version = supplier.version;
    }
  }
}

export class SupplierData implements ISupplierData {
  aggregateId: string = null;
  organizationName: string = null;
  address: string = null;
  comment: string = null;
  email: string = null;
  phone: string = null;
  contactName: string = null;
  supplierDate: Date | string = null;
  version?: number = null;

  constructor();
  constructor(supplier: ISupplierData);
  constructor(supplier?: ISupplierData) {
    if (!supplier) {
      this.aggregateId = null;
      this.organizationName = null;
      this.address = null;
      this.comment = null;
      this.email = null;
      this.phone = null;
      this.contactName = null;
      this.supplierDate = null;
      this.version = null;
    } else {
      this.aggregateId = supplier.aggregateId;
      this.organizationName = supplier.organizationName;
      this.address = supplier.address;
      this.comment = supplier.comment;
      this.email = supplier.email;
      this.phone = supplier.phone;
      this.contactName = supplier.contactName;
      this.supplierDate = supplier.supplierDate;
      this.version = supplier.version;
    }
  }
}

export class EquipmentLocationAssignment implements IEquipmentLocationAssignment {
  aggregateId: string;
  name?: string;
  type?: string;
  address?: string;
  identifier?: string;
  path?: ILocationAggregateId[];
  details?: string;

  constructor();
  constructor(location: IEquipmentLocationAssignment);
  constructor(location?: IEquipmentLocationAssignment) {
    if (!location) {
      this.aggregateId = null;
      this.name = null;
      this.type = null;
      this.address = null;
      this.identifier = null;
      this.path = null;
      this.details = null;
    } else {
      this.aggregateId = location.aggregateId;
      this.name = location.name;
      this.type = location.type;
      this.address = location.address;
      this.identifier = location.identifier;
      this.path = location.path;
      this.details = location.details;
    }
  }
}

export class Equipment implements IEquipment {
  aggregateId: string;
  version?: number;
  isMigrated: boolean;
  generalData: IEquipmentGeneralData;
  locationAssignment?: IEquipmentLocationAssignment;
  manufacturerData?: IManufacturerData;
  supplierData?: ISupplierData;
  additionalEquipmentData?: IAdditionalEquipmentData;
  serviceIntervals?: any[];
  nextDueService?: string;
  nextDueServiceDate?: string;

  constructor();
  constructor(eq: IEquipment);
  constructor(eq?: IEquipment) {
    if (!eq) {
      this.aggregateId = null;
      this.version = null;
      this.isMigrated = null;
      this.generalData = new EquipmentGeneralData();
      this.locationAssignment = new EquipmentLocationAssignment();
      this.manufacturerData = new ManufacturerData();
      this.supplierData = new SupplierData();
      this.additionalEquipmentData = new AdditionalEquipmentData();
      this.serviceIntervals = null;
      this.nextDueService = null;
      this.nextDueServiceDate = null;
    } else {
      this.aggregateId = eq.aggregateId;
      this.version = eq.version;
      this.isMigrated = eq.isMigrated;
      this.generalData = eq.generalData ? eq.generalData : new EquipmentGeneralData();
      this.locationAssignment = eq.locationAssignment ? eq.locationAssignment : new EquipmentLocationAssignment();
      this.manufacturerData = eq.manufacturerData ? eq.manufacturerData : new ManufacturerData();
      this.supplierData = eq.supplierData ? eq.supplierData : new SupplierData();
      this.additionalEquipmentData = eq.additionalEquipmentData
        ? eq.additionalEquipmentData
        : new AdditionalEquipmentData();
      this.serviceIntervals = eq.serviceIntervals;
      this.nextDueService = eq.nextDueService;
      this.nextDueServiceDate = eq.nextDueServiceDate;
    }
  }
}

export interface IEquipmentTableData {
  aggregateId: string;
  version: number;
  identifier: string;
  barcode: string;
  typeId: number;
  model: string;
  description: string;
  operationalStatus: string;
  serviceStatus: string;
  manufacturer: string;
  location: string;
  hasProtectorTestType?: boolean;
  owner: string;
  outOfService: string;
  firstRegisteredDate: string;
}

export class EquipmentTableData implements IEquipmentTableData {
  aggregateId: string;
  version: number;
  identifier: string;
  barcode: string;
  typeId: number;
  model: string;
  description: string;
  operationalStatus: string;
  serviceStatus: string;
  manufacturer: string;
  location: string;
  hasProtectorTestType: boolean;
  owner: string;
  outOfService: string;
  firstRegisteredDate: string;

  constructor();
  constructor(eq: IEquipmentTableData);
  constructor(eq?: IEquipmentTableData) {
    if (!eq) {
      this.aggregateId = null;
      this.version = null;
      this.identifier = null;
      this.barcode = null;
      this.typeId = null;
      this.model = null;
      this.description = null;
      this.operationalStatus = null;
      this.serviceStatus = null;
      this.manufacturer = null;
      this.location = null;
      this.hasProtectorTestType = null;
      this.owner = null;
      this.outOfService = null;
      this.firstRegisteredDate = null;
    } else {
      this.aggregateId = eq.aggregateId;
      this.version = eq.version;
      this.identifier = eq.identifier;
      this.barcode = eq.barcode;
      this.typeId = eq.typeId;
      this.model = eq.model;
      this.description = eq.description;
      this.operationalStatus = eq.operationalStatus;
      this.serviceStatus = eq.serviceStatus;
      this.manufacturer = eq.manufacturer;
      this.location = eq.location;
      this.hasProtectorTestType = eq.hasProtectorTestType;
      this.owner = eq.owner;
      this.outOfService = eq.outOfService;
      this.firstRegisteredDate = eq.firstRegisteredDate;
    }
  }
}

export interface IEquipmentListPage {
  currentPage: number;
  entries: IEquipmentTableData[];
  pageSize: number;
  totalPages: number;
  totalRecords: number;
}

export class EquipmentListPage implements IEquipmentListPage {
  currentPage: number;
  entries: IEquipmentTableData[];
  pageSize: number;
  totalPages: number;
  totalRecords: number;

  constructor();
  constructor(equipmentListPage: IEquipmentListPage);
  constructor(equipmentListPage?: IEquipmentListPage) {
    if (!equipmentListPage) {
      this.currentPage = null;
      this.entries = null;
      this.pageSize = null;
      this.totalPages = null;
      this.totalRecords = null;
    } else {
      this.currentPage = equipmentListPage.currentPage;
      this.entries = equipmentListPage.entries.map(
        (equipment: IEquipmentTableData) => new EquipmentTableData(equipment),
      );
      this.pageSize = equipmentListPage.pageSize;
      this.totalPages = equipmentListPage.totalPages;
      this.totalRecords = equipmentListPage.totalRecords;
    }
  }
}

export interface ICreateUpdateEquipment {
  aggregateId: string;
  version?: number;
  generalData?: ICreateUpdateGeneralData;
  manufacturerData?: ICreateUpdateManufacturerData;
  supplierData?: ICreateUpdateSupplierData;
  serviceIntervals?: ICreateUpdateServiceIntervals[];
  locationAssignment?: { aggregateId: string };
  additionalEquipmentData?: IAdditionalEquipmentData;
}
export class CreateUpdateEquipment implements ICreateUpdateEquipment {
  aggregateId: string;
  version?: number;
  generalData?: ICreateUpdateGeneralData;
  manufacturerData?: ICreateUpdateManufacturerData;
  supplierData?: ICreateUpdateSupplierData;
  serviceIntervals?: ICreateUpdateServiceIntervals[];
  locationAssignment?: ICreateUpdateLocationAssignment;
  additionalEquipmentData?: IAdditionalEquipmentData;

  constructor();
  constructor(equipment: ICreateUpdateEquipment);
  constructor(equipment?: ICreateUpdateEquipment) {
    if (!equipment) {
      this.aggregateId = null;
      this.version = null;
      this.generalData = new CreateUpdateGeneralData();
      this.manufacturerData = new CreateUpdateManufacturerData();
      this.supplierData = new CreateUpdateSupplierData();
      this.serviceIntervals = [new CreateUpdateServiceIntervals()];
      this.locationAssignment = new CreateUpdateLocationAssignment();
      this.additionalEquipmentData = new AdditionalEquipmentData();
    } else {
      this.aggregateId = equipment.aggregateId;
      this.version = equipment.version;
      this.generalData = new CreateUpdateGeneralData(equipment.generalData ?? null);
      this.manufacturerData = new CreateUpdateManufacturerData(equipment.manufacturerData ?? null);
      this.supplierData = new CreateUpdateSupplierData(equipment.supplierData ?? null);
      this.serviceIntervals = equipment.serviceIntervals.map(
        (service: ICreateUpdateServiceIntervals) => new CreateUpdateServiceIntervals(service),
      );
      this.locationAssignment = new CreateUpdateLocationAssignment(equipment.locationAssignment ?? null);
      this.additionalEquipmentData = new AdditionalEquipmentData(equipment.additionalEquipmentData ?? null);
    }
  }
}

export interface ICreateUpdateLocationAssignment {
  aggregateId: string;
}
export class CreateUpdateLocationAssignment implements ICreateUpdateLocationAssignment {
  aggregateId: string;

  constructor();
  constructor(location: ICreateUpdateLocationAssignment);
  constructor(location?: ICreateUpdateLocationAssignment) {
    if (!location) {
      this.aggregateId = null;
    } else {
      this.aggregateId = location.aggregateId;
    }
  }
}

export interface ICreateUpdateGeneralData {
  barcode: string;
  description: string;
  equipmentModel: string;
  identifier: string;
  operationalStatus: string;
  rfid: string;
  serialNo: string;
  partNo: string;
  partName: string;
  owner: string;
  inUseDate: string;
  outOfOrderDate: string;
  outOfServiceReason: string;
}

export class CreateUpdateGeneralData implements ICreateUpdateGeneralData {
  barcode: string;
  description: string;
  equipmentModel: string;
  identifier: string;
  operationalStatus: string;
  rfid: string;
  serialNo: string;
  partNo: string;
  partName: string;
  owner: string;
  inUseDate: string;
  outOfOrderDate: string;
  outOfServiceReason: string;

  constructor();
  constructor(generalData: ICreateUpdateGeneralData);
  constructor(generalData?: ICreateUpdateGeneralData) {
    if (!generalData) {
      this.barcode = null;
      this.description = null;
      this.equipmentModel = null;
      this.identifier = null;
      this.operationalStatus = null;
      this.rfid = null;
      this.serialNo = null;
      this.partNo = null;
      this.partName = null;
      this.owner = null;
      this.inUseDate = null;
      this.outOfOrderDate = null;
      this.outOfServiceReason = null;
    } else {
      this.barcode = generalData.barcode?.length < 1 ? null : generalData.barcode?.trimStart()?.trimEnd();
      this.description = generalData.description;
      this.equipmentModel = generalData.equipmentModel;
      this.identifier = generalData.identifier?.trimStart()?.trimEnd();
      this.operationalStatus = generalData.operationalStatus;
      this.rfid = generalData.rfid?.length < 1 ? null : generalData.rfid?.trimStart()?.trimEnd();
      this.serialNo = generalData.serialNo?.length < 1 ? null : generalData.serialNo?.trimStart()?.trimEnd();
      this.partNo = generalData.partNo?.length < 1 ? null : generalData.partNo?.trimStart()?.trimEnd();
      this.partName = generalData.partName?.length < 1 ? null : generalData.partName?.trimStart()?.trimEnd();
      this.owner = generalData.owner;
      this.inUseDate = extractOnlyDate(generalData.inUseDate) ?? null;
      this.outOfOrderDate = extractOnlyDate(generalData.outOfOrderDate) ?? null;
      this.outOfServiceReason = generalData.outOfServiceReason;
    }
  }
}

export interface ICreateUpdateManufacturerData {
  manufacturerDate: string;
}

export class CreateUpdateManufacturerData implements ICreateUpdateManufacturerData {
  manufacturerDate: string;

  constructor();
  constructor(manufacturer: ICreateUpdateManufacturerData);
  constructor(manufacturer?: ICreateUpdateManufacturerData) {
    if (!manufacturer) {
      this.manufacturerDate = null;
    } else {
      this.manufacturerDate = extractOnlyDate(manufacturer.manufacturerDate) ?? null;
    }
  }
}

export interface ICreateUpdateSupplierData {
  aggregateId: string;
  supplierDate: string;
}

export class CreateUpdateSupplierData implements ICreateUpdateSupplierData {
  aggregateId: string;
  supplierDate: string;

  constructor();
  constructor(supplier: ICreateUpdateSupplierData);
  constructor(supplier?: ICreateUpdateSupplierData) {
    if (!supplier) {
      this.aggregateId = null;
      this.supplierDate = null;
    } else {
      this.aggregateId = supplier.aggregateId ?? null;
      this.supplierDate = extractOnlyDate(supplier.supplierDate) ?? null;
    }
  }
}

export interface ICreateUpdateServiceIntervals {
  nextTestDate: string;
  lastTestDate: string;
  children: string[];
  serviceId: string;
  reason: string;
  isInherited: boolean;
  interval: IInterval;
}

export class CreateUpdateServiceIntervals implements ICreateUpdateServiceIntervals {
  nextTestDate: string;
  lastTestDate: string;
  children: string[];
  serviceId: string;
  reason: string;
  isInherited: boolean;
  interval: IInterval;

  constructor();
  constructor(interval: ICreateUpdateServiceIntervals);
  constructor(interval?: ICreateUpdateServiceIntervals) {
    if (!interval) {
      this.nextTestDate = null;
      this.lastTestDate = null;
      this.children = [];
      this.serviceId = null;
      this.reason = null;
      this.isInherited = null;
      this.interval = null;
    } else {
      this.nextTestDate = extractOnlyDate(interval.nextTestDate) ?? null;
      this.lastTestDate = extractOnlyDate(interval.lastTestDate) ?? null;
      this.children = interval.children ? interval.children.map((child: any) => child.serviceId) : [];
      this.serviceId = interval.serviceId;
      this.reason = interval.reason;
      this.isInherited = interval.isInherited;
      this.interval = interval.interval;
    }
  }
}

export interface IInvalidEquipment {
  code: number;
  errorMessage: string;
  equipmentIdentifier: string;
  lineNumber: number;
  invalidColumn: string;
  aggregateId?: number;
}

export interface IEquipmentServiceInterval {
  serviceId: string;
  taskId?: string;
  reason?: string;
  taskName?: string;
  isInherited: boolean;
  origin?: string;
  inheritedFrom?: string;
  interval: IInterval;
  hasCheckList?: boolean;
  children: any[];
  lastTestDate?: Date | string;
  nextTestDate?: Date | string;
  included?: boolean;
}

export class EquipmentServiceInterval implements IEquipmentServiceInterval {
  serviceId: string;
  taskId?: string;
  taskName: string;
  isInherited: boolean;
  origin?: string;
  inheritedFrom?: string;
  interval: IInterval;
  hasCheckList?: boolean;
  children: any[];
  lastTestDate: Date | string;
  nextTestDate: Date | string;

  constructor();
  constructor(interval: IEquipmentServiceInterval);
  constructor(interval?: IEquipmentServiceInterval | IConfigurationService) {
    if (!interval) {
      this.serviceId = null;
      this.taskId = null;
      this.taskName = null;
      this.hasCheckList = null;
      this.isInherited = null;
      this.inheritedFrom = null;
      this.interval = null;
      this.children = [];
      this.nextTestDate = null;
      this.lastTestDate = null;
    } else {
      this.hasCheckList = interval.hasCheckList;
      this.interval = interval.interval;
      this.serviceId = interval.serviceId;
      this.taskId = interval.taskId;
      this.taskName = interval.taskName;
      this.isInherited = interval.isInherited;
      this.inheritedFrom = interval.inheritedFrom;
      this.children = interval?.children ? interval.children.map((child: any) => child.serviceId) : [];
      this.nextTestDate = extractOnlyDate(interval.nextTestDate) ?? null;
      this.lastTestDate = extractOnlyDate(interval.lastTestDate) ?? null;
    }
  }
}

export interface IAdditionalEquipmentData {
  stockNumber: string;
  headUpDisplayId: string;
  backPlateId: string;
  gaugeId: string;
  passDeviceId: string;
  maskId: string;
  lungDemandValveId: string;
  pressureReducerId: string;
  cylinderId: string;
}

export class AdditionalEquipmentData implements IAdditionalEquipmentData {
  stockNumber: string;
  headUpDisplayId: string;
  backPlateId: string;
  gaugeId: string;
  passDeviceId: string;
  maskId: string;
  lungDemandValveId: string;
  pressureReducerId: string;
  cylinderId: string;

  constructor();
  constructor(data: IAdditionalEquipmentData);
  constructor(data?: IAdditionalEquipmentData) {
    if (!data) {
      this.stockNumber = null;
      this.headUpDisplayId = null;
      this.backPlateId = null;
      this.gaugeId = null;
      this.passDeviceId = null;
      this.maskId = null;
      this.lungDemandValveId = null;
      this.pressureReducerId = null;
      this.cylinderId = null;
    } else {
      this.stockNumber = data.stockNumber;
      this.headUpDisplayId = data.headUpDisplayId;
      this.backPlateId = data.backPlateId;
      this.gaugeId = data.gaugeId;
      this.passDeviceId = data.passDeviceId;
      this.maskId = data.maskId;
      this.lungDemandValveId = data.lungDemandValveId;
      this.pressureReducerId = data.pressureReducerId;
      this.cylinderId = data.cylinderId;
    }
  }
}

export interface IToken {
  token: string;
}
