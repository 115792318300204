<ng-template odxModal #workflowMobileModal let-modal="close">
  <odx-modal-header>
    <odx-area-header>
      {{ 'WORKSHOP_WORKFLOW_ASSET.STR_MOBILE_LOCATION_MODAL' | translate }}
    </odx-area-header>
  </odx-modal-header>
  <odx-modal-content [odxLoadingSpinner]="isLoading">
    <span class="mb-5">
      {{ 'WORKSHOP_WORKFLOW_ASSET.STR_MOBILE_LOCATION_NAME' | translate }}
      <strong>{{ mobileLocation?.name }}</strong>
    </span>

    <ignis-table
      [tableColumns]="tableColumns"
      [dataTable]="selectedEquipment"
      class="search-mobile-table"
      *ngIf="selectedEquipment?.length > 0; else noEquipments"
    >
    </ignis-table>

    <ng-template #noEquipments>
      <div class="mt-1">
        <span> {{ 'WORKSHOP_WORKFLOW_ASSET.STR_LOCATION_WITHOUT_EQUIPMENT' | translate }} </span>
      </div>
    </ng-template>
    <div class="separator"></div>
  </odx-modal-content>
  <odx-modal-footer>
    <button
      odxButton
      variant="primary"
      type="button"
      [disabled]="selectedEquipment?.length < 1"
      (click)="movePieces()"
      data-test-id="equipment_workflow_asset.move_items_btn"
    >
      {{ 'WORKSHOP_WORKFLOW_ASSET.STR_MOVE_ITEMS_BTN' | translate }}
    </button>
    <button
      odxButton
      variant="primary"
      (click)="moveLocation()"
      type="button"
      data-test-id="equipment_workflow_asset.move_locations_btn"
    >
      {{ 'WORKSHOP_WORKFLOW_ASSET.STR_MOVE_LOCATIONS_BTN' | translate }}
    </button>

    <button odxButton (click)="closeModal()" variant="secondary" data-test-id="modal_actions.cancel_btn">
      {{ 'WORKSHOP_WORKFLOW_ASSET.STR_CANCEL_BTN' | translate }}
    </button>
  </odx-modal-footer>
</ng-template>
