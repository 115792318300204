import { IStoreApiItem, IStoreApiList } from 'src/app/common';
import {
  IEditEquipmentHierarchy,
  IEquipmentConfiguration,
  IEquipmentHierarchy,
} from '../../models/equipment-configuration.models';
import { StoreApiItem } from './../../../common/models/store-api-item.model';
import { StoreApiList } from './../../../common/models/store-api-list.model';

export interface IConfigurationEquipmentHierarchyState {
  hierarchy: IStoreApiList<IEquipmentHierarchy[]>;
  newHierarchy: IStoreApiItem<IEquipmentHierarchy>;
  equipmentConfiguration: IStoreApiItem<IEquipmentConfiguration>;
  editedHierarchy: IStoreApiItem<IEditEquipmentHierarchy>;
  deletedHierarchy: IStoreApiItem<any>;
  importEquipmentHierarchy: IStoreApiItem<unknown>;
}

export class ConfigurationEquipmentHierarchyState implements IConfigurationEquipmentHierarchyState {
  hierarchy: IStoreApiList<IEquipmentHierarchy[]> = new StoreApiList();
  newHierarchy: IStoreApiItem<IEquipmentHierarchy> = new StoreApiItem();
  equipmentConfiguration: IStoreApiItem<IEquipmentConfiguration> = new StoreApiItem();
  editedHierarchy: IStoreApiItem<IEditEquipmentHierarchy> = new StoreApiItem();
  deletedHierarchy: IStoreApiItem<any> = new StoreApiItem();
  importEquipmentHierarchy: IStoreApiItem<unknown> = new StoreApiItem();
}
