import { ITableColumn } from 'src/app/common';

export const tableColumns: ITableColumn[] = [
  { field: 'startTime', header: 'REPORT_CENTER_TABLE.STR_DATE', visible: true },
  { field: 'incidentNumber', header: 'REPORT_CENTER_TABLE.STR_INCIDENT_NUMBER', visible: true },
  { field: 'type.value', header: 'REPORT_CENTER_TABLE.STR_INCIDENT_TYPE', visible: true },
  { field: 'startHour', header: 'REPORT_CENTER_TABLE.STR_START_TIME', visible: true },
  { field: 'duration', header: 'REPORT_CENTER_TABLE.STR_DURATION', visible: true },
  { field: 'ecpAddresses', header: 'REPORT_CENTER_TABLE.STR_LOCATION', visible: true },
  { field: 'ecpNames', header: 'REPORT_CENTER_TABLE.STR_UPLOAD_DEVICES', visible: true },
  { field: 'hubIds', header: 'REPORT_CENTER_TABLE.STR_HUB_ADDRESSES', visible: true },
  { field: 'firefighterNo', header: 'REPORT_CENTER_TABLE.STR_DEPLOYED_FIREFIGHTERS', visible: true },
  { field: 'occurredEvents', header: 'REPORT_CENTER_TABLE.STR_NOTEWORTHY_EVENTS', visible: true },
];
