import { IStoreApiItem, StoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IStoreApiList, StoreApiList } from 'src/app/common/models/store-api-list.model';
import { IEquipmentInitialSpecification } from '../../models/equipment-initial-specification.model';
import { IEquipment, IEquipmentListPage } from '../../models/equipment.model';
import { EquipmentHistoryState, IEquipmentHistoryState } from './history.model';
import { EquipmentLicenseInfoState, IEquipmentLicenseInfoState } from './license-info';
import { IRAMConnectorState, RAMConnectorState } from './ram-connector-state.model';
import { IServiceTaskState, ServiceTaskState } from './service-task.model';
import { ITestingState, TestingState } from './testing-state.model';

export interface IEquipmentState {
  equipmentData: IEquipmentDataState;
  equipmentHistory: IEquipmentHistoryState;
  equipmentInitialSpec: IEquipmentInitialSpecState;
  testing: ITestingState;
  serviceTask: IServiceTaskState;
  licenseInfo: IEquipmentLicenseInfoState;
  updateEquipmentFields: IUpdateEquipmentIdsState;
  ramConnector: IRAMConnectorState;
}

export class EquipmentState implements IEquipmentState {
  equipmentData: IEquipmentDataState = new EquipmentDataState();
  equipmentHistory: IEquipmentHistoryState = new EquipmentHistoryState();
  equipmentInitialSpec: IEquipmentInitialSpecState = new EquipmentInitialSpecState();
  testing: ITestingState = new TestingState();
  serviceTask: IServiceTaskState = new ServiceTaskState();
  licenseInfo: IEquipmentLicenseInfoState = new EquipmentLicenseInfoState();
  updateEquipmentFields: IUpdateEquipmentIdsState = new UpdateEquipmentIdsState();
  ramConnector: IRAMConnectorState = new RAMConnectorState();
}

export interface IEquipmentDataState {
  equipmentPage: IStoreApiItem<IEquipmentListPage>;
  selectedEquipment: IStoreApiItem<IEquipment>;
  savedEquipments: IStoreApiList<IEquipment[]>;
  savedLocationEquipments: IStoreApiList<IEquipment[]>;
  deletedEquipment: IStoreApiItem<IEquipment>;
  csv: IStoreApiItem<string>;
}

export class EquipmentDataState implements IEquipmentDataState {
  equipmentPage: IStoreApiItem<IEquipmentListPage> = new StoreApiItem();
  selectedEquipment: IStoreApiItem<IEquipment> = new StoreApiItem();
  savedEquipments: IStoreApiList<IEquipment[]> = new StoreApiList();
  savedLocationEquipments: IStoreApiList<IEquipment[]> = new StoreApiList();
  deletedEquipment: IStoreApiItem<IEquipment> = new StoreApiItem();
  csv: IStoreApiItem<string> = new StoreApiItem();
}

export interface IEquipmentInitialSpecState {
  equipmentInitialSpecification: IStoreApiList<IEquipmentInitialSpecification[]>;
  equipmentIdentifiersAreUnique: IStoreApiItem<string[]>;
}

export class EquipmentInitialSpecState implements IEquipmentInitialSpecState {
  equipmentInitialSpecification: IStoreApiList<IEquipmentInitialSpecification[]> = new StoreApiList();
  equipmentIdentifiersAreUnique: IStoreApiItem<string[]> = new StoreApiItem();
}

export interface IUpdateEquipmentIdsState {
  equipmentIds: IStoreApiItem<string[]>;
}

export class UpdateEquipmentIdsState implements IUpdateEquipmentIdsState {
  equipmentIds: IStoreApiItem<string[]> = new StoreApiItem();
}
