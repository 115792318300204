export interface ILocalizationProfile {
  language: string;
  dateFormat: string;
  currency: string;
  currencySymbol: string;
  timezone: string;
  theme: string;
  pressureUnit?: string;
}

export class LocalizationProfile {
  language: string;
  dateFormat: string;
  currency: string;
  currencySymbol: string;
  timezone: string;
  theme: string;
  pressureUnit?: string;

  constructor();
  constructor(profile: ILocalizationProfile);
  constructor(profile?: ILocalizationProfile) {
    if (!profile) {
      this.language = null;
      this.dateFormat = null;
      this.currency = null;
      this.currencySymbol = null;
      this.timezone = null;
      this.theme = null;
      this.pressureUnit = null;
    } else {
      this.language = profile.language;
      this.dateFormat = profile.dateFormat;
      this.currency = profile.currency;
      this.currencySymbol = profile.currencySymbol;
      this.timezone = profile.timezone;
      this.theme = profile.theme;
      this.pressureUnit = profile.pressureUnit;
    }
  }
}

export interface IMeasurementUnit {
  name: string;
}
