<ng-template odxModal #exportingModal>
  <odx-modal-header>
    <odx-area-header> {{ 'EXCEL_TABLE_EXPORT.STR_EXPORT_IN_PROGRESS' | translate }} </odx-area-header>
  </odx-modal-header>
  <odx-modal-content class="export-content">
    {{ 'EXCEL_TABLE_EXPORT.STR_WAIT_FOR_EXPORT_TO_FINISH' | translate }}
    <div class="empty">
      <button odxButton [odxLoadingSpinner]="true" odxLoadingSpinnerAutoColor variant="ghost"></button>
    </div>
  </odx-modal-content>
</ng-template>
