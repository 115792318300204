import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonConstants, EntryModel, IEntryModel } from 'src/app/common';
import { ReportCenterConstants } from 'src/app/report-center/constants/report-center.constants';

@Component({
  selector: 'ignis-incident-type-filter',
  templateUrl: './incident-type-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncidentTypeFilterComponent implements OnInit {
  incidentTypes: any[] = [];
  incidentTypesMap: any = {};
  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;

  @Input() typesList: any[] = [];
  @Input() filterService: any;
  @Input() selectedTypes: any[];

  @Output() handleSaveFilters: EventEmitter<any> = new EventEmitter();

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.incidentTypes = this.typesList.map((entry: IEntryModel) => ({
      ...entry,
      label: this.translateService.instant(
        ReportCenterConstants.incidentEntries.types.find((t: EntryModel) => t.value === entry.value)?.localizedName ||
          (entry.value[0].toUpperCase() + entry.value.slice(1)).replace('_', ' '),
      ),
    }));

    this.incidentTypesMap = this.incidentTypes.reduce((obj: any, item: IEntryModel) => {
      obj[item.value] = item.label;

      return obj;
    }, {});
  }

  saveFilters(field: string, value: any): void {
    this.handleSaveFilters.emit({ name: field, data: value });
  }

  removeSelectedTypes(): void {
    this.selectedTypes = [];
  }
}
