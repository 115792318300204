<ng-template odxModal #confirmChangeCategoryTypeModal>
  <odx-modal-hero icon="warning" variant="confirmation" data-test-id="CONFIRM_CLOSE_MODAL.STR_TITLE">
    {{ 'CONFIRM_CLOSE_MODAL.STR_TITLE' | translate }}
  </odx-modal-hero>
  <odx-modal-content>
    <p *ngIf="lastEquipmentDataChanged.lastEquipmentID !== equipmentForm.get('generalData.identifier').value">
      {{
        'CONFIRM_CHANGE_EQUIPMENT_ID.STR_CONFIRM'
          | translate
            : {
                lastEquipmentID: lastEquipmentDataChanged.lastEquipmentID,
                currentEquipmentID: equipmentForm.get('generalData.identifier').value,
              }
      }}
    </p>

    <p
      *ngIf="
        lastEquipmentDataChanged.lastEquipmentBarcode &&
        lastEquipmentDataChanged.lastEquipmentBarcode !== equipmentForm.get('generalData.barcode').value
      "
    >
      {{
        'CONFIRM_CHANGE_EQUIPMENT_BARCODE.STR_CONFIRM'
          | translate
            : {
                lastEquipmentBarcode: lastEquipmentDataChanged.lastEquipmentBarcode ?? '',
                currentEquipmentBarcode: equipmentForm.get('generalData.barcode').value
                  ? equipmentForm.get('generalData.barcode').value
                  : '-',
              }
      }}
    </p>

    <p
      *ngIf="
        lastEquipmentDataChanged.lastEquipmentRFID &&
        lastEquipmentDataChanged.lastEquipmentRFID !== equipmentForm.get('generalData.rfid').value
      "
    >
      {{
        'CONFIRM_CHANGE_EQUIPMENT_RFID.STR_CONFIRM'
          | translate
            : {
                lastEquipmentRFID: lastEquipmentDataChanged.lastEquipmentRFID ?? '',
                currentEquipmentRFID: equipmentForm.get('generalData.rfid').value
                  ? equipmentForm.get('generalData.rfid').value
                  : '-',
              }
      }}
    </p>

    <p
      *ngIf="
        lastEquipmentDataChanged.lastEquipmentCategory !== equipmentForm.get('generalData.model.category.name').value
      "
    >
      {{
        'CONFIRM_CHANGE_CATEGORY.STR_CONFIRM'
          | translate
            : {
                lastEquipmentCategory: lastEquipmentDataChanged.lastEquipmentCategory,
                currentEquipmentCategory: equipmentForm.get('generalData.model.category.name').value
                  ? equipmentForm.get('generalData.model.category.name').value
                  : '-',
              }
      }}
    </p>

    <p *ngIf="lastEquipmentDataChanged.lastEquipmentType !== equipmentForm.get('generalData.model.type.name').value">
      {{
        'CONFIRM_CHANGE_TYPE.STR_CONFIRM'
          | translate
            : {
                lastEquipmentType: lastEquipmentDataChanged.lastEquipmentType,
                currentEquipmentType: equipmentForm.get('generalData.model.type.name').value
                  ? equipmentForm.get('generalData.model.type.name').value
                  : '-',
              }
      }}
    </p>

    <p *ngIf="lastEquipmentDataChanged.lastEquipmentModel !== equipmentForm.get('generalData.model.name').value">
      {{
        'CONFIRM_CHANGE_MODEL.STR_CONFIRM'
          | translate
            : {
                lastEquipmentModel: lastEquipmentDataChanged.lastEquipmentModel,
                currentEquipmentModel: equipmentForm.get('generalData.model.name').value
                  ? equipmentForm.get('generalData.model.name').value
                  : '-',
              }
      }}
    </p>

    <p
      *ngIf="
        lastEquipmentDataChanged.lastEquipmentSerialNo &&
        lastEquipmentDataChanged.lastEquipmentSerialNo !== equipmentForm.get('generalData.serialNo').value
      "
    >
      {{
        'CONFIRM_CHANGE_EQUIPMENT_SERIAL_NO.STR_CONFIRM'
          | translate
            : {
                lastEquipmentSerialNo: lastEquipmentDataChanged.lastEquipmentSerialNo ?? '',
                currentEquipmentSerialNo: equipmentForm.get('generalData.serialNo').value
                  ? equipmentForm.get('generalData.serialNo').value
                  : '-',
              }
      }}
    </p>
  </odx-modal-content>

  <odx-modal-footer>
    <button odxButton variant="secondary" [odxModalClose]="false" data-test-id="modal_actions.no_btn">
      {{ 'CONFIRM_CLOSE_MODAL.STR_NO_BTN' | translate }}
    </button>
    <button odxButton variant="primary" [odxModalClose]="true" data-test-id="modal_actions.yes_btn">
      {{ 'CONFIRM_CLOSE_MODAL.STR_YES_BTN' | translate }}
    </button>
  </odx-modal-footer>
</ng-template>
