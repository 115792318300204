<router-outlet></router-outlet>
<ignis-progress-bar *ngIf="isLoading || equipmentConfigurationIsLoading"></ignis-progress-bar>

<odx-area-header size="large">
  <div class="row [ custom-icon-row ]">
    <div class="custom-icon-wrapper me-5">
      <div class="col-md- me-2">
        <odx-icon size="xlarge" name="breathing-apparatus"></odx-icon>
      </div>
      @if (
        selectedEquipment ||
        equipmentForm.get('generalData.identifier').value ||
        equipmentForm.get('generalData.model.name').value
      ) {
        <div class="col-md- [ custom-ellipsis ]">
          <dl class="custom-attributes">
            <dd
              class="attributes-value top-dd mb-0 mt-0"
              [title]="
                selectedEquipment && !equipmentForm.get('generalData.identifier').value
                  ? selectedEquipment.generalData.identifier
                  : equipmentForm.get('generalData.identifierClone').value ||
                    equipmentForm.get('generalData.identifier').value
              "
              data-test-id="create_update_equipment.attributes_identifier"
            >
              {{
                selectedEquipment && !equipmentForm.get('generalData.identifier').value
                  ? selectedEquipment.generalData.identifier
                  : equipmentForm.get('generalData.identifierClone').value ||
                    equipmentForm.get('generalData.identifier').value
              }}
            </dd>
            <dd
              class="attributes-value bottom-dd mb-0 mt-0"
              [title]="
                selectedEquipment && equipmentForm.get('generalData.model.name').value
                  ? selectedEquipment.generalData.model.name
                  : equipmentForm.get('generalData.model.name').value
              "
              data-test-id="create_update_equipment.attributes_model"
            >
              {{ equipmentForm.get('generalData.model.name').value }}
            </dd>
          </dl>
        </div>
      } @else {
        <div class="col-md-">
          <dl>
            <dd class="attributes-value" data-test-id="create_update_equipment.attributes_required_no_data">
              {{ 'CREATE_UPDATE_EQUIPMENT.STR_CREATE_EQUIPMENT_NO_DATA' | translate }}
            </dd>
          </dl>
        </div>
      }
    </div>

    <div class="custom-icon-wrapper me-5">
      <div class="col-md- me-2">
        <odx-icon size="xlarge" name="alarm-stel"></odx-icon>
      </div>
      @if (lastTest || nextTest) {
        <div class="col-md-">
          <dl class="custom-attributes mb-0">
            <dd
              class="attributes-value m-w-100 top-dd mb-0 mt-0"
              data-test-id="create_update_equipment.attributes_last_test"
              *ngIf="lastTest"
              [title]="lastTest | date: format"
            >
              <span class="me-2">
                {{ 'CREATE_UPDATE_EQUIPMENT.STR_CREATE_EQUIPMENT_LAST_TEST' | translate }}
              </span>
              {{ lastTest | date: format }}
            </dd>
            <dd
              class="attributes-value m-w-100 bottom-dd mb-0 mt-0"
              data-test-id="create_update_equipment.attributes_next_test"
              *ngIf="nextTest"
              [title]="nextTest | date: format"
            >
              <span class="me-2">
                {{ 'CREATE_UPDATE_EQUIPMENT.STR_CREATE_EQUIPMENT_NEXT_TEST' | translate }}
              </span>
              {{ nextTest | date: format }}
            </dd>
          </dl>
        </div>
      } @else {
        <div class="col-md-">
          <dl>
            <dd class="attributes-value" data-test-id="create_update_equipment.attributes_test_no_data">
              {{ 'CREATE_UPDATE_EQUIPMENT.STR_CREATE_EQUIPMENT_NO_DATA' | translate }}
            </dd>
          </dl>
        </div>
      }
    </div>

    <div class="custom-icon-wrapper me-5">
      <div class="col-md- me-2">
        <odx-icon size="xlarge" name="building"></odx-icon>
      </div>
      @if (
        selectedLocation ||
        equipmentForm.get('locationAssignment.identifier').value ||
        equipmentForm.get('locationAssignment.name').value
      ) {
        <div class="col-md- [ c-custom-ellipsis ]">
          <dl class="custom-attributes">
            <dd
              class="attributes-value top-dd mb-0 mt-0"
              [title]="
                selectedLocation && !equipmentForm.get('locationAssignment.identifier').value
                  ? selectedLocation.identifier
                  : equipmentForm.get('locationAssignment.identifier').value
              "
              data-test-id="create_update_equipment.attributes_location_identifier"
            >
              {{
                selectedLocation && !equipmentForm.get('locationAssignment.identifier').value
                  ? selectedLocation.identifier
                  : equipmentForm.get('locationAssignment.identifier').value
              }}
            </dd>
            <dd
              class="attributes-value bottom-dd mb-0 mt-0"
              [title]="
                selectedLocation && !equipmentForm.get('locationAssignment.name').value
                  ? selectedLocation.name
                  : equipmentForm.get('locationAssignment.name').value
              "
              data-test-id="create_update_equipment.attributes_location_name"
            >
              {{
                selectedLocation && !equipmentForm.get('locationAssignment.name').value
                  ? selectedLocation.name
                  : equipmentForm.get('locationAssignment.name').value
              }}
            </dd>
          </dl>
        </div>
      } @else {
        <div class="col-md-">
          <dl>
            <dd class="attributes-value" data-test-id="create_update_equipment.attributes_location_no_data">
              {{ 'CREATE_UPDATE_EQUIPMENT.STR_CREATE_EQUIPMENT_NO_DATA' | translate }}
            </dd>
          </dl>
        </div>
      }
    </div>

    @if (operationalStatus) {
      <div class="custom-icon-wrapper me-5">
        <div class="col-md- me-2">
          <odx-icon
            data-test-id="create_update_equipment.attributes_status_icon"
            size="xlarge"
            [name]="
              operationalStatus.value === 'OPERATIONAL'
                ? 'feedback-positive'
                : operationalStatus.value === 'DEFECTIVE_BUT_OPERATIONAL'
                  ? 'warning'
                  : 'error'
            "
          ></odx-icon>
        </div>
        <div class="col-md-">
          <dl class="custom-attributes" data-test-id="create_update_equipment.attributes_status_name">
            <dd class="attributes-value">
              {{ operationalStatus.localizedName | translate }}
            </dd>
          </dl>
        </div>
      </div>
    }
  </div>
</odx-area-header>

<div *ngIf="!isLoading">
  <div class="custom-odx-tab-bar odx-margin-bottom-24">
    <div class="odx-tab-bar__inner">
      <div class="odx-tab-bar__panel">
        <div class="back-btn-group ml--10">
          @if (equipmentForm.dirty) {
            <button
              odxNavigationBack
              class="odx-tab-bar-item"
              (click)="confirmCloseModalOpen()"
              data-test-id="create_update_equipment.equipment_back"
            ></button>
            <span class="odx-fs-normal"> {{ 'CREATE_UPDATE_EQUIPMENT.STR_EQUIPMENT_BACK' | translate }}</span>
          } @else {
            <button
              odxNavigationBack
              class="odx-tab-bar-item"
              (click)="navigateBack(true)"
              data-test-id="create_update_equipment.equipment_back"
            ></button>
            <span class="odx-fs-normal"> {{ 'CREATE_UPDATE_EQUIPMENT.STR_EQUIPMENT_BACK' | translate }}</span>
          }
        </div>

        <div
          [ngClass]="{
            'tab-is-active': activeTab === tabs.GENERAL,
            'tab-has-errors': tabsWithErrors.includes(tabs.GENERAL),
          }"
        >
          <button
            odxButton
            #generalTabBtn
            [disabled]="disableTabs"
            (click)="setActiveTab(tabs.GENERAL)"
            id="generalTab"
            class="odx-tab-bar-item"
            data-test-id="create_update_equipment.create_equipment_general_tab"
            [ngClass]="{
              disabled: disableTabs,
            }"
            [pTooltip]="!disableTabs ? null : noPermissionTooltip"
            tooltipPosition="top"
            positionTop="-10"
            tooltipStyleClass="tab-top-btn-tooltip"
          >
            {{ 'CREATE_UPDATE_EQUIPMENT.STR_CREATE_EQUIPMENT_GENERAL_TAB' | translate }}
          </button>
        </div>

        <div
          [ngClass]="{
            'tab-is-active': activeTab === tabs.TASKS,
            'tab-has-errors': tabsWithErrors.includes(tabs.TASKS),
          }"
        >
          <button
            odxButton
            #tasksTabBtn
            (click)="setActiveTab(tabs.TASKS)"
            class="odx-tab-bar-item"
            id="tasksTab"
            data-test-id="create_update_equipment.create_equipment_services_tab"
          >
            {{ 'CREATE_UPDATE_EQUIPMENT.STR_CREATE_EQUIPMENT_SERVICES_TAB' | translate }}
          </button>
        </div>

        <div
          [ngClass]="{
            'tab-is-active': activeTab === tabs.ASSIGNMENTS,
            'tab-has-errors': tabsWithErrors.includes(tabs.ASSIGNMENTS),
          }"
        >
          <button
            odxButton
            [disabled]="disableTabs"
            (click)="setActiveTab(tabs.ASSIGNMENTS)"
            class="odx-tab-bar-item"
            id="assignmentsTab"
            data-test-id="create_update_equipment.create_equipment_location_and_assignments_tab"
            [ngClass]="{
              disabled: disableTabs,
            }"
            [pTooltip]="!disableTabs ? null : noPermissionTooltip"
            tooltipPosition="top"
            positionTop="-10"
            tooltipStyleClass="tab-top-btn-tooltip"
          >
            {{ 'CREATE_UPDATE_EQUIPMENT.STR_CREATE_EQUIPMENT_LOCATION_AND_ASSIGNMENTS_TAB' | translate }}
          </button>
        </div>

        <div
          [ngClass]="{
            'tab-is-active': activeTab === tabs.MANUFACTURERS,
            'tab-has-errors': tabsWithErrors.includes(tabs.MANUFACTURERS),
          }"
        >
          <button
            odxButton
            [disabled]="disableTabs"
            (click)="setActiveTab(tabs.MANUFACTURERS)"
            class="odx-tab-bar-item"
            id="manufacturersTab"
            data-test-id="create_update_equipment.create_equipment_manufacturer_tab"
            [ngClass]="{
              disabled: disableTabs,
            }"
            [pTooltip]="!disableTabs ? null : noPermissionTooltip"
            tooltipPosition="top"
            positionTop="-10"
            tooltipStyleClass="tab-top-btn-tooltip"
          >
            {{ 'CREATE_UPDATE_EQUIPMENT.STR_CREATE_EQUIPMENT_MANUFACTURER_TAB' | translate }}
          </button>
        </div>

        <div
          *ngIf="eqIsMigrated"
          [ngClass]="{
            'tab-is-active': activeTab === tabs.ADDITIONAL_DETAILS,
            'tab-has-errors': tabsWithErrors.includes(tabs.ADDITIONAL_DETAILS),
          }"
        >
          <button
            odxButton
            [disabled]="disableTabs"
            (click)="setActiveTab(tabs.ADDITIONAL_DETAILS)"
            class="odx-tab-bar-item"
            id="additionalDetailsTab"
            data-test-id="create_update_equipment.create_equipment_additional_details_tab"
            [ngClass]="{
              disabled: disableTabs,
            }"
            [pTooltip]="!disableTabs ? null : noPermissionTooltip"
            tooltipPosition="top"
            positionTop="-10"
            tooltipStyleClass="tab-top-btn-tooltip"
          >
            {{ 'CREATE_UPDATE_EQUIPMENT.STR_CREATE_EQUIPMENT_ADDITIONAL_DETAILS_TAB' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <ng-template
    #noPermissionTooltip
    id="no_permission__tooltip"
    data-test-id="equipment_footer_items.no_permission_tooltip"
  >
    {{ 'EQUIPMENT_FOOTER_ITEMS.STR_NO_PERMISSION' | translate }}
  </ng-template>

  <form [formGroup]="equipmentForm" *ngIf="!isLoading" (ngSubmit)="onSubmit()">
    <ngx-simplebar [options]="scrollbarOptions" class="custom-equipment-scrollbar scrollbar-offset">
      <ignis-general
        *ngIf="activeTab === tabs.GENERAL && !disableTabs"
        [errors]="errors"
        [isSubmitted]="isSubmitted"
        [equipmentForm]="equipmentForm"
        [selectedEquipment]="selectedEquipment"
        [isLoading]="isLoading"
        [formatDate]="format"
        [modalType]="modalType"
        [categoryTypeModelOptions]="equipmentInitialSpecification"
        (operationalStatusChange)="onOperationalStatusChange($event)"
        (setupServices)="setEquipmentServices($event)"
        (markTabsWithErrors)="markTabsWithErrors()"
      ></ignis-general>

      <ignis-eq-tasks
        *ngIf="activeTab === tabs.TASKS"
        [equipmentForm]="equipmentForm"
        [formatDate]="format"
        [disableAllFields]="disableAllFields"
        (emitSelectedTask)="getSelectedTask($event)"
      ></ignis-eq-tasks>

      <ignis-assignments
        *ngIf="activeTab === tabs.ASSIGNMENTS && !disableTabs"
        [locations]="locations"
        [isSubmitted]="isSubmitted"
        [restrictToLocation]="restrictToLocation"
        [formatDate]="format"
        [equipmentForm]="equipmentForm"
        [disableAllFields]="disableAllFields"
        [selectedEquipment]="selectedEquipment"
        (showLocationChanged)="showLocationChanged($event)"
        (markTabsWithErrors)="markTabsWithErrors()"
      ></ignis-assignments>

      <ignis-manufacturer
        *ngIf="activeTab === tabs.MANUFACTURERS && !disableTabs"
        [isSubmitted]="isSubmitted"
        [formatDate]="format"
        [equipmentForm]="equipmentForm"
        [suppliers]="suppliers"
        [disableAllFields]="disableAllFields"
      ></ignis-manufacturer>

      <ignis-additional-details
        *ngIf="activeTab === tabs.ADDITIONAL_DETAILS && !disableTabs"
        [isSubmitted]="isSubmitted"
        [equipmentForm]="equipmentForm"
        [fieldError]="fieldError"
        [disableAllFields]="disableAllFields"
      ></ignis-additional-details>
    </ngx-simplebar>
  </form>
</div>

<ignis-confirm-change-category-type-ids
  [isOpen]="openConfirmChangeCategoryTypeModelOrID"
  [equipmentForm]="equipmentForm"
  [lastEquipmentDataChanged]="lastEquipmentDataChanged"
  (closeConfirmModal)="closeConfirmModal($event)"
>
</ignis-confirm-change-category-type-ids>

<ignis-confirmation-modal
  [isOpen]="isConfirmCloseModalOpen"
  (handleConfirmationModal)="navigateBack($event)"
  class="is-closed-modal"
>
  <div slot="content">
    {{ 'CONFIRM_CLOSE_MODAL.STR_DESCRIPTION_EQUIPMENT' | translate }}
  </div>
</ignis-confirmation-modal>

<ignis-confirm-start-cylinder-charge
  [isOpen]="isCylinderChargeConfirmationCloseModalOpen"
  (closeConfirmModal)="userResponseConfirmCylinderChargeBanner($event)"
>
</ignis-confirm-start-cylinder-charge>

<ignis-ram-connector-auto-update-modal
  [ramConnectorStatus]="ramConnectorStatus"
  [startAutoUpdate]="startAutoUpdate"
  [type]="notificationType.ERROR"
  (handleConfirmationModal)="startRAMConnectorAutoUpdateFromEQList($event)"
  (handleAutoUpdateStartsWithSuccess)="startAutoUpdate = false"
>
  <div slot="content">
    {{ 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_PROTECTOR_SOFTWARE_VERSION_NOT_SUPPORTED' | translate }}
  </div>
</ignis-ram-connector-auto-update-modal>

<footer class="blue-bar">
  <ignis-create-update-equipment-view-footer
    [activeEdit]="!!selectedEquipment"
    [disableStartTestBtn]="!selectedEquipment?.generalData.hasProtectorType"
    [disableCylinderCharghingBtn]="!selectedEquipment?.generalData.isCylinderType"
    [selectedTask]="selectedTask"
    [form]="equipmentForm"
    [mandatoryUncompletedFields]="mandatoryUncompletedFields"
    [isSubmitDisabled]="isSubmitting | async"
    [isModalOpened]="isConfirmCloseModalOpen || openConfirmChangeCategoryTypeModelOrID"
    (handleSubmit)="onSubmit()"
    (handleHistory)="redirectToHistory()"
    (handleCylinderCharge)="checkEquipmentStatusForCylinderCharge()"
    (handleCompleteChecklist)="completeChecklist()"
    (handleStartTesting)="startTest()"
  >
  </ignis-create-update-equipment-view-footer>
</footer>
