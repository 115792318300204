import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { HttpHelper } from 'src/app/common';
import { environment } from 'src/environments/environment';
import {
  IEditEquipmentHierarchy,
  IEditTask,
  IEquipmentConfiguration,
  IEquipmentHierarchy,
  INewTask,
  IUpdateTaskChildren,
} from '../models/equipment-configuration.models';
import { IChecklist } from './../models/checklist.model';

@Injectable({
  providedIn: 'root',
})
export class EquipmentConfigurationService {
  private baseUrl: string = environment.API_URLS.EQUIPMENT;

  constructor(
    private httpClient: HttpClient,
    private httpHelper: HttpHelper,
  ) {}

  public getEquipmentHierarchy(): Observable<IEquipmentHierarchy[] | HttpErrorResponse> {
    return this.httpClient.get<IEquipmentHierarchy[]>(`${this.baseUrl}/equipment-hierarchy`, this.httpHelper.options);
  }

  public addEquipmentHierarchy(params: any): Observable<IEquipmentHierarchy | HttpErrorResponse> {
    const version: number = params.payload.data.version;
    const hierarchyToAdd: IEquipmentHierarchy = structuredClone(params.payload.data);

    return this.httpClient.post<any>(
      `${this.baseUrl}/equipment-hierarchy/${params.payload.urlPath}`,
      hierarchyToAdd,
      version ? this.httpHelper.optionsWithIfMatch(version) : this.httpHelper.options,
    );
  }

  public getProtectorTypeValues(): Observable<any[] | HttpErrorResponse> {
    return this.httpClient.get<any[]>(`${this.baseUrl}/equipment-hierarchy/protector-types`, this.httpHelper.options);
  }

  public addTask(params: INewTask): Observable<INewTask | HttpErrorResponse> {
    const version: number = params.version;
    const serviceToAdd: INewTask = structuredClone(params);

    delete serviceToAdd.version;

    return this.httpClient.post<INewTask>(
      `${this.baseUrl}/equipment-hierarchy/${params.urlPath}/service-definition`,
      params,
      this.httpHelper.optionsWithIfMatch(version),
    );
  }

  public updateTaskChildren(params: IUpdateTaskChildren): Observable<IUpdateTaskChildren | HttpErrorResponse> {
    return this.httpClient.put<IUpdateTaskChildren>(
      `${this.baseUrl}/equipment-hierarchy/${params.urlPath}/children`,
      {
        ...params,
        version: undefined,
      },
      this.httpHelper.optionsWithIfMatch(params.version),
    );
  }

  public getEquipmentConfiguration(params: any): Observable<{ body: IEquipmentConfiguration } | HttpErrorResponse> {
    return this.httpClient
      .get<IEquipmentConfiguration>(`${this.baseUrl}/equipment-hierarchy/${params.payload.urlPath}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      })
      .pipe(
        tap((response: HttpResponseBase | any) => {
          /* istanbul ignore next */
          if (response.body) {
            response.body.version = response.headers?.get('etag');
          }
        }),
      );
  }

  public editEquipmentHierarchy(
    params: IEditEquipmentHierarchy,
  ): Observable<IEditEquipmentHierarchy | HttpErrorResponse> {
    const hierarchyToEdit: IEditEquipmentHierarchy = structuredClone(params);
    const hierarchyId: string = hierarchyToEdit.data.id;
    const version: number = hierarchyToEdit.data.version;

    delete hierarchyToEdit.data.aggregateId;
    delete hierarchyToEdit.data.id;
    delete hierarchyToEdit.data.version;

    return this.httpClient.put<IEditEquipmentHierarchy>(
      `${this.baseUrl}/equipment-hierarchy/${params.urlPath}/${hierarchyId}`,
      hierarchyToEdit.data,
      this.httpHelper.optionsWithIfMatch(version),
    );
  }

  public deleteEquipmentHierarchy(params: any): Observable<any | HttpErrorResponse> {
    const version: number = params.payload.version;

    return this.httpClient.delete<any>(
      `${this.baseUrl}/equipment-hierarchy/${params.payload.urlPath}`,
      this.httpHelper.optionsWithIfMatch(version),
    );
  }

  public editTask(params: IEditTask): Observable<IEditTask | HttpErrorResponse> {
    const taskToEdit: IEditTask = { ...params };
    const serviceId: string = taskToEdit.serviceId;
    const version: number = taskToEdit.version;

    delete taskToEdit.serviceId;
    delete taskToEdit.version;

    return this.httpClient.put<IEditTask>(
      `${this.baseUrl}/equipment-hierarchy/${params.urlPath}/${serviceId}`,
      taskToEdit,
      this.httpHelper.optionsWithIfMatch(version),
    );
  }

  public deleteTask(params: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.delete<any>(
      `${this.baseUrl}/equipment-hierarchy/${params.payload.urlPath}`,
      this.httpHelper.optionsWithIfMatch(params.payload.version),
    );
  }

  public addChecklist(params: IChecklist | any): Observable<IChecklist | HttpErrorResponse> {
    const version: number = params.payload.version;
    const checklistToAdd: IChecklist | any = { ...params.payload };

    delete checklistToAdd.version;

    return this.httpClient.post<IChecklist>(
      `${this.baseUrl}/equipment-hierarchy/${params.payload.urlPath}`,
      params.payload,
      this.httpHelper.optionsWithIfMatch(version),
    );
  }

  public editChecklist(params: IChecklist | any): Observable<IChecklist | HttpErrorResponse> {
    const editChecklist: IChecklist | any = { ...params.payload };
    const version: number = editChecklist.version;

    delete editChecklist.aggregateId;
    delete editChecklist.version;

    return this.httpClient.put<IChecklist>(
      `${this.baseUrl}/equipment-hierarchy/${params.payload.urlPath}`,
      editChecklist,
      this.httpHelper.optionsWithIfMatch(version),
    );
  }

  public deleteChecklist(params: any): Observable<IChecklist | HttpErrorResponse> {
    return this.httpClient.delete<IChecklist>(
      `${this.baseUrl}/equipment-hierarchy/${params.payload.urlPath}`,
      this.httpHelper.optionsWithIfMatch(params.payload.version),
    );
  }

  public importEquipmentHierarchy(payload: { language: string }): Observable<unknown | HttpErrorResponse> {
    return this.httpClient.post(
      `${this.baseUrl}/equipment-hierarchy/import/${payload.language}`,
      null,
      this.httpHelper.options,
    );
  }
}
