import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IEditEquipmentHierarchy, IEquipmentHierarchy } from 'src/app/configuration/models';
import { EquipmentHierarchyActionTypes } from './equipment-hierarchy-action-types';

export class RequestEquipmentHierarchy implements Action {
  readonly type: EquipmentHierarchyActionTypes.REQUEST_EQUIPMENT_HIERARCHY =
    EquipmentHierarchyActionTypes.REQUEST_EQUIPMENT_HIERARCHY;
}
export class ReceiveEquipmentHierarchy implements Action {
  readonly type: EquipmentHierarchyActionTypes.RECEIVE_EQUIPMENT_HIERARCHY =
    EquipmentHierarchyActionTypes.RECEIVE_EQUIPMENT_HIERARCHY;

  constructor(public payload: IEquipmentHierarchy[]) {}
}
export class ErrorReceiveEquipmentHierarchy implements Action {
  readonly type: EquipmentHierarchyActionTypes.ERROR_RECEIVE_EQUIPMENT_HIERARCHY =
    EquipmentHierarchyActionTypes.ERROR_RECEIVE_EQUIPMENT_HIERARCHY;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestAddEquipmentHierarchy implements Action {
  readonly type: EquipmentHierarchyActionTypes.REQUEST_ADD_EQUIPMENT_HIERARCHY =
    EquipmentHierarchyActionTypes.REQUEST_ADD_EQUIPMENT_HIERARCHY;

  constructor(public payload: any) {}
}
export class ReceiveAddEquipmentHierarchy implements Action {
  readonly type: EquipmentHierarchyActionTypes.RECEIVE_ADD_EQUIPMENT_HIERARCHY =
    EquipmentHierarchyActionTypes.RECEIVE_ADD_EQUIPMENT_HIERARCHY;

  constructor(public payload: IEquipmentHierarchy) {}
}
export class ErrorReceiveAddEquipmentHierarchy implements Action {
  readonly type: EquipmentHierarchyActionTypes.ERROR_RECEIVE_ADD_EQUIPMENT_HIERARCHY =
    EquipmentHierarchyActionTypes.ERROR_RECEIVE_ADD_EQUIPMENT_HIERARCHY;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestEditEquipmentHierarchy implements Action {
  readonly type: EquipmentHierarchyActionTypes.REQUEST_EDIT_EQUIPMENT_HIERARCHY =
    EquipmentHierarchyActionTypes.REQUEST_EDIT_EQUIPMENT_HIERARCHY;

  constructor(public payload: IEditEquipmentHierarchy) {}
}
export class ReceiveEditEquipmentHierarchy implements Action {
  readonly type: EquipmentHierarchyActionTypes.RECEIVE_EDIT_EQUIPMENT_HIERARCHY =
    EquipmentHierarchyActionTypes.RECEIVE_EDIT_EQUIPMENT_HIERARCHY;

  constructor(public payload: IEditEquipmentHierarchy) {}
}
export class ErrorReceiveEditEquipmentHierarchy implements Action {
  readonly type: EquipmentHierarchyActionTypes.ERROR_RECEIVE_EDIT_EQUIPMENT_HIERARCHY =
    EquipmentHierarchyActionTypes.ERROR_RECEIVE_EDIT_EQUIPMENT_HIERARCHY;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestDeleteEquipmentHierarchy implements Action {
  readonly type: EquipmentHierarchyActionTypes.REQUEST_DELETE_EQUIPMENT_HIERARCHY =
    EquipmentHierarchyActionTypes.REQUEST_DELETE_EQUIPMENT_HIERARCHY;

  constructor(public payload: { urlPath: string; version: number }) {}
}
export class ReceiveDeleteEquipmentHierarchy implements Action {
  readonly type: EquipmentHierarchyActionTypes.RECEIVE_DELETE_EQUIPMENT_HIERARCHY =
    EquipmentHierarchyActionTypes.RECEIVE_DELETE_EQUIPMENT_HIERARCHY;

  constructor(public payload: any) {}
}
export class ErrorReceiveDeleteEquipmentHierarchy implements Action {
  readonly type: EquipmentHierarchyActionTypes.ERROR_RECEIVE_DELETE_EQUIPMENT_HIERARCHY =
    EquipmentHierarchyActionTypes.ERROR_RECEIVE_DELETE_EQUIPMENT_HIERARCHY;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestImportEquipmentHierarchy implements Action {
  readonly type: EquipmentHierarchyActionTypes.REQUEST_IMPORT_EQUIPMENT_HIERARCHY =
    EquipmentHierarchyActionTypes.REQUEST_IMPORT_EQUIPMENT_HIERARCHY;

  constructor(public language: string) {}
}
export class ReceiveImportEquipmentHierarchy implements Action {
  readonly type: EquipmentHierarchyActionTypes.RECEIVE_IMPORT_EQUIPMENT_HIERARCHY =
    EquipmentHierarchyActionTypes.RECEIVE_IMPORT_EQUIPMENT_HIERARCHY;

  constructor(public payload: unknown) {}
}
export class ErrorReceiveImportEquipmentHierarchy implements Action {
  readonly type: EquipmentHierarchyActionTypes.ERROR_RECEIVE_IMPORT_EQUIPMENT_HIERARCHY =
    EquipmentHierarchyActionTypes.ERROR_RECEIVE_IMPORT_EQUIPMENT_HIERARCHY;

  constructor(public payload: HttpErrorResponse) {}
}
