<form odxForm [formGroup]="baWearerForm" [readonly]="baWearerForm.disabled">
  <section odxLayout="grid">
    <div odxLayout="12 12@desktop">
      <div class="firefighter-name mb-5">
        <odx-avatar variant="circle" size="large">
          <odx-icon
            name="user"
            *ngIf="
              baWearerForm.get('firstName')?.value?.trim().length < 1 &&
                baWearerForm.get('lastName')?.value?.trim().length < 1;
              else displayLetters
            "
          >
          </odx-icon>
          <ng-template #displayLetters>
            <label data-test-id="ba_wearer_personal_data.first_name_label">
              {{ baWearerForm.get('firstName')?.value?.trim()[0] }}
            </label>
            <label data-test-id="ba_wearer_personal_data.last_name_label">
              {{ baWearerForm.get('lastName')?.value?.trim()[0] }}
            </label>
          </ng-template>
        </odx-avatar>
        <span>{{ baWearerForm.get('firstName')?.value }} {{ baWearerForm.get('lastName')?.value }}</span>
      </div>
    </div>

    <div odxLayout="12 6@tablet 4@desktop" class="custom-form-field-pr-10">
      <odx-form-field
        variant="horizontal"
        label="{{ 'BA_WEARER.STR_PERSONAL_ID' | translate }}"
        [readonly]="baWearerForm.get('personalId').disabled"
      >
        <input
          type="text"
          ignisDisableAutocomplete
          id="personalId"
          data-test-id="ba_wearer.personal_id"
          odxFormFieldControl
          formControlName="personalId"
          [placeholder]="'BA_WEARER.STR_PERSONAL_ID' | translate"
          (input)="onValueChange($event, 'personalId')"
        />

        @if (
          httpCustomErrorCode &&
          httpCustomErrorCode.toString() === uniquePersonaIdCode &&
          baWearerForm.get('personalId').invalid &&
          baWearerForm.get('personalId').value?.length > 0
        ) {
          <odx-icon
            name="info"
            class="custom-validation-icon"
            iconSet="core"
            data-test-id="device_connection_form.name_wrap"
            [odxTooltip]="personalIdTooltipContent"
            [odxTooltipVisible]="
              httpCustomErrorCode &&
              httpCustomErrorCode.toString() === uniquePersonaIdCode &&
              baWearerForm.get('personalId').invalid &&
              baWearerForm.get('personalId').value?.length > 0
            "
          >
            <ng-template #personalIdTooltipContent>
              <div id="personalID-tooltip-error" data-test-id="ba_wearer.personal_id_error_tooltip">
                {{ 'BA_WEARER_NOTIFICATION.STR_NOT_UNIQUE_PERSONAL_ID' | translate }}
              </div>
            </ng-template>
          </odx-icon>
        }
      </odx-form-field>
    </div>

    <div odxLayout="12 6@tablet 4@desktop offset-1" class="custom-form-field-pr-10">
      <odx-form-field
        variant="horizontal"
        label="{{ 'BA_WEARER.STR_FIRST_NAME' | translate }}"
        [readonly]="baWearerForm.get('firstName').disabled"
      >
        <input
          type="text"
          ignisDisableAutocomplete
          id="firstName"
          data-test-id="ba_wearer.first_name"
          odxFormFieldControl
          formControlName="firstName"
          [placeholder]="'BA_WEARER.STR_FIRST_NAME' | translate"
        />
      </odx-form-field>
    </div>

    <div odxLayout="12 6@tablet 4@desktop" class="custom-form-field-pl-10">
      <odx-form-field
        variant="horizontal"
        label="{{ 'BA_WEARER.STR_LAST_NAME' | translate }}"
        [readonly]="baWearerForm.get('lastName').disabled"
      >
        <input
          type="text"
          ignisDisableAutocomplete
          id="lastName"
          data-test-id="ba_wearer.last_name"
          odxFormFieldControl
          formControlName="lastName"
          [placeholder]="'BA_WEARER.STR_LAST_NAME' | translate"
        />
      </odx-form-field>
    </div>

    <div odxLayout="12 6@tablet 4@desktop offset-1" class="custom-form-field-pr-10">
      <div class="odx-form-field--horizontal odx-form-field">
        <label class="odx-form-field__label" for="fireStation">
          <odx-form-field-label class="odx-form-field-label">
            {{ 'BA_WEARER.STR_FIRE_STATION' | translate }}
          </odx-form-field-label>
        </label>

        <div class="w-100 mb-15">
          <ng-select
            #fireStationsDD
            ngDefaultControl
            formControlName="fireStation"
            data-test-id="ba_wearer.fire_station"
            [items]="fireStationsToShow"
            [clearable]="true"
            clearAllText=""
            [placeholder]="'BA_WEARER.STR_FIRE_STATION_PLACEHOLDER' | translate"
            [notFoundText]="'BA_WEARER.STR_FIRE_STATION_NO_ENTRIES' | translate"
            (input)="addNewFireStation($event)"
            (change)="onFireStationChange($event)"
            (click)="toggleFireStationDropdown('fireStationsDD')"
            (blur)="fsDropdownAreOpened = []"
          >
          </ng-select>
        </div>
      </div>
    </div>

    <div odxLayout="12 6@tablet 4@desktop" class="custom-form-field-pl-10 position-relative">
      <odx-form-field
        variant="horizontal"
        label="{{ 'BA_WEARER.STR_RFID_CARD' | translate }}"
        [readonly]="baWearerForm.get('rfidCard').disabled"
        [ngClass]="{
          'has-error': baWearerForm.get('rfidCard').invalid && isSubmitted && baWearerForm.get('rfidCard').value,
        }"
      >
        <input
          type="number"
          ignisDisableAutocomplete
          id="rfidCard"
          data_test_id="ba_wearer.rfid_card"
          odxFormFieldControl
          formControlName="rfidCard"
          [placeholder]="'BA_WEARER.STR_RFID_CARD' | translate"
          [max]="255"
          [min]="1"
          (input)="onValueChange($event, 'rfidCard'); blockUserToInterval()"
        />

        @if (
          httpCustomErrorCode &&
          httpCustomErrorCode.toString() === uniqueRFIDCardCode &&
          baWearerForm.get('rfidCard').invalid &&
          baWearerForm.get('rfidCard').value
        ) {
          <odx-icon
            name="info"
            class="custom-validation-icon r-30"
            iconSet="core"
            data-test-id="device_connection_form.identifier_field_wrap"
            [odxTooltip]="rfidCardTooltipContent"
            [odxTooltipVisible]="
              httpCustomErrorCode &&
              httpCustomErrorCode.toString() === uniqueRFIDCardCode &&
              baWearerForm.get('rfidCard').invalid &&
              baWearerForm.get('rfidCard').value
            "
          >
            <ng-template #rfidCardTooltipContent>
              <div id="rfidCard-tooltip-error" data-test-id="ba_wearer.rfid_card_error_tooltip">
                {{ 'BA_WEARER_NOTIFICATION.STR_NOT_UNIQUE_RFID_CARD' | translate }}
              </div>
            </ng-template>
          </odx-icon>
        }
      </odx-form-field>
      <div class="position-relative rfid-card-info">
        <p class="odx-text--small">{{ 'BA_WEARER.STR_RFID_CARD_INFO_1' | translate }}</p>
        <p class="odx-text--small">{{ 'BA_WEARER.STR_RFID_CARD_INFO_2' | translate }}</p>
      </div>
    </div>

    <div odxLayout="12 12@desktop" class="mt-3">
      <hr class="ba-wearer-divider" />
    </div>
  </section>
</form>
