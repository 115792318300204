import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { SimplebarAngularModule } from 'simplebar-angular';
import { CommonModule, ExportTableExcelComponent, LoggerService, PressureUnitPipe } from 'src/app/common';
import { UploadFilesService } from 'src/app/common/services/upload-helper/upload-files.service';
import { ReportCenterContainerComponent } from '../components/report-center-container/report-center-container.component';
import { DurationFilterComponent } from '../components/report-center-list/duration-filter/duration-filter.component';
import { HubIdsFilterComponent } from '../components/report-center-list/hub-ids-filter/hub-ids-filter.component';
import { IncidentTypeFilterComponent } from '../components/report-center-list/incident-type-filter/incident-type-filter.component';
import { NoteworthyEventsColumnComponent } from '../components/report-center-list/noteworthy-events/noteworthy-events-column/noteworthy-events-column.component';
import { NoteworthyEventsFilterComponent } from '../components/report-center-list/noteworthy-events/noteworthy-events-filter/noteworthy-events-filter.component';
import { NoteworthyFilterOptionsComponent } from '../components/report-center-list/noteworthy-events/noteworthy-events-filter/noteworthy-filter-options/noteworthy-filter-options.component';
import { ReportCenterListViewFooterComponent } from '../components/report-center-list/report-center-list-view-footer/report-center-list-view-footer.component';
import { ReportCenterListComponent } from '../components/report-center-list/report-center-list.component';
import { ReportCenterComponent } from '../components/report-center/report-center.component';
import { CreateUpdateEventMarkerComponent } from '../components/view-update-incident/incident-analysis/create-update-event-marker/create-update-event-marker.component';
import { EventMarkerFormComponent } from '../components/view-update-incident/incident-analysis/create-update-event-marker/event-marker-form/event-marker-form.component';
import { EcpTimelineComponent } from '../components/view-update-incident/incident-analysis/ecp-timeline/ecp-timeline.component';
import { EventTimelineComponent } from '../components/view-update-incident/incident-analysis/event-timeline/event-timeline.component';
import { IncidentAnalysisComponent } from '../components/view-update-incident/incident-analysis/incident-analysis.component';
import { PressureTimelineComponent } from '../components/view-update-incident/incident-analysis/pressure-timeline/pressure-timeline.component';
import { IncidentInformationComponent } from '../components/view-update-incident/incident-information/incident-information.component';
import { MediaAssetsComponent } from '../components/view-update-incident/media-assets/media-assets.component';
import { MediaGalleryComponent } from '../components/view-update-incident/media-assets/media-gallery/media-galley.component';
import { MediaItemComponent } from '../components/view-update-incident/media-assets/media-gallery/media-item/media-item.component';
import { MediaNotesListComponent } from '../components/view-update-incident/media-assets/media-notes/media-notes-list/media-notes-list.component';
import { MediaNotesComponent } from '../components/view-update-incident/media-assets/media-notes/media-notes.component';
import { UploadMediaFileComponent } from '../components/view-update-incident/media-assets/upload-media-file/upload-media-file.component';
import { ViewUpdateIncidentFooterComponent } from '../components/view-update-incident/view-update-incident-footer/view-update-incident-footer.component';
import { ViewUpdateIncidentComponent } from '../components/view-update-incident/view-update-incident.component';
import { IncidentService } from '../services/incident.service';
import { IncidentEffects } from '../state/effects/incident.effects';
import { incidentReducer } from '../state/reducers/incident.reducer';
import { ConfirmMergeIncidentsComponent } from './../components/confirm-merge-incidents/confirm-merge-incidents.component';
import { MergeIncidentsComponent } from './../components/merge-incidents/merge-incidents.component';
import { ExportExcelService } from './../services/export-excel.service';
import { ReportCenterRoutingModule } from './report-center-routing.module';

@NgModule({
  declarations: [
    ReportCenterComponent,
    ReportCenterListComponent,
    ReportCenterListViewFooterComponent,
    ViewUpdateIncidentComponent,
    ViewUpdateIncidentFooterComponent,
    IncidentInformationComponent,
    IncidentAnalysisComponent,
    MergeIncidentsComponent,
    ConfirmMergeIncidentsComponent,
    EventTimelineComponent,
    EcpTimelineComponent,
    ReportCenterContainerComponent,
    PressureTimelineComponent,
    CreateUpdateEventMarkerComponent,
    EventMarkerFormComponent,
    MediaAssetsComponent,
    MediaGalleryComponent,
    MediaItemComponent,
    MediaNotesComponent,
    MediaNotesListComponent,
    NoteworthyEventsColumnComponent,
    NoteworthyEventsFilterComponent,
    NoteworthyFilterOptionsComponent,
    DurationFilterComponent,
    HubIdsFilterComponent,
    UploadMediaFileComponent,
    IncidentTypeFilterComponent,
  ],
  imports: [
    ReportCenterRoutingModule,
    StoreModule.forFeature('incident', incidentReducer),
    EffectsModule.forFeature([IncidentEffects]),
    CommonModule,
    TableModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    FormsModule,
    MultiSelectModule,
    DropdownModule,
    InputTextModule,
    SimplebarAngularModule,
    SliderModule,
    FileUploadModule,
    ExportTableExcelComponent,
    NgSelectModule,
  ],

  providers: [
    IncidentService,
    LoggerService,
    ExportExcelService,
    UploadFilesService,
    PressureUnitPipe,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class ReportCenterModule {}
