import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { EquipmentConfigurationService } from 'src/app/configuration/services/equipment-configuration.service';
import { ErrorReceiveProtectorTypeValues, ProtectorTypeActionTypes, ReceiveProtectorTypeValues } from '../../actions';

@Injectable()
export class ProtectorTypeEffects {
  equipmentConfigurationService: EquipmentConfigurationService = inject(EquipmentConfigurationService);

  requestProtectorTypeValues$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(ProtectorTypeActionTypes.REQUEST_PROTECTOR_TYPE_VALUES),
      switchMap(() =>
        this.equipmentConfigurationService.getProtectorTypeValues().pipe(
          map((types: any) => new ReceiveProtectorTypeValues(types)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveProtectorTypeValues(error))),
        ),
      ),
    );
  });
}
