import { Component, inject, Inject, OnInit } from '@angular/core';
import { Theme, ThemingService } from '@odx/angular/theming';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { UserStorageService } from 'src/app/common';

@Component({
  selector: 'ignis-wrong-system-date-error-page',
  templateUrl: './wrong-system-date-error-page.component.html',
  styleUrls: ['../unauthorized-page/unauthorized-page.component.scss'],
})
export class WrongSystemDateErrorPageComponent implements OnInit {
  themingService: ThemingService = inject(ThemingService);
  userStorageService: UserStorageService = inject(UserStorageService);

  constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth) {}

  ngOnInit(): void {
    this.themingService.selectTheme(Theme.DARK);
  }

  logout(): void {
    this.userStorageService.clearBrowserLocalStorageWithoutPersistentKeys();
    this.oktaAuth.clearStorage();
    this.oktaAuth.signOut();
  }
}
