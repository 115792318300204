import { GenericAction } from 'src/app/common';
import {
  errorItemData,
  errorListData,
  receiveItemData,
  receiveListData,
  requestItemData,
  requestListData,
} from 'src/app/common/utils/reducer/reducer.utils';
import { IOrganizationEntries, IOrganizationLicenseInfo } from '../../../models/organization-settings.model';
import { OrganizationSettingsActionTypes } from '../../actions/organization-settings';
import { IOrganizationEntriesState, OrganizationEntriesState } from '../../models/organization-entries.model';

const resetOrganizationLicenseInfo = (lastState: IOrganizationEntriesState): IOrganizationEntriesState => ({
  ...lastState,
  licenseInfo: {
    isLoading: false,
    data: null,
    errors: null,
  },
});

export function organizationEntriesReducer(
  lastState: IOrganizationEntriesState = new OrganizationEntriesState(),
  action: GenericAction<OrganizationSettingsActionTypes, any>,
): IOrganizationEntriesState {
  switch (action.type) {
    case OrganizationSettingsActionTypes.REQUEST_ORGANIZATION_SETTINGS_ENTRIES:
      return requestItemData<IOrganizationEntriesState, IOrganizationEntries>(lastState, ['entries']);
    case OrganizationSettingsActionTypes.RECEIVE_ORGANIZATION_SETTINGS_ENTRIES:
      return receiveItemData<IOrganizationEntriesState, IOrganizationEntries>(lastState, ['entries'], action.payload);
    case OrganizationSettingsActionTypes.ERROR_RECEIVE_ORGANIZATION_SETTINGS_ENTRIES:
      return errorItemData<IOrganizationEntriesState, IOrganizationEntries>(lastState, ['entries'], action.payload);

    case OrganizationSettingsActionTypes.REQUEST_ORGANIZATION_LICENSE_INFO:
      return requestListData<IOrganizationEntriesState, IOrganizationLicenseInfo[]>(lastState, ['licenseInfo']);
    case OrganizationSettingsActionTypes.RECEIVE_ORGANIZATION_LICENSE_INFO:
      return receiveListData<IOrganizationEntriesState, IOrganizationLicenseInfo[]>(
        lastState,
        ['licenseInfo'],
        action.payload,
      );
    case OrganizationSettingsActionTypes.ERROR_RECEIVE_ORGANIZATION_LICENSE_INFO:
      return errorListData<IOrganizationEntriesState, IOrganizationLicenseInfo[]>(
        lastState,
        ['licenseInfo'],
        action.payload,
      );

    case OrganizationSettingsActionTypes.RESET_ORGANIZATION_LICENSE_INFO:
      return resetOrganizationLicenseInfo(lastState);

    default:
      return lastState;
  }
}
