import { Component, inject, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Theme, ThemingService } from '@odx/angular/theming';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { captureMessage } from '@sentry/angular-ivy';
import { CommonConstants, UserStorageService } from 'src/app/common';
import { dateFormat } from 'src/app/common/utils/date-utils/date.utils';
import { AccessControlItemsService } from '../../services/access-control-items/access-control-items.service';
import { LoggerService } from './../../../common/services/logger-monitor/logger.service';

@Component({
  selector: 'ignis-generic-error-page',
  templateUrl: './generic-error-page.component.html',
  styleUrls: ['./generic-error-page.component.scss'],
})
export class GenericErrorPageComponent implements OnInit {
  errors: any;
  timestamp: any;
  user: any;
  jwtHelper: JwtHelperService = new JwtHelperService();
  displayContent: boolean = false;

  themingService: ThemingService = inject(ThemingService);
  loggerService: LoggerService = inject(LoggerService);
  jwtTokenService: AccessControlItemsService = inject(AccessControlItemsService);
  router: Router = inject(Router);
  userStorageService: UserStorageService = inject(UserStorageService);

  constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth) {}

  ngOnInit(): void {
    this.jwtTokenService.getAccessControlItems().subscribe({
      next: () => {
        this.displayContent = false;
        this.router.navigate(['']);
      },
      error: () => {
        this.displayContent = true;
        this.errors = JSON.parse(localStorage.getItem('AuthorizationError'));
        this.user = this.jwtHelper.decodeToken(localStorage.getItem(CommonConstants.AUTH_TOKEN));
        this.timestamp = this.errors.error.timestamp
          ? dateFormat(new Date(this.errors.error.timestamp).toString(), 'dd/MM/yyyy')
          : dateFormat(new Date().toString(), 'dd/MM/yyyy');

        const messageToLog: string = `Error code: ${this.errors.status}. Error message: ${this.errors.statusText}. Current timestamp: ${this.timestamp}. User email: ${this.user.email_address}. User fullname: ${this.user.name}.`;

        this.loggerService.logMessage(messageToLog, CommonConstants.logType.INFO);
        this.loggerService.logTrace(messageToLog, SeverityLevel.Information);

        captureMessage(messageToLog, 'info');
      },
    });

    this.themingService.selectTheme(Theme.DARK);
  }

  logout(): void {
    this.userStorageService.clearBrowserLocalStorageWithoutPersistentKeys();
    this.oktaAuth.signOut();
  }
}
