// eslint-disable-next-line no-shadow
export enum EquipmentInitialSpecificationsActionTypes {
  REQUEST_INITIAL_SPECIFICATION = 'REQUEST_INITIAL_SPECIFICATION',
  RECEIVE_INITIAL_SPECIFICATION = 'RECEIVE_INITIAL_SPECIFICATION',
  ERROR_INITIAL_SPECIFICATION = 'ERROR_INITIAL_SPECIFICATION',

  REQUEST_CHECKING_BARCODE_OR_RFID_IS_UNIQUE = 'REQUEST_CHECKING_BARCODE_OR_RFID_IS_UNIQUE',
  RECEIVE_CHECKING_BARCODE_OR_RFID_IS_UNIQUE = 'RECEIVE_CHECKING_BARCODE_OR_RFID_IS_UNIQUE',
  ERROR_RECEIVE_CHECKING_BARCODE_OR_RFID_IS_UNIQUE = 'ERROR_RECEIVE_CHECKING_BARCODE_OR_RFID_IS_UNIQUE',
  RESET_CHECKING_BARCODE_OR_RFID_IS_UNIQUE = 'RESET_CHECKING_BARCODE_OR_RFID_IS_UNIQUE',
}
