import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonConstants, CustomDigitDecimalNumberDirective, DropdownService } from 'src/app/common';
import { IEntryModel, PropertyBag } from 'src/app/common/models/common.model';
import { ReplaceDigitCharPipe } from 'src/app/common/pipes/replace-digit-char/replace-digit-char.pipe';
import { getDecimalNumberFormatUserProfile } from 'src/app/common/utils/settings-utils/settings-utils';
import {
  ChecklistParameterTypes,
  ConfigurationConstants,
} from 'src/app/configuration/constants/configuration.constants';
import { IChecklist } from 'src/app/configuration/models/checklist.model';

@Component({
  selector: 'ignis-checklist-form',
  templateUrl: './checklist-form.component.html',
  providers: [ReplaceDigitCharPipe],
  styleUrls: ['./checklist-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistFormComponent implements OnChanges, AfterViewInit {
  @Input() form: FormGroup;
  @Input() httpCustomErrorCode: number;
  @Input() checklistItems: Partial<FormArray>;

  @Output() onSubmit: EventEmitter<void> = new EventEmitter<void>();
  @Output() httpCustomErrorCodeChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() addParameterGroup: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(CustomDigitDecimalNumberDirective)
  directive: CustomDigitDecimalNumberDirective;

  selectedType: string;

  types: IEntryModel[] = ConfigurationConstants.checklistParameterTypes.types;
  checklistParameterTypes: any = ChecklistParameterTypes;
  modalTypes: PropertyBag = ConfigurationConstants.modalType;
  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;
  ParseFloat: unknown = parseFloat;

  formBuilder: FormBuilder = inject(FormBuilder);
  dropdownService: DropdownService = inject(DropdownService);
  replaceDigitCharPipe: ReplaceDigitCharPipe = inject(ReplaceDigitCharPipe);
  translateService: TranslateService = inject(TranslateService);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    public router: Router,
    public injector: Injector,
  ) {
    this.types.forEach((type: IEntryModel) => {
      type.localizedName = this.translateService.instant(type.localizedName);
    });
  }

  ngOnChanges(): void {
    return;
  }

  ngAfterViewInit(): void {
    if (this.router.url.includes(`${this.modalTypes.UPDATE}-checklist`)) {
      setTimeout(() => {
        this.processingAndPopulateChecklistForm();
        this.cdr.detectChanges();
      }, 0);
    }
  }

  changeType(event: PointerEvent | any, itemIndex: number): void {
    this.selectedType = event.value;
    const formGroup: FormGroup = this.items().controls[itemIndex] as FormGroup;

    if (!formGroup) return;

    formGroup?.removeControl('minimumValue');
    formGroup?.removeControl('maximumValue');
    formGroup?.removeControl('unit');
    formGroup?.removeControl('options');

    if (this.selectedType === this.checklistParameterTypes.NUMERIC_TYPE) {
      formGroup?.addControl('minimumValue', new FormControl(null));
      formGroup?.addControl('maximumValue', new FormControl(null));
      formGroup?.addControl('unit', new FormControl(null));
    }

    if (this.selectedType === this.checklistParameterTypes.MULTIPLE_SELECTION_TYPE) {
      formGroup.addControl('options', this.formBuilder.array([this.newOption(false)]));
    }

    this.dropdownService.changeModalOverflowToAccommodateTheDropdown(
      ConfigurationConstants.createUpdateChecklistModalId,
      ConfigurationConstants.createUpdateChecklistModalContentId,
    );
  }

  newOption(required: boolean): FormGroup<{
    name: FormControl<any>;
  }> {
    return this.formBuilder.group({
      name: new FormControl(null, required ? [Validators.required] : null),
    });
  }

  removeParameter(itemIndex: number): void {
    this.items().removeAt(itemIndex);
    this.form.markAsDirty();

    this.dropdownService.changeModalOverflowToAccommodateTheDropdown(
      ConfigurationConstants.createUpdateChecklistModalId,
      ConfigurationConstants.createUpdateChecklistModalContentId,
    );
  }

  addOption(itemIndex: number): void {
    this.options(itemIndex).push(this.newOption(true));

    this.dropdownService.changeModalOverflowToAccommodateTheDropdown(
      ConfigurationConstants.createUpdateChecklistModalId,
      ConfigurationConstants.createUpdateChecklistModalContentId,
    );
  }

  removeOption(itemIndex: number, optionIndex: number): void {
    this.options(itemIndex).removeAt(optionIndex);

    this.dropdownService.changeModalOverflowToAccommodateTheDropdown(
      ConfigurationConstants.createUpdateChecklistModalId,
      ConfigurationConstants.createUpdateChecklistModalContentId,
    );

    this.form.markAsDirty();
  }

  options(itemIndex: number): FormArray {
    return this.items().at(itemIndex)?.get('options') as FormArray;
  }

  items(): FormArray {
    return this.form.get('items') as FormArray;
  }

  processingAndPopulateChecklistForm(): void {
    const selectedChecklist: IChecklist = JSON.parse(localStorage.getItem(ConfigurationConstants.selectedChecklist));

    selectedChecklist.items.forEach((checklistItem: IChecklist | any, index: number) => {
      checklistItem.type = checklistItem.type.toLowerCase();

      if (checklistItem.type === this.checklistParameterTypes.NUMERIC_TYPE) {
        this.addParameterGroup.emit(ChecklistParameterTypes.NUMERIC_TYPE);

        const formGroup: FormGroup = this.items().controls[index] as FormGroup;

        formGroup.addControl(
          'minimumValue',
          new FormControl(
            this.replaceDigitCharPipe.transform(
              checklistItem.itemValue.minimum?.toString(),
              getDecimalNumberFormatUserProfile(this.translateService),
            ),
          ),
        );
        formGroup.addControl(
          'maximumValue',
          new FormControl(
            this.replaceDigitCharPipe.transform(
              checklistItem.itemValue.maximum?.toString(),
              getDecimalNumberFormatUserProfile(this.translateService),
            ),
          ),
        );
        formGroup.addControl('unit', new FormControl(checklistItem.itemValue.unit));
        this.cdr.detectChanges();

        return;
      }

      if (checklistItem.type === this.checklistParameterTypes.MULTIPLE_SELECTION_TYPE) {
        this.addParameterGroup.emit(ChecklistParameterTypes.MULTIPLE_SELECTION_TYPE);

        checklistItem.itemValue.options.forEach((optionValue: any) => {
          this.options(index).push(this.formBuilder.group(optionValue));
        });

        this.removeOption(index, 0);
        this.cdr.detectChanges();

        return;
      }

      this.items().push(this.formBuilder.group(checklistItem));
    });
    this.form.patchValue(selectedChecklist);
    this.form.markAsPristine();
  }

  checkInputValid(event: any): void {
    if (event.value?.name?.length) {
      this.httpCustomErrorCodeChange.emit(null);
      this.cdr.detectChanges();
    }
  }
}
