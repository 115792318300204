import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MaintenanceModeService } from './../../../services/maintenance-mode/maintenance-mode.service';
import { MaintenanceModeActionType } from './../actions/maintenance-mode-action-types';
import { ErrorReceiveMaintenanceDate, ReceiveMaintenanceDate } from './../actions/maintenance-mode-action-types-creator';
import { IMaintenanceDate } from './../models/maintenance-mode.model';

@Injectable()
export class MaintenanceModeEffects {
  constructor(private maintenanceModeService: MaintenanceModeService) { }

  requestMaintenanceDate$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(MaintenanceModeActionType.REQUEST_MAINTENANCE_DATE),
      switchMap(() =>
        this.maintenanceModeService.getMaintenanceDate().pipe(
          map((date: IMaintenanceDate) => new ReceiveMaintenanceDate(date)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveMaintenanceDate(error)))
        )
      )
    );
  });
}
