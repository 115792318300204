import { GenericAction } from 'src/app/common';
import { errorListData, receiveListData, requestListData } from 'src/app/common/utils/reducer/reducer.utils';
import { LocationTypeActionTypes } from '../../actions/location-type';
import { ILocationTypeValuesState, LocationTypeValuesState } from '../../models/location-type-values.model';

export function locationTypeValuesReducer(
  lastState: ILocationTypeValuesState = new LocationTypeValuesState(),
  action: GenericAction<LocationTypeActionTypes, any>,
): ILocationTypeValuesState {
  switch (action.type) {
    case LocationTypeActionTypes.REQUEST_LOCATION_TYPE_VALUES:
      return requestListData<ILocationTypeValuesState, any[]>(lastState, ['values']);
    case LocationTypeActionTypes.RECEIVE_LOCATION_TYPE_VALUES:
      return receiveListData<ILocationTypeValuesState, any[]>(lastState, ['values'], action.payload);
    case LocationTypeActionTypes.ERROR_RECEIVE_LOCATION_TYPE_VALUES:
      return errorListData<ILocationTypeValuesState, any[]>(lastState, ['values'], action.payload);

    default:
      return lastState;
  }
}
