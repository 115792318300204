import { IEntryModel } from 'src/app/common/models/common.model';

export interface IDevicePage {
  currentPage: number;
  entries: IDeviceConnection[];
  pageSize: number;
  totalPages: number;
  totalRecords: number;
}

export class DevicePage implements IDevicePage {
  currentPage: number;
  entries: IDeviceConnection[];
  pageSize: number;
  totalPages: number;
  totalRecords: number;

  constructor();
  constructor(devicePage: IDevicePage);
  constructor(devicePage?: IDevicePage) {
    if (!devicePage) {
      this.currentPage = null;
      this.entries = null;
      this.pageSize = null;
      this.totalPages = null;
      this.totalRecords = null;
    } else {
      this.currentPage = devicePage.currentPage;
      this.entries = devicePage.entries.map((device: IDeviceConnection) => new DeviceConnection(device));
      this.pageSize = devicePage.pageSize;
      this.totalPages = devicePage.totalPages;
      this.totalRecords = devicePage.totalRecords;
    }
  }
}

export interface IDeviceConnection {
  aggregateId: string;
  category: IEntryModel | string;
  identification: string;
  name: string;
  assignedFireStation: string;
  assignedVehicle: string;
  assignedPrimaryUser: string;
  description: string;
  authenticationToken?: string;
  firmwareVersion: string;
  registrationTimestamp?: string;
  role?: string;
  lastUploadTimestamp?: string;
  registrationTime?: string;
  lastUploadTime?: string;
  displayedRegistration?: string;
  displayedLastUpload?: string;
  migrationInfo?: {
    migrationId: string;
    status: string;
  };
  version?: number;
}

export class DeviceConnection implements IDeviceConnection {
  aggregateId: string;
  category: IEntryModel | string;
  identification: string;
  name: string;
  assignedFireStation: string;
  assignedVehicle: string;
  assignedPrimaryUser: string;
  description: string;
  authenticationToken?: string;
  firmwareVersion: string;
  registrationTimestamp?: string;
  role?: string;
  lastUploadTimestamp?: string;
  migrationInfo?: {
    migrationId: string;
    status: string;
  };
  version?: number;

  constructor();
  constructor(deviceConnection: IDeviceConnection);
  constructor(deviceConnection?: IDeviceConnection) {
    if (!deviceConnection) {
      this.aggregateId = null;
      this.category = null;
      this.identification = null;
      this.name = null;
      this.assignedFireStation = null;
      this.assignedVehicle = null;
      this.assignedPrimaryUser = null;
      this.description = null;
      this.firmwareVersion = null;
      this.registrationTimestamp = null;
      this.role = null;
      this.lastUploadTimestamp = null;
      this.migrationInfo = null;
      this.version = null;
    } else {
      this.aggregateId = deviceConnection.aggregateId;
      this.category = deviceConnection.category;
      this.identification = deviceConnection.identification;
      this.name = deviceConnection.name;
      this.assignedFireStation = deviceConnection.assignedFireStation;
      this.assignedVehicle = deviceConnection.assignedVehicle;
      this.assignedPrimaryUser = deviceConnection.assignedPrimaryUser;
      this.description = deviceConnection.description;
      this.authenticationToken = deviceConnection.authenticationToken;
      this.firmwareVersion = deviceConnection.firmwareVersion;
      this.role = deviceConnection.role;
      this.registrationTimestamp = deviceConnection.registrationTimestamp;
      this.lastUploadTimestamp = deviceConnection.lastUploadTimestamp;
      this.migrationInfo = deviceConnection.migrationInfo;
      this.version = deviceConnection.version;
    }
  }
}

export interface IDeviceConnectionToken {
  token: string;
}
export class DeviceConnectionToken implements IDeviceConnectionToken {
  token: string;

  constructor();
  constructor(deviceConnectionToken: IDeviceConnectionToken);
  constructor(deviceConnectionToken?: IDeviceConnectionToken) {
    if (!deviceConnectionToken) {
      this.token = null;
    } else {
      this.token = deviceConnectionToken.token;
    }
  }
}

export interface IAppQRCode {
  authenticationToken: string;
  version: number;
  apiBaseUrl: string;
  deviceName?: string;
  organizationName?: string;
}
export class AppQRCode implements IAppQRCode {
  authenticationToken: string;
  version: number;
  apiBaseUrl: string;

  constructor();
  constructor(qrCode: IAppQRCode);
  constructor(qrCode?: IAppQRCode) {
    if (!qrCode) {
      this.authenticationToken = null;
      this.version = null;
      this.apiBaseUrl = null;
    } else {
      this.authenticationToken = qrCode.authenticationToken;
      this.version = qrCode.version;
      this.apiBaseUrl = qrCode.apiBaseUrl;
    }
  }
}

export interface IDeviceConnectionLicenseInfo {
  noAllowedHubs: number;
  noRegisteredHubs: number;
}

export class DeviceConnectionLicenseInfo implements IDeviceConnectionLicenseInfo {
  noAllowedHubs: number;
  noRegisteredHubs: number;

  constructor();
  constructor(licenseInfo: IDeviceConnectionLicenseInfo);
  constructor(licenseInfo?: IDeviceConnectionLicenseInfo) {
    if (!licenseInfo) {
      this.noAllowedHubs = null;
      this.noRegisteredHubs = null;
    } else {
      this.noAllowedHubs = licenseInfo.noAllowedHubs;
      this.noRegisteredHubs = licenseInfo.noRegisteredHubs;
    }
  }
}

export interface IMigrationID {
  migrationId: string;
}
