import { GenericAction } from 'src/app/common';
import { IMediaAssets, INewNote } from '../../../models/incident.model';
import { IncidentActionTypes } from '../../actions/incident-action-types';
import { IIncidentNoteState, IncidentNoteState } from '../../models/incident-note.model';
import {
  errorItemData,
  errorPostPutItemData,
  receiveItemData,
  receivePostPutItemData,
  requestItemData,
  requestPostPutItemData,
} from '../../../../common/utils/reducer/reducer.utils';

export function incidentNoteReducer(
  lastState: IIncidentNoteState = new IncidentNoteState(),
  action: GenericAction<IncidentActionTypes, any>
): IIncidentNoteState {
  switch (action.type) {

    case IncidentActionTypes.REQUEST_INCIDENT_MEDIA_ASSETS:
      return requestItemData<IIncidentNoteState, IMediaAssets[]>(lastState, ['mediaAssets']);
    case IncidentActionTypes.RECEIVE_INCIDENT_MEDIA_ASSETS:
      return receiveItemData<IIncidentNoteState, IMediaAssets[]>(lastState, ['mediaAssets'], action.payload);
    case IncidentActionTypes.ERROR_RECEIVE_INCIDENT_MEDIA_ASSETS:
      return errorItemData<IIncidentNoteState, IMediaAssets[]>(lastState, ['mediaAssets'], action.payload);

    case IncidentActionTypes.REQUEST_ADD_MEDIA_NOTE_OR_MEDIA_FILE:
      return requestPostPutItemData<IIncidentNoteState, INewNote>(lastState, ['newNote'], action.payload);
    case IncidentActionTypes.RECEIVE_ADD_MEDIA_NOTE_OR_MEDIA_FILE:
      return receivePostPutItemData<IIncidentNoteState, INewNote>(lastState, ['newNote'], action.payload);
    case IncidentActionTypes.ERROR_RECEIVE_ADD_MEDIA_NOTE_OR_MEDIA_FILE:
      return errorPostPutItemData<IIncidentNoteState, INewNote>(lastState, ['newNote'], action.payload);

    default:
      return lastState;
  }
}
