import { Component, HostListener, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ModalRef, ModalService, ModalSize, ModalVariant } from '@odx/angular/components/modal';
import { OnDestroyMixin } from 'src/app/common/mixins';
import { CommonConstants } from './../../../common/constants/common.constants';

@Component({
  selector: 'ignis-export-in-progress',
  templateUrl: './export-in-progress.component.html',
  styleUrl: './export-in-progress.component.scss',
})
export class ExportInProgressComponent extends OnDestroyMixin() implements OnChanges {
  @Input() openModal: boolean;
  modalReference: ModalRef<unknown>;
  @ViewChild('exportingModal', { read: TemplateRef })
  public exportingModal: TemplateRef<unknown>;

  constructor(public modalService: ModalService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.openModal && this.openModal) {
      this.modalReference = this.modalService.open(this.exportingModal, {
        size: ModalSize.SMALL,
        variant: ModalVariant.DEFAULT,
        dismissable: false,
      });
    } else if (this.modalReference) {
      this.modalReference.close('');
      this.modalReference = null;
    }
  }

  @HostListener(CommonConstants.beforeUnloadWindowEvent, ['$event'])
  handleBeforeUnload($event: BeforeUnloadEvent): void {
    if (this.modalReference) {
      $event.preventDefault();
      $event.returnValue = true;
    }
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();

    if (this.modalReference) {
      this.modalReference.close('');
    }
  }
}
