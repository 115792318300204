import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { INote } from '../../../../models/remote-monitoring.model';
import { MapService } from './../../../../../common/services/map/map.service';

@Component({
  selector: 'ignis-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesListComponent {
  @Input() formatDate: string;
  @Input() currentAppTheme: string;
  @Input() notes: INote[];
  @Input() toggleWidget: boolean;
  @Output() handleNoteImageUrl: EventEmitter<string> = new EventEmitter();

  constructor(public mapService: MapService) {}

  getNoteImage(imageUrl: string): void {
    this.handleNoteImageUrl.emit(imageUrl);
  }

  trackByFn(_index: number, item: INote): string {
    return item.identifier;
  }
}
