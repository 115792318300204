import { IStoreApiItem, StoreApiItem } from 'src/app/common';
import { IRAMStatus, ITestStatus, IToken } from '../../models';

export interface IRAMConnectorState {
  checkRAMConnectorStatus: IStoreApiItem<ITestStatus>;
  ramConnectorToken: IStoreApiItem<IToken>;
  ramStatus: IStoreApiItem<IRAMStatus>;
  autoUpdate: IStoreApiItem<object>;
}

export class RAMConnectorState {
  checkRAMConnectorStatus: IStoreApiItem<ITestStatus> = new StoreApiItem<ITestStatus>();
  ramConnectorToken: IStoreApiItem<IToken> = new StoreApiItem<IToken>();
  ramStatus: IStoreApiItem<IRAMStatus> = new StoreApiItem<IRAMStatus>();
  autoUpdate: IStoreApiItem<object> = new StoreApiItem<object>();
}
