import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnChanges, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CheckingSettingsModalsService, GenericFooterItemsComponent, PropertyBag } from 'src/app/common';
import { AppUserPermissionList } from 'src/app/root/models';
import { AccessControlService } from 'src/app/root/services';
import { WorkshopConstants, WorkshopModuleRoutes } from 'src/app/workshop/constants';

@Component({
  selector: 'ignis-tasks-list-view-footer',
  templateUrl: './tasks-list-view-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksListViewFooterComponent<T> extends GenericFooterItemsComponent<T> implements OnChanges {
  @Input() numberOfRecordsToBeExported: number;
  @Input() isSubmitDisabled: boolean = false;
  @Input() activatePlayBtn: boolean = false;
  @Input() selectedTasksAreNotTheSame: boolean = false;

  @Output() handleExcelExport: EventEmitter<void> = new EventEmitter<void>();
  @Output() handleNavigateToEquipment: EventEmitter<void> = new EventEmitter<void>();
  @Output() handleExecuteChecklistOrTest: EventEmitter<string> = new EventEmitter<string>();

  isModalOpened: boolean;
  showExecuteBtnTooltip: boolean = false;
  showEditViewBtnTooltip: boolean = false;
  toggleStateOfExecuteBtn: boolean = false;
  batchChecklistFeatureToggle: boolean;

  appUserPermissionList: PropertyBag = AppUserPermissionList;
  readonly WorkshopModuleRoutes: PropertyBag = WorkshopModuleRoutes;

  public router: Router = inject(Router);
  public accessControlService: AccessControlService = inject(AccessControlService);
  private checkSettingsModal: CheckingSettingsModalsService = inject(CheckingSettingsModalsService);

  constructor() {
    super();

    this.checkSettingsModal.checkIfSettingsModalIsOpen().subscribe((isModalOpened: boolean) => {
      this.isModalOpened = isModalOpened;
      this.cdr.detectChanges();
    });

    this.batchChecklistFeatureToggle = this.accessControlService.equipmentBatchChecklistFeatureToggle;
  }

  ngOnChanges(): void {
    this.toggleStateOfExecuteBtn = false;

    if (
      this.selectedItem &&
      (((this.selectedItem.isProtectorTestType as boolean) &&
        this.accessControlService.equipmentTestingGlobalFeatureToggle) ||
        (this.selectedItem.hasCheckList as boolean))
    ) {
      this.toggleStateOfExecuteBtn = true;
    }
  }

  onExportExcelClick(e: Event): void {
    e.stopPropagation();
    this.handleExcelExport.emit();
  }

  onNavigateToEquipment(e: Event): void {
    e.stopPropagation();
    this.handleNavigateToEquipment.emit();
  }

  onExecuteClick(): void {
    if (this.selectedItem.hasCheckList as boolean) {
      this.handleExecuteChecklistOrTest.emit(WorkshopConstants.hasCheckList);
    } else if (
      (this.selectedItem.isProtectorTestType as boolean) &&
      this.accessControlService.equipmentTestingGlobalFeatureToggle
    ) {
      this.handleExecuteChecklistOrTest.emit(WorkshopConstants.isProtectorTestType);
    }
  }
}
