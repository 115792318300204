// eslint-disable-next-line no-shadow
export enum LogbookActionTypes {
  REQUEST_LOGBOOK_PAGE = 'REQUEST_LOGBOOK_PAGE',
  RECEIVE_LOGBOOK_PAGE = 'RECEIVE_LOGBOOK_PAGE',
  ERROR_RECEIVE_LOGBOOK_PAGE = 'ERROR_RECEIVE_LOGBOOK_PAGE',
  REQUEST_ENTRIES = 'REQUEST_ENTRIES',
  RECEIVE_ENTRIES = 'RECEIVE_ENTRIES',
  ERROR_RECEIVE_ENTRIES = 'ERROR_RECEIVE_ENTRIES',
  REQUEST_LOGBOOK_EXCEL_DATA = 'REQUEST_LOGBOOK_EXCEL_DATA',
  RECEIVE_LOGBOOK_EXCEL_DATA = 'RECEIVE_LOGBOOK_EXCEL_DATA',
  ERROR_RECEIVE_LOGBOOK_EXCEL_DATA = 'ERROR_RECEIVE_LOGBOOK_EXCEL_DATA',
}
