import { GenericAction } from 'src/app/common';
import { IEntryModel } from 'src/app/common/models/common.model';
import {
  errorItemData,
  errorPostPutItemData,
  receiveItemData,
  receivePostPutItemData,
  requestItemData,
  requestPostPutItemData,
} from '../../../../common/utils/reducer/reducer.utils';
import { IncidentActionTypes } from '../../actions/incident-action-types';
import { EventMarkerState, IEventMarkerState } from '../../models/incident-event-marker.model';
import { IEventMarker } from './../../../models/incident.model';

export function incidentEventMarkerReducer(
  lastState: IEventMarkerState = new EventMarkerState(),
  action: GenericAction<IncidentActionTypes, any>,
): IEventMarkerState {
  switch (action.type) {
    case IncidentActionTypes.REQUEST_EVENT_MARKERS_ENTRIES:
      return requestItemData<IEventMarkerState, IEntryModel[]>(lastState, ['eventMarkersEntries']);
    case IncidentActionTypes.RECEIVE_EVENT_MARKERS_ENTRIES:
      return receiveItemData<IEventMarkerState, IEntryModel[]>(lastState, ['eventMarkersEntries'], action.payload);
    case IncidentActionTypes.ERROR_RECEIVE_EVENT_MARKERS_ENTRIES:
      return errorItemData<IEventMarkerState, IEntryModel[]>(lastState, ['eventMarkersEntries'], action.payload);

    case IncidentActionTypes.REQUEST_ADD_EVENT_MARKER:
    case IncidentActionTypes.REQUEST_UPDATE_EVENT_MARKER:
      return requestPostPutItemData<IEventMarkerState, IEventMarker>(
        lastState,
        ['selectedEventMarker'],
        action.payload,
      );
    case IncidentActionTypes.RECEIVE_ADD_EVENT_MARKER:
    case IncidentActionTypes.RECEIVE_UPDATE_EVENT_MARKER:
      return receivePostPutItemData<IEventMarkerState, IEventMarker>(
        lastState,
        ['selectedEventMarker'],
        action.payload,
      );
    case IncidentActionTypes.ERROR_ADD_EVENT_MARKER:
    case IncidentActionTypes.ERROR_UPDATE_EVENT_MARKER:
      return errorPostPutItemData<IEventMarkerState, IEventMarker>(lastState, ['selectedEventMarker'], action.payload);

    case IncidentActionTypes.REQUEST_EVENT_MARKER_BY_ID:
      return requestItemData<IEventMarkerState, IEventMarker>(lastState, ['selectedEventMarker']);
    case IncidentActionTypes.RECEIVE_EVENT_MARKER_BY_ID:
      return receiveItemData<IEventMarkerState, IEventMarker>(lastState, ['selectedEventMarker'], action.payload);
    case IncidentActionTypes.ERROR_RECEIVE_EVENT_MARKER_BY_ID:
      return errorItemData<IEventMarkerState, IEventMarker>(lastState, ['selectedEventMarker'], action.payload);

    case IncidentActionTypes.REQUEST_EVENT_MARKER_DELETE:
      return requestPostPutItemData<IEventMarkerState, IEventMarker>(lastState, ['deletedEventMarker'], action.payload);
    case IncidentActionTypes.RECEIVE_EVENT_MARKER_DELETE:
      return receivePostPutItemData<IEventMarkerState, IEventMarker>(lastState, ['deletedEventMarker'], action.payload);
    case IncidentActionTypes.ERROR_RECEIVE_EVENT_MARKER_DELETE:
      return errorPostPutItemData<IEventMarkerState, IEventMarker>(lastState, ['deletedEventMarker'], action.payload);

    default:
      return lastState;
  }
}
