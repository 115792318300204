import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpHelper } from 'src/app/common';
import { environment } from 'src/environments/environment';
import { IMaintenanceDate } from '../../state/maintenance-mode/models/maintenance-mode.model';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceModeService {
  baseUrl: string = environment.MAINTENANCE_MODE.TENANTS;

  constructor(private httpClient: HttpClient, private httpHelper: HttpHelper) { }

  public getMaintenanceDate(): Observable<IMaintenanceDate | HttpErrorResponse> {
    return this.httpClient.get<IMaintenanceDate>(`${this.baseUrl}/maintenance/info/date`, this.httpHelper.options);
  }
}
