import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IEquipmentState } from '../../models';
import {
  RequestTestResultsById,
  RequestTestResultsExport,
  RequestTestResultsPage,
} from './equipment-test-results-action-types-creators';

@Injectable({ providedIn: 'root' })
export class EquipmentTestResultsActions {
  constructor(private store: Store<IEquipmentState>) {}

  public requestTestResultsPage(params?: any, isExport: boolean = false): void {
    this.store.dispatch(isExport ? new RequestTestResultsExport(params) : new RequestTestResultsPage(params));
  }

  public requestTestResultsById(aggregateId: string): void {
    this.store.dispatch(new RequestTestResultsById(aggregateId));
  }
}
