import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { isBefore, isSameDay, parse, startOfToday } from 'date-fns';
import { ICustomColumn } from 'src/app/common/models';

@Component({
  selector: 'ignis-next-service-column',
  templateUrl: './next-service-column.component.html',
  styleUrls: ['./next-service-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NextServiceColumnComponent implements OnInit {
  @Input() formatDate: string;
  @Input() nextServiceDate: ICustomColumn;

  isServiceOverdue: boolean = false;

  ngOnInit(): void {
    this.isServiceOverdue = this.checkIfDateIsOlderOrEqual();
  }

  checkIfDateIsOlderOrEqual(): boolean {
    if (!this.nextServiceDate?.data) return false;

    const targetDate: Date = parse(this.nextServiceDate?.data, this.formatDate, new Date());

    return isSameDay(targetDate, startOfToday()) || isBefore(targetDate, startOfToday());
  }
}
