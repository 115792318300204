import { CommonConstants } from 'src/app/common/constants/common.constants';

export interface INotificationMessage {
  value: string;
  text: string;
}
export interface INotificationState {
  type: NotificationTypes;
  message: INotificationMessage;
  value?: object;
  description?: string;
}

export class NotificationState implements INotificationState {
  type: NotificationTypes = null;
  message: INotificationMessage = { value: null, text: null };
  value?: object = null;
  description?: string = null;
}

const { SUCCESS, ERROR, WARNING, MAINTENANCE, HIDDEN } = CommonConstants.notificationType;

export type NotificationTypes = typeof SUCCESS | typeof ERROR | typeof WARNING | typeof MAINTENANCE | typeof HIDDEN;
