import { GenericAction } from 'src/app/common';
import { errorItemData, receiveItemData, requestItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { ExportTableActionTypes } from '../actions/export-action-types';
import { TableExportState } from '../models/export.model';
import { ITableExportState } from './../models/export.model';

const resetTableExportDataState = (lastState: ITableExportState): ITableExportState => ({
  ...lastState,
  tableExportData: {
    isLoading: false,
    isSuccess: false,
    data: null,
    errors: null,
  },
});

export function tableExportReducer(
  lastState: ITableExportState = new TableExportState(),
  action: GenericAction<ExportTableActionTypes, any>,
): ITableExportState {
  switch (action.type) {
    case ExportTableActionTypes.REQUEST_TABLE_DATA:
      return requestItemData<ITableExportState, ITableExportState>(lastState, ['tableExportData']);
    case ExportTableActionTypes.RECEIVE_TABLE_DATA:
      return receiveItemData<ITableExportState, ITableExportState>(lastState, ['tableExportData'], action.payload);
    case ExportTableActionTypes.ERROR_RECEIVE_TABLE_DATA:
      return errorItemData<ITableExportState, ITableExportState>(lastState, ['tableExportData'], action.payload);

    case ExportTableActionTypes.REQUEST_EXPORT_IN_PROGRESS:
      return requestItemData<ITableExportState, ITableExportState>(lastState, ['exportInProgress']);
    case ExportTableActionTypes.RECEIVE_EXPORT_IN_PROGRESS:
      return receiveItemData<ITableExportState, ITableExportState>(lastState, ['exportInProgress'], action.payload);
    case ExportTableActionTypes.ERROR_RECEIVE_EXPORT_IN_PROGRESS:
      return errorItemData<ITableExportState, ITableExportState>(lastState, ['exportInProgress'], action.payload);

    case ExportTableActionTypes.RESET_TABLE_DATA:
      return resetTableExportDataState(lastState);

    default:
      return lastState;
  }
}
