import { PropertyBag } from 'src/app/common';

export const tableCustomFiltersLayout: string[] = ['name', 'surname', 'emailAddress', 'userRole.value'];

export const readModelSortKeys: PropertyBag = {
  name: 'userDetails.name',
  surname: 'userDetails.surname',
  emailAddress: 'userDetails.email',
  userId: 'userDetails.oid',
  'userRole.value': 'userDetails.role.value',
  displayLastLogin: 'lastLogin',
};

export const tableDefaultSorting: string = 'userDetails.surname,ASC';
