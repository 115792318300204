import { Injectable } from '@angular/core';
import { NotificationActions } from '../../state/notifications/actions/notification.actions';
import { INotificationMessage, NotificationTypes } from '../../state/notifications/models/notification.model';
import { NotificationConstants } from './../../constants/notification.constants';
import { INotificationConstant } from './../../models/notifications.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private notificationsActions: NotificationActions) {}

  public requestShowNotification(
    type: NotificationTypes,
    message: INotificationMessage | string | number,
    notificationModule: INotificationConstant,
    value?: object,
    description?: string,
  ): void {
    this.notificationsActions.requestShowNotification(
      type,
      typeof message === 'object' ? message : this.findNotificationMessageFromCode(message, notificationModule),
      value ?? undefined,
      description ?? undefined,
    );
  }

  private findNotificationMessageFromCode(
    message: string | number | INotificationMessage,
    notificationModule: INotificationConstant,
  ): INotificationMessage {
    const combinedNotifications: INotificationConstant = {
      ...NotificationConstants.commonCodes,
      ...notificationModule,
    };

    let toBeDisplayed: INotificationMessage = NotificationConstants.globalCodes.GENERIC_SERVER_ERROR;

    Object.keys(combinedNotifications).some((key: string) => {
      if (combinedNotifications[key].value === message.toString()) {
        toBeDisplayed = combinedNotifications[key];

        return true;
      }
    });

    return toBeDisplayed;
  }
}
