import { environment } from 'src/environments/environment';
import { IScrollOptions, PropertyBag } from '../models';
import { INotificationType } from '../models/notifications.model';

export class CommonConstants {
  public static readonly notificationType: INotificationType = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    MAINTENANCE: 'maintenance',
    HIDDEN: 'hidden',
  };

  public static readonly logType: INotificationType = {
    DEBUG: 'debug',
    WARNING: 'warning',
    ERROR: 'error',
    INFO: 'info',
  };

  public static readonly modalType: PropertyBag = {
    CREATE: 'create',
    UPDATE: 'update',
  };

  public static readonly pressureDisplayUnit: PropertyBag = {
    bar: 'bar',
    psi: 'psi',
    mpa: 'mpa',
  };

  public static readonly supportedLanguages: PropertyBag = {
    de: 'de-DE',
    da: 'da-DK',
    es: 'es-ES',
    fr: 'fr-FR',
    nl: 'nl-NL',
    nb: 'nb-NO',
    'en-GB': 'en-GB',
    'de-DE': 'de-DE',
    'da-DK': 'da-DK',
    'es-ES': 'es-ES',
    'fr-FR': 'fr-FR',
    'nl-NL': 'nl-NL',
    'nb-NO': 'nb-NO',
  };

  public static readonly productNames: PropertyBag = {
    RAM: 'RAM',
    FIREGROUND_WEB: 'FireGround Web',
    SAFETY_CLOUD: 'Safety Cloud',
    API_KEY: 'API Key',
  };

  public static readonly productLabels: PropertyBag = {
    RAM: 'LICENSES.STR_RAM_LICENSE',
    FIREGROUND_WEB: 'LICENSES.STR_FIREGROUND_LICENSE',
    SAFETY_CLOUD: 'LICENSES.STR_SAFETY_CLOUD_LICENSE',
    API_KEY: 'LICENSES.STR_API_KEY',
  };

  public static readonly scrollbarOptions: IScrollOptions = {
    autoHide: false,
  };

  public static readonly dateFNSIntervalPattern: { [key: string]: string } = {
    DAY: 'DAY',
    WEEK: 'WEEK',
    MONTH: 'MONTH',
    YEAR: 'YEAR',
  };

  public static readonly modalsName: PropertyBag = {
    SETTINGS: 'settings',
    LICENSE: 'license',
    EQUIPMENT_HISTORY_FILTER: 'equipment history filter',
    ORGANIZATION_SETTINGS: 'organization settings',
  };

  public static readonly supportedImagesRegex: { [name: string]: RegExp } = {
    'image/png': /^89504e47/,
    'image/jpeg': /^ffd8ff/,
  };

  public static readonly oktaErrors: PropertyBag = {
    PKCE_CODE_VERIFIER_ERROR: 'PKCE codeVerifier',
    JWT_TIME_IN_FUTURE: 'JWT was issued in the future',
    VERIFICATION_CODE_EXPIRED: 'The authorization code is invalid or has expired.',
  };

  public static readonly excludeTenantIdEndpoints: string[] = [
    'access-control',
    'documents/gdpr',
    'documents/tac',
    '/documents/gdpr/accepted',
    '/documents/tac/accepted',
  ];

  public static readonly excelSheetNameLimit: number = 31;
  public static readonly excelForbiddenCharactersRegExp: RegExp = /['\\\/?*:|[\]]|^'|'$/g;
  public static readonly excelCharacterEscaper: string = '_';
  public static readonly excelType: string =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  // eslint-disable-next-line
  public static readonly emailRegex: RegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public static readonly emptyStringRegEx: RegExp = /^$|^\S+.*$/;
  public static readonly deviceConnectionIdentifierRegEx: RegExp = /^[a-zA-Z0-9,.:\-/\\+*#(){}[\]]+$/;
  public static readonly splitDateRangeRegex: RegExp = /\s* - \s*/;
  public static readonly notificationDuration: number = 4000;
  public static readonly DEFAULT_LOCALE: string = 'en-GB';
  public static readonly DATE_TIME_FORMAT: string = "yyyy-MM-dd'T'HH:mm:ss";
  public static readonly DATE_FORMAT: string = 'yyyy-MM-dd';
  public static readonly DATE_FORMAT_PRINT_NAME: string = 'yyyyMMdd';
  public static readonly TIME_FORMAT: string = 'HH:mm';
  public static readonly TIME_FORMAT_PRINT_NAME: string = 'HHmm';
  public static readonly TIME_WITH_SECONDS_FORMAT: string = 'HH:mm:ss';
  public static readonly DEFAULT_INPUT_DEBOUNCE_TIME: number = 600;
  public static readonly DEFAULT_REDIRECT_TIMEOUT: number = 3000;
  public static readonly PSI_CONVERSION_VALUE: number = 0.0689475728;
  public static readonly MPA_CONVERSION_VALUE: number = 10;
  public static readonly AUTH_TOKEN: string = 'AUTH_TOKEN';
  public static readonly ACCESS_TOKEN: string = 'ACCESS_TOKEN';
  public static readonly initial_light_theme: string = 'light';
  public static readonly initial_dark_theme: string = 'dark';
  public static readonly odx_light_theme: string = 'odx-theme-light';
  public static readonly odx_dark_theme: string = 'odx-theme-dark';
  public static readonly routeToNavigateAfterLogin: string = 'routeToNavigateAfterLogin';
  public static readonly routeWithoutAuthentication: string = 'routeWithoutAuthentication';
  public static readonly modalIsOpened: string = 'modalIsOpened';
  public static readonly appIsInMaintenanceMode: string = 'appIsInMaintenanceMode';
  public static readonly maintenanceDate: string = 'maintenanceDate';
  public static readonly rootUrl: string = 'rootUrl';
  public static readonly tenantId: string = 'tenantId';
  public static readonly sasReplaceKey: string = 'FILE_NAME';
  public static readonly updateWorkflowTableDataAfterChecklist: string = 'updateWorkflowFooterItemsAfterChecklist';
  public static readonly updateWorkflowFooterItemsAfterChecklist: string = 'updateWorkflowFooterItemsAfterChecklist';
  public static readonly markLoginFromOktaPage: string = 'markLoginFromOktaPage';
  public static readonly markEquipmentToBeRemovedAfterChecklist: string = 'markEquipmentToBeRemovedAfterChecklist';
  public static readonly beforeUnloadWindowEvent: string = 'window:beforeunload';
  public static readonly afterPrintWindowEvent: string = 'window:afterprint';
  public static readonly popStateWindowEvent: string = 'window:popstate';
  public static readonly mapOptions: string = 'mapOptions';
  public static readonly notificationClosed: string = 'notificationClosed';
  public static readonly userIsUnauthorized: string = 'userIsUnauthorized';
  public static readonly exportCheck: string = '✓';
  public static readonly exportDash: string = '-';
  public static readonly exportNewLine: string[] = [' '];
  public static readonly maintenanceWithoutMessage: string = '-';
  public static readonly pressureConversionMap: { [name: string]: number } = {
    bar: 1,
    psi: this.PSI_CONVERSION_VALUE,
    mpa: this.MPA_CONVERSION_VALUE,
  };
  public static readonly refreshAppAfterTicketSaved: string = 'refreshAppAfterTicketSaved';
  public static readonly splitArrayOfStringsRule: string = ', ';
  public static readonly numericWithOptionalDecimal: RegExp = /^-?\d{0,24}(\.\d{0,3})?$/;
  public static readonly numericWithOptionalCommaDecimal: RegExp = /^-?\d{0,24}(\,\d{0,3})?$/;
  public static readonly ramConnectorDownloadURL: string = 'https://sc.draeger.com/public/ram-connector-setup.exe';
  public static readonly defaultDropdownIconCSSClass: string = 'arrow-scroll-down custom-dropdown-icon';
  public static readonly openDropdownIconCSSClass: string = 'arrow-scroll-up custom-dropdown-icon';
  public static readonly tableDropdownIconCSSClass: string = `${CommonConstants.defaultDropdownIconCSSClass} table-dropdown-icon-color`;
  public static readonly allowedAudioTypes: string[] = ['mp3', 'wav', 'ogg', 'aac'];
  public static readonly allowedImagesFormat: string[] = ['jpeg', 'jpg', 'png'];
  public static readonly regexToExtractFileWithExtension: RegExp = /\/([^\/]+\.(jpg|jpeg|png|mp3|wav|ogg|aac))/;
  public static readonly maxSizeOfUploadedImages: number = 20971520; // 20MB;
  public static readonly availableRAMConnectorPort: string = 'availableRAMConnectorPort';
  public static readonly acceptedTerms: string = 'acceptedTerms';
  public static readonly defaultTableRowsNumber: number = 25;
  public static readonly RAMConnectorIsClosed: string = 'RAMConnectorIsClosed';
  public static readonly equipmentDataAfterScanLocation: string = 'equipmentDataAfterScanLocation';
  public static readonly isHeadless: boolean = /HeadlessChrome/.test(window.navigator.userAgent);
  public static readonly darkMapID: string = environment.GOOGLE_MAP.DARK_MAP_ID;
  public static readonly lightMapID: string = environment.GOOGLE_MAP.LIGHT_MAP_ID;
  public static readonly matchHTMLTags: RegExp = /<([a-z][a-z0-9]*)(\s+[^>]+)?\s*(\/?)>|<>/i;
}
