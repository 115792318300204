import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { CommonConstants } from 'src/app/common';

@Component({
  selector: 'ignis-hub-ids-filter',
  templateUrl: './hub-ids-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HubIdsFilterComponent implements OnChanges {
  copyOfHubIdsList: any[];
  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;

  @Input() hubIdsList: any[] = [];
  @Input() filterService: any;
  @Input() selectedHubIds: any;

  @Output() handleSaveFilters: EventEmitter<any> = new EventEmitter();

  ngOnChanges(): void {
    this.copyOfHubIdsList = this.hubIdsList;
  }

  saveFilters(field: string, value: any): void {
    this.handleSaveFilters.emit({ name: field, data: value });
  }

  removeSelectedHubIds(): void {
    this.selectedHubIds = [];
    this.hubIdsList = this.copyOfHubIdsList;
  }
}
