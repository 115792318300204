<div class="row justify-content-between custom-row">
  <div class="col-md-12 col-sm-12 col-xs-12 col-incident-statistic padding-left-20-statistics" *ngIf="selectedEcp">
    <div class="incident-statistic" data-test-id="remote_monitoring.teams_no">
      <div class="no">{{ getTeamsNo(selectedEcp?.teamsNo) || '00' }}</div>
      <div class="desc">
        {{ 'REMOTE_MONITORING.STR_TEAMS' | translate }}
      </div>
    </div>

    <div class="incident-statistic mr-64" data-test-id="remote_monitoring.firemen_in_teams_no">
      <div class="no">
        {{ getTeamsNo(selectedEcp?.firemenNo) || '00' }}
      </div>
      <div class="desc">{{ 'REMOTE_MONITORING.STR_FIREMEN_IN_TEAMS' | translate }}</div>
    </div>

    <div
      class="incident-statistic hardware-statistic"
      *ngIf="
        selectedEcp?.hardwareDetails.hubBattery?.overallBattery &&
        selectedEcp?.hardwareDetails.hubBattery?.overallBattery < 25
      "
    >
      <odx-icon name="fg-hub-battery-low" size="inline"></odx-icon>
      <div data-test-id="remote_monitoring.hub_name">
        <div class="desc">
          {{ selectedEcp?.hardwareDetails.hubBattery?.hubName }}
        </div>

        <div
          class="desc"
          *ngIf="!selectedEcp?.hardwareDetails.hubBattery?.hubBatteryLow; else lowBatteryAlarm"
          data-test-id="remote_monitoring.battery_level"
        >
          {{
            'REMOTE_MONITORING.STR_BATTERY_LEVEL'
              | translate: { displayedValue: selectedEcp?.hardwareDetails.hubBattery?.overallBattery }
          }}
        </div>

        <ng-template #lowBatteryAlarm>
          <div class="desc">
            {{ 'REMOTE_MONITORING.STR_HUB_HAS_LOW_BATTERY_ALARM' | translate }}
          </div>
        </ng-template>
      </div>
    </div>

    <div
      class="incident-statistic hardware-statistic"
      *ngIf="selectedEcp?.hardwareDetails.repeaters.length > 0"
      data-test-id="remote_monitoring.repeaters_no"
    >
      <odx-icon name="fg-repeater" size="inline"></odx-icon>
      <div class="desc">
        {{
          'REMOTE_MONITORING.STR_REPEATER_DEPLOYED'
            | translate: { displayedValue: selectedEcp?.hardwareDetails.repeaters.length }
        }}
      </div>
    </div>

    <div
      class="incident-statistic hardware-statistic"
      *ngIf="repeatersWithLowBattery.length > 0"
      data-test-id="remote_monitoring.repeaters_with_low_battery"
    >
      <odx-icon name="fg-repeater-battery-low" size="inline"></odx-icon>
      <div>
        <div class="desc">{{ 'REMOTE_MONITORING.STR_LOW_BATTERY_ALARM' | translate }}</div>
        <div class="desc">
          {{ 'REMOTE_MONITORING.STR_REPEATERS_AFFECTED' | translate: { displayedValue: repeatersWithLowBattery } }}
        </div>
      </div>
    </div>

    <div
      *ngIf="
        selectedEcp?.hardwareDetails.hubBattery?.overallBattery &&
          selectedEcp?.hardwareDetails.hubBattery?.overallBattery < 25 &&
          repeatersWithLowBattery.length > 0 &&
          selectedEcp?.hardwareDetails.repeaters.length > 0;
        else defaultRightStatistic
      "
      class="incident-statistic move-to-right"
      [ngClass]="{ 'reset-ml': alignToLeft }"
      data-test-id="remote_monitoring.standby_firemen_no"
      #ecpRigthStatisticBlock
    >
      <div class="no">{{ getTeamsNo(selectedEcp?.standbyFiremenNo) || '00' }}</div>
      <div class="desc">{{ 'REMOTE_MONITORING.STR_BA_WEARERS_ON_STANDBY' | translate }}</div>
    </div>

    <ng-template #defaultRightStatistic>
      <div class="incident-statistic right-align" data-test-id="remote_monitoring.standby_firemen_no">
        <div class="no">{{ getTeamsNo(selectedEcp?.standbyFiremenNo) || '00' }}</div>
        <div class="desc">{{ 'REMOTE_MONITORING.STR_BA_WEARERS_ON_STANDBY' | translate }}</div>
      </div>
    </ng-template>
  </div>
</div>
