import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ignis-multiple-items-selected',
  templateUrl: './multiple-items-selected.component.html',
  styleUrl: './multiple-items-selected.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultipleItemsSelectedComponent {
  @Input() value;
}
