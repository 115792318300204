import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CommonConstants, IApplicationState, PropertyBag } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins';
import { CallbackPipe } from 'src/app/common/pipes/callback-filter/callback-filter.pipe';
import { ImageReaderService } from 'src/app/common/services/image-reader/image-reader.service';
import { ChecklistParameterTypes } from 'src/app/configuration/constants';
import { IChecklistItem, IChecklistOption } from 'src/app/configuration/models';
import { TaskResultTypeEnum, WorkshopConstants } from 'src/app/workshop/constants';
import { IPrintImageSource, IPrintServiceResultValue, ITaskData, ITaskInterval } from 'src/app/workshop/models';
import { convertToDateTimePrintFormat } from '../../../../../common/utils';

@Component({
  selector: 'ignis-results-print',
  templateUrl: './results-print.component.html',
  styleUrls: ['./results-print.component.scss'],
  providers: [CallbackPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultsPrintComponent extends OnDestroyMixin() implements AfterViewInit {
  @Input() form: FormGroup;
  @Input() selectedTaskName: string;
  @Input() dateTime: string;
  @Input() formatDate: string;
  @Input() taskData: ITaskData;
  @Input() resultType: TaskResultTypeEnum;
  @Input() resultData: IPrintServiceResultValue;
  @Input() isFromMigration: boolean;

  @Output() isLoadingDone: EventEmitter<void> = new EventEmitter<void>();
  @Output() handlePreviousPageTitle: EventEmitter<string> = new EventEmitter<string>();

  imageSources: IPrintImageSource = {};
  otherTasks: ITaskInterval[];
  isOtherTasksDisplayed: boolean = false;
  mark: string = CommonConstants.exportCheck;

  assessmentsTranslation: PropertyBag = WorkshopConstants.testAssessmentsTranslation;
  resultTypes: typeof TaskResultTypeEnum = TaskResultTypeEnum;
  checklistParameterTypes: typeof ChecklistParameterTypes = ChecklistParameterTypes;
  operationalTranslations: PropertyBag = WorkshopConstants.operationalStatusTranslationKey;

  store: Store<IApplicationState> = inject<Store<IApplicationState>>(Store);
  imageService: ImageReaderService = inject(ImageReaderService);
  title: Title = inject(Title);
  translateService: TranslateService = inject(TranslateService);

  constructor() {
    super();
  }

  ngAfterViewInit(): void {
    this.otherTasks = this.taskData?.others?.filter((task: ITaskInterval) => task.wasTestIncluded);
    this.isOtherTasksDisplayed = Boolean(this.otherTasks?.length);

    setTimeout(() => {
      this.setTitleNameForPrintWorkaround();
    }, 500);

    if (this.resultType !== this.resultTypes.Checklist) {
      this.isLoadingDone.emit();

      return;
    }

    const imageItems: IChecklistItem[] = this.resultData.checklist.items.filter(
      (item: IChecklistItem) => item.type === this.checklistParameterTypes.IMAGE_TYPE.toString(),
    );

    const imageNames: IPrintImageSource = {};

    imageItems.forEach((item: IChecklistItem) => {
      imageNames[item.id] = item.itemValue.mediaLinks;
    });

    this.imageService.fetchImages(imageNames).subscribe({
      next: (imageSources: IPrintImageSource) => {
        this.imageSources = imageSources;
      },
      complete: () => {
        this.isLoadingDone.emit();
      },
    });
  }

  onlyChecked = (test: IChecklistOption): boolean => test.checked;

  setTitleNameForPrintWorkaround(): void {
    const taskDateTime: string = this.form.get('dateTime').value as string;
    const identifier: string = this.form.get('identification').value as string;
    const taskTranslated: string = this.translateService.instant(
      'EQUIPMENT_TASK_RESULTS.STR_TASK_RESULTS_FILE_NAME',
    ) as string;
    const dateWithTime: string = convertToDateTimePrintFormat(taskDateTime);

    this.handlePreviousPageTitle.emit(this.title.getTitle());
    this.title.setTitle(`${taskTranslated}_${identifier}_${dateWithTime}`);
  }
}
