import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { CommonConstants } from 'src/app/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ignis-image-checker',
  templateUrl: './image-checker.component.html',
  styleUrls: ['./image-checker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageCheckerComponent implements OnChanges {
  @Input() mediaName: string;
  @Output() handleImageOpened: EventEmitter<SafeUrl> = new EventEmitter<SafeUrl>();

  imageSrc: ArrayBuffer | string;

  constructor(
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(): void {
    if (this.mediaName) {
      this.loadChecklistImageWithHeaders();
    }
  }

  openImage(): void {
    this.handleImageOpened.emit(this.imageSrc);
  }

  loadChecklistImageWithHeaders(): void {
    const baseUrl: string = environment.API_URLS.DEVICE_CONNECTION;

    const headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem(CommonConstants.ACCESS_TOKEN)}`,
    });

    this.http
      .get(`${baseUrl}/files/${this.mediaName}`, { headers: headers, responseType: 'blob' })
      .subscribe((response: Blob) => {
        const reader: FileReader = new FileReader();

        reader.onloadend = () => {
          this.imageSrc = reader.result;
          this.cdr.detectChanges();
        };

        reader.readAsDataURL(response);
      });
  }
}
