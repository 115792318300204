import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, Subject, take, takeUntil } from 'rxjs';
import {
  AddressBookTableFiltersConstants,
  CommonConstants,
  IApplicationState,
  IStoreApiItem,
  IStoreApiList,
  StorageConstants,
  TableHelperComponent,
} from 'src/app/common';
import { ITableColumn, ITableSettings } from 'src/app/common/models/table.model';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { ConfigurationConstants } from 'src/app/configuration/constants/configuration.constants';
import { AppModulesTypes } from 'src/app/root/models/app-types';
import { ConfigurationNotificationConstants } from '../../constants';
import { AddressBookActions } from '../../state/actions';
import { selectAddressBookPage, selectDeleteAddressBook } from '../../state/selectors/configuration.selectors';
import { IAddressBook, IAddressBookPage } from './../../models/address-book.model';
import { tableColumns } from './table-columns';
import { tableCustomFiltersLayout, tableDefaultSorting } from './table-settings';

@Component({
  selector: 'ignis-address-book-list',
  templateUrl: './address-book-list.component.html',
  styleUrls: ['./address-book-list.component.scss'],
})
export class AddressBookListComponent extends TableHelperComponent implements OnInit {
  translateService: TranslateService = inject(TranslateService);
  notificationsService: NotificationsService = inject(NotificationsService);
  defaultSorting: string = tableDefaultSorting;
  tableFiltersKey: string = StorageConstants.tablesStorageKeys.ADDRESS_BOOK;

  addressBookList: IAddressBook[];
  selectedAddressBook: IAddressBook;
  openConfirmationDeleteDialog: boolean = false;
  activateClickOutside: boolean = true;
  addressTypeList: any;
  typeModel: any;

  httpCustomErrorCode: string | any;

  localTableColumns: ITableColumn[] | any = tableColumns;
  typesList: any[] = ConfigurationConstants.addressBookTypes.types;
  tableName: string = AppModulesTypes.addressBook;

  localDestroy$: Subject<boolean> = new Subject<boolean>();

  customFilterLayouts: string[] = tableCustomFiltersLayout;

  router: Router = inject(Router);
  store: Store<IApplicationState> = inject(Store<IApplicationState>);
  addressBookActions: AddressBookActions = inject(AddressBookActions);

  constructor() {
    super();

    this.tableHelperReadSavedFiltersValues(AddressBookTableFiltersConstants.selectedDropdownsFilterValues);
  }

  ngOnInit(): void {
    this.isLoading$ = this.store.pipe(
      select(selectAddressBookPage),
      takeUntil(this.destroy),
      map((state: IStoreApiList<IAddressBookPage>) => state.isLoading),
    );

    this.store
      .pipe(
        select(selectAddressBookPage),
        map((state: IStoreApiList<IAddressBookPage>) => state.data),
        takeUntil(this.localDestroy$),
      )
      .subscribe((response: IAddressBookPage) => {
        if (response) {
          this.processAddressBookPageData(response);
        }
      });

    this.addressTypeList = this.typesList.map((type: any) => ({
      ...type,
      label: this.translateService.instant(
        ConfigurationConstants.addressBookTypes.types.find((t: any) => t.value === type.value)?.localizedName ||
          type.value,
      ),
    }));
  }

  processAddressBookPageData(response: IAddressBookPage): void {
    const addressBookPage: IAddressBookPage = structuredClone(response);

    addressBookPage.entries.forEach((addressBook: IAddressBook) => {
      if (addressBook.types[0] !== 'NONE') {
        addressBook.types = addressBook.types
          .sort()
          .map((type: string) => {
            return this.translateService.instant(
              ConfigurationConstants.addressBookTypes.types.find(
                (storedType: { value: string; label: string; localizedName: string }) => storedType.value === type,
              )?.localizedName || type,
            );
          })
          .join(CommonConstants.splitArrayOfStringsRule);
      } else {
        addressBook.types = [];
      }
    });

    this.totalRecords = addressBookPage.totalRecords;
    this.totalPages = addressBookPage.totalPages;

    this.tableHelperCheckOutOfRangePageFilter();

    this.addressBookList = addressBookPage.entries;
  }

  createNewAddressBook(): void {
    this.router.navigate(['configuration', 'address', 'create']);
  }

  fetchTableData(params: any): void {
    this.addressBookActions.requestAddressBook(params);
  }

  changeColumnVisibility(columns: ITableColumn[]): void {
    this.tableHelperResetTablePaging();

    columns.forEach((column: ITableColumn) => {
      if (!column.visible) {
        if (this.tableHelperCheckForSorting(column.field)) {
          this.tableHelperResetTableSorting();
        }

        this.tableHelperHideAndResetTableColumn(this.localTableColumns, column);
      }
    });

    this.tableHelperSaveTableSettings(columns);
    this.tableHelperSetAllTableFilters(this.filtersData);

    this.tableHelperPrepareTableParameters();
  }

  processingTableSettings(settings: ITableSettings): void {
    this.rows = settings.pageSize;
    this.tableColumns = settings.columns;

    this.tableHelperPrepareTableParameters();
  }

  getFilterTableValue(event: any): void {
    Object.assign(this.filtersData, event);

    this.tableHelperSetAllTableFilters(this.filtersData);
  }

  onAddressBookSelect(address: IAddressBook): void {
    this.selectedAddressBook = address;
  }

  openDeleteAddressBookDialog(): void {
    this.openConfirmationDeleteDialog = true;
    this.activateClickOutside = false;
  }

  closeDeleteAddressBookDialog(confirmation: boolean): void {
    if (confirmation) {
      this.deleteAddressBook();
    } else {
      this.openConfirmationDeleteDialog = false;
      this.activateClickOutside = true;
    }
  }

  editAddressBook(): void {
    this.router.navigate(['configuration', 'address', 'update', this.selectedAddressBook.aggregateId]);
  }

  deleteAddressBook(): void {
    this.addressBookActions.requestAddressBookDelete({
      aggregateId: this.selectedAddressBook.aggregateId,
      version: this.selectedAddressBook.version,
    });

    this.store
      .pipe(
        select(selectDeleteAddressBook),
        filter((addressBooks: IStoreApiItem<IAddressBook>) => !addressBooks.isLoading),
        take(1),
      )
      .subscribe((addressBooks: IStoreApiItem<IAddressBook>) => {
        if (addressBooks.isSuccess) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            ConfigurationNotificationConstants.notificationCodes.DELETE_ADDRESS_BOOK_SUCCESS,
            ConfigurationNotificationConstants.notificationCodes,
          );
          this.tableHelperPrepareTableParameters();
          this.onAddressBookSelect(null);
        } else {
          this.httpCustomErrorCode = addressBooks.errors?.error.code.toString();

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            this.httpCustomErrorCode,
            ConfigurationNotificationConstants.notificationCodes,
          );
        }
      });
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();

    this.addressBookActions.resetAddressBookPage();
    this.localDestroy$.next(true);
    this.localDestroy$.complete();
  }
}
