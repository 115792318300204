import { IStoreApiItem, StoreApiItem } from 'src/app/common';
import { IAccessControlItems } from 'src/app/root/models/access-control-items.model';

export interface IAccessControlItemsState {
  accessControlItems: IStoreApiItem<IAccessControlItems>;
}

export class AccessControlItemsState implements IAccessControlItemsState {
  accessControlItems: IStoreApiItem<IAccessControlItems> = new StoreApiItem();
}
