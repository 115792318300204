import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { Router } from '@angular/router';
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import isEqual from 'lodash-es/isEqual';
import { CommonConstants, NotificationsService } from 'src/app/common';
import { IEcp, IIncident } from 'src/app/remote-monitoring/models/remote-monitoring.model';
import { NotificationConstants } from '../../constants/notification.constants';
import { OnDestroyMixin } from '../../mixins/destroy-mixin';
import { mapOptions } from './mapOptions';

interface IMapBounds {
  north: number;
  south: number;
  east: number;
  west: number;
}

@Component({
  selector: 'ignis-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MapComponent extends OnDestroyMixin() implements OnChanges, OnInit, AfterViewInit {
  @ViewChild(GoogleMap) map!: GoogleMap;
  mapLoaded: boolean = false;
  displayMap: boolean = false;
  mapVisible: boolean = true;

  mapOptions: google.maps.MapOptions;

  mouseOver: boolean = false;
  selectedMarkerECPIdentifier: string;

  copyOfECPList: IEcp[];

  mapMarkers: google.maps.marker.AdvancedMarkerElement[];
  mapClusterers: any;

  loadingDataFirstTime: boolean = false;
  lightMapID: string = CommonConstants.lightMapID;
  darkMapID: string = CommonConstants.darkMapID;

  @Input() selectedIncident: IIncident;
  @Input() selectedEcp: IEcp;
  @Input() ecpIdentifier: string;

  @Output() handleSelectedMarker: EventEmitter<any> = new EventEmitter();
  @Output() handleToDeselectECP: EventEmitter<boolean> = new EventEmitter();
  @Output() handleThemeChanged: EventEmitter<any> = new EventEmitter();

  bounds: IMapBounds;

  mapOptionsSelected: { view: string; mapId: string };

  router: Router = inject(Router);
  notificationsService: NotificationsService = inject(NotificationsService);

  constructor() {
    super();
    this.mapMarkers = [];
  }

  ngOnInit(): void {
    this.mapOptions = {
      ...mapOptions,
      mapId:
        CommonConstants.mapOptions in localStorage
          ? JSON.parse(localStorage.getItem(CommonConstants.mapOptions)).mapId
          : mapOptions.mapId,
    };
    this.mapOptions.mapTypeId = google.maps.MapTypeId?.ROADMAP;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.removeFuthermoreMarkers();

    if (
      changes.selectedIncident?.currentValue?.ecps.length > 0 &&
      !changes.selectedIncident?.previousValue?.ecps.length &&
      !this.loadingDataFirstTime
    ) {
      this.loadingDataFirstTime = true;
    }

    /* istanbul ignore if */
    if (this.loadingDataFirstTime && this.map) {
      this.processECPData();
      this.drawMarkerCluster();
      this.loadingDataFirstTime = false;

      this.mapOptionsSelected = {
        view: this.map.googleMap.getMapTypeId(),
        mapId: CommonConstants.darkMapID,
      };

      if (CommonConstants.mapOptions in localStorage) {
        this.mapOptionsSelected = JSON.parse(localStorage.getItem(CommonConstants.mapOptions));

        this.changeMapView(this.mapOptionsSelected.view);
        this.setDefaultMapId(this.mapOptionsSelected.mapId);
      }
    }

    /* istanbul ignore if */
    if (changes.selectedIncident?.currentValue?.ecps.length > changes.selectedIncident?.previousValue?.ecps.length) {
      this.deleteMarkers();
      this.processECPData();
      this.drawMarkerCluster();
    }

    /* istanbul ignore next */
    if (
      changes.selectedIncident?.currentValue?.ecps.length === changes.selectedIncident?.previousValue?.ecps.length &&
      !this.loadingDataFirstTime &&
      this.map
    ) {
      this.moveMarkerWhenCoordinatesIsChanged(changes);

      this.deleteMarkers();
      this.processECPData();
      this.drawMarkerCluster();

      this.updateMarkerPosition();
    }

    if (this.ecpIdentifier) {
      this.markerHover(this.ecpIdentifier);
    }

    if (this.selectedEcp) {
      this.zoomInToMarkerCluster();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      /* istanbul ignore if */
      if (document.querySelectorAll<HTMLElement | any>('.map-container')[1]?.children[0].style.position.length < 1) {
        window.location.reload();
      }

      this.removeTitleAttributeFromMarkers();
    }, 1000);
  }

  removeTitleAttributeFromMarkers(): void {
    const elements: NodeListOf<Element> = document.querySelectorAll('[class*="-marker-view"]');

    Array.from(elements).forEach((element: Element) => {
      element.removeAttribute('title');
    });
  }

  onMapReady(map: GoogleMap): void {
    this.map = map;
  }

  moveMarkerWhenCoordinatesIsChanged(changes: SimpleChanges): void {
    changes.selectedIncident?.currentValue?.ecps.forEach((currentData: IEcp) => {
      changes.selectedIncident?.previousValue?.ecps.forEach((previousData: IEcp) => {
        const coordinatesWasChanged: boolean = isEqual(
          currentData.location.gpsCoordinate,
          previousData.location.gpsCoordinate,
        );

        if (currentData.ecpId === previousData.ecpId && !coordinatesWasChanged) {
          this.displayMap = false;
        }
      });
    });
  }

  removeFuthermoreMarkers(): void {
    if (this.mapMarkers.length > this.selectedIncident?.ecps.length) {
      this.mapMarkers.forEach((_marker: google.maps.marker.AdvancedMarkerElement, markerIndex: number) => {
        this.selectedIncident?.ecps.forEach((_ecp: IEcp, ecpIndex: number) => {
          if (markerIndex === ecpIndex) {
            this.mapMarkers[markerIndex] = null;
          }
        });
      });
    }
  }

  mapErrorHandling(): void {
    this.notificationsService.requestShowNotification(
      CommonConstants.notificationType.ERROR,
      NotificationConstants.commonCodes.MAP_ERROR,
      NotificationConstants.commonCodes,
    );
  }

  getEcpIconCSSClass(ecp: IEcp): string {
    /* istanbul ignore else */
    if (!ecp?.hasAlarms && !ecp?.hasDsuOrLowPressureAlarms) {
      return this.toggleDefaultMarkerIcons(ecp);
    } else if (ecp?.hasDsuOrLowPressureAlarms) {
      return this.toggleDSUOrLowPressureAlarmsMarkerIcons(ecp);
    } else if (ecp?.hasAlarms) {
      return this.toggleOthersAlarmsMarkerIcons(ecp);
    } else {
      return null;
    }
  }

  toggleDefaultMarkerIcons(ecp: IEcp): string {
    if (this.mouseOver && ecp?.ecpId === this.selectedMarkerECPIdentifier) {
      return 'default-active-marker';
    } else {
      return null;
    }
  }

  toggleDSUOrLowPressureAlarmsMarkerIcons(ecp: IEcp): string {
    if (this.mouseOver && ecp?.ecpId === this.selectedMarkerECPIdentifier) {
      return 'marker-red marker-active-red';
    } else {
      return 'marker-red';
    }
  }

  toggleOthersAlarmsMarkerIcons(ecp: IEcp): string {
    if (this.mouseOver && ecp?.ecpId === this.selectedMarkerECPIdentifier) {
      return 'marker-yellow marker-active-yellow';
    } else {
      return 'marker-yellow';
    }
  }

  /* istanbul ignore next */
  processECPData(): void {
    if (this.selectedIncident) {
      this.copyOfECPList = this.selectedIncident?.ecps;
      const allEcps: IEcp[] = this.copyOfECPList;
      const ecpsWithCoordinates: IEcp[] = allEcps.filter((ecp: IEcp) => {
        return ecp.location.gpsCoordinate.lat !== 0 && ecp.location.gpsCoordinate.lng !== 0;
      });

      this.copyOfECPList?.forEach((ecp: IEcp, index: number) => {
        const ecpIdentifierFromUrl: string = window.location.href.split('/').slice(-2)[0];

        this.createMapMarkers(ecp, index);
        this.markerHover(ecpIdentifierFromUrl);

        if (!this.displayMap) {
          this.bounds = this.getBounds(ecpsWithCoordinates.length > 0 ? ecpsWithCoordinates : this.copyOfECPList);
          this.map?.googleMap.fitBounds(this.bounds);
          this.zoomOutTheMap();
        }

        this.displayMap = true;
      });
    }
  }

  /* istanbul ignore next */
  zoomOutTheMap(): void {
    const mapType: string = JSON.parse(localStorage.getItem(CommonConstants.mapOptions))?.view;
    const satelliteMapView: string = 'hybrid';

    setTimeout(
      () => {
        this.map?.googleMap.setZoom(this.map.getZoom() - 1);
      },
      mapType === satelliteMapView ? 1000 : 250,
    );
  }

  /* istanbul ignore next */
  drawMarkerCluster(): void {
    const clusterContentDiv: Element = this.createClusterContentDiv();

    this.updateClusterClasses(this.mapMarkers, clusterContentDiv);

    this.mapClusterers = this.createMarkerClusterer(clusterContentDiv);
  }

  zoomChanged(): void {
    this.handleToDeselectECP.emit(true);
  }

  private createClusterContentDiv(): Element {
    const clusterContentDiv: Element = document.createElement('div');

    clusterContentDiv.className = 'cluster-content';
    clusterContentDiv.setAttribute('title', '');

    clusterContentDiv.innerHTML = `
    <div class="cluster-text">
      <span>${this.mapMarkers.length}</span>
    </div>
  `;

    return clusterContentDiv;
  }

  private createMarkerClusterer(clusterContentDiv: Element): MarkerClusterer {
    const algorithm: SuperClusterAlgorithm = new SuperClusterAlgorithm({ maxZoom: 22 });

    return new MarkerClusterer({
      map: this.map.googleMap,
      markers: this.mapMarkers,
      algorithm,
      renderer: {
        render: ({ position }: { position: google.maps.LatLng }) => {
          return new google.maps.marker.AdvancedMarkerElement({
            position: position,
            map: this.map?.googleMap,
            content: clusterContentDiv,
          });
        },
      },
    });
  }

  private updateClusterClasses(markers: google.maps.marker.AdvancedMarkerElement[], clusterContentDiv: Element): void {
    markers.forEach((marker: google.maps.marker.AdvancedMarkerElement) => {
      const markerData: IEcp = JSON.parse(marker.title);

      if (markerData.hasDsuOrLowPressureAlarms && markerData.hasAlarms) {
        clusterContentDiv.classList.add('cluster-red');
      } else if (!markerData.hasDsuOrLowPressureAlarms && markerData.hasAlarms) {
        clusterContentDiv.classList.add('cluster-yellow');
      } else {
        clusterContentDiv.classList.add('cluster-default');
      }
    });
  }

  getBounds(ecpList: IEcp[]): IMapBounds {
    let north: number;
    let south: number;
    let east: number;
    let west: number;

    if (ecpList) {
      for (const ecp of ecpList) {
        north = north !== undefined ? Math.max(north, ecp.location.gpsCoordinate.lat) : ecp.location.gpsCoordinate.lat;
        south = south !== undefined ? Math.min(south, ecp.location.gpsCoordinate.lat) : ecp.location.gpsCoordinate.lat;
        east = east !== undefined ? Math.max(east, ecp.location.gpsCoordinate.lng) : ecp.location.gpsCoordinate.lng;
        west = west !== undefined ? Math.min(west, ecp.location.gpsCoordinate.lng) : ecp.location.gpsCoordinate.lng;
      }
    }

    return { north, south, east, west };
  }

  /* istanbul ignore next */
  createMapMarkers(ecp: IEcp, i: number): void {
    const markerContentDiv: Element = document.createElement('div');

    markerContentDiv.className = 'marker-content';
    markerContentDiv.setAttribute('title', '');

    markerContentDiv.innerHTML = `
        <div class="marker-text">
          <span>${ecp?.ecpNumber}</span>
        </div>
      `;

    const mapMarker: google.maps.marker.AdvancedMarkerElement = new google.maps.marker.AdvancedMarkerElement({
      position: ecp.location.gpsCoordinate,
      content: markerContentDiv,
      title: JSON.stringify(ecp),
      map: this.map?.googleMap,
      zIndex: 1 + i,
    });

    this.mapMarkers.push(mapMarker);

    mapMarker.addListener('click', () => {
      this.markerClick(mapMarker);

      this.handleSelectedMarker.emit(mapMarker);

      setTimeout(() => {
        this.resetMarkerIcon();
      }, 0);
    });

    this.markerSetIcon(mapMarker);

    setTimeout(() => {
      this.removeTitleAttributeFromMarkers();
    }, 50);
  }

  /* istanbul ignore next */
  updateMarkerPosition(): void {
    this.mapMarkers.forEach((marker: google.maps.marker.AdvancedMarkerElement, index: number) => {
      this.selectedIncident?.ecps.forEach((ecp: IEcp) => {
        if (JSON.parse(marker.title).ecpNumber === ecp.ecpNumber) {
          this.mapMarkers[index].position = {
            lat: ecp.location.gpsCoordinate.lat,
            lng: ecp.location.gpsCoordinate.lng,
          };
        }
      });
    });
    setTimeout(() => {
      this.map.googleMap.setZoom(this.map.googleMap.getZoom() - 1);
      this.map.googleMap.setZoom(this.map.googleMap.getZoom() + 1);
    }, 0);
  }

  /* istanbul ignore next */
  deleteMarkers(): void {
    this.mapClusterers?.removeMarkers(this.mapMarkers);

    this.mapMarkers.forEach((marker: google.maps.marker.AdvancedMarkerElement) => {
      marker.remove();
      marker.map = null;
    });

    this.mapMarkers = [];
    this.mapClusterers = null;
  }

  /* istanbul ignore next */
  markerHover(ecpIdentifier: string): void {
    this.selectedMarkerECPIdentifier = ecpIdentifier;

    this.copyOfECPList?.forEach((ecp: IEcp) => {
      if (ecp.ecpId === ecpIdentifier) {
        this.mouseOver = true;
      }

      this.mapMarkers.forEach((marker: google.maps.marker.AdvancedMarkerElement) => {
        if (JSON.parse(marker.title).ecpId === ecpIdentifier) {
          this.markerClick(marker);

          setTimeout(() => {
            this.resetMarkerIcon();
          }, 0);
        }
      });
    });
  }

  /* istanbul ignore next */
  markerClick(marker: google.maps.marker.AdvancedMarkerElement): void {
    const ecpData: IEcp = JSON.parse(marker.title);
    const ecpIdentifier: string = ecpData.ecpId;

    this.router.navigate([
      'remote-monitoring',
      'incident-overview',
      this.selectedIncident.aggregateId,
      'ecp',
      ecpIdentifier,
      'map-view',
    ]);

    this.mapMarkers.forEach((elem: google.maps.marker.AdvancedMarkerElement, index: number) => {
      if (JSON.parse(elem.title).ecpId === ecpIdentifier) {
        this.mapMarkers.splice(index, 1);
      } else {
        this.mouseOver = false;
      }
    });

    this.markerHover(ecpIdentifier);
    this.markerSetIcon(marker);

    this.mapMarkers.push(marker);
  }

  /* istanbul ignore next */
  resetMarkerIcon(): void {
    const ecpIdentifierFromUrl: string = window.location.href.split('/').slice(-2)[0];

    this.mapMarkers.forEach((marker: google.maps.marker.AdvancedMarkerElement) => {
      if (JSON.parse(marker.title).ecpId !== ecpIdentifierFromUrl) {
        const activeClass: string = Array.from((marker.content as HTMLDivElement).classList).find((className: string) =>
          className.includes('active'),
        );

        (marker.content as HTMLDivElement).classList.remove(activeClass);
      }
    });
  }

  /* istanbul ignore next */
  markerSetIcon(marker: google.maps.marker.AdvancedMarkerElement): void {
    let classesToAdd: string = this.getEcpIconCSSClass(JSON.parse(marker.title));

    if (!classesToAdd) {
      classesToAdd = 'cluster-content';
    }

    (marker.content as HTMLDivElement).classList.add(...classesToAdd.split(' '));
  }

  changeMapView(view: string): void {
    this.map?.googleMap.setOptions({ mapTypeId: view });

    this.mapOptionsSelected.view = view;
    localStorage.setItem(CommonConstants.mapOptions, JSON.stringify(this.mapOptionsSelected));
  }

  setDefaultMapId(mapId: string): void {
    this.map?.googleMap.setOptions({ mapId });

    this.map?.googleMap.setOptions({ mapTypeId: this.mapOptionsSelected.view });
    this.mapOptionsSelected.mapId = mapId;
    localStorage.setItem(CommonConstants.mapOptions, JSON.stringify(this.mapOptionsSelected));
  }

  changeMapId(mapId: string): void {
    this.mapVisible = false;

    const currentCenter: google.maps.LatLng = this.map?.googleMap.getCenter();
    const currentZoom: number = this.map?.googleMap.getZoom();

    setTimeout(() => {
      this.mapOptions = { ...mapOptions, mapId, mapTypeId: this.mapOptionsSelected.view };
      this.mapOptionsSelected.mapId = mapId;

      localStorage.setItem(CommonConstants.mapOptions, JSON.stringify(this.mapOptionsSelected));

      this.mapVisible = true;

      setTimeout(() => {
        if (this.map) {
          this.handleThemeChanged.emit();
          this.map?.googleMap.setCenter(currentCenter);
          this.map?.googleMap.setZoom(currentZoom);
        }
      }, 0);
    }, 0);
  }

  /* istanbul ignore next */
  zoomInToMarkerCluster(): void {
    setTimeout(() => {
      const mapClusterers: any = this.mapClusterers?.clusters;

      if (!mapClusterers) return;

      mapClusterers.forEach((cluster: any) => {
        this.handleCluster(cluster);
      });
    }, 0);
  }

  private handleCluster(cluster: any): void {
    const markersFromCluster: google.maps.marker.AdvancedMarkerElement[] = cluster.markers;

    if (!markersFromCluster || markersFromCluster.length <= 1) return;

    markersFromCluster.forEach((clusterMarker: google.maps.marker.AdvancedMarkerElement) => {
      this.checkAndZoomIn(clusterMarker, cluster);
    });
  }

  private checkAndZoomIn(clusterMarker: google.maps.marker.AdvancedMarkerElement, cluster: any): void {
    setTimeout(() => {
      if (this.isMarkerActive(clusterMarker)) {
        this.zoomIntoCluster(cluster);
        this.handleToDeselectECP.emit(true);
      }
    }, 0);
  }

  private isMarkerActive(clusterMarker: google.maps.marker.AdvancedMarkerElement): boolean {
    const markerContent: HTMLDivElement = clusterMarker.content as HTMLDivElement;

    return Array.from(markerContent.classList).some((className: string) => className.includes('active'));
  }

  private zoomIntoCluster(cluster: any): void {
    this.map.googleMap.fitBounds(cluster.bounds);
  }
}
