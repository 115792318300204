import { IStoreApiItem, IStoreApiList, StoreApiItem, StoreApiList } from 'src/app/common';
import { IOrganizationEntries, IOrganizationLicenseInfo } from '../../models/organization-settings.model';

export interface IOrganizationEntriesState {
  entries: IStoreApiItem<IOrganizationEntries>,
  licenseInfo: IStoreApiList<IOrganizationLicenseInfo[]>;
}

export class OrganizationEntriesState implements IOrganizationEntriesState {
  entries: IStoreApiItem<IOrganizationEntries> = new StoreApiItem();
  licenseInfo: IStoreApiList<IOrganizationLicenseInfo[]> = new StoreApiList();
}
