import { TreeNode } from 'primeng/api';
import { IEntryModel, IErrorCodesForCSV, INavigationTabs, PropertyBag, TableFilters } from 'src/app/common/models';
import { IEquipmentHistoryEventType } from '../models';

/* eslint-disable no-shadow */
export class WorkshopConstants {
  public static readonly equipmentInitialSpecification: { categories: any; types: any } = {
    categories: {
      1: {
        name: 'Respiratory Protection',
        localizedName: 'CATEGORY_TRANSLATION.STR_EQUIPMENT_INITIAL_SPECIFICATION_RESPIRATORY_PROTECTION',
      },
    },
    types: {
      1: {
        name: 'Face mask',
        localizedName: 'TYPE_TRANSLATION.STR_FACE_MASK',
      },
      2: {
        name: 'Lung demand valve',
        localizedName: 'TYPE_TRANSLATION.STR_LUNG_DEMAND_VALVE',
      },
      3: {
        name: 'Breathing apparatus',
        localizedName: 'TYPE_TRANSLATION.STR_BREATHING_APPARATUS',
      },
      4: {
        name: 'Cylinder',
        localizedName: 'TYPE_TRANSLATION.STR_CYLINDER',
      },
    },
  };

  public static readonly createEditModalTabs: PropertyBag = {
    GENERAL: 'GENERAL',
    ASSIGNMENTS: 'ASSIGNMENTS',
    TASKS: 'TASKS',
    MANUFACTURERS: 'MANUFACTURERS',
    ADDITIONAL_DETAILS: 'ADDITIONAL_DETAILS',
  };

  public static readonly testResultTabs: PropertyBag = {
    GENERAL: 'GENERAL',
    TEST_VALUES: 'TEST_VALUES',
  };

  public static readonly taskValuesType: PropertyBag = {
    AUTOMATIC_TEST: 'AUTOMATIC_TEST',
    MANUAL_TEST: 'MANUAL_TEST',
  };

  public static readonly tabs: INavigationTabs[] = [
    {
      displayName: 'General',
      value: 'generalData',
      localizedName: 'CREATE_UPDATE_EQUIPMENT.STR_CREATE_EQUIPMENT_GENERAL_TAB',
    },
    {
      displayName: 'Assignments',
      value: 'locationAssignment',
      localizedName: 'CREATE_UPDATE_EQUIPMENT.STR_CREATE_EQUIPMENT_LOCATION_AND_ASSIGNMENTS_TAB',
    },
    {
      displayName: 'Manufacturer',
      value: 'manufacturerData',
      localizedName: 'CREATE_UPDATE_EQUIPMENT.STR_CREATE_EQUIPMENT_MANUFACTURER_TAB',
    },
    {
      displayName: 'Manufacturer',
      value: 'supplierData',
      localizedName: 'CREATE_UPDATE_EQUIPMENT.STR_CREATE_EQUIPMENT_MANUFACTURER_TAB',
    },
    {
      displayName: 'Additional details',
      value: 'additionalEquipmentData',
      localizedName: 'CREATE_UPDATE_EQUIPMENT.STR_CREATE_EQUIPMENT_ADDITIONAL_DETAILS_TAB',
    },
  ];

  public static readonly historyTypes: {
    types: IEquipmentHistoryEventType[];
  } = {
    types: [
      {
        value: 'GENERAL_DATA_CREATED',
        name: 'EQUIPMENT_HISTORY.STR_GENERAL_DATA_CREATED',
        icon: 'database-edit',
        localizedName: 'EQUIPMENT_HISTORY.STR_TYPE_GENERAL_DATA_CREATED',
      },
      {
        value: 'EQUIPMENT_IMPORTED',
        name: 'EQUIPMENT_HISTORY.STR_GENERAL_DATA_IMPORTED',
        icon: 'database-edit',
        localizedName: 'EQUIPMENT_HISTORY.STR_TYPE_GENERAL_DATA_IMPORTED',
      },
      {
        value: 'GENERAL_DATA_CHANGED',
        name: 'EQUIPMENT_HISTORY.STR_GENERAL_DATA_CHANGED',
        icon: 'database-edit',
        localizedName: 'EQUIPMENT_HISTORY.STR_TYPE_GENERAL_DATA_CHANGED',
      },
      {
        value: 'LOCATION_CHANGED',
        name: 'EQUIPMENT_HISTORY.STR_LOCATION_CHANGED',
        icon: 'relocate',
        localizedName: 'EQUIPMENT_HISTORY.STR_TYPE_LOCATION_CHANGED',
      },
      {
        value: 'SERVICE_DATA_CHANGED',
        name: 'EQUIPMENT_HISTORY.STR_SERVICE_DATA_CHANGED',
        icon: 'log-service',
        localizedName: 'EQUIPMENT_HISTORY.STR_TYPE_SERVICE_DATA_CHANGED',
      },
      {
        value: 'EQUIPMENT_TASKS_CHANGED',
        name: 'EQUIPMENT_HISTORY.STR_EQUIPMENT_TASKS_CHANGED',
        icon: 'log-service',
        localizedName: 'EQUIPMENT_HISTORY.STR_TYPE_SERVICE_DATA_CHANGED',
      },
      {
        value: 'PROTECTOR_SOFTWARE_TEST_PERFORMED',
        name: 'EQUIPMENT_HISTORY.STR_PROTECTOR_SOFTWARE_TEST_PERFORMED',
        icon: 'test-eq',
        localizedName: 'EQUIPMENT_HISTORY.STR_TYPE_PROTECTOR_SOFTWARE_TEST_PERFORMED',
      },
      {
        value: 'MANUAL_TEST_PERFORMED',
        name: 'EQUIPMENT_HISTORY.STR_MANUAL_TEST_PERFORMED',
        icon: 'test',
        localizedName: 'EQUIPMENT_HISTORY.STR_TYPE_MANUAL_TEST_PERFORMED',
      },
      {
        value: 'CYLINDER_CHARGED_EVENT',
        name: 'EQUIPMENT_HISTORY.STR_CYLINDER_CHARGED',
        icon: 'cylinder-charge',
        localizedName: 'EQUIPMENT_HISTORY.STR_CYLINDER_CHARGED_PERFORMED',
      },
      {
        value: 'EQUIPMENT_BARCODE_CHANGED_AFTER_SERVICE',
        name: 'EQUIPMENT_HISTORY.STR_GENERAL_DATA_CHANGED',
        icon: 'database-edit',
        localizedName: 'EQUIPMENT_HISTORY.STR_EQUIPMENT_BARCODE_CHANGED_AFTER_SERVICE',
      },
    ],
  };

  public static readonly historyFilters: TreeNode[][] = [
    [
      {
        key: '0',
        label: 'EQUIPMENT_HISTORY.STR_FILTERS_MANUAL_SERVICE',
        icon: 'test',
        data: 'isManualTestEvent',
      },
      {
        key: '1',
        label: 'EQUIPMENT_HISTORY.STR_FILTERS_AUTOMATIC_SERVICE',
        icon: 'test-eq',
        data: 'isProtectorTestEvent',
      },
      {
        key: '2',
        label: 'EQUIPMENT_HISTORY.STR_FILTERS_CYLINDER_CHARGING',
        icon: 'cylinder-charge',
        data: 'isCylinderChargedEvent',
      },
      {
        key: '3',
        label: 'EQUIPMENT_HISTORY.STR_FILTERS_LOCATION_CHANGES',
        icon: 'relocate',
        data: 'isLocationChangeEvent',
      },
    ],
    [
      {
        key: '4',
        label: 'EQUIPMENT_HISTORY.STR_FILTERS_MASTER_DATA_CHANGES',
        icon: 'database-edit',
        data: 'isGeneralDataChangeEvent',
      },
      {
        key: '5',
        label: 'EQUIPMENT_HISTORY.STR_EQUIPMENT_TASKS_CHANGED',
        icon: 'log-service',
        data: 'isEquipmentTasksChangedEvent',
      },
      {
        key: '6',
        label: 'EQUIPMENT_HISTORY.STR_FILTERS_USAGE_DATA',
        icon: 'data-set',
        data: 'usage',
        partialSelected: false,
        expanded: true,
        children: [
          {
            key: '6-0',
            label: 'EQUIPMENT_HISTORY.STR_FILTERS_INCIDENT_USAGE',
            icon: 'fire',
            data: 'isIncidentUsageEvent',
            parent: {
              key: '6-0-0',
              label: 'EQUIPMENT_HISTORY.STR_FILTERS_USAGE_DATA',
              icon: 'data-set',
              data: 'usage',
              partialSelected: false,
              children: [
                {
                  key: '6-0-0-1',
                  label: 'EQUIPMENT_HISTORY.STR_FILTERS_INCIDENT_USAGE',
                  icon: 'fire',
                  data: 'isIncidentUsageEvent',
                },
              ],
            },
          },
        ],
      },
    ],
  ];

  public static readonly errorCodeForCSVFile: IErrorCodesForCSV = {
    100: {
      value: 100,
      text: 'INVENTORY_CSV_IMPORT_ERROR_LIST.STR_MISSING_REQUIRED_FIELD',
    },
    200: {
      value: 200,
      text: 'INVENTORY_CSV_IMPORT_ERROR_LIST.STR_MISSING_FIELD_AT_EXPECTED_POSITION',
    },
    2: {
      value: 2,
      text: 'INVENTORY_CSV_IMPORT_ERROR_LIST.STR_INVALID_DATE_FORMAT',
    },
    3: {
      value: 3,
      text: 'INVENTORY_CSV_IMPORT_ERROR_LIST.STR_INVALID_OPERATIONAL_STATUS',
    },
    4: {
      value: 4,
      text: 'INVENTORY_CSV_IMPORT_ERROR_LIST.STR_SAME_IDENTIFIER_IN_CSV',
    },
    5: {
      value: 5,
      text: 'INVENTORY_CSV_IMPORT_ERROR_LIST.STR_ALREADY_EXISTING_IDENTIFIER',
    },
    6: {
      value: 6,
      text: 'INVENTORY_CSV_IMPORT_ERROR_LIST.STR_SAME_BARCODE_IN_CSV',
    },
    7: {
      value: 7,
      text: 'INVENTORY_CSV_IMPORT_ERROR_LIST.STR_ALREADY_EXISTING_BARCODE',
    },
    8: {
      value: 8,
      text: 'INVENTORY_CSV_IMPORT_ERROR_LIST.STR_CATEGORY_NOT_FOUND',
    },
    9: {
      value: 9,
      text: 'INVENTORY_CSV_IMPORT_ERROR_LIST.STR_TYPE_NOT_FOUND',
    },
    10: {
      value: 10,
      text: 'INVENTORY_CSV_IMPORT_ERROR_LIST.STR_MODEL_NOT_FOUND',
    },
    11: {
      value: 11,
      text: 'INVENTORY_CSV_IMPORT_ERROR_LIST.STR_TYPE_NOT_BELONG_TO_CATEGORY',
    },
    12: {
      value: 12,
      text: 'INVENTORY_CSV_IMPORT_ERROR_LIST.STR_MODEL_NOT_BELONG_TO_TYPE',
    },
    13: {
      value: 13,
      text: 'INVENTORY_CSV_IMPORT_ERROR_LIST.STR_SAME_RFID_IN_CSV',
    },
    14: {
      value: 14,
      text: 'INVENTORY_CSV_IMPORT_ERROR_LIST.STR_EQUIPMENT_ALREADY_EXISTING_WITH_SAME_RFID',
    },
    15: {
      value: 15,
      text: 'INVENTORY_CSV_IMPORT_ERROR_LIST.STR_SAME_SERIAL_NO_IN_CSV',
    },
    16: {
      value: 16,
      text: 'INVENTORY_CSV_IMPORT_ERROR_LIST.STR_EQUIPMENT_ALREADY_EXISTING_WITH_SAME_SERIAL_NO',
    },
    17: {
      value: 17,
      text: 'INVENTORY_CSV_IMPORT_ERROR_LIST.STR_OUT_OF_SERVICE_DATE_BEFORE_IN_USE',
    },
  };

  public static readonly locationChangeReasons: IEntryModel[] = [
    {
      value: 'NOT_SPECIFIED',
      label: 'Not Specified',
      localizedName: 'WORKSHOP_WORKFLOW_ASSET.STR_NOT_SPECIFIED',
    },
    {
      value: 'TRAINING',
      label: 'Training',
      localizedName: 'WORKSHOP_WORKFLOW_ASSET.STR_TRAINING',
    },
    {
      value: 'INCIDENT',
      label: 'Incident',
      localizedName: 'WORKSHOP_WORKFLOW_ASSET.STR_INCIDENT',
    },
    {
      value: 'MAINTENANCE',
      label: 'Maintenance',
      localizedName: 'WORKSHOP_WORKFLOW_ASSET.STR_MAINTENANCE',
    },
    {
      value: 'CONTAMINATION',
      label: 'Contamination',
      localizedName: 'WORKSHOP_WORKFLOW_ASSET.STR_CONTAMINATION',
    },
    {
      value: 'CYLINDER_CHARGING',
      label: 'Cylinder Charging',
      localizedName: 'WORKSHOP_WORKFLOW_ASSET.STR_CYLINDER_CHARGING',
    },
  ];

  public static readonly operationalStatusLocalize: {
    status: IEntryModel[];
  } = {
    status: [
      {
        value: 'OPERATIONAL',
        localizedName: 'EQUIPMENT_GENERAL.STR_OPERATIONAL_STATUSES_OPERATIONAL',
      },
      {
        value: 'DEFECTIVE_BUT_OPERATIONAL',
        localizedName: 'EQUIPMENT_GENERAL.STR_DEFECTIVE_BUT_OPERATIONAL_STATUSES',
      },
      {
        value: 'NON_OPERATIONAL',
        localizedName: 'EQUIPMENT_GENERAL.STR_NON_OPERATIONAL_STATUSES',
      },
    ],
  };

  public static readonly operationalStatuses: PropertyBag = {
    OPERATIONAL: 'OPERATIONAL',
    DEFECTIVE_BUT_OPERATIONAL: 'DEFECTIVE_BUT_OPERATIONAL',
    NON_OPERATIONAL: 'NON_OPERATIONAL',
  };

  public static readonly operationalStatusesMapping: PropertyBag = {
    ...this.operationalStatuses,
    true: this.operationalStatuses.OPERATIONAL,
    false: this.operationalStatuses.NON_OPERATIONAL,
  };

  public static readonly serviceStatuses: PropertyBag = {
    OK: 'OK',
    WARNING: 'WARNING',
    NON_COMPUTABLE: 'NON_COMPUTABLE',
  };

  public static readonly testAssessmentsTranslation: PropertyBag = {
    PASS: 'EQUIPMENT_TASK_RESULTS_ASSESSMENTS.STR_PASS',
    FAIL: 'EQUIPMENT_TASK_RESULTS_ASSESSMENTS.STR_FAIL',
    ABORT: 'EQUIPMENT_TASK_RESULTS_ASSESSMENTS.STR_ABORT',
    N_A: 'EQUIPMENT_TASK_RESULTS_ASSESSMENTS.STR_NA',
    UNDEFINED: 'EQUIPMENT_TASK_RESULTS_ASSESSMENTS.STR_UNDEFINED',
  };

  public static readonly invalidFieldsErrorsMap: PropertyBag = {
    '2008': 'generalData.outOfOrderDate',
    '2017': 'generalData.barcode',
    '2045': 'generalData.rfid',
    '2051': 'generalData.serialNo',
    '10002': 'generalData.identifier',
  };

  public static readonly invalidAdditionalDetailsErrosMap: PropertyBag = {
    STOCK_NUMBER: 'additionalEquipmentData.stockNumber',
    HEAD_UP_DISPLAY_ID: 'additionalEquipmentData.headUpDisplayId',
    BACK_PLATE_ID: 'additionalEquipmentData.backPlateId',
    GAUGE_ID: 'additionalEquipmentData.gaugeId',
    PASS_DEVICE_ID: 'additionalEquipmentData.passDeviceId',
    MASK_ID: 'additionalEquipmentData.maskId',
    LUNG_DEMAND_VALVE_ID: 'additionalEquipmentData.lungDemandValveId',
    PRESSURE_REDUCER_ID: 'additionalEquipmentData.pressureReducerId',
    CYLINDER_ID: 'additionalEquipmentData.cylinderId',
  };

  public static readonly operationalStatusTranslationKey: PropertyBag = {
    OPERATIONAL: 'CREATE_UPDATE_EQUIPMENT.STR_OPERATIONAL_STATUSES_OPERATIONAL',
    DEFECTIVE_BUT_OPERATIONAL: 'CREATE_UPDATE_EQUIPMENT.STR_DEFECTIVE_BUT_OPERATIONAL_STATUSES',
    NON_OPERATIONAL: 'CREATE_UPDATE_EQUIPMENT.STR_NON_OPERATIONAL_STATUSES',
  };

  public static readonly selectedTask: string = 'selectedTask';
  public static readonly isProtectorTestType: string = 'isProtectorTestType';
  public static readonly hasCheckList: string = 'hasCheckList';
  public static readonly testParameters: string = 'testParameters';
  public static readonly equipmentTestedWithSuccess: string = 'equipmentTestedWithSuccess';
  public static readonly testStartFrom: string = 'testStartFrom';
  public static readonly minimumPressureConstant: number = 0;
  public static readonly addressBookSupplierRequestOnEquipment: TableFilters = {
    page: 0,
    size: 1000,
    sort: 'organizationName,ASC',
    types: 'SUPPLIER',
  };
  public static readonly selectedWorkflow: string = 'selectedWorkflow';
  public static readonly barCodeToSave: string = 'barCodeToSave';
  public static readonly equipmentCSVTemplateUrl: string = '/assets/Equipment-Import-CSV-Template.csv';
  public static readonly savedLocation: string = 'savedLocation';
  public static readonly workflowTable: string = 'workflowTableData';
  public static readonly equipmentToDelete: string = 'equipmentToDelete';
  public static readonly testFromWorkflowPage: string = 'testFromWorkflowPage';
  public static readonly equipmentAdded: string = 'equipmentAdded';
  public static readonly equipmentAddedAfterScan: string = 'equipmentAddedAfterScan';
  public static readonly testEnded: string = 'testEnded';
  public static readonly tasksIncluded: string = 'tasksIncluded';
  public static readonly otherTasks: string = 'otherTasks';
  public static readonly serviceIntervals: string = 'serviceIntervals';
  public static readonly toggledResultFields: string[] = [
    'comment',
    'operationalStatus',
    'airQualityMeasured',
    'newBarcode',
    'newRfid',
    'outOfServiceReason',
  ];
  public static readonly testIsCanceledStorageKey: string = 'testIsCanceledStorageKey';
  public static readonly taksId: string = 'taksId';
  public static readonly openTasksTab: string = 'openTasksTab';
}

export enum TaskResultTypeEnum {
  CylinderCharging = 'CYLINDER_CHARGING',
  Checklist = 'CHECKLIST',
  AutomatedTest = 'AUTOMATED_TEST',
}
