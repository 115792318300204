<ignis-progress-bar *ngIf="isLoadingIncidents"></ignis-progress-bar>

<div class="row incidents-grid-row">
  <div
    class="incident-tile"
    *ngFor="let incident of incidentsList; trackBy: trackByIncident"
    [attr.data-test-id]="'remote_monitoring.incident-' + incident.incidentId"
  >
    <ignis-incident-tile
      [incidentData]="incident"
      [isModalOpened]="isModalOpened"
      [formatDate]="formatDate"
      [selectedIncidentIds]="selectedIncidentIds"
      [numberOfSelectedIncidents]="numberOfSelectedIncidents"
      [disableMonitorIncidentBtn]="disableMonitorIncidentBtn"
      (navigateToIncidentOverview)="navigateToIncidentOverview($event)"
      (selectedIncidentToMerge)="getIncidentsToMerge($event)"
      (resetMergeProcess)="resetMergeProcess()"
    ></ignis-incident-tile>
  </div>
</div>
