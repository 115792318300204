import { INotificationConstant } from 'src/app/common';

export class IncidentNotificationConstants {
  public static readonly notificationCodes: INotificationConstant = {
    UNEXPECTED_ERROR: {
      value: '7001',
      text: 'INCIDENT_NOTIFICATION.STR_UNEXPECTED_ERROR',
    },
    ENTITY_NOT_FOUND: {
      value: '7002',
      text: 'INCIDENT_NOTIFICATION.STR_ENTITY_NOT_FOUND',
    },
    AGGREGATE_VERSION_CONFLICT: {
      value: '7003',
      text: 'INCIDENT_NOTIFICATION.STR_AGGREGATE_VERSION_CONFLICT',
    },
    START_TIME_ERROR: {
      value: '7004',
      text: 'INCIDENT_NOTIFICATION.STR_START_TIME_ERROR',
    },
    END_TIME_ERROR: {
      value: '7005',
      text: 'INCIDENT_NOTIFICATION.STR_END_TIME_ERROR',
    },
    MERGE_GAP_ERROR: {
      value: '7007',
      text: 'INCIDENT_NOTIFICATION.STR_INCIDENTS_MERGE_GAP_ERROR',
    },
    EVENT_MARKER_MANDATORY_NAME: {
      value: '7008',
      text: 'INCIDENT_NOTIFICATION.STR_EVENT_NAME_TOOLTIP',
    },
    START_DATE_OUTSIDE_TIMELINE: {
      value: '7009',
      text: 'INCIDENT_NOTIFICATION.STR_START_DATE_OUTSIDE_TIMELINE',
    },
    END_DATE_OUTSIDE_TIMELINE: {
      value: '7010',
      text: 'INCIDENT_NOTIFICATION.STR_END_DATE_OUTSIDE_TIMELINE',
    },
    END_DATE_BEFORE_START_DATE: {
      value: '7011',
      text: 'INCIDENT_NOTIFICATION.STR_END_DATE_BEFORE_START_DATE',
    },
    OVERLAPPING_TIME_RANGE: {
      value: '7012',
      text: 'INCIDENT_NOTIFICATION.STR_OVERLAPPING_TIME_RANGE',
    },
    OVERLAPPING_START_TIME: {
      value: '7013',
      text: 'INCIDENT_NOTIFICATION.STR_OVERLAPPING_START_TIME',
    },
    OVERLAPPING_END_TIME: {
      value: '7014',
      text: 'INCIDENT_NOTIFICATION.STR_OVERLAPPING_END_TIME',
    },

    INCIDENTS_MERGE_SUCCESS: {
      value: 'INCIDENTS_MERGE_SUCCESS',
      text: 'INCIDENT_NOTIFICATION.STR_INCIDENTS_MERGE_SUCCESS',
    },
    INCIDENTS_MERGE_SAME_INCIDENT_ERROR: {
      value: 'INCIDENTS_MERGE_SAME_INCIDENT_ERROR',
      text: 'INCIDENT_NOTIFICATION.STR_INCIDENTS_MERGE_SAME_INCIDENT_ERROR',
    },
    INCIDENTS_MERGE_DISTANCE_ERROR: {
      value: 'INCIDENTS_MERGE_DISTANCE_ERROR',
      text: 'INCIDENT_NOTIFICATION.STR_INCIDENTS_MERGE_DISTANCE_ERROR',
    },
    INCIDENTS_MERGE_ECP_ERROR: {
      value: 'INCIDENTS_MERGE_ECP_ERROR',
      text: 'INCIDENT_NOTIFICATION.STR_INCIDENTS_MERGE_ECP_ERROR',
    },
    INCIDENTS_MERGE_TIME_ERROR: {
      value: 'INCIDENTS_MERGE_TIME_ERROR',
      text: 'INCIDENT_NOTIFICATION.STR_INCIDENTS_MERGE_TIME_ERROR',
    },
    REMOVE_INCIDENT_SUCCESS: {
      value: 'REMOVE_INCIDENT_SUCCESS',
      text: 'INCIDENT_NOTIFICATION.STR_REMOVE_SUCCESS',
    },
    DELETE_EVENT_MARKER_SUCCESS: {
      value: 'DELETE_EVENT_MARKER_SUCCESS',
      text: 'INCIDENT_NOTIFICATION.STR_MANUAL_EVENT_DELETE_SUCCESS',
    },
    UPDATE_EVENT_MARKER_SUCCESS: {
      value: 'UPDATE_EVENT_MARKER_SUCCESS',
      text: 'INCIDENT_NOTIFICATION.STR_MANUAL_EVENT_UPDATE_SUCCESS',
    },
    ADD_EVENT_MARKER_SUCCESS: {
      value: 'ADD_EVENT_MARKER_SUCCESS',
      text: 'INCIDENT_NOTIFICATION.STR_MANUAL_EVENT_SAVED_SUCCESS',
    },
    UPDATE_INCIDENT_SUCCESS: {
      value: 'UPDATE_INCIDENT_SUCCESS',
      text: 'INCIDENT_NOTIFICATION.STR_UPDATE_INCIDENT_SUCCESS',
    },
    INCIDENTS_MERGE_PREVENT_ERROR: {
      value: 'INCIDENTS_MERGE_PREVENT_ERROR',
      text: 'INCIDENT_NOTIFICATION.STR_INCIDENTS_MERGE_PREVENT_ERROR',
    },
    UNSAVED_INCIDENT_INFORMATION: {
      value: 'UNSAVED_INCIDENT_INFORMATION',
      text: 'INCIDENT_NOTIFICATION.STR_UNSAVED_INCIDENT_INFORMATION',
    },
  };
}
