import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'ignis-tasks-column',
  templateUrl: './tasks-column.component.html',
  styleUrls: ['./tasks-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksColumnComponent implements OnChanges {
  @ViewChild('otherTasksColumnText') otherTasksColumnText: Partial<ElementRef>;
  @ViewChild('tableColumnEllipsisWrap') tableColumnEllipsisWrap: Partial<ElementRef>;
  @Input() tasks: { data: string[] | string; field: string };
  disableTooltip: boolean;
  disableRootTooltip: boolean;

  ngOnChanges(): void {
    if (typeof this.tasks.data === 'string') {
      this.tasks.data = [this.tasks.data];
    }
  }

  composeListTooltip(servicesList: string[]): string {
    let tooltipContent: string = '';

    if (Array.isArray(servicesList)) {
      servicesList.forEach((item: string) => (tooltipContent = tooltipContent + `${item},` + '\n'));
    }

    tooltipContent = tooltipContent.slice(0, -2);

    return tooltipContent;
  }

  calculateToDisableTooltip(): void {
    this.disableTooltip =
      (this.otherTasksColumnText.nativeElement as HTMLElement).scrollWidth >
      (this.otherTasksColumnText.nativeElement as HTMLElement).offsetWidth;
  }

  checkIfItHasEllipses(): void {
    const element: HTMLElement = this.tableColumnEllipsisWrap.nativeElement as HTMLElement;
    const itHasEllipses: boolean = element.scrollWidth > element.clientWidth;

    this.disableRootTooltip = itHasEllipses;
  }
}
