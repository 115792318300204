import { GenericAction } from 'src/app/common';
import {
  errorDeleteItem,
  errorItemData,
  errorPostPutItemData,
  receiveDeleteItem,
  receiveItemData,
  receivePostPutItemData,
  requestDeleteItem,
  requestItemData,
  requestPostPutItemData,
} from 'src/app/common/utils/reducer/reducer.utils';
import { IAddressBook, IAddressBookPage } from '../../../models/address-book.model';
import { AddressBookActionTypes } from '../../actions';
import { AddressBookState, IAddressBookState } from '../../models/address-book.models';

const resetAddressBookPageState = (lastState: IAddressBookState): IAddressBookState => ({
  ...lastState,
  addressBookList: {
    isLoading: false,
    isSuccess: false,
    data: null,
    errors: null,
  },
});

export function addressBookReducer(
  lastState: IAddressBookState = new AddressBookState(),
  action: GenericAction<AddressBookActionTypes, any>,
): IAddressBookState {
  switch (action.type) {
    case AddressBookActionTypes.REQUEST_ADDRESS_BOOK:
      return requestItemData<IAddressBookState, IAddressBookPage>(lastState, ['addressBookList']);
    case AddressBookActionTypes.RECEIVE_ADDRESS_BOOK:
      return receiveItemData<IAddressBookState, IAddressBookPage>(lastState, ['addressBookList'], action.payload);
    case AddressBookActionTypes.ERROR_RECEIVE_ADDRESS_BOOK:
      return errorItemData<IAddressBookState, IAddressBookPage>(lastState, ['addressBookList'], action.payload);
    case AddressBookActionTypes.RESET_ADDRESS_BOOK_PAGE:
      return resetAddressBookPageState(lastState);

    case AddressBookActionTypes.REQUEST_ADD_ADDRESS_BOOK:
      return requestPostPutItemData<IAddressBookState, IAddressBook>(lastState, ['newAddressBook'], action.payload);
    case AddressBookActionTypes.RECEIVE_ADD_ADDRESS_BOOK:
      return receivePostPutItemData<IAddressBookState, IAddressBook>(lastState, ['newAddressBook'], action.payload);
    case AddressBookActionTypes.ERROR_RECEIVE_ADD_ADDRESS_BOOK:
      return errorPostPutItemData<IAddressBookState, IAddressBook>(lastState, ['newAddressBook'], action.payload);

    case AddressBookActionTypes.REQUEST_EDIT_ADDRESS_BOOK:
      return requestDeleteItem<IAddressBookState, IAddressBook>(lastState, ['editAddressBook']);
    case AddressBookActionTypes.RECEIVE_EDIT_ADDRESS_BOOK:
      return receiveDeleteItem<IAddressBookState, IAddressBook>(lastState, ['editAddressBook']);
    case AddressBookActionTypes.ERROR_RECEIVE_EDIT_ADDRESS_BOOK:
      return errorDeleteItem<IAddressBookState, IAddressBook>(lastState, ['editAddressBook'], action.payload);

    case AddressBookActionTypes.REQUEST_DELETE_ADDRESS_BOOK:
      return requestPostPutItemData<IAddressBookState, IAddressBook>(lastState, ['deleteAddressBook'], action.payload);
    case AddressBookActionTypes.RECEIVE_DELETE_ADDRESS_BOOK:
      return receivePostPutItemData<IAddressBookState, IAddressBook>(lastState, ['deleteAddressBook'], action.payload);
    case AddressBookActionTypes.ERROR_RECEIVE_DELETE_ADDRESS_BOOK:
      return errorPostPutItemData<IAddressBookState, IAddressBook>(lastState, ['deleteAddressBook'], action.payload);

    case AddressBookActionTypes.REQUEST_ADDRESS_BOOK_BY_ID:
      return requestItemData<IAddressBookState, IAddressBook>(lastState, ['addressBookById']);
    case AddressBookActionTypes.RECEIVE_ADDRESS_BOOK_BY_ID:
      return receiveItemData<IAddressBookState, IAddressBook>(lastState, ['addressBookById'], action.payload);
    case AddressBookActionTypes.ERROR_RECEIVE_ADDRESS_BOOK_BY_ID:
      return errorItemData<IAddressBookState, IAddressBook>(lastState, ['addressBookById'], action.payload);

    default:
      return lastState;
  }
}
