import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IConfigurationState } from '../../models/configuration.models';
import { RequestEquipmentConfiguration } from './equipment-config-action-types-creators';

@Injectable({ providedIn: 'root' })
export class EquipmentConfigActions {
  constructor(public store: Store<IConfigurationState>) {}

  public requestEquipmentConfiguration(params: any): void {
    this.store.dispatch(new RequestEquipmentConfiguration(params));
  }
}
