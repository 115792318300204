import { IEntryModel } from '../models/alarms.model';

export class AlarmsConstants {

  public static readonly incidentType: Partial<IEntryModel[]> = [
    {
      value: 'withdraw',
      label: 'withdraw',
      localizedName: 'ALARMS.STR_WITHDRAW_SIGNAL',
      severity: 2
    },
    {
      value: 'adsu',
      label: 'adsu',
      localizedName: 'ALARMS.STR_ADSU',
      severity: 1
    },
    {
      value: 'dsu',
      label: 'dsu',
      localizedName: 'ALARMS.STR_MDSU',
      severity: 1
    },
    {
      value: 'out_of_range',
      label: 'out_of_range',
      localizedName: 'ALARMS.STR_LOSS_CONNECTION',
      severity: 2
    },
    {
      value: 'thermal_exposure',
      label: 'thermal_exposure',
      localizedName: 'ALARMS.STR_THERMAL_ALARM',
      severity: 2
    },
    {
      value: 'low_battery',
      label: 'low_battery',
      localizedName: 'ALARMS.STR_LOW_BATTERY',
      severity: 2
    },
    {
      value: 'evacuation',
      label: 'evacuation',
      localizedName: 'ALARMS.STR_EVACUATION_SIGNAL',
      severity: 2
    },
    {
      value: 'low_pressure',
      label: 'low_pressure',
      localizedName: 'ALARMS.STR_LOW_PRESSURE_ALARM_1',
      severity: 1
    },
    {
      value: 'low_pressure_2_alarm',
      label: 'low_pressure_2_alarm',
      localizedName: 'ALARMS.STR_LOW_PRESSURE_ALARM_2',
      severity: 1
    }

  ];

}
