import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from 'redux';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { WorkshopService } from 'src/app/workshop/services';
import * as models from '../../../models';
import * as actions from '../../actions/equipment-history';

@Injectable()
export class EquipmentHistoryEffects {
  wokrshopService: WorkshopService = inject(WorkshopService);

  requestEquipmentHistory$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentHistoryActionTypes.REQUEST_EQUIPMENT_HISTORY),
      switchMap((aggregateId: { payload: string }) =>
        this.wokrshopService.getEquipmentHistory(aggregateId.payload).pipe(
          map((history: models.IEquipmentHistoryEvent[]) => new actions.ReceiveEquipmentHistory(history)),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveEquipmentHistory(error))),
        ),
      ),
    );
  });
}
