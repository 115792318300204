import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ignis-login-callback',
  template: '<div></div>',
})
export class LoginCallbackComponent implements AfterViewInit {
  constructor(private router: Router) {}

  ngAfterViewInit(): void {
    this.router.navigate(['']);
  }
}
