import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ModalRef, ModalService } from '@odx/angular/components/modal';
import { takeUntil, timer } from 'rxjs';
import { OnDestroyMixin } from '../../mixins/destroy-mixin';

@Component({
  selector: 'ignis-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationModalComponent extends OnDestroyMixin() implements OnChanges {
  @Input() isOpen: boolean = false;
  @Input() displayProceedBtn: boolean = false;
  @Output() handleConfirmationModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  modalReference: ModalRef<any>;
  @ViewChild('confirmationModalTemplate', { read: TemplateRef })
  public confirmationModalTemplate: TemplateRef<any>;

  constructor(private modalService: ModalService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpen && this.isOpen) {
      timer(250)
        .pipe(takeUntil(this.destroy))
        .subscribe(() => {
          this.openModal();
        });
    }
  }

  openModal(): void {
    this.modalReference = this.modalService.open(this.confirmationModalTemplate, {
      size: 'small',
      dismissable: false,
      dismissOnNavigation: false,
    });
  }

  cancelChange(): void {
    this.handleConfirmationModal.emit(false);
  }

  discardChange(): void {
    this.handleConfirmationModal.emit(true);
  }
}
