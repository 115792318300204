import { combineReducers } from '@ngrx/store';
import { IDeviceConnectionState } from '../models/device-connection.model';
import { deviceConnectionDataReducer } from './device-connection-data.reducer';
import { deviceConnectionLicenseInfoReducer } from './device-connection-license-info.reducer';
import { protectorSoftwareMigrationReducer } from './protector-software-migration.reducer';
import { ramConnectorTokenReducer } from './ram-connector-token.reducer';

export const deviceConnectionReducer: any = combineReducers<IDeviceConnectionState>({
  deviceConnectionData: deviceConnectionDataReducer,
  deviceConnectionLicenseInfo: deviceConnectionLicenseInfoReducer,
  ramConnectorData: ramConnectorTokenReducer,
  protectorSoftwareMigration: protectorSoftwareMigrationReducer,
});
