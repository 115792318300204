import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { EquipmentConfiguration, IEquipmentConfiguration } from 'src/app/configuration/models';
import { EquipmentConfigurationService } from 'src/app/configuration/services/equipment-configuration.service';
import {
  EquipmentConfigurationActionTypes,
  ErrorReceiveEquipmentConfiguration,
  ReceiveEquipmentConfiguration,
} from '../../actions/equipment-config';

@Injectable()
export class EquipmentConfigEffects {
  equipmentConfigurationService: EquipmentConfigurationService = inject(EquipmentConfigurationService);

  requestEquipmentConfiguration$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(EquipmentConfigurationActionTypes.REQUEST_EQUIPMENT_CONFIGURATION),
      switchMap((params: any) =>
        this.equipmentConfigurationService.getEquipmentConfiguration(params).pipe(
          map(
            (eqConf: { body: IEquipmentConfiguration }) =>
              new ReceiveEquipmentConfiguration(new EquipmentConfiguration(eqConf.body)),
          ),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveEquipmentConfiguration(error))),
        ),
      ),
    );
  });
}
