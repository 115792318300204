// eslint-disable-next-line no-shadow
export enum RemoteMonitoringActionTypes {
  REQUEST_MONITORING_INCIDENTS = 'REQUEST_MONITORING_INCIDENTS',
  RECEIVE_MONITORING_INCIDENTS = 'RECEIVE_MONITORING_INCIDENTS',
  ERROR_RECEIVE_MONITORING_INCIDENTS = 'ERROR_RECEIVE_MONITORING_INCIDENTS',

  REQUEST_REMOVE_INCIDENT = 'REQUEST_REMOVE_INCIDENT',
  RECEIVE_REMOVE_INCIDENT = 'RECEIVE_REMOVE_INCIDENT',
  ERROR_RECEIVE_REMOVE_INCIDENT = 'ERROR_RECEIVE_REMOVE_INCIDENT',

  REQUEST_MONITORING_INCIDENT_BY_ID = 'REQUEST_MONITORING_INCIDENT_BY_ID',
  RECEIVE_MONITORING_INCIDENT_BY_ID = 'RECEIVE_MONITORING_INCIDENT_BY_ID',
  ERROR_RECEIVE_MONITORING_INCIDENT_BY_ID = 'ERROR_RECEIVE_MONITORING_INCIDENT_BY_ID',

  REQUEST_INCIDENT_ECP_BY_ID = 'REQUEST_INCIDENT_ECP_BY_ID',
  RECEIVE_INCIDENT_ECP_BY_ID = 'RECEIVE_INCIDENT_ECP_BY_ID',
  ERROR_RECEIVE_INCIDENT_ECP_BY_ID = 'ERROR_RECEIVE_INCIDENT_ECP_BY_ID',

  REQUEST_NOTES_LIST = 'REQUEST_NOTES_LIST',
  RECEIVE_NOTES_LIST = 'RECEIVE_NOTES_LIST',
  ERROR_RECEIVE_NOTES_LIST = 'ERROR_RECEIVE_NOTES_LIST',

  REQUEST_REMOTE_MONITORING_ENTRIES = 'REQUEST_REMOTE_MONITORING_ENTRIES',
  RECEIVE_REMOTE_MONITORING_ENTRIES = 'RECEIVE_REMOTE_MONITORING_ENTRIES',
  ERROR_RECEIVE_REMOTE_MONITORING_ENTRIES = 'ERROR_RECEIVE_REMOTE_MONITORING_ENTRIES',

  REQUEST_MERGE_RM_INCIDENTS = 'REQUEST_MERGE_RM_INCIDENTS',
  RECEIVE_MERGE_RM_INCIDENTS = 'RECEIVE_MERGE_RM_INCIDENTS',
  ERROR_MERGE_RM_INCIDENTS = 'ERROR_MERGE_RM_INCIDENTS',

  REQUEST_READ_NOTES = 'REQUEST_READ_NOTES',
  RECEIVE_READ_NOTES = 'RECEIVE_READ_NOTES',
  ERROR_RECEIVE_READ_NOTES = 'ERROR_RECEIVE_READ_NOTES',

  REQUEST_ADD_NOTE = 'REQUEST_ADD_NOTE',
  RECEIVE_ADD_NOTE = 'RECEIVE_ADD_NOTE',
  ERROR_RECEIVE_ADD_NOTE = 'ERROR_RECEIVE_ADD_NOTE',
  RESET_ADD_NOTE = 'RESET_ADD_NOTE',

  SAVE_FILTER_PARAMS = 'SAVE_FILTER_PARAMS',
  RESET_FILTER_PARAMS = 'RESET_FILTER_PARAMS',
}
