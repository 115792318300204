<h2 class="mb-3" odxLayout="12">
  {{ 'EQUIPMENT_TEST.STR_EQUIPMENT_INFORMATION' | translate }}
</h2>

<div odxLayout="grid" [formGroup]="form" [readonly]="true">
  <div odxLayout="12 12@tablet 4@desktop" class="custom-form-field-pr-10">
    <odx-form-field variant="horizontal" label="{{ 'EQUIPMENT_TEST.STR_EQUIPMENT_IDENTIFICATION' | translate }}">
      <input
        type="text"
        odxFormFieldControl
        id="equipmentIdentification"
        data-test-id="equipment_task_documentation.equipment_identification"
        formControlName="identification"
      />
    </odx-form-field>

    <odx-form-field variant="horizontal" label="{{ 'EQUIPMENT_TEST.STR_EQUIPMENT_BARCODE' | translate }}">
      <input
        type="text"
        odxFormFieldControl
        id="barcode"
        data-test-id="equipment_task_documentation.equipment_barcode"
        name="barcode"
        formControlName="barcode"
      />
    </odx-form-field>

    <odx-form-field variant="horizontal" label="{{ 'EQUIPMENT_TEST.STR_EQUIPMENT_RFID' | translate }}">
      <input
        type="text"
        odxFormFieldControl
        id="rfid"
        data-test-id="equipment_task_documentation.equipment_rfid"
        name="rfid"
        formControlName="rfid"
      />
    </odx-form-field>

    <odx-form-field variant="horizontal" label="{{ 'EQUIPMENT_TEST.STR_EQUIPMENT_SERIAL_NO' | translate }}">
      <input
        type="text"
        odxFormFieldControl
        id="serialNo"
        data-test-id="equipment_task_documentation.equipment_serial_no"
        name="serialNo"
        formControlName="serialNo"
      />
    </odx-form-field>
  </div>
  <div odxLayout="12 12@tablet 4@desktop" class="custom-form-field-pl-10">
    <odx-form-field variant="horizontal" label="{{ 'EQUIPMENT_TEST.STR_EQUIPMENT_TYPE' | translate }}">
      <input
        type="text"
        odxFormFieldControl
        id="equipmentType"
        data-test-id="equipment_task_documentation.equipment_type"
        name="service"
        formControlName="equipmentType"
      />
    </odx-form-field>

    <odx-form-field
      variant="horizontal"
      label="{{ 'EQUIPMENT_TEST.STR_EQUIPMENT_MODEL' | translate }}"
      [readonly]="true"
    >
      <input
        type="text"
        odxFormFieldControl
        id="equipmentModel"
        data-test-id="equipment_task_documentation.equipment_model"
        formControlName="equipmentModel"
      />
    </odx-form-field>
  </div>
</div>

<div class="mt-4">
  <hr class="divider" />
</div>
