import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonConstants, DropdownService } from 'src/app/common';
import { extractOnlyDate } from 'src/app/common/utils';
import { IAddressBook } from 'src/app/configuration/models';
import { DatePickerService } from '../../../../common/services';

@Component({
  selector: 'ignis-manufacturer',
  templateUrl: './manufacturer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManufacturerComponent implements OnInit, OnChanges {
  @Input() equipmentForm: FormGroup;
  @Input() isSubmitted: boolean;
  @Input() formatDate: string;
  @Input() suppliers: IAddressBook[];
  @Input() disableAllFields: boolean;

  showManufacturerDateTooltip: boolean = false;
  showSupplyDateTooltip: boolean = false;
  maxDate: Date;
  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;

  extractOnlyDate: (date: string | Date) => string = extractOnlyDate;

  constructor(
    public dropdownService: DropdownService,
    public datepickerService: DatePickerService,
  ) {}

  ngOnChanges(): void {
    if (!this.disableAllFields) {
      this.equipmentForm.get('manufacturerData.manufacturerDate').enable();

      this.equipmentForm.get('supplierData').enable();

      this.equipmentForm.get('supplierData.contactName').disable();
      this.equipmentForm.get('supplierData.email').disable();
      this.equipmentForm.get('supplierData.phone').disable();
    }
  }

  ngOnInit(): void {
    this.maxDate = new Date();
  }

  supplierChanged(selectedId: string): void {
    const supplier: IAddressBook[] = this.suppliers.filter(
      (addressBook: IAddressBook) => addressBook.aggregateId === selectedId,
    );

    this.equipmentForm.get('supplierData').patchValue(supplier[0]);
  }

  onSupplierClear(): void {
    this.equipmentForm.get('supplierData').reset();

    this.equipmentForm.get('supplierData').markAsTouched();
    this.equipmentForm.get('supplierData').markAsDirty();
  }
}
