<ng-template odxModal #confirmChangeCatTypeModelModalTemplate>
  <odx-modal-hero icon="warning" variant="confirmation" data-test-id="notification.banner">
    {{ 'CHANGE_DATA_CONFIRMATION_DIALOG.STR_TITLE' | translate }}
  </odx-modal-hero>
  <odx-modal-content>
    <div [ngSwitch]="addedType">
      <span *ngSwitchCase="configType.CATEGORY" [attr.data-test-id]="configType.CATEGORY">
        {{ 'CONFIGURATION_CONFIRM_CLOSE_MODAL.STR_DESCRIPTION_CONFIGURATION_FOR_CATEGORY' | translate }}
      </span>
      <span *ngSwitchCase="configType.TYPE" [attr.data-test-id]="configType.TYPE">
        {{ 'CONFIGURATION_CONFIRM_CLOSE_MODAL.STR_DESCRIPTION_CONFIGURATION_FOR_TYPE' | translate }}
      </span>
      <span *ngSwitchCase="configType.MODEL" [attr.data-test-id]="configType.MODEL">
        {{ 'CONFIGURATION_CONFIRM_CLOSE_MODAL.STR_DESCRIPTION_CONFIGURATION_FOR_MODEL' | translate }}
      </span>
      <span *ngSwitchCase="configType.TASK" [attr.data-test-id]="configType.TASK">
        {{ 'CONFIGURATION_CONFIRM_CLOSE_MODAL.STR_DESCRIPTION_CONFIGURATION_FOR_SERVICE' | translate }}
      </span>
    </div>
  </odx-modal-content>
  <odx-modal-footer>
    <button
      type="button"
      odxButton
      odxModalClose
      variant="secondary"
      (click)="closeBanner(false)"
      data-test-id="confirm_close_modal.cancel_btn"
    >
      {{ 'CONFIRM_CLOSE_MODAL.STR_CANCEL_BTN' | translate }}
    </button>
    <button
      type="button"
      odxModalClose
      odxButton
      variant="primary"
      (click)="closeBanner(true)"
      data-test-id="confirm_close_modal.confirm_btn"
    >
      {{ 'CONFIRM_CLOSE_MODAL.STR_TITLE' | translate }}
    </button>
  </odx-modal-footer>
</ng-template>
