export interface IFireStationList {
  fireStations: string[];
}

export class FireStationList implements IFireStationList {
  fireStations: string[];

  constructor();
  constructor(fireStations: IFireStationList);
  constructor(fireStations?: IFireStationList) {
    if (!fireStations) {
      this.fireStations = null;
    } else {
      this.fireStations = fireStations.fireStations;
    }
  }
}
