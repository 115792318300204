<div class="service-result-details">
  <ignis-progress-bar *ngIf="(isLoading | async) || isLoadingPrint"></ignis-progress-bar>

  <div class="custom-odx-tab-bar odx-margin-bottom-24">
    <div class="odx-tab-bar__inner">
      <div class="odx-tab-bar__panel">
        <div class="back-btn-group ml--10">
          @if (equipmentTaskResultsForm.dirty) {
            <button
              odxNavigationBack
              class="odx-tab-bar-item"
              (click)="confirmCloseModalOpen()"
              data-test-id="equipment_task_documentation.back"
            ></button>
            <span class="odx-fs-normal"> {{ 'WORKSHOP_TAB.STR_TASK_DOCUMENTATION' | translate }}</span>
          } @else {
            <button
              odxNavigationBack
              class="odx-tab-bar-item"
              (click)="navigateBack(true)"
              data-test-id="equipment_task_documentation.back"
            ></button>
            <span class="odx-fs-normal"> {{ 'WORKSHOP_TAB.STR_TASK_DOCUMENTATION' | translate }}</span>
          }
        </div>
        <div
          [ngClass]="{
            'tab-is-active': activeTab === tabs.GENERAL,
          }"
        >
          <button
            odxButton
            #generalTabBtn
            (click)="setActiveTab(tabs.GENERAL)"
            id="testGeneralTab"
            class="odx-tab-bar-item"
            data-test-id="equipment_task_documentation.general_tab"
          >
            {{ 'EQUIPMENT_TEST.STR_EQUIPMENT_TEST_GENERAL_TAB' | translate }}
          </button>
        </div>
        <div
          [ngClass]="{
            'tab-is-active': activeTab === tabs.TEST_VALUES,
          }"
        >
          <button
            *ngIf="taskResultType !== taskResultTypesEnum.CylinderCharging"
            odxButton
            #tasksTabBtn
            (click)="setActiveTab(tabs.TEST_VALUES)"
            class="odx-tab-bar-item"
            id="taskValuesTab"
            data-test-id="equipment_task_documentation.test_values_tab"
          >
            {{ 'EQUIPMENT_TEST.STR_EQUIPMENT_TEST_VALUES_TAB' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <form [formGroup]="equipmentTaskResultsForm" *ngIf="testResultData">
    <div class="c-test-results-tabs__wrapper">
      <ngx-simplebar
        [options]="scrollbarOptions"
        [ngClass]="{ 'no-footer': !isLastResult || activeTab === tabs.TEST_VALUES }"
      >
        <div class="mt-2">
          <ignis-general-tab
            [hidden]="activeTab === tabs.TEST_VALUES"
            [barcodeUpdateInTaskFeatureToggle]="barcodeUpdateInTaskFeatureToggle"
            [equipmentForm]="equipmentTaskResultsForm"
            [testResultData]="testResultData"
            [outOfServiceDatePickerAndOtherTasksCheckboxState]="outOfServiceDatePickerAndOtherTasksCheckboxState"
            [formatDate]="formatDate"
            [dateTime]="dateTime"
            [selectedTaskName]="selectedTaskName"
            [taskData]="taskData"
            [taskResultType]="taskResultType"
            [faultedFields]="faultedFields"
            [isFromMigration]="isFromMigration"
            (handleAddedOtherTasks)="getOtherTasks($event)"
          ></ignis-general-tab>
          <ignis-values-tab
            [hidden]="activeTab === tabs.GENERAL"
            [taskValues]="taskValues"
            [checklistData]="checklistData"
            [formatDate]="formatDate"
            [testType]="testType"
          >
          </ignis-values-tab>
        </div>
      </ngx-simplebar>
    </div>
  </form>

  <footer class="blue-bar" [hidden]="activeTab !== tabs.GENERAL">
    <ignis-task-result-view-footer
      [isModalOpened]="isConfirmCloseModalOpen"
      [isSubmitDisabled]="(isSubmitting | async) || !isLastResult"
      [form]="equipmentTaskResultsForm"
      [selectedTaskName]="selectedTaskName"
      [dateTime]="dateTime"
      [formatDate]="formatDate"
      [taskResultType]="taskResultType"
      [taskData]="taskData"
      [taskValues]="taskValues"
      [checklistData]="checklistData"
      [activeTab]="activeTab"
      [isFromMigration]="isFromMigration"
      (handleSubmit)="saveTaskResultData()"
      (handleSaveFromExport)="saveAndExport()"
      (handlePrintResult)="saveAndPrint()"
    ></ignis-task-result-view-footer>
  </footer>
</div>

<ignis-confirmation-modal [isOpen]="isConfirmCloseModalOpen" (handleConfirmationModal)="tryNavigateBack($event)">
  <div slot="content">
    {{ 'CONFIRM_CLOSE_MODAL.STR_DESCRIPTION_EQUIPMENT_TEST_RESULTS' | translate }}
  </div>
</ignis-confirmation-modal>

<ignis-results-print
  *ngIf="isResultPrintStarted"
  [form]="equipmentTaskResultsForm"
  [selectedTaskName]="selectedTaskName"
  [taskData]="taskData"
  [dateTime]="dateTime"
  [isFromMigration]="testResultData.isFromMigration"
  [formatDate]="formatDate"
  [resultType]="taskResultType"
  [resultData]="preparedPrintData"
  (isLoadingDone)="handlePrint()"
  (handlePreviousPageTitle)="getPreviousPageTitle($event)"
></ignis-results-print>
