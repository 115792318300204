import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { CommonModule } from 'src/app/common/modules/common.module';
import { SettingsComponent } from '../components/settings/settings.component';

@NgModule({
  declarations: [SettingsComponent],
  imports: [
    DropdownModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
  ],
  exports: [SettingsComponent]
})
export class SettingsModule { }
