import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { IEntryModel, PropertyBag } from 'src/app/common';
import { DatePickerComponent } from 'src/app/common/components/datepicker/datepicker.component';
import { CommonConstants, NotificationConstants } from 'src/app/common/constants';
import { DatePickerService, DropdownService } from 'src/app/common/services';
import { extractOnlyDate } from 'src/app/common/utils';
import { EquipmentConfigActions } from 'src/app/configuration/state/actions';
import { AccessControlService } from 'src/app/root';
import { AppUserPermissionList } from 'src/app/root/models/app-types';
import { EquipmentNotificationConstants, WorkshopConstants } from '../../../constants';
import {
  IEquipment,
  IEquipmentCategoryList,
  IEquipmentInitialSpecification,
  IEquipmentModel,
  IEquipmentType,
} from '../../../models';
import { OutOfServiceFieldInteractionService } from '../../../services/out-of-service-field-interaction.service';

@Component({
  selector: 'ignis-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralComponent implements OnInit, OnChanges {
  @Input() errors: string;
  @Input() modalType: string;
  @Input() formatDate: string;
  @Input() isLoading: boolean;
  @Input() isSubmitted: boolean;
  @Input() equipmentForm: FormGroup;
  @Input() selectedEquipment: IEquipment;
  @Input() categoryTypeModelOptions: IEquipmentCategoryList;
  @Output() setupServices: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() markTabsWithErrors: EventEmitter<string> = new EventEmitter<string>();
  @Output() operationalStatusChange: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('inUseDatePickerComponent') inUseDatePickerComponent: DatePickerComponent;
  @ViewChild('firstRegisteredDatePicker') firstRegisteredDatePicker: DatePickerComponent;

  filteredEquipmentTypes: IEquipmentType[];
  filteredEquipmentModels: IEquipmentModel[];

  showOutOfOrderDateTooltip: boolean = false;
  uniqueIdentifierCode: string = NotificationConstants.commonCodes.IDENTIFIER_SHOULD_BE_UNIQUE.value;
  uniqueBarcodeCode: string = EquipmentNotificationConstants.notificationCodes.EQUIPMENT_BARCODE_ALREADY_EXISTS.value;
  blankBarcodeCode: string = EquipmentNotificationConstants.notificationCodes.BARCODE_CANNOT_HAVE_ONLY_SPACES.value;
  uniqueRFIDCode: string = EquipmentNotificationConstants.notificationCodes.EQUIPMENT_RFID_ALREADY_EXISTS.value;
  blankRFIDCode: string = EquipmentNotificationConstants.notificationCodes.RFID_CANNOT_HAVE_ONLY_SPACES.value;
  uniqueSerialNo: string = EquipmentNotificationConstants.notificationCodes.EQUIPMENT_SERIAL_NO_ALREADY_EXISTS.value;
  blankSerialNo: string = EquipmentNotificationConstants.notificationCodes.SERIAL_NO_CANNOT_HAVE_ONLY_SPACES.value;
  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;
  operationalStatusesList: IEntryModel[] = WorkshopConstants.operationalStatusLocalize.status;
  operationalStatuses: PropertyBag = WorkshopConstants.operationalStatuses;

  showCategoryTooltip: boolean = false;
  showEquipmentTypeTooltip: boolean = false;
  showEquipmentModelTooltip: boolean = false;

  extractOnlyDate: (date: string | Date) => string = extractOnlyDate;
  openConfirmChangeCategoryType: boolean = false;

  outOfServiceService: OutOfServiceFieldInteractionService = inject(OutOfServiceFieldInteractionService);
  dropdownService: DropdownService = inject(DropdownService);
  datepickerService: DatePickerService = inject(DatePickerService);
  accessControlService: AccessControlService = inject(AccessControlService);
  equipmentConfigActions: EquipmentConfigActions = inject(EquipmentConfigActions);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  ngOnInit(): void {
    if (WorkshopConstants.barCodeToSave in localStorage) {
      this.equipmentForm.get('generalData.barcode').patchValue(localStorage.getItem(WorkshopConstants.barCodeToSave));

      setTimeout(() => {
        this.equipmentForm.markAsDirty();
      });
    }

    this.setFilteredEquipmentSpecification();
    this.listenOperationalStatusState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.categoryTypeModelOptions || this.selectedEquipment) {
      this.setFilteredEquipmentSpecification();
    }

    if (!this.equipmentForm.get('generalData.operationalStatus').value) {
      this.equipmentForm.get('generalData.operationalStatus').setValue(this.operationalStatuses.NON_OPERATIONAL);
    }

    if (this.equipmentForm.get('generalData.outOfOrderDate').value) {
      const hasWritePermission: boolean = this.accessControlService.checkPermission(
        AppUserPermissionList.equipmentWrite,
      );

      if (!hasWritePermission) {
        this.equipmentForm.disable();

        return;
      }

      this.changeInUseDate();
    }

    this.updateOutOfServiceDateState();
  }

  updateOutOfServiceDateState(): void {
    if (
      this.equipmentForm.dirty &&
      !this.equipmentForm.get('generalData.outOfOrderDate').value &&
      this.selectedEquipment?.generalData
    ) {
      this.selectedEquipment.generalData.outOfOrderDate = null;
    }

    this.outOfServiceService.checkOutOfServiceReasonFieldState(
      this.selectedEquipment?.generalData?.outOfOrderDate ??
        (this.equipmentForm.get('generalData.outOfOrderDate').value as Date),
      this.equipmentForm,
      'generalData.outOfServiceReason',
    );
  }

  changeInUseDate(): void {
    const outOfOrderDate: string = this.equipmentForm.get('generalData.outOfOrderDate').value as string;
    const inUseDate: string = this.equipmentForm.get('generalData.inUseDate').value as string;

    if (
      outOfOrderDate !== null &&
      inUseDate !== null &&
      new Date(outOfOrderDate).valueOf() < new Date(inUseDate).valueOf()
    ) {
      this.equipmentForm.get('generalData.inUseDate').reset();

      this.outOfServiceService.changeOutOfServiceDate(
        new Date(outOfOrderDate),
        this.equipmentForm,
        'generalData.operationalStatus',
        'generalData.outOfServiceReason',
        this.inUseDatePickerComponent,
        true,
      );
    }

    if (outOfOrderDate) {
      this.outOfServiceService.changeOutOfServiceDate(
        new Date(outOfOrderDate),
        this.equipmentForm,
        'generalData.operationalStatus',
        'generalData.outOfServiceReason',
        null,
      );
    }
  }

  onIdentifierCloneChange(identifier: { target: HTMLInputElement }): void {
    const input: string = identifier.target.value?.trim();

    const prefixIndex: number = input.indexOf('_');

    if (prefixIndex) {
      const newValue: string = input.slice(prefixIndex + 1);

      this.equipmentForm.get('generalData.identifier').patchValue(newValue);
      this.onIdentifierChange({
        target: {
          value: newValue,
        },
      });
    }
  }

  onIdentifierChange(identifier: { target: { value: string } }): void {
    if (identifier.target.value?.trim()) {
      this.clearFormControlValidators('generalData.identifier');
    } else {
      this.equipmentForm.get('generalData.identifier').patchValue('');
      this.equipmentForm.get('generalData.identifier').setValidators([Validators.required]);
      this.equipmentForm.get('generalData.identifier').updateValueAndValidity();
    }

    this.markTabsWithErrors.emit();
  }

  clearFormControlValidators(formControl: string): void {
    const formValue: string = this.equipmentForm.get(formControl).value as string;

    if (formValue.length > 1) {
      this.errors = '0000';
    }

    this.equipmentForm.get(formControl).clearValidators();
    this.equipmentForm.get(formControl).updateValueAndValidity();
  }

  setFilteredEquipmentSpecification(): void {
    const selectedCategoryId: string = this.equipmentForm.get('generalData.model.category.id').value as string;
    const selectedTypeId: string = this.equipmentForm.get('generalData.model.type.id').value as string;

    this.filteredEquipmentTypes = this.categoryTypeModelOptions?.categories
      ?.filter((c: IEquipmentInitialSpecification) => !selectedCategoryId || c.id === selectedCategoryId)
      .flatMap((c: IEquipmentInitialSpecification) => c.types)
      .sort((typeA: IEquipmentType, typeB: IEquipmentType) => typeA.name.localeCompare(typeB.name));

    this.filteredEquipmentModels = this.filteredEquipmentTypes
      ?.filter((t: IEquipmentType) => !selectedTypeId || t.id === selectedTypeId)
      .flatMap((t: IEquipmentType) => t.models)
      .sort((modelA: IEquipmentModel, modelB: IEquipmentModel) => modelA.name.localeCompare(modelB.name));
  }

  operationalStatusChanged(selectedStatus: { value: string }): void {
    this.operationalStatusChange.emit(selectedStatus.value);
  }

  listenOperationalStatusState(): void {
    this.equipmentForm.get('generalData.operationalStatus').statusChanges.subscribe((status: string) => {
      if (status === 'DISABLED') {
        this.operationalStatusChange.emit(this.equipmentForm.get('generalData.operationalStatus').value as string);
      }
    });
  }

  hideTooltips(): void {
    this.showEquipmentTypeTooltip = false;
    this.showEquipmentModelTooltip = false;
  }

  categoryChanged(category: IEquipmentInitialSpecification): void {
    this.equipmentForm.get('generalData.model.category.name').setValue(category.name);
    this.equipmentForm.get('generalData.model.type').reset();
    this.equipmentForm.get('generalData.model.id').reset();
    this.equipmentForm.get('generalData.model.name').reset();
    this.setFilteredEquipmentSpecification();

    if (this.modalType === CommonConstants.modalType.UPDATE) {
      this.hideTooltips();
    }

    this.setupServices.emit(true);

    if (category.value) {
      this.equipmentConfigActions.requestEquipmentConfiguration({ urlPath: `categories/${category.value}` });
    } else {
      this.equipmentForm.get('manufacturerData').reset();
    }
  }

  typeChanged(typeId: string): void {
    this.equipmentForm.get('generalData.model.id').reset();
    this.equipmentForm.get('generalData.model.name').reset();
    const findByType: (t: IEquipmentType) => boolean = (t: IEquipmentType) => t.id === typeId;
    const assignedCategory: IEquipmentInitialSpecification = this.categoryTypeModelOptions?.categories.find(
      (c: IEquipmentInitialSpecification) => c.types.find(findByType),
    );

    if (typeId) {
      this.equipmentForm.get('generalData.model.category.id').setValue(assignedCategory?.id);
      this.filteredEquipmentModels = structuredClone(assignedCategory?.types.find(findByType).models)?.sort(
        (typeA: IEquipmentType, typeB: IEquipmentType) => typeA.name.localeCompare(typeB.name),
      );
    } else {
      this.setFilteredEquipmentSpecification();
    }

    this.setupServices.emit(true);

    if (this.modalType === CommonConstants.modalType.UPDATE) {
      this.hideTooltips();
    }

    if (typeId) {
      this.equipmentConfigActions.requestEquipmentConfiguration({
        urlPath: `categories/${assignedCategory?.id}/types/${typeId}`,
      });
    } else {
      this.equipmentForm.get('manufacturerData').reset();
    }
  }

  equipmentModelChanged(equipmentModelId: string): void {
    const findByModel: (m: IEquipmentModel) => boolean = (m: IEquipmentModel) => m.id === equipmentModelId;
    const assignedCategory: IEquipmentInitialSpecification = this.categoryTypeModelOptions?.categories.find(
      (c: IEquipmentInitialSpecification) => c.types.find((t: IEquipmentType) => t.models.find(findByModel)),
    );
    const assignedType: IEquipmentType = assignedCategory?.types.find((t: IEquipmentType) =>
      t.models.find(findByModel),
    );

    const selectedModel: IEquipmentModel = this.filteredEquipmentModels?.find(
      (m: IEquipmentModel) => m.id === equipmentModelId,
    );

    if (equipmentModelId) {
      this.equipmentForm.get('generalData').patchValue(
        {
          equipmentModel: equipmentModelId,
          model: {
            id: equipmentModelId,
            name: selectedModel?.name,
            category: {
              id: assignedCategory?.id,
              name: assignedCategory?.name,
            },
            type: {
              id: assignedType?.id,
              name: assignedType?.name,
            },
          },
        },
        { onlySelf: true, emitEvent: false },
      );

      this.equipmentForm.get('serviceIntervals').markAsDirty();

      setTimeout(() => {
        this.setFilteredEquipmentSpecification();
      }, 0);
      this.markTabsWithErrors.emit();
    } else {
      this.equipmentForm.get('generalData.model.id').reset();
      this.equipmentForm.get('generalData.model.name').reset();
    }

    this.setupServices.emit(true);

    if (equipmentModelId) {
      this.equipmentConfigActions.requestEquipmentConfiguration({
        urlPath: `categories/${assignedCategory?.id}/types/${assignedType?.id}/models/${equipmentModelId}`,
      });
    } else {
      this.equipmentForm.get('manufacturerData').reset();
    }

    this.cdr.detectChanges();
  }

  resetOutOfOrderDateError(): void {
    if (
      this.errors &&
      this.errors.toString() ===
        EquipmentNotificationConstants.notificationCodes.NOT_CHRONOLOGICAL_IN_USE_OUT_OF_ORDER.value
    ) {
      this.errors = null;

      this.equipmentForm.get('generalData.outOfOrderDate').clearValidators();
      this.equipmentForm.get('generalData.outOfOrderDate').updateValueAndValidity();
    }
  }

  makeIdentifierCloneRequired(): void {
    this.equipmentForm.get('generalData.identifierClone').removeValidators([Validators.required]);

    if (this.equipmentForm.get('generalData.identifierClone').value) {
      this.equipmentForm.get('generalData.identifierClone').addValidators([Validators.required]);
    }

    this.equipmentForm.get('generalData.identifierClone').updateValueAndValidity();
  }
}
