import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TableFilterType } from 'src/app/common/constants/table.constants';
import { PropertyBag } from 'src/app/common/models';
import { IDatePickerTableFilter, ITableColumn } from 'src/app/common/models/table.model';

@Component({
  selector: 'ignis-table-datepicker-filter',
  templateUrl: './table-datepicker-filter.component.html',
  styleUrls: ['./table-datepicker-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableDatepickerFilterComponent {
  @Input() column: ITableColumn;
  @Input() customFilter: string;
  @Input() datePickerFilterOptions: IDatePickerTableFilter;

  filterType: PropertyBag = TableFilterType;

  @Output() handleDatePickerFilterValue: EventEmitter<{ date: Date[]; field: string }> = new EventEmitter<{
    date: Date[];
    field: string;
  }>();
  @Output() handleDatePickerFilterChangedValue: EventEmitter<string> = new EventEmitter<string>();

  onDateSelect(date: Date[]): void {
    this.handleDatePickerFilterValue.emit({ date, field: this.column?.field });
  }

  onDateChange(): void {
    this.handleDatePickerFilterChangedValue.emit(this.column?.field);
  }
}
