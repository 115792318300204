import { GenericAction } from 'src/app/common';
import { errorItemData, receiveItemData, requestItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { AccessControlItemsActionType } from '../actions/access-control-items-types';
import { AccessControlItemsState, IAccessControlItemsState } from '../models/access-control-items.model';

export function accessControlItemsReducer(
  lastState: IAccessControlItemsState = new AccessControlItemsState(),
  action: GenericAction<AccessControlItemsActionType, any>
): IAccessControlItemsState {
  switch (action.type) {
    case AccessControlItemsActionType.REQUEST_ACCESS_CONTROL_ITEMS:
      return requestItemData<IAccessControlItemsState, IAccessControlItemsState>(lastState, ['accessControlItems']);

    case AccessControlItemsActionType.RECEIVE_ACCESS_CONTROL_ITEMS:
      return receiveItemData<IAccessControlItemsState, IAccessControlItemsState>(lastState, ['accessControlItems'], action.payload);

    case AccessControlItemsActionType.ERROR_RECEIVE_ACCESS_CONTROL_ITEMS:
      return errorItemData<IAccessControlItemsState, IAccessControlItemsState>(lastState, ['accessControlItems'], action.payload);

    default:
      return lastState;
  }
}
