import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IIncidentsIds } from 'src/app/remote-monitoring/models/remote-monitoring.model';
import { RemoteMonitoringActionTypes } from '../remote-monitoring-action-types';

export class RequestMergeIncidents implements Action {
  readonly type: RemoteMonitoringActionTypes.REQUEST_MERGE_RM_INCIDENTS =
    RemoteMonitoringActionTypes.REQUEST_MERGE_RM_INCIDENTS;

  constructor(
    public payload: IIncidentsIds,
    public incidentVersion: number,
  ) {}
}
export class ReceiveMergeIncidents implements Action {
  readonly type: RemoteMonitoringActionTypes.RECEIVE_MERGE_RM_INCIDENTS =
    RemoteMonitoringActionTypes.RECEIVE_MERGE_RM_INCIDENTS;

  constructor(public payload: any) {}
}
export class ErrorMergeIncidents implements Action {
  readonly type: RemoteMonitoringActionTypes.ERROR_MERGE_RM_INCIDENTS =
    RemoteMonitoringActionTypes.ERROR_MERGE_RM_INCIDENTS;

  constructor(public payload: HttpErrorResponse) {}
}
