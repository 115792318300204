<div class="blue-bar-items">
  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="create-address-book-item"
    id="create-address-book-btn"
    (click)="onCreateClick($event)"
    [disabled]="selectedItem || isModalOpened"
    data-test-id="configuration.create_address_book_btn"
    [pTooltip]="addButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #addButtonTooltipContent>
      <div data-test-id="configuration.add_new_address_book_tooltip">
        {{ 'CONFIGURATION.STR_ADD_NEW_ADDRESS_BOOK_TOOLTIP' | translate }}
      </div>
    </ng-template>

    <odx-icon size="inline" name="add"></odx-icon>
  </button>

  <button
    type="button"
    [disabled]="!selectedItem || isModalOpened"
    (click)="onEditClick($event)"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="edit-address-book-item"
    id="edit-address-book-btn"
    data-test-id="configuration.edit_address_book_btn"
    [pTooltip]="editButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #editButtonTooltipContent>
      <div data-test-id="configuration.edit_address_book_tooltip">
        {{ 'CONFIGURATION.STR_EDIT_ADDRESS_BOOK_TOOLTIP' | translate }}
      </div>
    </ng-template>

    <odx-icon size="inline" name="edit"></odx-icon>
  </button>

  <button
    type="button"
    [disabled]="!selectedItem || isModalOpened"
    (click)="onDeleteClick($event)"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="delete-address-book-item"
    id="delete-address-book-btn"
    data-test-id="configuration.delete_address_book_btn"
    [pTooltip]="deleteButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #deleteButtonTooltipContent>
      <div data-test-id="configuration.delete_address_book_tooltip">
        {{ 'CONFIGURATION.STR_DELETE_ADDRESS_BOOK_TOOLTIP' | translate }}
      </div>
    </ng-template>

    <odx-icon name="delete"></odx-icon>
  </button>
</div>
