import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as d3 from 'd3';
import { IEntryModel } from 'src/app/common/models/common.model';
import { checkDuration, dateFormatWithSeconds } from 'src/app/common/utils/date-utils/date.utils';
import { RemoteMonitoringConstants } from 'src/app/remote-monitoring/constants/remote-monitoring.constants';
import { ReportCenterConstants } from 'src/app/report-center/constants/report-center.constants';
import { AlarmChartData } from 'src/app/report-center/models/incident.model';
import { ChartHelper, ChartOptions } from '../../../../helpers/chart-helper';

@Component({
  selector: 'ignis-event-timeline',
  templateUrl: './event-timeline.component.html',
  styleUrls: ['./event-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventTimelineComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('chart')
  public chart: ElementRef;

  @Input() alarmData: AlarmChartData[] = [];
  @Input() startDate: Date = new Date();
  @Input() endDate: Date = new Date();
  @Input() numberEcps: number;
  @Input() currentEcpIndex: number;
  @Input() formatDate: string;
  @Input() ecpName: string;
  @Input() timelineWidth: number;
  @Input() alarmLines: number;
  @Input() timelineList: HTMLElement;
  @Input() activateClickOutside: boolean;

  @Output() getManualEvent: EventEmitter<any> = new EventEmitter();

  alarmHeight: number = 15;
  mediaAssetWidth: number = 15;
  minAlarmWidth: number = 15;
  alarmPadding: number = 5;
  defaultTickLength: number = 15;
  accordionHeaderHeight: number = 58;
  chartHeight: number;
  lastChartHeight: number;
  chartHelper: any;
  options: ChartOptions;

  selectedManualEvent: any;

  x0: any;
  yScaleMap: any = {};
  svg: any;
  x0Axis: any;

  tooltip: any;
  scrollbarHeight: number = 20;

  constructor(
    private translateService: TranslateService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.x0 = this.chartHelper.createXTimeScale([this.startDate, this.endDate]);
  }

  ngOnChanges(): void {
    this.initializeChart();
  }

  ngAfterViewInit(): void {
    this.router.navigateByUrl(window.location.pathname, { skipLocationChange: true }).then(() => {
      this.router.navigate([window.location.pathname]);
      this.drawTimeline();
      this.showHideIncidentTimelineShadow();
    });
  }

  initializeChart(): void {
    this.options = {
      width: this.timelineWidth,
      height: this.alarmLines * this.alarmHeight * 2 + this.alarmHeight,
      minWidth: 0,
      minHeight: 0,
      margin: { top: 0, right: 0, bottom: 0, left: 0 },
    };

    this.chartHelper = new ChartHelper(this.options);
  }

  /* istanbul ignore next */
  drawTimeline(): void {
    this.svg = this.chartHelper?.appendSvg(this.chart);
    this.svg.attr('width', this.timelineWidth).attr('viewBox', [0, 0, this.timelineWidth, this.options.height]);

    if (this.numberEcps === this.currentEcpIndex) {
      this.drawAxis();
    }

    this.alarmData.forEach((alarmData: AlarmChartData, index: number) => {
      if (this.alarmData[index] && this.alarmData[index - 1]) {
        if (
          this.chartHelper.compareOverlappingAlarmDate(
            new Date(this.alarmData[index].entries[0].start),
            new Date(this.alarmData[index].entries[0].end),
            new Date(this.alarmData[index - 1].entries[0].start),
            new Date(this.alarmData[index - 1].entries[0].end),
          )
        ) {
          this.alarmData[index].isOverlap = true;
          this.alarmData[index].lineIndex = index;
        }
      }

      this.drawAlarms(alarmData, index);
    });

    this.drawFullHourMark();
    this.addToolTip();
  }

  /* istanbul ignore next */
  drawFullHourMark(): void {
    const currentChart: HTMLElement = this.timelineList?.childNodes[this.currentEcpIndex - 1] as HTMLElement;
    let currentChartHeight: number = currentChart?.offsetHeight;

    const ticks: Date[] = this.chartHelper
      .calculateTickValues(this.startDate, this.endDate)
      .filter((tick: Date) => tick !== this.startDate);
    const fullHourValues: Date[] = ticks.filter((tick: Date) =>
      Number.isInteger((tick.getTime() - this.startDate.getTime()) / 1000 / 60 / 60),
    );

    if (this.currentEcpIndex === this.numberEcps) {
      currentChartHeight = currentChart?.offsetHeight + 4 - this.alarmPadding * 2 - this.alarmHeight;
    }

    fullHourValues.forEach((fullHour: Date, index: number) => {
      this.svg
        .append('svg:line')
        .attr('class', 'full-hour')
        .attr('transform', `translate(0,${0 - this.alarmHeight})`)
        .attr('x1', this.x0(fullHour))
        .attr('y1', currentChartHeight)
        .attr('x2', this.x0(fullHour))
        .attr('y2', 0)
        .attr('stroke', ReportCenterConstants.alarmColors.FULL_HOUR_LINE)
        .attr('stroke-width', '3px')
        .attr('date', fullHour)
        .attr('index', index);
    });

    this.initializeFullHourTooltip();
  }

  /* istanbul ignore next */
  drawChartShadows(): void {
    const leftShadow: HTMLElement = document.getElementById('incident-timeline-left-shadow');
    const rightShadow: HTMLElement = document.getElementById('incident-timeline-right-shadow');

    if (leftShadow || rightShadow) {
      leftShadow.style.height = `calc(100% - ${this.accordionHeaderHeight}px)`;
      rightShadow.style.height = `calc(100% - ${this.accordionHeaderHeight}px)`;
    }
  }

  /* istanbul ignore next */
  drawAxis(): void {
    const lastChart: HTMLElement = this.timelineList?.childNodes[this.numberEcps - 1] as HTMLElement;

    this.x0 = this.chartHelper.createXTimeScale([this.startDate, this.endDate]);

    this.chartHeight = this.timelineList?.offsetHeight;
    this.lastChartHeight = lastChart?.offsetHeight - this.scrollbarHeight;

    const tickSize: number = this.chartHeight;

    this.drawChartShadows();

    const chartTimeFormat: string = '%H:%M:%S';

    this.x0Axis = this.svg
      .append('g')
      .classed('x-axis', true)
      .style('font-size', '16px')
      .style('color', ReportCenterConstants.alarmColors.X_AXIS_COLOR)
      .attr('transform', `translate(0, ${0 - this.chartHeight + this.lastChartHeight - this.alarmHeight})`)
      .call(
        d3
          .axisBottom()
          .tickFormat(d3.timeFormat(chartTimeFormat))
          .tickValues(this.chartHelper.calculateTickValues(this.startDate, this.endDate))
          .tickSize(tickSize)
          .scale(this.x0),
      );
  }
  /* istanbul ignore next */
  addToolTip(): void {
    this.tooltip = d3.select('body').append('div')?.style('display', 'none').classed(`timeline-tooltip`, true);

    const tooltips: Element[] = Array.from(document.getElementsByClassName('timeline-tooltip'));

    tooltips.forEach((tooltip: Element) => {
      (tooltip as HTMLElement).style.display = 'none';
    });
  }

  /* istanbul ignore next */
  initializeFullHourTooltip(): void {
    d3.selectAll('svg>line')
      .on('touchmove mousemove', (event: any) => {
        const attrs: HTMLElement = event.target as HTMLElement;
        const dateToShow: string = dateFormatWithSeconds(attrs.getAttribute('date'), this.formatDate);
        const alarmIndex: number = parseInt(attrs.getAttribute('index'), 10) + 1;
        const tooltipData: any = { dateToShow, alarmIndex };

        this.showFullHourTooltip(event, tooltipData);
      })
      .on('touchend mouseleave', () => this.tooltip?.style('display', 'none'));
  }

  /* istanbul ignore next */
  drawAlarms(alarmData: AlarmChartData, alarmIndex: number): void {
    const alarmWidthCorrection: number = 0.5;
    // eslint-disable-next-line no-prototype-builtins
    const lineIndex: number = alarmData.hasOwnProperty('lineIndex') ? alarmData.lineIndex : alarmIndex;

    this.yScaleMap[lineIndex] = d3
      .scaleLinear()
      .domain([0, 1])
      .range([this.alarmHeight * 2 * lineIndex, this.alarmHeight * lineIndex]);

    const alarm: any = this.svg.append('g');

    alarm
      .attr('fill', alarmData.color)
      .attr('pointer-events', 'all')
      .selectAll('rect')
      .data(alarmData.entries)
      .join('rect')
      .attr('x', (d: any) => this.x0(d.start) + alarmWidthCorrection)
      .attr('y', !isNaN(this.yScaleMap[lineIndex](1)) ? this.yScaleMap[lineIndex](1) : 0)
      .attr('width', (d: any) =>
        Math.max(this.x0(d.end) - this.x0(d.start) - alarmWidthCorrection * 2, this.minAlarmWidth),
      )
      .attr('height', this.alarmHeight)
      .on('touchmove mousemove', (event: any) => this.showAlarmTooltip(alarmData, event, alarm.node()))
      .on('touchend mouseleave', () => this.tooltip?.style('display', 'none'));

    if (alarmData.isManualEvent) {
      alarm
        .on('click', (event: any) =>
          this.createManualEvent(event, alarmData.id, alarm, alarmData.borderColor, alarmData.name),
        )
        .style('cursor', 'pointer')
        .attr('id', alarmData.id);
    } else {
      alarm.attr('stroke-width', '1px').attr('stroke', alarmData.borderColor);
    }

    if (lineIndex !== 0) {
      alarm?.style('transform', `translate(0px, ${(this.alarmHeight - this.alarmPadding) * lineIndex}px)`);
    }

    if (alarmData.isOverlap && !lineIndex) {
      alarm?.style('transform', `translate(0px, ${this.alarmHeight - this.alarmPadding + this.alarmHeight}px)`);
    }
  }

  /* istanbul ignore next */
  createManualEvent(event: PointerEvent, id: string, alarm?: any, borderColor?: string, alarmName?: string): void {
    event.stopPropagation();
    this.selectedManualEvent?.attr('stroke-width', '0px');

    if (alarm) {
      this.selectedManualEvent = alarm;
      alarm.style('stroke-width', '2px').style('stroke', borderColor);
    } else {
      this.selectedManualEvent?.style('stroke-width', '0px');
    }

    this.getManualEvent.emit({ id, alarmName });
  }

  /* istanbul ignore next */
  showAlarmTooltip(alarmData: AlarmChartData, event: any, node: any): void {
    const { start, end }: any = this.chartHelper.bisector(d3.pointer(event, node), alarmData.entries, this.x0);
    const diff: any = end.getTime() - start.getTime();
    const duration: any = checkDuration(Math.floor(diff / 1000));

    if (alarmData.isManualEvent) {
      this.initializeManualEventTooltip(alarmData, event, start, end, duration);
    } else if (alarmData.isMediaAsset) {
      this.initializeMediaAssetTooltip(alarmData, event, start);
    } else if (alarmData.isEventMarkerCluster) {
      this.initializeEventMarkerClusterTooltip(alarmData, event, start);
    } else {
      this.initializeAlarmTooltip(alarmData, event, start, end, duration);
    }
  }

  /* istanbul ignore next */
  initializeManualEventTooltip(alarmData: AlarmChartData, event: any, start: string, end: string, duration: any): void {
    let typeName: string;

    if (alarmData.type) {
      typeName = this.translateService.instant(
        ReportCenterConstants.incidentEntries.eventMarkerTypes.find((t: IEntryModel) => t.value === alarmData.type)
          ?.localizedName || alarmData.type,
      );
    } else {
      typeName = null;
    }

    let displayedTypeName: string;

    if (!typeName) {
      displayedTypeName = '';
    } else {
      displayedTypeName = `: ${typeName}`;
    }

    const [tooltipWidth, tooltipHeight]: any = this.chartHelper.showTooltip(
      this.tooltip,
      `
        <b>${this.translateService.instant('EVENT_MARKER.MANUAL_EVENT')}${displayedTypeName}</b><br>
        ${this.translateService.instant('EVENT_MARKER.EVENT_NAME')}: ${alarmData.name}<br>
        ${this.translateService.instant('INCIDENT_ANALYSIS.STR_START')}: ${dateFormatWithSeconds(
          start,
          this.formatDate,
        )}<br>
        ${this.translateService.instant('INCIDENT_ANALYSIS.STR_END')}: ${dateFormatWithSeconds(
          end,
          this.formatDate,
        )}<br>
        ${this.translateService.instant('INCIDENT_EVENT_TIMELINE.STR_DURATION')}: ${duration ? duration : '-'}
      `,
    );

    this.tooltip
      ?.style('top', `${event.pageY - tooltipHeight - this.alarmHeight}px`)
      ?.style('left', `${event.pageX - tooltipWidth / 2}px`);
  }

  /* istanbul ignore next */
  initializeEventMarkerClusterTooltip(alarmData: AlarmChartData, event: any, start: string): void {
    const translatedTeamStatus: string = this.translateService.instant(
      RemoteMonitoringConstants.teamStatuses.find((t: any) => t.value === alarmData.status)?.localizedName ||
        alarmData.status,
    );

    const [tooltipWidth, tooltipHeight]: any = this.chartHelper.showTooltip(
      this.tooltip,
      `
      <div class="event-timeline-tooltip-container" data-test-id="event_timeline.tooltip">
        <div class="tooltip-icon event-marker-cluster-icon-wrap">
          <span class="odx-icon" data-icon-set="uib-legacy" data-icon-name="firefighter-arrived">
            <span></span>
          </span>
        </div>
        <div class="tooltip-content">
          <b>${this.translateService.instant('EVENT_MARKER_CLUSTER.STR_TEAM_STATUS_CHANGE')}</b><br>
          ${this.translateService.instant('EVENT_MARKER_CLUSTER.STR_TEAM_NAME')}: ${
            alarmData.name ? alarmData.name : '-'
          }<br>
          ${this.translateService.instant('EVENT_MARKER_CLUSTER.STR_NEW_STATUS')}: ${
            alarmData.status ? translatedTeamStatus : '-'
          }<br>
          ${this.translateService.instant('EVENT_MARKER_CLUSTER.STR_OCCURRED')} ${dateFormatWithSeconds(
            start,
            this.formatDate,
          )}<br>
      </div>
      `,
    );

    this.chartHelper.increaseTooltipContentMarginLeft(
      tooltipWidth,
      225,
      'event-timeline-tooltip-container',
      'increase-event-timeline-tooltip-margin',
    );

    this.tooltip
      ?.style('top', `${event.pageY - tooltipHeight - this.alarmHeight}px`)
      ?.style('left', `${event.pageX - tooltipWidth / 2}px`);
  }

  /* istanbul ignore next */
  initializeMediaAssetTooltip(alarmData: AlarmChartData, event: any, start: string): void {
    const [tooltipWidth, tooltipHeight]: any = this.chartHelper.showTooltip(
      this.tooltip,
      `
        <b>${this.translateService.instant('INCIDENT_ANALYSIS.MEDIA_ASSET_TITLE')}</b><br>
        ${this.translateService.instant('INCIDENT_ANALYSIS.USER_NAME')}: ${alarmData.name ? alarmData.name : '-'}<br>
        ${this.translateService.instant('INCIDENT_ANALYSIS.DEVICE_CONNECTION_NAME')}: ${
          alarmData.uploaderName ? alarmData.uploaderName : '-'
        }<br>
        ${this.translateService.instant('INCIDENT_ANALYSIS.STR_CREATED')}: ${dateFormatWithSeconds(
          start,
          this.formatDate,
        )}<br>
      `,
    );

    this.tooltip
      ?.style('top', `${event.pageY - tooltipHeight - this.alarmHeight}px`)
      ?.style('left', `${event.pageX - tooltipWidth / 2}px`);
  }

  /* istanbul ignore next */
  initializeAlarmTooltip(alarmData: AlarmChartData, event: any, start: string, end: string, duration: any): void {
    const typeName: string = this.translateService.instant(
      ReportCenterConstants.incidentEntries.alarmTypes.find((t: IEntryModel) => t.value === alarmData.type)
        ?.localizedName || alarmData.type,
    );

    const alarmIcon: string = this.alarmData.find((alData: AlarmChartData) => alData.type === alarmData.type).icon;

    const [tooltipWidth, tooltipHeight]: any = this.chartHelper.showTooltip(
      this.tooltip,
      `
        <div class="event-timeline-tooltip-container" data-test-id="event_timeline.tooltip">
          <div class="tooltip-icon">
            ${alarmIcon}
          </div>
          <div class="tooltip-content">
            <b>${typeName}</b><br>
            ${this.translateService.instant('INCIDENT_EVENT_TIMELINE.STR_SCBA_ID')}: ${alarmData.equipmentId}<br>
            ${this.translateService.instant('INCIDENT_EVENT_TIMELINE.STR_FIREFIGHTER')}: ${
              alarmData?.firefighter ? alarmData.firefighter : '-'
            }<br>
            ${this.translateService.instant('INCIDENT_ANALYSIS.STR_START')}: ${dateFormatWithSeconds(
              start,
              this.formatDate,
            )}<br>
            ${this.translateService.instant('INCIDENT_ANALYSIS.STR_END')}: ${dateFormatWithSeconds(
              end,
              this.formatDate,
            )}<br>
            ${this.translateService.instant('INCIDENT_EVENT_TIMELINE.STR_DURATION')}: ${duration}
          </div>
       </div>
      `,
    );

    this.tooltip
      ?.style('top', `${event.pageY - tooltipHeight - this.alarmHeight}px`)
      ?.style('left', `${event.pageX - tooltipWidth / 2}px`);
  }

  /* istanbul ignore next */
  showFullHourTooltip(event: any, data: any): void {
    const [width, height]: any = this.chartHelper.showTooltip(
      this.tooltip,
      `
        <b>${data.alarmIndex + ' ' + this.translateService.instant('INCIDENT_EVENT_TIMELINE.STR_HOUR_MARK')}</b><br>
       ${this.translateService.instant('INCIDENT_EVENT_TIMELINE.STR_TIME')}: ${data.dateToShow}
      `,
    );

    this.tooltip
      ?.style('top', `${event.pageY - height - this.alarmHeight}px`)
      ?.style('left', `${event.pageX - width / 2}px`);
  }

  /* istanbul ignore next */
  showHideIncidentTimelineShadow(): void {
    const list: any = this.timelineList;
    const leftShadow: HTMLElement = document.getElementById('incident-timeline-left-shadow');
    const rightShadow: HTMLElement = document.getElementById('incident-timeline-right-shadow');

    if (leftShadow && rightShadow) {
      rightShadow.style.right = '0px';

      this.chartHelper.manageChartShadows(list, leftShadow, rightShadow);
    }
  }
}
