import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ICreateUpdateBAWearer, IDeleteBAWearer } from '../../models';
import { IBAWearerState } from '../models/ba-wearer.model';
import {
  RequestBAWearerById,
  RequestCreateBAWearer,
  RequestDeleteBAWearer,
  RequestUpdateBAWearer,
} from './ba-wearer-action-types-creators/ba-wearer-crud-action-types-creators';
import { RequestUploadCSVFile } from './ba-wearer-action-types-creators/ba-wearer-csv-file-action-types-creators';
import { RequestFireStationList } from './ba-wearer-action-types-creators/ba-wearer-fire-station-action-types-creators';
import { RequestBAWearerPage } from './ba-wearer-action-types-creators/ba-wearer-page-action-types-creators';

@Injectable({ providedIn: 'root' })
export class BAWearerActions {
  constructor(private store: Store<IBAWearerState>) {}

  public requestBAWearerPage(params?: any): void {
    this.store.dispatch(new RequestBAWearerPage(params));
  }

  public requestBAWearerById(id: string): void {
    this.store.dispatch(new RequestBAWearerById(id));
  }

  public requestCreateBAWearer(newBAWearer: ICreateUpdateBAWearer): void {
    this.store.dispatch(new RequestCreateBAWearer(newBAWearer));
  }

  public requestUpdateBAWearer(updatedBAWearer: ICreateUpdateBAWearer): void {
    this.store.dispatch(new RequestUpdateBAWearer(updatedBAWearer));
  }

  public requestDeleteBAWearer(deleteBAWearer: IDeleteBAWearer): void {
    this.store.dispatch(new RequestDeleteBAWearer(deleteBAWearer));
  }

  public requestFireStationList(): void {
    this.store.dispatch(new RequestFireStationList());
  }

  public uploadCSVFile(file: FormData): void {
    this.store.dispatch(new RequestUploadCSVFile(file));
  }
}
