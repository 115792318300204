import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { filter } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CommonConstants, IScrollOptions, IStoreApiItem, PropertyBag } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { ApplicationState } from 'src/app/common/state/models/app.state.model';
import { dateFormat, formatLocaleTime, getTimeFromSeconds } from 'src/app/common/utils/date-utils/date.utils';
import { getDateFormatFromUserProfile } from 'src/app/common/utils/settings-utils/settings-utils';
import { RemoteMonitoringNotificationConstants } from '../../constants';
import { RemoteMonitoringModuleRoutes } from '../../constants/remote-monitoring-module-routes.constants';
import { RemoteMonitoringConstants } from '../../constants/remote-monitoring.constants';
import { IIncident } from '../../models/remote-monitoring.model';
import { RemoteMonitoringActions } from '../../state/actions/remote-monitoring.actions';
import { selectIncident, selectNotesList, selectReadNotes } from '../../state/selectors/remote-monitoring.selector';
import { MapService } from './../../../common/services/map/map.service';
import { IEcp, INote } from './../../models/remote-monitoring.model';

@Component({
  selector: 'ignis-incident-overview',
  templateUrl: './incident-overview.component.html',
  styleUrls: ['./incident-overview.component.scss'],
})
export class IncidentOverviewComponent extends OnDestroyMixin() implements OnInit, AfterViewInit {
  selectedIncident: IIncident;
  incidentId: string;
  incidentVersion: number;

  getTime: any = getTimeFromSeconds;
  isMapView: boolean[] = [];

  statuses: any = RemoteMonitoringConstants.incidentStatusTypes;
  intervalToGetIncident: number | any;
  intervalsArr: number[];

  openNotesWidget: boolean = false;
  notes: INote[];
  formatDate: string;
  newNotesNumber: number;
  ecpIdentifier: string;

  showConnectionInformationTooltip: boolean[] = [];
  readonly RemoteMonitoringModuleRoutes: PropertyBag = RemoteMonitoringModuleRoutes;

  dateFormat: any = dateFormat;
  selectedEcp: IEcp;
  scrollbarOptions: IScrollOptions = CommonConstants.scrollbarOptions;
  currentAppTheme: string;

  private scrollbarIntervalId: any;
  scrollbarIsDisplayed: boolean = false;

  public remoteMonitoringActions: RemoteMonitoringActions = inject(RemoteMonitoringActions);
  public mapService: MapService = inject(MapService);
  private cookieService: CookieService = inject(CookieService);
  public notificationsService: NotificationsService = inject(NotificationsService);
  translateService: TranslateService = inject(TranslateService);

  constructor(
    private store: Store<ApplicationState>,
    public router: Router,
    public route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super();

    this.intervalsArr = [];

    this.router.events?.subscribe(() => {
      if (!this.router.url.includes(RemoteMonitoringModuleRoutes.mapView)) {
        this.openNotesWidget = false;
      }
    });

    this.store.pipe(select(selectReadNotes), takeUntil(this.destroy)).subscribe((response: IStoreApiItem<any>) => {
      if (response.data !== null && !response.errors && this.incidentId) {
        this.remoteMonitoringActions.requestNotesList(this.incidentId);
      }
    });

    this.currentAppTheme = this.document.body.className.split(' ')[1];
  }

  ngOnInit(): void {
    this.formatDate = getDateFormatFromUserProfile(this.translateService);
    this.checkForScrollbarElement();
  }

  ngAfterViewInit(): void {
    this.getIncident();

    this.store.pipe(select(selectIncident), takeUntil(this.destroy)).subscribe((response: any) => {
      if (response.data) {
        this.selectedIncident = structuredClone(response.data);
        this.incidentId = this.selectedIncident.aggregateId;
        this.incidentVersion = this.selectedIncident.version;
      } else {
        if (
          response.errors?.error.code.toString() ===
          RemoteMonitoringNotificationConstants.notificationCodes.ACCESS_UNAVAILABLE_INCIDENT.value
        ) {
          setTimeout(() => {
            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.WARNING,
              RemoteMonitoringNotificationConstants.notificationCodes.ACCESS_UNAVAILABLE_INCIDENT,
              RemoteMonitoringNotificationConstants.notificationCodes,
            );
          }, 250);

          setTimeout(() => {
            this.router.navigate([RemoteMonitoringModuleRoutes.remoteMonitoring]);
          }, CommonConstants.DEFAULT_REDIRECT_TIMEOUT);
        }
      }
    });

    this.store
      .pipe(
        select(selectNotesList),
        filter((selectedNotes: IStoreApiItem<INote[]>) => !selectedNotes.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((notesList: IStoreApiItem<INote[]>) => {
        if (notesList.data) {
          this.notes = notesList.data;
          const allNotes: INote[] = structuredClone(this.notes);

          allNotes?.forEach((note: INote) => {
            note.time = formatLocaleTime(new Date(note.timestamp));
          });

          this.notes = allNotes;
        }
      });
  }

  checkForScrollbarElement(): void {
    this.scrollbarIntervalId = setInterval(() => {
      const elements: HTMLCollectionOf<Element> = document.getElementsByClassName(
        'sidebar-scroll simplebar-scrollable-y',
      );

      this.scrollbarIsDisplayed = false;

      if (elements.length === 1) {
        this.scrollbarIsDisplayed = true;
      }
    }, 100);
  }

  getIncident(): void {
    this.route.params.pipe(take(1)).subscribe((ecpParams: Params) => {
      const aggregateId: string = ecpParams.id;

      this.remoteMonitoringActions.requestIncidentById(aggregateId);

      if (aggregateId) {
        this.remoteMonitoringActions.requestNotesList(aggregateId);
      }

      this.intervalToGetIncident = setInterval(() => {
        const incidentIdFromUrlForMonitorTeamsAndMapView: string = window.location.href.split('/').slice(-4)[0];
        const incidentIdFromUrlForOverviewPage: string = window.location.href
          .split('/')
          .slice(-1)[0]
          .replace('(modal:settings)', '');

        if (this.router.url.includes(RemoteMonitoringModuleRoutes.monitorTeams)) {
          this.remoteMonitoringActions.requestIncidentById(incidentIdFromUrlForMonitorTeamsAndMapView);
        }

        if (
          this.router.url.includes(RemoteMonitoringModuleRoutes.incidentOverview) &&
          !this.router.url.includes(RemoteMonitoringModuleRoutes.monitorTeams)
        ) {
          this.remoteMonitoringActions.requestIncidentById(incidentIdFromUrlForOverviewPage);
        }

        if (this.router.url.includes(RemoteMonitoringModuleRoutes.mapView)) {
          this.remoteMonitoringActions.requestIncidentById(incidentIdFromUrlForMonitorTeamsAndMapView);
        }

        if (!this.openNotesWidget && aggregateId) {
          this.remoteMonitoringActions.requestNotesList(aggregateId);
        }
      }, 5000);

      this.intervalsArr.push(this.intervalToGetIncident);

      if (this.intervalsArr.length > 1) {
        clearInterval(this.intervalsArr[0]);
        this.intervalsArr.shift();
      }
    });
  }

  getEcpIndex(index: number): string {
    if (index + 1 < 10) {
      return `0${index + 1}`;
    }

    return (index + 1).toString();
  }

  switchToMapView(event: PointerEvent, ecp: IEcp): void {
    event.stopPropagation();
    this.router.navigate([
      RemoteMonitoringModuleRoutes.remoteMonitoring,
      RemoteMonitoringModuleRoutes.incidentOverview,
      this.selectedIncident.aggregateId,
      RemoteMonitoringModuleRoutes.ecp,
      ecp.ecpId,
      RemoteMonitoringModuleRoutes.mapView,
    ]);
  }

  switchToMonitorTeams(event: PointerEvent, ecp: IEcp): void {
    event.stopPropagation();
    this.router.navigate([
      RemoteMonitoringModuleRoutes.remoteMonitoring,
      RemoteMonitoringModuleRoutes.incidentOverview,
      this.selectedIncident.aggregateId,
      RemoteMonitoringModuleRoutes.ecp,
      ecp.ecpId,
      RemoteMonitoringModuleRoutes.monitorTeams,
    ]);
  }

  checkActiveEcp(ecp: IEcp): boolean {
    if (!this.router.url.includes(`ecp/${ecp.ecpId.replace(' ', '%20')}`)) {
      return true;
    }

    return false;
  }

  checkActiveMapPage(ecp: IEcp): boolean {
    return !this.router.url.includes(`${ecp.ecpId.replace(' ', '%20')}/monitor-teams`);
  }

  onEcpCardClick(event: PointerEvent, ecp: IEcp): void {
    this.selectedEcp = ecp;

    event.stopPropagation();
    this.router.navigate([
      RemoteMonitoringModuleRoutes.remoteMonitoring,
      RemoteMonitoringModuleRoutes.incidentOverview,
      this.selectedIncident.aggregateId,
      RemoteMonitoringModuleRoutes.ecp,
      ecp.ecpId,
      RemoteMonitoringModuleRoutes.mapView,
    ]);

    this.router.events?.subscribe((routerEvent: any) => {
      if (routerEvent instanceof NavigationEnd) {
        const ecpIdentifierFromUrl: string = routerEvent.url?.split('/').slice(-2)[0];

        if (ecpIdentifierFromUrl) {
          this.ecpIdentifier = ecpIdentifierFromUrl;
        } else {
          this.ecpIdentifier = ecp.ecpId;
        }
      }
    });

    if (this.selectedIncident.aggregateId) {
      this.remoteMonitoringActions.requestNotesList(this.selectedIncident.aggregateId);
    }
  }

  openNotes(): void {
    this.openNotesWidget = true;
  }

  closeNotes(event: boolean): void {
    this.openNotesWidget = event;
  }

  getNewNotesNumber(event: number): void {
    setTimeout(() => {
      this.newNotesNumber = event;
    }, 0);
  }

  navigateBack(): void {
    this.router.navigate(['remote-monitoring']);
  }

  selectedMarkerOnMap(): void {
    if (this.selectedIncident.aggregateId) {
      this.remoteMonitoringActions.requestNotesList(this.selectedIncident.aggregateId);
    }

    this.closeNotes(false);
  }

  deselectEcp(): void {
    setTimeout(() => {
      this.selectedEcp = undefined;
    }, 0);
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();

    this.cookieService.deleteAll();

    if (this.intervalToGetIncident) {
      clearInterval(this.intervalToGetIncident);
    }

    if (this.scrollbarIntervalId) {
      clearInterval(this.scrollbarIntervalId);
    }
  }

  trackByIdentifier(_index: number, item: IEcp): any {
    return item.ecpIdentifier;
  }
}
