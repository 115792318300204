<ng-template odxModal #locationHierarchyModal let-modal="close">
  <odx-modal-header>
    <odx-area-header>
      @if (router.url?.includes(ConfigurationModuleRoutes.createLocationHierarchy)) {
        {{ 'CONFIGURATION.STR_NEW_ENTRY' | translate }}
      } @else {
        {{ 'CONFIGURATION.STR_EDIT_ENTRY' | translate }}
      }
    </odx-area-header>
  </odx-modal-header>
  <odx-modal-content [ngStyle]="{ overflow: 'visible' }">
    @if (isLoading) {
      <div [odxLoadingSpinner]="isLoading === true" [odxLoadingSpinnerMinHeight]="100" class="w-100"></div>
    } @else {
      <form odxForm [formGroup]="locationHierarchyForm" (ngSubmit)="onSubmit()">
        <section odxLayout="grid">
          <div odxLayout="12 6@tablet 6@desktop" class="custom-form-field-pr-10">
            <odx-form-field variant="full" label="{{ 'CONFIGURATION.STR_ENTRY_NAME' | translate }}">
              <input
                type="text"
                odxFormFieldControl
                ignisDisableAutocomplete
                id="name"
                data-test-id="configuration.entry_name"
                formControlName="name"
                placeholder="{{ 'CONFIGURATION.STR_ENTRY_NAME_PLACEHOLDER' | translate }}"
              />

              <odx-icon
                *ngIf="errors && locationHierarchyForm.get('name').invalid"
                name="info"
                class="custom-validation-icon"
                iconSet="core"
                [odxTooltip]="locationHierarchyNameFieldTooltipContent"
                [odxTooltipVisible]="errors && locationHierarchyForm.get('name').invalid"
              >
                <ng-template #locationHierarchyNameFieldTooltipContent>
                  <div id="name-error-tooltip" data-test-id="configuration.entry_name_ERROR_TOOLTIP">
                    {{
                      'CONFIGURATION_NOTIFICATION.STR_CONFIGURATION_NAME_SHOULD_BE_UNIQUE_AT_THE_SAME_LEVEL' | translate
                    }}
                  </div>
                </ng-template>
              </odx-icon>
            </odx-form-field>
          </div>

          <div odxLayout="12 6@tablet 6@desktop offset-1" class="custom-form-field-pr-10">
            <div
              class="odx-form-field odx-form-field--required"
              [ngClass]="{
                'has-error': locationHierarchyForm.get('type').touched && locationHierarchyForm.get('type').invalid,
              }"
            >
              <label class="odx-form-field__label" for="type">
                <odx-form-field-label class="odx-form-field-label">
                  {{ 'CONFIGURATION.STR_ENTRY_TYPE' | translate }}
                </odx-form-field-label>
                <span class="odx-form-field__label-is-required">*</span>
              </label>

              <div class="w-100 mb-15">
                <p-dropdown
                  #dd1
                  (onShow)="dropdownService.openDropdown(dd1)"
                  (onHide)="dropdownService.closeDropdown(dd1)"
                  [ngModel]="selectedType"
                  (ngModelChange)="selectedType = $event"
                  ngDefaultControl
                  formControlName="type"
                  data-test-id="configuration.entry_type"
                  [dropdownIcon]="dropdownIconCSSClass"
                  [options]="types"
                  [placeholder]="'CONFIGURATION.STR_ENTRY_TYPE_PLACEHOLDER' | translate"
                  [showClear]="false"
                  (onChange)="switchPropertyBasedOnType($event)"
                >
                  <ng-template let-selectedItem pTemplate="selectedItem">
                    <div class="selected-item-with-icon">
                      <odx-icon [name]="selectedItem?.icon"></odx-icon>
                      <span>{{ selectedItem?.label | translate }}</span>
                    </div>
                  </ng-template>
                  <ng-template let-option pTemplate="item">
                    <div class="item-with-icon">
                      <odx-icon [name]="option?.icon"></odx-icon>
                      <span>{{ option?.label | translate }}</span>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>

          <div odxLayout="12 6@tablet 6@desktop" class="custom-form-field-pr-10">
            <div
              class="odx-form-field odx-form-field--required"
              [ngClass]="{
                'has-error':
                  locationHierarchyForm.get('property').touched && locationHierarchyForm.get('property').invalid,
              }"
            >
              <label class="odx-form-field__label" for="property">
                <odx-form-field-label class="odx-form-field-label">
                  {{ 'CONFIGURATION.STR_LOCATION_PROPERTIES' | translate }}
                </odx-form-field-label>
                <span class="odx-form-field__label-is-required">*</span>
              </label>

              <div class="w-100 mb-15">
                <p-dropdown
                  #dd2
                  (onShow)="dropdownService.openDropdown(dd2)"
                  (onHide)="dropdownService.closeDropdown(dd2)"
                  [ngModel]="selectedProperty"
                  (ngModelChange)="selectedProperty = $event"
                  optionValue="value"
                  ngDefaultControl
                  formControlName="property"
                  data-test-id="configuration.location_properties"
                  [dropdownIcon]="dropdownIconCSSClass"
                  [options]="locationTypesProperty"
                  [placeholder]="'CONFIGURATION.STR_LOCATION_PROPERTIES_PLACEHOLDER' | translate"
                  [showClear]="false"
                >
                  <ng-template let-selectedItem pTemplate="selectedItem">
                    <div>
                      <span>{{ selectedItem?.localizedName | translate }}</span>
                    </div>
                  </ng-template>
                  <ng-template let-option pTemplate="item">
                    <div class="item-with-icon">
                      <span>{{ option?.localizedName | translate }}</span>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>

          <div odxLayout="12 6@tablet 6@desktop" class="custom-form-field-pr-10">
            <odx-form-field variant="full" label="{{ 'CONFIGURATION.STR_ENTRY_IDENTIFIER' | translate }}">
              <input
                type="text"
                odxFormFieldControl
                ignisDisableAutocomplete
                id="identifier"
                data-test-id="configuration.entry_identifier"
                formControlName="identifier"
                placeholder="{{ 'CONFIGURATION.STR_ENTRY_IDENTIFIER_PLACEHOLDER' | translate }}"
              />

              <odx-icon
                *ngIf="errors && locationHierarchyForm.get('identifier').invalid"
                name="info"
                class="custom-validation-icon"
                iconSet="core"
                [odxTooltip]="locationIdentifierFieldTooltipContent"
                [odxTooltipVisible]="errors && locationHierarchyForm.get('identifier').invalid"
              >
                <ng-template #locationIdentifierFieldTooltipContent>
                  <div id="identifier-error-tooltip" data-test-id="configuration.entry_identifier_ERROR_TOOLTIP">
                    {{ 'CONFIGURATION_NOTIFICATION.STR_CONFIGURATION_IDENTIFIER_SHOULD_BE_UNIQUE' | translate }}
                  </div>
                </ng-template>
              </odx-icon>
            </odx-form-field>
          </div>

          <div odxLayout="12 6@tablet 6@desktop" class="custom-form-field-pr-10">
            <odx-form-field variant="full" label="{{ 'CONFIGURATION.STR_ENTRY_ADDRESS' | translate }}">
              <input
                type="text"
                odxFormFieldControl
                ignisDisableAutocomplete
                id="address"
                data-test-id="configuration.entry_address"
                formControlName="address"
                placeholder="{{ 'CONFIGURATION.STR_ENTRY_ADDRESS_PLACEHOLDER' | translate }}"
              />
            </odx-form-field>
          </div>

          <div odxLayout="12 12@tablet 12@desktop" class="mb-3">
            <odx-form-field variant="full" label="{{ 'CONFIGURATION.STR_ENTRY_DESCRIPTION' | translate }}">
              <textarea
                ignisDisableAutocomplete
                odxFormFieldControl
                id="description"
                data-test-id="configuration.entry_description"
                formControlName="details"
                class="custom-form-textarea"
                rows="3"
              ></textarea>
            </odx-form-field>
          </div>
        </section>
      </form>
    }
  </odx-modal-content>
  <odx-modal-footer>
    <button odxButton (click)="closeModal()" variant="secondary" data-test-id="modal_actions.cancel_btn">
      {{ 'CONFIGURATION.STR_CANCEL_BTN' | translate }}
    </button>

    <button
      odxButton
      variant="primary"
      (click)="onSubmit()"
      [disabled]="!locationHierarchyForm.dirty || locationHierarchyForm.invalid || (isSubmitting | async)"
      type="button"
      data-test-id="modal_actions.save_btn"
    >
      {{ 'CONFIGURATION.STR_SAVE_BTN' | translate }}
    </button>
  </odx-modal-footer>
</ng-template>

<ignis-confirmation-modal
  [isOpen]="isConfirmCloseModalOpen"
  (handleConfirmationModal)="navigateBack($event)"
  class="is-closed-modal"
>
  <div slot="content">
    {{ 'CONFIRM_CLOSE_MODAL.STR_DESCRIPTION_LOCATION_HIERARCHY' | translate }}
  </div>
</ignis-confirmation-modal>
