import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BAWearerNotificationConstants } from 'src/app/ba-wearer/constants';
import { CommonConstants, DropdownService, IScrollOptions } from 'src/app/common';

@Component({
  selector: 'ignis-ba-wearer-personal-data',
  templateUrl: './ba-wearer-personal-data.component.html',
  styleUrl: './ba-wearer-personal-data.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaWearerPersonalDataComponent implements OnChanges {
  @ViewChild('fireStationsDD') fireStationsDD: Partial<NgSelectComponent>;

  @Input() baWearerForm: FormGroup;
  @Input() isSubmitted: boolean;
  @Input() httpCustomErrorCode: string;
  @Input() fireStations: string[];
  fireStationsToShow: string[];
  fsDropdownAreOpened: boolean[];

  uniquePersonaIdCode: string = BAWearerNotificationConstants.notificationCodes.NOT_UNIQUE_PERSONAL_ID.value;
  uniqueRFIDCardCode: string = BAWearerNotificationConstants.notificationCodes.NOT_UNIQUE_RFID_CARD.value;
  scrollbarOptions: IScrollOptions = CommonConstants.scrollbarOptions;
  currentAppTheme: string;

  dropdownService: DropdownService = inject(DropdownService);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.currentAppTheme = this.document.body.className.split(' ')[1];
    this.fsDropdownAreOpened = [];
  }

  ngOnChanges() {
    this.fireStationsToShow = this.fireStations;
  }

  onValueChange(input: { target: HTMLInputElement }, formControl: string): void {
    if (input.target.value?.trim()) {
      this.clearFormControlValidators(formControl);
    } else {
      this.baWearerForm.get(formControl).patchValue('');
      this.baWearerForm.get(formControl).setValidators([Validators.required]);
      this.baWearerForm.get(formControl).updateValueAndValidity();
    }
  }

  clearFormControlValidators(formControl: string): void {
    if (this.baWearerForm.get(formControl).value.length > 1) {
      this.httpCustomErrorCode = '0000';
    }

    this.baWearerForm.get(formControl).clearValidators();
    this.baWearerForm.get(formControl).updateValueAndValidity();
  }

  blockUserToInterval(): void {
    if (this.baWearerForm.get('rfidCard').value < 1 || this.baWearerForm.get('rfidCard').value > 255) {
      this.baWearerForm.get('rfidCard').setValue(null);
      this.baWearerForm.markAsPristine();
    }
  }

  addNewFireStation(event: InputEvent): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;

    if (target.value) {
      const mergedArray: string[] = [target.value, ...(this.fireStations || [])];

      this.fireStationsToShow = Array.from(new Set(mergedArray));
    } else {
      this.fireStationsToShow = this.fireStations;
    }
  }

  onFireStationChange(fireStationName: string): void {
    if (!fireStationName) {
      this.baWearerForm.get('fireStation').setValue(null);
    }

    this.resetFieldValidators();
  }

  resetFieldValidators(): void {
    if (this.httpCustomErrorCode && this.httpCustomErrorCode !== '0000') {
      this.httpCustomErrorCode = '0000';

      this.baWearerForm.get('rfidCard').clearValidators();
      this.baWearerForm.get('rfidCard').setErrors({ incorrect: false });
      this.baWearerForm.get('rfidCard').updateValueAndValidity();

      this.baWearerForm.markAsDirty();
    }
  }

  toggleFireStationDropdown(fireStationDD: string): void {
    this.fsDropdownAreOpened.push(this[fireStationDD].isOpen);

    if (this.fsDropdownAreOpened.length === 2) {
      this[fireStationDD].close();
      this[fireStationDD].focus();
      this.fsDropdownAreOpened = [];
    }
  }
}
