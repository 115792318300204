import { PropertyBag } from 'src/app/common/models/common.model';

export const RemoteMonitoringModuleRoutes: PropertyBag = {
  remoteMonitoring: 'remote-monitoring',
  filter: 'filter',
  incidentOverview: 'incident-overview',
  ecp: 'ecp',
  mapView: 'map-view',
  monitorTeams: 'monitor-teams',
};
