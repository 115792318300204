import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IEntryModel } from 'src/app/common/models/common.model';

@Component({
  selector: 'ignis-incident-type-translation',
  templateUrl: './incident-type-translation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncidentTypeTranslationComponent {
  @Input() entry: IEntryModel;
}
