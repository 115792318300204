<ignis-progress-bar *ngIf="isLoading"></ignis-progress-bar>

<ignis-table-settings
  class="logbook-list-table-settings"
  [tableColumns]="tableColumns"
  (changeColumnVisibility)="changeColumnVisibility($event)"
></ignis-table-settings>

<p-table
  #dt2
  styleClass="p-datatable-striped p-datatable-gridlines"
  scrollHeight="flex"
  class="fireground-table large-height-table"
  [value]="logbook"
  [scrollable]="true"
  [loading]="isLoading"
  [lazy]="true"
  [paginator]="true"
  [rows]="rows"
  [first]="first"
  [filterDelay]="600"
  [rowsPerPageOptions]="rowsPerPageOptions"
  [columns]="tableHelperVisibleColumns"
  [resizableColumns]="true"
  [reorderableColumns]="true"
  [columnResizeMode]="'fit'"
  [totalRecords]="totalRecords"
  (clickOutside)="onClickOutside()"
  (onLazyLoad)="loadData($event)"
  (onSort)="onChangeSort($event)"
  (onColReorder)="onColReorder($event)"
  (onColResize)="onColResize()"
  (onPage)="onPageChangeLocal($event)"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        *ngFor="let col of columns"
        class="column-header"
        pResizableColumn
        pReorderableColumn
        scope="col"
        [ngClass]="{
          'selected-sort-column': sortColumn?.field === col.field,
          'deselected-sort-column': sortColumn?.field !== col.field,
          'multiSelect-overflow': col.field === 'status',
          'usage-duration-column': col.field === 'usageDuration',
        }"
        [attr.data-test-id]="col.field"
        [attr.rowspan]="col.field === 'usageDuration' ? 2 : 1"
      >
        <span class="sort-area" [pSortableColumn]="col.field"
          >{{ col.header | translate }}
          <ignis-sort-icon [columnName]="col.field" [columnSorted]="sortColumn"></ignis-sort-icon
        ></span>
      </th>
    </tr>

    <tr>
      <th
        *ngFor="let col of columns"
        scope="col"
        [ngClass]="{
          'selected-sort-column': sortColumn?.field === col.field,
          'deselected-sort-column': sortColumn?.field !== col.field,
          'multiSelect-overflow': col.field === 'status',
          'hide-column': col.field === 'usageDuration',
        }"
      >
        <p-columnFilter
          class="w-100"
          id="columnFilterDate"
          data-test-id="ba_logbook_list.column_filter_Date"
          *ngIf="col.field === 'date'"
          field="date"
          matchMode="date"
          [showMenu]="false"
          [showClearButton]="false"
        >
          <ng-template pTemplate="filter">
            <odx-form-field variant="simple" odxLayout="6@phone 12">
              <ignis-datepicker
                class="w-100 logbook-date-filter"
                *ngIf="toggleCalendar"
                [selectionMode]="'range'"
                [formatDate]="formatDate"
                [minDate]="minDate"
                [maxDate]="maxDate"
                [defaultRange]="selectedDateRange"
                appendTo="body"
                (dateSelect)="onDateSelect($event)"
                (dateChange)="onDateChange($event)"
                data-test-id="ba_logbook_list.datepicker_filter"
              >
              </ignis-datepicker>
            </odx-form-field>
          </ng-template>
        </p-columnFilter>

        <p-columnFilter
          class="w-100"
          id="columnFilterEquipmentId"
          data-test-id="ba_logbook_list.column_filter_EquipmentId"
          *ngIf="col.field === 'equipmentId'"
          field="equipmentId"
          matchMode="inputFilter"
          [showMenu]="false"
          [showClearButton]="false"
        >
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <odx-form-field
              [variant]="'simple'"
              odxLayout="6@phone 12"
              [ngClass]="{ 'has-error': !isBASerialInputValueValid }"
            >
              <input
                type="text"
                ignisDisableAutocomplete
                odxFormFieldControl
                ignisNoAllowHtmlTags
                (emitIsInputValueValid)="isBASerialInputValueValid = $event"
                [ngClass]="{ 'text-filter-padding-right': tableHelperSavedFilters?.equipmentId }"
                data-test-id="ba_logbook_list.equipment_id_filter"
                [ngModel]="tableHelperSavedFilters?.equipmentId"
                [placeholder]="'LOGBOOK_TABLE.SEARCH_SCBA_ID' | translate"
                [title]="
                  tableHelperSavedFilters?.equipmentId
                    ? tableHelperSavedFilters?.equipmentId
                    : ('LOGBOOK_TABLE.SEARCH_SCBA_ID' | translate)
                "
                (input)="onFilter(col.field, $event.target.value)"
                (keyup)="setValueInPrefilter('equipmentId', $event.target.value)"
                (drop)="tableService.preventToDropColumn()"
              />
              <odx-action-group odxFormFieldSuffix>
                <button
                  odxButton
                  size="medium"
                  *ngIf="tableHelperSavedFilters?.equipmentId"
                  (click)="clearFilter('equipmentId'); isBASerialInputValueValid = true"
                >
                  <odx-icon name="close" size="medium"></odx-icon>
                </button>
              </odx-action-group>

              <odx-icon
                *ngIf="!isBASerialInputValueValid"
                name="info"
                class="custom-validation-icon r-35"
                iconSet="core"
                [odxTooltip]="'TABLE.STR_NO_VALID_VALUE' | translate"
                [odxTooltipVisible]="!isBASerialInputValueValid"
              >
              </odx-icon>
            </odx-form-field>
          </ng-template>
        </p-columnFilter>

        <p-columnFilter
          class="w-100"
          id="columnFilterStartPresure"
          data-test-id="ba_logbook_list.column_filter_StartPresure"
          *ngIf="col.field === 'startPressure'"
          field="startPressure"
          matchMode="between"
          [showMatchModes]="false"
          [showOperator]="false"
          [showAddButton]="false"
          [showMenu]="false"
          [showClearButton]="false"
        >
          <ng-template pTemplate="filter" let-filter="filterCallback">
            <div class="row m-b-15">
              <div class="col-6">
                <odx-form-field [variant]="'simple'" odxLayout="6@phone 12">
                  <input
                    odxFormFieldControl
                    data-test-id="ba_logbook_list.start_pressure_min_range_filter"
                    ngDefaultControl
                    type="number"
                    ignisDisableAutocomplete
                    placeholder="{{ 'TABLE.RANGE_SLIDER_MIN' | translate }}"
                    title=""
                    [min]="0"
                    [max]="startSliderMaxValue"
                    [ngModel]="startSliderValues[0]"
                    (ngModelChange)="startSliderValues[0] = $event"
                    (change)="sliderInputChange($event, 'min', 'start')"
                    (drop)="tableService.preventToDropColumn()"
                  />
                </odx-form-field>
              </div>
              <div class="col-6 ps-0">
                <odx-form-field [variant]="'simple'" odxLayout="6@phone 12">
                  <input
                    odxFormFieldControl
                    data-test-id="ba_logbook_list.start_pressure_max_range_filter"
                    ngDefaultControl
                    class="sliderMaxValue"
                    type="number"
                    ignisDisableAutocomplete
                    placeholder="{{ 'TABLE.RANGE_SLIDER_MAX' | translate }}"
                    title=""
                    [min]="startSliderMinValue"
                    [max]="startSliderMaxValue"
                    [ngModel]="startSliderValues[1]"
                    (ngModelChange)="startSliderValues[1] = $event"
                    (change)="sliderInputChange($event, 'max', 'start')"
                    (drop)="tableService.preventToDropColumn()"
                  />
                </odx-form-field>
              </div>
            </div>
            <div class="m-b-15">
              <p-slider
                ngDefaultControl
                data-test-id="ba_logbook_list.start_pressure_slider_filter"
                styleClass="p-m-3 z-index-1"
                [ngModel]="startSliderValues"
                (ngModelChange)="startSliderValues = $event"
                [range]="true"
                [step]="1"
                [min]="0"
                [max]="startSliderMaxValue"
                [disabled]="startSliderMaxValue === startSliderMinValue && startSliderMaxValue === 0"
                (change)="managePressureFilter($event.values, 'start')"
                (onSlideEnd)="managePressureFilter($event.values, 'start')"
              ></p-slider>
            </div>
          </ng-template>
        </p-columnFilter>
        <p-columnFilter
          class="w-100"
          id="columnFilterEndPresure"
          data-test-id="ba_logbook_list.column_filter_EndPresure"
          *ngIf="col.field === 'endPressure'"
          field="endPressure"
          matchMode="between"
          [showMatchModes]="false"
          [showOperator]="false"
          [showAddButton]="false"
          [showMenu]="false"
          [showClearButton]="false"
        >
          <ng-template pTemplate="filter" let-filter="filterCallback">
            <div class="row m-b-15">
              <div class="col-6">
                <odx-form-field [variant]="'simple'" odxLayout="6@phone 12">
                  <input
                    odxFormFieldControl
                    data-test-id="ba_logbook_list.end_pressure_min_value"
                    ngDefaultControl
                    type="number"
                    ignisDisableAutocomplete
                    placeholder="{{ 'TABLE.RANGE_SLIDER_MIN' | translate }}"
                    title=""
                    [min]="0"
                    [max]="endSliderMaxValue"
                    [ngModel]="endSliderValues[0]"
                    (ngModelChange)="endSliderValues[0] = $event"
                    (change)="sliderInputChange($event, 'min', 'end')"
                    (drop)="tableService.preventToDropColumn()"
                  />
                </odx-form-field>
              </div>
              <div class="col-6 ps-0">
                <odx-form-field [variant]="'simple'" odxLayout="6@phone 12">
                  <input
                    odxFormFieldControl
                    data-test-id="ba_logbook_list.end_pressure_max_value"
                    ngDefaultControl
                    class="sliderMaxValu1e"
                    type="number"
                    ignisDisableAutocomplete
                    placeholder="{{ 'TABLE.RANGE_SLIDER_MAX' | translate }}"
                    title=""
                    [min]="endSliderMinValue"
                    [max]="endSliderMaxValue"
                    [ngModel]="endSliderValues[1]"
                    (ngModelChange)="endSliderValues[1] = $event"
                    (change)="sliderInputChange($event, 'max', 'end')"
                    (drop)="tableService.preventToDropColumn()"
                  />
                </odx-form-field>
              </div>
            </div>
            <div class="m-b-15">
              <p-slider
                ngDefaultControl
                data-test-id="ba_logbook_list.end_pressure_slider_filter"
                styleClass="p-m-3 z-index-1"
                [ngModel]="endSliderValues"
                (ngModelChange)="endSliderValues = $event"
                [range]="true"
                [step]="1"
                [min]="0"
                [max]="endSliderMaxValue"
                [disabled]="endSliderMaxValue === endSliderMinValue && endSliderMaxValue === 0"
                (change)="managePressureFilter($event.values, 'end')"
                (onSlideEnd)="managePressureFilter($event.values, 'end')"
              ></p-slider>
            </div>
          </ng-template>
        </p-columnFilter>

        <p-columnFilter
          class="w-100"
          id="columnFilterReason"
          data-test-id="ba_logbook_list.column_filter_Reason"
          *ngIf="col.field === 'reason'"
          field="reason"
          matchMode="equals"
          [showMenu]="false"
          [showClearButton]="false"
        >
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <odx-form-field [variant]="'simple'" odxLayout="6@phone 12">
              <div class="w-100">
                <p-dropdown
                  appendTo="body"
                  class="table-dropdown-filter"
                  [ngModel]="tableHelperSavedFilters?.reason"
                  ngDefaultControl
                  data-test-id="ba_logbook_list.reason_filter"
                  [dropdownIcon]="dropdownIconCSSClass"
                  #dd2
                  (onShow)="dropdownService.openDropdown(dd2)"
                  (onHide)="dropdownService.closeDropdown(dd2)"
                  [options]="testReason"
                  (onChange)="onFilter(col.field, $event.value)"
                  [placeholder]="'LOGBOOK_TABLE.SELECT_REASON' | translate"
                  [showClear]="true"
                >
                  <ng-template let-option pTemplate="item">
                    <span>{{ option.label }}</span>
                  </ng-template>

                  <ng-template pTemplate="clearicon">
                    <odx-icon name="close"></odx-icon>
                  </ng-template>
                </p-dropdown>
              </div>
            </odx-form-field>
          </ng-template>
        </p-columnFilter>

        <p-columnFilter
          class="w-100"
          id="columnFilterName"
          data-test-id="ba_logbook_list.column_filter_Name"
          *ngIf="col.field === 'name'"
          field="name"
          matchMode="inputFilter"
          [showMenu]="false"
          [showClearButton]="false"
        >
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <odx-form-field
              [variant]="'simple'"
              odxLayout="6@phone 12"
              [ngClass]="{ 'has-error': !isUsernameInputValueValid }"
            >
              <input
                type="text"
                ignisNoAllowHtmlTags
                (emitIsInputValueValid)="isUsernameInputValueValid = $event"
                ignisDisableAutocomplete
                data-test-id="ba_logbook_list.user_filter"
                [ngClass]="{ 'text-filter-padding-right': tableHelperSavedFilters?.name }"
                odxFormFieldControl
                [ngModel]="tableHelperSavedFilters?.name"
                [placeholder]="'LOGBOOK_TABLE.SEARCH_USERNAME' | translate"
                [title]="
                  tableHelperSavedFilters?.name
                    ? tableHelperSavedFilters?.name
                    : ('LOGBOOK_TABLE.SEARCH_USERNAME' | translate)
                "
                (input)="onFilter(col.field, $event.target.value)"
                (keyup)="setValueInPrefilter('name', $event.target.value)"
                (drop)="tableService.preventToDropColumn()"
              />
              <odx-action-group odxFormFieldSuffix>
                <button
                  odxButton
                  size="medium"
                  *ngIf="tableHelperSavedFilters?.name"
                  (click)="clearFilter('name'); isUsernameInputValueValid = true"
                  data-test-id="ba_logbook_list.clear_user_filter"
                >
                  <odx-icon name="close" size="medium"></odx-icon>
                </button>
              </odx-action-group>

              <odx-icon
                *ngIf="!isUsernameInputValueValid"
                name="info"
                class="custom-validation-icon r-35"
                iconSet="core"
                [odxTooltip]="'TABLE.STR_NO_VALID_VALUE' | translate"
                [odxTooltipVisible]="!isUsernameInputValueValid"
              >
              </odx-icon>
            </odx-form-field>
          </ng-template>
        </p-columnFilter>

        <p-columnFilter
          class="w-100"
          id="columnFilterPersonalId"
          data-test-id="ba_logbook_list.column_filter_PersonalId"
          *ngIf="col.field === 'personalId'"
          field="personalId"
          matchMode="inputFilter"
          [showMenu]="false"
          [showClearButton]="false"
        >
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <odx-form-field
              [variant]="'simple'"
              odxLayout="6@phone 12"
              [ngClass]="{ 'has-error': !isPersonalIDInputValueValid }"
            >
              <input
                type="text"
                ignisDisableAutocomplete
                ignisNoAllowHtmlTags
                (emitIsInputValueValid)="isPersonalIDInputValueValid = $event"
                [ngClass]="{ 'text-filter-padding-right': tableHelperSavedFilters?.personalId }"
                odxFormFieldControl
                [ngModel]="tableHelperSavedFilters?.personalId"
                data-test-id="ba_logbook_list.personal_id_filter"
                [placeholder]="'LOGBOOK_TABLE.SEARCH_PERSONAL_ID' | translate"
                [title]="
                  tableHelperSavedFilters?.personalId
                    ? tableHelperSavedFilters?.personalId
                    : ('LOGBOOK_TABLE.SEARCH_PERSONAL_ID' | translate)
                "
                (input)="onFilter(col.field, $event.target.value)"
                (drop)="tableService.preventToDropColumn()"
              />
              <odx-action-group odxFormFieldSuffix>
                <button
                  odxButton
                  size="medium"
                  *ngIf="tableHelperSavedFilters?.personalId"
                  data-test-id="ba_logbook_list.clear_personal_id_filter"
                  (click)="clearFilter('personalId'); isPersonalIDInputValueValid = true"
                >
                  <odx-icon name="close" size="medium"></odx-icon>
                </button>
              </odx-action-group>

              <odx-icon
                *ngIf="!isPersonalIDInputValueValid"
                name="info"
                class="custom-validation-icon r-35"
                iconSet="core"
                [odxTooltip]="'TABLE.STR_NO_VALID_VALUE' | translate"
                [odxTooltipVisible]="!isPersonalIDInputValueValid"
              >
              </odx-icon>
            </odx-form-field>
          </ng-template>
        </p-columnFilter>

        <p-columnFilter
          class="w-100"
          id="columnFilterStatus"
          data-test-id="ba_logbook_list.column_filter_Status"
          *ngIf="col.field === 'status'"
          field="status"
          matchMode="in"
          [showMenu]="false"
          [showClearButton]="false"
        >
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <odx-form-field [variant]="'simple'" odxLayout="6@phone 12">
              <p-multiSelect
                appendTo="body"
                ngDefaultControl
                data-test-id="ba_logbook_list.status_filter"
                [dropdownIcon]="dropdownIconCSSClass"
                class="table-multiSelect-filter"
                optionLabel="name"
                appendTo="body"
                [showHeader]="false"
                [ngModel]="selectedStatuses"
                [options]="statusList"
                [placeholder]="'LOGBOOK_TABLE.STR_STATUS_PLACEHOLDER' | translate"
                (onChange)="onFilter('statuses', $event.value)"
              >
                <ng-template let-value pTemplate="selectedItems">
                  <div *ngIf="value !== null && value?.length === 1">
                    <div *ngFor="let val of value" class="ui-multiselected-item-token ui-corner-all">
                      <span [ngSwitch]="val?.name" class="text-center h3">
                        <odx-icon
                          *ngSwitchDefault
                          name="error"
                          data-test-id="ba_logbook_list.status_filter_error"
                        ></odx-icon>
                        <odx-icon
                          *ngSwitchCase="'WARNING'"
                          name="warning"
                          data-test-id="ba_logbook_list.status_filter_warning"
                        ></odx-icon>
                        <odx-icon
                          *ngSwitchCase="'OK'"
                          name="feedback-positive"
                          data-test-id="ba_logbook_list.status_filter_feedback_positive"
                        ></odx-icon>
                        <odx-icon
                          *ngSwitchCase="'SPECIAL_EXPOSURE'"
                          name="toxic"
                          data-test-id="ba_logbook_list.status_filter_toxic"
                        ></odx-icon>
                      </span>
                    </div>
                  </div>
                  <ignis-multiple-items-selected [value]="value"></ignis-multiple-items-selected>
                  <span *ngIf="!value || value?.length === 0" class="ui-multiselected-empty-token ui-corner-all">
                    {{ 'LOGBOOK_TABLE.STR_STATUS_PLACEHOLDER' | translate }}
                  </span>
                </ng-template>
                <ng-template let-option pTemplate="item">
                  <div class="p-multiselect-representative-option">
                    <span [ngSwitch]="option?.name" class="text-center h3">
                      <odx-icon
                        *ngSwitchDefault
                        name="error"
                        data-test-id="ba_logbook_list.status_filter_error"
                      ></odx-icon>
                      <odx-icon
                        *ngSwitchCase="'WARNING'"
                        name="warning"
                        data-test-id="ba_logbook_list.status_filter_warning"
                      ></odx-icon>
                      <odx-icon
                        *ngSwitchCase="'OK'"
                        name="feedback-positive"
                        data-test-id="ba_logbook_list.status_filter_feedback_positive"
                      ></odx-icon>
                      <odx-icon
                        *ngSwitchCase="'SPECIAL_EXPOSURE'"
                        name="toxic"
                        data-test-id="ba_logbook_list.status_filter_toxic"
                      ></odx-icon>
                    </span>
                  </div>
                </ng-template>
              </p-multiSelect>
            </odx-form-field>
          </ng-template>
        </p-columnFilter>
      </th>
    </tr>
    <div
      class="table-header-right-wrapper"
      [ngClass]="{
        'selected-sort-column': sortColumn?.field === lastTableElement,
        'deselected-sort-column': sortColumn?.field !== lastTableElement,
      }"
    ></div>
  </ng-template>
  <ng-template pTemplate="body" let-logbook let-rowIndex="rowIndex" let-columns="columns">
    <tr
      class="dynamic-column-width"
      data-test-id="ba_logbook_list.selected_row"
      (click)="onLogbookSelect(logbook)"
      (dblclick)="navigateToReportCenter()"
      [ngClass]="{
        'selected-row': activeLogbook?.aggregateId === logbook.aggregateId,
      }"
    >
      <td
        *ngFor="let col of columns"
        [ngClass]="{ 'multiSelect-overflow': col.field === 'status' }"
        [attr.data-column]="col.field"
      >
        <span *ngIf="col.field === 'date'">{{ logbook.date | date: formatDate }} {{ logbook.time }}</span>
        <span
          *ngIf="col.field === 'equipmentId'"
          [ngStyle]="{
            'padding-left': !logbook.equipmentModel ? '5px' : '',
          }"
          class="ba-serial-column-data"
        >
          <span [ngSwitch]="logbook.equipmentModel" class="text-center h3">
            <odx-icon
              *ngSwitchCase="'airboss'"
              [pTooltip]="'EQUIPMENT_MODEL.STR_AIRBOSS' | translate"
              tooltipPosition="top"
              positionTop="-10"
              size="inline"
              name="scba"
              data-test-id="ba_logbook_list.equipment_model_scba"
            ></odx-icon>
            <odx-icon
              *ngSwitchCase="'proair'"
              [pTooltip]="'EQUIPMENT_MODEL.STR_PROAIR' | translate"
              tooltipPosition="top"
              positionTop="-10"
              size="inline"
              name="ldba"
              data-test-id="ba_logbook_list.equipment_model_proair"
            ></odx-icon>
            <odx-icon
              *ngSwitchCase="'stand_alone'"
              [pTooltip]="'EQUIPMENT_MODEL.STR_STAND_ALONE' | translate"
              tooltipPosition="top"
              positionTop="-10"
              size="inline"
              name="telemetry-disabled"
              data-test-id="ba_logbook_list.equipment_model_stand_alone"
            ></odx-icon>
          </span>
          {{ logbook.equipmentId }}
        </span>
        <span
          *ngIf="col.field === 'startPressure'"
          [pTooltip]="
            logbook.reason.value === 'test'
              ? ('LOGBOOK_TABLE.STR_PRESSURE_FROM_TEST' | translate)
              : ('LOGBOOK_TABLE.STR_PRESSURE_FROM_INCIDENT_BEGINNING' | translate)
          "
          tooltipPosition="top"
          positionTop="-10"
          data-test-id="ba_logbook_list.start_pressure"
        >
          {{ logbook.startPressure | pressureUnit: [pressureDisplayUnit, true] }}
        </span>
        <span
          *ngIf="col.field === 'endPressure'"
          [pTooltip]="
            logbook.reason.value === 'test'
              ? ('LOGBOOK_TABLE.STR_PRESSURE_FROM_TEST' | translate)
              : ('LOGBOOK_TABLE.STR_PRESSURE_FROM_INCIDENT_END' | translate)
          "
          tooltipPosition="top"
          positionTop="-10"
          data-test-id="ba_logbook_list.end_pressure"
        >
          {{ logbook.endPressure | pressureUnit: [pressureDisplayUnit, true] }}
        </span>
        <span *ngIf="col.field === 'reason'"
          ><ignis-event-type-translation [entry]="logbook.reason"></ignis-event-type-translation>
        </span>
        <span *ngIf="col.field === 'incidentNumber'">
          <span *ngIf="logbook.reason.value === 'test'; else displayValue"> - </span>
          <ng-template #displayValue>
            <span *ngIf="logbook.incidentNumber; else displayMessage">
              {{ logbook.incidentNumber }}
            </span>
            <ng-template #displayMessage>
              <span>
                {{ 'LOGBOOK_TABLE.STR_NO_INCIDENT_NUMBER_YET' | translate }}
              </span>
            </ng-template>
          </ng-template></span
        >
        <span *ngIf="col.field === 'usageDuration'">{{ logbook.usageDuration }}</span>
        <span *ngIf="col.field === 'name'">{{ logbook.name }}</span>
        <span *ngIf="col.field === 'personalId'">{{ logbook.personalId }}</span>
        <span *ngIf="col.field === 'status'" class="text-center h3">
          <ignis-status-column
            [statusesName]="logbook.statuses"
            [incidentSpecialExposure]="logbook.incidentSpecialExposure"
            [type]="logbook.reason.value"
            [incidentType]="logbook.incidentType"
            [pressureDisplayUnit]="pressureDisplayUnit"
            [cylinderRatedPressure]="logbook.cylinderRatedPressure"
          ></ignis-status-column
        ></span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="11" id="tableNoEntries" data-test-id="table.no_entries">
        {{ 'LOGBOOK_TABLE.STR_NO_ENTRIES_FOUND' | translate }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft">
    <span *ngIf="totalRecords">
      {{ totalRecords }}
      <span *ngIf="totalRecords > 1; else onlyOneItem">{{ 'TABLE.STR_ITEMS' | translate }}</span>
      <ng-template #onlyOneItem>
        {{ 'TABLE.STR_ITEM' | translate }}
      </ng-template>
    </span>
  </ng-template>
  <ng-template pTemplate="paginatorright" let-state>
    @if (state.pageCount > 0) {
      <span> {{ 'TABLE.STR_TABLE_OF' | translate: { totalPageNumber: state.pageCount } }}</span>
    } @else {
      <span>1 {{ 'TABLE.STR_TABLE_OF' | translate: { totalPageNumber: 1 } }}</span>
    }
  </ng-template>
</p-table>

<footer class="blue-bar">
  <ignis-logbook-module-footer
    [selectedItem]="disableExportBtn"
    [activeEdit]="activatedViewButton"
    (handlePrefilter)="openPreFilterModal()"
    (handleExcelExport)="exportExcel()"
    (navigateToReportCenter)="navigateToReportCenter()"
  >
    <ignis-prefilter
      *ngIf="isPreFilterOpen"
      [openModal]="true"
      [formatDate]="formatDate"
      [logBookLength]="logBookLength"
      [isPreFilterSet]="isPreFilterSet"
      (handleProceed)="getLogbook()"
      (handleClosePrefilter)="resetPrefilterFlag($event)"
      (handlePrefilterFormValues)="getPreFilterFormValues($event)"
    >
    </ignis-prefilter>
  </ignis-logbook-module-footer>
</footer>
