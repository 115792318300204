import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  inject,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { CommonConstants } from 'src/app/common/constants/common.constants';
import { NotificationConstants } from 'src/app/common/constants/notification.constants';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IOKTAProfile } from 'src/app/common/models/common.model';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { ApplicationState } from 'src/app/common/state/models/app.state.model';
import { IncidentActions } from 'src/app/report-center';
import { IIncident, INewNote } from 'src/app/report-center/models/incident.model';
import { selectNewNote } from 'src/app/report-center/state/selectors/incident.selector';
import { ReportCenterConstants } from '../../../../constants/report-center.constants';
import { IMediaAssets } from './../../../../models/incident.model';

@Component({
  selector: 'ignis-media-notes',
  templateUrl: './media-notes.component.html',
  styleUrls: ['./media-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaNotesComponent extends OnDestroyMixin() implements OnChanges {
  @Input() notes: IMediaAssets[];
  @Input() formatDate: string;
  @Input() selectedIncident: IIncident;
  @Input() version: number;
  @Output() getMediaAssets: EventEmitter<string> = new EventEmitter();
  @Input() incidentForm: FormGroup;

  isLoadingNotes: boolean = true;
  showAddNote: boolean = false;
  newNoteForm: FormGroup = new FormGroup({
    noteText: new FormControl(null),
  });

  public route: ActivatedRoute = inject(ActivatedRoute);
  private store: Store<ApplicationState> = inject(Store<ApplicationState>);
  private notificationsService: NotificationsService = inject(NotificationsService);
  private incidentActions: IncidentActions = inject(IncidentActions);
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  jwtHelper: any = new JwtHelperService();

  constructor() {
    super();
  }

  /* istanbul ignore next */
  @HostListener(CommonConstants.beforeUnloadWindowEvent, ['$event'])
  handleBeforeUnload($event: any): void {
    if (this.showAddNote && this.newNoteForm.get('noteText').value && this.hasUnsavedData()) {
      $event.returnValue = this.hasUnsavedData();
    }
  }

  ngOnChanges(): void {
    if (this.notes) {
      this.isLoadingNotes = false;
    }
  }

  displayAddNote(): void {
    this.showAddNote = true;
  }

  hideAddNote(): void {
    this.showAddNote = false;
    this.newNoteForm.markAsPristine();
  }

  hasUnsavedData(): boolean {
    return this.newNoteForm.dirty;
  }

  addNote(): void {
    const loggedInUser: Partial<IOKTAProfile> = this.jwtHelper.decodeToken(
      localStorage.getItem(CommonConstants.AUTH_TOKEN),
    );

    const newNote: INewNote[] = [
      {
        senderName: `${loggedInUser?.firstname} ${loggedInUser?.lastname}`,
        text: this.newNoteForm.get('noteText').value,
      },
    ];

    if (this.version < this.selectedIncident?.version) {
      this.version = this.selectedIncident?.version;
    }

    this.incidentActions.requestAddNoteOrMediaFile(
      this.selectedIncident?.aggregateId,
      newNote,
      this.version ? this.version : this.selectedIncident?.version,
    );

    localStorage.setItem(ReportCenterConstants.mediaAssetsVersion, this.version?.toString());

    this.showAddNote = false;
    this.newNoteForm.reset();

    this.store.pipe(select(selectNewNote), takeUntil(this.destroy)).subscribe((response: any) => {
      if (response.isSuccess) {
        this.notificationsService.requestShowNotification(
          CommonConstants.notificationType.SUCCESS,
          NotificationConstants.commonCodes.ADD_NEW_NOTE,
          NotificationConstants.commonCodes,
        );

        this.getMediaAssets.emit(this.selectedIncident?.aggregateId);
      }

      this.cdr.detectChanges();
    });
  }
}
