import { GenericAction } from 'src/app/common';
import {
  errorItemData,
  errorListData,
  errorPostPutItemData,
  receiveItemData,
  receiveListData,
  receivePostPutItemData,
  requestItemData,
  requestListData,
  requestPostPutItemData,
} from 'src/app/common/utils/reducer/reducer.utils';
import { ICreateUpdateEquipment, IEquipment, IEquipmentListPage } from '../../../models';
import { EquipmentCRUDActionTypes } from '../../actions/equipment-crud';
import { EquipmentDataState, IEquipmentDataState } from '../../models';

const resetSavedEquipments = (lastState: IEquipmentDataState): IEquipmentDataState => ({
  ...lastState,
  savedEquipments: {
    data: null,
    errors: null,
    isLoading: false,
  },
});

const resetSelectedEquipmentDataState = (lastState: IEquipmentDataState): IEquipmentDataState => ({
  ...lastState,
  selectedEquipment: {
    data: null,
    errors: null,
    isSuccess: null,
    isLoading: false,
  },
});

const resetSavedLocationEquipmentState = (lastState: IEquipmentDataState): IEquipmentDataState => ({
  ...lastState,
  savedLocationEquipments: {
    data: null,
    errors: null,
    isLoading: false,
  },
});

export function equipmentCRUDReducer(
  lastState: IEquipmentDataState = new EquipmentDataState(),
  action: GenericAction<EquipmentCRUDActionTypes, any>,
): IEquipmentDataState {
  switch (action.type) {
    case EquipmentCRUDActionTypes.REQUEST_EQUIPMENT_PAGE:
      return requestListData<IEquipmentDataState, IEquipmentListPage>(lastState, ['equipmentPage']);
    case EquipmentCRUDActionTypes.RECEIVE_EQUIPMENT_PAGE:
      return receiveListData<IEquipmentDataState, IEquipmentListPage>(lastState, ['equipmentPage'], action.payload);
    case EquipmentCRUDActionTypes.ERROR_RECEIVE_EQUIPMENT_PAGE:
      return errorListData<IEquipmentDataState, IEquipmentListPage>(lastState, ['equipmentPage'], action.payload);

    case EquipmentCRUDActionTypes.REQUEST_EQUIPMENT_BY_ID:
      return requestItemData<IEquipmentDataState, IEquipment>(lastState, ['selectedEquipment']);
    case EquipmentCRUDActionTypes.RECEIVE_EQUIPMENT_BY_ID:
      return receiveItemData<IEquipmentDataState, IEquipment>(lastState, ['selectedEquipment'], action.payload);
    case EquipmentCRUDActionTypes.ERROR_RECEIVE_EQUIPMENT_BY_ID:
      return errorItemData<IEquipmentDataState, IEquipment>(lastState, ['selectedEquipment'], action.payload);

    case EquipmentCRUDActionTypes.REQUEST_SAVED_EQUIPMENTS:
      return requestListData<IEquipmentDataState, IEquipment[]>(lastState, ['savedEquipments']);
    case EquipmentCRUDActionTypes.RECEIVE_SAVED_EQUIPMENTS:
      return receiveListData<IEquipmentDataState, IEquipment[]>(lastState, ['savedEquipments'], action.payload);
    case EquipmentCRUDActionTypes.ERROR_RECEIVE_SAVED_EQUIPMENTS:
      return errorListData<IEquipmentDataState, IEquipment[]>(lastState, ['savedEquipments'], action.payload);

    case EquipmentCRUDActionTypes.REQUEST_SAVED_LOCATION_EQUIPMENTS:
      return requestListData<IEquipmentDataState, IEquipment[]>(lastState, ['savedLocationEquipments']);
    case EquipmentCRUDActionTypes.RECEIVE_SAVED_LOCATION_EQUIPMENTS:
      return receiveListData<IEquipmentDataState, IEquipment[]>(lastState, ['savedLocationEquipments'], action.payload);
    case EquipmentCRUDActionTypes.ERROR_RECEIVE_SAVED_LOCATION_EQUIPMENTS:
      return errorListData<IEquipmentDataState, IEquipment[]>(lastState, ['savedLocationEquipments'], action.payload);
    case EquipmentCRUDActionTypes.RESET_SAVED_LOCATION_EQUIPMENTS:
      return resetSavedLocationEquipmentState(lastState);

    case EquipmentCRUDActionTypes.REQUEST_ADD_EQUIPMENT:
    case EquipmentCRUDActionTypes.REQUEST_UPDATE_EQUIPMENT:
      return requestPostPutItemData<IEquipmentDataState, ICreateUpdateEquipment>(
        lastState,
        ['selectedEquipment'],
        action.payload,
      );

    case EquipmentCRUDActionTypes.RECEIVE_ADD_EQUIPMENT:
    case EquipmentCRUDActionTypes.RECEIVE_UPDATE_EQUIPMENT:
      return receivePostPutItemData<IEquipmentDataState, IEquipment>(lastState, ['selectedEquipment'], action.payload);

    case EquipmentCRUDActionTypes.ERROR_ADD_EQUIPMENT:
    case EquipmentCRUDActionTypes.ERROR_UPDATE_EQUIPMENT:
      return errorPostPutItemData<IEquipmentDataState, IEquipment>(lastState, ['selectedEquipment'], action.payload);

    case EquipmentCRUDActionTypes.REQUEST_EQUIPMENT_DELETE:
      return requestPostPutItemData<IEquipmentDataState, IEquipment>(lastState, ['deletedEquipment'], action.payload);
    case EquipmentCRUDActionTypes.RECEIVE_EQUIPMENT_DELETE:
      return receivePostPutItemData<IEquipmentDataState, IEquipment>(lastState, ['deletedEquipment'], action.payload);
    case EquipmentCRUDActionTypes.ERROR_RECEIVE_EQUIPMENT_DELETE:
      return errorPostPutItemData<IEquipmentDataState, IEquipment>(lastState, ['deletedEquipment'], action.payload);

    case EquipmentCRUDActionTypes.REQUEST_UPLOAD_EQUIPMENT_CSV_FILE:
      return requestPostPutItemData<IEquipmentDataState, any>(lastState, ['csv'], action.payload);
    case EquipmentCRUDActionTypes.RECEIVE_UPLOAD_EQUIPMENT_CSV_FILE:
      return receivePostPutItemData<IEquipmentDataState, any>(lastState, ['csv'], action.payload);
    case EquipmentCRUDActionTypes.ERROR_RECEIVE_UPLOAD_EQUIPMENT_CSV_FILE:
      return errorPostPutItemData<IEquipmentDataState, any>(lastState, ['csv'], action.payload);

    case EquipmentCRUDActionTypes.RESET_DATA_STATE:
      return resetSelectedEquipmentDataState(lastState);

    case EquipmentCRUDActionTypes.RESET_SAVED_EQUIPMENTS:
      return resetSavedEquipments(lastState);

    default:
      return lastState;
  }
}
