import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IAlarmLists } from 'src/app/root/models/alarms.model';
import { AlarmsActionType } from './alarms-action-types';

export class RequestAlarms implements Action {
  readonly type: AlarmsActionType.REQUEST_ALARMS = AlarmsActionType.REQUEST_ALARMS;
}

export class ReceiveAlarms implements Action {
  readonly type: AlarmsActionType.RECEIVE_ALARMS = AlarmsActionType.RECEIVE_ALARMS;

  constructor(public payload: IAlarmLists) {}
}

export class ErrorReceiveAlarms implements Action {
  readonly type: AlarmsActionType.ERROR_RECEIVE_ALARMS = AlarmsActionType.ERROR_RECEIVE_ALARMS;

  constructor(public payload: HttpErrorResponse) {}
}
