import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { BAWearerActionTypes } from '../ba-wearer-action-types';

export class RequestUploadCSVFile implements Action {
  readonly type: BAWearerActionTypes.REQUEST_UPLOAD_BA_WEARER_CSV_FILE =
    BAWearerActionTypes.REQUEST_UPLOAD_BA_WEARER_CSV_FILE;

  constructor(public payload: FormData) {}
}

export class ReceiveUploadCSVFile implements Action {
  readonly type: BAWearerActionTypes.RECEIVE_UPLOAD_BA_WEARER_CSV_FILE =
    BAWearerActionTypes.RECEIVE_UPLOAD_BA_WEARER_CSV_FILE;

  constructor(public payload: unknown) {}
}

export class ErrorReceiveUploadCSVFile implements Action {
  readonly type: BAWearerActionTypes.ERROR_RECEIVE_UPLOAD_BA_WEARER_CSV_FILE =
    BAWearerActionTypes.ERROR_RECEIVE_UPLOAD_BA_WEARER_CSV_FILE;

  constructor(public payload: HttpErrorResponse) {}
}
