<div
  *ngIf="displayWidget"
  class="alarms-widget"
  [class]="currentAppTheme"
  [style.height]="
    router.url?.includes('incident-overview') || router.url === '/' ? 'calc(100% - 56px)' : 'calc(100vh - 106px)'
  "
  (clickOutside)="onClickedOutside()"
  [attachOutsideOnClick]="true"
>
  <div class="header">
    <span>{{ 'ALARMS.STR_TITLE' | translate }}</span>
  </div>

  <div
    class="alarms-section new"
    *ngIf="newAlarms?.length > 0"
    [ngClass]="{ 'section-full-height': earlierAlarms?.length < 1 && processedClearedAlarms?.length < 1 }"
    [style.height]="newSectionHeight"
  >
    <span (click)="toggleNewSection()">
      {{ 'ALARMS.STR_NEW' | translate }}
      <odx-icon
        size="inline"
        name="arrow1-up"
        class="toggle-alarms-section"
        *ngIf="displayNewSection; else arrowDownNew"
      ></odx-icon>
      <ng-template #arrowDownNew>
        <odx-icon size="inline" name="arrow1-down" class="toggle-alarms-section"></odx-icon>
      </ng-template>
    </span>
    <ignis-alarms-list
      [alarms]="newAlarms"
      [currentAppTheme]="currentAppTheme"
      *ngIf="displayNewSection"
      [dateFormat]="dateFormat"
      (selectedAlert)="moveAlert($event)"
    ></ignis-alarms-list>
  </div>

  <div class="alarms-safety-line" *ngIf="newAlarms?.length > 0"></div>

  <div
    class="alarms-section earlier"
    *ngIf="earlierAlarms?.length > 0"
    [ngClass]="{ 'section-full-height': newAlarms?.length < 1 && processedClearedAlarms?.length < 1 }"
    [style.height]="earlierSectionHeight"
  >
    <span (click)="toggleEarlierSection()">
      {{ 'ALARMS.STR_EARLIER' | translate }}
      <odx-icon
        size="inline"
        name="arrow1-up"
        class="toggle-alarms-section"
        *ngIf="displayEarlierSection; else arrowDownEarlier"
      ></odx-icon>
      <ng-template #arrowDownEarlier>
        <odx-icon size="inline" name="arrow1-down" class="toggle-alarms-section"></odx-icon>
      </ng-template>
    </span>
    <ignis-alarms-list
      [alarms]="earlierAlarms"
      [currentAppTheme]="currentAppTheme"
      [dateFormat]="dateFormat"
      *ngIf="displayEarlierSection"
    ></ignis-alarms-list>
  </div>

  <div class="alarms-safety-line" *ngIf="earlierAlarms?.length > 0 && processedClearedAlarms?.length > 0"></div>

  <div
    class="alarms-section cleared"
    *ngIf="processedClearedAlarms?.length > 0"
    [ngClass]="{ 'section-full-height': newAlarms?.length < 1 && earlierAlarms?.length < 1 }"
    [style.height]="clearedSectionHeight"
  >
    <span (click)="toggleClearedSection()">
      {{ 'ALARMS.STR_CLEARED' | translate }}
      <odx-icon
        size="inline"
        name="arrow1-up"
        class="toggle-alarms-section"
        *ngIf="displayClearedSection; else arrowDownEarlier"
      ></odx-icon>
      <ng-template #arrowDownEarlier>
        <odx-icon size="inline" name="arrow1-down" class="toggle-alarms-section"></odx-icon>
      </ng-template>
    </span>
    <ignis-alarms-list
      [alarms]="processedClearedAlarms"
      [currentAppTheme]="currentAppTheme"
      [dateFormat]="dateFormat"
      *ngIf="displayClearedSection"
    ></ignis-alarms-list>
  </div>
</div>
