import { IStoreApiItem } from 'src/app/common';
import { StoreApiItem } from './../../../../common/models/store-api-item.model';

export interface IMaintenanceDate {
  date: string;
}

export interface IMaintenanceModeState {
  maintenanceDate: IStoreApiItem<IMaintenanceDate>;
}

export class MaintenanceModeState implements IMaintenanceModeState {
  maintenanceDate: IStoreApiItem<IMaintenanceDate> = new StoreApiItem();
}
