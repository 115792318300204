import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { INewUser, IUpdateUser, IUser, IUserPersonalInfo } from 'src/app/user-management/models/user.model';
import { UserActionTypes } from '../user-action-types';

export class RequestUserById implements Action {
  readonly type: UserActionTypes.REQUEST_USER_BY_ID = UserActionTypes.REQUEST_USER_BY_ID;

  constructor(public payload: string) { }
}
export class ReceiveUserById implements Action {
  readonly type: UserActionTypes.RECEIVE_USER_BY_ID = UserActionTypes.RECEIVE_USER_BY_ID;

  constructor(public payload: IUser) { }
}
export class ErrorReceiveUserById implements Action {
  readonly type: UserActionTypes.ERROR_RECEIVE_USER_BY_ID = UserActionTypes.ERROR_RECEIVE_USER_BY_ID;

  constructor(public payload: HttpErrorResponse) { }
}

export class RequestUsersPersonalInfoByIds implements Action {
  readonly type: UserActionTypes.REQUEST_USERS_PERSONAL_INFO_BY_IDS = UserActionTypes.REQUEST_USERS_PERSONAL_INFO_BY_IDS;

  constructor(public payload: string[]) { }
}
export class ReceiveUsersPersonalInfoByIds implements Action {
  readonly type: UserActionTypes.RECEIVE_USERS_PERSONAL_INFO_BY_IDS = UserActionTypes.RECEIVE_USERS_PERSONAL_INFO_BY_IDS;

  constructor(public payload: IUserPersonalInfo[]) { }
}
export class ErrorReceiveUsersPersonalInfoByIds implements Action {
  readonly type: UserActionTypes.ERROR_RECEIVE_USERS_PERSONAL_INFO_BY_IDS = UserActionTypes.ERROR_RECEIVE_USERS_PERSONAL_INFO_BY_IDS;

  constructor(public payload: HttpErrorResponse) { }
}

export class RequestAddUser implements Action {
  readonly type: UserActionTypes.REQUEST_ADD_USER = UserActionTypes.REQUEST_ADD_USER;

  constructor(public payload: INewUser) { }
}
export class ReceiveAddUser implements Action {
  readonly type: UserActionTypes.RECEIVE_ADD_USER = UserActionTypes.RECEIVE_ADD_USER;

  constructor(public payload: IUser) { }
}
export class ErrorAddUser implements Action {
  readonly type: UserActionTypes.ERROR_ADD_USER = UserActionTypes.ERROR_ADD_USER;

  constructor(public payload: HttpErrorResponse) { }
}

export class RequestUpdateUser implements Action {
  readonly type: UserActionTypes.REQUEST_UPDATE_USER = UserActionTypes.REQUEST_UPDATE_USER;

  constructor(public payload: IUpdateUser) { }
}
export class ReceiveUpdateUser implements Action {
  readonly type: UserActionTypes.RECEIVE_UPDATE_USER = UserActionTypes.RECEIVE_UPDATE_USER;

  constructor(public payload: IUpdateUser) { }
}
export class ErrorUpdateUser implements Action {
  readonly type: UserActionTypes.ERROR_UPDATE_USER = UserActionTypes.ERROR_UPDATE_USER;

  constructor(public payload: HttpErrorResponse) { }
}

export class RequestUserDelete implements Action {
  readonly type: UserActionTypes.REQUEST_USER_DELETE = UserActionTypes.REQUEST_USER_DELETE;

  constructor(public payload: IUser) { }
}
export class ReceiveUserDelete implements Action {
  readonly type: UserActionTypes.RECEIVE_USER_DELETE = UserActionTypes.RECEIVE_USER_DELETE;

  constructor(public payload: IUser) { }
}
export class ErrorReceiveUserDelete implements Action {
  readonly type: UserActionTypes.ERROR_RECEIVE_USER_DELETE = UserActionTypes.ERROR_RECEIVE_USER_DELETE;

  constructor(public payload: HttpErrorResponse) { }
}

export class ResetUserCRUDState implements Action {
  readonly type: UserActionTypes.RESET_CRUD_USER_STATE = UserActionTypes.RESET_CRUD_USER_STATE;
}
