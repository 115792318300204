import { ITableColumn } from 'src/app/common';
import { TableFilterType } from 'src/app/common/constants';

export const tableColumns: ITableColumn[] = [
  {
    field: 'identification',
    header: 'EQUIPMENT_TASK_RESULTS.STR_IDENTIFICATION',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'EQUIPMENT_TASK_RESULTS.STR_IDENTIFICATION_PLACEHOLDER',
  },
  {
    field: 'barcode',
    header: 'EQUIPMENT_TASK_RESULTS.STR_BARCODE',
    visible: false,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'EQUIPMENT_TASK_RESULTS.STR_BARCODE_PLACEHOLDER',
  },
  {
    field: 'rfid',
    header: 'EQUIPMENT_TASK_RESULTS.STR_RFID',
    visible: false,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'EQUIPMENT_TASK_RESULTS.STR_RFID_PLACEHOLDER',
  },
  {
    field: 'serialNo',
    header: 'EQUIPMENT_TASK_RESULTS.STR_SERIAL_NO',
    visible: false,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'EQUIPMENT_TASK_RESULTS.STR_SERIAL_NO_PLACEHOLDER',
  },
  {
    field: 'category',
    header: 'EQUIPMENT_TASK_RESULTS.STR_CATEGORY',
    visible: false,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'EQUIPMENT_TASK_RESULTS.STR_CATEGORY_PLACEHOLDER',
  },
  {
    field: 'type',
    header: 'EQUIPMENT_TASK_RESULTS.STR_TYPE',
    visible: false,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'EQUIPMENT_TASK_RESULTS.STR_TYPE_PLACEHOLDER',
  },
  {
    field: 'model',
    header: 'EQUIPMENT_TASK_RESULTS.STR_MODEL',
    visible: false,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'EQUIPMENT_TASK_RESULTS.STR_MODEL_PLACEHOLDER',
  },
  {
    field: 'dateTime',
    header: 'EQUIPMENT_TASK_RESULTS.STR_DATE_AND_TIME',
    visible: true,
    filterType: TableFilterType.DATE,
    filterKey: ['startTime', 'endTime'],
    filterPlaceholder: 'EQUIPMENT_TASK_RESULTS.STR_DATE_AND_TIME_PLACEHOLDER',
  },
  {
    field: 'task',
    header: 'EQUIPMENT_TASK_RESULTS.STR_TASK',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'EQUIPMENT_TASK_RESULTS.STR_TASK_PLACEHOLDER',
  },
  {
    field: 'includedTasks',
    header: 'EQUIPMENT_TASK_RESULTS.STR_TASKS_INCLUDED',
    visible: false,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'EQUIPMENT_TASK_RESULTS.STR_SERVICES_INCLUDED_PLACEHOLDER',
  },
  {
    field: 'others',
    header: 'EQUIPMENT_TASK_RESULTS.STR_OTHER_TASKS',
    visible: false,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'EQUIPMENT_TASK_RESULTS.STR_OTHER_SERVICES_PLACEHOLDER',
  },
  {
    field: 'testerName',
    header: 'EQUIPMENT_TASK_RESULTS.STR_USER',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterKey: ['tester'],
    filterPlaceholder: 'EQUIPMENT_TASK_RESULTS.STR_USER_PLACEHOLDER',
  },
  {
    field: 'operationalStatus',
    header: 'EQUIPMENT_TASK_RESULTS.STR_EQUIPMENT_STATUS',
    visible: true,
    filterType: TableFilterType.DROPDOWN,
    filterKey: ['functional'],
    filterPlaceholder: 'EQUIPMENT_TASK_RESULTS.STR_EQUIPMENT_STATUS_PLACEHOLDER',
    dropdownOptions: [
      {
        value: 'OPERATIONAL',
        icon: 'feedback-positive',
        label: 'CREATE_UPDATE_EQUIPMENT.STR_OPERATIONAL_STATUSES_OPERATIONAL',
      },
      {
        value: 'NON_OPERATIONAL',
        icon: 'error',
        label: 'CREATE_UPDATE_EQUIPMENT.STR_NON_OPERATIONAL_STATUSES',
      },
      {
        value: 'DEFECTIVE_BUT_OPERATIONAL',
        icon: 'warning',
        label: 'CREATE_UPDATE_EQUIPMENT.STR_DEFECTIVE_BUT_OPERATIONAL_STATUSES',
      },
    ],
    dropdownWithIcon: true,
  },
];
