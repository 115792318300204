import { createSelector, DefaultProjectorFn, MemoizedSelector, Selector } from '@ngrx/store';
import { ApplicationState } from 'src/app/common';
import { IAlarmsState } from 'src/app/root';
import { IStoreApiList } from '../../../../common/models/store-api-list.model';
import { IApplicationState } from '../../../../common/state/models/app.state.model';
import { IAlarmLists } from '../../../models/alarms.model';

export const alarmsState: Selector<IApplicationState, IAlarmsState> = (state: IApplicationState) => state.alarms;

export const selectAlarms: MemoizedSelector<
  ApplicationState,
  IStoreApiList<IAlarmLists>,
  DefaultProjectorFn<IStoreApiList<IAlarmLists>>
> = createSelector(alarmsState, (state: IAlarmsState) => state.alarms);
