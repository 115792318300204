import { combineReducers } from 'redux';
import { addressBookReducer } from './address-book/address-book.reducers';
import { checklistReducer } from './checklist/checklist.reducers';
import { configurationEquipmentHierarchyReducer } from './equipment-config-and-hierarchy/configuration-equipment-hierarchy.reducers';
import { configurationLocationHierarchyReducer } from './location-config-and-hierarchy/configuration-location-hierarchy.reducers';
import { locationTypeValuesReducer } from './location-type/location-type-values.reducers';
import { organizationReducer } from './organization-settings/organization-settings.reducers';
import { protectorTypeValuesReducer } from './protector-type/protector-type-values.reducers';
import { tasksReducer } from './tasks/tasks.reducers';

export const configurationReducer: any = combineReducers<{
  equipmentHierarchy: typeof configurationEquipmentHierarchyReducer;
  protectorTypeValues: typeof protectorTypeValuesReducer;
  tasks: typeof tasksReducer;
  locationHierarchy: typeof configurationLocationHierarchyReducer;
  locationTypeValues: typeof locationTypeValuesReducer;
  checklist: typeof checklistReducer;
  addressBook: typeof addressBookReducer;
  organizationSettings: typeof organizationReducer;
}>({
  equipmentHierarchy: configurationEquipmentHierarchyReducer,
  protectorTypeValues: protectorTypeValuesReducer,
  tasks: tasksReducer,
  locationHierarchy: configurationLocationHierarchyReducer,
  locationTypeValues: locationTypeValuesReducer,
  checklist: checklistReducer,
  addressBook: addressBookReducer,
  organizationSettings: organizationReducer,
});
