// eslint-disable-next-line no-shadow
export enum LegalTermsActionType {
  REQUEST_GDPR_DATA = 'REQUEST_GDPR_DATA',
  RECEIVE_GDPR_DATA = 'RECEIVE_GDPR_DATA',
  ERROR_RECEIVE_GDPR_DATA = 'ERROR_RECEIVE_GDPR_DATA',

  REQUEST_ACCEPT_GDPR = 'REQUEST_ACCEPT_GDPR',
  RECEIVE_ACCEPT_GDPR = 'RECEIVE_ACCEPT_GDPR',
  ERROR_RECEIVE_ACCEPT_GDPR = 'ERROR_RECEIVE_ACCEPT_GDPR',

  REQUEST_TAC_DATA = 'REQUEST_TAC_DATA',
  RECEIVE_TAC_DATA = 'RECEIVE_TAC_DATA',
  ERROR_RECEIVE_TAC_DATA = 'ERROR_RECEIVE_TAC_DATA',

  REQUEST_ACCEPT_TAC = 'REQUEST_ACCEPT_TAC',
  RECEIVE_ACCEPT_TAC = 'RECEIVE_ACCEPT_TAC',
  ERROR_RECEIVE_ACCEPT_TAC = 'ERROR_RECEIVE_ACCEPT_TAC',

  REQUEST_DPA_DATA = 'REQUEST_DPA_DATA',
  RECEIVE_DPA_DATA = 'RECEIVE_DPA_DATA',
  ERROR_RECEIVE_DPA_DATA = 'ERROR_RECEIVE_DPA_DATA',

  REQUEST_ACCEPT_DPA = 'REQUEST_ACCEPT_DPA',
  RECEIVE_ACCEPT_DPA = 'RECEIVE_ACCEPT_DPA',
  ERROR_RECEIVE_ACCEPT_DPA = 'ERROR_RECEIVE_ACCEPT_DPA',
}
