<ignis-maintenance-page
  *ngIf="isMaintenanceModeFromAuthorized; else appRunning"
  [maintenanceText]="maintenanceText"
></ignis-maintenance-page>
<ng-template #appRunning>
  <ignis-unauthorized-page
    *ngIf="(isUnauthorized && legalTermsAccepted) || isLicenseExpired; else isAuthorized"
    [isLicenseExpired]="isLicenseExpired"
  ></ignis-unauthorized-page>

  <ng-template #isAuthorized>
    <div
      id="wrapper"
      class="odx-app-main d-flex flex-column"
      *ngIf="translationLoaded && isMapLoaded && isGDPRAccepted && isTaCAccepted && isDPAAccepted"
    >
      <ignis-export-in-progress [openModal]="isExporting$ | async"></ignis-export-in-progress>
      <ignis-sidebar
        [userRole]="userRole"
        [isOpen]="isOpenSidebar"
        (openSidebar)="onOpenSidebar($event)"
      ></ignis-sidebar>
      <ignis-navbar
        [alarmsInfo]="alarmsInfo"
        [permissions]="permissions"
        (openSidebar)="onOpenSidebar($event)"
        (openAlarmsWidget)="openAlarms()"
      ></ignis-navbar>
      <ignis-notification></ignis-notification>
      <ignis-alarms
        [displayWidget]="displayAlarms"
        [activeAlarms]="activeAlarms"
        [clearedAlarms]="clearedAlarms"
        [dateFormat]="dateFormat"
        (handleCloseWidget)="closeAlarms($event)"
        (handleNewAlarmsInfo)="getNewAlarmsInfo($event)"
      ></ignis-alarms>
      <ignis-alarm-notification
        *ngIf="!displayAlarms && openedAlarms && !router.url?.includes('gdpr')"
        [newAlarms]="newAlarms"
        [dateFormat]="dateFormat"
        (closeNotification)="closeNotification($event)"
        (handleNewAlarmsInfo)="checkAlarmsInfoAfterClosingNotification()"
      ></ignis-alarm-notification>

      <router-outlet></router-outlet>
      <router-outlet name="modal"></router-outlet>
      <router-outlet name="modal2"></router-outlet>
    </div>
  </ng-template>

  <ignis-legal-terms-page
    *ngIf="!isGDPRAccepted || !isTaCAccepted || !isDPAAccepted"
    [gdprMode]="!isGDPRAccepted"
    [dpaMode]="!isDPAAccepted"
    [hyperLink]="legalHyperLink"
    (accepted)="submitLegal()"
  ></ignis-legal-terms-page>
</ng-template>
