import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { IApplicationState } from '../../models/app.state.model';
import { INotificationState } from '../models/notification.model';

/* istanbul ignore next */
export const getApplicationState = (state: IApplicationState): IApplicationState => state;

export const selectNotification: MemoizedSelector<
  IApplicationState,
  INotificationState,
  DefaultProjectorFn<INotificationState>
> = createSelector(getApplicationState, (state: IApplicationState) => state.notification);
