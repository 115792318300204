import { DatePipe } from '@angular/common';
import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { NotificationConstants, TableExportExcelConstants } from 'src/app/common/constants';
import {
  IEntryModel,
  IErrorCodesForCSV,
  ITableColumn,
  ITableSettings,
  PropertyBag,
  TableFilters,
} from 'src/app/common/models';
import { CSVService, NotificationsService } from 'src/app/common/services';
import { INotificationMessage } from 'src/app/common/state/notifications/models/notification.model';
import { ExportExcelService } from 'src/app/report-center/services/export-excel.service';
import { environment } from 'src/environments/environment';
import { AccessControlService } from '../../../root';
import { EquipmentNotificationConstants, WorkshopConstants, WorkshopModuleRoutes } from '../../constants';
import { RAMConnectorErrorsService } from '../../services';
import { EquipmentCRUDActions } from '../../state/actions/equipment-crud/equipment-crud.actions';
import { EquipmentInitialSpecificationActions } from '../../state/actions/equipment-initial-specs';
import { RAMActions } from '../../state/actions/ram';
import { eqImportCSVColumns, tableColumns } from './table-columns';
import { readModelSortKeys, tableCustomFiltersLayout, tableDefaultSorting } from './table-settings';

import {
  CommonConstants,
  EquipmentListTableFiltersConstants,
  IApplicationState,
  IStoreApiItem,
  IStoreApiList,
  LoggerService,
  StorageConstants,
  TableHelperComponent,
} from 'src/app/common';
import {
  extractOnlyDate,
  getDateFormatFromUserProfile,
  processEndDateForTableFilter,
  processStartDateForTableFilter,
} from 'src/app/common/utils';
import {
  IEquipment,
  IEquipmentCategoryList,
  IEquipmentInitialSpecification,
  IEquipmentListPage,
  IEquipmentModel,
  IEquipmentTableData,
  IEquipmentType,
  IRAMStatus,
  ITestStatus,
} from '../../models';
import {
  selectCheckRAMConnectorStatus,
  selectCheckRAMPortStatus,
  selectDeletedEquipment,
  selectEquipmentCSVFile,
  selectEquipmentItem,
  selectEquipmentListPage,
  selectEquipmentSpecification,
} from '../../state/selectors';

@Component({
  selector: 'ignis-inventory',
  templateUrl: './inventory.component.html',
  providers: [DatePipe],
})
export class InventoryComponent extends TableHelperComponent implements OnInit {
  equipmentList: IEquipment[];
  selectedEquipment: IEquipmentTableData = null;
  tableName: string = 'equipment';
  localTableColumns: ITableColumn[] = tableColumns;
  customFilterLayouts: string[] = tableCustomFiltersLayout;
  defaultSorting: string = tableDefaultSorting;
  tableFiltersKey: string = StorageConstants.tablesStorageKeys.EQUIPMENT;
  readModelSortKeys: PropertyBag = readModelSortKeys;
  serviceStatuses: PropertyBag = WorkshopConstants.serviceStatuses;
  operationalStatuses: PropertyBag = WorkshopConstants.operationalStatuses;
  excelTranslationKeys: PropertyBag = TableExportExcelConstants.equipmentsSheetTranslationKeys;

  formatDate: string;
  httpCustomErrorCode: string;
  tableExportList: IEquipmentTableData[] = [];
  openConfirmationDeleteDialog: boolean = false;
  startAutoUpdate: boolean = false;
  activateClickOutside: boolean = true;

  equipmentInitialSpecification: IEquipmentCategoryList;
  filteredEquipmentTypes: IEquipmentType[];
  filteredEquipmentModels: IEquipmentModel[];
  isSubmitting: Observable<boolean>;

  operationalStatus: string;
  nonOperationalStatus: string;
  defectiveButOperationalStatus: string;
  operationalStatusesList: IEntryModel[] = [];

  serviceStatusesList: IEntryModel[] = [];

  importCSVColumns: ITableColumn[] = eqImportCSVColumns;
  importCSVErrorCodes: IErrorCodesForCSV = WorkshopConstants.errorCodeForCSVFile;
  pageUrl: string = WorkshopModuleRoutes.workshop;

  datePipe: DatePipe = inject(DatePipe);
  loggerService: LoggerService = inject(LoggerService);
  translateService: TranslateService = inject(TranslateService);
  equipmentCRUDActions: EquipmentCRUDActions = inject(EquipmentCRUDActions);
  equipmentInitialSpecsActions: EquipmentInitialSpecificationActions = inject(EquipmentInitialSpecificationActions);
  ramActions: RAMActions = inject(RAMActions);
  public notificationsService: NotificationsService = inject(NotificationsService);
  ramConnectorErrorsService: RAMConnectorErrorsService = inject(RAMConnectorErrorsService);
  accessControlService: AccessControlService = inject(AccessControlService);
  exportExcelService: ExportExcelService = inject(ExportExcelService);
  csvService: CSVService = inject(CSVService);

  @ViewChild('fileInput', { read: ElementRef })
  public fileInput: ElementRef<HTMLInputElement>;

  openModal: boolean = false;

  constructor(
    private router: Router,
    private store: Store<IApplicationState>,
  ) {
    super();

    this.populateOperationalDropdown();
    this.populateServiceStatusDropdown();
  }

  ngOnInit(): void {
    this.redirectToMainPageIfFeatureInventoryPageToggleIsOff();
    this.getEquipmentTypes();

    this.formatDate = getDateFormatFromUserProfile(this.translateService);

    this.isLoading$ = combineLatest([
      this.store.select(selectEquipmentItem),
      this.store.select(selectEquipmentListPage),
      this.store.select(selectCheckRAMConnectorStatus),
      this.store.select(selectCheckRAMPortStatus),
    ]).pipe(
      map(
        ([itemState, listState, statusState, portStatus]: [
          IStoreApiItem<IEquipment>,
          IStoreApiItem<IEquipmentListPage>,
          IStoreApiItem<ITestStatus>,
          IStoreApiItem<IRAMStatus>,
        ]) => itemState.isLoading || listState.isLoading || statusState.isLoading || portStatus.isLoading,
      ),
      takeUntil(this.destroy),
    );

    this.store
      .pipe(
        select(selectEquipmentListPage),
        map((equipmentListState: IStoreApiItem<IEquipmentListPage>) => equipmentListState.data),
        takeUntil(this.destroy),
      )
      .subscribe((response: IEquipmentListPage) => {
        if (response) {
          this.equipmentList = structuredClone(response.entries);
          this.totalRecords = response.totalRecords;
          this.totalPages = response.totalPages;

          this.tableHelperCheckOutOfRangePageFilter();

          this.equipmentList.forEach((equipment: IEquipmentTableData) => {
            equipment.outOfService = equipment.outOfService
              ? `${this.datePipe.transform(equipment.outOfService, this.formatDate)}`
              : null;
            equipment.firstRegisteredDate = equipment.firstRegisteredDate
              ? `${this.datePipe.transform(equipment.firstRegisteredDate, this.formatDate)}`
              : null;
          });
        }
      });

    this.isSubmitting = this.store.select(selectCheckRAMConnectorStatus).pipe(
      takeUntil(this.destroy),
      map((statusState: IStoreApiItem<ITestStatus>) => statusState.isLoading),
    );
  }

  redirectToMainPageIfFeatureInventoryPageToggleIsOff(): void {
    if (!this.accessControlService.inventoryFeatureToggle) {
      this.router.navigate(['']);
    }
  }

  populateOperationalDropdown(): void {
    this.operationalStatus = this.translateService.instant(
      'EQUIPMENT_GENERAL.STR_OPERATIONAL_STATUSES_OPERATIONAL',
    ) as string;
    this.nonOperationalStatus = this.translateService.instant(
      'EQUIPMENT_GENERAL.STR_NON_OPERATIONAL_STATUSES',
    ) as string;
    this.defectiveButOperationalStatus = this.translateService.instant(
      'EQUIPMENT_GENERAL.STR_DEFECTIVE_BUT_OPERATIONAL_STATUSES',
    ) as string;

    this.operationalStatusesList = [
      {
        value: this.operationalStatuses.OPERATIONAL,
        label: this.operationalStatus,
        icon: 'feedback-positive',
      },
      { value: this.operationalStatuses.NON_OPERATIONAL, label: this.nonOperationalStatus, icon: 'error' },
      {
        value: this.operationalStatuses.DEFECTIVE_BUT_OPERATIONAL,
        label: this.defectiveButOperationalStatus,
        icon: 'warning',
      },
    ];
  }

  populateServiceStatusDropdown(): void {
    this.serviceStatusesList = [
      {
        value: this.serviceStatuses.OK,
        label: this.translateService.instant('EQUIPMENT_GENERAL.STR_OK_SERVICE_STATUS') as string,
        icon: 'ok',
      },
      {
        value: this.serviceStatuses.WARNING,
        label: this.translateService.instant('EQUIPMENT_GENERAL.STR_WARNING_SERVICE_STATUS') as string,
        icon: 'calendar-warning',
      },
      {
        value: this.serviceStatuses.NON_COMPUTABLE,
        label: this.translateService.instant('EQUIPMENT_GENERAL.STR_NON_COMPUTABLE_STATUS') as string,
        icon: '',
      },
    ];
  }

  processingTableSettings(settings: ITableSettings): void {
    this.rows = settings.pageSize;

    this.store
      .pipe(
        select(selectEquipmentSpecification),
        filter(
          (equipmentInitialSpecification: IStoreApiList<IEquipmentInitialSpecification[]>) =>
            Boolean(equipmentInitialSpecification) && !equipmentInitialSpecification.isLoading,
        ),
        takeUntil(this.destroy),
      )
      .subscribe((equipmentInitialSpecification: IStoreApiList<IEquipmentInitialSpecification[]>) => {
        const equipmentCategories: IEquipmentInitialSpecification[] = equipmentInitialSpecification.data || [];

        this.equipmentInitialSpecification = {
          categories: equipmentCategories.map((category: IEquipmentInitialSpecification) => ({
            ...category,
            types: category.types.map((type: IEquipmentType) => ({
              ...type,
              label: type.name,
              value: type.name,
              models: type.models.map((model: IEquipmentModel) => ({
                ...model,
                label: model.name,
                value: model.name,
              })),
            })),
          })),
        };

        this.filteredEquipmentTypes = this.equipmentInitialSpecification.categories.flatMap(
          (c: IEquipmentInitialSpecification) => c.types,
        );

        this.filteredEquipmentModels = this.filteredEquipmentTypes.flatMap((t: IEquipmentType) => t.models);

        settings.columns.forEach((column: ITableColumn) => {
          switch (column.field) {
            case 'typeId':
              column.dropdownOptions = [...this.filteredEquipmentTypes];
              break;
            case 'model':
              column.dropdownOptions = [...this.filteredEquipmentModels];
              break;
            case 'operationalStatus':
              column.dropdownOptions = [...this.operationalStatusesList];
              break;
            case 'serviceStatus':
              column.dropdownOptions = [...this.serviceStatusesList];
              break;
          }
        });

        this.tableColumns = settings.columns;

        this.tableHelperReadSavedFiltersValues(
          EquipmentListTableFiltersConstants.selectedDropdownsFilterValues,
          EquipmentListTableFiltersConstants.selectedDatesFilterValues,
        );
      });

    this.tableHelperPrepareTableParameters();
  }

  getEquipmentTypes(): void {
    this.equipmentInitialSpecsActions.requestInitialEquipmentSpecification();
  }

  fetchTableData(params: PropertyBag): void {
    this.equipmentCRUDActions.requestEquipmentPage(params, this.isExportMode);
  }

  onSelectEquipment(equipment: IEquipmentTableData): void {
    this.selectedEquipment = equipment;
  }

  openCreateEquipment(): void {
    this.router.navigate(['workshop', 'create']);
    localStorage.removeItem(WorkshopConstants.barCodeToSave);
  }

  openUpdateEquipment(): void {
    this.router.navigate(['workshop', 'update', this.selectedEquipment.aggregateId]);
    localStorage.removeItem(WorkshopConstants.barCodeToSave);
  }

  openSelectedEquipmentOnDblClick(): void {
    if (this.selectedEquipment) {
      this.router.navigate(['workshop', 'update', this.selectedEquipment.aggregateId]);
    }
  }

  changeColumnVisibility(columns: ITableColumn[]): void {
    this.tableHelperResetTablePaging();

    columns.forEach((column: ITableColumn) => {
      if (!column.visible) {
        if (this.tableHelperCheckForSorting(column.field)) {
          this.tableHelperResetTableSorting();
        }

        this.tableHelperHideAndResetTableColumn(this.localTableColumns, column);
      }
    });

    this.tableHelperReadSavedFiltersValues(
      EquipmentListTableFiltersConstants.selectedDropdownsFilterValues,
      EquipmentListTableFiltersConstants.selectedDatesFilterValues,
    );
    this.tableHelperSaveTableSettings(columns);
    this.tableHelperSetAllTableFilters(this.filtersData);

    this.tableHelperPrepareTableParameters();
  }

  getFilterTableValue(event: TableFilters): void {
    let localFilter: TableFilters = { ...event };

    if ('typeId' in event) {
      localFilter = { types: event.typeId as string };
      Object.assign(this.filtersData, localFilter);
    } else if ('model' in event) {
      localFilter = { models: event.model as string };
      Object.assign(this.filtersData, localFilter);
    } else if ('outOfService' in event) {
      this.processTableDateFilter(event, localFilter, 'outOfService', 'outOfServiceStartDate', 'outOfServiceEndDate');
    } else if ('firstRegisteredDate' in event) {
      this.processTableDateFilter(
        event,
        localFilter,
        'firstRegisteredDate',
        'firstRegisteredStartDate',
        'firstRegisteredEndDate',
      );
    } else {
      Object.assign(this.filtersData, event);
    }

    delete localFilter?.outOfService;
    delete localFilter?.firstRegisteredDate;

    this.tableHelperSetTableFilters(Object.keys(localFilter)[0], Object.values(localFilter)[0]);
  }

  processTableDateFilter(
    event: PropertyBag,
    localFilter: PropertyBag,
    columnName: string,
    startDateFilterName: string,
    endDateFilterName: string,
  ): void {
    const eventProcessed: string | PropertyBag = this.processTimeStampFilter(
      event,
      columnName,
      startDateFilterName,
      endDateFilterName,
    );

    if (eventProcessed) {
      localFilter = {
        [startDateFilterName]: eventProcessed[startDateFilterName] as string,
        [endDateFilterName]: eventProcessed[endDateFilterName] as string,
      };

      Object.assign(this.filtersData, localFilter);

      Object.keys(localFilter).forEach((key: string) => {
        this.tableHelperSetTableFilters(key, localFilter[key]);
      });
    }
  }

  processTimeStampFilter(
    event: PropertyBag,
    oldParamName: string,
    minParamName: string,
    maxParamName: string,
  ): PropertyBag | string {
    // eslint-disable-next-line no-prototype-builtins
    if (event.hasOwnProperty(oldParamName) && event[oldParamName] && event[oldParamName][1]) {
      if (Object.values(event)[0] === 'reset-date') {
        return 'reset-date';
      } else {
        if (oldParamName === 'outOfService') {
          const intervalEndDate: string = event[oldParamName][1] ? Object.values(event)[0][1].toString() : '';

          return {
            ...this.tableService.tableOnlyDateParameter(
              extractOnlyDate(Object.values(event)[0][0].toString()),
              minParamName,
            ),
            ...this.tableService.tableOnlyDateParameter(extractOnlyDate(intervalEndDate), maxParamName),
          };
        } else {
          return {
            ...this.tableService.tableDateParameter(
              processStartDateForTableFilter(new Date(Object.values(event)[0][0])),
              minParamName,
            ),
            ...this.tableService.tableDateParameter(
              processEndDateForTableFilter(new Date(Object.values(event)[0][1])),
              maxParamName,
            ),
          };
        }
      }
    }
  }

  openDeleteEquipmentDialog(): void {
    this.openConfirmationDeleteDialog = true;
    this.activateClickOutside = false;
  }

  closeDeleteEquipmentDialog(confirmation: boolean): void {
    if (confirmation) {
      this.deleteEquipment();
    } else {
      this.openConfirmationDeleteDialog = false;
      this.activateClickOutside = true;
    }
  }

  deleteEquipment(): void {
    const identifier: string = this.selectedEquipment.identifier;

    this.equipmentCRUDActions.requestEquipmentDelete(this.selectedEquipment);

    this.store
      .pipe(
        select(selectDeletedEquipment),
        filter((equipment: IStoreApiItem<IEquipment>) => !equipment.isLoading),
        take(1),
      )
      .subscribe((equipment: IStoreApiItem<IEquipment>) => {
        if (equipment.isSuccess) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            EquipmentNotificationConstants.notificationCodes.DELETE_EQUIPMENT_SUCCESS,
            EquipmentNotificationConstants.notificationCodes,
            { equipment: identifier },
          );
          this.tableHelperPrepareTableParameters();
        } else {
          this.httpCustomErrorCode = equipment.errors?.error.code as string;

          if (
            this.httpCustomErrorCode.toString() ===
            EquipmentNotificationConstants.notificationCodes.ENTITY_DOES_NOT_EXIST.value
          ) {
            this.callEquipmentListErrorNotification(
              EquipmentNotificationConstants.notificationCodes.ENTITY_DOES_NOT_EXIST,
            );
          }
        }
      });
  }

  openEquipmentTasksTab(): void {
    this.activateClickOutside = false;

    this.router.navigate(['workshop', 'update', this.selectedEquipment.aggregateId]);
    localStorage.setItem(WorkshopConstants.openTasksTab, 'true');
  }

  redirectToHistory(): void {
    this.router.navigate(['workshop', 'history', this.selectedEquipment.aggregateId]);
  }

  downloadTemplate(): void {
    this.csvService.downloadFile(
      `${environment.API_URLS.EQUIPMENT}/equipment/import/csv-template`,
      'IMPORT_CSV.STR_INVENTORY_FILE_NAME',
    );
  }

  uploadFile(event: File[]): void {
    this.isLoading$ = of(true);
    const formData: FormData = new FormData();

    formData.append('file', event[0], event[0].name);

    this.equipmentCRUDActions.uploadCSVFile(formData);

    this.fileInput.nativeElement.value = '';

    this.checkUploadedEquipments();
  }

  checkUploadedEquipments(): void {
    this.store
      .pipe(
        select(selectEquipmentCSVFile),
        filter((response: IStoreApiItem<string>) => !response.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((response: IStoreApiItem<string>) => {
        if (response.errors) {
          this.httpCustomErrorCode = response.errors?.error?.data as string;

          if (this.httpCustomErrorCode?.length) {
            this.openModal = true;

            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.HIDDEN,
              NotificationConstants.commonCodes.UNEXPECTED_ERROR,
              EquipmentNotificationConstants.notificationCodes,
            );
          } else if ((response.errors?.error.statusCode as number) !== 404) {
            this.httpCustomErrorCode = response.errors?.error.code as string;

            this.callEquipmentListErrorNotification(this.httpCustomErrorCode.toString());
          }

          this.isLoading$ = of(false);
        } else if (response.isSuccess) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            EquipmentNotificationConstants.notificationCodes.UPLOAD_EQUIPMENT_CSV_SUCCESS,
            EquipmentNotificationConstants.notificationCodes,
          );

          this.tableHelperPrepareTableParameters();

          this.isLoading$ = of(false);
        }
      });
  }

  callEquipmentListErrorNotification(errorCode: INotificationMessage | string | number): void {
    this.notificationsService.requestShowNotification(
      CommonConstants.notificationType.ERROR,
      errorCode,
      EquipmentNotificationConstants.notificationCodes,
    );
  }

  closeModal(event: boolean): void {
    this.openModal = event;
  }

  exportExcelFile(): void {
    this.isExportMode = true;
    this.exportExcelService.genericExport(this.getDataForExport, this.preparationForExport).then((data: unknown[]) => {
      this.excelArray = data;
      this.isExportMode = false;
    });
  }

  preparationForExport = (equipment: IEquipmentTableData): void => {
    equipment.outOfService = equipment.outOfService
      ? `${this.datePipe.transform(equipment.outOfService, this.formatDate)}`
      : null;
    equipment.firstRegisteredDate = equipment.firstRegisteredDate
      ? `${this.datePipe.transform(equipment.firstRegisteredDate, this.formatDate)}`
      : null;

    equipment.operationalStatus = this.translateService.instant(
      WorkshopConstants.operationalStatusLocalize.status.find(
        (t: IEntryModel) => t.value === equipment.operationalStatus,
      )?.localizedName || equipment.operationalStatus,
    ) as string;
    equipment.serviceStatus =
      equipment.serviceStatus === WorkshopConstants.serviceStatuses.OK
        ? (this.translateService.instant('EQUIPMENT_GENERAL.STR_OK_SERVICE_STATUS') as string)
        : (this.translateService.instant('EQUIPMENT_GENERAL.STR_WARNING_SERVICE_STATUS') as string);
  };

  getDataForExport = (page: number = 0): void => {
    this.tableHelperPrepareTableParameters(page);
  };

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();
  }
}
