<form odxForm [formGroup]="form" (ngSubmit)="onSubmit.emit()">
  <section odxLayout="grid">
    <div odxLayout="12 6@tablet 6@desktop">
      <odx-form-field variant="full" label="{{ 'CONFIGURATION_CHECKLIST.STR_CHECKLIST_NAME' | translate }}">
        <input
          type="text"
          odxFormFieldControl
          ignisDisableAutocomplete
          id="name"
          data-test-id="configuration_checklist.checklist_name"
          formControlName="name"
          placeholder="{{ 'CONFIGURATION_CHECKLIST.STR_CHECKLIST_NAME_PLACEHOLDER' | translate }}"
        />
      </odx-form-field>
    </div>
    <div odxLayout="12 12@desktop">
      <odx-form-field variant="full" label=" {{ 'CONFIGURATION_CHECKLIST.STR_CHECKLIST_DESCRIPTION' | translate }}">
        <textarea
          odxFormFieldControl
          ignisDisableAutocomplete
          class="form-control text-area"
          id="description"
          data-test-id="configuration_checklist.checklist_description"
          formControlName="description"
          class="custom-form-textarea"
          rows="3"
        >
        </textarea>
      </odx-form-field>

      <div class="mb-3">
        <hr class="parameter-divider" />
      </div>
    </div>

    @for (item of items().controls; track item; let itemIndex = $index) {
      <div formArrayName="items" odxLayout="12 12@desktop">
        <div [formGroupName]="itemIndex" odxLayout="grid">
          <div odxLayout="12 6@tablet 6@desktop">
            <odx-checkbox
              formControlName="required"
              ngDefaultControl
              data-test-id="configuration_checklist.checklist_required"
            >
              {{ 'CONFIGURATION_CHECKLIST.STR_CHECKLIST_REQUIRED' | translate }}
            </odx-checkbox>
          </div>
          <div odxLayout="12 6@tablet 6@desktop" class="d-flex align-items-center justify-content-end">
            <odx-icon
              name="close"
              size="medium"
              class="remove-parameter-btn"
              data-test-id="configuration_checklist.remove_parameter"
              (click)="removeParameter(itemIndex)"
              [ngClass]="{ ' disabled-remove-btn': items().controls.length < 2 }"
            ></odx-icon>
          </div>

          <div odxLayout="12 6@tablet 6@desktop" class="custom-form-field-pr-10">
            <odx-form-field
              variant="full"
              [ngClass]="{ 'has-error': httpCustomErrorCode && item.value?.name?.trim().length < 1 }"
            >
              <input
                type="text"
                odxFormFieldControl
                ignisDisableAutocomplete
                formControlName="name"
                data-test-id="configuration_checklist.checklist_parameter_name"
                (keyup)="checkInputValid(item)"
                placeholder="{{ 'CONFIGURATION_CHECKLIST.STR_PARAMETER_NAME' | translate }}"
              />

              <odx-icon
                *ngIf="httpCustomErrorCode && item.value?.name?.trim().length < 1"
                name="info"
                class="custom-validation-icon"
                iconSet="core"
                [odxTooltip]="checklistNameFieldTooltipContent"
                [odxTooltipVisible]="httpCustomErrorCode && item.value?.name?.trim().length < 1"
              >
                <ng-template #checklistNameFieldTooltipContent>
                  <div id="item-error-tooltip" data-test-id="configuration_checklist.item_error_tooltip">
                    {{ 'CONFIGURATION_CHECKLIST.STR_NO_FIELD_EMPTY' | translate }}
                  </div>
                </ng-template>
              </odx-icon>
            </odx-form-field>
          </div>

          <div odxLayout="12 6@tablet 6@desktop" class="custom-form-field-pl-10">
            <div class="odx-form-field">
              <div class="w-100">
                <p-dropdown
                  #dd1
                  (onShow)="dropdownService.openDropdown(dd1)"
                  (onHide)="dropdownService.closeDropdown(dd1)"
                  ngDefaultControl
                  optionValue="value"
                  formControlName="type"
                  [dropdownIcon]="dropdownIconCSSClass"
                  [options]="types"
                  [appendTo]="createUpdateChecklist"
                  [placeholder]="'CONFIGURATION_CHECKLIST.STR_PARAMETER_TYPE' | translate"
                  data-test-id="configuration_checklist.parameter_type"
                  [showClear]="false"
                  (onChange)="changeType($event, itemIndex)"
                >
                  <ng-template let-selectedItem pTemplate="selectedItem">
                    <span>{{ selectedItem?.localizedName | translate }}</span>
                  </ng-template>
                  <ng-template let-option pTemplate="item">
                    <span>{{ option?.localizedName | translate }}</span>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>

          @if (item.get('type').value === checklistParameterTypes.NUMERIC_TYPE) {
            <div odxLayout="12 6@tablet 6@desktop" class="custom-form-field-pr-10">
              <odx-form-field
                variant="full"
                class="numeric-type-padding-top"
                [ngClass]="{
                  'has-error':
                    httpCustomErrorCode &&
                    ParseFloat(item.get('minimumValue').value) > ParseFloat(item.get('maximumValue')?.value),
                }"
              >
                <input
                  type="text"
                  ignisDisableAutocomplete
                  odxFormFieldControl
                  formControlName="minimumValue"
                  id="minimumValue"
                  data-test-id="configuration_checklist.numeric_min_value"
                  placeholder="{{ 'CONFIGURATION_CHECKLIST.STR_MINIMUM_VALUE' | translate }}"
                  (keydown)="httpCustomErrorCode = null"
                  (keyup)="checkInputValid(item)"
                  [ignisCustomDigitDecimalNumber]="'items.' + itemIndex + '.minimumValue'"
                  [directiveFormData]="form"
                />

                <odx-icon
                  *ngIf="
                    httpCustomErrorCode &&
                    ParseFloat(item.get('minimumValue').value) > ParseFloat(item.get('maximumValue')?.value)
                  "
                  name="info"
                  class="custom-validation-icon"
                  iconSet="core"
                  [odxTooltip]="minValueTooltipContent"
                  [odxTooltipVisible]="
                    httpCustomErrorCode &&
                    ParseFloat(item.get('minimumValue').value) > ParseFloat(item.get('maximumValue')?.value)
                  "
                >
                  <ng-template #minValueTooltipContent>
                    <div
                      id="min-value-error-tooltip"
                      data-test-id="configuration_checklist.numeric_min_value_ERROR_TOOLTIP"
                    >
                      {{ 'CONFIGURATION_NOTIFICATION.STR_ADDING_CHECKLIST_NUMERIC_TYPE_ERROR' | translate }}
                    </div>
                  </ng-template>
                </odx-icon>
              </odx-form-field>
            </div>

            <div odxLayout="12 6@tablet 6@desktop" class="custom-form-field-pl-10">
              <odx-form-field variant="full" class="numeric-type-padding-top">
                <input
                  type="text"
                  ignisDisableAutocomplete
                  odxFormFieldControl
                  formControlName="maximumValue"
                  id="maximumValue"
                  data-test-id="configuration_checklist.numeric_max_value"
                  placeholder="{{ 'CONFIGURATION_CHECKLIST.STR_MAXIMUM_VALUE' | translate }}"
                  (keyup)="checkInputValid(item)"
                  [ignisCustomDigitDecimalNumber]="'items.' + itemIndex + '.maximumValue'"
                  [directiveFormData]="form"
                />
              </odx-form-field>
            </div>

            <div odxLayout="12 6@tablet 6@desktop" class="custom-form-field-pr-10">
              <odx-form-field variant="full" class="numeric-type-padding-top">
                <input
                  type="text"
                  ignisDisableAutocomplete
                  odxFormFieldControl
                  formControlName="unit"
                  placeholder="{{ 'CONFIGURATION_CHECKLIST.STR_UNIT' | translate }}"
                  data-test-id="configuration_checklist.numeric_unit"
                />
              </odx-form-field>
            </div>
          }

          @if (item.get('type').value === checklistParameterTypes.MULTIPLE_SELECTION_TYPE) {
            <div odxLayout="12 6@tablet 6@desktop offset-7" class="custom-form-field-pl-10" formArrayName="options">
              @for (option of options(itemIndex)?.controls; track option; let optionIndex = $index; let last = $last) {
                <div [formGroupName]="optionIndex">
                  <odx-form-field
                    variant="full"
                    [ngClass]="{ 'has-error': httpCustomErrorCode && option.value?.name?.trim().length < 1 }"
                    class="multiple-type-padding-top"
                  >
                    <input
                      type="text"
                      ignisDisableAutocomplete
                      odxFormFieldControl
                      formControlName="name"
                      data-test-id="configuration_checklist.multiple_option"
                      placeholder="{{ 'CONFIGURATION_CHECKLIST.STR_NEW_OPTION_PLACEHOLDER' | translate }}"
                      (keyup)="checkInputValid(option)"
                    />

                    <odx-icon
                      *ngIf="httpCustomErrorCode && option.value?.name?.trim().length < 1"
                      name="info"
                      class="custom-validation-icon create-update-checklist-validation-icon"
                      iconSet="core"
                      [odxTooltip]="multipleOptionNameTooltipContent"
                      [odxTooltipVisible]="httpCustomErrorCode && option.value?.name?.trim().length < 1"
                    >
                      <ng-template #multipleOptionNameTooltipContent>
                        <div data-test-id="configuration_checklist.multiple_option_ERROR_TOOLTIP">
                          {{ 'CONFIGURATION_CHECKLIST.STR_NO_FIELD_EMPTY' | translate }}
                        </div>
                      </ng-template>
                    </odx-icon>

                    <odx-icon
                      name="close"
                      class="remove-multiple-selection-option-btn"
                      data-test-id="configuration_checklist.multiple_option_REMOVE_BTN"
                      (click)="removeOption(itemIndex, optionIndex)"
                      [ngClass]="{ 'disabled-remove-btn': item.controls.options.controls.length < 2 }"
                    ></odx-icon>
                  </odx-form-field>

                  <div class="col-lg-6 mb-3" *ngIf="last">
                    <a
                      odxButton
                      (click)="addOption(itemIndex, optionIndex)"
                      variant="secondary"
                      data-test-id="configuration_checklist.multiple_option_ADD_BTN"
                    >
                      {{ 'CONFIGURATION_CHECKLIST.STR_ADD_OPTION_BTN' | translate }}
                    </a>
                  </div>
                </div>
              }
            </div>
          }
        </div>

        <div class="mb-3">
          <hr class="parameter-divider" />
        </div>
      </div>
    }
  </section>
</form>
