import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ModalRef, ModalService } from '@odx/angular/components/modal';
import { take, takeUntil } from 'rxjs';
import { OnDestroyMixin } from '../../mixins/destroy-mixin';

@Component({
  selector: 'ignis-delete-confirmation-modal',
  templateUrl: './delete-confirmation-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteConfirmationModalComponent extends OnDestroyMixin() implements OnChanges {
  @Input() isOpen: boolean = false;
  @Input() isLoading: boolean;
  @Output() handleDeleteModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  modalReference: ModalRef<any>;

  @ViewChild('confirmDeleteModalTemplate', { read: TemplateRef })
  public confirmDeleteModalTemplate: TemplateRef<any>;

  constructor(private modalService: ModalService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpen && this.isOpen) {
      this.modalReference = this.modalService.open(this.confirmDeleteModalTemplate, {
        size: 'small',
        dismissable: false,
      });

      this.modalReference.onClose$.pipe(take(1)).subscribe((isDeleteConfirmed: boolean) => {
        if (isDeleteConfirmed) {
          this.handleDeleteModal.emit(isDeleteConfirmed);
        }

        this.handleDeleteModal.emit(false);
      });

      this.modalReference.onDismiss$.pipe(takeUntil(this.destroy)).subscribe(() => {
        this.handleDeleteModal.emit(false);

        this.destroy.next();
      });
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(): void {
    if (this.modalReference?.onClose$) {
      this.modalReference.close('');
    }
  }
}
