import { CreateUpdateEventMarkerComponent } from '../components/view-update-incident/incident-analysis/create-update-event-marker/create-update-event-marker.component';
import { ViewUpdateIncidentComponent } from '../components/view-update-incident/view-update-incident.component';

export class DeactivateIncidentGuard {
  canDeactivate(component: ViewUpdateIncidentComponent) {
    return component.canDeactivate();
  }
}

export class DeactivateEventMarkerGuard {
  canDeactivate(component: CreateUpdateEventMarkerComponent) {
    return component.canDeactivate();
  }
}
