<div
  class="row odx-form-field--horizontal odx-form-field"
  [ngClass]="{ 'odx-form-field--required': item.required }"
  [formGroup]="form"
>
  <div class="col-6">
    <label class="odx-form-field__label" [for]="item.id">
      <odx-form-field-label class="odx-form-field-label">
        {{ item.name | translate }}
      </odx-form-field-label>
      @if (item.required) {
        <span class="odx-form-field__label-is-required ng-star-inserted">*</span>
      }
    </label>
  </div>
  <div class="col-6">
    <div [formGroup]="form">
      <input type="hidden" [formControlName]="item.id" />
    </div>
    <div class="w-100">
      <p-multiSelect
        #multiSelectControl
        ngDefaultControl
        [dropdownIcon]="dropdownIconCSSClass"
        [attr.data-test-id]="item.id"
        class="table-multiSelect-filter multiselect-style"
        optionLabel="label"
        [options]="multiSelectOptions"
        [ngModel]="selectedOptions"
        (ngModelChange)="selectedOptions = $event"
        [ngModelOptions]="{ standalone: true }"
        [emptyMessage]="'COMPLETE_CHECKLIST.STR_NO_RESULTS_FOUND' | translate"
        (onPanelShow)="removeUnusedElements()"
        (onChange)="markMultipleItem($event.value)"
      >
        <ng-template let-option pTemplate="item">
          <div class="p-multiselect-representative-option">
            <span class="p-ms-1" [title]="option.label">{{ option.label }}</span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <button
            type="button"
            class="p-multiselect-close p-link p-multiselect-custom-close-btn"
            (click)="removeSelectedItems(); multiSelectControl.hide()"
            data-test-id="complete_checklist.clear_multiple_item"
          >
            <span class="p-multiselect-close-icon pi pi-times"></span>
          </button>
          <div class="p-multiselect-filter-container odx-form-field__control">
            <input
              ngDefaultControl
              class="odx-form-field-control"
              id="multi-select-input"
              [placeholder]="'COMPLETE_CHECKLIST.STR_SEARCH_OPTION' | translate"
              [ngModel]="searchedOption"
              (ngModelChange)="searchedOption = $event"
              [ngModelOptions]="{ standalone: true }"
              data-test-id="complete_checklist.searched_item"
              type="text"
              id="{{ item.id }}search"
              ignisDisableAutocomplete
              (keyup)="onItemsFilter($event.target.value)"
            />
            <odx-icon
              class="clear-input"
              name="close"
              size="medium"
              *ngIf="searchedOption?.length > 0"
              (click)="searchedOption = ''; onItemsFilter('')"
            ></odx-icon>
          </div>
        </ng-template>
        <ng-template let-value pTemplate="selectedItems">
          <div *ngIf="value !== null && value?.length === 1">
            <div *ngFor="let val of value" class="ui-multiselected-item-token ui-corner-all">
              <odx-icon *ngIf="val?.icon" [name]="val.icon" size="inline" class="multiselect-icon-option"></odx-icon>
              <span class="ellipsis-text" [title]="val?.label">{{ val?.label }}</span>
            </div>
          </div>
          <ignis-multiple-items-selected [value]="value"></ignis-multiple-items-selected>
        </ng-template>
      </p-multiSelect>
    </div>
  </div>
</div>
