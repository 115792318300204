<ngx-simplebar [options]="scrollbarOptions" class="incident-information-scrollbar scrollbar-offset">
  <form odxForm [formGroup]="incidentForm" [readonly]="incidentForm.disabled">
    <section
      odxLayout="grid"
      class="mb-2"
      formGroupName="incidentData"
      [ngClass]="{ 'incident-divider': ecps.controls.length > 0 }"
    >
      <h2 class="mb-3" odxLayout="12">
        {{ 'INCIDENT_INFORMATION.STR_INCIDENT_DATA' | translate }}
      </h2>

      <div
        class="corrected-incident"
        *ngIf="selectedIncident.manuallyEdited"
        data-test-id="incident_information.manually_edited"
      >
        <odx-icon size="inline" name="log-edit"></odx-icon>
        <span class="manually-edited-label">{{
          'INCIDENT_INFORMATION.STR_CORRECTED_INCIDENT_TOOLTIP' | translate
        }}</span>
      </div>

      <div odxLayout="12 8@tablet">
        <div odxLayout="grid" class="custom-grid-gap">
          <div odxLayout="12 12@desktop">
            <div odxLayout="grid" class="custom-grid-gap">
              <div odxLayout="12 6@desktop" class="custom-form-field-pr-10">
                <odx-form-field
                  variant="horizontal"
                  label="{{ 'INCIDENT_INFORMATION.STR_INCIDENT_NUMBER' | translate }}"
                  [readonly]="incidentForm.get('incidentData.incidentNumber').disabled"
                >
                  <input
                    type="text"
                    ignisDisableAutocomplete
                    id="incidentNumber"
                    data-test-id="incident_information.incident_number"
                    formControlName="incidentNumber"
                    odxFormFieldControl
                    placeholder="{{ 'INCIDENT_INFORMATION.STR_INCIDENT_NUMBER_PLACEHOLDER' | translate }}"
                  />
                </odx-form-field>
              </div>

              <div odxLayout="12 6@desktop" class="custom-form-field-pl-10 custom-form-field-pr-10">
                <div class="odx-form-field--horizontal odx-form-field">
                  <label class="odx-form-field__label" for="incidentSpecialExposure">
                    <odx-form-field-label class="odx-form-field-label">
                      {{ 'INCIDENT_INFORMATION.STR_SPECIAL_EXPOSURE' | translate }}
                    </odx-form-field-label>
                  </label>
                  <div class="w-100 mb-15">
                    <ng-select
                      #specialExposureDD
                      ngDefaultControl
                      data-test-id="incident_information.special_exposure"
                      formControlName="incidentSpecialExposure"
                      [items]="specialExposuresToShow"
                      bindValue="label"
                      bindValue="value"
                      [clearable]="true"
                      clearAllText=""
                      [placeholder]="'INCIDENT_INFORMATION.STR_SPECIAL_EXPOSURE_PLACEHOLDER' | translate"
                      [notFoundText]="'INCIDENT_INFORMATION.STR_NO_ENTRIES' | translate"
                      (input)="addNewEntryInIncidentDropdown($event, 'specialExposuresToShow', 'specialExposures')"
                      (change)="onEntryChange($event, 'incidentData.incidentSpecialExposure')"
                      (click)="toggleDropdown('specialExposureDD')"
                      (blur)="ddAreOpened = []"
                    >
                      <ng-template ng-label-tmp let-item="item">
                        <span title="{{ item?.label }}">
                          {{ item.label }}
                        </span>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item">
                        <span title="{{ item.label }}">
                          {{ item.label }}
                        </span>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div odxLayout="12 12@desktop">
            <div odxLayout="grid" class="custom-grid-gap">
              <div odxLayout="12 6@desktop" class="custom-form-field-pr-10">
                <div class="odx-form-field--horizontal odx-form-field">
                  <label class="odx-form-field__label" for="incidentType">
                    <odx-form-field-label class="odx-form-field-label">
                      {{ 'INCIDENT_INFORMATION.STR_INCIDENT_TYPE' | translate }}
                    </odx-form-field-label>
                  </label>
                  <div class="w-100 mb-15">
                    <ng-select
                      #incidentTypeDD
                      ngDefaultControl
                      data-test-id="incident_information.incident_type"
                      formControlName="incidentType"
                      [items]="incidentTypesToShow"
                      bindValue="label"
                      bindValue="value"
                      [clearable]="true"
                      clearAllText=""
                      [placeholder]="'INCIDENT_INFORMATION.STR_INCIDENT_TYPE_PLACEHOLDER' | translate"
                      [notFoundText]="'INCIDENT_INFORMATION.STR_NO_ENTRIES' | translate"
                      (input)="addNewEntryInIncidentDropdown($event, 'incidentTypesToShow', 'incidentTypes')"
                      (change)="onEntryChange($event, 'incidentData.incidentType')"
                      (click)="toggleDropdown('incidentTypeDD')"
                      (blur)="ddAreOpened = []"
                    >
                    </ng-select>
                  </div>
                </div>
              </div>

              <div odxLayout="12 6@desktop" class="custom-form-field-pl-10 custom-form-field-pr-10">
                <odx-form-field
                  variant="horizontal"
                  label=" {{ 'INCIDENT_INFORMATION.STR_EXPOSURE_INFORMATION' | translate }}"
                  [readonly]="incidentForm.get('incidentData.incidentCategory').disabled"
                >
                  <input
                    type="text"
                    ignisDisableAutocomplete
                    id="incidentCategory"
                    data-test-id="incident_information.exposure_information"
                    formControlName="incidentCategory"
                    odxFormFieldControl
                    placeholder="{{ 'INCIDENT_INFORMATION.STR_EXPOSURE_INFORMATION_PLACEHOLDER' | translate }}"
                  />
                </odx-form-field>
              </div>

              <div odxLayout="12 6@desktop" class="custom-form-field-pr-10">
                <odx-form-field
                  variant="horizontal"
                  label="{{ 'INCIDENT_INFORMATION.STR_START_TIME' | translate }}"
                  class="incident-information-custom-datepicker-form-field"
                  [ngClass]="{ 'has-error': startTimeError }"
                >
                  <div class="w-100" data-test-id="incident_information.start_time_wrap">
                    <ignis-datepicker
                      formControlName="startTime"
                      ngDefaultControl
                      appendTo="body"
                      [selectionMode]="'single'"
                      [formatDate]="formatDate"
                      [showTime]="true"
                      [showSeconds]="true"
                      [defaultRange]="formatLocaleDate(incidentForm.get('incidentData.startTime').value)"
                      [maxDate]="formatLocaleDate(incidentForm.get('incidentData.endTime').value)"
                      (dateSelect)="onStartTimeSelect($event)"
                      [changeToPreviousValue]="true"
                      [clearable]="false"
                      data-test-id="incident_information.start_time"
                    >
                    </ignis-datepicker>

                    <odx-icon
                      *ngIf="startTimeError && errors"
                      name="info"
                      iconSet="core"
                      class="custom-validation-icon-for-datepicker"
                      data-test-id="incident_information.start_time_error_label"
                      [odxTooltip]="
                        startTimeError === incidentErrors.START_TIME_ERROR.value
                          ? ('INCIDENT_NOTIFICATION.STR_START_TIME_ERROR' | translate)
                          : startTimeError === incidentErrors.OVERLAPPING_TIME_RANGE.value ||
                              startTimeError === incidentErrors.OVERLAPPING_START_TIME.value
                            ? ('INCIDENT_NOTIFICATION.STR_OVERLAPPING_TIME_RANGE'
                              | translate
                                : {
                                    displayedValue: overlappingIncidentNumber || '-',
                                  })
                            : ''
                      "
                      [odxTooltipOptions]="{ position: 'right' }"
                      [odxTooltipVisible]="startTimeError && errors"
                    >
                    </odx-icon>
                  </div>
                </odx-form-field>
              </div>

              <div odxLayout="12 6@desktop" class="custom-form-field-pl-10 custom-form-field-pr-10">
                <odx-form-field
                  variant="horizontal"
                  label=" {{ 'INCIDENT_INFORMATION.STR_DEPLOYED_VEHICLES' | translate }}"
                  [readonly]="incidentForm.get('incidentData.deployedVehicles').disabled"
                  [pTooltip]="
                    isTextOverflow ? composeListTooltip(incidentForm.get('incidentData.deployedVehicles').value) : null
                  "
                  tooltipPosition="top"
                >
                  <input
                    #deployedVehicleElem
                    type="text"
                    ignisDisableAutocomplete
                    id="deployedVehicles"
                    data-test-id="incident_information.deployed_vehicles"
                    formControlName="deployedVehicles"
                    odxFormFieldControl
                    placeholder="{{ 'INCIDENT_INFORMATION.STR_DEPLOYED_VEHICLES_PLACEHOLDER' | translate }}"
                  />
                </odx-form-field>
              </div>

              <div odxLayout="12 6@desktop" class="custom-form-field-pr-10">
                <odx-form-field
                  variant="horizontal"
                  label="{{ 'INCIDENT_INFORMATION.STR_END_TIME' | translate }}"
                  class="incident-information-custom-datepicker-form-field"
                  [ngClass]="{ 'has-error': endTimeError }"
                >
                  <div class="w-100" data-test-id="incident_information.end_time_wrap">
                    <ignis-datepicker
                      formControlName="endTime"
                      ngDefaultControl
                      appendTo="body"
                      [selectionMode]="'single'"
                      [formatDate]="formatDate"
                      [showTime]="true"
                      [showSeconds]="true"
                      [minDate]="formatLocaleDate(incidentForm.get('incidentData.startTime').value)"
                      [defaultRange]="formatLocaleDate(incidentForm.get('incidentData.endTime').value)"
                      (dateSelect)="onEndTimeSelect($event)"
                      [changeToPreviousValue]="true"
                      [clearable]="false"
                      data-test-id="incident_information.end_time"
                    >
                    </ignis-datepicker>

                    <odx-icon
                      *ngIf="endTimeError && errors"
                      name="info"
                      iconSet="core"
                      class="custom-validation-icon-for-datepicker"
                      data-test-id="incident_information.end_time_error_tooltip"
                      [odxTooltipVisible]="endTimeError && errors"
                      [odxTooltip]="
                        endTimeError === incidentErrors.END_TIME_ERROR.value
                          ? ('INCIDENT_NOTIFICATION.STR_END_TIME_ERROR' | translate)
                          : endTimeError === incidentErrors.OVERLAPPING_TIME_RANGE.value ||
                              endTimeError === incidentErrors.OVERLAPPING_END_TIME.value
                            ? ('INCIDENT_NOTIFICATION.STR_OVERLAPPING_TIME_RANGE'
                              | translate
                                : {
                                    displayedValue: overlappingIncidentNumber || '-',
                                  })
                            : ''
                      "
                      [odxTooltipOptions]="{ position: 'right' }"
                    >
                    </odx-icon>
                  </div>
                </odx-form-field>
              </div>

              <div odxLayout="12 6@desktop" class="custom-form-field-pl-10 custom-form-field-pr-10">
                <odx-form-field
                  variant="horizontal"
                  label="{{ 'INCIDENT_INFORMATION.STR_DEPLOYED_RESOURCES' | translate }}"
                  [readonly]="incidentForm.get('incidentData.deployedResources').disabled"
                >
                  <input
                    type="text"
                    ignisDisableAutocomplete
                    id="deployedResources"
                    data-test-id="incident_information.deployed_resources"
                    formControlName="deployedResources"
                    odxFormFieldControl
                    placeholder="{{ 'INCIDENT_INFORMATION.STR_DEPLOYED_RESOURCES_PLACEHOLDER' | translate }}"
                  />
                </odx-form-field>
              </div>

              <div odxLayout="12 6@desktop" class="custom-form-field-pr-10">
                <odx-form-field
                  variant="horizontal"
                  label="{{ 'INCIDENT_INFORMATION.STR_OBJECT' | translate }}"
                  [readonly]="incidentForm.get('incidentData.object').disabled"
                >
                  <input
                    type="text"
                    ignisDisableAutocomplete
                    id="object"
                    data-test-id="incident_information.object"
                    formControlName="object"
                    odxFormFieldControl
                    placeholder="{{ 'INCIDENT_INFORMATION.STR_OBJECT_PLACEHOLDER' | translate }}"
                  />
                </odx-form-field>
              </div>

              <div odxLayout="12 6@desktop" class="custom-form-field-pl-10 custom-form-field-pr-10 mb-5">
                <div class="odx-form-field--horizontal odx-form-field">
                  <label class="odx-form-field__label" for="ecpLocations">
                    <odx-form-field-label class="odx-form-field-label">
                      {{ 'INCIDENT_INFORMATION.STR_ECP_LOCATIONS' | translate }}
                    </odx-form-field-label>
                  </label>

                  <button
                    odxButton
                    variant="highlight"
                    [disabled]="!incidentImage"
                    (click)="showMap()"
                    data-test-id="incident_information.show_map_btn"
                  >
                    {{ 'INCIDENT_INFORMATION.STR_SHOW_MAP_BTN' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div odxLayout="12 4@tablet">
        <div odxLayout="grid">
          <div odxLayout="12 12@desktop" class="custom-form-field-pl-10">
            <div class="odx-form-field--horizontal odx-form-field">
              <label class="odx-form-field__label" for="summary">
                <odx-form-field-label class="odx-form-field-label">
                  {{ 'INCIDENT_INFORMATION.STR_SUMMARY' | translate }}
                </odx-form-field-label>
              </label>
              <div class="summary-box">
                <ngx-simplebar>
                  <p class="mb-0">
                    {{ selectedIncident.incidentData.summary?.firefighterNo }}
                    <span *ngIf="selectedIncident.incidentData.summary?.firefighterNo < 2; else displayFirefighters"
                      >{{ 'INCIDENT_INFORMATION.STR_SINGLE_FIREFIGHTER' | translate }}
                    </span>
                    <ng-template #displayFirefighters>
                      <span>{{ 'INCIDENT_INFORMATION.STR_MULTIPLE_FIREFIGHTERS' | translate }}</span>
                    </ng-template>
                  </p>
                  <p class="mb-0">
                    {{ selectedIncident.incidentData.summary?.baSetNo }}
                    <span *ngIf="selectedIncident.incidentData.summary?.baSetNo < 2; else displayBASets">
                      {{ 'INCIDENT_INFORMATION.STR_ONE_BA_SET' | translate }}
                    </span>
                    <ng-template #displayBASets>
                      <span>
                        {{ 'INCIDENT_INFORMATION.STR_MULTIPLE_BA_SETS' | translate }}
                      </span>
                    </ng-template>
                  </p>
                  <p class="mb-0" *ngIf="selectedIncident.incidentData.summary?.manualEventsNo > 0">
                    <span *ngIf="selectedIncident.incidentData.summary?.manualEventsNo < 2; else displayMultipleEvents">
                      {{ selectedIncident.incidentData.summary?.manualEventsNo }}
                      {{ 'INCIDENT_INFORMATION.STR_SINGLE_MANUALLY_INCIDENT' | translate }}
                    </span>
                    <ng-template #displayMultipleEvents>
                      {{ selectedIncident.incidentData.summary?.manualEventsNo }}
                      {{ 'INCIDENT_INFORMATION.STR_MULTIPLE_MANUALLY_INCIDENT' | translate }}
                    </ng-template>
                  </p>
                  <p class="mb-0" *ngIf="noteworthyAlarms?.length > 0">
                    <span *ngIf="relevantAlarmsNo < 2; else multipleRelevantAlarms">
                      {{ relevantAlarmsNo }}
                      {{ 'INCIDENT_INFORMATION.STR_SINGLE_RELEVANT_ALARM' | translate }}
                    </span>
                    <ng-template #multipleRelevantAlarms>
                      <span>
                        {{ relevantAlarmsNo }}
                        {{ 'INCIDENT_INFORMATION.STR_MULTIPLE_RELEVANT_ALARMS' | translate }}
                      </span>
                    </ng-template>
                    <span *ngFor="let alarm of noteworthyAlarms; last as isLast">
                      {{ alarm.number }}
                      {{ alarm.name }}<span *ngIf="!isLast">,</span>
                    </span>
                  </p>
                </ngx-simplebar>
              </div>
            </div>
          </div>

          <div odxLayout="12 12@desktop" class="custom-form-field-pl-10">
            <odx-form-field
              variant="horizontal"
              label="{{ 'INCIDENT_INFORMATION.STR_COMMENT' | translate }}"
              [readonly]="incidentForm.get('incidentData.comment').disabled"
            >
              <textarea
                ignisDisableAutocomplete
                odxFormFieldControl
                class="custom-form-textarea"
                id="comment"
                data-test-id="incident_information.comment"
                formControlName="comment"
                rows="10"
              ></textarea>
            </odx-form-field>
          </div>
        </div>
      </div>
    </section>

    <section
      odxLayout="grid"
      formArrayName="ecps"
      *ngFor="let ecp of ecps.controls; let i = index"
      class="incident-divider"
    >
      <div odxLayout="12 8@tablet">
        <div odxLayout="grid" class="custom-grid-gap">
          <div odxLayout="12 6@desktop" class="custom-form-field-pr-10" [formGroupName]="i">
            <h2 class="mt-5 mb-5">
              {{ 'INCIDENT_INFORMATION.STR_ENTRY_CONTROL_POINT' | translate }} {{ i + 1 < 10 ? '0' : '' }}{{ i + 1 }}
            </h2>
            <odx-form-field
              variant="horizontal"
              label="{{ 'INCIDENT_INFORMATION.STR_UPLOAD_DEVICE' | translate }}"
              [readonly]="true"
              [disabled]="true"
            >
              <input
                type="text"
                ignisDisableAutocomplete
                formControlName="name"
                data-test-id="incident_information.upload_device_name"
                placeholder="{{ 'INCIDENT_INFORMATION.STR_UPLOAD_DEVICE_PLACEHOLDER' | translate }}"
                odxFormFieldControl
                maxlength="50"
              />
            </odx-form-field>

            <odx-form-field
              variant="horizontal"
              label="{{ 'INCIDENT_INFORMATION.STR_HUB_ID' | translate }}"
              [readonly]="true"
              [disabled]="true"
            >
              <input
                type="text"
                ignisDisableAutocomplete
                id="hubId"
                data-test-id="incident_information.hub_id"
                formControlName="hubId"
                placeholder="{{ 'INCIDENT_INFORMATION.STR_HUB_ID_PLACEHOLDER' | translate }}"
                odxFormFieldControl
              />
            </odx-form-field>

            <odx-form-field
              variant="horizontal"
              label="{{ 'INCIDENT_INFORMATION.STR_USER' | translate }}"
              [readonly]="true"
              [disabled]="true"
            >
              <input
                type="text"
                ignisDisableAutocomplete
                id="ecp_user"
                data-test-id="incident_information.ecp_user"
                formControlName="user"
                placeholder="{{ 'INCIDENT_INFORMATION.STR_USER_PLACEHOLDER' | translate }}"
                odxFormFieldControl
              />
            </odx-form-field>
          </div>

          <div
            odxLayout="12 6@desktop"
            class="custom-form-field-pl-10 custom-form-field-pr-10 mb-5"
            [formGroupName]="i"
          >
            <h2 class="mt-5 mb-5">
              {{ 'INCIDENT_INFORMATION.STR_LOCATION_INFO' | translate }}
            </h2>

            <odx-form-field
              variant="horizontal"
              label="{{ 'INCIDENT_INFORMATION.STR_COORDINATES' | translate }}"
              formGroupName="location"
              [readonly]="true"
              [disabled]="true"
            >
              <input
                type="text"
                ignisDisableAutocomplete
                id="displayedCoordinates"
                data-test-id="incident_information.coordinates"
                formControlName="displayedCoordinates"
                placeholder="{{ 'INCIDENT_INFORMATION.STR_COORDINATES' | translate }}"
                odxFormFieldControl
              />
            </odx-form-field>

            <odx-form-field
              variant="horizontal"
              label="{{ 'INCIDENT_INFORMATION.STR_ADDRESS' | translate }}"
              formGroupName="location"
            >
              <input
                type="text"
                ignisDisableAutocomplete
                id="location_address"
                data-test-id="incident_information.location_address"
                formControlName="address"
                placeholder="{{ 'INCIDENT_INFORMATION.STR_ADDRESS_PLACEHOLDER' | translate }}"
                odxFormFieldControl
              />
            </odx-form-field>

            <odx-form-field
              variant="horizontal"
              label="{{ 'INCIDENT_INFORMATION.STR_POSTCODE' | translate }}"
              formGroupName="location"
            >
              <input
                type="text"
                ignisDisableAutocomplete
                id="postcode"
                data-test-id="incident_information.postcode"
                formControlName="postcode"
                placeholder="{{ 'INCIDENT_INFORMATION.STR_POSTCODE_PLACEHOLDER' | translate }}"
                odxFormFieldControl
              />
            </odx-form-field>
          </div>
        </div>
      </div>

      <div odxLayout="12 4@tablet" class="custom-form-field-pl-10" [formGroupName]="i">
        <odx-radio-group [horizontal]="horizontal" formControlName="primary" class="mt-4 mb-5">
          <odx-radio-button
            id="primary-{{ i }}"
            [value]="true"
            [attr.data-test-id]="'incident_information.primary_incident-' + i"
            (click)="selectPrimaryEcp(ecp, i)"
          >
            {{ 'INCIDENT_INFORMATION.STR_DISPLAY_IN_INCIDENT_LIST' | translate }}
          </odx-radio-button>
        </odx-radio-group>
      </div>
    </section>
  </form>
</ngx-simplebar>

<ignis-image-modal
  [openModal]="isModalOpened"
  [image]="incidentImage"
  (handleCloseNoteImageModal)="closeMediaImageModal()"
></ignis-image-modal>
