// eslint-disable-next-line no-shadow
export enum FeatureModuleTileTitleEnum {
  Workshop = 'FIREGROUND_TILES.STR_WORKSHOP',
  RemoteMonitoring = 'FIREGROUND_TILES.STR_REMOTE_MONITORING',
  Logbook = 'FIREGROUND_TILES.STR_LOGBOOK',
  ReportCenter = 'FIREGROUND_TILES.STR_REPORT_CENTER',
  Configuration = 'FIREGROUND_TILES.STR_CONFIGURATION',
  UserManagement = 'FIREGROUND_TILES.STR_USER_MANAGEMENT',
  RoleManagement = 'FIREGROUND_TILES.STR_ROLE_MANAGEMENT',
  DeviceConnection = 'FIREGROUND_TILES.STR_DEVICE_CONNECTION',
  BAWearer = 'FIREGROUND_TILES.STR_BA_WEARER',
}

// eslint-disable-next-line no-shadow
export enum FeatureModuleSidebarTitleEnum {
  Workshop = 'SIDEBAR.STR_WORKSHOP_SIDEBAR',
  RemoteMonitoring = 'SIDEBAR.STR_REMOTE_MONITORING',
  Logbook = 'SIDEBAR.STR_BA_LOG_BOOK',
  ReportCenter = 'SIDEBAR.STR_REPORT_CENTER',
  Configuration = 'SIDEBAR.STR_CONFIGURATION_SIDEBAR',
  UserManagement = 'SIDEBAR.STR_USER_MANAGEMENT',
  RoleManagement = 'SIDEBAR.STR_ROLE_MANAGEMENT',
  DeviceConnection = 'SIDEBAR.STR_DEVICE_CONNECTION',
  BAWearer = 'SIDEBAR.STR_BA_WEARER',
}
