import { PropertyBag } from 'src/app/common';

export const readModelSortKeys: PropertyBag = {
  startTime: 'metadata.startTime',
  incidentNumber: 'metadata.incidentNumber',
  'type.value': 'metadata.incidentType.value',
  incidentType: 'metadata.incidentType.value',
  startHour: 'metadata.startTimePeriod',
  duration: 'metadata.duration',
  ecpAddresses: 'metadata.primaryEcpLocation',
  ecpNames: 'ecps.name',
  hubIds: 'ecp.hubId',
  firefighterNo: 'firefighterNumber',
  occurredEvents: 'occurredEventsSize',
};

export const tableDefaultSorting: string = 'metadata.startTime,DESC';
