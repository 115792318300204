import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ProductName } from 'src/app/root/models/product.model';
import { ProductService } from '../../../services/product/product.service';
import { ProductActionType } from '../actions/product-action-types';
import { ErrorReceiveProductName, ReceiveProductName } from '../actions/product-action-types-creators';

@Injectable()
export class ProductEffects {
  constructor(private productService: ProductService) { }

  requestProductName$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(ProductActionType.REQUEST_PRODUCT_NAME),
      switchMap(() =>
        this.productService.getProductName().pipe(
          map((productName: any) => new ReceiveProductName(new ProductName(productName))),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveProductName(error)))
        )
      )
    );
  });
}
