import { PropertyBag } from 'src/app/common';

export const WorkshopModuleRoutes: PropertyBag = {
  workshop: 'workshop',
  inventory: 'inventory',
  taskResult: 'task-result',
  taskDocumentation: 'task-documentation',
  testEquipment: 'test-equipment',
  testingEquipment: 'testing-equipment',
  testingEquipmentResults: 'testing-equipment',
  workflow: 'workflow',
  cylinder: 'cylinder',
  completeBatchChecklist: 'complete-batch-checklist',
  completeChecklist: 'complete-checklist',
  tasks: 'tasks',
  createEquipment: 'create',
  updateEquipment: 'update',
  equipmentHistory: 'history',
};
