import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalRef, ModalService } from '@odx/angular/components/modal';
import { takeUntil } from 'rxjs/operators';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { PropertyBag } from 'src/app/common/models/common.model';
import { DeviceConnectionConstants } from 'src/app/device-connection/constants/device-connection.constants';

@Component({
  selector: 'ignis-confirm-change-token',
  templateUrl: './confirm-change-token.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmChangeTokenComponent extends OnDestroyMixin() implements OnChanges {
  @Input() isOpen: boolean = false;
  @Input() category: string = '';

  @Output() closeConfirmModal: EventEmitter<boolean> = new EventEmitter();
  modalReference: ModalRef<any>;

  deviceCategories: PropertyBag = DeviceConnectionConstants.deviceCategories;
  @ViewChild('confirmChangeTokenModal', { read: TemplateRef })
  public confirmChangeTokenModal: TemplateRef<any>;

  constructor(
    private modalService: ModalService,
    public router: Router,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpen && this.isOpen) {
      this.modalReference = this.modalService.open(this.confirmChangeTokenModal, {
        size: 'small',
        dismissable: false,
      });

      this.modalReference.onDismiss$.pipe(takeUntil(this.destroy)).subscribe(() => {
        this.closeConfirmModal.emit(false);
        this.destroy.next();
      });

      this.modalReference.onClose$.pipe(takeUntil(this.destroy)).subscribe((shouldCloseModal: boolean) => {
        this.closeConfirmModal.emit(shouldCloseModal);
        this.destroy.next();
      });
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(): void {
    this.modalReference?.close('');
  }
}
