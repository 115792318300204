import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  ApplicationState,
  CheckingSettingsModalsService,
  GenericFooterItemsComponent,
  PropertyBag,
} from 'src/app/common';
import { getRAMSettingsAvailableWorkflows } from 'src/app/common/utils/settings-utils/settings-utils';
import { IRAMSettings } from 'src/app/configuration/models/organization-settings.model';
import { AccessControlService } from 'src/app/root';
import { AppUserPermissionList } from 'src/app/root/models/app-types';
import { WorkshopModuleRoutes } from 'src/app/workshop/constants/workshop-module-routes.constants';

@Component({
  selector: 'ignis-workflow-view-footer',
  templateUrl: './workflow-view-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowViewFooterComponent<T> extends GenericFooterItemsComponent<T> implements OnChanges, OnInit {
  @Input() disableCylinderChargerBtn: boolean;
  @Input() disableChecklistBntFlow: boolean;
  @Input() multiple: boolean = false;
  @Input() isModalOpened: boolean;
  @Input() isSubmitDisabled: boolean = false;
  @Input() isWorkflowInProgress: boolean;
  @Input() displayNoServiceIdTooltip: boolean;
  @Input() displayNoChecklistTooltip: boolean;

  @Output() handleCylinderCharge: EventEmitter<void> = new EventEmitter<void>();
  @Output() handleStartTesting: EventEmitter<void> = new EventEmitter<void>();
  @Output() handleDelete: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() handlePerformCompleteChecklist: EventEmitter<void> = new EventEmitter<void>();

  appUserPermissionList: PropertyBag = AppUserPermissionList;
  permissions: string[] = [];
  ramSettings: IRAMSettings;

  readonly WorkshopModuleRoutes: PropertyBag = WorkshopModuleRoutes;

  public router: Router = inject(Router);
  private checkSettingsModal: CheckingSettingsModalsService = inject(CheckingSettingsModalsService);
  public accessControlService: AccessControlService = inject(AccessControlService);
  private store: Store<ApplicationState> = inject<Store<ApplicationState>>(Store<ApplicationState>);

  constructor() {
    super();

    this.checkSettingsModal.checkIfSettingsModalIsOpen().subscribe((isModalOpened: boolean) => {
      this.isModalOpened = isModalOpened;
      this.cdr.detectChanges();
    });
  }

  ngOnChanges(): void {
    this.permissions = this.accessControlService.permissions;

    if (this.isWorkflowInProgress) {
      this.selectedItems = null;
      this.selectedItem = null;
    }
  }

  ngOnInit(): void {
    getRAMSettingsAvailableWorkflows(this.store, this.destroy).subscribe((response: IRAMSettings) => {
      this.ramSettings = response;
    });
  }

  onStartTestingClick(e: Event): void {
    e.stopPropagation();
    this.handleStartTesting.emit();
  }

  onCylinderCharge(e: Event): void {
    e.stopPropagation();
    this.handleCylinderCharge.emit();
  }

  onDeleteFromWorkflowClick(e: Event): void {
    e.stopPropagation();
    this.selectedItem = null;
    this.selectedItems = null;

    this.handleDelete.emit();
  }

  onPerformCompleteChecklistClick(): void {
    this.handlePerformCompleteChecklist.emit();
  }
}
