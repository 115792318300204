import { IStoreApiItem } from 'src/app/common';
import { StoreApiItem } from '../../../common/models/store-api-item.model';
import { IChecklist } from '../../models/checklist.model';
import { IEditTask, INewTask, IUpdateTaskChildren } from '../../models/equipment-configuration.models';

export interface ITaskState {
  newTask: IStoreApiItem<INewTask>;
  editedTask: IStoreApiItem<IEditTask>;
  deletedTask: IStoreApiItem<Partial<IChecklist>>;
  updateTaskChildren: IStoreApiItem<IUpdateTaskChildren>;
}

export class TaskState implements ITaskState {
  newTask: IStoreApiItem<INewTask> = new StoreApiItem();
  editedTask: IStoreApiItem<IEditTask> = new StoreApiItem();
  deletedTask: IStoreApiItem<Partial<IChecklist>> = new StoreApiItem();
  updateTaskChildren: IStoreApiItem<IUpdateTaskChildren> = new StoreApiItem();
}
