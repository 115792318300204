<odx-form-field [variant]="'simple'" odxLayout="6@phone 12">
  <p-multiSelect
    #multiSelection
    ngDefaultControl
    data-test-id="report_center_table.hub_ids_filter"
    appendTo="body"
    [dropdownIcon]="dropdownIconCSSClass"
    class="table-multiSelect-filter"
    optionLabel="name"
    [ngModel]="selectedHubIds"
    [options]="hubIdsList"
    [placeholder]="'REPORT_CENTER_TABLE.SEARCH_ECP_ADDRESS' | translate"
    [filter]="true"
    [resetFilterOnHide]="true"
    (onChange)="filterService($event.value); saveFilters('selectedHubIds', $event.value)"
  >
    <ng-template pTemplate="header">
      <button
        type="button"
        class="p-multiselect-close p-link p-multiselect-custom-close-btn"
        data-test-id="report_center_list.reset_hub_ids_filter_btn"
        (click)="
          multiSelection.hide(); removeSelectedHubIds(); filterService($event.value); saveFilters('selectedHubIds', [])
        "
      >
        <span class="p-multiselect-close-icon pi pi-times"></span>
      </button>
    </ng-template>
    <ng-template let-value pTemplate="selectedItems">
      <div *ngIf="value !== null && value?.length === 1">
        <div *ngFor="let val of value" class="ui-multiselected-item-token ui-corner-all">
          <span>{{ val.name }}</span>
        </div>
      </div>
      <ignis-multiple-items-selected [value]="value"></ignis-multiple-items-selected>
      <span *ngIf="!value || value?.length === 0" class="ui-multiselected-empty-token ui-corner-all">
        {{ 'REPORT_CENTER_TABLE.SEARCH_ECP_ADDRESS' | translate }}
      </span>
    </ng-template>
  </p-multiSelect>
</odx-form-field>
