<div class="page-container" *ngIf="displayContent">
  <img src="/assets/img/logo.svg" alt="logo" />
  <img src="/assets/img/unauthorized.svg" alt="error-page-logo" class="error-page-logo" />

  <p class="error-page">
    {{ 'GENERIC_ERROR_PAGE.STR_MAIN_MESSAGE' | translate }}
  </p>
  <div class="info-box">
    <h3 class="mb-3 mt-3">{{ 'GENERIC_ERROR_PAGE.STR_ERROR_INFORMATION_LABEL' | translate }}</h3>
    <span>
      {{ 'GENERIC_ERROR_PAGE.STR_ERROR_CODE_LABEL' | translate }} <strong>{{ errors?.status }}</strong>
    </span>
    <span>
      {{ 'GENERIC_ERROR_PAGE.STR_ERROR_MESSAGE_LABEL' | translate }}
      <strong>{{
        errors?.status === 500 ? ('GENERIC_ERROR_PAGE.STR_ERROR_MESSAGE' | translate) : errors?.statusText
      }}</strong>
    </span>
    <span>
      {{ 'GENERIC_ERROR_PAGE.STR_CURRENT_TIMESTAMP_LABEL' | translate }} <strong>{{ timestamp }}</strong>
    </span>
    <span>
      {{ 'GENERIC_ERROR_PAGE.STR_USER_EMAIL_LABEL' | translate }} <strong>{{ user?.email_address }}</strong>
    </span>
    <span class="mb-3">
      {{ 'GENERIC_ERROR_PAGE.STR_USER_FULLNAME_LABEL' | translate }}
      <strong>{{ user?.name }}</strong>
    </span>
  </div>
  <button type="button" odxButton variant="primary" (click)="logout()" data-test-id="generic_error_page.logout_btn">
    {{ 'GENERIC_ERROR_PAGE.STR_LOGOUT_BTN' | translate }}
  </button>
</div>
