<form [formGroup]="organizationSettingsForm" class="org-settings-section-ml-25">
  <section odxLayout="grid" formGroupName="fireGroundSettings">
    <div odxLayout="12 12@tablet 3@desktop" class="custom-form-field-pr-10">
      <odx-form-field
        variant="horizontal"
        label="{{ 'ORGANIZATION_SETTINGS.STR_ORGANIZATION_PROFILE' | translate }}"
        [readonly]="organizationSettingsForm.get('fireGroundSettings.name').disabled"
      >
        <div class="w-100">
          <p-dropdown
            #dd1
            class="custom-dropdown-form"
            ngDefaultControl
            odxFormFieldControl
            formControlName="name"
            data-test-id="organization_settings.organization_profile"
            [dropdownIcon]="dropdownIconCSSClass"
            [options]="profilesOptions"
            [appendTo]="'body'"
            [showClear]="false"
            (onChange)="onProfileChange($event.value)"
          >
            <ng-template let-selectedItem pTemplate="selectedItem">
              <span [title]="selectedItem?.label">{{ selectedItem?.label }}</span>
            </ng-template>
            <ng-template let-option pTemplate="item">
              <span>{{ option?.label }}</span>
            </ng-template>
          </p-dropdown>
        </div>
      </odx-form-field>
    </div>

    <div odxLayout="12 12@tablet 3@desktop" class="custom-form-field-pl-10">
      <odx-form-field
        variant="horizontal"
        label="{{ 'ORGANIZATION_SETTINGS.STR_DEFAULT_TIME_DISPLAY' | translate }}"
        [readonly]="organizationSettingsForm.get('fireGroundSettings.defaultTimeDisplay').disabled"
      >
        <div class="w-100">
          <p-dropdown
            #dd2
            class="custom-dropdown-form"
            ngDefaultControl
            odxFormFieldControl
            formControlName="defaultTimeDisplay"
            data-test-id="organization_settings.default_time_display"
            [dropdownIcon]="dropdownIconCSSClass"
            [options]="defaultTimeOptions"
            [appendTo]="'body'"
            [placeholder]=""
            [showClear]="false"
          >
            <ng-template let-selectedItem pTemplate="selectedItem">
              <span [title]="selectedItem">{{ selectedItem }}</span>
            </ng-template>
            <ng-template let-option pTemplate="item">
              <span>{{ option }}</span>
            </ng-template>
          </p-dropdown>
        </div>
      </odx-form-field>
    </div>

    <div odxLayout="12 12@tablet 3@desktop offset-1" class="custom-form-field-pr-10">
      <odx-form-field
        variant="horizontal"
        label=" {{ 'ORGANIZATION_SETTINGS.STR_DEFAULT_TTW' | translate }}"
        [readonly]="organizationSettingsForm.get('fireGroundSettings.defaultTTWMinutes').disabled"
        [ngClass]="{
          'has-error': organizationSettingsForm.get('fireGroundSettings.defaultTTWMinutes').invalid,
        }"
      >
        <input
          type="number"
          odxFormFieldControl
          ignisDisableAutocomplete
          min="0"
          ngDefaultControl
          formControlName="defaultTTWMinutes"
          data-test-id="organization_settings.default_ttw"
        />
      </odx-form-field>
    </div>

    <div odxLayout="12 12@tablet 3@desktop" class="custom-form-field-pl-10">
      <odx-form-field
        variant="horizontal"
        label=" {{ 'ORGANIZATION_SETTINGS.STR_CONSUMPTION_RATE' | translate }}"
        [readonly]="organizationSettingsForm.get('fireGroundSettings.consumptionRate').disabled"
        [ngClass]="{
          'has-error': organizationSettingsForm.get('fireGroundSettings.consumptionRate').invalid,
        }"
      >
        <input
          type="number"
          odxFormFieldControl
          ignisDisableAutocomplete
          min="0"
          ngDefaultControl
          formControlName="consumptionRate"
          data-test-id="organization_settings.consumption_rate"
        />
      </odx-form-field>
    </div>

    <div odxLayout="12 12@tablet 3@desktop offset-1" class="custom-form-field-pr-10">
      <odx-form-field
        variant="horizontal"
        label="{{ 'ORGANIZATION_SETTINGS.STR_PRESSURE_DISPLAY' | translate }}"
        [readonly]="organizationSettingsForm.get('fireGroundSettings.displayUnit').disabled"
      >
        <div class="w-100">
          <p-dropdown
            #dd3
            class="custom-dropdown-form"
            ngDefaultControl
            odxFormFieldControl
            formControlName="displayUnit"
            data-test-id="organization_settings.pressure_display"
            [dropdownIcon]="dropdownIconCSSClass"
            [options]="pressureOptions"
            [placeholder]=""
            [appendTo]="'body'"
            [showClear]="false"
          >
            <ng-template let-selectedItem pTemplate="selectedItem">
              <span [title]="selectedItem?.label">{{ selectedItem?.label }}</span>
            </ng-template>
            <ng-template let-option pTemplate="item">
              <span>{{ option?.label }}</span>
            </ng-template>
          </p-dropdown>
        </div>
      </odx-form-field>
    </div>

    <div odxLayout="12 12@tablet 3@desktop" class="custom-form-field-pl-10">
      <odx-form-field
        variant="horizontal"
        label="{{ 'ORGANIZATION_SETTINGS.STR_DEFAULT_CYL_PRESSURE' | translate }}"
        [readonly]="organizationSettingsForm.get('fireGroundSettings.defaultCylinderPressure').disabled"
      >
        <div class="w-100">
          <p-dropdown
            #dd4
            class="custom-dropdown-form"
            odxFormFieldControl
            ngDefaultControl
            formControlName="defaultCylinderPressure"
            data-test-id="organization_settings.default_cyl_pressure"
            [dropdownIcon]="dropdownIconCSSClass"
            [options]="cylinderPressureOptions"
            [appendTo]="'body'"
            [placeholder]=""
            [showClear]="false"
          >
            <ng-template let-selectedItem pTemplate="selectedItem">
              <span [title]="selectedItem">{{ selectedItem }}</span>
            </ng-template>
            <ng-template let-option pTemplate="item">
              <span>{{ option }}</span>
            </ng-template>
          </p-dropdown>
        </div>
      </odx-form-field>
    </div>

    <div odxLayout="12 12@tablet 3@desktop offset-1" class="custom-form-field-pr-10">
      <odx-form-field
        variant="horizontal"
        label="{{ 'ORGANIZATION_SETTINGS.STR_FwDV7' | translate }}"
        class="checkbox-custom-form-field"
        [readonly]="organizationSettingsForm.get('fireGroundSettings.fwDv7').disabled"
      >
        <odx-checkbox
          class="org-form-label org-checkbox-mt-6"
          formControlName="fwDv7"
          odxFormFieldControl
          data-test-id="organization_settings.fwdv7"
        >
          {{ 'ORGANIZATION_SETTINGS.STR_ACTIVE_LABEL' | translate }}
        </odx-checkbox>
      </odx-form-field>
    </div>

    <div odxLayout="12 12@tablet 3@desktop" class="custom-form-field-pl-10">
      <odx-form-field
        variant="horizontal"
        label="{{ 'ORGANIZATION_SETTINGS.STR_MAYDAY' | translate }}"
        class="checkbox-custom-form-field"
        [readonly]="organizationSettingsForm.get('fireGroundSettings.mayday').disabled"
      >
        <odx-checkbox
          class="org-form-label org-checkbox-mt-6"
          formControlName="mayday"
          odxFormFieldControl
          data-test-id="organization_settings.mayday"
        >
          {{ 'ORGANIZATION_SETTINGS.STR_ACTIVE_LABEL' | translate }}
        </odx-checkbox>
      </odx-form-field>
    </div>

    <div odxLayout="12 12@tablet 3@desktop offset-1" class="custom-form-field-pr-10">
      <odx-form-field
        variant="horizontal"
        label="{{ 'ORGANIZATION_SETTINGS.STR_RED_PRESSURE_THRESHOLD' | translate }}"
        [readonly]="organizationSettingsForm.get('fireGroundSettings.mediumPressureThreshold').disabled"
        [ngClass]="{
          'has-error': organizationSettingsForm.get('fireGroundSettings.mediumPressureThreshold').invalid,
        }"
      >
        <input
          type="number"
          ignisDisableAutocomplete
          odxFormFieldControl
          ngDefaultControl
          min="0"
          [max]="organizationSettingsForm.get('fireGroundSettings.mediumPressureThreshold').value - 1"
          formControlName="lowPressureThreshold"
          data-test-id="organization_settings.red_pressure_threshold"
          [pTooltip]="'ORGANIZATION_SETTINGS.STR_RED_PRESSURE_THRESHOLD_TOOLTIP' | translate"
          tooltipPosition="top"
          positionTop="-10"
        />
      </odx-form-field>
    </div>

    <div odxLayout="12 12@tablet 3@desktop" class="custom-form-field-pl-10">
      <odx-form-field
        variant="horizontal"
        label="{{ 'ORGANIZATION_SETTINGS.STR_YELLOW_PRESSURE_THRESHOLD' | translate }}"
        [readonly]="organizationSettingsForm.get('fireGroundSettings.lowPressureThreshold').disabled"
        [ngClass]="{
          'has-error': organizationSettingsForm.get('fireGroundSettings.lowPressureThreshold').invalid,
        }"
      >
        <input
          type="number"
          ignisDisableAutocomplete
          odxFormFieldControl
          [min]="organizationSettingsForm.get('fireGroundSettings.lowPressureThreshold').value - 1"
          ngDefaultControl
          formControlName="mediumPressureThreshold"
          data-test-id="organization_settings.yellow_pressure_threshold"
          [pTooltip]="'ORGANIZATION_SETTINGS.STR_YELLOW_PRESSURE_THRESHOLD_TOOLTIP' | translate"
          tooltipPosition="top"
          positionTop="-10"
        />
      </odx-form-field>
    </div>
  </section>
</form>
