import { combineReducers } from 'redux';
import { organizationAccessControlReducer } from './organization-access-control.reducers';
import { organizationEntriesReducer } from './organization-entries.reducers';

export const organizationReducer: any = combineReducers<{
  organizationEntries: typeof organizationEntriesReducer;
  organizationAccessControl: typeof organizationAccessControlReducer;
}>({
  organizationEntries: organizationEntriesReducer,
  organizationAccessControl: organizationAccessControlReducer,
});
