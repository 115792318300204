import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store, select } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { IApplicationState, IStoreApiItem } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IAccessControlItems } from '../../models';
import { AccessControlService } from '../../services';
import { selectAccessControlItems } from '../../state/access-control-items/selectors/access-control-items.selector';
import { IProduct } from '../../state/product/models/product.model';
import { selectProductName } from '../../state/product/selectors/product-name.selector';

@Component({
  selector: 'ignis-dashboard',
  templateUrl: './dashboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent extends OnDestroyMixin() implements OnInit {
  userRole: string;

  constructor(
    private store: Store<IApplicationState>,
    public titleService: Title,
    public accessControlService: AccessControlService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.store
      .pipe(
        select(selectProductName),
        filter((state: IStoreApiItem<IProduct>) => !state.isLoading),
      )
      .pipe(takeUntil(this.destroy))
      .subscribe((state: IStoreApiItem<IProduct>) => {
        document.title = state.data?.productName ?? 'Dräger';

        this.titleService.setTitle(this.titleService.getTitle());
      });

    this.getFeatureToggle();
  }

  getFeatureToggle(): void {
    this.store
      .select(selectAccessControlItems)
      .pipe(
        filter((accessControlItems: IStoreApiItem<IAccessControlItems>) => !accessControlItems?.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((accessControlItems: IStoreApiItem<IAccessControlItems>) => {
        this.userRole = accessControlItems?.data?.role;
      });
  }
}
