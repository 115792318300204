<div class="print-container">
  <h1 class="pTitles bold">{{ 'EQUIPMENT_TASK_RESULTS.STR_TASK_CERTIFICATE' | translate }}</h1>
  <div class="print-tables two-tables">
    <div class="full-length">
      <h2 class="pSubtitles bold">{{ 'EQUIPMENT_TEST.STR_EQUIPMENT_INFORMATION' | translate }}</h2>
      <table [attr.aria-labelledby]="'EQUIPMENT_TEST.STR_EQUIPMENT_INFORMATION' | translate" class="pTable">
        <tbody class="fifty-percent">
          <tr>
            <th>{{ 'EQUIPMENT_TEST.STR_EQUIPMENT_IDENTIFICATION' | translate }}</th>
            <td ignisDashIfEmpty>{{ form.get('identification').value }}</td>
          </tr>
          <tr>
            <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_BARCODE' | translate }}</th>
            <td ignisDashIfEmpty>{{ form.get('barcode').value }}</td>
          </tr>
          <tr>
            <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_RFID' | translate }}</th>
            <td ignisDashIfEmpty>{{ form.get('rfid').value }}</td>
          </tr>
          <tr>
            <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_SERIAL_NUMBER' | translate }}</th>
            <td ignisDashIfEmpty>{{ form.get('serialNo')?.value }}</td>
          </tr>
          <tr>
            <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TYPE' | translate }}</th>
            <td ignisDashIfEmpty>{{ form.get('equipmentType').value }}</td>
          </tr>
          <tr>
            <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_MODEL' | translate }}</th>
            <td ignisDashIfEmpty>{{ form.get('equipmentModel').value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="full-length">
      <h2 class="pSubtitles bold">{{ 'EQUIPMENT_TEST.STR_SERVICE_DETAILS' | translate }}</h2>
      <table [attr.aria-labelledby]="'EQUIPMENT_TEST.STR_SERVICE_DETAILS' | translate" class="pTable">
        <tbody class="fifty-percent">
          <tr>
            <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_SERVICE' | translate }}</th>
            <td ignisDashIfEmpty>{{ selectedTaskName }}</td>
          </tr>

          <tr>
            <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_SERVICE_DATE' | translate }}</th>
            <td ignisDashIfEmpty>{{ dateTime }}</td>
          </tr>
          <tr>
            <th>
              {{
                (resultType === resultTypes.AutomatedTest
                  ? 'EQUIPMENT_TASK_RESULTS.STR_TESTER_NAME'
                  : 'EQUIPMENT_TASK_RESULTS.STR_TECHNICIAN_DATE'
                ) | translate
              }}
            </th>
            <td ignisDashIfEmpty>{{ form.get('testerName').value }}</td>
          </tr>
          <ng-container *ngIf="resultTypes.AutomatedTest === resultType">
            <tr>
              <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_DEVICE' | translate }}</th>
              <td ignisDashIfEmpty>{{ form.get('testEquipment')?.value }}</td>
            </tr>
            <tr>
              <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_PROCEDURE' | translate }}</th>
              <td ignisDashIfEmpty>{{ form.get('testProcedure')?.value }}</td>
            </tr>
            <tr>
              <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_TYPE' | translate }}</th>
              <td ignisDashIfEmpty>{{ form.get('testType')?.value }}</td>
            </tr>
            <tr>
              <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_SOFTWARE_VERSION' | translate }}</th>
              <td ignisDashIfEmpty>{{ form.get('softwareVersion')?.value }}</td>
            </tr>
            <tr>
              <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_SERIAL_NUMBER' | translate }}</th>
              <td ignisDashIfEmpty>{{ form.get('deviceSerialNumber')?.value }}</td>
            </tr>
          </ng-container>
          <ng-container *ngIf="resultTypes.CylinderCharging === resultType">
            <tr>
              <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_PRESSURE' | translate }}</th>
              <td ignisDashIfEmpty>{{ form.get('pressure')?.value }}</td>
            </tr>
          </ng-container>
          <ng-container *ngIf="resultTypes.Checklist === resultType">
            <tr>
              <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_CHECKLIST_NAME' | translate }}</th>
              <td ignisDashIfEmpty>{{ resultData.checklist.name }}</td>
            </tr>
            <tr>
              <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_CHECKLIST_DESCRIPTION' | translate }}</th>
              <td ignisDashIfEmpty>{{ resultData.checklist.description }}</td>
            </tr>
          </ng-container>
          <tr>
            <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_LOCATION' | translate }}</th>
            <td ignisDashIfEmpty>{{ form.get('locationPath')?.value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="print-tables">
    <h2 class="pSubtitles bold">{{ 'WORKSHOP_TAB.STR_TASK_DOCUMENTATION' | translate }}</h2>
    <table [attr.aria-labelledby]="'WORKSHOP_TAB.STR_TASK_DOCUMENTATION' | translate" class="pTable full-length">
      <tbody class="fifty-percent">
        <tr>
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_EQUIPMENT_STATUS' | translate }}</th>
          <td ignisDashIfEmpty>{{ operationalTranslations[form.get('operationalStatus').value] | translate }}</td>
        </tr>
        <tr *ngIf="resultType === resultTypes.CylinderCharging">
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_AIR_PRESSURE' | translate }}</th>
          <td ignisDashIfEmpty>{{ form.get('airQualityMeasured').value ? mark : '' }}</td>
        </tr>
        <tr>
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_COMMENT' | translate }}</th>
          <td ignisDashIfEmpty>{{ form.get('comment').value }}</td>
        </tr>
        <tr>
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_OUT_OF_SERVICE_DATE' | translate }}</th>
          <td ignisDashIfEmpty>{{ form.get('outOfServiceDate').value | date: formatDate }}</td>
        </tr>
        <tr>
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_OUT_OF_SERVICE_REASON' | translate }}</th>
          <td ignisDashIfEmpty>{{ form.get('outOfServiceReason').value }}</td>
        </tr>
        <tr>
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_NEW_BARCODE' | translate }}</th>
          <td ignisDashIfEmpty>{{ form.get('newBarcode').value }}</td>
        </tr>
        <tr>
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_NEW_RFID' | translate }}</th>
          <td ignisDashIfEmpty>{{ form.get('newRfid').value }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="print-tables" *ngIf="resultType !== resultTypes.CylinderCharging && !isFromMigration">
    <h2 class="pSubtitles bold">{{ 'EQUIPMENT_TASK_RESULTS.STR_TASKS_INCLUDED' | translate }}</h2>
    <table [attr.aria-labelledby]="'EQUIPMENT_TASK_RESULTS.STR_TASKS_INCLUDED' | translate" class="pTable full-length">
      <tbody>
        <tr class="bold">
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_DESCRIPTION' | translate }}</th>
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_LAST_OLD' | translate }}</th>
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_NEXT_OLD' | translate }}</th>
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_LAST_NEW' | translate }}</th>
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_NEXT_NEW' | translate }}</th>
        </tr>
        <tr *ngFor="let task of taskData.includedTasks">
          <td ignisDashIfEmpty>{{ task.taskName }}</td>
          <td ignisDashIfEmpty>{{ task.oldLastTestDate | date: formatDate }}</td>
          <td ignisDashIfEmpty>{{ task.oldNextTestDate | date: formatDate }}</td>
          <td ignisDashIfEmpty>{{ task.newLastTestDate | date: formatDate }}</td>
          <td ignisDashIfEmpty>{{ task.newNextTestDate | date: formatDate }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="print-tables" *ngIf="isOtherTasksDisplayed && !isFromMigration">
    <h2 class="pSubtitles bold">{{ 'EQUIPMENT_TASK_RESULTS.STR_OTHER_TASKS' | translate }}</h2>
    <table [attr.aria-labelledby]="'EQUIPMENT_TASK_RESULTS.STR_OTHER_TASKS' | translate" class="pTable full-length">
      <tbody class="twenty-percent">
        <tr class="bold">
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_DESCRIPTION' | translate }}</th>
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_LAST_OLD' | translate }}</th>
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_NEXT_OLD' | translate }}</th>
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_LAST_NEW' | translate }}</th>
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_INTERVALS_NEXT_NEW' | translate }}</th>
        </tr>
        <tr *ngFor="let task of otherTasks">
          <td ignisDashIfEmpty>{{ task.taskName }}</td>
          <td ignisDashIfEmpty>{{ task.oldLastTestDate | date: formatDate }}</td>
          <td ignisDashIfEmpty>{{ task.oldNextTestDate | date: formatDate }}</td>
          <td ignisDashIfEmpty>{{ task.newLastTestDate | date: formatDate }}</td>
          <td ignisDashIfEmpty>{{ task.newNextTestDate | date: formatDate }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="print-tables" *ngIf="resultType === resultTypes.AutomatedTest">
    <h2 class="pSubtitles bold">{{ 'EQUIPMENT_TEST.STR_EQUIPMENT_TEST_VALUES_TAB' | translate }}</h2>
    <table
      [attr.aria-labelledby]="'EQUIPMENT_TEST.STR_EQUIPMENT_TEST_VALUES_TAB' | translate"
      class="pTable full-length"
    >
      <tbody class="automatic-table">
        <tr class="bold">
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_DESCRIPTION' | translate }}</th>
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_ASSESSMENT' | translate }}</th>
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_VALUE' | translate }}</th>
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_MIN_VALUE' | translate }}</th>
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_MAX_VALUE' | translate }}</th>
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_UNIT' | translate }}</th>
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_PARAMETERS' | translate }}</th>
        </tr>
        <tr *ngFor="let value of resultData.automatic">
          <td ignisDashIfEmpty>{{ value.description }}</td>
          <td ignisDashIfEmpty>{{ assessmentsTranslation[value.assessment] | translate }}</td>
          <td ignisDashIfEmpty>{{ value.value }}</td>
          <td ignisDashIfEmpty>{{ value.minValue }}</td>
          <td ignisDashIfEmpty>{{ value.maxValue }}</td>
          <td ignisDashIfEmpty>{{ value.unit }}</td>
          <td ignisDashIfEmpty>{{ value.parameters }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="print-tables" *ngIf="resultType === resultTypes.Checklist">
    <h2 class="pSubtitles bold">{{ 'EQUIPMENT_TEST.STR_EQUIPMENT_TEST_VALUES_TAB' | translate }}</h2>
    <table
      [attr.aria-labelledby]="'EQUIPMENT_TEST.STR_EQUIPMENT_TEST_VALUES_TAB' | translate"
      class="pTable full-length"
    >
      <tbody class="twenty-percent">
        <tr class="bold">
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_CHECKLIST_ITEMS' | translate }}</th>
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_VALUE' | translate }}</th>
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_MIN_VALUE' | translate }}</th>
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_MAX_VALUE' | translate }}</th>
          <th>{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_VALUES_UNIT' | translate }}</th>
        </tr>
        <tr *ngFor="let item of resultData.checklist.items">
          <td>{{ item.name }} {{ item.required ? '*' : '' }}</td>

          <td
            class="full-length"
            colspan="4"
            *ngIf="item.type === checklistParameterTypes.BOOLEAN_TYPE"
            ignisDashIfEmpty
          >
            {{ item.itemValue.value ? mark : '' }}
          </td>

          <td class="full-length" colspan="4" *ngIf="item.type === checklistParameterTypes.TEXT_TYPE" ignisDashIfEmpty>
            {{ item.itemValue.text }}
          </td>

          <ng-container *ngIf="item.type === checklistParameterTypes.NUMERIC_TYPE">
            <td ignisDashIfEmpty>
              {{ item.itemValue.value }}
            </td>
            <td ignisDashIfEmpty>
              {{ item.itemValue.minimum }}
            </td>
            <td ignisDashIfEmpty>
              {{ item.itemValue.maximum }}
            </td>
            <td ignisDashIfEmpty>
              {{ item.itemValue.unit }}
            </td>
          </ng-container>

          <td
            class="full-length"
            colspan="4"
            *ngIf="item.type === checklistParameterTypes.MULTIPLE_SELECTION_TYPE"
            ignisDashIfEmpty
          >
            <p *ngFor="let option of item.itemValue.options | callback: onlyChecked">{{ mark }} {{ option.name }}</p>
          </td>

          <td class="full-length" colspan="4" *ngIf="item.type === checklistParameterTypes.IMAGE_TYPE">
            {{ item.id }}
            <div class="pImages" *ngIf="imageSources && imageSources[item.id]?.length; else noImages">
              <img [src]="imageName" [alt]="item.name" *ngFor="let imageName of imageSources[item.id]" />
            </div>
            <ng-template #noImages>
              {{ 'EQUIPMENT_TASK_RESULTS.STR_CHECKLIST_NO_IMAGES' | translate }}
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <p *ngIf="isFromMigration" class="mt-5">
    {{ 'EQUIPMENT_TASK_RESULTS.STR_IMPORTED_FROM_PROTECTOR_SOFTWARE' | translate }}
  </p>

  <p class="signature bold">{{ 'EQUIPMENT_TASK_RESULTS.STR_TESTERS_SIGNATURE' | translate }}</p>
</div>
