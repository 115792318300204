import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalRef, ModalService, ModalSize, ModalVariant } from '@odx/angular/components/modal';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { CommonConstants } from 'src/app/common';
import { RemoteMonitoringModuleRoutes } from '../../constants/remote-monitoring-module-routes.constants';
import { IMonitoringIncident } from '../../models/remote-monitoring.model';
import { RemoteMonitoringActions } from '../../state/actions/remote-monitoring.actions';

@Component({
  selector: 'ignis-merge-remote-incidents',
  templateUrl: './merge-remote-incidents.component.html',
  styleUrls: ['./merge-remote-incidents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MergeRemoteIncidentsComponent implements OnChanges, OnDestroy {
  @ViewChild('mergeRemoteIncidentsModal', { read: TemplateRef })
  public mergeRemoteIncidentsModal: TemplateRef<any>;
  modalRef: ModalRef | any;
  @Input() openModal: boolean = false;
  @Input() selectedIncidents: string[];
  @Input() selectedIncidentsData: IMonitoringIncident[];
  @Input() formatDate: string;
  @Output() handleCloseMergeIncidentModal: EventEmitter<string> = new EventEmitter();
  openConfirmMergeIncidentsModal: boolean = false;
  mergeIncidentsForm: FormGroup = new FormGroup({});
  incidentsToMerge: string[];
  currentAppTheme: string;
  incidentVersion: number;

  constructor(
    private readonly modalService: ModalService,
    private router: Router,
    public remoteMonitoringActions: RemoteMonitoringActions,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.currentAppTheme = this.document.body.className.split(' ')[1];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.openModal && this.openModal && this.selectedIncidentsData.length === 2) {
      this.incidentVersion = this.selectedIncidentsData[0].version;
      this.openMergeRemoteIncidentsModal();

      const secondIncidentFormatStartDate: string = format(
        new Date(this.selectedIncidentsData[1].startTime),
        this.formatDate,
      );
      const secondIncidentFormatStartHour: string = formatInTimeZone(
        new Date(this.selectedIncidentsData[1].startTime),
        Intl.DateTimeFormat().resolvedOptions().timeZone,
        CommonConstants.TIME_WITH_SECONDS_FORMAT,
      );

      const firstIncidentFormatStartDate: string = format(
        new Date(this.selectedIncidentsData[0].startTime),
        this.formatDate,
      );
      const firstIncidentFormatStartHour: string = formatInTimeZone(
        new Date(this.selectedIncidentsData[0].startTime),
        Intl.DateTimeFormat().resolvedOptions().timeZone,
        CommonConstants.TIME_WITH_SECONDS_FORMAT,
      );

      this.mergeIncidentsForm = new FormGroup({
        first_date: new FormControl({
          value: `${firstIncidentFormatStartDate} ${firstIncidentFormatStartHour}`,
          disabled: true,
        }),
        first_number: new FormControl({ value: this.selectedIncidentsData[0].incidentId, disabled: true }),
        first_ecps_number: new FormControl({ value: this.selectedIncidentsData[0].ecpNo, disabled: true }),
        first_ecp_address: new FormControl({
          value: this.selectedIncidentsData[0]?.mainEcpName.length ? this.selectedIncidentsData[0]?.mainEcpName : '-',
          disabled: true,
        }),
        first_deployed_firefighters: new FormControl({
          value: this.selectedIncidentsData[0].deployedFirefighters,
          disabled: true,
        }),

        second_date: new FormControl({
          value: `${secondIncidentFormatStartDate} ${secondIncidentFormatStartHour} `,
          disabled: true,
        }),
        second_number: new FormControl({ value: this.selectedIncidentsData[1].incidentId, disabled: true }),
        second_ecps_number: new FormControl({ value: this.selectedIncidentsData[1].ecpNo, disabled: true }),
        second_ecp_address: new FormControl({
          value: this.selectedIncidentsData[1]?.mainEcpName.length ? this.selectedIncidentsData[1]?.mainEcpName : '-',
          disabled: true,
        }),
        second_deployed_firefighters: new FormControl({
          value: this.selectedIncidentsData[1].deployedFirefighters,
          disabled: true,
        }),
      });
    }

    if (this.selectedIncidents?.length) {
      this.incidentsToMerge = this.selectedIncidents;
    }
  }

  openMergeRemoteIncidentsModal(): void {
    this.modalRef = this.modalService.open(this.mergeRemoteIncidentsModal, {
      id: 'merge-incidents-modal',
      size: ModalSize.MEDIUM,
      variant: ModalVariant.DEFAULT,
      dismissable: false,
      data: 'isOpen',
    });
  }

  mergeIncidents(): void {
    this.openConfirmMergeIncidentsModal = true;
  }

  closeConfirmMergeIncidentModal(event: string): void {
    this.openConfirmMergeIncidentsModal = false;

    if (event !== 'close') {
      this.modalRef.close();
      this.modalService.dismissAll();
      this.handleCloseMergeIncidentModal.emit('merge');
      Object.defineProperty(this.modalRef, 'data', { value: 'isClosed', writable: false });
    }
  }

  close(): void {
    this.handleCloseMergeIncidentModal.emit('close');
    Object.defineProperty(this.modalRef, 'data', { value: 'isClosed', writable: false });
  }

  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (!this.router.url.includes(RemoteMonitoringModuleRoutes.remoteMonitoring) && this.modalRef?.data === 'isOpen') {
      this.modalRef.close();
    }
  }
}
