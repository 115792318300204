import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ILogbook, ILogbookPage, LogbookPage } from './../../models/logbook.model';
import { ErrorReceiveLogbookExcelData, ReceiveLogbookExcelData } from './../actions/logbook-action-types-creators';
import { EntriesModel, IEntriesModel } from '../../models/logbook.model';
import { LogbookService } from '../../services/logbook.service';
import { LogbookActionTypes } from '../actions/logbook-action-types';
import {
  ErrorReceiveEntries,
  ErrorReceiveLogbookPage,
  ReceiveEntries,
  ReceiveLogbookPage,
} from '../actions/logbook-action-types-creators';

@Injectable()
export class LogbookEffects {
  constructor(private logbookService: LogbookService) { }

  requestLogbookPage$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(LogbookActionTypes.REQUEST_LOGBOOK_PAGE),
      switchMap((params: any) =>
        this.logbookService.getLogbookPage(params.payload).pipe(
          map(
            (logbookPage: ILogbookPage) => new ReceiveLogbookPage(new LogbookPage(logbookPage))
          ),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveLogbookPage(error)))
        )
      )
    );
  });

  requestEntries$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(LogbookActionTypes.REQUEST_ENTRIES),
      switchMap(() =>
        this.logbookService.getEntries().pipe(
          map((entries: IEntriesModel) => new ReceiveEntries(new EntriesModel(entries))),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveEntries(error)))
        )
      )
    );
  });

  requestLogbookExcelData$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(LogbookActionTypes.REQUEST_LOGBOOK_EXCEL_DATA),
      switchMap((params: any) =>
        this.logbookService.getLogbookExcelData(params.payload).pipe(
          map((logbooks: ILogbook[]) => new ReceiveLogbookExcelData(logbooks)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveLogbookExcelData(error)))
        )
      )
    );
  });
}
