import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ModalRef, ModalService, ModalSize, ModalVariant } from '@odx/angular/components/modal';

@Component({
  selector: 'ignis-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageModalComponent implements OnChanges, OnDestroy {
  @ViewChild('noteImageModal', { read: TemplateRef })
  public noteImageModal: TemplateRef<any>;
  modalRef: ModalRef | any;
  @Input() openModal: boolean = false;
  @Input() image: string;
  @Output() handleCloseNoteImageModal: EventEmitter<string> = new EventEmitter();

  constructor(private modalService: ModalService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.openModal && this.openModal) {
      this.openNoteImageModal();
    }
  }

  /* istanbul ignore next */
  openNoteImageModal(): void {
    this.modalRef = this.modalService.open(this.noteImageModal, {
      id: 'note-image-modal',
      size: ModalSize.MEDIUM,
      variant: ModalVariant.DEFAULT,
      dismissable: false,
      data: 'isOpen',
    });
  }

  close(): void {
    this.handleCloseNoteImageModal.emit('close');
    Object.defineProperty(this.modalRef, 'data', { value: 'isClosed', writable: false });
    this.modalRef.close();
  }

  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (this.modalRef?.data === 'isOpen') {
      this.modalRef.close();
    }
  }
}
