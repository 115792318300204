import { PropertyBag } from 'src/app/common';

export const tableCustomFiltersLayout: string[] = [
  'identification',
  'barcode',
  'rfid',
  'serialNo',
  'category',
  'type',
  'model',
  'dateTime',
  'task',
  'others',
  'includedTasks',
  'testerName',
  'operationalStatus',
];

export const readModelSortKeys: PropertyBag = {
  identification: 'equipmentData.identification',
  barcode: 'equipmentData.barcode',
  rfid: 'equipmentData.rfid',
  serialNo: 'equipmentData.serialNo',
  category: 'equipmentData.category',
  type: 'equipmentData.type',
  model: 'equipmentData.model',
  dateTime: 'details.dateTime',
  task: 'details.taskName',
  others: 'otherIntervals.taskName',
  includedTasks: 'includedIntervals.taskName',
  testerName: 'details.user',
  operationalStatus: 'result.operationalStatus',
};

export const tableDefaultSorting: string = 'details.dateTime,DESC';
