import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';
import { ApplicationState, CommonConstants, IScrollOptions } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import {
  getDefaultTimeDisplay,
  getLowPressureThreshold,
  getMediumPressureThreshold,
  getPressureUnit,
} from 'src/app/common/utils/settings-utils/settings-utils';
import {
  FireFighterAlarms,
  IEcpTeam,
  IFireFighterAlarms,
  ITeamFirefighter,
} from '../../../../models/remote-monitoring.model';

@Component({
  selector: 'ignis-team-tile',
  templateUrl: './team-tile.component.html',
  styleUrls: ['./team-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamTileComponent extends OnDestroyMixin() implements OnChanges, OnInit {
  @Input() teamData: IEcpTeam;
  @Input() currentAppTheme: string;

  @Output() openNotes: EventEmitter<IEcpTeam> = new EventEmitter();

  team: IEcpTeam;
  maxPressure: number = 300;
  totalSeconds: number[];
  pressureDisplayUnit: Observable<string>;
  defaultTimeDisplay: Observable<string | number>;
  mediumPressureValue: Observable<number>;
  lowPressureValue: Observable<number>;
  scrollbarOptions: IScrollOptions = CommonConstants.scrollbarOptions;

  constructor(
    private store: Store<ApplicationState>,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnChanges(): void {
    if (this.teamData) {
      this.team = structuredClone(this.teamData);
    }
  }

  ngOnInit(): void {
    this.pressureDisplayUnit = getPressureUnit(this.store, this.destroy);
    this.defaultTimeDisplay = getDefaultTimeDisplay(this.store, this.destroy);
    this.mediumPressureValue = getMediumPressureThreshold(this.store, this.destroy);
    this.lowPressureValue = getLowPressureThreshold(this.store, this.destroy);

    this.cdr.detectChanges();
  }

  openTeamNotes(team: IEcpTeam): void {
    this.openNotes.emit(team);
    this.cdr.detectChanges();
  }

  getFirefighterAlarms(firefighter: ITeamFirefighter): number {
    const alarms: FireFighterAlarms[] = firefighter.fireFighterAlarms;
    const existingAlarms: string[] = alarms.map((alarm: FireFighterAlarms) => alarm.type.value);

    if (!existingAlarms.length) {
      return;
    }

    if (existingAlarms.includes('low_pressure') || existingAlarms.includes('dsu') || existingAlarms.includes('adsu')) {
      return 0;
    } else {
      return 1;
    }
  }

  getTheLongestFirefighterAlarm(firefighter: ITeamFirefighter): IFireFighterAlarms {
    const alarms: IFireFighterAlarms[] = firefighter.fireFighterAlarms;
    const yellowAlarms: IFireFighterAlarms[] = [];
    const redAlarms: IFireFighterAlarms[] = [];
    let alarmToShow: IFireFighterAlarms;

    if (!alarms.length) {
      return;
    }

    alarms.forEach((alarm: IFireFighterAlarms) => {
      if (
        alarm.type.value.includes('low_pressure') ||
        alarm.type.value.includes('dsu') ||
        alarm.type.value.includes('adsu')
      ) {
        redAlarms.push(alarm);
      } else {
        yellowAlarms.push(alarm);
      }
    });

    if (redAlarms.length > 0) {
      const sortedRedAlarms: IFireFighterAlarms[] = redAlarms;

      sortedRedAlarms.sort((a: IFireFighterAlarms, b: IFireFighterAlarms) => a.duration - b.duration);
      alarmToShow = sortedRedAlarms[sortedRedAlarms?.length - 1];
    } else {
      const sortedYellowAlarms: IFireFighterAlarms[] = [...yellowAlarms].reverse();

      yellowAlarms.reverse();

      sortedYellowAlarms.sort((a: IFireFighterAlarms, b: IFireFighterAlarms) => a.duration - b.duration);
      alarmToShow = sortedYellowAlarms[sortedYellowAlarms?.length - 1];
    }

    return alarmToShow;
  }
}
