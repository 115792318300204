<router-outlet></router-outlet>
<ignis-logbook-list
  [logbookPage]="logbookPage | async"
  [entries]="entries | async"
  [isLoading]="isLoading | async"
  [formatDate]="formatDate"
  [pressureDisplayUnit]="pressureDisplayUnit | async"
  [logbookExcelData]="logbookExcelData | async"
  [logBookLength]="logBookLength"
  (handleProceed)="getLogbook()"
>
</ignis-logbook-list>
