import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { ProtectorTypeActionTypes } from './protector-type-action-types';

export class RequestProtectorTypeValues implements Action {
  readonly type: ProtectorTypeActionTypes.REQUEST_PROTECTOR_TYPE_VALUES =
    ProtectorTypeActionTypes.REQUEST_PROTECTOR_TYPE_VALUES;
}
export class ReceiveProtectorTypeValues implements Action {
  readonly type: ProtectorTypeActionTypes.RECEIVE_PROTECTOR_TYPE_VALUES =
    ProtectorTypeActionTypes.RECEIVE_PROTECTOR_TYPE_VALUES;

  constructor(public payload: any) {}
}
export class ErrorReceiveProtectorTypeValues implements Action {
  readonly type: ProtectorTypeActionTypes.ERROR_RECEIVE_PROTECTOR_TYPE_VALUES =
    ProtectorTypeActionTypes.ERROR_RECEIVE_PROTECTOR_TYPE_VALUES;

  constructor(public payload: HttpErrorResponse) {}
}
