import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EditTask, IEditTask, INewTask, IUpdateTaskChildren, NewTask } from 'src/app/configuration/models';
import { EquipmentConfigurationService } from 'src/app/configuration/services/equipment-configuration.service';
import {
  ErrorReceiveAddTask,
  ErrorReceiveDeleteTask,
  ErrorReceiveEditTask,
  ErrorReceiveUpdateTaskChildren,
  ReceiveAddTask,
  ReceiveDeleteTask,
  ReceiveEditTask,
  ReceiveUpdateTaskChildren,
  TasksActionTypes,
} from '../../actions';

@Injectable()
export class TasksEffects {
  equipmentConfigurationService: EquipmentConfigurationService = inject(EquipmentConfigurationService);

  requestAddTask$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(TasksActionTypes.REQUEST_ADD_TASK),
      switchMap((params: INewTask | any) =>
        this.equipmentConfigurationService.addTask(params.payload).pipe(
          map((newService: INewTask) => new ReceiveAddTask(new NewTask(newService))),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAddTask(error))),
        ),
      ),
    );
  });

  requestUpdateTaskChildren$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(TasksActionTypes.REQUEST_UPDATE_TASK_CHILDREN),
      switchMap((params: IUpdateTaskChildren | any) =>
        this.equipmentConfigurationService.updateTaskChildren(params.payload).pipe(
          map((updateServiceChildren: IUpdateTaskChildren) => new ReceiveUpdateTaskChildren(updateServiceChildren)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUpdateTaskChildren(error))),
        ),
      ),
    );
  });

  requestEditTask$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(TasksActionTypes.REQUEST_EDIT_TASK),
      switchMap((params: IEditTask | any) =>
        this.equipmentConfigurationService.editTask(params.payload).pipe(
          map((editedService: IEditTask) => new ReceiveEditTask(new EditTask(editedService))),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveEditTask(error))),
        ),
      ),
    );
  });

  requestDeleteTask$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(TasksActionTypes.REQUEST_DELETE_TASK),
      switchMap((params: any) =>
        this.equipmentConfigurationService.deleteTask(params).pipe(
          map((deletedService: any) => new ReceiveDeleteTask(deletedService)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveDeleteTask(error))),
        ),
      ),
    );
  });
}
