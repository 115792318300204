<ng-template odxModal #filterModal>
  <odx-modal-header>
    <odx-area-header>
      {{ 'REMOTE_MONITORING.STR_FILTERS' | translate }}
    </odx-area-header>
  </odx-modal-header>
  <odx-modal-conten [ngStyle]="{ overflow: 'visible' }">
    <form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
      <section odxLayout="grid">
        <p odxLayout="12">{{ 'REMOTE_MONITORING.STR_FILTERS_COMMENT' | translate }}</p>

        <div odxLayout="12 6@phone">
          <div class="odx-form-field">
            <label class="odx-form-field__label" for="incidentType">
              <odx-form-field-label class="odx-form-field-label">
                {{ 'REMOTE_MONITORING.FIELD_INCIDENT_TYPE' | translate }}
              </odx-form-field-label>
            </label>

            <div class="w-100">
              <p-multiSelect
                ngDefaultControl
                formControlName="type"
                data-test-id="remote_monitoring.incident_type"
                [dropdownIcon]="dropdownIconCSSClass"
                optionLabel="label"
                [ngModel]="selectedTypes"
                (ngModelChange)="selectedTypes = $event"
                [options]="types"
                (onPanelShow)="removeUnusedElements()"
                [placeholder]="'REMOTE_MONITORING.FIELD_INCIDENT_TYPE' | translate"
              >
                <ng-template let-option pTemplate="item">
                  <div class="p-multiselect-representative-option">
                    <span class="p-ms-1">{{ option.label }}</span>
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <button
                    type="button"
                    class="p-multiselect-close p-link p-multiselect-custom-close-btn"
                    (click)="removeSelectedTypes()"
                    data-test-id="remote_monitoring.clear_incident_type"
                  >
                    <span class="p-multiselect-close-icon pi pi-times"></span>
                  </button>
                  <div class="p-multiselect-filter-container">
                    <input
                      ngDefaultControl
                      formControlName="searchedType"
                      data-test-id="remote_monitoring.searched_type"
                      type="text"
                      ignisDisableAutocomplete
                      class="p-multiselect-filter p-inputtext"
                      (keyup)="onTypesFilter($event)"
                    /><span class="p-multiselect-filter-icon pi pi-search"></span>
                  </div>
                </ng-template>
                <ng-template let-value pTemplate="selectedItems">
                  <div *ngIf="value !== null && value?.length === 1">
                    <div *ngFor="let val of value" class="ui-multiselected-item-token ui-corner-all">
                      <span>{{ val.label }}</span>
                    </div>
                  </div>
                  <span *ngIf="value !== null && value?.length >= 2" class="ui-multiselected-empty-token ui-corner-all"
                    >{{ value.length }} {{ 'REMOTE_MONITORING.SELECTED_ITEMS' | translate }}</span
                  >
                  <span *ngIf="!value || value?.length === 0" class="ui-multiselected-empty-token ui-corner-all">
                    {{ 'REMOTE_MONITORING.FIELD_INCIDENT_TYPE' | translate }}
                  </span>
                </ng-template>
              </p-multiSelect>
            </div>
          </div>
        </div>
        <div odxLayout="12 6@phone" class="position-relative">
          <div class="filter-checkbox">
            <odx-checkbox
              ngDefaultControl
              formControlName="showFinishedIncidents"
              data-test-id="remote_monitoring.finished_incidents"
              inputId="showFinishedIncidents"
            >
              {{ 'REMOTE_MONITORING.FIELD_RECENTLY_FINISHED_INCIDENTS' | translate }}
            </odx-checkbox>
          </div>
        </div>

        <div odxLayout="12 12@tablet 6@desktop">
          <div class="odx-form-field ba-wearers-column">
            <label class="odx-form-field__label" for="baWearersNo">
              <odx-form-field-label class="odx-form-field-label">
                {{ 'REMOTE_MONITORING.FIELD_FIREMEN_NO' | translate }}
              </odx-form-field-label>
            </label>

            <div class="slider-inputs">
              <div class="odx-form-field__inner slider-value">
                <div class="odx-form-field__control">
                  <div class="odx-form-field__prefix">
                    <input
                      type="number"
                      ignisDisableAutocomplete
                      ngDefaultControl
                      id="firemenNoMin"
                      data-test-id="remote_monitoring.firemen_min_no"
                      formControlName="firemenNoMin"
                      class=""
                      placeholder="{{ 'REMOTE_MONITORING.RANGE_SLIDER_MIN' | translate }}"
                      [min]="0"
                      [max]="sliderMaxValue"
                      [ngModel]="sliderValues[0]"
                      (ngModelChange)="sliderValues[0] = $event"
                      (change)="sliderInputChange($event, 'min')"
                    />
                  </div>
                </div>
              </div>

              <div class="odx-form-field__inner slider-value">
                <div class="odx-form-field__control">
                  <div class="odx-form-field__prefix">
                    <input
                      type="number"
                      ignisDisableAutocomplete
                      ngDefaultControl
                      id="firemenNoMax"
                      data-test-id="remote_monitoring.firemen_max_no"
                      formControlName="firemenNoMax"
                      class=""
                      placeholder="{{ 'REMOTE_MONITORING.RANGE_SLIDER_MAX' | translate }}"
                      [min]="0"
                      [max]="sliderMaxValue"
                      [ngModel]="sliderValues[1]"
                      (ngModelChange)="sliderValues[1] = $event"
                      (change)="sliderInputChange($event, 'max')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p-slider
            ngDefaultControl
            formControlName="firemenNo"
            data-test-id="remote_monitoring.firemen_no"
            styleClass="p-m-3 z-index-1"
            [class]="currentAppTheme"
            [ngModel]="sliderValues"
            (ngModelChange)="sliderValues = $event"
            [range]="true"
            [step]="1"
            [min]="0"
            [max]="sliderMaxValue"
          ></p-slider>
        </div>
      </section>
    </form>
  </odx-modal-conten>
  <odx-modal-footer>
    <button odxButton (click)="closeModal()" variant="secondary" data-test-id="remote_monitoring.back_btn">
      {{ 'REMOTE_MONITORING.FILTER_BACK_BUTTON' | translate }}
    </button>

    <button
      odxButton
      *ngIf="filterParams"
      (click)="resetFilterParams()"
      variant="secondary"
      data-test-id="remote_monitoring.clear_btn"
    >
      {{ 'REMOTE_MONITORING.FILTER_CLEAR_FILTERS_BUTTON' | translate }}
    </button>

    <button
      odxButton
      variant="primary"
      (click)="onSubmit()"
      type="button"
      data-test-id="remote_monitoring.filter_proceed_btn"
    >
      {{ 'REMOTE_MONITORING.FILTER_PROCEED_BUTTON' | translate }}
    </button>
  </odx-modal-footer>
</ng-template>
