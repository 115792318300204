<div class="blue-bar-items">
  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="add-equipment-hierarchy-item"
    id="add-equipment-hierarchy-btn"
    data-test-id="configuration.add_equipment_hierarchy_btn"
    (click)="onCreateClick($event)"
    [disabled]="disableAddBtn || isModalOpened"
    [pTooltip]="addButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #addButtonTooltipContent>
      <div data-test-id="configuration.add_equipment_hierarchy_tooltip">
        {{ 'CONFIGURATION.STR_ADD_NEW_EQUIPMENT_CONFIGURATION_TOOLTIP' | translate }}
      </div>
    </ng-template>

    <odx-icon size="inline" name="add"></odx-icon>
  </button>

  <button
    type="button"
    [disabled]="!selectedItem || isModalOpened || !selectedItem.parent"
    (click)="onEditClick($event)"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="edit-equipment-hierarchy-item"
    id="edit-equipment-hierarchy-btn"
    data-test-id="configuration.edit_equipment_hierarchy_btn"
    [pTooltip]="editButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #editButtonTooltipContent>
      <div data-test-id="configuration.edit_equipment_hierarchy_tooltip">
        {{ 'CONFIGURATION.STR_EDIT_EQUIPMENT_CONFIGURATION_TOOLTIP' | translate }}
      </div>
    </ng-template>

    <odx-icon size="inline" name="edit"></odx-icon>
  </button>

  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="delete-equipment-hierarchy-item"
    id="delete-equipment-hierarchy-btn"
    [disabled]="!selectedItem || isModalOpened || !selectedItem.parent"
    (click)="onDeleteClick($event)"
    data-test-id="configuration.delete_equipment_hierarchy_btn"
    [pTooltip]="deleteButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #deleteButtonTooltipContent>
      <div data-test-id="configuration.delete_equipment_hierarchy_tooltip">
        {{ 'CONFIGURATION.STR_DELETE_EQUIPMENT_CONFIGURATION_TOOLTIP' | translate }}
      </div>
    </ng-template>

    <odx-icon name="delete"></odx-icon>
  </button>
</div>
