import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { ILicenseTicket } from 'src/app/root/models/licenses.model';
import { LicensesActionType } from './licenses-action-types';

export class RequestLicenseStatus implements Action {
  readonly type: LicensesActionType.REQUEST_LICENSE_STATUS = LicensesActionType.REQUEST_LICENSE_STATUS;
  constructor(public payload: ILicenseTicket) {}
}
export class ReceiveLicenseStatus implements Action {
  readonly type: LicensesActionType.RECEIVE_LICENSE_STATUS = LicensesActionType.RECEIVE_LICENSE_STATUS;

  constructor(public payload: any) {}
}
export class ErrorReceiveLicenseStatus implements Action {
  readonly type: LicensesActionType.ERROR_RECEIVE_LICENSE_STATUS = LicensesActionType.ERROR_RECEIVE_LICENSE_STATUS;

  constructor(public payload: HttpErrorResponse) {}
}

export class ResetLicenseStatus implements Action {
  readonly type: LicensesActionType.RESET_LICENSE_STATUS = LicensesActionType.RESET_LICENSE_STATUS;
}

export class RequestActivateTicketID implements Action {
  readonly type: LicensesActionType.REQUEST_ACTIVATE_TICKET_ID = LicensesActionType.REQUEST_ACTIVATE_TICKET_ID;
  constructor(public payload: ILicenseTicket) {}
}
export class ReceiveActivateTicketID implements Action {
  readonly type: LicensesActionType.RECEIVE_ACTIVATE_TICKET_ID = LicensesActionType.RECEIVE_ACTIVATE_TICKET_ID;

  constructor(public payload: any) {}
}
export class ErrorReceiveActivateTicketID implements Action {
  readonly type: LicensesActionType.ERROR_RECEIVE_ACTIVATE_ITCKET_ID =
    LicensesActionType.ERROR_RECEIVE_ACTIVATE_ITCKET_ID;

  constructor(public payload: HttpErrorResponse) {}
}

export class ResetActivateTicketID implements Action {
  readonly type: LicensesActionType.RESET_ACTIVATE_TICKET_ID = LicensesActionType.RESET_ACTIVATE_TICKET_ID;
}
