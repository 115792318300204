import { GenericAction } from 'src/app/common';
import { LicensesActionType } from '../actions/licenses-action-types';
import { ILicensesState, ILicenseStatus, LicensesState } from '../models/licenses.model';
import {
  errorPostPutItemData,
  receivePostPutItemData,
  requestPostPutItemData,
} from 'src/app/common/utils/reducer/reducer.utils';

const resetLicenseStatus = (lastState: ILicensesState): ILicensesState => ({
  ...lastState,
  licenseStatus: {
    data: null,
    isLoading: false,
    isSuccess: false,
    errors: null,
  },
});

const resetActivateTicketID = (lastState: ILicensesState): ILicensesState => ({
  ...lastState,
  ticketID: {
    data: null,
    isLoading: false,
    isSuccess: false,
    errors: null,
  },
});

export function licensesReducer(
  lastState: ILicensesState = new LicensesState(),
  action: GenericAction<LicensesActionType, any>,
): ILicensesState {
  switch (action.type) {
    case LicensesActionType.REQUEST_LICENSE_STATUS:
      return requestPostPutItemData<ILicensesState, ILicenseStatus>(lastState, ['licenseStatus'], action.payload);
    case LicensesActionType.RECEIVE_LICENSE_STATUS:
      return receivePostPutItemData<ILicensesState, ILicenseStatus>(lastState, ['licenseStatus'], action.payload);
    case LicensesActionType.ERROR_RECEIVE_LICENSE_STATUS:
      return errorPostPutItemData<ILicensesState, ILicenseStatus>(lastState, ['licenseStatus'], action.payload);
    case LicensesActionType.RESET_LICENSE_STATUS:
      return resetLicenseStatus(lastState);

    case LicensesActionType.REQUEST_ACTIVATE_TICKET_ID:
      return requestPostPutItemData<ILicensesState, ILicenseStatus>(lastState, ['ticketID'], action.payload);
    case LicensesActionType.RECEIVE_ACTIVATE_TICKET_ID:
      return receivePostPutItemData<ILicensesState, ILicenseStatus>(lastState, ['ticketID'], action.payload);
    case LicensesActionType.ERROR_RECEIVE_ACTIVATE_ITCKET_ID:
      return errorPostPutItemData<ILicensesState, ILicenseStatus>(lastState, ['ticketID'], action.payload);
    case LicensesActionType.RESET_ACTIVATE_TICKET_ID:
      return resetActivateTicketID(lastState);

    default:
      return lastState;
  }
}
