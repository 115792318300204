import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IEquipmentIdentifiers } from 'src/app/workshop/models';
import { IEquipmentState } from '../../models';
import {
  RequestCheckingBarcodeOrRFIDIsUnique,
  RequestInitialEquipmentSpecification,
  ResetCheckingBarcodeOrRFIDIsUnique,
} from './equipment-initial-specs-action-types-creators';

@Injectable({ providedIn: 'root' })
export class EquipmentInitialSpecificationActions {
  constructor(private store: Store<IEquipmentState>) {}

  public requestInitialEquipmentSpecification(): void {
    this.store.dispatch(new RequestInitialEquipmentSpecification());
  }

  public requestCheckingBarcodeOrRFIDIsUnique(params: IEquipmentIdentifiers): void {
    this.store.dispatch(new RequestCheckingBarcodeOrRFIDIsUnique(params));
  }

  public resetCheckingBarcodeOrRFIDIsUnique(): void {
    this.store.dispatch(new ResetCheckingBarcodeOrRFIDIsUnique());
  }
}
