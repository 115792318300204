import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs';
import { IApplicationState, IStoreApiList, PropertyBag } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins';
import { AppModulesTypes, AppUserPermissionList } from 'src/app/root/models';
import { AccessControlService } from 'src/app/root/services';
import { IFeatureToggle } from 'src/app/root/state/features-toggle/models/features-toggle.model';
import { selectFeaturesToggleList } from 'src/app/root/state/features-toggle/selectors/features-toggle.selector';
import { WorkshopModuleRoutes } from '../../constants';

@Component({
  selector: 'ignis-workshop',
  templateUrl: './workshop.component.html',
})
export class WorkshopComponent extends OnDestroyMixin() {
  inventoryTab: boolean;
  equipmentWorkflowTab: boolean;
  tasksTab: boolean;
  equipmentTestingGlobal: boolean;

  readonly WorkshopModuleRoutes: PropertyBag = WorkshopModuleRoutes;

  appUserPermissionList: PropertyBag = AppUserPermissionList;
  permissions: string[] = [];

  constructor(
    public router: Router,
    private store: Store<IApplicationState>,
    public accessControlService: AccessControlService,
  ) {
    super();
    this.permissions = this.accessControlService.permissions;
    this.featureToggle();

    this.redirectUserToInventoryTab();
  }

  navigateToInventoryTab(): void {
    this.router.navigate([WorkshopModuleRoutes.workshop, WorkshopModuleRoutes.inventory]);
  }

  navigateToWorkflowTab(): void {
    this.router.navigate([WorkshopModuleRoutes.workshop, WorkshopModuleRoutes.workflow]);
  }

  navigateToTasksTab(): void {
    this.router.navigate([WorkshopModuleRoutes.workshop, WorkshopModuleRoutes.tasks]);
  }

  navigateToTaskDocumentationResultsTab(): void {
    this.router.navigate([WorkshopModuleRoutes.workshop, WorkshopModuleRoutes.taskDocumentation]);
  }

  navigateToOverviewPage(ft: boolean, name: string): void {
    const urlKey: string = name.substring(name.indexOf('-') + 1, name.lastIndexOf(''));

    if (!ft && this.router.url.includes(urlKey)) {
      this.navigateToInventoryTab();
    }
  }

  featureToggle(): void {
    this.store
      .pipe(select(selectFeaturesToggleList))
      .pipe(takeUntil(this.destroy))
      .subscribe((state: IStoreApiList<IFeatureToggle[]>) => {
        const featuresToggle: IFeatureToggle[] = state.data;

        featuresToggle?.forEach((ft: IFeatureToggle) => {
          if (Object.values(ft).includes(AppModulesTypes.inventoryPage)) {
            this.inventoryTab = ft.isEnabled;
            this.navigateToOverviewPage(this.inventoryTab, AppModulesTypes.inventoryPage);
          } else if (Object.values(ft).includes(AppModulesTypes.equipmentWorkflowPage)) {
            this.equipmentWorkflowTab = ft.isEnabled;
            this.navigateToOverviewPage(this.equipmentWorkflowTab, AppModulesTypes.equipmentWorkflowPage);
          } else if (Object.values(ft).includes(AppModulesTypes.equipmentTasksTab)) {
            this.tasksTab = ft.isEnabled;
            this.navigateToOverviewPage(this.tasksTab, AppModulesTypes.equipmentTasksTab);
          } else if (Object.values(ft).includes(AppModulesTypes.equipmentTestingGlobal)) {
            this.equipmentTestingGlobal = ft.isEnabled;
            this.navigateToOverviewPage(this.equipmentTestingGlobal, AppModulesTypes.equipmentTestingGlobal);
          }
        });
      });
  }

  redirectUserToInventoryTab(): void {
    if (this.router.url.split('/').length === 2) {
      this.navigateToInventoryTab();
    }
  }
}
