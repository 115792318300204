import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

export function createEquipmentForm(): FormGroup {
  return new FormGroup({
    aggregateId: new FormControl(null),
    version: new FormControl(null),
    generalData: new FormGroup({
      identifier: new FormControl(null, Validators.required),
      identifierClone: new FormControl(null),
      barcode: new FormControl(null),
      equipmentModel: new FormControl(null),
      model: new FormGroup({
        id: new FormControl(null, Validators.required),
        name: new FormControl(null),
        type: new FormGroup({
          id: new FormControl(null, Validators.required),
          name: new FormControl(null),
        }),
        category: new FormGroup({
          id: new FormControl(null, Validators.required),
          name: new FormControl(null, Validators.required),
        }),
      }),
      description: new FormControl(null),
      operationalStatus: new FormControl(null),
      rfid: new FormControl(null),
      serialNo: new FormControl(null),
      partNo: new FormControl(null),
      partName: new FormControl(null),
      inUseDate: new FormControl(null),
      outOfOrderDate: new FormControl(null),
      owner: new FormControl(null),
      firstRegisteredDate: new FormControl(null),
      outOfServiceReason: new FormControl({
        value: null,
        disabled: true,
      }),
    }),
    locationAssignment: new FormGroup({
      location: new FormControl(null),
      aggregateId: new FormControl(null),
      name: new FormControl(null),
      type: new FormControl({
        value: null,
        disabled: true,
      }),
      identifier: new FormControl({
        value: null,
        disabled: true,
      }),
      address: new FormControl({
        value: null,
        disabled: true,
      }),
      details: new FormControl({
        value: null,
        disabled: true,
      }),
    }),
    serviceIntervals: new FormArray([]),
    manufacturerData: new FormGroup({
      aggregateId: new FormControl(null),
      organizationName: new FormControl({ value: null, disabled: true }),
      manufacturerDate: new FormControl(null),
      address: new FormControl({ value: null, disabled: true }),
      contactName: new FormControl({ value: null, disabled: true }),
      email: new FormControl({ value: null, disabled: true }),
      phone: new FormControl({ value: null, disabled: true }),
    }),
    supplierData: new FormGroup({
      aggregateId: new FormControl(null),
      organizationName: new FormControl(null),
      contactName: new FormControl({ value: null, disabled: true }),
      email: new FormControl({ value: null, disabled: true }),
      phone: new FormControl({ value: null, disabled: true }),
      supplierDate: new FormControl(null),
    }),
    additionalEquipmentData: new FormGroup({
      stockNumber: new FormControl(null),
      headUpDisplayId: new FormControl(null),
      backPlateId: new FormControl(null),
      gaugeId: new FormControl(null),
      passDeviceId: new FormControl(null),
      maskId: new FormControl(null),
      lungDemandValveId: new FormControl(null),
      pressureReducerId: new FormControl(null),
      cylinderId: new FormControl(null),
    }),
  });
}
