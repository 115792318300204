<odx-modal-header>
  <odx-area-header>
    @if (modalType !== modalTypes.CREATE) {
      {{ 'EVENT_MARKER.UPDATE_EVENT' | translate }}
    } @else {
      {{ 'EVENT_MARKER.ADD_EVENT' | translate }}
    }
  </odx-area-header>
</odx-modal-header>

<odx-modal-content [ngStyle]="{ overflow: 'auto' }">
  <form odxForm [formGroup]="eventMarkerForm">
    <section odxLayout="grid">
      <div odxLayout="12 12@tablet 6@desktop" class="mb-3 custom-form-field-pr-10">
        <odx-form-field
          variant="full"
          label="{{ 'EVENT_MARKER.EVENT_NAME' | translate }}"
          [ngClass]="{
            'has-error': eventMarkerForm.controls.name.invalid && isSubmitted,
          }"
        >
          <input
            ngDefaultControl
            type="text"
            ignisDisableAutocomplete
            id="event-marker-name"
            data-test-id="event_marker.event_name"
            formControlName="name"
            placeholder="{{ 'EVENT_MARKER.EVENT_NAME_PLACEHOLDER' | translate }}"
            odxFormFieldControl
            (change)="nameChanged()"
          />

          <odx-icon
            *ngIf="eventMarkerForm.controls.name.invalid && isSubmitted"
            name="info"
            class="custom-validation-icon"
            iconSet="core"
            [odxTooltip]="locationHierarchyNameFieldTooltipContent"
            [odxTooltipVisible]="eventMarkerForm.controls.name.invalid && isSubmitted"
          >
            <ng-template #locationHierarchyNameFieldTooltipContent>
              <div id="name-error-tooltip" data-test-id="event_marker.event_name_error_tooltip">
                {{ 'INCIDENT_NOTIFICATION.STR_EVENT_NAME_TOOLTIP' | translate }}
              </div>
            </ng-template>
          </odx-icon>
        </odx-form-field>
      </div>

      <div odxLayout="12 12@tablet 6@desktop" class="mb-3 custom-form-field-pr-10">
        <div class="odx-form-field">
          <label class="odx-form-field__label" for="type">
            <odx-form-field-label class="odx-form-field-label">
              {{ 'EVENT_MARKER.EVENT_TYPE' | translate }}
            </odx-form-field-label>
          </label>
          <div class="w-100 mb-15">
            <p-dropdown
              #dd1
              (onShow)="dropdownService.openDropdown(dd1)"
              (onHide)="dropdownService.closeDropdown(dd1)"
              optionValue="value"
              [appendTo]="endDatePickerWrapp"
              ngDefaultControl
              formControlName="type"
              data-test-id="event_marker.event_type"
              [dropdownIcon]="dropdownIconCSSClass"
              [options]="eventMarkerEntries"
              [placeholder]="'EVENT_MARKER.EVENT_TYPE_PLACEHOLDER' | translate"
              [showClear]="true"
            >
              <ng-template let-option pTemplate="item">
                <span>{{ option.label }}</span>
              </ng-template>
              <ng-template pTemplate="clearicon">
                <odx-icon name="close"></odx-icon>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
      </div>

      <div odxLayout="12 12@tablet 6@desktop" class="mb-3 custom-form-field-pr-10">
        <odx-form-field
          variant="full"
          label="{{ 'EVENT_MARKER.EVENT_START_DATE' | translate }}"
          class="event-marker-custom-datepicker-form-field"
          [ngClass]="{ 'has-error': startTimeError || (isSubmitted && !eventMarkerForm.get('startTime').value) }"
        >
          <div class="w-100">
            <ignis-datepicker
              formControlName="startTime"
              ngDefaultControl
              data-test-id="event_marker.start_time"
              [appendTo]="startDatePickerWrapp"
              [selectionMode]="'single'"
              [formatDate]="formatDate"
              [showTime]="true"
              [clearable]="false"
              [defaultRange]="
                modalType === modalTypes.CREATE
                  ? initialMinDate
                  : formatLocaleDate(eventMarkerForm.get('startTime').value)
              "
              (dateSelect)="onStartDateSelect($event)"
              [changeToPreviousValue]="true"
            >
            </ignis-datepicker>

            <odx-icon
              *ngIf="startTimeError || (isSubmitted && !eventMarkerForm.get('startTime').value)"
              name="info"
              iconSet="core"
              id="start-date-error-tooltip"
              class="custom-validation-icon-for-datepicker"
              data-test-id="event_marker.start_date_error_tooltip"
              [odxTooltip]="startDateErrorTooltip"
              [odxTooltipOptions]="{ position: 'top' }"
              [odxTooltipVisible]="startTimeError || (isSubmitted && !eventMarkerForm.get('startTime').value)"
            >
              <ng-template #startDateErrorTooltip>
                {{
                  startTimeError === incidentErrors.START_DATE_OUTSIDE_TIMELINE.value
                    ? ('INCIDENT_NOTIFICATION.STR_START_DATE_OUTSIDE_TIMELINE' | translate)
                    : ('EVENT_MARKER.EVENT_START_DATE_MANDATORY' | translate)
                }}
              </ng-template>
            </odx-icon>
          </div>
        </odx-form-field>
        <div class="startDatePickerWrapp" #startDatePickerWrapp></div>
      </div>

      <div odxLayout="12 12@tablet 6@desktop" class="mb-3 custom-form-field-pr-10">
        <odx-form-field
          variant="full"
          label=" {{ 'EVENT_MARKER.EVENT_END_DATE' | translate }}"
          class="event-marker-custom-datepicker-form-field"
          [ngClass]="{
            'has-error':
              endTimeError ||
              (isSubmitted && !eventMarkerForm.get('endTime').value) ||
              (isSubmitted && eventMarkerForm.get('endTime').errors?.wrongDuration),
          }"
        >
          <div class="w-100">
            <ignis-datepicker
              formControlName="endTime"
              ngDefaultControl
              [appendTo]="endDatePickerWrapp"
              [selectionMode]="'single'"
              [formatDate]="formatDate"
              data-test-id="event_marker.end_time"
              [showTime]="true"
              [clearable]="false"
              [defaultRange]="
                modalType === modalTypes.CREATE
                  ? initialMaxDate
                  : formatLocaleDate(eventMarkerForm.get('endTime').value)
              "
              (dateSelect)="onEndDateSelect($event)"
              [changeToPreviousValue]="true"
            >
            </ignis-datepicker>

            <odx-icon
              *ngIf="
                endTimeError ||
                (isSubmitted && !eventMarkerForm.get('endTime').value) ||
                (isSubmitted && eventMarkerForm.get('endTime').errors?.wrongDuration)
              "
              name="info"
              iconSet="core"
              class="custom-validation-icon-for-datepicker"
              id="end-date-error-tooltip"
              data-test-id="event_marker.end_date_error_tooltip"
              [odxTooltip]="endDateErrorTooltip"
              [odxTooltipOptions]="{ position: 'top' }"
              [odxTooltipVisible]="
                endTimeError ||
                (isSubmitted && !eventMarkerForm.get('endTime').value) ||
                (isSubmitted && eventMarkerForm.get('endTime').errors?.wrongDuration)
              "
            >
              <ng-template #endDateErrorTooltip>
                {{
                  endTimeError === incidentErrors.END_DATE_BEFORE_START_DATE.value
                    ? ('EVENT_MARKER.EVENT_END_DATE_ERROR' | translate)
                    : endTimeError === incidentErrors.END_DATE_OUTSIDE_TIMELINE.value
                      ? ('INCIDENT_NOTIFICATION.STR_END_DATE_OUTSIDE_TIMELINE' | translate)
                      : eventMarkerForm.get('endTime').errors?.wrongDuration
                        ? ('EVENT_MARKER.EVENT_DURATION_LESS_THAN_ONE_MINUTE' | translate)
                        : ('EVENT_MARKER.EVENT_END_DATE_MANDATORY' | translate)
                }}
              </ng-template>
            </odx-icon>
          </div>
        </odx-form-field>
        <div class="endDatePickerWrapp" #endDatePickerWrapp></div>
      </div>

      <div odxLayout="12 12@tablet 6@desktop" class="mb-3 custom-form-field-pr-10">
        <div class="odx-form-field">
          <label class="odx-form-field__label" for="ecp">
            <odx-form-field-label class="odx-form-field-label">
              {{ 'EVENT_MARKER.EVENT_ECP' | translate }}
            </odx-form-field-label>
          </label>
          <div class="w-100 mb-15">
            <p-dropdown
              #dd2
              (onShow)="dropdownService.openDropdown(dd2)"
              (onHide)="dropdownService.closeDropdown(dd2)"
              optionValue="appId"
              [appendTo]="startDatePickerWrapp"
              data-test-id="event_marker.event_ecp"
              ngDefaultControl
              formControlName="ecp"
              [dropdownIcon]="dropdownIconCSSClass"
              [options]="ecpsList"
              [placeholder]="'EVENT_MARKER.EVENT_ECP_PLACEHOLDER' | translate"
              [showClear]="false"
            >
              <ng-template let-option pTemplate="item">
                <span>{{ option.label }}</span>
              </ng-template>
              <ng-template pTemplate="clearicon">
                <odx-icon name="close"></odx-icon>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
      </div>

      <div odxLayout="12 12@tablet 6@desktop" class="mb-3 custom-form-field-pr-10">
        <odx-form-field variant="full" label="{{ 'EVENT_MARKER.EVENT_DURATION' | translate }}" [readonly]="true">
          <input
            ngDefaultControl
            type="text"
            ignisDisableAutocomplete
            data-test-id="event_marker.event_duration"
            id="duration"
            formControlName="duration"
            odxFormFieldControl
          />
        </odx-form-field>
      </div>

      <div odxLayout="12 12@tablet">
        <odx-form-field variant="full" label="{{ 'EVENT_MARKER.EVENT_COMMENT' | translate }}">
          <textarea
            ignisDisableAutocomplete
            odxFormFieldControl
            class="custom-form-textarea event-marker-textarea"
            id="event-marker-comment"
            data-test-id="event_marker.event_comment"
            formControlName="comment"
            rows="10"
          ></textarea>
        </odx-form-field>
      </div>
    </section>
  </form>
</odx-modal-content>
