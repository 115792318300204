<ng-template odxModal #completeChecklistModal let-modal="close">
  <odx-modal-header data-test-id="complete_checklist.complete_checklist_modal"
    ><odx-area-header>
      {{ 'COMPLETE_CHECKLIST.STR_TITLE' | translate }}
    </odx-area-header>
  </odx-modal-header>

  <odx-modal-content odxLayout="grid" [odxLoadingSpinner]="isLoading" class="complete-checklist-overflow">
    <form [formGroup]="completeChecklistForm" (ngSubmit)="onSubmit()" odxForm>
      <div class="odx-form-field odx-form-field--full mb-3">
        <label class="odx-form-field__label" for="dd1">
          <odx-form-field-label class="odx-form-field-label">
            {{ 'COMPLETE_CHECKLIST.STR_SELECT_SERVICE_LABEL' | translate }}
          </odx-form-field-label>
        </label>

        <div class="col-6">
          <p-dropdown
            #dd1
            ngDefaultControl
            optionLabel="taskName"
            formControlName="taskData"
            data-test-id="complete_checklist.select_task_label"
            [dropdownIcon]="dropdownIconCSSClass"
            [options]="serviceIntervals"
            [showClear]="false"
            (onShow)="dropdownService.openDropdown(dd1)"
            (onHide)="dropdownService.closeDropdown(dd1)"
            (onChange)="changeTasks($event)"
          >
          </p-dropdown>
        </div>
      </div>
      <odx-form-field
        variant="full"
        label="{{ 'COMPLETE_CHECKLIST.STR_CHECKLIST_NAME_LABEL' | translate }}"
        [readonly]="true"
      >
        <input
          type="text"
          id="checklistName"
          data-test-id="complete_checklist.checklist_name"
          formControlName="checklistName"
          ngDefaultControl
          odxFormFieldControl
        />
      </odx-form-field>

      <odx-form-field
        variant="full"
        label="{{ 'COMPLETE_CHECKLIST.STR_DESCRIPTION_LABEL' | translate }}"
        [readonly]="true"
      >
        <textarea
          class="form-control text-area"
          id="checklistDescription"
          data-test-id="complete_checklist.description"
          formControlName="checklistDescription"
          odxFormFieldControl
          rows="3"
        ></textarea>
      </odx-form-field>

      <div class="mb-3">
        <hr class="items-divider" />
      </div>

      <div *ngFor="let item of checklistItems" class="checklist-items">
        <ng-container [ngSwitch]="item.type">
          <ng-container *ngSwitchCase="checklistParameterTypes.TEXT_TYPE">
            <ignis-checklist-text-type
              [item]="item"
              [form]="completeChecklistForm"
              data-test-id="batch_checklist.text_type"
            ></ignis-checklist-text-type>
          </ng-container>
          <ng-container *ngSwitchCase="checklistParameterTypes.BOOLEAN_TYPE">
            <ignis-checklist-boolean-type
              [item]="item"
              [form]="completeChecklistForm"
              data-test-id="batch_checklist.boolean_type"
            ></ignis-checklist-boolean-type>
          </ng-container>
          <ng-container *ngSwitchCase="checklistParameterTypes.MULTIPLE_SELECTION_TYPE">
            <ignis-checklist-multiple-selection
              [item]="item"
              [form]="completeChecklistForm"
              data-test-id="batch_checklist.multiple_selection_type"
            ></ignis-checklist-multiple-selection>
          </ng-container>
          <ng-container *ngSwitchCase="checklistParameterTypes.NUMERIC_TYPE">
            <ignis-checklist-numeric-type
              [item]="item"
              [form]="completeChecklistForm"
              data-test-id="batch_checklist.numeric_type"
            ></ignis-checklist-numeric-type>
          </ng-container>
          <ng-container *ngSwitchCase="checklistParameterTypes.IMAGE_TYPE">
            <ignis-checklist-image-type
              [item]="item"
              [form]="completeChecklistForm"
              [deleteImages]="deleteImagesSubject"
              (areFilesUploaded)="areFilesUploaded = $event"
              data-test-id="batch_checklist.image_type"
            ></ignis-checklist-image-type>
          </ng-container>
        </ng-container>

        <div class="mt-1" *ngIf="checklistItems.length > 0">
          <hr class="items-divider" />
        </div>
      </div>
      <div odxLayout="grid">
        <div odxLayout="6" class="odx-form-field mb-3 complete-checklist operational-status odx-form-field--required">
          <label class="odx-form-field__label" for="dd3">
            <odx-form-field-label class="odx-form-field-label">
              {{ 'COMPLETE_CHECKLIST.STR_OPERATIONAL_STATUS' | translate }}
            </odx-form-field-label>
            <span class="odx-form-field__label-is-required ng-star-inserted">*</span>
          </label>
          <div class="w-100">
            <p-dropdown
              #dd3
              ngDefaultControl
              [ngClass]="{ 'custom-is-readonly': completeChecklistForm.get('operationalStatus').disabled }"
              optionLabel="label"
              optionValue="value"
              formControlName="operationalStatus"
              data-test-id="complete_checklist.operational_status"
              [dropdownIcon]="dropdownIconCSSClass"
              [options]="operationalStatusesList"
              [showClear]="false"
              (onShow)="dropdownService.openDropdown(dd3)"
              (onHide)="dropdownService.closeDropdown(dd3)"
            >
              <ng-template let-option pTemplate="item">
                <span>{{ option.label }}</span>
              </ng-template>
            </p-dropdown>
          </div>
          <odx-form-field-info class="odx-form-field__info"></odx-form-field-info>
        </div>
      </div>
    </form>

    <div class="mt-1">
      <hr class="items-divider" />
    </div>

    <ignis-services-tables
      [selectedTaskIntervals]="taskIncluded"
      [otherTaskIntervals]="otherTaskIncluded"
      [form]="completeChecklistForm"
      [formatDate]="formatDate"
      (handleOtherTaskIntervals)="getOtherTaskIntervals($event)"
    ></ignis-services-tables>
  </odx-modal-content>

  <odx-modal-footer>
    <button odxButton (click)="closeModal()" variant="secondary" data-test-id="modal_actions.cancel_btn">
      {{ 'COMPLETE_CHECKLIST.STR_CANCEL_BTN' | translate }}
    </button>
    <button
      odxButton
      variant="primary"
      type="submit"
      [disabled]="completeChecklistForm.invalid || uploadInProgress || (isSubmitting | async)"
      (click)="onSubmit()"
      data-test-id="modal_actions.save_btn"
    >
      {{ 'COMPLETE_CHECKLIST.STR_SAVE_BTN' | translate }}
    </button>
  </odx-modal-footer>
</ng-template>

<ignis-confirmation-modal [isOpen]="openConfirmChangeTasks" (handleConfirmationModal)="closeConfirmBanner($event)">
  <div slot="content">
    {{ 'COMPLETE_CHECKLIST.CONFIRM_CLOSE_MODAL' | translate }}
  </div>
</ignis-confirmation-modal>

<ignis-confirmation-modal [isOpen]="isConfirmCloseModalOpen" (handleConfirmationModal)="navigateBack($event)">
  <div slot="content">
    {{ 'COMPLETE_CHECKLIST.CONFIRM_CLOSE_MODAL' | translate }}
  </div>
</ignis-confirmation-modal>
