// eslint-disable-next-line no-shadow
export enum AddressBookActionTypes {
  REQUEST_ADDRESS_BOOK = 'REQUEST_ADDRESS_BOOK',
  RECEIVE_ADDRESS_BOOK = 'RECEIVE_ADDRESS_BOOK',
  ERROR_RECEIVE_ADDRESS_BOOK = 'ERROR_RECEIVE_ADDRESS_BOOK',
  RESET_ADDRESS_BOOK_PAGE = 'RESET_ADDRESS_BOOK_PAGE',

  REQUEST_ADD_ADDRESS_BOOK = 'REQUEST_ADD_ADDRESS_BOOK',
  RECEIVE_ADD_ADDRESS_BOOK = 'RECEIVE_ADD_ADDRESS_BOOK',
  ERROR_RECEIVE_ADD_ADDRESS_BOOK = 'ERROR_RECEIVE_ADD_ADDRESS_BOOK',

  REQUEST_EDIT_ADDRESS_BOOK = 'REQUEST_EDIT_ADDRESS_BOOK',
  RECEIVE_EDIT_ADDRESS_BOOK = 'RECEIVE_EDIT_ADDRESS_BOOK',
  ERROR_RECEIVE_EDIT_ADDRESS_BOOK = 'ERROR_RECEIVE_EDIT_ADDRESS_BOOK',

  REQUEST_DELETE_ADDRESS_BOOK = 'REQUEST_DELETE_ADDRESS_BOOK',
  RECEIVE_DELETE_ADDRESS_BOOK = 'RECEIVE_DELETE_ADDRESS_BOOK',
  ERROR_RECEIVE_DELETE_ADDRESS_BOOK = 'ERROR_RECEIVE_DELETE_ADDRESS_BOOK',

  REQUEST_ADDRESS_BOOK_BY_ID = 'REQUEST_ADDRESS_BOOK_BY_ID',
  RECEIVE_ADDRESS_BOOK_BY_ID = 'RECEIVE_ADDRESS_BOOK_BY_ID',
  ERROR_RECEIVE_ADDRESS_BOOK_BY_ID = 'ERROR_RECEIVE_ADDRESS_BOOK_BY_ID',
}
