import { IStoreApiItem } from 'src/app/common';
import { StoreApiItem } from '../../../common/models/store-api-item.model';
import { IAddressBook, IAddressBookPage } from '../../models/address-book.model';

export interface IAddressBookState {
  addressBookList: IStoreApiItem<IAddressBookPage>;
  newAddressBook: IStoreApiItem<IAddressBook>;
  addressBookById: IStoreApiItem<IAddressBook>;
  editAddressBook: IStoreApiItem<IAddressBook>;
  deleteAddressBook: IStoreApiItem<IAddressBook>;
}

export class AddressBookState implements IAddressBookState {
  addressBookList: IStoreApiItem<IAddressBookPage> = new StoreApiItem();
  newAddressBook: IStoreApiItem<IAddressBook> = new StoreApiItem();
  editAddressBook: IStoreApiItem<IAddressBook> = new StoreApiItem();
  addressBookById: IStoreApiItem<IAddressBook> = new StoreApiItem();
  deleteAddressBook: IStoreApiItem<IAddressBook> = new StoreApiItem();
}
