<button
  odxButton
  size="medium"
  variant="highlight"
  class="blue-bar-btn"
  *ngIf="url ? url.includes(router.url) : true"
  [disabled]="
    numberOfRecordsToBeExported < 1 || numberOfRecordsToBeExported > maximumExcelEntriesNumber || isModalOpened
  "
  (click)="onClick($event)"
  type="button"
  id="bluebar-excel-export-btn"
  data-test-id="excel_table_export.export_data_btn"
  [pTooltip]="exportExcelButtonTooltipContent"
  tooltipPosition="top"
  positionLeft="5"
  positionTop="-10"
  tooltipStyleClass="footer-tooltip"
>
  <ng-template #exportExcelButtonTooltipContent>
    @if (numberOfRecordsToBeExported < 1) {
      <div data-test-id="excel_table_export.no_entries_tooltip">
        {{ 'EXCEL_TABLE_EXPORT.STR_NO_ENTRIES' | translate }}
      </div>
    }

    @if (numberOfRecordsToBeExported > maximumExcelEntriesNumber) {
      <div data-test-id="excel_table_export.maximum_entries_number_tooltip">
        {{ 'EXCEL_TABLE_EXPORT.STR_MAXIMUM_ENTRIES_NUMBER' | translate }}
      </div>
    }

    @if (numberOfRecordsToBeExported > 0 && numberOfRecordsToBeExported <= maximumExcelEntriesNumber) {
      <div data-test-id="excel_table_export.export_data_tooltip">
        {{ 'EXCEL_TABLE_EXPORT.STR_EXPORT_DATA' | translate }}
      </div>
    }

    @if (exportRoleEntries) {
      <div data-test-id="excel_role_export.export_data_tooltip">
        {{ 'EXCEL_ROLE_EXPORT.STR_EXPORT_DATA' | translate }}
      </div>
    }
  </ng-template>

  <odx-icon size="inline" name="export-to-excel"></odx-icon>
</button>
