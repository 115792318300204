import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import {
  IOrganizationAccessControl,
  IOrganizationEntries,
  IOrganizationLicenseInfo,
} from 'src/app/configuration/models';
import { OrganizationSettingsActionTypes } from './organization-settings-action-types';

export class RequestOrganizationSettingsEntries implements Action {
  readonly type: OrganizationSettingsActionTypes.REQUEST_ORGANIZATION_SETTINGS_ENTRIES =
    OrganizationSettingsActionTypes.REQUEST_ORGANIZATION_SETTINGS_ENTRIES;
}
export class ReceiveOrganizationSettingsEntries implements Action {
  readonly type: OrganizationSettingsActionTypes.RECEIVE_ORGANIZATION_SETTINGS_ENTRIES =
    OrganizationSettingsActionTypes.RECEIVE_ORGANIZATION_SETTINGS_ENTRIES;

  constructor(public payload: IOrganizationEntries) {}
}
export class ErrorReceiveOrganizationSettingsEntries implements Action {
  readonly type: OrganizationSettingsActionTypes.ERROR_RECEIVE_ORGANIZATION_SETTINGS_ENTRIES =
    OrganizationSettingsActionTypes.ERROR_RECEIVE_ORGANIZATION_SETTINGS_ENTRIES;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestOrganizationAccessControl implements Action {
  readonly type: OrganizationSettingsActionTypes.REQUEST_ORGANIZATION_ACCESS_CONTROL =
    OrganizationSettingsActionTypes.REQUEST_ORGANIZATION_ACCESS_CONTROL;
}
export class ReceiveOrganizationAccessControl implements Action {
  readonly type: OrganizationSettingsActionTypes.RECEIVE_ORGANIZATION_ACCESS_CONTROL =
    OrganizationSettingsActionTypes.RECEIVE_ORGANIZATION_ACCESS_CONTROL;

  constructor(public payload: IOrganizationAccessControl) {}
}
export class ErrorReceiveOrganizationAccessControl implements Action {
  readonly type: OrganizationSettingsActionTypes.ERROR_RECEIVE_ORGANIZATION_ACCESS_CONTROL =
    OrganizationSettingsActionTypes.ERROR_RECEIVE_ORGANIZATION_ACCESS_CONTROL;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestUpdateOrganizationAccessControl implements Action {
  readonly type: OrganizationSettingsActionTypes.REQUEST_UPDATE_ORGANIZATION_ACCESS_CONTROL =
    OrganizationSettingsActionTypes.REQUEST_UPDATE_ORGANIZATION_ACCESS_CONTROL;

  constructor(public payload: IOrganizationAccessControl) {}
}
export class ReceiveUpdateOrganizationAccessControl implements Action {
  readonly type: OrganizationSettingsActionTypes.RECEIVE_UPDATE_ORGANIZATION_ACCESS_CONTROL =
    OrganizationSettingsActionTypes.RECEIVE_UPDATE_ORGANIZATION_ACCESS_CONTROL;

  constructor(public payload: IOrganizationAccessControl) {}
}
export class ErrorReceiveUpdateOrganizationAccessControl implements Action {
  readonly type: OrganizationSettingsActionTypes.ERROR_RECEIVE_UPDATE_ORGANIZATION_ACCESS_CONTROL =
    OrganizationSettingsActionTypes.ERROR_RECEIVE_UPDATE_ORGANIZATION_ACCESS_CONTROL;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestOrganizationLicenseInfo implements Action {
  readonly type: OrganizationSettingsActionTypes.REQUEST_ORGANIZATION_LICENSE_INFO =
    OrganizationSettingsActionTypes.REQUEST_ORGANIZATION_LICENSE_INFO;
}
export class ReceiveOrganizationLicenseInfo implements Action {
  readonly type: OrganizationSettingsActionTypes.RECEIVE_ORGANIZATION_LICENSE_INFO =
    OrganizationSettingsActionTypes.RECEIVE_ORGANIZATION_LICENSE_INFO;

  constructor(public payload: IOrganizationLicenseInfo[]) {}
}
export class ErrorReceiveOrganizationLicenseInfo implements Action {
  readonly type: OrganizationSettingsActionTypes.ERROR_RECEIVE_ORGANIZATION_LICENSE_INFO =
    OrganizationSettingsActionTypes.ERROR_RECEIVE_ORGANIZATION_LICENSE_INFO;

  constructor(public payload: HttpErrorResponse) {}
}
export class ResetOrganizationLicenseInfo implements Action {
  readonly type: OrganizationSettingsActionTypes.RESET_ORGANIZATION_LICENSE_INFO =
    OrganizationSettingsActionTypes.RESET_ORGANIZATION_LICENSE_INFO;
}
