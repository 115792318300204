import { GenericAction, reducerUtils } from 'src/app/common';
import { errorListData, receiveListData, requestListData } from 'src/app/common/utils/reducer/reducer.utils';
import {
  IEquipment,
  ITestParameters,
  ITestResultDetails,
  ITestResultPage,
  ITestStart,
  ITestStartResponse,
  ITestStatus,
} from '../../../models';
import { EquipmentTestActionTypes } from '../../actions/equipment-test';
import { EquipmentTestResultsActionTypes } from '../../actions/equipment-test-results';
import { ITestingState, TestingState } from '../../models';

const resetWorkflowAssetState = (lastState: ITestingState): ITestingState => ({
  ...lastState,
  workflowAsset: {
    data: null,
    isLoading: false,
    isSuccess: false,
    errors: null,
  },
});

const resetTestParametersState = (lastState: ITestingState): ITestingState => ({
  ...lastState,
  testParameters: {
    data: null,
    isLoading: false,
    isSuccess: false,
    errors: null,
  },
});

export function testingReducer(
  lastState: ITestingState = new TestingState(),
  action: GenericAction<EquipmentTestActionTypes | EquipmentTestResultsActionTypes, any>,
): ITestingState {
  switch (action.type) {
    case EquipmentTestActionTypes.REQUEST_EQUIPMENT_TEST_IS_RUNNING:
      return reducerUtils.requestItemData<ITestingState, ITestStatus>(lastState, ['isTestingInProgress']);
    case EquipmentTestActionTypes.RECEIVE_EQUIPMENT_TEST_IS_RUNNING:
      return reducerUtils.receiveItemData<ITestingState, ITestStatus>(
        lastState,
        ['isTestingInProgress'],
        action.payload,
      );
    case EquipmentTestActionTypes.ERROR_RECEIVE_EQUIPMENT_TEST_IS_RUNNING:
      return reducerUtils.errorItemData<ITestingState, ITestStatus>(lastState, ['isTestingInProgress'], action.payload);

    case EquipmentTestActionTypes.REQUEST_TEST_PARAMETERS:
      return reducerUtils.requestItemData<ITestingState, ITestParameters>(lastState, ['testParameters']);
    case EquipmentTestActionTypes.RECEIVE_TEST_PARAMETERS:
      return reducerUtils.receiveItemData<ITestingState, ITestParameters>(
        lastState,
        ['testParameters'],
        action.payload,
      );
    case EquipmentTestActionTypes.ERROR_RECEIVE_TEST_PARAMETERS:
      return reducerUtils.errorItemData<ITestingState, ITestParameters>(lastState, ['testParameters'], action.payload);

    case EquipmentTestActionTypes.REQUEST_START_TEST_EQUIPMENT:
      return reducerUtils.requestPostPutItemData<ITestingState, ITestStart>(lastState, ['startTest'], action.payload);
    case EquipmentTestActionTypes.RECEIVE_START_TEST_EQUIPMENT:
      return reducerUtils.receivePostPutItemData<ITestingState, ITestStartResponse>(
        lastState,
        ['startTest'],
        action.payload,
      );
    case EquipmentTestActionTypes.ERROR_RECEIVE_START_TEST_EQUIPMENT:
      return reducerUtils.errorPostPutItemData<ITestingState, ITestStartResponse>(
        lastState,
        ['startTest'],
        action.payload,
      );

    case EquipmentTestActionTypes.REQUEST_TEST_RESULTS:
      return reducerUtils.requestItemData<ITestingState, any>(lastState, ['testResults']);
    case EquipmentTestActionTypes.RECEIVE_TEST_RESULTS:
      return reducerUtils.receiveItemData<ITestingState, any>(lastState, ['testResults'], action.payload);
    case EquipmentTestActionTypes.ERROR_RECEIVE_TEST_RESULTS:
      return reducerUtils.errorItemData<ITestingState, any>(lastState, ['testResults'], action.payload);

    case EquipmentTestActionTypes.REQUEST_SAVE_TEST_RESULTS:
      return reducerUtils.requestPostPutItemData<ITestingState, any>(lastState, ['saveTest'], action.payload);
    case EquipmentTestActionTypes.RECEIVE_SAVE_TEST_RESULTS:
      return reducerUtils.receivePostPutItemData<ITestingState, any>(lastState, ['saveTest'], action.payload);
    case EquipmentTestActionTypes.ERROR_RECEIVE_SAVE_TEST_RESULTS:
      return reducerUtils.errorPostPutItemData<ITestingState, any>(lastState, ['saveTest'], action.payload);

    case EquipmentTestResultsActionTypes.REQUEST_TEST_RESULTS_PAGE:
      return requestListData<ITestingState, ITestResultPage>(lastState, ['testResultsPage']);
    case EquipmentTestResultsActionTypes.RECEIVE_TEST_RESULTS_PAGE:
      return receiveListData<ITestingState, ITestResultPage>(lastState, ['testResultsPage'], action.payload);
    case EquipmentTestResultsActionTypes.ERROR_RECEIVE_TEST_RESULTS_PAGE:
      return errorListData<ITestingState, ITestResultPage>(lastState, ['testResultsPage'], action.payload);

    case EquipmentTestResultsActionTypes.REQUEST_TEST_RESULTS_BY_ID:
      return requestListData<ITestingState, any>(lastState, ['testResultsById']);
    case EquipmentTestResultsActionTypes.RECEIVE_TEST_RESULTS_BY_ID:
      return receiveListData<ITestingState, ITestResultDetails>(lastState, ['testResultsById'], action.payload);
    case EquipmentTestResultsActionTypes.ERROR_RECEIVE_TEST_RESULTS_BY_ID:
      return errorListData<ITestingState, any>(lastState, ['testResultsById'], action.payload);

    case EquipmentTestActionTypes.REQUEST_WORKFLOW_ASSET:
      return reducerUtils.requestItemData<ITestingState, IEquipment>(lastState, ['workflowAsset']);
    case EquipmentTestActionTypes.RECEIVE_WORKFLOW_ASSET:
      return reducerUtils.receiveItemData<ITestingState, IEquipment>(lastState, ['workflowAsset'], action.payload);
    case EquipmentTestActionTypes.ERROR_RECEIVE_WORKFLOW_ASSET:
      return reducerUtils.errorItemData<ITestingState, IEquipment>(lastState, ['workflowAsset'], action.payload);

    case EquipmentTestActionTypes.RESET_WORKFLOW_ASSET_STATE:
      return resetWorkflowAssetState(lastState);

    case EquipmentTestActionTypes.RESET_TEST_PARAMETERS_STATE:
      return resetTestParametersState(lastState);

    default:
      return lastState;
  }
}
