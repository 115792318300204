import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ignis-additional-details',
  templateUrl: './additional-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionalDetailsComponent {
  @Input() equipmentForm: FormGroup;
  @Input() isSubmitted: boolean;
  @Input() disableAllFields: boolean;
  @Input() fieldError: string;

  clearAdditionalDetailsFormControlValidators(formControl: string): void {
    this.equipmentForm.get(formControl).clearValidators();
    this.equipmentForm.get(formControl).updateValueAndValidity();
  }
}
