import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IEquipment, ISaveTestResults, ITestStart, ITestStatus } from '../../../models';
import { EquipmentTestActionTypes } from './equipment-test-action-types';

export class RequestEquipmentTestIsRunning implements Action {
  readonly type: EquipmentTestActionTypes.REQUEST_EQUIPMENT_TEST_IS_RUNNING =
    EquipmentTestActionTypes.REQUEST_EQUIPMENT_TEST_IS_RUNNING;
}
export class ReceiveEquipmentTestIsRunning implements Action {
  readonly type: EquipmentTestActionTypes.RECEIVE_EQUIPMENT_TEST_IS_RUNNING =
    EquipmentTestActionTypes.RECEIVE_EQUIPMENT_TEST_IS_RUNNING;

  constructor(public payload: ITestStatus) {}
}
export class ErrorReceiveEquipmentTestIsRunning implements Action {
  readonly type: EquipmentTestActionTypes.ERROR_RECEIVE_EQUIPMENT_TEST_IS_RUNNING =
    EquipmentTestActionTypes.ERROR_RECEIVE_EQUIPMENT_TEST_IS_RUNNING;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestTestParameters implements Action {
  readonly type: EquipmentTestActionTypes.REQUEST_TEST_PARAMETERS = EquipmentTestActionTypes.REQUEST_TEST_PARAMETERS;

  constructor(public payload: string) {}
}
export class ReceiveTestParameters implements Action {
  readonly type: EquipmentTestActionTypes.RECEIVE_TEST_PARAMETERS = EquipmentTestActionTypes.RECEIVE_TEST_PARAMETERS;

  constructor(public payload: any) {}
}
export class ErrorReceiveTestParameters implements Action {
  readonly type: EquipmentTestActionTypes.ERROR_RECEIVE_TEST_PARAMETERS =
    EquipmentTestActionTypes.ERROR_RECEIVE_TEST_PARAMETERS;

  constructor(public payload: HttpErrorResponse) {}
}
export class ResetTestParameters implements Action {
  readonly type: EquipmentTestActionTypes.RESET_TEST_PARAMETERS_STATE =
    EquipmentTestActionTypes.RESET_TEST_PARAMETERS_STATE;
}

export class RequestStartTestEquipment implements Action {
  readonly type: EquipmentTestActionTypes.REQUEST_START_TEST_EQUIPMENT =
    EquipmentTestActionTypes.REQUEST_START_TEST_EQUIPMENT;

  constructor(public payload: ITestStart) {}
}
export class ReceiveStartTestEquipment implements Action {
  readonly type: EquipmentTestActionTypes.RECEIVE_START_TEST_EQUIPMENT =
    EquipmentTestActionTypes.RECEIVE_START_TEST_EQUIPMENT;

  constructor(public payload: any) {}
}
export class ErrorReceiveStartTestEquipment implements Action {
  readonly type: EquipmentTestActionTypes.ERROR_RECEIVE_START_TEST_EQUIPMENT =
    EquipmentTestActionTypes.ERROR_RECEIVE_START_TEST_EQUIPMENT;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestTestResults implements Action {
  readonly type: EquipmentTestActionTypes.REQUEST_TEST_RESULTS = EquipmentTestActionTypes.REQUEST_TEST_RESULTS;

  constructor(public payload: string) {}
}
export class ReceiveTestResults implements Action {
  readonly type: EquipmentTestActionTypes.RECEIVE_TEST_RESULTS = EquipmentTestActionTypes.RECEIVE_TEST_RESULTS;

  constructor(public payload: any) {}
}
export class ErrorReceiveTestResults implements Action {
  readonly type: EquipmentTestActionTypes.ERROR_RECEIVE_TEST_RESULTS =
    EquipmentTestActionTypes.ERROR_RECEIVE_TEST_RESULTS;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestSaveTestResults implements Action {
  readonly type: EquipmentTestActionTypes.REQUEST_SAVE_TEST_RESULTS =
    EquipmentTestActionTypes.REQUEST_SAVE_TEST_RESULTS;

  constructor(public payload: ISaveTestResults) {}
}
export class ReceiveSaveTestResults implements Action {
  readonly type: EquipmentTestActionTypes.RECEIVE_SAVE_TEST_RESULTS =
    EquipmentTestActionTypes.RECEIVE_SAVE_TEST_RESULTS;

  constructor(public payload: ISaveTestResults) {}
}
export class ErrorReceiveSaveTestResults implements Action {
  readonly type: EquipmentTestActionTypes.ERROR_RECEIVE_SAVE_TEST_RESULTS =
    EquipmentTestActionTypes.ERROR_RECEIVE_SAVE_TEST_RESULTS;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestWorkflowAsset implements Action {
  readonly type: EquipmentTestActionTypes.REQUEST_WORKFLOW_ASSET = EquipmentTestActionTypes.REQUEST_WORKFLOW_ASSET;

  constructor(public payload: string) {}
}
export class ReceiveWorkflowAsset implements Action {
  readonly type: EquipmentTestActionTypes.RECEIVE_WORKFLOW_ASSET = EquipmentTestActionTypes.RECEIVE_WORKFLOW_ASSET;

  constructor(public payload: IEquipment) {}
}
export class ErrorReceiveWorkflowAsset implements Action {
  readonly type: EquipmentTestActionTypes.ERROR_RECEIVE_WORKFLOW_ASSET =
    EquipmentTestActionTypes.ERROR_RECEIVE_WORKFLOW_ASSET;

  constructor(public payload: HttpErrorResponse) {}
}

export class ResetWorkflowAssetState implements Action {
  readonly type: EquipmentTestActionTypes.RESET_WORKFLOW_ASSET_STATE =
    EquipmentTestActionTypes.RESET_WORKFLOW_ASSET_STATE;
}
