import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlarmsInfo, UserStorageService } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { formatLocaleTime } from 'src/app/common/utils';
import { AlarmsConstants } from '../../constants/alarms.constants';
import { IAlarm, IEntryModel } from '../../models';

@Component({
  selector: 'ignis-alarms',
  templateUrl: './alarms.component.html',
  styleUrls: ['./alarms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlarmsComponent extends OnDestroyMixin() implements OnChanges {
  @Input() displayWidget: boolean = false;
  @Input() activeAlarms: IAlarm[];
  @Input() clearedAlarms: IAlarm[];
  @Input() dateFormat: string;
  @Output() handleCloseWidget: EventEmitter<boolean> = new EventEmitter();
  @Output() handleNewAlarmsInfo: EventEmitter<any> = new EventEmitter();
  newAlarms: IAlarm[];
  earlierAlarms: IAlarm[];
  processedClearedAlarms: IAlarm[];

  alarmType: Partial<IEntryModel>[];

  displayNewSection: boolean = true;
  newSectionHeight: string;
  displayEarlierSection: boolean = true;
  earlierSectionHeight: string;
  displayClearedSection: boolean = true;
  clearedSectionHeight: string;
  currentAppTheme: string;

  router: Router = inject(Router);
  translateService: TranslateService = inject(TranslateService);
  userStorageService: UserStorageService = inject(UserStorageService);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  constructor(@Inject(DOCUMENT) public document: Document) {
    super();

    this.currentAppTheme = this.document.body.className.split(' ')[1];
  }

  ngOnChanges(): void {
    this.alarmType = AlarmsConstants.incidentType;

    this.separateActiveAlarms(this.readAlarms);
    this.processClearedAlarms();

    const sectionHeight: string = this.clearedAlarms?.length > 0 ? '100%' : '50%';

    this.newSectionHeight = sectionHeight;
    this.earlierSectionHeight = sectionHeight;
    this.clearedSectionHeight = sectionHeight;
  }

  get readAlarms(): string[] {
    const readAlarms: string[] = this.userStorageService.getStoredValue('readAlarms');

    return readAlarms ? readAlarms : [];
  }

  separateActiveAlarms(readAlarms: string[]): void {
    if (this.activeAlarms) {
      const allAlarms: IAlarm[] = structuredClone(this.activeAlarms);

      this.changeAlarmDateAndTranslateLabel(allAlarms);

      this.newAlarms = allAlarms.filter((alarm: IAlarm) => !readAlarms?.includes(alarm.id));
      this.earlierAlarms = allAlarms.filter((alarm: IAlarm) => readAlarms?.includes(alarm.id));

      const alarmsInfo: AlarmsInfo = {
        newAlarmsNumber: this.newAlarms.length,
        severityAlarms: this.newAlarms.filter((alarm: IAlarm) => alarm.type.severity === 1).length,
      };

      this.handleNewAlarmsInfo.emit(alarmsInfo);

      this.cdr.detectChanges();
    }
  }

  processClearedAlarms(): void {
    if (this.clearedAlarms) {
      const alarms: IAlarm[] = structuredClone(this.clearedAlarms);

      this.changeAlarmDateAndTranslateLabel(alarms);

      this.processedClearedAlarms = alarms;
    }
  }

  changeAlarmDateAndTranslateLabel(alarms: IAlarm[]): void {
    alarms.forEach((alarm: IAlarm) => {
      alarm.time = formatLocaleTime(new Date(alarm.activationAt));

      this.alarmType.forEach((t: IEntryModel) => {
        if (t.value === alarm.type?.value) {
          alarm.type.label = this.translateService.instant(t.localizedName);
        }
      });
    });
  }

  onClickedOutside(): void {
    this.handleCloseWidget.emit(false);
    this.cdr.detectChanges();
  }

  moveAlert(selectedAlert: IAlarm): void {
    const read: string[] = this.readAlarms;

    if (!read.length) {
      read.push(selectedAlert.id);
    }

    if (read.length && !read.find((alarm: string) => alarm === selectedAlert.id)) {
      read.push(selectedAlert.id);
    }

    this.userStorageService.setStoredValue('readAlarms', read);
    this.separateActiveAlarms(read);
  }

  changeHeightValue(): string {
    return this.processedClearedAlarms?.length < 1 ? '50%' : '100%';
  }

  toggleNewSection(): void {
    this.displayNewSection = !this.displayNewSection;
    this.newSectionHeight = !this.displayNewSection ? 'auto' : this.changeHeightValue();
  }

  toggleEarlierSection(): void {
    this.displayEarlierSection = !this.displayEarlierSection;
    this.earlierSectionHeight = !this.displayEarlierSection ? 'auto' : this.changeHeightValue();
  }

  toggleClearedSection(): void {
    this.displayClearedSection = !this.displayClearedSection;
    this.clearedSectionHeight = !this.displayClearedSection ? 'auto' : this.changeHeightValue();
  }
}
