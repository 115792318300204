import { GenericAction } from 'src/app/common';
import { errorItemData, receiveItemData, requestItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { MaintenanceModeActionType } from './../actions/maintenance-mode-action-types';
import { IMaintenanceDate, IMaintenanceModeState, MaintenanceModeState } from './../models/maintenance-mode.model';

export function maintenanceModeReducer(
  lastState: IMaintenanceModeState = new MaintenanceModeState(),
  action: GenericAction<MaintenanceModeActionType, any>
): IMaintenanceModeState {
  switch (action.type) {
    case MaintenanceModeActionType.REQUEST_MAINTENANCE_DATE:
      return requestItemData<IMaintenanceModeState, IMaintenanceDate>(lastState, ['maintenanceDate']);
    case MaintenanceModeActionType.RECEIVE_MAINTENANCE_DATE:
      return receiveItemData<IMaintenanceModeState, IMaintenanceDate>(lastState, ['maintenanceDate'], action.payload);
    case MaintenanceModeActionType.ERROR_RECEIVE_MAINTENANCE_DATE:
      return errorItemData<IMaintenanceModeState, IMaintenanceDate>(lastState, ['maintenanceDate'], action.payload);

    default:
      return lastState;
  }
}
