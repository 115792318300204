<div *ngIf="incident?.occurredEvents[0] !== '-'; else noEvents" class="d-flex flex-wrap">
  <div *ngFor="let event of incident?.occurredEvents; let i = index" class="h2 me-1">
    <div [ngSwitch]="event">
      <odx-icon
        *ngSwitchCase="'motion_alarm'"
        name="motion-sensing"
        [pTooltip]="'INCIDENT_ALARM_TYPE.AUTOMATIC_DSU' | translate"
        tooltipPosition="top"
        positionTop="-10"
        data-test-id="incident_alarm_type.automatic_dsu"
      ></odx-icon>
      <odx-icon
        *ngSwitchCase="'distress_button'"
        name="sos"
        [pTooltip]="'INCIDENT_ALARM_TYPE.MANUAL_DSU' | translate"
        tooltipPosition="top"
        positionTop="-10"
        data-test-id="incident_alarm_type.manual_dsu"
      ></odx-icon>
      <odx-icon
        *ngSwitchCase="'ecb_evacuation'"
        name="evacuation-warning"
        [pTooltip]="'INCIDENT_ALARM_TYPE.EVACUATION_SIGNAL' | translate"
        tooltipPosition="top"
        positionTop="-10"
        data-test-id="incident_alarm_type.evacuation_signal"
      >
        <span></span>
      </odx-icon>
      <odx-icon
        *ngSwitchCase="'ecb_out_withdraw'"
        name="person-confirm"
        [pTooltip]="'INCIDENT_ALARM_TYPE.WITHDRAW_SIGNAL' | translate"
        tooltipPosition="top"
        positionTop="-10"
        data-test-id="incident_alarm_type.withdraw_signal"
      >
        <span></span>
      </odx-icon>
      <odx-icon
        *ngSwitchCase="'low_pressure_1_alarm'"
        name="cylinder-error"
        [pTooltip]="'INCIDENT_ALARM_TYPE.STR_LOW_PRESSURE_ALARM_1' | translate"
        tooltipPosition="top"
        positionTop="-10"
        data-test-id="incident_alarm_type.low_pressure_alarm_1"
      >
        <span></span>
      </odx-icon>
      <odx-icon
        *ngSwitchCase="'low_pressure_2_alarm'"
        name="cylinder-error"
        [pTooltip]="'INCIDENT_ALARM_TYPE.STR_LOW_PRESSURE_ALARM_2' | translate"
        tooltipPosition="top"
        positionTop="-10"
        data-test-id="incident_alarm_type.low_pressure_alarm_2"
      >
        <span></span>
      </odx-icon>
      <odx-icon
        *ngSwitchCase="'manual'"
        name="edit"
        [pTooltip]="'INCIDENT_ALARM_TYPE.MANUAL_EVENT' | translate"
        tooltipPosition="top"
        positionTop="-10"
        data-test-id="incident_alarm_type.manual_event"
      ></odx-icon>
      <odx-icon
        *ngSwitchCase="'media'"
        name="picture"
        [pTooltip]="'INCIDENT_ALARM_TYPE.MEDIA_ASSET_TOOLTIP' | translate"
        tooltipPosition="top"
        positionTop="-10"
        data-test-id="incident_alarm_type.media_asset"
      ></odx-icon>
      <odx-icon
        *ngSwitchCase="'hazmat_type'"
        name="toxic"
        [pTooltip]="'INCIDENT_ALARM_TYPE.STR_HAZMAT' | translate"
        data-test-id="incident_alarm_type.hazmat"
        tooltipPosition="top"
        positionTop="-10"
      ></odx-icon>
      <odx-icon
        *ngSwitchCase="'witnessed_fatality'"
        name="toxic"
        [pTooltip]="'INCIDENT_ALARM_TYPE.STR_WITNESSED_FATALITY' | translate"
        data-test-id="incident_alarm_type.witnessed_fatality"
        tooltipPosition="top"
        positionTop="-10"
      ></odx-icon>
      <odx-icon
        *ngSwitchCase="'asbestos'"
        name="toxic"
        [pTooltip]="'INCIDENT_ALARM_TYPE.STR_HAZMAT_ASBESTOS' | translate"
        data-test-id="incident_alarm_type.hazmat_asbestos"
        tooltipPosition="top"
        positionTop="-10"
      ></odx-icon>
      <odx-icon
        *ngSwitchCase="'hazmat'"
        name="toxic"
        [pTooltip]="'INCIDENT_ALARM_TYPE.STR_HAZMAT_HAZMAT' | translate"
        data-test-id="incident_alarm_type.hazmat_hazmat"
        tooltipPosition="top"
        positionTop="-10"
      ></odx-icon>
      <odx-icon
        *ngSwitchCase="'extreme_heat'"
        name="toxic"
        [pTooltip]="'INCIDENT_ALARM_TYPE.STR_HAZMAT_EXTREME_HEAT' | translate"
        data-test-id="incident_alarm_type.hazmat_extreme_heat"
        tooltipPosition="top"
        positionTop="-10"
      ></odx-icon>
      <odx-icon
        *ngSwitchCase="'other'"
        name="toxic"
        [pTooltip]="'INCIDENT_ALARM_TYPE.STR_HAZMAT_OTHER' | translate"
        data-test-id="incident_alarm_type.hazmat_other"
        tooltipPosition="top"
        positionTop="-10"
      ></odx-icon>
      <odx-icon
        *ngSwitchDefault
        name="toxic"
        [pTooltip]="'INCIDENT_ALARM_TYPE.STR_CUSTOM_HAZMAT' | translate: { event: event }"
        data-test-id="incident_alarm_type.custom_hazmat"
        tooltipPosition="top"
        positionTop="-10"
      ></odx-icon>
    </div>
  </div>
</div>
<ng-template #noEvents>
  <span>{{ 'REPORT_CENTER_TABLE.NO_NOTEWORTHY_EVENTS' | translate }}</span>
</ng-template>
