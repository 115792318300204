<ng-template odxModal #barcodeScannerModal>
  <odx-modal-header>
    <odx-area-header> {{ 'BARCODE_SCANNER.STR_TITLE' | translate }} </odx-area-header>
  </odx-modal-header>
  <odx-modal-content style="overflow-x: hidden">
    <section odLayout="grid">
      @if (isDisplayPermissionMessageVisible) {
        <odx-inline-message variant="warning">
          {{ 'BARCODE_SCANNER.STR_NO_PERMISSION_DESCRIPTION' | translate }}
        </odx-inline-message>
      } @else {
        <p class="mb-3">
          @if (!isDisplayBarcodeInputVisible) {
            {{ 'BARCODE_SCANNER.STR_SCANNER_DESCRIPTION' | translate }}
          } @else {
            {{ 'BARCODE_SCANNER.STR_MANUALLY_DESCRIPTION' | translate }}
          }
        </p>

        <div odxLayout="12 12@desktop">
          @if (!isDisplayBarcodeInputVisible) {
            <div id="qr-reader"></div>
          } @else {
            <div class="scan-barcode mt-3">
              <odx-icon name="barcode-scan" class="barcode-scanner-icon"></odx-icon>

              <div class="odx-form-field--horizontal odx-form-field relative">
                <div class="odx-form-field__inner">
                  <div class="odx-form-field__control">
                    <input
                      id="barcode"
                      type="text"
                      ignisDisableAutocomplete
                      data-test-id="barcode_scanner.barcode"
                      [ngModel]="barcode"
                      (ngModelChange)="barcode = $event"
                      #barcodeInput
                      (keyup.enter)="emitEnteredBarcode()"
                      placeholder="{{ 'BARCODE_SCANNER.STR_INPUT_PLACEHOLDER' | translate }}"
                    />

                    <odx-icon
                      class="clear-barcode"
                      name="close"
                      size="medium"
                      *ngIf="barcode?.length > 0"
                      data-test-id="barcode_scanner.clearbarcode"
                    ></odx-icon>
                  </div>
                </div>
              </div>
              <div class="search-button">
                <button
                  odxButton
                  variant="primary"
                  class="align-self-end custom-btn"
                  (click)="emitEnteredBarcode()"
                  [disabled]="barcode?.length < 1"
                  data-test-id="barcode_scanner.emit_barcode"
                >
                  <odx-icon name="ok" size="medium"></odx-icon>
                </button>
              </div>
            </div>
          }
        </div>
      }
    </section>
  </odx-modal-content>

  <odx-modal-footer>
    @if (isDisplayHintVisible) {
      <p class="mb-5 hint odx-text--small">
        <odx-icon name="info" size="medium" iconSet="core" class="mr-10"></odx-icon>
        {{ 'BARCODE_SCANNER.STR_HINT_DESCRIPTION' | translate }}

        <button class="ml-10" odxButton size="small" variant="ghost" (click)="swithToEnterManual()">
          {{ 'BARCODE_SCANNER.STR_HINT_BTN_TEXT' | translate }}
        </button>
      </p>
    }
    <button odxButton variant="primary" [odxModalClose]="false" data-test-id="modal_actions.done_btn">
      {{ 'BARCODE_SCANNER.STR_CLOSE_BTN' | translate }}
    </button>
  </odx-modal-footer>
</ng-template>
