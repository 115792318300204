import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs';
import { CommonConstants, IStoreApiItem } from 'src/app/common';
import { NotificationsService } from 'src/app/common/services';
import { EquipmentNotificationConstants, WorkshopConstants } from 'src/app/workshop/constants';
import { ITestResultDetails } from 'src/app/workshop/models';
import { EquipmentTestActions } from 'src/app/workshop/state/actions/equipment-test';
import { selectTestResults } from 'src/app/workshop/state/selectors';
import { OnDestroyMixin } from '../../../../../common/mixins';
import { IApplicationState } from '../../../../../common/state/models/app.state.model';

@Component({
  selector: 'ignis-redirect-after-testing',
  template: '<div></div>',
})
export class RedirectAfterTestingComponent extends OnDestroyMixin() implements OnInit {
  errorsCode: number;
  testResultsId: string;
  aggregateId: string = null;

  equipmentTestActions: EquipmentTestActions = inject(EquipmentTestActions);
  notificationsService: NotificationsService = inject(NotificationsService);

  constructor(
    public router: Router,
    private store: Store<IApplicationState>,
    private route: ActivatedRoute,
  ) {
    super();

    this.route.params.pipe(takeUntil(this.destroy)).subscribe((params: Params) => {
      this.testResultsId = params.id as string;
    });
  }

  ngOnInit(): void {
    this.getTestResults();
  }

  getTestResults(): void {
    this.equipmentTestActions.requestTestResults(this.testResultsId);

    this.store
      .select(selectTestResults)
      .pipe(
        filter((state: IStoreApiItem<ITestResultDetails>) => !state.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((testResults: IStoreApiItem<ITestResultDetails>) => {
        if (testResults.data) {
          this.aggregateId = testResults.data.aggregateId;

          this.router.navigate(['workshop', 'task-result', this.aggregateId]);
          localStorage.setItem(WorkshopConstants.equipmentTestedWithSuccess, 'true');

          if (WorkshopConstants.testFromWorkflowPage in localStorage) {
            localStorage.removeItem(WorkshopConstants.testFromWorkflowPage);
            localStorage.setItem(WorkshopConstants.testEnded, 'true');
          }
        }

        if (testResults.errors) {
          localStorage.removeItem(WorkshopConstants.equipmentTestedWithSuccess);
          this.errorsCode = testResults.errors.error.code as number;

          this.handleSaveTestErrors();
        }
      });
  }

  handleSaveTestErrors(): void {
    this.notificationsService.requestShowNotification(
      CommonConstants.notificationType.ERROR,
      this.errorsCode.toString(),
      EquipmentNotificationConstants.notificationCodes,
    );
  }
}
