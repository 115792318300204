import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OnDestroyMixin } from 'src/app/common/mixins';
import { getDateFormatFromUserProfile } from 'src/app/common/utils';

@Component({
  selector: 'ignis-task-documentation',
  templateUrl: './task-documentation.component.html',
})
export class TaskDocumentationComponent extends OnDestroyMixin() implements OnInit {
  formatDate: string;

  constructor(private translateService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.formatDate = getDateFormatFromUserProfile(this.translateService);
  }
}
