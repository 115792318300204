import { IEntryModel, PropertyBag } from 'src/app/common/models/common.model';
import * as entryTypeList from './entryTypeList.json';
/* eslint-disable no-shadow */

export enum AddressBookTypes {
  MANUFACTURER = 'MANUFACTURER',
  SUPPLIER = 'SUPPLIER',
  NONE = 'NONE',
}
export enum LocationTypesProperty {
  STATIC = 'static',
  MOBILE = 'mobile',
}

export class ConfigurationConstants {
  public static readonly protectorTypes: { types: IEntryModel[] } = {
    types: [
      {
        value: 'MASKE',
        label: 'MASKE',
        localizedName: 'CONFIGURATION.STR_FACE_MASK',
      },
      {
        value: 'LUNGENAUTOMAT',
        label: 'LUNGENAUTOMAT',
        localizedName: 'CONFIGURATION.STR_LUNG_DEMAND_VALVE',
      },
      {
        value: 'DRUCKMINDERER',
        label: 'DRUCKMINDERER',
        localizedName: 'CONFIGURATION.STR_PRESSURE_REDUCER',
      },
      {
        value: 'PRESSLUFTATMER',
        label: 'PRESSLUFTATMER',
        localizedName: 'CONFIGURATION.STR_BREATHING_APPARATUS',
      },
      {
        value: 'CCBA',
        label: 'CCBA',
        localizedName: 'CONFIGURATION.STR_CLOSED_CIRCUIT_BREATHING_APPARATUS',
      },
      {
        value: 'CSA',
        label: 'CSA',
        localizedName: 'CONFIGURATION.STR_CHEMICAL_PROTECTION_SUIT',
      },
      {
        value: 'BA_CYLINDER',
        label: 'BA_CYLINDER',
        localizedName: 'CONFIGURATION.STR_BA_CYLINDER',
      },
      {
        value: 'TAUCHGERAET',
        label: 'TAUCHGERAET',
        localizedName: 'CONFIGURATION.STR_DIVING_DEVICE',
      },
      {
        value: 'PRUEFGERAETE_UNABHAENGIG',
        label: 'PRUEFGERAETE_UNABHAENGIG',
        localizedName: 'CONFIGURATION.STR_PRUEFGERAETE_UNABHAENGIG',
      },
    ],
  };

  public static readonly locationTypes: { types: IEntryModel[] } = {
    types: [
      {
        value: 'station',
        label: 'station',
        localizedName: 'CONFIGURATION.STR_STATION',
        icon: 'top-level',
      },
      {
        value: 'truck',
        label: 'truck',
        localizedName: 'CONFIGURATION.STR_TRUCK',
        icon: 'truck',
      },
      {
        value: 'building',
        label: 'building',
        localizedName: 'CONFIGURATION.STR_BUILDING',
        icon: 'building',
      },
      {
        value: 'rack',
        label: 'rack',
        localizedName: 'CONFIGURATION.STR_RACK',
        icon: 'rack',
      },
      {
        value: 'floor',
        label: 'floor',
        localizedName: 'CONFIGURATION.STR_FLOOR',
        icon: 'floorplan',
      },
      {
        value: 'room',
        label: 'room',
        localizedName: 'CONFIGURATION.STR_ROOM',
        icon: 'floorplan-room',
      },
      {
        value: 'trailer',
        label: 'trailer',
        localizedName: 'CONFIGURATION.STR_TRAILER',
        icon: 'trailer',
      },
      {
        value: 'mobile_rack',
        label: 'mobile_rack',
        localizedName: 'CONFIGURATION.STR_MOBILE_RACK',
        icon: 'rack-mobile',
      },
      {
        value: 'box',
        label: 'box',
        localizedName: 'CONFIGURATION.STR_BOX',
        icon: 'storage-box',
      },
      {
        value: 'washing_machine',
        label: 'washing_machine',
        localizedName: 'CONFIGURATION.STR_WASHING_MACHINE',
        icon: 'washing-machine',
      },
      {
        value: 'dryer',
        label: 'dryer',
        localizedName: 'CONFIGURATION.STR_DRYER',
        icon: 'laundry-dryer',
      },
      {
        value: 'test_device',
        label: 'test_device',
        localizedName: 'CONFIGURATION.STR_TEST_DEVICE',
        icon: 'test-eq',
      },
      {
        value: 'person',
        label: 'person',
        localizedName: 'CONFIGURATION.STR_PERSON',
        icon: 'person-standing',
      },
    ],
  };

  public static readonly addressBookTypes: { types: IEntryModel[] } = {
    types: [
      {
        value: AddressBookTypes.MANUFACTURER,
        label: 'Manufacturer',
        localizedName: 'CONFIGURATION_ADDRESS_TABLE.STR_MANUFACTURER',
      },
      {
        value: AddressBookTypes.SUPPLIER,
        label: 'Supplier',
        localizedName: 'CONFIGURATION_ADDRESS_TABLE.STR_SUPPLIER',
      },
      {
        value: AddressBookTypes.NONE,
        label: 'None',
        localizedName: 'CONFIGURATION_ADDRESS_TABLE.STR_NONE',
      },
    ],
  };

  public static readonly locationTypesProperty: { properties: IEntryModel[] } = {
    properties: [
      {
        value: LocationTypesProperty.MOBILE,
        label: 'Mobile',
        localizedName: 'CONFIGURATION.STR_MOBILE',
      },
      {
        value: LocationTypesProperty.STATIC,
        label: 'Static',
        localizedName: 'CONFIGURATION.STR_STATIC',
      },
    ],
  };

  public static readonly checklistParameterTypes: { types: IEntryModel[] } = {
    types: [
      {
        value: 'boolean',
        label: 'boolean',
        localizedName: 'CONFIGURATION_CHECKLIST.STR_BOOLEAN_PARAMETER',
      },
      {
        value: 'text',
        label: 'text',
        localizedName: 'CONFIGURATION_CHECKLIST.STR_TEXT_PARAMETER',
      },
      {
        value: 'image',
        label: 'image',
        localizedName: 'CONFIGURATION_CHECKLIST.STR_IMAGE_PARAMETER',
      },
      {
        value: 'numeric',
        label: 'numeric',
        localizedName: 'CONFIGURATION_CHECKLIST.STR_NUMERIC_PARAMETER',
      },
      {
        value: 'multiple_selection',
        label: 'multiple_selection',
        localizedName: 'CONFIGURATION_CHECKLIST.STR_MULTIPLE_SELECTION_PARAMETER',
      },
    ],
  };

  public static readonly intervalList: { patterns: IEntryModel[] } = {
    patterns: [
      {
        value: null,
        label: '',
        localizedName: 'CONFIGURATION.STR_INTERVAL_PATTERN_EMPTY',
      },
      {
        value: 'DAY',
        label: 'Day',
        localizedName: 'CONFIGURATION.STR_INTERVAL_PATTERN_DAY',
      },
      {
        value: 'WEEK',
        label: 'Week',
        localizedName: 'CONFIGURATION.STR_INTERVAL_PATTERN_WEEK',
      },
      {
        value: 'MONTH',
        label: 'Month',
        localizedName: 'CONFIGURATION.STR_INTERVAL_PATTERN_MONTH',
      },
      {
        value: 'YEAR',
        label: 'Year',
        localizedName: 'CONFIGURATION.STR_INTERVAL_PATTERN_YEAR',
      },
    ],
  };

  public static readonly selectedChecklist: string = 'selectedChecklist';
  public static readonly configurationVersion: string = 'configurationVersion';
  public static readonly comeFromEquipmentHierarchy: string = 'comeFromEquipmentHierarchy';
  public static readonly comeFromAddAddress: string = 'comeFromAddAddress';
  public static readonly modelId: string = 'modelId';
  public static readonly typeId: string = 'typeId';
  public static readonly categoryId: string = 'categoryId';
  public static readonly tempParentAggregateId: string = 'tempParentAggregateId';
  public static readonly tempParentAddress: string = 'tempParentAddress';
  public static readonly rootNode: string = 'root-node';
  public static readonly isModelRegex: RegExp = /\/configuration\/.+\/types\/.+\/models/;
  public static readonly createUpdateChecklistModalId: string = 'create-update-checklist';
  public static readonly createUpdateChecklistModalContentId: string = 'create-update-checklist-content';

  public static readonly modalType: PropertyBag = {
    ADD: 'add',
    UPDATE: 'update',
  };

  public static readonly EquipmentConfigType: PropertyBag = {
    CATEGORY: 'category',
    TYPE: 'type',
    MODEL: 'model',
    TASK: 'task',
  };

  public static readonly importEquipmentHierarchyLanguagesMap: IEntryModel[] = [
    {
      label: 'English (British)',
      value: 'UK',
      localizedName: 'CONFIGURATION.STR_IMPORT_ENGLISH_LANGUAGE',
    },
    {
      label: 'German',
      value: 'DE',
      localizedName: 'CONFIGURATION.STR_IMPORT_GERMAN_LANGUAGE',
    },
    {
      label: 'Dansk',
      value: 'DK',
      localizedName: 'CONFIGURATION.STR_IMPORT_DANISH_LANGUAGE',
    },
    {
      label: 'Greek',
      value: 'GR',
      localizedName: 'CONFIGURATION.STR_IMPORT_GREEK_LANGUAGE',
    },
    {
      label: 'French (Belgium)',
      value: 'BE_FR',
      localizedName: 'CONFIGURATION.STR_IMPORT_FRENCH_BELGIUM_LANGUAGE',
    },
    {
      label: 'Dutch (Belgium)',
      value: 'BE_NL',
      localizedName: 'CONFIGURATION.STR_IMPORT_NEDERLAND_BELGIUM_LANGUAGE',
    },
    {
      label: 'Dutch',
      value: 'NL',
      localizedName: 'CONFIGURATION.STR_IMPORT_NEDERLAND_LANGUAGE',
    },
    {
      label: 'Norsk',
      value: 'NO',
      localizedName: 'CONFIGURATION.STR_IMPORT_NORSK_LANGUAGE',
    },
  ];
}

export enum LocationTypes {
  BOX = 'box',
  RACK = 'rack',
  ROOM = 'room',
  DRYER = 'dryer',
  FLOOR = 'floor',
  TRUCK = 'truck',
  PERSON = 'person',
  STATION = 'station',
  TRAILER = 'trailer',
  BUILDING = 'building',
  MOBILE_RACK = 'mobile_rack',
  TEST_DEVICE = 'test_device',
  WASHING_MACHINE = 'washing_machine',
}

export enum ChecklistParameterTypes {
  BOOLEAN_TYPE = 'boolean',
  TEXT_TYPE = 'text',
  NUMERIC_TYPE = 'numeric',
  IMAGE_TYPE = 'image',
  MULTIPLE_SELECTION_TYPE = 'multiple_selection',
}

export class OrganizationConstants {
  public static readonly organizationEntries: {
    profiles: IEntryModel[];
    languages: IEntryModel[];
    orientations: IEntryModel[];
    displayUnits: IEntryModel[];
  } = entryTypeList;
}
