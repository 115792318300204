import { CommonConstants } from '../../constants';

export const mapOptions: google.maps.MapOptions = {
  mapTypeId: 'roadmap',
  gestureHandling: 'greedy',
  disableDefaultUI: false,
  center: { lat: 0, lng: 0 },
  zoom: 15,
  mapId: CommonConstants.darkMapID,
};
