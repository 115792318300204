import { GenericAction } from 'src/app/common';
import { ILogbook, ILogbookPage } from './../../models/logbook.model';
import { IEntriesModel } from '../../models/logbook.model';
import { LogbookActionTypes } from '../actions/logbook-action-types';
import { ILogbookState, LogbookState } from '../models/logbook.model';
import {
  errorItemData,
  errorListData,
  receiveItemData,
  receiveListData,
  requestItemData,
  requestListData,
} from './../../../common/utils/reducer/reducer.utils';

export function logbookReducer(
  lastState: ILogbookState = new LogbookState(),
  action: GenericAction<LogbookActionTypes, any>
): ILogbookState {
  switch (action.type) {

    case LogbookActionTypes.REQUEST_LOGBOOK_PAGE:
      return requestItemData<ILogbookState, ILogbookPage>(lastState, ['logbookPage']);
    case LogbookActionTypes.RECEIVE_LOGBOOK_PAGE:
      return receiveItemData<ILogbookState, ILogbookPage>(lastState, ['logbookPage'], action.payload);
    case LogbookActionTypes.ERROR_RECEIVE_LOGBOOK_PAGE:
      return errorItemData<ILogbookState, ILogbookPage>(lastState, ['logbookPage'], action.payload);

    case LogbookActionTypes.REQUEST_ENTRIES:
      return requestItemData<ILogbookState, IEntriesModel>(lastState, ['entries']);
    case LogbookActionTypes.RECEIVE_ENTRIES:
      return receiveItemData<ILogbookState, IEntriesModel>(lastState, ['entries'], action.payload);
    case LogbookActionTypes.ERROR_RECEIVE_ENTRIES:
      return errorItemData<ILogbookState, IEntriesModel>(lastState, ['entries'], action.payload);

    case LogbookActionTypes.REQUEST_LOGBOOK_EXCEL_DATA:
      return requestListData<ILogbookState, ILogbook[]>(lastState, ['logbookExcel']);
    case LogbookActionTypes.RECEIVE_LOGBOOK_EXCEL_DATA:
      return receiveListData<ILogbookState, ILogbook[]>(lastState, ['logbookExcel'], action.payload);
    case LogbookActionTypes.ERROR_RECEIVE_LOGBOOK_EXCEL_DATA:
      return errorListData<ILogbookState, ILogbook[]>(lastState, ['logbookExcel'], action.payload);

    default:
      return lastState;
  }
}
