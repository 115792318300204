import { IStoreApiItem, IStoreApiList, StoreApiItem, StoreApiList } from 'src/app/common';
import { INewNote, INote } from '../../models/remote-monitoring.model';

export interface IRemoteMonitoringNotesState {
  notesList: IStoreApiList<INote[]>;
  readNotes: IStoreApiList<Partial<INote>[]>;
  newNote: IStoreApiItem<INewNote>;
}

export class RemoteMonitoringNotesState implements IRemoteMonitoringNotesState {
  notesList: IStoreApiList<INote[]> = new StoreApiList();
  readNotes: IStoreApiList<Partial<INote>[]> = new StoreApiList();
  newNote: IStoreApiItem<INewNote> = new StoreApiItem();
}
