export interface IProtectorMigrationStatus {
  state: string;
  equipmentMigrated: number;
  totalEquipment: number;
  equipmentUploaded: number;
  taskResultsMigrated: number;
  totalTaskResults: number;
  taskResultsUploaded: number;
}

export class ProtectorMigrationStatus implements IProtectorMigrationStatus {
  state: string;
  equipmentMigrated: number;
  totalEquipment: number;
  equipmentUploaded: number;
  taskResultsMigrated: number;
  totalTaskResults: number;
  taskResultsUploaded: number;

  constructor();
  constructor(status: IProtectorMigrationStatus);
  constructor(status?: IProtectorMigrationStatus) {
    if (!status) {
      this.state = null;
      this.equipmentMigrated = null;
      this.totalEquipment = null;
      this.equipmentUploaded = null;
      this.taskResultsMigrated = null;
      this.totalTaskResults = null;
      this.taskResultsUploaded = null;
    } else {
      this.state = status.state;
      this.equipmentMigrated = status.equipmentMigrated;
      this.totalEquipment = status.totalEquipment;
      this.equipmentUploaded = status.equipmentUploaded;
      this.taskResultsMigrated = status.taskResultsMigrated;
      this.totalTaskResults = status.totalTaskResults;
      this.taskResultsUploaded = status.taskResultsUploaded;
    }
  }
}

export interface IProtectorMigrationErrorPage {
  totalRecords: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  entries: IProtectorMigrationTableEntry[];
}

export interface IProtectorMigrationTableEntry {
  identification: string;
  entryType: string;
  errors: Array<{ code: number; message: string }>;
  errorMessage?: Array<{ code: number; message: string }>;
  aggregateId?: string;
}

export class ProtectorMigrationErrorPage implements IProtectorMigrationErrorPage {
  totalRecords: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  entries: IProtectorMigrationTableEntry[];

  constructor();
  constructor(page: IProtectorMigrationErrorPage);
  constructor(page?: IProtectorMigrationErrorPage) {
    if (!page) {
      this.totalRecords = null;
      this.pageSize = null;
      this.currentPage = null;
      this.totalPages = null;
      this.entries = null;
    } else {
      this.totalRecords = page.totalRecords;
      this.pageSize = page.pageSize;
      this.currentPage = page.currentPage;
      this.totalPages = page.totalPages;
      this.entries = page.entries;
    }
  }
}
