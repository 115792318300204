import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceDigitChar',
  standalone: true,
})
export class ReplaceDigitCharPipe implements PipeTransform {
  transform(value: string, decimalNumberFormat): string {
    if (decimalNumberFormat.decimalNumberFormat === ',' || decimalNumberFormat === ',') {
      return value?.toString().replaceAll('.', ',');
    }

    return value;
  }
}
