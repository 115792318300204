import * as fromRouter from '@ngrx/router-store';
import { RouterStateSelectors } from '@ngrx/router-store/src/models';
import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { RouterState } from '../models/router.model';

/* istanbul ignore next */
export const selectRouter: MemoizedSelector<
  RouterState,
  fromRouter.RouterReducerState<any>,
  DefaultProjectorFn<fromRouter.RouterReducerState<any>>
> = createSelector(
  (state: RouterState) => state.router,
  (value: fromRouter.RouterReducerState<any>) => value
);

export const {
  selectCurrentRoute,
  selectFragment,
  selectQueryParams,
  selectQueryParam,
  selectRouteParams,
  selectRouteParam,
  selectRouteData,
  selectUrl,
}: RouterStateSelectors<RouterState> = fromRouter.getRouterSelectors(selectRouter);
