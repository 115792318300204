import { IStoreApiItem, StoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IStoreApiList, StoreApiList } from './../../../common/models/store-api-list.model';
import { IUserPage, IUserPersonalInfo } from './../../models/user.model';
import { IUser, IUserRole } from '../../models/user.model';

export interface IUserState {
  usersPage: IStoreApiItem<IUserPage>;
  user: IStoreApiItem<IUser>;
  usersPersonalInfo: IStoreApiList<IUserPersonalInfo[]>;
  roleList: IStoreApiList<IUserRole[]>;
  csv: IStoreApiItem<any>;
}

export class UserState implements IUserState {
  usersPage: IStoreApiItem<IUserPage> = new StoreApiItem();
  user: IStoreApiItem<IUser> = new StoreApiItem();
  usersPersonalInfo: IStoreApiList<IUserPersonalInfo[]> = new StoreApiList();
  roleList: IStoreApiList<IUserRole[]> = new StoreApiList();
  csv: IStoreApiItem<any> = new StoreApiItem();
}
