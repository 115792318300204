import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as d3 from 'd3';
import { IEntryModel } from 'src/app/common/models/common.model';
import { checkDuration, dateFormatWithSeconds } from 'src/app/common/utils/date-utils/date.utils';
import { AlarmChartData, AlarmChartEntry } from 'src/app/report-center/models/incident.model';
import { ReportCenterConstants } from '../../../../constants/report-center.constants';
import { ChartHelper, ChartOptions } from '../../../../helpers/chart-helper';

@Component({
  selector: 'ignis-ecp-timeline',
  templateUrl: './ecp-timeline.component.html',
  styleUrls: ['./ecp-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EcpTimelineComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('chart')
  public chart: ElementRef;

  @Input() alarmData: AlarmChartData[] = [];
  @Input() startDate: Date = new Date();
  @Input() endDate: Date = new Date();
  @Input() numberBaSets: number;
  @Input() currentBaSetIndex: number;
  @Input() currentEventTimelineIndex: number;
  @Input() totalAlarms: number;
  @Input() formatDate: string;
  @Input() ecpName: string;
  @Input() timelineVisibleWidth: number;
  @Input() timelineLeftContentWidth: number = 230;
  @Input() minuteToPixel: number;
  @Input() timelineWidth: number;
  @Input() activationTimes: string[];
  @Input() deactivationTimes: string[];
  @Input() showEcpShadows: boolean;
  @Input() isAlarmTimeline: boolean;
  @Input() ecpsList: HTMLElement;
  @Input() baSetItem: HTMLElement;
  @Output() timelineIsLoading: EventEmitter<boolean> = new EventEmitter();

  alarmHeight: number = 15;
  minAlarmWidth: number = 15;
  alarmPadding: number = 5;
  defaultTickLength: number = 15;
  minimumViewPortHeight: number = 45;
  chartHeight: number;
  lastChartHeight: number;
  chartHelper: any;
  options: ChartOptions;

  x0: any;
  yScaleMap: any = {};
  svg: any;
  x0Axis: any;

  tooltip: any;
  scrollbarHeight: number = 20;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.x0 = this.chartHelper.createXTimeScale([this.startDate, this.endDate]);
  }

  ngOnChanges(): void {
    this.initializeChart();

    if (!this.showEcpShadows) {
      this.hideChartShadows();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.drawTimeline();
    });
  }

  initializeChart(): void {
    this.options = {
      width: this.timelineWidth,
      height: this.alarmData.length * this.alarmHeight + this.alarmData.length * (this.alarmPadding + 3),
      minWidth: 0,
      minHeight: 0,
      margin: { top: 0, right: 0, bottom: 0, left: 0 },
    };

    if (this.numberBaSets === this.currentBaSetIndex) {
      this.options.height = this.alarmData.length * this.alarmHeight * 2 + this.alarmHeight;
    } else if (this.isAlarmTimeline) {
      const chartHeightWithoutAlarms: number = 30;

      this.options.height = this.alarmData.length
        ? this.alarmData.length * this.alarmHeight * 2
        : chartHeightWithoutAlarms;
    }

    this.chartHelper = new ChartHelper(this.options);
  }

  /* istanbul ignore next */
  drawTimeline(): void {
    if (this.options.height < this.minimumViewPortHeight) {
      this.options.height = this.minimumViewPortHeight;
    }

    this.svg = this.chartHelper?.appendSvg(this.chart);
    this.svg.attr('width', this.timelineWidth).attr('viewBox', [0, 0, this.timelineWidth, this.options.height]);

    if (this.numberBaSets === this.currentBaSetIndex || this.isAlarmTimeline) {
      this.drawAxis();
    }

    this.alarmData.forEach((alarmData: AlarmChartData, index: any) => {
      this.drawAlarms(alarmData.entries, alarmData.color, alarmData.borderColor, alarmData.type, index);
    });

    this.addToolTip();
    this.drawFullHourMark();
    this.showHideEcpTimelineShadow();
    this.timelineIsLoading.emit(true);

    this.activationTimes?.forEach((activationTime: string) => {
      this.drawActivationMark(activationTime);
    });

    this.deactivationTimes?.forEach((deactivationTime: string) => {
      this.drawDeactivationMark(deactivationTime);
    });
  }

  /* istanbul ignore next */
  drawFullHourMark(): void {
    let lineHeight: number;
    const ticks: Date[] = this.chartHelper
      .calculateTickValues(this.startDate, this.endDate)
      .filter((tick: Date) => tick !== this.startDate);
    const fullHourValues: Date[] = ticks.filter((tick: Date) =>
      Number.isInteger((tick.getTime() - this.startDate.getTime()) / 1000 / 60 / 60),
    );

    if (!this.isAlarmTimeline) {
      const currentChart: HTMLElement = this.ecpsList?.childNodes[this.currentBaSetIndex - 1] as HTMLElement;

      if (this.currentBaSetIndex === this.numberBaSets) {
        lineHeight = currentChart?.offsetHeight + 1 - this.alarmPadding * 2 - this.alarmHeight;
      } else {
        lineHeight = currentChart?.offsetHeight + 1;
      }
    } else {
      lineHeight =
        (this.baSetItem?.childNodes[1] as HTMLElement)?.offsetHeight + 2 - this.alarmPadding * 2 - this.alarmHeight;
    }

    fullHourValues.forEach((fullHour: Date, index: number) => {
      this.svg
        .append('svg:line')
        .attr('class', 'full-hour')
        .attr('transform', `translate(0,${0 - this.alarmHeight})`)
        .attr('x1', this.x0(fullHour))
        .attr('y1', lineHeight)
        .attr('x2', this.x0(fullHour))
        .attr('y2', 0)
        .attr('stroke', ReportCenterConstants.alarmColors.FULL_HOUR_LINE)
        .attr('stroke-width', '3px')
        .attr('date', fullHour)
        .attr('index', index);
    });
    this.initializeFullHourTooltip();
  }

  /* istanbul ignore next */
  drawActivationMark(activationTime: string): void {
    this.svg
      .append('svg:line')
      .attr('class', 'activation-mark')
      .attr('transform', `translate(0,${0 - this.alarmHeight})`)
      .attr('x1', this.x0(new Date(activationTime)))
      .attr('y1', this.calculateCurrentChartHeight() - 1)
      .attr('x2', this.x0(new Date(activationTime)))
      .attr('y2', this.alarmPadding)
      .attr('stroke', ReportCenterConstants.alarmColors.ACTIVATION_LINE_COLOR)
      .attr('stroke-width', '3px')
      .attr('activation-time', activationTime);

    this.initializeActivationLineTooltip();
  }

  /* istanbul ignore next */
  drawDeactivationMark(deactivationTime: string): void {
    this.svg
      .append('svg:line')
      .attr('class', 'deactivation-mark')
      .attr('transform', `translate(0,${0 - this.alarmHeight})`)
      .attr('x1', this.x0(new Date(deactivationTime)))
      .attr('y1', this.calculateCurrentChartHeight() - 1)
      .attr('x2', this.x0(new Date(deactivationTime)))
      .attr('y2', this.alarmPadding)
      .attr('stroke', ReportCenterConstants.alarmColors.DEACTIVATION_LINE_COLOR)
      .attr('stroke-width', '3px')
      .attr('deactivation-time', deactivationTime);

    this.initializeDeactivationLineTooltip();
  }

  /* istanbul ignore next */
  calculateCurrentChartHeight(): number {
    const currentChart: HTMLElement = this.ecpsList?.childNodes[this.currentBaSetIndex - 1] as HTMLElement;
    const baSetAlarmChart: HTMLElement = this.baSetItem?.childNodes[1] as HTMLElement;

    if (!this.isAlarmTimeline) {
      if (this.currentBaSetIndex === this.numberBaSets) {
        return currentChart?.offsetHeight - this.alarmPadding * 2 - this.alarmHeight;
      }

      return currentChart?.offsetHeight + this.alarmPadding;
    }

    return baSetAlarmChart?.offsetHeight - this.alarmHeight - this.alarmPadding;
  }

  /* istanbul ignore next */
  drawChartShadows(): void {
    const leftShadow: HTMLElement = document.getElementById(
      `ecp-timeline-left-shadow-${this.currentEventTimelineIndex}`,
    );
    const rightShadow: HTMLElement = document.getElementById(
      `ecp-timeline-right-shadow-${this.currentEventTimelineIndex}`,
    );

    if (leftShadow || rightShadow) {
      leftShadow.style.height = `${this.chartHeight}px`;
      rightShadow.style.height = `${this.chartHeight}px`;
    }
  }

  /* istanbul ignore next */
  hideChartShadows(): void {
    const leftShadow: HTMLElement = document.getElementById(
      `ecp-timeline-left-shadow-${this.currentEventTimelineIndex}`,
    );
    const rightShadow: HTMLElement = document.getElementById(
      `ecp-timeline-right-shadow-${this.currentEventTimelineIndex}`,
    );
    const baSetLeftShadow: HTMLElement = document.getElementById(
      `ba-set-timeline-left-shadow-${this.currentEventTimelineIndex}${this.currentBaSetIndex - 1}`,
    );
    const baSetRightShadow: HTMLElement = document.getElementById(
      `ba-set-timeline-right-shadow-${this.currentEventTimelineIndex}${this.currentBaSetIndex - 1}`,
    );

    if (leftShadow) {
      leftShadow.style.visibility = 'hidden';
    }

    if (rightShadow) {
      rightShadow.style.visibility = 'hidden';
    }

    if (baSetLeftShadow) {
      baSetLeftShadow.style.visibility = 'hidden';
    }

    if (baSetRightShadow) {
      baSetRightShadow.style.visibility = 'hidden';
    }
  }

  /* istanbul ignore next */
  drawAxis(): void {
    let tickSize: number;
    let firstChartHeight: number;
    const lastChart: HTMLElement = this.ecpsList?.childNodes[this.numberBaSets - 1] as HTMLElement;
    const currentBaSet: HTMLElement = this.baSetItem;
    const firstBaSetChart: HTMLElement = this.baSetItem?.childNodes[0] as HTMLElement;

    this.x0 = this.chartHelper.createXTimeScale([this.startDate, this.endDate]);

    if (!this.isAlarmTimeline) {
      this.chartHeight = this.ecpsList?.offsetHeight;
      this.lastChartHeight = lastChart?.offsetHeight - this.scrollbarHeight;
      firstChartHeight =
        this.ecpsList?.childNodes.length > 2
          ? this.ecpsList?.offsetHeight - lastChart?.offsetHeight + this.alarmHeight
          : this.scrollbarHeight;
      tickSize = this.chartHeight - this.scrollbarHeight;
    } else {
      firstChartHeight = firstBaSetChart?.offsetHeight + this.alarmHeight * 2 + this.alarmPadding;
      tickSize = currentBaSet?.offsetHeight;
    }

    this.drawChartShadows();

    const chartTimeFormat: string = '%H:%M:%S';

    this.x0Axis = this.svg
      .append('g')
      .classed('x-axis', true)
      .style('font-size', '16px')
      .style('color', ReportCenterConstants.alarmColors.X_AXIS_COLOR)
      .attr('transform', `translate(0, ${0 - firstChartHeight})`)
      .call(
        d3
          .axisBottom()
          .tickFormat(d3.timeFormat(chartTimeFormat))
          .tickValues(this.chartHelper.calculateTickValues(this.startDate, this.endDate))
          .tickSize(tickSize)
          .scale(this.x0),
      );
  }
  /* istanbul ignore next */
  addToolTip(): void {
    this.tooltip = d3.select('body').append('div')?.style('display', 'none').classed(`timeline-tooltip`, true);
  }

  /* istanbul ignore next */
  drawAlarms(data: AlarmChartEntry[], color: string, borderColor: string, alarmType: string, alarmIndex: number): void {
    const alarmWidthCorrection: number = 1;

    this.yScaleMap[alarmIndex] = d3
      .scaleLinear()
      .domain([0, 1])
      .range([this.alarmHeight * 2 * alarmIndex, this.alarmHeight * alarmIndex]);

    const alarm: any = this.svg.append('g');

    alarm
      .attr('fill', color)
      .attr('pointer-events', 'all')
      .selectAll('rect')
      .data(data)
      .join('rect')
      .attr('x', (d: any) => this.x0(d.start) + alarmWidthCorrection)
      .attr('y', this.yScaleMap[alarmIndex](1))
      .attr('width', (d: any) =>
        Math.max(Math.round(this.x0(d.end) - this.x0(d.start) - alarmWidthCorrection * 2), this.minAlarmWidth),
      )
      .attr('height', this.alarmHeight)
      .attr('stroke-width', '1px')
      .attr('stroke', borderColor)
      .on('touchmove mousemove', (event: any) => this.showAlarmTooltip(alarmType, event, alarm.node(), data))
      .on('touchend mouseleave', () => this.tooltip?.style('display', 'none'));

    if (alarmIndex !== 0) {
      alarm?.style('transform', `translate(0px, ${(this.alarmHeight - this.alarmPadding) * alarmIndex}px)`);
    }
  }

  /* istanbul ignore next */
  showAlarmTooltip(alarmType: string, event: any, node: any, data: AlarmChartEntry[]): void {
    const { start, end }: any = this.chartHelper.bisector(d3.pointer(event, node), data, this.x0);
    const diff: any = end.getTime() - start.getTime();
    const duration: any = checkDuration(Math.floor(diff / 1000));
    const typeName: string = this.translateService.instant(
      ReportCenterConstants.incidentEntries.alarmTypes.find((t: IEntryModel) => t.value === alarmType)?.localizedName ||
        alarmType,
    );

    const alarmIcon: string = this.alarmData.find((alarmData: AlarmChartData) => alarmData.type === alarmType).icon;

    const [tooltipWidth, tooltipHeight]: any = this.chartHelper.showTooltip(
      this.tooltip,
      `
       <div class="tooltip-container" data-test-id="ecp_timeline.tooltip">
        <div class="tooltip-icon">
          ${alarmIcon}
        </div>
        <div class="tooltip-content">
          <b>${typeName}</b><br>
          ${this.translateService.instant('INCIDENT_ANALYSIS.STR_START')}: ${dateFormatWithSeconds(
            start,
            this.formatDate,
          )}<br>
          ${this.translateService.instant('INCIDENT_ANALYSIS.STR_END')}: ${dateFormatWithSeconds(
            end,
            this.formatDate,
          )}<br>
          ${this.translateService.instant('INCIDENT_EVENT_TIMELINE.STR_DURATION')}: ${duration}
        </div>
       </div>
      `,
    );

    this.chartHelper.increaseTooltipContentMarginLeft(
      tooltipWidth,
      200,
      'tooltip-container',
      'increase-ecp-timeline-tooltip-margin',
    );

    this.tooltip
      ?.style('top', `${event.pageY - tooltipHeight - this.alarmHeight}px`)
      ?.style('left', `${event.pageX - tooltipWidth / 2}px`);
  }
  /* istanbul ignore next */
  initializeActivationLineTooltip(): void {
    d3.selectAll('svg>line.activation-mark')
      .on('touchmove mousemove', (event: any) => {
        const attributes: HTMLElement = event.target as HTMLElement;
        const date: string = dateFormatWithSeconds(attributes.getAttribute('activation-time'), this.formatDate);

        this.showActivationLineTooltip(event, date);
      })
      .on('touchend mouseleave', () => this.tooltip?.style('display', 'none'));
  }

  /* istanbul ignore next */
  initializeDeactivationLineTooltip(): void {
    d3.selectAll('svg>line.deactivation-mark')
      .on('touchmove mousemove', (event: any) => {
        const attributes: HTMLElement = event.target as HTMLElement;
        const date: string = dateFormatWithSeconds(attributes.getAttribute('deactivation-time'), this.formatDate);

        this.showDeactivationLineTooltip(event, date);
      })
      .on('touchend mouseleave', () => this.tooltip?.style('display', 'none'));
  }

  /* istanbul ignore next */
  showActivationLineTooltip(event: any, date: string): void {
    const [tooltipWidth, tooltipHeight]: any = this.chartHelper.showTooltip(
      this.tooltip,
      `
        <b>${this.translateService.instant('INCIDENT_LINE_TYPE.ACTIVATION_LINE')}</b><br>
        ${this.translateService.instant('INCIDENT_EVENT_TIMELINE.STR_TIME')}: ${date}
      `,
    );

    this.tooltip
      ?.style('top', `${event.pageY - tooltipHeight - this.alarmHeight}px`)
      ?.style('left', `${event.pageX - tooltipWidth / 2}px`);
  }

  /* istanbul ignore next */
  showDeactivationLineTooltip(event: any, date: string): void {
    const [tooltipWidth, tooltipHeight]: any = this.chartHelper.showTooltip(
      this.tooltip,
      `
        <b>${this.translateService.instant('INCIDENT_LINE_TYPE.DEACTIVATION_LINE')}</b><br>
        ${this.translateService.instant('INCIDENT_EVENT_TIMELINE.STR_TIME')}: ${date}
      `,
    );

    this.tooltip
      ?.style('top', `${event.pageY - tooltipHeight - this.alarmHeight}px`)
      ?.style('left', `${event.pageX - tooltipWidth / 2}px`);
  }

  /* istanbul ignore next */
  initializeFullHourTooltip(): void {
    d3.selectAll('svg>line')
      .on('touchmove mousemove', (event: any) => {
        const attributes: HTMLElement = event.target as HTMLElement;
        const date: string = dateFormatWithSeconds(attributes.getAttribute('date'), this.formatDate);
        const index: number = parseInt(attributes.getAttribute('index'), 10) + 1;
        const tooltipData: any = { date, index };

        this.showFullHourTooltip(event, tooltipData);
      })
      .on('touchend mouseleave', () => this.tooltip?.style('display', 'none'));
  }

  /* istanbul ignore next */
  showFullHourTooltip(event: any, data: any): void {
    const [tooltipWidth, tooltipHeight]: any = this.chartHelper.showTooltip(
      this.tooltip,
      `
        <b>${data.index + ' ' + this.translateService.instant('INCIDENT_ECP_TIMELINE.STR_HOUR_MARK')}</b><br>
       ${this.translateService.instant('INCIDENT_ECP_TIMELINE.STR_TIME')}: ${data.date}
      `,
    );

    this.tooltip
      ?.style('top', `${event.pageY - tooltipHeight - this.alarmHeight}px`)
      ?.style('left', `${event.pageX - tooltipWidth / 2}px`);
  }

  /* istanbul ignore next */
  showHideEcpTimelineShadow(): void {
    const list: any = this.ecpsList;
    const leftShadow: HTMLElement = document.getElementById(
      `ecp-timeline-left-shadow-${this.currentEventTimelineIndex}`,
    );
    const rightShadow: HTMLElement = document.getElementById(
      `ecp-timeline-right-shadow-${this.currentEventTimelineIndex}`,
    );

    if (rightShadow && leftShadow) {
      rightShadow.style.right = '0px';

      this.chartHelper.manageChartShadows(list, leftShadow, rightShadow);
    }
  }
}
