import { FormGroup } from '@angular/forms';

export const setHazmatValue: (beHazmatValue: boolean, incidentForm: FormGroup, incidentType: string) => boolean = (
  beHazmatValue: boolean,
  incidentForm: FormGroup,
  incidentType: string,
) => {
  if (
    (incidentForm?.get('incidentData.incidentType').value === incidentType &&
      incidentForm?.get('incidentData.incidentType').dirty) ||
    (incidentForm?.get('incidentData.incidentSpecialExposure').value === incidentType &&
      incidentForm?.get('incidentData.incidentSpecialExposure').dirty)
  ) {
    return true;
  } else if (
    (incidentForm?.get('incidentData.incidentType').value !== incidentType &&
      incidentForm?.get('incidentData.incidentType').dirty) ||
    (incidentForm?.get('incidentData.incidentSpecialExposure').value !== incidentType &&
      incidentForm?.get('incidentData.incidentSpecialExposure').dirty)
  ) {
    return beHazmatValue;
  }

  return beHazmatValue;
};
