import { GenericAction } from 'src/app/common';
import { errorPostPutItemData, receivePostPutItemData, requestItemData } from '../../../../common/utils/reducer/reducer.utils';
import { IncidentActionTypes } from '../../actions/incident-action-types';
import { IMergeIncidentsState, MergeIncidentsState } from '../../models/incident-merge.model';

const resetMergeIncidentsState = (lastState: IMergeIncidentsState): IMergeIncidentsState => ({
  ...lastState,
  merged: {
    isLoading: false,
    data: null,
    errors: null,
    isSuccess: false,
  },
});

export function mergeIncidentReducer(
  lastState: IMergeIncidentsState = new MergeIncidentsState(),
  action: GenericAction<IncidentActionTypes, any>
): IMergeIncidentsState {
  switch (action.type) {

    case IncidentActionTypes.REQUEST_MERGE_INCIDENTS:
      return requestItemData<IMergeIncidentsState, any>(lastState, ['merged']);
    case IncidentActionTypes.RECEIVE_MERGE_INCIDENTS:
      return receivePostPutItemData<IMergeIncidentsState, any>(lastState, ['merged'], action.payload);
    case IncidentActionTypes.ERROR_MERGE_INCIDENTS:
      return errorPostPutItemData<IMergeIncidentsState, any>(lastState, ['merged'], action.payload);

    case IncidentActionTypes.RESET_STATE:
      return resetMergeIncidentsState(lastState);

    default:
      return lastState;
  }
}
