import { ChangeDetectionStrategy, Component, Input, OnChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'ignis-device-connection-form-assignment',
  templateUrl: './device-connection-form-assignment.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceConnectionFormAssignmentComponent implements OnChanges {
  @ViewChild('assignedFireStationsDD') assignedFireStationsDD: NgSelectComponent | any;

  @Input() deviceConnectionForm: FormGroup;
  @Input() fireStations: string[];
  assignedFireStationsToShow: string[];
  fsDropdownAreOpened: boolean[];

  constructor() {
    this.fsDropdownAreOpened = [];
  }

  ngOnChanges() {
    this.assignedFireStationsToShow = this.fireStations;
  }

  addAssignedFireStation(event: Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;

    if (target.value) {
      const mergedArray: string[] = [target.value, ...(this.fireStations || [])];

      this.assignedFireStationsToShow = Array.from(new Set(mergedArray));
    } else {
      this.assignedFireStationsToShow = this.fireStations;
    }
  }

  onFireStationChange(fireStationName: string): void {
    if (!fireStationName) {
      this.deviceConnectionForm.get('assignedFireStation').setValue(null);
    }
  }

  toggleAssignedFireStationDropdown(assignedFireStationDDName: string): void {
    this.fsDropdownAreOpened.push(this[assignedFireStationDDName].isOpen);

    if (this.fsDropdownAreOpened.length === 2) {
      this[assignedFireStationDDName].close();
      this[assignedFireStationDDName].focus();
      this.fsDropdownAreOpened = [];
    }
  }
}
