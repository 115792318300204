import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CommonConstants, StorageConstants } from '../..';

@Injectable({
  providedIn: 'root',
})
export class UserStorageService {
  jwtHelper: JwtHelperService = new JwtHelperService();
  persistentLocalStorageKeys: string[] = StorageConstants.persistentLocalStorageKeys;

  get userInfo(): any {
    return this.jwtHelper.decodeToken(localStorage.getItem(CommonConstants.ACCESS_TOKEN));
  }

  setStoredValue(key: string, data: any): void {
    localStorage.setItem(`${this.userInfo?.sub}.${key}`, JSON.stringify(data));
  }

  getStoredValue(key: string): any {
    return JSON.parse(localStorage.getItem(`${this.userInfo?.sub}.${key}`));
  }

  removeUserLocalStorage(key: string): any {
    localStorage.removeItem(`${this.userInfo?.sub}.${key}`);
  }

  clearBrowserLocalStorageWithoutPersistentKeys(): void {
    const allKeys: string[] = Object.keys(localStorage);
    const keysToRemove: string[] = allKeys.filter(
      (key: string) => !this.persistentLocalStorageKeys.some((persistentKey: string) => key.includes(persistentKey)),
    );

    keysToRemove.forEach((key: string) => localStorage.removeItem(key));
  }
}
