import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, takeUntil } from 'rxjs';
import { IApplicationState, IStoreApiItem } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins';
import { IProtectorMigrationErrorPage } from 'src/app/device-connection/models/protector-migration.model';
import { selectProtectorMigrationErrorPage } from 'src/app/device-connection/state/selectors/device-connection.selector';
import { IRAMStatus, ITestStatus } from 'src/app/workshop/models';
import { selectCheckRAMConnectorStatus, selectCheckRAMPortStatus } from 'src/app/workshop/state/selectors';

@Component({
  selector: 'ignis-protector-software-migration-progress-bar',
  templateUrl: './protector-software-migration-progress-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProtectorSoftwareMigrationProgressBarComponent extends OnDestroyMixin() implements OnInit {
  isLoading$: Observable<boolean>;

  store: Store<IApplicationState> = inject(Store);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.isLoading$ = combineLatest([
      this.store.select(selectCheckRAMConnectorStatus),
      this.store.select(selectCheckRAMPortStatus),
      this.store.select(selectProtectorMigrationErrorPage),
    ]).pipe(
      map(
        ([statusState, portStatus, errors]: [
          IStoreApiItem<ITestStatus>,
          IStoreApiItem<IRAMStatus>,
          IStoreApiItem<IProtectorMigrationErrorPage>,
        ]) => statusState.isLoading || portStatus.isLoading || errors.isLoading,
      ),
      takeUntil(this.destroy),
    );

    this.cdr.detectChanges();
  }
}
