import { createSelector, DefaultProjectorFn, MemoizedSelector, Selector } from '@ngrx/store';
import { IApplicationState, IStoreApiItem, IStoreApiList } from 'src/app/common';
import { IMeasurementUnit } from './../../models/localization-profile.model';
import { ILocalizationProfile } from '../..';
import { ISettingsState } from '../models/settings.model';
import { ITableSettingsResponse } from 'src/app/common/models/table.model';

export const selectSettingsState: Selector<IApplicationState, ISettingsState> = (state: IApplicationState) =>
  state.settings;

export const selectLocalizationSettingsList: MemoizedSelector<
  IApplicationState,
  IStoreApiList<ILocalizationProfile[]>,
  DefaultProjectorFn<IStoreApiList<ILocalizationProfile[]>>
> = createSelector(selectSettingsState, (state: ISettingsState) => state.localizationList);

export const selectUserSettings: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<ILocalizationProfile>,
  DefaultProjectorFn<IStoreApiItem<ILocalizationProfile>>
> = createSelector(selectSettingsState, (state: ISettingsState) => state.userSettings);

export const selectMeasurementUnits: MemoizedSelector<
  IApplicationState,
  IStoreApiList<IMeasurementUnit[]>,
  DefaultProjectorFn<IStoreApiList<IMeasurementUnit[]>>
> = createSelector(selectSettingsState, (state: ISettingsState) => state.measurementUnits);

export const selectTableColumns: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<ITableSettingsResponse>,
  DefaultProjectorFn<IStoreApiItem<ITableSettingsResponse>>
> = createSelector(selectSettingsState, (state: ISettingsState) => state.tableColumns);
