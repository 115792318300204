<div class="blue-bar-items">
  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="edit-report-center-list-item"
    (click)="onEditClick($event)"
    [disabled]="!selectedItem || !selectedMerge || isModalOpened"
    id="bluebar-edit"
    data-test-id="report_center_table.open_incident_page_btn"
    [pTooltip]="editButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #editButtonTooltipContent>
      <div data-test-id="report_center_table.open_incident_page_tooltip">
        {{ 'REPORT_CENTER_TABLE.STR_OPEN_INCIDENT_REPORT_TOOLTIP' | translate }}
      </div>
    </ng-template>

    <odx-icon size="inline" name="edit"></odx-icon>
  </button>

  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="merge-report-center-list-item"
    id="bluebar-merge"
    (click)="onMergeIncidentsClick($event)"
    [disabled]="!selectedItem || isModalOpened"
    data-test-id="report_center_table.merge_incidents_btn"
    [pTooltip]="mergeButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #mergeButtonTooltipContent>
      @if (!selectedItem) {
        <div data-test-id="report_center_table.merge_incidents_not_selected_tooltip">
          {{ 'REPORT_CENTER_TABLE.STR_MERGE_INCIDENT_NOT_SELECTED_TOOLTIP' | translate }}
        </div>
      } @else if (selectedItem && !mergeMode) {
        <div data-test-id="report_center_table.merge_incidents_select_next_tooltip">
          {{ 'REPORT_CENTER_TABLE.STR_BEGIN_MERGE_INCIDENT_TOOLTIP' | translate }}
        </div>
      } @else if (selectedItem && mergeMode) {
        <div data-test-id="report_center_table.merge_incidents_select_last_tooltip">
          {{ 'REPORT_CENTER_TABLE.STR_MERGE_INCIDENT_SELECT_SECOND_TOOLTIP' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon size="inline" name="composition-edit"></odx-icon>
  </button>

  <button
    type="button"
    name="download-report-center-excel-file"
    id="bluebar-export-excel"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    [disabled]="!selectedItem || !selectedMerge"
    (click)="onExportExcelClick($event)"
    data-test-id="report_center_table.download_incident_report_btn"
    [pTooltip]="downloadExcelButtonTooltipContent"
    tooltipPosition="top"
    positionTop="-10"
  >
    <ng-template #downloadExcelButtonTooltipContent>
      <div data-test-id="report_center_table.download_incident_report_tooltip">
        {{ 'REPORT_CENTER_TABLE.STR_DOWNLOAD_INCIDENT_REPORT_TOOLTIP' | translate }}
      </div>
    </ng-template>

    <odx-icon size="inline" name="download"></odx-icon>
  </button>

  <ignis-export-excel-footer-btn
    [isModalOpened]="isModalOpened"
    [numberOfRecordsToBeExported]="numberOfRecordsToBeExported"
    (handleClickEvent)="onExportDataTableToExcel($event)"
  >
  </ignis-export-excel-footer-btn>
</div>
