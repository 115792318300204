import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IBAWearer, ICreateUpdateBAWearer, IDeleteBAWearer } from 'src/app/ba-wearer/models';
import { BAWearerActionTypes } from '../ba-wearer-action-types';

export class RequestBAWearerById implements Action {
  readonly type: BAWearerActionTypes.REQUEST_BA_WEARER_BY_ID = BAWearerActionTypes.REQUEST_BA_WEARER_BY_ID;

  constructor(public payload: string) {}
}
export class ReceiveBAWearerById implements Action {
  readonly type: BAWearerActionTypes.RECEIVE_BA_WEARER_BY_ID = BAWearerActionTypes.RECEIVE_BA_WEARER_BY_ID;

  constructor(public payload: IBAWearer) {}
}
export class ErrorReceiveBAWearerById implements Action {
  readonly type: BAWearerActionTypes.ERROR_RECEIVE_BA_WEARER_BY_ID = BAWearerActionTypes.ERROR_RECEIVE_BA_WEARER_BY_ID;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestCreateBAWearer implements Action {
  readonly type: BAWearerActionTypes.REQUEST_CREATE_BA_WEARER = BAWearerActionTypes.REQUEST_CREATE_BA_WEARER;

  constructor(public payload: ICreateUpdateBAWearer) {}
}
export class ReceiveCreateBAWearer implements Action {
  readonly type: BAWearerActionTypes.RECEIVE_CREATE_BA_WEARER = BAWearerActionTypes.RECEIVE_CREATE_BA_WEARER;

  constructor(public payload: IBAWearer) {}
}
export class ErrorReceiveCreateBAWearer implements Action {
  readonly type: BAWearerActionTypes.ERROR_RECEIVE_CREATE_BA_WEARER =
    BAWearerActionTypes.ERROR_RECEIVE_CREATE_BA_WEARER;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestUpdateBAWearer implements Action {
  readonly type: BAWearerActionTypes.REQUEST_UPDATE_BA_WEARER = BAWearerActionTypes.REQUEST_UPDATE_BA_WEARER;

  constructor(public payload: ICreateUpdateBAWearer) {}
}
export class ReceiveUpdateBAWearer implements Action {
  readonly type: BAWearerActionTypes.RECEIVE_UPDATE_BA_WEARER = BAWearerActionTypes.RECEIVE_UPDATE_BA_WEARER;

  constructor(public payload: IBAWearer) {}
}
export class ErrorReceiveUpdateBAWearer implements Action {
  readonly type: BAWearerActionTypes.ERROR_RECEIVE_UPDATE_BA_WEARER =
    BAWearerActionTypes.ERROR_RECEIVE_UPDATE_BA_WEARER;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestDeleteBAWearer implements Action {
  readonly type: BAWearerActionTypes.REQUEST_DELETE_BA_WEARER = BAWearerActionTypes.REQUEST_DELETE_BA_WEARER;

  constructor(public payload: IDeleteBAWearer) {}
}
export class ReceiveDeleteBAWearer implements Action {
  readonly type: BAWearerActionTypes.RECEIVE_DELETE_BA_WEARER = BAWearerActionTypes.RECEIVE_DELETE_BA_WEARER;

  constructor(public payload: IBAWearer) {}
}
export class ErrorReceiveDeleteBAWearer implements Action {
  readonly type: BAWearerActionTypes.ERROR_RECEIVE_DELETE_BA_WEARER =
    BAWearerActionTypes.ERROR_RECEIVE_DELETE_BA_WEARER;

  constructor(public payload: HttpErrorResponse) {}
}
