import { Injectable } from '@angular/core';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { AppUserPermissionList } from 'src/app/root/models/app-types';
import { AccessControlService } from 'src/app/root/services/access-control/access-control.service';

@Injectable()
export class WorkshopTestingModuleGuard extends OnDestroyMixin() {
  constructor(private accessControlService: AccessControlService) {
    super();
  }

  canActivate(): boolean {
    if (this.accessControlService.permissions) {
      return this.accessControlService.canActivateModule(AppUserPermissionList.equipmentTest);
    }
  }
}
