import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IEntriesModel } from 'src/app/report-center/models/incident.model';
import { RemoteMonitoringActionTypes } from '../remote-monitoring-action-types';


export class RequestIncidentEntries implements Action {
  readonly type: RemoteMonitoringActionTypes.REQUEST_REMOTE_MONITORING_ENTRIES = RemoteMonitoringActionTypes.REQUEST_REMOTE_MONITORING_ENTRIES;
}
export class ReceiveIncidentEntries implements Action {
  readonly type: RemoteMonitoringActionTypes.RECEIVE_REMOTE_MONITORING_ENTRIES = RemoteMonitoringActionTypes.RECEIVE_REMOTE_MONITORING_ENTRIES;

  constructor(public payload: IEntriesModel) { }
}
export class ErrorReceiveIncidentEntries implements Action {
  readonly type: RemoteMonitoringActionTypes.ERROR_RECEIVE_REMOTE_MONITORING_ENTRIES =
    RemoteMonitoringActionTypes.ERROR_RECEIVE_REMOTE_MONITORING_ENTRIES;

  constructor(public payload: HttpErrorResponse) { }
}
