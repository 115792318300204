import { GenericAction } from '../../../common/state/models/generic-action.model';
import { errorItemData, receiveItemData, requestItemData } from '../../../common/utils/reducer/reducer.utils';
import { DeviceConnectionActionTypes } from '../actions/device-connection-action-types';
import { IRAMConnectorDataState, RAMConnectorDataState } from '../models/device-connection.model';

const resetRAMConnectorToken = (lastState: IRAMConnectorDataState): IRAMConnectorDataState => ({
  ...lastState,
  checkRAMConnectorToken: {
    isLoading: false,
    isSuccess: false,
    data: null,
    errors: null,
  },
});

export function ramConnectorTokenReducer(
  lastState: IRAMConnectorDataState = new RAMConnectorDataState(),
  action: GenericAction<DeviceConnectionActionTypes, any>,
): IRAMConnectorDataState {
  switch (action.type) {
    case DeviceConnectionActionTypes.REQUEST_TO_CHECK_RAM_CONNECTOR_TOKEN:
      return requestItemData<IRAMConnectorDataState, any>(lastState, ['checkRAMConnectorToken']);
    case DeviceConnectionActionTypes.RECEIVE_TO_CHECK_RAM_CONNECTOR_TOKEN:
      return receiveItemData<IRAMConnectorDataState, any>(lastState, ['checkRAMConnectorToken'], action.payload);
    case DeviceConnectionActionTypes.ERROR_RECEIVE_TO_CHECK_RAM_CONNECTOR_TOKEN:
      return errorItemData<IRAMConnectorDataState, any>(lastState, ['checkRAMConnectorToken'], action.payload);
    case DeviceConnectionActionTypes.RESET_CHECK_RAM_CONNECTOR_TOKEN:
      return resetRAMConnectorToken(lastState);

    default:
      return lastState;
  }
}
