import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { HttpHelper } from 'src/app/common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UploadFilesService {
  httpClient: HttpClient = inject(HttpClient);
  httpHelper: HttpHelper = inject(HttpHelper);

  private baseUrl: string = environment.API_URLS.DEVICE_CONNECTION;

  public uploadFiles(file: File): Observable<HttpResponse<File> | HttpErrorResponse> {
    const formData: FormData = new FormData();

    formData.append('file', file, file.name);

    return this.httpClient.post<File>(`${this.baseUrl}/files`, formData, { observe: 'response' });
  }

  public deleteSelectedFile(fileName: string): Observable<File | HttpErrorResponse> {
    return this.httpClient.delete<File>(`${this.baseUrl}/files/${fileName}`);
  }

  public removeUploadedFile(fileName: string): unknown {
    return lastValueFrom(this.deleteSelectedFile(fileName));
  }
}
