import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import {
  IOrganizationAccessControl,
  IProfile,
  IRAMSettings,
} from 'src/app/configuration/models/organization-settings.model';
import { selectOrganizationAccessControl } from 'src/app/configuration/state/selectors/configuration.selectors';
import { ILocalizationProfile, settingsSelectors } from '../../../settings';
import { CommonConstants } from '../../constants/common.constants';
import { IStoreApiItem } from '../../models/store-api-item.model';
import { ApplicationState } from '../../state/models/app.state.model';

export function getLanguage(store: Store<ApplicationState>, destroy: Subject<void>): Observable<string> {
  return store.select(settingsSelectors.selectUserSettings).pipe(
    filter(
      (userSettings: IStoreApiItem<ILocalizationProfile>) =>
        Boolean(userSettings) && !userSettings.errors && !userSettings.isLoading && !!userSettings.data,
    ),
    takeUntil(destroy),
    map((userSettings: IStoreApiItem<ILocalizationProfile>) => {
      return userSettings.data?.language ?? '';
    }),
  );
}

export function getDateFormatFromUserProfile(translateService: TranslateService): string {
  return translateService.instant('SETTINGS.STR_DATE_FORMAT');
}

export function getDecimalNumberFormatUserProfile(translateService: TranslateService): string {
  return translateService.instant('SETTINGS.STR_DECIMAL_NUMBER_FORMAT');
}

export function getDateFormatFromOrganizationProfile(
  store: Store<ApplicationState>,
  destroy: Subject<void>,
): Observable<string> {
  return store.select(selectOrganizationAccessControl).pipe(
    filter(
      (orgProfile: IStoreApiItem<IOrganizationAccessControl>) =>
        Boolean(orgProfile) && !orgProfile.errors && !orgProfile.isLoading && !!orgProfile.data,
    ),
    takeUntil(destroy),
    map((orgProfile: IStoreApiItem<IOrganizationAccessControl>) => {
      return orgProfile.data.organizationSettings?.dateFormat ?? '';
    }),
  );
}

export function getLanguageFromOrganizationProfile(
  store: Store<ApplicationState>,
  destroy: Subject<void>,
): Observable<string> {
  return store.select(selectOrganizationAccessControl).pipe(
    filter(
      (orgProfile: IStoreApiItem<IOrganizationAccessControl>) =>
        Boolean(orgProfile) && !orgProfile.errors && !orgProfile.isLoading && !!orgProfile.data,
    ),
    takeUntil(destroy),
    map((orgProfile: IStoreApiItem<IOrganizationAccessControl>) => {
      return orgProfile.data.organizationSettings?.language ?? '';
    }),
  );
}

export function getOrgNameFromOrganizationProfile(
  store: Store<ApplicationState>,
  destroy: Subject<void>,
): Observable<string> {
  return store.select(selectOrganizationAccessControl).pipe(
    filter(
      (orgProfile: IStoreApiItem<IOrganizationAccessControl>) =>
        Boolean(orgProfile) && !orgProfile.errors && !orgProfile.isLoading && !!orgProfile.data,
    ),
    takeUntil(destroy),
    map((orgProfile: IStoreApiItem<IOrganizationAccessControl>) => {
      return orgProfile.data.organizationSettings?.name ?? '';
    }),
  );
}

export function getPressureUnit(store: Store<ApplicationState>, destroy: Subject<void>): Observable<string> {
  return store.select(selectOrganizationAccessControl).pipe(
    filter(
      (orgProfile: IStoreApiItem<IOrganizationAccessControl>) =>
        Boolean(orgProfile) && !orgProfile.errors && !orgProfile.isLoading && !!orgProfile.data,
    ),
    takeUntil(destroy),
    map((orgProfile: IStoreApiItem<IOrganizationAccessControl>) => {
      if (orgProfile.data) {
        if (orgProfile.data.fireGroundSettings) {
          const profile: IProfile = orgProfile.data.fireGroundSettings.profiles.find(
            (prof: IProfile) => prof.isSelected,
          );

          return profile?.displayUnit ?? '';
        }

        return CommonConstants.pressureDisplayUnit['bar'];
      }
    }),
  );
}

export function getDefaultTimeDisplay(store: Store<ApplicationState>, destroy: Subject<void>): Observable<string> {
  return store.select(selectOrganizationAccessControl).pipe(
    filter(
      (orgProfile: IStoreApiItem<IOrganizationAccessControl>) =>
        Boolean(orgProfile) && !orgProfile.errors && !orgProfile.isLoading && !!orgProfile.data,
    ),
    takeUntil(destroy),
    map((orgProfile: IStoreApiItem<IOrganizationAccessControl>) => {
      const profile: IProfile = orgProfile.data.fireGroundSettings.profiles.find((prof: IProfile) => prof.isSelected);

      return profile.defaultTimeDisplay;
    }),
  );
}

export function getMediumPressureThreshold(store: Store<ApplicationState>, destroy: Subject<void>): Observable<number> {
  return store.select(selectOrganizationAccessControl).pipe(
    filter(
      (orgProfile: IStoreApiItem<IOrganizationAccessControl>) =>
        Boolean(orgProfile) && !orgProfile.errors && !orgProfile.isLoading && !!orgProfile.data,
    ),
    takeUntil(destroy),
    map((orgProfile: IStoreApiItem<IOrganizationAccessControl>) => {
      const profile: IProfile = orgProfile.data.fireGroundSettings.profiles.find((prof: IProfile) => prof.isSelected);

      return profile.mediumPressureThreshold;
    }),
  );
}

export function getLowPressureThreshold(store: Store<ApplicationState>, destroy: Subject<void>): Observable<number> {
  return store.select(selectOrganizationAccessControl).pipe(
    filter(
      (orgProfile: IStoreApiItem<IOrganizationAccessControl>) =>
        Boolean(orgProfile) && !orgProfile.errors && !orgProfile.isLoading && !!orgProfile.data,
    ),
    takeUntil(destroy),
    map((orgProfile: IStoreApiItem<IOrganizationAccessControl>) => {
      const profile: IProfile = orgProfile.data.fireGroundSettings.profiles.find((prof: IProfile) => prof.isSelected);

      return profile.lowPressureThreshold;
    }),
  );
}

export function getRAMSettingsAvailableWorkflows(
  store: Store<ApplicationState>,
  destroy: Subject<void>,
): Observable<object> {
  return store.select(selectOrganizationAccessControl).pipe(
    filter(
      (orgProfile: IStoreApiItem<IOrganizationAccessControl>) =>
        Boolean(orgProfile) && !orgProfile.errors && !orgProfile.isLoading && !!orgProfile.data,
    ),
    takeUntil(destroy),
    map((orgProfile: IStoreApiItem<IOrganizationAccessControl>) => {
      const predefinedWorkflows: IRAMSettings = orgProfile.data.ramSettings;

      return predefinedWorkflows;
    }),
  );
}
