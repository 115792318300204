import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from 'redux';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { WorkshopService } from 'src/app/workshop/services';
import * as models from '../../../models';
import * as actions from '../../actions/equipment-test';

@Injectable()
export class EquipmentTestEffects {
  workshopService: WorkshopService = inject(WorkshopService);

  requestIsTestingInProgress$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentTestActionTypes.REQUEST_EQUIPMENT_TEST_IS_RUNNING),
      switchMap(() =>
        this.workshopService.testRunning().pipe(
          map((testStatus: models.ITestStatus) => new actions.ReceiveEquipmentTestIsRunning(testStatus)),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveEquipmentTestIsRunning(error))),
        ),
      ),
    );
  });

  requestSaveTestResults$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentTestActionTypes.REQUEST_SAVE_TEST_RESULTS),
      switchMap((equipmentId: { payload: any }) =>
        this.workshopService.saveTestResults(equipmentId.payload).pipe(
          map((saveTest: any) => new actions.ReceiveSaveTestResults(saveTest)),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveSaveTestResults(error))),
        ),
      ),
    );
  });

  requestTestParameters$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentTestActionTypes.REQUEST_TEST_PARAMETERS),
      switchMap((params: any) =>
        this.workshopService.getTestParameters(params.payload).pipe(
          map((testParameters: models.ITestParameters) => new actions.ReceiveTestParameters({ ...testParameters })),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveTestParameters(error))),
        ),
      ),
    );
  });

  requestStartTestEquipment$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentTestActionTypes.REQUEST_START_TEST_EQUIPMENT),
      switchMap((testEquipment: { payload: models.ITestStart }) =>
        this.workshopService.startTestEquipment(testEquipment.payload).pipe(
          map((startTestResult: models.ITestStartResponse) => new actions.ReceiveStartTestEquipment(startTestResult)),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveStartTestEquipment(error))),
        ),
      ),
    );
  });

  requestTestResults$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentTestActionTypes.REQUEST_TEST_RESULTS),
      switchMap((identifier: { payload: string }) =>
        this.workshopService.getTestResults(identifier.payload).pipe(
          map(
            (testResults: HttpResponse<models.ITestResultDetails>) => new actions.ReceiveTestResults(testResults?.body),
          ),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveTestResults(error))),
        ),
      ),
    );
  });

  requestWorkflowAsset$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentTestActionTypes.REQUEST_WORKFLOW_ASSET),
      switchMap((params: any) =>
        this.workshopService.getWorkflowAsset(params.payload).pipe(
          map((equipment: models.IEquipment) => new actions.ReceiveWorkflowAsset(new models.Equipment(equipment))),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveWorkflowAsset(error))),
        ),
      ),
    );
  });
}
