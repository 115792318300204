import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpResponseBase,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { HttpHelper, PropertyBag } from 'src/app/common';
import { environment } from 'src/environments/environment';
import {
  IEditLocationHierarchy,
  ILocationHierarchy,
  ILocationHierarchyChange,
  INewLocationHierarchy,
} from './../models/location-configuration.model';

@Injectable({
  providedIn: 'root',
})
export class LocationConfigurationService {
  private baseUrl: string = environment.API_URLS.LOCATION;

  constructor(
    private httpClient: HttpClient,
    private httpHelper: HttpHelper,
  ) {}

  public getLocationHierarchy(): Observable<ILocationHierarchy[] | HttpErrorResponse> {
    return this.httpClient.get<ILocationHierarchy[]>(`${this.baseUrl}/locations`, this.httpHelper.options);
  }

  public addLocationHierarchy(params: INewLocationHierarchy): Observable<ILocationHierarchy | HttpErrorResponse> {
    return this.httpClient.post<any>(`${this.baseUrl}/locations`, params, this.httpHelper.options);
  }

  public changeLocationHierarchy(
    params: ILocationHierarchyChange,
  ): Observable<ILocationHierarchyChange | HttpErrorResponse> {
    const objToSend: ILocationHierarchyChange = { ...params };
    const version: string = params.version?.toString();

    delete objToSend.version;

    return this.httpClient.put<IEditLocationHierarchy>(`${this.baseUrl}/locations/change-parent`, objToSend, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'If-Match': version,
      }),
    });
  }

  public getLocationTypeValues(): Observable<any[] | HttpErrorResponse> {
    return this.httpClient.get<any[]>(`${this.baseUrl}/locations/types`, this.httpHelper.options);
  }

  public getLocationConfiguration(params: any): Observable<IEditLocationHierarchy | HttpErrorResponse> {
    return this.httpClient
      .get<IEditLocationHierarchy>(`${this.baseUrl}/locations/${params}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      })
      .pipe(
        tap((response: HttpResponseBase | any) => {
          this.setVersionFromETagHeader(response);
        }),
      );
  }

  public editLocationHierarchy(params: IEditLocationHierarchy): Observable<IEditLocationHierarchy | HttpErrorResponse> {
    const objToSend: IEditLocationHierarchy = { ...params };
    const version: string = params.version?.toString();
    const aggregateId: string = params.aggregateId;

    delete objToSend.version;
    delete objToSend.aggregateId;

    return this.httpClient.put<IEditLocationHierarchy>(`${this.baseUrl}/locations/${aggregateId}`, objToSend, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'If-Match': version,
      }),
    });
  }

  public deleteLocationHierarchy(params: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.delete<any>(`${this.baseUrl}/locations/${params.payload?.id}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'If-Match': params.payload?.version,
      }),
    });
  }

  public getSearchLocation(id: string): Observable<any | HttpErrorResponse> {
    let httpParams: HttpParams = new HttpParams();
    const idParam: Partial<PropertyBag> = { identifier: id };

    httpParams = httpParams.append('identifier', idParam['identifier']);

    return this.httpClient
      .get<any>(`${this.baseUrl}/locations/search`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
        params: httpParams,
      })
      .pipe(
        tap((response: HttpResponse<any>) => {
          this.setVersionFromETagHeader(response);
        }),
      );
  }

  setVersionFromETagHeader(response: HttpResponse<any>): string {
    if (response.body) {
      response.body.version = response.headers?.get('etag');

      return response.body.version;
    }
  }
}
