import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard } from '@okta/okta-angular';
import { CreateUpdateUserComponent } from '../components/create-update-user/create-update-user.component';
import { UserManagementModuleRoutes } from '../constants/user-management-module-routes.contants';
import { UserManagementComponent } from './../components/user-management/user-management.component';
import { DeactivateUserManagementGuard } from './can-deactivate.guard';

export const routes: Routes = [
  {
    path: '',
    canActivate: [OktaAuthGuard],
    component: UserManagementComponent,
    children: [
      {
        path: UserManagementModuleRoutes.createUser,
        component: CreateUpdateUserComponent,
        canDeactivate: [DeactivateUserManagementGuard],
      },
      {
        path: `${UserManagementModuleRoutes.updateUser}/:id`,
        component: CreateUpdateUserComponent,
        canDeactivate: [DeactivateUserManagementGuard],
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserManagementRoutingModule {}
