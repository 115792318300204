<ignis-progress-bar *ngIf="processingPreviousRoute"></ignis-progress-bar>

<div *ngIf="processingPreviousRoute; else appTiles" class="centered-loader">
  <div [odxLoadingSpinner]="true" [odxLoadingSpinnerMinHeight]="60" class="app-loader"></div>
</div>

<ng-template #appTiles>
  <div class="tiles-container">
    <div
      class="app-tiles"
      [ngClass]="{
        'grid-1-item': visibleTilesNo === 1,
        'grid-2-items': visibleTilesNo === 2 || visibleTilesNo === 4,
        'grid-3-items': visibleTilesNo === 6 || visibleTilesNo % 2 === 1,
        'grid-4-items': visibleTilesNo === 7,
      }"
    >
      <odx-card
        *ngFor="let tile of tiles"
        [ngClass]="{
          'hide-elem': !tile.visibility,
        }"
        [menuIcon]="menuIcon"
        [disabled]="disabled"
        [attr.data-test-id]="tile.title"
        (interact)="goToModule(tile.route)"
      >
        <odx-card-content>
          <odx-icon size="xlarge" name="{{ tile.icon }}"></odx-icon>
          <p>{{ tile.title | translate }}</p>
        </odx-card-content>
      </odx-card>
    </div>
  </div>
</ng-template>
