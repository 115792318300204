import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CheckingSettingsModalsService, GenericFooterItemsComponent, PropertyBag } from 'src/app/common';
import { ConfigurationModuleRoutes } from 'src/app/configuration/constants/configuration-module-routes.constants';

@Component({
  selector: 'ignis-location-hierarchy-view-footer',
  templateUrl: './location-hierarchy-view-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationHierarchyViewFooterComponent<T> extends GenericFooterItemsComponent<T> {
  @Input() disableAddBtn: boolean;
  @Input() isModalOpened: boolean;
  @Input() disableEditBtnAndDelete: boolean;

  readonly ConfigurationModuleRoutes: PropertyBag = ConfigurationModuleRoutes;

  constructor(
    public router: Router,
    private checkSettingsModal: CheckingSettingsModalsService,
  ) {
    super();

    this.checkSettingsModal.checkIfSettingsModalIsOpen().subscribe((isModalOpened: boolean) => {
      this.isModalOpened = isModalOpened;
      this.cdr.detectChanges();
    });
  }
}
