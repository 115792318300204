<ng-template #tooltipMessage data-test-id="overdue">
  {{ 'SERVICE_TASK_TABLE.STR_SERVICE_DATE_IS_OVERDUE' | translate: { date: nextServiceDate?.data } }}
</ng-template>
<span
  [ngClass]="{ overdue: isServiceOverdue }"
  [pTooltip]="isServiceOverdue ? tooltipMessage : null"
  tooltipPosition="top"
  positionTop="-10"
>
  {{ nextServiceDate?.data }}
</span>
