<ng-container *ngIf="entry" [ngSwitch]="entry.value">
  <ng-container *ngSwitchCase="'dwelling'">
    {{ 'INCIDENT_TYPE_TRANSLATION.DWELLING' | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="'other_buildings'">
    {{ 'INCIDENT_TYPE_TRANSLATION.OTHER_BUILDINGS' | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="'road_vehicles'">
    {{ 'INCIDENT_TYPE_TRANSLATION.ROAD_VEHICLES' | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="'other_outdoor'">
    {{ 'INCIDENT_TYPE_TRANSLATION.OTHER_OUTDOOR' | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="'chimney_fire'">
    {{ 'INCIDENT_TYPE_TRANSLATION.CHIMNEY_FIRE' | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="'accidental_fire'">
    {{ 'INCIDENT_TYPE_TRANSLATION.ACCIDENTAL_FIRE' | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="'deliberate_fire'">
    {{ 'INCIDENT_TYPE_TRANSLATION.DELIBERATE_FIRE' | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="'false_alarm'">
    {{ 'INCIDENT_TYPE_TRANSLATION.FALSE_ALARM' | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="'training'">
    {{ 'INCIDENT_TYPE_TRANSLATION.TRAINING' | translate }}
  </ng-container>
  <ng-container *ngSwitchDefault>
    <span
      [title]="
        entry.value && entry.value.length > 0
          ? (entry.value[0]?.toUpperCase() + entry.value.slice(1)).replace('_', '')
          : ''
      "
    >
      {{
        entry.value && entry.value.length > 0
          ? (entry.value[0]?.toUpperCase() + entry.value.slice(1)).replace('_', '')
          : ''
      }}
    </span>
  </ng-container>
</ng-container>
