<ngx-simplebar class="widget-container">
  <div class="note-list" *ngFor="let note of notes; let i = index; trackBy: trackByFn">
    <div class="note">
      <div class="icon" [class]="currentAppTheme">
        <odx-icon size="inline" name="user"></odx-icon>
      </div>
      <div class="note-content">
        <span class="name" data-test-id="remote_monitoring.sender_name">{{ note.senderName }}</span>
        <span class="location">
          <span *ngIf="note.ecpNumber && !note.manualNote">
            {{ 'REMOTE_MONITORING.STR_NOTE_ECP_NUMBER' | translate }} {{ note.ecpNumber }}
          </span>
          <span
            *ngIf="note.ecpNumber && note.teamName && !note.manualNote && note.teamId !== '0'"
            data-test-id="remote_monitoring.empty_team_name"
            >-</span
          >
          <span *ngIf="note.teamName && note.teamId !== '0'" data-test-id="remote_monitoring.team_name">
            {{ note.teamName }}
          </span>
        </span>
        <span class="message" data-test-id="remote_monitoring.message">
          {{ note.note }}
        </span>
        <ignis-image-audio
          *ngIf="note?.mediaLink?.length > 0"
          [mediaName]="note.mediaLink"
          [toggleWidget]="toggleWidget"
          (handleNoteImageUrl)="getNoteImage($event)"
        ></ignis-image-audio>
        <div class="note-footer" [class]="currentAppTheme">
          <span>
            <span *ngIf="note.ecpName">{{ note.ecpName }} |</span> {{ note.timestamp | date: formatDate }}
            {{ note.time }}
          </span>
        </div>
      </div>
      <div class="hover-effect" #displayOnHover></div>
    </div>
  </div>
</ngx-simplebar>
