import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { combineLatest, filter } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CommonConstants, IApplicationState, IFireStationList, IStoreApiList } from 'src/app/common';
import { IEntryModel, PropertyBag } from 'src/app/common/models/common.model';
import { DropdownService } from 'src/app/common/services/dropdown/dropdown.service';
import { DeviceConnectionConstants } from 'src/app/device-connection/constants/device-connection.constants';
import { IDeviceConnection } from 'src/app/device-connection/models/device-connection.model';
import { AppUserPermissionList } from 'src/app/root/models';
import { IUserRole } from 'src/app/user-management/models/user.model';
import { BAWearerActions } from '../../../../ba-wearer';
import { selectFireStationList } from '../../../../ba-wearer/state/selectors/ba-wearer.selector';
import { OnDestroyMixin } from '../../../../common/mixins';
import { AccessControlService } from '../../../../root';
import { DeviceConnectionService } from '../../../services/device-connection.service';
import { DeviceConnectionActions } from '../../../state/actions/device-connection.actions';
import { selectDeviceConnectionAssignedFireStations } from '../../../state/selectors/device-connection.selector';

@Component({
  selector: 'ignis-device-connection-form',
  templateUrl: './device-connection-form.component.html',
  styleUrls: ['./device-connection-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceConnectionFormComponent extends OnDestroyMixin() implements OnChanges, OnInit {
  @Input() errors: string;
  @Input() categories: any[];
  @Input() modalType: string;
  @Input() isLoading: boolean;
  @Input() isSubmitted: boolean;
  @Input() apiKeyRoles: IUserRole[];
  @Input() deviceConnectionToken: string;
  @Input() deviceConnectionForm: FormGroup;
  @Input() deviceConnectionIdentifier: string;
  @Input() selectedDeviceConnection: IDeviceConnection;
  @Output() generateToken: EventEmitter<void> = new EventEmitter();
  @Output() setSelectedCategory: EventEmitter<IEntryModel> = new EventEmitter();

  openQRCodeModal: boolean = false;
  showNameTooltip: boolean = false;
  showCopiedTooltip: boolean = false;
  fireStations: string[];
  ramConnectorDownloadURL: string = CommonConstants.ramConnectorDownloadURL;
  deviceCategories: PropertyBag = DeviceConnectionConstants.deviceCategories;
  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;

  dropdownService: DropdownService = inject(DropdownService);
  identifierRegEx: RegExp = CommonConstants.deviceConnectionIdentifierRegEx;

  store: Store<IApplicationState> = inject(Store);
  baWearerActions: BAWearerActions = inject(BAWearerActions);
  deviceConnectionActions: DeviceConnectionActions = inject(DeviceConnectionActions);
  accessControlService: AccessControlService = inject(AccessControlService);
  deviceConnectionService: DeviceConnectionService = inject(DeviceConnectionService);

  get isFormValid(): boolean {
    return this.deviceConnectionForm.valid;
  }

  get isQRCodeDeactivated(): boolean {
    return (
      ((!this.isFormValid || this.deviceConnectionForm.dirty) && !this.deviceConnectionForm.disabled) ||
      this.deviceConnectionForm.get('category').value !== this.deviceCategories.APP
    );
  }

  ngOnInit() {
    this.requestFireStations();
    this.readFireStationListFromStore();
  }

  ngOnChanges(): void {
    if (this.deviceConnectionToken) {
      this.deviceConnectionForm.get('authenticationToken').setValue(this.deviceConnectionToken);
    }
  }

  requestFireStations(): void {
    const hasBAWearerAccess: boolean = this.accessControlService.checkPermission(AppUserPermissionList.baWearer);
    const isBAWearerToggled: boolean = this.accessControlService.baWearerFeatureToggle;

    if (isBAWearerToggled && hasBAWearerAccess) {
      this.baWearerActions.requestFireStationList();
    }

    this.deviceConnectionActions.requestDeviceConnectionAssignedFireStations();
  }

  readFireStationListFromStore(): void {
    combineLatest([
      this.store.select(selectDeviceConnectionAssignedFireStations),
      this.store.select(selectFireStationList),
    ])
      .pipe(
        filter(
          ([deviceConnectionFireStations, baWearerFireStations]: [
            IStoreApiList<IFireStationList>,
            IStoreApiList<IFireStationList>,
          ]) => !deviceConnectionFireStations.isLoading && !baWearerFireStations.isLoading,
        ),
        takeUntil(this.destroy),
      )
      .subscribe(
        ([deviceConnectionFireStations, baWearerFireStations]: [
          IStoreApiList<IFireStationList>,
          IStoreApiList<IFireStationList>,
        ]) => {
          this.fireStations = this.deviceConnectionService.mergeFireStationsFromBAWearerAndDeviceConnection(
            baWearerFireStations.data?.fireStations,
            deviceConnectionFireStations.data?.fireStations,
          );
        },
      );
  }

  categoryChanged(selection: string): void {
    const category: IEntryModel = this.categories.find((cat: IEntryModel) => cat.value === selection);

    this.setSelectedCategory.emit(category);

    if (!category || category.value === this.deviceCategories.HUB) {
      this.deviceConnectionForm.get('identification').reset();
      this.deviceConnectionForm.markAsPristine();

      return;
    }

    this.deviceConnectionForm.get('identification').setValue(this.deviceConnectionIdentifier);
  }

  generateDeviceConnectionToken(): void {
    this.generateToken.emit();
  }

  viewQRCode(): void {
    this.openQRCodeModal = true;
  }

  copyToClipboard(token: string): void {
    navigator.clipboard.writeText(token);
    this.showCopiedTooltip = true;
  }

  closeViewQRCodeModal(isConfirmed: boolean): void {
    if (!isConfirmed) {
      this.openQRCodeModal = false;
    }
  }
}
