import { createSelector, DefaultProjectorFn, MemoizedSelector, Selector } from '@ngrx/store';
import { ApplicationState, IApplicationState, IStoreApiItem } from 'src/app/common';
import { IMaintenanceDate, IMaintenanceModeState } from './../models/maintenance-mode.model';

export const maintenanceModeState: Selector<IApplicationState, IMaintenanceModeState> = (state: IApplicationState) =>
  state.maintenance;

export const selectMaintenanceDate: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IMaintenanceDate>,
  DefaultProjectorFn<IStoreApiItem<IMaintenanceDate>>
> = createSelector(maintenanceModeState, (state: IMaintenanceModeState) => state.maintenanceDate);
