<div *ngIf="column?.field === customFilter && column?.filterType === filterType.DATE">
  <odx-form-field [variant]="'simple'" odxLayout="6@phone 12">
    <ignis-datepicker
      class="table-datepicker-filter"
      appendTo="body"
      [selectionMode]="datePickerFilterOptions.datePickerSelectionMode"
      [formatDate]="datePickerFilterOptions.datePickerFormat"
      [readOnly]="datePickerFilterOptions.readOnlyDatePicker"
      [showTime]="datePickerFilterOptions.isDatePickerShowingTime"
      [showSeconds]="datePickerFilterOptions.isDatePickerShowingSeconds"
      [defaultRange]="
        datePickerFilterOptions.isDefaultRangeShown
          ? datePickerFilterOptions.activeDate
            ? datePickerFilterOptions.activeDate[column?.field]
            : null
          : null
      "
      (dateSelect)="onDateSelect($event)"
      (dateChange)="onDateChange()"
    >
    </ignis-datepicker>
  </odx-form-field>
</div>
