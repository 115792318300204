<router-outlet></router-outlet>
<div class="container-fluid remote-monitoring-container">
  <ignis-incident-statistics [incidents]="incidents | async"></ignis-incident-statistics>
  <ignis-incidents-grid
    [isLoadingIncidents]="isLoadingIncidents | async"
    [incidents]="incidents | async"
    [formatDate]="formatDate"
    [isModalOpened]="openManualRemoveModal || openModal"
    [disableMonitorIncidentBtn]="disableMonitorIncidentBtn"
    [numberOfSelectedIncidents]="numberOfSelectedIncidents"
    [resetMerge]="resetMerge"
    (resetMergeAction)="resetMergeAction()"
    (handleIncidentsIds)="getSelectedIncidents($event)"
    (handleSelectedIncidentsData)="getSelectedIncidentsData($event)"
  ></ignis-incidents-grid>

  <p *ngIf="!existIncidents" class="no-incidents-message" data-test-id="remote_monitoring.empty_incidents">
    {{ 'REMOTE_MONITORING.STR_EMPTY_INCIDENTS' | translate }}
  </p>
  <p
    *ngIf="existIncidents && !existFilterIncidents"
    class="no-incidents-message"
    data-test-id="remote_monitoring.no_incidents"
  >
    {{ 'REMOTE_MONITORING.STR_NO_INCIDENTS' | translate }}
  </p>
</div>

<footer
  class="blue-bar"
  *ngIf="
    router.url.includes(RemoteMonitoringModuleRoutes.remoteMonitoring) ||
    router.url.includes(RemoteMonitoringModuleRoutes.filter)
  "
>
  <ignis-remote-monitoring-module-footer
    [selectedItem]="selectedItem"
    [selectedToClose]="activateCloseBtn"
    [selectedMerge]="activateMergeBtn"
    [mergeMode]="countMergeBtnClick === 1"
    [filterParams]="filterParams | async"
    [appearedNewIncidents]="appearedNewIncidents"
    [isModalOpened]="openManualRemoveModal || openModal"
    (handleFilter)="filterIncidents()"
    (handleMergeIncidents)="mergeIncidents()"
    (handleCloseIncident)="manualRemoveIncident()"
  >
  </ignis-remote-monitoring-module-footer>
  <ignis-manual-remove-remote-incident
    [openModal]="openManualRemoveModal"
    [incidentData]="selectedIncident"
    (handleCloseManualRemoveIncidentModal)="closeManualRemoveModal($event)"
  >
  </ignis-manual-remove-remote-incident>
  <ignis-merge-remote-incidents
    [openModal]="openModal"
    [selectedIncidents]="incidentsToMerge"
    [selectedIncidentsData]="selectedIncidentsData"
    [formatDate]="formatDate"
    (handleCloseMergeIncidentModal)="closeMergeModal($event)"
  ></ignis-merge-remote-incidents>
</footer>
