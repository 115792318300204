import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, of } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import {
  ApplicationState,
  CommonConstants,
  CSVService,
  ExportTableExcelService,
  IStoreApiItem,
  IStoreApiList,
  StorageConstants,
  TableHelperComponent,
} from 'src/app/common';
import { NotificationConstants, TableExportExcelConstants } from 'src/app/common/constants';
import { IErrorCodesForCSV, ITableColumn, PropertyBag } from 'src/app/common/models';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { INotificationMessage } from 'src/app/common/state/notifications/models/notification.model';
import { ExportExcelService } from 'src/app/report-center/services/export-excel.service';
import { AppModulesTypes } from 'src/app/root/models/app-types';
import { environment } from 'src/environments/environment';
import { UserNotificationConstants } from '../../constants';
import { UserManagementModuleRoutes } from '../../constants/user-management-module-routes.contants';
import {
  selectCSVFile,
  selectDeletedUser,
  selectedUser,
  selectRoleList,
  selectUsersPage,
} from '../../state/selectors/user.selector';
import { UserManagementConstants } from './../../constants/user-management.constants';
import { IUser, IUserPage, IUserRole } from './../../models/user.model';
import { UserActions } from './../../state/actions/user.actions';
import { tableColumns, userImportCSVColumns } from './table-columns';
import { readModelSortKeys, tableCustomFiltersLayout, tableDefaultSorting } from './table-settings';

@Component({
  selector: 'ignis-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersListComponent extends TableHelperComponent implements OnInit {
  @Input() formatDate: string;
  list: IUser[];
  roles: IUserRole[];
  selectedRoles: any[];
  selectedUser: IUser;

  disableEditBtn: boolean = false;
  openConfirmationDeleteDialog: boolean = false;
  jwtHelper: JwtHelperService = new JwtHelperService();
  httpCustomErrorCode: string | any;
  defaultSorting: string = tableDefaultSorting;
  tableFiltersKey: string = StorageConstants.tablesStorageKeys.USER_MANAGEMENT;
  activateClickOutside: boolean = true;

  importCSVColumns: ITableColumn[] = userImportCSVColumns;
  importCSVErrorCodes: IErrorCodesForCSV = UserManagementConstants.errorCodesForCSVFile;
  pageUrl: string = UserManagementModuleRoutes.userManagement;

  customFilterLayouts: string[] = tableCustomFiltersLayout;

  readModelSortKeys: PropertyBag = readModelSortKeys;

  localTableColumns: ITableColumn[] = tableColumns;
  tableName: string = AppModulesTypes.userManagement;
  tableParameters: any;
  tableExportList: IUser[] = [];
  excelTranslationKeys: PropertyBag = TableExportExcelConstants.userManagementSheetTranslationKeys;

  datePipe: DatePipe = inject(DatePipe);
  notificationsService: NotificationsService = inject(NotificationsService);
  exportExcelService: ExportExcelService = inject(ExportExcelService);
  userActions: UserActions = inject(UserActions);
  exportTableExcelService: ExportTableExcelService = inject(ExportTableExcelService);
  translateService: TranslateService = inject(TranslateService);
  csvService: CSVService = inject(CSVService);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  @ViewChild('fileInput', { read: ElementRef })
  public fileInput: ElementRef<any>;

  openModal: boolean = false;

  constructor(
    private router: Router,
    private store: Store<ApplicationState>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initiateLoadingSubscription();
    this.initiateRoleListSubscription();
    this.initiateUsersPageSubscription();
    this.initiateUserSubscription();

    this.selectedRoles = this.tableHelperSavedFilters?.role;
  }

  initiateUserSubscription(): void {
    this.store
      .pipe(
        select(selectedUser),
        filter((user: IStoreApiItem<IUser>) => !user.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((user: IStoreApiItem<IUser>) => {
        if (user.isSuccess) {
          this.tableHelperPrepareTableParameters();
          this.userActions.resetUserCRUDState();
        }

        this.cdr.detectChanges();
      });
  }

  initiateRoleListSubscription(): void {
    this.store
      .pipe(
        select(selectRoleList),
        map((state: IStoreApiList<IUserRole[]>) => state.data),
        takeUntil(this.destroy),
      )
      .subscribe((roles: IUserRole[]) => {
        this.roles = roles?.map((role: IUserRole) => ({
          ...role,
          label: this.translateService.instant(
            UserManagementConstants.userRoles.roles.find((t: IUserRole) => t.value === role.value)?.localizedName ||
              role.value,
          ),
        }));

        this.cdr.detectChanges();
      });
  }

  initiateUsersPageSubscription(): void {
    this.store
      .pipe(
        select(selectUsersPage),
        map((state: IStoreApiItem<IUserPage>) => state.data),
        takeUntil(this.destroy),
      )
      .subscribe((response: IUserPage) => {
        if (response) {
          this.list = structuredClone(response.entries);
          this.totalRecords = response.totalRecords;
          this.totalPages = response.totalPages;

          this.tableHelperCheckOutOfRangePageFilter();

          this.list.forEach((user: IUser) => {
            user.emailAddress = user.email;
            user.displayLastLogin = user.lastLogin ? `${this.datePipe.transform(user.lastLogin, this.formatDate)}` : '';
          });

          this.cdr.detectChanges();
        }
      });
  }

  initiateLoadingSubscription(): void {
    this.isLoading$ = combineLatest([this.store.select(selectRoleList), this.store.select(selectUsersPage)]).pipe(
      takeUntil(this.destroy),
      map(
        ([roleState, userState]: [IStoreApiList<IUserRole[]>, IStoreApiList<IUserPage>]) =>
          roleState.isLoading || userState.isLoading,
      ),
    );

    this.cdr.detectChanges();
  }

  getFilterTableValue(event: any): void {
    this.tableHelperResetTablePaging();

    if ('userRole.value' in event) {
      event.role = event['userRole.value'];
      delete event['userRole.value'];
    }

    this.tableHelperSetAllTableFilters(event);
  }

  fetchTableData(params: any): void {
    this.userActions.requestUserPage(params, this.isExportMode);
  }

  onUserSelect(user: IUser): void {
    if (user) {
      this.selectedUser = user;
      this.disableEditBtn = false;
    } else {
      this.selectedUser = null;
    }

    const token: { userid: string; oktaid: string } = this.jwtHelper.decodeToken(
      localStorage.getItem(CommonConstants.AUTH_TOKEN),
    );

    if (this.selectedUser?.userId === token?.oktaid) {
      this.disableEditBtn = true;
    }
  }

  createUser(): void {
    this.router.navigate(['/user-management/add-user']);
  }

  assignRole(user: IUser): void {
    if (this.disableEditBtn) return;

    this.router.navigate([`/user-management/update-user/${user.aggregateId}`]);
  }

  changeColumnVisibility(columns: ITableColumn[]): void {
    this.tableHelperResetTablePaging();

    columns.forEach((column: ITableColumn) => {
      if (!column.visible) {
        if (this.tableHelperCheckForSorting(column.field)) {
          this.tableHelperResetTableSorting();
        }

        this.tableHelperHideAndResetTableColumn(this.localTableColumns, column, false);
        this.tableService.resetTableSort(column.field, 'ASC', this.tableRef, this.loadDataEvent, 'surname');
      }
    });

    this.tableHelperSaveTableSettings(columns);
    this.tableHelperSetAllTableFilters(this.filtersData);
    this.selectedRoles = this.tableHelperSavedFilters?.role;

    this.tableHelperPrepareTableParameters();
    this.cdr.detectChanges();
  }

  openDeleteUserDialog(): void {
    this.openConfirmationDeleteDialog = true;
    this.activateClickOutside = false;
  }

  closeDeleteUserDialog(confirmation: boolean): void {
    if (confirmation) {
      this.deleteUser(this.selectedUser);
    } else {
      this.openConfirmationDeleteDialog = false;
      this.activateClickOutside = true;
    }
  }

  deleteUser(userToDelete: IUser): void {
    this.userActions.requestUserDelete(userToDelete);

    this.store
      .pipe(
        select(selectDeletedUser),
        filter((user: IStoreApiItem<IUser>) => !user.isLoading),
        take(1),
      )
      .subscribe((user: IStoreApiItem<IUser>) => {
        if (user.isSuccess) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            UserNotificationConstants.notificationCodes.DELETE_USER_SUCCESS,
            UserNotificationConstants.notificationCodes,
            { name: `${userToDelete.name} ${userToDelete.surname}` },
          );

          this.tableHelperPrepareTableParameters();
        } else if (user.errors) {
          this.httpCustomErrorCode = user.errors?.error.code?.toString();

          this.callUsersListErrorNotification(this.httpCustomErrorCode);
        }

        this.cdr.detectChanges();
      });
  }

  downloadTemplate(): void {
    this.csvService.downloadFile(
      `${environment.API_URLS.USERS}/users/import/csv-template`,
      'IMPORT_CSV.STR_USERS_FILE_NAME',
    );
  }

  uploadFile(event: any): void {
    this.isLoading$ = of(true);
    const formData: FormData = new FormData();

    formData.append('file', event[0], event[0].name);

    this.userActions.uploadCSVFile(formData);

    this.fileInput.nativeElement.value = '';

    this.checkUploadedUsers();
  }

  checkUploadedUsers(): void {
    this.store
      .pipe(
        select(selectCSVFile),
        filter((response: IStoreApiItem<any>) => !response.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((response: IStoreApiItem<any>) => {
        if (response.errors) {
          this.httpCustomErrorCode = response.errors?.error?.data;

          if (this.httpCustomErrorCode?.length) {
            this.openModal = true;

            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.HIDDEN,
              NotificationConstants.commonCodes.UNEXPECTED_ERROR,
              UserNotificationConstants.notificationCodes,
            );
          } else if (
            response.errors?.error.statusCode !== 404 &&
            response.errors?.error.code?.toString() !==
              UserNotificationConstants.notificationCodes.CSV_FILE_FORMAT_INCORRECT.value
          ) {
            this.callUsersListErrorNotification(UserNotificationConstants.notificationCodes.LARGE_CSV_FILE);
          }

          if (
            response.errors?.error.code?.toString() ===
            UserNotificationConstants.notificationCodes.CSV_FILE_FORMAT_INCORRECT.value
          ) {
            this.callUsersListErrorNotification(UserNotificationConstants.notificationCodes.CSV_FILE_FORMAT_INCORRECT);
          }

          this.isLoading$ = of(false);
        } else if (response.isSuccess) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            UserNotificationConstants.notificationCodes.UPLOAD_USERS_CSV_SUCCESS,
            UserNotificationConstants.notificationCodes,
          );

          this.tableHelperPrepareTableParameters();

          this.isLoading$ = of(false);
        }

        this.cdr.detectChanges();
      });
  }

  callUsersListErrorNotification(errorCode: INotificationMessage): void {
    this.notificationsService.requestShowNotification(
      CommonConstants.notificationType.ERROR,
      errorCode,
      UserNotificationConstants.notificationCodes,
    );
  }

  closeModal(event: boolean): void {
    this.openModal = event;
  }

  getUpdatedTableColumns(event): void {
    this.tableColumns = event.columns;
  }

  exportExcelFile(): void {
    this.isExportMode = true;

    this.exportExcelService.genericExport(this.getDataForExport, this.preparationForExport).then((data: unknown[]) => {
      this.excelArray = data;
      this.isExportMode = false;
      this.cdr.detectChanges();
    });
  }

  preparationForExport = (user: IUser): void => {
    user['emailAddress'] = user.email;
    user['displayLastLogin'] = this.exportTableExcelService.formatExcelDataToLocal(user.lastLogin, this.formatDate);
    const roleKey: string =
      UserManagementConstants.userRoles.roles.find((t: IUserRole) => t.value === user.userRole.value)?.localizedName ||
      user.userRole.value;

    user['userRole.value'] = this.translateService.instant(roleKey);
  };

  getDataForExport = (page: number = 0): void => {
    this.tableHelperPrepareTableParameters(page);
  };
}
