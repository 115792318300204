// eslint-disable-next-line no-shadow
export enum OrganizationSettingsActionTypes {
  REQUEST_ORGANIZATION_SETTINGS_ENTRIES = 'REQUEST_ORGANIZATION_SETTINGS_ENTRIES',
  RECEIVE_ORGANIZATION_SETTINGS_ENTRIES = 'RECEIVE_ORGANIZATION_SETTINGS_ENTRIES',
  ERROR_RECEIVE_ORGANIZATION_SETTINGS_ENTRIES = 'ERROR_RECEIVE_ORGANIZATION_SETTINGS_ENTRIES',

  REQUEST_ORGANIZATION_ACCESS_CONTROL = 'REQUEST_ORGANIZATION_ACCESS_CONTROL',
  RECEIVE_ORGANIZATION_ACCESS_CONTROL = 'RECEIVE_ORGANIZATION_ACCESS_CONTROL',
  ERROR_RECEIVE_ORGANIZATION_ACCESS_CONTROL = 'ERROR_RECEIVE_ORGANIZATION_ACCESS_CONTROL',

  REQUEST_UPDATE_ORGANIZATION_ACCESS_CONTROL = 'REQUEST_UPDATE_ORGANIZATION_ACCESS_CONTROL',
  RECEIVE_UPDATE_ORGANIZATION_ACCESS_CONTROL = 'RECEIVE_UPDATE_ORGANIZATION_ACCESS_CONTROL',
  ERROR_RECEIVE_UPDATE_ORGANIZATION_ACCESS_CONTROL = 'ERROR_RECEIVE_UPDATE_ORGANIZATION_ACCESS_CONTROL',

  REQUEST_ORGANIZATION_LICENSE_INFO = 'REQUEST_ORGANIZATION_LICENSE_INFO',
  RECEIVE_ORGANIZATION_LICENSE_INFO = 'RECEIVE_ORGANIZATION_LICENSE_INFO',
  ERROR_RECEIVE_ORGANIZATION_LICENSE_INFO = 'ERROR_RECEIVE_ORGANIZATION_LICENSE_INFO',
  RESET_ORGANIZATION_LICENSE_INFO = 'RESET_ORGANIZATION_LICENSE_INFO',
}
