import { Action } from '@ngrx/store';
import { IIncidentFilter } from 'src/app/remote-monitoring/models/remote-monitoring.model';
import { RemoteMonitoringActionTypes } from '../remote-monitoring-action-types';

export class SaveFilterParams implements Action {
  readonly type: RemoteMonitoringActionTypes.SAVE_FILTER_PARAMS = RemoteMonitoringActionTypes.SAVE_FILTER_PARAMS;

  constructor(public payload: IIncidentFilter) { }
}

export class ResetFilterParams implements Action {
  readonly type: RemoteMonitoringActionTypes.RESET_FILTER_PARAMS = RemoteMonitoringActionTypes.RESET_FILTER_PARAMS;
}
