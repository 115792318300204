// eslint-disable-next-line no-shadow
export enum EquipmentServiceTaskActionTypes {
  REQUEST_SERVICE_TASK_PAGE = 'REQUEST_SERVICE_TASK_PAGE',
  RECEIVE_SERVICE_TASK_PAGE = 'RECEIVE_SERVICE_TASK_PAGE',
  ERROR_RECEIVE_SERVICE_TASK_PAGE = 'ERROR_RECEIVE_SERVICE_TASK_PAGE',
  REQUEST_SERVICE_TASK_EXPORT = 'REQUEST_SERVICE_TASK_EXPORT',

  REQUEST_CHECKLISTS_DATA = 'REQUEST_CHECKLISTS_DATA',
  RECEIVE_CHECKLISTS_DATA = 'RECEIVE_CHECKLISTS_DATA',
  ERROR_RECEIVE_CHECKLISTS_DATA = 'ERROR_RECEIVE_CHECKLISTS_DATA',
  RESET_REQUEST_CHECKLISTS_DATA = 'RESET_REQUEST_CHECKLISTS_DATA',

  REQUEST_SEND_SERVICE_INTERVALS = 'REQUEST_SEND_SERVICE_INTERVALS',
  RECEIVE_SEND_SERVICE_INTERVALS = 'RECEIVE_SEND_SERVICE_INTERVALS',
  ERROR_RECEIVE_SEND_SERVICE_INTERVALS = 'ERROR_RECEIVE_SEND_SERVICE_INTERVALS',
  RESET_SEND_SERVICE_INTERVALS = 'RESET_SEND_SERVICE_INTERVALS',

  REQUEST_UPDATE_EQUIPMENT_LOCATION = 'REQUEST_UPDATE_EQUIPMENT_LOCATION',
  RECEIVE_UPDATE_EQUIPMENT_LOCATION = 'RECEIVE_UPDATE_EQUIPMENT_LOCATION',
  ERROR_RECEIVE_UPDATE_EQUIPMENT_LOCATION = 'ERROR_RECEIVE_UPDATE_EQUIPMENT_LOCATION',
  RESET_UPDATE_EQUIPMENT_LOCATION = 'RESET_UPDATE_EQUIPMENT_LOCATION',

  REQUEST_UPDATE_EQUIPMENT_LOCATION_DURING_BARCODE_SEARCH = 'REQUEST_UPDATE_EQUIPMENT_LOCATION_DURING_BARCODE_SEARCH',
  RECEIVE_UPDATE_EQUIPMENT_LOCATION_DURING_BARCODE_SEARCH = 'RECEIVE_UPDATE_EQUIPMENT_LOCATION_DURING_BARCODE_SEARCH',
  ERROR_RECEIVE_UPDATE_EQUIPMENT_LOCATION_DURING_BARCODE_SEARCH = 'ERROR_RECEIVE_UPDATE_EQUIPMENT_LOCATION_DURING_BARCODE_SEARCH',

  SAVE_SELECTED_SERVICE_EQUIPMENT = 'SAVE_SELECTED_SERVICE_EQUIPMENT',
  RESET_SAVE_SELECTED_SERVICE_EQUIPMENT = 'RESET_SAVE_SELECTED_SERVICE_EQUIPMENT',

  REQUEST_SAVE_SERVICE_DEFINITION_CHECKLIST = 'REQUEST_SAVE_SERVICE_DEFINITION_CHECKLIST',
  RECEIVE_SAVE_SERVICE_DEFINITION_CHECKLIST = 'RECEIVE_SAVE_SERVICE_DEFINITION_CHECKLIST',
  ERROR_RECEIVE_SAVE_SERVICE_DEFINITION_CHECKLIST = 'ERROR_RECEIVE_SAVE_SERVICE_DEFINITION_CHECKLIST',
  RESET_SAVE_SERVICE_DEFINITION_CHECKLIST = 'RESET_SAVE_SERVICE_DEFINITION_CHECKLIST',

  REQUEST_SAVE_BATCH_CHECKLIST = 'REQUEST_SAVE_BATCH_CHECKLIST',
  RECEIVE_SAVE_BATCH_CHECKLIST = 'RECEIVE_SAVE_BATCH_CHECKLIST',
  ERROR_RECEIVE_SAVE_BATCH_CHECKLIST = 'ERROR_RECEIVE_SAVE_BATCH_CHECKLIST',
  RESET_SAVE_BATCH_CHECKLIST = 'RESET_SAVE_BATCH_CHECKLIST',
}
