<div class="blue-bar-items">
  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="create-event-marker"
    *ngIf="
      activeTab === incidentTabs.INCIDENT_INFORMATION ||
      activeTab === incidentTabs.INCIDENT_ANALYSIS ||
      activeEdit ||
      router.url?.includes(ReportCenterModuleRoutes.eventMarker)
    "
    [disabled]="
      activeTab !== incidentTabs.INCIDENT_ANALYSIS ||
      activeEdit ||
      router.url?.includes(ReportCenterModuleRoutes.eventMarker) ||
      isModalOpened ||
      dirtyForm ||
      router.url?.includes(ReportCenterModuleRoutes.uploadMediaFile)
    "
    (click)="onCreateClick($event); showAddButtonTooltip = false"
    id="bluebar-create"
    data-test-id="incident_information_footer.add_event_marker_btn"
    [pTooltip]="activeTab !== incidentTabs.INCIDENT_ANALYSIS ? null : addButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #addButtonTooltipContent>
      @if (dirtyForm && activeTab === incidentTabs.INCIDENT_ANALYSIS) {
        <div data-test-id="incident_information_footer.add_event_marker_unsaved_tooltip">
          {{ 'INCIDENT_INFORMATION.UNSAVED_CHANGES_TOOLTIP_FOR_CREATE_BTN' | translate }}
        </div>
      } @else if (!dirtyForm && activeTab === incidentTabs.INCIDENT_ANALYSIS) {
        <div data-test-id="incident_information_footer.add_event_marker_tooltip">
          {{ 'INCIDENT_INFORMATION.STR_ADD_MANUAL_EVENT' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon size="inline" name="add"></odx-icon>
  </button>

  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="upload-media-file"
    *ngIf="activeTab === incidentTabs.INCIDENT_MEDIA_ASSETS"
    [disabled]="
      activeTab !== incidentTabs.INCIDENT_MEDIA_ASSETS ||
      activeEdit ||
      isModalOpened ||
      dirtyForm ||
      router.url?.includes(ReportCenterModuleRoutes.uploadMediaFile)
    "
    (click)="onUploadFileClick($event); showAddButtonTooltip = false"
    data-test-id="incident_information_footer.add_media_file_btn"
    [pTooltip]="!uploadFileButtonTooltipContent ? null : uploadFileButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #uploadFileButtonTooltipContent>
      @if (!dirtyForm) {
        <div data-test-id="incident_information_footer.add_media_file_tooltip">
          {{ 'INCIDENT_INFORMATION.STR_UPLOAD_MEDIA_FILES' | translate }}
        </div>
      } @else {
        <div data-test-id="incident_information_footer.add_media_file_unsaved_tooltip">
          {{ 'INCIDENT_INFORMATION.UNSAVED_CHANGES_TOOLTIP_FOR_UPLOAD_MEDIA_BTN' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon size="inline" name="add"></odx-icon>
  </button>

  <button
    [disabled]="
      (!activeEdit && !router.url?.includes(ReportCenterModuleRoutes.eventMarker)) ||
      isModalOpened ||
      dirtyForm ||
      router.url?.includes(ReportCenterModuleRoutes.eventMarker)
    "
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="edit-report-center-incident"
    (click)="onEditClick($event); showEditButtonTooltip = false"
    id="bluebar-edit"
    data-test-id="incident_information_footer.edit_btn"
    [pTooltip]="activeTab !== incidentTabs.INCIDENT_ANALYSIS ? null : editButtonTooltipContent"
    *ngIf="activeTab !== incidentTabs.INCIDENT_MEDIA_ASSETS"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #editButtonTooltipContent>
      @if (dirtyForm && activeEdit && activeTab === incidentTabs.INCIDENT_ANALYSIS) {
        <div data-test-id="incident_information_footer.edit_unsaved_tooltip">
          {{ 'INCIDENT_INFORMATION.UNSAVED_CHANGES_TOOLTIP_FOR_EDIT_BTN' | translate }}
        </div>
      } @else {
        <div data-test-id="incident_information_footer.edit_event_marker">
          {{ 'INCIDENT_INFORMATION.TOOLTIP_FOR_EDIT_BTN' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon size="inline" name="edit"></odx-icon>
  </button>
  <button
    type="button"
    [disabled]="
      (!activeEdit && !router.url?.includes(ReportCenterModuleRoutes.eventMarker)) ||
      isModalOpened ||
      dirtyForm ||
      router.url?.includes(ReportCenterModuleRoutes.eventMarker)
    "
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="save-report-center-incident"
    (click)="onDeleteClick($event); showDeleteButtonTooltip = false"
    id="bluebar-delete"
    *ngIf="activeTab !== incidentTabs.INCIDENT_MEDIA_ASSETS"
    data-test-id="incident_information_footer.delete_btn"
    [pTooltip]="activeTab !== incidentTabs.INCIDENT_ANALYSIS ? null : deleteButtonTooltipContent"
    tooltipPosition="top"
    positionTop="-10"
  >
    <ng-template #deleteButtonTooltipContent>
      @if (dirtyForm && activeEdit && activeTab === incidentTabs.INCIDENT_ANALYSIS) {
        <div data-test-id="incident_information_footer.delete_unsaved_tooltip">
          {{ 'INCIDENT_INFORMATION.UNSAVED_CHANGES_TOOLTIP_FOR_DELETE_BTN' | translate }}
        </div>
      } @else {
        <div data-test-id="incident_information_footer.delete_event_marker">
          {{ 'INCIDENT_INFORMATION.TOOLTIP_FOR_DELETE_BTN' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon size="inline" name="delete"></odx-icon>
  </button>
  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    [disabled]="
      !dirtyForm || isModalOpened || router.url?.includes(ReportCenterModuleRoutes.eventMarker) || isSubmitDisabled
    "
    (click)="onSubmit($event)"
    id="bluebar-save"
    *ngIf="activeTab !== incidentTabs.INCIDENT_MEDIA_ASSETS"
    data-test-id="incident_information_footer.save_btn"
  >
    <odx-icon size="inline" name="save"></odx-icon>
  </button>

  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="download-report-center-incident-excel"
    [disabled]="
      activeEdit ||
      router.url?.includes(ReportCenterModuleRoutes.eventMarker) ||
      activeTab === incidentTabs.INCIDENT_MEDIA_ASSETS ||
      isModalOpened ||
      dirtyForm ||
      router.url?.includes(ReportCenterModuleRoutes.uploadMediaFile)
    "
    (click)="onIncidentExportClick($event)"
    *ngIf="activeTab !== incidentTabs.INCIDENT_MEDIA_ASSETS"
    data-test-id="incident_information_footer.download_incident_file_btn"
    [pTooltip]="exportExcelButtonTooltipContent"
    tooltipPosition="top"
    positionTop="-10"
  >
    <ng-template #exportExcelButtonTooltipContent>
      @if (!dirtyForm) {
        <div data-test-id="incident_information_footer.download_incident_file_tooltip">
          {{ 'VIEW_UPDATE_INCIDENT.STR_DOWNLOAD_INCIDENT_REPORT_TOOLTIP' | translate }}
        </div>
      } @else {
        <div data-test-id="incident_information_footer.download_incident_file_unsaved_tooltip">
          {{ 'INCIDENT_INFORMATION.UNSAVED_CHANGES_TOOLTIP_FOR_DOWNLOAD_INCIDENT_BTN' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon size="inline" name="download"></odx-icon>
  </button>
</div>
