import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { SettingsActions } from 'src/app/settings';
import { CommonConstants } from '../../constants/common.constants';
import { ITableColumn, ITableSettings } from '../../models/table.model';

@Injectable({
  providedIn: 'root',
})
export class TableService {
  tableColumns: ITableColumn[];

  constructor(@Inject(DOCUMENT) private document: Document) {}

  public lastTableHeaderElem(tableColumns: ITableColumn[]): string {
    const visibleColumns: ITableColumn[] = tableColumns.filter((field: ITableColumn) => field.visible);

    return visibleColumns[visibleColumns.length - 1]?.field;
  }

  public reorderColumns(event: any, tableColumns: ITableColumn[]): ITableColumn[] | any {
    const columns: ITableColumn[] = event.columns;

    tableColumns?.forEach((col: ITableColumn, index: number) => {
      if (columns?.indexOf(col) === -1) {
        columns.splice(index, 0, { ...col, visible: false });
      } else {
        return;
      }
    });

    return columns;
  }

  public toggleColumn(column: ITableColumn, tableColumns: ITableColumn[]): void {
    const index: number = tableColumns.findIndex((col: ITableColumn) => col === column);

    if (tableColumns[index]) {
      tableColumns[index].visible = !tableColumns[index].visible;
    }

    this.tableColumns = structuredClone(tableColumns);
  }

  public resetPropertyFromTableState(stateName: string, property: string): void {
    const tableState: any = JSON.parse(localStorage.getItem(stateName));

    if (tableState) {
      tableState[property] = null;
      setTimeout(() => {
        localStorage.setItem(stateName, JSON.stringify(tableState));
      });
    }
  }

  public saveFilters(field: string, value: any, filterName: string, filters: any): void {
    const savedFilters: any = { ...filters };

    savedFilters[field] = value;
    localStorage.setItem(filterName, JSON.stringify(savedFilters));
  }

  public saveAllFilters(object: any, filterName: string, filters: any): void {
    const savedFilters: any = { ...filters, ...object };

    localStorage.setItem(filterName, JSON.stringify(savedFilters));
  }

  public processFilterValues(filters: unknown): any {
    const filtersValue: any = filters;

    for (const property in filtersValue) {
      /* istanbul ignore else */
      if (filtersValue[property] === null || filtersValue[property] === undefined || filtersValue[property] === '') {
        delete filtersValue[property];
      } else if (Array.isArray(filtersValue[property]) && filtersValue[property].length > 0) {
        filtersValue[property] = filtersValue[property]
          // eslint-disable-next-line no-prototype-builtins
          .map((elem: any) => (elem?.hasOwnProperty('value') ? elem?.value : elem))
          .toString();
      }
    }

    return filtersValue;
  }

  /* istanbul ignore next */
  public arrangePaginatingOfElement(table: any): void {
    setTimeout(() => {
      if (table) {
        const tablePages: Element = document.getElementsByClassName('p-paginator-pages')[0];
        const tableAllPagesNumber: Element = document.getElementsByClassName('p-paginator-right-content')[0];

        tablePages?.append(tableAllPagesNumber);
      }
    });
  }

  public tableDateParameter(date: string, fieldName: string): any {
    return date?.length > 0 ? { [fieldName]: date } : null;
  }

  public tableOnlyDateParameter(date: string, fieldName: string): any {
    if (!date) {
      return;
    }

    const localDate: Date = toZonedTime(new Date(date), Intl.DateTimeFormat().resolvedOptions().timeZone);
    const formattedDate: string = format(localDate, CommonConstants.DATE_FORMAT);

    return date?.length > 0 ? { [fieldName]: formattedDate } : null;
  }

  public resetTableSort(column: string, sortOrder: string, tableRef: any, loadDataEvent: any, sortField: string): void {
    if (column === tableRef.sortField) {
      loadDataEvent.sortOrder = sortOrder === 'ASC' ? 1 : -1;
      loadDataEvent.sortField = sortField;
      tableRef.sortField = '';
    }
  }

  public saveColumnsSettings(
    columns: ITableColumn[],
    settingsActions: SettingsActions,
    tableName: string,
    pageSize: number = undefined,
    currentPage?: number,
  ): void {
    const columnsToSend: Partial<ITableColumn>[] = columns.map((data: Partial<ITableColumn> | any) => {
      return { column: data.field, visible: data.visible };
    });

    settingsActions.requestUpdateTableColumn({
      updateSettings: { columns: columnsToSend, pageSize, currentPage },
      tableName,
    });
  }

  public preventToDropColumn(): boolean {
    const tableReorderIndicatorUpClassName: string = 'p-datatable-reorder-indicator-up';
    const tableReorderIndicatorDownClassName: string = 'p-datatable-reorder-indicator-down';

    (document.getElementsByClassName(tableReorderIndicatorUpClassName)[0] as HTMLElement).style.display = 'none';
    (document.getElementsByClassName(tableReorderIndicatorDownClassName)[0] as HTMLElement).style.display = 'none';

    return false;
  }

  public getInputFilterValueLength(field: string): number {
    return (this.document.getElementById(field) as HTMLInputElement)?.value.length;
  }

  public clearTextFilter(inputId: string): void {
    (this.document.getElementById(inputId) as HTMLInputElement).value = null;
  }

  public determineSortingOrder(sortOrder: number): string {
    return sortOrder === 1 ? 'ASC' : 'DESC';
  }

  public orderTableColumns(tableSettings: ITableSettings, localColumns: ITableColumn[]): ITableColumn[] {
    const newTableColumns: { column: ITableColumn; position: number }[] = [];

    if (this.checkTableColumns(tableSettings, localColumns)) {
      localColumns.forEach((localColumn: ITableColumn, index: number) => {
        const checkIfColumnIsSavedInBE: boolean = tableSettings.columns.some(
          (savedColumn: ITableColumn) => savedColumn.column === localColumn.field,
        );

        if (!checkIfColumnIsSavedInBE) {
          newTableColumns.push({ column: localColumn, position: index });
        }
      });

      const tableColumns: ITableColumn[] = tableSettings.columns.map<ITableColumn>((column: ITableColumn) => {
        const foundColumn: Partial<ITableColumn> = localColumns.find(
          (localColumn: Partial<ITableColumn>) => localColumn.field === column.column,
        );

        return { ...foundColumn, ...column };
      });

      newTableColumns.forEach((newColumn: { column: ITableColumn; position: number }) => {
        tableColumns.splice(newColumn.position, 0, newColumn.column);
      });

      return tableColumns;
    }

    return localColumns;
  }

  private checkTableColumns(storedTableSettings: ITableSettings, localTableColumns: ITableColumn[]): boolean {
    if (!storedTableSettings || (!('columns' in storedTableSettings) && !('pageSize' in storedTableSettings))) {
      return false;
    }

    if (localTableColumns.length !== storedTableSettings.columns?.length) {
      return false;
    }

    return true;
  }
}
