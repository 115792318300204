<div
  class="row odx-form-field--horizontal odx-form-field"
  [ngClass]="{ 'odx-form-field--required': item.required }"
  [formGroup]="form"
>
  <div class="col-6 custom-form-field-pr-10">
    <label class="odx-form-field__label" [for]="item.id">
      <odx-form-field-label class="odx-form-field-label">
        {{ item.name }}
      </odx-form-field-label>
      @if (item.required) {
        <span class="odx-form-field__label-is-required ng-star-inserted">*</span>
      }
    </label>
  </div>
  <div class="col-6 custom-form-field-pl-10">
    <div class="odx-form-field__inner">
      <div class="odx-form-field__control">
        <div class="odx-form-field__prefix"></div>
        <textarea
          ignisDisableAutocomplete
          [id]="item.id"
          data-test-id="settings.organization"
          [formControlName]="item.id"
          class="custom-form-textarea odx-form-field-control w-100"
          rows="3"
        ></textarea>
        <div class="odx-form-field__suffix"></div>
      </div>
    </div>
  </div>
</div>
