import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IConfigurationState } from '../../models/configuration.models';
import { RequestLocationTypeValues } from './location-type-action-types-creators';

@Injectable({ providedIn: 'root' })
export class LocationTypeActions {
  constructor(public store: Store<IConfigurationState>) {}

  public requestLocationTypeValues(): void {
    this.store.dispatch(new RequestLocationTypeValues());
  }
}
