import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  inject,
} from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { addDays, addMonths, addWeeks, addYears, isBefore, isValid, parse, startOfToday } from 'date-fns';
import { CommonConstants, NotificationsService } from 'src/app/common';
import { dateFormat, extractOnlyDate } from 'src/app/common/utils';
import { IInterval, ITask } from 'src/app/configuration/models';
import { EquipmentNotificationConstants, WorkshopConstants } from 'src/app/workshop/constants';

@Component({
  selector: 'ignis-eq-tasks',
  templateUrl: './eq-tasks.component.html',
  styleUrls: ['./eq-tasks.component.scss'],
})
export class EqTasksComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() equipmentForm: FormGroup;
  @Input() format: string;
  @Input() formatDate: string;
  @Input() disableAllFields: boolean;

  @Output() emitSelectedTask: EventEmitter<ITask> = new EventEmitter<ITask>();

  maxDate: Date = new Date();
  extractOnlyDate: typeof extractOnlyDate = extractOnlyDate;
  toggleNextServiceDatePicker: boolean = true;
  selectedTask: ITask;

  @ViewChildren('taskNameElement') taskNameElement!: QueryList<ElementRef>;
  isTaskNameEllipsisActive: boolean[] = [];

  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  notificationsService: NotificationsService = inject(NotificationsService);

  get serviceControls(): FormArray {
    return this.equipmentForm.get('serviceIntervals') as FormArray;
  }

  get typeControls(): AbstractControl {
    return this.equipmentForm.get('generalData.model.type');
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkEllipsis();
  }

  ngOnChanges(): void {
    if (this.disableAllFields) {
      this.serviceControls.disable();
    }
  }

  ngOnInit(): void {
    if (!this.equipmentForm.dirty) {
      const serviceControls: ITask[] = this.serviceControls.value as ITask[];

      serviceControls?.forEach((interval: ITask, index: number) => {
        this.onLastTaskDateChange(interval.lastTestDate, index, interval.interval, interval.nextTestDate);
        this.onNextTaskDateChange(interval.nextTestDate, index);
      });

      this.serviceControls.markAsUntouched();
      this.serviceControls.markAsPristine();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.checkEllipsis();
    }, 0);
  }

  checkEllipsis(): void {
    this.taskNameElement.forEach((element: ElementRef, index: number): void => {
      const el: HTMLElement = element.nativeElement as HTMLElement;

      this.isTaskNameEllipsisActive[index] = el.scrollWidth > el.clientWidth;
    });
  }

  onLastTaskDateChange(
    lastTest: Date | string,
    index: number,
    interval: IInterval,
    nextTest: Date | string = null,
  ): void {
    const newIntervals: ITask[] = structuredClone(this.serviceControls.value) as ITask[];

    if (lastTest instanceof Date || !lastTest) {
      if (lastTest && interval) {
        const pattern: string = CommonConstants.dateFNSIntervalPattern[interval?.pattern];

        switch (pattern) {
          case CommonConstants.dateFNSIntervalPattern.DAY:
            nextTest = addDays(new Date(lastTest), interval.duration);
            break;
          case CommonConstants.dateFNSIntervalPattern.WEEK:
            nextTest = addWeeks(new Date(lastTest), interval.duration);
            break;
          case CommonConstants.dateFNSIntervalPattern.MONTH:
            nextTest = addMonths(new Date(lastTest), interval.duration);
            break;
          case CommonConstants.dateFNSIntervalPattern.YEAR:
            nextTest = addYears(new Date(lastTest), interval.duration);
            break;
          default:
            return null;
        }
      }

      newIntervals[index].nextTestDate = nextTest ? this.extractOnlyDate(nextTest) : null;
      newIntervals[index].lastTestDate = lastTest ? this.extractOnlyDate(lastTest) : null;

      this.serviceControls.setValue(newIntervals);

      if (lastTest) {
        this.serviceControls.markAsTouched();
        this.serviceControls.markAsDirty();
      }
    }
  }

  resetLastTaskDate(lastTest: Date, index: number, interval: IInterval): void {
    this.onLastTaskDateChange(lastTest, index, interval);

    this.serviceControls.markAsTouched();
    this.serviceControls.markAsDirty();

    this.toggleNextServiceDatePicker = false;

    setTimeout(() => {
      this.toggleNextServiceDatePicker = true;
    }, 0);

    this.cdr.detectChanges();
  }

  lastIntervalErrorHandler(event: boolean, index: number, interval: IInterval): void {
    if (event) {
      this.onLastTaskDateChange(this.maxDate, index, interval);
    }
  }

  onNextTaskDateChange(date: Date | string, index: number): void {
    const intervals: ITask[] = structuredClone(this.serviceControls.value) as ITask[];

    this.serviceControls.markAsTouched();
    this.serviceControls.markAsDirty();

    intervals[index].nextTestDate = date ? this.extractOnlyDate(date) : null;

    this.checkIfDateIsOlder(date);

    this.serviceControls.setValue(intervals);
  }

  checkIfDateIsOlder(date: Date | string): boolean {
    if (!date || !isValid(new Date(date))) return false;

    return isBefore(
      parse(dateFormat(new Date(date).toString(), this.formatDate)?.split(', ')[0], this.formatDate, new Date()),
      startOfToday(),
    );
  }

  displayWarningNotification(): void {
    this.notificationsService.requestShowNotification(
      CommonConstants.notificationType.WARNING,
      EquipmentNotificationConstants.notificationCodes.EQUIPMENT_NEXT_TASK_DATE_CHANGE,
      EquipmentNotificationConstants.notificationCodes,
    );
  }

  selectTask(task: ITask): void {
    this.selectedTask = task;
    this.emitSelectedTask.emit(this.selectedTask);
    localStorage.setItem(WorkshopConstants.taksId, this.selectedTask.serviceId);
  }

  ngOnDestroy(): void {
    this.selectedTask = null;
    this.emitSelectedTask.emit(this.selectedTask);
  }
}
