<ignis-progress-bar *ngIf="isLoading$ | async"></ignis-progress-bar>

<ignis-table
  #ignisTableComponent
  class="large-height-table user-list-table"
  [tableName]="tableName"
  [localColumns]="localTableColumns"
  [dataTable]="list"
  [resizableColumns]="true"
  [reorderableColumns]="true"
  [displayFilters]="true"
  [activateClickOutside]="activateClickOutside"
  [customFilterLayout]="customFilterLayouts"
  [multiSelectOptions]="roles"
  [customColumnsLayout]="['userRole.value']"
  [customColumnsRef]="[userRole]"
  [lazy]="'true'"
  [isLoading]="isLoading$ | async"
  [sortColumn]="sortColumn"
  [paginator]="'true'"
  [pageNumber]="pageNumber"
  [totalRecords]="totalRecords"
  [selectActiveOptions]="{ 'userRole.value': selectedRoles }"
  [savedFiltersName]="tableFiltersKey"
  [routeUrl]="'update-user'"
  (columns)="tableHelperGetTableColumns($event); getUpdatedTableColumns($event)"
  (handleOnSortChange)="tableHelperOnChangeSort($event)"
  (handleTableRef)="tableHelperGetTableRef($event)"
  (handleRowSelection)="onUserSelect($event)"
  (handleDoubleClickRowSelection)="assignRole($event)"
  (handleColumnVisibility)="changeColumnVisibility($event)"
  (handleFilterValue)="getFilterTableValue($event)"
  (handleOnLazyLoading)="tableHelperOnLazyLoading($event)"
  (handleOnPageChange)="tableHelperOnPageChange($event)"
>
  <ng-template #userRole let-userRole>
    <ignis-user-management-role-translation [entry]="userRole"></ignis-user-management-role-translation>
  </ng-template>
</ignis-table>

<input type="file" accept=".csv" #fileInput class="upload-csv-input" (change)="uploadFile($event.target.files)" />

<ignis-import-csv-error-modal
  [openModal]="openModal"
  [invalidDataList]="httpCustomErrorCode"
  [tableColumns]="importCSVColumns"
  [errorsConstant]="importCSVErrorCodes"
  [pageUrl]="pageUrl"
  (handleCloseModal)="closeModal($event)"
>
  <div slot="header">
    <odx-icon size="xlarge" name="error"></odx-icon>
    {{ 'USER_MANAGEMENT.STR_UPLOAD_CSV_ERROR_MSG' | translate }}
  </div>
</ignis-import-csv-error-modal>

<ignis-export-table-excel
  [excelArray]="excelArray"
  [formatDate]="formatDate"
  [sheetName]="'EXCEL_TABLE_EXPORT.USERS' | translate"
  [fileName]="'EXCEL_TABLE_EXPORT.USERS' | translate"
  [tableColumns]="tableColumns"
  [excelTranslationKeys]="excelTranslationKeys"
  (handleExcelExportFinished)="tableHelperExcelExportDone()"
></ignis-export-table-excel>

<footer class="blue-bar">
  <ignis-user-management-module-footer
    [selectedItem]="selectedUser"
    [selfSelected]="disableEditBtn"
    [numberOfRecordsToBeExported]="totalRecords"
    [isModalOpened]="openConfirmationDeleteDialog"
    (handleCreate)="createUser()"
    (handleEdit)="assignRole(selectedUser)"
    (handleDelete)="openDeleteUserDialog()"
    (handleDownloadTemplate)="downloadTemplate()"
    (handleUploadCSVFile)="fileInput.click()"
    (handleExcelExport)="exportExcelFile()"
  >
  </ignis-user-management-module-footer>
</footer>

<ignis-delete-confirmation-modal
  [isOpen]="openConfirmationDeleteDialog"
  [isLoading]="isLoading$ | async"
  (handleDeleteModal)="closeDeleteUserDialog($event)"
>
  <div slot="header">
    {{ 'USER_MANAGEMENT.STR_DELETE_USER' | translate }}
  </div>
  <div slot="content">
    {{ 'USER_MANAGEMENT.STR_DELETE_USER_MSG' | translate }}
  </div>
</ignis-delete-confirmation-modal>
