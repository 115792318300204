<span [ngSwitch]="statusesName[0]">
  <odx-icon *ngSwitchDefault name="error"></odx-icon>
  <odx-icon
    *ngSwitchCase="'error'"
    name="error"
    [pTooltip]="composeTooltipTxt(statusesName[0])"
    tooltipPosition="top"
    positionTop="-10"
    data-test-id="ba_logbook.error_pressure"
  >
  </odx-icon>
  <odx-icon
    *ngSwitchCase="'warning'"
    name="warning"
    [pTooltip]="composeTooltipTxt(statusesName[0])"
    tooltipPosition="top"
    positionTop="-10"
    data-test-id="ba_logbook.warning_pressure"
  ></odx-icon>
  <odx-icon
    *ngSwitchCase="'ok'"
    name="feedback-positive"
    [pTooltip]="composeTooltipTxt(statusesName[0])"
    tooltipPosition="top"
    positionTop="-10"
    data-test-id="ba_logbook.ok"
  ></odx-icon>
</span>

<span [ngSwitch]="statusesName[1]">
  <odx-icon
    *ngSwitchCase="'special_exposure'"
    name="toxic"
    [pTooltip]="composeSpecialExposureTooltipTxt()"
    tooltipPosition="top"
    positionTop="-10"
    data-test-id="ba_logbook.special_exposure"
  >
  </odx-icon>
</span>
