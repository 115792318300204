import { Component, Input } from '@angular/core';

@Component({
  selector: 'ignis-warning-workflow-table-items',
  templateUrl: './warning-workflow-table-items.component.html',
  styleUrls: ['./warning-workflow-table-items.component.scss'],
})
export class WarningWorkflowTableItemsComponent {
  @Input() tableLimit: number;
}
