import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { ApplicationState } from 'src/app/common/state/models/app.state.model';
import { getDateFormatFromUserProfile, getPressureUnit } from '../../../common/utils/settings-utils/settings-utils';
import { IEntriesModel } from '../../models/incident.model';
import { IncidentActions } from '../../state/actions/incident.actions';
import { selectEntries } from '../../state/selectors/incident.selector';

@Component({
  selector: 'ignis-report-center',
  templateUrl: './report-center.component.html',
})
export class ReportCenterComponent extends OnDestroyMixin() implements OnInit {
  formatDate: string;
  pressureDisplayUnit: Observable<string>;
  entries: Observable<IEntriesModel>;

  constructor(
    private incidentActions: IncidentActions,
    private store: Store<ApplicationState>,
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getEntries();

    this.entries = this.store.pipe(
      select(selectEntries),
      map((state: IStoreApiItem<IEntriesModel>) => state.data),
      takeUntil(this.destroy)
    );

    this.formatDate = getDateFormatFromUserProfile(this.translateService);
    this.pressureDisplayUnit = getPressureUnit(this.store, this.destroy);
  }

  getEntries(): void {
    this.incidentActions.requestIncidentEntries();
  }
}
