<div
  class="row odx-form-field--horizontal odx-form-field"
  [ngClass]="{ 'odx-form-field--required': item.required }"
  [formGroup]="form"
>
  <div class="col-6">
    <label class="odx-form-field__label" [for]="item.id">
      <odx-form-field-label class="odx-form-field-label"> {{ item.name }} </odx-form-field-label>
      @if (item.required) {
        <span class="odx-form-field__label-is-required ng-star-inserted">*</span>
      }
    </label>
  </div>
  <div class="col-lg-6">
    <odx-switch [id]="item.id" [formControlName]="item.id" [attr.data-test-id]="item.id"></odx-switch>
  </div>
</div>
