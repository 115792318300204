import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IEquipmentHistoryFilters } from 'src/app/workshop/models';
import { IEquipmentState } from '../../models';

import {
  RequestEquipmentHistory,
  ResetEquipmentHistoryState,
  ResetHistoryFilterParams,
  SaveHistoryFilterParams,
} from './equipment-history-action-types-creators';

@Injectable({ providedIn: 'root' })
export class EquipmentHistoryActions {
  constructor(private store: Store<IEquipmentState>) {}

  public requestEquipmentHistory(aggregateId: string): void {
    this.store.dispatch(new RequestEquipmentHistory(aggregateId));
  }

  public resetEquipmentHistoryState(): void {
    this.store.dispatch(new ResetEquipmentHistoryState());
  }

  public saveHistoryFilters(filters: IEquipmentHistoryFilters): void {
    this.store.dispatch(new SaveHistoryFilterParams(filters));
  }

  public resetHistoryFilters(): void {
    this.store.dispatch(new ResetHistoryFilterParams());
  }
}
