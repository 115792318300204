import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard } from '@okta/okta-angular';
import { AddressBookListComponent } from '../components/address-book/address-book-list.component';
import { CreateUpdateAddressBookComponent } from '../components/address-book/create-update-address-book/create-update-address-book.component';
import { CreateUpdateChecklistComponent } from '../components/equipment-hierarchy/create-update-configuration/create-update-checklist/create-update-checklist.component';
import { CreateUpdateConfigurationComponent } from '../components/equipment-hierarchy/create-update-configuration/create-update-configuration.component';
import { CreateUpdateTaskComponent } from '../components/equipment-hierarchy/create-update-configuration/create-update-task/create-update-task.component';
import { LocationHierarchyComponent } from '../components/location-hierarchy/location-hierarchy.component';
import { OrganizationSettingsComponent } from '../components/organization-settings/organization-settings.component';
import { ConfigurationModuleRoutes } from '../constants/configuration-module-routes.constants';
import { ConfigurationOverviewComponent } from './../components/configuration-overview/configuration-overview.component';
import { EquipmentHierarchyComponent } from './../components/equipment-hierarchy/equipment-hierarchy.component';
import { CreateUpdateLocationHierarchyComponent } from './../components/location-hierarchy/create-update-location-hierarchy/create-update-location-hierarchy.component';
import {
  DeactivateCreateUpdateAddressBookGuard,
  DeactivateCreateUpdateChecklistGuard,
  DeactivateCreateUpdateConfigurationComponentGuard,
  DeactivateCreateUpdateLocationHierarchyGuard,
  DeactivateCreateUpdateServiceGuard,
  DeactivateOrganizationSettingsGuard,
} from './can-deactivate.guard';
import { LocationEquipmentAddressBookTabGuard } from './location_equipment-address_book-tab.guard';
import { OrganizationSettingsTabGuard } from './organization_settings-tab.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [OktaAuthGuard],
    canActivateChild: [OktaAuthGuard],
    component: ConfigurationOverviewComponent,
    children: [
      {
        path: '',
        component: EquipmentHierarchyComponent,
        canActivate: [LocationEquipmentAddressBookTabGuard],
      },
      ...[
        ConfigurationModuleRoutes.createEquipmentHierarchy,
        `${ConfigurationModuleRoutes.createEquipmentHierarchy}/:categoryId/${ConfigurationModuleRoutes.createOrUpdateEquipmentTypes}`,
        `${ConfigurationModuleRoutes.createEquipmentHierarchy}/:categoryId/${ConfigurationModuleRoutes.createOrUpdateEquipmentTypes}/:typeId/${ConfigurationModuleRoutes.createOrUpdateEquipmentModels}`,
      ].map((path: string) => ({
        path,
        component: CreateUpdateConfigurationComponent,
        canDeactivate: [DeactivateCreateUpdateConfigurationComponentGuard],
      })),
      ...[
        `${ConfigurationModuleRoutes.updateEquipmentHierarchy}/${ConfigurationModuleRoutes.updateEquipmentCategories}/:categoryId`,
        `${ConfigurationModuleRoutes.updateEquipmentHierarchy}/${ConfigurationModuleRoutes.updateEquipmentCategories}/:categoryId/${ConfigurationModuleRoutes.createOrUpdateEquipmentTypes}/:typeId`,
        `${ConfigurationModuleRoutes.updateEquipmentHierarchy}/${ConfigurationModuleRoutes.updateEquipmentCategories}/:categoryId/${ConfigurationModuleRoutes.createOrUpdateEquipmentTypes}/:typeId/${ConfigurationModuleRoutes.createOrUpdateEquipmentModels}/:modelId`,
      ].map((path: string) => ({
        path,
        component: CreateUpdateConfigurationComponent,
        canDeactivate: [DeactivateCreateUpdateConfigurationComponentGuard],
        children: [
          {
            path: ConfigurationModuleRoutes.createTask,
            component: CreateUpdateTaskComponent,
            canDeactivate: [DeactivateCreateUpdateServiceGuard],
          },
          {
            path: `${ConfigurationModuleRoutes.updateTask}/:serviceId`,
            component: CreateUpdateTaskComponent,
            canDeactivate: [DeactivateCreateUpdateServiceGuard],
          },
          {
            path: `${ConfigurationModuleRoutes.createChecklist}/:serviceId`,
            component: CreateUpdateChecklistComponent,
            canDeactivate: [DeactivateCreateUpdateChecklistGuard],
          },
          {
            path: `${ConfigurationModuleRoutes.updateChecklist}/:serviceId`,
            component: CreateUpdateChecklistComponent,
            canDeactivate: [DeactivateCreateUpdateChecklistGuard],
          },
        ],
      })),
      {
        path: ConfigurationModuleRoutes.locationHierarchy,
        component: LocationHierarchyComponent,
        canActivate: [LocationEquipmentAddressBookTabGuard, OktaAuthGuard],
        children: [
          {
            path: `${ConfigurationModuleRoutes.createLocationHierarchy}/:id`,
            component: CreateUpdateLocationHierarchyComponent,
            canDeactivate: [DeactivateCreateUpdateLocationHierarchyGuard],
          },
          {
            path: `${ConfigurationModuleRoutes.updateLocationHierarchy}/:id`,
            component: CreateUpdateLocationHierarchyComponent,
            canDeactivate: [DeactivateCreateUpdateLocationHierarchyGuard],
          },
        ],
      },
      {
        path: ConfigurationModuleRoutes.addressBook,
        component: AddressBookListComponent,
        canActivate: [LocationEquipmentAddressBookTabGuard],
      },
      {
        path: ConfigurationModuleRoutes.createAddressBook,
        component: CreateUpdateAddressBookComponent,
        canDeactivate: [DeactivateCreateUpdateAddressBookGuard],
      },
      {
        path: `${ConfigurationModuleRoutes.updateAddressBook}/:id`,
        component: CreateUpdateAddressBookComponent,
        canDeactivate: [DeactivateCreateUpdateAddressBookGuard],
      },
      {
        path: ConfigurationModuleRoutes.organizationSettings,
        component: OrganizationSettingsComponent,
        canActivate: [OrganizationSettingsTabGuard],
        canDeactivate: [DeactivateOrganizationSettingsGuard],
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConfigurationRoutingModule {}
