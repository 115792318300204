import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins';
import { getRAMSettingsAvailableWorkflows } from 'src/app/common/utils';
import { IRAMSettings } from 'src/app/configuration/models';
import { AccessControlService } from 'src/app/root';
import { WorkshopConstants } from 'src/app/workshop';
import { WorkflowType } from 'src/app/workshop/models/workflow-page.model';

import {
  IWorkflowBtn,
  WorkflowBtnIconEnum,
  WorkflowBtnIdEnum,
  WorkflowBtnNameEnum,
  WorkflowBtnTooltipEnum,
} from 'src/app/workshop/models';

@Component({
  selector: 'ignis-selected-workflow',
  templateUrl: './selected-workflow.component.html',
  styleUrls: ['./selected-workflow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectedWorkflowComponent extends OnDestroyMixin() implements OnInit {
  @Output() handleSelectedWorkflow: EventEmitter<number> = new EventEmitter<number>();

  ramSettings: IRAMSettings;
  hideLabel: boolean = false;
  workflowsBtnData: IWorkflowBtn[];

  workflowBtnType: typeof WorkflowType = WorkflowType;
  currentlyWorkflowChecked: WorkflowType = WorkflowType.NONE;

  constructor(
    private cdr: ChangeDetectorRef,
    private store: Store<ApplicationState>,
    public accessControlService: AccessControlService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.readSelectedWorkflow();

    getRAMSettingsAvailableWorkflows(this.store, this.destroy).subscribe((response: IRAMSettings) => {
      this.ramSettings = response;

      if (!Object.values(this.ramSettings)[this.currentlyWorkflowChecked]) {
        this.currentlyWorkflowChecked = null;
        this.handleSelectedWorkflow.emit(this.currentlyWorkflowChecked);
      }

      this.populateWorkflowButtons();
    });
  }

  populateWorkflowButtons(): void {
    this.workflowsBtnData = [
      {
        visible: this.ramSettings.cleaningWorkflow,
        disabled: true,
        tooltipText: WorkflowBtnTooltipEnum.Cleaning,
        id: WorkflowBtnIdEnum.Cleaning,
        name: WorkflowBtnNameEnum.Cleaning,
        checked: this.currentlyWorkflowChecked === WorkflowType.CLEANING,
        workflowType: WorkflowType.CLEANING,
        icon: WorkflowBtnIconEnum.Cleaning,
      },
      {
        visible: this.ramSettings.cylinderChargingWorkflow,
        disabled: false,
        tooltipText: WorkflowBtnTooltipEnum.CylinderCharging,
        id: WorkflowBtnIdEnum.CylinderCharging,
        name: WorkflowBtnNameEnum.CylinderCharging,
        checked: this.currentlyWorkflowChecked === WorkflowType.CYLINDER_CHARGING,
        workflowType: WorkflowType.CYLINDER_CHARGING,
        icon: WorkflowBtnIconEnum.CylinderCharging,
      },
      {
        visible:
          this.ramSettings.automaticTestingWorkflow && this.accessControlService.equipmentTestingGlobalFeatureToggle,
        disabled: false,
        tooltipText: WorkflowBtnTooltipEnum.Testing,
        id: WorkflowBtnIdEnum.Testing,
        name: WorkflowBtnNameEnum.Testing,
        checked: this.currentlyWorkflowChecked === WorkflowType.TESTING,
        workflowType: WorkflowType.TESTING,
        icon: WorkflowBtnIconEnum.Testing,
      },
      {
        visible: this.ramSettings.checklistWorkflow,
        disabled: false,
        tooltipText: WorkflowBtnTooltipEnum.Checklist,
        id: WorkflowBtnIdEnum.Checklist,
        name: WorkflowBtnNameEnum.Checklist,
        checked: this.currentlyWorkflowChecked === WorkflowType.CHECKING,
        workflowType: WorkflowType.CHECKING,
        icon: WorkflowBtnIconEnum.Checklist,
      },
    ].filter((workflow: IWorkflowBtn) => workflow.visible);

    this.cdr.detectChanges();
  }

  getSelectedWorkflowFromChildAndSendToTheParent(targetType: WorkflowType): void {
    this.currentlyWorkflowChecked = targetType;
    this.populateWorkflowButtons();
    this.handleSelectedWorkflow.emit(this.currentlyWorkflowChecked);
  }

  readSelectedWorkflow(): void {
    if (WorkshopConstants.selectedWorkflow in localStorage) {
      this.currentlyWorkflowChecked = Number(localStorage.getItem(WorkshopConstants.selectedWorkflow));
    }
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();
  }
}
