import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  inject,
} from '@angular/core';
import { Observable } from 'rxjs';
import { CommonConstants } from 'src/app/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ignis-image-audio',
  templateUrl: './image-audio.component.html',
  styleUrls: ['./image-audio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageAudioComponent implements OnChanges {
  @Input() formatDate: string;
  @Input() mediaName: string;
  @Input() toggleWidget: boolean;
  @Output() handleNoteImageUrl: EventEmitter<string> = new EventEmitter();

  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  httpClient: HttpClient = inject(HttpClient);

  isImage: boolean = false;
  imageSrc: string | ArrayBuffer;
  isAudio: boolean = false;
  audioSrc: string | ArrayBuffer;

  getNoteImage(imageUrl: string): void {
    this.handleNoteImageUrl.emit(imageUrl);
  }

  ngOnChanges(): void {
    if (this.mediaName) {
      this.checkMediaType(this.mediaName);
    }
  }

  checkMediaType(fileName: string): void {
    let mediaUrl: string[] = fileName.split('.');
    let fileExtension: string = mediaUrl.pop();
    const imagesFormat: string[] = CommonConstants.allowedImagesFormat;
    const allowedAudioTypes: string[] = CommonConstants.allowedAudioTypes;
    const deviceConnectionBaseUrl: string = environment.API_URLS.DEVICE_CONNECTION;
    const headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem(CommonConstants.ACCESS_TOKEN)}`,
    });

    if (fileName.includes('https://')) {
      const regex: RegExp = CommonConstants.regexToExtractFileWithExtension;
      const match: unknown = fileName.match(regex);
      const fileNameFromUrl: string = match ? match[1] : '';

      mediaUrl = fileNameFromUrl.split('.');
      fileExtension = mediaUrl.pop();

      fileName = fileNameFromUrl;
    }

    if (imagesFormat.includes(fileExtension)) {
      this.isImage = true;
      this.loadNotesImageFile(fileName, deviceConnectionBaseUrl, headers);
    }

    if (allowedAudioTypes.includes(fileExtension)) {
      this.isAudio = true;
      this.loadNotesAudioFile(fileName, deviceConnectionBaseUrl, headers).subscribe((result: Blob) => {
        this.audioSrc = URL.createObjectURL(result);
        this.cdr.detectChanges();
      });
    }
  }

  loadNotesAudioFile(audioFileName: string, deviceConnectionBaseUrl: string, headers: HttpHeaders): Observable<Blob> {
    return this.httpClient.get(`${deviceConnectionBaseUrl}/files/${audioFileName}`, {
      headers: headers,
      responseType: 'blob',
    });
  }

  loadNotesImageFile(imageFileName: string, deviceConnectionBaseUrl: string, headers: HttpHeaders): void {
    this.httpClient
      .get(`${deviceConnectionBaseUrl}/files/${imageFileName}`, { headers: headers, responseType: 'blob' })
      .subscribe((response: Blob) => {
        const reader: FileReader = new FileReader();

        reader.onloadend = () => {
          this.imageSrc = reader.result;
          this.cdr.detectChanges();
        };

        reader.readAsDataURL(response);
      });
  }
}
