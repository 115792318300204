import {
  errorListData,
  errorPostPutItemData,
  receiveListData,
  receivePostPutItemData,
  requestListData,
  requestPostPutItemData,
} from 'src/app/common/utils/reducer/reducer.utils';
import { INewNote, INote } from 'src/app/remote-monitoring/models/remote-monitoring.model';
import { RemoteMonitoringActionTypes } from '../../actions/remote-monitoring-action-types';
import { RemoteMonitoringNotesState } from '../../models/notes.model';
import { GenericAction } from './../../../../common/state/models/generic-action.model';
import { IRemoteMonitoringNotesState } from './../../models/notes.model';

const resetAddNote = (lastState: IRemoteMonitoringNotesState): IRemoteMonitoringNotesState => ({
  ...lastState,
  newNote: {
    data: null,
    errors: null,
    isSuccess: null,
    isLoading: false,
  },
});

export function remoteMonitoringNotesReducer(
  lastState: IRemoteMonitoringNotesState = new RemoteMonitoringNotesState(),
  action: GenericAction<RemoteMonitoringActionTypes, any>,
): IRemoteMonitoringNotesState {
  switch (action.type) {
    case RemoteMonitoringActionTypes.REQUEST_NOTES_LIST:
      return requestListData<IRemoteMonitoringNotesState, INote[]>(lastState, ['notesList']);
    case RemoteMonitoringActionTypes.RECEIVE_NOTES_LIST:
      return receiveListData<IRemoteMonitoringNotesState, INote[]>(lastState, ['notesList'], action.payload);
    case RemoteMonitoringActionTypes.ERROR_RECEIVE_NOTES_LIST:
      return errorListData<IRemoteMonitoringNotesState, INote[]>(lastState, ['notesList'], action.payload);

    case RemoteMonitoringActionTypes.REQUEST_READ_NOTES:
      return requestListData<IRemoteMonitoringNotesState, Partial<INote>[]>(lastState, ['readNotes']);
    case RemoteMonitoringActionTypes.RECEIVE_READ_NOTES:
      return receiveListData<IRemoteMonitoringNotesState, Partial<INote>[]>(lastState, ['readNotes'], action.payload);
    case RemoteMonitoringActionTypes.ERROR_RECEIVE_READ_NOTES:
      return errorListData<IRemoteMonitoringNotesState, Partial<INote>[]>(lastState, ['readNotes'], action.payload);

    case RemoteMonitoringActionTypes.REQUEST_ADD_NOTE:
      return requestPostPutItemData<IRemoteMonitoringNotesState, INewNote>(lastState, ['newNote'], action.payload);
    case RemoteMonitoringActionTypes.RECEIVE_ADD_NOTE:
      return receivePostPutItemData<IRemoteMonitoringNotesState, INewNote>(lastState, ['newNote'], action.payload);
    case RemoteMonitoringActionTypes.ERROR_RECEIVE_ADD_NOTE:
      return errorPostPutItemData<IRemoteMonitoringNotesState, INewNote>(lastState, ['newNote'], action.payload);
    case RemoteMonitoringActionTypes.RESET_ADD_NOTE:
      return resetAddNote(lastState);

    default:
      return lastState;
  }
}
