import { IStoreApiList, StoreApiList } from 'src/app/common/models/store-api-list.model';

export interface IFeatureToggle {
  feature: string;
  isEnabled: boolean;
}

export interface IFeaturesToggleState {
  featuresToggleList: IStoreApiList<IFeatureToggle[]>;
}

export class FeaturesToggleState implements IFeaturesToggleState {
  featuresToggleList: IStoreApiList<IFeatureToggle[]> = new StoreApiList();
}
