import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IChecklist } from 'src/app/configuration/models';
import { IConfigurationState } from '../../models/configuration.models';
import { RequestAddChecklist, RequestDeleteChecklist, RequestEditChecklist } from './checklist-action-types-creators';

@Injectable({ providedIn: 'root' })
export class ChecklistActions {
  constructor(public store: Store<IConfigurationState>) {}

  public requestAddChecklist(params: IChecklist): void {
    this.store.dispatch(new RequestAddChecklist(params));
  }

  public requestDeleteChecklist(params: Partial<IChecklist>): void {
    this.store.dispatch(new RequestDeleteChecklist(params));
  }

  public requestEditChecklist(params: IChecklist): void {
    this.store.dispatch(new RequestEditChecklist(params));
  }
}
