import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ITableSettingsUpdate } from 'src/app/common/models/table.model';
import { ILocalizationProfile } from '../../models/localization-profile.model';
import { ISettingsState } from '../models/settings.model';
import {
  RequestLocalizedOptions,
  RequestMeasurementUnits,
  RequestSaveUserSettings,
  RequestTableColumns,
  RequestUpdateTableColumn,
  RequestUserSettings,
  ResetTableColumns,
} from './settings-action-types-creators';

@Injectable({ providedIn: 'root' })
export class SettingsActions {
  constructor(private store: Store<ISettingsState>) {}

  public requestLocalizedOptions(): void {
    this.store.dispatch(new RequestLocalizedOptions());
  }

  public requestUserSettings(): void {
    this.store.dispatch(new RequestUserSettings());
  }

  public requestSaveUserSettings(settings: ILocalizationProfile): void {
    this.store.dispatch(new RequestSaveUserSettings(settings));
  }

  public requestMeasurementUnits(): void {
    this.store.dispatch(new RequestMeasurementUnits());
  }

  public requestTableColumns(tableName: string): void {
    this.store.dispatch(new RequestTableColumns(tableName));
  }

  public resetTableColumns(): void {
    this.store.dispatch(new ResetTableColumns());
  }

  public requestUpdateTableColumn(payload: ITableSettingsUpdate): void {
    this.store.dispatch(new RequestUpdateTableColumn(payload));
  }
}
