<div class="table-management">
  <odx-icon
    (click)="changeColumnSettingsState()"
    name="tile-menu"
    size="inline"
    class="table-management-icon"
    [ngClass]="{ 'opened-dropdown': isColumnsSettingsOpened }"
    data-test-id="table_settings.change_column_settings"
  ></odx-icon>
</div>
<div
  class="columns-dropdown"
  [class]="currentAppTheme"
  *ngIf="isColumnsSettingsOpened"
  (odxClickOutside)="closeColumnSettings()"
  data-test-id="table_settings.columns_list"
>
  <ul class="column-list">
    <li class="column-settings-row" *ngFor="let col of tableColumns; let i = index">
      <odx-checkbox
        [ngClass]="{ 'disable-click-event': isLoading }"
        [disabled]="disableCheckbox && lastCheckboxChecked.field === col.field"
        [(checked)]="col.visible"
        [attr.data-test-id]="col.field"
        id="{{ col.field }}-{{ i }}"
        (click)="changeVisibility(col)"
      >
        {{ col.header | translate }}
      </odx-checkbox>
    </li>
  </ul>
</div>
