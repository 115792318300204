import { IStoreApiItem, StoreApiItem } from 'src/app/common';

export interface ILegalTermsData {
  version: string;
  hyperlink: string;
  userLanguage: string;
}

export interface ILegalTermsState {
  legalTermsData: IStoreApiItem<ILegalTermsData>;
  accepted: IStoreApiItem<any>;
}

export class LegalTermsState implements ILegalTermsState {
  legalTermsData: IStoreApiItem<ILegalTermsData> = new StoreApiItem();
  accepted: IStoreApiItem<any> = new StoreApiItem();
}
