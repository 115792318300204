import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { INewNote, INote } from 'src/app/remote-monitoring/models/remote-monitoring.model';
import { RemoteMonitoringActionTypes } from '../remote-monitoring-action-types';

export class RequestNotesList implements Action {
  readonly type: RemoteMonitoringActionTypes.REQUEST_NOTES_LIST = RemoteMonitoringActionTypes.REQUEST_NOTES_LIST;

  constructor(public payload: string) {}
}
export class ReceiveNotesList implements Action {
  readonly type: RemoteMonitoringActionTypes.RECEIVE_NOTES_LIST = RemoteMonitoringActionTypes.RECEIVE_NOTES_LIST;

  constructor(public payload: INote[]) {}
}
export class ErrorReceiveNotesList implements Action {
  readonly type: RemoteMonitoringActionTypes.ERROR_RECEIVE_NOTES_LIST =
    RemoteMonitoringActionTypes.ERROR_RECEIVE_NOTES_LIST;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestReadNotes implements Action {
  readonly type: RemoteMonitoringActionTypes.REQUEST_READ_NOTES = RemoteMonitoringActionTypes.REQUEST_READ_NOTES;

  constructor(
    public incidentId: string,
    public notes: Partial<INote>[],
    public incidentVersion: number,
  ) {}
}
export class ReceiveReadNotes implements Action {
  readonly type: RemoteMonitoringActionTypes.RECEIVE_READ_NOTES = RemoteMonitoringActionTypes.RECEIVE_READ_NOTES;
}
export class ErrorReceiveReadNotes implements Action {
  readonly type: RemoteMonitoringActionTypes.ERROR_RECEIVE_READ_NOTES =
    RemoteMonitoringActionTypes.ERROR_RECEIVE_READ_NOTES;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestAddNote implements Action {
  readonly type: RemoteMonitoringActionTypes.REQUEST_ADD_NOTE = RemoteMonitoringActionTypes.REQUEST_ADD_NOTE;

  constructor(public payload: INewNote) {}
}
export class ReceiveAddNote implements Action {
  readonly type: RemoteMonitoringActionTypes.RECEIVE_ADD_NOTE = RemoteMonitoringActionTypes.RECEIVE_ADD_NOTE;
}
export class ErrorReceiveAddNote implements Action {
  readonly type: RemoteMonitoringActionTypes.ERROR_RECEIVE_ADD_NOTE =
    RemoteMonitoringActionTypes.ERROR_RECEIVE_ADD_NOTE;

  constructor(public payload: HttpErrorResponse) {}
}
export class ResetAddNote implements Action {
  readonly type: RemoteMonitoringActionTypes.RESET_ADD_NOTE = RemoteMonitoringActionTypes.RESET_ADD_NOTE;
}
