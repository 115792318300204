import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { interval, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { formatLocaleTime } from 'src/app/common/utils/date-utils/date.utils';
import { IEcpTeam, IFireFighterAlarms, ITeamFirefighter } from '../../../../../models/remote-monitoring.model';
import { getTimeFromSeconds } from './../../../../../../common/utils/date-utils/date.utils';

@Component({
  selector: 'ignis-team-firefighter',
  templateUrl: './team-firefighter.component.html',
  styleUrls: ['./team-firefighter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamFirefighterComponent implements OnInit, OnDestroy {
  @Input() firefighter: ITeamFirefighter;
  @Input() longestAlarm: IFireFighterAlarms;
  @Input() pressureDisplayUnit: string;
  @Input() defaultTimeDisplay: string | number;
  @Input() teamId: string;
  @Input() mediumPressureValue: number;
  @Input() lowPressureValue: number;

  team: IEcpTeam;
  timer: string = null;
  defaultTimeLabel: string;
  defaultTimeTooltip: string;
  currentAppTheme: string;
  emptyDate: string = '--:--';

  subscription: Subscription;

  constructor(
    private cookieService: CookieService,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    private cdr: ChangeDetectorRef,
  ) {
    this.currentAppTheme = this.document.body.className.split(' ')[1];
  }

  ngOnInit(): void {
    if (this.firefighter) {
      this.subscription = interval(1000)
        .pipe(
          map((count: any) => {
            return this.format(count + this.longestAlarm?.duration);
          }),
        )
        .subscribe((time: string) => {
          this.timer = time;
          this.cookieService.set(`timer${this.firefighter?.id}-${this.teamId}`, time);
          this.cdr.detectChanges();
        });

      this.timer = this.cookieService.get(`timer${this.firefighter?.id}-${this.teamId}`);

      const towHourWithLocalTimeZone: string = this.firefighter.tow
        ? formatLocaleTime(new Date(this.firefighter.tow))
        : null;

      const teString: string = `${this.translateService.instant('REMOTE_MONITORING.STR_ELAPSED')}: ${
        typeof this.firefighter.te === 'object' ? this.emptyDate : this.firefighter.te
      }`;
      const ttwString: string = `TTW: ${
        typeof this.firefighter.ttw === 'object' ? this.emptyDate : this.firefighter.ttw
      }`;
      const towString: string = `TOW: ${
        typeof towHourWithLocalTimeZone === 'object' ? this.emptyDate : towHourWithLocalTimeZone
      }`;
      const ttrString: string = `TTR: ${
        typeof this.firefighter.ttr === 'object' ? this.emptyDate : this.firefighter.ttr
      }`;

      this.composeTimeData(ttwString, towString, ttrString, teString, towHourWithLocalTimeZone);
    }
  }

  composeTimeData(
    ttwString: string,
    towString: string,
    ttrString: string,
    teString: string,
    towHourWithLocalTimeZone: string,
  ): void {
    switch (this.defaultTimeDisplay) {
      case 'TE':
        this.firefighter.defaultTimeDisplay =
          typeof this.firefighter.te === 'object' ? this.emptyDate : this.firefighter.te;
        this.defaultTimeLabel = this.translateService.instant('REMOTE_MONITORING.STR_ELAPSED');
        this.defaultTimeTooltip = `${ttwString} \n${towString} \n${ttrString}`;
        break;
      case 'TTW':
        this.firefighter.defaultTimeDisplay =
          typeof this.firefighter.ttw === 'object' ? this.emptyDate : this.firefighter.ttw;
        this.defaultTimeLabel = 'TTW';
        this.defaultTimeTooltip = `${teString} \n${towString} \n${ttrString}`;
        break;
      case 'ToW':
        this.firefighter.defaultTimeDisplay =
          typeof towHourWithLocalTimeZone === 'object' ? this.emptyDate : towHourWithLocalTimeZone;
        this.defaultTimeLabel = 'ToW';
        this.defaultTimeTooltip = `${teString} \n${ttwString} \n${ttrString}`;
        break;
      case 'TTR':
        this.firefighter.defaultTimeDisplay =
          typeof this.firefighter.ttr === 'object' ? this.emptyDate : this.firefighter.ttr;
        this.defaultTimeLabel = 'TTR';
        this.defaultTimeTooltip = `${teString} \n${ttwString} \n${towString}`;
        break;
    }
  }

  getFullProgressWidth(firefighter: ITeamFirefighter): number {
    const progressDiv: HTMLElement = document.getElementsByClassName('bar-unit-progress')[0] as HTMLElement;
    const progressWidth: number = progressDiv.offsetWidth;

    const pressurePercent: number = (firefighter.currentPressure / firefighter.cylinderRatedPressure) * 100;

    return (progressWidth * pressurePercent) / 100;
  }

  getEmptyProgressWidth(firefighter: ITeamFirefighter): number {
    const progressDiv: HTMLElement = document.getElementsByClassName('bar-unit-progress')[0] as HTMLElement;
    const progressWidth: number = progressDiv.offsetWidth;

    return progressWidth - this.getFullProgressWidth(firefighter);
  }

  getFirefighterAlarms(firefighter: ITeamFirefighter): number {
    const alarms: IFireFighterAlarms[] = firefighter.fireFighterAlarms;
    const existingAlarms: string[] = alarms.map((alarm: IFireFighterAlarms) => alarm.type.value);

    if (!existingAlarms.length) {
      return;
    }

    if (
      existingAlarms.includes('low_pressure') ||
      existingAlarms.includes('dsu') ||
      existingAlarms.includes('adsu') ||
      existingAlarms.includes('motion_alarm')
    ) {
      return 0;
    } else {
      return 1;
    }
  }

  format(seconds: number): string {
    return getTimeFromSeconds(seconds, false);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
