import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isValid, parse, parseISO } from 'date-fns';
import { CommonConstants } from '../../constants/common.constants';

@Component({
  selector: 'ignis-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent implements OnChanges, OnInit {
  @Input() formatDate: string;
  @Input() readOnly: boolean;
  @Input() appendTo: string = undefined;
  @Input() showTime: boolean;
  @Input() showSeconds: boolean;
  @Input() selectionMode: string;
  @Input() defaultRange: string | any;
  @Input() minDate: string | Date;
  @Input() maxDate: string | Date;
  @Input() numberOfMonths: number = 1;
  @Input() clearable: boolean = true;
  @Input() isDisabled: boolean = false;
  @Input() changeToPreviousValue: boolean = false;
  @Input() yearNavigator: boolean = true;

  @Output() dateSelect: EventEmitter<any> = new EventEmitter();
  @Output() dateChange: EventEmitter<any> = new EventEmitter();

  minDateAsDate: Date;
  maxDateAsDate: Date;

  dateFilters: string | any = '';
  placeholder: string;

  showError: boolean = false;
  editManually: boolean = false;
  previousValue: string | any;
  currentAppTheme: string;

  constructor(
    private translateService: TranslateService,
    @Inject(DOCUMENT) public document: Document,
  ) {
    this.currentAppTheme = this.document.body.className.split(' ')[1];
  }

  ngOnInit(): void {
    this.checkDefaultDate();
  }

  ngOnChanges(): void {
    if (!this.editManually) {
      this.checkDefaultDate();
    }

    if (this.minDate && isValid(new Date(this.minDate))) {
      this.minDateAsDate = new Date(this.minDate);
    } else {
      this.minDateAsDate = null;
    }

    if (this.maxDate && isValid(new Date(this.maxDate))) {
      this.maxDateAsDate = new Date(this.maxDate);
    } else {
      this.maxDateAsDate = null;
    }

    this.changePlaceholderFormat();
  }

  onSelect(): void {
    this.previousValue = this.dateFilters;
    this.dateSelect.emit(this.dateFilters);
  }

  editManuallyStart(inputValue: string): void {
    this.editManually = true;
    const newDateEntered: string = inputValue;

    if (newDateEntered.length < 1) {
      this.resetRange();
      this.showError = false;

      return;
    }

    this.showError = !this.isDateRangeValid(newDateEntered);
  }

  hideTableReorderIndicators(): void {
    const tableReorderIndicatorUpClassName: string = 'p-datatable-reorder-indicator-up';
    const rableReorderIndicatorDownClassName: string = 'p-datatable-reorder-indicator-down';

    const topIndicatorElem: HTMLElement = this.document.getElementsByClassName(
      tableReorderIndicatorUpClassName,
    )[0] as HTMLElement;
    const downIndicatorElem: HTMLElement = this.document.getElementsByClassName(
      rableReorderIndicatorDownClassName,
    )[0] as HTMLElement;

    if (topIndicatorElem?.style.display === 'block' && downIndicatorElem?.style.display === 'block') {
      topIndicatorElem.style.display = 'none';
      downIndicatorElem.style.display = 'none';
    }
  }

  checkIfDateIsInvalid(inputValue: string): void {
    const wrongDate: string = inputValue;

    if (this.dateFilters === null) {
      this.addPreviousValue();
    } else if (this.editManually) {
      this.checkIfDateIsValid();
    }

    if (wrongDate.length < 1) {
      this.showError = false;
    }

    this.editManually = false;
  }

  changePlaceholderFormat(): void {
    const dateFormatToLowerCase: string = this.translateService.instant(
      'SETTINGS.STR_DATEPICKER_PLACEHOLDER_DATE_FORMAT',
    );
    const hourMinuteSecondFormat: string = this.translateService.instant(
      'SETTINGS.STR_DATEPICKER_PLACEHOLDER_TIME_FORMAT',
    );

    if (this.selectionMode === 'range') {
      if (this.showTime && this.showSeconds) {
        this.placeholder = `${dateFormatToLowerCase}  ${hourMinuteSecondFormat} - ${dateFormatToLowerCase} ${hourMinuteSecondFormat}`;

        return;
      }

      this.placeholder = dateFormatToLowerCase + ' - ' + dateFormatToLowerCase;

      return;
    }

    if (this.showTime) {
      this.placeholder = dateFormatToLowerCase + ' ' + hourMinuteSecondFormat;

      return;
    }

    this.placeholder = dateFormatToLowerCase;
  }

  checkDefaultDate(): void {
    if (!this.defaultRange) {
      return;
    }

    let startDate: Date | null = null;
    let endDate: Date | null = null;

    if (typeof this.defaultRange === 'string') {
      const dates: string[] = this.defaultRange.split(' - ');

      if (dates.length === 2) {
        const date1: string = dates[0].trim();
        const date2: string = dates[1].trim();

        startDate = parse(date1, this.formatDate, new Date());
        endDate = parse(date2, this.formatDate, new Date());
      } else {
        this.dateFilters = parseISO(dates[0]);
        this.previousValue = parseISO(dates[0]);
      }
    }

    if (Array.isArray(this.defaultRange) && this.defaultRange.length === 2) {
      startDate = parseISO(new Date(this.defaultRange[0]).toString());
      endDate = parseISO(new Date(this.defaultRange[1]).toString());
    }

    if (startDate && endDate && isValid(startDate) && isValid(endDate)) {
      this.dateFilters = [startDate, endDate];
      this.previousValue = [startDate, endDate];
    } else if (Array.isArray(this.defaultRange) && this.defaultRange[0]) {
      this.dateFilters = this.defaultRange;
      this.previousValue = this.defaultRange;
    }
  }

  getTheDateSeparator(): string {
    return this.formatDate.includes('.') ? '.' : '/';
  }

  resetRange(): void {
    this.dateFilters = '';

    if (!this.changeToPreviousValue) {
      this.previousValue = this.dateFilters;
    }

    this.dateChange.emit(this.dateFilters);
  }

  isDateRangeValid(dateInStringFormat: string): boolean {
    if (!dateInStringFormat.includes('-')) {
      return this.isDateValid(dateInStringFormat);
    }

    const dateRangeToArr: string[] = dateInStringFormat.split(CommonConstants.splitDateRangeRegex);

    return this.isDateValid(dateRangeToArr[0]) && this.isDateValid(dateRangeToArr[1]);
  }

  isDateValid(dateInStringFormat: string): boolean {
    return isValid(parse(dateInStringFormat?.trim(), this.formatDate, new Date()));
  }

  addPreviousValue(): void {
    this.showError = true;

    if (Array.isArray(this.previousValue)) {
      this.dateFilters = this.previousValue;
      this.dateSelect.emit(this.dateFilters);
      this.showError = false;
    }

    if (!this.previousValue) {
      this.showError = false;
    }

    if (typeof this.previousValue === 'object') {
      this.dateSelect.emit(this.previousValue);
    } else if (typeof this.previousValue !== 'object' && !this.previousValue?.includes('-')) {
      if (this.previousValue) {
        this.dateSelect.emit(this.previousValue);
      } else {
        this.resetRange();
      }
    } else {
      this.formatPreviousDateRange();
    }

    this.showError = false;
  }

  formatPreviousDateRange(): void {
    const dateRangeToArr: string[] = this.previousValue.split('-');
    let startDateArr: string[] = dateRangeToArr[0].split(this.getTheDateSeparator());

    startDateArr = [startDateArr[1], startDateArr[0], startDateArr[2]];
    const formattedStartDate: string = startDateArr.toString().replace(',', this.getTheDateSeparator());

    let endDateArr: string[] = dateRangeToArr[1].split(this.getTheDateSeparator());

    endDateArr = [endDateArr[1], endDateArr[0], endDateArr[2]];
    const formattedEndDate: string = endDateArr.toString().replace(',', this.getTheDateSeparator());

    this.previousValue = [new Date(formattedStartDate), new Date(formattedEndDate)];
    this.dateSelect.emit(this.previousValue);
  }

  checkIfDateIsValid(): void {
    this.returnPreviousValueIfSelectedRangeIsLessThanMinValue();
    this.resetOrEmitPreviousValue();

    this.showError = false;
  }

  returnPreviousValueIfSelectedRangeIsLessThanMinValue(): void {
    if (
      (new Date(this.dateFilters[0]).getTime() < new Date(this.minDate).getTime() ||
        new Date(this.dateFilters[1]).getTime() > new Date(this.maxDate).getTime()) &&
      this.selectionMode === 'range'
    ) {
      this.dateFilters = this.previousValue;
      this.dateSelect.emit(this.dateFilters);
    }
  }

  resetOrEmitPreviousValue(): void {
    if (Array.isArray(this.dateFilters)) {
      this.dateSelect.emit(this.dateFilters);
    }

    if (typeof this.dateFilters === 'object') {
      this.dateSelect.emit(this.dateFilters);
    }

    if (typeof this.dateFilters === 'string' && this.dateFilters.length < 1) {
      if (this.changeToPreviousValue) {
        this.dateSelect.emit(this.previousValue);
      } else {
        this.resetRange();
      }
    }
  }
}
