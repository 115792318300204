import { GenericAction } from './../../../common/state/models/generic-action.model';
import { INewUser, IUpdateUser, IUserPage, IUserPersonalInfo, IUserRole } from './../../models/user.model';
import { UserActionTypes } from './../actions/user-action-types';
import { IUserState, UserState } from './../models/user.model';
import { IUser } from '../../models/user.model';
import {
  errorItemData,
  errorListData,
  errorPostPutItemData,
  receiveItemData,
  receiveListData,
  receivePostPutItemData,
  requestItemData,
  requestListData,
  requestPostPutItemData,
} from './../../../common/utils/reducer/reducer.utils';

const resetUserCRUDState = (lastState: IUserState): IUserState => ({
  ...lastState,
  user: {
    ...lastState.user,
    errors: null,
    isSuccess: null,
  },
});

export function userReducer(
  lastState: IUserState = new UserState(),
  action: GenericAction<UserActionTypes, any>
): IUserState {
  switch (action.type) {

    case UserActionTypes.REQUEST_USER_PAGE:
      return requestItemData<IUserState, IUserPage>(lastState, ['usersPage']);
    case UserActionTypes.RECEIVE_USER_PAGE:
      return receiveItemData<IUserState, IUserPage>(lastState, ['usersPage'], action.payload);
    case UserActionTypes.ERROR_RECEIVE_USER_PAGE:
      return errorItemData<IUserState, IUserPage>(lastState, ['usersPage'], action.payload);

    case UserActionTypes.REQUEST_ADD_USER:
      return requestPostPutItemData<IUserState, INewUser>(lastState, ['user'], action.payload);
    case UserActionTypes.RECEIVE_ADD_USER:
      return receivePostPutItemData<IUserState, IUser>(lastState, ['user'], action.payload);
    case UserActionTypes.ERROR_ADD_USER:
      return errorPostPutItemData<IUserState, INewUser>(lastState, ['user'], action.payload);

    case UserActionTypes.REQUEST_USER_BY_ID:
      return requestItemData<IUserState, IUser>(lastState, ['user']);
    case UserActionTypes.RECEIVE_USER_BY_ID:
      return receiveItemData<IUserState, IUser>(lastState, ['user'], action.payload as IUser);
    case UserActionTypes.ERROR_RECEIVE_USER_BY_ID:
      return errorItemData<IUserState, IUser>(lastState, ['user'], action.payload);

    case UserActionTypes.REQUEST_USERS_PERSONAL_INFO_BY_IDS:
      return requestListData<IUserState, IUserPersonalInfo[]>(lastState, ['usersPersonalInfo']);
    case UserActionTypes.RECEIVE_USERS_PERSONAL_INFO_BY_IDS:
      return receiveListData<IUserState, IUserPersonalInfo[]>(lastState, ['usersPersonalInfo'], action.payload);
    case UserActionTypes.ERROR_RECEIVE_USERS_PERSONAL_INFO_BY_IDS:
      return errorListData<IUserState, IUserPersonalInfo[]>(lastState, ['usersPersonalInfo'], action.payload);

    case UserActionTypes.REQUEST_UPDATE_USER:
      return requestPostPutItemData<IUserState, IUpdateUser>(lastState, ['user'], action.payload);
    case UserActionTypes.RECEIVE_UPDATE_USER:
      return receivePostPutItemData<IUserState, IUpdateUser>(lastState, ['user'], action.payload);
    case UserActionTypes.ERROR_UPDATE_USER:
      return errorPostPutItemData<IUserState, IUpdateUser>(lastState, ['user'], action.payload);

    case UserActionTypes.REQUEST_USER_ROLE_LIST:
      return requestListData<IUserState, IUserRole[]>(lastState, ['roleList']);
    case UserActionTypes.RECEIVE_USER_ROLE_LIST:
      return receiveListData<IUserState, IUserRole[]>(lastState, ['roleList'], action.payload);
    case UserActionTypes.ERROR_RECEIVE_USER_ROLE_LIST:
      return errorListData<IUserState, IUserRole[]>(lastState, ['roleList'], action.payload);

    case UserActionTypes.REQUEST_USER_DELETE:
      return requestPostPutItemData<IUserState, IUser>(lastState, ['user'], action.payload);
    case UserActionTypes.RECEIVE_USER_DELETE:
      return receivePostPutItemData<IUserState, IUser>(lastState, ['user'], action.payload);
    case UserActionTypes.ERROR_RECEIVE_USER_DELETE:
      return errorPostPutItemData<IUserState, IUser>(lastState, ['user'], action.payload);

    case UserActionTypes.REQUEST_UPLOAD_CSV_FILE:
      return requestPostPutItemData<IUserState, any>(lastState, ['csv'], action.payload);
    case UserActionTypes.RECEIVE_UPLOAD_CSV_FILE:
      return receivePostPutItemData<IUserState, any>(lastState, ['csv'], action.payload);
    case UserActionTypes.ERROR_RECEIVE_UPLOAD_CSV_FILE:
      return errorPostPutItemData<IUserState, any>(lastState, ['csv'], action.payload);

    case UserActionTypes.RESET_CRUD_USER_STATE:
      return resetUserCRUDState(lastState);

    default:
      return lastState;
  }
}
