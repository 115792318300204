import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { select, Store } from '@ngrx/store';
import { Theme, ThemingService } from '@odx/angular/theming';
import { filter, Subject, takeUntil } from 'rxjs';
import { ApplicationState, CommonConstants, IStoreApiItem, LoggerService } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { LicenseTicket } from 'src/app/root/models/licenses.model';
import { LicensesActions } from 'src/app/root/state/licenses/actions/licenses.actions';
import { selectLicenseStatus } from 'src/app/root/state/licenses/selectors/licenses.selector';
import { UserNotificationConstants } from 'src/app/user-management/constants';

@Component({
  selector: 'ignis-license-validation',
  templateUrl: './license-validation.component.html',
  styleUrls: ['./license-validation.component.scss'],
})
export class LicenseValidationComponent extends OnDestroyMixin() implements OnInit {
  isLicenseValid: boolean = false;
  isSubmitting: boolean = false;
  httpCustomErrorCode: string;
  userNotificationsConstants: any = UserNotificationConstants.notificationCodes;

  licenseForm: FormGroup;

  licensesActions: LicensesActions = inject(LicensesActions);
  loggerService: LoggerService = inject(LoggerService);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  themingService: ThemingService = inject(ThemingService);

  localUnsubscribe$: Subject<void>;

  constructor(private store: Store<ApplicationState>) {
    super();
  }

  ngOnInit(): void {
    this.licenseForm = new FormGroup({
      ticketNumber: new FormControl('', [Validators.required]),
    });

    this.themingService.selectTheme(Theme.DARK);
  }

  checkLicense(): void {
    this.httpCustomErrorCode = null;
    this.isSubmitting = true;

    this.localUnsubscribe$ = new Subject<void>();

    this.licensesActions.requestLicenseStatus(new LicenseTicket(this.licenseForm.getRawValue()));

    this.store
      .pipe(
        select(selectLicenseStatus),
        filter((state: IStoreApiItem<any>) => !state.isLoading),
        takeUntil(this.localUnsubscribe$),
      )
      .subscribe((response: IStoreApiItem<any>) => {
        if (response.isSuccess) {
          this.isLicenseValid = true;
          this.resetLicense();
        }

        if (response.errors) {
          this.resetLicense();

          this.httpCustomErrorCode = response.errors?.error.code?.toString();

          this.loggerService.logMessage(response.errors.error.debugMessage, CommonConstants.logType.INFO);
          this.loggerService.logTrace(response.errors.error.debugMessage, SeverityLevel.Information);
        }

        this.cdr.detectChanges();
      });
  }

  resetLicense(): void {
    this.isSubmitting = false;
    this.localUnsubscribe$.next();
    this.licensesActions.resetLicenseStatus();
  }

  finishActivation(): void {
    this.isSubmitting = true;

    setTimeout(() => {
      this.isSubmitting = false;
      window.location.reload();
      this.cdr.detectChanges();
    }, 1000);
  }
}
