<ng-container *ngIf="operationalStatus?.data" [ngSwitch]="operationalStatus?.data">
  <span *ngSwitchCase="operationalStatuses.OPERATIONAL" [attr.data-test-id]="operationalStatuses.OPERATIONAL">
    <odx-icon name="feedback-positive"></odx-icon>
    {{ 'CREATE_UPDATE_EQUIPMENT.STR_OPERATIONAL_STATUSES_OPERATIONAL' | translate }}
  </span>
  <span *ngSwitchCase="operationalStatuses.NON_OPERATIONAL" [attr.data-test-id]="operationalStatuses.NON_OPERATIONAL">
    <odx-icon name="error"></odx-icon>
    {{ 'CREATE_UPDATE_EQUIPMENT.STR_NON_OPERATIONAL_STATUSES' | translate }}
  </span>
  <span
    *ngSwitchCase="operationalStatuses.DEFECTIVE_BUT_OPERATIONAL"
    [attr.data-test-id]="operationalStatuses.DEFECTIVE_BUT_OPERATIONAL"
  >
    <odx-icon name="warning"></odx-icon>
    {{ 'CREATE_UPDATE_EQUIPMENT.STR_DEFECTIVE_BUT_OPERATIONAL_STATUSES' | translate }}
  </span>
</ng-container>
