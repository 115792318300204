import { IAlarmLists } from 'src/app/root/models/alarms.model';
import { IStoreApiList, StoreApiList } from '../../../../common/models/store-api-list.model';

export interface IAlarmsState {
  alarms: IStoreApiList<IAlarmLists>;
}

export class AlarmsState implements IAlarmsState {
  alarms: IStoreApiList<IAlarmLists> = new StoreApiList();
}
