import { PropertyBag } from 'src/app/common';

export const tableCustomFiltersLayout: string[] = [
  'identifier',
  'barcode',
  'rfid',
  'serialNo',
  'partNo',
  'partName',
  'typeId',
  'manufacturer',
  'model',
  'description',
  'operationalStatus',
  'serviceStatus',
  'location',
  'owner',
  'outOfService',
  'firstRegisteredDate',
];

export const readModelSortKeys: PropertyBag = {
  identifier: 'generalData.identifier',
  barcode: 'generalData.barcode',
  rfid: 'generalData.rfid',
  serialNo: 'generalData.serialNo',
  partNo: 'generalData.partNo',
  partName: 'generalData.partName',
  typeId: 'equipmentCategory.type.name',
  manufacturer: 'manufacturerData.organizationName',
  model: 'equipmentCategory.type.model.name',
  description: 'generalData.description',
  operationalStatus: 'generalData.operationalStatus',
  serviceStatus: 'serviceData.serviceStatus',
  location: 'locationAssignment.name',
  owner: 'generalData.owner',
  outOfService: 'generalData.outOfOrderDate',
  firstRegisteredDate: 'generalData.firstRegisteredDate',
};

export const tableDefaultSorting: string = 'generalData.identifier,ASC';
