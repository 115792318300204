import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { StorageMap } from '@ngx-pwa/local-storage';
import { provideFormFieldConfig } from '@odx/angular/components/form-field';
import { ModalRef, ModalService, ModalSize, ModalVariant } from '@odx/angular/components/modal';
import { combineLatest, filter, map, Observable, of, take, takeUntil } from 'rxjs';
import { IOrganizationLicenseInfo } from 'src/app/configuration/models/organization-settings.model';
import { OrganizationSettingsActions } from 'src/app/configuration/state/actions';
import { selectOrganizationLicenseInfo } from 'src/app/configuration/state/selectors/configuration.selectors';
import { DeviceConnectionActions } from 'src/app/device-connection/state/actions/device-connection.actions';
import { AccessControlService } from 'src/app/root/services';
import { LicensesActions } from 'src/app/root/state/licenses/actions/licenses.actions';
import { selectTicketID } from 'src/app/root/state/licenses/selectors/licenses.selector';
import { UserNotificationConstants } from 'src/app/user-management/constants';
import { EquipmentLicenseInfoActions } from 'src/app/workshop/state/actions/equipment-license-info';
import { IDeviceConnectionLicenseInfo } from './../../../device-connection/models/device-connection.model';
import { selectDeviceConnectionLicenseInfo } from './../../../device-connection/state/selectors/device-connection.selector';
import { IEquipmentLicenseInfo } from '../../../workshop/models/equipment-license-info.model';
import { selectEquipmentLicenseInfo } from '../../../workshop/state/selectors/equipment.selector';
import { CommonConstants } from '../../constants/common.constants';
import { NotificationConstants } from '../../constants/notification.constants';
import { OnDestroyMixin } from '../../mixins/destroy-mixin';
import { IModalState, INotificationConstant } from '../../models';
import { IStoreApiItem } from '../../models/store-api-item.model';
import { IStoreApiList } from '../../models/store-api-list.model';
import { CheckingSettingsModalsService } from '../../services/checking-settings-modals/checking-settings-modals.service';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { IApplicationState } from '../../state/models/app.state.model';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';

@Component({
  selector: 'ignis-licenses-modal',
  templateUrl: './licenses-modal.component.html',
  styleUrls: ['./licenses-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    provideFormFieldConfig({
      errors: {
        required: () => {
          return of();
        },
      },
    }),
  ],
})
export class LicensesModalComponent extends OnDestroyMixin() implements OnInit, OnChanges, OnDestroy {
  @Input() isOpen: boolean = false;
  @Output() closeLicensesModal: EventEmitter<void> = new EventEmitter<void>();
  modalReference: ModalRef<any>;

  @ViewChild('licensesModal', { read: TemplateRef })
  public licensesModal: TemplateRef<any>;

  isLoading: Observable<boolean>;
  organizationLicenseInfo: IOrganizationLicenseInfo[];
  equipmentLicenseInfo: IEquipmentLicenseInfo;
  deviceConnectionLicenseInfo: IDeviceConnectionLicenseInfo;
  routeAfterBrowserBackBtnIsPressed: string;

  licenseForm: FormGroup;
  httpErrorResponse: string = null;
  notificationConstants: INotificationConstant = UserNotificationConstants.notificationCodes;

  equipmentLicenseInfoActions: EquipmentLicenseInfoActions = inject(EquipmentLicenseInfoActions);
  organizationSettingsActions: OrganizationSettingsActions = inject(OrganizationSettingsActions);
  deviceConnectionActions: DeviceConnectionActions = inject(DeviceConnectionActions);
  notificationsService: NotificationsService = inject(NotificationsService);
  accessControlService: AccessControlService = inject(AccessControlService);
  licenseActions: LicensesActions = inject(LicensesActions);
  checkingSettingsModalsService: CheckingSettingsModalsService = inject(CheckingSettingsModalsService);
  store: Store<IApplicationState> = inject<Store<IApplicationState>>(Store<IApplicationState>);
  modalService: ModalService = inject(ModalService);
  router: Router = inject(Router);
  storage: StorageMap = inject(StorageMap);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  constructor() {
    super();
  }

  @HostListener(CommonConstants.popStateWindowEvent, ['$event'])
  onPopState(): void {
    this.checkingSettingsModalsService.remainOnTheSamePageIfTheModalIsOpened(
      this.isOpen,
      this.routeAfterBrowserBackBtnIsPressed,
    );
  }

  @HostListener(CommonConstants.beforeUnloadWindowEvent, ['$event'])
  handleBeforeUnload(): void {
    localStorage.setItem(
      CommonConstants.modalIsOpened,
      JSON.stringify({ modalName: CommonConstants.modalsName.LICENSE, open: false }),
    );
    this.storage
      .set(CommonConstants.modalIsOpened, { modalName: CommonConstants.modalsName.LICENSE, open: false })
      .subscribe(() => {
        return;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const modalIsOpened: IModalState = JSON.parse(localStorage.getItem(CommonConstants.modalIsOpened)) as IModalState;

    if (
      (changes.isOpen && this.isOpen) ||
      (modalIsOpened?.modalName === CommonConstants.modalsName.LICENSE && modalIsOpened?.open)
    ) {
      setTimeout(() => {
        this.openLicenseModal();

        this.routeAfterBrowserBackBtnIsPressed = this.router.url;
      }, 0);
    }
  }

  ngOnInit(): void {
    this.licenseForm = new FormGroup({
      ticketNumber: new FormControl('', [Validators.required]),
    });

    this.readLicensesDate();
    this.readLoadingState();
    this.requestLicensesData();
    this.readEquipmentAndDeviceConnectionLicensesDate();
  }

  openLicenseModal(): void {
    this.modalReference = this.modalService.open(this.licensesModal, {
      size: ModalSize.MEDIUM,
      variant: ModalVariant.DEFAULT,
      dismissable: false,
    });
  }

  requestLicensesData(): void {
    this.organizationSettingsActions.requestOrganizationLicenseInfo();
  }

  readLoadingState(): void {
    this.isLoading = combineLatest([
      this.store.select(selectOrganizationLicenseInfo),
      this.store.select(selectEquipmentLicenseInfo),
      this.store.select(selectDeviceConnectionLicenseInfo),
    ]).pipe(
      map(
        ([organizationLicenseInfo, equipmentLicenseInfo, deviceConnectionLicenseInfo]: [
          IStoreApiList<IOrganizationLicenseInfo[]>,
          IStoreApiItem<IEquipmentLicenseInfo>,
          IStoreApiItem<IDeviceConnectionLicenseInfo>,
        ]) =>
          organizationLicenseInfo.isLoading || equipmentLicenseInfo.isLoading || deviceConnectionLicenseInfo.isLoading,
      ),
      takeUntil(this.destroy),
    );
  }

  readLicensesDate(): void {
    this.store
      .pipe(
        select(selectOrganizationLicenseInfo),
        filter((state: IStoreApiList<IOrganizationLicenseInfo[]>) => {
          return !state.isLoading;
        }),
        takeUntil(this.destroy),
      )
      .subscribe((organizationLicenseInfo: IStoreApiList<IOrganizationLicenseInfo[]>) => {
        if (Array.isArray(organizationLicenseInfo.data)) {
          this.organizationLicenseInfo = organizationLicenseInfo.data.map((licenseInfo: IOrganizationLicenseInfo) => {
            return {
              ...licenseInfo,
              productName: CommonConstants.productNames[licenseInfo.productName]
                ? CommonConstants.productNames[licenseInfo.productName]
                : licenseInfo.productName,
              label: CommonConstants.productLabels[licenseInfo.productName]
                ? CommonConstants.productLabels[licenseInfo.productName]
                : licenseInfo.productName,
            };
          });

          this.handleLicenseToggles();
        }

        this.cdr.detectChanges();
      });
  }

  handleLicenseToggles(): void {
    if (this.accessControlService.workshopFeatureToggle) {
      this.equipmentLicenseInfoActions.requestEquipmentLicenseInfo();
    }

    this.deviceConnectionActions.requestDeviceConnectionLicenseInfo();
  }

  readEquipmentAndDeviceConnectionLicensesDate(): void {
    combineLatest([this.store.select(selectEquipmentLicenseInfo), this.store.select(selectDeviceConnectionLicenseInfo)])
      .pipe(
        filter(
          ([equipmentLicenseInfo, deviceConnectionLicenseInfo]: [
            IStoreApiItem<IEquipmentLicenseInfo>,
            IStoreApiItem<IDeviceConnectionLicenseInfo>,
          ]) => !equipmentLicenseInfo.isLoading && !deviceConnectionLicenseInfo.isLoading,
        ),
        takeUntil(this.destroy),
      )
      .subscribe(
        ([equipmentLicenseInfo, deviceConnectionLicenseInfo]: [
          IStoreApiItem<IEquipmentLicenseInfo>,
          IStoreApiItem<IDeviceConnectionLicenseInfo>,
        ]) => {
          if (equipmentLicenseInfo.data || deviceConnectionLicenseInfo.data) {
            this.deviceConnectionLicenseInfo = deviceConnectionLicenseInfo.data;
            this.equipmentLicenseInfo = equipmentLicenseInfo.data;

            return;
          }

          if (equipmentLicenseInfo.errors || deviceConnectionLicenseInfo.errors) {
            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.ERROR,
              NotificationConstants.commonCodes.UNEXPECTED_ERROR,
              NotificationConstants.commonCodes,
            );
          }

          this.cdr.detectChanges();
        },
      );
  }

  handleCloseModal(): void {
    this.closeLicensesModal.emit();
    this.modalReference?.close('');
  }

  onTicketChange(): void {
    this.licenseForm.get('ticketNumber').setErrors({ invalid: false });
    this.httpErrorResponse = null;
  }

  onSubmit(): void {
    this.isLoading = of(true);
    this.licenseActions.requestActivateTicketID(this.licenseForm.getRawValue());
    this.readTicketIdStatus();
  }

  readTicketIdStatus(): void {
    this.store
      .pipe(
        select(selectTicketID),
        filter((state: IStoreApiItem<any>) => {
          return !state.isLoading;
        }),
        take(1),
      )
      .subscribe((response: IStoreApiItem<any>) => {
        if (response.errors) {
          this.httpErrorResponse = response.errors.error?.code.toString();

          this.notificationsService.requestShowNotification(
            this.checkNotificationType(),
            this.httpErrorResponse,
            this.notificationConstants,
          );

          this.isLoading = of(false);
        } else {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            this.notificationConstants.TICKET_SAVED_SUCCESSFULLY,
            this.notificationConstants,
          );

          localStorage.setItem(CommonConstants.refreshAppAfterTicketSaved, CommonConstants.refreshAppAfterTicketSaved);
          this.reloadApp();
        }

        this.cdr.detectChanges();
      });
  }

  reloadApp(): void {
    window.location.reload();
  }

  checkNotificationType(): string {
    if (
      this.httpErrorResponse === this.notificationConstants.LICENSE_IS_INVALID.value ||
      this.httpErrorResponse === this.notificationConstants.LICENSE_ALREADY_USED.value
    ) {
      this.licenseForm.get('ticketNumber').setErrors({ invalid: true });

      return CommonConstants.notificationType.HIDDEN;
    }

    return CommonConstants.notificationType.ERROR;
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    this.organizationSettingsActions.resetOrganizationLicenseInfo();
    this.licenseActions.resetActivateTicketID();

    if (this.isOpen) {
      this.handleCloseModal();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();
  }
}
