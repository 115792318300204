import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { AppUserPermissionList } from 'src/app/root/models/app-types';
import { AccessControlService } from 'src/app/root/services/access-control/access-control.service';
import { WorkshopModuleRoutes } from 'src/app/workshop/constants';

@Injectable()
export class WorkshopWorkflowModuleGuard extends OnDestroyMixin() {
  constructor(
    private accessControlService: AccessControlService,
    private router: Router,
  ) {
    super();
  }

  canActivate(): boolean {
    if (this.accessControlService.permissions) {
      if (!this.accessControlService.canActivateModule(AppUserPermissionList.equipmentTest)) {
        this.router.navigate([WorkshopModuleRoutes.workshop]);

        return false;
      } else {
        return true;
      }
    }
  }
}
