import { IStoreApiItem, StoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IStoreApiList, StoreApiList } from 'src/app/common/models/store-api-list.model';
import { IEntryModel } from 'src/app/root/models/alarms.model';
import { IEventMarker } from '../../models/incident.model';

export interface IEventMarkerState {
  eventMarkersEntries: IStoreApiList<IEntryModel[]>;
  selectedEventMarker: IStoreApiItem<IEventMarker>;
  deletedEventMarker: IStoreApiItem<IEventMarker>;
}

export class EventMarkerState implements IEventMarkerState {
  eventMarkersEntries: IStoreApiList<IEntryModel[]> = new StoreApiList();
  selectedEventMarker: IStoreApiItem<IEventMarker> = new StoreApiItem();
  deletedEventMarker: IStoreApiItem<IEventMarker> = new StoreApiItem();
}
