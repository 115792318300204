import { DOCUMENT } from '@angular/common';
import { Component, Inject, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TreeNode } from 'primeng/api';
import { TreeNodeSelectEvent } from 'primeng/tree';
import { combineLatest, filter, map, Observable, takeUntil } from 'rxjs';
import { CommonConstants, IApplicationState, IStoreApiItem, IStoreApiList } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { PropertyBag } from 'src/app/common/models/common.model';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { ConfigurationNotificationConstants } from '../../constants';
import { ConfigurationConstants } from '../../constants/configuration.constants';
import { CustomTreeNode, IEquipmentHierarchy } from '../../models/equipment-configuration.models';
import { EquipmentHierarchyActions, TasksActions } from '../../state/actions';

import {
  selectDeletedEquipmentHierarchy,
  selectEquipmentHierarchy,
  selectImportEquipmentHierarchy,
} from './../../state/selectors/configuration.selectors';

@Component({
  selector: 'ignis-equipment-hierarchy',
  templateUrl: './equipment-hierarchy.component.html',
  styleUrls: ['./equipment-hierarchy.component.scss'],
})
export class EquipmentHierarchyComponent extends OnDestroyMixin() implements OnInit {
  hierarchyData: IEquipmentHierarchy[] | any;
  isLoading: Observable<boolean>;
  selectedTreeNode: any;
  disableAddBtn: boolean = false;
  disableEditBtnAndDelete: boolean = true;
  openConfirmationDeleteDialog: boolean = false;
  openImportEQHierarchyModal: boolean = false;
  activateClickOutside: boolean = true;
  deletedType: string;
  equipmentConfigType: string;
  configType: PropertyBag = ConfigurationConstants.EquipmentConfigType;
  deleteUrl: string;
  errors: any;
  currentAppTheme: string;

  displayImportBtn: boolean = true;
  displayTypeLabel: boolean = false;
  displayModelLabel: boolean = false;

  store: Store<IApplicationState> = inject(Store<IApplicationState>);
  tasksActions: TasksActions = inject(TasksActions);
  equipmentHierarchyActions: EquipmentHierarchyActions = inject(EquipmentHierarchyActions);
  router: Router = inject(Router);
  translateService: TranslateService = inject(TranslateService);
  notificationsService: NotificationsService = inject(NotificationsService);

  constructor(@Inject(DOCUMENT) private readonly document: Document) {
    super();
    this.currentAppTheme = this.document.body.className.split(' ')[1];
    this.hierarchyData = [
      {
        label: this.translateService.instant('CONFIGURATION.STR_EQUIPMENT_HIERARCHY'),
        data: null,
        styleClass: 'hiddenNode',
        children: [],
      },
    ];
  }

  ngOnInit(): void {
    this.equipmentHierarchyActions.requestEquipmentHierarchy();

    this.isLoading = combineLatest([
      this.store.select(selectEquipmentHierarchy),
      this.store.select(selectImportEquipmentHierarchy),
    ]).pipe(
      takeUntil(this.destroy),
      map(
        ([hierarchyState, importHierarchyState]: [IStoreApiList<IEquipmentHierarchy[]>, IStoreApiItem<unknown>]) =>
          hierarchyState.isLoading || importHierarchyState.isLoading,
      ),
    );

    this.store
      .pipe(
        select(selectEquipmentHierarchy),
        filter((state: IStoreApiList<IEquipmentHierarchy[]>) => !state.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((response: IStoreApiList<IEquipmentHierarchy[]>) => {
        if (response.data) {
          this.hierarchyData[0].children = structuredClone(response.data);

          this.hierarchyData.filter((parentNode: any) => {
            parentNode.children.some((childNode: any) => {
              if (
                ConfigurationConstants.categoryId in localStorage &&
                childNode.data.aggregateId === localStorage.getItem(ConfigurationConstants.categoryId)
              ) {
                childNode.expanded = true;
                this.toggleTreeHeader();
              }

              childNode.children.some((nephewNode: any) => {
                if (
                  ConfigurationConstants.typeId in localStorage &&
                  nephewNode.data.typeId === localStorage.getItem(ConfigurationConstants.typeId) &&
                  nephewNode.children?.length > 0
                ) {
                  nephewNode.expanded = true;
                  this.toggleTreeHeader();
                }
              });
            });
          });

          localStorage.removeItem(ConfigurationConstants.categoryId);
          localStorage.removeItem(ConfigurationConstants.typeId);
          localStorage.removeItem(ConfigurationConstants.modelId);
          localStorage.removeItem(ConfigurationConstants.configurationVersion);

          if (this.hierarchyData[0].children.length > 0) {
            this.hierarchyData[0].expanded = true;
            this.displayImportBtn = false;
          } else {
            this.selectedTreeNode = this.hierarchyData[0];
            this.displayImportBtn = true;
          }
        }
      });
  }

  setParentReferences(nodes: any[], parent: any = null): void {
    nodes.forEach((node) => {
      node.parent = parent;

      if (node.children) {
        this.setParentReferences(node.children, node);
      }
    });
  }

  nodeSelect(event: TreeNodeSelectEvent): void {
    this.setParentReferences(this.hierarchyData);

    this.selectedTreeNode = event.node;
    this.disableAddBtn = false;
    this.disableEditBtnAndDelete = false;

    localStorage.removeItem('isCylinderType');

    this.clearHighlights();
    this.markParentNodes(event.node);

    if (event.node.children?.length > 0 && !event.node.expanded) {
      event.node.expanded = !event.node.expanded;
    }

    if (!this.selectedTreeNode.data) {
      this.disableEditBtnAndDelete = true;
    }

    if (this.selectedTreeNode?.parent?.parent?.parent?.data === null && this.selectedTreeNode.children?.length < 1) {
      this.disableAddBtn = true;
    }

    if (!this.selectedTreeNode.parent?.data) {
      this.deletedType = this.translateService.instant('CONFIGURATION.STR_CATEGORY');
      this.equipmentConfigType = ConfigurationConstants.EquipmentConfigType.CATEGORY;
      this.deleteUrl = `categories/${this.selectedTreeNode.data?.aggregateId}`;
    } else if (!this.selectedTreeNode.parent?.parent.data) {
      localStorage.setItem('isCylinderType', this.selectedTreeNode.data.isCylinderType);
      this.deletedType = this.translateService.instant('CONFIGURATION.STR_TYPE');
      this.equipmentConfigType = ConfigurationConstants.EquipmentConfigType.TYPE;
      this.deleteUrl = `categories/${this.selectedTreeNode.parent.data.aggregateId}/types/${this.selectedTreeNode.data.typeId}`;
    } else if (!this.selectedTreeNode.parent?.parent.parent.data) {
      this.deletedType = this.translateService.instant('CONFIGURATION.STR_MODEL');
      this.equipmentConfigType = ConfigurationConstants.EquipmentConfigType.MODEL;
      this.deleteUrl = `categories/${this.selectedTreeNode.parent.parent.data.aggregateId}/types/${this.selectedTreeNode.parent.data.typeId}/models/${this.selectedTreeNode.data.modelId}`;
    }
  }

  nodeUnselect(event: TreeNodeSelectEvent): void {
    if (event.node.children?.length > 0) {
      event.node.expanded = !event.node.expanded;
    }
  }

  markParentNodes(node: TreeNode): void {
    let currentNode: CustomTreeNode<undefined> = node.parent;

    while (currentNode) {
      currentNode.highlighted = true;
      currentNode = currentNode.parent;
    }
  }

  clearHighlights(): void {
    const traverseNodes = (nodes: TreeNode[]) => {
      nodes?.forEach((node: CustomTreeNode<undefined>) => {
        node.highlighted = false;

        if (node.children) {
          traverseNodes(node.children);
        }
      });
    };

    traverseNodes(this.hierarchyData);
  }

  toggleTreeHeader(): void {
    setTimeout(() => {
      const treeNodeElement: Element = document.getElementsByTagName('p-treenode')[0];
      const typeNodeWidth: number = 250;
      const modelNodeWidth: number = 550;

      this.displayTypeLabel = (treeNodeElement as HTMLElement)?.offsetWidth > typeNodeWidth;
      this.displayModelLabel = (treeNodeElement as HTMLElement)?.offsetWidth > modelNodeWidth;
    }, 0);
  }

  removeSelectedNode(): void {
    if (this.activateClickOutside) {
      this.selectedTreeNode = null;
      this.disableAddBtn = false;
      this.clearHighlights();
    }
  }

  displayFiteredData(event: IEquipmentHierarchy[]): void {
    this.hierarchyData = event;
    this.toggleTreeHeader();

    this.displayImportBtn = false;
  }

  createNewConfiguration(): void {
    if (!this.selectedTreeNode?.data) {
      this.router.navigate(['configuration', 'create']);
    } else if (!this.selectedTreeNode.parent?.data) {
      this.router.navigate(['configuration', 'create', this.selectedTreeNode.data.aggregateId, 'types']);
    } else if (!this.selectedTreeNode.parent?.parent?.data) {
      this.router.navigate([
        'configuration',
        'create',
        this.selectedTreeNode.parent.data.aggregateId,
        'types',
        this.selectedTreeNode.data.typeId,
        'models',
      ]);
    }
  }

  editConfiguration(): void {
    if (!this.selectedTreeNode.parent?.data) {
      this.router.navigate(['configuration', 'update', 'categories', this.selectedTreeNode.data.aggregateId]);
    } else if (!this.selectedTreeNode.parent?.parent?.data) {
      this.router.navigate([
        'configuration',
        'update',
        'categories',
        this.selectedTreeNode.parent.data.aggregateId,
        'types',
        this.selectedTreeNode.data.typeId,
      ]);
    } else if (!this.selectedTreeNode.parent?.parent?.parent?.data) {
      this.router.navigate([
        'configuration',
        'update',
        'categories',
        this.selectedTreeNode.parent.parent.data.aggregateId,
        'types',
        this.selectedTreeNode.parent.data.typeId,
        'models',
        this.selectedTreeNode.data.modelId,
      ]);
    }
  }

  openDeleteEquipmentHierarchyDialog(): void {
    this.openConfirmationDeleteDialog = true;
    this.activateClickOutside = false;
  }

  closeDeleteEquipmentHierarchyDialog(confirmation: boolean): void {
    if (confirmation) {
      this.deleteConfiguration();
    } else {
      this.openConfirmationDeleteDialog = false;
      this.activateClickOutside = true;
    }
  }

  deleteConfiguration(): void {
    this.equipmentHierarchyActions.requestDeleteEquipmentHierarchy({
      urlPath: this.deleteUrl,
      version: this.selectedTreeNode.data.version,
    });

    this.store
      .pipe(
        select(selectDeletedEquipmentHierarchy),
        filter((state: IStoreApiItem<any>) => !state.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((response: IStoreApiItem<any>) => {
        if (response.isSuccess) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            ConfigurationNotificationConstants.notificationCodes.DELETE_HIERARCHY_SUCCESS,
            ConfigurationNotificationConstants.notificationCodes,
            {
              type: this.deletedType,
            },
          );

          this.ngOnInit();
          this.toggleTreeHeader();
        } else {
          this.errors = response.errors.error?.code.toString();

          if (this.errors) {
            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.ERROR,
              this.errors,
              ConfigurationNotificationConstants.notificationCodes,
            );
          }
        }
      });
  }

  openImportEquipmentHierarchyModal(): void {
    this.openImportEQHierarchyModal = true;
  }

  closeImportEquipmentHierarchyModal(event: boolean): void {
    this.openImportEQHierarchyModal = event;
  }
}
