import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { ModalRef, ModalService, ModalSize, ModalVariant } from '@odx/angular/components/modal';
import { CameraDevice, Html5Qrcode, Html5QrcodeResult, Html5QrcodeScannerState } from 'html5-qrcode';
import { Subscription, takeUntil, timer } from 'rxjs';
import { OnDestroyMixin } from 'src/app/common/mixins';

@Component({
  selector: 'ignis-barcode-scanner-modal',
  templateUrl: './barcode-scanner-modal.component.html',
  styleUrl: './barcode-scanner-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarcodeScannerModalComponent extends OnDestroyMixin() implements OnChanges {
  @Input() isOpen: boolean = false;
  isDisplayHintVisible: boolean = false;
  isDisplayBarcodeInputVisible: boolean = false;
  isDisplayPermissionMessageVisible: boolean = false;
  timerSubscription: Subscription | undefined;

  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() scannedBarcode: EventEmitter<string> = new EventEmitter<string>();
  modalReference: ModalRef<any>;

  @ViewChild('barcodeScannerModal', { read: TemplateRef })
  public barcodeScannerModal: TemplateRef<any>;

  html5QrcodeScanner: Html5Qrcode = null;
  cameraId: string;
  code: Html5QrcodeResult;
  barcode: string;

  modalService: ModalService = inject(ModalService);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpen && this.isOpen) {
      this.openBarcodeScannerModal();
      this.openScanner();
    }
  }

  openBarcodeScannerModal(): void {
    this.modalReference = this.modalService.open(this.barcodeScannerModal, {
      size: ModalSize.MEDIUM,
      variant: ModalVariant.DEFAULT,
      dismissable: false,
    });

    this.modalReference.onClose$.pipe(takeUntil(this.destroy)).subscribe((closeModalState: boolean) => {
      this.isDisplayHintVisible = false;
      this.isDisplayBarcodeInputVisible = false;
      this.timerSubscription?.unsubscribe();
      this.stopTheScanner();

      this.closeModal.emit(closeModalState);
      this.destroy.next();
    });
  }

  stopTheScanner(): void {
    if (this.html5QrcodeScanner && this.html5QrcodeScanner.getState() !== Html5QrcodeScannerState.NOT_STARTED) {
      this.html5QrcodeScanner?.stop();
    }
  }

  openScanner(): void {
    Html5Qrcode.getCameras()
      .then((devices: CameraDevice[]) => {
        if (devices) {
          this.cameraId = devices[0].id;
          this.isDisplayPermissionMessageVisible = false;

          this.html5QrcodeScanner = new Html5Qrcode('qr-reader', {
            verbose: false,
          });

          this.startTimerToDisplayTheHint();

          this.html5QrcodeScanner
            .start(
              this.cameraId,
              {
                fps: 60,
                qrbox: { width: 460, height: 340 },
              },
              (decodedText: string, decodedResult: Html5QrcodeResult) => {
                this.emitAndCloseTheModal(decodedResult);
              },
              (errorMessage: string) => {
                return errorMessage;
              },
            )
            .catch((err: Error) => {
              return err;
            });
        }

        this.cdr.detectChanges();
      })
      .catch(() => {
        this.isDisplayPermissionMessageVisible = true;
        this.cdr.detectChanges();
      });
  }

  emitAndCloseTheModal(result: Html5QrcodeResult): void {
    this.code = result;

    if (this.code.decodedText) {
      this.scannedBarcode.emit(this.code.decodedText);
      this.modalReference?.close('');
      this.closeModal.emit(false);
    }
  }

  startTimerToDisplayTheHint(): void {
    const sixtySeconds: number = 60000;

    this.timerSubscription = timer(sixtySeconds).subscribe(() => {
      this.isDisplayHintVisible = true;
      this.cdr.detectChanges();
    });
  }

  swithToEnterManual(): void {
    this.stopTheScanner();

    this.isDisplayBarcodeInputVisible = true;
    this.isDisplayHintVisible = false;
  }

  emitEnteredBarcode(): void {
    this.scannedBarcode.emit(this.barcode);
    this.modalReference?.close('');
    this.barcode = null;
  }
}
