import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { InlineMessageComponent } from '@odx/angular/components/inline-message';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { TimelineModule } from 'primeng/timeline';
import { TooltipModule } from 'primeng/tooltip';
import { TreeSelectModule } from 'primeng/treeselect';
import { SimplebarAngularModule } from 'simplebar-angular';
import { TasksColumnComponent, TasksComponent } from '..';
import { BarcodeScannerModalComponent } from '../components/barcode-scanner-modal/barcode-scanner-modal.component';
import { AdditionalDetailsComponent } from '../components/create-update-equipment/additional-details/additional-details.component';
import { AssignmentsComponent } from '../components/create-update-equipment/assignments/assignments.component';
import { ConfirmChangeCategoryTypeIdsComponent } from '../components/create-update-equipment/confirm-change-category-type-ids/confirm-change-category-type-ids.component';
import { CreateUpdateEquipmentViewFooterComponent } from '../components/create-update-equipment/create-update-equipment-view-footer/create-update-equipment-view-footer.component';
import { CreateUpdateEquipmentComponent } from '../components/create-update-equipment/create-update-equipment.component';
import { EqTasksComponent } from '../components/create-update-equipment/eq-tasks/eq-tasks.component';
import { GeneralComponent } from '../components/create-update-equipment/general/general.component';
import { ManufacturerComponent } from '../components/create-update-equipment/manufacturer/manufacturer.component';
import { EquipmentHistoryViewFooterComponent } from '../components/equipment-history/equipment-history-view-footer/equipment-history-view-footer.component';
import { EquipmentHistoryComponent } from '../components/equipment-history/equipment-history.component';
import { TimelineEventComponent } from '../components/equipment-history/timeline-event/timeline-event.component';
import { TimelineFilterComponent } from '../components/equipment-history/timeline-filter/timeline-filter.component';
import { InventoryViewFooterComponent } from '../components/inventory/inventory-view-footer/inventory-view-footer.component';
import { InventoryComponent } from '../components/inventory/inventory.component';
import { OperationalStatusColumnComponent } from '../components/inventory/operational-status-column/operational-status-column.component';
import { ServiceStatusColumnComponent } from '../components/inventory/service-status-column/service-status-column.component';
import { RamConnectorAutoUpdateModalComponent } from '../components/ram-connector-auto-update-modal/ram-connector-auto-update-modal.component';
import { FunctionalStatusColumnComponent } from '../components/task-documentation/task-documentation-list/functional-status-column/functional-status-column.component';
import { TaskDocumentationListViewFooterComponent } from '../components/task-documentation/task-documentation-list/task-documentation-list-view-footer/task-documentation-list-view-footer.component';
import { TaskDocumentationListComponent } from '../components/task-documentation/task-documentation-list/task-documentation-list.component';
import { TaskDocumentationComponent } from '../components/task-documentation/task-documentation.component';
import { DocumentationEquipmentInformationComponent } from '../components/task-documentation/task-result/general-tab/documentation-equipment-information/documentation-equipment-information.component';
import { GeneralTabComponent } from '../components/task-documentation/task-result/general-tab/general-tab.component';
import { ServiceDetailsAutomaticTestComponent } from '../components/task-documentation/task-result/general-tab/service-details-automatic-test/service-details-automatic-test.component';
import { ServiceDetailsChecklistComponent } from '../components/task-documentation/task-result/general-tab/service-details-checklist/service-details-checklist.component';
import { ServiceDetailsCylinderComponent } from '../components/task-documentation/task-result/general-tab/service-details-cylinder/service-details-cylinder.component';
import { ServiceDetailsComponent } from '../components/task-documentation/task-result/general-tab/service-details/service-details.component';
import { ResultsPrintComponent } from '../components/task-documentation/task-result/results-print/results-print.component';
import { ServiceResultExportComponent } from '../components/task-documentation/task-result/task-result-view-footer/service-result-export/service-result-export.component';
import { TaskResultViewViewFooterComponent } from '../components/task-documentation/task-result/task-result-view-footer/task-result-view-footer.component';
import { TaskResultComponent } from '../components/task-documentation/task-result/task-result.component';
import { AutomaticTaskValuesComponent } from '../components/task-documentation/task-result/values-tab/automatic-test-values/automatic-test-values.component';
import { ImageCheckerComponent } from '../components/task-documentation/task-result/values-tab/manual-test-values/image-checker/image-checker.component';
import { ManualTaskValuesComponent } from '../components/task-documentation/task-result/values-tab/manual-test-values/manual-test-values.component';
import { ValuesTabComponent } from '../components/task-documentation/task-result/values-tab/values-tab.component';
import { ChecklistBooleanTypeComponent } from '../components/tasks/complete-batch-checklist/checklist-item/checklist-boolean-type/checklist-boolean-type.component';
import { ChecklistImageTypeComponent } from '../components/tasks/complete-batch-checklist/checklist-item/checklist-image-type/checklist-image-type.component';
import { ChecklistMultipleSelectionComponent } from '../components/tasks/complete-batch-checklist/checklist-item/checklist-multiple-selection/checklist-multiple-selection.component';
import { ChecklistNumericTypeComponent } from '../components/tasks/complete-batch-checklist/checklist-item/checklist-numeric-type/checklist-numeric-type.component';
import { ChecklistTextTypeComponent } from '../components/tasks/complete-batch-checklist/checklist-item/checklist-text-type/checklist-text-type.component';
import { CompleteBatchChecklistComponent } from '../components/tasks/complete-batch-checklist/complete-batch-checklist.component';
import { ServicesTablesComponent } from '../components/tasks/complete-batch-checklist/services-tables/services-tables.component';
import { CompleteChecklistComponent } from '../components/tasks/complete-checklist/complete-checklist.component';
import { NextServiceColumnComponent } from '../components/tasks/tasks-list/next-service-column/next-service-column.component';
import { ChecklistWorkflowServiceTasksFooterViewComponent } from '../components/tasks/tasks-list/tasks-list-view-footer/checklist-workflow-service-tasks-footer-view/checklist-workflow-service-tasks-footer-view.component';
import { TasksListViewFooterComponent } from '../components/tasks/tasks-list/tasks-list-view-footer/tasks-list-view-footer.component';
import { TasksListComponent } from '../components/tasks/tasks-list/tasks-list.component';
import { TestEquipmentViewFooterComponent } from '../components/test-equipment/test-equipment-view-footer/test-equipment-view-footer.component';
import { TestEquipmentComponent } from '../components/test-equipment/test-equipment.component';
import { RedirectAfterTestingComponent } from '../components/test-equipment/testing/redirect-after-testing/redirect-after-testing.component';
import { TestingComponent } from '../components/test-equipment/testing/testing.component';
import { ConfirmStartCylinderChargeComponent } from '../components/workflow/confirm-start-cylinder-charge/confirm-start-cylinder-charge.component';
import { CylinderChargerComponent } from '../components/workflow/cylinder-charger/cylinder-charger.component';
import { DirectlyAssignLocationComponent } from '../components/workflow/directly-assign-location/directly-assign-location.component';
import { SearchBarcodeComponent } from '../components/workflow/search-barcode/search-barcode.component';
import { SearchMobileComponent } from '../components/workflow/search-mobile/search-mobile.component';
import { SelectedWorkflowComponent } from '../components/workflow/selected-workflow/selected-workflow.component';
import { WorkflowButtonComponent } from '../components/workflow/selected-workflow/workflow-button/workflow-button.component';
import { WarningWorkflowTableItemsComponent } from '../components/workflow/warning-workflow-table-items/warning-workflow-table-items.component';
import { ChecklistWorkflowFooterViewComponent } from '../components/workflow/workflow-view-footer/checklist-workflow-footer-view/checklist-workflow-footer-view.component';
import { WorkflowViewFooterComponent } from '../components/workflow/workflow-view-footer/workflow-view-footer.component';
import { WorkflowComponent } from '../components/workflow/workflow.component';
import { WorkshopComponent } from '../components/workshop/workshop.component';
import { OutOfServiceFieldInteractionService } from '../services/out-of-service-field-interaction.service';
import { RAMConnectorErrorsService } from '../services/ram-connector-errors.service';
import { RestoreServiceTaskReasonService } from '../services/restore-service-task-reason.service';
import { WorkshopService } from '../services/workshop.service';
import { EquipmentCRUDEffects } from '../state/effects/equipment-crud/equipment-crud.effects';
import { EquipmentHistoryEffects } from '../state/effects/equipment-history/equipment-history.effects';
import { EquipmentInitialSpecificationEffects } from '../state/effects/equipment-initial-specs/equipment-initial-specs.effects';
import { EquipmentLicenseInfoEffects } from '../state/effects/equipment-license-info/equipment-license-info.effects';
import { EquipmentServiceTaskEffects } from '../state/effects/equipment-service-task/equipment-service-task.effects';
import { EquipmentTestResultsEffects } from '../state/effects/equipment-test-results/equipment-test-results.effects';
import { EquipmentTestEffects } from '../state/effects/equipment-test/equipment-test.effects';
import { EquipmentUpdateIdsEffects } from '../state/effects/equipment-update-ids/equipment-update-ids.effects';
import { EquipmentRAMEffects } from '../state/effects/ram';
import { equipmentReducer } from '../state/reducers/equipment.reducer';
import { WorkshopRoutingModule } from './workshop-routing.module';

import {
  CommonModule,
  CustomDigitDecimalNumberDirective,
  ExportTableExcelComponent,
  ReplaceDigitCharPipe,
} from 'src/app/common';

@NgModule({
  declarations: [
    AssignmentsComponent,
    AdditionalDetailsComponent,
    AutomaticTaskValuesComponent,
    ChecklistBooleanTypeComponent,
    ChecklistImageTypeComponent,
    ChecklistMultipleSelectionComponent,
    ChecklistNumericTypeComponent,
    ChecklistTextTypeComponent,
    CompleteBatchChecklistComponent,
    CompleteChecklistComponent,
    ConfirmChangeCategoryTypeIdsComponent,
    ConfirmStartCylinderChargeComponent,
    CreateUpdateEquipmentComponent,
    CreateUpdateEquipmentViewFooterComponent,
    CylinderChargerComponent,
    ServiceDetailsChecklistComponent,
    ServiceDetailsCylinderComponent,
    DirectlyAssignLocationComponent,
    WorkshopComponent,
    EquipmentHistoryComponent,
    EquipmentHistoryViewFooterComponent,
    InventoryComponent,
    InventoryViewFooterComponent,
    FunctionalStatusColumnComponent,
    GeneralComponent,
    GeneralTabComponent,
    ImageCheckerComponent,
    ManualTaskValuesComponent,
    ManufacturerComponent,
    NextServiceColumnComponent,
    OperationalStatusColumnComponent,
    RedirectAfterTestingComponent,
    ServiceDetailsAutomaticTestComponent,
    DocumentationEquipmentInformationComponent,
    SearchBarcodeComponent,
    WorkflowComponent,
    SearchMobileComponent,
    WorkflowViewFooterComponent,
    ChecklistWorkflowFooterViewComponent,
    ResultsPrintComponent,
    SelectedWorkflowComponent,
    ServiceDetailsComponent,
    ServiceResultExportComponent,
    TaskDocumentationComponent,
    TaskResultComponent,
    TaskResultViewViewFooterComponent,
    TaskDocumentationListComponent,
    TasksColumnComponent,
    TaskDocumentationListViewFooterComponent,
    ServiceStatusColumnComponent,
    EqTasksComponent,
    TasksListComponent,
    TasksListViewFooterComponent,
    ChecklistWorkflowServiceTasksFooterViewComponent,
    TasksComponent,
    ServicesTablesComponent,
    TestEquipmentComponent,
    TestEquipmentViewFooterComponent,
    TestingComponent,
    TimelineEventComponent,
    TimelineFilterComponent,
    ValuesTabComponent,
    WorkflowButtonComponent,
    WarningWorkflowTableItemsComponent,
    RamConnectorAutoUpdateModalComponent,
    BarcodeScannerModalComponent,
  ],
  imports: [
    FormsModule,
    WorkshopRoutingModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    StoreModule.forFeature('equipment', equipmentReducer),
    EffectsModule.forFeature([
      EquipmentCRUDEffects,
      EquipmentHistoryEffects,
      EquipmentInitialSpecificationEffects,
      EquipmentLicenseInfoEffects,
      EquipmentServiceTaskEffects,
      EquipmentTestEffects,
      EquipmentTestResultsEffects,
      EquipmentUpdateIdsEffects,
      EquipmentRAMEffects,
    ]),
    CommonModule,
    NgSelectModule,
    DropdownModule,
    TreeSelectModule,
    TimelineModule,
    SimplebarAngularModule,
    TableModule,
    ExportTableExcelComponent,
    ReplaceDigitCharPipe,
    CustomDigitDecimalNumberDirective,
    InlineMessageComponent,
    MultiSelectModule,
    TooltipModule,
  ],
  providers: [
    WorkshopService,
    RAMConnectorErrorsService,
    OutOfServiceFieldInteractionService,
    RestoreServiceTaskReasonService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class WorkshopModule {}
