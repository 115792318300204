import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { HttpHelper } from 'src/app/common';
import { environment } from 'src/environments/environment';
import { IOrganizationAccessControl, IOrganizationEntries, IOrganizationLicenseInfo } from '../models/organization-settings.model';

@Injectable({
  providedIn: 'root'
})
export class OrganizationSettingsService {

  private baseUrl: string = environment.API_URLS.ORGANIZATION;

  constructor(private httpClient: HttpClient, private httpHelper: HttpHelper) { }

  public getOrganizationEntries(): Observable<IOrganizationEntries | HttpErrorResponse> {
    return this.httpClient.get<IOrganizationEntries>(`${this.baseUrl}/organizations/profile/values`, this.httpHelper.options);
  }

  public getOrganizationAccessControl(): Observable<HttpResponse<IOrganizationAccessControl> | HttpErrorResponse> {
    return this.httpClient.get<IOrganizationAccessControl>(`${this.baseUrl}/organizations`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      observe: 'response',
    }).pipe(
      tap((response: HttpResponse<IOrganizationAccessControl>) => {
        /* istanbul ignore next */
        if (response.body) {
          response.body.version = Number(response.headers?.get('etag'));
        }
      })
    );
  }

  public updateOrganizationAccessControl(orgAccessControl: IOrganizationAccessControl | any): Observable<IOrganizationAccessControl | HttpErrorResponse> {
    const organizationAccessControlData: IOrganizationAccessControl = { ...orgAccessControl.payload };
    const version: number = organizationAccessControlData.version;

    delete organizationAccessControlData.version;

    return this.httpClient.put<IOrganizationAccessControl>(`${this.baseUrl}/organizations`, organizationAccessControlData, this.httpHelper.optionsWithIfMatch(version));
  }

  public getOrganizationLicenseInfo(): Observable<IOrganizationLicenseInfo[] | HttpErrorResponse> {
    return this.httpClient.get<IOrganizationLicenseInfo[]>(`${this.baseUrl}/organizations/license-info`, this.httpHelper.options);
  }
}
