import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IEquipmentLicenseInfo } from '../../../models/equipment-license-info.model';
import { EquipmentLicenseInfoActionTypes } from './equipment-license-info-action-types';

export class RequestEquipmentLicenseInfo implements Action {
  readonly type: EquipmentLicenseInfoActionTypes.REQUEST_EQUIPMENT_LICENSE_INFO =
    EquipmentLicenseInfoActionTypes.REQUEST_EQUIPMENT_LICENSE_INFO;
}

export class ReceiveEquipmentLicenseInfo implements Action {
  readonly type: EquipmentLicenseInfoActionTypes.RECEIVE_EQUIPMENT_LICENSE_INFO =
    EquipmentLicenseInfoActionTypes.RECEIVE_EQUIPMENT_LICENSE_INFO;

  constructor(public payload: IEquipmentLicenseInfo) {}
}

export class ErrorReceiveEquipmentLicenseInfo implements Action {
  readonly type: EquipmentLicenseInfoActionTypes.ERROR_RECEIVE_EQUIPMENT_LICENSE_INFO =
    EquipmentLicenseInfoActionTypes.ERROR_RECEIVE_EQUIPMENT_LICENSE_INFO;

  constructor(public payload: HttpErrorResponse) {}
}
