import { GenericAction } from 'src/app/common';
import { errorItemData, receiveItemData, requestItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { EquipmentLicenseInfoActionTypes } from '../../actions/equipment-license-info';
import { EquipmentLicenseInfoState, IEquipmentLicenseInfoState } from '../../models/license-info';

export function equipmentLicenseInfoReducer(
  lastState: IEquipmentLicenseInfoState = new EquipmentLicenseInfoState(),
  action: GenericAction<EquipmentLicenseInfoActionTypes, any>,
): IEquipmentLicenseInfoState {
  switch (action.type) {
    case EquipmentLicenseInfoActionTypes.REQUEST_EQUIPMENT_LICENSE_INFO:
      return requestItemData<IEquipmentLicenseInfoState, IEquipmentLicenseInfoState>(lastState, ['licenseInfo']);
    case EquipmentLicenseInfoActionTypes.RECEIVE_EQUIPMENT_LICENSE_INFO:
      return receiveItemData<IEquipmentLicenseInfoState, IEquipmentLicenseInfoState>(
        lastState,
        ['licenseInfo'],
        action.payload,
      );
    case EquipmentLicenseInfoActionTypes.ERROR_RECEIVE_EQUIPMENT_LICENSE_INFO:
      return errorItemData<IEquipmentLicenseInfoState, IEquipmentLicenseInfoState>(
        lastState,
        ['licenseInfo'],
        action.payload,
      );

    default:
      return lastState;
  }
}
