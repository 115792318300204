<ignis-progress-bar *ngIf="isLoading$ | async"></ignis-progress-bar>
<ignis-table
  #ignisTableComponent
  [tableName]="tableName"
  [localColumns]="localTableColumns"
  [datePickerFormat]="formatDate"
  [datePickerSelectionMode]="'range'"
  [readOnlyDatePicker]="false"
  [isDatePickerShowingTime]="false"
  [dataTable]="list"
  [resizableColumns]="true"
  [reorderableColumns]="true"
  [displayFilters]="true"
  [activeDate]="selectedDates"
  [selectActiveOptions]="selectedDropdowns"
  [isDefaultRangeShown]="true"
  [customFilterLayout]="customFilterLayouts"
  [customColumnsLayout]="['operationalStatus', 'nextServiceDate', 'rfid', 'serialNo']"
  [customColumnsRef]="[operationalStatus, nextServiceDate, rfid, serialNo]"
  [lazy]="'true'"
  [isLoading]="isLoading$ | async"
  [savedFiltersName]="tableFiltersKey"
  [sortColumn]="sortColumn"
  [paginator]="'true'"
  [first]="first"
  [pageNumber]="pageNumber"
  [totalRecords]="totalRecords"
  [multiSelect]="true"
  (columns)="getTableColumns($event)"
  [preselectedElements]="selectedTasks"
  (selectedElementsChange)="onTaskServiceSelect($event)"
  (handleRowSelection)="onTaskServiceSelect($event)"
  (handleDoubleClickRowSelection)="openSelectedTaskOnDblClick($event)"
  (handleOnLazyLoading)="tableHelperOnLazyLoading($event)"
  (handleOnSortChange)="tableHelperOnChangeSort($event)"
  (handleTableRef)="tableHelperGetTableRef($event)"
  (handleColumnVisibility)="changeColumnVisibility($event)"
  (handleFilterValue)="getFilterTableValue($event)"
  (handleOnPageChange)="tableHelperOnPageChange($event)"
>
  <ng-template #operationalStatus let-operationalStatus>
    <div *ngIf="operationalStatus.field === 'operationalStatus'">
      <ignis-operational-status-column [operationalStatus]="operationalStatus"></ignis-operational-status-column>
    </div>
  </ng-template>

  <ng-template #rfid let-rfid>
    <div *ngIf="rfid.field === 'rfid' && rfid.data">
      <ignis-table-ellipsis-data-column
        [columnData]="rfid"
        class="ellipsis-data-column"
      ></ignis-table-ellipsis-data-column>
    </div>
  </ng-template>

  <ng-template #nextServiceDate let-nextServiceDate>
    <div *ngIf="nextServiceDate.field === 'nextServiceDate'">
      <ignis-next-service-column
        [nextServiceDate]="nextServiceDate"
        [formatDate]="formatDate"
      ></ignis-next-service-column>
    </div>
  </ng-template>

  <ng-template #serialNo let-serialNo>
    <div *ngIf="serialNo.field === 'serialNo' && serialNo.data">
      <ignis-table-ellipsis-data-column
        [columnData]="serialNo"
        class="ellipsis-data-column"
      ></ignis-table-ellipsis-data-column>
    </div>
  </ng-template>
</ignis-table>

<ignis-export-table-excel
  [excelArray]="excelArray"
  [formatDate]="formatDate"
  [sheetName]="'EXCEL_TABLE_EXPORT.SERVICE_TASKS' | translate"
  [fileName]="'EXCEL_TABLE_EXPORT.SERVICE_TASKS' | translate"
  [tableColumns]="tableColumns"
  [excelTranslationKeys]="excelTranslationKeys"
  (handleExcelExportFinished)="tableHelperExcelExportDone()"
></ignis-export-table-excel>

<footer class="blue-bar">
  <ignis-tasks-list-view-footer
    [selectedItem]="selectedTasks[0]"
    [selectedItems]="selectedTasks"
    [activatePlayBtn]="activatePlayBtn"
    [selectedTasksAreNotTheSame]="selectedTasksAreNotTheSame"
    [numberOfRecordsToBeExported]="totalRecords"
    [isSubmitDisabled]="isSubmitting | async"
    (handleExcelExport)="exportExcelFile()"
    (handleNavigateToEquipment)="editEquipment(selectedTasks[0])"
    (handleExecuteChecklistOrTest)="startChecklistOrTestEquipment($event)"
  ></ignis-tasks-list-view-footer>
</footer>

<ignis-ram-connector-auto-update-modal
  [ramConnectorStatus]="ramConnectorStatus"
  [startAutoUpdate]="startAutoUpdate"
  [type]="notificationType.ERROR"
  (handleConfirmationModal)="startRAMConnectorAutoUpdateFromServiceTasksList($event)"
  (handleAutoUpdateStartsWithSuccess)="startAutoUpdate = false"
>
  <div slot="content">
    {{ 'PROTECTOR_SOFTWARE_NOTIFICATION.STR_PROTECTOR_SOFTWARE_VERSION_NOT_SUPPORTED' | translate }}
  </div>
</ignis-ram-connector-auto-update-modal>
