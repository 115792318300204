<div class="blue-bar-items">
  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    id="create-address-book-btn"
    [disabled]="!selectedItem || isModalOpened"
    data-test-id="configuration.create_address_book_btn"
    [pTooltip]="addAddressBookButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #addAddressBookButtonTooltipContent>
      <div data-test-id="configuration.add_new_address_book_tooltip">
        {{ 'CONFIGURATION.STR_ADD_NEW_ADDRESS_BOOK_TOOLTIP' | translate }}
      </div>
    </ng-template>

    <odx-icon size="inline" name="add"></odx-icon>
  </button>

  <button
    type="button"
    [disabled]="!selectedItem || isModalOpened || isSubmitDisabled"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="edit-address-book-item"
    id="edit-address-book-btn"
    data-test-id="configuration.edit_address_book_btn"
    [pTooltip]="editAddressBookButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #editAddressBookButtonTooltipContent>
      <div data-test-id="configuration.edit_address_book_tooltip">
        {{ 'CONFIGURATION.STR_EDIT_ADDRESS_BOOK_TOOLTIP' | translate }}
      </div>
    </ng-template>

    <odx-icon size="inline" name="edit"></odx-icon>
  </button>

  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="delete-address-book-item"
    [disabled]="!selectedItem || isModalOpened"
    id="delete-address-book-btn"
    data-test-id="configuration.delete_address_book_btn"
    [pTooltip]="deleteAddressBookButtonTooltipContent"
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #deleteAddressBookButtonTooltipContent>
      <div data-test-id="configuration.delete_address_book_tooltip">
        {{ 'CONFIGURATION.STR_DELETE_ADDRESS_BOOK_TOOLTIP' | translate }}
      </div>
    </ng-template>
    <odx-icon name="delete"></odx-icon>
  </button>

  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="edit-or-save-address-book-item"
    [disabled]="!form?.valid || !form?.dirty || isModalOpened || isSubmitDisabled"
    (click)="onSubmit($event)"
    id="submit-address-book-btn"
    data-test-id="configuration.save_address_book_btn"
    [pTooltip]="addressBookSaveButtonTooltipContent"
    tooltipPosition="top"
    positionTop="-10"
  >
    <ng-template #addressBookSaveButtonTooltipContent>
      @if (form?.controls?.email.invalid) {
        <div data-test-id="configuration.address_invalid_form_footer_btn_tooltip">
          {{ 'CONFIGURATION.STR_ADDRESS_INVALID_FORM_FOOTER_BTN_TOOLTIP' | translate }}
        </div>
      } @else if (form?.controls?.organizationName.invalid) {
        <div data-test-id="configuration.address_organization_footer_btn_tooltip">
          {{ 'CONFIGURATION.STR_ADDRESS_ORGANIZATION_FOOTER_BTN_TOOLTIP' | translate }}
        </div>
      } @else if (!form?.controls?.email.invalid && !form?.controls?.organizationName.invalid) {
        <div data-test-id="configuration.save_tooltip">
          {{ 'CONFIGURATION.STR_SAVE_TOOLTIP' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon size="inline" name="save"></odx-icon>
  </button>
</div>
