import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RequestAccessControlItems } from './access-control-items-action-types-creators';
import { IAccessControlItemsState } from '../models/access-control-items.model';

@Injectable({ providedIn: 'root' })
export class AccessControlItemsActions {
  constructor(private store: Store<IAccessControlItemsState>) { }

  public requestAccessControlItems(): void {
    this.store.dispatch(new RequestAccessControlItems());
  }
}
