import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from 'redux';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import {
  ErrorReceiveTableExport,
  ReceiveTableExport,
} from 'src/app/common/state/export/actions/export-action-types-creators';
import { WorkshopService } from 'src/app/workshop/services';
import * as models from '../../../models';
import * as actions from '../../actions/equipment-test-results';

@Injectable()
export class EquipmentTestResultsEffects {
  workshopService: WorkshopService = inject(WorkshopService);

  requestTestResultsById$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentTestResultsActionTypes.REQUEST_TEST_RESULTS_BY_ID),
      switchMap((identifier: { payload: string }) =>
        this.workshopService.getTestResultsById(identifier.payload).pipe(
          map(
            (testResults: HttpResponse<models.ITestResultDetails>) =>
              new actions.ReceiveTestResultsById(new models.TestResultDetails(testResults?.body)),
          ),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveTestResultsById(error))),
        ),
      ),
    );
  });

  requestTestResultsPage$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentTestResultsActionTypes.REQUEST_TEST_RESULTS_PAGE),
      switchMap((params: any) =>
        this.workshopService.getTestResultsPage(params.payload).pipe(
          map(
            (testResultsPage: models.ITestResultPage) =>
              new actions.ReceiveTestResultPage(new models.TestResultPage(testResultsPage)),
          ),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveTestResultPage(error))),
        ),
      ),
    );
  });

  requestTestResultsExport$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentTestResultsActionTypes.REQUEST_TEST_RESULTS_EXPORT),
      switchMap((params: any) =>
        this.workshopService.getTestResultsPage(params.payload).pipe(
          map(
            (testResultsPage: models.ITestResultPage) =>
              new ReceiveTableExport(new models.TestResultPage(testResultsPage)),
          ),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveTableExport(error))),
        ),
      ),
    );
  });
}
