<ng-template odxModal #addUserModal>
  <odx-modal-header>
    <odx-area-header>
      {{
        (router.url?.includes(UserManagementModuleRoutes.createUser)
          ? 'USER_MANAGEMENT.STR_ADD_USER_TITLE'
          : 'USER_MANAGEMENT.STR_EDIT_USER_TITLE'
        ) | translate
      }}
    </odx-area-header>
  </odx-modal-header>
  <odx-modal-content [ngStyle]="{ overflow: 'visible' }">
    <div *ngIf="isLoading; else formContent" class="loading-centered">
      <div [odxLoadingSpinner]="true" [odxLoadingSpinnerMinHeight]="60" class="app-loader"></div>
    </div>
    <ng-template #formContent>
      <form
        odxForm
        [formGroup]="addUserForm"
        (ngSubmit)="onSubmit()"
        data-test-id="user_management.create_update_user_modal"
        class="create-update-user"
      >
        <p class="mb-3" *ngIf="router.url?.includes(UserManagementModuleRoutes.createUser)">
          {{ 'USER_MANAGEMENT.STR_ADD_USER_INFO' | translate }}
        </p>
        <odx-form-field
          variant="full"
          label="{{ 'USER_MANAGEMENT.STR_ADD_USER_EMAIL_INPUT' | translate }}"
          [readonly]="!router.url?.includes(UserManagementModuleRoutes.createUser)"
          class="input-with-icon"
        >
          <input
            type="email"
            id="email"
            data-test-id="user_management.add_user_email_input"
            formControlName="email"
            odxFormFieldControl
            placeholder="{{ 'USER_MANAGEMENT.STR_ADD_USER_EMAIL_INPUT_PLACEHOLDER' | translate }}"
            (change)="emailChanged()"
            (keyup)="httpCustomErrorCode === emailAlreadyExist ? (httpCustomErrorCode = null) : ''"
          />
          <ng-template #emailAddressAlreadyExistTooltipContent>
            <div id="email-error-tooltip" data-test-id="user_management.add_user_email_input_ERROR_TOOLTIP">
              {{ 'USER_NOTIFICATION.STR_EMAIL_ALREADY_EXISTS' | translate }}
            </div>
          </ng-template>

          <odx-icon
            name="info"
            class="custom-validation-icon"
            iconSet="core"
            (mouseenter)="showEmailTooltip = true"
            (mouseleave)="showEmailTooltip = false"
            *ngIf="!httpCustomErrorCode && addUserForm.controls.email.invalid && addUserForm.controls.email.touched"
            [odxTooltip]="'USER_MANAGEMENT.STR_ADD_USER_EMAIL_INPUT_TOOLTIP' | translate"
            [odxTooltipDisabled]="!addUserForm.controls.email.invalid"
            data-test-id="user_management.add_user_email_input_TOOLTIP"
          >
          </odx-icon>
          <odx-icon
            name="info"
            class="custom-validation-icon"
            iconSet="core"
            *ngIf="
              httpCustomErrorCode === emailAlreadyExist &&
              addUserForm.controls.email.invalid &&
              addUserForm.controls.email.value?.length > 0
            "
            [odxTooltipOptions]="{ matchReferenceWidth: true }"
            [odxTooltip]="emailAddressAlreadyExistTooltipContent"
            [odxTooltipDisabled]="httpCustomErrorCode !== emailAlreadyExist"
            [odxTooltipVisible]="true"
          ></odx-icon>
          <odx-icon
            name="check"
            class="custom-validation-icon"
            iconSet="core"
            *ngIf="addUserForm.controls.email.valid && addUserForm.controls.email.dirty"
          ></odx-icon>
        </odx-form-field>

        <div class="odx-form-field mb-3">
          <label class="odx-form-field__label" for="dd1">
            <odx-form-field-label class="odx-form-field-label">
              {{ 'USER_MANAGEMENT.STR_USER_ROLE' | translate }}
            </odx-form-field-label>
          </label>

          <p-dropdown
            #dd1
            (onShow)="dropdownService.openDropdown(dd1)"
            (onHide)="dropdownService.closeDropdown(dd1)"
            [ngModel]="selectedUserRole"
            (ngModelChange)="selectedUserRole = $event"
            ngDefaultControl
            formControlName="role"
            data-test-id="user_management.user_role"
            [dropdownIcon]="dropdownIconCSSClass"
            [options]="roles"
            [placeholder]="'USER_MANAGEMENT.STR_USER_ROLE_PLACEHOLDER' | translate"
            [showClear]="false"
            (onChange)="changeUserRole($event)"
          >
            <ng-template let-option pTemplate="item">
              <span>{{ option.label }}</span>
            </ng-template>
          </p-dropdown>
        </div>
        <div
          class="odx-form-field mb-3"
          *ngIf="restrictToLocationFeatureToggle && selectedUserRole === appUserRole.tester"
        >
          <label class="odx-form-field__label" for="dd2">
            <odx-form-field-label class="odx-form-field-label">
              {{ 'USER_MANAGEMENT.STR_RESTRICTED_TO_LOCATION' | translate }}
            </odx-form-field-label>
          </label>

          <p-treeSelect
            #dd2
            [options]="locations"
            formControlName="location"
            filter="true"
            [ngModel]="selectedRestrictedUserLocation"
            (ngModelChange)="selectedRestrictedUserLocation = $event"
            [filterPlaceholder]="'CONFIGURATION.STR_SEARCH_LOCATION' | translate"
            [emptyMessage]="'CONFIGURATION.STR_NO_RECORDS_FOUND' | translate"
            name="location"
            data-test-id="user_management.restricted_to_location"
            [dropdownIcon]="dropdownIconCSSClass"
            [placeholder]="'USER_MANAGEMENT.STR_RESTRICTED_TO_LOCATION_PLACEHOLDER' | translate"
            [virtualScroll]="true"
            [virtualScrollItemSize]="46"
            [virtualScrollOptions]="{ scrollHeight: '250px' }"
            (onNodeSelect)="setSelectedLocation($event)"
          >
            <ng-template let-selectedItem pTemplate="value">
              <div class="select-tree-selectedItem" *ngIf="selectedItem; else placeholder">
                <odx-icon
                  [name]="selectedItem?.icon"
                  class="p-treenode-icon"
                  [ngClass]="{ 'hidden-icon': selectedItem?.icon === 'hidden-icon' }"
                ></odx-icon>
                <span class="long-tree-label">{{ selectedItem?.label }}</span>
              </div>
              <ng-template #placeholder>
                <span>{{ 'USER_MANAGEMENT.STR_RESTRICTED_TO_LOCATION_PLACEHOLDER' | translate }}</span>
              </ng-template>
            </ng-template>
            <ng-template let-node pTemplate="default">
              <div class="d-flex">
                <odx-icon
                  [name]="node.icon"
                  class="p-treenode-icon"
                  [ngClass]="{ 'hidden-icon': node.icon === 'hidden-icon' }"
                ></odx-icon>
                <span class="p-treenode-label long-tree-label">{{ node.label }}</span>
              </div>
            </ng-template>
          </p-treeSelect>
        </div>
      </form>
    </ng-template>
  </odx-modal-content>
  <odx-modal-footer>
    <button odxButton odxModalClose (click)="closeModal()" variant="secondary" data-test-id="modal_actions.cancel_btn">
      {{ 'USER_MANAGEMENT.STR_ADD_USER_CANCEL_BTN' | translate }}
    </button>

    <button
      odxButton
      variant="primary"
      (click)="onSubmit()"
      [disabled]="!addUserForm.dirty || addUserForm.invalid || (isSubmitting | async)"
      type="button"
      data-test-id="modal_actions.save_btn"
    >
      {{ 'USER_MANAGEMENT.STR_ADD_USER_SAVE_BTN' | translate }}
    </button>
  </odx-modal-footer>
</ng-template>
