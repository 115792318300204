import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ignis-ram-settings-section',
  templateUrl: './ram-settings-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RamSettingsSectionComponent {
  @Input() organizationSettingsForm: FormGroup;
  @Input() hasAutomaticTestsAccess: boolean;
}
