import { GenericAction } from 'src/app/common';
import { FeaturesToggleActionType } from './../actions/features-toggle-action-types';
import { FeaturesToggleState, IFeaturesToggleState } from './../models/features-toggle.model';

export function featuresToggleReducer(
  lastState: IFeaturesToggleState = new FeaturesToggleState(),
  action: GenericAction<FeaturesToggleActionType, any>
): IFeaturesToggleState {

  if (action.type === FeaturesToggleActionType.ADD_FEATURES_TOGGLE) {
    return { featuresToggleList: { isLoading: false, data: action.payload, errors: null } };
  } else {
    return lastState;
  }
}
