<div
  class="row"
  class="alarm-firefighter-row"
  [ngClass]="{
    'firefighter-row': !firefighter?.fireFighterAlarms.length && !firefighter?.equipmentModel,
    'firefighter-row-icon': !firefighter?.fireFighterAlarms.length && firefighter?.equipmentModel,
    'alarm-firefighter-row': firefighter?.fireFighterAlarms.length,
  }"
>
  <div class="col-12 mt-3">
    <div
      class="row"
      [ngClass]="{
        'name-row': firefighter?.fireFighterAlarms.length,
        'name-row-no-alarm': !firefighter?.fireFighterAlarms.length,
      }"
    >
      <div class="col-4 custom-col-4 text-start">
        <span
          class="firefighter-name pt-2"
          [ngClass]="{ 'firefighter-name-highlighted': getFirefighterAlarms(firefighter) === 1 }"
          ><span
            class="me-2"
            *ngIf="firefighter?.teamLead"
            [attr.data-test-id]="'team_firefighter.team_lead-' + firefighter?.name"
            >&#9733;</span
          >{{ firefighter?.name }}</span
        >
      </div>
      <div class="col-7 custom-col-6">
        <div
          class="alarm-duration-container"
          [class]="currentAppTheme"
          *ngIf="longestAlarm"
          data-test-id="remote_monitoring.longest_alarm"
        >
          <ignis-alarm-icon
            [currentAppTheme]="currentAppTheme"
            [alarmTypeValue]="longestAlarm.type.value"
            [usage]="'team-firefighter'"
          ></ignis-alarm-icon>
          <span
            [ngClass]="{
              'duration-label-yellow': getFirefighterAlarms(firefighter) === 1,
              'duration-label-red': getFirefighterAlarms(firefighter) === 0,
            }"
          >
            {{ timer || '00:00:00' }}
          </span>
        </div>
      </div>
      <div class="col-1 custom-col-2">
        <span class="model-icon" [ngSwitch]="firefighter?.equipmentModel">
          <odx-icon
            size="inline"
            name="scba"
            *ngSwitchCase="'airboss'"
            [pTooltip]="'REMOTE_MONITORING.STR_AIRBOSS_EQUIPMENT' | translate"
            tooltipPosition="left"
            tooltipStyleClass="left-tooltip"
            attr.data-test-id="team_firefighter.equipment_model_airboss"
          ></odx-icon>
          <odx-icon
            size="inline"
            name="ldba"
            *ngSwitchCase="'proair'"
            [pTooltip]="'REMOTE_MONITORING.STR_PROAIR_EQUIPMENT' | translate"
            tooltipPosition="left"
            tooltipStyleClass="left-tooltip"
            attr.data-test-id="team_firefighter.equipment_model_proair"
          ></odx-icon>
          <odx-icon
            size="inline"
            name="telemetry-disabled"
            *ngSwitchCase="'stand_alone'"
            [pTooltip]="'REMOTE_MONITORING.STR_STAND_ALONE_EQUIPMENT' | translate"
            tooltipPosition="left"
            tooltipStyleClass="left-tooltip"
            attr.data-test-id="team_firefighter.equipment_model_stand_alone"
          >
          </odx-icon>
        </span>
      </div>
    </div>
    <div
      class="row firefighter-details"
      [ngClass]="{
        'firefighter-alarm-details': firefighter?.fireFighterAlarms.length,
        'firefighter-details-no-alarm': !firefighter?.fireFighterAlarms.length,
      }"
    >
      <div
        class="unit-count unit-border pe-2"
        [pTooltip]="defaultTimeTooltip"
        tooltipPosition="right"
        tooltipStyleClass="right-tooltip"
      >
        <span
          class="unit-number"
          [ngClass]="{ 'unit-number-highlighted': getFirefighterAlarms(firefighter) === 1 }"
          data-test-id="team_firefighter.default_time_display"
        >
          {{ firefighter.defaultTimeDisplay }}
        </span>
        <span
          class="unit-name"
          [ngClass]="{ 'unit-name-highlighted': getFirefighterAlarms(firefighter) === 1 }"
          data-test-id="team_firefighter.unit_name"
        >
          {{ defaultTimeLabel }}
        </span>
      </div>
      <div class="unit-count ms-2">
        <span
          class="unit-number"
          [ngClass]="{ 'unit-number-highlighted': getFirefighterAlarms(firefighter) === 1 }"
          data-test-id="team_firefighter.unit_count"
        >
          {{ firefighter.currentPressure | pressureUnit: [pressureDisplayUnit, false] || 0 }}
        </span>
        <span
          class="unit-name"
          [ngClass]="{ 'unit-name-highlighted': getFirefighterAlarms(firefighter) === 1 }"
          data-test-id="team_firefighter.pressure_display_unit"
        >
          {{ pressureDisplayUnit | uppercase }}
        </span>
      </div>
      <div class="bar-unit-progress">
        <div
          class="full-progress"
          [ngClass]="{
            'high-pressure': firefighter.currentPressure > mediumPressureValue,
            'medium-pressure':
              firefighter.currentPressure <= mediumPressureValue && firefighter.currentPressure > lowPressureValue,
            'low-pressure': firefighter.currentPressure <= lowPressureValue,
          }"
          [style.width.px]="getFullProgressWidth(firefighter)"
        ></div>
        <div class="empty-progress" [style.width.px]="getEmptyProgressWidth(firefighter)"></div>
      </div>
    </div>
  </div>
</div>
