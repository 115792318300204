import { IStoreApiItem, StoreApiItem } from 'src/app/common';
import { IEntriesModel, IIncident, IIncidentPage } from '../../models/incident.model';

export interface IIncidentDataState {
  incidentPage: IStoreApiItem<IIncidentPage>;
  selectedIncident: IStoreApiItem<IIncident>;
  entries: IStoreApiItem<IEntriesModel>;
}

export class IncidentDataState implements IIncidentDataState {
  incidentPage: IStoreApiItem<IIncidentPage> = new StoreApiItem();
  selectedIncident: IStoreApiItem<IIncident> = new StoreApiItem();
  entries: IStoreApiItem<IEntriesModel> = new StoreApiItem();
}
