import { Injectable } from '@angular/core';
import { ConfigurationConstants } from 'src/app/configuration/constants/configuration.constants';
import { ILocationHierarchy } from 'src/app/configuration/models/location-configuration.model';
import { CommonConstants } from '../../constants';
import { IEntryModel } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  locationIconMapping: { types: IEntryModel[] };

  constructor() {
    this.locationIconMapping = ConfigurationConstants.locationTypes.types.reduce((acc: any, { value, icon }: any) => {
      acc[value] = icon;

      return acc;
    }, {});
  }

  public openDropdown(dropdownRef: any): string {
    dropdownRef.dropdownIcon = CommonConstants.openDropdownIconCSSClass;

    return dropdownRef.dropdownIcon;
  }

  public closeDropdown(dropdownRef: any): string {
    dropdownRef.dropdownIcon = CommonConstants.defaultDropdownIconCSSClass;

    return dropdownRef.dropdownIcon;
  }

  public findTreeNodeAndAddIcon(array: ILocationHierarchy[]): void {
    for (const node of array) {
      node.key = node.data?.aggregateId;
      node.icon = `${this.locationIconMapping[node.data?.type]}`;

      if (node.children) {
        this.findTreeNodeAndAddIcon(node.children);
      }
    }
  }

  public changeModalOverflowToAccommodateTheDropdown(modalId: string, modalContentId: string): void {
    setTimeout(() => {
      const elemToModifyOverflow: HTMLElement = document.querySelector(`#${modalId} > .odx-modal__container`);
      const modalContentElem: HTMLElement = document.getElementById(modalContentId);

      if (
        modalContentElem?.offsetHeight < elemToModifyOverflow?.offsetHeight ||
        modalContentElem?.offsetHeight > elemToModifyOverflow?.offsetHeight
      ) {
        elemToModifyOverflow.style.overflow = 'auto';
      }

      if (elemToModifyOverflow?.scrollHeight <= elemToModifyOverflow?.clientHeight) {
        elemToModifyOverflow.style.overflow = 'visible';
      }
    }, 250);
  }
}
