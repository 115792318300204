import { ITableColumn } from 'src/app/common';

export const tableColumns: ITableColumn[] = [
  { field: 'date', header: 'LOGBOOK_TABLE.STR_DATE_TIME', visible: true },
  { field: 'equipmentId', header: 'LOGBOOK_TABLE.STR_SCBA_ID', visible: true },
  { field: 'startPressure', header: 'LOGBOOK_TABLE.STR_START_CYLINDER_PRESSURE', visible: true },
  { field: 'endPressure', header: 'LOGBOOK_TABLE.STR_END_CYLINDER_PRESSURE', visible: true },
  { field: 'reason', header: 'LOGBOOK_TABLE.STR_EVENT', visible: true },
  { field: 'incidentNumber', header: 'LOGBOOK_TABLE.STR_INCIDENT_NUMBER', visible: true },
  { field: 'usageDuration', header: 'LOGBOOK_TABLE.STR_USAGE_DURATION', visible: true },
  { field: 'name', header: 'LOGBOOK_TABLE.STR_USERNAME', visible: true },
  { field: 'personalId', header: 'LOGBOOK_TABLE.STR_PERSONAL_ID', visible: true },
  { field: 'status', header: 'LOGBOOK_TABLE.STR_STATUS', visible: true },
];
