<div class="license_container">
  <img src="/assets/img/logo.svg" alt="logo" />

  <odx-card variant="default" class="license-box">
    <odx-card-content>
      <ignis-progress-bar *ngIf="isSubmitting" class="license-validation-progress-bar"></ignis-progress-bar>

      @if (!isLicenseValid) {
        <div class="license_form__container container">
          <form [formGroup]="licenseForm" class="form-group m-auto py-xl-5 py-3">
            <div class="license-texts">
              <p class="licenses">{{ 'LICENSE.STR_LICENSES' | translate }}</p>
              <p class="licenses-text-second">{{ 'LICENSE.STR_VALID_TICKET_ID' | translate }}</p>
            </div>
            <div class="input-container">
              <odx-form-field variant="full" [ngCLass]="{ 'has-error': httpCustomErrorCode }">
                <input
                  type="text"
                  ignisDisableAutocomplete
                  odxFormFieldControl
                  class="textfield"
                  id="ticket-number"
                  data-test-id="license.ticket_nmber"
                  formControlName="ticketNumber"
                  placeholder="{{ 'LICENSE.STR_LICENSE_INPUT_PLACEHOLDER' | translate }}"
                  (keyup)="httpCustomErrorCode = null"
                />
              </odx-form-field>

              <span class="textfield--text-error" [ngSwitch]="httpCustomErrorCode">
                <odx-inline-message
                  *ngSwitchCase="userNotificationsConstants.LICENSE_IS_INVALID.value"
                  variant="danger"
                >
                  {{ 'LICENSE.STR_INVALID_LICENSE' | translate }}
                </odx-inline-message>
                <odx-inline-message
                  *ngSwitchCase="userNotificationsConstants.LICENSE_ALREADY_USED.value"
                  variant="danger"
                >
                  {{ 'LICENSE.STR_USED_LICENSE' | translate }}
                </odx-inline-message>
                <odx-inline-message *ngSwitchCase="userNotificationsConstants.LICENSE_EXPIRED.value" variant="danger">
                  {{ 'LICENSE.STR_EXPIRED_LICENSE' | translate }}
                </odx-inline-message>
              </span>

              <button
                id="validate-license"
                data-test-id="license.check_license"
                odxButton
                variant="highlight"
                (click)="checkLicense()"
                [disabled]="licenseForm.invalid || isSubmitting"
              >
                {{ 'LICENSE.STR_OK' | translate }}
              </button>
            </div>
          </form>
        </div>
      } @else {
        <div class="license_valid m-auto py-xl-5 py-3">
          <div class="activated-successfully">
            <p class="created-successfully">{{ 'LICENSE.STR_CREATION_SUCCESSFUL' | translate }}</p>
            <div class="my-3">
              <odx-icon name="user-id-ready" size="xlarge"></odx-icon>
            </div>
            <div class="steps">
              <div class="step">
                <odx-icon name="login"></odx-icon>
                <p class="step-right">{{ 'LICENSE.STR_ADD_USERS' | translate }}</p>
              </div>
              <div class="step">
                <odx-icon name="unlock"></odx-icon>
                <p class="step-right">{{ 'LICENSE.STR_CONFIRM_WITH_OK' | translate }}</p>
              </div>
            </div>
            <button
              odxButton
              variant="highlight"
              id="finish-activation"
              data-test-id="license.finish_activation"
              [disabled]="isSubmitting"
              (click)="finishActivation()"
            >
              {{ 'LICENSE.STR_OK' | translate }}
            </button>
          </div>
        </div>
      }
    </odx-card-content>
  </odx-card>
</div>
