import { IStoreApiItem, IStoreApiList, StoreApiItem } from 'src/app/common';
import { StoreApiList } from '../../../common';
import { IEditLocationHierarchy, ILocationHierarchy, ILocationHierarchyChange, SearchedLocation } from '../../models';

export interface IConfigurationLocationHierarchyState {
  hierarchy: IStoreApiList<ILocationHierarchy[]>;
  newHierarchy: IStoreApiItem<ILocationHierarchy>;
  changeHierarchy: IStoreApiItem<ILocationHierarchyChange>;
  locationConfiguration: IStoreApiItem<IEditLocationHierarchy>;
  editedHierarchy: IStoreApiItem<IEditLocationHierarchy>;
  deletedHierarchy: IStoreApiItem<any>;
  searchedLocation: IStoreApiItem<SearchedLocation>;
}

export class ConfigurationLocationHierarchyState implements IConfigurationLocationHierarchyState {
  hierarchy: IStoreApiList<ILocationHierarchy[]> = new StoreApiList();
  newHierarchy: IStoreApiItem<ILocationHierarchy> = new StoreApiItem();
  changeHierarchy: IStoreApiItem<ILocationHierarchyChange> = new StoreApiItem();
  locationConfiguration: IStoreApiItem<IEditLocationHierarchy> = new StoreApiItem();
  editedHierarchy: IStoreApiItem<IEditLocationHierarchy> = new StoreApiItem();
  deletedHierarchy: IStoreApiItem<any> = new StoreApiItem();
  searchedLocation: IStoreApiItem<SearchedLocation> = new StoreApiItem();
}
