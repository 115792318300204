<div class="blue-bar-items">
  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    (click)="onCreateClick($event)"
    id="bluebar-create"
    data-test-id="device_connection.create_btn"
    [disabled]="
      selectedItem ||
      router.url?.includes(DeviceConnectionModuleRoutes.updateDeviceConnection) ||
      router.url?.includes(DeviceConnectionModuleRoutes.createDeviceConnection) ||
      restrictedItem ||
      isModalOpened
    "
  >
    <odx-icon size="inline" name="add"></odx-icon>
  </button>
  <button
    [disabled]="
      !selectedItem ||
      router.url?.includes(DeviceConnectionModuleRoutes.updateDeviceConnection) ||
      router.url?.includes(DeviceConnectionModuleRoutes.createDeviceConnection) ||
      isModalOpened
    "
    (click)="onEditClick($event)"
    id="bluebar-edit"
    data-test-id="device_connection.edit_btn"
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
  >
    <odx-icon size="inline" name="edit"></odx-icon>
  </button>
  <button
    [disabled]="
      !selectedItem ||
      router.url?.includes(DeviceConnectionModuleRoutes.updateDeviceConnection) ||
      router.url?.includes(DeviceConnectionModuleRoutes.createDeviceConnection) ||
      restrictedItem ||
      isModalOpened
    "
    (click)="onDeleteClick($event)"
    id="bluebar-delete"
    data-test-id="device_connection.delete_btn"
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
  >
    <odx-icon name="delete"></odx-icon>
  </button>

  <button
    *ngIf="
      router.url?.includes(DeviceConnectionModuleRoutes.updateDeviceConnection) ||
      router.url?.includes(DeviceConnectionModuleRoutes.createDeviceConnection)
    "
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    [disabled]="!form?.valid || !form?.dirty || restrictedItem || isSubmitDisabled || isModalOpened"
    (click)="onSubmit($event)"
    id="bluebar-save"
    data-test-id="device_connection.save_btn"
    [pTooltip]="form?.valid ? null : saveVButtonTooltipContent"
    tooltipPosition="top"
    positionTop="-10"
  >
    <ng-template #saveVButtonTooltipContent>
      @if (!form?.valid && !form?.disabled) {
        <div data-test-id="device_connection.save_btn_tooltip">
          {{ deviceConnectionsSaveTooltip }}
        </div>
      }
    </ng-template>

    <odx-icon size="inline" name="save"></odx-icon>
  </button>

  <ignis-export-excel-footer-btn
    [url]="displayExportExcelBtnRoutes"
    [numberOfRecordsToBeExported]="numberOfRecordsToBeExported"
    [isModalOpened]="isModalOpened"
    (handleClickEvent)="onExportExcelClick($event)"
  >
  </ignis-export-excel-footer-btn>
</div>
