import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { OrganizationSettingsService } from 'src/app/configuration/services/organization-settings.service';

import {
  IOrganizationAccessControl,
  IOrganizationEntries,
  IOrganizationLicenseInfo,
  OrganizationAccessControl,
  OrganizationEntries,
} from 'src/app/configuration/models';
import {
  ErrorReceiveOrganizationAccessControl,
  ErrorReceiveOrganizationLicenseInfo,
  ErrorReceiveOrganizationSettingsEntries,
  ErrorReceiveUpdateOrganizationAccessControl,
  OrganizationSettingsActionTypes,
  ReceiveOrganizationAccessControl,
  ReceiveOrganizationLicenseInfo,
  ReceiveOrganizationSettingsEntries,
  ReceiveUpdateOrganizationAccessControl,
} from '../../actions';

@Injectable()
export class OrganizationSettingsEffects {
  private organizationService: OrganizationSettingsService = inject(OrganizationSettingsService);

  requestOrganizationEntries$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(OrganizationSettingsActionTypes.REQUEST_ORGANIZATION_SETTINGS_ENTRIES),
      switchMap(() =>
        this.organizationService.getOrganizationEntries().pipe(
          map(
            (entries: IOrganizationEntries) => new ReceiveOrganizationSettingsEntries(new OrganizationEntries(entries)),
          ),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveOrganizationSettingsEntries(error))),
        ),
      ),
    );
  });

  requestOrganizationAccessControl$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(OrganizationSettingsActionTypes.REQUEST_ORGANIZATION_ACCESS_CONTROL),
      switchMap(() =>
        this.organizationService.getOrganizationAccessControl().pipe(
          map(
            (orgAccessControl: HttpResponse<IOrganizationAccessControl>) =>
              new ReceiveOrganizationAccessControl(new OrganizationAccessControl(orgAccessControl.body)),
          ),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveOrganizationAccessControl(error))),
        ),
      ),
    );
  });

  requestUpdateOrganizationAccessControl$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(OrganizationSettingsActionTypes.REQUEST_UPDATE_ORGANIZATION_ACCESS_CONTROL),
      switchMap((profile: IOrganizationAccessControl) =>
        this.organizationService.updateOrganizationAccessControl(profile).pipe(
          map(
            (receivedOrgAccessControl: IOrganizationAccessControl) =>
              new ReceiveUpdateOrganizationAccessControl(receivedOrgAccessControl),
          ),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUpdateOrganizationAccessControl(error))),
        ),
      ),
    );
  });

  requestOrganizationLicenseInfo$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(OrganizationSettingsActionTypes.REQUEST_ORGANIZATION_LICENSE_INFO),
      switchMap(() =>
        this.organizationService.getOrganizationLicenseInfo().pipe(
          map((entries: IOrganizationLicenseInfo[]) => new ReceiveOrganizationLicenseInfo(entries)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveOrganizationLicenseInfo(error))),
        ),
      ),
    );
  });
}
