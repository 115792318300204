import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import filter from 'lodash-es/filter';
import get from 'lodash-es/get';
import { LazyLoadEvent, SortMeta } from 'primeng/api';
import { Table } from 'primeng/table';
import { debounceTime, Subject, take, takeUntil } from 'rxjs';
import { filter as rxjsFilter } from 'rxjs/operators';
import { SettingsActions } from 'src/app/settings/state/actions/settings.actions';
import { selectTableColumns } from 'src/app/settings/state/selectors/settings.selector';
import { ApplicationState, CommonConstants, IStoreApiItem } from '../..';
import { NotificationConstants } from '../../constants/notification.constants';
import { TableConstants, TableFilterType } from '../../constants/table.constants';
import { OnDestroyMixin } from '../../mixins/destroy-mixin';
import { ITableColumn, ITableSettings, ITableSettingsResponse } from '../../models/table.model';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { TableService } from '../../services/table/table.service';
import { DropdownService } from './../../services/dropdown/dropdown.service';

@Component({
  selector: 'ignis-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent extends OnDestroyMixin() implements OnChanges, AfterViewInit {
  @ViewChild('ignisTable') public ignisTable: Table;

  @Input() selectedRow: any;

  @Input() tableColumns: ITableColumn[];
  @Input() dataTable: any;
  @Input() scrollable: boolean = true;

  @Input() datePickerFormat: string;
  @Input() datePickerSelectionMode: string;
  @Input() isDatePickerShowingTime: boolean = true;
  @Input() isDatePickerShowingSeconds: boolean = true;
  @Input() readOnlyDatePicker: boolean = false;
  @Input() activeDate: any = {};
  @Input() isDefaultRangeShown: boolean = false;

  @Input() multiSelectOptions: any;
  @Input() selectActiveOptions: any = {};

  @Input() customFilterLayout: string[];

  @Input() customColumnsLayout: string[];
  @Input() customColumnsRef: TemplateRef<any[]>;
  @Input() columnResizeMode: string = 'fit';

  @Input() resizableColumns: boolean = false;
  @Input() reorderableColumns: boolean = false;
  @Input() displayFilters: boolean = false;
  @Input() multiSelect: boolean = false;
  @Input() disableRowCheckbox: boolean = false;
  @Input() lazy: boolean = false;
  @Input() paginator: boolean = false;
  @Input() first: number = 0;
  @Input() filterDelay: number = 600;
  @Input() rowsPerPageOptions: number[] = TableConstants.rowsPerPageOptions;
  @Input() totalRecords: number;
  @Input() isLoading: boolean = false;
  @Input() dataKey: string = null;
  @Input() savedFiltersName: string;
  @Input() routeUrl: string;
  @Input() activateClickOutside: boolean = true;

  @Input() sortColumn: SortMeta;
  @Input() pageNumber: number = 0;
  @Input() resetPagining: boolean = true;

  @Input() set preselectedElements(value: any[]) {
    this.selectedElements = value;
  }

  @Input() set preselectedElement(value: any) {
    this.selectedData = value;
  }

  @Input() tableName: string;
  @Input() localColumns: any;

  @Output() handleTableRef: EventEmitter<Table> = new EventEmitter();
  @Output() handleRowSelection: EventEmitter<any> = new EventEmitter();
  @Output() handleDoubleClickRowSelection: EventEmitter<any> = new EventEmitter();
  @Output() handleOnSortChange: EventEmitter<SortMeta> = new EventEmitter();

  @Output() handleColumnVisibility: EventEmitter<ITableColumn[]> = new EventEmitter();
  @Output() handleOnPageChange: EventEmitter<{ page: number; rows: number }> = new EventEmitter();

  @Output() handleFilterValue: EventEmitter<any> = new EventEmitter();
  @Output() handleOnLazyLoading: EventEmitter<LazyLoadEvent> = new EventEmitter();

  @Output() selectedElementsChange: EventEmitter<any> = new EventEmitter();

  @Output() columns: EventEmitter<any> = new EventEmitter();

  rows: number = CommonConstants.defaultTableRowsNumber;
  selectedElements: any[] = [];
  selectedData: any;
  loadDataEvent: LazyLoadEvent;
  filtersData: any = {};
  isFirstLoad: boolean = true;

  lastTableElement: any;
  dataTableAsObs: Subject<any> = new Subject();
  debounce: Subject<string> = new Subject<string>();

  filterType: any = TableFilterType;

  notificationsService: NotificationsService = inject(NotificationsService);
  settingsActions: SettingsActions = inject(SettingsActions);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  constructor(
    public tableService: TableService,
    public translateService: TranslateService,
    public dropdownService: DropdownService,
    private router: Router,
    private store: Store<ApplicationState>,
  ) {
    super();

    this.inputFieldsDebounce();
  }

  get visibleColumns(): ITableColumn[] {
    if (this.tableColumns) {
      return this.tableColumns.filter((col: ITableColumn) => col.visible);
    }
  }

  get tableStoredFilters(): any {
    return localStorage.getItem(this.savedFiltersName) ? JSON.parse(localStorage.getItem(this.savedFiltersName)) : null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataTable?.firstChange && this.tableName) {
      this.getAndSetTableSettings();
    }

    if (this.dataTable && this.tableColumns) {
      const mutableDataTable: any = structuredClone(this.dataTable);

      this.tableColumns = filter(this.tableColumns, (col: ITableColumn) => {
        return 'field' in col;
      });

      mutableDataTable.forEach((data: any) => {
        this.tableColumns.forEach((column: ITableColumn) => {
          data[column.field] = get(data, column.field);
        });
      });

      this.dataTable = mutableDataTable;
      this.dataTableAsObs.next(this.dataTable);
      this.lastTableElement = this.tableService.lastTableHeaderElem(this.tableColumns);
    }
  }

  ngAfterViewInit(): void {
    this.handleTableRef.emit(this.ignisTable);
    this.tableService.arrangePaginatingOfElement(this.ignisTable);
    this.cdr.detectChanges();
  }

  inputFieldsDebounce(): void {
    this.debounce.pipe(debounceTime(CommonConstants.DEFAULT_INPUT_DEBOUNCE_TIME)).subscribe(() => {
      this.handleFilterValue.emit({ ...this.filtersData });
      this.resetPaging();
      this.checkIfSelectedElementsAreVisibleInTheTable();
      this.filtersData = {};
    });
  }

  onSelect(column: MouseEvent, event?: Partial<MouseEvent>): void {
    if (event?.detail === 2) {
      this.handleDoubleClickRowSelection.emit(column);
    }

    if (!this.multiSelect) {
      this.selectedData = column;
      this.handleRowSelection.emit(column);
    }
  }

  removeSelectedRow(): void {
    if (!this.router.url.includes(this.routeUrl) && this.activateClickOutside) {
      this.onSelect(null);
    }
  }

  resetPaging(): void {
    if (!this.resetPagining) return;

    this.onPageAndRowChange({ first: 0, rows: this.rows });
  }

  onChangeSort(event: SortMeta): void {
    this.sortColumn = event;

    this.resetPaging();

    this.handleOnSortChange.emit(this.sortColumn);
  }

  onPageAndRowChange(event: { first: number; rows: number }): void {
    if (this.rows !== event.rows) {
      this.pageNumber = 0;
      this.first = 0;
      this.rows = event.rows;
      this.saveTableSettings();
    } else {
      this.first = event.first;
      this.pageNumber = event.first / event.rows;
      this.rows = event.rows;
    }

    this.resetSelection();

    this.handleOnPageChange.emit({ page: this.pageNumber, rows: this.rows });
  }

  resetSelection(): void {
    if (!this.multiSelect) {
      this.onSelect(null);

      return;
    }

    this.onSelectChange([]);
  }

  onLazyLoadingData(event: LazyLoadEvent): void {
    this.loadDataEvent = event;

    this.handleOnLazyLoading.emit({ ...this.loadDataEvent });

    if (event.rows !== this.rows) {
      this.ignisTable._first = 0;
    }
  }

  onDateFilterSelect(event: { date: Date[]; field: string }): void {
    this.activeDate = { ...this.activeDate, [event.field]: event.date };
    this.onFilter(event.field, event.date);
  }

  onDateFilterChange(field: string): void {
    this.activeDate = { ...this.activeDate, [field]: undefined };
    this.onFilter(field, 'reset-date');
  }

  onMultiSelectChange(event: { options: any[]; field: string }): void {
    this.selectActiveOptions = { ...this.selectActiveOptions, [event.field]: event.options };
    this.onFilter(event.field, event.options);
  }

  onMultiSelectRemoveSelectedItems(field: string): void {
    this.selectActiveOptions = { ...this.selectActiveOptions, [field]: undefined };
    this.onFilter(field, []);
  }

  onDropdownChange(event: { value: any; field: string }): void {
    this.onFilter(event.field, event.value);
  }

  onInputFilter(event: { field: string; value: string }) {
    this.filtersData[event.field] = event.value?.trimStart()?.trimEnd();
    this.debounce.next(this.filtersData);
  }

  onFilter(field: string, value: any): void {
    this.handleFilterValue.emit({ [field]: value });
    this.resetPaging();
    this.checkIfSelectedElementsAreVisibleInTheTable();
  }

  onClearFilter(field: string): void {
    delete this.filtersData[field];
    this.onFilter(field, null);
  }

  changeColumnVisibility(column: ITableColumn): void {
    this.tableService.toggleColumn(column, this.tableColumns);
    this.lastTableElement = this.tableService.lastTableHeaderElem(this.tableColumns);
    this.tableColumns = structuredClone(this.tableService.tableColumns);

    this.handleColumnVisibility.emit(this.tableColumns);
  }

  onColumnReorder(event: any): void {
    this.tableColumns = this.tableService.reorderColumns(event, this.tableColumns);
    this.lastTableElement = this.tableService.lastTableHeaderElem(this.tableColumns);

    this.emitColumns();
    this.saveTableSettings();
  }

  saveTableSettings(): void {
    this.tableService.saveColumnsSettings(this.tableColumns, this.settingsActions, this.tableName, this.rows);
  }

  onSelectChange(event: any): void {
    this.selectedElements = event;
    this.selectedElementsChange.emit(this.selectedElements);
  }

  checkIfSelectedElementsAreVisibleInTheTable(): void {
    if (this.selectedElements?.length > 0) {
      const filteredData: any[] = [];

      this.dataTableAsObs
        .pipe(
          rxjsFilter(() => !this.isLoading),
          take(1),
        )
        .subscribe((dataTable: any) => {
          dataTable.forEach((data: any) => {
            this.selectedElements.forEach((selectedElement: any) => {
              if (data.uniqueId === selectedElement.uniqueId) {
                filteredData.push(data);
              }
            });
          });

          this.selectedElements = filteredData.length < 1 ? null : filteredData;
          this.cdr.detectChanges();
        });
    }
  }

  getAndSetTableSettings(): void {
    this.settingsActions.requestTableColumns(this.tableName);

    this.store
      .pipe(
        select(selectTableColumns),
        rxjsFilter((state: IStoreApiItem<ITableSettingsResponse>) => !state.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((tableSettings: IStoreApiItem<ITableSettingsResponse>) => {
        if (tableSettings.data) {
          if (tableSettings.data.status === TableConstants.NO_CONTENT_STATUS_CODE) {
            this.handleNoSavedColumns();

            return;
          }

          this.handleSavedColumns(tableSettings.data.body);
          this.emitColumns();
        }

        if (
          tableSettings.errors &&
          tableSettings.errors.error.code ===
            Number(NotificationConstants.commonCodes.TABLE_COLUMN_SETTINGS_ERROR.value)
        ) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            NotificationConstants.commonCodes.TABLE_COLUMN_SETTINGS_ERROR,
            NotificationConstants.commonCodes,
          );

          this.handleNoSavedColumns();
        }

        this.cdr.detectChanges();
      });
  }

  handleNoSavedColumns(): void {
    this.tableColumns = this.localColumns;

    this.emitColumns();
  }

  handleSavedColumns(tableSettings: ITableSettings): void {
    this.rows = tableSettings.pageSize || CommonConstants.defaultTableRowsNumber;

    if (this.tableStoredFilters && 'pageNumber' in this.tableStoredFilters) {
      this.first = this.tableStoredFilters.pageNumber * this.rows;
      this.pageNumber = this.tableStoredFilters.pageNumber;
    }

    this.tableColumns = this.tableService.orderTableColumns(tableSettings, this.localColumns);
  }

  emitColumns(): void {
    this.columns.emit({ columns: this.tableColumns, pageSize: this.rows, first: this.first });
  }

  trackByFn(_index: number, item): string {
    return item;
  }
}
