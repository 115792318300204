import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard } from '@okta/okta-angular';
import { BaWearerContainerComponent } from '../components/ba-wearer-container/ba-wearer-container.component';
import { BaWearerComponent } from '../components/ba-wearer/ba-wearer.component';
import { CreateUpdateBaWearerComponent } from '../components/create-update-ba-wearer/create-update-ba-wearer.component';
import { BAWearerModuleRoutes } from '../constants';
import { DeactivateBAWearerGuard } from './guards/can-deactivate/can-deactivate.guard';

export const routes: Routes = [
  {
    path: '',
    canActivate: [OktaAuthGuard],
    canActivateChild: [OktaAuthGuard],
    component: BaWearerContainerComponent,
    children: [
      { path: '', component: BaWearerComponent, pathMatch: 'full' },
      {
        path: BAWearerModuleRoutes.createBAWearer,
        component: CreateUpdateBaWearerComponent,
        canDeactivate: [DeactivateBAWearerGuard],
      },
      {
        path: `${BAWearerModuleRoutes.updateBAWearer}/:id`,
        component: CreateUpdateBaWearerComponent,
        canDeactivate: [DeactivateBAWearerGuard],
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BAWearerRoutingModule {}
