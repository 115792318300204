import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IConfigurationState } from '../../models/configuration.models';
import { RequestProtectorTypeValues } from './protector-type-action-types-creators';

@Injectable({ providedIn: 'root' })
export class ProtectorTypeActions {
  constructor(public store: Store<IConfigurationState>) {}

  public requestProtectorTypeValues(): void {
    this.store.dispatch(new RequestProtectorTypeValues());
  }
}
