import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import filter from 'lodash-es/filter';
import { filter as rxjsFilter, takeUntil } from 'rxjs';
import { selectTableColumns } from 'src/app/settings/state/selectors/settings.selector';
import { OnDestroyMixin } from '../../mixins/destroy-mixin';
import { ITableColumn } from '../../models/table.model';
import { IApplicationState } from './../../state/models/app.state.model';

@Component({
  selector: 'ignis-table-settings',
  templateUrl: './table-settings.component.html',
  styleUrls: ['./table-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSettingsComponent extends OnDestroyMixin() implements OnChanges, OnInit {
  isColumnsSettingsOpened: boolean = false;
  disableCheckbox: boolean = false;
  lastCheckboxChecked: ITableColumn;
  isLoading: boolean;
  currentAppTheme: string;

  @Input() tableColumns: ITableColumn[];
  @Output() changeColumnVisibility: EventEmitter<ITableColumn> = new EventEmitter();

  constructor(
    private store: Store<IApplicationState>,
    @Inject(DOCUMENT) private document: Document,
    private cdr: ChangeDetectorRef,
  ) {
    super();

    this.currentAppTheme = this.document.body.className.split(' ')[1];
  }

  ngOnChanges(): void {
    if (this.tableColumns) {
      this.tableColumns = filter(this.tableColumns, (col: ITableColumn) => {
        return 'field' in col;
      });
      this.checkColumnsOptions(this.tableColumns);
    }
  }

  ngOnInit(): void {
    this.store
      .pipe(
        select(selectTableColumns),
        rxjsFilter((state: any) => state),
        takeUntil(this.destroy),
      )
      .subscribe((response: any) => {
        this.isLoading = response.isLoading;
        this.cdr.detectChanges();
      });
  }

  checkColumnsOptions(tableColumns: ITableColumn[]): void {
    const lastCheckboxCheckedArr: ITableColumn[] = tableColumns.filter((column: ITableColumn) => column.visible);

    this.lastCheckboxChecked = { ...lastCheckboxCheckedArr[0] };

    if (lastCheckboxCheckedArr.length === 1) {
      this.disableCheckbox = true;
    } else {
      this.disableCheckbox = false;
    }
  }

  changeColumnSettingsState(): void {
    this.isColumnsSettingsOpened = !this.isColumnsSettingsOpened;
  }

  closeColumnSettings(): void {
    this.isColumnsSettingsOpened = false;
  }

  changeVisibility(column: ITableColumn): void {
    this.changeColumnVisibility.emit(column);
  }
}
