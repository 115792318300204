import { Pipe, PipeTransform } from '@angular/core';
import { CommonConstants } from '../../constants/common.constants';

@Pipe({
  name: 'pressureUnit',
})
export class PressureUnitPipe implements PipeTransform {
  transform(value: number, args: any[]): any {
    const psiConstant: number = 14.504;
    const mpaConstant: number = 10;

    if (typeof value !== 'number') return '-';

    switch (args[0]) {
      case CommonConstants.pressureDisplayUnit.bar:
        return args[1] ? `${value} bar` : value;
      case CommonConstants.pressureDisplayUnit.psi:
        return args[1] ? `${Math.round(value * psiConstant)} psi` : Math.round(value * psiConstant);
      case CommonConstants.pressureDisplayUnit.mpa:
        return args[1] ? `${Math.round(value / mpaConstant)} mpa` : Math.round(value / mpaConstant);

      default:
        return value;
    }
  }
}
