// eslint-disable-next-line no-shadow
export enum EquipmentTestResultsActionTypes {
  REQUEST_TEST_RESULTS_PAGE = 'REQUEST_TEST_RESULTS_PAGE',
  RECEIVE_TEST_RESULTS_PAGE = 'RECEIVE_TEST_RESULTS_PAGE',
  ERROR_RECEIVE_TEST_RESULTS_PAGE = 'ERROR_RECEIVE_TEST_RESULTS_PAGE',
  REQUEST_TEST_RESULTS_EXPORT = 'REQUEST_TEST_RESULTS_EXPORT',

  REQUEST_TEST_RESULTS_BY_ID = 'REQUEST_TEST_RESULTS_BY_ID',
  RECEIVE_TEST_RESULTS_BY_ID = 'RECEIVE_TEST_RESULTS_BY_ID',
  ERROR_RECEIVE_TEST_RESULTS_BY_ID = 'ERROR_RECEIVE_TEST_RESULTS_BY_ID',
}
