import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { EquipmentCRUDActions } from 'src/app/workshop/state/actions/equipment-crud/equipment-crud.actions';

export const editEquipmentResolver: ResolveFn<boolean> = (route: ActivatedRouteSnapshot) => {
  return editEquipmentResolverFn(route);
};

export const editEquipmentResolverFn = (route: ActivatedRouteSnapshot): boolean => {
  const equipmentCRUDActions: EquipmentCRUDActions = inject(EquipmentCRUDActions);
  const equipmentId: string = route.params.id as string;

  equipmentCRUDActions.requestEquipmentById(equipmentId);

  return true;
};
