import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IMaintenanceModeState } from './../models/maintenance-mode.model';
import { RequestMaintenanceDate } from './maintenance-mode-action-types-creator';

@Injectable({ providedIn: 'root' })
export class MaintenanceModeActions {
  constructor(private store: Store<IMaintenanceModeState>) { }

  public requestMaintenanceDate(): void {
    this.store.dispatch(new RequestMaintenanceDate());
  }
}
