<form odxForm [formGroup]="equipmentForm" [readonly]="equipmentForm.disabled">
  <section>
    @if (!typeControls.value?.id) {
      <h2 class="mb-3" odxLayout="12">{{ 'TASKS_INTERVALS.STR_NO_MODEL_FOR_TASKS_INTERVALS' | translate }}</h2>
    }

    @if (!!typeControls.value?.id) {
      @for (service of serviceControls?.controls; track service; let i = $index) {
        <div
          odxLayout="grid"
          class="task"
          [attr.serviceName]="this.serviceControls.value[i]?.taskName"
          (click)="selectTask(this.serviceControls.value[i])"
        >
          <div odxLayout="12 12@tablet 3@desktop" class="mb-2 odx-form-field--horizontal odx-form-field">
            <label
              class="odx-form-field__label ellipse-text"
              [for]="this.serviceControls.value[i]?.taskName"
              [pTooltip]="isTaskNameEllipsisActive[i] ? taskNameTooltip : null"
              tooltipPosition="top"
              positionLeft="5"
              positionTop="-10"
              #taskNameElement
            >
              <odx-form-field-label class="odx-form-field-label">
                <odx-radio-group [horizontal]="horizontal" class="eq-task-checkbox-group">
                  <odx-radio-button
                    [id]="this.serviceControls.value[i]?.serviceId"
                    [value]="selectedTask?.serviceId === this.serviceControls.value[i]?.serviceId ? null : true"
                    #radioElem
                  >
                  </odx-radio-button>
                </odx-radio-group>

                {{ this.serviceControls.value[i]?.taskName }}
                @if (this.serviceControls.value[i]?.isInherited) {
                  <span>
                    ({{ 'TASKS_INTERVALS.STR_INHERITED_FROM' | translate }}
                    {{ this.serviceControls.value[i]?.inheritedFrom }})
                  </span>
                }
              </odx-form-field-label>

              <ng-template #taskNameTooltip>
                <span>
                  {{ this.serviceControls.value[i]?.taskName }}
                  @if (this.serviceControls.value[i]?.isInherited) {
                    <span>
                      ({{ 'TASKS_INTERVALS.STR_INHERITED_FROM' | translate }}
                      {{ this.serviceControls.value[i]?.inheritedFrom }})</span
                    >
                  }
                </span>
              </ng-template>
            </label>
          </div>

          <div odxLayout="12 2@tablet 2@desktop" class="task-grid-elem">
            <odx-form-field
              variant="horizontal"
              label="{{ 'TASKS_INTERVALS.STR_LAST_TASK_DATE' | translate }}:"
              class="custom-eq-task-label"
              [ngClass]="{ 'custom-datepicker-form-field-for-task isReadOnly': disableAllFields }"
            >
              <div class="w-100" [formGroup]="serviceControls.at(i)">
                <ignis-datepicker
                  formControlName="lastTestDate"
                  class="tasks-datepicker"
                  data-test-id="service_intervals.service_date"
                  ngDefaultControl
                  [isDisabled]="serviceControls.disabled"
                  appendTo="body"
                  [selectionMode]="'single'"
                  [showTime]="false"
                  [formatDate]="formatDate"
                  [defaultRange]="extractOnlyDate(this.serviceControls.value[i]?.lastTestDate)"
                  [maxDate]="maxDate"
                  [clearable]="true"
                  (dateSelect)="onLastTaskDateChange($event, i, this.serviceControls.value[i]?.interval)"
                  (dateChange)="resetLastTaskDate($event, i, this.serviceControls.value[i]?.interval)"
                  (errorHandle)="lastIntervalErrorHandler($event, i, this.serviceControls.value[i]?.interval)"
                >
                </ignis-datepicker>
              </div>
            </odx-form-field>
          </div>

          <div odxLayout="12 2@tablet 2@desktop" class="task-grid-elem">
            <odx-form-field
              variant="horizontal"
              label="{{ 'TASKS_INTERVALS.STR_NEXT_TASK_DATE' | translate }}:"
              class="custom-eq-task-label"
              [ngClass]="{
                'custom-datepicker-form-field-for-task isReadOnly':
                  disableAllFields || !this.serviceControls.value[i].interval,
              }"
            >
              <div class="w-100" [formGroup]="serviceControls.at(i)">
                <ignis-datepicker
                  *ngIf="toggleNextServiceDatePicker"
                  formControlName="nextTestDate"
                  class="tasks-datepicker"
                  [ngClass]="{ overdue: checkIfDateIsOlder(this.serviceControls.value[i]?.nextTestDate) }"
                  data-test-id="service_intervals.next_service_date"
                  ngDefaultControl
                  appendTo="body"
                  [selectionMode]="'single'"
                  [showTime]="false"
                  [isDisabled]="serviceControls.disabled || !this.serviceControls.value[i].interval"
                  [formatDate]="formatDate"
                  [defaultRange]="extractOnlyDate(this.serviceControls.value[i]?.nextTestDate)"
                  [clearable]="true"
                  (dateSelect)="onNextTaskDateChange($event, i); displayWarningNotification()"
                  (dateChange)="onNextTaskDateChange($event, i); displayWarningNotification()"
                >
                </ignis-datepicker>
              </div>
            </odx-form-field>
          </div>
        </div>
      }
    }
  </section>
</form>
