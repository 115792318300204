import { IStoreApiItem, StoreApiItem } from 'src/app/common';
import { IIncidentFilter, IncidentFilter } from '../../models/remote-monitoring.model';

export interface IRemoteMonitoringFilterState {
  filterParams: IStoreApiItem<IIncidentFilter>;
}

export class RemoteMonitoringFilterState implements IRemoteMonitoringFilterState {
  filterParams: IStoreApiItem<IncidentFilter> = new StoreApiItem();
}
