import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { WorkshopService } from 'src/app/workshop/services';
import * as models from '../../../models';
import * as actions from '../../actions/equipment-initial-specs';

@Injectable()
export class EquipmentInitialSpecificationEffects {
  workshopService: WorkshopService = inject(WorkshopService);

  requestInitialEquipmentSpecification$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentInitialSpecificationsActionTypes.REQUEST_INITIAL_SPECIFICATION),
      switchMap(() =>
        this.workshopService.getInitialEquipmentSpecification().pipe(
          map(
            (initialSpecification: models.IEquipmentInitialSpecification[]) =>
              new actions.ReceiveInitialEquipmentSpecification(initialSpecification),
          ),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorInitialEquipmentSpecification(error))),
        ),
      ),
    );
  });

  requestEquipmentIdentifiersUniqueness$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentInitialSpecificationsActionTypes.REQUEST_CHECKING_BARCODE_OR_RFID_IS_UNIQUE),
      switchMap((params?: { payload: models.IEquipmentIdentifiers }) =>
        this.workshopService.checkEquipmentIdentifiersUniqueness(params.payload).pipe(
          map((isUnique: any) => new actions.ReceiveCheckingBarcodeOrRFIDIsUnique(isUnique)),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveCheckingBarcodeOrRFIDIsUnique(error))),
        ),
      ),
    );
  });
}
