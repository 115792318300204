import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CheckingSettingsModalsService, GenericFooterItemsComponent, PropertyBag } from 'src/app/common';
import { AccessControlService } from 'src/app/root';
import { WorkshopModuleRoutes } from 'src/app/workshop/constants/workshop-module-routes.constants';
import { WorkshopConstants } from 'src/app/workshop/constants/workshop.constants';

@Component({
  selector: 'ignis-checklist-workflow-service-tasks-footer-view',
  templateUrl: './checklist-workflow-service-tasks-footer-view.component.html',
})
export class ChecklistWorkflowServiceTasksFooterViewComponent<T> extends GenericFooterItemsComponent<T> {
  @Input() isSubmitDisabled: boolean = false;
  @Input() activatePlayBtn: boolean;
  @Input() selectedTasksAreNotTheSame: boolean;

  @Output() handleExecuteChecklistOrTest: EventEmitter<string> = new EventEmitter<string>();

  isModalOpened: boolean;

  readonly WorkshopModuleRoutes: PropertyBag = WorkshopModuleRoutes;

  public router: Router = inject(Router);
  public accessControlService: AccessControlService = inject(AccessControlService);
  private checkSettingsModal: CheckingSettingsModalsService = inject(CheckingSettingsModalsService);

  constructor() {
    super();

    this.checkSettingsModal.checkIfSettingsModalIsOpen().subscribe((isModalOpened: boolean) => {
      this.isModalOpened = isModalOpened;
      this.cdr.detectChanges();
    });
  }

  onExecuteClick(e: Event): void {
    e.stopPropagation();

    if (this.selectedItem.hasCheckList as boolean) {
      this.handleExecuteChecklistOrTest.emit(WorkshopConstants.hasCheckList);
    } else if (
      (this.selectedItem.isProtectorTestType as boolean) &&
      this.accessControlService.equipmentTestingGlobalFeatureToggle
    ) {
      this.handleExecuteChecklistOrTest.emit(WorkshopConstants.isProtectorTestType);
    }
  }
}
