<div
  class="datepicker-container"
  [ngClass]="{
    'datepicker-error': showError,
    'datepicker-container__range': selectionMode === 'range',
  }"
>
  <p-calendar
    yearRange="2000:2030"
    data-test-id="datepicker.component"
    [appendTo]="appendTo"
    [panelStyleClass]="currentAppTheme"
    [selectionMode]="selectionMode"
    [showTime]="showTime"
    [dateFormat]="formatDate?.replace('MM', 'mm')?.replace('yyyy', 'yy')"
    [placeholder]="placeholder"
    [readonlyInput]="readOnly"
    [showSeconds]="showSeconds"
    [minDate]="minDateAsDate"
    [maxDate]="maxDateAsDate"
    [ngModel]="dateFilters"
    (ngModelChange)="dateFilters = $event"
    [ngModelOptions]="{ standalone: true }"
    [yearNavigator]="yearNavigator"
    [showIcon]="true"
    [selectOtherMonths]="true"
    [firstDayOfWeek]="1"
    [numberOfMonths]="numberOfMonths"
    [showTransitionOptions]="'0ms'"
    [hideTransitionOptions]="'0ms'"
    [disabled]="isDisabled"
    (onSelect)="onSelect()"
    (onInput)="editManuallyStart($event.target.value)"
    (onBlur)="checkIfDateIsInvalid($event.target.value)"
    (onFocus)="hideTableReorderIndicators()"
  >
  </p-calendar>

  <odx-icon
    class="datepicker-reset-value"
    name="close"
    data-test-id="DATEPICKER.RESET_RANGE"
    [ngClass]="{ 'reset-disabled': isDisabled }"
    *ngIf="dateFilters && clearable"
    (click)="resetRange()"
  ></odx-icon>
</div>
