<div odxLayout="grid" [formGroup]="form" [readonly]="true">
  <div odxLayout="12 12@tablet 6@desktop" class="custom-form-field-pr-10">
    <odx-form-field variant="horizontal" label="{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_DEVICE' | translate }}">
      <input
        type="text"
        odxFormFieldControl
        id="testEquipment"
        data-test-id="equipment_task_documentation.test_device"
        formControlName="testEquipment"
      />
    </odx-form-field>

    <odx-form-field variant="horizontal" label="{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_PROCEDURE' | translate }}">
      <input
        type="text"
        odxFormFieldControl
        id="testProcedure"
        data-test-id="equipment_task_documentation.test_procedure"
        formControlName="testProcedure"
      />
    </odx-form-field>

    <odx-form-field variant="horizontal" label="{{ 'EQUIPMENT_TASK_RESULTS.STR_TEST_TYPE' | translate }}">
      <input
        type="text"
        odxFormFieldControl
        id="testType"
        data-test-id="equipment_task_documentation.test_type"
        formControlName="testType"
      />
    </odx-form-field>
  </div>
  <div odxLayout="12 12@tablet 6@desktop" class="custom-form-field-pl-10" [formGroup]="form">
    <odx-form-field variant="horizontal" label="{{ 'EQUIPMENT_TASK_RESULTS.STR_SOFTWARE_VERSION' | translate }}">
      <input
        type="text"
        odxFormFieldControl
        id="softwareVersion"
        data-test-id="equipment_task_documentation.software_version"
        formControlName="softwareVersion"
      />
    </odx-form-field>
    <odx-form-field variant="horizontal" label="{{ 'EQUIPMENT_TASK_RESULTS.STR_SERIAL_NUMBER' | translate }}">
      <input
        type="text"
        odxFormFieldControl
        id="deviceSerialNumber"
        data-test-id="equipment_task_documentation.serial_number"
        formControlName="deviceSerialNumber"
      />
    </odx-form-field>
    <odx-form-field variant="horizontal" label="{{ 'EQUIPMENT_TASK_RESULTS.STR_LOCATION' | translate }}">
      <input
        type="text"
        odxFormFieldControl
        id="locationPath"
        data-test-id="equipment_task_documentation.location"
        formControlName="locationPath"
      />
    </odx-form-field>
  </div>
</div>
