import { PropertyBag } from 'src/app/common';

export const AppModuleRoutes: PropertyBag = {
  workshopModule: 'workshop',
  logbookModule: 'logbook',
  reportCenterModule: 'report-center',
  userManagementModule: 'user-management',
  roleManagementModule: 'role-management',
  remoteMonitoringModule: 'remote-monitoring',
  deviceConnectionModule: 'device-connection',
  configurationModule: 'configuration',
  loginCallback: 'login-callback',
  internalServerError: 'internal-server-error',
  baWearer: 'ba-wearer',
};
