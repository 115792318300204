import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ModalRef, ModalService, ModalSize, ModalVariant } from '@odx/angular/components/modal';
import { filter, take } from 'rxjs';
import { ApplicationState, CommonConstants, IStoreApiItem, IStoreApiList, LoggerService } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins';
import { ITableColumn } from 'src/app/common/models';
import { NotificationsService } from 'src/app/common/services';
import { getRAMSettingsAvailableWorkflows } from 'src/app/common/utils';
import { ConfigurationNotificationConstants, LocationHierarchyNotificationConstants } from 'src/app/configuration';
import { ILocationHierarchyChange, IRAMSettings } from 'src/app/configuration/models';
import { LocationHierarchyActions } from 'src/app/configuration/state/actions';
import { selectLocationHierarchyChange } from 'src/app/configuration/state/selectors/configuration.selectors';
import { EquipmentCRUDActions, EquipmentNotificationConstants, WorkshopConstants } from 'src/app/workshop';
import { IEquipment, IEquipmentLocationAssignment, ILocationChange } from 'src/app/workshop/models';
import { EquipmentServiceTaskActions } from 'src/app/workshop/state/actions/equipment-service-task';
import { selectSavedLocationEquipments, selectUpdateEquipmentsLocation } from 'src/app/workshop/state/selectors';
import { workflowMobileColumns } from './table-columns';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'ignis-search-mobile',
  templateUrl: './search-mobile.component.html',
  styleUrls: ['./search-mobile.component.scss'],
})
export class SearchMobileComponent extends OnDestroyMixin() implements AfterViewInit {
  @ViewChild('workflowMobileModal', { read: TemplateRef })
  public workflowMobileModal: TemplateRef<ViewContainerRef>;
  modalRef: ModalRef<string, string>;
  private readonly modalService: ModalService = inject(ModalService);

  @Input() mobileLocation: IEquipmentLocationAssignment;
  isSelectedWorkflowVisible: boolean;
  @Output() closeMobileLocationModal: EventEmitter<IEquipment[] | boolean> = new EventEmitter<IEquipment[] | boolean>();
  selectedEquipment: IEquipment[];
  savedLocation: IEquipmentLocationAssignment;

  tableColumns: ITableColumn[] = workflowMobileColumns;

  isLoading: boolean = true;
  httpCustomErrorCode: string;

  loggerService: LoggerService = inject(LoggerService);
  equipmentServiceTaskActions: EquipmentServiceTaskActions = inject(EquipmentServiceTaskActions);
  equipmentCRUDActions: EquipmentCRUDActions = inject(EquipmentCRUDActions);
  locationHierarchyActions: LocationHierarchyActions = inject(LocationHierarchyActions);
  notificationsService: NotificationsService = inject(NotificationsService);

  constructor(
    public router: Router,
    private store: Store<ApplicationState>,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.savedLocation = JSON.parse(
      localStorage.getItem(WorkshopConstants.savedLocation),
    ) as IEquipmentLocationAssignment;
    this.openWorkflowMobileModal();

    this.equipmentCRUDActions.requestSavedLocationEquipment(this.mobileLocation?.aggregateId);

    this.store
      .pipe(
        select(selectSavedLocationEquipments),
        filter((state: IStoreApiList<IEquipment[]>) => {
          return !state.isLoading;
        }),
        take(1),
      )
      .subscribe((equipment: IStoreApiList<IEquipment[]>) => {
        this.selectedEquipment = equipment.data;
        this.isLoading = false;

        this.equipmentCRUDActions.resetSavedLocationEquipmentState();
      });

    getRAMSettingsAvailableWorkflows(this.store, this.destroy).subscribe((response: IRAMSettings) => {
      this.isSelectedWorkflowVisible = Object.values(response)[
        localStorage.getItem(WorkshopConstants.selectedWorkflow)
      ] as boolean;
    });
  }

  openWorkflowMobileModal(): void {
    this.modalRef = this.modalService.open(this.workflowMobileModal, {
      size: ModalSize.MEDIUM,
      variant: ModalVariant.DEFAULT,
      dismissable: false,
      data: 'isOpen',
    });
  }

  closeModal(output: IEquipment[] | boolean): void {
    this.modalRef.close('');

    Object.defineProperty(this.modalRef, 'data', { value: 'isClosed', writable: false });

    this.closeMobileLocationModal.emit(output);
  }

  movePieces(): void {
    const ids: string[] = this.selectedEquipment.map((eq: IEquipment) => eq.aggregateId);

    const locationChange: ILocationChange = {
      locationAssignment: this.savedLocation,
      equipmentAggregateIds: ids,
    };

    this.equipmentServiceTaskActions.requestUpdateEquipmentLocation(locationChange);

    this.store
      .pipe(
        select(selectUpdateEquipmentsLocation),
        filter((equipmentsLocation: IStoreApiItem<ILocationChange>) => !equipmentsLocation.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<ILocationChange>) => {
        if (response.isSuccess) {
          this.closeModal(this.selectedEquipment);

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            ConfigurationNotificationConstants.notificationCodes.EQUIPMENTS_CHANGE_LOCATION_SUCCESSFULLY,
            ConfigurationNotificationConstants.notificationCodes,
          );
        } else {
          this.httpCustomErrorCode = (response.errors?.error.code as string).toString();

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            this.httpCustomErrorCode,
            EquipmentNotificationConstants.notificationCodes,
          );
        }
      });
  }

  moveLocation(): void {
    this.locationHierarchyActions.requestLocationHierarchyChange({
      aggregateId: this.mobileLocation.aggregateId,
      parentAggregateId: this.savedLocation.aggregateId,
      version: this.mobileLocation.version,
    });

    this.store
      .pipe(
        select(selectLocationHierarchyChange),
        filter((state: IStoreApiItem<ILocationHierarchyChange>) => !state.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<ILocationHierarchyChange>) => {
        if (response.isSuccess) {
          if (this.selectedEquipment.length < 1 && this.isSelectedWorkflowVisible) {
            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.WARNING,
              ConfigurationNotificationConstants.notificationCodes
                .MOBILE_LOCATION_MOVED_SUCCESSFULLY_WITH_SELECTED_WORKFLOW,
              ConfigurationNotificationConstants.notificationCodes,
            );
          } else {
            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.SUCCESS,
              ConfigurationNotificationConstants.notificationCodes.MOBILE_LOCATION_MOVED_SUCCESSFULLY,
              ConfigurationNotificationConstants.notificationCodes,
            );
          }

          this.closeModal(this.selectedEquipment);
        } else if (response.errors) {
          this.httpCustomErrorCode = (response.errors.error?.code as string).toString();

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            this.httpCustomErrorCode,
            {
              ...ConfigurationNotificationConstants.notificationCodes,
              ...LocationHierarchyNotificationConstants.notificationCodes,
            },
          );
        }
      });
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();

    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (this.modalRef && this.modalRef['data'] === 'isOpen') {
      this.closeModal(false);
    }
  }
}
