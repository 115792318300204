import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[ignisOnlyNumber]',
})
export class OnlyNumberDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(): void {
    const initialValue: string = this.el.nativeElement.value;

    this.el.nativeElement.value = initialValue.replace(/\D/g, '');
  }
}
