<div class="page-container">
  <img src="/assets/img/logo.svg" alt="logo" />
  <img src="/assets/img/unauthorized.svg" alt="maintenance-logo" class="maintenance-logo" />
  <div class="info-box" *ngIf="maintenanceText && maintenanceText.length > 0">
    {{ maintenanceText }}
  </div>
  <p class="maintenance">{{ 'CONTAINER.STR_MAINTENANCE_MODE' | translate }}</p>
  <p class="maintenance-text">{{ 'CONTAINER.STR_MAINTENANCE_MODE_TEXT' | translate }}</p>
  <p class="maintenance-text-second">{{ 'CONTAINER.STR_MAINTENANCE_MODE_TEXT_SECOND' | translate }}</p>
</div>
