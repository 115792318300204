import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HttpHelper } from 'src/app/common';
import { environment } from 'src/environments/environment';
import { ILicenseTicket } from '../../models/licenses.model';
import { ILicenseStatus } from '../../state/licenses/models/licenses.model';

@Injectable({
  providedIn: 'root',
})
export class LicensesService {
  private baseUrl: string = environment.API_URLS.USERS;

  constructor(
    private httpClient: HttpClient,
    private httpHelper: HttpHelper,
  ) {}

  public checkLicenseStatus(ticket: ILicenseTicket): Observable<any | HttpErrorResponse> {
    return this.httpClient.post<ILicenseStatus>(
      `${this.baseUrl}/authorization/license`,
      ticket,
      this.httpHelper.options,
    );
  }

  public activateTicketID(ticket: ILicenseTicket): Observable<any | HttpErrorResponse> {
    return this.httpClient.post<ILicenseStatus>(`${this.baseUrl}/license`, ticket, this.httpHelper.options);
  }
}
