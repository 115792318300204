import { IFireStationList, IStoreApiItem, IStoreApiList, StoreApiItem, StoreApiList } from 'src/app/common';
import { IBAWearer, IBAWearerPage } from '../../models';

export interface IBAWearerState {
  baWearerPage: IStoreApiItem<IBAWearerPage>;
  baWearer: IStoreApiItem<IBAWearer>;
  newBAWearer: IStoreApiItem<IBAWearer>;
  updatedBAWearer: IStoreApiItem<IBAWearer>;
  deletedBAWearer: IStoreApiItem<IBAWearer>;
  fireStationList: IStoreApiList<IFireStationList>;
  csv: IStoreApiItem<unknown>;
}

export class BAWearerState implements IBAWearerState {
  baWearerPage: IStoreApiItem<IBAWearerPage> = new StoreApiItem();
  baWearer: IStoreApiItem<IBAWearer> = new StoreApiItem();
  newBAWearer: IStoreApiItem<IBAWearer> = new StoreApiItem();
  updatedBAWearer: IStoreApiItem<IBAWearer> = new StoreApiItem();
  deletedBAWearer: IStoreApiItem<IBAWearer> = new StoreApiItem();
  fireStationList: IStoreApiList<IFireStationList> = new StoreApiList();
  csv: IStoreApiItem<unknown> = new StoreApiItem();
}
