<form odxForm [formGroup]="equipmentForm" [readonly]="equipmentForm.disabled">
  <section odxLayout="grid" class="mb-5">
    <h2 class="mb-3" odxLayout="12">
      {{ 'ADDITIONAL_DETAILS.STR_ADDITIONAL_DETAILS_TITLE' | translate }}
    </h2>

    <div odxLayout="12 12@tablet 4@desktop" class="custom-form-field-pr-10" formGroupName="additionalEquipmentData">
      <odx-form-field
        variant="horizontal"
        label="{{ 'ADDITIONAL_DETAILS.STR_STOCK_NUMBER' | translate }}"
        [readonly]="equipmentForm.get('additionalEquipmentData.stockNumber').disabled"
        [ngClass]="{
          'has-error':
            fieldError &&
            fieldError === 'STOCK_NUMBER' &&
            equipmentForm.get('additionalEquipmentData.stockNumber').invalid &&
            equipmentForm.get('additionalEquipmentData.stockNumber').value?.length > 0,
        }"
      >
        <input
          type="text"
          odxFormFieldControl
          ignisDisableAutocomplete
          id="stockNumber"
          data-test-id="additional_details.form_stock_number"
          formControlName="stockNumber"
          (change)="clearAdditionalDetailsFormControlValidators('additionalEquipmentData.stockNumber')"
        />

        <odx-icon
          *ngIf="
            fieldError &&
            fieldError === 'STOCK_NUMBER' &&
            equipmentForm.get('additionalEquipmentData.stockNumber').invalid &&
            equipmentForm.get('additionalEquipmentData.stockNumber').value?.length > 0
          "
          name="info"
          class="custom-validation-icon"
          iconSet="core"
          [odxTooltip]="uniqueValueTooltip"
          [odxTooltipVisible]="fieldError && fieldError === 'STOCK_NUMBER'"
        >
        </odx-icon>
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label="{{ 'ADDITIONAL_DETAILS.STR_HEAD_UP_DISPLAY' | translate }}"
        [readonly]="equipmentForm.get('additionalEquipmentData.headUpDisplayId').disabled"
        [ngClass]="{
          'has-error':
            fieldError &&
            fieldError === 'HEAD_UP_DISPLAY_ID' &&
            equipmentForm.get('additionalEquipmentData.headUpDisplayId').invalid &&
            equipmentForm.get('additionalEquipmentData.headUpDisplayId').value?.length > 0,
        }"
      >
        <input
          type="text"
          odxFormFieldControl
          ignisDisableAutocomplete
          id="headUpDisplayId"
          data-test-id="additional_details.form_head_up_display_id"
          formControlName="headUpDisplayId"
          (change)="clearAdditionalDetailsFormControlValidators('additionalEquipmentData.headUpDisplayId')"
        />

        <odx-icon
          *ngIf="
            fieldError &&
            fieldError === 'HEAD_UP_DISPLAY_ID' &&
            equipmentForm.get('additionalEquipmentData.headUpDisplayId').invalid &&
            equipmentForm.get('additionalEquipmentData.headUpDisplayId').value?.length > 0
          "
          name="info"
          class="custom-validation-icon"
          iconSet="core"
          [odxTooltip]="uniqueValueTooltip"
          [odxTooltipVisible]="fieldError && fieldError === 'HEAD_UP_DISPLAY_ID'"
        >
        </odx-icon>
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label="{{ 'ADDITIONAL_DETAILS.STR_BACK_PLATE' | translate }}"
        [readonly]="equipmentForm.get('additionalEquipmentData.backPlateId').disabled"
        [ngClass]="{
          'has-error':
            fieldError &&
            fieldError === 'BACK_PLATE_ID' &&
            equipmentForm.get('additionalEquipmentData.backPlateId').invalid &&
            equipmentForm.get('additionalEquipmentData.backPlateId').value?.length > 0,
        }"
      >
        <input
          type="text"
          odxFormFieldControl
          ignisDisableAutocomplete
          id="backPlateId"
          data-test-id="additional_details.form_back_plate_id"
          formControlName="backPlateId"
          (change)="clearAdditionalDetailsFormControlValidators('additionalEquipmentData.backPlateId')"
        />

        <odx-icon
          *ngIf="
            fieldError &&
            fieldError === 'BACK_PLATE_ID' &&
            equipmentForm.get('additionalEquipmentData.backPlateId').invalid &&
            equipmentForm.get('additionalEquipmentData.backPlateId').value?.length > 0
          "
          name="info"
          class="custom-validation-icon"
          iconSet="core"
          [odxTooltip]="uniqueValueTooltip"
          [odxTooltipVisible]="fieldError && fieldError === 'BACK_PLATE_ID'"
        >
        </odx-icon>
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label="{{ 'ADDITIONAL_DETAILS.STR_GAUGE' | translate }}"
        [readonly]="equipmentForm.get('additionalEquipmentData.gaugeId').disabled"
        [ngClass]="{
          'has-error':
            fieldError &&
            fieldError === 'GAUGE_ID' &&
            equipmentForm.get('additionalEquipmentData.gaugeId').invalid &&
            equipmentForm.get('additionalEquipmentData.gaugeId').value?.length > 0,
        }"
      >
        <input
          type="text"
          odxFormFieldControl
          ignisDisableAutocomplete
          id="gaugeId"
          data-test-id="additional_details.form_gauge_id"
          formControlName="gaugeId"
          (change)="clearAdditionalDetailsFormControlValidators('additionalEquipmentData.gaugeId')"
        />

        <odx-icon
          *ngIf="
            fieldError &&
            fieldError === 'GAUGE_ID' &&
            equipmentForm.get('additionalEquipmentData.gaugeId').invalid &&
            equipmentForm.get('additionalEquipmentData.gaugeId').value?.length > 0
          "
          name="info"
          class="custom-validation-icon"
          iconSet="core"
          [odxTooltip]="uniqueValueTooltip"
          [odxTooltipVisible]="fieldError && fieldError === 'GAUGE_ID'"
        >
        </odx-icon>
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label="{{ 'ADDITIONAL_DETAILS.STR_PASS_DEVICE' | translate }}"
        [readonly]="equipmentForm.get('additionalEquipmentData.passDeviceId').disabled"
        [ngClass]="{
          'has-error':
            fieldError &&
            fieldError === 'PASS_DEVICE_ID' &&
            equipmentForm.get('additionalEquipmentData.passDeviceId').invalid &&
            equipmentForm.get('additionalEquipmentData.passDeviceId').value?.length > 0,
        }"
      >
        <input
          type="text"
          odxFormFieldControl
          ignisDisableAutocomplete
          id="passDeviceId"
          data-test-id="additional_details.form_pass_device_id"
          formControlName="passDeviceId"
          (change)="clearAdditionalDetailsFormControlValidators('additionalEquipmentData.passDeviceId')"
        />

        <odx-icon
          *ngIf="
            fieldError &&
            fieldError === 'PASS_DEVICE_ID' &&
            equipmentForm.get('additionalEquipmentData.passDeviceId').invalid &&
            equipmentForm.get('additionalEquipmentData.passDeviceId').value?.length > 0
          "
          name="info"
          class="custom-validation-icon"
          iconSet="core"
          [odxTooltip]="uniqueValueTooltip"
          [odxTooltipVisible]="fieldError && fieldError === 'PASS_DEVICE_ID'"
        >
        </odx-icon>
      </odx-form-field>
    </div>

    <div odxLayout="12 12@tablet 4@desktop" class="custom-form-field-pl-10" formGroupName="additionalEquipmentData">
      <odx-form-field
        variant="horizontal"
        label="{{ 'ADDITIONAL_DETAILS.STR_MASK' | translate }}"
        [readonly]="equipmentForm.get('additionalEquipmentData.maskId').disabled"
        [ngClass]="{
          'has-error':
            fieldError &&
            fieldError === 'MASK_ID' &&
            equipmentForm.get('additionalEquipmentData.maskId').invalid &&
            equipmentForm.get('additionalEquipmentData.maskId').value?.length > 0,
        }"
      >
        <input
          type="text"
          odxFormFieldControl
          ignisDisableAutocomplete
          id="maskId"
          data-test-id="additional_details.form_mask_id"
          formControlName="maskId"
          (change)="clearAdditionalDetailsFormControlValidators('additionalEquipmentData.maskId')"
        />

        <odx-icon
          *ngIf="
            fieldError &&
            fieldError === 'MASK_ID' &&
            equipmentForm.get('additionalEquipmentData.maskId').invalid &&
            equipmentForm.get('additionalEquipmentData.maskId').value?.length > 0
          "
          name="info"
          class="custom-validation-icon"
          iconSet="core"
          [odxTooltip]="uniqueValueTooltip"
          [odxTooltipVisible]="fieldError && fieldError === 'MASK_ID'"
        >
        </odx-icon>
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label="{{ 'ADDITIONAL_DETAILS.STR_LUNG_DEMAND_VALVE' | translate }}"
        [readonly]="equipmentForm.get('additionalEquipmentData.lungDemandValveId').disabled"
        [ngClass]="{
          'has-error':
            fieldError &&
            fieldError === 'LUNG_DEMAND_VALVE_ID' &&
            equipmentForm.get('additionalEquipmentData.lungDemandValveId').invalid &&
            equipmentForm.get('additionalEquipmentData.lungDemandValveId').value?.length > 0,
        }"
      >
        <input
          type="text"
          odxFormFieldControl
          ignisDisableAutocomplete
          id="lungDemandValveId"
          data-test-id="additional_details.form_lung_demand_valve_id"
          formControlName="lungDemandValveId"
          (change)="clearAdditionalDetailsFormControlValidators('additionalEquipmentData.lungDemandValveId')"
        />

        <odx-icon
          *ngIf="
            fieldError &&
            fieldError === 'LUNG_DEMAND_VALVE_ID' &&
            equipmentForm.get('additionalEquipmentData.lungDemandValveId').invalid &&
            equipmentForm.get('additionalEquipmentData.lungDemandValveId').value?.length > 0
          "
          name="info"
          class="custom-validation-icon"
          iconSet="core"
          [odxTooltip]="uniqueValueTooltip"
          [odxTooltipVisible]="fieldError && fieldError === 'LUNG_DEMAND_VALVE_ID'"
        >
        </odx-icon>
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label="{{ 'ADDITIONAL_DETAILS.STR_PRESSURE_REDUCER' | translate }}"
        [readonly]="equipmentForm.get('additionalEquipmentData.pressureReducerId').disabled"
        [ngClass]="{
          'has-error':
            fieldError &&
            fieldError === 'PRESSURE_REDUCER_ID' &&
            equipmentForm.get('additionalEquipmentData.pressureReducerId').invalid &&
            equipmentForm.get('additionalEquipmentData.pressureReducerId').value?.length > 0,
        }"
      >
        <input
          type="text"
          odxFormFieldControl
          ignisDisableAutocomplete
          id="pressureReducerId"
          data-test-id="additional_details.form_pressure_reducer_id"
          formControlName="pressureReducerId"
          (change)="clearAdditionalDetailsFormControlValidators('additionalEquipmentData.pressureReducerId')"
        />

        <odx-icon
          *ngIf="
            fieldError &&
            fieldError === 'PRESSURE_REDUCER_ID' &&
            equipmentForm.get('additionalEquipmentData.pressureReducerId').invalid &&
            equipmentForm.get('additionalEquipmentData.pressureReducerId').value?.length > 0
          "
          name="info"
          class="custom-validation-icon"
          iconSet="core"
          [odxTooltip]="uniqueValueTooltip"
          [odxTooltipVisible]="fieldError && fieldError === 'PRESSURE_REDUCER_ID'"
        >
        </odx-icon>
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label="{{ 'ADDITIONAL_DETAILS.STR_CYLINDER' | translate }}"
        [readonly]="equipmentForm.get('additionalEquipmentData.cylinderId').disabled"
        [ngClass]="{
          'has-error':
            fieldError &&
            fieldError === 'CYLINDER_ID' &&
            equipmentForm.get('additionalEquipmentData.cylinderId').invalid &&
            equipmentForm.get('additionalEquipmentData.cylinderId').value?.length > 0,
        }"
      >
        <input
          type="text"
          odxFormFieldControl
          ignisDisableAutocomplete
          id="cylinderId"
          data-test-id="additional_details.form_cylinder_id"
          formControlName="cylinderId"
          (change)="clearAdditionalDetailsFormControlValidators('additionalEquipmentData.cylinderId')"
        />

        <odx-icon
          *ngIf="
            fieldError &&
            fieldError === 'CYLINDER_ID' &&
            equipmentForm.get('additionalEquipmentData.cylinderId').invalid &&
            equipmentForm.get('additionalEquipmentData.cylinderId').value?.length > 0
          "
          name="info"
          class="custom-validation-icon"
          iconSet="core"
          [odxTooltip]="uniqueValueTooltip"
          [odxTooltipVisible]="fieldError && fieldError === 'CYLINDER_ID'"
        >
        </odx-icon>
      </odx-form-field>
    </div>
  </section>
</form>

<ng-template #uniqueValueTooltip>
  <div id="unique-value-tooltip-error" data-test-id="equipment_additional_details.unique_value_tooltip_error">
    {{ 'EQUIPMENT_TOOLTIP.STR_VALUE_SHOULD_BE_UNIQUE' | translate }}
  </div>
</ng-template>
