import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ignis-documentation-equipment-information',
  templateUrl: './documentation-equipment-information.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentationEquipmentInformationComponent {
  @Input() form: FormGroup;
}
