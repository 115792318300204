import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { IChecklistItem } from 'src/app/configuration/models';

@Component({
  selector: 'ignis-checklist-text-type',
  templateUrl: './checklist-text-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistTextTypeComponent {
  @Input() item: IChecklistItem;
  @Input() form: FormGroup;
}
