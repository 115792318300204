import { IStoreApiItem, StoreApiItem } from 'src/app/common';
import { IOrganizationAccessControl } from '../../models/organization-settings.model';

export interface IOrganizationAccessControlState {
  organizationAccessControl: IStoreApiItem<IOrganizationAccessControl>,
  updateOrganizationAccessControl: IStoreApiItem<IOrganizationAccessControl>,
}

export class OrganizationAccessControlState implements IOrganizationAccessControlState {
  organizationAccessControl: IStoreApiItem<IOrganizationAccessControl> = new StoreApiItem();
  updateOrganizationAccessControl: IStoreApiItem<IOrganizationAccessControl> = new StoreApiItem();
}
