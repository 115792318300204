export interface IWorkflowBtn {
  visible: boolean;
  disabled: boolean;
  tooltipText: string;
  id: string;
  name: string;
  checked: boolean;
  workflowType: WorkflowType;
  icon: string;
}

export interface IWorkflowStoredTableElement {
  id: string;
  defaultPosition: number;
}

// eslint-disable-next-line no-shadow
export enum WorkflowType {
  CLEANING,
  CYLINDER_CHARGING,
  TESTING,
  CHECKING,
  NONE,
}

export enum WorkflowBtnTooltipEnum {
  Cleaning = 'WORKSHOP_WORKFLOW_ASSET.STR_CLEANING_WORKFLOW_TOOLTIP',
  CylinderCharging = 'WORKSHOP_WORKFLOW_ASSET.STR_CYLINDER_CHARGING_TOOLTIP',
  Testing = 'WORKSHOP_WORKFLOW_ASSET.STR_PROTECTOR_TEST_TOOLTIP',
  Checklist = 'WORKSHOP_WORKFLOW_ASSET.STR_CHECKLIST_SERVICE_TOOLTIP',
}

export enum WorkflowBtnIdEnum {
  Cleaning = 'btn-cleaning-workflow',
  CylinderCharging = 'btn-cylinder-charging-workflow',
  Testing = 'btn-testing-workflow',
  Checklist = 'btn-checking-workflow',
}

export enum WorkflowBtnNameEnum {
  Cleaning = 'cleaningWorkflow',
  CylinderCharging = 'cylinderWorkflow',
  Testing = 'testingWorkflow',
  Checklist = 'checkWorkflow',
}

export enum WorkflowBtnIconEnum {
  Cleaning = 'cleaning',
  CylinderCharging = 'cylinder-charge',
  Testing = 'test-eq',
  Checklist = 'test',
}
