import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICustomColumn, PropertyBag } from 'src/app/common/models';
import { WorkshopConstants } from 'src/app/workshop/constants';

@Component({
  selector: 'ignis-service-status-column',
  templateUrl: './service-status-column.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceStatusColumnComponent {
  @Input() serviceStatus: ICustomColumn;

  serviceStatusesList: PropertyBag = WorkshopConstants.serviceStatuses;
}
