<div class="blue-bar-items">
  <button
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="add-equipment-hierarchy"
    id="add-equipment-hierarchy-btn"
    data-test-id="configuration.add_equipment_hierarchy_btn"
    (click)="onCreateClick($event)"
    [disabled]="
      disableAddBtn ||
      router.url?.includes(ConfigurationModuleRoutes.createEquipmentHierarchy) ||
      router.url?.includes(ConfigurationModuleRoutes.updateService) ||
      disableAddBtnTaskInterval ||
      router.url?.includes(ConfigurationModuleRoutes.createChecklist) ||
      router.url?.includes(ConfigurationModuleRoutes.createService) ||
      isModalOpened
    "
    [pTooltip]="
      disableAddBtn ||
      router.url?.includes(ConfigurationModuleRoutes.createEquipmentHierarchy) ||
      router.url?.includes(ConfigurationModuleRoutes.updateService) ||
      disableAddBtnTaskInterval ||
      router.url?.includes(ConfigurationModuleRoutes.createChecklist) ||
      router.url?.includes(ConfigurationModuleRoutes.createService) ||
      isModalOpened
        ? null
        : addButtonTooltipContent
    "
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #addButtonTooltipContent>
      @if (
        selectedItem &&
        disableAddBtn &&
        (router.url?.includes(ConfigurationModuleRoutes.createEquipmentHierarchy) ||
          router.url?.includes(ConfigurationModuleRoutes.updateEquipmentHierarchy))
      ) {
        <div data-test-id="configuration.add_equipment_hierarchy_tooltip">
          {{ 'CONFIGURATION.STR_FOOTER_BTN_TOOLTIP' | translate }}
        </div>
      } @else if (
        !disableAddBtn &&
        (router.url?.includes(ConfigurationModuleRoutes.createEquipmentHierarchy) ||
          router.url?.includes(ConfigurationModuleRoutes.updateEquipmentHierarchy))
      ) {
        <div data-test-id="configuration.generic_add_service_tooltip">
          {{ 'CONFIGURATION.STR_ADD_SERVICE_TOOLTIP' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon size="inline" name="add"></odx-icon>
  </button>

  <button
    [disabled]="
      !selectedItem ||
      disableEditBtnAndDelete ||
      router.url?.includes(ConfigurationModuleRoutes.updateService) ||
      router.url?.includes(ConfigurationModuleRoutes.createService) ||
      router.url?.includes(ConfigurationModuleRoutes.createChecklist) ||
      isModalOpened
    "
    (click)="onEditClick($event)"
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="edit-equipment-hierarchy"
    id="edit-equipment-hierarchy-btn"
    data-test-id="configuration.edit_equipment_hierarchy_btn"
    [pTooltip]="
      !selectedItem ||
      disableEditBtnAndDelete ||
      router.url?.includes(ConfigurationModuleRoutes.updateService) ||
      router.url?.includes(ConfigurationModuleRoutes.createService) ||
      router.url?.includes(ConfigurationModuleRoutes.createChecklist) ||
      isModalOpened
        ? null
        : editButtonTooltipContent
    "
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #editButtonTooltipContent>
      @if (
        selectedItem &&
        disableEditBtnAndDelete &&
        (router.url?.includes(ConfigurationModuleRoutes.createEquipmentHierarchy) ||
          router.url?.includes(ConfigurationModuleRoutes.updateEquipmentHierarchy))
      ) {
        <div data-test-id="configuration.edit_equipment_hierarchy_tooltip">
          {{ 'CONFIGURATION.STR_FOOTER_BTN_TOOLTIP' | translate }}
        </div>
      } @else if (
        !disableEditBtnAndDelete &&
        (router.url?.includes(ConfigurationModuleRoutes.createEquipmentHierarchy) ||
          router.url?.includes(ConfigurationModuleRoutes.updateEquipmentHierarchy))
      ) {
        <div data-test-id="configuration.generic_edit_equipment_hierarchy_tooltip">
          {{ 'CONFIGURATION.STR_EDIT_TASK_TOOLTIP' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon size="inline" name="edit"></odx-icon>
  </button>

  <button
    [disabled]="
      !selectedItem ||
      disableEditBtnAndDelete ||
      router.url?.includes(ConfigurationModuleRoutes.updateService) ||
      router.url?.includes(ConfigurationModuleRoutes.createService) ||
      router.url?.includes(ConfigurationModuleRoutes.createChecklist) ||
      isModalOpened
    "
    (click)="onDeleteClick($event)"
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="delete-equipment-hierarchy"
    id="delete-equipment-hierarchy-btn"
    data-test-id="configuration.delete_equipment_hierarchy_btn"
    [pTooltip]="
      !selectedItem ||
      disableEditBtnAndDelete ||
      router.url?.includes(ConfigurationModuleRoutes.updateService) ||
      router.url?.includes(ConfigurationModuleRoutes.createService) ||
      router.url?.includes(ConfigurationModuleRoutes.createChecklist) ||
      isModalOpened
        ? null
        : deleteButtonTooltipContent
    "
    tooltipPosition="top"
    positionLeft="5"
    positionTop="-10"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #deleteButtonTooltipContent>
      @if (
        selectedItem &&
        disableEditBtnAndDelete &&
        (router.url?.includes(ConfigurationModuleRoutes.createEquipmentHierarchy) ||
          router.url?.includes(ConfigurationModuleRoutes.updateEquipmentHierarchy))
      ) {
        <div data-test-id="configuration.delete_equipment_hierarchy_tooltip">
          {{ 'CONFIGURATION.STR_FOOTER_BTN_TOOLTIP' | translate }}
        </div>
      } @else if (
        !disableEditBtnAndDelete &&
        (router.url?.includes(ConfigurationModuleRoutes.createEquipmentHierarchy) ||
          router.url?.includes(ConfigurationModuleRoutes.updateEquipmentHierarchy))
      ) {
        <div data-test-id="configuration.generic_delete_equipment_hierarchy_tooltip">
          {{ 'CONFIGURATION.STR_DELETE_TASK_TOOLTIP' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon name="delete"></odx-icon>
  </button>

  <button
    *ngIf="
      router.url?.includes(ConfigurationModuleRoutes.updateEquipmentHierarchy) ||
      router.url?.includes(ConfigurationModuleRoutes.createEquipmentHierarchy)
    "
    type="button"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="save-equipment-hierarchy"
    [disabled]="
      ((!form?.valid || !form?.dirty || isModalOpened) && !selectedItem) ||
      router.url?.includes(ConfigurationModuleRoutes.updateService) ||
      router.url?.includes(ConfigurationModuleRoutes.createChecklist) ||
      isModalOpened ||
      !form?.valid ||
      isSubmitDisabled
    "
    (click)="onSubmit($event)"
    id="submit-equipment-hierarchy-btn"
    data-test-id="configuration.submit_equipment_hierarchy_btn"
    [pTooltip]="saveButtonTooltipContent"
    tooltipPosition="top"
    positionTop="-10"
    positionLeft="5"
    tooltipStyleClass="footer-tooltip"
  >
    <ng-template #saveButtonTooltipContent>
      @if (form?.valid) {
        <div data-test-id="configuration.save_equipment_hierarchy_tooltip">
          {{ 'CONFIGURATION.STR_SAVE_TOOLTIP' | translate }}
        </div>
      } @else {
        <div data-test-id="configuration.error_save_equipment_hierarchy_tooltip">
          {{
            'CONFIGURATION.STR_ERROR_SAVE_TOOLTIP'
              | translate
                : {
                    name: !form?.get('name')?.valid ? ('CONFIGURATION.STR_NAME' | translate) : '',
                    pressure:
                      form.get('maxPressure') && !form.get('maxPressure').valid
                        ? ('CONFIGURATION.STR_MAX_PRESSURE' | translate)
                        : '',
                  }
          }}
        </div>
      }
    </ng-template>
    <odx-icon size="inline" name="save"></odx-icon>
  </button>

  <button
    type="button"
    [disabled]="
      !selectedItem ||
      disableAddChecklistBtn ||
      router.url?.includes(ConfigurationModuleRoutes.updateService) ||
      router.url?.includes(ConfigurationModuleRoutes.createService) ||
      router.url?.includes(ConfigurationModuleRoutes.createChecklist) ||
      isModalOpened ||
      isSubmitDisabled
    "
    *ngIf="
      (!toggleAddAndEditChecklistBtn && router.url?.includes(ConfigurationModuleRoutes.updateEquipmentHierarchy)) ||
      router.url?.includes(ConfigurationModuleRoutes.createEquipmentHierarchy)
    "
    (click)="onAddChecklistOpen($event)"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="add-checklist"
    id="add-checklist-hierarchy-btn"
    data-test-id="configuration.add_checklist_hierarchy_btn"
    [pTooltip]="checklistButtonTooltipContent"
    tooltipPosition="top"
    positionTop="-10"
  >
    <ng-template #checklistButtonTooltipContent>
      @if (disableAddChecklistBtn) {
        <div data-test-id="configuration_checklist.add_checklist_hierarchy_tooltip">
          {{ 'CONFIGURATION_CHECKLIST.FOOTER_BTN_CREATE_TOOLTIP' | translate }}
        </div>
      } @else {
        <div data-test-id="configuration_checklist.generic_add_checklist_hierarchy_tooltip">
          {{ 'CONFIGURATION.STR_ADD_CHECKLIST_TOOLTIP' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon name="test"></odx-icon>
  </button>

  <button
    type="button"
    [disabled]="
      !selectedItem ||
      disableEditChecklistBtn ||
      router.url?.includes(ConfigurationModuleRoutes.updateService) ||
      router.url?.includes(ConfigurationModuleRoutes.createService) ||
      router.url?.includes(ConfigurationModuleRoutes.updateChecklist) ||
      isModalOpened
    "
    (click)="onEditChecklistOpen($event)"
    *ngIf="toggleAddAndEditChecklistBtn && router.url?.includes(ConfigurationModuleRoutes.updateEquipmentHierarchy)"
    odxButton
    size="medium"
    variant="highlight"
    class="blue-bar-btn"
    name="edit-checklist"
    id="edit-checklist-hierarchy-btn"
    data-test-id="configuration_checklist.edit_checklist_hierarchy_btn"
    [pTooltip]="!selectedItem ? null : editChecklistButtonTooltipContent"
    tooltipPosition="top"
    positionTop="-10"
  >
    <ng-template #editChecklistButtonTooltipContent>
      @if (disableEditChecklistBtn) {
        <div data-test-id="configuration_checklist.edit_checklist_hierarchy_tooltip">
          {{ 'CONFIGURATION_CHECKLIST.FOOTER_BTN__EDIT_TOOLTIP' | translate }}
        </div>
      } @else if (selectedItem && !disableEditChecklistBtn) {
        <div data-test-id="configuration_checklist.generic_edit_checklist_hierarchy_tooltip">
          {{ 'CONFIGURATION.STR_EDIT_CHECKLIST_TOOLTIP' | translate }}
        </div>
      }
    </ng-template>

    <odx-icon name="test-edit"></odx-icon>
  </button>
</div>
