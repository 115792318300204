import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard } from '@okta/okta-angular';
import { CreateUpdateDeviceConnectionComponent } from '../components/create-update-device-connection/create-update-device-connection.component';
import { DeviceConnectionContainerComponent } from '../components/device-connection-container/device-connection-container.component';
import { DeviceConnectionManagementComponent } from '../components/device-connection-management/device-connection-management.component';
import { DeviceConnectionModuleRoutes } from '../constants/device-connection-module-routes.constants';
import { DeactivateDeviceConnectionGuard } from './can-deactivate.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [OktaAuthGuard],
    canActivateChild: [OktaAuthGuard],
    component: DeviceConnectionContainerComponent,
    children: [
      { path: '', component: DeviceConnectionManagementComponent, pathMatch: 'full' },
      {
        path: DeviceConnectionModuleRoutes.createDeviceConnection,
        component: CreateUpdateDeviceConnectionComponent,
        canDeactivate: [DeactivateDeviceConnectionGuard],
      },
      {
        path: `${DeviceConnectionModuleRoutes.updateDeviceConnection}/:id`,
        component: CreateUpdateDeviceConnectionComponent,
        canDeactivate: [DeactivateDeviceConnectionGuard],
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DeviceConnectionManagementRoutingModule {}
