import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { select, Store } from '@ngrx/store';
import { StorageMap } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@uib/angular';
import { PrimeNGConfig } from 'primeng/api';
import { Observable } from 'rxjs';
import { filter, map, pairwise, take, takeUntil } from 'rxjs/operators';
import { AlarmsInfo, CommonConstants, IApplicationState, IStoreApiItem, UserStorageService } from 'src/app/common';
import { NotificationConstants } from 'src/app/common/constants/notification.constants';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { selectTableExportInProgress } from 'src/app/common/state/export/selectors/export.selector';
import { OrganizationSettingsActions } from 'src/app/configuration/state/actions';
import { ILocalizationProfile, SettingsActions, settingsSelectors } from 'src/app/settings';
import { UserNotificationConstants } from 'src/app/user-management/constants';
import { environment } from 'src/environments/environment';
import { MaintenanceModeActions } from './../../state/maintenance-mode/actions/maintenance-mode.actions';
import { IMaintenanceDate } from './../../state/maintenance-mode/models/maintenance-mode.model';
import { selectMaintenanceDate } from './../../state/maintenance-mode/selectors/maintenance-mode.selector';
import { ProductActions } from './../../state/product/actions/product.actions';
import { dateFormat, formatLocaleTime } from '../../../common/utils/date-utils/date.utils';
import { AlarmsConstants } from '../../constants/alarms.constants';
import { IAccessControlItems } from '../../models/access-control-items.model';
import { IAlarm, IAlarmLists, IEntryModel } from '../../models/alarms.model';
import { AppModulesTypes } from '../../models/app-types';
import { AccessControlService } from '../../services/access-control/access-control.service';
import { AccessControlItemsActions } from '../../state/access-control-items/actions/access-control-items.actions';
import { selectAccessControlItems } from '../../state/access-control-items/selectors/access-control-items.selector';
import { FeaturesToggleActions } from '../../state/features-toggle/actions/features-toggle.actions';
import { IFeatureToggle } from '../../state/features-toggle/models/features-toggle.model';
import { LegalTermsActions } from '../../state/legal-terms/actions/legal-terms.actions';
import { ILegalTermsData } from '../../state/legal-terms/models/legal-terms.model';
import { selectAcceptLegalTerms, selectLegalTermsData } from '../../state/legal-terms/selectors/legal-terms.selector';

@Component({
  selector: 'ignis-container',
  templateUrl: './container.component.html',
})
export class ContainerComponent extends OnDestroyMixin() implements OnChanges, OnInit {
  isOpenSidebar: boolean;
  translationLoaded: boolean = false;
  displayAlarms: boolean;
  alarmsInfo: AlarmsInfo;
  jwtHelper: JwtHelperService = new JwtHelperService();
  featuresToggle: IFeatureToggle[];
  getDataOnlyFirstTime: boolean = false;
  activeAlarms: IAlarm[];
  clearedAlarms: IAlarm[];
  newAlarms: IAlarm[];
  alarmType: Partial<IEntryModel>[];
  openedAlarms: boolean;
  isMapLoaded: boolean;
  isUnauthorized: boolean = false;
  isLicenseExpired: boolean = false;
  isMaintenanceModeFromAuthorized: boolean = false;
  legalTermsAccepted: boolean = false;
  isGDPRAccepted: boolean = true;
  isTaCAccepted: boolean = true;
  isDPAAccepted: boolean = true;
  isExporting$: Observable<boolean> = null;

  maintenanceText: string = null;
  legalHyperLink: string = null;
  legalVersion: string = null;
  documentsLang: string;

  @Input() dateFormat: string;
  @Input() organizationDateFormat: string;
  @Input() alarmList: IAlarmLists;
  @Input() userRole: string;
  @Input() permissions: string[];
  @Input() isLoggedIn: boolean = false;

  @Output() requestAlarms: EventEmitter<void> = new EventEmitter<void>();
  @Output() checkUserRole: EventEmitter<IAccessControlItems> = new EventEmitter<IAccessControlItems>();
  @Output() handleAppLanguage: EventEmitter<string> = new EventEmitter<string>();

  settingsActions: SettingsActions = inject(SettingsActions);
  featuresToggleActions: FeaturesToggleActions = inject(FeaturesToggleActions);
  maintenanceModeActions: MaintenanceModeActions = inject(MaintenanceModeActions);
  accessControlItemsActions: AccessControlItemsActions = inject(AccessControlItemsActions);
  accessControlService: AccessControlService = inject(AccessControlService);
  organizationSettingsActions: OrganizationSettingsActions = inject(OrganizationSettingsActions);
  notificationsService: NotificationsService = inject(NotificationsService);
  legalTermsActions: LegalTermsActions = inject(LegalTermsActions);
  productActions: ProductActions = inject(ProductActions);
  primeNgConfig: PrimeNGConfig = inject(PrimeNGConfig);
  store: Store<IApplicationState> = inject(Store<IApplicationState>) as Store<IApplicationState>;
  translate: TranslateService = inject(TranslateService);
  httpClient: HttpClient = inject(HttpClient);
  userStorageService: UserStorageService = inject(UserStorageService);
  router: Router = inject(Router);
  storage: StorageMap = inject(StorageMap);

  constructor(private readonly modalService: ModalService) {
    super();

    this.translate.addLangs(Object.values(CommonConstants.supportedLanguages));
  }

  get readAlarms(): string[] {
    const readAlarms: string[] = this.userStorageService.getStoredValue('readAlarms') as string[];

    return readAlarms ? readAlarms : [];
  }

  get dismissedAlarms(): string[] {
    const dismissedAlarms: string[] = this.userStorageService.getStoredValue('dismissedAlarms') as string[];

    return dismissedAlarms ? dismissedAlarms : [];
  }

  ngOnChanges(): void {
    this.separateAlarms();
    this.displayMaintenanceNotification();

    this.translate.get('primeng').subscribe((res: any) => {
      this.primeNgConfig.setTranslation(res);
    });

    if (this.isLoggedIn && this.getDataOnlyFirstTime) {
      this.accessControlItemsActions.requestAccessControlItems();
      this.readAccessControlItems();
    }
  }

  ngOnInit(): void {
    if (this.translate.store.translations[this.translate.currentLang]) {
      this.translationLoaded = true;
      this.loadGoogleMapsApi(this.translate.currentLang, ['marker']);
    } else {
      this.translate.onLangChange.subscribe(() => {
        this.translationLoaded = true;
        this.loadGoogleMapsApi(this.translate.currentLang, ['marker']);
      });

      this.checkIfAppIsInMaintenanceMode();
    }

    this.storage.watch(CommonConstants.userIsUnauthorized).subscribe((unauthorized: string) => {
      if (unauthorized !== undefined) {
        this.legalTermsAccepted = true;
        this.isUnauthorized = true;
        this.storage.delete(CommonConstants.userIsUnauthorized).subscribe(() => {
          return;
        });
      }
    });

    this.setAppLanguage();

    this.router.events
      .pipe(
        filter((e: NavigationEnd) => e instanceof NavigationEnd),
        pairwise(),
      )
      .subscribe((routes: any[]) => {
        this.setThePreviousRoute(routes[0].url);
      });

    this.getDataOnlyFirstTime = true;

    this.isExporting$ = this.store.pipe(
      select(selectTableExportInProgress),
      takeUntil(this.destroy),
      map((state: IStoreApiItem<boolean>) => state.data),
    );
  }

  setThePreviousRoute(route: string): void {
    localStorage.setItem('previousRoute', route);
  }

  setAppLanguage(): void {
    this.store
      .select(settingsSelectors.selectUserSettings)
      .pipe(
        filter(
          (userSettings: IStoreApiItem<ILocalizationProfile>) =>
            Boolean(userSettings) && !userSettings.isLoading && !userSettings.errors && Boolean(userSettings.data),
        ),
        take(1),
      )
      .subscribe((userSettings: IStoreApiItem<ILocalizationProfile>) => {
        this.translate.use(userSettings.data.language);
        this.handleAppLanguage.emit(userSettings.data.language);
      });
  }

  separateAlarms(): void {
    let countAlarms: number = 0;
    const fiveMinutes: number = 5;

    this.activeAlarms = structuredClone(this.alarmList?.activeAlarms);
    this.clearedAlarms = structuredClone(this.alarmList?.clearedAlarms);
    this.alarmType = AlarmsConstants.incidentType;
    this.activeAlarms?.forEach((alarm: IAlarm) => (alarm.open = false));

    this.newAlarms = this.activeAlarms?.filter(
      (alarm: IAlarm) =>
        !this.readAlarms?.includes(alarm.id) &&
        !this.dismissedAlarms.includes(alarm.id) &&
        this.checkTheMinutesDifference(alarm) <= fiveMinutes,
    );

    if (this.newAlarms) {
      this.newAlarms.sort(
        (a: IAlarm, b: IAlarm) => new Date(a.activationAt).getTime() - new Date(b.activationAt).getTime(),
      );
    }

    this.newAlarms?.forEach((alarm: IAlarm) => {
      alarm.time = formatLocaleTime(new Date(alarm.activationAt));
      alarm.open = true;

      if (alarm.open === true) {
        countAlarms = countAlarms + 1;
      }

      this.checkOpenedAlarms(countAlarms);

      setTimeout(() => {
        alarm.open = false;

        countAlarms = countAlarms - 1;
        this.checkOpenedAlarms(countAlarms);
      }, 10000);

      this.alarmType.forEach((t: IEntryModel) => {
        if (t.value === alarm.type?.value) {
          alarm.type.label = t.localizedName;
        }
      });
    });
  }

  checkIfAppIsInMaintenanceMode(): void {
    this.storage.watch(CommonConstants.appIsInMaintenanceMode).subscribe((maintenanceStatus: string) => {
      if (maintenanceStatus !== undefined) {
        this.isMaintenanceModeFromAuthorized = true;
        this.maintenanceText = maintenanceStatus !== CommonConstants.maintenanceWithoutMessage ? maintenanceStatus : '';

        this.checkIfMaintenanceMessageIsHTMLPage(maintenanceStatus);

        this.storage.delete(CommonConstants.appIsInMaintenanceMode).subscribe(() => {
          return;
        });
      }
    });
  }

  checkIfMaintenanceMessageIsHTMLPage(message: string): void {
    if (typeof message === 'string' && message?.includes('<html>')) {
      this.maintenanceText = '';
    }
  }

  displayMaintenanceNotification(): void {
    if (this.organizationDateFormat) {
      this.store
        .pipe(
          select(selectMaintenanceDate),
          filter((user: IStoreApiItem<IMaintenanceDate>) => !user.isLoading),
          takeUntil(this.destroy),
        )
        .subscribe((response: IStoreApiItem<IMaintenanceDate>) => {
          // eslint-disable-next-line no-prototype-builtins
          if (response.data && !localStorage.hasOwnProperty(CommonConstants.maintenanceDate)) {
            const maintenanceDate: string = dateFormat(response.data.date, this.organizationDateFormat);

            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.MAINTENANCE,
              NotificationConstants.commonCodes.MAINTENANCE_MODE,
              NotificationConstants.commonCodes,
              { date: maintenanceDate },
            );
          }
        });
    }
  }

  checkOpenedAlarms(countAlarms: number): void {
    if (countAlarms > 0) {
      this.openedAlarms = true;
    } else {
      this.openedAlarms = false;
    }
  }

  checkTheMinutesDifference(alarm: IAlarm): number {
    let diff: number = (new Date().getTime() - new Date(alarm.activationAt).getTime()) / 1000;

    diff /= 60;

    return Math.abs(Math.round(diff));
  }

  closeNotification(closedAlarm: IAlarm): void {
    let countAlarms: number = 0;

    this.dismissNotification(closedAlarm);

    this.newAlarms?.forEach((alarm: IAlarm) => {
      if (closedAlarm.id === alarm.id) {
        alarm.open = false;
      }

      if (alarm.open === true) {
        countAlarms = countAlarms + 1;
      }

      this.checkOpenedAlarms(countAlarms);
    });
  }

  dismissNotification(alarm: IAlarm): void {
    const dismissed: string[] = this.dismissedAlarms;

    if (!dismissed.length) {
      dismissed.push(alarm.id);
    }

    if (dismissed.length && !dismissed.find((dAlarm: string) => dAlarm === alarm.id)) {
      dismissed.push(alarm.id);
    }

    this.userStorageService.setStoredValue('dismissedAlarms', dismissed);
  }

  getSettings(): void {
    this.settingsActions.requestUserSettings();

    if (!this.router.url.includes('configuration/organization-settings')) {
      this.organizationSettingsActions.requestOrganizationAccessControl();
    }
  }

  getProductName(): void {
    this.productActions.requestProductName();
  }

  onOpenSidebar(isOpen: boolean): void {
    this.isOpenSidebar = isOpen;
  }

  openAlarms(): void {
    if (this.displayAlarms) {
      this.closeAlarms(false);
    } else {
      this.displayAlarms = true;
    }
  }

  closeAlarms(event: boolean): void {
    this.displayAlarms = event;
    this.activeAlarms?.forEach((alarm: IAlarm) => {
      alarm.open = false;
      this.dismissNotification(alarm);
    });
    this.checkAlarmsInfoAfterClosingNotification();
  }

  getNewAlarmsInfo(event: AlarmsInfo): void {
    setTimeout(() => {
      this.alarmsInfo = event;
    }, 0);
  }

  checkAlarmsInfoAfterClosingNotification(): void {
    const localAlarms: string[] = this.userStorageService.getStoredValue('readAlarms') as string[];
    const readAlarms: string[] = localAlarms ? localAlarms : [];
    const newNotificationAlarms: IAlarm[] = this.activeAlarms?.filter(
      (alarm: IAlarm) => !readAlarms?.includes(alarm.id),
    );

    this.alarmsInfo = {
      newAlarmsNumber: newNotificationAlarms?.length,
      severityAlarms: newNotificationAlarms?.filter((alarm: IAlarm) => alarm.type.severity === 1).length,
    };
  }

  readAccessControlItems(): void {
    this.store
      .pipe(
        select(selectAccessControlItems),
        filter((accessControl: IStoreApiItem<IAccessControlItems>) => !accessControl.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<IAccessControlItems>) => {
        if (response.errors) {
          const useLanguage: string =
            CommonConstants.supportedLanguages[navigator.language] ?? CommonConstants.DEFAULT_LOCALE;

          this.translate.use(useLanguage);
          this.documentsLang = useLanguage;

          if (
            response.errors.error?.code ===
            Number(UserNotificationConstants.notificationCodes.USER_NOT_AUTHORISED.value)
          ) {
            this.isUnauthorized = true;
          }

          if (
            response.errors.error?.code === Number(UserNotificationConstants.notificationCodes.LICENSE_EXPIRED.value)
          ) {
            this.isLicenseExpired = true;
          }

          this.checkLegal(response.errors.error?.code);
        } else {
          this.checkUserRole.emit(response.data);
          localStorage.setItem(CommonConstants.tenantId, response.data.tenantId);

          if (this.getDataOnlyFirstTime) {
            this.initFeatureTogglesData(response.data.features);
            this.getProductName();
            this.getSettings();
            this.requestAlarms.emit();
          }

          this.getDataOnlyFirstTime = false;
          this.isGDPRAccepted = true;

          if (!(CommonConstants.maintenanceDate in localStorage)) {
            this.maintenanceModeActions.requestMaintenanceDate();
          }
        }
      });
  }

  checkLegal(errorCode: number): void {
    if (errorCode === Number(NotificationConstants.commonCodes.TAC_NOT_ACCEPTED.value)) {
      this.isTaCAccepted = false;
      this.legalTermsActions.requestTACData();
      this.handleLegalResponse();
    }

    if (errorCode === Number(NotificationConstants.commonCodes.GDPR_NOT_ACCEPTED.value)) {
      this.isGDPRAccepted = false;
      this.legalTermsActions.requestGDPRData();
      this.handleLegalResponse();
    }

    if (errorCode === Number(NotificationConstants.commonCodes.DPA_NOT_ACCEPTED.value)) {
      this.isDPAAccepted = false;
      this.legalTermsActions.requestDPAData();
      this.handleLegalResponse();
    }

    if (this.isGDPRAccepted && this.isTaCAccepted && this.isDPAAccepted) {
      this.legalTermsAccepted = true;
    }
  }

  handleLegalResponse(): void {
    this.store
      .pipe(select(selectLegalTermsData), takeUntil(this.destroy))
      .subscribe((response: IStoreApiItem<ILegalTermsData>) => {
        if (response.data) {
          this.legalHyperLink = response.data.hyperlink;
          this.legalVersion = response.data.version;

          this.translate.use(response.data.userLanguage);
          this.documentsLang = response.data.userLanguage;
        }
      });

    this.store.pipe(select(selectAcceptLegalTerms), takeUntil(this.destroy)).subscribe((response: any) => {
      if (response.isSuccess) {
        this.router.navigate(['']);

        setTimeout(() => {
          this.reloadPage();
        }, 0);
      }
    });
  }

  reloadPage(): void {
    window.location.reload();
  }

  submitLegal(): void {
    if (!this.isTaCAccepted) {
      this.legalTermsActions.requestAcceptTAC(this.legalVersion ?? '');
    } else if (!this.isGDPRAccepted) {
      this.legalTermsActions.requestAcceptGDPR(this.legalVersion ?? '');
    } else if (!this.isDPAAccepted) {
      this.legalTermsActions.requestAcceptDPA(this.legalVersion ?? '');
    }
  }

  initFeatureTogglesData(features: string[]): void {
    const localFeatureList: string[] = Object.values(AppModulesTypes);

    const appFeatureList: Record<string, string> = features.reduce(
      (acc: any, feature: string) => ({ ...acc, [feature]: feature }),
      {},
    );

    this.featuresToggle = localFeatureList.map((featureName: string) => ({
      feature: featureName,
      isEnabled: !!appFeatureList[featureName],
    }));

    this.featuresToggleActions.setFeaturesToggle(this.featuresToggle);
    this.accessControlService.setFeaturesToggle(this.featuresToggle);
  }

  loadGoogleMapsApi(language: string, libraries: string[] = []): void {
    const apiKey: string = environment.GOOGLE_MAP.GOOGLE_API_KEY;

    if (typeof google === 'object' && typeof google.maps === 'object') {
      this.isMapLoaded = true;

      return;
    }

    const script: HTMLScriptElement = document.createElement('script');
    const params: URLSearchParams = new URLSearchParams();

    params.set('key', apiKey);

    if (libraries.length > 0) {
      params.set('libraries', libraries.join(','));
      params.set('language', language);
    }

    script.src = `https://maps.googleapis.com/maps/api/js?${params.toString()}`;
    script.async = true;
    script.defer = true;

    script.onerror = () => {
      this.isMapLoaded = false;
    };

    script.onload = () => {
      this.isMapLoaded = true;
    };

    document.head.appendChild(script);
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();

    this.translate.onLangChange.complete();
  }
}
