<odx-icon
  size="inline"
  name="chevron-up"
  class="ms-7 custom-icon-sort"
  iconSet="core"
  *ngIf="columnSorted?.order === 1 && columnSorted?.field === columnName"
></odx-icon>

<odx-icon
  size="inline"
  name="chevron-down"
  class="ms-7 custom-icon-sort"
  iconSet="core"
  *ngIf="columnSorted?.order === -1 && columnSorted?.field === columnName"
></odx-icon>

<odx-icon
  size="inline"
  name="chevron-down"
  iconSet="core"
  class="ms-7 custom-icon-sort"
  *ngIf="columnSorted?.field !== columnName"
></odx-icon>
