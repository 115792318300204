<form odxForm [formGroup]="equipmentForm" [readonly]="equipmentForm.disabled">
  <section odxLayout="grid" class="mb-5">
    <h2 class="mb-3" odxLayout="12">{{ 'MANUFACTURER.STR_MANUFACTURER_FORM_EQUIPMENT_DATA' | translate }}</h2>

    <div odxLayout="12 12@tablet 4@desktop" class="custom-form-field-pr-10" formGroupName="manufacturerData">
      <odx-form-field
        variant="horizontal"
        label="{{ 'MANUFACTURER.STR_MANUFACTURER_FORM_ORGANIZATION' | translate }}"
        [readonly]="equipmentForm.get('manufacturerData.organizationName').disabled"
      >
        <input
          type="text"
          odxFormFieldControl
          ignisDisableAutocomplete
          id="organizationName"
          data-test-id="manufacturer.manufacturer_form_organization"
          formControlName="organizationName"
        />
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label="{{ 'MANUFACTURER.STR_MANUFACTURER_FORM_MANUFACTURING_DATE' | translate }}"
        class="custom-datepicker-form-field"
        [ngClass]="{ 'custom-datepicker-form-field-for-task isReadOnly': disableAllFields }"
      >
        <ng-container [formGroup]="equipmentForm.get('manufacturerData')">
          <div class="w-100">
            <ignis-datepicker
              formControlName="manufacturerDate"
              data-test-id="manufacturer.manufacturing_date"
              ngDefaultControl
              [isDisabled]="equipmentForm.get('manufacturerData.manufacturerDate').disabled"
              appendTo="body"
              [selectionMode]="'single'"
              [showTime]="false"
              [formatDate]="formatDate"
              [defaultRange]="extractOnlyDate(equipmentForm.get('manufacturerData.manufacturerDate').value)"
              [maxDate]="maxDate"
              [clearable]="true"
              (dateSelect)="datepickerService.onDateChange($event, equipmentForm, 'manufacturerData.manufacturerDate')"
              (dateChange)="datepickerService.resetSelectedDate(equipmentForm, 'manufacturerData.manufacturerDate')"
            >
            </ignis-datepicker>
          </div>
        </ng-container>
      </odx-form-field>
    </div>

    <div odxLayout="12 12@tablet 4@desktop" class="custom-form-field-pl-10" formGroupName="manufacturerData">
      <odx-form-field
        variant="horizontal"
        label="{{ 'MANUFACTURER.STR_MANUFACTURER_FORM_CONTACT_NAME' | translate }}"
        [readonly]="equipmentForm.get('manufacturerData.contactName').disabled"
      >
        <input
          type="text"
          odxFormFieldControl
          ignisDisableAutocomplete
          id="contactName"
          data-test-id="manufacturer.manufacturer_form_contact_name"
          formControlName="contactName"
        />
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label="{{ 'MANUFACTURER.STR_MANUFACTURER_FORM_EMAIL' | translate }}"
        [readonly]="equipmentForm.get('manufacturerData.email').disabled"
      >
        <input
          type="text"
          odxFormFieldControl
          ignisDisableAutocomplete
          id="supplierContactEmail"
          data-test-id="manufacturer.manufacturer_form_email"
          formControlName="email"
        />
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label="{{ 'MANUFACTURER.STR_MANUFACTURER_FORM_PHONE' | translate }}"
        [readonly]="equipmentForm.get('manufacturerData.phone').disabled"
      >
        <input
          type="tel"
          odxFormFieldControl
          ignisDisableAutocomplete
          id="manufacturerContactPhone"
          data-test-id="manufacturer.manufacturer_form_phone"
          formControlName="phone"
        />
      </odx-form-field>
    </div>

    <h2 class="mb-3" odxLayout="12">{{ 'MANUFACTURER.STR_MANUFACTURER_FORM_SUPPLIER_DATA' | translate }}</h2>

    <div odxLayout="12 12@tablet 4@desktop" class="custom-form-field-pr-10" formGroupName="supplierData">
      <odx-form-field
        variant="horizontal"
        label=" {{ 'MANUFACTURER.STR_MANUFACTURER_FORM_ORGANIZATION' | translate }}"
        [readonly]="equipmentForm.get('manufacturerData.aggregateId').disabled"
      >
        <div class="w-100">
          <p-dropdown
            #dd4
            (onShow)="dropdownService.openDropdown(dd4)"
            (onHide)="dropdownService.closeDropdown(dd4)"
            (onClear)="onSupplierClear()"
            optionLabel="organizationName"
            optionValue="aggregateId"
            ngDefaultControl
            odxFormFieldControl
            class="custom-dropdown-form"
            formControlName="aggregateId"
            data-test-id="manufacturer.manufacturer_form_organization"
            [dropdownIcon]="dropdownIconCSSClass"
            [options]="suppliers"
            [placeholder]="'MANUFACTURER.STR_MANUFACTURER_FORM_SELECT_SUPPLIER' | translate"
            [emptyMessage]="'MANUFACTURER.STR_NO_RESULTS_FOUND' | translate"
            [showClear]="true"
            (onChange)="supplierChanged($event.value)"
            appendTo="body"
          >
            <ng-template let-option pTemplate="item">
              <span>{{ option.organizationName }}</span>
            </ng-template>
            <ng-template pTemplate="clearicon">
              <odx-icon name="close"></odx-icon>
            </ng-template>
          </p-dropdown>
        </div>
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label="{{ 'MANUFACTURER.STR_MANUFACTURER_FORM_SUPPLY_DATE' | translate }}"
        class="custom-datepicker-form-field"
        [ngClass]="{ 'custom-datepicker-form-field-for-task isReadOnly': disableAllFields }"
      >
        <ng-container [formGroup]="equipmentForm.get('supplierData')">
          <div class="w-100">
            <ignis-datepicker
              formControlName="supplierDate"
              data-test-id="manufacturer.supply_date"
              ngDefaultControl
              [isDisabled]="equipmentForm.get('supplierData.supplierDate').disabled"
              appendTo="body"
              [selectionMode]="'single'"
              [showTime]="false"
              [formatDate]="formatDate"
              [defaultRange]="extractOnlyDate(equipmentForm.get('supplierData.supplierDate').value)"
              [maxDate]="maxDate"
              [clearable]="true"
              (dateSelect)="datepickerService.onDateChange($event, equipmentForm, 'supplierData.supplierDate')"
              (dateChange)="datepickerService.resetSelectedDate(equipmentForm, 'supplierData.supplierDate')"
            >
            </ignis-datepicker>
          </div>
        </ng-container>
      </odx-form-field>
    </div>

    <div odxLayout="12 12@tablet 4@desktop" class="custom-form-field-pl-10" formGroupName="supplierData">
      <odx-form-field
        variant="horizontal"
        label="{{ 'MANUFACTURER.STR_MANUFACTURER_FORM_CONTACT_NAME' | translate }}"
        [readonly]="equipmentForm.get('supplierData.contactName').disabled"
      >
        <input
          type="tel"
          ignisDisableAutocomplete
          odxFormFieldControl
          id="supplierContactName"
          data-test-id="manufacturer.manufacturer_form_contact_name"
          formControlName="contactName"
        />
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label="{{ 'MANUFACTURER.STR_MANUFACTURER_FORM_EMAIL' | translate }}"
        [readonly]="equipmentForm.get('supplierData.email').disabled"
      >
        <input
          type="text"
          ignisDisableAutocomplete
          odxFormFieldControl
          id="supplierContactEmail"
          data-test-id="manufacturer.manufacturer_form_email"
          formControlName="email"
        />
      </odx-form-field>

      <odx-form-field
        variant="horizontal"
        label="{{ 'MANUFACTURER.STR_MANUFACTURER_FORM_PHONE' | translate }}"
        [readonly]="equipmentForm.get('supplierData.phone').disabled"
      >
        <input
          rel="tel"
          id="supplierContactPhone"
          odxFormFieldControl
          data-test-id="manufacturer.manufacturer_form_phone"
          formControlName="phone"
        />
      </odx-form-field>
    </div>
  </section>
</form>
