import { Location } from '@angular/common';
import { AfterViewInit, Component, HostListener, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ModalRef, ModalService, ModalSize, ModalVariant } from '@odx/angular/components/modal';
import { combineLatest, filter, map, Observable, take, takeUntil } from 'rxjs';
import { ApplicationState, CommonConstants, IStoreApiItem, IStoreApiList, PropertyBag } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { ConfigurationNotificationConstants } from 'src/app/configuration/constants';
import { ConfigurationModuleRoutes } from 'src/app/configuration/constants/configuration-module-routes.constants';
import { ConfigurationConstants } from 'src/app/configuration/constants/configuration.constants';
import {
  LocationConfigActions,
  LocationHierarchyActions,
  LocationTypeActions,
} from 'src/app/configuration/state/actions';
import { EquipmentNotificationConstants } from 'src/app/workshop';
import { DropdownService } from './../../../../common/services/dropdown/dropdown.service';
import { IEditLocationHierarchy, ILocationHierarchy } from './../../../models/location-configuration.model';
import {
  selectAddedLocationHierarchy,
  selectEditedLocationHierarchy,
  selectedLocationConfiguration,
  selectLocationTypeValues,
} from './../../../state/selectors/configuration.selectors';

@Component({
  selector: 'ignis-create-update-location-hierarchy',
  templateUrl: './create-update-location-hierarchy.component.html',
  styleUrls: ['./create-update-location-hierarchy.component.scss'],
})
export class CreateUpdateLocationHierarchyComponent extends OnDestroyMixin() implements OnInit, AfterViewInit {
  @ViewChild('locationHierarchyModal', { read: TemplateRef })
  public locationHierarchyModal: TemplateRef<any>;
  modalRef: Partial<ModalRef>;

  isLoading: boolean = false;
  errors: any;
  isConfirmCloseModalOpen: boolean = false;

  types: any[];
  locationTypesProperty: any[] = ConfigurationConstants.locationTypesProperty.properties;
  selectedType: any;
  selectedProperty: any;
  storedAddress: string;
  isSubmitting: Observable<boolean>;
  readonly ConfigurationModuleRoutes: PropertyBag = ConfigurationModuleRoutes;
  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;

  locationHierarchyForm: FormGroup = new FormGroup({
    parentAggregateId: new FormControl(null),
    aggregateId: new FormControl(null),
    name: new FormControl(null, [Validators.required, Validators.pattern(CommonConstants.emptyStringRegEx)]),
    type: new FormControl(null, [Validators.required]),
    property: new FormControl(null, [Validators.required]),
    identifier: new FormControl(null),
    address: new FormControl(null),
    details: new FormControl(null),
    version: new FormControl(null),
  });

  private readonly modalService: ModalService = inject(ModalService);
  locationTypeActions: LocationTypeActions = inject(LocationTypeActions);
  locationConfigActions: LocationConfigActions = inject(LocationConfigActions);
  locationHierarchyActions: LocationHierarchyActions = inject(LocationHierarchyActions);
  notificationsService: NotificationsService = inject(NotificationsService);
  translateService: TranslateService = inject(TranslateService);
  public dropdownService: DropdownService = inject(DropdownService);
  router: Router = inject(Router);
  route: ActivatedRoute = inject(ActivatedRoute);
  store: Store<ApplicationState> = inject(Store<ApplicationState>);
  location: Location = inject(Location);

  constructor() {
    super();
  }

  @HostListener(CommonConstants.beforeUnloadWindowEvent, ['$event'])
  handleBeforeUnload($event: any): void {
    if (this.hasUnsavedData()) {
      $event.returnValue = this.hasUnsavedData();
    }
  }

  ngOnInit(): void {
    this.processingTypeDropdownValues();
    this.isLoading = true;

    this.isSubmitting = combineLatest([
      this.store.select(selectAddedLocationHierarchy),
      this.store.select(selectEditedLocationHierarchy),
    ]).pipe(
      takeUntil(this.destroy),
      map(
        ([newHierarchy, editHierarchy]: [
          newHierarchy: IStoreApiItem<ILocationHierarchy>,
          editHierarchy: IStoreApiItem<IEditLocationHierarchy>,
        ]) => newHierarchy.isLoading || editHierarchy.isLoading,
      ),
    );
  }

  ngAfterViewInit(): void {
    this.openLocationHierarchyModal();
    this.readLocationConfigurationFromStore();
    this.requestOrPatchValue();
  }

  requestOrPatchValue(): void {
    this.route.params.pipe(takeUntil(this.destroy)).subscribe((params: Params) => {
      if (params.id !== 'root-node') {
        if (this.router.url.includes(ConfigurationModuleRoutes.updateLocationHierarchy)) {
          this.locationConfigActions.requestLocationConfiguration(params.id);
        } else {
          this.locationHierarchyForm.patchValue(null);
          this.locationHierarchyForm.patchValue({
            parentAggregateId: params.id,
            address: localStorage.getItem(ConfigurationConstants.tempParentAddress),
          });
          this.isLoading = false;
        }

        return;
      }

      this.isLoading = false;
    });
  }

  readLocationConfigurationFromStore(): void {
    this.store
      .pipe(
        select(selectedLocationConfiguration),
        filter((response: IStoreApiItem<IEditLocationHierarchy>) => !response.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((response: IStoreApiItem<IEditLocationHierarchy>) => {
        if (response.data) {
          this.selectedType = response.data.type;
          this.selectedProperty = response.data.property;

          this.storedAddress = response.data.address;
          this.locationHierarchyForm.patchValue(response.data);
          this.locationHierarchyForm.get('parentAggregateId').setValue(response.data.parentId);

          if (this.router.url.includes(ConfigurationModuleRoutes.createLocationHierarchy)) {
            this.locationHierarchyForm.patchValue({ address: response.data });
          }

          this.isLoading = false;
        }
      });
  }

  openLocationHierarchyModal(): void {
    this.modalRef = this.modalService.open(this.locationHierarchyModal, {
      size: ModalSize.MEDIUM,
      variant: ModalVariant.DEFAULT,
      dismissable: false,
      data: 'isOpen',
      dismissOnNavigation: false,
    });
  }

  closeModal(): void {
    if (this.locationHierarchyForm.dirty) {
      this.confirmCloseModalOpen();

      return;
    }

    this.modalRef.close('');
    this.location.back();

    Object.defineProperty(this.modalRef, 'data', { value: 'isClosed', writable: false });
  }

  canDeactivate(): boolean {
    if (this.locationHierarchyForm.dirty) {
      this.confirmCloseModalOpen();

      return false;
    }

    this.isConfirmCloseModalOpen = false;

    return true;
  }

  confirmCloseModalOpen(): void {
    this.isConfirmCloseModalOpen = true;
  }

  navigateBack(isOpen: boolean): void {
    if (!isOpen) {
      this.isConfirmCloseModalOpen = false;

      return;
    }

    this.locationHierarchyForm.markAsPristine();
    this.closeModal();
  }

  switchPropertyBasedOnType(event: { value: string }): void {
    const locationTypeInfo: any = this.types.find((type: any) => type.value === event.value);

    if (locationTypeInfo) {
      this.locationHierarchyForm.get('property').patchValue(locationTypeInfo.property);
    }
  }

  processingTypeDropdownValues(): void {
    this.locationTypeActions.requestLocationTypeValues();

    this.store
      .pipe(
        select(selectLocationTypeValues),
        filter((types: IStoreApiList<any[]>) => !types.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((types: IStoreApiList<any[]>) => {
        const localizedData: any[] = ConfigurationConstants.locationTypes.types;

        this.types = types.data
          ?.map((locationType: any) => {
            const foundConstant: any = localizedData.find(
              (constantType: any) => constantType.value === locationType.value,
            );

            return {
              ...locationType,
              label: this.translateService.instant(foundConstant?.localizedName || locationType.value),
              icon: foundConstant?.icon,
            };
          })
          .sort((typeOne: any, typeTwo: any) => {
            return typeOne.label.localeCompare(typeTwo.label, { sensitivity: 'accent' });
          });
      });
  }

  onSubmit(): void {
    if (this.router.url?.includes(ConfigurationModuleRoutes.createLocationHierarchy)) {
      this.addLocation();

      return;
    }

    this.editLocation();
  }

  addLocation(): void {
    this.locationHierarchyActions.requestAddLocationHierarchy(this.locationHierarchyForm.value);

    this.store
      .pipe(
        select(selectAddedLocationHierarchy),
        filter((newHierarchy: IStoreApiItem<ILocationHierarchy>) => !newHierarchy.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<ILocationHierarchy>) => {
        if (response.isSuccess) {
          localStorage.setItem('newLocationIsAdded', this.locationHierarchyForm.get('name').value);
          this.closeModalAfterSuccessResponse();
        } else if (response.errors) {
          this.errors = response.errors?.error.code.toString();
          this.notificationsService.requestShowNotification(this.checkNotificationType(), this.errors, {
            ...ConfigurationNotificationConstants.notificationCodes,
            ...EquipmentNotificationConstants.notificationCodes,
          });
        }
      });
  }

  editLocation(): void {
    this.locationHierarchyActions.requestEditLocationHierarchy(this.locationHierarchyForm.value);

    this.store
      .pipe(
        select(selectEditedLocationHierarchy),
        filter((editHierarchy: IStoreApiItem<IEditLocationHierarchy>) => !editHierarchy.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<IEditLocationHierarchy>) => {
        if (response.isSuccess) {
          this.closeModalAfterSuccessResponse();

          if (this.locationHierarchyForm.get('address').value?.length > 0) {
            localStorage.setItem(
              ConfigurationConstants.tempParentAddress,
              this.locationHierarchyForm.get('address').value,
            );
          }
        } else if (response.errors) {
          this.errors = response.errors.error?.code.toString();

          if (this.errors === ConfigurationNotificationConstants.notificationCodes.ENTITY_NOT_EXIST.value) {
            this.notificationsService.requestShowNotification(
              this.checkNotificationType(),
              ConfigurationNotificationConstants.notificationCodes.ENTITY_NOT_EXIST,
              ConfigurationNotificationConstants.notificationCodes,
            );

            this.locationHierarchyForm.markAsPristine();
            this.closeModal();
          } else {
            this.notificationsService.requestShowNotification(
              this.checkNotificationType(),
              this.errors,
              ConfigurationNotificationConstants.notificationCodes,
            );
          }
        }
      });
  }

  checkNotificationType(): string {
    if (
      this.errors === ConfigurationNotificationConstants.notificationCodes.NAME_SHOULD_BE_UNIQUE_AT_THE_SAME_LEVEL.value
    ) {
      this.locationHierarchyForm.get('name').setErrors({ invalid: true });

      return CommonConstants.notificationType.HIDDEN;
    }

    if (
      this.errors ===
      ConfigurationNotificationConstants.notificationCodes.CONFIGURATION_IDENTIFIER_SHOULD_BE_UNIQUE.value
    ) {
      this.locationHierarchyForm.get('identifier').setErrors({ invalid: true });

      return CommonConstants.notificationType.HIDDEN;
    }

    return CommonConstants.notificationType.ERROR;
  }

  closeModalAfterSuccessResponse(): void {
    localStorage.setItem(
      ConfigurationConstants.tempParentAggregateId,
      this.locationHierarchyForm.get('parentAggregateId').value,
    );

    this.locationHierarchyForm.markAsPristine();
    this.closeModal();
    this.locationHierarchyActions.requestLocationHierarchy();
  }

  hasUnsavedData(): boolean {
    return this.locationHierarchyForm.dirty;
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();
    this.locationConfigActions.resetLocationConfiguration();

    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (this.router.url !== 'configuration/location' && this.modalRef && this.modalRef['data'] === 'isOpen') {
      this.modalRef.close('');
    }
  }
}
