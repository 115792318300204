import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { IStoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IStoreApiList } from './../../../common/models/store-api-list.model';
import { ApplicationState, IApplicationState } from './../../../common/state/models/app.state.model';
import { IIncident, IIncidentEcp, IIncidentFilter, IMonitoringIncidents, INote } from './../../models/remote-monitoring.model';
import { IRemoteMonitoringState } from '../models/remote-monitoring.model';



const remoteMonitoringState = (state: IApplicationState) => state.remoteMonitoring;

export const selectMonitoringIncidents: MemoizedSelector<
  ApplicationState,
  IStoreApiList<IMonitoringIncidents>,
  DefaultProjectorFn<IStoreApiList<IMonitoringIncidents>>
> = createSelector(remoteMonitoringState, (state: IRemoteMonitoringState) => state.incident.monitoringIncidentList);

export const selectIncident: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IIncident>,
  DefaultProjectorFn<IStoreApiItem<IIncident>>
> = createSelector(remoteMonitoringState, (state: IRemoteMonitoringState) => state.incident.selectedIncident);

export const selectIncidentEcp: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IIncidentEcp>,
  DefaultProjectorFn<IStoreApiItem<IIncidentEcp>>
> = createSelector(remoteMonitoringState, (state: IRemoteMonitoringState) => state.incident.selectedEcp);

export const selectNotesList: MemoizedSelector<
  ApplicationState,
  IStoreApiList<INote[]>,
  DefaultProjectorFn<IStoreApiList<INote[]>>
> = createSelector(remoteMonitoringState, (state: IRemoteMonitoringState) => state.notes.notesList);

export const selectReadNotes: MemoizedSelector<
  ApplicationState,
  IStoreApiList<Partial<INote>[]>,
  DefaultProjectorFn<IStoreApiList<Partial<INote>[]>>
> = createSelector(remoteMonitoringState, (state: IRemoteMonitoringState) => state.notes.readNotes);

export const selectFilterParams: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IIncidentFilter>,
  DefaultProjectorFn<IStoreApiItem<IIncidentFilter>>
> = createSelector(remoteMonitoringState, (state: IRemoteMonitoringState) => state.filter.filterParams);

export const selectEntries: MemoizedSelector<
  ApplicationState,
  IStoreApiList<any>,
  DefaultProjectorFn<IStoreApiList<any>>
> = createSelector(remoteMonitoringState, (state: IRemoteMonitoringState) => state.entries.entriesList);

export const selectClosedIncident: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<any>,
  DefaultProjectorFn<IStoreApiItem<any>>
> = createSelector(remoteMonitoringState, (state: IRemoteMonitoringState) => state.incident.closedIncident);

export const selectMergeIncidents: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<any>,
  DefaultProjectorFn<IStoreApiItem<any>>
> = createSelector(remoteMonitoringState, (state: IRemoteMonitoringState) => state.incidentsMerge.merged);

export const selectNewNote: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<any>,
  DefaultProjectorFn<IStoreApiItem<any>>
> = createSelector(remoteMonitoringState, (state: IRemoteMonitoringState) => state.notes.newNote);
