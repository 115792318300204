import { INotificationConstant } from 'src/app/common';

export class BAWearerNotificationConstants {
  public static readonly notificationCodes: INotificationConstant = {
    ADD_BA_WEARER_SUCCESS: {
      value: 'ADD_BA_WEARER_SUCCESS',
      text: 'BA_WEARER_NOTIFICATION.STR_BA_WEARER_SAVED_SUCCESS',
    },
    UPDATE_BA_WEARER_SUCCESS: {
      value: 'UPDATE_BA_WEARER_SUCCESS',
      text: 'BA_WEARER_NOTIFICATION.STR_BA_WEARER_UPDATED_SUCCESS',
    },
    DELETE_BA_WEARER_SUCCESS: {
      value: 'DELETE_BA_WEARER_SUCCESS',
      text: 'BA_WEARER_NOTIFICATION.STR_DELETE_BA_WEARER_SUCCESS',
    },
    UNEXPECTED_ERROR: {
      value: '7001',
      text: 'BA_WEARER_NOTIFICATION.STR_UNEXPECTED_ERROR',
    },
    ENTITY_NOT_FOUND: {
      value: '7002',
      text: 'BA_WEARER_NOTIFICATION.STR_ENTITY_NOT_FOUND',
    },
    AGGREGATE_VERSION_CONFLICT: {
      value: '7003',
      text: 'BA_WEARER_NOTIFICATION.STR_AGGREGATE_VERSION_CONFLICT',
    },
    NOT_UNIQUE_PERSONAL_ID: {
      value: '7025',
      text: 'BA_WEARER_NOTIFICATION.STR_NOT_UNIQUE_PERSONAL_ID',
    },
    NOT_UNIQUE_RFID_CARD: {
      value: '7026',
      text: 'BA_WEARER_NOTIFICATION.STR_NOT_UNIQUE_RFID_CARD',
    },
    UPLOAD_BA_WEARERS_CSV_SUCCESS: {
      value: 'UPLOAD_BA_WEARERS_CSV_SUCCESS',
      text: 'BA_WEARER_NOTIFICATION.UPLOAD_BA_WEARERS_CSV_SUCCESS',
    },
    CSV_FILE_FORMAT_INCORRECT: {
      value: '102',
      text: 'BA_WEARER_NOTIFICATION.STR_CSV_FILE_FORMAT_INCORRECT',
    },
    CSV_IMPORT_FAIL: {
      value: '103',
      text: 'BA_WEARER_NOTIFICATION.STR_CSV_IMPORT_FAIL',
    },
    CSV_REQUEST_REJECTED: {
      value: '104',
      text: 'BA_WEARER_NOTIFICATION.STR_CSV_REQUEST_REJECTED',
    },
    LARGE_CSV_FILE: {
      value: '7028',
      text: 'EQUIPMENT_NOTIFICATION.STR_INVENTORY_LARGE_CSV_FILE',
    },
  };
}
