import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IEquipmentInitialSpecification } from 'src/app/workshop/models/equipment-initial-specification.model';
import { IEquipmentIdentifiers } from 'src/app/workshop/models/test-results.model';
import { EquipmentInitialSpecificationsActionTypes } from './equipment-initial-specs-action-types';

export class RequestInitialEquipmentSpecification implements Action {
  readonly type: EquipmentInitialSpecificationsActionTypes.REQUEST_INITIAL_SPECIFICATION =
    EquipmentInitialSpecificationsActionTypes.REQUEST_INITIAL_SPECIFICATION;
}
export class ReceiveInitialEquipmentSpecification implements Action {
  readonly type: EquipmentInitialSpecificationsActionTypes.RECEIVE_INITIAL_SPECIFICATION =
    EquipmentInitialSpecificationsActionTypes.RECEIVE_INITIAL_SPECIFICATION;

  constructor(public payload: IEquipmentInitialSpecification[]) {}
}
export class ErrorInitialEquipmentSpecification implements Action {
  readonly type: EquipmentInitialSpecificationsActionTypes.ERROR_INITIAL_SPECIFICATION =
    EquipmentInitialSpecificationsActionTypes.ERROR_INITIAL_SPECIFICATION;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestCheckingBarcodeOrRFIDIsUnique implements Action {
  readonly type: EquipmentInitialSpecificationsActionTypes.REQUEST_CHECKING_BARCODE_OR_RFID_IS_UNIQUE =
    EquipmentInitialSpecificationsActionTypes.REQUEST_CHECKING_BARCODE_OR_RFID_IS_UNIQUE;

  constructor(public payload: IEquipmentIdentifiers) {}
}

export class ReceiveCheckingBarcodeOrRFIDIsUnique implements Action {
  readonly type: EquipmentInitialSpecificationsActionTypes.RECEIVE_CHECKING_BARCODE_OR_RFID_IS_UNIQUE =
    EquipmentInitialSpecificationsActionTypes.RECEIVE_CHECKING_BARCODE_OR_RFID_IS_UNIQUE;

  constructor(public payload: any) {}
}
export class ErrorReceiveCheckingBarcodeOrRFIDIsUnique implements Action {
  readonly type: EquipmentInitialSpecificationsActionTypes.ERROR_RECEIVE_CHECKING_BARCODE_OR_RFID_IS_UNIQUE =
    EquipmentInitialSpecificationsActionTypes.ERROR_RECEIVE_CHECKING_BARCODE_OR_RFID_IS_UNIQUE;

  constructor(public payload: HttpErrorResponse) {}
}
export class ResetCheckingBarcodeOrRFIDIsUnique implements Action {
  readonly type: EquipmentInitialSpecificationsActionTypes.RESET_CHECKING_BARCODE_OR_RFID_IS_UNIQUE =
    EquipmentInitialSpecificationsActionTypes.RESET_CHECKING_BARCODE_OR_RFID_IS_UNIQUE;
}
