import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IChecklists, ISaveBatchChecklist } from 'src/app/configuration/models/checklist.model';
import { ITask } from 'src/app/configuration/models/equipment-configuration.models';
import {
  ICylinderChargerRequest,
  IIntervalData,
  ILocationChange,
  IServiceTaskPage,
} from '../../../models/service-task.model';
import { EquipmentServiceTaskActionTypes } from './equipment-service-task-action-types';

export class RequestServiceTaskExport implements Action {
  readonly type: EquipmentServiceTaskActionTypes.REQUEST_SERVICE_TASK_EXPORT =
    EquipmentServiceTaskActionTypes.REQUEST_SERVICE_TASK_EXPORT;

  constructor(public payload?: any) {}
}
export class RequestServiceTaskPage implements Action {
  readonly type: EquipmentServiceTaskActionTypes.REQUEST_SERVICE_TASK_PAGE =
    EquipmentServiceTaskActionTypes.REQUEST_SERVICE_TASK_PAGE;

  constructor(public payload?: any) {}
}
export class ReceiveServiceTaskPage implements Action {
  readonly type: EquipmentServiceTaskActionTypes.RECEIVE_SERVICE_TASK_PAGE =
    EquipmentServiceTaskActionTypes.RECEIVE_SERVICE_TASK_PAGE;

  constructor(public payload: IServiceTaskPage) {}
}
export class ErrorReceiveServiceTaskPage implements Action {
  readonly type: EquipmentServiceTaskActionTypes.ERROR_RECEIVE_SERVICE_TASK_PAGE =
    EquipmentServiceTaskActionTypes.ERROR_RECEIVE_SERVICE_TASK_PAGE;

  constructor(public payload: HttpErrorResponse) {}
}

export class RequestChecklistsData implements Action {
  readonly type: EquipmentServiceTaskActionTypes.REQUEST_CHECKLISTS_DATA =
    EquipmentServiceTaskActionTypes.REQUEST_CHECKLISTS_DATA;

  constructor(public payload: string[]) {}
}
export class ReceiveChecklistsData implements Action {
  readonly type: EquipmentServiceTaskActionTypes.RECEIVE_CHECKLISTS_DATA =
    EquipmentServiceTaskActionTypes.RECEIVE_CHECKLISTS_DATA;

  constructor(public payload: IChecklists) {}
}
export class ErrorReceiveChecklistsData implements Action {
  readonly type: EquipmentServiceTaskActionTypes.ERROR_RECEIVE_CHECKLISTS_DATA =
    EquipmentServiceTaskActionTypes.ERROR_RECEIVE_CHECKLISTS_DATA;

  constructor(public payload: HttpErrorResponse) {}
}
export class ResetRequestChecklistsData implements Action {
  readonly type: EquipmentServiceTaskActionTypes.RESET_REQUEST_CHECKLISTS_DATA =
    EquipmentServiceTaskActionTypes.RESET_REQUEST_CHECKLISTS_DATA;
}

export class RequestSendServiceIntervals implements Action {
  readonly type: EquipmentServiceTaskActionTypes.REQUEST_SEND_SERVICE_INTERVALS =
    EquipmentServiceTaskActionTypes.REQUEST_SEND_SERVICE_INTERVALS;

  constructor(public payload: ICylinderChargerRequest) {}
}
export class ReceiveSendServiceIntervals implements Action {
  readonly type: EquipmentServiceTaskActionTypes.RECEIVE_SEND_SERVICE_INTERVALS =
    EquipmentServiceTaskActionTypes.RECEIVE_SEND_SERVICE_INTERVALS;

  constructor(public payload: any) {}
}
export class ErrorReceiveSendServiceIntervals implements Action {
  readonly type: EquipmentServiceTaskActionTypes.ERROR_RECEIVE_SEND_SERVICE_INTERVALS =
    EquipmentServiceTaskActionTypes.ERROR_RECEIVE_SEND_SERVICE_INTERVALS;

  constructor(public payload: HttpErrorResponse) {}
}
export class ResetSendServiceIntervals implements Action {
  readonly type: EquipmentServiceTaskActionTypes.RESET_SEND_SERVICE_INTERVALS =
    EquipmentServiceTaskActionTypes.RESET_SEND_SERVICE_INTERVALS;
}

export class RequestUpdateEquipmentLocation implements Action {
  readonly type: EquipmentServiceTaskActionTypes.REQUEST_UPDATE_EQUIPMENT_LOCATION =
    EquipmentServiceTaskActionTypes.REQUEST_UPDATE_EQUIPMENT_LOCATION;

  constructor(public payload: ILocationChange) {}
}
export class ReceiveUpdateEquipmentLocation implements Action {
  readonly type: EquipmentServiceTaskActionTypes.RECEIVE_UPDATE_EQUIPMENT_LOCATION =
    EquipmentServiceTaskActionTypes.RECEIVE_UPDATE_EQUIPMENT_LOCATION;

  constructor(public payload: ILocationChange) {}
}
export class ErrorReceiveUpdateEquipmentLocation implements Action {
  readonly type: EquipmentServiceTaskActionTypes.ERROR_RECEIVE_UPDATE_EQUIPMENT_LOCATION =
    EquipmentServiceTaskActionTypes.ERROR_RECEIVE_UPDATE_EQUIPMENT_LOCATION;

  constructor(public payload: HttpErrorResponse) {}
}
export class ResetUpdateEquipmentLocation implements Action {
  readonly type: EquipmentServiceTaskActionTypes.RESET_UPDATE_EQUIPMENT_LOCATION =
    EquipmentServiceTaskActionTypes.RESET_UPDATE_EQUIPMENT_LOCATION;
}

export class RequestUpdateEquipmentLocationDuringBarcodeSearch implements Action {
  readonly type: EquipmentServiceTaskActionTypes.REQUEST_UPDATE_EQUIPMENT_LOCATION_DURING_BARCODE_SEARCH =
    EquipmentServiceTaskActionTypes.REQUEST_UPDATE_EQUIPMENT_LOCATION_DURING_BARCODE_SEARCH;

  constructor(public payload: ILocationChange) {}
}
export class ReceiveUpdateEquipmentLocationDuringBarcodeSearch implements Action {
  readonly type: EquipmentServiceTaskActionTypes.RECEIVE_UPDATE_EQUIPMENT_LOCATION_DURING_BARCODE_SEARCH =
    EquipmentServiceTaskActionTypes.RECEIVE_UPDATE_EQUIPMENT_LOCATION_DURING_BARCODE_SEARCH;

  constructor(public payload: ILocationChange) {}
}
export class ErrorReceiveUpdateEquipmentLocationDuringBarcodeSearch implements Action {
  readonly type: EquipmentServiceTaskActionTypes.ERROR_RECEIVE_UPDATE_EQUIPMENT_LOCATION_DURING_BARCODE_SEARCH =
    EquipmentServiceTaskActionTypes.ERROR_RECEIVE_UPDATE_EQUIPMENT_LOCATION_DURING_BARCODE_SEARCH;

  constructor(public payload: HttpErrorResponse) {}
}

export class SaveSelectedServiceEquipment implements Action {
  readonly type: EquipmentServiceTaskActionTypes.SAVE_SELECTED_SERVICE_EQUIPMENT =
    EquipmentServiceTaskActionTypes.SAVE_SELECTED_SERVICE_EQUIPMENT;

  constructor(public payload?: IIntervalData[]) {}
}

export class RequestSaveServicesChecklist implements Action {
  readonly type: EquipmentServiceTaskActionTypes.REQUEST_SAVE_SERVICE_DEFINITION_CHECKLIST =
    EquipmentServiceTaskActionTypes.REQUEST_SAVE_SERVICE_DEFINITION_CHECKLIST;

  constructor(public payload: ITask | any) {}
}
export class ReceiveSaveServicesChecklist implements Action {
  readonly type: EquipmentServiceTaskActionTypes.RECEIVE_SAVE_SERVICE_DEFINITION_CHECKLIST =
    EquipmentServiceTaskActionTypes.RECEIVE_SAVE_SERVICE_DEFINITION_CHECKLIST;

  constructor(public payload: ITask | any) {}
}
export class ErrorReceiveSaveServicesChecklist implements Action {
  readonly type: EquipmentServiceTaskActionTypes.ERROR_RECEIVE_SAVE_SERVICE_DEFINITION_CHECKLIST =
    EquipmentServiceTaskActionTypes.ERROR_RECEIVE_SAVE_SERVICE_DEFINITION_CHECKLIST;

  constructor(public payload: HttpErrorResponse) {}
}
export class ResetSaveServicesChecklist implements Action {
  readonly type: EquipmentServiceTaskActionTypes.RESET_SAVE_SERVICE_DEFINITION_CHECKLIST =
    EquipmentServiceTaskActionTypes.RESET_SAVE_SERVICE_DEFINITION_CHECKLIST;
}

export class RequestSaveBatchChecklist implements Action {
  readonly type: EquipmentServiceTaskActionTypes.REQUEST_SAVE_BATCH_CHECKLIST =
    EquipmentServiceTaskActionTypes.REQUEST_SAVE_BATCH_CHECKLIST;

  constructor(public payload: ISaveBatchChecklist) {}
}
export class ReceiveSaveBatchChecklist implements Action {
  readonly type: EquipmentServiceTaskActionTypes.RECEIVE_SAVE_BATCH_CHECKLIST =
    EquipmentServiceTaskActionTypes.RECEIVE_SAVE_BATCH_CHECKLIST;

  constructor(public payload: ISaveBatchChecklist) {}
}
export class ErrorReceiveSaveBatchChecklist implements Action {
  readonly type: EquipmentServiceTaskActionTypes.ERROR_RECEIVE_SAVE_BATCH_CHECKLIST =
    EquipmentServiceTaskActionTypes.ERROR_RECEIVE_SAVE_BATCH_CHECKLIST;

  constructor(public payload: HttpErrorResponse) {}
}
export class ResetSaveBatchChecklist implements Action {
  readonly type: EquipmentServiceTaskActionTypes.RESET_SAVE_BATCH_CHECKLIST =
    EquipmentServiceTaskActionTypes.RESET_SAVE_BATCH_CHECKLIST;
}
