import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { IApplicationState, IStoreApiItem, IStoreApiList } from 'src/app/common';
import { IChecklists, ISaveBatchChecklist, ITask } from 'src/app/configuration/models';
import * as models from '../../models';
import { IEquipmentState } from '../models';

export const equipmentState = (state: IApplicationState) => state.equipment;

export const selectEquipmentListPage: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<models.IEquipmentListPage>,
  DefaultProjectorFn<IStoreApiItem<models.IEquipmentListPage>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.equipmentData.equipmentPage);

export const selectEquipmentItem: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<models.IEquipment>,
  DefaultProjectorFn<IStoreApiItem<models.IEquipment>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.equipmentData.selectedEquipment);

export const selectSavedEquipments: MemoizedSelector<
  IApplicationState,
  IStoreApiList<models.IEquipment[]>,
  DefaultProjectorFn<IStoreApiList<models.IEquipment[]>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.equipmentData.savedEquipments);

export const selectSavedLocationEquipments: MemoizedSelector<
  IApplicationState,
  IStoreApiList<models.IEquipment[]>,
  DefaultProjectorFn<IStoreApiList<models.IEquipment[]>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.equipmentData.savedLocationEquipments);

export const selectDeletedEquipment: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<models.IEquipment>,
  DefaultProjectorFn<IStoreApiItem<models.IEquipment>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.equipmentData.deletedEquipment);

export const selectTestEquipmentInProgress: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<models.ITestStatus>,
  DefaultProjectorFn<IStoreApiItem<models.ITestStatus>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.testing.isTestingInProgress);

export const selectSaveTest: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<models.ISaveTestResults>,
  DefaultProjectorFn<IStoreApiItem<models.ISaveTestResults>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.testing.saveTest);

export const selectTestResults: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<models.ITestResultDetails>,
  DefaultProjectorFn<IStoreApiItem<models.ITestResultDetails>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.testing.testResults);

export const selectStartTest: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<models.ITestStartResponse>,
  DefaultProjectorFn<IStoreApiItem<models.ITestStartResponse>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.testing.startTest);

export const selectTestParameters: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<models.ITestParameters>,
  DefaultProjectorFn<IStoreApiItem<models.ITestParameters>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.testing.testParameters);

export const selectEquipmentSpecification: MemoizedSelector<
  IApplicationState,
  IStoreApiList<models.IEquipmentInitialSpecification[]>,
  DefaultProjectorFn<IStoreApiList<models.IEquipmentInitialSpecification[]>>
> = createSelector(
  equipmentState,
  (state: IEquipmentState) => state.equipmentInitialSpec.equipmentInitialSpecification,
);

export const selectTestResultsPage: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<models.ITestResultPage>,
  DefaultProjectorFn<IStoreApiItem<models.ITestResultPage>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.testing.testResultsPage);

export const selectTestResultsById: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<models.ITestResultDetails>,
  DefaultProjectorFn<IStoreApiItem<models.ITestResultDetails>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.testing.testResultsById);

export const selectWorkflowAsset: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<models.IEquipment>,
  DefaultProjectorFn<IStoreApiItem<models.IEquipment>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.testing.workflowAsset);

export const selectServiceTaskPage: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<models.IServiceTaskPage>,
  DefaultProjectorFn<IStoreApiItem<models.IServiceTaskPage>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.serviceTask.serviceTaskPage);

export const selectSelectedServiceEquipment: MemoizedSelector<
  IApplicationState,
  IStoreApiList<models.IIntervalData[]>,
  DefaultProjectorFn<IStoreApiList<models.IIntervalData[]>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.serviceTask.selectedServiceEquipment);

export const selectSendServiceInterval: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<models.ICylinderChargerRequest>,
  DefaultProjectorFn<IStoreApiItem<models.ICylinderChargerRequest>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.serviceTask.sendServiceIntervals);

export const selectUpdateEquipmentsLocation: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<models.ILocationChange>,
  DefaultProjectorFn<IStoreApiItem<models.ILocationChange>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.serviceTask.updateEquipmentsLocation);

export const selectUpdateEquipmentLocationDuringBarcodeSearch: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<models.ILocationChange>,
  DefaultProjectorFn<IStoreApiItem<models.ILocationChange>>
> = createSelector(
  equipmentState,
  (state: IEquipmentState) => state.serviceTask.updateEquipmentLocationDuringBarcodeSearch,
);

export const selectEquipmentHistory: MemoizedSelector<
  IApplicationState,
  IStoreApiList<models.IEquipmentHistoryEvent[]>,
  DefaultProjectorFn<IStoreApiList<models.IEquipmentHistoryEvent[]>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.equipmentHistory.equipmentHistory);

export const selectHistoryFilters: MemoizedSelector<
  IApplicationState,
  IStoreApiList<models.IEquipmentHistoryFilters>,
  DefaultProjectorFn<IStoreApiList<models.IEquipmentHistoryFilters>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.equipmentHistory.historyFilters);

export const selectEquipmentCSVFile: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<string>,
  DefaultProjectorFn<IStoreApiItem<string>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.equipmentData.csv);

export const selectRAMConnectorToken: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<models.IToken>,
  DefaultProjectorFn<IStoreApiItem<models.IToken>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.ramConnector.ramConnectorToken);

export const selectChecklistsData: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<IChecklists>,
  DefaultProjectorFn<IStoreApiItem<IChecklists>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.serviceTask.checklists);

export const selectedSavedServicesChecklist: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<ITask | any>,
  DefaultProjectorFn<IStoreApiItem<ITask | any>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.serviceTask.sendServicesChecklist);

export const selectSaveBatchChecklist: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<ISaveBatchChecklist>,
  DefaultProjectorFn<IStoreApiItem<ISaveBatchChecklist>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.serviceTask.saveBatchChecklist);

export const selectEquipmentLicenseInfo: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<models.IEquipmentLicenseInfo>,
  DefaultProjectorFn<IStoreApiItem<models.IEquipmentLicenseInfo>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.licenseInfo.licenseInfo);

export const selectUpdatedEquipmentIds: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<string[]>,
  DefaultProjectorFn<IStoreApiItem<string[]>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.updateEquipmentFields.equipmentIds);

export const selectCheckRAMConnectorStatus: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<models.ITestStatus>,
  DefaultProjectorFn<IStoreApiItem<models.ITestStatus>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.ramConnector.checkRAMConnectorStatus);

export const selectCheckRAMPortStatus: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<models.IRAMStatus>,
  DefaultProjectorFn<IStoreApiItem<models.IRAMStatus>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.ramConnector.ramStatus);

export const selectEquipmentIdentifiersUniqueness: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<string[]>,
  DefaultProjectorFn<IStoreApiItem<string[]>>
> = createSelector(
  equipmentState,
  (state: IEquipmentState) => state.equipmentInitialSpec.equipmentIdentifiersAreUnique,
);

export const selectRAMAutoUpdate: MemoizedSelector<
  IApplicationState,
  IStoreApiItem<object>,
  DefaultProjectorFn<IStoreApiItem<object>>
> = createSelector(equipmentState, (state: IEquipmentState) => state.ramConnector.autoUpdate);
