import { Component, inject, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, takeUntil } from 'rxjs';
import { IStoreApiList } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { ApplicationState } from 'src/app/common/state/models/app.state.model';
import { OrganizationSettingsActions } from 'src/app/configuration/state/actions';
import { AppModulesTypes } from 'src/app/root/models/app-types';
import { IFeatureToggle } from 'src/app/root/state/features-toggle/models/features-toggle.model';
import { selectFeaturesToggleList } from 'src/app/root/state/features-toggle/selectors/features-toggle.selector';
import { getDateFormatFromUserProfile } from '../../../common/utils/settings-utils/settings-utils';

@Component({
  selector: 'ignis-device-connection-management',
  templateUrl: './device-connection-management.component.html',
})
export class DeviceConnectionManagementComponent extends OnDestroyMixin() implements OnInit {
  formatDate: string;
  draegerwareWorkshopFeatureToggle: Observable<IFeatureToggle>;
  ramConnectorFeatureToggle: Observable<IFeatureToggle>;
  workshopFeatureToggle: Observable<IFeatureToggle>;
  baWearerFeatureToggle: Observable<IFeatureToggle>;

  organizationSettingsActions: OrganizationSettingsActions =
    inject<OrganizationSettingsActions>(OrganizationSettingsActions);
  store: Store<ApplicationState> = inject<Store<ApplicationState>>(Store<ApplicationState>);
  translateService: TranslateService = inject<TranslateService>(TranslateService);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.formatDate = getDateFormatFromUserProfile(this.translateService);

    this.draegerwareWorkshopFeatureToggle = this.store.pipe(
      select(selectFeaturesToggleList),
      map((state: IStoreApiList<IFeatureToggle[]>) =>
        state.data?.find((ft: IFeatureToggle) => Object.values(ft).includes(AppModulesTypes.draegerwareWorkshop)),
      ),
      takeUntil(this.destroy),
    );

    this.ramConnectorFeatureToggle = this.store.pipe(
      select(selectFeaturesToggleList),
      map((state: IStoreApiList<IFeatureToggle[]>) =>
        state.data?.find((ft: IFeatureToggle) => Object.values(ft).includes(AppModulesTypes.ramConnector)),
      ),
      takeUntil(this.destroy),
    );

    this.baWearerFeatureToggle = this.store.pipe(
      select(selectFeaturesToggleList),
      map((state: IStoreApiList<IFeatureToggle[]>) =>
        state.data?.find((ft: IFeatureToggle) => Object.values(ft).includes(AppModulesTypes.baWearer)),
      ),
      takeUntil(this.destroy),
    );

    this.workshopFeatureToggle = this.store.pipe(
      select(selectFeaturesToggleList),
      map((state: IStoreApiList<IFeatureToggle[]>) =>
        state.data?.find((ft: IFeatureToggle) => Object.values(ft).includes(AppModulesTypes.workshop)),
      ),
      takeUntil(this.destroy),
    );
  }
}
