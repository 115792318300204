import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PropertyBag } from 'src/app/common/models/common.model';
import { IChecklist } from 'src/app/configuration/models/checklist.model';
import { WorkshopConstants } from 'src/app/workshop';
import { ITestValue } from 'src/app/workshop/models/test-results.model';

@Component({
  selector: 'ignis-values-tab',
  templateUrl: './values-tab.component.html',
  styleUrls: ['./values-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValuesTabComponent {
  @Input() equipmentTaskResultsForm: FormGroup;
  @Input() formatDate: string;
  @Input() checklistData: Partial<IChecklist>;
  @Input() taskValues: ITestValue[];
  @Input() testType: string;

  taskValuesTypes: PropertyBag = WorkshopConstants.taskValuesType;
}
