<ng-template odxModal #timelineFilterModal let-modal="close">
  <odx-modal-header id="timeline-filter-modal--header">
    <odx-area-header>
      {{ 'EQUIPMENT_HISTORY.STR_FILTERS' | translate }}
    </odx-area-header>
  </odx-modal-header>
  <odx-modal-content [ngStyle]="{ overflow: 'visible' }">
    <div class="row timeline-filters">
      <div class="col-md-6 col-sm-12">
        <p-tree
          [value]="timelineFilters[0]"
          selectionMode="checkbox"
          [(selection)]="selectedFilters"
          class="timeline-filter-tree"
          [class]="currentAppTheme"
          data-test-id="equipment_history.filter_left_side_options"
        >
          <ng-template let-node pTemplate="default">
            <odx-icon [name]="node.icon"></odx-icon>
            {{ node.label | translate }}
          </ng-template></p-tree
        >
      </div>
      <div class="col-md-6 col-sm-12">
        <p-tree
          [value]="timelineFilters[1]"
          selectionMode="checkbox"
          [(selection)]="selectedFilters"
          class="timeline-filter-tree"
          [class]="currentAppTheme"
          data-test-id="equipment_history.filter_right_side_options"
        >
          <ng-template let-node pTemplate="default">
            <odx-icon [name]="node.icon"></odx-icon>
            {{ node.label | translate }}
          </ng-template>
        </p-tree>
      </div>
    </div>
  </odx-modal-content>
  <odx-modal-footer class="equipment-timeline-filter">
    <div class="equipment-timeline-filter-footer-btns">
      <button
        odxButton
        (click)="onResetFilters()"
        class="reset-button"
        [class]="currentAppTheme"
        variant="ghost"
        id="history-filter--reset"
        data-test-id="modal_actions.clear_btn"
      >
        <odx-icon name="reload" alignLeft></odx-icon>
        {{ 'EQUIPMENT_HISTORY.STR_FILTERS_CLEAR_FILTERS_BUTTON' | translate }}
      </button>

      <button
        odxButton
        (click)="closeModal()"
        variant="secondary"
        id="history-filter--cancel"
        class="ms-auto mr-15"
        data-test-id="modal_actions.cancel_btn"
      >
        {{ 'EQUIPMENT_HISTORY.STR_FILTERS_CANCEL_BUTTON' | translate }}
      </button>

      <button
        odxButton
        variant="primary"
        (click)="onSubmit()"
        type="button"
        id="history-filter--save"
        data-test-id="modal_actions.save_btn"
      >
        {{ 'EQUIPMENT_HISTORY.STR_FILTERS_SAVE_BUTTON' | translate }}
      </button>
    </div>
  </odx-modal-footer>
</ng-template>
