<ng-template odxModal #confirmationModalTemplate>
  <odx-modal-hero icon="warning" variant="confirmation">
    {{ 'CHANGE_DATA_CONFIRMATION_DIALOG.STR_TITLE' | translate }}
  </odx-modal-hero>
  <odx-modal-content>
    <ng-content select="[slot=content]"></ng-content>
  </odx-modal-content>
  <odx-modal-footer>
    <button
      odxButton
      variant="secondary"
      odxModalClose
      data-test-id="confirm_close_modal.cancel_btn"
      (click)="cancelChange()"
    >
      {{ 'CONFIRM_CLOSE_MODAL.STR_CANCEL_BTN' | translate }}
    </button>

    @if (displayProceedBtn) {
      <button odxButton odxModalClose data-test-id="confirm_close_modal.discard_btn" (click)="discardChange()">
        {{ 'CONFIRM_CLOSE_MODAL.STR_PROCEED_BTN' | translate }}
      </button>
    } @else {
      <button
        odxButton
        variant="primary"
        odxModalClose
        data-test-id="confirm_close_modal.discard_btn"
        (click)="discardChange()"
      >
        {{ 'CONFIRM_CLOSE_MODAL.STR_DISCARD_BTN' | translate }}
      </button>
    }
  </odx-modal-footer>
</ng-template>
