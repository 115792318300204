import { CommonModule as AngularCommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
  withJsonpSupport,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { HammerModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '@odx/angular';
import { BarModule } from '@odx/angular/components/bar';
import { ButtonComponent } from '@odx/angular/components/button';
import { CheckboxModule } from '@odx/angular/components/checkbox';
import { FormFieldModule } from '@odx/angular/components/form-field';
import { IconComponent, IconConfig } from '@odx/angular/components/icon';
import { LoadingSpinnerModule } from '@odx/angular/components/loading-spinner';
import { ModalModule } from '@odx/angular/components/modal';
import { NavigationBackComponent } from '@odx/angular/components/navigation-back';
import { ProgressComponent } from '@odx/angular/components/progress';
import { RadioGroupModule } from '@odx/angular/components/radio-group';
import { SelectModule } from '@odx/angular/components/select';
import { SwitchModule } from '@odx/angular/components/switch';
import { ToastModule } from '@odx/angular/components/toast';
import { ToggleButtonGroupModule } from '@odx/angular/components/toggle-button-group';
import { TooltipDirective } from '@odx/angular/components/tooltip';
import { UibAngularComponents } from '@uib/angular';
import { CookieService } from 'ngx-cookie-service';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { SimplebarAngularModule } from 'simplebar-angular';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';
import { DatePickerComponent } from '../components/datepicker/datepicker.component';
import { DeleteConfirmationModalComponent } from '../components/delete-confirmation-modal/delete-confirmation-modal.component';
import { ExportExcelFooterBtnComponent } from '../components/export-excel-footer-btn/export-excel-footer-btn.component';
import { GenericFooterItemsComponent } from '../components/generic-footer-items/generic-footer-items.component';
import { ImageModalComponent } from '../components/image-modal/image-modal.component';
import { ImportCsvErrorModalComponent } from '../components/import-csv-error-modal/import-csv-error-modal.component';
import { LicensesModalComponent } from '../components/licenses-modal/licenses-modal.component';
import { MapComponent } from '../components/map/map.component';
import { SortIconComponent } from '../components/sort-icon/sort-icon.component';
import { TableSettingsComponent } from '../components/table-settings/table-settings.component';
import { TableDatepickerFilterComponent } from '../components/table/components/table-datepicker-filter/table-datepicker-filter.component';
import { TableDropdownFilterComponent } from '../components/table/components/table-dropdown-filter/table-dropdown-filter.component';
import { TableEllipsisDataColumnComponent } from '../components/table/components/table-ellipsis-data-column/table-ellipsis-data-column.component';
import { MultipleItemsSelectedComponent } from '../components/table/components/table-multi-select-filter/multiple-items-selected/multiple-items-selected.component';
import { TableMultiSelectFilterComponent } from '../components/table/components/table-multi-select-filter/table-multi-select-filter.component';
import { TableTextFilterComponent } from '../components/table/components/table-text-filter/table-text-filter.component';
import { TableComponent } from '../components/table/table.component';
import { DeviceConnectionCategoryTranslationComponent } from '../components/translations/device-connection-category-translation/device-connection-category-translation.component';
import { EventTypeTranslationComponent } from '../components/translations/event-type-translation/event-type-translation.component';
import { IncidentTypeTranslationComponent } from '../components/translations/incident-type-translation/incident-type-translation.component';
import { UserManagementRoleTranslationComponent } from '../components/translations/user-management-role-translation/user-management-role-translation.component';
import { DashIfEmptyDirective } from '../directives/dash-if-empty.directive';
import { DisableAutocompleteDirective } from '../directives/disable-autocomplete.directive';
import { ElementIsVisibleDirective } from '../directives/element-is-visible.directive';
import { NoAllowHtmlTagsDirective } from '../directives/no-allow-html-tags.directive';
import { OnlyNumberDirective } from '../directives/only-number.directive';
import { CallbackPipe } from '../pipes/callback-filter/callback-filter.pipe';
import { PressureUnitPipe } from '../pipes/pressure-unit/pressure-unit.pipe';
import { AppHttpInterceptor } from '../services/app-interceptor/app.interceptor';
import { CheckingSettingsModalsService } from '../services/checking-settings-modals/checking-settings-modals.service';
import { CSVService } from '../services/csv/csv.service';
import { DropdownService } from '../services/dropdown/dropdown.service';
import { ExportTableExcelService } from '../services/export-table-excel/export-table-excel.service';
import { MapService } from '../services/map/map.service';
import { UserStorageService } from '../services/user-based-local-storage/user-based-local-storage.service';
import { WindowService } from '../services/window/window.service';
import { AlarmIconComponent } from './../components/alarm-icon/alarm-icon.component';
import { NotificationComponent } from './../components/notification/notification.component';
import { ProgressBarComponent } from './../components/progress-bar/progress-bar.component';
import { CategoryTranslationComponent } from './../components/translations/category-translation/category-translation.component';
import { TypeTranslationComponent } from './../components/translations/type-translation/type-translation.component';
import { DatePickerService } from './../services/datepicker/date-picker.service';
import { TableService } from './../services/table/table.service';
import { HttpLoaderFactory } from './../utils/http-loader.factory';

@NgModule({
  declarations: [
    ConfirmationModalComponent,
    DeleteConfirmationModalComponent,
    AlarmIconComponent,
    NotificationComponent,
    CategoryTranslationComponent,
    EventTypeTranslationComponent,
    DeviceConnectionCategoryTranslationComponent,
    IncidentTypeTranslationComponent,
    TypeTranslationComponent,
    DeviceConnectionCategoryTranslationComponent,
    GenericFooterItemsComponent,
    DatePickerComponent,
    ProgressBarComponent,
    SortIconComponent,
    MapComponent,
    TableSettingsComponent,
    ImageModalComponent,
    LicensesModalComponent,
    TableComponent,
    TableTextFilterComponent,
    TableDatepickerFilterComponent,
    TableMultiSelectFilterComponent,
    TableEllipsisDataColumnComponent,
    MultipleItemsSelectedComponent,
    TableDropdownFilterComponent,
    PressureUnitPipe,
    CallbackPipe,
    UserManagementRoleTranslationComponent,
    ExportExcelFooterBtnComponent,
    DashIfEmptyDirective,
    OnlyNumberDirective,
    DisableAutocompleteDirective,
    ElementIsVisibleDirective,
    NoAllowHtmlTagsDirective,
    ImportCsvErrorModalComponent,
  ],
  imports: [
    AngularCommonModule,
    ReactiveFormsModule,
    HammerModule,
    FormsModule,
    GoogleMapsModule,
    UibAngularComponents,
    SimplebarAngularModule,
    MultiSelectModule,
    DropdownModule,
    SwitchModule,
    CalendarModule,
    TableModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    TooltipModule,
    CoreModule,
    IconComponent,
    CheckboxModule,
    BarModule,
    TooltipDirective,
    ButtonComponent,
    FormFieldModule,
    ModalModule,
    ToggleButtonGroupModule,
    LoadingSpinnerModule,
    NavigationBackComponent,
    ProgressComponent,
    SelectModule,
    ToastModule,
    RadioGroupModule,
  ],
  exports: [
    ConfirmationModalComponent,
    DeleteConfirmationModalComponent,
    AlarmIconComponent,
    NotificationComponent,
    CategoryTranslationComponent,
    TypeTranslationComponent,
    DeviceConnectionCategoryTranslationComponent,
    IncidentTypeTranslationComponent,
    DeviceConnectionCategoryTranslationComponent,
    TranslateModule,
    GenericFooterItemsComponent,
    EventTypeTranslationComponent,
    DatePickerComponent,
    ProgressBarComponent,
    SortIconComponent,
    UibAngularComponents,
    MapComponent,
    ImageModalComponent,
    TableSettingsComponent,
    LicensesModalComponent,
    TableComponent,
    TableTextFilterComponent,
    TableDatepickerFilterComponent,
    TableMultiSelectFilterComponent,
    TableEllipsisDataColumnComponent,
    MultipleItemsSelectedComponent,
    TableDropdownFilterComponent,
    PressureUnitPipe,
    CallbackPipe,
    UserManagementRoleTranslationComponent,
    ExportExcelFooterBtnComponent,
    DashIfEmptyDirective,
    OnlyNumberDirective,
    DisableAutocompleteDirective,
    ElementIsVisibleDirective,
    NoAllowHtmlTagsDirective,
    ImportCsvErrorModalComponent,
    TooltipModule,
    CoreModule,
    IconComponent,
    CheckboxModule,
    BarModule,
    TooltipDirective,
    ButtonComponent,
    FormFieldModule,
    ModalModule,
    ToggleButtonGroupModule,
    LoadingSpinnerModule,
    NavigationBackComponent,
    ProgressComponent,
    SelectModule,
    SwitchModule,
    ToastModule,
    RadioGroupModule,
  ],
  providers: [
    WindowService,
    DropdownService,
    DatePickerService,
    MapService,
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
    UserStorageService,
    TableService,
    ExportTableExcelService,
    CookieService,
    CheckingSettingsModalsService,
    CSVService,
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    {
      provide: IconConfig,
      useValue: {
        defaultIconSet: 'uib-legacy',
      },
    },
  ],
})
export class CommonModule {}
