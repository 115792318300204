import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { HttpHelper, IFireStationList } from 'src/app/common';
import { environment } from 'src/environments/environment';
import { IBAWearer, IBAWearerPage, ICreateUpdateBAWearer, IDeleteBAWearer } from '../models';

@Injectable({ providedIn: 'root' })
export class BAWearerService {
  private baseUrl: string = environment.API_URLS.BA_WEARER;

  constructor(
    private httpClient: HttpClient,
    private httpHelper: HttpHelper,
  ) {}

  public getBAWearerPage(params?: any): Observable<IBAWearerPage | HttpErrorResponse> {
    return this.httpClient.get<IBAWearerPage>(`${this.baseUrl}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: { ...params },
    });
  }

  public getBAWearerById(aggregateId: string): Observable<{ body: IBAWearer } | HttpErrorResponse> {
    return this.httpClient
      .get<{ body: IBAWearer }>(`${this.baseUrl}/${aggregateId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      })
      .pipe(
        tap((response: HttpResponseBase | any) => {
          /* istanbul ignore next */
          if (response.body) {
            response.body.version = response.headers?.get('etag');
          }
        }),
      );
  }

  public addBAWearer(baWearer: ICreateUpdateBAWearer): Observable<IBAWearer | HttpErrorResponse> {
    return this.httpClient.post<IBAWearer>(`${this.baseUrl}`, baWearer, this.httpHelper.options);
  }

  public updateBAWearer(baWearer: ICreateUpdateBAWearer): Observable<IBAWearer | HttpErrorResponse> {
    const updatedBAWearer: ICreateUpdateBAWearer = { ...baWearer };
    const version: number = baWearer.version;
    const aggregateId: string = baWearer.aggregateId;

    delete updatedBAWearer.version;
    delete updatedBAWearer.aggregateId;

    return this.httpClient.put<IBAWearer>(
      `${this.baseUrl}/${aggregateId}`,
      updatedBAWearer,
      this.httpHelper.optionsWithIfMatch(version),
    );
  }

  public deleteBAWearer(baWearer: IDeleteBAWearer): Observable<IBAWearer | HttpErrorResponse> {
    return this.httpClient.delete<IBAWearer>(
      `${this.baseUrl}/${baWearer.aggregateId}`,
      this.httpHelper.optionsWithIfMatch(baWearer.version),
    );
  }

  public getFireStations(): Observable<IFireStationList | HttpErrorResponse> {
    return this.httpClient.get<IFireStationList>(`${this.baseUrl}/fire-stations`, this.httpHelper.options);
  }

  public uploadCSVFile(data: {
    payload: FormData;
    type: string;
  }): Observable<{ payload: FormData; type: string } | HttpErrorResponse> {
    return this.httpClient.post<{ payload: FormData; type: string }>(`${this.baseUrl}/import`, data.payload);
  }
}
