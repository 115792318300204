import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { ICustomColumn } from 'src/app/common/models/table.model';

@Component({
  selector: 'ignis-table-ellipsis-data-column',
  templateUrl: './table-ellipsis-data-column.component.html',
  styleUrl: './table-ellipsis-data-column.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableEllipsisDataColumnComponent implements AfterViewChecked {
  @ViewChild('columnText') columnText: Partial<ElementRef>;
  @Input() columnData: ICustomColumn;
  disableTooltip: boolean;

  constructor(public cd: ChangeDetectorRef) {}

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  calculateToDisableTooltip(): void {
    this.disableTooltip = this.columnText.nativeElement.scrollWidth > this.columnText.nativeElement.offsetWidth;
  }
}
