import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { ILogbook, ILogbookPage } from './../../models/logbook.model';
import { LogbookActionTypes } from './logbook-action-types';
import { IEntriesModel } from '../../models/logbook.model';

export class RequestLogbookPage implements Action {
  readonly type: LogbookActionTypes.REQUEST_LOGBOOK_PAGE = LogbookActionTypes.REQUEST_LOGBOOK_PAGE;

  constructor(public payload?: any) { }
}

export class ReceiveLogbookPage implements Action {
  readonly type: LogbookActionTypes.RECEIVE_LOGBOOK_PAGE = LogbookActionTypes.RECEIVE_LOGBOOK_PAGE;

  constructor(public payload: ILogbookPage) { }
}

export class ErrorReceiveLogbookPage implements Action {
  readonly type: LogbookActionTypes.ERROR_RECEIVE_LOGBOOK_PAGE = LogbookActionTypes.ERROR_RECEIVE_LOGBOOK_PAGE;

  constructor(public payload: HttpErrorResponse) { }
}

export class RequestEntries implements Action {
  readonly type: LogbookActionTypes.REQUEST_ENTRIES = LogbookActionTypes.REQUEST_ENTRIES;
}

export class ReceiveEntries implements Action {
  readonly type: LogbookActionTypes.RECEIVE_ENTRIES = LogbookActionTypes.RECEIVE_ENTRIES;

  constructor(public payload: IEntriesModel) { }
}

export class ErrorReceiveEntries implements Action {
  readonly type: LogbookActionTypes.ERROR_RECEIVE_ENTRIES = LogbookActionTypes.ERROR_RECEIVE_ENTRIES;

  constructor(public payload: HttpErrorResponse) { }
}

export class RequestLogbookExcelData implements Action {
  readonly type: LogbookActionTypes.REQUEST_LOGBOOK_EXCEL_DATA = LogbookActionTypes.REQUEST_LOGBOOK_EXCEL_DATA;

  constructor(public payload?: any) { }
}

export class ReceiveLogbookExcelData implements Action {
  readonly type: LogbookActionTypes.RECEIVE_LOGBOOK_EXCEL_DATA = LogbookActionTypes.RECEIVE_LOGBOOK_EXCEL_DATA;

  constructor(public payload: ILogbook[]) { }
}

export class ErrorReceiveLogbookExcelData implements Action {
  readonly type: LogbookActionTypes.ERROR_RECEIVE_LOGBOOK_EXCEL_DATA = LogbookActionTypes.ERROR_RECEIVE_LOGBOOK_EXCEL_DATA;

  constructor(public payload: HttpErrorResponse) { }
}
