import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonConstants } from 'src/app/common';
import { formatLocaleTime } from '../../../../../../common/utils/date-utils/date.utils';
import { IMediaAssets } from './../../../../../models/incident.model';

@Component({
  selector: 'ignis-media-notes-list',
  templateUrl: './media-notes-list.component.html',
  styleUrls: ['./media-notes-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaNotesListComponent implements OnChanges {
  @Input() formatDate: string;
  @Input() notes: IMediaAssets[];

  notesList: IMediaAssets[];

  constructor(
    private httpClient: HttpClient,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(): void {
    if (this.notes) {
      this.notesList = structuredClone(this.notes);
      this.notesList.forEach((note: IMediaAssets) => {
        note.time = formatLocaleTime(new Date(note.timestamp));

        if (note.mediaLink) {
          this.loadAudioWithHeaders(note.mediaLink).subscribe((result: Blob) => {
            note.mediaSrc = URL.createObjectURL(result);
            this.cdr.detectChanges();
          });
        }
      });

      const compareTimestamps = (a: IMediaAssets, b: IMediaAssets) =>
        new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();

      this.notesList.slice().sort(compareTimestamps);
    }
  }

  loadAudioWithHeaders(audioUrl: string): Observable<Blob> {
    const headers: HttpHeaders = new HttpHeaders({
      Authorization: `Bearer ${localStorage.getItem(CommonConstants.ACCESS_TOKEN)}`,
    });

    return this.httpClient.get(audioUrl, { headers: headers, responseType: 'blob' });
  }
}
