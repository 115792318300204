export const tableCustomFiltersLayout: string[] = [
  'organizationName',
  'contactName',
  'email',
  'phone',
  'types',
  'address',
  'comment',
];

export const tableDefaultSorting: string = 'organizationName,ASC';
