import { DatePipe, Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnInit, inject } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { StorageMap } from '@ngx-pwa/local-storage';
import { TranslateService } from '@ngx-translate/core';
import { isBefore, subHours } from 'date-fns';
import { Observable, combineLatest, filter, map, of, skip, take, takeUntil } from 'rxjs';
import {
  CommonConstants,
  IApplicationState,
  IScrollOptions,
  IStoreApiItem,
  IStoreApiList,
  PropertyBag,
} from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins';
import { NotificationsService } from 'src/app/common/services';
import { dateFormat, getDateFormatFromUserProfile } from 'src/app/common/utils';
import { ChecklistParameterTypes } from 'src/app/configuration/constants';
import { IChecklist } from 'src/app/configuration/models';
import { IncidentActions } from 'src/app/report-center';
import { AppModulesTypes } from 'src/app/root/models/app-types';
import { IFeatureToggle } from 'src/app/root/state/features-toggle/models/features-toggle.model';
import { selectFeaturesToggleList } from 'src/app/root/state/features-toggle/selectors/features-toggle.selector';
import { EquipmentNotificationConstants, TaskResultTypeEnum, WorkshopConstants } from 'src/app/workshop/constants';
import {
  IFaultedField,
  IPrintServiceResultValue,
  ISaveTestResults,
  ITaskData,
  ITaskInterval,
  ITestResultDetails,
  ITestValue,
} from 'src/app/workshop/models';
import { EquipmentInitialSpecificationActions } from 'src/app/workshop/state/actions/equipment-initial-specs';
import { EquipmentTestActions } from 'src/app/workshop/state/actions/equipment-test';
import {
  selectEquipmentIdentifiersUniqueness,
  selectSaveTest,
  selectTestResultsById,
} from 'src/app/workshop/state/selectors';
import { AccessControlService } from '../../../../root';
import { testResultsFormFieldsDisabled } from '../../test-equipment/form-fields';
import { IChecklistItem } from './../../../../configuration/models/checklist.model';

@Component({
  selector: 'ignis-task-result',
  templateUrl: './task-result.component.html',
  styleUrls: ['./task-result.component.scss'],
  providers: [DatePipe],
})
export class TaskResultComponent extends OnDestroyMixin() implements OnInit {
  selectedTaskName: string;
  dateTime: string;
  formatDate: string;
  aggregateId: string;
  previousTitle: string;
  testType: string = null;
  taskValues: ITestValue[];
  taskData: ITaskData;
  isLastResult: boolean = false;
  isLoading: Observable<boolean>;
  isLoadingPrint: boolean;
  faultedFields: IFaultedField[];
  previousRouteIsTesting: boolean;
  isExportStarted: boolean = false;
  isNavigatedBack: boolean = false;
  isSubmitting: Observable<boolean>;
  checklistData: Partial<IChecklist>;
  testResultData: ITestResultDetails;
  equipmentTaskResultsForm: FormGroup;
  isResultPrintStarted: boolean = false;
  isConfirmCloseModalOpen: boolean = false;
  othersTasksIncluded: ITaskInterval[];
  preparedPrintData: IPrintServiceResultValue;
  barcodeUpdateInTaskFeatureToggle: boolean;
  ramChangeCompletedTasksFeatureToggle: boolean;
  isLessThanAnHourFromNow: boolean;
  isFromMigration: boolean;
  outOfServiceDatePickerAndOtherTasksCheckboxState: boolean;

  taskResultType: TaskResultTypeEnum;
  tabs: PropertyBag = WorkshopConstants.testResultTabs;
  taskResultTypesEnum: typeof TaskResultTypeEnum = TaskResultTypeEnum;
  checklistParameterTypes: typeof ChecklistParameterTypes = ChecklistParameterTypes;
  scrollbarOptions: IScrollOptions = CommonConstants.scrollbarOptions;
  taskValuesTypes: PropertyBag = WorkshopConstants.taskValuesType;
  activeTab: string = WorkshopConstants.testResultTabs.GENERAL;
  toggledFields: string[] = WorkshopConstants.toggledResultFields;

  storage: StorageMap = inject(StorageMap);
  incidentActions: IncidentActions = inject(IncidentActions);
  equipmentTestActions: EquipmentTestActions = inject(EquipmentTestActions);
  equipmentInitialSpecsActions: EquipmentInitialSpecificationActions = inject(EquipmentInitialSpecificationActions);
  translateService: TranslateService = inject(TranslateService);
  notificationsService: NotificationsService = inject(NotificationsService);
  accessControlService: AccessControlService = inject(AccessControlService);
  location: Location = inject(Location);

  constructor(
    public title: Title,
    public router: Router,
    private route: ActivatedRoute,
    private store: Store<IApplicationState>,
  ) {
    super();
    this.buildForm();
    this.route.params.pipe(takeUntil(this.destroy)).subscribe((params: Params) => {
      this.aggregateId = params.id as string;
    });

    this.formatDate = getDateFormatFromUserProfile(this.translateService);
  }

  @HostListener(CommonConstants.beforeUnloadWindowEvent, ['$event'])
  handleBeforeUnload($event: { returnValue: boolean }): void {
    if (this.hasUnsavedData()) {
      $event.returnValue = this.hasUnsavedData();
    }
  }

  @HostListener(CommonConstants.afterPrintWindowEvent, [])
  handleAfterPrint(): void {
    this.isLoadingPrint = false;
    this.isResultPrintStarted = false;

    this.title.setTitle(this.previousTitle);
  }

  canDeactivate(): boolean {
    if (!this.hasUnsavedData()) {
      this.isConfirmCloseModalOpen = false;

      return true;
    }

    this.confirmCloseModalOpen();

    return false;
  }

  ngOnInit(): void {
    this.isLoading = combineLatest([this.store.select(selectTestResultsById)]).pipe(
      map(([testResultsByIdState]: [IStoreApiItem<ITestResultDetails>]) => testResultsByIdState.isLoading),
      takeUntil(this.destroy),
    );

    this.isSubmitting = this.store.pipe(
      select(selectSaveTest),
      takeUntil(this.destroy),
      map((saveResults: IStoreApiItem<ISaveTestResults>) => {
        return saveResults.isLoading;
      }),
    );

    this.activateFieldsIfPreviousWasTesting();
    this.readFeatureTogglesData();
  }

  readFeatureTogglesData(): void {
    this.store
      .pipe(
        select(selectFeaturesToggleList),
        map((state: IStoreApiList<IFeatureToggle[]>) => {
          const featuresToggle: IFeatureToggle[] = state.data;
          const barcodeUpdateInTaskFeatureToggle: IFeatureToggle = featuresToggle?.find((ft: IFeatureToggle) =>
            Object.values(ft).includes(AppModulesTypes.barcodeUpdateInTask),
          );
          const ramChangeCompletedTasksFeatureToggle: IFeatureToggle = featuresToggle?.find((ft: IFeatureToggle) =>
            Object.values(ft).includes(AppModulesTypes.ramChangeCompletedTasks),
          );

          return {
            barcodeToggle: barcodeUpdateInTaskFeatureToggle,
            ramToggle: ramChangeCompletedTasksFeatureToggle,
          };
        }),
        takeUntil(this.destroy),
      )
      .subscribe((response: { barcodeToggle: IFeatureToggle; ramToggle: IFeatureToggle }) => {
        this.barcodeUpdateInTaskFeatureToggle = response.barcodeToggle?.isEnabled;
        this.ramChangeCompletedTasksFeatureToggle = response.ramToggle?.isEnabled;

        this.readTestResultsDataFromRouteSnapshot();
      });
  }

  buildForm(): void {
    this.equipmentTaskResultsForm = testResultsFormFieldsDisabled();
  }

  activateFieldsIfPreviousWasTesting(): void {
    this.isLastResult = WorkshopConstants.equipmentTestedWithSuccess in localStorage;

    if (WorkshopConstants.equipmentTestedWithSuccess in localStorage) {
      this.equipmentTaskResultsForm.markAsDirty();
    }

    this.previousRouteIsTesting = this.isLastResult;
    this.activateFields(this.isLastResult);
  }

  readTestResultsDataFromRouteSnapshot(): void {
    const testResultData: ITestResultDetails = structuredClone(
      this.route.snapshot.data.serviceResultResolver.data,
    ) as ITestResultDetails;

    this.redirectUserToMainViewIfEntityNotExist();

    if (testResultData) {
      this.testResultData = testResultData;
      this.isLastResult = testResultData.isLastResult;
      this.isFromMigration = testResultData.isFromMigration;
      this.outOfServiceDatePickerAndOtherTasksCheckboxState = this.isLastResult;
      this.taskData = testResultData.taskData;
      this.taskResultType = testResultData.taskResultType;
      testResultData.locationPath = testResultData.locationPath.join(CommonConstants.splitArrayOfStringsRule) as string;
      this.equipmentTaskResultsForm.patchValue(testResultData);
      this.equipmentTaskResultsForm.get('outOfServiceDate').patchValue(testResultData.outOfService?.date);
      this.equipmentTaskResultsForm.get('outOfServiceReason').patchValue(testResultData.outOfService?.reason);

      this.checkTaskResultType(testResultData, this.taskResultType);

      this.dateTime = dateFormat(new Date(testResultData.dateTime).toString(), this.formatDate);
      this.selectedTaskName = testResultData.selectedTaskName;

      this.activateFields(this.isLastResult);

      this.checkIfTestExecutionIsLessThanAnHourFromNow(testResultData.dateTime);

      this.addInheritedPartToServiceTaskName();
    }
  }

  redirectUserToMainViewIfEntityNotExist(): boolean {
    const errorResponse: HttpErrorResponse = this.route.snapshot.data.serviceResultResolver.errors as HttpErrorResponse;

    if (
      errorResponse &&
      errorResponse.error.code.toString() ===
        EquipmentNotificationConstants.notificationCodes.TASK_RESULT_NOT_FOUND.value
    ) {
      this.notificationsService.requestShowNotification(
        CommonConstants.notificationType.ERROR,
        EquipmentNotificationConstants.notificationCodes.TASK_RESULT_NOT_FOUND.value,
        EquipmentNotificationConstants.notificationCodes,
      );

      setTimeout(() => {
        this.location.back();
      }, CommonConstants.DEFAULT_REDIRECT_TIMEOUT);

      return true;
    }
  }

  addInheritedPartToServiceTaskName(): void {
    this.taskData.includedTasks?.forEach((service: ITaskInterval) => {
      service.taskName = service.inheritedFrom
        ? `${service.taskName} (${this.translateService.instant('EQUIPMENT_TASK_RESULTS.STR_INHERITED')} ${service.inheritedFrom})`
        : service.taskName;
    });

    this.taskData.others?.forEach((service: ITaskInterval) => {
      service.taskName = service.inheritedFrom
        ? `${service.taskName} (${this.translateService.instant('EQUIPMENT_TASK_RESULTS.STR_INHERITED')} ${service.inheritedFrom})`
        : service.taskName;
    });
  }

  checkIfTestExecutionIsLessThanAnHourFromNow(date: string): void {
    const testDate: Date = new Date(date);
    const oneHourAgo: Date = subHours(new Date(), 1);

    this.isLessThanAnHourFromNow = isBefore(testDate, oneHourAgo);

    if (!this.ramChangeCompletedTasksFeatureToggle && this.isLastResult) {
      this.outOfServiceDatePickerAndOtherTasksCheckboxState = !this.isLessThanAnHourFromNow;
      this.isLastResult = !this.isLessThanAnHourFromNow;
      setTimeout(() => {
        this.activateFields(!this.isLessThanAnHourFromNow);
      }, 50);
    }
  }

  checkTaskResultType(testResultData: ITestResultDetails, taskResultType: string): void {
    if ((taskResultType as TaskResultTypeEnum) === TaskResultTypeEnum.Checklist) {
      this.testType = this.taskValuesTypes.MANUAL_TEST;
      this.checklistData = testResultData.checklist ?? ({} as IChecklist);
    } else {
      this.testType = this.taskValuesTypes.AUTOMATIC_TEST;
      this.taskValues = testResultData.taskValues ?? [];
    }
  }

  activateFields(activate: boolean): void {
    this.toggledFields.forEach((field: string) => {
      const control: AbstractControl = this.equipmentTaskResultsForm.get(field);

      return activate ? control.enable() : control.disable();
    });
  }

  confirmCloseModalOpen(): void {
    this.isConfirmCloseModalOpen = true;
  }

  tryNavigateBack(isConfirmed: boolean): void {
    if (!isConfirmed) {
      this.isConfirmCloseModalOpen = false;

      return;
    }

    this.equipmentTaskResultsForm.markAsUntouched();
    this.equipmentTaskResultsForm.markAsPristine();

    this.navigateBack();
  }

  navigateBack(): void {
    this.router.navigate([localStorage.getItem(WorkshopConstants.testStartFrom) || '/workshop/task-documentation']);
  }

  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }

  getOtherTasks(otherTasks: ITaskInterval[]): void {
    this.othersTasksIncluded = otherTasks;
    this.taskData.others = this.othersTasksIncluded;
  }

  saveTaskResultData(): void {
    this.isNavigatedBack = true;
    this.checkEquipmentIdentifiersUniqueness();
  }

  saveAndExport(): void {
    this.isNavigatedBack = false;
    this.isExportStarted = true;
    this.checkEquipmentIdentifiersUniqueness();
  }

  startPrintProcess(): void {
    this.checklistData?.items?.forEach((item: IChecklistItem, index: number) => (item.id = item.name + index));

    this.preparedPrintData = {
      checklist: this.checklistData as IChecklist,
      automatic: this.taskValues,
    };

    this.isResultPrintStarted = true;
    this.isNavigatedBack = false;
  }

  saveAndPrint(): void {
    this.isLoadingPrint = true;

    if (this.equipmentTaskResultsForm.dirty) {
      this.checkEquipmentIdentifiersUniqueness();

      return;
    }

    this.startPrintProcess();
  }

  handlePrint(): void {
    this.triggerPrint();
  }

  getPreviousPageTitle(title: string): void {
    this.previousTitle = title;
  }

  triggerPrint(): void {
    setTimeout(() => {
      window.print();
    }, 500);
  }

  setExportXLSXState(state: boolean): void {
    this.storage.set('proceedExportXLSX', state)?.subscribe(() => {
      return;
    });
  }

  checkEquipmentIdentifiersUniqueness(): void {
    this.isLoading = of(true);

    if (this.barcodeUpdateInTaskFeatureToggle) {
      this.checkAndSave();
    } else {
      this.equipmentTaskResultsForm.get('newBarcode').setValue(null);
      this.equipmentTaskResultsForm.get('newRfid').setValue(null);
      this.onSave();
    }
  }

  checkAndSave(): void {
    const barcode: string | null = (this.equipmentTaskResultsForm.get('newBarcode').value as string | null)?.trim()
      ? (this.equipmentTaskResultsForm.get('newBarcode').value as string | null)?.trim()
      : (null as string);

    const rfid: string | null = (this.equipmentTaskResultsForm.get('newRfid').value as string | null)?.trim()
      ? (this.equipmentTaskResultsForm.get('newRfid').value as string | null)?.trim()
      : null;

    this.equipmentInitialSpecsActions.requestCheckingBarcodeOrRFIDIsUnique({
      barcode,
      rfid,
      equipmentAggregateId: this.testResultData?.equipmentAggregateId || null,
    });

    this.store
      .select(selectEquipmentIdentifiersUniqueness)
      .pipe(
        filter((equipmentIdentifiersAreUnique: IStoreApiItem<string[]>) => !equipmentIdentifiersAreUnique.isLoading),
        take(1),
      )
      .subscribe((equipmentIdentifiersAreUnique: IStoreApiItem<string[]>) => {
        if (equipmentIdentifiersAreUnique.isSuccess) {
          this.onSave();

          return;
        }

        if (equipmentIdentifiersAreUnique.errors) {
          const customHttpErrorCode: string = equipmentIdentifiersAreUnique.errors.error?.code?.toString() as string;

          this.faultedFields = equipmentIdentifiersAreUnique.errors.error?.violations as IFaultedField[];

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.HIDDEN,
            customHttpErrorCode || '',
            EquipmentNotificationConstants.notificationCodes,
          );

          this.isLoading = of(false);
          this.isLoadingPrint = false;
          this.setExportXLSXState(false);
        }
      });
  }

  onSave(): void {
    let testResultVersion: number = Number(this.equipmentTaskResultsForm.controls.version.value);

    testResultVersion += 1;
    let otherIds: string[] = [];

    if (this.othersTasksIncluded?.length) {
      otherIds = this.othersTasksIncluded
        .filter((task: ITaskInterval) => task.taskId && task.wasTestIncluded)
        .map((task: ITaskInterval) => task.taskId);
    }

    const objToBeSend: ISaveTestResults = {
      aggregateId: this.aggregateId,
      operationalStatus: this.handleOperationalStatus(
        this.equipmentTaskResultsForm.controls.operationalStatus.value as string,
      ),
      comment: this.equipmentTaskResultsForm.controls.comment.value as string,
      airQualityMeasured: this.equipmentTaskResultsForm.controls.airQualityMeasured.value as boolean,
      version: this.equipmentTaskResultsForm.controls.version.value as number,
      othersTasksIncluded: Array.from(new Set(otherIds)),
      outOfService: {
        date: this.equipmentTaskResultsForm.controls.outOfServiceDate.value as string,
        reason: this.equipmentTaskResultsForm.controls.outOfServiceReason.value as string,
      },
      newBarcode: (this.equipmentTaskResultsForm.get('newBarcode').value as string)?.trim(),
      newRfid: (this.equipmentTaskResultsForm.get('newRfid').value as string)?.trim(),
    };

    if (!this.equipmentTaskResultsForm.controls.outOfServiceDate.value) {
      objToBeSend.outOfService = null;
    }

    this.equipmentTestActions.requestSaveTestResults(objToBeSend);

    this.store
      .select(selectSaveTest)
      .pipe(
        filter((saveResults: IStoreApiItem<ISaveTestResults>) => !saveResults.isLoading),
        take(2),
        skip(1),
      )
      .subscribe((saveResults: IStoreApiItem<ISaveTestResults>) => {
        this.equipmentTaskResultsForm.markAsUntouched();

        if (saveResults.isSuccess) {
          if (!this.isNavigatedBack && this.isExportStarted) {
            this.setExportXLSXState(true);
            this.isExportStarted = false;
          }

          if (this.isLoadingPrint) {
            this.startPrintProcess();
          }

          this.equipmentTaskResultsForm.markAsPristine();
          this.checkIfUserExportAndSaveExcel(testResultVersion);
          this.equipmentInitialSpecsActions.resetCheckingBarcodeOrRFIDIsUnique();
          this.isLoading = of(false);

          return;
        }

        if (saveResults.errors) {
          this.isLoadingPrint = false;
          this.setExportXLSXState(false);

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            saveResults.errors?.error.code?.toString() as string,
            EquipmentNotificationConstants.notificationCodes,
          );
        }

        this.isLoading = of(false);
      });
  }

  checkIfUserExportAndSaveExcel(testResultVersion: number): void {
    if (this.isNavigatedBack) {
      this.navigateBack();
    } else {
      this.equipmentTaskResultsForm.get('version').setValue(testResultVersion);
    }
  }

  handleOperationalStatus(value: boolean | string): string {
    return WorkshopConstants.operationalStatusesMapping[value?.toString()];
  }

  hasUnsavedData(): boolean {
    return this.equipmentTaskResultsForm.dirty;
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();

    localStorage.removeItem(WorkshopConstants.equipmentTestedWithSuccess);
    localStorage.removeItem(WorkshopConstants.testStartFrom);

    this.equipmentTaskResultsForm.markAsUntouched();
    this.equipmentTaskResultsForm.markAsPristine();
  }
}
