import { GenericAction, reducerUtils } from 'src/app/common';
import { IRAMStatus, ITestStatus, IToken } from '../../../models';
import { RAMActionTypes } from '../../actions/ram';
import { IRAMConnectorState, RAMConnectorState } from '../../models';

export function RAMConnectorReducer(
  lastState: IRAMConnectorState = new RAMConnectorState(),
  action: GenericAction<RAMActionTypes, any>,
): IRAMConnectorState {
  switch (action.type) {
    case RAMActionTypes.REQUEST_RAM_CONNECTOR_TOKEN:
      return reducerUtils.requestItemData<IRAMConnectorState, IToken>(lastState, ['ramConnectorToken']);
    case RAMActionTypes.RECEIVE_RAM_CONNECTOR_TOKEN:
      return reducerUtils.receiveItemData<IRAMConnectorState, IToken>(lastState, ['ramConnectorToken'], action.payload);
    case RAMActionTypes.ERROR_RECEIVE_RAM_CONNECTOR_TOKEN:
      return reducerUtils.errorItemData<IRAMConnectorState, IToken>(lastState, ['ramConnectorToken'], action.payload);

    case RAMActionTypes.REQUEST_CHECK_RAM_CONNECTOR_STATUS:
      return reducerUtils.requestItemData<IRAMConnectorState, ITestStatus>(lastState, ['checkRAMConnectorStatus']);
    case RAMActionTypes.RECEIVE_CHECK_RAM_CONNECTOR_STATUS:
      return reducerUtils.receiveItemData<IRAMConnectorState, ITestStatus>(
        lastState,
        ['checkRAMConnectorStatus'],
        action.payload,
      );
    case RAMActionTypes.ERROR_RECEIVE_CHECK_RAM_CONNECTOR_STATUS:
      return reducerUtils.errorItemData<IRAMConnectorState, ITestStatus>(
        lastState,
        ['checkRAMConnectorStatus'],
        action.payload,
      );

    case RAMActionTypes.REQUEST_CHECK_RAM_PORT_STATUS:
      return reducerUtils.requestItemData<IRAMConnectorState, IRAMStatus>(lastState, ['ramStatus']);
    case RAMActionTypes.RECEIVE_CHECK_RAM_PORT_STATUS:
      return reducerUtils.receiveItemData<IRAMConnectorState, IRAMStatus>(lastState, ['ramStatus'], action.payload);
    case RAMActionTypes.ERROR_RECEIVE_CHECK_RAM_PORT_STATUS:
      return reducerUtils.errorItemData<IRAMConnectorState, IRAMStatus>(lastState, ['ramStatus'], action.payload);

    case RAMActionTypes.REQUEST_RAM_AUTO_UPDATE:
      return reducerUtils.requestPostPutItemData<IRAMConnectorState, object>(lastState, ['autoUpdate'], action.payload);
    case RAMActionTypes.RECEIVE_RAM_AUTO_UPDATE:
      return reducerUtils.receivePostPutItemData<IRAMConnectorState, object>(lastState, ['autoUpdate'], action.payload);
    case RAMActionTypes.ERROR_RECEIVE_RAM_AUTO_UPDATE:
      return reducerUtils.errorPostPutItemData<IRAMConnectorState, object>(lastState, ['autoUpdate'], action.payload);

    default:
      return lastState;
  }
}
