import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { ExportTableActionTypes } from './export-action-types';

export class RequestTableExport implements Action {
  readonly type: ExportTableActionTypes.REQUEST_TABLE_DATA = ExportTableActionTypes.REQUEST_TABLE_DATA;

  constructor(public payload: any) {}
}

export class ReceiveTableExport implements Action {
  readonly type: ExportTableActionTypes.RECEIVE_TABLE_DATA = ExportTableActionTypes.RECEIVE_TABLE_DATA;

  constructor(public payload: any) {}
}
export class ErrorReceiveTableExport implements Action {
  readonly type: ExportTableActionTypes.ERROR_RECEIVE_TABLE_DATA = ExportTableActionTypes.ERROR_RECEIVE_TABLE_DATA;

  constructor(public payload: HttpErrorResponse) {}
}

export class ReceiveExportInProgress implements Action {
  readonly type: ExportTableActionTypes.RECEIVE_EXPORT_IN_PROGRESS = ExportTableActionTypes.RECEIVE_EXPORT_IN_PROGRESS;

  constructor(public payload: boolean) {}
}
export class ErrorReceiveExportInProgress implements Action {
  readonly type: ExportTableActionTypes.ERROR_RECEIVE_EXPORT_IN_PROGRESS =
    ExportTableActionTypes.ERROR_RECEIVE_EXPORT_IN_PROGRESS;

  constructor(public payload: HttpErrorResponse) {}
}

export class ResetTableExport implements Action {
  readonly type: ExportTableActionTypes.RESET_TABLE_DATA = ExportTableActionTypes.RESET_TABLE_DATA;
}
