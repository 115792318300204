import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { StorageMap } from '@ngx-pwa/local-storage';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { captureMessage, setTag } from '@sentry/angular-ivy';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BAWearerNotificationConstants } from 'src/app/ba-wearer/constants';
import { CommonConstants, LoggerService } from 'src/app/common';
import { NotificationConstants } from 'src/app/common/constants/notification.constants';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { ConfigurationNotificationConstants } from 'src/app/configuration/constants';
import { RemoteMonitoringNotificationConstants } from 'src/app/remote-monitoring/constants';
import { UserNotificationConstants } from 'src/app/user-management/constants';
import { EquipmentNotificationConstants } from 'src/app/workshop/constants';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  beNotAvailable: number[] = [502, 504];

  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    public notificationsService: NotificationsService,
    private router: Router,
    public loggerService: LoggerService,
    public storage: StorageMap,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: any) => {
        if (event.status === 200 && !('okta-token-storage' in localStorage) && window.navigator.onLine) {
          this.renewOktaTokens();

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            NotificationConstants.globalCodes.INTERNET_CONNECTION_IS_UP,
            NotificationConstants.globalCodes,
          );
        }
      }),
      catchError((error: HttpErrorResponse) => {
        const description: string = `[Code: ${error.status}, description: ${error.error ? error.error : `An unexpected error occurred on the server. Please try again later.`}]`;
        const message: string = error.error?.debugMessage ? error.error?.debugMessage : description;

        this.loggerService.logMessage(message, CommonConstants.logType.INFO);
        this.loggerService.logTrace(message, SeverityLevel.Information);
        setTag('error-id', undefined);
        captureMessage(message, 'info');

        const jwtHelper: JwtHelperService = new JwtHelperService();
        const token: string = localStorage.getItem(CommonConstants.AUTH_TOKEN);

        const licenseErrorCodes: string[] = [
          UserNotificationConstants.notificationCodes['LICENSE_IS_INVALID'].value,
          UserNotificationConstants.notificationCodes['LICENSE_ALREADY_USED'].value,
          UserNotificationConstants.notificationCodes['LICENSE_EXPIRED'].value,
        ];

        if (!window.navigator.onLine) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            NotificationConstants.globalCodes.INTERNET_CONNECTION_IS_DOWN,
            NotificationConstants.globalCodes,
          );

          return;
        }

        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 503:
              this.setStorageMaintenance(error);
              break;

            case 401:
              if (token && jwtHelper.isTokenExpired(token)) {
                if ('okta-token-storage' in localStorage) {
                  this.oktaAuth.tokenManager.renew('idToken');
                  this.oktaAuth.tokenManager.renew('accessToken');

                  localStorage.setItem(CommonConstants.AUTH_TOKEN, this.oktaAuth.getIdToken());
                } else {
                  sessionStorage.clear();
                }

                this.reloadPage();
              }

              break;
            case 403:
              this.notificationsService.requestShowNotification(
                CommonConstants.notificationType.HIDDEN,
                NotificationConstants.globalCodes.BACKEND_NOT_AVAILABLE,
                NotificationConstants.globalCodes,
              );

              if (licenseErrorCodes.includes(error?.error?.code?.toString()) || request.url.includes('license-info')) {
                break;
              }

              this.router.navigate(['']);

              break;

            case 414:
              this.notificationsService.requestShowNotification(
                CommonConstants.notificationType.ERROR,
                NotificationConstants.globalCodes.URL_TOO_LONG,
                NotificationConstants.globalCodes,
              );
              break;

            default:
              if (!this.beNotAvailable.includes(error.status)) {
                this.redirectToErrorPage(error);

                break;
              }

              this.notificationsService.requestShowNotification(
                CommonConstants.notificationType.ERROR,
                NotificationConstants.globalCodes.BACKEND_NOT_AVAILABLE,
                NotificationConstants.globalCodes,
              );
              break;
          }

          this.setClosedRAMConnectorState(error);
        }

        this.doesUserExists(error);

        return throwError(() => new HttpErrorResponse(error));
      }),
    );
  }

  reloadPage(): void {
    window.location.reload();
  }

  setStorageMaintenance(error: any): void {
    const errorFromMaintenance: string = error?.error ? error.error : CommonConstants.maintenanceWithoutMessage;

    this.storage.set(CommonConstants.appIsInMaintenanceMode, errorFromMaintenance)?.subscribe(() => {
      return;
    });
  }

  setClosedRAMConnectorState(error: HttpErrorResponse): void {
    if (error.status === 0 && error.url.includes('test/check')) {
      this.storage.set(CommonConstants.RAMConnectorIsClosed, true)?.subscribe(() => {
        return;
      });
    }
  }

  doesUserExists(error: HttpErrorResponse): void {
    if (error?.error?.code !== Number(UserNotificationConstants.notificationCodes.USER_NOT_AUTHORISED.value)) return;

    this.storage.set(CommonConstants.userIsUnauthorized, error.error)?.subscribe(() => {
      return;
    });
  }

  redirectToErrorPage(error: HttpErrorResponse): void {
    const RAMConnectorUrlHostName: string = 'localhost';

    if (error.status === 500 && error.url.includes('access-control')) {
      localStorage.setItem('AuthorizationError', JSON.stringify(error));
      this.router.navigate(['internal-server-error']);
    } else if (!error.url.includes(RAMConnectorUrlHostName)) {
      const listOfHiddenNotificationCodes: string[] = [
        EquipmentNotificationConstants.notificationCodes.BARCODE_NO_EXIST.value,
        ConfigurationNotificationConstants.notificationCodes.LOCATION_ENTITY_NOT_EXIST.value,
        UserNotificationConstants.notificationCodes.CSV_FILE_FORMAT_INCORRECT.value,
        UserNotificationConstants.notificationCodes.ADMIN_DELETE_ERROR.value,
        EquipmentNotificationConstants.notificationCodes.CSV_FILE_FORMAT.value,
        BAWearerNotificationConstants.notificationCodes.CSV_FILE_FORMAT_INCORRECT.value,
        RemoteMonitoringNotificationConstants.notificationCodes.ACCESS_UNAVAILABLE_INCIDENT.value,
      ];

      if (listOfHiddenNotificationCodes.includes(error.error?.code?.toString())) {
        this.notificationsService.requestShowNotification(
          CommonConstants.notificationType.HIDDEN,
          EquipmentNotificationConstants.notificationCodes.BARCODE_NO_EXIST,
          EquipmentNotificationConstants.notificationCodes,
        );

        return;
      }

      this.notificationsService.requestShowNotification(
        CommonConstants.notificationType.ERROR,
        NotificationConstants.globalCodes.GENERIC_SERVER_ERROR,
        NotificationConstants.globalCodes,
      );
    }
  }

  renewOktaTokens(): void {
    this.oktaAuth.tokenManager.renew('idToken');
    this.oktaAuth.tokenManager.renew('accessToken');

    localStorage.setItem(CommonConstants.AUTH_TOKEN, this.oktaAuth.getIdToken());
    localStorage.setItem(CommonConstants.ACCESS_TOKEN, this.oktaAuth.getAccessToken());
  }
}
