/* eslint-disable no-shadow */
export enum AppModulesTypes {
  restrictToLocation = 'restrict-to-location',
  addressBook = 'address-book',
  inventoryPage = 'inventory-page',
  equipmentWorkflowPage = 'equipment-workflow-page',
  equipmentTasksTab = 'equipment-tasks',
  equipmentTestingGlobal = 'equipment-automatic-tests',
  equipmentBatchChecklistTask = 'equipment-batch-checklist-task',
  equipmentPrintTaskResult = 'equipment-print-task-results',
  location = 'location',
  logbook = 'logbook',
  remoteMonitoring = 'remote-monitoring',
  reportCenter = 'report-center',
  userManagement = 'user-management',
  roleManagementGlobal = 'role-management-menu',
  deviceConnections = 'device-connection',
  workshop = 'workshop',
  draegerwareWorkshop = 'draegerware-workshop',
  ramConnector = 'ram-connector',
  ramConnectorAutoUpdate = 'ram-connector-auto-update',
  barcodeUpdateInTask = 'barcode-update-in-task',
  generalAPIKeys = 'general-api-keys',
  configuration = 'configuration',
  baWearer = 'ba-wearer-management',
  productFruits = 'ignis-product-fruits',
  ramChangeCompletedTasks = 'RAM-change-completed-tasks',
  ramProtectorMigration = 'RAM-protector-migration',
}

export enum AppUserRole {
  technician = 'technician',
  fireChief = 'fire_chief',
  firefighter = 'firefighter',
  admin = 'admin',
  tester = 'tester',
  itManager = 'it_manager',
}

export enum AppUserPermissionList {
  equipmentWrite = 'equipment.access.write',
  equipmentView = 'equipment.access.view',
  equipmentTest = 'equipment.access.execute-test',
  equipmentTaskDocumentation = 'equipment.access.task-documentation',
  equipmentChangeLocation = 'equipment.access.change-location',
  equipmentChangeTasks = 'equipment.access.change-tasks',
  configuration = 'configuration.access',
  logbook = 'logbook.access',
  remoteMonitoring = 'remote-monitoring.access',
  organization = 'organization.access',
  reportCenter = 'report-center.access',
  userManagement = 'user-management.access',
  roleManagement = 'role-management.access',
  deviceConnections = 'device-connection.access',
  baWearer = 'ba-wearer.access',
}
