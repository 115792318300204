import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICustomColumn } from 'src/app/common/models/table.model';
import { AppUserRole } from 'src/app/root/models/app-types';

@Component({
  selector: 'ignis-user-management-role-translation',
  templateUrl: './user-management-role-translation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserManagementRoleTranslationComponent {
  @Input() entry: ICustomColumn;
  appUserRole = AppUserRole;
}
