import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { formatLocaleTime } from '../../../../common/utils';
import { WorkshopConstants } from '../../../constants';
import { IEquipmentHistoryEvent, IEquipmentHistoryEventType } from '../../../models';

@Component({
  selector: 'ignis-timeline-event',
  templateUrl: './timeline-event.component.html',
  styleUrls: ['./timeline-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineEventComponent implements OnInit {
  @Input() event: IEquipmentHistoryEvent;
  @Input() formatDate: string;

  time: string;
  eventType: { label: string; icon: string; name: string; userNameOrId: string } = null;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.time = formatLocaleTime(new Date(this.event.occurredAt));

    const foundType: IEquipmentHistoryEventType = WorkshopConstants.historyTypes.types.find(
      (type: IEquipmentHistoryEventType) => type.value === this.event.type,
    );

    this.eventType = {
      ...foundType,
      name: this.translateService.instant(foundType.name) as string,
      label: this.translateService.instant(foundType.localizedName) as string,
      userNameOrId: this.event.userNameOrId,
    };
  }
}
