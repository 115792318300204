import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, filter, map, Observable, takeUntil } from 'rxjs';
import { CommonConstants, TableExportExcelConstants } from 'src/app/common/constants';
import { INotificationType, ITableColumn, ITableSettings, PropertyBag } from 'src/app/common/models';
import { NotificationsService } from 'src/app/common/services';
import {
  checkAutoUpdateFeatureToggleBeforeStartProtectorTest,
  checkRAMConnectorPort,
  dateFormat,
  extractOnlyDate,
  getDateFormatFromUserProfile,
  startTest,
} from 'src/app/common/utils';
import { ISaveBatchChecklist, ITask } from 'src/app/configuration/models';
import { ExportExcelService } from 'src/app/report-center/services/export-excel.service';
import { AccessControlService } from 'src/app/root';
import { RAMConnectorErrorsService } from 'src/app/workshop';
import { WorkshopConstants, WorkshopModuleRoutes } from 'src/app/workshop/constants';
import { ILocationChange, IRAMStatus, IServiceTask, IServiceTaskPage, ITestStatus } from 'src/app/workshop/models';
import { EquipmentServiceTaskActions } from 'src/app/workshop/state/actions/equipment-service-task';
import { RAMActions } from 'src/app/workshop/state/actions/ram';
import { tableColumns } from './table-columns';
import { readModelSortKeys, tableCustomFiltersLayout, tableDefaultSorting } from './table-settings';

import {
  ApplicationState,
  EquipmentTasksTableFiltersConstants,
  IStoreApiItem,
  IStoreApiList,
  LoggerService,
  StorageConstants,
  TableHelperComponent,
} from 'src/app/common';
import {
  selectCheckRAMConnectorStatus,
  selectCheckRAMPortStatus,
  selectedSavedServicesChecklist,
  selectSaveBatchChecklist,
  selectServiceTaskPage,
  selectUpdateEquipmentsLocation,
} from '../../../state/selectors';

@Component({
  selector: 'ignis-tasks-list',
  templateUrl: './tasks-list.component.html',
})
export class TasksListComponent extends TableHelperComponent implements OnInit {
  tableName: string = 'service-tasks';
  localTableColumns: ITableColumn[] = tableColumns;
  customFilterLayouts: string[] = tableCustomFiltersLayout;
  readModelSortKeys: PropertyBag = readModelSortKeys;
  defaultSorting: string = tableDefaultSorting;
  tableFiltersKey: string = StorageConstants.tablesStorageKeys.SERVICE_TASKS;
  excelTranslationKeys: PropertyBag = TableExportExcelConstants.serviceTasksSheetTranslationKeys;
  notificationType: INotificationType = CommonConstants.notificationType;

  formatDate: string;
  list: IServiceTask[];
  selectedTasks: IServiceTask[] = [];
  activatePlayBtn: boolean = false;
  selectedTasksAreNotTheSame: boolean;
  isSubmitting: Observable<boolean>;
  tableExportList: IServiceTask[] = [];
  ramConnectorStatus: boolean | string;
  startAutoUpdate: boolean = false;

  router: Router = inject(Router);
  loggerService: LoggerService = inject(LoggerService);
  exportExcelService: ExportExcelService = inject(ExportExcelService);
  ramActions: RAMActions = inject(RAMActions);
  equipmentServiceTaskActions: EquipmentServiceTaskActions = inject(EquipmentServiceTaskActions);
  translateService: TranslateService = inject(TranslateService);
  accessControlService: AccessControlService = inject(AccessControlService);
  notificationsService: NotificationsService = inject(NotificationsService);
  ramConnectorErrorsService: RAMConnectorErrorsService = inject(RAMConnectorErrorsService);

  constructor(private store: Store<ApplicationState>) {
    super();

    this.tableHelperReadSavedFiltersValues(
      EquipmentTasksTableFiltersConstants.selectedDropdownsFilterValues,
      EquipmentTasksTableFiltersConstants.selectedDatesFilterValues,
    );
  }

  ngOnInit(): void {
    this.readServiceTaskData();
    this.initiateCompleteChecklistSubscription();
    this.refreshTableOnLocationChange();

    this.isLoading$ = combineLatest([this.store.select(selectServiceTaskPage)]).pipe(
      map(([locationState]: [IStoreApiList<IServiceTaskPage>]) => locationState.isLoading),
      takeUntil(this.destroy),
    );

    this.isSubmitting = combineLatest([
      this.store.select(selectCheckRAMConnectorStatus),
      this.store.select(selectCheckRAMPortStatus),
    ]).pipe(
      takeUntil(this.destroy),
      map(
        ([ramConnectorStatus, portStatus]: [
          ramConnectorStatus: IStoreApiItem<ITestStatus>,
          portStatus: IStoreApiItem<IRAMStatus>,
        ]) => ramConnectorStatus.isLoading || portStatus.isLoading,
      ),
    );

    this.formatDate = getDateFormatFromUserProfile(this.translateService);
  }

  refreshTableOnLocationChange(): void {
    this.store
      .pipe(
        select(selectUpdateEquipmentsLocation),
        filter((equipmentsLocation: IStoreApiItem<ILocationChange>) => !equipmentsLocation.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((response: IStoreApiItem<ILocationChange>) => {
        if (response.isSuccess) {
          this.onTaskServiceSelect([]);
          this.tableHelperPrepareTableParameters();
        }
      });
  }

  initiateCompleteChecklistSubscription(): void {
    combineLatest([this.store.select(selectedSavedServicesChecklist), this.store.select(selectSaveBatchChecklist)])
      .pipe(
        filter(
          ([checklistCompleted, batchChecklistCompleted]: [IStoreApiItem<ITask>, IStoreApiItem<ISaveBatchChecklist>]) =>
            !checklistCompleted.isLoading || !batchChecklistCompleted.isLoading,
        ),
        takeUntil(this.destroy),
      )
      .subscribe(
        ([checklistCompleted, batchChecklistCompleted]: [IStoreApiItem<ITask>, IStoreApiItem<ISaveBatchChecklist>]) => {
          if (checklistCompleted.isSuccess || batchChecklistCompleted.isSuccess) {
            this.selectedTasks = [];
            this.tableHelperResetTablePaging();
            this.tableHelperPrepareTableParameters();
          }

          if (checklistCompleted.isSuccess) {
            this.equipmentServiceTaskActions.resetSaveServicesChecklistState();
          }

          if (batchChecklistCompleted.isSuccess) {
            this.equipmentServiceTaskActions.resetSaveBatchChecklistState();
          }
        },
      );
  }

  fetchTableData(params: PropertyBag): void {
    this.equipmentServiceTaskActions.requestServiceTaskPage(params, this.isExportMode);
  }

  readServiceTaskData(): void {
    this.store
      .pipe(
        select(selectServiceTaskPage),
        takeUntil(this.destroy),
        map((state: IStoreApiList<IServiceTaskPage>) => state.data),
      )
      .subscribe((response: IServiceTaskPage) => {
        this.totalRecords = 0;

        if (response) {
          this.list = structuredClone(response.entries);
          this.totalRecords = response.totalRecords;
          this.totalPages = response.totalPages;

          this.tableHelperCheckOutOfRangePageFilter();

          this.list.forEach((serviceTask: IServiceTask) => {
            serviceTask.nextServiceDate = serviceTask.nextServiceDate
              ? dateFormat(new Date(serviceTask.nextServiceDate).toString(), this.formatDate).split(', ')[0]
              : null;
            serviceTask.lastServiceDate = serviceTask.lastServiceDate
              ? dateFormat(new Date(serviceTask.lastServiceDate).toString(), this.formatDate).split(', ')[0]
              : null;
            serviceTask.uniqueId = `${serviceTask.equipmentAggregateId}+${serviceTask.serviceId}`;
          });
        }
      });
  }

  changeColumnVisibility(columns: ITableColumn[]): void {
    this.tableHelperResetTablePaging();

    columns.forEach((column: ITableColumn) => {
      if (!column.visible) {
        if (this.tableHelperCheckForSorting(column.field)) {
          this.tableHelperResetTableSorting();
        }

        this.tableHelperHideAndResetTableColumn(this.localTableColumns, column);
      }
    });

    this.tableHelperReadSavedFiltersValues(
      EquipmentTasksTableFiltersConstants.selectedDropdownsFilterValues,
      EquipmentTasksTableFiltersConstants.selectedDatesFilterValues,
    );
    this.tableHelperSaveTableSettings(columns);
    this.tableHelperSetAllTableFilters(this.filtersData);

    this.tableHelperPrepareTableParameters();
  }

  getFilterTableValue(event: { nextServiceDate?: string[] | string; lastServiceDate?: string[] | string }): void {
    if ('nextServiceDate' in event) {
      event = {
        ...this.dateFiltersProcessing(
          event as { nextServiceDate: string[] },
          'nextServiceDateStartDate',
          'nextServiceDateEndDate',
          'nextServiceDate',
        ),
      };

      this.tableHelperSetAllTableFilters(event);
    } else if ('lastServiceDate' in event) {
      event = {
        ...this.dateFiltersProcessing(
          event as { nextServiceDate: string[] },
          'lastServiceDateStartDate',
          'lastServiceDateEndDate',
          'lastServiceDate',
        ),
      };
    }

    Object.assign(this.filtersData, event);

    this.tableHelperSetAllTableFilters(this.filtersData);
  }

  getTableColumns(settings: ITableSettings): void {
    this.rows = settings.pageSize;
    this.tableColumns = settings.columns;

    this.tableHelperPrepareTableParameters();
  }

  dateFiltersProcessing(
    event: { nextServiceDate: string[] | string },
    startIntervalName: string,
    endIntervalName: string,
    intervalName: string,
  ): { [key: string]: null } {
    const intervalEndDate: string = event[intervalName][1] ? Object.values(event)[0][1].toString() : '';

    if (Object.values(event)[0] === 'reset-date') {
      return {
        [startIntervalName]: null,
        [endIntervalName]: null,
        [intervalName]: null,
        [intervalName]: null,
      };
    } else {
      return {
        ...this.tableService.tableOnlyDateParameter(
          extractOnlyDate(Object.values(event)[0][0].toString()),
          startIntervalName,
        ),
        ...this.tableService.tableOnlyDateParameter(extractOnlyDate(intervalEndDate), endIntervalName),
      };
    }
  }

  resetSelectionOnSortTable(): void {
    this.selectedTasks.length = 0;
  }

  onTaskServiceSelect(event: IServiceTask[] = []): void {
    this.selectedTasks = event ?? [];

    this.checkChecklistToActivatePlayBtn();
    this.checkProtectorTestToActivatePlayBtn();
  }

  checkChecklistToActivatePlayBtn(): void {
    const serviceIdsSet: Set<unknown> = new Set();
    let taskHasChecklist: boolean = true;

    for (const eq of this.selectedTasks) {
      serviceIdsSet.add(eq.serviceId);

      if (!eq.hasCheckList) {
        taskHasChecklist = false;
      }
    }

    this.activatePlayBtn = serviceIdsSet.size === 1 && taskHasChecklist;
    this.selectedTasksAreNotTheSame = serviceIdsSet.size > 1;
  }

  checkProtectorTestToActivatePlayBtn(): void {
    if (this.selectedTasks[0]?.isProtectorTestType && this.selectedTasks.length === 1) {
      this.activatePlayBtn = true;
    }
  }

  exportExcelFile(): void {
    this.isExportMode = true;

    this.exportExcelService.genericExport(this.getDataForExport, this.preparationForExport).then((data: unknown[]) => {
      this.excelArray = data;
      this.isExportMode = false;
    });
  }

  preparationForExport = (serviceTask: IServiceTask): void => {
    serviceTask.operationalStatus = this.translateService.instant(
      WorkshopConstants.operationalStatusLocalize.status.find(
        (storedType: { value: string; localizedName: string }) => storedType.value === serviceTask.operationalStatus,
      )?.localizedName || serviceTask.operationalStatus,
    ) as string;
    serviceTask.nextServiceDate = serviceTask.nextServiceDate
      ? dateFormat(new Date(serviceTask.nextServiceDate).toString(), this.formatDate).split(', ')[0]
      : null;
    serviceTask.lastServiceDate = serviceTask.lastServiceDate
      ? dateFormat(new Date(serviceTask.lastServiceDate).toString(), this.formatDate).split(', ')[0]
      : null;
  };

  getDataForExport = (page: number = 0): void => {
    this.tableHelperPrepareTableParameters(page);
  };

  completeChecklist(): void {
    localStorage.setItem(WorkshopConstants.selectedTask, JSON.stringify(this.selectedTasks[0]));
    this.router.navigate([
      'workshop',
      'tasks',
      this.accessControlService.equipmentBatchChecklistFeatureToggle && this.selectedTasks.length > 1
        ? WorkshopModuleRoutes.completeBatchChecklist
        : WorkshopModuleRoutes.completeChecklist,
      this.selectedTasks.map((tk: IServiceTask) => tk.equipmentAggregateId).toString(),
    ]);
  }

  editEquipment(selectedTask: IServiceTask): void {
    this.router.navigate(['workshop', 'update', selectedTask?.equipmentAggregateId]);
  }

  openSelectedTaskOnDblClick(selectedTask: IServiceTask): void {
    this.selectedTasks = [selectedTask];

    if (selectedTask.hasCheckList) {
      this.startChecklistOrTestEquipment(WorkshopConstants.hasCheckList);
    } else if (selectedTask.isProtectorTestType && this.accessControlService.equipmentTestingGlobalFeatureToggle) {
      this.startChecklistOrTestEquipment(WorkshopConstants.isProtectorTestType);
    }
  }

  startChecklistOrTestEquipment(type: string): void {
    if (type === WorkshopConstants.hasCheckList) {
      this.completeChecklist();
    } else {
      localStorage.setItem(WorkshopConstants.taksId, this.selectedTasks[0].serviceId);

      checkRAMConnectorPort({
        ramActions: this.ramActions,
        store: this.store,
        notificationsService: this.notificationsService,
      }).then(() => {
        startTest({
          ramActions: this.ramActions,
          store: this.store,
          ramConnectorErrorsService: this.ramConnectorErrorsService,
          router: this.router,
          notificationsService: this.notificationsService,
          equipmentAggregateId: this.selectedTasks[0]?.equipmentAggregateId,
          module: 'workshop/tasks',
        }).then((response: boolean | string) => {
          this.ramConnectorStatus = checkAutoUpdateFeatureToggleBeforeStartProtectorTest(
            this.accessControlService,
            response,
            this.notificationsService,
          );
        });
      });
    }
  }

  startRAMConnectorAutoUpdateFromServiceTasksList(event: boolean): void {
    this.ramConnectorStatus = null;
    this.startAutoUpdate = event;
  }
}
