import { ITableColumn } from 'src/app/common';
import { TableFilterType } from 'src/app/common/constants';

export const tableColumns: ITableColumn[] = [
  {
    field: 'equipmentId',
    header: 'SERVICE_TASK_TABLE.STR_EQUIPMENT_ID',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'SERVICE_TASK_TABLE.STR_EQUIPMENT_ID_PLACEHOLDER',
  },
  {
    field: 'barcode',
    header: 'SERVICE_TASK_TABLE.STR_BARCODE',
    visible: false,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'SERVICE_TASK_TABLE.STR_BARCODE_PLACEHOLDER',
  },
  {
    field: 'rfid',
    header: 'RFID',
    visible: false,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'SERVICE_TASK_TABLE.STR_RFID_PLACEHOLDER',
  },
  {
    field: 'serialNo',
    header: 'SERVICE_TASK_TABLE.STR_SERIAL_NO',
    visible: false,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'SERVICE_TASK_TABLE.STR_SERIAL_NO_PLACEHOLDER',
  },
  {
    field: 'category',
    header: 'SERVICE_TASK_TABLE.STR_CATEGORY',
    visible: false,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'SERVICE_TASK_TABLE.STR_CATEGORY_PLACEHOLDER',
  },
  {
    field: 'type',
    header: 'SERVICE_TASK_TABLE.STR_TYPE',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'SERVICE_TASK_TABLE.STR_TYPE_PLACEHOLDER',
  },
  {
    field: 'model',
    header: 'SERVICE_TASK_TABLE.STR_MODEL',
    visible: false,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'SERVICE_TASK_TABLE.STR_MODEL_PLACEHOLDER',
  },
  {
    field: 'operationalStatus',
    header: 'SERVICE_TASK_TABLE.STR_OPERATIONAL_STATUS',
    visible: true,
    filterType: TableFilterType.DROPDOWN,
    dropdownOptions: [
      {
        value: 'OPERATIONAL',
        label: 'EQUIPMENT_GENERAL.STR_OPERATIONAL_STATUSES_OPERATIONAL',
        icon: 'feedback-positive',
      },
      { value: 'NON_OPERATIONAL', label: 'EQUIPMENT_GENERAL.STR_NON_OPERATIONAL_STATUSES', icon: 'error' },
      {
        value: 'DEFECTIVE_BUT_OPERATIONAL',
        label: 'EQUIPMENT_GENERAL.STR_DEFECTIVE_BUT_OPERATIONAL_STATUSES',
        icon: 'warning',
      },
    ],
    filterPlaceholder: 'SERVICE_TASK_TABLE.STR_OPERATIONAL_STATUS_PLACEHOLDER',
    dropdownWithIcon: true,
  },
  {
    field: 'nextServiceDate',
    header: 'SERVICE_TASK_TABLE.STR_NEXT_SERVICE_DATE',
    visible: true,
    filterType: TableFilterType.DATE,
    filterKey: ['nextServiceDateStartDate', 'nextServiceDateEndDate'],
  },
  {
    field: 'taskName',
    header: 'SERVICE_TASK_TABLE.STR_TASK',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'SERVICE_TASK_TABLE.STR_TASK_PLACEHOLDER',
  },
  {
    field: 'owner',
    header: 'SERVICE_TASK_TABLE.STR_OWNER',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'SERVICE_TASK_TABLE.STR_OWNER_PLACEHOLDER',
  },
  {
    field: 'location',
    header: 'SERVICE_TASK_TABLE.STR_LOCATION',
    visible: true,
    filterType: TableFilterType.TEXT,
    filterPlaceholder: 'SERVICE_TASK_TABLE.STR_LOCATION_PLACEHOLDER',
  },
  {
    field: 'lastServiceDate',
    header: 'SERVICE_TASK_TABLE.STR_LAST_SERVICE_DATE',
    visible: true,
    filterType: TableFilterType.DATE,
    filterKey: ['lastServiceDateStartDate', 'lastServiceDateEndDate'],
  },
];
