import { CreateUpdateAddressBookComponent } from '../components/address-book/create-update-address-book/create-update-address-book.component';
import { CreateUpdateConfigurationComponent } from '../components/equipment-hierarchy/create-update-configuration/create-update-configuration.component';
import { CreateUpdateTaskComponent } from '../components/equipment-hierarchy/create-update-configuration/create-update-task/create-update-task.component';
import { CreateUpdateLocationHierarchyComponent } from '../components/location-hierarchy/create-update-location-hierarchy/create-update-location-hierarchy.component';
import { OrganizationSettingsComponent } from '../components/organization-settings/organization-settings.component';
import { CreateUpdateChecklistComponent } from './../components/equipment-hierarchy/create-update-configuration/create-update-checklist/create-update-checklist.component';

export class DeactivateCreateUpdateConfigurationComponentGuard {
  canDeactivate(component: CreateUpdateConfigurationComponent) {
    return component.canDeactivate();
  }
}

export class DeactivateCreateUpdateServiceGuard {
  canDeactivate(component: CreateUpdateTaskComponent) {
    return component.canDeactivate();
  }
}

export class DeactivateCreateUpdateLocationHierarchyGuard {
  canDeactivate(component: CreateUpdateLocationHierarchyComponent) {
    return component.canDeactivate();
  }
}

export class DeactivateCreateUpdateChecklistGuard {
  canDeactivate(component: CreateUpdateChecklistComponent) {
    return component.canDeactivate();
  }
}

export class DeactivateCreateUpdateAddressBookGuard {
  canDeactivate(component: CreateUpdateAddressBookComponent) {
    return component.canDeactivate();
  }
}

export class DeactivateOrganizationSettingsGuard {
  canDeactivate(component: OrganizationSettingsComponent) {
    return component?.canDeactivate();
  }
}
