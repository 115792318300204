import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, filter, take } from 'rxjs';
import { ApplicationState, CommonConstants, IStoreApiItem } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins';
import { NotificationsService } from 'src/app/common/services';
import { INotificationMessage } from 'src/app/common/state/notifications/models/notification.model';
import { ConfigurationNotificationConstants, LocationHierarchyNotificationConstants } from 'src/app/configuration';
import { SearchedLocation } from 'src/app/configuration/models';
import { LocationHierarchyActions } from 'src/app/configuration/state/actions';
import { selectSearchedLocation } from 'src/app/configuration/state/selectors/configuration.selectors';
import { AppUserPermissionList } from 'src/app/root/models/app-types';
import { AccessControlService } from 'src/app/root/services';
import { EquipmentNotificationConstants, WorkshopConstants } from 'src/app/workshop';
import { IEquipment, IEquipmentLocationAssignment } from 'src/app/workshop/models';
import { WorkflowType } from 'src/app/workshop/models/workflow-page.model';
import { EquipmentTestActions } from 'src/app/workshop/state/actions';
import { selectWorkflowAsset } from 'src/app/workshop/state/selectors';

@Component({
  selector: 'ignis-search-barcode',
  templateUrl: './search-barcode.component.html',
  styleUrls: ['./search-barcode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBarcodeComponent extends OnDestroyMixin() implements OnChanges, OnInit, AfterViewInit {
  @ViewChild('barcodeInput') private barcodeInput: ElementRef;

  isBlueBarButtonsDisabled: boolean = false;
  isAddEquipmentBtnEnabled: boolean = false;
  isAddEquipmentBtnHidden: boolean = false;
  isBarcodeInputValueValid: boolean = true;
  isBarcodeScannerOpened: boolean = false;
  httpErrorCode: string;
  workflowBtnType: typeof WorkflowType = WorkflowType;
  searchRoutePath: string = '/workshop/workflow';

  accessControlService: AccessControlService = inject(AccessControlService);
  equipmentTestActions: EquipmentTestActions = inject(EquipmentTestActions);
  notificationsService: NotificationsService = inject(NotificationsService);
  locationHierarchyActions: LocationHierarchyActions = inject(LocationHierarchyActions);

  @Input() _barcode: string;
  @Input() isSearchInputFocused: boolean;
  @Input() isSubmitting: Observable<boolean>;
  @Input() selectedLocation: IEquipmentLocationAssignment;

  @Output() handleEnteredBarcode: EventEmitter<string> = new EventEmitter<string>();
  @Output() handleMobileLocation: EventEmitter<{ isMobileSearch: boolean; mobileLocation: SearchedLocation }> =
    new EventEmitter<{ isMobileSearch: boolean; mobileLocation: SearchedLocation }>();
  @Output() handleSearchedEquipmentData: EventEmitter<IEquipment> = new EventEmitter<IEquipment>();
  @Output() handleToUpdateEquipmentData: EventEmitter<void> = new EventEmitter<void>();
  @Output() resetSearchInputFocus: EventEmitter<boolean> = new EventEmitter<boolean>();

  get barcode(): string {
    return this._barcode?.trimStart().trimEnd();
  }

  get currentlyWorkflowChecked(): number {
    return Number(localStorage.getItem(WorkshopConstants.selectedWorkflow) || this.workflowBtnType.NONE);
  }

  constructor(
    private router: Router,
    private store: Store<ApplicationState>,
  ) {
    super();

    this.router.events?.subscribe((event: NavigationEnd) => {
      if (
        event instanceof NavigationEnd &&
        (this.currentlyWorkflowChecked as WorkflowType) === this.workflowBtnType.NONE &&
        this.router.url === this.searchRoutePath
      ) {
        this.setFocusToBarcodeInput();
      }
    });
  }

  ngOnChanges(): void {
    if (
      this.isSearchInputFocused &&
      (this.currentlyWorkflowChecked as WorkflowType) === this.workflowBtnType.NONE &&
      this.router.url === this.searchRoutePath
    ) {
      this.setFocusToBarcodeInput();
    }
  }

  ngOnInit(): void {
    this.checkUserPermission();
  }

  ngAfterViewInit(): void {
    this.setFocusToBarcodeInput();
  }

  setFocusToBarcodeInput(): void {
    setTimeout(() => {
      (this.barcodeInput.nativeElement as HTMLElement).focus();
      this.resetSearchInputFocus.emit();
    }, 250);
  }

  getWorkflowAsset(): void {
    if (this.barcode) {
      this.handleEnteredBarcode.emit(this.barcode);
      this.equipmentTestActions.requestWorkflowAsset(this.barcode);
      this.isBlueBarButtonsDisabled = true;

      this.readWorkflowAssetsData();
    }
  }

  addNewEquipment(): void {
    this.router.navigate(['workshop', 'create']);
  }

  readWorkflowAssetsData(): void {
    this.store
      .pipe(
        select(selectWorkflowAsset),
        filter((state: IStoreApiItem<IEquipment>) => !state.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<IEquipment>) => {
        this.httpErrorCode = null;

        if (response.data) {
          this.handleSearchedEquipmentData.emit(response.data);

          if (response.data?.generalData.isCylinderType) {
            this.handleToUpdateEquipmentData.emit();
          }
        } else if (response.errors) {
          this.httpErrorCode = (response.errors?.error.code as string).toString();

          if (
            this.httpErrorCode === EquipmentNotificationConstants.notificationCodes.BARCODE_NO_EXIST.value &&
            this.barcode
          ) {
            this.locationHierarchyActions.requestSearchLocation(this.barcode);

            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.HIDDEN,
              this.httpErrorCode,
              EquipmentNotificationConstants.notificationCodes,
            );

            this.readWorkflowLocation();
            this.readErrorsFormWorkflowLocation();

            return;
          }

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            this.httpErrorCode,
            EquipmentNotificationConstants.notificationCodes,
          );

          this.equipmentTestActions.resetWorkflowAssetState();
        }
      });
  }

  readWorkflowLocation(): void {
    this.store
      .pipe(
        select(selectSearchedLocation),
        filter((state: IStoreApiItem<SearchedLocation>) => state.data !== null && !state.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<SearchedLocation>) => {
        this.httpErrorCode = null;

        if (response.data) {
          if (this.selectedLocation && response.data.aggregateId === this.selectedLocation.aggregateId) {
            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.WARNING,
              ConfigurationNotificationConstants.notificationCodes
                .SEARCHED_LOCATION_IS_SAME_WITH_DIRECTLY_ASSIGNED_LOCATION,
              ConfigurationNotificationConstants.notificationCodes,
            );
          } else {
            this.handleMobileLocation.emit({
              isMobileSearch: !!this.selectedLocation,
              mobileLocation: structuredClone(response.data),
            });
          }

          this.locationHierarchyActions.resetSearchLocation();
        }
      });
  }

  readErrorsFormWorkflowLocation(): void {
    this.store
      .pipe(
        select(selectSearchedLocation),
        filter((state: IStoreApiItem<SearchedLocation>) => state.errors !== null && !state.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<SearchedLocation>) => {
        this.httpErrorCode = null;

        if (response.errors) {
          this.httpErrorCode = (response.errors?.error.code as string)?.toString();
          this.setFocusToBarcodeInput();

          if (
            this.httpErrorCode === ConfigurationNotificationConstants.notificationCodes.LOCATION_ENTITY_NOT_EXIST.value
          ) {
            let notification: INotificationMessage =
              EquipmentNotificationConstants.notificationCodes.SEARCHED_BARCODE_NOT_EXIST;

            if (this.isAddEquipmentBtnHidden) {
              notification = EquipmentNotificationConstants.notificationCodes.BARCODE_NO_EXIST_TESTER;
            } else {
              this.setBarcodeToSaveInLocalStorage();
            }

            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.WARNING,
              notification,
              EquipmentNotificationConstants.notificationCodes,
            );
          } else if (
            this.httpErrorCode === ConfigurationNotificationConstants.notificationCodes.SEARCHED_STATIC_LOCATION.value
          ) {
            this.isAddEquipmentBtnEnabled = false;

            this.notificationsService.requestShowNotification(
              CommonConstants.notificationType.WARNING,
              ConfigurationNotificationConstants.notificationCodes.SEARCHED_STATIC_LOCATION,
              LocationHierarchyNotificationConstants.notificationCodes,
            );
          }

          this.locationHierarchyActions.resetSearchLocation();
        }
      });
  }

  setBarcodeToSaveInLocalStorage(): void {
    if (this.barcode?.length > 0) {
      this.isAddEquipmentBtnEnabled = true;
      localStorage.setItem(WorkshopConstants.barCodeToSave, this.barcode);
    }
  }

  checkUserPermission(): void {
    this.isAddEquipmentBtnHidden = !this.accessControlService.checkPermission(AppUserPermissionList.equipmentWrite);
  }

  startBarcodeScanner(): void {
    this.isBarcodeScannerOpened = true;
  }

  getScannedBarcode(event: string): void {
    this._barcode = event;
    this.getWorkflowAsset();
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions, @typescript-eslint/dot-notation
    super['ngOnDestroy'] && super['ngOnDestroy']();
  }
}
