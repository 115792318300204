<ng-template odxModal #qrCodeModal>
  <odx-modal-header>
    <odx-area-header>
      {{ 'QR_CODE_MODAL.STR_HEADER' | translate }}
    </odx-area-header>
  </odx-modal-header>
  <odx-modal-content class="qr-content">
    <div class="row">
      <div class="col-12">
        {{ 'QR_CODE_MODAL.STR_CONTENT' | translate }}
      </div>
    </div>
    <div class="row mt-4 text-center">
      <div class="col-12">
        <qrcode
          [margin]="1"
          [qrdata]="qrData"
          [width]="475"
          [errorCorrectionLevel]="'M'"
          [elementType]="'png'"
          data-test-id="qr_code_modal.qr_code"
        ></qrcode>
      </div>
    </div>
  </odx-modal-content>

  <odx-modal-footer>
    <button odxButton variant="primary" [odxModalClose]="false" data-test-id="modal_actions.done_btn">
      {{ 'QR_CODE_MODAL.STR_DONE_BTN' | translate }}
    </button>
  </odx-modal-footer>
</ng-template>
