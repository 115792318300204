import { INotificationConstant } from 'src/app/common';

export class DeviceConnectionNotificationConstants {
  public static readonly notificationCodes: INotificationConstant = {
    DEVICE_UNEXPECTED_ERROR: {
      value: '9001',
      text: 'DEVICE_CONNECTION_NOTIFICATION.STR_UNEXPECTED_ERROR',
    },
    ENTITY_NOT_FOUND: {
      value: '9002',
      text: 'DEVICE_CONNECTION_NOTIFICATION.STR_ENTITY_NOT_FOUND',
    },
    AGGREGATE_VERSION_CONFLICT: {
      value: '9003',
      text: 'DEVICE_CONNECTION_NOTIFICATION.STR_USER_AGGREGATE_VERSION_CONFLICT',
    },
    DEVICE_INVALID_IDENTIFIER: {
      value: '9004',
      text: 'DEVICE_CONNECTION_NOTIFICATION.STR_INVALID_IDENTIFIER',
    },
    ADD_DEVICE_CONNECTION_SUCCESS: {
      value: 'ADD_DEVICE_CONNECTION_SUCCESS',
      text: 'DEVICE_CONNECTION_NOTIFICATION.STR_DEVICE_CONNECTION_SAVED_SUCCESS',
    },
    UPDATE_APP_DEVICE_CONNECTION_SUCCESS: {
      value: 'UPDATE_APP_DEVICE_CONNECTION_SUCCESS',
      text: 'DEVICE_CONNECTION_NOTIFICATION.STR_UPDATE_APP_DEVICE_CONNECTION_SUCCESS',
    },
    UPDATE_DEVICE_CONNECTION_SUCCESS: {
      value: 'UPDATE_DEVICE_CONNECTION_SUCCESS',
      text: 'DEVICE_CONNECTION_NOTIFICATION.STR_DEVICE_CONNECTION_UPDATED_SUCCESS',
    },
    DELETE_API_KEY_DEVICE_CONNECTION_SUCCESS: {
      value: 'DELETE_API_KEY_DEVICE_CONNECTION_SUCCESS',
      text: 'DEVICE_CONNECTION_NOTIFICATION.STR_API_KEY_DELETED_SUCCESS',
    },
    DELETE_APP_DEVICE_CONNECTION_SUCCESS: {
      value: 'DELETE_APP_DEVICE_CONNECTION_SUCCESS',
      text: 'DEVICE_CONNECTION_NOTIFICATION.STR_APP_DELETED_SUCCESS',
    },
    DELETE_HUB_DEVICE_CONNECTION_SUCCESS: {
      value: 'DELETE_HUB_DEVICE_CONNECTION_SUCCESS',
      text: 'DEVICE_CONNECTION_NOTIFICATION.STR_HUB_DELETED_SUCCESS',
    },
    DELETE_WORKSHOP_DEVICE_CONNECTION_SUCCESS: {
      value: 'DELETE_WORKSHOP_DEVICE_CONNECTION_SUCCESS',
      text: 'DEVICE_CONNECTION_NOTIFICATION.STR_WORKSHOP_DELETED_SUCCESS',
    },
    DELETE_RAM_CONNECTOR_DEVICE_CONNECTION_SUCCESS: {
      value: 'DELETE_RAM_CONNECTOR_DEVICE_CONNECTION_SUCCESS',
      text: 'DEVICE_CONNECTION_NOTIFICATION.STR_RAM_CONNECTOR_DELETED_SUCCESS',
    },
    MAXIMUM_NUMBER_OF_ASSETS_HAS_BEEN_REACHED: {
      value: '9009',
      text: 'DEVICE_CONNECTION_NOTIFICATION.STR_MAXIMUM_NUMBER_OF_DEVICES_HAS_BEEN_REACHED',
    },
    DEVICE_CANNOT_BE_DELETED_LESS_ONE_HOUR: {
      value: '9015',
      text: 'DEVICE_CONNECTION_NOTIFICATION.STR_NOTIFICATION.STR_DEVICE_CANT_BE_DELETED_AFTER_ONE_HOUR',
    },
    RAM_CONNECTOR_CANNOT_BE_DELETED_BECAUSE_OF_MIGRATION: {
      value: '9015',
      text: 'DEVICE_CONNECTION_NOTIFICATION.STR_RAM_MIGRATION_IN_PROGRESS',
    },
    INVALID_QR_CODE: {
      value: 'INVALID_QR_CODE',
      text: 'DEVICE_CONNECTION_NOTIFICATION.STR_INVALID_QR_CODE',
    },
  };
}
