import { GenericAction } from 'src/app/common';
import { errorItemData, errorListData, errorPostPutItemData, receiveItemData, receiveListData, receivePostPutItemData, requestItemData, requestListData, requestPostPutItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { IIncident, IIncidentEcp, IMonitoringIncidents, IRemoveIncident } from 'src/app/remote-monitoring/models/remote-monitoring.model';
import { RemoteMonitoringActionTypes } from '../../actions/remote-monitoring-action-types';
import { IRemoteMonitoringIncidentState, RemoteMonitoringIncidentState } from '../../models/incident.model';


export function remoteMonitoringIncidentReducer(
  lastState: IRemoteMonitoringIncidentState = new RemoteMonitoringIncidentState(),
  action: GenericAction<RemoteMonitoringActionTypes, any>
): IRemoteMonitoringIncidentState {


  switch (action.type) {
    case RemoteMonitoringActionTypes.REQUEST_MONITORING_INCIDENTS:
      return requestListData<IRemoteMonitoringIncidentState, IMonitoringIncidents>(lastState, ['monitoringIncidentList']);
    case RemoteMonitoringActionTypes.RECEIVE_MONITORING_INCIDENTS:
      return receiveListData<IRemoteMonitoringIncidentState, IMonitoringIncidents>(lastState, ['monitoringIncidentList'], action.payload);
    case RemoteMonitoringActionTypes.ERROR_RECEIVE_MONITORING_INCIDENTS:
      return errorListData<IRemoteMonitoringIncidentState, IMonitoringIncidents>(lastState, ['monitoringIncidentList'], action.payload);

    case RemoteMonitoringActionTypes.REQUEST_REMOVE_INCIDENT:
      return requestPostPutItemData<IRemoteMonitoringIncidentState, IRemoveIncident>(lastState, ['closedIncident'], action.payload);
    case RemoteMonitoringActionTypes.RECEIVE_REMOVE_INCIDENT:
      return receivePostPutItemData<IRemoteMonitoringIncidentState, IRemoveIncident>(lastState, ['closedIncident'], action.payload);
    case RemoteMonitoringActionTypes.ERROR_RECEIVE_REMOVE_INCIDENT:
      return errorPostPutItemData<IRemoteMonitoringIncidentState, IRemoveIncident>(lastState, ['closedIncident'], action.payload);

    case RemoteMonitoringActionTypes.REQUEST_MONITORING_INCIDENT_BY_ID:
      return requestItemData<IRemoteMonitoringIncidentState, IIncident>(lastState, ['selectedIncident']);
    case RemoteMonitoringActionTypes.RECEIVE_MONITORING_INCIDENT_BY_ID:
      return receiveItemData<IRemoteMonitoringIncidentState, IIncident>(lastState, ['selectedIncident'], action.payload);
    case RemoteMonitoringActionTypes.ERROR_RECEIVE_MONITORING_INCIDENT_BY_ID:
      return errorItemData<IRemoteMonitoringIncidentState, IIncident>(lastState, ['selectedIncident'], action.payload);

    case RemoteMonitoringActionTypes.REQUEST_INCIDENT_ECP_BY_ID:
      return requestItemData<IRemoteMonitoringIncidentState, IIncidentEcp>(lastState, ['selectedEcp']);
    case RemoteMonitoringActionTypes.RECEIVE_INCIDENT_ECP_BY_ID:
      return receiveItemData<IRemoteMonitoringIncidentState, IIncidentEcp>(lastState, ['selectedEcp'], action.payload);
    case RemoteMonitoringActionTypes.ERROR_RECEIVE_INCIDENT_ECP_BY_ID:
      return errorItemData<IRemoteMonitoringIncidentState, IIncidentEcp>(lastState, ['selectedEcp'], action.payload);

    default:
      return lastState;
  }
}
