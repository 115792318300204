<router-outlet></router-outlet>

<ignis-progress-bar *ngIf="isLoading | async"></ignis-progress-bar>
<div class="history">
  <div class="custom-odx-tab-bar odx-margin-bottom-24">
    <div class="odx-tab-bar__inner">
      <div class="odx-tab-bar__panel padding-left-0">
        <div class="back-btn-group">
          <button
            odxNavigationBack
            id="navigation-back"
            data-test-id="equipment_history.equipment_back"
            class="odx-tab-bar-item"
            (click)="navigateToEquipment()"
          ></button>
          <span class="odx-fs-normal">
            @if (!isComingFromEqPage) {
              {{ 'EQUIPMENT_HISTORY.STR_INVENTORY_PAGE' | translate }}
            } @else {
              {{ 'EQUIPMENT_HISTORY.STR_EQUIPMENT_BACK' | translate }}
            }
          </span>
        </div>
        <div class="tab-is-active">
          <button odxButton class="odx-tab-bar-item">
            {{ 'EQUIPMENT_HISTORY.STR_EQUIPMENT_HISTORY' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="timeline">
    <div class="timeline-header" *ngIf="selectedEquipment" id="timeline-header">
      <span
        class="equipment-name"
        title="{{ selectedEquipment.generalData.model.type.name }} - {{ selectedEquipment.generalData.model.name }}"
      >
        {{ selectedEquipment.generalData.model.type.name }} - {{ selectedEquipment.generalData.model.name }}
      </span>
      <span class="equipment-identifier"> ID-{{ selectedEquipment.generalData.identifier }} </span>
    </div>
    <div class="timeline-content" id="timeline-content">
      <p-timeline
        *ngIf="displayTimeline"
        [value]="history"
        [align]="'alternate'"
        layout="horizontal"
        styleClass="customized-timeline"
        data-test-id="equipment_history.timeline"
      >
        <ng-template pTemplate="content" let-event>
          <ignis-timeline-event [event]="event" [formatDate]="formatDate"></ignis-timeline-event>
        </ng-template>
        <ng-template pTemplate="opposite" let-event>
          <span class="display-year" *ngIf="event.displayYear">{{ event.displayYear }}</span>
        </ng-template>
      </p-timeline>
      <div id="spacer" #spacerElem></div>
    </div>
  </div>
</div>

<ignis-timeline-filter *ngIf="openFilterModal" [isOpen]="openFilterModal" (closeFilterModal)="toggleFilterModal(false)">
</ignis-timeline-filter>

<footer class="blue-bar">
  <ignis-equipment-history-view-footer (handleFilter)="toggleFilterModal(true)" [filterParams]="filterParams | async">
  </ignis-equipment-history-view-footer>
</footer>
