<ng-container *ngIf="entry?.data" [ngSwitch]="entry?.data">
  <ng-container *ngSwitchCase="deviceCategories.APP">
    <odx-icon name="tablet" size="inline" class="device-connection-category-icon"></odx-icon>
    {{ 'DEVICE_CONNECTION.STR_APP' | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="deviceCategories.HUB">
    <odx-icon name="fg-hub" size="inline" class="device-connection-category-icon"></odx-icon>
    {{ 'DEVICE_CONNECTION.STR_HUB' | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="deviceCategories.WORKSHOP">
    <odx-icon name="pc" size="inline" class="device-connection-category-icon"></odx-icon>
    {{ 'DEVICE_CONNECTION.STR_WORKSHOP' | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="deviceCategories.RAM_CONNECTOR">
    <odx-icon name="test-eq-connection" size="inline" class="device-connection-category-icon"></odx-icon>
    {{ 'DEVICE_CONNECTION.STR_RAM_CONNECTOR' | translate }}
  </ng-container>
  <ng-container *ngSwitchCase="deviceCategories.API_KEY">
    <odx-icon name="password" size="inline" class="device-connection-category-icon"></odx-icon>
    {{ 'DEVICE_CONNECTION.STR_API_KEY' | translate }}
  </ng-container>
  <ng-container *ngSwitchDefault>{{ entry }}</ng-container>
</ng-container>
