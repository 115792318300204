import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EntryModel, IEntryModel } from 'src/app/common/models/common.model';
import { UploadFilesService } from 'src/app/common/services/upload-helper/upload-files.service';
import {
  ErrorReceiveTableExport,
  ReceiveTableExport,
} from 'src/app/common/state/export/actions/export-action-types-creators';
import { EntriesModel, IEntriesModel, IIncident, IIncidentAnalysis, Incident } from '../../models/incident.model';
import { IncidentService } from '../../services/incident.service';
import { IncidentActionTypes } from '../actions/incident-action-types';
import {
  ErrorReceiveIncidentAnalysis,
  ReceiveIncidentAnalysis,
} from '../actions/incident-action-types-creators/incident-analysis-action-types.creators';
import {
  ErrorReceiveIncidentById,
  ErrorReceiveIncidentEntries,
  ErrorReceiveIncidentPage,
  ErrorUpdateIncident,
  ReceiveIncidentById,
  ReceiveIncidentEntries,
  ReceiveIncidentPage,
  ReceiveUpdateIncident,
} from '../actions/incident-action-types-creators/incident-data-action-types-creators';
import {
  ErrorAddEventMarker,
  ErrorReceiveEventMarkerById,
  ErrorReceiveEventMarkerDelete,
  ErrorReceiveEventMarkersEntries,
  ErrorUpdateEventMarker,
  ReceiveAddEventMarker,
  ReceiveEventMarkerById,
  ReceiveEventMarkerDelete,
  ReceiveEventMarkersEntries,
  ReceiveUpdateEventMarker,
} from '../actions/incident-action-types-creators/incident-event-marker-action-types-creators';
import {
  ErrorIncidentExcelData,
  ReceiveIncidentExcelData,
} from '../actions/incident-action-types-creators/incident-excel-action-types-creators';
import {
  ErrorMergeIncidents,
  ReceiveMergeIncidents,
  ResetState,
} from '../actions/incident-action-types-creators/incident-merge-action-types-creators';
import {
  ErrorReceiveAddNoteOrMediaFile,
  ErrorReceiveIncidentMediaAssets,
  ReceiveAddNoteOrMediaFile,
  ReceiveIncidentMediaAssets,
} from '../actions/incident-action-types-creators/incident-note-action-types-creators';
import {
  EventMarker,
  IEventMarker,
  IIncidentExcelData,
  IIncidentPage,
  IMediaAssets,
  IncidentAnalysis,
  IncidentPage,
  MediaAssets,
} from './../../models/incident.model';

@Injectable()
export class IncidentEffects {
  constructor(
    private incidentService: IncidentService,
    private uploadFileService: UploadFilesService,
  ) {}

  requestIncidents$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(IncidentActionTypes.REQUEST_INCIDENTS),
      switchMap((params: any) =>
        this.incidentService.getIncidents(params.payload).pipe(
          map((incidentPage: IIncidentPage) => new ReceiveIncidentPage(new IncidentPage(incidentPage))),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveIncidentPage(error))),
        ),
      ),
    );
  });

  requestIncidentsExport$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(IncidentActionTypes.REQUEST_INCIDENTS_EXPORT),
      switchMap((params: any) =>
        this.incidentService.getIncidents(params.payload).pipe(
          map((incidentPage: IIncidentPage) => new ReceiveTableExport(new IncidentPage(incidentPage))),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveTableExport(error))),
        ),
      ),
    );
  });

  requestIncidentById$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(IncidentActionTypes.REQUEST_INCIDENT_BY_ID),
      switchMap((incidentId: any) =>
        this.incidentService.getIncidentById(incidentId.payload).pipe(
          map((incident: IIncident | any) => new ReceiveIncidentById(new Incident(incident?.body))),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveIncidentById(error))),
        ),
      ),
    );
  });

  requestIncidentEntries$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(IncidentActionTypes.REQUEST_INCIDENT_ENTRIES),
      switchMap(() =>
        this.incidentService.getIncidentEntries().pipe(
          map((entries: IEntriesModel) => new ReceiveIncidentEntries(new EntriesModel(entries))),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveIncidentEntries(error))),
        ),
      ),
    );
  });

  updateIncident$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(IncidentActionTypes.REQUEST_UPDATE_INCIDENT),
      switchMap((incident: any) =>
        this.incidentService.updateIncident(incident.payload).pipe(
          map((inc: IIncident) => new ReceiveUpdateIncident(inc)),
          catchError((error: HttpErrorResponse) => of(new ErrorUpdateIncident(error))),
        ),
      ),
    );
  });

  mergeIncidents$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(IncidentActionTypes.REQUEST_MERGE_INCIDENTS),
      switchMap((incidentsIds: any) =>
        this.incidentService.mergeIncidents(incidentsIds.payload).pipe(
          map((response: any) => new ReceiveMergeIncidents(response)),
          catchError((error: HttpErrorResponse) => of(new ErrorMergeIncidents(error))),
        ),
      ),
    );
  });

  incidentExcelData$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(IncidentActionTypes.REQUEST_INCIDENT_EXCEL_DATA),
      switchMap((incidentId: string) =>
        this.incidentService.getIncidentExcelData(incidentId).pipe(
          map((response: IIncidentExcelData[]) => new ReceiveIncidentExcelData(response)),
          catchError((error: HttpErrorResponse) => of(new ErrorIncidentExcelData(error))),
        ),
      ),
    );
  });

  requestIncidentAnalysis$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(IncidentActionTypes.REQUEST_INCIDENT_ANALYSIS),
      switchMap((incidentId: any) =>
        this.incidentService.getIncidentAnalysis(incidentId.payload).pipe(
          map(
            (incidentAnalysis: IIncidentAnalysis | any) =>
              new ReceiveIncidentAnalysis(new IncidentAnalysis(incidentAnalysis.body)),
          ),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveIncidentAnalysis(error))),
        ),
      ),
    );
  });

  requestEventMarkersEntries$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(IncidentActionTypes.REQUEST_EVENT_MARKERS_ENTRIES),
      switchMap(() =>
        this.incidentService.getEventMarkersEntries().pipe(
          map(
            (entries: IEntryModel[]) =>
              new ReceiveEventMarkersEntries(entries.map((entry: IEntryModel) => new EntryModel(entry))),
          ),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveEventMarkersEntries(error))),
        ),
      ),
    );
  });

  addEventMarker$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(IncidentActionTypes.REQUEST_ADD_EVENT_MARKER),
      switchMap((eventMarker: any) =>
        this.incidentService.addEventMarker(eventMarker.payload, eventMarker.incidentId, eventMarker.ecpAddress).pipe(
          map((ev: IEventMarker) => new ReceiveAddEventMarker(ev)),
          catchError((error: HttpErrorResponse) => of(new ErrorAddEventMarker(error))),
        ),
      ),
    );
  });

  updateEventMarker$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(IncidentActionTypes.REQUEST_UPDATE_EVENT_MARKER),
      switchMap((eventMarker: any) =>
        this.incidentService
          .updateEventMarker(eventMarker.payload, eventMarker.incidentId, eventMarker.ecpAddress)
          .pipe(
            map((ev: IEventMarker) => new ReceiveUpdateEventMarker(ev)),
            catchError((error: HttpErrorResponse) => of(new ErrorUpdateEventMarker(error))),
          ),
      ),
    );
  });

  requestEventMarkerById$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(IncidentActionTypes.REQUEST_EVENT_MARKER_BY_ID),
      switchMap((ids: any) =>
        this.incidentService.getEventMarkerById(ids.payload, ids.eventMarkerId, ids.ecpAddress).pipe(
          map((eventMarker: IEventMarker | any) => new ReceiveEventMarkerById(new EventMarker(eventMarker?.body))),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveEventMarkerById(error))),
        ),
      ),
    );
  });

  requestEventMarkerDelete$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(IncidentActionTypes.REQUEST_EVENT_MARKER_DELETE),
      switchMap((eventMarkerPayload: any) =>
        this.incidentService
          .deleteEventMarker(
            eventMarkerPayload.incidentId,
            eventMarkerPayload.payload,
            eventMarkerPayload.ecpAddress,
            eventMarkerPayload.version,
          )
          .pipe(
            map((deletedEventMarker: IEventMarker) => new ReceiveEventMarkerDelete(deletedEventMarker)),
            catchError((error: HttpErrorResponse) => of(new ErrorReceiveEventMarkerDelete(error))),
          ),
      ),
    );
  });

  requestIncidentMediaAssets$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(IncidentActionTypes.REQUEST_INCIDENT_MEDIA_ASSETS),
      switchMap((incidentId: any) =>
        this.incidentService.getMediaAssets(incidentId.payload).pipe(
          map((mediaAssets: IMediaAssets[] | any) => {
            return new ReceiveIncidentMediaAssets(
              mediaAssets?.body.map((media: IMediaAssets | any) => new MediaAssets(media)),
            );
          }),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveIncidentMediaAssets(error))),
        ),
      ),
    );
  });

  requestAdNote$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(IncidentActionTypes.REQUEST_ADD_MEDIA_NOTE_OR_MEDIA_FILE),
      switchMap((newNote: any) =>
        this.incidentService.addNote(newNote.incidentId, newNote.payload, newNote.version).pipe(
          map(() => new ReceiveAddNoteOrMediaFile()),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAddNoteOrMediaFile(error))),
        ),
      ),
    );
  });

  resetState$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(IncidentActionTypes.RECEIVE_MERGE_INCIDENTS, IncidentActionTypes.ERROR_MERGE_INCIDENTS),
      map(() => {
        return new ResetState();
      }),
    );
  });
}
