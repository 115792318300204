import { IStoreApiItem, IStoreApiList, StoreApiItem, StoreApiList } from 'src/app/common';
import { IIncident, IIncidentEcp, IMonitoringIncidents, IRemoveIncident } from '../../models/remote-monitoring.model';

export interface IRemoteMonitoringIncidentState {
  closedIncident: IStoreApiItem<IRemoveIncident>;
  monitoringIncidentList: IStoreApiList<IMonitoringIncidents>,
  selectedEcp: IStoreApiItem<IIncidentEcp>;
  selectedIncident: IStoreApiItem<IIncident>;
}

export class RemoteMonitoringIncidentState implements IRemoteMonitoringIncidentState {
  closedIncident: IStoreApiItem<IRemoveIncident> = new StoreApiItem();
  monitoringIncidentList: IStoreApiList<IMonitoringIncidents> = new StoreApiList();
  selectedEcp: IStoreApiItem<IIncidentEcp> = new StoreApiItem();
  selectedIncident: IStoreApiItem<IIncident> = new StoreApiItem();
}
