<ng-container *ngIf="entry" [ngSwitch]="entry.value">
  <ng-container *ngSwitchCase="'finished'">{{ 'REMOTE_MONITORING.STR_STATUS_FINISHED' | translate }}</ng-container>
  <ng-container *ngSwitchCase="'at_incident'">{{
    'REMOTE_MONITORING.STR_STATUS_AT_INCIDENT' | translate
  }}</ng-container>
  <ng-container *ngSwitchCase="'retreating'">{{ 'REMOTE_MONITORING.STR_STATUS_RETREATING' | translate }}</ng-container>
  <ng-container *ngSwitchCase="'entering_incident'">{{
    'REMOTE_MONITORING.STR_STATUS_ENTERING_INCIDENT' | translate
  }}</ng-container>
  <ng-container *ngSwitchCase="'standby'">{{ 'REMOTE_MONITORING.STR_STATUS_STANDBY' | translate }}</ng-container>
  <ng-container *ngSwitchDefault>{{ entry.value }}</ng-container>
</ng-container>
