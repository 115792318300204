import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { Theme, ThemingService } from '@odx/angular/theming';

@Component({
  selector: 'ignis-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenancePageComponent implements OnInit {
  @Input() maintenanceText: string;

  themingService: ThemingService = inject(ThemingService);

  ngOnInit(): void {
    this.themingService.selectTheme(Theme.DARK);
  }
}
