<ng-template odxModal #importCSVErrorModal let-modal="close">
  <odx-modal-header>
    <odx-area-header>
      <span class="import-csv-error-modal-message">
        <ng-content select="[slot=header]"></ng-content>
      </span>
    </odx-area-header>
  </odx-modal-header>
  <odx-modal-content [ngStyle]="{ overflow: 'auto' }">
    <ignis-table [tableColumns]="tableColumns" [dataTable]="invalidDataList" class="error-table"> </ignis-table>
  </odx-modal-content>
  <odx-modal-footer>
    <button odxButton variant="primary" odxModalClose (click)="closeModal()" data-test-id="modal_actions.ok_btn">
      {{ 'IMPORT_CSV_ERROR_MODAL.STR_OK_BTN' | translate }}
    </button>
  </odx-modal-footer>
</ng-template>
