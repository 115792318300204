import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from 'redux';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { WorkshopService } from 'src/app/workshop/services';
import * as models from '../../../models';
import * as actions from '../../actions/equipment-update-ids';

@Injectable()
export class EquipmentUpdateIdsEffects {
  workshopService: WorkshopService = inject(WorkshopService);

  updateEquipmentIds$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentUpdateIdsActionTypes.REQUEST_UPDATE_EQUIPMENT_IDS),
      switchMap((payload: any) => {
        return this.workshopService
          .updateEquipmentIds(
            payload.equipmentId,
            {
              barcode: payload.fields.barcode,
              rfid: payload.fields.rfid,
              equipmentAggregateId: payload.fields.equipmentAggregateId,
            },
            payload.version,
          )
          .pipe(
            map((eq: models.IEquipment) => new actions.ReceiveUpdateEquipmentIds(eq)),
            catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveUpdateEquipmentIds(error))),
          );
      }),
    );
  });
}
