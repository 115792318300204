<ng-template odxModal #licensesModal>
  <odx-modal-header>
    <odx-area-header>
      {{ 'LICENSES.STR_TITLE' | translate }}
    </odx-area-header>
  </odx-modal-header>
  <odx-modal-content>
    @if (isLoading | async) {
      <div [odxLoadingSpinner]="isLoading | async" [odxLoadingSpinnerMinHeight]="100" class="w-100"></div>
    } @else {
      <div class="license-group">
        <p class="title">
          {{ 'LICENSES.STR_TICKET_IDS' | translate }}
        </p>

        @if (organizationLicenseInfo?.length < 1) {
          <div>
            {{ 'LICENSES.STR_NO_ACTIVE_LICENSE' | translate }}
          </div>
        } @else {
          <form [formGroup]="licenseForm" (ngSubmit)="onSubmit()">
            <div class="mt-3 mb-3">
              <div class="ticket-id-group" odxLayout="grid">
                <odx-form-field
                  variant="horizontal"
                  [label]="'LICENSES.STR_TICKET_ID' | translate"
                  [ngClass]="{
                    'has-error':
                      httpErrorResponse === notificationConstants.LICENSE_IS_INVALID.value ||
                      httpErrorResponse === notificationConstants.LICENSE_ALREADY_USED.value,
                  }"
                  odxLayout="10"
                >
                  <input
                    formControlName="ticketNumber"
                    id="ticket-id"
                    data-test-id="licenses.ticket_id_placeholder"
                    odxFormFieldControl
                    ignisDisableAutocomplete
                    placeholder="{{ 'LICENSES.STR_TICKET_ID_PLACEHOLDER' | translate }}"
                    (keyup)="onTicketChange()"
                  />

                  <odx-icon
                    *ngIf="httpErrorResponse"
                    name="info"
                    class="form-input-info-icon"
                    iconSet="core"
                    [odxTooltip]="ticketNumberTooltipContent"
                    [odxTooltipVisible]="
                      httpErrorResponse === notificationConstants.LICENSE_IS_INVALID.value ||
                      httpErrorResponse === notificationConstants.LICENSE_ALREADY_USED.value
                    "
                    [odxTooltipDisabled]="!httpErrorResponse"
                  ></odx-icon>
                  <ng-template #ticketNumberTooltipContent>
                    @if (httpErrorResponse === notificationConstants.LICENSE_IS_INVALID.value) {
                      <div id="ticketID-error-tooltip">
                        {{ 'USER_NOTIFICATION.STR_INVALID_LICENSE' | translate }}
                      </div>
                    } @else {
                      <div id="ticketID-error-tooltip">
                        {{ 'USER_NOTIFICATION.STR_USED_LICENSE' | translate }}
                      </div>
                    }
                  </ng-template>
                </odx-form-field>

                <button
                  odxLayout="2"
                  odxButton
                  type="submit"
                  variant="highlight"
                  [disabled]="(isLoading | async) || !licenseForm.get('ticketNumber').value"
                  class="activate-ticketID-btn"
                  data-test-id="licenses.ticket_id_activate_btn"
                >
                  {{ 'LICENSES.STR_TICKET_ID_ACTIVATE_BTN' | translate }}
                </button>
              </div>
            </div>
          </form>
        }

        <div odxLayout="grid" *ngFor="let ticket of organizationLicenseInfo">
          <span odxLayout="6" class="description">{{ ticket.label | translate }}:</span>
          <strong odxLayout="6"> {{ ticket?.ticketId }}</strong>
        </div>
      </div>
      <div class="license-group" *ngIf="deviceConnectionLicenseInfo">
        <p class="title">
          {{ 'LICENSES.STR_FIREGROUND_LICENSE' | translate }}
        </p>

        <div odxLayout="grid" class="mt-3">
          <span odxLayout="8" class="description">
            {{ 'LICENSES.STR_MAXIMUM_NUMBER_OF_FIREGROUND_HUBS' | translate }}:
          </span>

          <strong odxLayout="4">
            {{
              deviceConnectionLicenseInfo?.noAllowedHubs < 0
                ? ('LICENSES.STR_UNLIMITED' | translate)
                : deviceConnectionLicenseInfo?.noAllowedHubs
            }}</strong
          >
        </div>
        <div odxLayout="grid">
          <span odxLayout="8" class="description">
            {{ 'LICENSES.STR_CURRENTLY_REGISTERED_FIREGROUND_HUBS' | translate }}:
          </span>

          <strong odxLayout="4"> {{ deviceConnectionLicenseInfo?.noRegisteredHubs }}</strong>
        </div>
      </div>

      <div class="license-group" *ngIf="equipmentLicenseInfo">
        <p class="title">
          {{ 'LICENSES.STR_RESOURCE_AND_ASSET_MANAGEMENT_LICENSE' | translate }}
        </p>

        <div odxLayout="grid" class="mt-3">
          <span odxLayout="8" class="description"> {{ 'LICENSES.STR_MAXIMUM_NUMBER_OF_ASSETS' | translate }}: </span>

          <strong odxLayout="4">
            {{
              equipmentLicenseInfo?.noAllowedAssets < 0
                ? ('LICENSES.STR_UNLIMITED' | translate)
                : equipmentLicenseInfo?.noAllowedAssets
            }}</strong
          >
        </div>
        <div odxLayout="grid">
          <span odxLayout="8" class="description"> {{ 'LICENSES.STR_CURRENTLY_REGISTERED_ASSETS' | translate }}: </span>

          <strong odxLayout="4"> {{ equipmentLicenseInfo?.noCreatedAssets }}</strong>
        </div>
      </div>
    }
  </odx-modal-content>
  <odx-modal-footer>
    <button
      odxButton
      (click)="handleCloseModal()"
      variant="primary"
      id="close-modal"
      data-test-id="modal_actions.close_btn"
    >
      {{ 'LICENSES.STR_CLOSE_BTN' | translate }}
    </button>
  </odx-modal-footer>
</ng-template>
