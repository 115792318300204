import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PropertyBag } from 'src/app/common/models/common.model';
import { ICustomColumn } from 'src/app/common/models/table.model';
import { DeviceConnectionConstants } from 'src/app/device-connection/constants/device-connection.constants';

@Component({
  selector: 'ignis-device-connection-category-translation',
  templateUrl: './device-connection-category-translation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceConnectionCategoryTranslationComponent {
  @Input() entry: ICustomColumn;

  deviceCategories: PropertyBag = DeviceConnectionConstants.deviceCategories;
}
