import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalRef, ModalService } from '@odx/angular/components/modal';
import { BehaviorSubject, Observable, takeUntil } from 'rxjs';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';

@Component({
  selector: 'ignis-confirm-start-cylinder-charge',
  templateUrl: './confirm-start-cylinder-charge.component.html',
})
export class ConfirmStartCylinderChargeComponent extends OnDestroyMixin() implements OnChanges {
  @Input() isOpen: boolean = false;

  @Output() closeConfirmModal: EventEmitter<boolean> = new EventEmitter<boolean>();
  modalReference: ModalRef;

  @ViewChild('confirmStartCylinderChargeModal', { read: TemplateRef })
  public confirmStartCylinderChargeModal: TemplateRef<ViewContainerRef>;

  confirmationStartCylinderChargeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  confirmationState$: Observable<boolean> = this.confirmationStartCylinderChargeSubject.asObservable();

  constructor(
    private modalService: ModalService,
    public router: Router,
  ) {
    super();
  }

  /* istanbul ignore next */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpen && this.isOpen) {
      this.modalReference = this.modalReference = this.modalService.open(this.confirmStartCylinderChargeModal, {
        size: 'small',
        dismissable: false,
      });

      this.modalReference.onDismiss$.pipe(takeUntil(this.destroy)).subscribe(() => {
        this.closeConfirmModal.emit(false);
        this.destroy.next();
        this.confirmationStartCylinderChargeSubject.next(true);
      });

      this.modalReference.onClose$.pipe(takeUntil(this.destroy)).subscribe((shouldCloseModal: boolean) => {
        this.closeConfirmModal.emit(shouldCloseModal);
        this.destroy.next();
        this.confirmationStartCylinderChargeSubject.next(true);
      });
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(): void {
    this.confirmationState$.pipe(takeUntil(this.destroy)).subscribe(() => {
      if (this.modalReference?.onClose$) {
        this.modalReference?.close('');
      }
    });
  }
}
