import { INotificationConstant } from 'src/app/common';

export class RemoteMonitoringNotificationConstants {
  public static readonly notificationCodes: INotificationConstant = {
    ACCESS_UNAVAILABLE_INCIDENT: {
      value: '11002',
      text: 'REMOTE_MONITORING_NOTIFICATION.STR_ACCESS_UNAVAILABLE_INCIDENT',
    },
    INCIDENT_CANT_BE_CLOSED: {
      value: '11012',
      text: 'REMOTE_MONITORING_NOTIFICATION.STR_INCIDENT_CANT_BE_CLOSED',
    },
    INCIDENT_OUTDATED_VERSION: {
      value: '11018',
      text: 'REMOTE_MONITORING_NOTIFICATION.STR_INCIDENT_OUTDATED_VERSION',
    },
  };
}
