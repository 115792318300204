// eslint-disable-next-line no-shadow
export enum ChecklistActionTypes {
  REQUEST_ADD_CHECKLIST = 'REQUEST_ADD_CHECKLIST',
  RECEIVE_ADD_CHECKLIST = 'RECEIVE_ADD_CHECKLIST',
  ERROR_RECEIVE_ADD_CHECKLIST = 'ERROR_RECEIVE_ADD_CHECKLIST',

  REQUEST_EDIT_CHECKLIST = 'REQUEST_EDIT_CHECKLIST',
  RECEIVE_EDIT_CHECKLIST = 'RECEIVE_EDIT_CHECKLIST',
  ERROR_RECEIVE_EDIT_CHECKLIST = 'ERROR_RECEIVE_EDIT_CHECKLIST',

  REQUEST_DELETE_CHECKLIST = 'REQUEST_DELETE_CHECKLIST',
  RECEIVE_DELETE_CHECKLIST = 'RECEIVE_DELETE_CHECKLIST',
  ERROR_RECEIVE_DELETE_CHECKLIST = 'ERROR_RECEIVE_DELETE_CHECKLIST',
}
