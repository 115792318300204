import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CheckingSettingsModalsService, GenericFooterItemsComponent, PropertyBag } from 'src/app/common';
import { ConfigurationModuleRoutes } from 'src/app/configuration/constants/configuration-module-routes.constants';

@Component({
  selector: 'ignis-create-update-configuration-view-footer',
  templateUrl: './create-update-configuration-view-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateUpdateConfigurationViewFooterComponent<T> extends GenericFooterItemsComponent<T> {
  @Input() disableAddBtn: boolean;
  @Input() isModalOpened: boolean;
  @Input() disableAddBtnTaskInterval: boolean;
  @Input() disableEditBtnAndDelete: boolean;
  @Input() toggleAddAndEditChecklistBtn: boolean = false;
  @Input() disableAddChecklistBtn: boolean = true;
  @Input() selectedIncludedTasks: any[] = [];
  @Input() disableEditChecklistBtn: boolean = true;
  @Input() isSubmitDisabled: boolean = false;

  @Output() handleAddOpenCheckList: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleEditOpenCheckList: EventEmitter<any> = new EventEmitter<any>();

  showAddButtonTooltip: boolean = false;
  showEditButtonTooltip: boolean = false;
  showDeleteButtonTooltip: boolean = false;
  showSaveButtonTooltip: boolean = false;
  showCheckListBtnTooltip: boolean = false;

  readonly ConfigurationModuleRoutes: PropertyBag = ConfigurationModuleRoutes;

  constructor(
    public router: Router,
    private checkSettingsModal: CheckingSettingsModalsService,
  ) {
    super();

    this.checkSettingsModal.checkIfSettingsModalIsOpen().subscribe((isModalOpened: boolean) => {
      this.isModalOpened = isModalOpened;
      this.cdr.detectChanges();
    });
  }

  onAddChecklistOpen(event: MouseEvent): void {
    event.stopPropagation();
    this.handleAddOpenCheckList.emit();
  }

  onEditChecklistOpen(event: MouseEvent): void {
    event.stopPropagation();
    this.handleEditOpenCheckList.emit();
  }
}
