import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap, timeout } from 'rxjs/operators';
import { CommonConstants, HttpHelper, UserStorageService } from 'src/app/common';
import { PropertyBag, TableFilters } from 'src/app/common/models/common.model';
import { IChecklists, ISaveBatchChecklist } from 'src/app/configuration/models/checklist.model';
import { ITask } from 'src/app/configuration/models/equipment-configuration.models';
import { environment } from 'src/environments/environment';
import { WorkshopConstants, WorkshopModuleRoutes } from '../constants';
import { IEquipmentHistoryEvent } from '../models/equipment-history.model';
import { IEquipmentInitialSpecification } from '../models/equipment-initial-specification.model';
import { IEquipmentLicenseInfo } from '../models/equipment-license-info.model';
import {
  ICreateUpdateEquipment,
  IEquipment,
  IEquipmentListPage,
  IRAMStatus,
  ITestParameters,
  ITestStart,
  ITestStartResponse,
  ITestStatus,
  IToken,
} from '../models/equipment.model';
import { ILocationChange, IServiceTaskPage } from '../models/service-task.model';
import {
  IEquipmentIdentifiers,
  ISaveTestResults,
  ITestResultDetails,
  ITestResultPage,
} from '../models/test-results.model';

@Injectable()
export class WorkshopService {
  private baseUrl: string = environment.API_URLS.EQUIPMENT;
  private baseUrlTaskDocumentation: string = environment.API_URLS.TASK_DOCUMENTATION;

  private scanTableDataSource: BehaviorSubject<IEquipment[]> = new BehaviorSubject<IEquipment[]>(null);
  workflowTableStorage: Observable<IEquipment[]> = this.scanTableDataSource.asObservable();

  get availableRAMConnectorPort(): number {
    return Number(localStorage.getItem(CommonConstants.availableRAMConnectorPort));
  }

  constructor(
    private httpClient: HttpClient,
    private httpHelper: HttpHelper,
    public userStorageService: UserStorageService,
  ) {}

  changeScanTableData(data: IEquipment[]): void {
    this.scanTableDataSource.next(data);
  }

  public getEquipmentPage(params?: PropertyBag): Observable<IEquipmentListPage | HttpErrorResponse> {
    return this.httpClient
      .get<Array<IEquipment>>(`${this.baseUrl}/equipment`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        params: { ...params },
      })
      .pipe(
        map(
          this.httpHelper.prepareHttpResponse as (
            value: IEquipment[],
            index: number,
          ) => IEquipmentListPage | HttpErrorResponse,
        ),
      );
  }

  public addEquipment(equipment: ICreateUpdateEquipment): Observable<HttpResponse<IEquipment> | HttpErrorResponse> {
    return this.httpClient
      .post<IEquipment>(`${this.baseUrl}/equipment`, equipment, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      })
      .pipe(
        tap((response: HttpResponse<IEquipment>) => {
          this.setInScanTableStorageNewEquipment(response);
        }),
      );
  }

  setInScanTableStorageNewEquipment(response: HttpResponse<IEquipment>): void {
    if (localStorage.getItem('previousRoute')?.includes(WorkshopModuleRoutes.scan)) {
      const eqLocation: string[] = response.headers?.get('Location').split('/');
      const eqAggregateId: string = eqLocation?.pop();

      const savedEquipmentsIds: string[] =
        (this.userStorageService.getStoredValue(WorkshopConstants.workflowTable) as string[]) || [];

      savedEquipmentsIds.push(eqAggregateId);
      this.userStorageService.setStoredValue(WorkshopConstants.workflowTable, savedEquipmentsIds);
    }
  }

  public getEquipmentById(id: string): Observable<HttpResponse<IEquipment> | HttpErrorResponse> {
    return this.httpClient
      .get<IEquipment>(`${this.baseUrl}/equipment/${id}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      })
      .pipe(
        tap((response: HttpResponse<IEquipment>) => {
          /* istanbul ignore next */
          if (response.body) {
            response.body.version = +response.headers?.get('etag');
          }
        }),
      );
  }

  public getSavedEquipments(ids: string[]): Observable<IEquipment[] | HttpErrorResponse> {
    const queryIds: string = ids.map((id: string) => `ids=${id}`).join('&');

    return this.httpClient.get<Array<IEquipment>>(
      `${this.baseUrl}/equipment/batch/search?${queryIds}`,
      this.httpHelper.options,
    );
  }

  public getSavedLocationEquipments(aggregateId: string): Observable<IEquipment[] | HttpErrorResponse> {
    return this.httpClient.get<Array<IEquipment>>(
      `${this.baseUrl}/equipment/batch/location/${aggregateId}`,
      this.httpHelper.options,
    );
  }

  public getInitialEquipmentSpecification(): Observable<IEquipmentInitialSpecification[] | HttpErrorResponse> {
    return this.httpClient.get<Array<IEquipmentInitialSpecification>>(
      `${this.baseUrl}/equipment-hierarchy/categories`,
      this.httpHelper.options,
    );
  }

  public deleteEquipment(equipmentPayload: { payload: IEquipment }): Observable<IEquipment | HttpErrorResponse> {
    const version: number = equipmentPayload.payload.version;

    return this.httpClient.delete<IEquipment>(
      `${this.baseUrl}/equipment/${equipmentPayload.payload.aggregateId}`,
      this.httpHelper.optionsWithIfMatch(version),
    );
  }

  public updateEquipment(equipment: ICreateUpdateEquipment): Observable<IEquipment | HttpErrorResponse> {
    const eq: ICreateUpdateEquipment = { ...equipment };
    const eqId: string = eq.aggregateId;
    const version: number = eq.version;

    delete eq.aggregateId;
    delete eq.version;

    return this.httpClient.put<IEquipment>(
      `${this.baseUrl}/equipment/${eqId}`,
      eq,
      this.httpHelper.optionsWithIfMatch(version),
    );
  }

  public testRunning(): Observable<ITestStatus | HttpErrorResponse> {
    return this.httpClient.get<ITestStatus>(
      `http://localhost:${this.availableRAMConnectorPort}/api/v1/test/status`,
      this.httpHelper.RAMConnectorOptions,
    );
  }

  public checkRAMConnectorStatus(port: number): Observable<HttpResponse<ITestStatus> | HttpErrorResponse> {
    return this.httpClient
      .get<ITestStatus>(`http://localhost:${port}/api/v1/test/check`, {
        ...this.httpHelper.RAMConnectorOptions,
        observe: 'response',
      })
      .pipe(
        tap((response: HttpResponse<ITestStatus>) => {
          /* istanbul ignore next */
          if (response.body) {
            response.body.isLatestVersion = response.headers?.get('IsLatest') === 'true';
            response.body.currentVersion = response.headers?.get('x-current-ram-connector-version');
            response.body.latestVersion = response.headers?.get('x-latest-ram-connector-version');
          }
        }),
      );
  }

  public checkRAMPortStatus(port: number): Observable<HttpResponse<IRAMStatus> | HttpErrorResponse> {
    return this.httpClient
      .get<IRAMStatus>(`http://localhost:${port}/api/v1/test/ramStatus`, {
        ...this.httpHelper.RAMConnectorOptions,
        observe: 'response',
      })
      .pipe(
        timeout(60000),
        tap((response: HttpResponse<IRAMStatus>) => {
          /* istanbul ignore next */
          if (response.status === 200) {
            const parsedUrl: URL = new URL(response.url);

            response.body.port = parsedUrl.port;
          }
        }),
      );
  }

  public getTestParameters(protectorTestType: string): Observable<ITestParameters | HttpErrorResponse> {
    return this.httpClient.get<ITestParameters>(
      `http://localhost:${this.availableRAMConnectorPort}/api/v1/test-data/${protectorTestType}`,
      this.httpHelper.RAMConnectorOptions,
    );
  }

  public startTestEquipment(startTest: ITestStart): Observable<ITestStartResponse | HttpErrorResponse> {
    return this.httpClient.post<ITestStartResponse>(
      `http://localhost:${this.availableRAMConnectorPort}/api/v1/test/start`,
      startTest,
      this.httpHelper.RAMConnectorOptions,
    );
  }

  public startAutoUpdate(): Observable<object | HttpErrorResponse> {
    return this.httpClient.post(
      `http://localhost:${this.availableRAMConnectorPort}/api/v1/auto-update`,
      {},
      this.httpHelper.RAMConnectorOptions,
    );
  }

  public getTestResults(identifier: string): Observable<HttpResponse<ITestResultDetails> | HttpErrorResponse> {
    return this.httpClient
      .get<ITestResultDetails>(`${this.baseUrlTaskDocumentation}/task-documentation/result-id/${identifier}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      })
      .pipe(
        tap((response: HttpResponse<ITestResultDetails>) => {
          /* istanbul ignore next */
          if (response.body) {
            response.body.version = +response.headers?.get('etag');
          }
        }),
      );
  }

  public saveTestResults(testResults: ISaveTestResults): Observable<void | HttpErrorResponse> {
    const testResultsToSave: ISaveTestResults = { ...testResults } as ISaveTestResults;
    const id: string = testResultsToSave.aggregateId;
    const version: number = testResultsToSave.version;

    delete testResultsToSave.aggregateId;
    delete testResultsToSave.version;

    return this.httpClient.put<void>(
      `${this.baseUrlTaskDocumentation}/task-documentation/${id}`,
      testResultsToSave,
      this.httpHelper.optionsWithIfMatch(version),
    );
  }

  public getTestResultsPage(params?: PropertyBag): Observable<ITestResultPage | HttpErrorResponse> {
    return this.httpClient.get<ITestResultPage>(`${this.baseUrlTaskDocumentation}/task-documentation`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: { ...params },
    });
  }

  public getTestResultsById(aggregateId: string): Observable<HttpResponse<ITestResultDetails> | HttpErrorResponse> {
    return this.httpClient
      .get<ITestResultDetails>(`${this.baseUrlTaskDocumentation}/task-documentation/${aggregateId}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      })
      .pipe(
        tap((response: HttpResponse<ITestResultDetails>) => {
          /* istanbul ignore next */
          if (response.body) {
            response.body.version = +response.headers?.get('etag');
          }
        }),
      );
  }

  public getWorkflowAsset(barcode: string): Observable<IEquipment | HttpErrorResponse> {
    let httpParams: HttpParams = new HttpParams();
    const idParam: Partial<PropertyBag> = { id: barcode };

    httpParams = httpParams.append('id', idParam['id']);

    return this.httpClient.get<IEquipment>(`${this.baseUrl}/equipment/search`, {
      ...this.httpHelper.options,
      params: httpParams,
    });
  }

  public getServiceTaskPage(params?: PropertyBag): Observable<IServiceTaskPage | HttpErrorResponse> {
    return this.httpClient
      .get<Array<IEquipment>>(`${this.baseUrl}/service-task`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        params: { ...params },
      })
      .pipe(
        map(
          this.httpHelper.prepareHttpResponse as (
            value: IEquipment[],
            index: number,
          ) => HttpErrorResponse | IServiceTaskPage,
        ),
      );
  }

  public sendServiceIntervals(serviceIntervals?: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.post<any>(
      `${this.baseUrlTaskDocumentation}/task-documentation/cylinder-charging`,
      serviceIntervals,
      this.httpHelper.options,
    );
  }

  public updateEquipmentsLocation(locationChange?: ILocationChange): Observable<any | HttpErrorResponse> {
    return this.httpClient.put<Array<any>>(
      `${this.baseUrl}/equipment/location-change`,
      locationChange,
      this.httpHelper.options,
    );
  }

  public getEquipmentHistory(aggregateId: string): Observable<IEquipmentHistoryEvent[] | HttpErrorResponse> {
    const filters: string = localStorage.getItem('timelineFilters');

    return this.httpClient
      .get<
        IEquipmentHistoryEvent[]
      >(`${this.baseUrl}/equipment/${aggregateId}/history${this.URLParams(filters)}`, this.httpHelper.options)
      .pipe(
        map(
          this.httpHelper.prepareHttpResponse as (
            value: IEquipmentHistoryEvent[],
            index: number,
          ) => IEquipmentHistoryEvent[] | HttpErrorResponse,
        ),
      );
  }

  public uploadCSVFile(file: any): Observable<any | HttpErrorResponse> {
    return this.httpClient.post<any>(`${this.baseUrl}/equipment/import`, file.payload);
  }

  URLParams(filters: string): string {
    if (!filters) {
      return '';
    }

    const parsedFilters: TableFilters = JSON.parse(filters) as TableFilters;

    Object.keys(parsedFilters).forEach((key: string) => {
      if (parsedFilters[key] === false) {
        delete parsedFilters[key];
      }
    });

    return `?${new URLSearchParams(parsedFilters).toString()}`;
  }

  public getRAMConnectorToken(): Observable<IToken | HttpErrorResponse> {
    return this.httpClient.get<IToken>(
      `http://localhost:${this.availableRAMConnectorPort}/api/v1/settings/token`,
      this.httpHelper.RAMConnectorOptions,
    );
  }

  public getChecklistsData(ids: string[]): Observable<IChecklists | HttpErrorResponse> {
    const queryIds: string = ids.map((id: string) => `ids=${id}`).join('&');

    return this.httpClient.get<IChecklists>(
      `${this.baseUrl}/equipment/batch/checklists?${queryIds}`,
      this.httpHelper.options,
    );
  }

  public saveServicesChecklist(serviceDefinition: ITask): Observable<ITask | HttpErrorResponse> {
    return this.httpClient.post<ITask>(
      `${this.baseUrlTaskDocumentation}/task-documentation/checklist`,
      serviceDefinition,
      this.httpHelper.options,
    );
  }

  public saveBatchChecklist(
    batchChecklistData: ISaveBatchChecklist,
  ): Observable<ISaveBatchChecklist | HttpErrorResponse> {
    return this.httpClient.post<ISaveBatchChecklist>(
      `${this.baseUrlTaskDocumentation}/task-documentation/batch/checklist`,
      batchChecklistData,
      this.httpHelper.options,
    );
  }

  public getEquipmentLicenseInfo(): Observable<IEquipmentLicenseInfo | HttpErrorResponse> {
    return this.httpClient.get<IEquipmentLicenseInfo>(
      `${this.baseUrl}/equipment/license-info`,
      this.httpHelper.options,
    );
  }

  public updateEquipmentIds(
    equipmentId: string,
    fields: IEquipmentIdentifiers,
    version: number,
  ): Observable<IEquipment | HttpErrorResponse> {
    return this.httpClient.put<IEquipment>(
      `${this.baseUrl}/equipment/${equipmentId}/equipmentIds`,
      {
        barcode: fields.barcode?.length < 1 ? null : fields.barcode,
        rfid: fields.rfid?.length < 1 ? null : fields.rfid,
      },
      this.httpHelper.optionsWithIfMatch(version),
    );
  }

  public checkEquipmentIdentifiersUniqueness(
    eqIdentifiers: IEquipmentIdentifiers,
  ): Observable<IEquipmentIdentifiers | HttpErrorResponse> {
    return this.httpClient.post<IEquipmentIdentifiers>(
      `${this.baseUrl}/equipment/equipmentIds`,
      eqIdentifiers,
      this.httpHelper.options,
    );
  }
}
