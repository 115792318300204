import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { IStoreApiItem } from 'src/app/common';
import { IStoreApiList } from './../../../common/models/store-api-list.model';
import { ApplicationState, IApplicationState } from './../../../common/state/models/app.state.model';
import { IUser, IUserPage, IUserPersonalInfo, IUserRole } from './../../models/user.model';
import { IUserState } from './../models/user.model';

export const userState = (state: IApplicationState) => state.user;

export const selectUsersPage: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IUserPage>,
  DefaultProjectorFn<IStoreApiItem<IUserPage>>
> = createSelector(userState, (state: IUserState) => state.usersPage);

export const selectedUser: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<any>,
  DefaultProjectorFn<IStoreApiItem<any>>
> = createSelector(userState, (state: IUserState) => state.user);

export const selectUsersPersonalInfo: MemoizedSelector<
  ApplicationState,
  IStoreApiList<IUserPersonalInfo[]>,
  DefaultProjectorFn<IStoreApiList<IUserPersonalInfo[]>>
> = createSelector(userState, (state: IUserState) => state.usersPersonalInfo);

export const selectRoleList: MemoizedSelector<
  ApplicationState,
  IStoreApiList<IUserRole[]>,
  DefaultProjectorFn<IStoreApiList<IUserRole[]>>
> = createSelector(userState, (state: IUserState) => state.roleList);

export const selectDeletedUser: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<IUser>,
  DefaultProjectorFn<IStoreApiItem<IUser>>
> = createSelector(userState, (state: IUserState) => state.user);

export const selectCSVFile: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<any>,
  DefaultProjectorFn<IStoreApiItem<any>>
> = createSelector(userState, (state: IUserState) => state.csv);
