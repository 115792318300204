import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IEditTask, INewTask, IUpdateTaskChildren } from 'src/app/configuration/models';
import { IConfigurationState } from '../../models/configuration.models';
import {
  RequestAddTask,
  RequestDeleteTask,
  RequestEditTask,
  RequestUpdateTaskChildren,
} from './tasks-action-types-creators';

@Injectable({ providedIn: 'root' })
export class TasksActions {
  constructor(public store: Store<IConfigurationState>) {}

  public requestAddTask(params: INewTask): void {
    this.store.dispatch(new RequestAddTask(params));
  }

  public requestUpdateTaskChildren(params: IUpdateTaskChildren): void {
    this.store.dispatch(new RequestUpdateTaskChildren(params));
  }

  public requestEditTask(params: IEditTask): void {
    this.store.dispatch(new RequestEditTask(params));
  }

  public requestDeleteTask(params: { urlPath: string; version: number }): void {
    this.store.dispatch(new RequestDeleteTask(params));
  }
}
