import { IStoreApiItem, StoreApiItem } from 'src/app/common/models/store-api-item.model';

export interface IProduct {
  productName: string;
}

export interface IProductState {
  productName: IStoreApiItem<IProduct>;
}

export class ProductState implements IProductState {
  productName: IStoreApiItem<IProduct> = new StoreApiItem();
}
