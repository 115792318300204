import { GenericAction } from 'src/app/common';
import {
  errorItemData,
  errorListData,
  errorPostPutItemData,
  receiveItemData,
  receiveListData,
  receivePostPutItemData,
  requestItemData,
  requestListData,
  requestPostPutItemData,
} from 'src/app/common/utils/reducer/reducer.utils';
import {
  IEditLocationHierarchy,
  ILocationHierarchy,
  ILocationHierarchyChange,
} from '../../../models/location-configuration.model';
import { LocationConfigActionTypes } from '../../actions/location-config';
import { LocationHierarchyActionTypes } from '../../actions/location-hierarchy';
import {
  ConfigurationLocationHierarchyState,
  IConfigurationLocationHierarchyState,
} from '../../models/location-hierarchy.model';

const resetLocationConfiguration = (
  lastState: IConfigurationLocationHierarchyState,
): IConfigurationLocationHierarchyState => ({
  ...lastState,
  locationConfiguration: {
    isLoading: false,
    data: null,
    errors: null,
    isSuccess: false,
  },
});

const resetLocationHierarchyDelete = (
  lastState: IConfigurationLocationHierarchyState,
): IConfigurationLocationHierarchyState => ({
  ...lastState,
  deletedHierarchy: {
    isLoading: false,
    data: null,
    errors: null,
    isSuccess: false,
  },
});

const resetScanLocation = (lastState: IConfigurationLocationHierarchyState): IConfigurationLocationHierarchyState => ({
  ...lastState,
  searchedLocation: {
    isLoading: false,
    data: null,
    errors: null,
    isSuccess: false,
  },
});

export function configurationLocationHierarchyReducer(
  lastState: IConfigurationLocationHierarchyState = new ConfigurationLocationHierarchyState(),
  action: GenericAction<LocationHierarchyActionTypes | LocationConfigActionTypes, any>,
): IConfigurationLocationHierarchyState {
  switch (action.type) {
    case LocationHierarchyActionTypes.REQUEST_LOCATION_HIERARCHY:
      return requestListData<IConfigurationLocationHierarchyState, ILocationHierarchy[]>(lastState, ['hierarchy']);
    case LocationHierarchyActionTypes.RECEIVE_LOCATION_HIERARCHY:
      return receiveListData<IConfigurationLocationHierarchyState, ILocationHierarchy[]>(
        lastState,
        ['hierarchy'],
        action.payload,
      );
    case LocationHierarchyActionTypes.ERROR_RECEIVE_LOCATION_HIERARCHY:
      return errorListData<IConfigurationLocationHierarchyState, ILocationHierarchy[]>(
        lastState,
        ['hierarchy'],
        action.payload,
      );

    case LocationHierarchyActionTypes.REQUEST_ADD_LOCATION_HIERARCHY:
      return requestPostPutItemData<IConfigurationLocationHierarchyState, ILocationHierarchy>(
        lastState,
        ['newHierarchy'],
        action.payload,
      );
    case LocationHierarchyActionTypes.RECEIVE_ADD_LOCATION_HIERARCHY:
      return receivePostPutItemData<IConfigurationLocationHierarchyState, ILocationHierarchy>(
        lastState,
        ['newHierarchy'],
        action.payload,
      );
    case LocationHierarchyActionTypes.ERROR_RECEIVE_ADD_LOCATION_HIERARCHY:
      return errorPostPutItemData<IConfigurationLocationHierarchyState, ILocationHierarchy>(
        lastState,
        ['newHierarchy'],
        action.payload,
      );

    case LocationHierarchyActionTypes.REQUEST_CHANGE_LOCATION_HIERARCHY:
      return requestPostPutItemData<IConfigurationLocationHierarchyState, ILocationHierarchyChange>(
        lastState,
        ['changeHierarchy'],
        action.payload,
      );
    case LocationHierarchyActionTypes.RECEIVE_CHANGE_LOCATION_HIERARCHY:
      return receivePostPutItemData<IConfigurationLocationHierarchyState, ILocationHierarchyChange>(
        lastState,
        ['changeHierarchy'],
        action.payload,
      );
    case LocationHierarchyActionTypes.ERROR_RECEIVE_CHANGE_LOCATION_HIERARCHY:
      return errorPostPutItemData<IConfigurationLocationHierarchyState, ILocationHierarchyChange>(
        lastState,
        ['changeHierarchy'],
        action.payload,
      );

    case LocationConfigActionTypes.REQUEST_LOCATION_CONFIGURATION:
      return requestItemData<IConfigurationLocationHierarchyState, IEditLocationHierarchy>(lastState, [
        'locationConfiguration',
      ]);
    case LocationConfigActionTypes.RECEIVE_LOCATION_CONFIGURATION:
      return receiveItemData<IConfigurationLocationHierarchyState, IEditLocationHierarchy>(
        lastState,
        ['locationConfiguration'],
        action.payload,
      );
    case LocationConfigActionTypes.ERROR_RECEIVE_LOCATION_CONFIGURATION:
      return errorItemData<IConfigurationLocationHierarchyState, IEditLocationHierarchy>(
        lastState,
        ['locationConfiguration'],
        action.payload,
      );
    case LocationConfigActionTypes.RESET_LOCATION_CONFIGURATION:
      return resetLocationConfiguration(lastState);

    case LocationHierarchyActionTypes.REQUEST_EDIT_LOCATION_HIERARCHY:
      return requestPostPutItemData<IConfigurationLocationHierarchyState, IEditLocationHierarchy>(
        lastState,
        ['editedHierarchy'],
        action.payload,
      );
    case LocationHierarchyActionTypes.RECEIVE_EDIT_LOCATION_HIERARCHY:
      return receivePostPutItemData<IConfigurationLocationHierarchyState, IEditLocationHierarchy>(
        lastState,
        ['editedHierarchy'],
        action.payload,
      );
    case LocationHierarchyActionTypes.ERROR_RECEIVE_EDIT_LOCATION_HIERARCHY:
      return errorPostPutItemData<IConfigurationLocationHierarchyState, IEditLocationHierarchy>(
        lastState,
        ['editedHierarchy'],
        action.payload,
      );

    case LocationHierarchyActionTypes.REQUEST_DELETE_LOCATION_HIERARCHY:
      return requestPostPutItemData<IConfigurationLocationHierarchyState, any>(
        lastState,
        ['deletedHierarchy'],
        action.payload,
      );
    case LocationHierarchyActionTypes.RECEIVE_DELETE_LOCATION_HIERARCHY:
      return receivePostPutItemData<IConfigurationLocationHierarchyState, any>(
        lastState,
        ['deletedHierarchy'],
        action.payload,
      );
    case LocationHierarchyActionTypes.ERROR_RECEIVE_DELETE_LOCATION_HIERARCHY:
      return errorPostPutItemData<IConfigurationLocationHierarchyState, any>(
        lastState,
        ['deletedHierarchy'],
        action.payload,
      );
    case LocationHierarchyActionTypes.RESET_DELETE_LOCATION_HIERARCHY:
      return resetLocationHierarchyDelete(lastState);

    case LocationHierarchyActionTypes.REQUEST_SEARCH_LOCATION:
      return requestPostPutItemData<IConfigurationLocationHierarchyState, any>(
        lastState,
        ['searchedLocation'],
        action.payload,
      );
    case LocationHierarchyActionTypes.RECEIVE_SEARCH_LOCATION:
      return receivePostPutItemData<IConfigurationLocationHierarchyState, any>(
        lastState,
        ['searchedLocation'],
        action.payload,
      );
    case LocationHierarchyActionTypes.ERROR_RECEIVE_SEARCH_LOCATION:
      return errorPostPutItemData<IConfigurationLocationHierarchyState, any>(
        lastState,
        ['searchedLocation'],
        action.payload,
      );
    case LocationHierarchyActionTypes.RESET_SEARCH_LOCATION:
      return resetScanLocation(lastState);

    default:
      return lastState;
  }
}
