import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  ErrorReceiveTableExport,
  ReceiveTableExport,
} from 'src/app/common/state/export/actions/export-action-types-creators';
import { IFireStationList } from '../../../common';
import {
  DeviceConnection,
  DeviceConnectionToken,
  DevicePage,
  IDeviceConnection,
  IDeviceConnectionToken,
  IDevicePage,
  IMigrationID,
} from '../../models/device-connection.model';
import {
  IProtectorMigrationErrorPage,
  IProtectorMigrationStatus,
  ProtectorMigrationErrorPage,
} from '../../models/protector-migration.model';
import { DeviceConnectionService } from '../../services/device-connection.service';
import { DeviceConnectionActionTypes } from '../actions/device-connection-action-types';
import {
  ErrorAddDeviceConnection,
  ErrorReceiveCancelProtectorMigration,
  ErrorReceiveCancelProtectorMigrationInRAM,
  ErrorReceiveDeviceConnectionAssignedFireStations,
  ErrorReceiveDeviceConnectionById,
  ErrorReceiveDeviceConnectionDelete,
  ErrorReceiveDeviceConnectionIdentifier,
  ErrorReceiveDeviceConnectionPage,
  ErrorReceiveProtectorMigrationErrorPage,
  ErrorReceiveProtectorMigrationStatus,
  ErrorReceiveStartProtectorMigration,
  ErrorReceiveUpdateDeviceConnectionAuthToken,
  ErrorUpdateDeviceConnection,
  ReceiveAddDeviceConnection,
  ReceiveCancelProtectorMigration,
  ReceiveCancelProtectorMigrationInRAM,
  ReceiveDeviceConnectionAssignedFireStations,
  ReceiveDeviceConnectionById,
  ReceiveDeviceConnectionDelete,
  ReceiveDeviceConnectionIdentifier,
  ReceiveDeviceConnectionPage,
  ReceiveProtectorMigrationErrorPage,
  ReceiveProtectorMigrationStatus,
  ReceiveStartProtectorMigration,
  ReceiveUpdateDeviceConnection,
  ReceiveUpdateDeviceConnectionAuthToken,
} from '../actions/device-connection-action-types-creators';
import { DeviceConnectionLicenseInfo, IDeviceConnectionLicenseInfo } from './../../models/device-connection.model';
import {
  ErrorReceiveDeviceConnectionCategories,
  ErrorReceiveDeviceConnectionLicenseInfo,
  ErrorReceiveToCheckRAMConnectorToken,
  ReceiveDeviceConnectionCategories,
  ReceiveDeviceConnectionLicenseInfo,
  ReceiveToCheckRAMConnectorToken,
} from './../actions/device-connection-action-types-creators';

@Injectable()
export class DeviceConnectionEffects {
  constructor(private deviceConnectionsService: DeviceConnectionService) {}

  requestDeviceConnectionPage$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_PAGE),
      switchMap((params: any) =>
        this.deviceConnectionsService.getDeviceConnectionPage(params.payload).pipe(
          map((devicePage: IDevicePage) => new ReceiveDeviceConnectionPage(new DevicePage(devicePage))),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveDeviceConnectionPage(error))),
        ),
      ),
    );
  });

  requestDeviceConnectionExport$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_EXPORT),
      switchMap((params: any) =>
        this.deviceConnectionsService.getDeviceConnectionPage(params.payload).pipe(
          map((devicePage: IDevicePage) => new ReceiveTableExport(new DevicePage(devicePage))),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveTableExport(error))),
        ),
      ),
    );
  });

  requestDeviceConnectionById$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_BY_ID),
      switchMap((locationId: any) =>
        this.deviceConnectionsService.getDeviceConnectionById(locationId.payload).pipe(
          map((st: IDeviceConnection | any) => new ReceiveDeviceConnectionById(new DeviceConnection(st?.body))),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveDeviceConnectionById(error))),
        ),
      ),
    );
  });

  addDeviceConnection$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(DeviceConnectionActionTypes.REQUEST_ADD_DEVICE_CONNECTION),
      switchMap((deviceConnection: any) =>
        this.deviceConnectionsService.addDeviceConnection(deviceConnection.payload).pipe(
          map((st: IDeviceConnection | any) => new ReceiveAddDeviceConnection(st)),
          catchError((error: HttpErrorResponse) => of(new ErrorAddDeviceConnection(error))),
        ),
      ),
    );
  });

  updateDeviceConnection$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(DeviceConnectionActionTypes.REQUEST_UPDATE_DEVICE_CONNECTION),
      switchMap((deviceConnection: any) =>
        this.deviceConnectionsService.updateDeviceConnection(deviceConnection.payload).pipe(
          map((st: IDeviceConnection) => new ReceiveUpdateDeviceConnection(st)),
          catchError((error: HttpErrorResponse) => of(new ErrorUpdateDeviceConnection(error))),
        ),
      ),
    );
  });

  requestDeviceConnectionIdentifier$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_IDENTIFIER),
      switchMap(() =>
        this.deviceConnectionsService.getDeviceConnectionIdentifier().pipe(
          map(
            (identifier: IDeviceConnectionToken) =>
              new ReceiveDeviceConnectionIdentifier(new DeviceConnectionToken(identifier)),
          ),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveDeviceConnectionIdentifier(error))),
        ),
      ),
    );
  });

  requestDeviceConnectionDelete$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_DELETE),
      switchMap((deviceConnection: IDeviceConnection) =>
        this.deviceConnectionsService.deleteDeviceConnection(deviceConnection).pipe(
          map(
            (deletedDeviceConnection: IDeviceConnection) => new ReceiveDeviceConnectionDelete(deletedDeviceConnection),
          ),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveDeviceConnectionDelete(error))),
        ),
      ),
    );
  });

  requestDeviceConnectionUpdateAuthToken$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(DeviceConnectionActionTypes.REQUEST_UPDATE_DEVICE_CONNECTION_AUTH_TOKEN),
      switchMap((payload: any) =>
        this.deviceConnectionsService.updateDeviceConnectionAuthToken(payload.aggregateId, payload.version).pipe(
          map((token: any) => new ReceiveUpdateDeviceConnectionAuthToken(token)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveUpdateDeviceConnectionAuthToken(error))),
        ),
      ),
    );
  });

  requestDeviceConnectionCategories$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_CATEGORIES),
      switchMap(() =>
        this.deviceConnectionsService.getDeviceConnectionCategories().pipe(
          map((categories: any) => new ReceiveDeviceConnectionCategories(categories)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveDeviceConnectionCategories(error))),
        ),
      ),
    );
  });

  requestToCheckRAMConnectorToken$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(DeviceConnectionActionTypes.REQUEST_TO_CHECK_RAM_CONNECTOR_TOKEN),
      switchMap((token: any) =>
        this.deviceConnectionsService.checkRAMConnectorToken(token.payload).pipe(
          map((tokenExist: any) => new ReceiveToCheckRAMConnectorToken({ tokenExists: true })),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveToCheckRAMConnectorToken(error))),
        ),
      ),
    );
  });

  requestDeviceConnectionLicenseInfo$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_LICENSE_INFO),
      switchMap(() =>
        this.deviceConnectionsService.getDeviceConnectionLicenseInfo().pipe(
          map(
            (licenseInfo: IDeviceConnectionLicenseInfo) =>
              new ReceiveDeviceConnectionLicenseInfo(new DeviceConnectionLicenseInfo(licenseInfo)),
          ),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveDeviceConnectionLicenseInfo(error))),
        ),
      ),
    );
  });

  requestDeviceConnectionAssignedFireStations$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(DeviceConnectionActionTypes.REQUEST_DEVICE_CONNECTION_ASSIGNED_FIRE_STATIONS),
      switchMap(() =>
        this.deviceConnectionsService.getDeviceConnectionAssignedFireStations().pipe(
          map((fireStations: IFireStationList) => new ReceiveDeviceConnectionAssignedFireStations(fireStations)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveDeviceConnectionAssignedFireStations(error))),
        ),
      ),
    );
  });

  requestStartProtectorMigration$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(DeviceConnectionActionTypes.REQUEST_START_PROTECTOR_MIGRATION),
      switchMap(() =>
        this.deviceConnectionsService.startProtectorMigration().pipe(
          map((migrationInfo: IMigrationID) => new ReceiveStartProtectorMigration(migrationInfo)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveStartProtectorMigration(error))),
        ),
      ),
    );
  });

  requestCancelProtectorMigrationInRAM$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(DeviceConnectionActionTypes.REQUEST_CANCEL_PROTECTOR_MIGRATION_IN_RAM),
      switchMap(() =>
        this.deviceConnectionsService.cancelProtectorMigrationInRAM().pipe(
          map(() => new ReceiveCancelProtectorMigrationInRAM()),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveCancelProtectorMigrationInRAM(error))),
        ),
      ),
    );
  });

  requestCancelProtectorMigration$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(DeviceConnectionActionTypes.REQUEST_CANCEL_PROTECTOR_MIGRATION),
      switchMap((migrationId: { payload: string; type: string }) =>
        this.deviceConnectionsService.cancelProtectorMigration(migrationId).pipe(
          map(() => new ReceiveCancelProtectorMigration()),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveCancelProtectorMigration(error))),
        ),
      ),
    );
  });

  requestProtectorMigrationStatus$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(DeviceConnectionActionTypes.REQUEST_PROTECTOR_MIGRATION_STATUS),
      switchMap((migrationId: { payload: string; type: string }) =>
        this.deviceConnectionsService.getProtectorSoftwareMigrationStatus(migrationId).pipe(
          map((status: IProtectorMigrationStatus) => new ReceiveProtectorMigrationStatus(status)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveProtectorMigrationStatus(error))),
        ),
      ),
    );
  });

  requestProtectorMigrationErrorPage$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(DeviceConnectionActionTypes.REQUEST_PROTECTOR_MIGRATION_ERROR_PAGE),
      switchMap((payload: { migrationId: string; type: string; payload: any }) =>
        this.deviceConnectionsService.geProtectorSoftwareMigrationErrorPage(payload).pipe(
          map(
            (errorPage: IProtectorMigrationErrorPage) =>
              new ReceiveProtectorMigrationErrorPage(new ProtectorMigrationErrorPage(errorPage)),
          ),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveProtectorMigrationErrorPage(error))),
        ),
      ),
    );
  });
}
