import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import {
  EditLocationHierarchy,
  IEditLocationHierarchy,
  ILocationHierarchy,
  ILocationHierarchyChange,
  LocationHierarchy,
} from 'src/app/configuration/models';
import { LocationConfigurationService } from 'src/app/configuration/services/location-configuration.service';
import {
  ErrorReceiveAddLocationHierarchy,
  ErrorReceiveDeleteLocationHierarchy,
  ErrorReceiveEditLocationHierarchy,
  ErrorReceiveLocationHierarchy,
  ErrorReceiveLocationHierarchyChange,
  ErrorReceiveSearchLocation,
  LocationHierarchyActionTypes,
  ReceiveAddLocationHierarchy,
  ReceiveDeleteLocationHierarchy,
  ReceiveEditLocationHierarchy,
  ReceiveLocationHierarchy,
  ReceiveLocationHierarchyChange,
  ReceiveSearchLocation,
} from '../../actions';

@Injectable()
export class LocationHierarchyEffects {
  locationConfigurationService: LocationConfigurationService = inject(LocationConfigurationService);

  requestLocationHierarchy$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(LocationHierarchyActionTypes.REQUEST_LOCATION_HIERARCHY),
      switchMap(() =>
        this.locationConfigurationService.getLocationHierarchy().pipe(
          map(
            (hierarchy: ILocationHierarchy[] | any) =>
              new ReceiveLocationHierarchy(
                hierarchy.data.map((data: ILocationHierarchy) => new LocationHierarchy(data)),
              ),
          ),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveLocationHierarchy(error))),
        ),
      ),
    );
  });

  requestAddLocationHierarchy$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(LocationHierarchyActionTypes.REQUEST_ADD_LOCATION_HIERARCHY),
      switchMap((params: any) =>
        this.locationConfigurationService.addLocationHierarchy(params.payload).pipe(
          map((newHierarchy: ILocationHierarchy) => new ReceiveAddLocationHierarchy(newHierarchy)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveAddLocationHierarchy(error))),
        ),
      ),
    );
  });

  requestLocationHierarchyChange$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(LocationHierarchyActionTypes.REQUEST_CHANGE_LOCATION_HIERARCHY),
      switchMap((params: { payload: ILocationHierarchyChange }) =>
        this.locationConfigurationService.changeLocationHierarchy(params.payload).pipe(
          map((changeHierarchy: ILocationHierarchyChange) => new ReceiveLocationHierarchyChange(changeHierarchy)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveLocationHierarchyChange(error))),
        ),
      ),
    );
  });

  requestEditLocationHierarchy$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(LocationHierarchyActionTypes.REQUEST_EDIT_LOCATION_HIERARCHY),
      switchMap((params: IEditLocationHierarchy | any) =>
        this.locationConfigurationService.editLocationHierarchy(params.payload).pipe(
          map(
            (editedHierarchy: IEditLocationHierarchy) =>
              new ReceiveEditLocationHierarchy(new EditLocationHierarchy(editedHierarchy)),
          ),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveEditLocationHierarchy(error))),
        ),
      ),
    );
  });

  requestDeleteLocationHierarchy$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(LocationHierarchyActionTypes.REQUEST_DELETE_LOCATION_HIERARCHY),
      switchMap((params: any) =>
        this.locationConfigurationService.deleteLocationHierarchy(params).pipe(
          map((deleteHierarchy: any) => new ReceiveDeleteLocationHierarchy(deleteHierarchy)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveDeleteLocationHierarchy(error))),
        ),
      ),
    );
  });

  requestSearchedLocation$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(LocationHierarchyActionTypes.REQUEST_SEARCH_LOCATION),
      switchMap((id: { payload: string }) =>
        this.locationConfigurationService.getSearchLocation(id.payload).pipe(
          map((locations: any) => new ReceiveSearchLocation(locations.body)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveSearchLocation(error))),
        ),
      ),
    );
  });
}
