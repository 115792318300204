import { combineReducers } from 'redux';
import { equipmentCRUDReducer } from './equipment-crud/equipment-crud.reducer';
import { historyReducer } from './equipment-history/equipment-history.reducer';
import { equipmentInitialSpecReducer } from './equipment-initial-specs/equipment-initial-spec.reducer';
import { equipmentLicenseInfoReducer } from './equipment-license-info/equipment-license-info.reducer';
import { serviceTaskReducer } from './equipment-service-task/equipment-service-task.reducer';
import { testingReducer } from './equipment-test/equipment-testing.reducer';
import { RAMConnectorReducer } from './ram-connector/ram-connector.reducer';
import { updateEquipmentIdsReducer } from './update-equipment-ids/update-equipment-ids.reducer';

export const equipmentReducer = combineReducers<{
  equipmentData: typeof equipmentCRUDReducer;
  equipmentHistory: typeof historyReducer;
  equipmentInitialSpec: typeof equipmentInitialSpecReducer;
  testing: typeof testingReducer;
  serviceTask: typeof serviceTaskReducer;
  licenseInfo: typeof equipmentLicenseInfoReducer;
  updateEquipmentFields: typeof updateEquipmentIdsReducer;
  ramConnector: typeof RAMConnectorReducer;
}>({
  equipmentData: equipmentCRUDReducer,
  equipmentHistory: historyReducer,
  equipmentInitialSpec: equipmentInitialSpecReducer,
  testing: testingReducer,
  serviceTask: serviceTaskReducer,
  licenseInfo: equipmentLicenseInfoReducer,
  updateEquipmentFields: updateEquipmentIdsReducer,
  ramConnector: RAMConnectorReducer,
});
