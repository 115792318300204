import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import { EditLocationHierarchy, IEditLocationHierarchy } from 'src/app/configuration/models';
import { LocationConfigurationService } from 'src/app/configuration/services/location-configuration.service';
import {
  ErrorReceiveLocationConfiguration,
  LocationConfigActionTypes,
  ReceiveLocationConfiguration,
} from '../../actions';

@Injectable()
export class LocationConfigEffects {
  constructor(private locationConfigurationService: LocationConfigurationService) {}

  requestLocationConfiguration$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(LocationConfigActionTypes.REQUEST_LOCATION_CONFIGURATION),
      switchMap((params: any) =>
        this.locationConfigurationService.getLocationConfiguration(params.payload).pipe(
          map(
            (locConf: IEditLocationHierarchy | any) =>
              new ReceiveLocationConfiguration(new EditLocationHierarchy(locConf.body)),
          ),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveLocationConfiguration(error))),
        ),
      ),
    );
  });
}
