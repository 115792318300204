import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ignis-sort-icon',
  templateUrl: './sort-icon.component.html',
  styleUrl: './sort-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortIconComponent {
  @Input() columnName: string;
  @Input() columnSorted: any;
}
