import { GenericAction, reducerUtils } from 'src/app/common';
import { IChecklists, ISaveBatchChecklist } from 'src/app/configuration/models/checklist.model';
import { ITask } from 'src/app/configuration/models/equipment-configuration.models';
import { EquipmentServiceTaskActionTypes } from '../../actions/equipment-service-task';
import { IServiceTaskState, ServiceTaskState } from '../../models/service-task.model';

import {
  ICylinderChargerRequest,
  ILocationChange,
  IServiceTask,
  IServiceTaskPage,
} from '../../../models/service-task.model';

const resetUpdateEquipmentLocation = (lastState: IServiceTaskState): IServiceTaskState => ({
  ...lastState,
  updateEquipmentsLocation: {
    data: null,
    isLoading: false,
    isSuccess: false,
    errors: null,
  },
});

const resetSendServiceIntervals = (lastState: IServiceTaskState): IServiceTaskState => ({
  ...lastState,
  sendServiceIntervals: {
    data: null,
    isLoading: false,
    isSuccess: false,
    errors: null,
  },
});

const resetSaveServicesChecklistState = (lastState: IServiceTaskState): IServiceTaskState => ({
  ...lastState,
  sendServicesChecklist: {
    data: null,
    isLoading: false,
    isSuccess: false,
    errors: null,
  },
});

const resetChecklistsDataState = (lastState: IServiceTaskState): IServiceTaskState => ({
  ...lastState,
  checklists: {
    data: null,
    isLoading: false,
    isSuccess: false,
    errors: null,
  },
});

const resetSaveBatchChecklistState = (lastState: IServiceTaskState): IServiceTaskState => ({
  ...lastState,
  saveBatchChecklist: {
    data: null,
    isLoading: false,
    isSuccess: false,
    errors: null,
  },
});

export function serviceTaskReducer(
  lastState: IServiceTaskState = new ServiceTaskState(),
  action: GenericAction<EquipmentServiceTaskActionTypes, any>,
): IServiceTaskState {
  switch (action.type) {
    case EquipmentServiceTaskActionTypes.REQUEST_SERVICE_TASK_PAGE:
      return reducerUtils.requestListData<IServiceTaskState, IServiceTaskPage>(lastState, ['serviceTaskPage']);
    case EquipmentServiceTaskActionTypes.RECEIVE_SERVICE_TASK_PAGE:
      return reducerUtils.receiveListData<IServiceTaskState, IServiceTaskPage>(
        lastState,
        ['serviceTaskPage'],
        action.payload,
      );
    case EquipmentServiceTaskActionTypes.ERROR_RECEIVE_SERVICE_TASK_PAGE:
      return reducerUtils.errorItemData<IServiceTaskState, IServiceTaskPage>(
        lastState,
        ['serviceTaskPage'],
        action.payload,
      );

    case EquipmentServiceTaskActionTypes.REQUEST_SEND_SERVICE_INTERVALS:
      return reducerUtils.requestPostPutItemData<IServiceTaskState, ICylinderChargerRequest>(
        lastState,
        ['sendServiceIntervals'],
        action.payload,
      );
    case EquipmentServiceTaskActionTypes.RECEIVE_SEND_SERVICE_INTERVALS:
      return reducerUtils.receivePostPutItemData<IServiceTaskState, ICylinderChargerRequest>(
        lastState,
        ['sendServiceIntervals'],
        action.payload,
      );
    case EquipmentServiceTaskActionTypes.ERROR_RECEIVE_SEND_SERVICE_INTERVALS:
      return reducerUtils.errorPostPutItemData<IServiceTaskState, ICylinderChargerRequest>(
        lastState,
        ['sendServiceIntervals'],
        action.payload,
      );
    case EquipmentServiceTaskActionTypes.RESET_SEND_SERVICE_INTERVALS:
      return resetSendServiceIntervals(lastState);

    case EquipmentServiceTaskActionTypes.REQUEST_UPDATE_EQUIPMENT_LOCATION:
      return reducerUtils.requestPostPutItemData<IServiceTaskState, ILocationChange>(
        lastState,
        ['updateEquipmentsLocation'],
        action.payload,
      );
    case EquipmentServiceTaskActionTypes.RECEIVE_UPDATE_EQUIPMENT_LOCATION:
      return reducerUtils.receivePostPutItemData<IServiceTaskState, ILocationChange>(
        lastState,
        ['updateEquipmentsLocation'],
        action.payload,
      );
    case EquipmentServiceTaskActionTypes.ERROR_RECEIVE_UPDATE_EQUIPMENT_LOCATION:
      return reducerUtils.errorPostPutItemData<IServiceTaskState, ILocationChange>(
        lastState,
        ['updateEquipmentsLocation'],
        action.payload,
      );
    case EquipmentServiceTaskActionTypes.RESET_UPDATE_EQUIPMENT_LOCATION:
      return resetUpdateEquipmentLocation(lastState);

    case EquipmentServiceTaskActionTypes.REQUEST_UPDATE_EQUIPMENT_LOCATION_DURING_BARCODE_SEARCH:
      return reducerUtils.requestPostPutItemData<IServiceTaskState, ILocationChange>(
        lastState,
        ['updateEquipmentLocationDuringBarcodeSearch'],
        action.payload,
      );
    case EquipmentServiceTaskActionTypes.RECEIVE_UPDATE_EQUIPMENT_LOCATION_DURING_BARCODE_SEARCH:
      return reducerUtils.receivePostPutItemData<IServiceTaskState, ILocationChange>(
        lastState,
        ['updateEquipmentLocationDuringBarcodeSearch'],
        action.payload,
      );
    case EquipmentServiceTaskActionTypes.ERROR_RECEIVE_UPDATE_EQUIPMENT_LOCATION_DURING_BARCODE_SEARCH:
      return reducerUtils.errorPostPutItemData<IServiceTaskState, ILocationChange>(
        lastState,
        ['updateEquipmentLocationDuringBarcodeSearch'],
        action.payload,
      );

    case EquipmentServiceTaskActionTypes.SAVE_SELECTED_SERVICE_EQUIPMENT:
      return reducerUtils.receiveListData<IServiceTaskState, IServiceTask[]>(
        lastState,
        ['selectedServiceEquipment'],
        action.payload,
      );

    case EquipmentServiceTaskActionTypes.REQUEST_SAVE_SERVICE_DEFINITION_CHECKLIST:
      return reducerUtils.requestPostPutItemData<IServiceTaskState, ITask | any>(
        lastState,
        ['sendServicesChecklist'],
        action.payload,
      );
    case EquipmentServiceTaskActionTypes.RECEIVE_SAVE_SERVICE_DEFINITION_CHECKLIST:
      return reducerUtils.receivePostPutItemData<IServiceTaskState, ITask | any>(
        lastState,
        ['sendServicesChecklist'],
        action.payload,
      );
    case EquipmentServiceTaskActionTypes.ERROR_RECEIVE_SAVE_SERVICE_DEFINITION_CHECKLIST:
      return reducerUtils.errorPostPutItemData<IServiceTaskState, ITask | any>(
        lastState,
        ['sendServicesChecklist'],
        action.payload,
      );
    case EquipmentServiceTaskActionTypes.RESET_SAVE_SERVICE_DEFINITION_CHECKLIST:
      return resetSaveServicesChecklistState(lastState);

    case EquipmentServiceTaskActionTypes.REQUEST_CHECKLISTS_DATA:
      return reducerUtils.requestItemData<IServiceTaskState, IChecklists>(lastState, ['checklists']);
    case EquipmentServiceTaskActionTypes.RECEIVE_CHECKLISTS_DATA:
      return reducerUtils.receiveItemData<IServiceTaskState, IChecklists>(lastState, ['checklists'], action.payload);
    case EquipmentServiceTaskActionTypes.ERROR_RECEIVE_CHECKLISTS_DATA:
      return reducerUtils.errorItemData<IServiceTaskState, IChecklists>(lastState, ['checklists'], action.payload);
    case EquipmentServiceTaskActionTypes.RESET_SAVE_SERVICE_DEFINITION_CHECKLIST:
      return resetChecklistsDataState(lastState);

    case EquipmentServiceTaskActionTypes.REQUEST_SAVE_BATCH_CHECKLIST:
      return reducerUtils.requestPostPutItemData<IServiceTaskState, ISaveBatchChecklist>(
        lastState,
        ['saveBatchChecklist'],
        action.payload,
      );
    case EquipmentServiceTaskActionTypes.RECEIVE_SAVE_BATCH_CHECKLIST:
      return reducerUtils.receivePostPutItemData<IServiceTaskState, ISaveBatchChecklist>(
        lastState,
        ['saveBatchChecklist'],
        action.payload,
      );
    case EquipmentServiceTaskActionTypes.ERROR_RECEIVE_SAVE_BATCH_CHECKLIST:
      return reducerUtils.errorPostPutItemData<IServiceTaskState, ISaveBatchChecklist>(
        lastState,
        ['saveBatchChecklist'],
        action.payload,
      );
    case EquipmentServiceTaskActionTypes.RESET_SAVE_BATCH_CHECKLIST:
      return resetSaveBatchChecklistState(lastState);

    default:
      return lastState;
  }
}
