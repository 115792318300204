import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ApplicationState, CommonConstants, IStoreApiItem } from 'src/app/common';
import { NotificationConstants } from 'src/app/common/constants/notification.constants';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { NotificationsService } from 'src/app/common/services/notifications/notifications.service';
import { formatLocaleTime } from 'src/app/common/utils/date-utils/date.utils';
import { RemoteMonitoringConstants } from 'src/app/remote-monitoring/constants/remote-monitoring.constants';
import { RemoteMonitoringActions } from 'src/app/remote-monitoring/state/actions/remote-monitoring.actions';
import { IncidentActions } from 'src/app/report-center/state/actions/incident.actions';
import { INote } from '../../../models/remote-monitoring.model';
import { IEcpTeam, INewNote } from './../../../models/remote-monitoring.model';
import { selectNewNote } from './../../../state/selectors/remote-monitoring.selector';

@Component({
  selector: 'ignis-notes-widget',
  templateUrl: './notes-widget.component.html',
  styleUrls: ['./notes-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesWidgetComponent extends OnDestroyMixin() implements OnChanges {
  @Input() openNotesWidget: boolean = false;
  @Input() incidentId: string;
  @Input() incidentVersion: number;
  @Input() notes: INote[];
  @Input() formatDate: string;
  @Input() openFromTeams: boolean = false;
  @Input() team: IEcpTeam;

  @Output() handleCloseNotesWidget: EventEmitter<boolean> = new EventEmitter();
  @Output() handleNewNotesNumber: EventEmitter<number> = new EventEmitter();
  @Output() handleAddNewNote: EventEmitter<string> = new EventEmitter();

  widgetWidth: number = 395;
  toggleWidget: boolean = false;
  closeNotesWidget: boolean = false;
  newNotes: INote[];
  earlierNotes: INote[];
  readNotesTimeout: number | any;
  loadingDataFirstTime: boolean = false;
  showAddNote: boolean = false;
  imageUrl: string;
  openImageModal: boolean = false;
  newNoteForm: FormGroup = new FormGroup({
    noteText: new FormControl(null),
  });

  displayNewSection: boolean = true;
  newSectionHeight: string = 'calc(100% /2 - 25px)';
  displayEarlierSection: boolean = true;
  earlierSectionHeight: string = 'calc(100% /2 - 25px)';

  currentAppTheme: string;

  private notificationsService: NotificationsService = inject(NotificationsService);
  public remoteMonitoringActions: RemoteMonitoringActions = inject(RemoteMonitoringActions);
  public reportCenterActions: IncidentActions = inject(IncidentActions);
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    private store: Store<ApplicationState>,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super();

    this.router.events?.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.handleNewNotesNumber.emit(0);
        this.closeWidget();
        this.cdr.detectChanges();
      }
    });

    this.newNotes = [];
    this.earlierNotes = [];

    this.currentAppTheme = this.document.body.className.split(' ')[1];
  }

  @HostListener(CommonConstants.beforeUnloadWindowEvent, ['$event'])
  handleBeforeUnload($event: any): void {
    if (this.showAddNote && this.newNoteForm.get('noteText').value && this.hasUnsavedData()) {
      $event.returnValue = this.hasUnsavedData();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.notes || changes.team) {
      this.processNotes();
    }

    this.markNotesAsReadByComponentState(changes);
  }

  processNotes() {
    const notes: INote[] = this.processNotesMediaAssets();

    this.newNotes = notes.filter((note: INote) => !note.read);
    this.earlierNotes = notes.filter((note: INote) => note.read);

    const newNotesNumber: number = notes.filter((note: INote) => !note.read).length;

    this.handleNewNotesNumber.emit(newNotesNumber);
  }

  markNotesAsReadByComponentState(changes: SimpleChanges | any): void {
    if (
      (!changes?.openNotesWidget?.previousValue && changes?.openNotesWidget?.currentValue) ||
      (!changes?.openFromTeams?.previousValue && changes?.openFromTeams?.currentValue)
    ) {
      this.markNotesAsRead(this.openNotesWidget);
    }

    if (
      (changes?.openNotesWidget?.previousValue && !changes?.openNotesWidget?.currentValue) ||
      (changes?.openFromTeams?.previousValue && !changes?.openFromTeams?.currentValue)
    ) {
      this.markNotesAsRead(false);
    }

    if (
      (!changes?.openNotesWidget?.previousValue && !changes?.openNotesWidget?.currentValue && !this.openNotesWidget) ||
      (!changes?.openFromTeams?.previousValue && !changes?.openFromTeams?.currentValue && !this.openNotesWidget)
    ) {
      this.markNotesAsRead(false);
    }
  }

  processNotesMediaAssets(): INote[] {
    if (!this.notes) {
      return [];
    }

    const allNotes: INote[] = structuredClone(this.notes);

    allNotes?.forEach((note: INote) => {
      note.time = formatLocaleTime(new Date(note.timestamp));
    });

    return allNotes;
  }

  markNotesAsRead(openNotesWidget: boolean): void {
    const unreadNotesIds: Partial<INote>[] = [];
    const readNotesTimeOutID: number = Number(localStorage.getItem(RemoteMonitoringConstants.readNotesTimeoutID));

    this.newNotes?.forEach((note: INote) =>
      unreadNotesIds.push({ ecpId: note.ecpId, teamId: note.teamId, identifier: note.identifier }),
    );

    if (openNotesWidget) {
      this.readNotesTimeout = setTimeout(() => {
        if (unreadNotesIds.length > 0) {
          this.remoteMonitoringActions.requestReadNotes(this.incidentId, unreadNotesIds, this.incidentVersion);
          localStorage.removeItem(RemoteMonitoringConstants.readNotesTimeoutID);
        }
      }, 10000);

      localStorage.setItem(RemoteMonitoringConstants.readNotesTimeoutID, this.readNotesTimeout.toString());
    } else {
      if (readNotesTimeOutID) {
        clearTimeout(readNotesTimeOutID);
        localStorage.removeItem(RemoteMonitoringConstants.readNotesTimeoutID);
      }
    }
  }

  closeWidget(): void {
    this.closeNotesWidget = true;
    this.loadingDataFirstTime = false;
    this.openFromTeams = false;

    setTimeout(() => {
      this.handleCloseNotesWidget.emit(false);
      this.closeNotesWidget = false;
      this.widgetWidth = 395;
      this.showAddNote = false;
      this.toggleWidget = false;
    }, 500);
  }

  toggleWidgetWidth(): void {
    this.widgetWidth = 790;

    if (!this.toggleWidget) {
      this.widgetWidth = 790;
    } else {
      this.widgetWidth = 395;
    }

    this.toggleWidget = !this.toggleWidget;
  }

  displayAddNote(): void {
    this.showAddNote = true;
  }

  hideAddNote(): void {
    this.showAddNote = false;
    this.newNoteForm.markAsPristine();
  }

  addNote(): void {
    let incidentId: string = window.location.href.split('/').slice(-4)[0];
    let ecpId: string = null;

    this.route.params.pipe(takeUntil(this.destroy)).subscribe((incidentParams: Params) => {
      // eslint-disable-next-line no-prototype-builtins
      if (incidentParams.hasOwnProperty('id')) {
        incidentId = incidentParams.id;
      } else {
        ecpId = incidentParams.ecpId;
      }

      this.cdr.detectChanges();
    });

    const newNote: INewNote = {
      incidentAggregateId: incidentId,
      ecpId,
      teamId: this.team ? this.team.teamId : null,
      text: this.newNoteForm.get('noteText').value,
      version: this.incidentVersion,
    };

    this.remoteMonitoringActions.requestAddNote(newNote);

    this.showAddNote = false;
    this.newNoteForm.reset();

    this.store
      .pipe(select(selectNewNote))
      .pipe(
        filter((newNote: IStoreApiItem<INewNote>) => !newNote.isLoading),
        take(1),
      )
      .subscribe((response: IStoreApiItem<INewNote>) => {
        if (response.isSuccess) {
          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.SUCCESS,
            NotificationConstants.commonCodes.ADD_NEW_NOTE,
            NotificationConstants.commonCodes,
          );

          if (this.team) {
            this.remoteMonitoringActions.requestIncidentEcpById(this.incidentId, ecpId);
          }

          if (incidentId) {
            this.handleAddNewNote.emit(this.incidentId);
          }

          this.remoteMonitoringActions.requestResetAddNote();
        }

        this.cdr.detectChanges();
      });
  }

  openImagePopUp(imageUrl: string): void {
    this.imageUrl = imageUrl;
    this.openImageModal = true;
  }

  closeNoteImageModal(): void {
    this.openImageModal = false;
  }

  hasUnsavedData(): boolean {
    return this.newNoteForm.dirty;
  }

  toggleNewSection(): void {
    this.displayNewSection = !this.displayNewSection;

    if (!this.displayNewSection) {
      this.newSectionHeight = 'auto';
      this.earlierSectionHeight = '90%';
    }

    if (this.displayNewSection && this.displayEarlierSection) {
      this.newSectionHeight = 'calc(100% /2 - 25px)';
      this.earlierSectionHeight = 'calc(100% /2 - 25px)';
    }

    if (this.displayNewSection && !this.displayEarlierSection) {
      this.newSectionHeight = '90%';
      this.earlierSectionHeight = 'auto';
    }
  }

  toggleEarlierSection(): void {
    this.displayEarlierSection = !this.displayEarlierSection;

    if (!this.displayEarlierSection) {
      this.newSectionHeight = '90%';
      this.earlierSectionHeight = 'auto';
    }

    if (!this.displayEarlierSection && !this.displayNewSection) {
      this.newSectionHeight = 'auto';
      this.earlierSectionHeight = 'auto';
    }

    if (this.displayNewSection && this.displayEarlierSection) {
      this.newSectionHeight = 'calc(100% /2 - 25px)';
      this.earlierSectionHeight = 'calc(100% /2 - 25px)';
    }

    if (!this.displayNewSection && this.displayEarlierSection) {
      this.newSectionHeight = 'auto';
      this.earlierSectionHeight = '90%';
    }
  }
}
