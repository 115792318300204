import { GenericAction } from 'src/app/common';
import { receiveItemData } from 'src/app/common/utils/reducer/reducer.utils';
import { IIncidentFilter } from 'src/app/remote-monitoring/models/remote-monitoring.model';
import { RemoteMonitoringActionTypes } from '../../actions/remote-monitoring-action-types';
import { IRemoteMonitoringFilterState, RemoteMonitoringFilterState } from '../../models/filter.models';

const resetFilterParamsState = (lastState: RemoteMonitoringFilterState): IRemoteMonitoringFilterState => ({
  ...lastState,
  filterParams: {
    isLoading: false,
    data: null,
    errors: null,
    isSuccess: false
  }
});

export function remoteMonitoringFilterReducer(
  lastState: IRemoteMonitoringFilterState = new RemoteMonitoringFilterState(),
  action: GenericAction<RemoteMonitoringActionTypes, any>
): IRemoteMonitoringFilterState {

  switch (action.type) {
    case RemoteMonitoringActionTypes.SAVE_FILTER_PARAMS:
      return receiveItemData<IRemoteMonitoringFilterState, IIncidentFilter>(lastState, ['filterParams'], action.payload);

    case RemoteMonitoringActionTypes.RESET_FILTER_PARAMS:
      return resetFilterParamsState(lastState);

    default:
      return lastState;
  }
}
