// eslint-disable-next-line no-shadow
export enum EquipmentCRUDActionTypes {
  REQUEST_EQUIPMENT_PAGE = 'REQUEST_EQUIPMENT_PAGE',
  RECEIVE_EQUIPMENT_PAGE = 'RECEIVE_EQUIPMENT_PAGE',
  ERROR_RECEIVE_EQUIPMENT_PAGE = 'RECEIVE_EQUIPMENT_FAILED_PAGE',

  REQUEST_EQUIPMENT_BY_ID = 'REQUEST_EQUIPMENT_BY_ID',
  RECEIVE_EQUIPMENT_BY_ID = 'RECEIVE_EQUIPMENT_BY_ID',
  ERROR_RECEIVE_EQUIPMENT_BY_ID = 'ERROR_RECEIVE_EQUIPMENT_BY_ID',

  REQUEST_SAVED_EQUIPMENTS = 'REQUEST_SAVED_EQUIPMENTS',
  RECEIVE_SAVED_EQUIPMENTS = 'RECEIVE_SAVED_EQUIPMENTS',
  ERROR_RECEIVE_SAVED_EQUIPMENTS = 'ERROR_RECEIVE_SAVED_EQUIPMENTS',
  RESET_SAVED_EQUIPMENTS = 'RESET_SAVED_EQUIPMENTS',

  REQUEST_SAVED_LOCATION_EQUIPMENTS = 'REQUEST_SAVED_LOCATION_EQUIPMENTS',
  RECEIVE_SAVED_LOCATION_EQUIPMENTS = 'RECEIVE_SAVED_LOCATION_EQUIPMENTS',
  ERROR_RECEIVE_SAVED_LOCATION_EQUIPMENTS = 'ERROR_RECEIVE_SAVED_LOCATION_EQUIPMENTS',
  RESET_SAVED_LOCATION_EQUIPMENTS = 'RESET_SAVED_LOCATION_EQUIPMENTS',

  REQUEST_ADD_EQUIPMENT = 'REQUEST_ADD_EQUIPMENT',
  RECEIVE_ADD_EQUIPMENT = 'RECEIVE_ADD_EQUIPMENT',
  ERROR_ADD_EQUIPMENT = 'ERROR_ADD_EQUIPMENT',

  REQUEST_UPDATE_EQUIPMENT = 'REQUEST_UPDATE_EQUIPMENT',
  RECEIVE_UPDATE_EQUIPMENT = 'RECEIVE_UPDATE_EQUIPMENT',
  ERROR_UPDATE_EQUIPMENT = 'ERROR_UPDATE_EQUIPMENT',

  REQUEST_EQUIPMENT_DELETE = 'REQUEST_EQUIPMENT_DELETE',
  RECEIVE_EQUIPMENT_DELETE = 'RECEIVE_EQUIPMENT_DELETE',
  ERROR_RECEIVE_EQUIPMENT_DELETE = 'ERROR_RECEIVE_EQUIPMENT_DELETE',

  REQUEST_UPLOAD_EQUIPMENT_CSV_FILE = 'REQUEST_UPLOAD_EQUIPMENT_CSV_FILE',
  RECEIVE_UPLOAD_EQUIPMENT_CSV_FILE = 'RECEIVE_UPLOAD_EQUIPMENT_CSV_FILE',
  ERROR_RECEIVE_UPLOAD_EQUIPMENT_CSV_FILE = 'ERROR_RECEIVE_UPLOAD_EQUIPMENT_CSV_FILE',

  RESET_STATE = 'RESET_STATE',
  RESET_DATA_STATE = 'RESET_DATA_STATE',
  REQUEST_EQUIPMENT_EXPORT = 'REQUEST_EQUIPMENT_EXPORT',
}
