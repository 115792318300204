import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { CommonConstants } from 'src/app/common/constants/common.constants';
import { TableFilterType } from 'src/app/common/constants/table.constants';
import { ITableColumn } from 'src/app/common/models/table.model';
import { DropdownService } from 'src/app/common/services/dropdown/dropdown.service';

@Component({
  selector: 'ignis-table-dropdown-filter',
  templateUrl: './table-dropdown-filter.component.html',
  styleUrls: ['./table-dropdown-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableDropdownFilterComponent {
  @Input() column: ITableColumn;
  @Input() customFilter: string;
  @Input() selectActiveOptions = {};

  filterType = TableFilterType;
  dropdownService: DropdownService = inject(DropdownService);
  dropdownIconCSSClass: string = CommonConstants.tableDropdownIconCSSClass;

  @Output() handleDropdownFilterValue: EventEmitter<{ value: any; field: string }> = new EventEmitter<{
    value: any;
    field: string;
  }>();

  onDropdownChange(event: any): void {
    this.handleDropdownFilterValue.emit({ value: event.value, field: this.column?.field });
  }
}
