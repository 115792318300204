import { createSelector, DefaultProjectorFn, MemoizedSelector, Selector } from '@ngrx/store';
import { ApplicationState, IApplicationState } from 'src/app/common';
import { IStoreApiList } from './../../../../common/models/store-api-list.model';
import { IFeaturesToggleState, IFeatureToggle } from './../models/features-toggle.model';

export const featuresToggleState: Selector<IApplicationState, IFeaturesToggleState> = (state: IApplicationState) =>
  state.featuresToggle;

export const selectFeaturesToggleList: MemoizedSelector<
  ApplicationState,
  IStoreApiList<IFeatureToggle[]>,
  DefaultProjectorFn<IStoreApiList<IFeatureToggle[]>>
> = createSelector(featuresToggleState, (state: IFeaturesToggleState) => state?.featuresToggleList);
