import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import filter from 'lodash-es/filter';
import sortBy from 'lodash-es/sortBy';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { filter as rxjsFilter, takeUntil } from 'rxjs';
import { NotificationConstants, TableExportExcelConstants } from 'src/app/common/constants';
import {
  EntryModel,
  IEntryModel,
  ITableColumn,
  ITableSettings,
  ITableSettingsResponse,
  PropertyBag,
} from 'src/app/common/models';
import { NotificationsService } from 'src/app/common/services';
import { formatLocaleTime, getDateAsNgbStruct, sliderInputValueChange } from 'src/app/common/utils';
import { AccessControlService } from 'src/app/root';
import { AppModulesTypes, AppUserPermissionList } from 'src/app/root/models';
import { selectTableColumns } from 'src/app/settings/state/selectors/settings.selector';
import { ColInfo, WorkBook, WorkSheet } from 'xlsx/types';
import { LogbookConstants } from '../../constants/logbook.constants';
import { IEntriesModel, ILogbook, ILogbookPage } from '../../models';
import { LogbookActions } from '../../state/actions/logbook.actions';
import { LogbookService } from './../../services';
import { tableColumns } from './table-columns';
import { tableDefaultSorting } from './table-settings';

import {
  CommonConstants,
  DropdownService,
  ExportTableExcelService,
  IApplicationState,
  IStoreApiItem,
  PressureUnitPipe,
  StorageConstants,
  TableConstants,
  TableHelperComponent,
} from 'src/app/common';

/* eslint-disable no-prototype-builtins */

@Component({
  selector: 'ignis-logbook-list',
  templateUrl: './logbook-list.component.html',
  styleUrls: ['./logbook-list.component.scss'],
  providers: [PressureUnitPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogbookListComponent extends TableHelperComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() formatDate: string;
  @Input() isLoading: boolean;
  @Input() logBookLength: number;
  @Input() entries: IEntriesModel;
  @Input() logbookPage: ILogbookPage;
  @Input() pressureDisplayUnit: string;
  @Input() logbookExcelData: ILogbook[];

  @Output() handleProceed: EventEmitter<void> = new EventEmitter();

  tableName: string = AppModulesTypes.logbook;
  localColumns: ITableColumn[] = tableColumns;
  defaultSorting: string = tableDefaultSorting;
  tableFiltersKey: string = StorageConstants.tablesStorageKeys.BA_LOGBOOK;
  preFilterKey: string = StorageConstants.tablesStorageKeys.BA_LOGBOOK_PRE_FILTER;
  dropdownIconCSSClass: string = CommonConstants.defaultDropdownIconCSSClass;

  logbook: ILogbook[] | any;
  copyOfLogbook: ILogbook[] | any;
  filteredLogbook: ILogbook[] | any;
  disableExportBtn: boolean = false;
  testReason: IEntryModel[];
  statuses: any[];
  loading: boolean = true;

  excelArray: any[] = [];

  startSliderValues: number[] = [0, 0];
  startSliderMinValue: number = 0;
  startSliderMaxValue: number = 0;
  endSliderValues: number[] = [0, 0];
  endSliderMinValue: number = 0;
  endSliderMaxValue: number = 0;

  minDate: string | Date;
  maxDate: string | Date;
  selectedDateRange: Date[];

  isPreFilterSet: boolean = false;
  activatedViewButton: boolean = false;
  activeLogbook: ILogbook;

  @ViewChild('dt2')
  public tableRef: Table | any = null;

  toggleCalendar: boolean = true;

  dateFilters: any;

  isPreFilterOpen: boolean = false;

  showPressureTooltip: string = '';
  startExport: boolean = false;

  statusList: { name: string; value: string }[] = LogbookConstants.statusList;
  selectedStatuses: { name: string; value: string }[] = [];

  rowsPerPageOptions: number[] = TableConstants.rowsPerPageOptions;

  params: any;
  lastTableElement: any;

  name: string;
  equipmentId: string;
  previousRoute: string;

  logbookActions: LogbookActions = inject(LogbookActions);
  logbookService: LogbookService = inject(LogbookService);
  dropdownService: DropdownService = inject(DropdownService);
  pressureUnitPipe: PressureUnitPipe = inject(PressureUnitPipe);
  translateService: TranslateService = inject(TranslateService);
  notificationsService: NotificationsService = inject(NotificationsService);
  exportTableExcelService: ExportTableExcelService = inject(ExportTableExcelService);
  accessControlService: AccessControlService = inject(AccessControlService);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  readModelSortKeys: PropertyBag = LogbookConstants.readModelSortKeys;

  constructor(
    private router: Router,
    private store: Store<IApplicationState>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setTableColumns();
    this.getLogbookDateFilter();
    this.checkPreviousRouteForPreFilter();

    this.isPreFilterSet = this.preFilterValueExists();
    this.selectedStatuses = this.tableHelperSavedFilters?.statuses;
  }

  ngAfterViewInit(): void {
    this.tableService.arrangePaginatingOfElement(this.tableRef);
  }

  ngOnChanges(changes?: SimpleChanges | any): void {
    if (this.logbookPage) {
      this.processLogbookData();
      this.setPreFilterDate();
    }

    if (this.entries) {
      this.processEntriesData(this.entries);
    }

    this.setPreviousPressure();

    if (this.logbookExcelData && this.startExport) {
      this.processExcelData(this.logbookExcelData);
    }

    if (changes.pressureDisplayUnit && changes.entries?.currentValue) {
      [this.startSliderMinValue, this.startSliderMaxValue, this.endSliderMinValue, this.endSliderMaxValue] =
        this.transformPressures(['minStartPressure', 'maxStartPressure', 'minEndPressure', 'maxEndPressure']);

      this.startSliderValues = [this.startSliderMinValue, this.startSliderMaxValue];
      this.endSliderValues = [this.endSliderMinValue, this.endSliderMaxValue];

      this.managePressureFilter(this.startSliderValues, 'start');
      this.managePressureFilter(this.endSliderValues, 'end');

      this.prepareTableParameters();
    }
  }

  transformPressures(pressureFields: string[]): number[] {
    return pressureFields.map((field: string) =>
      this.pressureUnitPipe.transform(this.entries[field], [this.pressureDisplayUnit, false]),
    );
  }

  managePressureFilter(pressures: number[], type: string): void {
    this.onFilter(`${type}Pressure`, sortBy(pressures));
  }

  processLogbookData(): void {
    this.totalRecords = this.logbookPage.totalRecords;
    this.totalPages = this.logbookPage.totalPages;

    this.tableHelperCheckOutOfRangePageFilter();
    this.logbook = structuredClone(this.logbookPage.entries);

    this.disableExportBtn = this.logbook.length > 0;

    this.logbook.forEach((logbook: ILogbook) => {
      const date: string = logbook.date;

      logbook.date = new Date(logbook.date);
      logbook.time = format(new Date(date), CommonConstants.TIME_WITH_SECONDS_FORMAT);
    });

    this.copyOfLogbook = this.logbook;
  }

  prepareTableParameters(): void {
    this.extraFilters = {
      ...this.tableService.tableDateParameter(
        this.tableHelperSavedFilters?.date ? this.tableHelperSavedFilters?.date[0] : null,
        'startTime',
      ),
      ...this.tableService.tableDateParameter(
        this.tableHelperSavedFilters?.date ? this.tableHelperSavedFilters?.date[1] : null,
        'endTime',
      ),
      ...this.tablePressureNoParameter('startPressure', ['minStartPressure', 'maxStartPressure']),
      ...this.tablePressureNoParameter('endPressure', ['minEndPressure', 'maxEndPressure']),
    };

    this.convertPressureUnit('start');
    this.convertPressureUnit('end');

    this.tableHelperPrepareTableParameters();
    this.refreshDatepicker();
  }

  convertPressureUnit(type: string): void {
    if (!this.pressureDisplayUnit) return;

    const pressureField: string = `${type}Pressure`;
    const extraField: string = `${type[0].toUpperCase()}${type.slice(1)}`;

    const min: string = `min${extraField}Pressure`;
    const max: string = `max${extraField}Pressure`;

    if (this.extraFilters.hasOwnProperty(pressureField)) {
      const pressureArray: string[] = this.extraFilters[pressureField].split(',');

      const minExtra: string = this.convertPressure(+pressureArray[0]);
      const maxExtra: string = this.convertPressure(+pressureArray[1]);

      this.extraFilters = { ...this.extraFilters, [pressureField]: [minExtra, maxExtra].toString() };
    }

    [min, max].forEach((category: string) => {
      if (this.extraFilters.hasOwnProperty(category)) {
        this.extraFilters[category] = this.convertPressure(+this.extraFilters[category]);
      }
    });
  }

  convertPressure(value: number): string {
    return Math.round(value * CommonConstants.pressureConversionMap[this.pressureDisplayUnit]).toString();
  }

  fetchTableData(params: any): void {
    this.isExportMode
      ? this.logbookActions.requestLogbookExcelData(params)
      : this.logbookActions.requestLogbookPage(params);
  }

  tablePressureNoParameter(savedFilter: string, newFilters: string[]): any {
    if (!this.tableHelperSavedFilters?.[savedFilter]?.length) return null;

    return {
      [newFilters[0]]: this.tableHelperSavedFilters[savedFilter].at(0),
      [newFilters[1]]: this.tableHelperSavedFilters[savedFilter].at(1),
    };
  }

  loadData(event: LazyLoadEvent | any): void {
    this.tableHelperOnLazyLoading(event);

    if (event.rows !== this.rows) this.tableRef._first = 0;
  }

  onPageChangeLocal(event: { first: number; rows: number }): void {
    if (this.rows === event.rows) {
      this.tableHelperOnPageChange({ page: event.first / event.rows, rows: this.rows });
    } else {
      this.rows = event.rows;
      this.tableHelperResetTablePaging();
      this.tableHelperSaveTableSettings();
      this.prepareTableParameters();
    }

    this.onClickOutside();
  }

  checkPreviousRouteForPreFilter(): void {
    this.isPreFilterOpen = LogbookConstants.preFilterKey in localStorage;
  }

  setPreFilterDate(): void {
    if (this.selectedDateRange?.length > 0 && this.selectedDateRange[0] && this.selectedDateRange[1]) {
      this.dateFilters = this.selectedDateRange;
    }
  }

  getLogbookDateFilter(): void {
    if (this.tableHelperSavedFilters && this.tableHelperSavedFilters.date) {
      this.selectedDateRange = [
        new Date(new Date(this.tableHelperSavedFilters.date[0]).setHours(0, 0, 0, 0)),
        new Date(new Date(this.tableHelperSavedFilters.date[1]).setHours(23, 59, 59)),
      ];
      this.dateFilters = this.selectedDateRange;

      return;
    }

    this.selectedDateRange = null;
    this.dateFilters = null;

    this.refreshDatepicker();
  }

  setTableColumns(): void {
    this.settingsActions.requestTableColumns(this.tableName);

    this.store
      .pipe(
        select(selectTableColumns),
        rxjsFilter((state: IStoreApiItem<ITableSettingsResponse>) => !state.isLoading),
        takeUntil(this.destroy),
      )
      .subscribe((columnsSettings: IStoreApiItem<ITableSettingsResponse>) => {
        if (columnsSettings.data) {
          if (columnsSettings.data.status === TableConstants.NO_CONTENT_STATUS_CODE) {
            this.handleNoSavedColumns();

            return;
          }

          this.handleSavedColumns(columnsSettings.data.body);
          this.prepareTableParameters();

          return;
        }

        if (columnsSettings.errors) {
          const errorCode: string = columnsSettings.errors.error.code?.toString();

          this.notificationsService.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            errorCode,
            NotificationConstants.commonCodes,
          );

          this.handleNoSavedColumns();
        }

        this.cdr.detectChanges();
      });
  }

  handleNoSavedColumns(): void {
    this.tableColumns = this.localColumns;
    this.lastTableElement = this.tableService.lastTableHeaderElem(this.tableColumns);

    this.prepareTableParameters();
  }

  handleSavedColumns(columnsSettings: ITableSettings): void {
    this.rows = columnsSettings.pageSize || CommonConstants.defaultTableRowsNumber;

    const responseData: ITableColumn[] = this.tableService.orderTableColumns(columnsSettings, this.localColumns);

    this.tableColumns = filter(responseData, (col: ITableColumn) => 'field' in col);
    this.lastTableElement = this.tableService.lastTableHeaderElem(this.tableColumns);
  }

  changeColumnVisibility(column: ITableColumn): void {
    this.tableService.toggleColumn(column, this.tableColumns);
    this.lastTableElement = this.tableService.lastTableHeaderElem(this.tableColumns);
    this.tableColumns = [...this.tableService.tableColumns];

    this.tableHelperResetTablePaging();

    if (!column.visible) {
      if (this.tableHelperCheckForSorting(column.field)) {
        this.tableHelperResetTableSorting();
      }

      switch (column.field) {
        case 'date':
          this.selectedDateRange = null;
          this.dateFilters = null;
          this.filtersData.date = null;
          this.tableService.resetPropertyFromTableState(this.preFilterKey, 'date');
          break;
        case 'startPressure':
          this.startSliderMinValue = null;
          this.startSliderMaxValue = null;
          this.startSliderValues = null;
          this.filtersData.startPressure = null;
          break;
        case 'endPressure':
          this.endSliderMinValue = null;
          this.endSliderMaxValue = null;
          this.endSliderValues = null;
          this.filtersData.endPressure = null;
          break;
        case 'status':
          this.filtersData.statuses = null;
          this.selectedStatuses = null;
          break;
        default:
          this.filtersData[column.field] = null;
          this.tableService.resetPropertyFromTableState(this.preFilterKey, column.field);
      }
    }

    this.tableHelperSaveTableSettings();
    this.tableHelperSetAllTableFilters(this.filtersData);

    this.selectedStatuses = this.tableHelperSavedFilters?.statuses ? this.tableHelperSavedFilters?.statuses : null;

    this.prepareTableParameters();
  }

  onColReorder(event: any): void {
    this.tableColumns = this.tableService.reorderColumns(event, this.tableColumns);
    this.lastTableElement = this.tableService.lastTableHeaderElem(this.tableColumns);

    this.tableHelperSaveTableSettings();
  }

  onColResize(): void {
    this.tableService.resetPropertyFromTableState(this.tableFiltersKey, 'columnWidths');
  }

  processEntriesData(entries: IEntriesModel): void {
    this.startSliderMinValue = this.pressureUnitPipe.transform(entries.minStartPressure, [
      this.pressureDisplayUnit,
      false,
    ]);
    this.startSliderMaxValue = this.pressureUnitPipe.transform(entries.maxStartPressure, [
      this.pressureDisplayUnit,
      false,
    ]);
    this.startSliderValues =
      this.tableHelperSavedFilters?.startPressure?.length > 0
        ? this.tableHelperSavedFilters?.startPressure
        : [this.startSliderMinValue, this.startSliderMaxValue];

    this.endSliderMinValue = this.pressureUnitPipe.transform(entries.minEndPressure, [this.pressureDisplayUnit, false]);
    this.endSliderMaxValue = this.pressureUnitPipe.transform(entries.maxEndPressure, [this.pressureDisplayUnit, false]);
    this.endSliderValues =
      this.tableHelperSavedFilters?.endPressure?.length > 0
        ? this.tableHelperSavedFilters?.endPressure
        : [this.endSliderMinValue, this.endSliderMaxValue];

    this.testReason = entries.entryReasonList.map((entry: IEntryModel) => ({
      ...entry,
      label: this.translateService.instant(
        LogbookConstants.logbookEntries.entryReasonList.find((t: EntryModel) => t.value === entry.value)
          ?.localizedName || entry.value,
      ),
    }));
  }

  preFilterValueExists(): boolean {
    const preFilterValue: any = JSON.parse(localStorage.getItem(this.preFilterKey));

    if (!preFilterValue) {
      return;
    }

    return Object.keys(preFilterValue).some((key: string) => {
      return preFilterValue[key]?.length;
    });
  }

  sliderInputChange(event: any, type: string, model: string): void {
    if (model === 'start') {
      this.startSliderValues = sortBy(
        sliderInputValueChange(event, type, this.startSliderValues, this.startSliderMaxValue),
      );

      this.managePressureFilter(this.startSliderValues, model);

      return;
    }

    this.endSliderValues = sortBy(sliderInputValueChange(event, type, this.endSliderValues, this.endSliderMaxValue));
    this.managePressureFilter(this.endSliderValues, model);
  }

  getPreFilterFormValues(event: { equipmentId: string; name: string; dateRange: Date[] }): void {
    this.equipmentId = event?.equipmentId;
    this.name = event?.name;

    this.setOrResetPreFilterValues(event);

    const mergeFilters: any = {
      equipmentId: this.equipmentId,
      name: this.name,
      date: this.selectedDateRange,
    };

    this.tableHelperSetAllTableFilters(mergeFilters);

    this.prepareTableParameters();
    this.refreshDatepicker();
  }

  setOrResetPreFilterValues(event: { equipmentId: string; name: string; dateRange: Date[] }): void {
    if (event?.dateRange[0] && event?.dateRange[1]) {
      this.selectedDateRange = [
        new Date(new Date(event.dateRange[0]).setHours(0, 0, 0, 0)),
        new Date(new Date(event.dateRange[1]).setHours(23, 59, 59)),
      ];
    } else {
      this.selectedDateRange = null;
    }

    this.tableColumns
      ?.filter((column: ITableColumn | any) => !column.visible)
      .forEach((column: ITableColumn | any) => {
        switch (column.field) {
          case 'equipmentId':
            this.equipmentId = null;
            break;
          case 'name':
            this.name = null;
            break;
          case 'date':
            this.selectedDateRange = null;
            break;
          default:
            break;
        }
      });

    this.refreshDatepicker();
  }

  onDateSelect(event: any): void {
    this.dateFilters = event;
    let splitOperator: string = '/';
    const fromStartDate: any = getDateAsNgbStruct(new Date(`${this.dateFilters[0]}`));
    const toEndDate: any = getDateAsNgbStruct(new Date(`${this.dateFilters[1]}`));

    if (this.formatDate && this.formatDate.includes('.')) {
      splitOperator = '.';
    } else if (this.formatDate && this.formatDate.includes('-')) {
      splitOperator = '-';
    }

    setTimeout(() => {
      if (this.dateFilters && this.dateFilters[1]) {
        const selectedDateRange: string = `${fromStartDate.day}${splitOperator}${fromStartDate.month}${splitOperator}${fromStartDate.year} - ${toEndDate.day}${splitOperator}${toEndDate.month}${splitOperator}${toEndDate.year}`;

        this.selectedDateRange = [
          new Date(new Date(this.dateFilters[0])),
          new Date(new Date(this.dateFilters[1]).setHours(23, 59, 59)),
        ];

        this.setValueInPrefilter('date', selectedDateRange);
        this.tableHelperSetTableFilters('date', [
          this.selectedDateRange[0].toISOString(),
          this.selectedDateRange[1].toISOString(),
        ]);
      }

      if (this.dateFilters && this.dateFilters[1]) {
        this.prepareTableParameters();
      }
    }, 250);
  }

  setValueInPrefilter(type: string, value: any): void {
    setTimeout(() => {
      const prefilterFormStorage: any = JSON.parse(localStorage.getItem(this.preFilterKey));

      if (prefilterFormStorage) {
        prefilterFormStorage[type] = value;
        localStorage.setItem(this.preFilterKey, JSON.stringify(prefilterFormStorage));
      }
    }, 600);
  }

  onDateChange(event: any): void {
    if (!event) {
      this.setValueInPrefilter('date', null);
      this.tableHelperSetTableFilters('date', null);

      this.dateFilters = null;
    }

    if (this.selectedDateRange?.length > 1) {
      this.dateFilters = null;
      this.selectedDateRange = null;

      this.prepareTableParameters();
    }
  }

  resetPrefilterFlag(event: boolean): void {
    this.isPreFilterOpen = event;
    this.refreshDatepicker();
  }

  openPreFilterModal(): void {
    this.isPreFilterOpen = true;
  }

  getLogbook(): void {
    this.handleProceed.emit();
    localStorage.removeItem(LogbookConstants.preFilterKey);
    this.isPreFilterSet = this.preFilterValueExists();

    this.refreshDatepicker();
  }

  onFilter(field: string, value: any): void {
    this.tableHelperResetTablePaging();
    this.filtersData[field] = typeof value === 'string' ? value?.trimStart()?.trimEnd() : value;

    this.tableHelperSetAllTableFilters(this.filtersData);
    this.prepareTableParameters();
  }

  clearFilter(column: string): void {
    this.onFilter(column, null);
  }

  exportExcel(): void {
    this.isExportMode = true;
    this.tableHelperPrepareTableParameters();
    this.excelArray = [];
    this.isLoading = true;
    this.startExport = true;
  }

  processExcelData(excelData: ILogbook[]): void {
    // eslint-disable-next-line @typescript-eslint/typedef
    const translationsKeysObj = LogbookConstants.translationKeyMap;
    const logbookList: ILogbook[] | any = excelData;

    const visibleTableColumns: ITableColumn[] = [];

    this.tableColumns?.forEach((column: ITableColumn | any) => {
      if (column.visible) {
        visibleTableColumns.push(column.field);
      }
    });

    logbookList?.forEach((logbook: ILogbook | any) => {
      const date: string = logbook?.date;

      logbook = {
        ...logbook,
        time: formatLocaleTime(new Date(date)),
        date: new DatePipe(CommonConstants.DEFAULT_LOCALE).transform(date, this.formatDate),
      };

      if (logbook.reason.value === 'test') {
        logbook.incidentNumber = '-';
      }

      const statusTranslateObj: any = {
        statuses: logbook.statuses,
        type: logbook.reason,
        incidentType: logbook.incidentType,
        cylinderRatedPressure: logbook.cylinderRatedPressure,
        pressureDisplayUnit: this.pressureDisplayUnit,
        incidentSpecialExposure: logbook.incidentSpecialExposure,
      };

      logbook.date = `${logbook.date} ${logbook.time}`;
      logbook.startPressure = this.pressureUnitPipe.transform(logbook.startPressure, [this.pressureDisplayUnit, true]);
      logbook.endPressure = this.pressureUnitPipe.transform(logbook.endPressure, [this.pressureDisplayUnit, true]);
      logbook.reason = this.logbookService.translateEventType(logbook.reason);
      logbook.status = this.logbookService.translateStatus(statusTranslateObj);
      logbook.incidentNumber = logbook.incidentNumber
        ? logbook.incidentNumber
        : this.logbookService.translateIncidentNo(logbook.incidentNumber);

      delete logbook.aggregateId;
      delete logbook.correlationId;
      delete logbook.equipmentModel;
      delete logbook.time;

      const orderedColumnsObj: any = visibleTableColumns.reduce((a: any, v: any) => ({ ...a, [v]: logbook[v] }), {});

      logbook = Object.assign(orderedColumnsObj, {});

      // eslint-disable-next-line @typescript-eslint/typedef
      logbook = Object.entries(logbook).reduce((op: any, [key, value]) => {
        const newKey: string = translationsKeysObj[key];

        op[(newKey?.includes('STR') ? this.translateService.instant(newKey) : newKey) || key] = value;

        return op;
      }, {});

      this.excelArray.push(logbook);
    });

    import('xlsx').then((xlsx) => {
      const worksheet: WorkSheet = xlsx.utils.json_to_sheet(this.excelArray, { origin: 'A6' });

      this.exportTableExcelService.insertMetaInformationHeader(this.store, worksheet, this.formatDate);

      const colsOptions: ColInfo[] = [];

      Object.keys(this.excelArray[0]).forEach(() => {
        colsOptions.push({ wpx: TableExportExcelConstants.cellWidthSize.NORMAL });
      });

      worksheet['!cols'] = colsOptions;

      const workbook: WorkBook = { Sheets: { logbook: worksheet }, SheetNames: ['logbook'] };
      const excelBuffer: WorkBook = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });

      this.saveAsExcelFile(
        excelBuffer,
        this.translateService.instant('LOGBOOK_TABLE.STR_EXCEL_FILE_NAME'),
        logbookList,
      );
    });
  }

  saveAsExcelFile(buffer: any, fileName: string, logbookList: ILogbook[] | any): void {
    import('file-saver').then((FileSaver: any) => {
      const excelExtension: string = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: CommonConstants.excelType,
      });

      const storedDateRange: string = JSON.parse(localStorage.getItem(this.preFilterKey))?.date;

      let splitOperator: string = '/';

      if (this.formatDate && this.formatDate.includes('.')) {
        splitOperator = '.';
      }

      let excelDate: string;

      if (storedDateRange) {
        excelDate = `${storedDateRange.split('-')[0].split(splitOperator)[2]?.replace(' ', '')}-${
          storedDateRange.split('-')[0].split(splitOperator)[1]
        }-${storedDateRange.split('-')[0].split(splitOperator)[0]}_${
          storedDateRange.split('-')[1].split(splitOperator)[2]
        }-${storedDateRange.split('-')[1].split(splitOperator)[1]}-${storedDateRange
          .split('-')[1]
          .split(splitOperator)[0]
          .replace(' ', '')}`;
      }

      if (!storedDateRange) {
        const dateArray: Date[] | any[] = logbookList.map((logbook: ILogbook) => new Date(logbook.date));

        const min: string = new DatePipe(CommonConstants.DEFAULT_LOCALE).transform(
          dateArray.sort((a: number, b: number) => a - b)[0],
          this.formatDate,
        );
        const max: string = new DatePipe(CommonConstants.DEFAULT_LOCALE).transform(
          dateArray.slice(-1)[0],
          this.formatDate,
        );

        excelDate = `${min.split(splitOperator)[2]}-${min.split(splitOperator)[1]}-${min.split(splitOperator)[0]}_${
          max.split(splitOperator)[2]
        }-${max.split(splitOperator)[1]}-${max.split(splitOperator)[0]}`;
      }

      FileSaver.default.saveAs(data, fileName + '_report_' + excelDate + excelExtension);
      this.excelArray = [];
      this.filteredLogbook = [];
      this.isLoading = false;
      this.startExport = false;
      this.logbookExcelData = null;

      this.tableHelperExcelExportDone();
    });
  }

  onChangeSort(event: any): void {
    this.tableHelperOnChangeSort(event);
    this.tableHelperResetTablePaging();
    this.prepareTableParameters();
  }

  onLogbookSelect(logbook: ILogbook): void {
    this.activeLogbook = logbook;

    this.activatedViewButton = logbook?.reason?.value !== 'test';
  }

  onClickOutside(): void {
    this.activeLogbook = null;
    this.activatedViewButton = false;
  }

  setPreviousPressure(): void {
    if (this.tableHelperSavedFilters) {
      if (this.tableHelperSavedFilters.startPressure) {
        this.startSliderValues = this.tableHelperSavedFilters.startPressure;
      }

      if (this.tableHelperSavedFilters.endPressure) {
        this.endSliderValues = this.tableHelperSavedFilters.endPressure;
      }
    }
  }

  refreshDatepicker(): void {
    this.toggleCalendar = false;

    setTimeout(() => {
      this.toggleCalendar = true;
      this.cdr.detectChanges();
    }, 10);
  }

  navigateToReportCenter(): void {
    if (
      this.accessControlService.checkPermission(AppUserPermissionList.reportCenter) &&
      this.accessControlService.reportCenterFeatureToggle
    ) {
      this.router.navigate(['report-center', 'update', this.activeLogbook.correlationId]);
    }
  }
}
