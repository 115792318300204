import { IEntryModel, PropertyBag } from 'src/app/common/models/common.model';

export class RemoteMonitoringConstants {
  public static readonly staticDarkTheme: string =
    'element:geometry%7Ccolor:0x242f3e&style=element:labels.text.fill%7Ccolor:0x746855&style=element:labels.text.stroke%7Ccolor:0x242f3e&style=feature:administrative.locality%7Celement:labels.text.fill%7Ccolor:0xd59563&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0xd59563&style=feature:poi.park%7Celement:geometry%7Ccolor:0x263c3f&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x6b9a76&style=feature:road%7Celement:geometry%7Ccolor:0x38414e&style=feature:road%7Celement:geometry.stroke%7Ccolor:0x212a37&style=feature:road%7Celement:labels.text.fill%7Ccolor:0x9ca5b3&style=feature:road.highway%7Celement:geometry%7Ccolor:0x746855&style=feature:road.highway%7Celement:geometry.stroke%7Ccolor:0x1f2835&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0xf3d19c&style=feature:transit%7Celement:geometry%7Ccolor:0x2f3948&style=feature:transit.location%7Celement:labels.text.fill%7Ccolor:0xd59563&style=feature:water%7Celement:geometry%7Ccolor:0x17263c&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x515c6d&style=feature:water%7Celement:labels.text.stroke%7Ccolor:0x17263c';

  public static readonly teamStatuses: Partial<IEntryModel>[] = [
    {
      value: 'finished',
      label: 'Finished',
      localizedName: 'REMOTE_MONITORING.STR_STATUS_FINISHED',
    },
    {
      value: 'at_incident',
      label: 'At Incident',
      localizedName: 'REMOTE_MONITORING.STR_STATUS_AT_INCIDENT',
    },
    {
      value: 'retreating',
      label: 'Retreating',
      localizedName: 'REMOTE_MONITORING.STR_STATUS_RETREATING',
    },
    {
      value: 'entering_incident',
      label: 'Entering incident',
      localizedName: 'REMOTE_MONITORING.STR_STATUS_ENTERING_INCIDENT',
    },
    {
      value: 'standby',
      label: 'Standby',
      localizedName: 'REMOTE_MONITORING.STR_STATUS_STANDBY',
    },
  ];

  public static readonly incidentStatusTypes: PropertyBag = {
    FINISHED: 'finished',
    LOST: 'lost',
  };

  public static readonly readNotesTimeoutID: string = 'readNotesTimeoutID';
  public static readonly lastGotIncidents: string = 'lastGotIncidents';
  public static readonly selectedIncidents: string = 'selectedIncidents';
}
