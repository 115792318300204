import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { CommonConstants, ExportTableExcelService } from 'src/app/common';
import { ColInfo, WorkBook, WorkSheet } from 'xlsx/types';
import { TableExportExcelConstants } from '../../constants/table-excel-export.constants';
import { OnDestroyMixin } from '../../mixins/destroy-mixin';
import { PropertyBag } from '../../models/common.model';
import { ITableColumn } from '../../models/table.model';
import { ApplicationState } from '../../state/models/app.state.model';
import { extractOnlyDate } from '../../utils/date-utils/date.utils';

@Component({
  standalone: true,
  selector: 'ignis-export-table-excel',
  template: '<span></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportTableExcelComponent extends OnDestroyMixin() implements OnChanges {
  @Input() formatDate: string;
  @Input() excelArray: any;
  @Input() sheetName: string;
  @Input() fileName: string;
  @Input() isSorted: boolean = false;
  @Input() tableColumns: ITableColumn[] | any;
  @Input() excelTranslationKeys: PropertyBag;

  dataToExport: any[] = [];

  @Output() handleExcelExportFinished: EventEmitter<void> = new EventEmitter();

  store: Store<ApplicationState> = inject(Store<ApplicationState>);
  exportTableExcelService: ExportTableExcelService = inject(ExportTableExcelService);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  constructor() {
    super();
  }

  ngOnChanges(): void {
    if (this.excelArray && this.tableColumns) {
      this.processingExcelData();
    }
  }

  processingExcelData(): void {
    this.orderAndToggleColumns();

    import('xlsx').then((xlsx) => {
      const worksheet: WorkSheet = xlsx.utils.json_to_sheet(this.dataToExport, { origin: 'A6' });

      this.exportTableExcelService.insertMetaInformationHeader(this.store, worksheet, this.formatDate);

      const colsOptions: ColInfo[] = [];

      Object.keys(this.excelArray[0]).forEach(() => {
        colsOptions.push({ wpx: TableExportExcelConstants.cellWidthSize.NORMAL });
      });

      worksheet['!cols'] = colsOptions;

      const workbook: WorkBook = { Sheets: { [this.sheetName]: worksheet }, SheetNames: [this.sheetName] };
      const excelBuffer: WorkBook = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });

      this.saveAsExcelFile(excelBuffer);
    });
  }

  orderAndToggleColumns(): void {
    const visibleTableColumns: ITableColumn[] = [];
    const tableColumnsToExcelExport: ITableColumn[] | any = this.tableColumns;

    tableColumnsToExcelExport.forEach((column: ITableColumn | any) => {
      if (column.visible) {
        visibleTableColumns.push(column.field);
      }
    });

    this.excelArray.forEach((excelData: any) => {
      if (!this.isSorted) {
        const orderedColumnsObj: any = visibleTableColumns.reduce(
          (a: any, v: any) => ({ ...a, [v]: excelData[v] }),
          {},
        );

        excelData = Object.assign(orderedColumnsObj, {});
      }

      excelData = this.exportTableExcelService.translateKeys(excelData, this.excelTranslationKeys);

      this.dataToExport.push(excelData);
    });
  }

  saveAsExcelFile(buffer: any): void {
    import('file-saver').then((FileSaver: any) => {
      const excelExtension: string = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: CommonConstants.excelType,
      });

      FileSaver.default.saveAs(data, this.fileName + '_export_' + extractOnlyDate(new Date()) + excelExtension);

      this.handleExcelExportFinished.emit();
      this.dataToExport = [];
    });
  }
}
