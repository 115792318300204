import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TooltipOptions } from 'primeng/api/tooltipoptions';
import { WorkshopConstants } from 'src/app/workshop';
import { IWorkflowBtn, WorkflowType } from 'src/app/workshop/models/workflow-page.model';

@Component({
  selector: 'ignis-workflow-button',
  templateUrl: './workflow-button.component.html',
  styleUrls: ['./workflow-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowButtonComponent {
  @Input() workflowData: IWorkflowBtn;
  @Input() currentlyWorkflowChecked: WorkflowType;
  @Output() handleSelectedWorkflow: EventEmitter<number> = new EventEmitter<number>();

  tooltipOptions: TooltipOptions = {
    appendTo: 'body',
    tooltipPosition: 'top',
  };
  workflowBtnType: typeof WorkflowType = WorkflowType;

  setWorkflow(targetType: WorkflowType): void {
    if (this.workflowData.disabled) return;

    this.currentlyWorkflowChecked =
      this.currentlyWorkflowChecked === targetType ? this.workflowBtnType.NONE : targetType;

    localStorage.setItem(WorkshopConstants.selectedWorkflow, this.currentlyWorkflowChecked.toString());
    this.handleSelectedWorkflow.emit(this.currentlyWorkflowChecked);
  }
}
