import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { INewUser, IUpdateUser } from '../../models/user.model';
import { IUser } from './../../models/user.model';
import { IUserState } from './../models/user.model';
import {
  RequestAddUser,
  RequestUpdateUser,
  RequestUserById,
  RequestUserDelete,
  RequestUsersPersonalInfoByIds,
  ResetUserCRUDState,
} from './user-action-types-creators/user-crud-action-types-creators';
import { RequestUserExport, RequestUserPage } from './user-action-types-creators/user-page-action-types-creators';
import { RequestUserRoleList } from './user-action-types-creators/user-role-list-action-types-creators';
import { RequestUploadCSVFile } from './user-action-types-creators/user-upload-csv-file-action-types-creators';

@Injectable({ providedIn: 'root' })
export class UserActions {
  constructor(private store: Store<IUserState>) {}

  public requestUserPage(params?: any, isExport: boolean = false): void {
    this.store.dispatch(isExport ? new RequestUserExport(params) : new RequestUserPage(params));
  }

  public requestAddUser(newUser: INewUser): any {
    this.store.dispatch(new RequestAddUser(newUser));
  }

  public requestUserById(aggregateId: string): void {
    this.store.dispatch(new RequestUserById(aggregateId));
  }

  public requestUsersPersonalInfoByIds(ids: string[]): void {
    this.store.dispatch(new RequestUsersPersonalInfoByIds(ids));
  }

  public requestUpdateUser(user: IUpdateUser): void {
    this.store.dispatch(new RequestUpdateUser(user));
  }

  public requestUserRoleList(): void {
    this.store.dispatch(new RequestUserRoleList());
  }

  public requestUserDelete(user: IUser): void {
    this.store.dispatch(new RequestUserDelete(user));
  }

  public resetUserCRUDState(): any {
    this.store.dispatch(new ResetUserCRUDState());
  }

  public uploadCSVFile(file: any): any {
    this.store.dispatch(new RequestUploadCSVFile(file));
  }
}
