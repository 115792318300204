import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ILegalTermsData } from '../models/legal-terms.model';
import {
  RequestAcceptDPA,
  RequestAcceptGDPR,
  RequestAcceptTAC,
  RequestDPAData,
  RequestGDPRData,
  RequestTACData,
} from './legal-terms-action-types-creators';

@Injectable({ providedIn: 'root' })
export class LegalTermsActions {
  constructor(private store: Store<ILegalTermsData>) {}

  public requestGDPRData(): void {
    this.store.dispatch(new RequestGDPRData());
  }

  public requestAcceptGDPR(version: string): void {
    this.store.dispatch(new RequestAcceptGDPR(version));
  }

  public requestTACData(): void {
    this.store.dispatch(new RequestTACData());
  }

  public requestAcceptTAC(version: string): void {
    this.store.dispatch(new RequestAcceptTAC(version));
  }

  public requestDPAData(): void {
    this.store.dispatch(new RequestDPAData());
  }

  public requestAcceptDPA(version: string): void {
    this.store.dispatch(new RequestAcceptDPA(version));
  }
}
