import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IEntryModel } from 'src/app/common/models/common.model';

@Component({
  selector: 'ignis-team-status-translation',
  templateUrl: './team-status-translation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamStatusTranslationComponent {
  @Input() entry: IEntryModel;
}
