import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'ignis-service-details-automatic-test',
  templateUrl: './service-details-automatic-test.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceDetailsAutomaticTestComponent {
  @Input() form: FormGroup;
}
