import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GenericAction } from 'src/app/common';
import { IMeasurementUnit } from './../../models/localization-profile.model';
import { ILocalizationProfile, LocalizationProfile } from '../../models/localization-profile.model';
import { SettingsService } from '../../services/settings.service';
import { SettingsActionTypes } from '../actions/settings-action-types';
import * as settingsActionCreators from '../actions/settings-action-types-creators';
import { ITableSettingsUpdate } from 'src/app/common/models/table.model';

@Injectable()
export class SettingsEffects {
  constructor(private settingsService: SettingsService) { }

  requestLocalizationSettings$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(SettingsActionTypes.REQUEST_LOCALIZED_OPTIONS),
      switchMap(() =>
        this.settingsService.getLocalizationSettings().pipe(
          map(
            (localizationOptions: ILocalizationProfile[]) =>
              new settingsActionCreators.ReceiveLocalizedOptions(
                localizationOptions.map((option: ILocalizationProfile) => new LocalizationProfile(option))
              )
          ),
          catchError((error: HttpErrorResponse) => of(new settingsActionCreators.ErrorReceiveLocalizedOptions(error)))
        )
      )
    );
  });

  requestUserLocalizationSettings$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(SettingsActionTypes.REQUEST_GET_USER_SETTINGS),
      switchMap(() =>
        this.settingsService.getUserLocalizationSettings().pipe(
          map(
            (localizationOptions: ILocalizationProfile) =>
              new settingsActionCreators.ReceiveUserSettings(new LocalizationProfile(localizationOptions))
          ),
          catchError((error: HttpErrorResponse) => of(new settingsActionCreators.ErrorUserSettings(error)))
        )
      )
    );
  });

  saveUserLocalizationSettings$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(SettingsActionTypes.REQUEST_SAVE_USER_SETTINGS),
      switchMap(
        (localizationProfile: GenericAction<SettingsActionTypes.REQUEST_GET_USER_SETTINGS, ILocalizationProfile>) =>
          this.settingsService.saveUserLocalizationSettings(localizationProfile.payload).pipe(
            map(() => new settingsActionCreators.ReceiveSaveUserSettings()),
            catchError((error: HttpErrorResponse) => of(new settingsActionCreators.ErrorSaveUserSettings(error)))
          )
      )
    );
  });

  requestMeasurementUnits$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(SettingsActionTypes.REQUEST_MEASUREMENT_UNITS),
      switchMap(() =>
        this.settingsService.getMeasurementUnits().pipe(
          map(
            (measurementUnits: IMeasurementUnit[]) =>
              new settingsActionCreators.ReceiveMeasurementUnits(measurementUnits)
          ),
          catchError((error: HttpErrorResponse) => of(new settingsActionCreators.ErrorReceiveMeasurementUnits(error)))
        )
      )
    );
  });

  requestTableSettings$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(SettingsActionTypes.REQUEST_TABLE_COLUMNS),
      switchMap((params: any) =>
        this.settingsService.getTableSettings(params.payload).pipe(
          map(
            (response: any) =>
              new settingsActionCreators.ReceiveTableColumns(response)
          ),
          catchError((error: HttpErrorResponse) => of(new settingsActionCreators.ErrorReceiveTableColumns(error)))
        )
      )
    );
  });

  requestUpdateTableSettings$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(SettingsActionTypes.REQUEST_UPDATE_TABLE_COLUMN),
      switchMap((params: { payload: ITableSettingsUpdate }) =>
        this.settingsService.updateTableSettings(params.payload).pipe(
          map(() => new settingsActionCreators.ReceiveUpdateTableColumn()),
          catchError((error: HttpErrorResponse) => of(new settingsActionCreators.ErrorReceiveUpdateTableColumn(error)))
        )
      )
    );
  });
}
