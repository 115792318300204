import { Component, inject, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { IApplicationState, IStoreApiList } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { LocationHierarchyActions } from 'src/app/configuration/state/actions';
import { AppModulesTypes } from 'src/app/root/models/app-types';
import { IFeatureToggle } from 'src/app/root/state/features-toggle/models/features-toggle.model';
import { selectFeaturesToggleList } from 'src/app/root/state/features-toggle/selectors/features-toggle.selector';
import { getDateFormatFromUserProfile } from '../../../common/utils/settings-utils/settings-utils';
import { UserActions } from './../../state/actions/user.actions';

@Component({
  selector: 'ignis-user-management',
  templateUrl: './user-management.component.html',
})
export class UserManagementComponent extends OnDestroyMixin() implements OnInit {
  formatDate: string;

  userActions: UserActions = inject(UserActions);
  store: Store<IApplicationState> = inject(Store<IApplicationState>);
  translateService: TranslateService = inject(TranslateService);
  locationHierarchyActions: LocationHierarchyActions = inject(LocationHierarchyActions);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.getRoleList();
    this.checkRestrictFeatureToggle();

    this.formatDate = getDateFormatFromUserProfile(this.translateService);
  }

  getRoleList(): void {
    this.userActions.requestUserRoleList();
  }

  getLocations(): void {
    this.locationHierarchyActions.requestLocationHierarchy();
  }

  checkRestrictFeatureToggle(): void {
    this.store
      .pipe(select(selectFeaturesToggleList))
      .pipe(takeUntil(this.destroy))
      .subscribe((state: IStoreApiList<IFeatureToggle[]>) => {
        const featuresToggle: IFeatureToggle[] = state?.data;

        featuresToggle?.forEach((ft: IFeatureToggle) => {
          if (Object.values(ft).includes(AppModulesTypes.restrictToLocation) && ft.isEnabled) {
            this.getLocations();
          }
        });
      });
  }
}
