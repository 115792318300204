// eslint-disable-next-line no-shadow
export enum BAWearerActionTypes {
  REQUEST_BA_WEARER_PAGE = 'REQUEST_BA_WEARER_PAGE',
  RECEIVE_BA_WEARER_PAGE = 'RECEIVE_BA_WEARER_PAGE',
  ERROR_RECEIVE_BA_WEARER_PAGE = 'ERROR_RECEIVE_BA_WEARER_PAGE',

  REQUEST_BA_WEARER_BY_ID = 'REQUEST_BA_WEARER_BY_ID',
  RECEIVE_BA_WEARER_BY_ID = 'RECEIVE_BA_WEARER_BY_ID',
  ERROR_RECEIVE_BA_WEARER_BY_ID = 'ERROR_RECEIVE_BA_WEARER_BY_ID',

  REQUEST_CREATE_BA_WEARER = 'REQUEST_CREATE_BA_WEARER',
  RECEIVE_CREATE_BA_WEARER = 'RECEIVE_CREATE_BA_WEARER',
  ERROR_RECEIVE_CREATE_BA_WEARER = 'ERROR_RECEIVE_CREATE_BA_WEARER',

  REQUEST_UPDATE_BA_WEARER = 'REQUEST_UPDATE_BA_WEARER',
  RECEIVE_UPDATE_BA_WEARER = 'RECEIVE_UPDATE_BA_WEARER',
  ERROR_RECEIVE_UPDATE_BA_WEARER = 'ERROR_RECEIVE_UPDATE_BA_WEARER',

  REQUEST_DELETE_BA_WEARER = 'REQUEST_DELETE_BA_WEARER',
  RECEIVE_DELETE_BA_WEARER = 'RECEIVE_DELETE_BA_WEARER',
  ERROR_RECEIVE_DELETE_BA_WEARER = 'ERROR_RECEIVE_DELETE_BA_WEARER',

  REQUEST_FIRE_STATION_LIST = 'REQUEST_FIRE_STATION_LIST',
  RECEIVE_FIRE_STATION_LIST = 'RECEIVE_FIRE_STATION_LIST',
  ERROR_RECEIVE_FIRE_STATION_LIST = 'ERROR_RECEIVE_FIRE_STATION_LIST',

  REQUEST_UPLOAD_BA_WEARER_CSV_FILE = 'REQUEST_UPLOAD_BA_WEARER_CSV_FILE',
  RECEIVE_UPLOAD_BA_WEARER_CSV_FILE = 'RECEIVE_UPLOAD_BA_WEARER_CSV_FILE',
  ERROR_RECEIVE_UPLOAD_BA_WEARER_CSV_FILE = 'ERROR_RECEIVE_UPLOAD_BA_WEARER_CSV_FILE',
}
