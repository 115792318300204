import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IEditLocationHierarchy } from 'src/app/configuration/models';
import { LocationConfigActionTypes } from './location-config-action-types';

export class RequestLocationConfiguration implements Action {
  readonly type: LocationConfigActionTypes.REQUEST_LOCATION_CONFIGURATION =
    LocationConfigActionTypes.REQUEST_LOCATION_CONFIGURATION;

  constructor(public payload: string) {}
}
export class ReceiveLocationConfiguration implements Action {
  readonly type: LocationConfigActionTypes.RECEIVE_LOCATION_CONFIGURATION =
    LocationConfigActionTypes.RECEIVE_LOCATION_CONFIGURATION;

  constructor(public payload: IEditLocationHierarchy) {}
}
export class ErrorReceiveLocationConfiguration implements Action {
  readonly type: LocationConfigActionTypes.ERROR_RECEIVE_LOCATION_CONFIGURATION =
    LocationConfigActionTypes.ERROR_RECEIVE_LOCATION_CONFIGURATION;

  constructor(public payload: HttpErrorResponse) {}
}
export class ResetLocationConfiguration implements Action {
  readonly type: LocationConfigActionTypes.RESET_LOCATION_CONFIGURATION =
    LocationConfigActionTypes.RESET_LOCATION_CONFIGURATION;
}
