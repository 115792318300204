<div class="no-footer-table">
  <ignis-automatic-test-values
    *ngIf="testType === taskValuesTypes.AUTOMATIC_TEST"
    [equipmentTaskResultsForm]="equipmentTaskResultsForm"
    [formatDate]="formatDate"
    [taskValues]="taskValues"
  ></ignis-automatic-test-values>
  <ignis-manual-test-values
    *ngIf="testType === taskValuesTypes.MANUAL_TEST"
    [formatDate]="formatDate"
    [checklistData]="checklistData"
  ></ignis-manual-test-values>
</div>
