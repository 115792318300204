import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, map, Observable, of, switchMap } from 'rxjs';
import {
  ErrorReceiveTableExport,
  ReceiveTableExport,
} from 'src/app/common/state/export/actions/export-action-types-creators';
import { IChecklists, ISaveBatchChecklist, ITask } from 'src/app/configuration/models';
import { WorkshopService } from 'src/app/workshop/services';
import * as models from '../../../models';
import * as actions from '../../actions/equipment-service-task';

@Injectable()
export class EquipmentServiceTaskEffects {
  workshopService: WorkshopService = inject(WorkshopService);

  requestServiceTaskPage$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentServiceTaskActionTypes.REQUEST_SERVICE_TASK_PAGE),
      switchMap((params: any) =>
        this.workshopService.getServiceTaskPage(params.payload).pipe(
          map(
            (serviceTaskPage: models.IServiceTaskPage) =>
              new actions.ReceiveServiceTaskPage(new models.ServiceTaskPage(serviceTaskPage)),
          ),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveServiceTaskPage(error))),
        ),
      ),
    );
  });

  requestServiceTaskExport$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentServiceTaskActionTypes.REQUEST_SERVICE_TASK_EXPORT),
      switchMap((params: any) =>
        this.workshopService.getServiceTaskPage(params.payload).pipe(
          map(
            (serviceTaskPage: models.IServiceTaskPage) =>
              new ReceiveTableExport(new models.ServiceTaskPage(serviceTaskPage)),
          ),
          catchError((error: HttpErrorResponse) => of(new ErrorReceiveTableExport(error))),
        ),
      ),
    );
  });

  requestSendServiceIntervals$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentServiceTaskActionTypes.REQUEST_SEND_SERVICE_INTERVALS),
      switchMap((serviceIntervalsData: { payload: models.ICylinderChargerRequest }) =>
        this.workshopService.sendServiceIntervals(serviceIntervalsData.payload).pipe(
          map((serviceIntervals: any) => new actions.ReceiveSendServiceIntervals(serviceIntervals)),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveSendServiceIntervals(error))),
        ),
      ),
    );
  });

  requestUpdateEquipmentLocation$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentServiceTaskActionTypes.REQUEST_UPDATE_EQUIPMENT_LOCATION),
      switchMap((locationChange: { payload: models.ILocationChange }) =>
        this.workshopService.updateEquipmentsLocation(locationChange.payload).pipe(
          map((movedEquipments: models.ILocationChange) => new actions.ReceiveUpdateEquipmentLocation(movedEquipments)),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveUpdateEquipmentLocation(error))),
        ),
      ),
    );
  });

  requestUpdateEquipmentLocationDuringBarcodeSearch$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentServiceTaskActionTypes.REQUEST_UPDATE_EQUIPMENT_LOCATION_DURING_BARCODE_SEARCH),
      switchMap((locationChange: { payload: models.ILocationChange }) =>
        this.workshopService.updateEquipmentsLocation(locationChange.payload).pipe(
          map(
            (movedEquipments: models.ILocationChange) =>
              new actions.ReceiveUpdateEquipmentLocationDuringBarcodeSearch(movedEquipments),
          ),
          catchError((error: HttpErrorResponse) =>
            of(new actions.ErrorReceiveUpdateEquipmentLocationDuringBarcodeSearch(error)),
          ),
        ),
      ),
    );
  });

  requestChecklistsData$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentServiceTaskActionTypes.REQUEST_CHECKLISTS_DATA),
      switchMap((equipmentIds: any) =>
        this.workshopService.getChecklistsData(equipmentIds.payload).pipe(
          map((checklists: IChecklists) => new actions.ReceiveChecklistsData(checklists)),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveChecklistsData(error))),
        ),
      ),
    );
  });

  requestSaveServicesChecklist$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentServiceTaskActionTypes.REQUEST_SAVE_SERVICE_DEFINITION_CHECKLIST),
      switchMap((serviceDefinition: { payload: ITask }) =>
        this.workshopService.saveServicesChecklist(serviceDefinition.payload).pipe(
          map((sd: ITask) => new actions.ReceiveSaveServicesChecklist(sd)),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveSaveServicesChecklist(error))),
        ),
      ),
    );
  });

  requestSaveBatchChecklist$: Observable<Action> = createEffect(() => {
    return inject(Actions).pipe(
      ofType(actions.EquipmentServiceTaskActionTypes.REQUEST_SAVE_BATCH_CHECKLIST),
      switchMap((batchChecklistData: { payload: ISaveBatchChecklist }) =>
        this.workshopService.saveBatchChecklist(batchChecklistData.payload).pipe(
          map((sd: ISaveBatchChecklist) => new actions.ReceiveSaveBatchChecklist(sd)),
          catchError((error: HttpErrorResponse) => of(new actions.ErrorReceiveSaveBatchChecklist(error))),
        ),
      ),
    );
  });
}
