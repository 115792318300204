<div
  class="row odx-form-field--horizontal odx-form-field"
  [ngClass]="{ 'odx-form-field--required': item.required }"
  [formGroup]="form"
>
  <div class="col-6">
    <label class="odx-form-field__label" [for]="item.id">
      <odx-form-field-label class="odx-form-field-label">
        {{ item.name | translate }}
      </odx-form-field-label>
      @if (item.required) {
        <span class="odx-form-field__label-is-required ng-star-inserted">*</span>
      }
    </label>
  </div>
  <div class="col-6">
    <div class="numeric-type-input-wrapp">
      <div class="odx-form-field__inner">
        <div
          class="odx-form-field__control"
          [ngClass]="{
            'odx-textfield--warning':
              form.get(item.id)?.value &&
              ((item.itemValue.minimum !== null && form.get(item.id)?.value < item.itemValue.minimum) ||
                (item.itemValue.maximum !== null && form.get(item.id)?.value > item.itemValue.maximum)),
          }"
        >
          <input
            type="text"
            ignisDisableAutocomplete
            [formControlName]="item.id"
            [attr.data-test-id]="item.id"
            [placeholder]="'COMPLETE_CHECKLIST.STR_NUMERIC_TYPE_PLACEHOLDER' | translate"
            [ignisCustomDigitDecimalNumber]="item.id"
            [directiveFormData]="form"
            (blur)="checkOutOfRange()"
            class="odx-form-field-control"
          />
        </div>
      </div>
      <span class="unit" [title]="item.itemValue.unit">{{ item.itemValue.unit }}</span>
    </div>
    <span class="numeric-type-info" *ngIf="item.itemValue.minimum !== null && item.itemValue.maximum !== null">
      {{
        'COMPLETE_CHECKLIST.STR_NUMERIC_TYPE_INFO_TEXT_BOTH'
          | translate: { minimum: item.itemValue.minimum, maximum: item.itemValue.maximum }
          | replaceDigitChar
            : {
                minimum: item.itemValue.minimum,
                maximum: item.itemValue.maximum,
                decimalNumberFormat: decimalNumberFormat,
              }
      }}
    </span>

    <span class="numeric-type-info" *ngIf="item.itemValue.minimum !== null && item.itemValue.maximum === null">
      {{
        'COMPLETE_CHECKLIST.STR_ONLY_MIN_NUMERIC_TYPE_INFO_TEXT'
          | translate: { minimum: item.itemValue.minimum }
          | replaceDigitChar: { minimum: item.itemValue.minimum, decimalNumberFormat: decimalNumberFormat }
      }}
    </span>

    <span class="numeric-type-info" *ngIf="item.itemValue.maximum !== null && item.itemValue.minimum === null">
      {{
        'COMPLETE_CHECKLIST.STR_ONLY_MAX_NUMERIC_TYPE_INFO_TEXT'
          | translate: { maximum: item.itemValue.maximum }
          | replaceDigitChar: { maximum: item.itemValue.maximum, decimalNumberFormat: decimalNumberFormat }
      }}
    </span>
  </div>
</div>
