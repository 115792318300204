<div class="unauthorized-container">
  <div class="page-container">
    <img src="/assets/img/logo.svg" alt="logo" />
    <img src="/assets/img/unauthorized.svg" alt="unauthorized-logo" class="unauthorized-logo" />

    <p class="no-permission">Wrong system time</p>

    <p class="no-permission-text">
      We're sorry, but it looks like the date and time settings on your computer are incorrect. Please make sure your
      system date and time are set accurately.
    </p>
    <p class="no-permission-text-second">Please log out, adjust your device's system time and try again.</p>
    <div class="license-container">
      <p class="back_text">
        <span class="back_link" (click)="logout()" data-test-id="root.logout"> Logout </span>
      </p>
    </div>
  </div>
</div>
