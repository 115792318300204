import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { cloneDeep } from 'lodash-es';
import { ITask } from 'src/app/configuration/models';

@Component({
  selector: 'ignis-services-tables',
  templateUrl: './services-tables.component.html',
  styleUrls: ['./services-tables.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicesTablesComponent {
  @Input() form: FormGroup;
  @Input() formatDate: string;
  @Input() otherTaskIntervals: ITask[] = [];
  @Input() selectedTaskIntervals: ITask[] = [];

  @Output() handleOtherTaskIntervals: EventEmitter<ITask[]> = new EventEmitter<ITask[]>();

  testIsExpired(date: string): boolean {
    return new Date(date) < new Date();
  }

  changeOtherService(service: ITask): void {
    this.otherTaskIntervals = cloneDeep(this.otherTaskIntervals);

    this.otherTaskIntervals.forEach((otherService: ITask) => {
      if (otherService.serviceId === service.serviceId) {
        otherService.included = !otherService.included;
      }
    });

    this.handleOtherTaskIntervals.emit(this.otherTaskIntervals);

    this.form?.markAsDirty();
    this.form?.get('otherTasks').markAsTouched();
    this.form?.get('otherTasks').markAsDirty();
  }
}
