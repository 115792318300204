import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { HttpHelper } from 'src/app/common';
import { IEntryModel, PropertyBag } from 'src/app/common/models/common.model';
import { environment } from 'src/environments/environment';
import { ReportCenterConstants } from '../constants/report-center.constants';
import {
  IEcp,
  IEntriesModel,
  IEventMarker,
  IIncident,
  IIncidentAnalysis,
  IIncidentExcelData,
  IIncidentsIds,
  IMediaAssets,
  INewNote,
  INote,
} from '../models/incident.model';
import { IIncidentPage } from './../models/incident.model';

@Injectable()
export class IncidentService {
  constructor(
    private httpClient: HttpClient,
    private httpHelper: HttpHelper,
    private translateService: TranslateService,
  ) {}

  private baseUrl: string = environment.API_URLS.REPORT_CENTER;

  public getIncidents(params?: any): Observable<IIncidentPage | HttpErrorResponse> {
    return this.httpClient.get<IIncidentPage>(`${this.baseUrl}/incidents`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: { ...params },
    });
  }

  public getIncidentById(id: string): Observable<IIncident | HttpErrorResponse> {
    return this.httpClient
      .get<IIncident>(`${this.baseUrl}/incidents/${id}`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      })
      .pipe(
        tap((response: HttpResponseBase | any) => {
          /* istanbul ignore next */
          if (response.body) {
            response.body.version = response.headers?.get('etag');
          }
        }),
      );
  }

  public getIncidentEntries(): Observable<IEntriesModel | HttpErrorResponse> {
    return this.httpClient.get<IEntriesModel>(`${this.baseUrl}/incidents/filter-options`, this.httpHelper.options);
  }

  public updateIncident(incident: IIncident): Observable<IIncident | HttpErrorResponse> {
    const incidentToUpdate: IIncident = structuredClone({ ...incident });
    const incidentId: string = incident.aggregateId;
    const version: string = incident.version?.toString();

    delete incidentToUpdate.version;
    delete incidentToUpdate.aggregateId;

    incidentToUpdate.ecps.forEach((ecp: IEcp) => {
      delete ecp.location.coordinates;
      delete ecp.user;
      delete ecp.name;
    });

    return this.httpClient.put<IIncident>(`${this.baseUrl}/incidents/${incidentId}`, incidentToUpdate, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'If-Match': version,
      }),
    });
  }

  public mergeIncidents(incidentsIds: IIncidentsIds): Observable<IIncident[] | IIncidentsIds | HttpErrorResponse> {
    const objToSend: IIncidentsIds = { ...incidentsIds };
    const version: string = incidentsIds.sourceVersion?.toString();

    delete objToSend.sourceVersion;

    return this.httpClient.put<IIncidentsIds>(`${this.baseUrl}/incidents/merge`, objToSend, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'If-Match': version,
      }),
    });
  }

  public getIncidentExcelData(request: any): Observable<IIncidentExcelData[] | HttpErrorResponse> {
    return this.httpClient.get<IIncidentExcelData[]>(
      `${this.baseUrl}/incidents/${request.payload}/data-export`,
      this.httpHelper.options,
    );
  }

  public getIncidentAnalysis(id: string): Observable<IIncidentAnalysis | HttpErrorResponse> {
    return this.httpClient
      .get<IIncidentAnalysis>(`${this.baseUrl}/incidents/${id}/analysis`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      })
      .pipe(
        tap((response: HttpResponseBase | any) => {
          /* istanbul ignore next */
          if (response.body) {
            response.body.version = response.headers?.get('etag');
          }
        }),
      );
  }

  public getEventMarkersEntries(): Observable<IEntryModel[] | HttpErrorResponse> {
    return this.httpClient.get<IEntryModel[]>(`${this.baseUrl}/incidents/event-markers/types`, this.httpHelper.options);
  }

  public addEventMarker(
    eventMarker: IEventMarker,
    incidentId: string,
    ecpAddress: string,
  ): Observable<IEventMarker | HttpErrorResponse> {
    const objToSend: IEventMarker = { ...eventMarker };
    const version: string = eventMarker.version?.toString();

    objToSend.startTime = new Date(objToSend.startTime).toISOString().replace(/Z$/, '');
    objToSend.endTime = new Date(objToSend.endTime).toISOString().replace(/Z$/, '');

    delete objToSend.id;
    delete objToSend.version;
    delete objToSend.duration;
    delete objToSend.ecp;

    return this.httpClient.post<IEventMarker>(
      `${this.baseUrl}/incidents/${incidentId}/${ecpAddress}/event-markers`,
      objToSend,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'If-Match': version,
        }),
      },
    );
  }

  public updateEventMarker(
    eventMarker: IEventMarker,
    incidentId: string,
    ecpAddress: string,
  ): Observable<IEventMarker | HttpErrorResponse> {
    const editedEventMarker: IEventMarker = { ...eventMarker };
    const version: string = eventMarker.version?.toString();

    editedEventMarker.startTime = new Date(editedEventMarker.startTime).toISOString().replace(/Z$/, '');
    editedEventMarker.endTime = new Date(editedEventMarker.endTime).toISOString().replace(/Z$/, '');

    delete editedEventMarker.version;
    delete editedEventMarker.id;
    delete editedEventMarker.duration;
    delete editedEventMarker.ecp;

    return this.httpClient.put<IEventMarker>(
      `${this.baseUrl}/incidents/${incidentId}/${ecpAddress}/event-markers/${eventMarker.id}`,
      editedEventMarker,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'If-Match': version,
        }),
      },
    );
  }

  public getEventMarkerById(
    id: string,
    eventMarkerId: string,
    ecpAddress: string,
  ): Observable<IEventMarker | HttpErrorResponse> {
    return this.httpClient
      .get<IEventMarker>(
        `${this.baseUrl}/incidents/${id}/${ecpAddress ? ecpAddress : '-'}/event-markers/${eventMarkerId}`,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
          observe: 'response',
        },
      )
      .pipe(
        tap((response: HttpResponseBase | any) => {
          /* istanbul ignore next */
          if (response.body) {
            response.body.version = response.headers?.get('etag');
          }
        }),
      );
  }

  public deleteEventMarker(
    id: string,
    eventMarker: IEventMarker,
    ecpAddress: string,
    version: number,
  ): Observable<any | HttpErrorResponse> {
    return this.httpClient.delete<void>(
      `${this.baseUrl}/incidents/${id}/${ecpAddress}/event-markers/${eventMarker.id}`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'If-Match': version.toString(),
        }),
      },
    );
  }

  public getMediaAssets(id: string): Observable<IMediaAssets[] | HttpErrorResponse> {
    return this.httpClient
      .get<IMediaAssets[]>(`${this.baseUrl}/incidents/${id}/assets`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        observe: 'response',
      })
      .pipe(
        tap((response: HttpResponseBase | any) => {
          /* istanbul ignore next */
          if (response.body?.length > 0) {
            response.body[0].version = response.headers?.get('etag');
          } else {
            localStorage.setItem(ReportCenterConstants.mediaAssetsVersion, response.headers?.get('etag'));
          }
        }),
      );
  }

  public addNote(
    incidentId: string,
    newNote: INewNote[] | any,
    version: number,
  ): Observable<INewNote | HttpErrorResponse> {
    const noteData: INewNote[] | any = structuredClone(newNote);

    noteData.forEach((note: INote) => {
      delete note.senderName;
    });

    return this.httpClient.post<INewNote>(`${this.baseUrl}/incidents/${incidentId}/notes`, noteData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'If-Match': version.toString(),
      }),
    });
  }

  public translateOccurredEvents(occurredEvent: string): string {
    const events: PropertyBag = {
      motion_alarm: this.translateService.instant('INCIDENT_ALARM_TYPE.AUTOMATIC_DSU'),
      distress_button: this.translateService.instant('INCIDENT_ALARM_TYPE.MANUAL_DSU'),
      ecb_evacuation: this.translateService.instant('INCIDENT_ALARM_TYPE.EVACUATION_SIGNAL'),
      ecb_out_withdraw: this.translateService.instant('INCIDENT_ALARM_TYPE.WITHDRAW_SIGNAL'),
      low_pressure_1_alarm: this.translateService.instant('INCIDENT_ALARM_TYPE.STR_LOW_PRESSURE_ALARM_1'),
      low_pressure_2_alarm: this.translateService.instant('INCIDENT_ALARM_TYPE.STR_LOW_PRESSURE_ALARM_2'),
      manual: this.translateService.instant('INCIDENT_ALARM_TYPE.MANUAL_EVENT'),
      media: this.translateService.instant('INCIDENT_ALARM_TYPE.MEDIA_ASSET_TOOLTIP'),
      hazmat_type: this.translateService.instant('INCIDENT_ALARM_TYPE.STR_HAZMAT'),
      witnessed_fatality: this.translateService.instant('INCIDENT_ALARM_TYPE.STR_WITNESSED_FATALITY'),
      asbestos: this.translateService.instant('INCIDENT_ALARM_TYPE.STR_HAZMAT_ASBESTOS'),
      hazmat: this.translateService.instant('INCIDENT_ALARM_TYPE.STR_HAZMAT_HAZMAT'),
      extreme_heat: this.translateService.instant('INCIDENT_ALARM_TYPE.STR_HAZMAT_EXTREME_HEAT'),
      other: this.translateService.instant('INCIDENT_ALARM_TYPE.STR_HAZMAT_OTHER'),
    };

    if (!Object.keys(events).includes(occurredEvent)) {
      events[occurredEvent] = this.translateService.instant('INCIDENT_ALARM_TYPE.STR_CUSTOM_HAZMAT', {
        event: occurredEvent,
      });
    }

    return events[occurredEvent];
  }
}
