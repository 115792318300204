import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IEventMarker, IIncident, IIncidentsIds, INewNote } from '../../models/incident.model';
import { IIncidentState } from '../models/incident.model';
import { RequestIncidentAnalysis } from './incident-action-types-creators/incident-analysis-action-types.creators';
import {
  ReceiveIncidentById,
  RequestIncidentById,
  RequestIncidentEntries,
  RequestIncidentExport,
  RequestIncidentPage,
  RequestUpdateIncident,
} from './incident-action-types-creators/incident-data-action-types-creators';
import {
  RequestAddEventMarker,
  RequestEventMarkerById,
  RequestEventMarkerDelete,
  RequestEventMarkersEntries,
  RequestUpdateEventMarker,
} from './incident-action-types-creators/incident-event-marker-action-types-creators';
import { RequestIncidentExcelData } from './incident-action-types-creators/incident-excel-action-types-creators';
import {
  RequestMergeIncidents,
  ResetState,
} from './incident-action-types-creators/incident-merge-action-types-creators';
import {
  RequestAddNoteOrMediaFile,
  RequestIncidentMediaAssets,
} from './incident-action-types-creators/incident-note-action-types-creators';

@Injectable({ providedIn: 'root' })
export class IncidentActions {
  constructor(private store: Store<IIncidentState>) {}

  public requestIncidentPage(params?: any, isExport: boolean = false): void {
    this.store.dispatch(isExport ? new RequestIncidentExport(params) : new RequestIncidentPage(params));
  }

  public selectIncident(incident: IIncident): void {
    this.store.dispatch(new ReceiveIncidentById(incident));
  }

  public requestIncidentById(id: string): void {
    this.store.dispatch(new RequestIncidentById(id));
  }

  public requestIncidentEntries(): void {
    this.store.dispatch(new RequestIncidentEntries());
  }

  public requestUpdateIncident(incident: IIncident): any {
    this.store.dispatch(new RequestUpdateIncident(incident));
  }

  public requestMergeIncidents(incidentsIds: IIncidentsIds): void {
    this.store.dispatch(new RequestMergeIncidents(incidentsIds));
  }

  public requestIncidentAnalysis(id: string): void {
    this.store.dispatch(new RequestIncidentAnalysis(id));
  }

  public requestIncidentExcelData(incidentId: string): void {
    this.store.dispatch(new RequestIncidentExcelData(incidentId));
  }

  public requestEventMarkersEntries(): void {
    this.store.dispatch(new RequestEventMarkersEntries());
  }

  public requestAddEventMarker(eventMarker: IEventMarker, incidentId: string, ecpAddress: string): any {
    this.store.dispatch(new RequestAddEventMarker(eventMarker, incidentId, ecpAddress));
  }

  public requestUpdateEventMarker(eventMarker: IEventMarker, incidentId: string, ecpAddress: string): any {
    this.store.dispatch(new RequestUpdateEventMarker(eventMarker, incidentId, ecpAddress));
  }

  public requestEventMarkerById(id: string, eventMarkerId: string, ecpAddress: string): void {
    this.store.dispatch(new RequestEventMarkerById(id, eventMarkerId, ecpAddress));
  }

  public requestEventMarkerDelete(
    incidentId: string,
    eventMarker: IEventMarker,
    ecpAddress: string,
    version: number,
  ): void {
    this.store.dispatch(new RequestEventMarkerDelete(incidentId, eventMarker, ecpAddress, version));
  }

  public requestIncidentMediaAssets(id: string): void {
    this.store.dispatch(new RequestIncidentMediaAssets(id));
  }

  public resetState(): any {
    this.store.dispatch(new ResetState());
  }

  public requestAddNoteOrMediaFile(incidentId: string, newNote: INewNote[], version: number): void {
    this.store.dispatch(new RequestAddNoteOrMediaFile(incidentId, newNote, version));
  }
}
