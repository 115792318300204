import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Theme, ThemingService } from '@odx/angular/theming';
import { CommonConstants } from 'src/app/common';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';

@Component({
  selector: 'ignis-legal-terms-page',
  templateUrl: './legal-terms-page.component.html',
  styleUrls: ['./legal-terms-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegalTermsPageComponent extends OnDestroyMixin() {
  legalTermsForm: FormGroup = new FormGroup({
    accepted: new FormControl(false, [Validators.required]),
  });

  @Input() hyperLink: string = null;
  @Input() gdprMode: boolean = true;
  @Input() dpaMode: boolean = false;
  @Output() accepted: EventEmitter<void> = new EventEmitter();

  legalTermsAccepted: boolean = false;

  translateService: TranslateService = inject(TranslateService);
  cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  themingService: ThemingService = inject(ThemingService);

  constructor() {
    super();

    this.themingService.selectTheme(Theme.LIGHT);
  }

  submitLegal(): void {
    if (this.legalTermsForm.get('accepted').value) {
      this.accepted.emit();
      this.legalTermsAccepted = true;

      localStorage.setItem(CommonConstants.acceptedTerms, 'true');
      this.cdr.detectChanges();
    }
  }
}
