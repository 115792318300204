import { IStoreApiItem, StoreApiItem } from 'src/app/common';
import { IStoreApiList, StoreApiList } from '../../../common/models/store-api-list.model';
import { IRole, IRoleExport, IRoleModule } from '../../models/role.model';

export interface IRoleState {
  roleList: IStoreApiList<IRole[]>;
  permissionList: IStoreApiList<IRoleModule[]>;
  roleListExport: IStoreApiItem<IRoleExport[]>;
}

export class RoleState implements IRoleState {
  roleList: IStoreApiList<IRole[]> = new StoreApiList();
  permissionList: IStoreApiList<IRoleModule[]> = new StoreApiList();
  roleListExport: IStoreApiItem<IRoleExport[]> = new StoreApiItem();
}
