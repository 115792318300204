import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalRef, ModalService, ModalSize, ModalVariant } from '@odx/angular/components/modal';
import { IncidentActions } from '../../state/actions/incident.actions';
import { IIncident, IIncidentsIds } from './../../models/incident.model';

@Component({
  selector: 'ignis-confirm-merge-incidents',
  templateUrl: './confirm-merge-incidents.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmMergeIncidentsComponent implements OnChanges, OnDestroy {
  @ViewChild('confirmMergeIncidentsModal', { read: TemplateRef })
  public confirmMergeIncidentsModal: TemplateRef<any>;
  modalRef: ModalRef;
  @Input() openModal: boolean = false;
  @Input() selectedIncidents: IIncident[];
  @Output() handleCloseConfirmMergeIncidentModal: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private readonly modalService: ModalService,
    private router: Router,
    private incidentActions: IncidentActions,
  ) {}

  ngOnChanges(): void {
    if (this.openModal) {
      this.openConfirmMergeIncidentsModal();
    }
  }

  openConfirmMergeIncidentsModal(): void {
    this.modalRef = this.modalService.open(this.confirmMergeIncidentsModal, {
      size: ModalSize.SMALL,
      variant: ModalVariant.DEFAULT,
      dismissable: false,
      data: 'isOpen',
    });
  }

  proceedMerge(): void {
    const incidentsIds: IIncidentsIds = {
      incidentNumberOneId: this.selectedIncidents[0].aggregateId,
      incidentNumberTwoId: this.selectedIncidents[1].aggregateId,
      sourceVersion: this.selectedIncidents[0].version,
    };

    this.incidentActions.requestMergeIncidents(incidentsIds);
    this.handleCloseConfirmMergeIncidentModal.emit('merge');
  }

  close(): void {
    this.handleCloseConfirmMergeIncidentModal.emit('close');
  }

  ngOnDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (this.router.url !== 'report-center' && this.modalRef?.data === 'isOpen') {
      this.modalRef.close('');
    }
  }
}
