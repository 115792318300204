import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IFireStationList } from '../../../../common';
import { BAWearerActionTypes } from '../ba-wearer-action-types';

export class RequestFireStationList implements Action {
  readonly type: BAWearerActionTypes.REQUEST_FIRE_STATION_LIST = BAWearerActionTypes.REQUEST_FIRE_STATION_LIST;
}

export class ReceiveFireStationList implements Action {
  readonly type: BAWearerActionTypes.RECEIVE_FIRE_STATION_LIST = BAWearerActionTypes.RECEIVE_FIRE_STATION_LIST;

  constructor(public payload: IFireStationList) {}
}

export class ErrorReceiveFireStationList implements Action {
  readonly type: BAWearerActionTypes.ERROR_RECEIVE_FIRE_STATION_LIST =
    BAWearerActionTypes.ERROR_RECEIVE_FIRE_STATION_LIST;

  constructor(public payload: HttpErrorResponse) {}
}
