import { inject, Injectable } from '@angular/core';
import { AccessControlService } from 'src/app/root';
import { AppUserPermissionList, AppUserRole } from 'src/app/root/models/app-types';
import { WorkshopModuleRoutes } from '../../../workshop/constants';
import { FeatureModuleIconEnum, FeatureModuleRouteForNavigationEnum } from '../../enums/feature-module';
import { IFeatureModule, IFeatureModuleTitle } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class FeatureModuleBuilderService {
  featureModuleTitleEnum: IFeatureModuleTitle;
  userRole: string;
  role: AppUserRole;

  accessControlService: AccessControlService = inject(AccessControlService);

  getFeatureModuleData(
    featureModuleTitleEnum: IFeatureModuleTitle,
    userRole: string,
    role: AppUserRole,
  ): IFeatureModule[] {
    this.initData(featureModuleTitleEnum, userRole, role);

    return [
      this.getEquipmentModule(),
      this.getRemoteMonitoringModule(),
      this.getLogbookModule(),
      this.getReportCenterModule(),
      this.getConfigurationModule(),
      this.getBAWearerModule(),
      this.getUserManagementModule(),
      this.getRoleManagementModule(),
      this.getDeviceConnectionModule(),
    ];
  }

  protected getEquipmentModule(): IFeatureModule {
    return {
      title: this.featureModuleTitleEnum.Workshop,
      route: this.checkWorkShopRoute(),
      icon: FeatureModuleIconEnum.Workshop,
      visibility:
        this.accessControlService.workshopFeatureToggle && this.checkPermission(AppUserPermissionList.equipmentView),
    };
  }

  protected getRemoteMonitoringModule(): IFeatureModule {
    return {
      title: this.featureModuleTitleEnum.RemoteMonitoring,
      route: FeatureModuleRouteForNavigationEnum.RemoteMonitoring,
      icon: FeatureModuleIconEnum.RemoteMonitoring,
      visibility:
        this.accessControlService.remoteMonitoringFeatureToggle &&
        this.checkPermission(AppUserPermissionList.remoteMonitoring),
    };
  }

  protected getLogbookModule(): IFeatureModule {
    return {
      title: this.featureModuleTitleEnum.Logbook,
      route: FeatureModuleRouteForNavigationEnum.Logbook,
      icon: FeatureModuleIconEnum.Logbook,
      visibility: this.accessControlService.logbookFeatureToggle && this.checkPermission(AppUserPermissionList.logbook),
    };
  }

  protected getReportCenterModule(): IFeatureModule {
    return {
      title: this.featureModuleTitleEnum.ReportCenter,
      route: FeatureModuleRouteForNavigationEnum.ReportCenter,
      icon: FeatureModuleIconEnum.ReportCenter,
      visibility:
        this.accessControlService.reportCenterFeatureToggle && this.checkPermission(AppUserPermissionList.reportCenter),
    };
  }

  protected getConfigurationModule(): IFeatureModule {
    const isITManager: boolean =
      this.checkPermission(AppUserPermissionList.configuration) &&
      this.checkPermission(AppUserPermissionList.organization) &&
      !this.checkPermission(AppUserPermissionList.equipmentWrite);
    const checkUrlForLocationFeatureToggle: string = this.accessControlService.locationFeatureToggle
      ? FeatureModuleRouteForNavigationEnum.ConfigurationLocation
      : FeatureModuleRouteForNavigationEnum.Configuration;

    return {
      title: this.featureModuleTitleEnum.Configuration,
      route:
        isITManager || !this.accessControlService.workshopFeatureToggle
          ? FeatureModuleRouteForNavigationEnum.ConfigurationOrganizationSettings
          : checkUrlForLocationFeatureToggle,
      icon: FeatureModuleIconEnum.Configuration,
      visibility:
        (this.checkPermission(AppUserPermissionList.configuration) &&
          this.checkPermission(AppUserPermissionList.equipmentWrite)) ||
        (this.checkPermission(AppUserPermissionList.configuration) &&
          this.checkPermission(AppUserPermissionList.organization)),
    };
  }

  protected getUserManagementModule(): IFeatureModule {
    return {
      title: this.featureModuleTitleEnum.UserManagement,
      route: FeatureModuleRouteForNavigationEnum.UserManagement,
      icon: FeatureModuleIconEnum.UserManagement,
      visibility:
        ((this.userRole as AppUserRole) === this.role || (this.userRole as AppUserRole) === AppUserRole.itManager) &&
        this.checkPermission(AppUserPermissionList.userManagement),
    };
  }

  protected getRoleManagementModule(): IFeatureModule {
    return {
      title: this.featureModuleTitleEnum.RoleManagement,
      route: FeatureModuleRouteForNavigationEnum.RoleManagement,
      icon: FeatureModuleIconEnum.RoleManagement,
      visibility:
        this.accessControlService.roleManagementGlobalFeatureToggle &&
        ((this.userRole as AppUserRole) === this.role || (this.userRole as AppUserRole) === AppUserRole.itManager) &&
        this.checkPermission(AppUserPermissionList.roleManagement) &&
        this.checkPermission(AppUserPermissionList.userManagement),
    };
  }

  protected getDeviceConnectionModule(): IFeatureModule {
    return {
      title: this.featureModuleTitleEnum.DeviceConnection,
      route: FeatureModuleRouteForNavigationEnum.DeviceConnection,
      icon: FeatureModuleIconEnum.DeviceConnection,
      visibility:
        this.accessControlService.deviceConnectionsManagementFeatureToggle &&
        ((this.userRole as AppUserRole) === this.role ||
          (this.userRole as AppUserRole) === AppUserRole.technician ||
          (this.userRole as AppUserRole) === AppUserRole.itManager) &&
        this.checkPermission(AppUserPermissionList.deviceConnections),
    };
  }

  protected getBAWearerModule(): IFeatureModule {
    return {
      title: this.featureModuleTitleEnum.BAWearer,
      route: FeatureModuleRouteForNavigationEnum.BAWearer,
      icon: FeatureModuleIconEnum.BAWearer,
      visibility:
        this.accessControlService.baWearerFeatureToggle &&
        ((this.userRole as AppUserRole) === this.role ||
          (this.userRole as AppUserRole) === AppUserRole.fireChief ||
          (this.userRole as AppUserRole) === AppUserRole.itManager) &&
        this.checkPermission(AppUserPermissionList.baWearer),
    };
  }

  private initData(featureModuleTitleEnum: IFeatureModuleTitle, userRole: string, role: AppUserRole): void {
    this.featureModuleTitleEnum = featureModuleTitleEnum;
    this.userRole = userRole;
    this.role = role;
  }

  private checkPermission(permissionName: AppUserPermissionList): boolean {
    return this.accessControlService.checkPermission(permissionName);
  }

  private checkWorkShopRoute(): string {
    return this.accessControlService.equipmentWorkflowPageFeatureToggle
      ? FeatureModuleRouteForNavigationEnum.Workshop
      : `${WorkshopModuleRoutes.workshop}/${WorkshopModuleRoutes.inventory}`;
  }
}
